/* eslint-disable react/no-children-prop */
import React, { useState, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  ButtonToolbar,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues, change } from "redux-form";
import queryString from "query-string";
import CryptoJS from "crypto-js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { Tooltip } from "@material-ui/core";
import LinkIcon from "mdi-react/LinkIcon";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classnames from "classnames";
import { _hasPermission, _shareThemes } from "../../../../shared/helpers/access";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { _slugifyText, _privateKey, matTheme, _urlSafe } from "../../../../shared/helpers/utils";
import { _axiosCall } from "../../../helpers/apicalls";
import { ThemeProvider } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import RichTextEditor from "../RichTextEditor";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  children,
  select,
  rows,
}) => (
  <ThemeProvider theme={matTheme}>
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      select={select}
      multiline={rows}
      rows={rows}
      onFocus={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
      variant="outlined"
    />
  </ThemeProvider>
);

const renderCheckbox = ({ input, label }) => {
  console.log(input.value);
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  );
};

const renderLinkField = ({
  input,
  label,
  meta: { asyncValidating, touched, error },
  children,
  select,
  rows,
}) => {
  console.log(touched, error);
  return (
    <ThemeProvider theme={matTheme}>
      <TextField
        className="material-form__link"
        label={label}
        error={error !== undefined}
        helperText={error}
        value={_urlSafe(input.value)}
        children={children}
        select={select}
        multiline={rows}
        rows={rows}
        onChange={(e) => {
          e.preventDefault();

          input.onChange(e.target.value);
          //checkLink(e.target.value)
        }}
      />
    </ThemeProvider>
  );
};

const renderRichTextEditor = ({
  input,
  label,
  meta: { touched, error },
  children,
  select,
  rows,
}) => {
  const editorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 300,
    allowResizeX: false,
    enableDragAndDropFileToEditor: true,
    buttons: [
      "bold",
      "italic",
      "underline",
      "fontsize",
      "|",
      "link",
      "hr",
      "align",
      "|",
      "undo",
      "redo",
    ],
    buttonsMD: [
      "bold",
      "italic",
      "underline",
      "fontsize",
      "|",
      "link",
      "hr",
      "align",
      "|",
      "undo",
      "redo",
    ],
    buttonsXS: [
      "bold",
      "italic",
      "underline",
      "fontsize",
      "|",
      "link",
      "hr",
      "align",
      "|",
      "undo",
      "redo",
    ],
    colorPickerDefaultTab: "text",
  };
  return (
    <RichTextEditor
      value={input.value}
      onChange={(content) => {
        input.onChange(content);
      }}
      editorConfig={editorConfig}
    />
  );
};
renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: "",
  meta: null,
  select: false,
  children: [],
};

const apiUrl = process.env.API_ROOT + "/buyerslist/";
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const checkLink = (formValues) => {
  if (!formValues.customLink || formValues.customLink.length < 3) {
    return sleep(1).then(() => {
      throw { customLink: "Must be 3 or more characters." };
    });
  }

  let dupquery = { custom_link: formValues.customLink };

  let apiData = {
    url: apiUrl + "sharepage/query",
    method: "post",
    query: dupquery,
  };

  return _axiosCall(apiData).then((res) => {
    if (res.length > 0) {
      throw { customLink: "Link has already been used." };
    }
  });
};

const generateShareLink = (props, activeTab) => {
  const { buildingRes, property, formValues } = props;
  let building_slug = buildingRes.key.substr(4);

  let shareBaseURL = `https://share.marketproof.com/building/`;

  const encryptedBuildingSlug = btoa(
    CryptoJS.AES.encrypt(building_slug, _privateKey)
  );
  let shareLink = `${building_slug}`;
  if (property && property.address_2_normalized) {
    shareBaseURL = `https://share.marketproof.com/unit/`;
    shareLink = `${building_slug}/${_slugifyText(
      property.address_2_normalized
    )}`;
  }

  let queryStr = false;
  if (formValues) {
    let queryArray = {};
    Object.keys(formValues)
      .filter((key) => ["ap", "lk"].indexOf(key) > -1)
      .map((key) => {
        if (formValues[key] === true) {
          if (key === "ap") {
            queryArray[key] = props.user.id ? props.user.id : ""; //formValues[key];
          } else {
            queryArray[key] = formValues[key];
          }
        }
      });
    queryStr = queryString.stringify(queryArray); //.filter((key) => ['ap', 'addcontact', 'addsalesgallery'].indexOf(key)>-1)
  }
  if (queryStr) {
    shareLink += "?" + queryStr;
  }
  console.log(shareLink);
  shareLink = btoa(CryptoJS.AES.encrypt(shareLink, _privateKey));

  return shareBaseURL + shareLink;
};

const ShareForm = (props) => {
  const {
    handleSubmit,
    reset,
    t,
    buildingRes,
    user,
    toggle,
    property,
    values,
    listing,
    handleSave,
    generateShareBase,
    generateShareLink,
  } = props;
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const renderRadioThemes = ({ input, label, disabled }) => {
    return (
      <RadioGroup
        aria-label="reportTheme"
        name="reportTheme"
        value={input.value}
        onChange={input.onChange}
        onClick={(e) => e.stopPropagation()}
        row
      >
        <FormControlLabel
          value="marketproof"
          control={<Radio />}
          label="Marketproof"
        />
        {user && user.themes && user.themes.length > 0 && (
          <Fragment>
            {user.themes.map((theme) => {
              if (_shareThemes[theme]) {
                return (
                  <FormControlLabel
                    value={theme}
                    control={<Radio />}
                    label={_shareThemes[theme]["display_name"]}
                  />
                );
              }
            })}
          </Fragment>
        )}
      </RadioGroup>
    );
  };
  const renderRadioContact = ({ input, label, disabled }) => {
    return (
      <RadioGroup
        aria-label="contactForm"
        name="contactForm"
        value={input.value}
        onChange={input.onChange}
        onClick={(e) => e.stopPropagation()}
        row
      >
        <FormControlLabel
          value="ap"
          control={<Radio />}
          label="Show My Profile"
        />
       {/* <FormControlLabel
          value="contact"
          control={<Radio />}
          label="Request Information"
        />*/}
        <FormControlLabel
          value=""
          control={<Radio />}
          label="No Contact Info"
        />
      </RadioGroup>
    );
  };

  return (
    <form className="material-form" onSubmit={handleSubmit}>
      <Row>
        {/* <Col sm={12} md={12} lg={12} xl={12}>
          <div className="checkbox__items mb-2">
            <Field
              name="ap"
              component={renderCheckbox}
              label={
                <span>
                  Include My Name and Contact (Update in{" "}
                  <Link to={`/my-account`} className="link-text">
                    My Account
                  </Link>
                  )
                </span>
              }
              value="info"
            />
          </div>
        </Col> */}
        {listing && listing.sale_status === "active" && (
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="share__link mb-2">
              <Field
                name="lk"
                component={renderCheckbox}
                label="Include a Link to the Active Listing"
                value="link"
              />
            </div>
          </Col>
        )}

        <Col sm={12} md={12} lg={12} xl={12}>
          <div className="share__link">
            <span className="material-form__label bold">Message</span>
            <Field
              name="message"
              component={renderRichTextEditor}
              placeholder=""
              multiline
              rows="2"
              rowsMax="2"
            />
          </div>
        </Col>

        <Col sm={12} md={12} lg={12} xl={12}>
          <div className="share__link">
            <hr style={{ borderColor: "#EEE" }} />
            <span className="material-form__label  bold">Contact Method</span>
            <div className="small-text light-text">
              Form submissions will be sent to your email address. Update your
              profile in{" "}
              <Link target="_blank" to={`/my-account`} className="link-text">
                My Account
              </Link>
              .
            </div>
            <Field
              name="contactForm"
              component={renderRadioContact}
              label="Add a Contact Form"
              value="show"
            />
          </div>
        </Col>
        {/* user && user.themes && user.themes.length > 0 && (
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="share__link">
              <hr style={{ borderColor: "#EEE" }} />
              <span className="material-form__label  bold">Theme</span>
              <Field
                name="reportTheme"
                component={renderRadioThemes}
                placeholder=""
              />
            </div>
          </Col>
        )*/}
        <Col sm={12} md={12} lg={12} xl={12} className="mt-2 mb-4">
          <div>
            <span className="material-form__label bold">Share Link</span>

            <div className="custom_share">
              <div className="share_base">{generateShareBase()}</div>
              <div>
                <Field
                  name="customLink"
                  component={renderLinkField}
                  placeholder=""
                  checkLink={checkLink}
                />
              </div>
              <div>
                <span className="material-form__label">
                  <a href="javascript:void(0)" onClick={() => handleSave(true)}>
                    Preview <OpenInNewIcon size={12} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={12} lg={12} xl={12}>
          <div className="share__link">
            <p>
              <span className="material-form__label">
                Save and copy the link to share. Anyone with the link will have
                access. You can delete or update the page under{" "}
                <a href="/my-shared" target="_blank">
                  My Shared
                </a>
              </span>
              .
            </p>
            <hr />
          </div>
        </Col>
      </Row>
      <ButtonToolbar className="modal__footer">
        {/* <Field
          name="saveme"
          component={renderCheckbox}
          label={`Save this ${property ? "Unit" : "Building"}`}
          value="saveme"
        /> */}
        <Tooltip title="Cancel">
          <Button
            onClick={toggle}
            variant="outlined"
            color=""
            className=""
            startIcon={null}
          >
            {" "}
            Cancel
          </Button>
        </Tooltip>{" "}
        <Tooltip title="Save & Copy Share Link to Clipboard">
          <CopyToClipboard
            text={generateShareLink(props)}
            onCopy={() => handleSave()}
          >
            <Button
              variant="contained"
              color="primary"
              className="buyersList__primary-button"
              startIcon={<LinkIcon />}
            >
              {" "}
              Save & Copy Link
            </Button>
          </CopyToClipboard>
        </Tooltip>
      </ButtonToolbar>
    </form>
  );
};

ShareForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
const form = "share_form";

export default connect((state, props) => {
  let savedValues = {};
  if (props.report) {
    savedValues["ap"] =
      props.report.custom_data && props.report.custom_data.ap !== undefined
        ? props.report.custom_data.ap
        : true;
    savedValues["saveme"] =
      props.report.custom_data && props.report.custom_data.saveme !== undefined
        ? props.report.custom_data.saveme
        : true;
    savedValues["customLink"] = props.report.custom_link
      ? props.report.custom_link
      : _slugifyText(nanoid(10));
    savedValues["message"] =
      props.report.custom_data && props.report.custom_data.message !== undefined
        ? props.report.custom_data.message
        : "";
    savedValues["reportTitle"] =
      props.report.custom_data &&
      props.report.custom_data.report_title !== undefined
        ? props.report.custom_data.report_title
        : "";
    savedValues["reportSubTitle"] =
      props.report.custom_data &&
      props.report.custom_data.report_subtitle !== undefined
        ? props.report.custom_data.report_subtitle
        : "";
    savedValues["reportTheme"] =
      props.report.custom_data &&
      props.report.custom_data.report_theme !== undefined
        ? props.report.custom_data.report_theme
        : "marketproof";
    savedValues["preparedFor"] =
      props.report.custom_data &&
      props.report.custom_data.prepared_for !== undefined
        ? props.report.custom_data.prepared_for
        : "";
    savedValues["contactForm"] =
      props.report.custom_data &&
      props.report.custom_data.contact_form !== undefined
        ? props.report.custom_data.contact_form
        : props.report.custom_data && props.report.custom_data.ap
        ? "ap"
        : "";
  } else {
    savedValues["ap"] = true;
    savedValues["saveme"] = true;
    savedValues["customLink"] = _slugifyText(nanoid(10));
    savedValues["message"] = "";
    savedValues["reportTitle"] = "";
    savedValues["reportSubTitle"] = "";
    savedValues["reportTheme"] = "marketproof";
    savedValues["preparedFor"] = "";
    savedValues["contactForm"] = "ap";
  }
  const initalValues = Object.assign(savedValues, props.user);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  };
})(
  reduxForm({
    form: form, // a unique identifier for this form
    asyncValidate: checkLink,
    asyncChangeFields: ["customLink"],
  })(ShareForm)
);
