import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import searchActions from '../../../redux/actions/searchActions';
import { Hits, Index, Highlight, Configure, connectSearchBox } from "react-instantsearch-dom";
import { _capitalizeText } from '../../../shared/helpers/utils';


import SearchTags from './SearchTags';


//currentRefinement = user query, refine is algolia defined function
class MySearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this._clearTagAndQuery = this._clearTagAndQuery.bind(this);
		this._handleKeyPress = this._handleKeyPress.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.log('ASearch didUpdate ', prevProps, this.props, prevState, this.state);
	}

	_inputFocus(e) {
		const input = document.getElementById("tag-input");
		input.focus();
	}

	_clearTagAndQuery(tag) {
		this.props.refine("");
		return this.props.onIcon(tag);
	}

	_openResults = () => {
		//return this.props.onFocus();
	};

	 _handleKeyPress(e) {
		 
	  
	   if(e.key === 'Enter'){
	     console.log('enter press here! ', e.target.value)
	     this.props.handleSearchText(e.target.value);
	   } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
	   	console.log('arrow keys')
	   }
	  }

	render() {
		const searchTags = this.props.searchTags;
		const { clearTags } = this.props.actions;
		const tagLen = searchTags ? searchTags.length : 0;
		const displayTags = !this.props.isMobile
			? searchTags && tagLen > 2
				? searchTags.slice(0, 2)
				: false
			: searchTags && tagLen > 1
			? searchTags.slice(0, 1)
			: false;

		// console.log(
		// 	"ASearch searchTags ", searchTags, 'displayTags', displayTags, 'tagLen', tagLen)
		// 	window.location.pathname.includes("building")
		// );
		return (
			<div
				className="MuiInputBase-root navbar-center c_search-nav"
				onClick={this.props.openAConClick.bind(this)}
			>
				<SearchTags searchBar />
				
				<input
					autoComplete="off"
					style={{
						paddingLeft: this.props.width,
						height: this.props.height,
						paddingTop: this.props.pTop
					}}
					onKeyPress={this._handleKeyPress}
					type="text"
					value={this.props.value}
					onChange={e => this.props.refine(e.target.value)}
					className="c_nav-input MuiInputBase-input"
					placeholder={this.props.placeholder}
					onFocus={this._openResults}
					id="tag-input"
				/>
				{tagLen > 0 &&
					<span className={`c_clear-search`} onClick={() => { return clearTags() }}>Clear</span>
				}
				
				
			</div>
		);
	}
}

const ASearch = connectSearchBox(MySearch);

function mapStateToProps(state) {
	console.log(state)
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(ASearch);
