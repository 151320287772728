import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import renderPhotoUploadField from './fields/PhotoUpload';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';



import Form from "@rjsf/material-ui";
import LayoutField from './LayoutField';

import AssocationStepper from './AssocationStepper';

import renderCompanySearch from './fields/CompanySearch';
import renderPersonSearch from './fields/PersonSearch';
import renderRichTextEditor from './fields/RichTextEditor';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_CreateGuid
} from '../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}

let schema = {
  title: "Project Profile",
  type: "object",
  required: ['name'],
  properties: {
    'contact_search' : {
	      'type' : 'string',
	      'description' : 'Search for the contact name, if not found add as a new contact.'
    },
    'project_role' : {
	      'title' : 'Project Role',
	      'type' : 'string',
	      'description' : 'Primary role on this project.  Ex: Developer.',
	       "enum": ["developer", "marketing", "expeditor", "engineer", "architect",  "architect-landscape", "architect-design","architect-former", "interior designer", "lender", "construction", "property manager", "commercial broker", "residential broker", "code consultant", "filing applicant", "owner", "owner-former",   "developer-former", "marketing-former", "lender-former", "other"],
		   "enumNames": ["Developer", "Marketing", "Expeditor",  "Engineer", "Architect", "Architect (Landscape)",  "Architect (Design)", "Architect (Former)", "Interior Designer", "Lender", "Construction", "Property Manager", "Commercial Broker", "Residential Broker",  "Code Consultant", "Filing Applicant", "Owner", "Owner (Former)",  "Developer (Former)", "Marketing (Former)", "Lender (Former)", "Other"],
    },    
    'first_name' : {
	      'title' : 'First Name',
	      'type' : 'string',
	      'description' : 'First name'
    },
    'last_name' : {
	      'title' : 'Last Name',
	      'type' : 'string',
	      'description' : 'Last name'
    },
    'title' : {
	      'title' : 'Title',
	      'type' : 'string',
	      'description' : 'Title.  Ex: Head of Marketing'
    },
    'company' : {
	      'title' : 'Company',
	      'type' : 'string',
	      'description' : 'Company. Ex: ABC Development Corp'
    },
    'photo': {
      'type': 'string',
      'title': 'Photo',
      'description' : 'Contact headshot.'
    },
    
    'phone' : {
	      'title' : 'Phone Number',
	      'type' : 'array',
	      'description' : 'Phone Numbers',
	      'items': {
		    'type': "string"
		  }
    },
    'email' : {
	      'title' : 'Email',
	      'type' : 'array',
	      'description' : 'Email Addresses',
	      'items': {
		    'type': "string"
		  }
    },
    'website' : {
	      'title' : 'Website',
	      'type' : 'string',
	      'description' : 'Personal Website'
    },
    "full_street_address": {
      'type': 'string',
      "title" : "Full Street Address",
      "description" : "Main business address Ex: 123 5th Avenue"
    },
    "address_2": {
      'type': 'string',
      "title" : "Address 2",
      "description" : "Apt/Suite/Floor Ex: Suite 101"
    },
    "city": {
      'type': 'string',
      "title" : "City",
      "description" : "City"
    },
    "state": {
      'type': 'string',
      "title" : "State",
      "description" : "State"
    },
    "zip": {
      'type': 'string',
      "title" : "Zip Code",
      "description" : "Zipcode of this project. Ex: 10001"
    },
    'linkedin' : {
	      'title' : 'LinkedIn',
	      'type' : 'string',
	      'description' : 'Personal LinkedIn page'
    },
    'twitter' : {
	      'title' : 'Twitter',
	      'type' : 'string',
	      'description' : 'Personal Twitter page'
    },
    'facebook' : {
	      'title' : 'Facebook',
	      'type' : 'string',
	      'description' : 'Personal Facebook page'
    },
    'instagram' : {
	      'title' : 'Instagram',
	      'type' : 'string',
	      'description' : 'Personal Instagram page'
    },
    'description' : {
	  'type': 'string',
      'title': 'Description',
      "description" : "Discription of this person" 
    },
  },
  "definitions": {}
      
    
  
}

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField  
}


const isFilled = (fieldName) => ({ formData }) => (formData[fieldName] && formData[fieldName].length) ? true : false
const isTrue = (fieldName) => ({ formData }) => (formData[fieldName])

const widgets = {
  PersonSearch: renderPersonSearch,
  PersonCompanySearch: renderCompanySearch,
  ImageUpload: renderPhotoUploadField,
  RichTextEditor: renderRichTextEditor
}

let associateSchema = [{ 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Search for a person and select the role the person plays for this project.</p>
              </div>
            )
          } }
        ] } },
      ] },
	  { 'ui:row': [
        { 'ui:col': { md: 8, children: ['contact_search'] } }, 
        { 'ui:col': { md: 4, children: ['project_role'] } },       
      ] }]
let noAssociationSchema = [{ 'ui:row': [
		{ 'ui:col': { md: 12, children: [
		  { render: (props) => {
			const { formData, errorSchema } = props
	  
			return (
			  <div>
				<p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Add a new person (search for existing first)</p>
			  </div>
			)
		  } }
		] } },
	  ] },
	  { 'ui:row': [
		{ 'ui:col': { md: 12, children: ['contact_search'] } }, 
	  ] }]      
let associateSchemaCompany = [{ 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Search for a person or add new.</p>
              </div>
            )
          } }
        ] } },
      ] },
	  { 'ui:row': [
        { 'ui:col': { md: 12, children: ['contact_search'] } }, 
      ] }]

let personDetailSchema = [
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
               
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Edit contact details.  This will update the contact information across all associated projects.</p>
              </div>
            )
          } }
        ] } },
      ] },
	  { 'ui:row': [
        { 'ui:col': { md: 6, children: ['first_name'] } },
        { 'ui:col': { md: 6, children: ['last_name'] } },
        
      ] },
       { 'ui:row': [
	    { 'ui:col': { md: 2, children: ['photo'] } },
		{ 'ui:col': { md: 4, children: ['title'] } },
		{ 'ui:col': { md: 6, children: ['company'] } },
      ] },
      
       { 'ui:row': [
        { 'ui:col': { md: 12, children: ['website'] } }, 
      ] },
     
      { 'ui:row': [
        { 'ui:col': { md: 6, children: ['linkedin'] } }, 
        { 'ui:col': { md: 6, children: ['twitter'] } },
      ] },
       { 'ui:row': [
        { 'ui:col': { md: 6, children: ['instagram'] } }, 
        { 'ui:col': { md: 6, children: ['facebook'] } },
      ] },
     { 'ui:row': [
        { 'ui:col': { md: 8, children: ['full_street_address'] } }, 
        { 'ui:col': { md: 4, children: ['address_2'] } },
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 5, children: ['city'] } }, 
        { 'ui:col': { md: 4, children: ['state'] } }, 
        { 'ui:col': { md: 3, children: ['zip'] } }, 
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
               
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Notes</p>
              </div>
            )
          } }
        ] } },
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: ['description'] } }
      ] }]
	  
let personDetailSchemaEdit = [
		{ 'ui:row': [
		  { 'ui:col': { md: 12, children: [
			{ render: (props) => {
			  const { formData, errorSchema } = props
	  
			  return (
				<div>
				 
				  <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Edit contact details.  This will update the contact information across all associated projects.</p>
				</div>
			  )
			} }
		  ] } },
		] },
		{ 'ui:row': [
		  { 'ui:col': { md: 6, children: ['first_name'] } },
		  { 'ui:col': { md: 6, children: ['last_name'] } },
		  
		] },
		 { 'ui:row': [
		  { 'ui:col': { md: 2, children: ['photo'] } },
		  { 'ui:col': { md: 8, children: ['title'] } },
		 
		] },
		
		 { 'ui:row': [
		  { 'ui:col': { md: 12, children: ['website'] } }, 
		] },
	   
		{ 'ui:row': [
		  { 'ui:col': { md: 6, children: ['linkedin'] } }, 
		  { 'ui:col': { md: 6, children: ['twitter'] } },
		] },
		 { 'ui:row': [
		  { 'ui:col': { md: 6, children: ['instagram'] } }, 
		  { 'ui:col': { md: 6, children: ['facebook'] } },
		] },
	   { 'ui:row': [
		  { 'ui:col': { md: 8, children: ['full_street_address'] } }, 
		  { 'ui:col': { md: 4, children: ['address_2'] } },
		] },
		{ 'ui:row': [
		  { 'ui:col': { md: 5, children: ['city'] } }, 
		  { 'ui:col': { md: 4, children: ['state'] } }, 
		  { 'ui:col': { md: 3, children: ['zip'] } }, 
		] },
		{ 'ui:row': [
		  { 'ui:col': { md: 12, children: [
			{ render: (props) => {
			  const { formData, errorSchema } = props
	  
			  return (
				<div>
				 
				  <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Notes</p>
				</div>
			  )
			} }
		  ] } },
		] },
		{ 'ui:row': [
		  { 'ui:col': { md: 12, children: ['description'] } }
		] }]
const schema0 = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: associateSchema } },
  ] },

	 'contact_search' : {
		  'ui:widget': 'PersonSearch'
	  }
}
const schema0noassociation = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
	{ 'ui:col': { md: 12, children: noAssociationSchema } },
  ] },

	 'contact_search' : {
		  'ui:widget': 'PersonSearch'
	  }
}
const schema0company = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: associateSchemaCompany } },
  ] },

	 'contact_search' : {
		  'ui:widget': 'PersonSearch'
	  }
}

const schema0edit = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: associateSchema } },
  ] },

	 'contact_search' : {
		  'ui:widget': 'PersonSearch',
		  'ui:disabled': true
	  }
}

const schema1 = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: personDetailSchema } },
  ] },

	 'contact_search' : {
		  'ui:widget': 'PersonSearch'
	  },
	  'company' : {
		  'ui:widget': 'PersonCompanySearch'
		  
	  },
	  'photo': {
	  	'ui:widget': 'ImageUpload'   
	  },
	  "categories" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
			    'inline' : true
		  }  
	  },
	  'description': {
	  	'ui:widget': 'RichTextEditor'   
	  },
}

const schema2 = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
	{ 'ui:col': { md: 12, children: personDetailSchemaEdit } },
  ] },

	 'contact_search' : {
		  'ui:widget': 'PersonSearch'
	  },
	  'company' : {
		  'ui:widget': 'PersonCompanySearch'
		  
	  },
	  'photo': {
		  'ui:widget': 'ImageUpload'   
	  },
	  "categories" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
				'inline' : true
		  }  
	  },
	  'description': {
		  'ui:widget': 'RichTextEditor'   
	  },
}

//uiSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'] = [...uiSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'], ...personDetailSchema];

class EditPeople extends Component {

  constructor(props) {
    super(props);
    this.state={
	    activeStep : 0
    }
    this.doValidateSubmit = this.doValidateSubmit.bind(this);
    this.setActiveStep = this.setActiveStep.bind(this);
    
  }
  doValidateSubmit = () => {
	  const { formData, onSubmit } = this.props;
	  
	  const errorList = [];
	  if (!formData.first_name || formData.first_name == "" || !formData.last_name || formData.last_name == "") {
	    errorList.push("First and last name are required");
	  }
	  
	  
	  
	  onSubmit(errorList);
	  
  } 
  setActiveStep(step){
	 const {
     formData, onChange, onSubmit, toggle, buildingRes, add
    } = this.props; 
	  
/*
	  
	  if(step == 0){
		  defaultSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'] = associateSchema;
	  }else if(step == 1){
		  defaultSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'] = personDetailSchema;
	  }
*/
	  if(step === 1){
		  if(formData.contact_search && (!formData["name"] || formData["name"]=="")){
			    let newObj = Object.assign({}, formData);
				
				const newName = newObj.contact_search.split(" ")
				//newObj["key"] = _CreateGuid();
				newObj["first_name"] = newName[0] ? newName[0] : "";
				newObj["last_name"] = newName[1] ? newName[1] : "";
				newObj['name'] = (newObj['first_name'] + " " + newObj['last_name']).trim();
				onChange({formData : newObj})
			}
		  
	  }
	  
	  this.setState({ activeStep : step });
  }

  render() {
    const {
     formData, onChange, onSubmit, toggle, buildingRes, add, single, saveInProgress, company, organizationRes, noAssociation
    } = this.props;
    
    const { activeStep, uiSchema } = this.state;
    
    
    if(single){
	    
	    return (
		    <div className="contact_edit contact">
		    	<Form
			        formData={formData}
			        schema={schema}
			        uiSchema={!add ? schema2 : schema1}
			        fields={fields}
			        widgets={widgets}
			        onChange={onChange}
			        noHtml5Validate={true}>
			       <ButtonToolbar className="modal__footer">
		            <Tooltip title="Cancel">
				        <Button
					         onClick={toggle}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					         disabled={saveInProgress}
					     >	Cancel
					     </Button>				 
				  	</Tooltip>{' '}
		            <Tooltip title="Save">
				        <Button
				        	 onClick={this.doValidateSubmit}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		
					         disabled={saveInProgress}         
					     >	{saveInProgress ? "Saving..." : "Save" }
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>	
			   </Form>
		    
		    </div>
	    )
    }
        
    return (
	    <div className="contact_edit contact">
	    	<AssocationStepper type={"contact"} add={add===true ? true : false} activeStep={activeStep} setActiveStep={this.setActiveStep} onSubmit={this.doValidateSubmit}>
	    	{activeStep == 0 ?
			    <Form
			        formData={formData}
			        schema={schema}
			        uiSchema={add===true ? company ? schema0company : noAssociation ? schema0noassociation : schema0 : schema0edit}
			        fields={fields}
			        widgets={widgets}
			        onChange={onChange}
			        noHtml5Validate={true}>
			        <br/>
			       
			      {/*  <ButtonToolbar className="modal__footer">
		            <Tooltip title="Cancel">
				        <Button
					         onClick={toggle}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					     >	Cancel
					     </Button>				 
				  	</Tooltip>{' '}
		            <Tooltip title="Send">
				        <Button
				        	 onClick={onSubmit}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					     >	Save
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>*/}
	
			   </Form>
			: activeStep == 1 ?
				<Form
			        formData={formData}
			        schema={schema}
			        uiSchema={schema1}
			        fields={fields}
			        widgets={widgets}
			        onChange={onChange}
			        noHtml5Validate={true}>
			       <br/>
			        {/*   <ButtonToolbar className="modal__footer">
		            <Tooltip title="Cancel">
				        <Button
					         onClick={toggle}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					     >	Cancel
					     </Button>				 
				  	</Tooltip>{' '}
		            <Tooltip title="Send">
				        <Button
				        	 onClick={onSubmit}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					     >	Save
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>*/}
	
			   </Form>
			: activeStep == 2 ?
				<div className="confirm_association confirm_dialog">
				{ company ? 
					<p>Completing this step will associate <strong>{formData.name}</strong> to <strong>{organizationRes && organizationRes["name"] ? _capitalizeText(organizationRes["name"]) : ""}</strong><br/> and update any changed  details across all companies and projects.</p>
					:
					noAssociation ? 
					<p>Completing this step will create a new contact with no associations to pipeline projects.</p>	
					:
					<p>Completing this step will associate <strong>{formData.name}</strong> to <strong>{buildingRes && buildingRes["name"] ? _capitalizeText(buildingRes["name"]) : _capitalizeText(buildingRes["display_full_street_address"])}</strong><br/> and update any changed company details across all companies and projects.</p>
				}
			
				
				</div>
				
			: null
			}
			</AssocationStepper>
		</div>
    );
  }
}



export default EditPeople;
