import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import SpeedometerIcon from 'mdi-react/ContrastCircleIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';




const calculateBarData = (buildingRes, transactionRes) => {	
	
	const totalSold = transactionRes.pct_financed && transactionRes.pct_financed.avg_pct ? transactionRes.pct_financed.avg_pct : 0;
	const totalAvail = 	100 - totalSold;
	return [{ value: totalSold, fill: getDefaultChartColors[0] },
  				{ value: totalAvail, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const PercentFinanced = (props, { t }) => {
	const { buildingRes, location, transactionRes } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	
	return (
		  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
		    <Card>
		    <Link to={`${base_url}`}>
		      <CardBody className="dashboard__health-chart-card card__link">
		        <div className="card__title">
		          <h5 className="bold-text card__title-center">{`Percent Financed`}</h5>
		        </div>
		        <div className="dashboard__health-chart"> 
		          <ResponsiveContainer height={180}>
		            <PieChart>
		              <Pie data={calculateBarData(buildingRes, transactionRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
		            </PieChart>
		          </ResponsiveContainer>
		          <div className="dashboard__health-chart-info">
		            <SpeedometerIcon style={{ fill: getDefaultChartColors[0] }} />
		            <p className="dashboard__health-chart-number">{transactionRes.pct_financed && transactionRes.pct_financed.avg_pct ? transactionRes.pct_financed.avg_pct.toFixed(0) + '%' : null}</p>
		            <p className="dashboard__health-chart-units">{transactionRes.pct_financed && transactionRes.pct_financed.avg_pct ? (100-transactionRes.pct_financed.avg_pct).toFixed(0) + '% down payment' : null}</p>
		          </div>
		        </div>
		        <p className="dashboard__goal">Average Based On: {transactionRes.pct_financed && transactionRes.pct_financed.count ? transactionRes.pct_financed && transactionRes.pct_financed.count : null} Sales</p>
		      </CardBody>
		      </Link>
		    </Card>
		  </Col>
	)
};

export default PercentFinanced;