/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/MagnifyIcon';
import Panel from './../../../../../shared/components/Panel';
import Button from '@material-ui/core/Button';
import {
  Legend, Pie, PieChart, ResponsiveContainer, Tooltip,
} from 'recharts';

import ArrowIcon from 'mdi-react/ChevronRightIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_formatPrice,
	_formatNumber,
	_formatBigNumber
} from "../../../../../shared/helpers/utils";

import {
  Card, CardBody, Col, Progress, ButtonToolbar
} from 'reactstrap';

const dir = 'ltr';





const ProjectStage = ( { data, type } ) => {

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const preDev =  data ? data['by_stage'].filter(item => item._id == "planning")[0] : 0;
  const constr =  data ? data['by_stage'].filter(item => item._id == "construction")[0] : 0;
  const operation = data ? data['by_stage'].filter(item => item._id == "completion")[0] : 0;
  const totalProj = preDev + constr + operation;
  const chartData = [{
	id: 0, name: 'Pre-development', value: preDev['count'], fill: '#28C2FF',
  },
   {
	id: 1, name: 'Construction', value: constr['count'], fill: '#FFD23F',
  },
  { id: 2, name: 'Operation', value: operation['count'], fill: '#4ce1b6',
  }];
	const onMouseMove = (e) => {
	  if (e.tooltipPosition) {
		setCoordinates({
		  x: dir === 'ltr' ? e.tooltipPosition.x - 0 : e.tooltipPosition.x / 10, y: e.tooltipPosition.y - 40,
		});
	  }
	};
  const renderLegend = ({ payload }) => (
	<ul className="dashboard__booking-reservations-chart-legend dashboard__chart-legend">
	  {payload.map(entry => (
		<li key={entry.payload.id}>
		  <span style={{ backgroundColor: entry.color }} />
		  <p>{entry.value} <div style={{ display:"inline-block"}}>({_formatNumber(entry.payload.payload.value)})</div></p>
		</li>
	  ))}
	</ul>
  );

	  return (	
			<Col md={12} xl={4} lg={4} xs={12}>
			  <Card>
				  <CardBody className="dashboard__card-widget card__link">				
					  <Link to={`/pipeline-search?building_stage=all`}>
						  <div className="card__title">
							<h5 className="bold-text">{`Projects By Stage`} 
							
							</h5>
						  </div>
					  
			
						  <div style={{width:"100%", height:100}}>
						   <ResponsiveContainer>
							   <PieChart className="dashboard__booking-reservations-chart-container">
								 <Tooltip position={coordinates}  />
								 <Pie
								   data={chartData}
								   dataKey="value"
								   cy={30}
								   innerRadius={47}
								   outerRadius={85}
								   onMouseMove={onMouseMove}
								   style={{top:"-20px"}}
								 />
								 <Legend
									 layout="vertical"
									 verticalAlign="middle"
									 wrapperStyle={{left:0, position:"relative",width:"100%",top:"-10px", marginTop: '-5px', lineHeight: '16px'}}
									 content={renderLegend}
								   />
							   </PieChart>
							 </ResponsiveContainer>
							 </div>
			
					</Link>
					
				  </CardBody>
			  </Card>
			</Col>
		)
  
  
};


export default withTranslation('common')(ProjectStage);
