import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import UnitsSearchTable from "./components/UnitsSearchTable";
import Alert from "../../shared/components/Alert";
import AlertIcon from "mdi-react/InformationOutlineIcon";
import TrialAlert from "../../shared/components/payment/TrialAlert";
import EnterpriseGate from "../../shared/components/payment/EnterpriseGate";
import { _hasPermission } from "../../shared/helpers/access";
class Units extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingSlug: null,
    };
  }
  componentDidMount() {
   
   
  }

  render() {
    const { user } = this.props;
    if (!user) return null;

    return (
      <Container>

            <Row className="c_seconday-nav-container">
              <Col md={12}>
                <h3 className="page-title">
                  Construction Permits and Offering Plan Filings
                </h3>
                <h3 className="page-subhead subhead">
                  Buildings with Permits Filed or Offering Plans Submitted
                </h3>
              </Col>
            </Row>

            {_hasPermission(user, "permits-filings") ? (
              <Fragment>
                <Row>
                  <Col md={12} className={"mb-3"}>
                    <TrialAlert />
                  </Col>
                </Row>
                <Row id="research_bar">
                  <Col md={12} className={"mb-3"}>
                    <Alert
                      color="success"
                      className="alert--bordered"
                      alertId="researchTools"
                      icon
                      iconItem={<AlertIcon />}
                      minimizeText={`About Research Tools`}
                    >
                      <p>
                        <span className="bold-text">{`About Research Tools`}</span>
                        <div>{`Research Tools are provided 'As-Is'. The information provided herein is provisional and subject to change.`}</div>
                      </p>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <UnitsSearchTable {...this.props} />
                </Row>
              </Fragment>
            ) : (
              <Row>
                <EnterpriseGate
                  copy={
                    <span>
                      Access to Permits and Offering Filings requires an upgrade
                      to your subscription.
                    </span>
                  }
                  upgrade={false}
                  user={user}
                  img={`https://marketproof.com/svgs/PLGroup-2249.svg`}
                  classes={"small-image"}
                />
              </Row>
            )}          
      </Container>
    );
  }
}

export default connect((state) => ({
  user: state.user,
}))(Units);
