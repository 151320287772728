import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';
import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteOutlineIcon';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import HideIcon from 'mdi-react/EyeOffIcon';
import UnHideIcon from 'mdi-react/EyeIcon';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Form from "@rjsf/material-ui";
import LayoutField from './components/LayoutField';
import ImageUploadWidget from './components/ImageUploadWidget'

import EditPeople from './components/EditPeople'
import EditPhones from './components/EditPhones'
import EditEmails from './components/EditEmails'
import EditCompanyAssociations from './components/EditCompanyAssociations'

import { Publisher } from './../../pubsub/publisher.js';
import { _hasPermission	} from "../../../shared/helpers/access";
import SnackBar from '../../../shared/components/SimpleSnackBar';

import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	EstimateProjectStage,
	_CreateGuid,
	_arrayUnique
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT; //'http://localhost:8000/buyerslist/';


export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}
const addressFields = ['full_street_address', 'address_2', 'city', 'state', 'zip', 'street_number', 'street', 'county', 'geocode', 'borough', 'borough_code', 'original', 'normalized', 'block', 'lot', 'bin', 'goat_enriched'];
const cleanFormData = (data, buildingRes, organizationRes, update) => {
	let cleanData = Object.assign({}, (data && data.contact ? data.contact : data));
	
	Object.keys(cleanData).map(key => {
		if(cleanData[key] === -1){
			cleanData[key] = null;
		}
	});
	
	
	if(!update && cleanData['address']){
		
		
		Object.keys(cleanData['address']).map(key => {
			if(addressFields.indexOf(key)>-1){
				cleanData[key] = cleanData['address'][key];
			}
		})
	}
	cleanData['name'] = _capitalizeText(cleanData['name']);
	
	cleanData['contact_search'] = cleanData['name'];
	
	if(!update && cleanData['project_roles'] && buildingRes && buildingRes.key && cleanData['project_roles'][buildingRes.key]){
		cleanData['project_role'] = cleanData['project_roles'][buildingRes.key][0];
	}
	
    if(organizationRes && organizationRes.key){
		if(data && Array.isArray(data['organizations'])){
			if(data['organizations'].filter(org => org.key == organizationRes.key).length < 1){
				data['organizations'].push(organizationRes);
			}
		}else{
			cleanData['organizations'] = [organizationRes];
			cleanData['company'] = organizationRes;
		}
		
	}
	
	if(data && data['organizations'] && data['organizations'].filter(org => org.key).length>0){
		if(!cleanData['company']){
			cleanData['company'] = data['organizations'][data['organizations'].filter(org => org.key).length-1];
		}
		
	} 
	
	if(cleanData['email'] && !Array.isArray(cleanData['email'])){
		cleanData['email'] = [cleanData['email']];
	}else if(!cleanData['email']){
		cleanData['email'] = [];
	}
	
	if(cleanData['phone'] && !Array.isArray(cleanData['phone'])){
		cleanData['phone'] = [cleanData['phone']];
	}else if(!cleanData['phone']){
		cleanData['phone'] = [];
	}
	
	if(!cleanData['phone_numbers']){
		cleanData['phone_numbers'] = cleanData['phone'].map(number => {
			return {
			"Phone" : number,
			"Extension" : "",
			"Type" : ""
			}
		})
	}
	
	if(!cleanData['email_addresses']){
		cleanData['email_addresses'] = cleanData['email'].map(email => {
			return {
			"Email" : email,
			"Type" : "",
			}
		})
	}
	
	if(!cleanData['organization_associations'] && cleanData['organizations'] && Array.isArray(cleanData['organizations'])){
		cleanData['organization_associations'] = cleanData['organizations'].map(org => {
			const fields = ["key", "name", "name_list" ];
			let nameData = Object.assign({}, org);
			Object.keys(nameData).forEach(f =>{
				if(fields.indexOf(f)<0){
					delete nameData[f];
				}
			})
			return {
				"Name" : nameData,
				"Type" : org.name_list && org.name_list.filter(name => name.indexOf("llc")>=0).length > 0 ? "owner entity" : ""
			}
		})
	}
	//console.log(Object.assign({}, cleanData['organization_associations']))
	
	return cleanData;
}

const prepareFormDataForSave = (formData, buildingRes, add, single) => {

	let now = moment();
	
	let saveFormData = Object.assign({}, formData);
	
	if(!single){
	
		if(buildingRes && buildingRes.key){
			if(saveFormData['projects'] &&  Array.isArray(saveFormData['projects'])){
				saveFormData['projects'].push(buildingRes.key)
			}else{
				saveFormData['projects'] = [buildingRes.key];
			}
			
			saveFormData['projects'] = saveFormData['projects'].filter(_arrayUnique);
			let roles = {};
			roles[buildingRes.key] = [];
			
			if(saveFormData.project_role){
				roles[buildingRes.key].push(saveFormData.project_role);
				roles[buildingRes.key] = roles[buildingRes.key].filter(_arrayUnique);
				delete saveFormData.project_role;
			}
			if(saveFormData['project_roles']){		
			
				saveFormData['project_roles'] = Object.assign({}, saveFormData['project_roles'], roles);
			}else{
				saveFormData['project_roles'] = Object.assign({}, roles);
			}

		}
		
		
		
	}
	if(!saveFormData['address']){
		saveFormData['address'] = {};
	}
	
	addressFields.map(key => {
		if(saveFormData[key]){
			saveFormData['address'][key] = saveFormData[key];
			delete saveFormData[key];
		}else{
			saveFormData['address'][key] = "";
		}
		
	})
	
	if(saveFormData['phone_numbers']){
		if(saveFormData['phone_numbers'].length>0){
			saveFormData['phone'] = saveFormData['phone_numbers'].map(item => item['Phone']);
		}else{
			saveFormData['phone'] = []
		}
	}
	if(saveFormData['email_addresses']){
		if(saveFormData['email_addresses'].length>0){
			saveFormData['email'] = saveFormData['email_addresses'].map(item => item['Email']);
		}else{
			saveFormData['email'] = []
		}
	}
	
	if(saveFormData['organizations']){
		saveFormData['organizations'] = saveFormData['organizations'].map(company => {
			if(company.key){
				 return company.key
			}else{
				return company
			}
		})
	}else{
		saveFormData['organizations'] = [];
	}
	
	if(saveFormData['organization_associations'] && saveFormData['organization_associations'].length>0){
		saveFormData['organizations'] = saveFormData['organization_associations'].map(company => {
			if(company.Name && company.Name.key){
				 return company.Name.key
			}
		})
		
	}
	
	
	
	
	if(saveFormData['company'] && saveFormData['company'].key){
		saveFormData['organizations'].unshift(saveFormData['company'].key);
		delete saveFormData['company'];
	}
	
	saveFormData['organizations'] = saveFormData['organizations'].filter(_arrayUnique);
	
	if(saveFormData['first_name'] && saveFormData['last_name']){
		saveFormData['name'] = saveFormData['first_name'] + " " + saveFormData['last_name'];
	}
	
	delete saveFormData['contact_search'];
	
	if(!saveFormData['project_roles']){
		saveFormData['project_roles']=[];
	}
	
	Object.keys(saveFormData).forEach(key => {
		if(saveFormData[key]===undefined){
			saveFormData[key]="";
		}
	})
	
	return saveFormData;
}

const prepareFormDataForRemove = (item, buildingRes) => {
	
	
	let saveFields = ["project_roles"];
	
	let saveFormData = Object.assign({}, item);
	
	Object.keys(saveFormData).map(key => {
		if(saveFields.indexOf(key) < 0){
			delete saveFormData[key];
		}
	});
	
	
	
	if(saveFormData["project_roles"][buildingRes.key]){
		 saveFormData["project_roles"][buildingRes.key] = null;
	}

	
	return saveFormData;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
    width: "100%"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth:180
  },
});
function ShowErrors({errors}){
	if(!errors || errors.length < 1 || !Array.isArray(errors)) return null;

	return (
		<div className="edit-errors">
	      <ul>
	        {errors && errors.map(error => (
	            <li>
	              {error}
	            </li>
	          ))}
	      </ul>
        </div>
    )
}


class EditContact extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: 'Update Project',
	    modal: false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
	    formData : cleanFormData(this.props.item, this.props.buildingRes, this.props.organizationRes),
	    activeTab: 'profile',
	    value: 0,
	    justDeleted: false,
	    fetchInProgress: false,
	    delModal: false,
	    saveInProgress: false
	    
	};
	this.toggle = this.toggle.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	this.onChange = this.onChange.bind(this);
	this.saveProject = this.saveProject.bind(this);
	this.deleteProject = this.deleteProject.bind(this);
	this.toggleDel = this.toggleDel.bind(this);
    
  }
  
  saveProject(){           
      const { authState, authService, dispatch, user, setBuilding, buildingRes, setChanged,  add, item, single} = this.props;	
      const { formData } = this.state;
      
	  const user_id = user.id;
	  const user_email = user.email;
	  this.setState({saveInProgress: true});
	  let saveData = prepareFormDataForSave(formData, buildingRes, add, single);	
	  
	  
	    
	  let meta = {
		  'source_namespace' : formData && formData.meta && formData.meta.source_namespace ? formData.meta.source_namespace : 'MARKETPROOF-PIPELINE-APP',
		  'user_id' : user_id,
		  'user_email' : user_email
		  
	  }
	  if(saveData['meta']){
		  saveData['meta'] = Object.assign({}, saveData['meta'], meta)
	  }else{
		  saveData['meta'] = meta;
	  }
	  
	  
	  console.log(saveData);
	  
	  //return false;
	  let userData = {
			url: apiUrl + '/contacts',
			method: "post",
			query: {
				"contact": JSON.stringify(saveData)
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	       this.setState({saveInProgress: false});
	      if(res && res.success){
		      
		      if(res.message && res.message == "no changes found"){
			      console.log("this.snackOpen('Success! Your update has been saved.'); ")
			    this.snackOpen('Success! Your update has been saved.'); 
		      }else{
			      			      console.log("this.snackOpen('Success! Project news has been saved.'); ")

			    this.snackOpen('Success! Your update has been saved.');
			  	
		      }
			  if(setChanged){
			  	setTimeout(() => { 
					
					  	setChanged();
					  	this.toggle();
				  	
			  	}, 3000);
			 }	
		  }else{
			  if(!res.success){
				this.snackOpen(<Fragment><span>Oops, Your Profile Was Not Updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>Oops, Something went wrong.  {_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, project was not saved.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, something went wrong, project was not saved.', 'error');
	  });
	}
	
	deleteProject(item){           
      const { authState, authService, dispatch, user, setChanged, buildingRes, setBuilding } = this.props;	
	  const user_id = user.id;
	  const user_email = user.email;
	  let saveData = prepareFormDataForRemove(item, buildingRes);
	  
	  let meta = {
		  'source_namespace' : item && item.meta && item.meta.source_namespace ? item.meta.source_namespace : 'MARKETPROOF-PIPELINE-APP',
		  'user_id' : user_id,
		  'user_email' : user_email
		  
	  }
	  
	  if(saveData['meta']){
		  saveData['meta'] = Object.assign({}, saveData['meta'], meta)
	  }else{
		  saveData['meta'] = meta;
	  }
	  saveData['key'] = item['key'];
	  saveData['_id'] = item['_id'];
	  console.log(saveData);
	  
	 // return false;
	  let userData = {
			url: apiUrl + '/contacts',
			method: "post",
			query: {
				"contact": JSON.stringify(saveData)
			}
	  };

	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.success){
		      
		      if(res.message && res.message == "no changes found"){
			    this.snackOpen(`Success! Contact has been removed from this project.`); 
		      }else{
			    this.snackOpen(`Success! Contact has been removed from this project.`); 
			  	
		      }
			 setTimeout(() => { 
				
				  setChanged();
				  this.toggleDel();
				  
			  }, 3000);	
	
		  }else{
			  if(!res.success){
				this.snackOpen(<Fragment><span>Oops, Your Profile Was Not Updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>Oops, Something went wrong.  {_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, request not saved.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, something went wrong, request not saved.', 'error');
	  });
	}
	
	deleteContact(){           
      const { authState, authService, dispatch, user, setBuilding, item } = this.props;	
      
	  const user_id = user.id;
	  const user_email = user.email;
	  
	  let meta = {
		  'source_namespace' :  item && item.meta && item.meta.source_namespace ? item.meta.source_namespace : 'MARKETPROOF-PIPELINE-APP',
		  'user_id' : user_id,
		  'user_email' : user_email
		  
	  }
	  
	  let deleteData = {
		  key : item.key,
		  meta : meta
	  }
	  
	  console.log(deleteData);
	  
	  //return false;
	  let userData = {
			url: apiUrl + '/contacts/delete', //"http://52.70.164.132:8001/pipeline-projects/delete",
			method: "post",
			query: {
				"contact": JSON.stringify(deleteData)
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.success){
		      
		      if(res.message && res.message == "no changes found"){
			    this.snackOpen('Success! This contact has been deleted.'); 
		      }else{
			    this.snackOpen('Success! This contact has been deleted.');
			  	
		      }
		      setTimeout(() => { this.setState( {justDeleted : true })}, 3000);
			 	
		  }else{
			  if(!res.success){
				this.snackOpen(<Fragment><span>Oops, Your Profile Was Not Updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>Oops, Something went wrong.  {_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, project was not deleted.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, something went wrong, project was not saved.', 'error');
	  });
	}
	
	_getMongoContactData = (key) => {
     
		    if(!key) return false;
		    const { formData } = this.state;
		    if (!this.state.fetchInProgress) {
				  this.setState(prevState => ({ fetchInProgress: true, }));
		    }

		    
		    let mongoQuery = [{
					"$match": {
						"key": key
						}
					},
					{
			            "$lookup": {
			                "from": "organizations",
			                "localField": "organizations",
			                "foreignField": "key",
			                "as": "organizations"
			            }
			        },{
				    "$project": {
				        "contact": "$$ROOT",
				        "pipeline_projects" : {
				            "key" : 1,
				            "name" : 1
				        },
				        "organizations" : {
				            "key" : 1,
				            "name" : 1,
				       
				        }
				    }
				}]
			
			let axiosData = {
				url: apiUrl + "/pipeline/aggregate/contacts",
				method: "post",
				query: mongoQuery
			};
	
			_axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					console.log(res)	
					if(res && res[0]){
						this.setState({
					      	formData: cleanFormData(res[0], this.props.buildingRes, this.props.organizationRes),
					      	fetchInProgress: false
					      });
					}else{
						this.setState({ organizationRes : {} })
					}				
				})
				.catch(error => {
					console.log("error: " + error);
				});	
		
	  };
	
	   
  	
	handleChange = (event, newValue)  => {
	 	this.setState( { value: newValue } )
  	};

	
  toggle() {
	//this.snackOpen('Your Message Has Been Sent!', 'success');
	const { setBuilding, add, item } = this.props;	
	if(this.state.modal){
		if(add===true){
			this.setState({
	      		formData:{}
	      	});
		}else{
			this.setState({
	      		formData: cleanFormData(this.props.item, this.props.buildingRes, this.props.organizationRes)
	      	});
		}
		
	}
	
    this.setState(prevState => ({ modal: !prevState.modal }));
    
  }
  
  toggleDel(){
	this.setState(prevState => ({ delModal: !prevState.delModal }));  
  }
  	snackOpen = (message, type) => {
		this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	};
	
	snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: '' } )
	};

  
  onChange = ({formData}, e) => {
	  
	  let newObj = Object.assign({}, formData);
	  
	 
	  console.log("on change", newObj);
	
	  if(newObj.contact_search && newObj.contact_search['key'] && (!newObj["key"] || newObj["key"] !== newObj.contact_search['key'])){
		 
		  this._getMongoContactData(newObj.contact_search['key'])
	  }else{
		   if(newObj && newObj.project_role){
				if(!newObj.categories){
					newObj['categories'] = [newObj.project_role];
				}else if(newObj.categories.indexOf(newObj.project_role)<0){
					newObj.categories.push(newObj.project_role);
				}
				
			}
			
			if(newObj.contact_search && newObj.contact_search.type && newObj.contact_search.type==="add"){
				const newName = newObj.contact_search.name.split(" ")
				//newObj["key"] = _CreateGuid();
				
				newObj["first_name"] = newName[0] ? newName[0] : "";
				newObj["last_name"] = newName[1] ? newName[1] : "";
				newObj['name'] = (newObj['first_name'] + " " + newObj['last_name']).trim();
			}
			
		   this.setState({
	      		formData:cleanFormData(newObj, this.props.buildingRes, this.props.organizationRes, true)
	 	   });

	  }
	  
	  
	
	 	  
	  
	  

  }
  onSelectItem = ({item}, e) => {
	  
	  if(item['key']){
		  this._getMongoContactData(item['key'])
	  }
	  
  }
  onSubmit = (errorList) => {	  
	  if(!errorList || errorList.length<1 || !Array.isArray(errorList)){
		  this.saveProject();
	  }else{
		  this.snackOpen(<Fragment><span>Please correct the following errors</span><br/><ShowErrors errors={errorList} /></Fragment>, 'error');
	  }
  
  }
  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, listing, classes, add, del, item, edit, single, organizationRes, company, noAssociation
    } = this.props;
    
    if(!_hasPermission(user, "admin-tools")) return null;
    
    const { modal, snackShow, snackMessage, snackType, formData, value, justDeleted, delModal, saveInProgress} = this.state;
    
    if(justDeleted){
	    return <Redirect  to={{ pathname: '/dashboard' }}/> 
    }
   
	
    
    console.log(formData);		
    if(del){
	   return( <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
			/>
			<Tooltip title={`Delete this contact (Admin Only)`}>
			        <Button
				         onClick={this.toggle}
				         variant="contained"
				         color="secondary"
				         className="buyersList__secondary-button"		         
				         startIcon={<DeleteIcon />}
				     >	Delete
				     </Button>				 
			</Tooltip>				
		    <Modal
	          isOpen={modal}
	          toggle={this.toggle}
	          modalClassName={`${rtl.direction}-support`}
	          className={`modal-dialog contact__modal delete-project`}
	        >
	        		<div className="modal__header">
			            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
			        </div>
					<div className="modal__body background_white">
						<h4 className="contact__title">{`Delete Contact: ${item && item["name"] ? item["name"] : ""}`}</h4>
						 <hr/>
						 <div>
						 
						 <p>This will remove {item && item["name"] ? _capitalizeText(item["name"]) : "this contact"} from all projects and delete the contact permanently.  Are you sure you want to continue?</p><br/><br/>
						 </div>
						 <ButtonToolbar className="modal__footer">
				            <Tooltip title="Cancel">
						        <Button
							         onClick={this.toggle}
							         variant="outlined"
							         color=""
							         className=""		         
							         startIcon={null}
							     >	Cancel
							     </Button>				 
						  	</Tooltip>{' '}
				            <Tooltip title="Confirm you want to delete this contact">
	
						        <Button
						        	 onClick={() => this.deleteContact()}
							         variant="contained"
							         color="secondary"
							         className="buyersList__primary-button"		         
							         startIcon={<DeleteIcon />}
							     >	Delete Contact
							     </Button>	
			 
						  	</Tooltip>
						</ButtonToolbar>
					</div>
					
					 
							
	        </Modal>
        </Fragment>
	    )
    }

    
    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
			/>				
			{add ? 
				<Fragment>
				{noAssociation ? 
				<Tooltip title={`Add a new contact (Admin Only)`}>
					<Button
					  onClick={this.toggle}
					  variant="contained"
					  color="primary"
					  className=""
					  startIcon={<AddIcon />}
					>
					  {" "}
					  Add Person
					</Button>
				</Tooltip>	
					
				:
				  <ButtonGroup size="small" aria-label="small outlined button group">
					  <Tooltip title={`Add a contact to this project (Admin Only)`}>
						  <Button onClick={this.toggle} startIcon={<AddIcon size={20} />}>Person</Button>
					  </Tooltip>
					  
				  </ButtonGroup>
					
				}
				</Fragment>
			  	

		  	: single ?
		  		<Tooltip title={`Update the details of this company  (Admin Only)`}>
			        <Button
				         onClick={this.toggle}
				         variant="contained"
				         color="secondary"
				         className="buyersList__secondary-button"		         
				         startIcon={<EditIcon />}
				     >	Update Person
				     </Button>				 
			  	</Tooltip>
		  	
		  	
		  	:
			    <ButtonGroup size="small" aria-label="small outlined button group">
			    	<Tooltip title={`Edit contact info (Admin Only)`}>
			        	<Button onClick={this.toggle} startIcon={<EditIcon size={11} />}>Edit</Button>
			        </Tooltip>
			        <Tooltip title={`Remove this contact from this project (Admin Only)`}>
		        		<Button onClick={this.toggleDel} startIcon={<DeleteIcon size={11} />}>Remove</Button>
		        	</Tooltip>		        				  
			    </ButtonGroup>
		  	}
		  	
		  	<Fragment>
		  		<Modal
		          isOpen={delModal}
		          toggle={this.toggleDel}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal delete-project`}
		        >
		        		<div className="modal__header">
				            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggleDel} />
				        </div>
						<div className="modal__body background_white">
							<h4 className="contact__title">{`Remove From Project: ${item && item["name"] ? item["name"] : ""}`}</h4>
							 <hr/>
							 <div className="confirm_dialog">
							 
							 <p>This will remove the contact from this project only.<br/><br/>  Confirm to continue.</p>
							 </div>
							 <ButtonToolbar className="modal__footer">
					            <Tooltip title="Cancel">
							        <Button
								         onClick={this.toggleDel}
								         variant="outlined"
								         color=""
								         className=""		         
								         startIcon={null}
								     >	Cancel
								     </Button>				 
							  	</Tooltip>{' '}
					            <Tooltip title="Confirm you want to delete this project">
		
							        <Button
							        	 onClick={() => this.deleteProject(item)}
								         variant="contained"
								         color="secondary"
								         className="buyersList__primary-button"		         
								         startIcon={<DeleteIcon />}
								     >	Confirm Remove
								     </Button>	
				 
							  	</Tooltip>
							</ButtonToolbar>
						</div>
						
						 
								
		        </Modal>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
		
						<div className="modal__body background_white">
						{ company ? 
							<h4 className="contact__title">
								{add ? `Add Contact to Company: ${organizationRes && organizationRes["name"] ? organizationRes["name"] : ""}` : `Edit Contact: ${item && item["name"] ? item["name"] : ""}`}														</h4>
							
							:
							noAssociation ?
								<h4 className="contact__title">Add a New Person</h4>
							:
							
							<h4 className="contact__title">
								{add ? `Add Contact to Project: ${buildingRes && buildingRes["name"] ? buildingRes["name"] : buildingRes["display_full_street_address"]}` : `Edit Contact: ${item && item["name"] ? item["name"] : ""}`}							</h4>
						}
							 <hr/>
							 <div className={classes.root}>
						      <Tabs
						        orientation="vertical"
						        variant="scrollable"
						        value={value}
						        onChange={this.handleChange}
						        aria-label="Vertical tabs example"
						        className={classes.tabs}
						      >
						        <Tab label="Profile" {...a11yProps(0)} />							
						        <Tab label="Phone Numbers" {...a11yProps(1)} />
						        <Tab label="Emails" {...a11yProps(2)} />
								{!add && <Tab label="Organizations" {...a11yProps(3)} />}
						       </Tabs>
						      <TabPanel value={value} index={0}>						
						         <EditPeople
						        	buildingRes={buildingRes}
								 	formData={formData}				               
								 	onChange={this.onChange}
								 	onSubmit={this.onSubmit}
								 	toggle={this.toggle}
								 	add={add===true ? true : false}
								 	single={single===true ? true : false}
								 	saveInProgress={saveInProgress}
								 	{...this.props}
									noAssociation={noAssociation===true ? true : false}
								 />
						      </TabPanel>
							 
						      <TabPanel value={value} index={1}>						
						         <EditPhones
						        	buildingRes={buildingRes}
								 	formData={formData}				               
								 	onChange={this.onChange}
								 	onSubmit={this.onSubmit}
								 	toggle={this.toggle}
								 	add={add===true ? true : false}
								 	single={single===true ? true : false}
								 	saveInProgress={saveInProgress}
								 	{...this.props}
								 />
						      </TabPanel>  
						      <TabPanel value={value} index={2}>						
						         <EditEmails
						        	buildingRes={buildingRes}
								 	formData={formData}				               
								 	onChange={this.onChange}
								 	onSubmit={this.onSubmit}
								 	toggle={this.toggle}
								 	add={add===true ? true : false}
								 	single={single===true ? true : false}
								 	saveInProgress={saveInProgress}
								 	{...this.props}
								 />
						      </TabPanel>
						     <TabPanel value={value} index={3}>						
								<EditCompanyAssociations
								   buildingRes={buildingRes}
									formData={formData}				               
									onChange={this.onChange}
									onSubmit={this.onSubmit}
									toggle={this.toggle}
									add={add===true ? true : false}
									single={single===true ? true : false}
									saveInProgress={saveInProgress}
									{...this.props}
								/>
							 </TabPanel>  
						    </div>
							
				                 
				             
						    
				        </div>	
		
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("contact_form")(state),
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [] 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditContact));
