import React, { Fragment } from "react";
import { _capitalizeText, _PickOrgName } from "../../../shared/helpers/utils";

const CardHeaderData = ({ organizationRes }) => {
  return (
    <Fragment>
      <h3 className="new-page-title">
        {organizationRes.project_keys &&
        organizationRes.project_keys.length == 1
          ? _capitalizeText(
              _PickOrgName(organizationRes, organizationRes.project_keys[0])
            )
          : _capitalizeText(organizationRes.name)}
        <span style={{ color: "#858AA1", marginTop: "15px" }}>
          {organizationRes &&
          organizationRes.categories &&
          organizationRes.categories.length > 0 &&
          organizationRes.categories.sort().map(c => _capitalizeText(c.replace(/-/g, " "))).join(", ")}
        </span>
      </h3>

      <div className="socila_media_icon">
        {organizationRes && organizationRes.facebook && (
          <a
            className="product__external-icon-link"
            href={`${organizationRes.facebook}`}
            target="_blank"
          >
            <img src="/img/facebook.svg" width="10px" height="19px" />
          </a>
        )}
        {organizationRes && organizationRes.instagram && (
          <a
            className="product__external-icon-link"
            href={`${organizationRes.instagram}`}
            target="_blank"
          >
            <img src="/img/instagram.svg" width="10px" height="19px" />
          </a>
        )}
        {organizationRes && organizationRes.linkedin && (
          <a
            className="product__external-icon-link"
            href={`${organizationRes.linkedin}`}
            target="_blank"
          >
            <img src="/img/linkdin.svg" width="10px" height="19px" />
          </a>
        )}
        {organizationRes && organizationRes.twitter && (
          <a
            className="product__external-icon-link"
            href={`${organizationRes.twitter}`}
            target="_blank"
          >
            <img src="/img/website.svg" width="10px" height="19px" />
          </a>
        )}
      </div>
    </Fragment>
  );
};
export default CardHeaderData;
