import React, { PureComponent } from 'react';
import { withRouter, Link } from "react-router-dom";

import { Col, Card, CardBody } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import LinearLoading from '../../../../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';
import PopularBuildingItems from './PopularBuildingItems';

import Panel from '../../../../../../shared/components/Panel';


import { _buildingIDs, _axiosCall } from '../../../../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_replaceAbbreviations,
	_formatBed,
	_calPercentage,
	_checkFieldForValue,
	_isCCC,
	propertyFL,
	buildingFL
} from "../../../../../../shared/helpers/utils";

const apiRoot = process.env.API_ROOT;

const buildingUrl = apiRoot + '/query/';

class PopularBuildings extends PureComponent {

  state = {
		rowsPerPage: 35,
		fetchInProgress: false,
		buildingAnalytics: null,
		popularBuildings: null
  }


  componentDidMount() {	  
	  this._getBuildingAnalytics();
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	 	 
	
  }
  
  _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
   }
   
   _getBuildingAnalytics = async () => {
						
		var q = "*:*";
		
		var query = [{
				"$facet": {
					"buildingKeys": [{
							"$unwind": "$savedBuildings"
						},
						{
							"$unwind": "$savedBuildings.key"
						},
						{
							"$match": {
								"$and": [{
										"$or": [{
												"savedBuildings.deleted": {
													"$exists": false
												}
											},
											{
												"savedBuildings.deleted": false
											}
										]
									}
								]
							}
						},
						{
							"$group": {
								"_id": {
									"key": "$savedBuildings.key"
								},
								"total": {
									"$sum": 1
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": "$_id.key",
								"total": 1
							}
						},
						{ "$sort" : {
								"total" : -1
							}
						},
						{
							"$limit" : 10
						}
					]
				}
			}]


		let axiosData = {
			url: apiRoot+ "/buyerslist/analytics/aggregate?cache=60",
			method: "post",
			query: query
			
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log(res[0].buildingKeys);
				if(res && res[0] && res[0].buildingKeys){
					const _buildingIDs = res[0].buildingKeys.map(item => item.key);
					this._getBuildings(_buildingIDs);
					this.setState( { buildingAnalytics : res[0].buildingKeys })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };
  
  _getBuildings = async (_buildingIDs) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
			
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var q = "*:*";
		
		var fq = [
			"deleted:false",
			"key:("+_buildingIDs.join(" ")+")"
		];


		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=1440",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: "inventory_unsold desc",
				fl: buildingFL,
				wt: "json",
				rows: 10
			}
		};
		

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				//this._setBuyersList(res);
				if(res && res.docs){
					const { buildingAnalytics } = this.state;
					const buildings = res.docs.map(bldg => {
						const savedCount = buildingAnalytics.filter(item => item.key === bldg.key)[0].total;
						bldg['savedCount'] = savedCount;
						return bldg;
						
					});
					buildings.sort((a, b) => a.savedCount > b.savedCount ? -1 : 1);
					this.setState( { popularBuildings : buildings } )
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };

	
  render(){
	  const { popularBuildings } = this.state;
	  if(!popularBuildings){
		  return null;
	  }
	 	  return(
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={'Popular Buildings'}
		  	subhead={'Most Saved Buildings on Marketproof New Development'}
		  	label={null}
		  >
		  
		    <PopularBuildingItems items={popularBuildings} />
		   
		    <div className="unit__load text-right semi-bold-text">			   		
			    <Tooltip title="See All Buildings">
			        <Link to="/building-database/sale">See All &raquo;</Link>			 
			  	</Tooltip>				
		    </div>
		  </Panel>
	  )
	  
  }
  
}

export default withRouter(PopularBuildings);
