import React, { PureComponent, Fragment } from "react";

import {
  Card,
  CardBody,
  Col,
  Row,
  ButtonToolbar,
  Progress,
  Container,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MainIcon from "mdi-react/CheckDecagramIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import moment from 'moment';


import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _getCrossStreet,
  _isResidential,
  _isHouse,
  _formatNum,
  _getServiceLevel,
  _validateLink,
  _showFeatures,
  _ordinalSuffix,
  _titleCase,
  _formatDate,
  _formatOwnerName,
  _getMPNDBuildingURL,
  SalesProgress
} from "../../../../../shared/helpers/utils";

const MPNDBuilding = ({mpndBuilding}) => {

  const _seeProject = (e, mpndBuilding) => {
	e.preventDefault();
	e.stopPropagation();
	let buildingURL = null;
	if(mpndBuilding.address){
		buildingURL = 'https://newdev.marketproof.com'+_getMPNDBuildingURL(Object.assign({}, mpndBuilding, mpndBuilding.address));
	}else{
		buildingURL = 'https://newdev.marketproof.com'+_getMPNDBuildingURL(mpndBuilding);
	}

    window.open(buildingURL);
    return false;
	 
  }
  if(!mpndBuilding || ["condo", "coop"].indexOf(mpndBuilding.ownership_type)<0) return null;
  return (
  	
	
	<div className="mpnd-callout">
	<p>Additional information from <a href="https://marketproof.com/new-development" className="link-text" target="_blank">Marketproof New Dev & Condo</a></p>
	<div className="info-section">
	<div className="stats">
		
		{mpndBuilding.inventory_stats_listings_low_price && _shouldShow(mpndBuilding.inventory_stats_listings_low_price) && mpndBuilding.inventory_stats_listings_average_ppsf && _shouldShow(mpndBuilding.inventory_stats_listings_average_ppsf) ?  (
			<Fragment>
			<div className="stat mt-2">
				<h3>{_formatPrice(mpndBuilding.inventory_stats_listings_low_price).toUpperCase()} - {_formatPrice(mpndBuilding.inventory_stats_listings_high_price).toUpperCase()}</h3>
				<span className="product-card__subtext">Price Range</span>
			</div>
			<div className="stat mt-2">
				<h3>${_formatNumber(mpndBuilding.inventory_stats_listings_average_ppsf).toUpperCase()}</h3>
				<span className="product-card__subtext">Avg Asking PPSF</span>
			</div>
			</Fragment>
		) : (
			<Fragment>
			<div className="stat mt-2">
				<h3>{parseInt(moment(mpndBuilding.offering_submitted_date).format('YYYY')) > 1990 ? 
					  <Fragment>
						  {_capitalizeText(moment(mpndBuilding.offering_submitted_date).fromNow())}
						  <span className="small-text"> 
							  {` `}({_capitalizeText(moment(mpndBuilding.offering_submitted_date).format('MM/DD/YYYY'))})
						  </span>
					  </Fragment> : `Not Started`}</h3>
				<span className="product-card__subtext">Offering Submitted</span>
			</div>
			<div className="stat mt-2">
				<h3>{parseInt(moment(mpndBuilding.offering_accepted_date).format('YYYY')) > 1990 ? 
					  <Fragment>
						  {_capitalizeText(moment(mpndBuilding.offering_accepted_date).fromNow())}
						  <span className="small-text"> 
							  {` `}({_capitalizeText(moment(mpndBuilding.offering_accepted_date).format('MM/DD/YYYY'))})
						  </span>
					  </Fragment> : `Offering Not Accepeted`}</h3>
				<span className="product-card__subtext">Offering Accepeted</span>
			</div>
			</Fragment>
		)}	
		<div className="stat mt-2">
			<h3>{parseInt(moment(mpndBuilding.sales_start_date).format('YYYY')) > 1990 ? 
				  <Fragment>
					  {_capitalizeText(moment(mpndBuilding.sales_start_date).fromNow())}
					  <span className="small-text"> 
						  {` `}({_capitalizeText(moment(mpndBuilding.sales_start_date).format('MM/DD/YYYY'))})
					  </span>
				  </Fragment> : `Sales Not Started`}</h3>
			<span className="product-card__subtext">Sales Started</span>
		</div>
	
		<div className="stat mt-2 sales" >
			<SalesProgress label={"Units Sold"} 
			progress={parseFloat(((mpndBuilding.inventory_total - mpndBuilding.inventory_unsold) / mpndBuilding.inventory_total) * 100).toFixed(0)} 
			inventory_unsold={mpndBuilding.inventory_total - mpndBuilding.inventory_unsold} inventory_total={mpndBuilding.inventory_total} maxWidth="80%"
			size={'middle'}  />
			<span className="product-card__subtext">Percent Sold</span>
		</div>
	</div>
	<Tooltip title="See Project">
		<Button
		   onClick={(e) => _seeProject(e, mpndBuilding)}
		   variant="contained"
		   color="primary"
		   className="buyersList__primary-button"	
		   style={{width:200}}	   
		   endIcon={<OpenInNewIcon size={18} />}      
	   >	More on Pro
	   </Button>
			   
	</Tooltip>
	</div>
	</div>
  )
}


export default MPNDBuilding