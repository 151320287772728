import React, { Fragment } from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import moment from "moment";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from '../../../../../shared/helpers/utils';


const CurrentProgress = (props) => {
	const { buildingRes, location, statsRes } = props;
	
	if(!buildingRes || !statsRes){
		return null;
	}
	
	
/*
	let total_active = buildingRes.inventory_active > 0 ? buildingRes.inventory_active : 0;
	let total_contract = buildingRes.inventory_contract > 0 ? buildingRes.inventory_contract : 0;
	let total_sold = buildingRes.inventory_sold > 0 || buildingRes.inventory_not_yet_recorded > 0 ? buildingRes.inventory_sold + buildingRes.inventory_not_yet_recorded : 0;
	total_sold += buildingRes.inventory_resale > 0 ? buildingRes.inventory_resale : 0;
	
	let total_resale = buildingRes.inventory_resale > 0 ? buildingRes.inventory_resale : 0;
	
	let total_count = buildingRes.inventory_total > 0 ? buildingRes.inventory_total : 0;
	let total_shadow = total_count - total_active - total_contract - total_sold - total_resale;
*/
	let units_url = location.pathname.replace('analysis', 'units');
	let base_url = units_url + '?';
	if(['active', 'condo declaration vetted'].indexOf(buildingRes.offering_verified_status) >= 0 || buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970){
		base_url = units_url +'?unit_stage=sponsor&'
	}
	
	let total_count = 0;
	let total_shadow = 0;
	let total_active = 0;
	let total_contract = 0;
	let total_bulk = 0;
	let total_sold = 0;
	let total_resale = statsRes && statsRes.resale && statsRes.resale.count ? statsRes.resale.count : 0;
	
	if(statsRes.by_status && statsRes.by_status.sponsor && statsRes.by_status.sponsor.buckets && statsRes.by_status.sponsor.buckets.length > 0){
		total_count = statsRes.by_status.count
		statsRes.by_status.sponsor.buckets.map(bucket =>{
			if(bucket.val === 'shadow' || bucket.val === 'never listed' || bucket.val === ''){
				total_shadow += bucket.count;				
			}
			if(bucket.val === 'active'){
				total_active += bucket.count;				
			}
			if(bucket.val === 'in contract'){
				total_contract += bucket.count;				
			}
			if(bucket.val === 'sold' || bucket.val === 'not_yet_recorded'){
				total_sold += bucket.count;				
			}
			if(bucket.val === 'bulk sold'){
				total_bulk += bucket.count;				
			}
			
		})
	}
	
	total_sold += total_resale;
	total_sold += total_bulk;
	
	let total_unsold = buildingRes.inventory_total - total_contract - total_sold;
	
	if(total_count < buildingRes.inventory_total){
		total_count = buildingRes.inventory_total;
	}
	
	if((total_shadow + total_active) < total_unsold){
		total_shadow = total_unsold - total_active;
	}

	
	return (
	  <Col md={12} xl={12} lg={12} xs={12}>
	    <Card>
	      <CardBody className="dashboard__booking-card">
	        {buildingRes && buildingRes.offering_construction === 'conversion' ?
		          <h5 className="dashboard__booking-total-description">	         	 
			        	 <Fragment>Unit Breakdown</Fragment>   
			      </h5>
		        
		        : 
		        <Fragment>
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            {total_unsold} 
			          </h5>
			          <TrendingUpIcon className="dashboard__trend-icon" />
			        </div>
			        <h5 className="dashboard__booking-total-description">	         	 
			        	 <Fragment>Units Unsold - Sponsor Sales Progress</Fragment>   
			        </h5>	
		        </Fragment>
	        } 
	        <div className="progress-wrap progress-wrap--big">
	        	{!(buildingRes && buildingRes.offering_construction === 'conversion') ?
		        	<p className="dashboard__booking-card-progress-label">
		        		 ({((total_unsold / props.buildingRes.inventory_total) * 100).toFixed(0)}%) Available
		        	</p>
		        	:<p className="dashboard__booking-card-progress-label">&nbsp;</p>
		        	
	        	}
			    <Progress multi>
			    	{total_shadow>0 &&
				        <Progress bar color="shadow" value={total_shadow} max={total_count}>
				        	{/*((total_shadow / total_count) * 100).toFixed(0)}% */}
								
							{buildingRes && buildingRes.offering_construction === 'conversion' ? 		
							    <Link to={`${base_url}current_status=shadow`}>{_formatNumber(total_shadow)} Conversion</Link>
							    :
								<Link to={`${base_url}current_status=shadow`}>{_formatNumber(total_shadow)} Shadow</Link>
							}
							
								
						</Progress>
				    }
				    {total_active>0 &&
				        <Progress bar color="active" value={total_active} max={total_count}>
				        	{/*((total_active / total_count) * 100).toFixed(0)}% */}
				        	<Link to={`${base_url}current_status=active`}>{_formatNumber(total_active)} Active</Link>			
				        </Progress>
				    }				    
				    {total_contract>0 &&
				        <Progress bar color="contract" value={total_contract} max={total_count}>
				        	{/*((total_contract / total_count) * 100).toFixed(0)}%*/ }
				        	<Link to={`${base_url}current_status=in-contract`}>{_formatNumber(total_contract)} Contract</Link>				
				        </Progress>
				    }
				    {total_sold>0 &&
				        <Progress bar color="sold" value={total_sold} max={total_count}>
				        	{/*((total_sold / total_count) * 100).toFixed(0)}% */}
				        	<Link to={`${units_url}?current_status=closed`}>{_formatNumber(total_sold)} Sold {total_bulk>0 && ` (${_formatNumber(total_bulk)} Bulk)`}	</Link>			
				        </Progress>
				    }
				</Progress> 
			    
			</div>     
	      </CardBody>
	    </Card>
	  </Col>
	)
};

export default CurrentProgress;
