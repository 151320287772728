import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import PropTypes from "prop-types";
import LinearLoading from "../../../../../shared/components/LinearLoading";
import moment from "moment";
import { _axiosCall } from "../../../../../shared/helpers/apicalls";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  buildingFL,
  propertyFL,
  _capitalizeText,
  _getBuildingURL,
  _shouldShow,
  _formatNumber,
  _formatPrice,
  UnitCurrentStatus,
  _nameToUrl,
  BuildingPhoto,
  BuildingStageBadge,
  _replaceAbbreviations,
  _getCompanyURL,
  _PickOrgName,
  _getContactURL,
  ProfileAvatar,
  _pickPrimaryCompany,
  _formatNameAddress
} from "../../../../../shared/helpers/utils";
import { withStyles } from "@material-ui/core/styles";

const apiURL = process.env.API_ROOT;
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    //marginLeft:"-8px",
    marginBottom: "15px",
    alignItems: "left",
    "& > *": {},
  },
  button: {
    "&.selected": {
      background: "#D1D6E0",
    },
    fontSize: 11,
    width: 90,
  },
});
const EmptyList = ({ title, type }) => {
  return (
    <Card>
      <CardBody style={{ padding: 15 }}>
        <Table responsive className="table-striped trend-buildings">
          <tbody>
            <tr>
              <th colSpan={2}>
                <h5 className="bold-text">{title}</h5>
              </th>
            </tr>
            {type === "projects" && (
              <tr>
                <td colSpan={2}>
                  <div className="light-text">No project viewed yet</div>
                  <br />
                  <Link to="/pipeline-search">
                    <div>
                      <span className="link-text">Search projects &raquo;</span>
                    </div>
                  </Link>
                </td>
              </tr>
            )}
            {type === "companies" && (
              <tr>
                <td colSpan={2}>
                  <div className="light-text">No company viewed yet</div>
                  <br />
                  <Link to="/companies">
                    <div>
                      <span className="link-text">
                        Search Companies &raquo;
                      </span>
                    </div>
                  </Link>
                </td>
              </tr>
            )}
            {type === "people" && (
              <tr>
                <td colSpan={2}>
                  <div className="light-text">No contact viewed yet</div>
                  <br />
                  <Link to="/people">
                    <div>
                      <span className="link-text">Search People &raquo;</span>
                    </div>
                  </Link>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const ShowProjects = ({ data, title, color, period }) => {
  if (!data || data.length<=0) {
    return <EmptyList title={title} type={"projects"} />;
  }

  return (
    <Card>
      <CardBody style={{ padding: 15 }}>
        <Table responsive className="table-striped trend-buildings">
          <tbody>
            <tr>
              <th colSpan={2}>
                <h5 className="bold-text">{title}</h5>
              </th>
              {!isMobile && (
                <th className="views">
                  <h5 className="bold-text"></h5>
                </th>
              )}
            </tr>

            {data &&
              Array.isArray(data) &&
              data.map((bldg, i) => {
                if (!bldg || !bldg.address) return null;
                
                const unitURL = _getBuildingURL(bldg, "_id");
                
                const { buildingNameOrAdr, adr, _boro, _hood } = _formatNameAddress(bldg);  
                return (
                  <tr key={i} style={{ border: 0, cursor: "pointer" }}>
                    <td className="photo">
                      <Link to={unitURL}>
                        <BuildingPhoto item={bldg} />
                      </Link>
                    </td>
                    <td>
                      <Link to={unitURL}>
                        <div>
                          {buildingNameOrAdr}{" "}
                          <BuildingStageBadge item={bldg} small />
                        </div>
                        <span
                          className="light-text small-text"
                          style={{ color: "#858AA1" }}
                        >
                          {_hood
                            ? `${_capitalizeText(
                                _hood
                              )}, `
                            : ""}{" "}
                          {_capitalizeText(_boro)}
                        </span>
                        <br />
                        {!isMobile && (
                          <td className="views">
                            <span
                              className="light-text small-text"
                              style={{ color: "#858AA1" }}
                            >
                              {" "}
                              {_capitalizeText(
                                moment(bldg.created_timestamp).fromNow()
                              )}
                            </span>
                          </td>
                        )}
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const ShowCompanies = ({ data, title, color }) => {
  if (!data || data.length<=0) {
    return <EmptyList title={title} type={"companies"} />;
  }
  return (
    <Card>
      <CardBody style={{ padding: 15 }}>
        <Table responsive className="table-striped trend-buildings">
          <tbody>
            <tr>
              <th colSpan={2}>
                <h5 className="bold-text">{title}</h5>
              </th>
              {!isMobile && (
                <th className="views">
                  <h5 className="bold-text"></h5>
                </th>
              )}
            </tr>

            {data &&
              Array.isArray(data) &&
              data.map((item, i) => {
                if (!item || !item.name) return null;
                const buildingNameOrAdr = item.name
                  ? _capitalizeText(item.name)
                  : "--";
               
                const unitURL = _getCompanyURL(item, "key");
                return (
                  <tr key={i} style={{ border: 0, cursor: "pointer" }}>
                    <td className="avatar">
                      <Link to={unitURL}>
                        <ProfileAvatar
                          contact={item}                          
                          companyCard={true}
                        />
                      </Link>
                    </td>
                    <td>
                      <Link to={unitURL}>
                        <div>
                          {buildingNameOrAdr}
                        </div>
                        
                          {item.categories &&
                          item.categories.length > 0 &&
                          <span
                            className="light-text small-text"
                            style={{ color: "#858AA1" }}
                          >
                            {item.categories.sort().map(c => _capitalizeText(c.replace(/-/g, " "))).join(", ")}
                            <br />
                          </span>
                        }
                        
                        {!isMobile && (
                          <td className="views">
                            <span
                              className="light-text small-text"
                              style={{ color: "#858AA1" }}
                            >
                              {" "}
                              {_capitalizeText(
                                moment(item.created_timestamp).fromNow()
                              )}
                            </span>
                          </td>
                        )}
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const ShowPeoples = ({ data, title, color }) => {
  if (!data || data.length<=0) {
    return <EmptyList title={title} type={"people"} />;
  }
  return (
    <Card>
      <CardBody style={{ padding: 15 }}>
        <Table responsive className="table-striped trend-buildings">
          <tbody>
            <tr>
              <th colSpan={2}>
                <h5 className="bold-text">{title}</h5>
              </th>
              {!isMobile && (
                <th className="views">
                  <h5 className="bold-text"></h5>
                </th>
              )}
            </tr>

            {data &&
              Array.isArray(data) &&
              data.map((item, i) => {
                if (!item || !item.name) return null;
                const buildingNameOrAdr = item.name
                  ? _capitalizeText(item.name)
                  : "";
               
                const unitURL = _getContactURL(item, "key");
                const primaryOrg = _pickPrimaryCompany(item);
                return (
                  <tr key={i} style={{ border: 0, cursor: "pointer" }}>
                    <td className="avatar">
                      <Link to={unitURL}>
                        <ProfileAvatar contact={item} />
                      </Link>
                    </td>
                    <td>
                      <Link to={unitURL}>
                        <div>
                          {buildingNameOrAdr}
                        </div>
                        
                          {primaryOrg && primaryOrg.name && 
                            <span
                              className="light-text small-text"
                  
                            >
                              {_capitalizeText(primaryOrg.name)}
                              <br />
                            </span>
                          }
                        
                        
                        {!isMobile && (
                          <td className="views">
                            <span
                              className="light-text small-text"
                              style={{ color: "#858AA1" }}
                            >
                              {" "}
                              {_capitalizeText(
                                moment(item.created_timestamp).fromNow()
                              )}
                            </span>
                          </td>
                        )}
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

class RecentHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetchInProgress: false,
      projects: null,
      companies: null,
      peoples: null,
      period: "today",
      limit: isMobile ? 3 : 5,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.period !== prevState.period) {
      this._getActivity();
    }
  }

  componentDidMount() {
    this._getActivity();
  }

  _getActivity = async () => {
    const { type, user } = this.props;

    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const queryData = [
      {
        $match: {
          user_key: { $in: [user.id, user.id.toString().toLowerCase()] },
        },
      },

      {
        $facet: {
          projects: [
            {
              $match: {
                event_type: "projectView",
              },
            },

            {
              $group: {
                _id: {
                  property: "$project.key",
                },
                created_timestamp: { $max: "$created_timestamp" },
                count: {
                  $sum: 1,
                },
              },
            },
            {
              $sort: {
                created_timestamp: -1,
              },
            },
            {
              $limit: 10,
            },
          ],
          companies: [
            {
              $match: {
                event_type: "organizationView",
              },
            },

            {
              $group: {
                _id: {
                  property: "$organization.key",
                },
                created_timestamp: { $max: "$created_timestamp" },
                count: {
                  $sum: 1,
                },
              },
            },
            {
              $sort: {
                created_timestamp: -1,
              },
            },
            {
              $limit: 10,
            },
          ],
          people: [
            {
              $match: {
                event_type: "contactView",
              },
            },

            {
              $group: {
                _id: {
                  property: "$contact.key",
                },
                created_timestamp: { $max: "$created_timestamp" },
                count: {
                  $sum: 1,
                },
              },
            },
            {
              $sort: {
                created_timestamp: -1,
              },
            },
            {
              $limit: 10,
            },
          ],
        },
      },
    ];

    let axiosData = {
      url: apiURL + "/buyerslist/event/aggregate?cache=refresh",
      method: "post",
      query: queryData,
    };

    let response = await _axiosCall(axiosData)
      .then((res) => {
        console.log(res)
        const projectIDs = [];
        const companyIDs = [];
        const peopleIDs = [];
        if (res && res[0]) {
          if (res[0]["projects"] && res[0]["projects"].length > 0) {
            res[0]["projects"].map((building) => {
              projectIDs.push(building._id.property);
            });
            this._getProjects(projectIDs, res[0]);
          } else {
            this.setState({
              projects: false,
              fetchInProgress: false,
              buildingsFetchInProgress: false,
            });
          }
          if (res[0]["companies"] && res[0]["companies"].length > 0) {
            res[0]["companies"].map((property) => {
              companyIDs.push(property._id.property);
            });
            this._getCompanies(companyIDs, res[0]);
          } else {
            this.setState({
              companies: false,
              fetchInProgress: false,
              buildingsFetchInProgress: false,
            });
          }
          if (res[0]["people"] && res[0]["people"].length > 0) {
            res[0]["people"].map((property) => {
              peopleIDs.push(property._id.property);
            });
            
            this._getPeoples(peopleIDs, res[0]);
          } else {
            this.setState({
              peoples: false,
              fetchInProgress: false,
              buildingsFetchInProgress: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _getProjects = async (_projectIDs, resData) => {
    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { limit } = this.state;

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          { "$and" : [{source_provider: "permits" }, {building_status: { "$ne" : "resale"}}]},
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      { $or : [{pipeline_occupancy_classification_proposed_code:{$nin:["u"]}}, {pipeline_total_construction_floor_area:{$gt:1000}}]},
      { key: { $in: [..._projectIDs] } },
    ];
    let mongoQuery = [
      {
        $search: {
          compound: {
            "mustNot": [
                {
                    "exists": {
                        "path": "meta.deleted"
                    }
                }
            ],
             must: [
               {
                   "compound": {
                       "should": [
                           {
                               "compound": {
                                   "mustNot": [
                                       {
                                           "queryString": {
                                               "defaultPath": "source_provider",
                                               "query": "mpnd"
                                           }
                                       }
                                   ]
                               }
                           },
                           {
                               "queryString": {
                                   "defaultPath": "source_provider",
                                   "query": "permits AND building_status:(NOT resale)"
                               }
                           },
                           {
                               "queryString": {
                                   "defaultPath": "building_status",
                                   "query": "sponsor OR pipeline"
                               }
                           }
                       ]
                   }
                  
               },
               {
                 "queryString": {
                     "defaultPath" : "key",
                     "query": `("${_projectIDs.join('" "')}")`
                 }
               }
             ]
          }
      },
    },
    {
      $project: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        meta: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
        project_stage: 1,
        pipeline_project_stalled: 1,
        pipeline_project_abandoned: 1
      }
    }
     
    ];
    let axiosData = {
      url: apiURL + "/pipeline/aggregate-search",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res) {
          res.map((bldg) => {
            if (
              resData["projects"].filter((b) => b._id.property == bldg.key)
                .length > 0
            ) {
              resData["projects"].filter((b) => b._id.property == bldg.key)[0][
                "property"
              ] = bldg;
            }
          });
          this.setState({
            projects: resData["projects"]
              .map((b) => Object.assign({}, b, b.property)).filter((b) => b.name || b.address)
              .slice(0, limit),
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        } else {
          this.setState({
            projects: resData["projects"].slice(0, limit),
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _getPeoples = async (_peopleIDs, resData) => {
    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { limit } = this.state;

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let mongoQuery = [
      {
        $search: {
          compound: {
            "mustNot": [
                {
                    "exists": {
                        "path": "meta.deleted"
                    }
                }
            ],
             must: [
               {
               "queryString": {
                   "defaultPath" : "key",
                   "query": `(${_peopleIDs.join(" ")})`
               }
               }
             ]
            }
        },
      },
      {
        $project: {
          address: 1,
          date_added: 1,
          key: 1,
          logo: 1,
          name: 1,
          slug: 1,
          _id: 1,
          first_name: 1,
          last_name: 1,
          organizations: true
        },
      },
    ];
    let axiosData = {
      url: apiURL + "/pipeline/aggregate/contacts",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        console.log(res)
        if (res) {
          console.log(resData["people"])
          res.map((property) => {
            if (
              resData["people"].filter((b) => b._id.property == property.key)
                .length > 0
            ) {
              resData["people"].filter(
                (b) => b._id.property == property.key
              )[0]["property"] = property;
            }
          });
          this.setState({
            peoples: resData["people"]
              .map((b) => Object.assign({}, b, b.property))
              .slice(0, limit),
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        } else {
          this.setState({
            peoples: resData["people"].slice(0, limit),
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _getCompanies = async (_companyIDs, resData) => {
    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { limit } = this.state;

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let mongoQuery = [
      {
        $search: {
          compound: {
            "mustNot": [
                {
                    "exists": {
                        "path": "meta.deleted"
                    }
                }
            ],
             must: [
               {
               "queryString": {
                   "defaultPath" : "key",
                   "query": `(${_companyIDs.join(" ")})`
               }
               }
             ]
            }
        },
      },
      {
        $project: {
          address: 1,
          date_added: 1,
          key: 1,
          logo: 1,
          name: 1,
          slug: 1,
          _id: 1,
          categories: 1
        },
      },
    ];

    let axiosData = {
      url: apiURL + "/pipeline/aggregate/organizations",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        if (res) {
          res.map((property) => {
            if (
              resData["companies"].filter((b) => b._id.property == property.key)
                .length > 0
            ) {
              resData["companies"].filter(
                (b) => b._id.property == property.key
              )[0]["property"] = property;
            }
          });

          this.setState({
            companies: resData["companies"]
              .map((b) => Object.assign({}, b, b.property))
              .slice(0, limit),
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        } else {
          this.setState({
            companies: resData["companies"].slice(0, limit),
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  handleBuildingClick = (event, building, tab) => {
    event.stopPropagation();
    const buildingURL =
      _getBuildingURL(building, "building_slugs") + (tab ? `/${tab}` : "");
    this.props.history.push(buildingURL);
  };

  setPeriod = (period) => {
    this.setState({ period: period });
  };

  render() {
    const {
      savedUnits,
      totalCount,
      projects,
      period,
      companies,
      peoples,
      fetchInProgress,
      buildingsFetchInProgress,
    } = this.state;
    const { classes, type } = this.props;
    //if(!data) return null;

    if (projects === false && companies === false && peoples === false)
      return null;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col md={12} className={"mb-2"}>
              <div className="card__title">
                <h5 className="bold-text">{"My History (Recently Viewed)"}</h5>
              </div>
            </Col>
          </Row>

          {fetchInProgress || buildingsFetchInProgress ? (
            <div>
              <LinearLoading />
              <div style={{ height: 357 }}></div>
            </div>
          ) : (
            <Row>
              <Col md={4} className={"mb-2"}>
                <ShowProjects
                  data={projects}
                  title={"Projects"}
                  color={"#4ce1b6"}
                  period={period}
                />
              </Col>
              <Col md={4} className={"mb-2"}>
                <ShowCompanies
                  data={companies}
                  title={"Companies"}
                  color={"#FFD23F"}
                  period={period}
                />
              </Col>
              <Col md={4} className={"mb-2"}>
                <ShowPeoples
                  data={peoples}
                  title={"People"}
                  color={"#FFD23F"}
                  period={period}
                />
              </Col>
            </Row>
          )}

          {/*<div className="unit__load text-right semi-bold-text">			   		
				    <Tooltip title="See More">
				        <Link to={`/building-database/sale/sponsor?building_stage=sponsor,resale`}>See All History &raquo;</Link>			 
				  	</Tooltip>				
			    </div>*/}
        </CardBody>
      </Card>
    );
  }
}

RecentHistory.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(RecentHistory);
