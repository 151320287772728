/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/MagnifyIcon';
import Panel from './../../../../../shared/components/Panel';
import Button from '@material-ui/core/Button';
import {
  Legend, Pie, PieChart, ResponsiveContainer, Tooltip,
} from 'recharts';

import ResiIcon from 'mdi-react/HomeVariantOutlineIcon';
import CommIcon from 'mdi-react/DomainIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_formatPrice,
	_formatNumber,
	_formatBigNumber
} from "../../../../../shared/helpers/utils";

import {
  Card, CardBody, Col, Progress, ButtonToolbar
} from 'reactstrap';

const dir = 'ltr';
const chartData = [{
  id: 0, name: 'Operation', value: 2500, fill: '#4ce1b6',
}, {
  id: 1, name: 'Construction', value: 2500, fill: '#FFD23F',
}, {
  id: 2, name: 'Pre-development', value: 5000, fill: '#28C2FF',
}];


const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { right: 0 };
  return ({
	...left,
	marginTop: '-5px',
	lineHeight: '16px',
	position: 'absolute',
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__booking-reservations-chart-legend dashboard__chart-legend">
	{payload.map(entry => (
	  <li key={entry.payload.id}>
		<span style={{ backgroundColor: entry.color }} />
		<p>{entry.value}</p>
	  </li>
	))}
  </ul>
);


const ProjectCount = ( { data, type } ) => {

 // if(!data) return null;
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  
	const onMouseMove = (e) => {
	  if (e.tooltipPosition) {
		setCoordinates({
		  x: dir === 'ltr' ? e.tooltipPosition.x - 130 : e.tooltipPosition.x / 10, y: e.tooltipPosition.y - 40,
		});
	  }
	};
  
  if(type == "residential"){
	  
	  return (	
			<Col md={12} xl={4} lg={4} xs={12}>
			  <Card>
				  <CardBody className="dashboard__card-widget card__link">				
					  <Link to={`/pipeline-search?building_stage=all&order=desc&order_by=pipeline_dwelling_units_proposed&pipeline_occupancy_type=residential`}>
						  <div className="card__title">
							<h5 className="bold-text">{`Residential`} 
							
							</h5>
						  </div>
					  
					<div className="mobile-app-widget">
						  <div className="mobile-app-widget__top-line">
							<p className="mobile-app-widget__total-stat">{data ? _formatNumber(data['resi_project_count'][0]['count']) : "--"}</p>
							<ResiIcon className="dashboard__trend-icon" />
						  </div>
						  <div className="mobile-app-widget__title">
							<h5>{'Pipeline Projects'}</h5>
						  </div>
						  <p className="progress__label">
							 <span style={{ color:"#9600BF" }}>{data ? _formatBigNumber((data['resi_project_count'][0]['units'] - data['resi_project_count'][0]['units_existing'])) : "--"}</span> <span className="small-text light-text"> Units Across </span> <span style={{ color:"#9600BF" }}>{data ? _formatBigNumber(data['resi_project_count'][0]['sq_ft']) : "--"}</span> <span className="small-text light-text">  Sq Ft</span>
					  
						  </p> 
					   </div>
					</Link>
					
				  </CardBody>
			  </Card>
			</Col>
		)
  }	else if(type == "commercial"){
		
		return (	
			  <Col md={12} xl={4} lg={4} xs={12}>
				<Card>
					<CardBody className="dashboard__card-widget card__link">				
						<Link to={`/pipeline-search?building_stage=all&order=desc&order_by=pipeline_dwelling_units_proposed&pipeline_occupancy_type=commercial`}>
							<div className="card__title">
							  <h5 className="bold-text">{`Commercial`} 
							  
							  </h5>
							</div>
						
					  <div className="mobile-app-widget">
						  <div className="mobile-app-widget__top-line">
							<p className="mobile-app-widget__total-stat">{data ?_formatNumber(data['comm_project_count'][0]['count']) : "--"}</p>
							<CommIcon className="dashboard__trend-icon" />
						  </div>
						  <div className="mobile-app-widget__title">
							<h5>{'Pipeline Projects'}</h5>
						  </div>
						  <p className="progress__label">
							 <span style={{ color:"#9600BF" }}>{data ? _formatBigNumber(data['comm_project_count'][0]['sq_ft']) : "--"}</span> <span className="small-text light-text">  Sq Ft</span>
					  
						  </p> 
					   </div>
					  </Link>
					</CardBody>
				</Card>
			  </Col>
		  )
	}	
  
};


export default withTranslation('common')(ProjectCount);
