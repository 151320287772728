import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Row,
  Col,
  Progress,
  Badge,
  Container,
  ButtonToolbar,
} from "reactstrap";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import MatTableToolbar from "./MatTableToolbar";
import MatTableFiltersSelect from "./MatTableFiltersSelect";
import MatTableFilterButton from "./MatTableFilterButton";

import MatTableView from "./MatTableView";
import MatGridView from "./MatGridView";
import MatMapView from "./MatMapView";
import UnitsListTable from "./UnitsListTable";

import MatAppBar from "../../../shared/components/building_filters/UnitsSearchAppBar";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import OpenIcon from "mdi-react/FileDocumentOutlineIcon";
import ContactIcon from "mdi-react/EmailOutlineIcon";
import PreviewIcon from "mdi-react/EyeOutlineIcon";
import OpenInNew from "mdi-react/OpenInNewIcon";

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import CheckedIcon from "mdi-react/CheckboxMarkedCircleOutlineIcon";
import LinearLoading from "../../../shared/components/LinearLoading";

import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import MapViewIcon from "mdi-react/MapOutlineIcon";

import LoadMoreIcon from "mdi-react/RefreshIcon";
import LoadingIcon from "mdi-react/DotsHorizontalIcon";

import CondoDecIcon from "mdi-react/AlphaCCircleOutlineIcon";

import BuildingIcons from "../../../shared/components/BuildingIcons";

import EditOrganization from "../../../shared/components/edit/EditOrganization";

import MergeContactsButton from "../../../shared/components/buttons/MergeContactsButton";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";

import GroupedUnitsSearchTableHead from "./GroupedUnitsSearchTableHead";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  ConstructionProgress,
  BuildingStatus,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  mpndCohortQuery,
  syncGenericFilter,
  _objectEqual,
  buildingExportFL,
  getCohortQuery,
  StatusBadge,
  GenerateBuildingSlug,
  GenerateBuildingKey,
  _tranformTagsMongoQuery,
} from "../../../shared/helpers/utils";

import {
  _buildingAdminMatchFilters,
  _buildingStageFilter,
  _companyFilters,
} from "../../../shared/helpers/filters";

import queryString from "query-string";

let counter = 0;

function getSorting(order, orderBy) {
  if (order === "desc") {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}
const PurpleSwitch = withStyles({
  switchBase: {
    color: "#A3ADC2",

    "&$checked": {
      color: "#A3ADC2",
    },
    "&$checked + $track": {
      backgroundColor: "#D1D6E0",
    },
  },
  checked: {},
  track: { backgroundColor: "#D1D6E0" },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {
  if (buildingRes.offering_verified_status === "abandoned") {
    return "--";
  }
  const sales_year = parseInt(
    moment(buildingRes.sales_start_date).format("YYYY")
  );
  if (buildingRes.building_status === "resale") {
    if (!buildingRes.sales_start_date || sales_year <= 1970) {
      return "--";
    }

    const offering_effective_year = buildingRes.offering_effective_date
      ? parseInt(moment(buildingRes.offering_effective_date).format("YYYY"))
      : 0;
    const first_closing_year = buildingRes.first_closing_date
      ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
      : 0;

    if (sales_year > offering_effective_year) {
      return "--";
    }
    if (sales_year > first_closing_year) {
      return "--";
    }
  }
  if (
    !buildingRes.sales_start_date ||
    parseInt(moment(buildingRes.sales_start_date).format("YYYY")) <= 1970
  ) {
    if (type === "fromNow") {
      return "Not Yet Started";
    } else {
      return null;
    }
  }

  if (type === "fromNow") {
    return moment(buildingRes.sales_start_date).fromNow();
  }
  return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
};
const calcuateTCODate = (buildingRes, type) => {
  if (buildingRes.offering_verified_status === "abandoned") {
    return "--";
  }
  const tco_year = parseInt(
    moment(buildingRes.building_effective_start).format("YYYY")
  );
  if (buildingRes.building_status === "resale") {
    if (!buildingRes.sales_start_date || tco_year <= 1970) {
      return "--";
    }

    const offering_effective_year = buildingRes.offering_effective_date
      ? parseInt(moment(buildingRes.offering_effective_date).format("YYYY"))
      : 0;
    const first_closing_year = buildingRes.first_closing_date
      ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
      : 0;

    if (tco_year > offering_effective_year) {
      return "--";
    }
    if (tco_year > first_closing_year) {
      return "--";
    }
  }
  if (
    !buildingRes.building_effective_start ||
    parseInt(moment(buildingRes.building_effective_start).format("YYYY")) <=
      1970
  ) {
    if (type === "fromNow") {
      return "--";
    } else {
      return null;
    }
  }

  if (type === "fromNow") {
    return moment(buildingRes.building_effective_start).fromNow();
  }
  return `(${moment(buildingRes.building_effective_start).format("M/D/YYYY")})`;
};

const buildingUrl = process.env.API_ROOT + "/pipeline/";

const subFilterKey = "subFilters";
const buildingStagesKey = "buildingStages";
const defaultSubFilter = {};
const defaultStage = ["all"];

const CurrentStatus = ({ item }) => {
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
      <div>
        <Badge color="available">Move-in Ready</Badge>
      </div>
    );
  }
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};

const getSubFilters = ({ stage, match }) => {
  const type = match.params.type ? match.params.type : "residential";

  let filters = _companyFilters.concat(
    _buildingAdminMatchFilters.filter(
      (filter) =>
        !(filter.exclude_stage && filter.exclude_stage.indexOf(stage) >= 0)
    )
  );

  if (type === "commercial") {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "residential")
    );
  } else {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "commercial")
    );
  }
  return filters;
};

class MatTable extends PureComponent {
  lsPre = `PIPECOMPANY_DB_`;
  buildingSubFilters = getSubFilters(this.props);

  state = {
    order: syncGenericFilter("order", this.lsPre, "order", "desc"),
    orderBy: syncGenericFilter("order_by", this.lsPre, "orderBy", "count"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: this.props.isSingle ? 8 : 40,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    clearInput: false,
    end: 30,
    filterBorough: "all_boroughs",
    // filterFetchInProgress: false,
    filterStage: "all_projects",
    futureInventoryData: false,
    // headerHeight: 'auto',
    inventoryData: false,
    originalData: false,
    ovelayMinHeight: false,
    reactTableFiltered: [],
    // signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
    showCounts: { desktop: 3, tablet: 2, mobile: 1 },
    start: 0,
    stats: {},
    subFilters: syncURLFilters(
      this.props,
      this.buildingSubFilters,
      subFilterKey,
      this.lsPre,
      defaultSubFilter
    ),
    tableData: false,
    searchText: syncURLSearch(this.props, "searchText"),
    viewType: "grid",
    facetData: null,
    buildingStages: syncStageFilters(
      this.props,
      _buildingStageFilter,
      buildingStagesKey,
      this.lsPre,
      defaultStage
    ),
    type:
      this.props.match && this.props.match.params.type
        ? this.props.match.params.type
        : "residential",
    groupedData: false,
    groupedCount: false,
    projectCount: false,
    selectedItems: {},
    numeric: false,
  };

  q = "";
  fq = "";
  sort = "";

  componentDidMount() {
    //this._getInventoryStats();
    this._getPipelineBuildings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let buildingStageChange = !_isEqual(
      prevState.buildingStages,
      this.state.buildingStages
    );
    let subFilterChanged =
      this.buildingSubFilters.filter((item) => {
        return (
          this.state.subFilters[item.search_key] !==
          prevState.subFilters[item.search_key]
        );
      }).length > 0;
    let multiSubFilterChanged =
      this.buildingSubFilters.filter((item) => {
        return (
          this.state.subFilters[item.search_key && item.search_key[0]] !==
          prevState.subFilters[item.search_key && item.search_key[0]]
        );
      }).length > 0;
    if (
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.filterStage !== prevState.filterStage ||
      this.state.searchText !== prevState.searchText ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.page !== prevState.page ||
      this.state.rowsPerPage !== prevState.rowsPerPage ||
      this.state.numeric !== prevState.numeric ||
      searchTagsChange ||
      subFilterChanged ||
      buildingStageChange ||
      multiSubFilterChanged
    ) {
      const resetPage = this.state.page === prevState.page;
      console.log("component update", prevState, this.state);
      this._getPipelineBuildings();
    }
  }

  handleRequestSort = (event, property, setOrder) => {
    const orderBy = property;
    let order = setOrder ? setOrder : "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc" && !setOrder) {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  shouldLinkToMP = (building) => {
    const { user } = this.props;
    return (
      (user.role &&
        Array.isArray(user.role) &&
        user.role.indexOf("admin") >= 0) ||
      [
        "active",
        "coming soon",
        "offering plan required",
        "offering plan acquired",
        "condo declaration vetted",
      ].indexOf(building.offering_verified_status) < 0
    );
  };

  handleBuildingClick = (event, building) => {
    this.handleBuildingPreviewClick(event, building);
  };

  handleBuildingPreviewClick = (event, contact, openNew) => {
    event.preventDefault();
    event.stopPropagation();
    const url = `/company/${contact["key"]}`;
    this.props.history.push(url);
  };

  _addFilterQuery = (
    min,
    max,
    query_key,
    searchFilters,
    companyFilters,
    filter,
    key
  ) => {
    let qVal = {};
    if (min && max) {
      qVal = {
        [query_key]: { $gte: min, $lte: max },
      };
    } else if (min && !max) {
      qVal = {
        [query_key]: { $gte: min },
      };
    } else if (!min && max) {
      qVal = {
        [query_key]: { $lte: max },
      };
    }
    if (Object.keys(qVal).length > 0) {
      if (filter && filter.value_contains_key) {
        if (filter.filter_type === "company") {
          companyFilters.push(qVal);
        } else {
          searchFilters.push(qVal);
        }
      } else {
        if (filter && filter.filter_type === "company") {
          const addQuery = {};
          addQuery[key] = qVal;
          companyFilters.push(addQuery);
        } else {
          const addQuery = {};
          addQuery[key] = qVal;
          searchFilters.push(addQuery);
        }
      }
    }
    return;
  };

  _getSpecificFilter = (key) => {
    let filter =
      this &&
      this.buildingSubFilters &&
      this.buildingSubFilters.filter(
        (filter) =>
          filter.search_key === key ||
          (filter.search_key && filter.search_key.includes(key))
      )[0];
    return filter;
  };
  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0, start: 0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  _getPipelineBuildings = async (query, resetPage, obj) => {
    const { user, searchTags, organizationRes, contactRes } = this.props;
    const { buildingStages, type, start, rowsPerPage, page, orderBy, order } =
      this.state;
    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      { "organizations.categories": { $nin: ["marketing"] } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
    ];

    let baseQuery = false;
    if (organizationRes && organizationRes.projects) {
      baseQuery = { key: { $in: organizationRes.projects } };
    } else if (contactRes && contactRes.projects) {
      baseQuery = { key: { $in: contactRes.projects } };
    }
    if (baseQuery) {
      searchFilters.push(baseQuery);
    }
    let locQuery = _tranformTagsMongoQuery(
      searchTags.filter((tag) => ["company", "contact"].indexOf(tag.type) < 0)
    );

    if (locQuery) {
      searchFilters.push(locQuery);
    }

    let contactQuery = _tranformTagsMongoQuery(
      searchTags.filter((tag) => ["company", "contact"].indexOf(tag.type) >= 0)
    );

    let companyFilters = [];

    if (contactQuery) {
      companyFilters.push(contactQuery);
    }

    if (searchTags.filter((tag) => tag.type == "text").length > 0) {
      searchTags
        .filter((tag) => tag.type == "text")
        .map((tag) => {
          let textQuery = {
            $and: tag.data.split(" ").map((token) => {
              return {
                "organizations.name": { $regex: `${token}`, $options: "i" },
              };
            }),
          };
          companyFilters.push(textQuery);
        });
    }
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          const filter = this.buildingSubFilters.filter(
            (filter) =>
              filter.search_key === key ||
              (filter.search_key && filter.search_key.includes(key))
          )[0];
          let result = [];
          let qVal = {};
          let search_query = [];
          if (filter && !filter.format_type) {
            const qVal1 = filter.values.filter(
              // (value) => value.filter === subFilterVal
              (value) =>
                subFilterVal.indexOf(value.filter) === -1 ? false : true
            );

            qVal1.forEach((data) => {
              if (key === "pipeline_building_stories_proposed") {
                if (data.search_value) {
                  search_query.push({ [key]: data.search_value });
                }
              }
              // for handling roles filter seprately
              if (
                data &&
                data.search_value &&
                data.search_value["organizations.project_roles"]
              ) {
                result.push(
                  ...data.search_value["organizations.project_roles"]["$in"]
                );
              }
              if (data && data.search_value && data.search_value[key]) {
                result.push(...data.search_value[key]["$in"]);
              }
              if (
                data &&
                data.search_value &&
                (data.search_value.$or || data.search_value.$and)
              ) {
                searchFilters.push(data.search_value);
              }
            });
          }
          if (search_query.length) {
            qVal = { $or: search_query };
          } else if (result.length) {
            qVal = { [key]: { $in: result } };
          }
          if (Object.keys(qVal).length > 0) {
            if (filter.value_contains_key) {
              if (filter.filter_type === "company") {
                companyFilters.push(qVal);
              } else {
                searchFilters.push(qVal);
              }
            } else {
              if (filter.filter_type === "company") {
                const addQuery = {};
                addQuery[key] = qVal;
                companyFilters.push(addQuery);
              } else {
                const addQuery = {};
                addQuery[key] = qVal;
                searchFilters.push(addQuery);
              }
            }
          }
        }
      });
    }
    // for numeric & price filter queries
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      let query_key,
        queryCount = {};
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];

        const filter = this._getSpecificFilter(key);
        query_key = filter && filter.filter_key;
        if (filter && filter.format_type && !filter.date_filter) {
          queryCount[key] = parseInt(subFilterVal);
          if (query_key in queryCount) {
            queryCount[query_key] += 1;
          } else {
            queryCount[query_key] = 1;
          }

          if (queryCount[query_key] === 2) {
            this._addFilterQuery(
              queryCount[filter.search_key[0]], // add minimum value
              queryCount[[filter.search_key[1]]], // add maximum value
              query_key,
              searchFilters,
              companyFilters,
              filter,
              key
            );
          }
        }
      });
    }

    // for date filter
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      let query_key,
        queryCount = {};
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];

        const filter = this._getSpecificFilter(key);
        query_key = filter && filter.filter_key;

        if (filter && filter.date_filter) {
          queryCount[key] = subFilterVal;
          if (query_key in queryCount) {
            queryCount[query_key] += 1;
          } else {
            queryCount[query_key] = 1;
          }

          if (queryCount[query_key] === 2) {
            if (queryCount.min_date || queryCount.max_date) {
              this._addFilterQuery(
                queryCount.min_date
                  ? 'ISODate:' + moment(queryCount.min_date).toDate().toISOString()
                  : false,
                queryCount.max_date
                  ? 'ISODate:' + moment(queryCount.max_date).toDate().toISOString()
                  : false,
                query_key,
                searchFilters,
                companyFilters,
                filter,
                key
              );
            }
          }
        }
      });
    }
    const buildingStageFilters = {
      "pre-development": { pipeline_project_stage: "planning" },
      construction: { pipeline_project_stage: "construction" },
      operation: { pipeline_project_stage: "completion" },
    };
    if (buildingStages && buildingStages.length > 0) {
      let stageQ = [];
      if (buildingStages.indexOf("all") < 0) {
        buildingStages.map((stage) => {
          stageQ.push(buildingStageFilters[stage]);
        });
        if (stageQ.length > 1) {
          searchFilters.push({ $or: stageQ });
        } else if (stageQ.length === 1) {
          stageQ && stageQ[0] && searchFilters.push(stageQ[0]);
        }
      }
    }
    let sort = {};

    let skip = page * rowsPerPage;
    let limit = (page + 1) * rowsPerPage;

    sort[orderBy] = order === "asc" ? 1 : -1;
    setURLFromState(this.props, this.state);

    this.q = searchFilters;
    let atlasQuery = [
      { $match: { $and: searchFilters } },
      { $project: { _id: 0, key: 1, organizations: 1 } },
      {
        $facet: {
          project_count: [
            { $group: { _id: null, count: { $sum: 1 } } },
            { $project: { _id: 0 } },
          ],
          org_count: [
            { $unwind: "$organizations" },
            { $group: { _id: { organization: "$organizations.key" } } },
            { $group: { _id: null, count: { $sum: 1 } } },
            { $project: { _id: 0 } },
          ],
          organizations: [
            { $unwind: "$organizations" },
            { $project: { _id: 0, key: 1, organizations: 1 } },
            { $unwind: "$organizations" },

            {
              $project: {
                _id: 0,
                key: 1,
                "organizations.key": 1,
                "organizations.name": 1,
                "organizations.project_roles": 1,
              },
            },
            {
              $group: {
                _id: { key: "$organizations.key" },
                name: { $first: "$organizations.name" },
                count: { $sum: 1 },
              },
            },
            { $project: { _id: 0, organizations: "$_id", name: 1, count: 1 } },
            { $sort: sort },
            { $limit: limit },
            { $skip: skip },
          ],
        },
      },
    ];
    if (companyFilters && companyFilters.length > 0) {
      atlasQuery[2]["$facet"]["project_count"].splice(0, 0, {
        $unwind: "$organizations",
      });
      atlasQuery[2]["$facet"]["project_count"].splice(1, 0, {
        $match: { $and: companyFilters },
      });
      atlasQuery[2]["$facet"]["org_count"].splice(0, 0, {
        $unwind: "$organizations",
      });
      atlasQuery[2]["$facet"]["org_count"].splice(1, 0, {
        $match: { $and: companyFilters },
      });
      atlasQuery[2]["$facet"]["organizations"].splice(3, 0, {
        $match: { $and: companyFilters },
      });
    }

    /*
[
	    {
        "$match": {
            "$and" : searchFilters
        }
	    },
	    { "$project" : {
	        "_id": 0, "organizations": 1
	    }},
	    { "$unwind" : "$organizations"},
	    {
		"$group": {
			"_id": "$organizations",
			"count" : { "$sum" : 1}
		}},
	    {
	        "$project" : { "_id" : 0,  "organizations" : "$_id",  "count" : 1 }
	    },
	    {"$sort" : { "count" : -1}},
	    {"$limit": parseInt(rowsPerPage)},
	    {"$skip" : parseInt(start)}
	]
*/
    let axiosData = {
      url: buildingUrl + "aggregate-search",
      method: "post",
      query: atlasQuery,
    };

    _axiosCall(axiosData)
      .then((groupedResults) => {
        this._getCompanies(groupedResults);
        this.setState((prevState) => ({
          numeric: false,
        }));
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _getCompanies = async (groupedResults) => {
    let companyProjects = {};

    let companyKeys = [];

    if (
      !groupedResults ||
      !groupedResults[0] ||
      !groupedResults[0].organizations ||
      groupedResults[0].organizations.length <= 0 ||
      !groupedResults[0].org_count
    ) {
      this.setState({
        groupedData: [],
        fetchInProgress: false,
        groupedCount: 0,
      });
      return false;
    }

    groupedResults[0].organizations.forEach((item) => {
      companyKeys.push(item.organizations.key);
      companyProjects[item.organizations] = item.count;
    });

    const { buildingStages, type, start, rowsPerPage, page, orderBy, order } =
      this.state;
    // let mongoQuery = [
    //   {
    //     $search: {
    //       "compound": {
    //           "mustNot": [
    //               {
    //                   "exists": {
    //                       "path": "meta.deleted"
    //                   }
    //               }
    //           ]
    //       },
    //       "queryString": {
    //          "defaultPath" : "key",
    //          "query" : `("${companyKeys.join('" OR "')}")`
    //       }
    //     },
    //   },
    //   { $limit: parseInt(rowsPerPage) },
    // ];
    let mongoQuery = [
      {
        $search: {
          compound: {
            "mustNot": [
                {
                    "exists": {
                        "path": "meta.deleted"
                    }
                }
            ],
             must: [
               {
               "queryString": {
                   "defaultPath" : "key",
                   "query": `(${companyKeys.join(" ")})`
               }
               }
             ]
            }
        },
      },
      {
        $project: {
          address: 1,
          date_added: 1,
          roles: 1,
          project_roles: 1,
          key: 1,
          logo: 1,
          name: 1,
          slug: 1,
          _id: 1,
          categories: 1,
          "meta.source_namespace" : 1
        },
      },
      { $limit: parseInt(rowsPerPage) }
    ];

    let axiosData = {
      url: buildingUrl + "aggregate/organizations",
      method: "post",
      query: mongoQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        groupedResults[0].organizations.forEach((item) => {
          let org = res.filter((o) => o.key == item.organizations.key)[0];
          item = Object.assign(item, org);
          //item.project_count = companyProjects[item.key] ? companyProjects[item.key] : item.project_count;
        });

        this.setState({
          groupedData: groupedResults[0].organizations,
          fetchInProgress: false,
          groupedCount: groupedResults[0].org_count[0].count,
          projectCount: groupedResults[0].project_count[0].count,
        });
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _filterTableByInput = (name, eValue) => {
    console.log("_filterTableByInput name", name, "eValue", eValue);
    console.log("_filterTableByInput name", name, "eValue", eValue);
    this.setState({ searchText: eValue });
    // if(this.state.view === 'grid') {
    //this._filterResponseTableData(eValue);
    // } else {
    // 	this._filterColumn(name, eValue);
    // }
  };

  _filterResponseTableData = (eValue) => {
    console.log("_filterResponseTableData eValue", eValue);
    if (
      !this.state.tableData ||
      (this.state.tableData && this.state.tableData.length === 0)
    ) {
      return;
    }

    const filteredData =
      eValue.length > 0
        ? this.state.tableData.filter((item) => {
            // console.log("filterData item", item)
            const inputVal = eValue.toLowerCase();
            let nameVal, adrVal, hoodVal;
            if (item.name) {
              // console.log('item has name')
              nameVal = item.name.toLowerCase();
            }

            if (
              (item.display_full_street_address &&
                _shouldShow(item.display_full_street_address)) ||
              (item.full_street_address &&
                _shouldShow(item.full_street_address))
            ) {
              const field = item.display_full_street_address
                ? "display_full_street_address"
                : "full_street_address";
              // console.log('item has adr', field)

              adrVal = item[field].toLowerCase();
            }

            if (item.neighborhood && _shouldShow(item.neighborhood)) {
              const hood =
                Array.isArray(item.neighborhood) &&
                _shouldShow(item.neighborhood[0])
                  ? item.neighborhood[0]
                  : item.neighborhood;
              hoodVal = hood.toLowerCase();
            }

            // console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
            // searching name and ONE adr field - could update to search both display and full and all alt adrs
            return (
              (nameVal && nameVal.indexOf(inputVal) >= 0) ||
              (adrVal && adrVal.indexOf(inputVal) >= 0) ||
              (hoodVal && hoodVal.indexOf(inputVal) >= 0)
            );
          })
        : this.state.tableData;

    console.log(
      "filteredData",
      filteredData,
      "this.state.tableData",
      this.state.tableData
    );
    this.setState((prevState) => ({
      // buildingsFetchInProgress: false,
      reactTableFiltered: filteredData,
      //tableData: filteredData,
      //originalData: response,
      //filterFetchInProgress:false
    }));
  };

  _filterColumn = (columnId, value, display) => {
    const newfilter = {
      display: display,
      id: columnId,
      value,
    };

    const filtersWhithoutNew = this.state.reactTableFiltered.filter(
      (item) => item.id !== columnId
    );
    this.setState({
      reactTableFiltered: [...filtersWhithoutNew, newfilter],
    });
  };

  _setSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, this.state.subFilters, {
      [search_key]: search_val,
    });
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });

    setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
  };

  _setBuildingStage = (stage) => {
    let newStages = this.state.buildingStages.slice();
    const index = newStages.indexOf(stage);
    const indexAll = newStages.indexOf("all");

    if (index >= 0) {
      if (stage === "all") {
        newStages = ["sponsor"];
      } else {
        newStages.splice(index, 1);
      }
    } else {
      if (stage === "all") {
        newStages = ["all"];
      } else {
        if (indexAll >= 0) {
          newStages.splice(indexAll, 1);
        }
        newStages.push(stage);
      }
    }
    if (newStages.length === 0) newStages = ["all"];
    this.setState({
      buildingStages: newStages,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(this.lsPre, buildingStagesKey, JSON.stringify(newStages));
  };

  __setSubFilters = (subFilters) => {
    const subF = Object.assign({}, this.state.subFilters, subFilters);
    this.setState({
      subFilters: subF,
      start: 0,
      numeric: true,
      end: this.state.rowPerPage,
    });

    setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
  };

  _resetSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, defaultSubFilter);
    this.setState({
      buildingStages: defaultStage,
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    deleteLocalStorage(this.lsPre, buildingStagesKey);
    deleteLocalStorage(this.lsPre, subFilterKey);
  };

  _resetActive = () => {
    let isDefault =
      this.props.searchTags.length === 0 &&
      _objectEqual(defaultSubFilter, this.state.subFilters) &&
      _objectEqual(defaultStage, this.state.buildingStages);
    return !isDefault;
  };

  _setBorough = (search_val) => {
    this.setState({
      filterBorough: search_val,
      start: 0,
      end: this.state.rowPerPage,
    });
  };

  _toggleView = (type) => {
    this.setState({ viewType: type });
  };
  _selectUnit(property) {
    const { selectedItems } = this.state;
    const newSelected = Object.assign({}, selectedItems);

    if (newSelected[property.key]) {
      delete newSelected[property.key];
    } else {
      newSelected[property.key] = property;
    }

    this.setState({ selectedItems: Object.assign({}, newSelected) });
  }
  _clearSelect() {
    this.setState({ selectedItems: {} });
  }

  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      tableData,
      reactTableFiltered,
      subFilters,
      totalCount,
      viewType,
      buildingsFetchInProgress,
      type,
      fetchInProgress,
      groupedData,
      groupedCount,
      projectCount,
      selectedItems,
    } = this.state;
    const { user, isSingle, min, toggle } = this.props;
    const tableCount = groupedCount;
    const paginationCount = tableCount;
    if (min) {
      return (
        <div>
          <Row>
            <MatGridView
              {...this.state}
              {...this.props}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              isSelected={this.isSelected}
              handleBuildingClick={this.handleBuildingClick}
              handleSelectAllClick={this.handleSelectAllClick}
              handleRequestSort={this.handleRequestSort}
              noPagination
            />
          </Row>
          <Row>
            <Col xl={12} lg={12} className="mt-3 text-center">
              <div className="timeline_load">
                <Tooltip title="See all projects">
                  <Button
                    onClick={() => toggle("projects")}
                    variant="contained"
                    color="news"
                    className={`timeline__load-more`}
                    startIcon={<LoadMoreIcon />}
                  >
                    {" "}
                    See All Projects
                  </Button>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <Fragment>
        <MergeContactsButton
          selectedItems={selectedItems}
          clearSelected={this._clearSelect.bind(this)}
          type={"companies"}
        />
        {!isSingle && (
          <Row>
            <Col md={12} className={"mb-3 text-right"}>
              <EditOrganization noAssociation add />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody style={{ padding: 0 }}>
                {/*<div className="card__title">
		              <h5 className="bold-text">Select and Analyze</h5>
		            </div>*/}
                <MatAppBar
                  handleSearch={this._filterTableByInput}
                  numSelected={[...selected].filter((el) => el[1]).length}
                  handleDeleteSelected={this.handleDeleteSelected}
                  onRequestSort={this.handleRequestSort}
                  subFilters={subFilters}
                  resetSubFilter={this._resetSubFilter}
                  buildingStages={this.state.buildingStages}
                  setBuildingStage={this._setBuildingStage}
                  setSubFilter={this._setSubFilter}
                  _filters={
                    user && user.role.indexOf("admin") >= 0
                      ? this.buildingSubFilters
                      : this.buildingSubFilters.filter(
                          (item) => !item.admin_only
                        )
                  }
                  placeHolder={"Company Name or Project Location"}
                  buildingAttributes={[]}
                  goToBuilding={true}
                  hideBuilding={true}
                  resetActive={this._resetActive()}
                  type={type}
                  q={this.q}
                  fq={this.fq}
                  fl={buildingExportFL}
                  sort={this.sort}
                  totalCount={totalCount}
                  hidePeople
                  hideProjects
                  addBuilding
                  allowTextSearch
                  setSubFilters={this.__setSubFilters}
                />
                {/* <div style={{padding:"0 10px",clear:"both"}}>
		            	{buildingsFetchInProgress ? <LinearLoading /> : <div style={{height:isMobile ? 0 : 20}} />}
					</div>	*/}
                <div className="material-table__header-info">
                  <Row>
                    <Col md={6} lg={6}>
                      <div
                        className={"count_subheader"}
                        style={{ marginTop: 10 }}
                      >
                        <span className="bold-text">
                          {tableCount > 0 ? _formatNumber(tableCount) : "--"}
                        </span>
                        {` `}Companies Across{" "}
                        <span className="bold-text">
                          {projectCount > 0
                            ? _formatNumber(projectCount)
                            : "--"}
                        </span>
                        {` `}Projects
                      </div>
                    </Col>
                    <Col md={6} lg={6} style={{ textAlign: "right" }}>
                      {/*<span>
								  
								  <GridViewIcon color="#DBE0E8" />		
						          <Tooltip title={`${this.state.gridView ? "Switch to List View" : "Switch to Grid View"}`}>
							        <PurpleSwitch 
							            checked={!this.state.gridView}
							        	onChange={this._toggleView}
							        />
							      </Tooltip>			
							      <ListViewIcon color="#DBE0E8" />		      	
							      		       
							    </span>*/}

                      {/* <ButtonGroup size="small" aria-label="small outlined button group">
	
							        	
							        	<Tooltip title={`See projects in a photo grid`}>
							        		<Button disabled={viewType=="grid"} onClick={() => {this._toggleView("grid")}} startIcon={<GridViewIcon size={14} color={viewType!="grid" ? "#780F9E" : "#A3ADC2"} />}>Grid</Button>
							        	</Tooltip>
							        	<Tooltip title={`See projects in a list`}>
							        		<Button disabled={viewType=="list"} onClick={() => {this._toggleView("list")}} startIcon={<ListViewIcon size={14} color={viewType!="list" ? "#780F9E" : "#A3ADC2"} />}>List</Button>
							        	</Tooltip>
							        	<Tooltip title={`See projects on a map`}>
							        		<Button disabled={viewType=="map"} onClick={() => {this._toggleView("map")}} startIcon={<MapViewIcon size={14} color={viewType!="map" ? "#780F9E" : "#A3ADC2"} />}>Map</Button>
							        	</Tooltip>
  
								</ButtonGroup>
							    <span className="small-divider" style={{marginLeft:15}}>|</span>
								<MatTableFilterButton handleRequestSort={this.handleRequestSort} type={type} />
								*/}
                    </Col>
                  </Row>
                </div>

                <div className="material-table__wrap">
                  <div style={{ height: 10 }}>
                    {fetchInProgress ? (
                      <LinearLoading />
                    ) : (
                      <div style={{ margin: 8 }} />
                    )}
                  </div>
                  {groupedData && (
                    <div className="material-table expansion__table">
                      <Container>
                        <Row className="expansion__summary__header">
                          <Col lg={1} xs={2}></Col>
                          <Col lg={2} xs={12}>
                            <TableSortLabel
                              active={orderBy === "count"}
                              direction={order}
                              onClick={(event) =>
                                this.handleRequestSort(event, "count")
                              }
                              className="material-table__sort-label"
                              dir="ltr"
                            >
                              Projects
                            </TableSortLabel>
                          </Col>
                          <Col lg={3} className="no-padding">
                            <TableSortLabel
                              active={orderBy === "name"}
                              direction={order}
                              onClick={(event) =>
                                this.handleRequestSort(event, "name")
                              }
                              className="material-table__sort-label"
                              dir="ltr"
                            >
                              Company
                            </TableSortLabel>
                          </Col>
                          <Col lg={2} className="no-padding">
                            Category / Role
                          </Col>

                          <Col lg={2}>Location</Col>

                          <Col lg={2} className="no-padding">
                            Date Added
                          </Col>
                        </Row>
                      </Container>
                      {groupedData.length === 0 && (
                        <Container>
                          <Row className="no__data">
                            <Col lg={12} xs={12} className="no__data-text">
                              No data found for your search.
                            </Col>
                          </Row>
                        </Container>
                      )}
                      {groupedData.map((d) => {
                        const item = d;
                        const numFound = item.project_count;
                        const isSelected = this.isSelected(item.val);
                        const roles = [];
                        if (
                          d &&
                          d.project_roles &&
                          Object.keys(d.project_roles).length > 0
                        ) {
                          Object.keys(d.project_roles).forEach((key) => {
                            const p = d.project_roles[key];
                            p.map((r) => {
                              r =
                                r === "applicant"
                                  ? "permit applicant"
                                  : r === "filing_applicant"
                                  ? "permit applicant"
                                  : r;
                              if (roles.indexOf(r) < 0) {
                                roles.push(r);
                              }
                            });
                          });
                        }
                        return (
                          <ExpansionPanel
                            TransitionProps={{ unmountOnExit: true }}
                            id={item.val}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-label="Expand"
                              aria-controls=""
                              id={d.groupValue}
                            >
                              <Container>
                                <Row className="expansion__summary">
                                  <Col lg={1} xs={2}>
                                    {user && user.role.indexOf("admin") >= 0 && (
                                      <Checkbox
                                        checked={
                                          selectedItems[d.key] === undefined
                                            ? false
                                            : true
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this._selectUnit(d);
                                        }}
                                        className="material-table__checkbox"
                                      />
                                    )}
                                  </Col>
                                  <Col lg={2} xs={12}>
                                    <Badge color="primary">
                                      <span className="bold-text">
                                        {item.count}
                                      </span>{" "}
                                      Projects
                                    </Badge>
                                  </Col>
                                  <Col lg={3} xs={12} className="no-padding">
                                    
                                    <div className="">
                                      <Link
                                        className={
                                          "expansion__header building_name"
                                        }
                                        to={`/company/${item["key"]}`}
                                        style={{ marginRight: 3 }}
                                      >
                                        {item.name
                                          ? _capitalizeText(item.name)
                                          : "--"}
                                      </Link>
                                    </div>
                                    <span className="light-text small-text"></span>
                                    {isMobile && <div className="mb-2"/>}
                                  </Col>
                                  <Col lg={2} xs={7} className="no-padding">
                                    <span
                                      className={"light-text small-text mb-1"}
                                    ></span>
                                    {d &&
                                    d.categories &&
                                    d.categories.length > 0 ? (
                                      <span className="light-text small-text">
                                        {d.categories
                                          .map((u) => _capitalizeText(u))
                                          .join(", ")}
                                      </span>
                                    ) : roles.length > 0 ? (
                                      <span className="light-text small-text">
                                        {roles
                                          .map((u) =>
                                            _capitalizeText(
                                              u.toString().replace("_", " ")
                                            )
                                          )
                                          .join(", ")}
                                      </span>
                                    ) : (
                                      <span className="light-text small-text">
                                        --
                                      </span>
                                    )}
                                  </Col>
                                  <Col lg={2} xs={5}>
                                    <span
                                      className={
                                        "mobile__show light-text small-text"
                                      }
                                    >
                                      Location:
                                    </span>
                                    {isMobile && <br />}
                                    {d.address && d.address.city ? (
                                      <span className="light-text small-text">
                                        {d.address && d.address.city
                                          ? `${_capitalizeText(d.address.city)}`
                                          : ""}
                                        {d.address && d.address.state
                                          ? `, ${
                                              d.address.state.length > 2
                                                ? _capitalizeText(
                                                    d.address.state
                                                  )
                                                : d.address.state
                                                    .toString()
                                                    .toUpperCase()
                                            }`
                                          : ""}
                                      </span>
                                    ) : (
                                      <span className="light-text small-text">
                                        --
                                      </span>
                                    )}
                                  </Col>

                                  <Col lg={2} xs={12} className="no-padding">
                                    {isMobile && <div style={{ height: 10 }} />}
                                    <span
                                      className={
                                        "mobile__show light-text small-text"
                                      }
                                    >
                                      Date Added:
                                    </span>
                                    <span className="light-text small-text">
                                      {moment(d.date_added).year() > 1970
                                        ? _capitalizeText(
                                            moment(d.date_added).fromNow()
                                          )
                                        : "--"}
                                    </span>
                                  </Col>
                                </Row>
                              </Container>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <UnitsListTable
                                isSelected={this.isSelected}
                                handleBuildingClick={this.handleBuildingClick}
                                handleClick={this.handleClick}
                                handleRequestSort={this.handleRequestSort}
                                handleSelectAllClick={this.handleSelectAllClick}
                                {...this.state}
                                {...this.props}
                                reactTableFiltered={false}
                                isGrouped
                                q={this.q}
                                fq={this.unitFq}
                                fl={this.fl}
                                companyKey={item.key}
                                maxCount={item.count}
                              />
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        );
                      })}
                    </div>
                  )}
                </div>
                {paginationCount ? (
                  <TablePagination
                    component="div"
                    className="material-table__pagination"
                    count={paginationCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                    dir="ltr"
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default connect((state) => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags)
    ? state.search.searchTags.slice()
    : [],
}))(withRouter(MatTable));
