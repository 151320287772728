import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import UnitsSearchAppBar from "./UnitsSearchAppBar";
import UnitsListTable from "./UnitsListTable";
import LinearLoading from "../../../shared/components/LinearLoading";
import { isMobile } from "react-device-detect";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _formatBed,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  syncGenericFilter,
  _objectEqual,
  getLocalStorage,
} from "../../../shared/helpers/utils";

import { _pipeLineFilter } from "../../../shared/helpers/filters";
import { _hasPermission } from "../../../shared/helpers/access";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import MapViewIcon from "mdi-react/MapOutlineIcon";
import SalesMapView from "../../../shared/components/map/SalesMapView";
import { getBoundFromUrl } from "../../../shared/helpers/mapUtil";
import BuildingDetail from "../../BuildingDetail";

am4core.useTheme(am4themes_animated);

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#A3ADC2",

    "&$checked": {
      color: "#A3ADC2",
    },
    "&$checked + $track": {
      backgroundColor: "#D1D6E0",
    },
  },
  checked: {},
  track: { backgroundColor: "#D1D6E0" },
})(Switch);

const buildingUrl = process.env.API_ROOT + "/query/";
const lsPre = "FP_";
const subFilterKey = "subFilters";
const gap = syncGenericFilter("gap", lsPre, "gap", "+7DAY");
const defaultGap = gap === "+1MONTH" ? "month" : "week";
const dateStart = "01/05/2019";
const isDST = moment().isDST();
const defaultSubFilter = {};
class UnitsSearchTable extends PureComponent {
  state = {
    order: syncGenericFilter("order", lsPre, "order", "desc"),
    orderBy: syncGenericFilter(
      "order_by",
      lsPre,
      "orderBy",
      "solr_updated_date"
    ),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 25,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    clearInput: false,
    end: 30,
    filterBorough: "all_boroughs",
    searchText: syncURLSearch(this.props, "searchText"),
    futureInventoryData: false,
    inventoryData: false,
    originalData: false,
    ovelayMinHeight: false,
    reactTableFiltered: [],
    showCounts: { desktop: 3, tablet: 2, mobile: 1 },
    start: 0,
    stats: {},

    tableData: false,
    totalUnsoldUnits: false,
    totalCount: 0,
    groupedView: this.props.buildingView ? false : true,
    groupedData: false,
    groupedCount: false,
    buildingKey: false,
    unitListData: false,
    modal: false,
    trxnRes: false,
    startDate: moment(dateStart, "MM/DD/YYYY")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .startOf(defaultGap)
      .add(defaultGap == "month" ? 0 : 1, "day")
      .add(isDST ? -1 : 0, "hour")
      .toDate()
      .toISOString(),
    endDate: moment()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate()
      .toISOString(),
    gap: gap,
    chartData: false,
    dataStart: isMobile
      ? syncGenericFilter(
          "start_date",
          lsPre,
          "dataStart",
          moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .subtract(3, "months")
            .startOf("week")
            .add(1, "day")
            .toDate()
            .toISOString()
        )
      : syncGenericFilter(
          "start_date",
          lsPre,
          "dataStart",
          moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .subtract(1, "years")
            .startOf("week")
            .add(1, "day")
            .toDate()
            .toISOString()
        ),
    dataEnd: syncGenericFilter(
      "end_date",
      lsPre,
      "dataEnd",
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate()
        .toISOString()
    ),
    buildingEventCount: 0,
    selectedDate: syncGenericFilter(
      "selected_date",
      lsPre,
      "selectedDate",
      false
    ),
    eventTypes: syncGenericFilter(
      "event_types",
      lsPre,
      "eventTypes",
      [
        "Construction Permit",
        "Demolition Permit",
        "Offering Plan",
        "Certificate of Occupancy" /* "Offering Accepted", "Offering Effective", "Certificate of Occupancy"*/,
      ],
      true
    ),
    viewType: syncGenericFilter(
      "viewType",
      lsPre,
      "viewType",
      getLocalStorage(lsPre, "viewType") || "chart"
    ),
    mapBounds: getBoundFromUrl(),
    mobileMapMoving: false,
    neighborhoods: [],
    authData: {
      appState: { viewType: "filingmap" },
      authenticated: false,
      idToken: null,
      myFeed: { isMyFeed: false },
      userData: { ...this.props.user },
    },
    width: window.innerWidth,
    height: window.innerHeight,
    boroughs: ["Brooklyn", "Bronx", "Manhattan", "Staten Island", "Queens"],
    majorMarkets: {},
    neighborhoods: [],
    hoodTags: [],
    boroTags: [],
    preUrl: "",
    mapLayerZoom: false,
    subFilters: syncURLFilters(
      this.props,
      _pipeLineFilter,
      subFilterKey,
      lsPre
    ),
  };

  defaultQ = "*:*";
  q = "";
  fq = ["hierarchy:[0 TO 1]"];
  unitFq = null;
  //"(-actor:permits-dobnow) OR (actor:permits-dobnow AND id:permits-dobnow*I1)"
  chartLoaded = false;

  fl = buildingFL;
  chart;
  _unitSubFilter = _pipeLineFilter.filter(
    (filter) => !(this.props.buildingView && filter.building_hide)
  );
  timeout = null;
  _matchedBuildingIds = [];
  formattedData;
  tempEventTypes = this.state.eventTypes.slice(0);
  noDataIndicator;
  componentDidMount() {
    window.addEventListener("resize", this._handleWindowSizeChange);
    this._getBuildings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let subFilterChanged =
      this._unitSubFilter.filter((item) => {
        return Array.isArray(item.search_key)
          ? item.search_key.filter(
              (key) => this.state.subFilters[key] !== prevState.subFilters[key]
            ).length > 0
          : this.state.subFilters[item.search_key] !==
              prevState.subFilters[item.search_key];
      }).length > 0;

    if (
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.searchText !== prevState.searchText ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.page !== prevState.page ||
      this.state.gap !== prevState.gap ||
      this.state.viewType !== prevState.viewType ||
      this.state.groupedView !== prevState.groupedView ||
      (this.state.buildingKey !== prevState.buildingKey &&
        this.state.buildingKey !== false) ||
      searchTagsChange ||
      subFilterChanged
    ) {
      const resetPage = this.state.page === prevState.page;

      this._getBuildings(resetPage);
    } else if (
      this.state.dataStart !== prevState.dataStart ||
      this.state.dataEnd !== prevState.dataEnd ||
      this.state.selectedDate !== prevState.selectedDate ||
      !_isEqual(this.state.eventTypes, prevState.eventTypes)
    ) {
      this._getEvents(null, (res) => {
        this.setState((prevState) => ({
          stats: res.facets,
          buildingsFetchInProgress: false,
        }));
      });
    }
  }

  componentWillUnmount() {
    console.log("LS unmounting");
    window.removeEventListener("resize", this._handleWindowSizeChange);
    
  }
  buildChart = (data) => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.scrollbarX = new am4core.Scrollbar();
    // Add data
    const { user } = this.props;
    const { eventTypes, selectedDate } = this.state;

    chart.data = data;

    function showIndicator(noDataIndicator) {
      if (noDataIndicator) {
        noDataIndicator.show();
      } else {
        noDataIndicator = chart.tooltipContainer.createChild(am4core.Container);
        noDataIndicator.background.fill = am4core.color("#fff");
        noDataIndicator.background.fillOpacity = 0.8;
        noDataIndicator.width = am4core.percent(100);
        noDataIndicator.height = am4core.percent(100);

        var indicatorLabel = noDataIndicator.createChild(am4core.Label);
        indicatorLabel.text = "No data found for your search.";
        indicatorLabel.align = "center";
        indicatorLabel.valign = "middle";
        indicatorLabel.fontSize = 14;
      }
    }

    function hideIndicator(noDataIndicator) {
      noDataIndicator.hide();
    }

    function highLightColumn(categoryX) {
      chart.series.each(
        function (series) {
          series.columns &&
            series.columns.values.forEach((c) => {
              if (c.dataItem.categoryX == categoryX) {
                c.isActive = true;
              }
            });
        }.bind(this)
      );
    }

    chart.events.on(
      "beforedatavalidated",
      function (ev) {
        // check if there's data
        if (ev.target.data.length == 0) {
          showIndicator(this.noDataIndicator);
        } else if (this.noDataIndicator) {
          hideIndicator(this.noDataIndicator);
        }
      }.bind(this)
    );

    chart.events.on(
      "ready",
      function (ev) {
        if (selectedDate) {
          const columnX = this.getIntervalName(selectedDate);
          highLightColumn(columnX);
        }
        this.chartLoaded = true;
      }.bind(this)
    );

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 290;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;

    const slideChange = (ev) => {
      if (this.timeout) clearTimeout(this.timeout);

      const { dataStart, dataEnd } = this.state;
      let axis = ev.target;
      let start = axis.getPositionLabel(axis.start);
      let end = axis.getPositionLabel(axis.end);
      let formattedStart;
      let formattedEnd;
      if (this.state.gap === "+1MONTH") {
        let newStart = moment(start, "MMM, YYYY").format("MM/DD/YY");
        formattedStart = moment(newStart + "T00:00", "M/D/YYTHH:mm");
        let newEnd = moment(end, "MMM, YYYY").format("MM/DD/YY");
        formattedEnd =
          moment().diff(moment(newEnd + "T00:00", "M/D/YYTHH:mm")) < 0
            ? moment()
            : moment(newEnd + "T00:00", "M/D/YYTHH:mm");
      } else {
        formattedStart = moment(start + "T00:00", "M/D/YYTHH:mm");
        formattedEnd =
          moment().diff(moment(end + "T00:00", "M/D/YYTHH:mm")) < 0
            ? moment()
            : moment(end + "T00:00", "M/D/YYTHH:mm");
      }

      if (start && end && (dataStart !== start || dataEnd !== end)) {
        this.timeout = setTimeout(
          function (start, end, series) {
            //
            if (this.chartLoaded) {
              chart.series.each(function (series) {
                series.columns.values.forEach((c) => (c.isActive = false));
              });
              this.setState({
                dataStart: formattedStart.toDate().toISOString(),
                dataEnd: formattedEnd.toDate().toISOString(),
                selectedDate: false,
                eventTypes: this.tempEventTypes.slice(0),
              });
              this._setSubFilters({
                start_date: formattedStart.toDate().toISOString(),
                end_date: formattedEnd.toDate().toISOString(),
              });
            }
          }.bind(this, start, end, chart),
          300
        );
      }
    };

    categoryAxis.events.on("endchanged", slideChange);
    categoryAxis.events.on("startchanged", slideChange);

    categoryAxis.start = isMobile ? 0.875 : 0.5;
    categoryAxis.end = 1;
    categoryAxis.keepSelection = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    const createSeries = (field, name) => {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "time";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 300;
      series.hiddenState.transitionDuration = 300;
      series.interpolationDuration = 10;

      // Make it stacked
      series.stacked = true;

      series.showOnInit = false;

      // Configure columns
      series.columns.template.width = am4core.percent(80);
      series.columns.template.tooltipText =
        "{categoryX}\n[font-size:14px]{name}: [bold]{valueY}[/]";

      series.columns.template.column.cornerRadiusTopLeft = 0;
      series.columns.template.column.cornerRadiusTopRight = 0;
      series.columns.template.column.fillOpacity = 0.8;
      series.columns.template.strokeWidth = 0;

      series.columns.template.column.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;

      var hoverState = series.columns.template.column.states.create("hover");
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      let activeColor = "#780F9E";
      if (field === "offering_accepted") activeColor = "#780F9E";
      if (field === "offering_effective") activeColor = "#5C1F7A";
      if (field === "new_building_filed") activeColor = "#420F6B";
      if (field === "co_received") activeColor = "#4ce1b6";
      if (field === "demo_filed") activeColor = "#28C2FF";

      //series.columns.template.column.togglable=true;
      var activeState = series.columns.template.states.create("active");
      activeState.properties.fill = am4core.color(activeColor);
      activeState.dx = 6;
      activeState.dy = 6;
      activeState.opacity = 0.3;

      series.columns.template.events.on(
        "hit",
        function (ev) {
          const { dataStart, dataEnd, gap } = this.state;
          console.log(ev);
          chart.series.each(function (series) {
            series.columns.values
              .filter((c) => c !== ev.target)
              .forEach((c) => (c.isActive = false));
          });

          ev.target.isActive = !ev.target.isActive;

          if (ev.target.isActive) {
            const columnX = ev.target.dataItem.categoryX;
            highLightColumn(columnX);

            let start = ev.target.dataItem.categories.categoryX;
            let end =
              gap === "+1MONTH"
                ? this.getIntervalTime(start).add(1, "month")
                : this.getIntervalTime(start).add(1, "week");

            const formattedStart =
              gap === "+1MONTH"
                ? this.getIntervalTime(start).startOf("month")
                : this.getIntervalTime(start).startOf("week").add(1, "day");
            let type = ev.target.dataItem.component.dataFields.valueY;
            const translateType = {
              new_building_filed: "Construction Permit",
              offering_effective: "Offering Effective",
              offering_accepted: "Offering Acceptetd",
              offering_submitted: "Offering Plan",
              co_received: "Certificate of Occupancy",
              demo_filed: "Demolition Permit",
            };

            if (dataStart !== start || dataEnd !== end) {
              setTimeout(
                function (start, end, series, translateType) {
                  this.setState({
                    selectedDate: formattedStart.toDate().toISOString(),
                    //eventTypes:[translateType[type]]
                  });
                }.bind(this, start, end, series, translateType),
                100
              );
            }
          } else {
            this.setState({
              selectedDate: false,
              //eventTypes: this.tempEventTypes.slice(0)
            });
          }
        }.bind(this)
      );

      let color = "#FF691E";
      if (field === "offering_accepted") color = "#FF206E";
      if (field === "offering_effective") color = "#82012e";
      if (field === "new_building_filed") color = "#FFD23F";
      if (field === "demo_filed") color = "#28C2FF";
      if (field === "co_received") color = "#4ce1b6";

      series.columns.template.adapter.add(
        "fill",
        function (fill, target) {
          //return chart.colors.getIndex(target.dataItem.index);

          return am4core.color(color);
        }.bind(this)
      );

      if (eventTypes.indexOf(name) < 0) {
        series.hidden = true;
        series.hide();
      }

      return series;
    };

    createSeries("demo_filed", "Demolition Permit");
    createSeries("new_building_filed", "Construction Permit");
    createSeries("offering_submitted", "Offering Plan");
    createSeries("co_received", "Certificate of Occupancy");
    //createSeries("offering_accepted", "Offering Accepted");
    //createSeries("offering_effective", "Offering Effective");
    //createSeries("co_received", "Certificate of Occupancy");

    /*
		var series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = "offering_submitted";
		series.dataFields.categoryX = "time";
		series.tooltipText = "Week of {categoryX} - [{categoryX}: bold]{valueY}[/] Listings";
		series.columns.template.strokeWidth = 0;
		
		series.tooltip.pointerOrientation = "vertical";
		
		series.columns.template.column.cornerRadiusTopLeft = 0;
		series.columns.template.column.cornerRadiusTopRight = 0;
		series.columns.template.column.fillOpacity = 0.8;
		
		series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
*/

    // on hover, make corner radiuses bigger

    // Cursor
    chart.cursor = new am4charts.XYCursor();
    chart.exporting.menu = new am4core.ExportMenu();
    chart.zoomOutButton.background.fill = am4core.color("#780F9E");
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#9600BF");

    // Legend
    chart.legend = new am4charts.Legend();
    var markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;

    chart.legend.itemContainers.template.events.on(
      "hit",
      function (ev) {
        const { eventTypes } = this.state;
        const type = ev.target.dataItem.name;
        chart.series.each(function (series) {
          series.columns.values.forEach((c) => (c.isActive = false));
        });
        setTimeout(
          function (eventTypes, type) {
            console.log(eventTypes, type);
            if (this.tempEventTypes.indexOf(type) >= 0) {
              this.tempEventTypes = this.tempEventTypes
                .filter((item) => item !== type)
                .slice(0);
              this.setState({ eventTypes: this.tempEventTypes.slice(0) });
              setLocalStorage(
                lsPre,
                "eventTypes",
                this.tempEventTypes.slice(0)
              );
            } else {
              this.tempEventTypes = [...this.tempEventTypes, ...[type]];
              this.setState({ eventTypes: this.tempEventTypes.slice(0) });
              setLocalStorage(
                lsPre,
                "eventTypes",
                this.tempEventTypes.slice(0)
              );
            }
          }.bind(this, eventTypes, type),
          100
        );
        //let seriesColumn = ev.target.dataItem.dataContext.columns.template;
      }.bind(this)
    );

    chart.exporting.menu.items = [
      {
        label: "Export",
        menu: [
          { type: "png", label: "Image (PNG)" },
          { type: "pdf", label: "PDF" },
          // 		    { "type": "csv", "label": "Data (CSV)" },
        ],
      },
    ];
    if (_hasPermission(user, "data-export")) {
      chart.exporting.menu.items[0].menu.push({
        type: "csv",
        label: "Data (CSV)",
      });
    }
    chart.exporting.filePrefix =
      "Marketproof Pipeline - Permit and Offering Activity";
    this.chart = chart;
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleBuildingClick = (event, building) => {
    const buildingURL = _getBuildingURL(building);
    this.props.history.push(buildingURL);
  };

  handleGroupedBuildingClick = (event, building) => {
    event.stopPropagation();
    console.log(building);
    const buildingURL = _getBuildingURL(building, "key");

    this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  handleExpandPanel(e, expanded, buildingKey) {
    console.log(e, expanded, buildingKey);
    if (expanded) {
      this.setState({ buildingKey: buildingKey });
    }
  }

  _getDetailQuery = () => {
    const { dataStart, dataEnd, selectedDate, eventTypes, gap } = this.state;

    const buildingStart = selectedDate
      ? moment(selectedDate)
          .add(defaultGap == "month" ? 0 : 0, "day")
          .toDate()
          .toISOString()
      : dataStart;
    const buildingEnd = selectedDate
      ? gap === "+1MONTH"
        ? moment(selectedDate)
            .add(1, "month")
            .add(-1, "hour")
            .toDate()
            .toISOString()
        : moment(selectedDate)
            .add(1, "week")
            .add(0, "day")
            .add(-1, "hour")
            .toDate()
            .toISOString()
      : dataEnd;

    const detailQuery = eventTypes
      .map((type) => {
        switch (type) {
          case "Offering Plan":
            return `(topic:offeringplans AND time:[${buildingStart} TO ${buildingEnd}])`;
            break;
          case "Offering Accepted":
            return `(topic:offeringplans AND event_tags:accepted AND time:[${buildingStart} TO ${buildingEnd}])`;
            break;
          case "Offering Effective":
            return `(topic:offeringplans AND event_tags:effective AND time:[${buildingStart} TO ${buildingEnd}])`;
            break;
          case "Construction Permit":
            return `(topic:permits AND event_tags:(nb a1) AND event_tags:"permit-filing" AND time:[${buildingStart} TO ${buildingEnd}])`;
            break;
          case "Demolition Permit":
            return `(topic:permits AND event_tags:(dm) AND event_tags:"permit-filing" AND time:[${buildingStart} TO ${buildingEnd}])`;
            break;
          case "Certificate of Occupancy":
            return `(topic:coo AND -event_tags:"public-housing" AND time:[${buildingStart} TO ${buildingEnd}])`;
            break;
        }
      })
      .join(" OR ");

    return detailQuery ? detailQuery : "id:none";
  };

  getIntervalName(date) {
    const { gap } = this.state;
    if (gap === "+1MONTH") {
      return moment(date).add(1, "hour").format("MMM, YYYY");
    }

    return moment(date).add(1, "hour").format("M/D/YY");
  }

  getIntervalTime(date) {
    const { gap } = this.state;
    if (gap === "+1MONTH") {
      return moment(date, "MMM, YYYY");
    }

    return moment(date, "M/D/YY");
  }

  _getBuildings = async (resetPage) => {
    this._getEvents(
      [],
      function (res) {
        let chartData = [];
        if (res && res.facets) {
          let facetData = {};

          if (
            res.facets.offering_submitted &&
            res.facets.offering_submitted.by_time
          ) {
            chartData = res.facets.offering_submitted.by_time.buckets.map(
              (bucket) => {
                const key = this.getIntervalName(bucket.val);
                return { time: key, offering_submitted: bucket.count };
              }
            );
          }

          if (
            res.facets.offering_accepted &&
            res.facets.offering_accepted.by_time
          ) {
            res.facets.offering_accepted.by_time.buckets.forEach((bucket) => {
              const key = this.getIntervalName(bucket.val);
              let existingItem = chartData.filter((item) => item.time === key);
              if (existingItem && existingItem[0]) {
                existingItem[0].offering_accepted = bucket.count;
              } else {
                chartData.push({ time: key, offering_accepted: bucket.count });
              }
            });
          }

          if (
            res.facets.offering_effective &&
            res.facets.offering_effective.by_time
          ) {
            res.facets.offering_effective.by_time.buckets.forEach((bucket) => {
              const key = this.getIntervalName(bucket.val);
              let existingItem = chartData.filter((item) => item.time === key);
              if (existingItem && existingItem[0]) {
                existingItem[0].offering_effective = bucket.count;
              } else {
                chartData.push({ time: key, offering_effective: bucket.count });
              }
            });
          }

          if (
            res.facets.new_building_filed &&
            res.facets.new_building_filed.by_time
          ) {
            res.facets.new_building_filed.by_time.buckets.forEach((bucket) => {
              const key = this.getIntervalName(bucket.val);
              let existingItem = chartData.filter((item) => item.time === key);
              if (existingItem && existingItem[0]) {
                existingItem[0].new_building_filed = bucket.count;
              } else {
                chartData.push({ time: key, new_building_filed: bucket.count });
              }
            });
          }
          if (res.facets.demo_filed && res.facets.demo_filed.by_time) {
            res.facets.demo_filed.by_time.buckets.forEach((bucket) => {
              const key = this.getIntervalName(bucket.val);
              let existingItem = chartData.filter((item) => item.time === key);
              if (existingItem && existingItem[0]) {
                existingItem[0].demo_filed = bucket.count;
              } else {
                chartData.push({ time: key, demo_filed: bucket.count });
              }
            });
          }
          if (res.facets.co_received && res.facets.co_received.by_time) {
            res.facets.co_received.by_time.buckets.forEach((bucket) => {
              const key = this.getIntervalName(bucket.val);
              let existingItem = chartData.filter((item) => item.time === key);
              if (existingItem && existingItem[0]) {
                existingItem[0].co_received = bucket.count;
              } else {
                chartData.push({ time: key, co_received: bucket.count });
              }
            });
          }
        }

        if (this.state.viewType === "chart") this.buildChart(chartData);

        this.setState((prevState) => ({
          fetchInProgress: false,
          buildingsFetchInProgress: false,
          buildingEventCount:
            res.facets && res.facets.by_building
              ? res.facets.by_building.count
              : 0,
          start: resetPage ? 0 : this.state.start,
          page: resetPage ? 0 : this.state.page,
          chartData: chartData,
          stats: res.facets,
        }));
      }.bind(this)
    );
  };

  _getSpecificFilter = (key) => {
    let filter =
      this &&
      this._unitSubFilter &&
      this._unitSubFilter.filter(
        (filter) =>
          filter.search_key === key ||
          (filter.search_key && filter.search_key.includes(key))
      )[0];
    return filter;
  };

  _getEvents = async (_matchedBuildingIds, callback) => {
    if (this.state.buildingsFetchInProgress) return false;
    this.q = "";
    const {
      startDate,
      endDate,
      gap,
      dataStart,
      dataEnd,
      selectedDate,
      eventTypes,
      rowsPerPage,
    } = this.state;

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    const { searchTags } = this.props;

    let locQuery = _tranformTagsQuery(searchTags);

    if (locQuery) {
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += `${locQuery}`;
    }

    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];

        if (subFilterVal && subFilterVal != "") {
          const theFilter = this._unitSubFilter.filter(
            (filter) => filter.search_key === key
          );
          let qVal = null;
          if (theFilter && theFilter[0] && theFilter[0].text_filter) {
            qVal = subFilterVal
              .split(" ")
              .map((term) => {
                return `(${key}:*${term.toLowerCase()}*)`;
              })
              .join(" AND ");

            if (qVal !== null) {
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${qVal})`;
            }
          } else if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
            const qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => subFilterVal.indexOf(value.filter) > -1
              ).length > 0
                ? theFilter[0].values
                    .filter((value) => subFilterVal.indexOf(value.filter) > -1)
                    .map((value) => value.search_value)
                : null;
            if (qVal !== null) {
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${key}:(${qVal.join(" ")}))`;
            }
          } else {
            qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => value.filter === subFilterVal
              ).length > 0
                ? theFilter[0].values.filter(
                    (value) => value.filter === subFilterVal
                  )[0].search_value
                : null;
            if (qVal !== null) {
              if (theFilter[0].target && theFilter[0].target === "fq") {
                this.unitFq.push(qVal);
              } else {
                this.q += this.q.length > 0 ? " AND " : "";
                this.q += `(${key}:${qVal})`;
              }
            }
          }
        }
      });
    }

    let dateQ = "";
    let dateReplaceKeys = [];
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      let query_key,
        queryCount = {};
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];
        const filter = this._getSpecificFilter(key);
        query_key = filter && filter.filter_key;

        if (filter && filter.format_type && !filter.date_filter) {
          queryCount[key] = parseInt(subFilterVal);
          if (query_key in queryCount) {
            queryCount[query_key] += 1;
          } else {
            queryCount[query_key] = 1;
            queryCount[query_key + "Q"] = "";
          }
          const qArrayFilter = this._unitSubFilter.filter(
            (filter) =>
              filter.price_filter &&
              Array.isArray(filter.search_key) &&
              filter.search_key.filter((skey) => skey === key).length > 0
          );
          if (qArrayFilter.length > 0) {
            if (queryCount[query_key + "Q"]) {
              let regex = new RegExp(`{${key}}`, "g");
              if (String(subFilterVal) == "NaN" || subFilterVal == "0") {
                queryCount[query_key + "Q"] = queryCount[
                  query_key + "Q"
                ].replace(regex, "*");
              } else {
                queryCount[query_key + "Q"] = queryCount[
                  query_key + "Q"
                ].replace(regex, subFilterVal);
              }
            } else {
              if (String(subFilterVal) == "NaN" || subFilterVal == "0") {
                queryCount[query_key + "Q"] = qArrayFilter[0].search_value
                  .map((search) => search.replace(`{${key}}`, "*"))
                  .join(" OR ");
              } else {
                queryCount[query_key + "Q"] = qArrayFilter[0].search_value
                  .map((search) => search.replace(`{${key}}`, subFilterVal))
                  .join(" OR ");
              }
            }
          }

          if (
            queryCount[query_key] === 2 &&
            !queryCount[query_key + "Q"].includes("[* TO *]") &&
            !queryCount[query_key + "Q"].includes("[ TO ]")
          ) {
            this.q += this.q.length > 0 ? " AND " : "";
            this.q += "(";
            this.q += queryCount[query_key + "Q"];
            this.q += ")";
          }
        }
        if (subFilterVal && subFilterVal != "") {
          const dateArrayFilter = this._unitSubFilter.filter(
            (filter) =>
              filter.date_filter &&
              Array.isArray(filter.search_key) &&
              filter.search_key.filter((skey) => skey === key).length > 0
          );

          // if (key === "end_date") {
          //   this.setState({ dataEnd: subFilterVal });
          // } else if (key === "start_date") {
          //   this.setState({ dataStart: subFilterVal });
          // }
          if (dateArrayFilter.length > 0) {
            dateReplaceKeys = [
              ...dateReplaceKeys,
              ...dateArrayFilter[0].search_key,
            ];
            if (dateQ) {
              let regex = new RegExp(`{${key}}`, "g");
              dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString());
            } else {
              dateQ = dateArrayFilter[0].search_value
                .map((search) =>
                  search.replace(`{${key}}`, moment(subFilterVal).toISOString())
                )
                .join(" OR ");
            }
          }
        }
      });
    }
    // if (priceQ) {
    //   replaceKeys.map((key) => {
    //     let regex = new RegExp(`{${key}}`, "g");
    //     priceQ = priceQ.replace(regex, "*");
    //   });
    //   this.q += this.q.length > 0 ? " AND " : "";
    //   this.q += "(";
    //   this.q += priceQ;
    //   this.q += ")";
    // }
    // if (dateQ) {
    //   dateReplaceKeys.map((key) => {
    //     let regex = new RegExp(`{${key}}`, "g");
    //     dateQ = dateQ.replace(regex, "*");
    //   });
    //   this.q += this.q.length > 0 ? " AND " : "";
    //   this.q += "(";
    //   this.q += dateQ;
    //   this.q += ")";
    // }
    if (!this.props.history.location.search.includes("BuildingId")) {
      setURLFromState(this.props, this.state);
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      const text = this.state.searchText.replace(/ /g, "*").toLowerCase();
      const textCaps = _capitalizeText(
        this.state.searchText.toLowerCase(),
        false
      ).replace(/ /g, "*");
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += `(display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
    }
    this.q = this.q.length > 0 ? this.q : "*:*";

    const buildingQuery = this._getDetailQuery();

    let axiosData = {
      url: buildingUrl + "we3-activities-3?cache=60",
      method: "post",
      query: {
        q: this.q,
        fq: this.fq,
        wt: "json",
        rows: 0,
        "json.facet": {
          new_building_filed: {
            type: "query",
            q: `topic:permits AND event_tags:(nb a1) AND event_tags:"permit-filing"`,
            facet: {
              by_time: {
                range: {
                  field: "time",
                  start: startDate,
                  end: endDate,
                  gap: gap,
                },
              },
              stats: {
                type: "query",
                q: `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
                facet: {
                  total_units: "sum(units)",
                  by_type: {
                    type: "terms",
                    field: "event_tags",
                    facet: {
                      total_units: "sum(units)",
                    },
                  },
                },
              },
            },
          },
          demo_filed: {
            type: "query",
            q: `topic:permits AND event_tags:(dm) AND event_tags:"permit-filing"`,
            facet: {
              by_time: {
                range: {
                  field: "time",
                  start: startDate,
                  end: endDate,
                  gap: gap,
                },
              },
              stats: {
                type: "query",
                q: `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
                facet: {
                  total_units: "sum(units)",
                  by_type: {
                    type: "terms",
                    field: "event_tags",
                    facet: {
                      total_units: "sum(units)",
                    },
                  },
                },
              },
            },
          },
          offering_submitted: {
            type: "query",
            q: `topic:offeringplans`,
            facet: {
              by_time: {
                range: {
                  field: "time",
                  start: startDate,
                  end: endDate,
                  gap: gap,
                },
              },
              stats: {
                type: "query",
                q: `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
                facet: {
                  total_units: "sum(units)",
                  by_type: {
                    type: "terms",
                    field: "event_tags",
                    facet: {
                      total_units: "sum(units)",
                    },
                  },
                },
              },
            },
          },
          offering_accepted: {
            type: "query",
            q: "topic:offeringplans AND event_tags:accepted",
            facet: {
              by_time: {
                range: {
                  field: "time",
                  start: startDate,
                  end: endDate,
                  gap: gap,
                },
              },
              stats: {
                type: "query",
                q: `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
                facet: {
                  total_units: "sum(units)",
                  by_type: {
                    type: "terms",
                    field: "event_tags",
                    facet: {
                      total_units: "sum(units)",
                    },
                  },
                },
              },
            },
          },
          offering_effective: {
            type: "query",
            q: "topic:offeringplans AND event_tags:effective",
            facet: {
              by_time: {
                range: {
                  field: "time",
                  start: startDate,
                  end: endDate,
                  gap: gap,
                },
              },
              stats: {
                type: "query",
                q: `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
                facet: {
                  total_units: "sum(units)",
                  by_type: {
                    type: "terms",
                    field: "event_tags",
                    facet: {
                      total_units: "sum(units)",
                    },
                  },
                },
              },
            },
          },
          co_received: {
            type: "query",
            q: "topic:coo AND hierarchy:0",
            facet: {
              by_time: {
                range: {
                  field: "time",
                  start: startDate,
                  end: endDate,
                  gap: gap,
                },
              },
              stats: {
                type: "query",
                q: `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
                facet: {
                  total_units: "sum(units)",
                  by_type: {
                    type: "terms",
                    field: "event_tags",
                    facet: {
                      total_units: "sum(units)",
                    },
                  },
                },
              },
            },
          },
          by_building: {
            type: "query",
            q: `${buildingQuery}`,
            facet: {
              unique_projects: "unique(building)",
            },
          },
        },
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (typeof callback === "function") {
          let formattedRes = callback(res);
          return formattedRes;
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _formatStats = (response, redoHiddenFields = false) => {
    if (!response && !redoHiddenFields) {
      return;
    }
  };

  _filterTableByInput = (name, eValue) => {
    console.log("_filterTableByInput name", name, "eValue", eValue);
    this.setState({ searchText: eValue });
  };

  _filterColumn = (columnId, value, display) => {
    const newfilter = {
      display: display,
      id: columnId,
      value,
    };

    const filtersWhithoutNew = this.state.reactTableFiltered.filter(
      (item) => item.id !== columnId
    );
    this.setState({
      reactTableFiltered: [...filtersWhithoutNew, newfilter],
    });
  };

  _setSubFilter = (search_key, search_val) => {
    console.log(search_key, search_val);
    const subF = Object.assign({}, this.state.subFilters, {
      [search_key]: search_val,
    });
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });

    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  };

  _resetSubFilter = (search_key, search_val) => {
    const subF = Object.assign(
      {},
      { start_date: this.state.dataStart, end_date: this.state.dataEnd }
    );
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });

    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  };

  _resetActive = () => {
    let isDefault =
      this.props.searchTags.length === 0 &&
      _objectEqual(
        { start_date: this.state.dataStart, end_date: this.state.dataEnd },
        this.state.subFilters
      );

    return !isDefault;
  };

  _setSubFilters = (subFilters) => {
    const subF = Object.assign({}, this.state.subFilters, subFilters);
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
      dataStart:
        subFilters && subFilters.start_date
          ? moment(subFilters.start_date).toDate().toISOString()
          : this.state.dataStart,
      dataEnd:
        subFilters && subFilters.end_date
          ? moment(subFilters.end_date).toDate().toISOString()
          : this.state.dataEnd,
    });

    setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
  };

  _setBorough = (search_val) => {
    this.setState({
      filterBorough: search_val,
      start: 0,
      end: this.state.rowPerPage,
    });
  };

  _toggleMapView = (type) => {
    this.setState({ viewType: type });
    setURLFromState(this.props, this.state, type);
    setLocalStorage(lsPre, "viewType", type);
  };

  _toggleView = () => {
    const { startDate, dataStart, dataEnd } = this.state;

    const isStartDST = moment(dateStart, "MM/DD/YYYY").isDST();
    if (this.state.gap === "+7DAY") {
      this.setState({
        gap: "+1MONTH",
        selectedDate: false,
        startDate: moment(dateStart, "MM/DD/YYYY")
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .startOf("month")
          .add(0, "day")
          .add(isStartDST !== isDST ? -1 : 0, "hour")
          .toDate()
          .toISOString(),
        endDate: moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate()
          .toISOString(),
        dataStart: isMobile
          ? moment(startDate).startOf("month").toDate().toISOString()
          : moment(dataStart).startOf("month").toDate().toISOString(),
        dataEnd: moment(dataEnd).toDate().toISOString(),
      });
      setLocalStorage(lsPre, "gap", "+1MONTH");
      setLocalStorage(lsPre, "gap", "+7DAY");
      this._setSubFilters({
        start_date: moment(dataStart).startOf("month").toDate().toISOString(),
        end_date: moment(dataEnd).toDate().toISOString(),
      });
    } else {
      this.setState({
        gap: "+7DAY",
        selectedDate: false,
        startDate: moment(dateStart, "MM/DD/YYYY")
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .startOf("week")
          .add(1, "day")
          .add(isStartDST !== isDST ? -1 : 0, "hour")
          .toDate()
          .toISOString(),
        endDate: moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate()
          .toISOString(),
        dataStart: isMobile
          ? moment(dataStart)
              // .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              // .subtract(3, "months")
              .startOf("week")
              // .add(1, "day")
              .toDate()
              .toISOString()
          : moment(dataStart)
              // .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              // .subtract(1, "years")
              // .startOf("week")
              // .add(1, "day")
              .toDate()
              .toISOString(),
        dataEnd: moment(dataEnd)
          // .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate()
          .toISOString(),
      });
      setLocalStorage(lsPre, "gap", "+7DAY");
      this._setSubFilters({
        start_date: moment(dataStart).toDate().toISOString(),
        end_date: moment(dataEnd).toDate().toISOString(),
      });
    }
  };

  _toggleTime = () => {
    if (this.state.soldTimeField === "event_time") {
      this.setState({ soldTimeField: "time", selectedDate: false });
      setLocalStorage(lsPre, "soldTimeField", "time");
    } else {
      this.setState({ soldTimeField: "event_time", selectedDate: false });
      setLocalStorage(lsPre, "soldTimeField", "event_time");
    }
  };

  _mobileMapMove = (mapNotMoving = false) => {
    console.log("_mobileMapMove fired", mapNotMoving);
    if (!mapNotMoving && !this.state.mobileMapMoving) {
      this.setState({ mobileMapMoving: true });
    } else if (mapNotMoving && this.state.mobileMapMoving) {
      this.setState({ mobileMapMoving: false });
    }
  };

  toggle() {
    //this.snackOpen('Your Message Has Been Sent!', 'success');
    this.setState((prevState) => ({ modal: !prevState.modal }));
    this.props.history.push(`/filings${this.state.preUrl}`);
  }

  _setCurrentListing = (urlObj, listing) => () => {
    this.setState({
      modal: true,
      trxnRes: listing,
      preUrl: this.props.history.location.search,
    });
  };

  _handleMapBounds = (mapBounds) => this.setState({ mapBounds, page: 0 });

  _showMapLayer = () => {
    this.setState({ mapLayerZoom: true });
  };
  _hideMapLayer = () => {
    this.setState({ mapLayerZoom: false });
  };

  handleBuildingPreviewClick = (event, building) => {
    const buildingURL = _getBuildingURL(building, "id");

    window.open(buildingURL);
  };

  _handleWindowSizeChange = () => {
    // console.log('handleWindowSizeChange fired', window.innerWidth)
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  render() {
    const {
      selected,
      rowsPerPage,
      reactTableFiltered,
      subFilters,
      fetchInProgress,
      buildingsFetchInProgress,
      searchText,
      dataStart,
      dataEnd,
      selectedDate,
      eventTypes,
      chartData,
      stats,
      viewType,
      modal,
      trxnRes,
      mapLayerZoom,
    } = this.state;
    const emptyRows = rowsPerPage - reactTableFiltered.length;

    const buildingQuery = this._getDetailQuery();
    let containerHeight;
    if (viewType === "filingmap") {
      const header =
        document.getElementsByClassName("topbar").length > 0
          ? document.getElementsByClassName("topbar")[0].offsetHeight
          : 0;

      const secondary_header =
        document.getElementsByClassName("c_seconday-nav-container").length > 0
          ? document.getElementsByClassName("c_seconday-nav-container")[0]
              .offsetHeight
          : 0;

      const search_container =
        document.getElementById("searchbar_filter_box") &&
        !this.props.isDesktop
          ? document.getElementById("searchbar_filter_box").offsetHeight
          : 0;
      const researchbar_container =
        document.getElementById("research_bar") && !this.props.isDesktop
          ? document.getElementById("research_bar").offsetHeight
          : 0;
          
      containerHeight =
        this.state.height -
        header -
        secondary_header -
        search_container -
        researchbar_container -
        168;
    }

    return (
      <>
        <Col md={12} lg={12}>
          <Card>
            <CardBody style={{ padding: 0 }}>
              <UnitsSearchAppBar
                handleSearch={this._filterTableByInput}
                numSelected={[...selected].filter((el) => el[1]).length}
                handleDeleteSelected={this.handleDeleteSelected}
                onRequestSort={this.handleRequestSort}
                subFilters={subFilters}
                searchText={searchText}
                setSubFilter={this._setSubFilter}
                resetSubFilter={this._resetSubFilter}
                setSubFilters={this._setSubFilters}
                _filters={this._unitSubFilter}
                {...this.props}
                groupedView
                resetActive={this._resetActive()}
              />
              {(buildingsFetchInProgress || fetchInProgress) && (
                <div style={{ height: 10, padding: "0 10", margin: 10 }}>
                  {this.state.groupedView && <LinearLoading />}
                </div>
              )}

              {viewType == "chart" && (
                <div className="material-table__header-info">
                  <Row>
                    <Col md={12} lg={12}>
                      <h5 className="bold-text mb-3">
                        <div className={"mobile__show"}>
                          {this.state.gap === "+1MONTH" ? "Monthly" : "Weekly"}{" "}
                          {` `}
                          {eventTypes.length === 1
                            ? eventTypes[0]
                            : eventTypes.length === 2
                            ? eventTypes.join(" and ")
                            : "Pipeline"}{" "}
                          Activity <br />
                          {selectedDate ? (
                            <Fragment>
                              {" "}
                              {this.state.gap === "+1MONTH"
                                ? "Month"
                                : "Week"}{" "}
                              of
                              {` `}
                              <span className="bold-text">
                                {this.getIntervalName(selectedDate)}
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <span className="bold-text">
                                {this.getIntervalName(dataStart)}
                              </span>{" "}
                              to{` `}
                              <span className="bold-text">
                                {this.getIntervalName(dataEnd)}
                              </span>
                            </Fragment>
                          )}
                          {` `} (24 Months Max)
                        </div>
                        <span className={"desktop__show"}>
                          {this.state.gap === "+1MONTH" ? "Monthly" : "Weekly"}{" "}
                          {` `}
                          {eventTypes.length === 1
                            ? eventTypes[0]
                            : eventTypes.length === 2
                            ? eventTypes.join(" and ")
                            : "Pipeline"}{" "}
                          Activity - {` `}
                          {selectedDate ? (
                            <Fragment>
                              {" "}
                              {this.state.gap === "+1MONTH"
                                ? "Month"
                                : "Week"}{" "}
                              of
                              {` `}
                              <span className="bold-text">
                                {this.getIntervalName(selectedDate)}
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <span className="bold-text">
                                {this.getIntervalName(dataStart)}
                              </span>{" "}
                              to{` `}
                              <span className="bold-text">
                                {this.getIntervalName(dataEnd)}
                              </span>
                            </Fragment>
                          )}
                          {` `} (24 Months Max)
                        </span>
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}

              <div className="material-table__header-info">
                <Row>
                  <Col
                    md={6}
                    lg={6}
                    xl={6}
                    sm={6}
                    style={{ textAlign: "left" }}
                  >
                    {viewType == "chart" && (
                      <span>
                        {/*<WeeklyIcon color="#DBE0E8" />	*/}
                        <span>Weekly</span>
                        <Tooltip
                          title={`${
                            this.state.gap === "+7DAY"
                              ? "Change to Monthly Chart"
                              : "Change to Weekly Chart"
                          }`}
                        >
                          <PurpleSwitch
                            checked={this.state.gap !== "+7DAY"}
                            onChange={this._toggleView}
                          />
                        </Tooltip>
                        <span>Monthly</span>
                        {/*<MonthlyIcon color="#DBE0E8" />*/}
                      </span>
                    )}
                    {viewType == "filingmap" && mapLayerZoom && (
                      <ul className="top-colorbar">
                        <li>
                          <span className="color-boxfirst"></span>Demolition
                          Permit
                        </li>
                        <li>
                          <span className="color-boxsecond"></span>Construction
                          Permit
                        </li>
                        <li>
                          <span className="color-boxthird"></span>Offering plan
                        </li>
                        <li>
                          <span className="color-boxfourth"></span>Certificate
                          of Occupancy
                        </li>
                      </ul>
                    )}
                  </Col>

                  <Col md={6} lg={6} className="fillingButtonGRP">
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      <Button
                        disabled={viewType == "filingmap"}
                        onClick={() => {
                          this._toggleMapView("filingmap");
                        }}
                        startIcon={
                          <MapViewIcon
                            size={14}
                            color={
                              viewType != "filingmap" ? "#780F9E" : "#A3ADC2"
                            }
                          />
                        }
                      >
                        Map
                      </Button>

                      <Button
                        disabled={viewType == "chart"}
                        onClick={() => {
                          this._toggleMapView("chart");
                        }}
                        startIcon={
                          <ListViewIcon
                            size={14}
                            color={viewType != "chart" ? "#780F9E" : "#A3ADC2"}
                          />
                        }
                      >
                        Chart
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </div>
              {viewType == "chart" ? (
                <div
                  id="chartdiv"
                  style={{
                    width: "100%",
                    height: "500px",
                    marginTop: "30px",
                    opacity: fetchInProgress ? 0.5 : 1,
                  }}
                ></div>
              ) : (
                <SalesMapView
                  captureMobileMapMove={this._mobileMapMove}
                  hoveredListing={this.state.hoveredListing}
                  setCurrentListing={this._setCurrentListing}
                  currentListing={
                    this.state.currentListing
                      ? this.state.currentListing.listing
                      : null
                  }
                  handleMapBounds={this._handleMapBounds}
                  authData={this.state.authData}
                  handleBuildingClick={this.handleBuildingPreviewClick}
                  containerHeight={containerHeight}
                  width={this.state.width}
                  boroughs={this.state.boroughs}
                  majorMarkets={this.state.majorMarkets}
                  neighborhoods={this.state.neighborhoods}
                  q={this.q}
                  fq={this.unitFq}
                  {...this.state}
                  {...this.props}
                  isfilingPage={true}
                  buildingQuery={buildingQuery}
                  showMapLayer={this._showMapLayer}
                  hideMapLayer={this._hideMapLayer}
                  isMobile={isMobile}
                />
              )}

              {stats && stats.new_building_filed && viewType == "chart" && (
                <Fragment>
                  <hr />
                  <div className="material-table__header-info desktop__show">
                    {stats.by_building ? (
                      <Fragment>
                        {" "}
                        <span className="bold-text">
                          {_formatNumber(stats.by_building.count)}
                        </span>{" "}
                        Activities{" "}
                      </Fragment>
                    ) : (
                      ""
                    )}
                    | Date Range:{" "}
                    {selectedDate ? (
                      <Fragment>
                        {" "}
                        {this.state.gap === "+1MONTH" ? "Month" : "Week"} of
                        {` `}
                        <span className="bold-text">
                          {this.getIntervalName(selectedDate)}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className="bold-text">
                          {this.getIntervalName(dataStart)}
                        </span>{" "}
                        to{` `}
                        <span className="bold-text">
                          {this.getIntervalName(dataEnd)}
                        </span>
                      </Fragment>
                    )}
                  </div>
                  <div className="material-table__header-info mobile__show">
                    {stats.by_building ? (
                      <Fragment>
                        {" "}
                        <span className="bold-text">
                          {_formatNumber(stats.by_building.unique_projects)}
                        </span>{" "}
                        Projects{" "}
                      </Fragment>
                    ) : (
                      ""
                    )}
                    <br /> Date Range:{" "}
                    {selectedDate ? (
                      <Fragment>
                        {" "}
                        {this.state.gap === "+1MONTH" ? "Month" : "Week"} of
                        {` `}
                        <span className="bold-text">
                          {this.getIntervalName(selectedDate)}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className="bold-text">
                          {this.getIntervalName(dataStart)}
                        </span>{" "}
                        to{` `}
                        <span className="bold-text">
                          {this.getIntervalName(dataEnd)}
                        </span>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )}

              {!chartData === false && viewType == "chart" && (
                <div className="material-table__wrap">
                  <UnitsListTable
                    isSelected={this.isSelected}
                    handleBuildingClick={this.handleBuildingClick}
                    handleClick={this.handleClick}
                    handleRequestSort={this.handleRequestSort}
                    handleSelectAllClick={this.handleSelectAllClick}
                    {...this.state}
                    {...this.props}
                    reactTableFiltered={false}
                    isGrouped
                    q={this.q ? this.q : "*:*"}
                    fq={this.fq}
                    fl={this.fl}
                    buildingQuery={buildingQuery}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Fragment>
          <Modal
            isOpen={modal}
            toggle={this.toggle.bind(this)}
            modalClassName={`ltr-support`}
            className={`modal-dialog contact__modal`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggle.bind(this)}
              />
            </div>
            <div className="modal__body">
              <BuildingDetail
                trxnRes={trxnRes}
                eventData={trxnRes}
                mainTab={{ key: "construction", name: "Construction" }}
              />
              {/* <Project buildingRes={buildingRes} /> */}
            </div>
          </Modal>
        </Fragment>
      </>
    );
  }
}
export default connect((state) => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags)
    ? state.search.searchTags.slice()
    : [],
}))(withRouter(UnitsSearchTable));
