/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { NewOrderTableProps } from "../../../../../shared/prop-types/TablesProps";
import moment from "moment";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import ArrowUpIcon from "mdi-react/ChevronUpCircleOutlineIcon";
import ArrowDownIcon from "mdi-react/ChevronDownCircleOutlineIcon";
import ArrowEqualIcon from "mdi-react/MinusCircleOutlineIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import SupplyIcon from "mdi-react/CurrencyUsdIcon";

import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import AlertIcon from "mdi-react/InformationOutlineIcon";

import LinearLoading from "../../../../../shared/components/LinearLoading";

import EnterpriseGate from "./../../../../../shared/components/payment/EnterpriseGate";
import { _hasPermission } from "./../../../../../shared/helpers/access";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _formatBed,
  _formatBath,
  _formatOwnerName,
} from "../../../../../shared/helpers/utils";
import { _axiosCall } from "../../../../../shared/helpers/apicalls";

import Panel from "../../../../../shared/components/Panel";

const apiURL = process.env.API_ROOT + "/query/";

const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p>
        <DotsHorizontalIcon />
      </p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}>
        <DropdownItem>Messsage</DropdownItem>
      </Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const Trend = ({ last, current }) => {
  if (last.total_price > current.total_price) {
    return <ArrowDownIcon size={18} color="#f03434" />;
  } else if (last.total_price < current.total_price) {
    return <ArrowUpIcon size={18} color="#4ce1b6" />;
  }

  return <ArrowEqualIcon size={18} color="#999" />;
};

const processHistory = (offeringPlan) => {
  let lastPrice = offeringPlan.statCard_ppOnAccep
    ? parseFloat(
        offeringPlan.statCard_ppOnAccep.replace(/,/g, "").replace("$", "")
      ).toFixed(0)
    : offeringPlan.price_current;

  const newTotalPriceRegEx = /new\s+total\s+offering\s+price\s+\$[0-9\,]+/;
  const newTotalPriceReplaceRegEx = /new\s+total\s+offering\s+price\s+\$/;

  let amendments = [
    {
      number: 0,
      total_price: lastPrice,
      submitted_date: moment(offeringPlan.submitted_date).format("MM/DD/YYYY"),
      contents: offeringPlan.file_number.toUpperCase(),
    },
  ];

  offeringPlan.amendments.map((item) => {
    const newPriceMatch = item.contents.match(newTotalPriceRegEx);
    let newPrice = lastPrice;
    if (newPriceMatch && newPriceMatch[0]) {
      newPrice = parseInt(
        newPriceMatch[0]
          .replace(newTotalPriceReplaceRegEx, "")
          .replace(/\,/g, "")
      );
      lastPrice = newPrice;
    }
    let amendment = {
      number: item.amendmentnum,
      total_price: newPrice,
      submitted_date: item.submitteddate,
      contents: item.contents,
    };
    amendments.push(amendment);
  });

  return amendments;
};

class LendingHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lendingData: null,
      order: "desc",
      orderBy: "recorded_datetime",
    };
  }

  componentDidMount() {
    this._getFinanceData();
    //this._getACRISData();
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  _getFinanceData = async () => {
    const { buildingRes } = this.props;

    const addressQ =
      buildingRes.address && buildingRes.address.alternate_addresses
        ? buildingRes.address.alternate_addresses.join('" OR "')
        : false;

    const q = `(full_street_address:("${addressQ}") AND (zip:${
      buildingRes.address.zip
    } OR borough:"${buildingRes.address.borough.toLowerCase()}")) AND address_2:""`;
    let axiosData = {
      url: apiURL + "dob-assessments",
      method: "post",
      query: {
        q: q,
        //fq: [`assessment_year:[${buildingRes.year_converted > buildingRes.year_built ? (buildingRes.year_converted - 2) : (buildingRes.year_built - 2)} TO *]`],
        fl: "borough_code,block,lot",
        sort: "assessment_year desc",
        wt: "json",
        rows: 99,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        let bblQuery = `(block:${buildingRes.address.block} AND lot:${buildingRes.address.lot} AND borough_code:${buildingRes.address.borough_code})`;
        if (res.docs && res.docs.length > 0) {
          const lots = res.docs
            .map((item) => item.lot)
            .filter((value, index, self) => self.indexOf(value) === index);

          bblQuery = `(block:${buildingRes.address.block} AND lot:(${lots.join(
            " OR "
          )}) AND borough_code:${buildingRes.address.borough_code})`;
        }

        this._getACRISData(bblQuery);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };

  _getACRISData = async (bblQuery) => {
    const { buildingRes } = this.props;

    const fl = [
      "recorded_datetime",
      "document_amt",
      "document_id",
      "party*",
      "document_date",
      "doc_type*",
      "id",
      "source_providers",
      "address_2_normalized",
      "property_type",
    ];

    const fq = [
      "doc_type:(mtge agmt asst sat cdec adec al&r deed mcon)",
      //"document_amt:[1 TO *]",
      //`class_code_description:"mortgages & instruments"`,
      //`recorded_datetime:[${buildingRes.year_converted > buildingRes.year_built ? (buildingRes.year_converted - 15) : (buildingRes.year_built - 15)}-01-01T00:00:00Z TO *]`,
      "-property_type:(d* rg rv rp pa sc sp)",
      `address_2_normalized:""`
    ];

    const addressQ =
      buildingRes.address && buildingRes.address.alternate_addresses
        ? buildingRes.address.alternate_addresses.join('" OR "')
        : false;

    const q = `(full_street_address:("${addressQ}") AND (zip:${
      buildingRes.address.zip
    } OR borough:"${buildingRes.address.borough.toLowerCase()}")) OR ${bblQuery}`;
    let axiosData = {
      url: apiURL + "we3-acris-real-property",
      method: "post",
      query: {
        q: q,
        fq: fq,
        fl: fl.join(", "),
        sort: "document_date desc, recorded_datetime asc, doc_type asc",
        wt: "json",
        rows: 9999,
        /*
					"json.facet": {
						"by_document": {
							"type" : "terms",
							"field" : "document_id",
							"mincount" : 3,
							"limit" : 999
						}		
					}
*/
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (res.docs && res.docs.length > 0) {
          let documents = {};

          const lendingData = [];
          let lenders = [];

          res.docs.map((item) => {
            if (!documents[item.document_id]) {
              documents[item.document_id] = {
                address2_list:
                  item.address_2_normalized !== ""
                    ? [item.address_2_normalized]
                    : [],
                property_type_list: item.property_type
                  ? [item.property_type]
                  : [],
                type: item.doc_type,
                doc: item,
                amount: item.document_amt ? item.document_amt : 0,
                date: item.document_date ? item.document_date : null,
              };
            } else {
              if (
                item.address_2_normalized !== "" &&
                documents[item.document_id].address2_list.indexOf(
                  item.address_2_normalized
                ) < 0
              ) {
                documents[item.document_id].address2_list.push(
                  item.address_2_normalized
                );
                if (item.property_type) {
                  documents[item.document_id].property_type_list.push(
                    item.property_type
                  );
                }
              }
            }

            return item;
          });
          for (let document_id in documents) {
            let doc = documents[document_id];
            let count_sc = doc.property_type_list.filter(
              (item) => item === "sc"
            ).length;
           
            try {
              doc.doc.party_json = JSON.parse(doc.doc.party_json);
            } catch (e) {
              doc.doc.party_json = false;
            }
            doc.doc.address2_list = doc.address2_list;
            lendingData.push(doc.doc);
            
          }
          lendingData.sort((a, b) => {
            if (a.document_date == b.document_date) {
              if (b.doc_type == "deed" && a.doc_type != "deed") {
                return -1;
              } else {
                return 1;
              }
            } else {
              return 1;
            }
          });
          this.setState({ lendingData: lendingData });
        } else {
          this.setState({ lendingData: [] });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };

  render() {
    const { offeringPlan, buildingRes, user, isShare } = this.props;
    const { lendingData, order, orderBy } = this.state;

    if (!isShare && !_hasPermission(user, "finance-tab")) {
      return (
        <EnterpriseGate
          copy={
            <span>
              Access to building financing history requires an Enterprise
              subscription.
            </span>
          }
          upgrade={false}
          user={user}
          nextPlan={"enterprise"}
        />
      );
    }

    if (lendingData === null) {
      return <LinearLoading />;
    }

    if (lendingData.length <= 0) {
      return (
        <Col md={12}>
          <Card>
            <CardBody className={`small`}>
              <div className={`email-confirmation`}>
                <div className="email-confirmation__icon">
                  <AlertIcon size={50} color={`#D6BAE8`} />
                </div>
                <h3 className="email-confirmation__title">
                  No Financing Found
                </h3>
                <p className="email-confirmation__sub"></p>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    const data = lendingData.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.document_id === thing.document_id)
    );

    const loans = data.filter(
      (item) =>
        ["agmt", "mtge"].indexOf(item.doc_type) >= 0 && item.document_amt > 0
    );
    const lender = data.filter(
      (item) => ["asst", "agmt", "mtge"].indexOf(item.doc_type) >= 0
    );
    //const amendments = processHistory(offeringPlan[0]);
    return (
      <React.Fragment>
        <Row>
          <Col md={3} xl={3} lg={3} xs={12}>
            <Card style={{ height: "auto", paddingBottom: 50 }}>
              <CardBody className="dashboard__card-widget" style={{padding: "21px 0px 24px 25px"}}>
                <div className="card__title">
                  <h5 className="bold-text">{`Latest Financing`}</h5>
                </div>

                <div className="mobile-app-widget">
                  <div className="mobile-app-widget__top-line">
                    <p
                      className="mobile-app-widget__total-stat"
                      style={{ fontSize: 20 }}
                    >
                      {loans[0] && loans[0].document_amt
                        ? `$${_formatNumber(loans[0].document_amt)}`
                        : "--"}
                    </p>
                    {/* <SupplyIcon className="dashboard__trend-icon" /> */}
                  </div>
                  <div className="mobile-app-widget__title">
                    <h5>Loan Amount</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Card style={{ height: "auto", paddingBottom: 5 }}>
              <CardBody className="dashboard__card-widget" style={{padding: "21px 0px 24px 25px"}}>
                <div className="card__title">
                  <h5 className="bold-text">{`Lender`}</h5>
                </div>

                <div className="mobile-app-widget">
                  <div className="mobile-app-widget__top-line">
                    <p
                      className="mobile-app-widget__total-stat"
                      style={{ fontSize: 20 }}
                    >
                      {lender[0] && lender[0].party_2
                        ? _capitalizeText(lender[0].party_2[0])
                        : "--"}
                    </p>
                    {/* <SupplyIcon className="dashboard__trend-icon" /> */}
                  </div>
                  <div className="mobile-app-widget__title">
                    <h5>{"Entity Name"}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} xl={3} lg={3} xs={12}>
            <Card style={{ height: "auto", paddingBottom: 5 }}>
              <CardBody className="dashboard__card-widget" style={{padding: "21px 0px 24px 25px"}}>
                <div className="card__title">
                  <h5 className="bold-text">{`Date`}</h5>
                </div>

                <div className="mobile-app-widget">
                  {loans[0] && loans[0].document_date ? (
                    <Fragment>
                      <div className="mobile-app-widget__top-line">
                        <p
                          className="mobile-app-widget__total-stat"
                          style={{ fontSize: 20 }}
                        >
                          {moment(
                            loans[0].document_date.split("T")[0],
                            "YYYY-MM-DD"
                          ).format("MM/DD/YYYY")}
                        </p>
                        {/* <SupplyIcon className="dashboard__trend-icon" /> */}
                      </div>
                      <div className="mobile-app-widget__title">
                        <h5>
                          {moment(
                            loans[0].document_date.split("T")[0],
                            "YYYY-MM-DD"
                          ).fromNow()}
                        </h5>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="mobile-app-widget__top-line">
                        <p
                          className="mobile-app-widget__total-stat"
                          style={{ fontSize: 20 }}
                        >
                          --
                        </p>
                        {/* <SupplyIcon className="dashboard__trend-icon" /> */}
                      </div>
                      <div className="mobile-app-widget__title">
                        <h5>--</h5>
                      </div>
                    </Fragment>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="material-table__wrap">
                  <div className="material-table expansion__table">
                    <Container>
                      <Row className="expansion__summary__header">
                        <Col lg={2} className="no-padding">
                          Type
                        </Col>
                        {/*	<Col lg={2} xs={12} >	                          

					                Recorded Date
	
							</Col>	*/}
                        <Col lg={2} xs={12}>
                          Date
                        </Col>

                        <Col lg={2} className="no-padding">
                          Amount
                        </Col>

                        <Col lg={4}>Entity</Col>
                      </Row>
                    </Container>

                    {data.map((item, index) => (
                      <ExpansionPanel
                        TransitionProps={{ unmountOnExit: true }}
                        id={item.val}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-label="Expand"
                          aria-controls=""
                          id={item.id}
                        >
                          <Container>
                            <Row className="expansion__summary">
                              <Col lg={2} xs={12} className="no-padding">
                                <span
                                  className={
                                    "mobile__show light-text small-text"
                                  }
                                >
                                  Type:
                                </span>
                                <span
                                  style={{
                                    width: 12,
                                    height: 12,
                                    display: "inline-block",
                                    background:
                                      item.doc_type === "deed"
                                        ? "#F53246"
                                        : item.doc_type.indexOf("sat") >= 0
                                        ? "#4ce1b6"
                                        : "#9600BF",
                                    borderRadius: 3,
                                    marginRight: 5,
                                  }}
                                />
                                {item.doc_type_desc === "deed"
                                  ? "Ownership Transfer (Deed)"
                                  : _capitalizeText(item.doc_type_desc)}
                              </Col>
                              {/*<Col lg={2} xs={12} >
			                            <span className={"mobile__show light-text small-text"}>Recorded Date:</span>
									  	{moment(item.recorded_datetime.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY')}
									  	<br/>
									  	<span className="light-text small-text">
							         		<a className="primary" 
							         			href="javascript:void(0);"
							         			onClick={(e) => {
								         			e.stopPropagation(); 
								         			window.open(`https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${item.document_id}`);}} 
								         		>
							         		 See Document <OpenInNewIcon size={12} />
							         		</a>
							         	</span>
									</Col>*/}
                              <Col lg={2} xs={12}>
                                <span
                                  className={
                                    "mobile__show light-text small-text"
                                  }
                                >
                                  Document Date:
                                </span>
                                {moment(
                                  item.document_date.split("T")[0],
                                  "YYYY-MM-DD"
                                ).format("MM/DD/YYYY")}
                                <br />
                                <span className="light-text small-text">
                                  <a
                                    className="primary"
                                    href="javascript:void(0);"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(
                                        `https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${item.document_id}`
                                      );
                                    }}
                                  >
                                    See Document <OpenInNewIcon size={12} />
                                  </a>
                                </span>
                              </Col>
                              <Col lg={2} xs={12} className="no-padding">
                                <span
                                  className={
                                    "mobile__show light-text small-text"
                                  }
                                >
                                  Amount:
                                </span>
                                {item.document_amt > 0
                                  ? `$${_formatNumber(item.document_amt)}`
                                  : `--`}
                                <br />
                                <span className="light-text small-text">
                                  {item.address2_list.length > 0
                                    ? item.address2_list.length +
                                      " Unit" +
                                      (item.address2_list.length > 1 ? "s" : "")
                                    : "Building / Lot"}
                                </span>
                              </Col>

                              <Col lg={6} xs={7}>
                                <span
                                  className={
                                    "mobile__show light-text small-text"
                                  }
                                >
                                  Description:
                                </span>

                                {item.doc_type === "deed" &&
                                item.document_amt > 0 ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    transfered ownership to{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>{" "}
                                    through a sale of the unit.
                                  </Fragment>
                                ) : item.doc_type === "deed" &&
                                  item.document_amt == 0 ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    transfered ownership to{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>
                                  </Fragment>
                                ) : item.doc_type === "mtge" ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    took out or modified a mortgage with{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>
                                  </Fragment>
                                ) : item.doc_type === "agmt" ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    consolidated or transfered a mortgage to{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>
                                  </Fragment>
                                ) : item.doc_type === "asst" ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    assigned a mortgage to{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>
                                  </Fragment>
                                ) : item.doc_type === "sat" ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    paid off a mortagage with{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>
                                  </Fragment>
                                ) : item.doc_type === "sat" ? (
                                  <Fragment>
                                    <span className={""}>
                                      {_formatOwnerName(item.party_1)}
                                    </span>{" "}
                                    partially paid off a mortagage with{" "}
                                    <span className={""}>
                                      {_formatOwnerName(item.party_2)}
                                    </span>
                                  </Fragment>
                                ) : (
                                  <span className={""}>
                                    {_formatOwnerName(item.party_2)}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Container>
                            <Table
                              responsive
                              striped
                              className="dashboard__table-orders table-finance"
                            >
                              <thead>
                                <tr>
                                  <th>Property Type</th>
                                  <th>
                                    {item.doc_type === "mtge"
                                      ? "Borrower"
                                      : "Party 1"}
                                  </th>
                                  <th>
                                    {item.doc_type === "mtge"
                                      ? "Lender"
                                      : "Party 2"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={index}>
                                  <td>
                                    <p>
                                      {_capitalizeText(item.property_type_desc)}
                                    </p>
                                    <br />
                                    <span className="light-text small-text">
                                      Ref:
                                      <a
                                        className="primary"
                                        href="javascript:void(0);"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          window.open(
                                            `https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id=${item.document_id}`
                                          );
                                        }}
                                      >
                                        {item.document_id}{" "}
                                        <OpenInNewIcon size={12} />
                                      </a>
                                    </span>
                                  </td>
                                  <td>
                                    {item.party_json &&
                                      item.party_json.party_1 &&
                                      item.party_json.party_1.parties &&
                                      item.party_json.party_1.parties.map(
                                        (item, index) => (
                                          <div style={{ padding: 5 }}>
                                            {_capitalizeText(item.name)}
                                            <br />
                                            {item.address_1
                                              ? _capitalizeText(item.address_1)
                                              : item.addr1
                                              ? _capitalizeText(item.addr1)
                                              : ""}
                                            {item.addr2 ? (
                                              <Fragment>
                                                <br />
                                                {_capitalizeText(item.addr2)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                            {item.city ? (
                                              <Fragment>
                                                <br />
                                                {_capitalizeText(item.city)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                            {item.state ? (
                                              <Fragment>
                                                {", "}
                                                {_capitalizeText(item.state)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                            {item.zip ? (
                                              <Fragment>
                                                {" "}
                                                {_capitalizeText(item.zip)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )
                                      )}
                                  </td>
                                  <td
                                    className=""
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {item.party_json &&
                                      item.party_json.party_2 &&
                                      item.party_json.party_2.parties &&
                                      item.party_json.party_2.parties.map(
                                        (item, index) => (
                                          <div style={{ padding: 5 }}>
                                            {_capitalizeText(item.name)}
                                            <br />
                                            {item.address_1
                                              ? _capitalizeText(item.address_1)
                                              : item.addr1
                                              ? _capitalizeText(item.addr1)
                                              : ""}
                                            {item.addr2 ? (
                                              <Fragment>
                                                <br />
                                                {_capitalizeText(item.addr2)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                            {item.city ? (
                                              <Fragment>
                                                <br />
                                                {_capitalizeText(item.city)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                            {item.state ? (
                                              <Fragment>
                                                {", "}
                                                {_capitalizeText(item.state)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                            {item.zip ? (
                                              <Fragment>
                                                {" "}
                                                {_capitalizeText(item.zip)}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Container>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row>
			    	<Col md={12}>	    	
						<div>				 
						    <Table responsive striped className="dashboard__table-orders">
						      <thead>
						        <tr>
						          <th>Date</th>
						          <th>Amount</th>
						          <th>Type</th>
						          
						          <th>Lender</th>
						          <th/>
						        </tr>
						      </thead>
						      <tbody>
						        {data.map((item, index) => (
						          <tr key={index}>	
						          	<td style={{paddingRight:0}}>
						            	{moment(item.recorded_datetime).format('MM/DD/YYYY')}						            	
						            </td>	
						            <td className="dashboard__table-orders-total" dir="ltr">${_formatNumber(item.document_amt)}</td>	            
						            <td className="" style={{whiteSpace:'nowrap'}}>
						              {_capitalizeText(item.doc_type_desc)}
						            </td>						            
						            <td className="" dir="ltr" style={{maxWidth:250}}>
						            	{item.party_2 && item.party_2.length > 1 ? _capitalizeText(item.party_2.join(', ')) :  item.party_2 ? _capitalizeText(item.party_2[0]) : '--'}
							            	
							         </td>
							         <td>
							         	<span className="light-text small-text">
							         		<a href={`https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${item.document_id}`} target="_blank">
							         		 See Document <OpenInNewIcon size={12} />
							         		</a>
							         	</span>
							         </td>
						            
						          </tr>
						        ))}
						      </tbody>
						    </Table>
						  </div>
			    	</Col>
			    </Row> */}
      </React.Fragment>
    );
  }
}

export default LendingHistory;
