import React, { Fragment, useState } from 'react';
import {
  Card, CardBody, Col, Button,
  
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

import CheckIcon from 'mdi-react/CheckCircleOutlineIcon';
import CircleIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import ChangePlan from '../../ChangePlan';
import OpenNew from 'mdi-react/OpenInNewIcon';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';


import{
	_capitalizeText,
	_formatNumber
} from '../../../../helpers/utils';

import { appFeatures, dataFeatures, costPerCredit } from './../../plans.js';

const HelpTip = ( { description, learn_more  } ) => {
	return (
		<Tooltip title={description}>
			<IconButton size={`small`} style={{background:'transparent', margin:"-1px 0 0 0px"}} onClick={() => { if(learn_more) window.open(learn_more) }} >
				<HelpCircleOutlineIcon size={14} color={'#780F9E'} />
			</IconButton>
		</Tooltip>
	)
}

const PlanCard = ({ plan, user, currentPlan }) => {
	const [ planOpen, setPlanOpen ] = useState(false);
	
	return (
	  <Col md={4} xl={4} sm={12}>
	    <Card>
	      <CardBody className="pricing-card pricing-card--info">
	        <div className="pricing-card__body">
	          <h3 className="pricing-card__plan">{plan.name}</h3>
	          
	          <hr />
	          
	          {plan.hidePrice ? 
		          <p className=""></p>
		          :
		          <p className="pricing-card__price">${plan.pricePerMonth % 1 != 0 ? plan.pricePerMonth : _formatNumber(plan.pricePerMonth)}<span>/mo</span></p>
	          }
	          
	          
	          {/*<div className="plan-recommended"><div className="title">Recommended For:</div>{plan.users.join(", ")}</div>*/}
	          {currentPlan ?
		          <Fragment>
	          		{currentPlan.id === plan.id ?
			  			<Button className="button pricing-card__button" color="success" style={{marginTop:5,cursor:"default"}}>Current Plan</Button>
			  			:			  			
			  			 <Fragment>
			  			 	{plan.contactUs || currentPlan.id === "enterprise"  ?
				  			 <Button className="button pricing-card__button intercom_launch" color="primary" style={{marginTop:5}}>Contact Us</Button>
				  			 :
				  			  <Fragment>
					  			 <Button className="pricing-card__button" color="primary" style={{marginTop:5}}  onClick={() => {setPlanOpen(true)}} >Change Plan</Button>
					  		  </Fragment>
			  			 	}
			  			 </Fragment>
				  			 
			  		}
			  	  </Fragment>		          
		      : plan.contactUs ? 
		          <Fragment>
	          		<div style={{color:'#780F9E'}}>{plan.checkTitle}</div>
			  		<a href={`https://marketproof.com#trial`}><Button className="button pricing-card__button" color="primary" style={{marginTop:5}}>Start Trial</Button></a>
			  	  </Fragment>
		      :
	          	<Fragment>
	          		{user === false ?
		          	   <Fragment>
		          			<div style={{color:'#780F9E'}}>&nbsp;</div>
				  			<Button className="button pricing-card__button" color="primary" style={{marginTop:5}}>&nbsp;</Button>
		          		</Fragment>
		          	:
			          	<Fragment>
		          			<div style={{color:'#780F9E'}}>{plan.checkTitle}</div>
				  			<a href={`/signin/register?plan=${plan.id}`}><Button className="button pricing-card__button" color="primary" style={{marginTop:5}}>Start Trial</Button></a>
				  		</Fragment>
			  		}        		
	          	</Fragment>
	          }
	          <hr />
	          <div className="mt-3 mb-3">
	          <h6 className="semi-bold-text text-left">Features</h6>
	      
	          {appFeatures.map(feature => {
			     if(feature.plans.indexOf(plan.id)>=0){
					 if(feature.name == "Monthly Project Views"){
					 	return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {plan.upTo && <span>Up to</span>} <strong>{plan.projectViews}</strong> {feature.name}{feature.description && <HelpTip description={feature.description} />}</p>

				 	 }else if(feature.name == "Users Included"){
					   return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {typeof plan.usersInlcuded === "number" && plan.upTo && <span>Up to</span>} <strong>{plan.usersInlcuded}</strong>{typeof plan.usersInlcuded === "number" && <span>{ plan.usersInlcuded > 1 ? " Users Included" : " User Included"}{feature.description && <HelpTip description={feature.description} />}</span>}</p>
				  
					}else{
					 return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {feature.name}{feature.description && <HelpTip description={feature.description} />}</p>
 
					  }
			     }else{
				     return <p className="pricing-card__feature pricing-card__feature--inactive"><CircleIcon size={16} style={{marginTop:-3}}  /> {feature.name}{feature.description && <HelpTip description={feature.description} />}</p>
			     }
			    
		      })}
		      </div>
		
	          <hr />         
	          <div className="mt-3 mb-3">
	          	<h6 className="semi-bold-text text-left">Unlock Contact Info (Email, Phone)</h6>
	          	{dataFeatures.map(feature => {
				     if(feature.plans.indexOf(plan.id)>=0){
					     if(feature.name==='No Tokens Inlcuded'){
						     return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} />{` `}
							 		{plan.upTo && <span>Up to </span>} 
						     		 <span className="semi-bold-text text-left">{plan.dataCredits}</span> Tokens{feature.description && <HelpTip {...feature} />}
						     			
						     		</p>
					     }else{
						     return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {feature.name}{feature.description && <HelpTip {...feature} />}</p>
					     }
					     				     
				     }else{
					     return <p className="pricing-card__feature pricing-card__feature--inactive"><CircleIcon size={16} style={{marginTop:-3}}  /> {feature.name}{feature.description && <HelpTip description={feature.description} />}</p>
				     }
				    
			    })}
	     
			  	
	          </div>
	        </div>
	        {user && <ChangePlan modal={planOpen} setModal={setPlanOpen} changePlan={plan.id} />}
	      </CardBody>
	    </Card>
	  </Col>
  )
};

export default PlanCard;
