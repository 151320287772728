/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import Button from '@material-ui/core/Button';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import{
	matTheme,
} from '../../../helpers/utils';

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    multiline={rows}
	    rows={rows}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    variant="outlined"

	  />
	</ThemeProvider>
);

const renderCheckbox = ({ input, label, disabled }) => {
	
	console.log(input.value);
	return (
		  <FormControlLabel
		      control={
		        <Checkbox
		          checked={input.value ? true : false}
		          onChange={input.onChange}
		        />
		      }
		      label={label}
		      disabled={disabled}
		    />
   )
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const ContactForm = ({ handleSubmit, reset, t, buildingRes, user, toggle, property, saved }) => (
	
        <form className="material-form" onSubmit={handleSubmit}>
         <Row>
          <Col sm={12} md={12} lg={12} xl={12}>         	
	          <div>
	            <span className="material-form__label">Your Name</span>
	            <Field
	              name="fullName"
	              component={renderTextField}
	              placeholder="Name"
	            />
	          </div>
          </Col>
          <Col sm={12} md={12} lg={6} xl={6}> 
	          <div>
	            <span className="material-form__label">Your Email</span>
	            <Field
	              name="email"
	              component={renderTextField}
	              placeholder="example@mail.com"
	              type="email"
	            />
	          </div>
          </Col>
          <Col sm={12} md={12} lg={6} xl={6}>
          <div>
            <span className="material-form__label">Your Phone</span>
            <Field
              name="primaryPhone"
              component={renderTextField}
              placeholder=""
              type="phone"
            />
          </div>
	      </Col>
          <Col sm={12} md={12} lg={12} xl={12}>              
          <div>
            <span className="material-form__label">Price Range</span>
            <Field
              name="priceRange"
              component={renderTextField}
              select
            >
              <MenuItem className="material-form__option" value="Less than $500K">Less than $500K</MenuItem>
              <MenuItem className="material-form__option" value="$500K to $750K">$500K to $750K</MenuItem>
              <MenuItem className="material-form__option" value="$750K to $1M">$750K to $1M</MenuItem>
              <MenuItem className="material-form__option" value="$1M to $2M">$1M to $2M</MenuItem>
              <MenuItem className="material-form__option" value="$2M to $3M">$2M to $3M</MenuItem>
              <MenuItem className="material-form__option" value="$3M to $5M">$3M to $5M</MenuItem>
              <MenuItem className="material-form__option" value="More than $5M">More than $5M</MenuItem>
            </Field>
          </div>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12}> 
          <div className="material-form__label">Preferred Unit Size</div>
          <div className="checkbox__items mb-2">            
            <Field name="studio" component={renderCheckbox} label="Studio" value="Studio" />
            <Field name="one_bed" component={renderCheckbox} label="1 Bed"  value="1 Bed" />              
            <Field name="two_bed" component={renderCheckbox} label="2 Beds"  value="2 Beds" /> 
            <Field name="three_bed" component={renderCheckbox} label="3 Beds"  value="3 Beds" />  
            <Field name="four_bed" component={renderCheckbox} label="4+ Beds"  value="4+ Bed" /> 
          </div>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12}> 
	          <div className="material-form__label">What are you interested in?</div>
	          <div className="checkbox__items mb-2">            
	            <Field name="info" component={renderCheckbox} label="Request Information" value="Request Information" />
	            <Field name="tour" component={renderCheckbox} label="Schedule Tour"  value="Schedule Tour" />              
	            <Field name="offer" component={renderCheckbox} label="Make an Offer"  value="Make an Offer" />  
	          </div>
		  </Col>
          <Col sm={12} md={12} lg={12} xl={12}> 
	          <div>
	            <span className="material-form__label">Message</span>
	            <Field
	              name="message"
	              component={renderTextField}
	              placeholder="Type message here"
	              multiline
	              rows="3"
	              rowsMax="3"
	            />
	          </div>         
          </Col>
          </Row>
          <a className="reset__link" onClick={reset}>Reset Form</a>
		  <ButtonToolbar className="modal__footer">
		  	{!saved && <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />}
		  	<Field name="ccme" component={renderCheckbox} label="Send Me a Copy" value="ccme" />
            <Tooltip title="Cancel">
		        <Button
			         onClick={toggle}
			         variant="outlined"
			         color=""
			         className=""		         
			         startIcon={null}
			     >	Cancel
			     </Button>				 
		  	</Tooltip>{' '}
            <Tooltip title="Send">
		        <Button
		        	 onClick={handleSubmit}
			         variant="contained"
			         color="primary"
			         className="buyersList__primary-button"		         
			         startIcon={<EmailOutlineIcon />}
			     >	Submit
			     </Button>				 
		  	</Tooltip>
         </ButtonToolbar>
    </form>
);

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default connect(
  (state, props) => ({
    initialValues: Object.assign({ccme : true, saveme : !props.saved, fullName: props.user.firstName + ' ' + props.user.lastName}, props.user), // pull initial values from account reducer
    formValues: getFormValues("contact_form")(state)
  })
)(reduxForm({
  form: 'contact_form', // a unique identifier for this form
})(ContactForm));
