const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

function getTooltipStyles(themeName, type) {
  switch (themeName) {
    case 'theme-dark': {
      const { backgroundColor, color } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default: return ligthTheme;
  }
}

export default getTooltipStyles;

export const getDefaultChartColors = ['#9a12b3',  '#00b5cc', '#f03434', '#e9d460', '#2abb9b', '#fde3a7', '#28C2FF'];

export const getStatusColors = {
	"sold" : "#28C2FF",
	"contract" : "#FFD23F",
	"active" : "#4ce1b6",
	"shadow" : "#858AA1",
	"transferred" : "#9ecae1"
}