/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/first: off */
import React, { Component } from "react";
import moment from "moment";
import ReactDOM from "react-dom";

import mapboxgl from "mapbox-gl";
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
import L from "leaflet";
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';

import PitchToggle from './../../../shared/components/map/PitchToggle';
// import { Publisher } from "./../../pubsub/publisher.js";
import * as MapUtil from "./../../../shared/helpers/mapUtil";
import {
	_isEqual,
	_capitalizeText,
	_getBuildingPhotos,
	_formatQueensAddress,
	_urlToName,
	_nameToUrl,
	_replaceAbbreviations,
	_shouldShow,
	_generateRandomAPI
} from './../../../shared/helpers/utils';
 import { _getGeocoding } from  "./../../../shared/helpers/apicalls";
// import "./../static/styles/_mapboxgl.css";



const BOROUGHS = {
	Brooklyn: {
		center: [-73.949997, 40.650002]
	},
	Bronx: {
		center: [-73.865433, 40.837048]
	},
	Manhattan: {
		center: [-73.984016, 40.754932]
	},
	"Staten Island": {
		center: [-74.151535, 40.579021]
	},
	Queens: {
		center: [-73.769417, 40.742054]
	}
};

const pipeline_tileset = [
	{
		id: "mp-pipeline-buildings",
		layer_id: "mp-pipeline-buildings-layer",
		url: "mapbox://ningzhou.pipeline-buildings",
		source_layer: "manhattan"
	}
]

const building_tileset = [

	{
		id: "mp-building-manhattan",
		layer_id: "mp-building-manhattan-layer",
		url: "mapbox://ningzhou.cjv5grhlg3xlr2ro21g760vdd-63y9y",
		source_layer: "nyc-building-manhattan"
	},
	{
		id: "mp-building-staten",
		layer_id: "mp-building-staten-layer",
		url: "mapbox://ningzhou.cjvg8q7680exe2xqhzcej54qd-9xeuw",
		source_layer: "nyc-building-staten"
	},
	{
		id: "mp-building-bronx",
		layer_id: "mp-building-bronx-layer",
		url: "mapbox://ningzhou.cjvgrtx1u0htl2rpnfdchg13v-1aseh",
		source_layer: "nyc-building-bronx"
	},
	{
		id: "mp-building-brooklyn",
		layer_id: "mp-building-brooklyn-layer",
		url: "mapbox://ningzhou.cjvfch80a12cl2xs98iwejfnx-0s7ec",
		source_layer: "nyc-building-brooklyn"
	},
	{
		id: "mp-buildings-queens-0-4000",
		layer_id: "mp-buildings-queens-0-4000-layer",
		url: "mapbox://ningzhou.cjvlyvou11dzg2ws1sspk9u4k-5t9og",
		source_layer: "nyc-buildings-queens-0-4000"
	},
	{
		id: "mp-buildings-queens-4001-8000",
		layer_id: "mp-buildings-queens-4001-8000-layer",
		url: "mapbox://ningzhou.cjvmg56vh1jng2ykw5rikl569-1e9gm",
		source_layer: "nyc-buildings-queens-4001-8000"
	},
	{
		id: "mp-buildings-queens-8001-12000",
		layer_id: "mp-buildings-queens-8001-12000-layer",
		url: "mapbox://ningzhou.cjvmkfknk0vgi31s37e954hpa-9u6m1",
		source_layer: "nyc-buildings-queens-8001-12000"
	},
	{
		id: "mp-buildings-queens-12001",
		layer_id: "mp-buildings-queens-12001-layer",
		url: "mapbox://ningzhou.cjvmw4b5615xe33o9dqcpo0ae-9mm6a",
		source_layer: "nyc-buildings-queens-12001"
	}

];

const neighborhood_tileset = {
	id:"mp-neighborhoods",
	url:"mapbox://ningzhou.cjyhuu6sg05a52onyjsix2wm3-2t5i9",
	source_layer: "nyc-neighborhoods-v3",
}

mapboxgl.accessToken =
	"pk.eyJ1IjoibmluZ3pob3UiLCJhIjoiY2lwaXBiaWtyMDFxZXVnbmpkaWR4dXd0MSJ9.GJX2c6TviB6opu84mCEOIg";

const buildingStyle = {
	fillColor: "#A3ADC2",
	fillOpacity: 0.45
};

const buildingHighlightStyle = {
	fillColor: "#780F9E",
	fillOpacity: 0.8
};

const defaultMap = {
	lng: -73.9949712,
    lat: 40.7379366,
    zoom: 16,
}

const containerStyle = {
	width: "100%",
	position: "absolute",
	top: 0,
	left: 0,
};
class MatMapView extends Component {
	 constructor(props) {
    super(props);
    this.state = {
      // fetchInProgress: false,
      lng: -73.9949712,
      lat: 40.7379366,
      zoom: 16,
      mapLoaded: false,
      hoveredStateId: null,
      buildingsSelected : []
    };
    
    this._buildingData = this.props.buildingData && this.props.buildingTags.length === 1
			? this.props.buildingData
			: this.props.axios && this.props.axios.buildingAxios && this.props.axios.buildingAxios[0]
				? this.props.axios.buildingAxios[0]
				: false;

    this._hoods = [];
    this._loadLayers = this._loadLayers.bind(this);
    this._handlePage = this._handlePage.bind(this);
    this._setNeighborhood = this._setNeighborhood.bind(this);
    this._setRenderStates = this._setRenderStates.bind(this);
    this._setBuilding = this._setBuilding.bind(this);
    this._resetMap = this._resetMap.bind(this);
    this._filterMap = this._filterMap.bind(this);
    this._openBuilding = this._openBuilding.bind(this);
  }
  
  tooltipContainer;
  tooltip;
  map;
  geoLocate;
  hoveredStateId = null;
  hoveredLayerId = null;
  popup = new mapboxgl.Popup({
	closeButton: false,
	closeOnClick: false
  });
  
  hoods = [];

  _loadLayers(){}
  
  _handlePage(){}
  
  _setTooltip(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(
          Tooltip, {
            features
          }
        ),
        this.tooltipContainer
      );
    } else {
    	ReactDOM.unmountComponentAtNode(this.tooltipContainer)
    }
  }

  _onMouseMove(e){
  	//this._onMouseOut();
  	console.log(e.features);
  	if (e.features.length > 0 && e.features[0].properties && e.features[0].properties.key) {
		//var mapLayer = this.map.getLayer('mp-building-layer-hover');  
	  	
	  	//e.features[0].state = {"hover":true};
			this.map.getCanvas().style.cursor = 'pointer';
			//
			this.tooltip && e.lngLat && this.tooltip.setLngLat(e.lngLat);
			this._setTooltip(e.features);
			if(this.hoveredStateId !== e.features[0].properties.key){
				//console.log(this.hoveredStateId, e.features[0].properties.master_key);
				this.hoveredStateId = e.features[0].properties.key;
				this.hoveredLayerId = e.features[0].layer.id+"-hover";
				if(this.map.getZoom()>15){
					console.log(this.hoveredLayerId, this.hoveredStateId);
					this.map.setFilter(this.hoveredLayerId, ["==", "key", this.hoveredStateId]);
				}	
			}
		
		} else{
			this.map.getCanvas().style.cursor = '';
		}
  }
  
  _onMouseOut(){
		if(this.hoveredStateId && this.hoveredLayerId){
			  this.map.setFilter(this.hoveredLayerId, ["==", "key", false])
		}
		this.hoveredStateId =  null;
		this.map.getCanvas().style.cursor = '';

		ReactDOM.unmountComponentAtNode(this.tooltipContainer);
  }
  
  _setMouseClick(e) {
  	console.log("_setMouseClick called", e, e.features)
		if (e.features.length > 0 && e.features[0].properties && e.features[0].properties.bbl) {
			let buildings = [];
			
			if(this.props.authData && this.props.authData.appState 
	        && this.props.authData.appState 
	        && this.props.authData.appState.buildingsSelected
	        && this.props.authData.appState.buildingsSelected.length>0){
	          buildings =   this.props.authData.appState.buildingsSelected.slice(0);
	        }
			let building = Object.assign({},e.features[0].properties);
			try{
				building.building_slugs = JSON.parse(building.building_slugs);
				building.neighborhood = JSON.parse(building.neighborhood);
			}catch(e){
				console.log(e);
			}
			
			building["id"] = building.building_slugs[0];
			building["key"] = building.key;
			
			buildings = buildings.filter(existing => {
				return (existing.key != building.key);
			})
			buildings.unshift(building);
			
			
			//Show building page temporarily until we can add collection
			if(this.props.isMobile){
				this.props.updateBuildings({buildingsSelected:buildings.slice(0,1)});
			}else{
				//window.open("/building/" + _nameToUrl(building.borough) + "/" + _nameToUrl(building.main_neighborhood) + "/" + building.main_building_slug);
				this._openBuilding(building)
			}
			//this.props.updateBuildings({buildingsSelected:buildings});
			//this.setState({buildingsSelected:buildings})		
		}
  }
  
  _getMapFilters(){
	  var current_year = new Date().getFullYear()
	  var filters = [];
	  var filterBuildingSearchTags = {
		  "condos-and-coops" : ["in", "ownership_type", "condo", "coop", "condop"],
		  "condos" : ["in", "ownership_type", "condo", "condo-retail"],
		  "coops" : ["in", "ownership_type", "coop"],
		  "condops" : ["in", "ownership_type", "condop"],
		  "houses" : ["in", "ownership_type", "townhouse", "single-family", "multi-family", "two-family", "three-family", "four-family", "five-to-six-family"],
		  "single-family" : ["in", "ownership_type", "single-family"],
		  "multi-family" : ["in", "ownership_type", "multi-family", "two-family", "three-family", "four-family", "five-to-six-family"],
		  "rental" : ["in", "ownership_type", "rental", "apartment"],
		  "commercial" : ["any", 
		  					["in", "ownership_type", "commercial", "office", "retail", "public-facilities", "industrial", "vacant-land", "parking", "garage", "hotel"],
		  					["in", "building_category", "commercial", "office", "retail", "public-facilities", "industrial", "vacant-land", "parking", "garage", "hotel"]
		  				  ],
		  "retail" : ["any", 
		  				["in", "ownership_type", "retail"], 
		  				["in", "building_category", "retail"]
		  			  ],
		  "office" : ["any", 
		  				["in", "ownership_type", "office"], 
		  				["in", "building_category", "office"]
		  			  ],
		  "industrial" : ["any", 
		  					["in", "ownership_type", "industrial"], 
		  					["in", "building_category", "industrial"]
		  				],
		  "hotel" : ["any", 
		  					["in", "ownership_type", "hotel"], 
		  					["in", "building_category", "hotel"]
		  				],
		  "parking" : ["any", 
		  					["in", "ownership_type", "parking", "garage"], 
		  					["in", "building_category", "parking", "garage"]
		  				],
		  "public-facilities" : ["any", 
		  					["in", "ownership_type", "public-facilities"], 
		  					["in", "building_category", "public-facilities", "regligious"]
		  				],
		  "vacant-land" : ["any", 
		  					["in", "ownership_type", "vacant-land", "vacant lot"], 
		  					["in", "building_category", "vacant-land", "vacant lot"]
		  				],
		  "landmarks" : null,
		  "historic-districts" : null
	  }
	  
	  var filterAgeSearchTags = {
		  "age" : {
			  "pre construction" : [">=", "year_built", current_year],
			  "new development" : ["all", [">=", "year_built", current_year-5], ["<", "year_built", current_year]],
			  "recently built" : ["all", [">=", "year_built", 2000], ["<", "year_built", current_year-5]],
			  "post war" : ["all", [">=", "year_built", 1945], ["<", "year_built", 2000]],
			  "pre war" : ["all", [">=", "year_built", 1600], ["<", "year_built", 1945]]
		  },
		  "units" : {
			  "units 1" : ["==", "residential_units", 1],
			  "units 2 3" : ["all", [">=", "residential_units", 2], ["<=", "residential_units", 3]],
			  "units 4 6" : ["all", [">=", "residential_units", 4], ["<=", "residential_units", 6]],
			  "units 7 10" : ["all", [">=", "residential_units", 7], ["<=", "residential_units", 10]],
			  "units 11 30" : ["all", [">=", "residential_units", 11], ["<=", "residential_units", 30]],
			  "units 31 50" : ["all", [">=", "residential_units", 31], ["<=", "residential_units", 50]],
			  "units 51 100" : ["all", [">=", "residential_units", 51], ["<=", "residential_units", 100]],
			  "units 101 200" : ["all", [">=", "residential_units", 101], ["<=", "residential_units", 200]],
			  "units 201 or more" : ["all", [">=", "residential_units", 201]],
		  },
		  "floors" : {
			  "floors 1" : ["==", "total_floors", 1],
			  "floors 2" : ["==", "total_floors", 2],
			  "floors 3 5" : ["all", [">=", "total_floors", 3], ["<=", "total_floors", 5]],
			  "floors 6 10" : ["all", [">=", "total_floors", 6], ["<=", "total_floors", 10]],
			  "floors 11 20" : ["all", [">=", "total_floors", 11], ["<=", "total_floors", 20]],
			  "floors 21 40" : ["all", [">=", "total_floors", 21], ["<=", "total_floors", 40]],
			  "floors 41 or more" : ["all", [">=", "total_floors", 41]],
		  },
		  "features" : {
			  "doorman" : ["in", "service_level", "doorman", "doorman-full-time"],
			  "elevator" : ["in", "building_access", "elevator"],
			  "gym" : ["in", "amenities_health", "gym", "fitness"],
			  "no fee" : ["in", "amenities_other", "no-fee"],
			  "parking" : ["in", "amenities_auto", "parking", "garage"],
			  "pets allowed" : ["in", "building_policies", "pets"],
			  "storage" : ["in", "amenities_common_space", "storage"],
			  "outdoor space" : ["in", "amenities_outdoor_space", "outdoor", "garden", "roof"],
			  "pool" : ["in", "amenities_health", "pool"],
		  },
		  
	  }
	  
	  
	  if(this.props.buildingSearchTags && filterBuildingSearchTags[this.props.buildingSearchTags[0]]){
		  filters.push(filterBuildingSearchTags[this.props.buildingSearchTags[0]]);
	  }
	  
	  if(this.props.authData &&
	     this.props.authData.appState.neighborhoods && 
	     this.props.authData.appState.neighborhoods.length>0){
		  let hood_keys = this.props.authData.appState.neighborhoods.map(tag => tag.name.toLowerCase());
		  let filter = ["in", "main_neighborhood", ...hood_keys];
		  filters.push(filter);
	  }/*
else{
		  let filter = ["in", "main_neighborhood", "FALSE"];
		  filters.push(filter);
	  }
*/
	  console.log(this.props.buildingSearchFilterTags);
	  let searchFilters = {}
	  if(this.props.buildingSearchFilterTags && this.props.buildingSearchFilterTags.length>0){		  
		  this.props.buildingSearchFilterTags.map((item) => {			  
			  if(filterAgeSearchTags[item.filter]){
				if(Array.isArray(item.val)){
					searchFilters[item.filter] = item.val.map((item_key) =>{
					  	return filterAgeSearchTags[item.filter][item_key];
					});
				}else{
					searchFilters[item.filter] = [filterAgeSearchTags[item.filter][item.val]];
				}
			 	
			  }
		  })
	  }
	  console.log(searchFilters);
	  for (var key in searchFilters) {
		  if(searchFilters[key].length>0){
			  filters.push(["any"].concat(searchFilters[key]))
		  }
	   }
	  
	  var queryFilter = false;
	  if(filters && filters.length>0){
		  if(filters.length > 1){
			  queryFilter = ["all"].concat(filters);
		  }else{
			  queryFilter = filters[0];
		  }
	  }
	  
	  if(queryFilter.length==2 && queryFilter[0]=="any"){
		  queryFilter = queryFilter[1];
	  }
	  
	  console.log(queryFilter);
	  if(queryFilter){
		  return {
			  filter: queryFilter,
			  fillOpacity: buildingHighlightStyle.fillOpacity,
			  fillColor: buildingHighlightStyle.fillColor
		  }
	  }else{
		  return false;
	  }
		   
  }
  
  _setRenderStates(){
	  if (!this.map.loaded()) { return }
	  if(_isEqual(this.props.hoodTags, this.hoods)) { return }
	  if(!this.props.hoodTags || this.props.hoodTags.length==0){return}
	  	
		var features = this.map.querySourceFeatures(neighborhood_tileset.id, {
		    sourceLayer: [neighborhood_tileset.source_layer],
		    filter:["in", "name", _capitalizeText(this.props.hoodTags[this.props.hoodTags.length-1].hood)]
		});
		if(!features){ return }
		this.hoods = this.props.hoodTags.map(tag => _capitalizeText(tag.hood));
		
		var polygon = features[0].geometry.coordinates;
		var fit = new L.Polygon(polygon).getBounds();
		var southWest = new mapboxgl.LngLat(fit['_southWest']['lat'], fit['_southWest']['lng']);
		var northEast = new mapboxgl.LngLat(fit['_northEast']['lat'], fit['_northEast']['lng']);			
		var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
		var center = boundBox.getCenter();
		this.map.fitBounds(new mapboxgl.LngLatBounds(southWest, northEast));
		this.map.off('render', "mp-neighborhood-layer", this._setRenderStates);
		this.map.off('render', "mp-neighborhood-layer-outline", this._setRenderStates);
	  
  }
  
  _setNeighborhood(prevProps) {
    // let neighborhoodLayer = this.map.getLayer("mp-neighborhood-layer");
    // console.log(neighborhoodLayer);
    //Get selected hoods from redux appState
    let hoods = []

    if(!hoods.length) {
	     this.map.setFilter(
		      "mp-neighborhood-layer", 
		      [
		      "in", 
		      "id",
		      "FALSE"
		      ]
		  );
		  this.map.setFilter(
		      "mp-neighborhood-layer-outline", 
		      [
		      "in", 
		      "id",
		      "FALSE"
		      ]
		  );
	    return;
	 	}
    
    const hood_keys = hoods ? hoods.map(tag => tag.key) : [];

    this.map.setFilter(
      "mp-neighborhood-layer", 
      [
      "in", 
      "id",
      ...hood_keys
      ]
    );
    this.map.setFilter(
	      "mp-neighborhood-layer-outline", 
	      [
	      "in", 
	      "id",
	      ...hood_keys
	      ]
	  );
        
    let last_hood = hoods.length>1 ? hoods.filter(tag => {
	    let found = false;
	    
	    prevProps && 
	    prevProps.authData &&
	    prevProps.authData.appState.neighborhoods && 
	    prevProps.authData.appState.neighborhoods.forEach(item => {
		    console.log(tag.id, item.id);
		    if(item.id == tag.id){found=true};
	    });
	    
	    return !found;
	    
    })
    :
    hoods;
    
    if(!last_hood || last_hood.length <=0){
	    last_hood = hoods.slice(hoods.length-1);
    }
    
    console.log(last_hood, hoods);
    // const polygons = features.map(feature => feature.geometry.coordinates);
    const polygons = last_hood.map(hood => {
		      const coords = hood.coordinates;
		      return [coords.slice(0, coords.length - 1)];

    });

    // L.Polygon returns lat and lng values reversed
    // meaning 'lat' key maps to long value / 'lng' key maps to lat value
    const fit = new L.Polygon(polygons).getBounds(); 
    const southWest = new mapboxgl.LngLat(fit.getSouthWest().lat, fit.getSouthWest().lng);
    const northEast = new mapboxgl.LngLat(fit.getNorthEast().lat, fit.getNorthEast().lng);      
    const boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
    const center = boundBox.getCenter();
    const [swLng, swLat] = boundBox.getSouthWest().toArray();
    const [neLng, neLat] = boundBox.getNorthEast().toArray();

    let bounds;
    if (polygons.length === 1) bounds = [boundBox.getSouthWest().toArray(), boundBox.getNorthEast().toArray()];
    else bounds = [[swLng - 0.011, swLat - 0.011], [neLng + 0.011, neLat + 0.011]];

    //this.map.fitBounds(bounds, {maxZoom: 17});
    this.map.flyTo({center: center, zoom:15, pitch:0});
  }
  
  _setBuilding(buildingData){
	  console.log("_setBuilding called", buildingData)
	  const zoom = this.map.getZoom();
	  // const polygonQuery= MapUtil._createPolygonQuery(this.map.getBounds());
    // console.log(polygonQuery.q);
	  
	  if(this.props.axios && this.props.axios.buildingAxios && this.props.axios.buildingAxios[0]){
/*
		  if(buildingData.geocode){
			
				var geocode = buildingData.geocode.split(", ");
				//let query = "geocode="+buildingData.geocode;
				 _getEvents(buildingData.geocode, {callback:function(data){
			       console.log(data);
			       
		       },url:url});
		  }
*/

		  if(buildingData.polygon_json){
				var polygon_json = JSON.parse(buildingData.polygon_json);
				var polygon = polygon_json.coordinates;
				var fit = new L.Polygon(polygon).getBounds();
				var southWest = new mapboxgl.LngLat(fit['_southWest']['lat'], fit['_southWest']['lng']);
				var northEast = new mapboxgl.LngLat(fit['_northEast']['lat'], fit['_northEast']['lng']);			
				var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
				var center = boundBox.getCenter();
				
				console.log(center);
				this.map.flyTo({center: center, zoom:18, pitch:0});

		  } else if(buildingData.geocode) {
		  	console.log('buildingData.geocode', buildingData.geocode)
				var geocode = typeof buildingData.geocode === 'string'
					? buildingData.geocode.split(", ")
					: buildingData.geocode;
				var lngLat = typeof buildingData.geocode !== 'string'
					? buildingData.geocode
					: new mapboxgl.LngLat(geocode[1],geocode[0])
				// var lngLat = new mapboxgl.LngLat(geocode[1],geocode[0]);
				console.log(lngLat);
				
				this.map.flyTo({center: lngLat, zoom:18, pitch:0});

				// future_building marker
				if(lngLat) {
					console.log("setting marker")
					var map = this.map
					var geojson = {
					  type: 'FeatureCollection',
					  features: [{
					    type: 'Feature',
					    geometry: {
					      type: 'Point',
					      coordinates: typeof buildingData.geocode !== 'string'
					      	? buildingData.geocode
					      	: [geocode[1], geocode[0]]
					    },
					    properties: {
					      title: 'Future Building',
					      description: buildingData.display_full_street_address ? buildingData.display_full_street_address : buildingData.full_street_address
					    }
					  }]
					};

					const boro = buildingData.borough 
						? Array.isArray(buildingData.borough) && _shouldShow(buildingData.borough[0])
							? _nameToUrl(buildingData.borough[0])
							: _shouldShow(buildingData.borough)
								? _nameToUrl(buildingData.borough)
								: 'new-york'
						: 'new-york';	
					
					const hood = buildingData.neighborhood 
						? Array.isArray(buildingData.neighborhood) && _shouldShow(buildingData.neighborhood[0])
							? _nameToUrl(buildingData.neighborhood[0])
							: _shouldShow(buildingData.neighborhood)
								? _nameToUrl(buildingData.neighborhood)
								: 'nyc'
						: 'nyc';	

					const slug = 	buildingData.building_slugs 
						? Array.isArray(buildingData.building_slugs) && _shouldShow(buildingData.building_slugs[0])
							? buildingData.building_slugs[0]
							: this.props.buildingTags && this.props.buildingTags.length === 1
								? this.props.buildingTags[0].slug
								: false
						: false
					
					// remove other future_building markers
					var markerList = document.getElementsByClassName('future-building-marker-js');
		  		console.log('future_building remove other markers', markerList)
		  		if(markerList) {
		  			for (var marker of markerList) {
		  				marker.remove();
						}
		  		}

					geojson.features.forEach(function(marker) {
					  // create a HTML element for each feature
						var el;
						if(slug) {  
						  el = document.createElement('a');
						  var icon = document.createElement('i');
						  icon.className = 'fa fa-map-marker mapbox-marker';
							el.appendChild(icon);
							// link.title = "my title text";
							el.href = `/building/${boro}/${hood}/${slug}?tab=overview`;
						  el.target = "_blank";
						  el.className = `u_link future-building-marker-js`;
						  el.id = `${slug}`;
						} else {
							el = document.createElement('i');
					  	el.className = 'fa fa-map-marker mapbox-marker future-building-marker-js';
						  el.id = `${slug}`;
						}

					  // make a marker for each feature and add to the map
					  var marker = new mapboxgl.Marker(el)
					    .setLngLat(marker.geometry.coordinates)
					    .addTo(map);
					});
				}
		  }

		  // checks for past markers and removes all
	  	if(!buildingData.future_building) {	
	  		var markerList = document.getElementsByClassName('future-building-marker-js');
		  	console.log('other building remove other markers', markerList)
	  		if(markerList) {
	  			for (var marker of markerList) {
	  				marker.remove();
					}
	  		}
	  	}
		  
		  building_tileset.forEach((tileset, i) => {	
		  	this.map.setFilter(tileset.layer_id+"-select",["==", "key", buildingData.key ? buildingData.key : "FALSE"])
		  	// .setFilter(`mp-building-bbl`, ["==", "metadata", this.props.buildingTags[0].slug]);		
				
				let bblMapLayerExists = false 
				if(this.props.buildingTags && this.props.buildingTags.length > 0) {		  	
		  		bblMapLayerExists = this.map.getLayer(`mp-building-bbl`);
		  		
		  		if(bblMapLayerExists && bblMapLayerExists.metadata !== this.props.buildingTags[0].slug) {
		  			this.map.removeLayer('mp-building-bbl').removeSource('mp-building-bbl');
		  			bblMapLayerExists = false
		  		}
		  	}

		  	if(polygon && polygon.length > 0 && !bblMapLayerExists) {
			  	this.map.addLayer({
						id: `mp-building-bbl`,
						type: "fill",
						'metadata': this.props.buildingTags[0].slug,
						'source': {
							'type': 'geojson',
							'data': {
								'type': 'Feature',
								'geometry': {
									'type': 'Polygon',
									'coordinates': polygon
								},
							},
						},
						minZoom: 18,
						paint: {
							"fill-outline-color": "#000",
							"fill-color": "transparent",
						}
					});

				}
		  });
		  
	  } else {
		  building_tileset.forEach(tileset => {
		  	this.map.setFilter(tileset.layer_id+"-select",["==", "key", "FALSE"]);
		  });
	  }
  }
  
  _resetMap(){
	  const { lng, lat, zoom } = defaultMap;
	  this.map.flyTo({center: [lng, lat], zoom:zoom});
  }
  
  _filterMap(){
	  let updateMap =  this._getMapFilters();
	  
	  building_tileset.forEach(tileset => {
	  
		  if(updateMap){
			  this.map.setFilter(tileset.layer_id,updateMap.filter);	  
			  this.map.setPaintProperty(tileset.layer_id, 'fill-opacity', updateMap.fillOpacity);
			  this.map.setPaintProperty(tileset.layer_id, 'fill-color', updateMap.fillColor);
		  }else{
			  this.map.setFilter(tileset.layer_id,null);
			  this.map.setPaintProperty(tileset.layer_id, 'fill-opacity', buildingStyle.fillOpacity);
			  this.map.setPaintProperty(tileset.layer_id, 'fill-color', buildingStyle.fillColor);
			  //this._resetMap();
		  }
	  });
  }
  
  shouldComponentUpdate(nextProps, nextState){
	if (this.state.mapLoaded !== nextState.mapLoaded ||
		this.state.hoveredStateId !== nextState.hoveredStateId ||
		//!_isEqual(this.props.authData.appState.buildingsSelected, nextProps.authData.appState.buildingsSelected) ||
		//!_isEqual(this.props.authData.appState.neighborhoods, nextProps.authData.appState.neighborhoods) ||
	  !_isEqual(
        nextProps.buildingTags,
        this.props.buildingTags
      ) ||
      !_isEqual(
        nextProps.buildingSearchFilterTags,
        this.props.buildingSearchFilterTags,
        "filter"
      ) || 
        !_isEqual(
          nextProps.buildingSearchFilterTags,
          this.props.buildingSearchFilterTags,
          "val"
        ) ||
        !_isEqual(
          nextProps.buildingSearchBtnFilterTags,
          this.props.buildingSearchBtnFilterTags,
          "filter"
        ) ||
        !_isEqual(
            nextProps.buildingSearchBtnFilterTags,
            this.props.buildingSearchBtnFilterTags,
            "val"
          ) ||
       !_isEqual(
          nextProps.buildingSearchTags,
          this.props.buildingSearchTags
        ) ||
        !_isEqual(this.props.boroTags, nextProps.boroTags) ||
        !_isEqual(this.props.hoodTags, nextProps.hoodTags, "hood"))
     {
	    return true;
	}else{
		return false
	}
	  
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	 console.log("MapSearch didUpdate", this.props, prevProps,this.state, prevState);
	 const differentTags =
      !_isEqual(prevProps.hoodTags, this.props.hoodTags, "hood") ||
      !_isEqual(prevProps.boroTags, this.props.boroTags) ||
      !_isEqual(
        prevProps.listingSearchFilterTags,
        this.props.listingSearchFilterTags,
        "filter"
      ) ||
      !_isEqual(
        prevProps.listingSearchFilterTags,
        this.props.listingSearchFilterTags,
        "val"
      ) ||
      !_isEqual(
        prevProps.listingSearchBtnFilterTags,
        this.props.listingSearchBtnFilterTags,
        "filter"
      ) ||
      !_isEqual(
        prevProps.listingSearchBtnFilterTags,
        this.props.listingSearchBtnFilterTags,
        "val"
      ) ||
      !_isEqual(prevProps.listingSearchTags, this.props.listingSearchTags) ||
      !_isEqual(
        prevProps.listingSearchSortTags,
        this.props.listingSearchSortTags,
        "urlHash"
      ) ||
      !_isEqual(prevProps.subTabTags, this.props.subTabTags) ||
      !_isEqual(this.props.boroTags, prevProps.boroTags) ||
      !_isEqual(this.props.hoodTags, prevProps.hoodTags, "hood");
	 
	 if(this.state.mapLoaded){		 
		 this._filterMap();
	 }
	 
/*
 	let sidebarOuter = document.getElementsByClassName("c_map-side-bar-outer")
		? document.getElementsByClassName("c_map-side-bar-outer")[0]
		: 0 
	let sidebarInner = document.getElementsByClassName("c_map-side-bar")
		? document.getElementsByClassName("c_map-side-bar")[0]
		: 0 
	if(sidebarOuter && sidebarInner && sidebarInner.offsetHeight >= sidebarOuter.offsetHeight){
		sidebarOuter.classList.add("c_overflow");
	}
*/
	if(this.state.mapLoaded && (
		!_isEqual(this.props.buildingTags, prevProps.buildingTags)
		|| (this.state.mapLoaded !== prevState.mapLoaded && this.props.buildingTags && this.props.buildingTags.length>0))
	){
		let buildingData = !_isEqual(this.props.buildingTags, prevProps.buildingTags)
			? this.props.buildingData
				? this.props.buildingData
				: this.props.axios && this.props.axios.buildingAxios && this.props.axios.buildingAxios[0]
					? this.props.axios.buildingAxios[0]
					: false
			: this._buildingData;

		if (buildingData && buildingData.future_building &&
				(buildingData.full_street_address ||
				buildingData.display_full_street_address)
			) 
	  {
	  	// const gmapApiArr = process.env.GMAP_API_KEY;
  		// const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
  		const buildingAdr = buildingData.display_full_street_address
  			? buildingData.display_full_street_address
  			: buildingData.full_street_address;
  		
  		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${buildingAdr}, ${buildingData.borough ? buildingData.borough : "new york"}, ${buildingData.zip}`
	  		// this.setState({fetchInProgress: true})
				_getGeocoding(buildingData, {
					callback: function(res) {
						if (res.results && res.results[0] && res.results[0].geometry && res.results[0].geometry.location) {
						//mapbox version
						// if (res && res.features && res.features[0]) {
							console.log('getGeocoding res', res)
							const resp = res.results[0].geometry.location;
							buildingData.geocode = [resp.lng, resp.lat];
							console.log('getGeoCode buildingData',buildingData);
	  					// this.setState({fetchInProgress: false})
							console.log('didUPdate buildingData', buildingData)
							this._setBuilding(buildingData);
						}
					}.bind(this),
					url: url
					// url: mapBoxGeoUrl, token: mapboxgl.accessToken
				});
		
		} else {
			this._setBuilding(buildingData);
		}	
	}

	if(this.state.mapLoaded && (
		(this.state.mapLoaded !== prevState.mapLoaded))
	){
		this._setNeighborhood(prevProps);
	}
	
/*
	if (this.props.boroTags.length > 0 && (differentTags || this.state.mapLoaded !== prevState.mapLoaded)) {
      const tags = this.props.boroTags.filter(tag => prevProps.boroTags.indexOf(tag) < 0);
      const tag = tags[tags.length - 1] || this.props.boroTags[0];
	    this.map.flyTo({
        center: BOROUGHS[_capitalizeText(tag)].center,
        zoom: this.props.isMobile ? 13 : 15,
	    })
    } 
*/
  }

  componentDidMount() {
    console.log("MapSearch didMount", this.props)
    let singleBuildingLot = false;


    const { lng, lat, zoom } = this.state;
		this.tooltipContainer = document.createElement('div');
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/ningzhou/cjvpf64h318z01cn8axood9r9',
      center: [lng, lat],
      zoom,
      minZoom: 11
    });

    if(this._buildingData) {
    	singleBuildingLot = true;
    	if (this._buildingData.polygon_json) {
				var polygon_json = JSON.parse(this._buildingData.polygon_json);
				var polygon = polygon_json.coordinates;
				console.log('polygon', polygon)

			} else if(this._buildingData.geocode){
				// var geocode = this._buildingData.geocode.split(", ");
				// var lngLat = new mapboxgl.LngLat(geocode[1],geocode[0]);
				var geocode = typeof this._buildingData.geocode === 'string'
					? this._buildingData.geocode.split(", ")
					: this._buildingData.geocode;
				var lngLat = typeof this._buildingData.geocode !== 'string'
					? this._buildingData.geocode
					: new mapboxgl.LngLat(geocode[1],geocode[0])
				console.log("lngLat", lngLat);		
			}
    }
    
    this.map.scrollZoom.disable();
		this.map.addControl(new mapboxgl.NavigationControl());
		this.map.addControl(new PitchToggle({minpitchzoom: this.props.fullSize ? 18 : 17, buildingRes: null}), 'top-right'); 
		this.geoLocate = new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			fitBoundsOptions:{
				maxZoom:17
			},
			trackUserLocation: false
		});
	
		this.map.addControl(this.geoLocate);	
		this.map.on('load', function () {
			console.log("Map Loaded", this.map);
			this.tooltip = new mapboxgl.Marker(this.tooltipContainer, {
		      offset: [0, -76]
		    }).setLngLat([0,0]).addTo(this.map);

			//this.geoLocate.trigger();
			
			this.map.addSource(neighborhood_tileset.id, {
				"type": "vector",
				"url": neighborhood_tileset.url
		  });
		  const layers = this.map.getStyle().layers;
		  const labelLayerId = layers.find(
				(layer) => layer.type === 'symbol' && layer.layout['text-field']
				).id;
		  this.map.addLayer({
					'id': 'add-3d-buildings',
					'source': 'composite',
					'source-layer': 'building',
					'filter': ['==', 'extrude', 'true'],
					'type': 'fill-extrusion',
					'minzoom': 14,
					'layout' : {
						'visibility' : 'none',
					},				
					'paint': {
						'fill-extrusion-color': '#aaa',
						'fill-extrusion-height': [
							'interpolate',
							['linear'],
							['zoom'],
							15,
							0,
							15.05,
							['get', 'height']
						],
						'fill-extrusion-base': [
							'interpolate',
							['linear'],
							['zoom'],
							15,
							0,
							15.05,
							['get', 'min_height']
						],
						'fill-extrusion-opacity': 0.6
					}
				},
			labelLayerId
			);
	  
		  	building_tileset.forEach(tileset => {
			  	this.map.addSource(tileset.id, {
					"type": "vector",
					"url": tileset.url
			  	});
			});
			 
		 	pipeline_tileset.forEach(tileset => {
			  	this.map.addSource(tileset.id, {
					"type": "vector",
					"url": tileset.url
			  	});
			 });

			 
			building_tileset.forEach((tileset, i) => {	
			// ALL buildings footprints - adds a layer for all the building outlines
			  this.map.addLayer({
					"id": tileset.layer_id,
					"type": "fill",
					"source": tileset.id,
					"source-layer": tileset.source_layer,
					'minZoom': 18,
					"paint": {
						"fill-outline-color": "#000",
						"fill-color": buildingStyle.fillColor,
						"fill-opacity": buildingStyle.fillOpacity,
					},
					//"filter": ["in", "year_built", "2000"]
				});

			// lot footprint
				if(polygon && polygon.length > 0 && singleBuildingLot && i === 0) {
					this.map.addLayer({
						id: `mp-building-bbl`,
						type: "fill",
						'metadata': this.props.buildingTags[0].slug,
						'source': {
							'type': 'geojson',
							'data': {
								'type': 'Feature',
								'geometry': {
									'type': 'Polygon',
									'coordinates': polygon
								},
							},
						},
						minZoom: 18,
						paint: {
							"fill-outline-color": "#000",
							"fill-color": "transparent",
						}
					});
				}
			});
			pipeline_tileset.forEach((tileset, i) => {	
			// ALL buildings footprints - adds a layer for all the building outlines
			  this.map.addLayer({
					"id": tileset.layer_id,
					"type": "fill",
					"source": tileset.id,
					"source-layer": tileset.source_layer,
					'minZoom': 18,
					"paint": {
						"fill-outline-color": "#000",
						"fill-color": "#780F9E",
						"fill-opacity": buildingStyle.fillOpacity,
					},
					//"filter": ["==", "zip", "10013"]
				});
			})
	
			if(!this.props.isMobile){	
/*				building_tileset.forEach(tileset => {	
						 this.map.addLayer({
								"id": tileset.layer_id+"-hover",
								"type": "fill",
								"source": tileset.id,
								"source-layer": tileset.source_layer,
								'minzoom': 17,
								"paint": {
									"fill-outline-color": "#000",
									"fill-color": "#B070CC",
									"fill-opacity": .8
								},
								"filter": ["==", "key", false]
						 });
					});
				}	
				 
				building_tileset.forEach(tileset => {	
					this.map.addLayer({
							"id": tileset.layer_id+"-select",
							"source": tileset.id,
							"source-layer": tileset.source_layer,
							'minZoom': 17,
							"type": "fill",
							"paint": {
								"fill-outline-color": "#000",
								"fill-color": "#28C2FF",
								"fill-opacity": .8
							},
							"filter": ["==", "key", "FALSE"]
					});
				});
				

				building_tileset.forEach(tileset => {	
					//Skip mouse events for mobile
					if(!this.props.isMobile) {		 
						this.map.on("mousemove", tileset.layer_id, function(e) {			
							this._onMouseMove(e);			
						}.bind(this));
						this.map.on("mouseout", tileset.layer_id, function(e) {
								this._onMouseOut();			
						}.bind(this));		
					}	
					this.map.on("click", tileset.layer_id, function(e) {
							this._setMouseClick(e);			
					}.bind(this));
			  	});
*/
			  	
			  	
			  	pipeline_tileset.forEach(tileset => {	
						 this.map.addLayer({
								"id": tileset.layer_id+"-hover",
								"type": "fill",
								"source": tileset.id,
								"source-layer": tileset.source_layer,
								'minzoom': 17,
								"paint": {
									"fill-outline-color": "#000",
									"fill-color": "#B070CC",
									"fill-opacity": .8
								},
								"filter": ["==", "key", false]
						 });
					});
				}	
				 
				pipeline_tileset.forEach(tileset => {	
					this.map.addLayer({
							"id": tileset.layer_id+"-select",
							"source": tileset.id,
							"source-layer": tileset.source_layer,
							'minZoom': 17,
							"type": "fill",
							"paint": {
								"fill-outline-color": "#000",
								"fill-color": "#28C2FF",
								"fill-opacity": .8
							},
							"filter": ["==", "key", "FALSE"]
					});
				});
			  	pipeline_tileset.forEach(tileset => {	
					//Skip mouse events for mobile
					
					this.map.on("mousemove", tileset.layer_id, function(e) {
						console.log(tileset)			
						this._onMouseMove(e);			
					}.bind(this));
					this.map.on("mouseout", tileset.layer_id, function(e) {
							this._onMouseOut();			
					}.bind(this));		
						
					this.map.on("click", tileset.layer_id, function(e) {
							this._setMouseClick(e);			
					}.bind(this));
			  	});
			  
			  
			  
			  
	/*
			  this.geoLocate.on("error", function(e) {			
				console.log(e);	
			  }.bind(this));
			  
			   this.geoLocate.on("geolocate", function(e) {			
				console.log(e);	
			  }.bind(this));
	*/
		  	
		  
	/*
		  this.map.addLayer({
				"id": "mp-building-layer",
				"type": "fill",
				"source": building_tileset.id,
				"source-layer": building_tileset.source_layer,
				'minZoom': 15,
				"paint": {
					"fill-outline-color": "#000",
					"fill-color": ["case",
					["boolean", ["feature-state", "hover"], false],
						"#2B1ACC",
						buildingStyle.fillColor
					],
					"fill-opacity": ["case",
						["boolean", ["feature-state", "hover"], false],
							1,
							buildingStyle.fillOpacity
					],	
				},
				//"filter": ["in", "year_built", "2000"]
			 });
	*/
			 
		  
	/*
			 this.map.addLayer({
					"id": "mp-neighborhood-layer",
					"type": "fill",
					"source": neighborhood_tileset.id,
					"source-layer": neighborhood_tileset.source_layer,
					"paint": {
						"fill-outline-color": "#000",
						"fill-color": "#BFC4D1",
						"fill-opacity": .4
					},
			 });
	*/
			 
			 this.map.addLayer({
		        "id": "mp-neighborhood-layer",
		        "source": neighborhood_tileset.id,
		        "source-layer": neighborhood_tileset.source_layer,
		        "type": "fill",
				"paint": {
					"fill-outline-color": "#000",
					"fill-color": "#363347",
					"fill-opacity": .1
				},
		        "filter": ["in", "name","FALSE"],
		      });
		      
		      this.map.addLayer({
		        "id": "mp-neighborhood-layer-outline",
		        "source": neighborhood_tileset.id,
		        "source-layer": neighborhood_tileset.source_layer,
		        "type": "line",
		        "paint": {
						'line-color': '#363347',
						'line-width': 1
					},
		        "filter": ["in", "name","FALSE"],
		      });

			
	/*
			this.map.on("mousemove", "3d-buildings", function(e) {
				if (e.features.length > 0) {
					if (this.hoveredStateId) {
						this.map.setFeatureState({source: 'composite', sourceLayer:"building", id: this.hoveredStateId}, { hover: false});
					}
					this.hoveredStateId = e.features[0].id;
					console.log(e.features);
					this.map.setFeatureState({source: 'composite', sourceLayer:"building", id: this.hoveredStateId}, { hover: true});
				}
			}.bind(this));
			
			this.map.on("mouseleave", "3d-buildings", function() {
				if (this.hoveredStateId) {
					this.map.setFeatureState({source: 'composite',sourceLayer:"building", id: this.hoveredStateId}, { hover: false});
				}
				this.hoveredStateId =  null;
			}.bind(this));
	*/
			// future_building marker
			if(lngLat) {
				console.log("setting marker didMount")
				var map = this.map
				var geojson = {
				  type: 'FeatureCollection',
				  features: [{
				    type: 'Feature',
				    geometry: {
				      type: 'Point',
				      coordinates: typeof this._buildingData.geocode !== 'string'
					      	? this._buildingData.geocode
					      	: [geocode[1], geocode[0]]
				    },
				    properties: {
				      title: 'Future Building',
				      description: this._buildingData.display_full_street_address ? this._buildingData.display_full_street_address : this._buildingData.full_street_address
				    }
				  }]
				};

				const boro = this._buildingData.borough 
						? Array.isArray(this._buildingData.borough) && _shouldShow(this._buildingData.borough[0])
							? _nameToUrl(this._buildingData.borough[0])
							: _shouldShow(this._buildingData.borough)
								? _nameToUrl(this._buildingData.borough)
								: 'new-york'
						: 'new-york';	
					
					const hood = this._buildingData.neighborhood 
						? Array.isArray(this._buildingData.neighborhood) && _shouldShow(this._buildingData.neighborhood[0])
							? _nameToUrl(this._buildingData.neighborhood[0])
							: _shouldShow(this._buildingData.neighborhood)
								? _nameToUrl(this._buildingData.neighborhood)
								: 'nyc'
						: 'nyc';

					const slug = 	this._buildingData.building_slugs 
						? Array.isArray(this._buildingData.building_slugs) && _shouldShow(this._buildingData.building_slugs[0])
							? this._buildingData.building_slugs[0]
							: this.props.buildingTags && this.props.buildingTags.length === 1
								? this.props.buildingTags[0].slug
								: false
						: false

					geojson.features.forEach(function(marker) {
					  // create a HTML element for each feature
						var el;
						if(slug) {  
						  el = document.createElement('a');
						  var icon = document.createElement('i');
						  icon.className = 'fa fa-map-marker mapbox-marker';
							el.appendChild(icon);
							// link.title = "my title text";
							el.href = `/building/${boro}/${hood}/${slug}?tab=overview`;
						  el.target = "_blank";
						  el.className = 'u_link future-building-marker-js';
						} else {
							el = document.createElement('i');
					  	el.className = 'fa fa-map-marker mapbox-marker future-building-marker-js';
						}

				  // make a marker for each feature and add to the map
				  new mapboxgl.Marker(el)
				    .setLngLat(marker.geometry.coordinates)
				    .addTo(map);
				});
			}

			this._setNeighborhood(false);
			this.setState({mapLoaded:true})
			
			if(this.props.isMobile){
				//NZ Super hacky code to keep map fixed on mobile IOS
				if(document.getElementsByClassName("c_map-wrapper") && document.getElementsByClassName("c_seconday-nav-container")[0] && document.getElementById("search-container")){
					document.getElementsByClassName("c_map-wrapper")[0].style.position="fixed";
					document.getElementsByClassName("c_map-wrapper")[0].style.top="167px";
					document.getElementsByClassName("c_map-wrapper")[0].style.zIndex="8";
					document.getElementsByClassName("c_map-wrapper")[0].style.width="100%";
					document.getElementsByClassName("c_seconday-nav-container")[0].style.position="fixed";
					document.getElementsByClassName("c_seconday-nav-container")[0].style.top="63px";
					document.getElementsByClassName("c_seconday-nav-container")[0].style.zIndex="10";
					document.getElementsByClassName("c_seconday-nav-container")[0].style.width="100%";
					document.getElementById("search-container").style.position="fixed";
					document.getElementById("search-container").style.top="109px";
					document.getElementById("search-container").style.zIndex="9";
					document.getElementById("search-container").style.width="100%";
				}
	// 			document.getElementsByClassName("c_seconday-nav-container")[0].style.overflow = "hidden";
				document.ontouchmove = function (e) {
				  e.preventDefault();
				}
			} else {
  /*
				if((window.localStorage && !window.localStorage.getItem('-LeYu4PUpv6GbQ5AAuR-'))){
			    	//window.Appcues && window.Appcues.show('-LeYu4PUpv6GbQ5AAuR-');
					//window.localStorage && window.localStorage.setItem('-LeYu4PUpv6GbQ5AAuR-', true);
				}
  */
			}
		}.bind(this));

    this.map.on('move', () => {
      const { lng, lat } = this.map.getCenter();

      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2)
      });
    });
    
    this.map.on('click', (e) => {
    	console.log('map on click', e, e.features)
       if(!e.features){
	      // this.props.updateBuildings({buildingsSelected:[]});
       }
    });
  }

  componentWillUnmount() {
    console.log("BS unmounting");
    this._isMounted = false;
    this.map.remove();
    if(this.props.isMobile){
	    //NZ Super hacky code to keep map fixed on mobile IOS
	    if(document.getElementsByClassName("c_map-wrapper") && document.getElementsByClassName("c_seconday-nav-container")[0] && document.getElementById("search-container")){
		    document.getElementsByClassName("c_map-wrapper")[0].style.position=null;
				document.getElementsByClassName("c_map-wrapper")[0].style.top=null;
				document.getElementsByClassName("c_map-wrapper")[0].style.zIndex=null;
				document.getElementsByClassName("c_map-wrapper")[0].style.width=null;
				document.getElementsByClassName("c_seconday-nav-container")[0].style.position=null;
				document.getElementsByClassName("c_seconday-nav-container")[0].style.top=null;
				document.getElementsByClassName("c_seconday-nav-container")[0].style.zIndex=null;
				document.getElementsByClassName("c_seconday-nav-container")[0].style.width=null;
				document.getElementById("search-container").style.position=null;
				document.getElementById("search-container").style.top=null;
				document.getElementById("search-container").style.zIndex=null;
				document.getElementById("search-container").style.width=null;
	    }
		}
  }
  
  _openBuilding(building){
	  let address = _replaceAbbreviations(building.display_full_street_address).toLowerCase();
	  if(address.indexOf("garage")<0
	  	&& address.indexOf("rear")<0){
		  window.open("/building/" + _nameToUrl(building.borough) + "/" + _nameToUrl(building.main_neighborhood) + "/" + building.main_building_slug + "?tab=overview")
	  }
  }
  
  _showBuilding(building){
	  const gmapApiArr = process.env.GMAP_API_KEY;
	  const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
	  let buildingImages = _getBuildingPhotos(building, gApiKey);
	  let image = `https://maps.googleapis.com/maps/api/streetview?${gApiKey}&size=700x700&location=${
										building.display_full_street_address
											? building.display_full_street_address
											: building.full_street_address
									},${building.zip}&fov=90&pitch=10&source=outdoor`;
	  if (buildingImages.length == 0) {
		 buildingImages.push(image);
	  }
	  let adr = building.display_full_street_address
			? _capitalizeText(
					building.display_full_street_address
			  ).trim()
			: building.primary_full_street_address
			? _capitalizeText(
					building.primary_full_street_address
			  ).trim()
			: building.full_street_address
			? _capitalizeText(building.full_street_address).trim()
			: false;
	
		if (
			building.borough == "queens" &&
			buildingName &&
			building.alternate_addresses
		) {
			adr = _formatQueensAddress(
				adr,
				building.alternate_addresses
			);
		}
	
		let buildingName = building.name && building.name != building.display_full_street_address
			? _capitalizeText(
					_urlToName(building.name.replace("corporation", ""))
			  )
			: false;
					
	  return(
			<div className={`c_map-building-container`}
				key={`${building.key}`} 
				onClick={(e)=> e.target.id != "mp_close" && this._openBuilding(building)}
			>
				
				<div className={`row`}>
					<div className={`col-xs-5 u_pd-rt-none`}>
						<div className={`img-responsive c_building-img`} style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}>
							<span className={`c_building-type`}>{_capitalizeText(_urlToName(building.ownership_type))}</span>
						</div>
					</div>
					<div className={`col-xs-7 u_pd-left-none c_building-info`}>
						<div className={`u_pd-5px`}>
							<div className="c_title u_600-wgt">{buildingName ? buildingName : adr} <i id="mp_close" className="fa fa-times" aria-hidden="true" onClick={()=>this.props.updateBuildings({buildingsSelected:[]})}></i></div>
							<div className="u_font-11">{buildingName ? adr + ", " : "" }</div>
							<div className="u_font-11">{building.main_neighborhood ? _capitalizeText(building.main_neighborhood) + ", " : ""}{_capitalizeText(building.borough)}</div>
							
							<div className="u_font-12 u_pd-top-5px">
								{_shouldShow(building.year_built)
									? moment().year() < building.year_built
										? "Proj Completion " + building.year_built
										: "Year Built " + building.year_built
									: ""}
								
							</div>
							<div className="u_font-12">
								{building.total_units &&
									(building.total_units > 1
										? building.total_units + " Units"
										: "")}
								{building.total_floors && building.total_floors>0
									? building.total_units > 1
										? " | " +
										  (building.total_floors > 1
												? building.total_floors + " Floors"
												: building.total_floors + " Floor")
										: building.total_floors > 1
										? building.total_floors + " Floors"
										: building.total_floors + " Floor"
									: false}
							</div>
						</div>
						<div className="c_tooltip-hint u_font-11">Click for details <i className="fa fa-external-link" aria-hidden="true"></i></div>
					</div>
				</div>
			</div>
		);
  }

  render() {
    const { lng, lat, zoom } = this.state;
    const header = document.getElementsByClassName("navbar").length > 0
      ? document.getElementsByClassName("navbar")[0].offsetHeight
      : 0

    const secondary_header = document.getElementsByClassName("mat-app-bar").length > 0
      ? document.getElementsByClassName("mat-app-bar")[0].offsetHeight
      : 0

    const search_container = document.getElementsByClassName("search-container").length > 0 && this.props.isMobile
      ? document.getElementsByClassName("c_seconday-nav-container")[0].offsetHeight
      : 0

    const mapHeight = window.innerHeight - header - secondary_header - search_container; 
	
		let bottomOffset = 50;	
		let sideBarHeight = 0;
		let showShadow = false;
		
		if(this.props.authData && this.props.authData.appState 
	    && this.props.authData.appState 
	    && this.props.authData.appState.buildingsSelected
	    && this.props.authData.appState.buildingsSelected.length>0
	    )
		{
			sideBarHeight = 110*this.props.authData.appState.buildingsSelected.length;
			
			if(sideBarHeight >= (mapHeight-bottomOffset)){
				sideBarHeight = mapHeight-bottomOffset;
				showShadow = true;
			}           
		}

		let dragging = false;
		var settings = {
	    dots: false,
	    infinite: true,
	    speed: 200,
	    slidesToShow: 4,
	    slidesToScroll: 1,
	    arrows:true,
	    beforeChange: () => dragging = true,
	  	afterChange: () => dragging = false,
	  };
			
    return (
      <div className={`c_map-wrapper ${this.props.isMobile ? "is-mobile" : ""}`} style={{position:"relative",height:mapHeight}}>
        {!this.props.isMobile && sideBarHeight>0 &&
          <div className={`c_map-side-bar-outer ${showShadow ? "c_overflow" : ""}`} style={{height:sideBarHeight}}>
            <div className="c_map-side-bar" style={{height:sideBarHeight}}>
            {this.props.authData.appState.buildingsSelected.map(building => {
	            return this._showBuilding(building);
            })}
            </div>
          </div>
        }

        <div className={`c_map-container ${this.props.isMobile ? "is-mobile" : ""}`} ref={el => this.mapContainer = el} style={{ ...containerStyle, minHeight: mapHeight}} ></div>
        {this.props.isMobile && sideBarHeight>0 &&
        <div className={`c_map-mobile-slider-outer`}>
        	{/*<Slider {...Object.assign({}, settings, {slidesToShow:1})}>	
      		{this.props.authData.appState.buildingsSelected.map(building => {
			  		return this._showBuilding(building);
					})}
    		</Slider>*/}
    	  </div>
    	}
      </div>
    );
  }
}

class Tooltip extends React.Component {
	render() {
		const { features } = this.props;
		const renderFeature = (feature, i) => {
			let buildingName =
				feature.properties.name &&
				feature.properties.name !=
					feature.properties.display_full_street_address
					? _capitalizeText(
							_urlToName(feature.properties.name.replace("corporation", ""))
					  )
					: false;
			const gmapApiArr = process.env.GMAP_API_KEY;
			const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
			let buildingImages = _getBuildingPhotos(feature.properties, gApiKey);
			return (
				<div key={i} className="c_map-tooltip">
					<div className="c_img">
						<div
							style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}
							className="c_tooltip-img"
						>
							<span className={`c_building-type`}>
								{_capitalizeText(_urlToName(feature.properties.ownership_type))}
							</span>
						</div>
					</div>
					<div className="c_info">
						<div className="c_tooltip-content">
							{buildingName ? (
								<div className="">
									<div className="c_title u_600-wgt">{buildingName}</div>
									{buildingName.toLowerCase() !== feature.properties.display_full_street_address.toLowerCase() &&
										<div className="u_font-12">
										{_capitalizeText(
											_replaceAbbreviations(
												feature.properties.display_full_street_address
											)
										)}
									</div>
									}
								</div>
							) : (
								<div className="c_title u_600-wgt">
									{_capitalizeText(
										_replaceAbbreviations(
											feature.properties.display_full_street_address
										)
									)}
								</div>
							)}
							<div className="u_font-11" style={{ paddingTop: "3px" }}>
								{_shouldShow(feature.properties.year_built)
									? moment().year() < feature.properties.year_built
										? "Proj Completion " + feature.properties.year_built
										: "Year Built " + feature.properties.year_built
									: ""}
							</div>
							<div className="u_font-11">
								{feature.properties.total_units &&
								feature.properties.total_units > 1
									? feature.properties.total_units + " Units"
									: ""}
								{feature.properties.total_units &&
								feature.properties.total_units > 1 &&
								feature.properties.total_floors &&
								feature.properties.total_floors > 1
									? " | "
									: ""}
								{feature.properties.total_floors &&
								feature.properties.total_floors > 1
									? feature.properties.total_floors + " Floors"
									: ""}
							</div>
							
						</div>
						<div className="c_tooltip-hint u_font-11">
							Click for details{" "}
							<i className="fa fa-external-link" aria-hidden="true" />
						</div>
					</div>
				</div>
			);
		};
		return <div>{features && features.slice(0, 1).map(renderFeature)}</div>;
	}
}

export default MatMapView;
