/* eslint-disable react/no-children-prop */
import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Badge,
  Table,
  Button,
  Row,
  Container,
} from "reactstrap";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";

import { Tooltip } from "@material-ui/core";
// import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from "redux-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import PreviewIcon from "mdi-react/EyeIcon";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "mdi-react/CloudDownloadIcon";
import IconShare from "mdi-react/AccountPlusOutlineIcon";
import ShareButton from "../../../shared/components/buttons/ShareButton";

import ExportIcon from "mdi-react/CloudDownloadIcon";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";

import GroupedUnitsSearchTableHead from "./GroupedUnitsSearchTableHead";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import BuildingIcons from "../../../shared/components/BuildingIcons";

import NotifyOnIcon from "mdi-react/BellIcon";
import NotifyOffIcon from "mdi-react/BellOffOutlineIcon";

import UnitsListTable from "./UnitsListTable";
import AddCollectionButton from "../../../shared/components/collections/AddCollectionButton";
import ExportButton from "../../../shared/components/data_export/ExportButton";

import Menu from "@material-ui/core/Menu";
import SnackBar from "../../../shared/components/SimpleSnackBar";

import UnitsSearchAppBar from "./UnitsSearchAppBar";

import {
  subscriptionPlans,
  _capitalizeText,
  _formatNumber,
  _slugifyText,
  BuildingStageBadge,
  BuildingCurrentStatus,
  _replaceAbbreviations,
  _getBuildingURL,
  _formatPrice,
  BuildingPhoto,
  UnitCurrentStatus,
  _getUnitFloorPlan,
  _nameToUrl,
  _formatBed,
  SalesProgress,
  propertyExportFL,
  buildingExportFL,
} from "../../../shared/helpers/utils";
import ReportButton from "../../../shared/components/buttons/ReportButton";
import CollectionReportButton from "../../SharePage/CollectionReportButton";

const generateShareLink = (group) => {
  let shareBaseURL =
    window.location.protocol + "//" + window.location.host + "/share/";

  if (group && group.custom_link) {
    return shareBaseURL + group.custom_link;
  }

  return shareBaseURL;
};

const ShowGroups = ({ data, toggleRefresh }) => {
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{"My Groups"}</h5>
          <h5 className="subhead"></h5>
        </div>
        <Table responsive className="table-striped trend-buildings">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Projects</th>
              <th>Units</th>
              <th>Updated</th>
              <th>Created</th>

              <th></th>
            </tr>
            {data &&
              Array.isArray(data) &&
              data.map((group, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className="group-subject">
                        <span className="bold-text">{group.group_name}</span>
                      </div>
                    </td>
                    <td>
                      {group.group_data &&
                        group.group_data.projects &&
                        group.group_data.projects.length}
                    </td>
                    <td>
                      {group.group_data &&
                        group.group_data.buildings &&
                        group.group_data.buildings.length}
                    </td>
                    <td>
                      <span
                        className="light-text small-text"
                        style={{ color: "#858AA1" }}
                      >
                        {moment(group.modified_timestamp).format(
                          "M/D/YYYY hh:mm A"
                        )}
                      </span>
                    </td>
                    <td>
                      <span
                        className="light-text small-text"
                        style={{ color: "#858AA1" }}
                      >
                        {moment(group.created_timestamp).format(
                          "M/D/YYYY hh:mm A"
                        )}
                      </span>
                    </td>

                    <td>
                      {/*/<AddCollectionButton buildingRes={item} linkID={group.link_id} property={group.property ? group.property  : false} group={group} toggleRefresh={toggleRefresh} shareLink={shareLink}/>*/}
                    </td>
                    {/*<td className="views">
							<span className="view-count">
								  {i+1}
							  </span> 
							  
						</td>*/}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const CollectionExportButton = ({ group }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackShow, setShowSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState(null);
  const [snackType, setSnackType] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const snackOpen = (message, type) => {
    setSnackMessage(message);
    setSnackType(type);
    setShowSnack(true);
  };

  const snackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMessage(null);
    setShowSnack(false);
  };

  if (
    group &&
    group.group_data &&
    group.group_data.buildings &&
    group.group_data.buildings.length > 0 &&
    group.group_data.projects &&
    group.group_data.projects.length > 0
  ) {
    return (
      <div>
        <SnackBar
          snackShow={snackShow}
          snackMessage={snackMessage}
          snackClose={snackClose}
          snackDuration={5000}
          snackType={snackType}
        />
        <Tooltip
          title={`Export for Buildings or Projects in This Collection (CSV)`}
        >
          <IconButton
            color="inherit"
            className={"material-table__toolbar-button"}
            onClick={handleClick}
          >
            {" "}
            <ExportIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <ExportButton
            q={`key:(${group.group_data.projects.join(" ")})`}
            fq={[]}
            fl={buildingExportFL}
            sort={"key asc"}
            core_name={"we3-buildings"}
            export_name={`Collection: ${group.group_name} Projects`}
            title={"Export Projects in This Collection (CSV)"}
            description={
              <Fragment>
                <h4>
                  <strong>Export Projects for your own analysis.</strong>
                </h4>{" "}
                <br /> The export will include the profile of the building,
                sales progress, key dates and more.
              </Fragment>
            }
            menu_button={"Export Projects"}
            on_toggle={handleClose}
            parentSnackOpen={snackOpen}
          />
          <ExportButton
            q={`key:(${group.group_data.buildings.join(" ")})`}
            fq={[]}
            fl={propertyExportFL}
            sort={"key asc"}
            core_name={"we3-properties"}
            export_name={`Collection: ${group.group_name} Buildings`}
            title={"Export Buildings in This Collection (CSV)"}
            description={
              <Fragment>
                <h4>
                  <strong>Export buildings for your own analysis.</strong>
                </h4>{" "}
                <br /> The export will include details about unit status, bed,
                bath, size and more.
              </Fragment>
            }
            menu_button={"Export Buildings"}
            on_toggle={handleClose}
            parentSnackOpen={snackOpen}
          />
        </Menu>
      </div>
    );
  } else if (group && group.group_data && group.group_data.buildings.length > 0) {
    return (
      <ExportButton
        q={`key:(${group.group_data.buildings.join(" ")})`}
        fq={[]}
        fl={propertyExportFL}
        sort={"key asc"}
        core_name={"we3-properties"}
        export_name={`Collection: ${group.group_name} Buildings`}
        title={"Export Buildings in This Collection (CSV)"}
        custom_class={"material-table__toolbar-button"}
        description={
          <Fragment>
            <h4>
              <strong>Export buildings for your own analysis.</strong>
            </h4>{" "}
            <br /> The export will include details about building status, bed,
            bath, size and more.
          </Fragment>
        }
      />
    );
  } else if (
    group &&
    group.group_data &&
    group.group_data.projects &&
    group.group_data.projects.length > 0
  ) {
    return (
      <ExportButton
        q={`key:(${group.group_data.projects.join(" ")})`}
        fq={[]}
        fl={buildingExportFL}
        sort={"key asc"}
        core_name={"we3-buildings"}
        export_name={`Collection: ${group.group_name} Projects`}
        title={"Export Projects in This Collection (CSV)"}
        custom_class={"material-table__toolbar-button"}
        description={
          <Fragment>
            <h4>
              <strong>Export projects for your own analysis.</strong>
            </h4>{" "}
            <br /> The export will include the profile of the project, sales
            progress, key dates and more.
          </Fragment>
        }
      />
    );
  }

  return null;
};

const ShowExpanableGroups = ({
  groupedData,
  numFound,
  orderBy,
  order,
  rowsPerPage,
  page,
  paginationCount,
  toggleRefresh,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [hidePagination, setHidePagination] = useState(false);
  
  const handlePagination = (data) => {
    setHidePagination(data);
  }
  return (
    <Fragment>
      <div className="material-table__wrap dashboard">
        {groupedData && (
          <div className="material-table expansion__table">
            <Container>
              <Row className="expansion__summary__header">
                <Col lg={2} xs={12}></Col>
                <Col lg={4} xs={12}>
                  <TableSortLabel
                    active={orderBy === "count"}
                    direction={order}
                    onClick={(event) => handleRequestSort(event, "group_name")}
                    className="material-table__sort-label"
                    dir="ltr"
                  >
                    Collection
                  </TableSortLabel>
                </Col>

                <Col lg={2} className="no-padding">
                  <TableSortLabel
                    active={orderBy === "avg_price"}
                    direction={order}
                    onClick={(event) =>
                      handleRequestSort(event, "created_timestamp")
                    }
                    className="material-table__sort-label"
                    dir="ltr"
                  >
                    Created
                  </TableSortLabel>
                </Col>
                <Col lg={2} className="no-padding">
                  <TableSortLabel
                    active={orderBy === "avg_price"}
                    direction={order}
                    onClick={(event) =>
                      handleRequestSort(event, "modified_timestamp")
                    }
                    className="material-table__sort-label"
                    dir="ltr"
                  >
                    Updated
                  </TableSortLabel>
                </Col>
                <Col lg={2} className="no-padding"></Col>
              </Row>
            </Container>
            {groupedData.length === 0 && (
              <Container>
                <Row className="no__data">
                  <Col lg={12} xs={12} className="no__data-text">
                    No data found for your search.
                  </Col>
                </Row>
              </Container>
            )}
            {groupedData.map((group, i) => {
              const groupData =
                group && group.group_data ? group.group_data : false;
              return (
                <ExpansionPanel
                  TransitionProps={{ unmountOnExit: true }}
                  id={group.group_id}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls=""
                    id={group.group_id}
                  >
                    <Container>
                      <Row className="group_summary">
                        <Col lg={2} xs={12}>
                          <div className={isMobile ? "mb-2" : ""}>
                            <CollectionReportButton group={group} />
                          </div>
                        </Col>
                        <Col lg={4} xs={12}>
                          <div className="collection-info mb-1">
                            <span
                              className="building_name"
                              style={{ fontSize: 14 }}
                            >
                              {group.group_name}
                            </span>
                            {group.notify ?
                 
                                <NotifyOnIcon size={16} color={"#B070CC"} style={{marginLeft:3,marginTop:-5}}/>
                              
                            :
                           
                                <NotifyOffIcon size={16} color={"#A3ADC2"} style={{marginLeft:3,marginTop:-5}}/>
                             
                            }
                          </div>
                          <div>
                            <span className="small-text light-text">
                              Pipeline:{" "}
                              {groupData &&
                                groupData.projects &&
                                groupData.projects.length}{" "}
                            </span>
                            <span className="small-text light-text">
                              {" | "}
                            </span>
                            <span className="small-text light-text">
                              Existing:{" "}
                              {groupData &&
                                groupData.buildings &&
                                groupData.buildings.length}{" "}
                            </span>
                          </div>
                        </Col>
                        {!isMobile && (
                          <Fragment>
                            <Col lg={2} xs={7} className="no-padding">
                              <div className="light-text small-text">
                                {moment(group.created_timestamp).format(
                                  "M/D/YYYY hh:mm A"
                                )}
                              </div>
                            </Col>
                            <Col lg={2} xs={7} className="no-padding">
                              <div className="light-text small-text">
                                {moment(group.modified_timestamp).format(
                                  "M/D/YYYY hh:mm A"
                                )}
                              </div>
                            </Col>
                          </Fragment>
                        )}
                        {/*user, core_name, q, fq, fl, sort */}
                        <Col lg={2} xs={7} className="no-padding">
                          <div style={{ display: "flex" }}>
                            <AddCollectionButton
                              group={group}
                              toggleRefresh={toggleRefresh}
                              edit
                            />
                           {/* <CollectionExportButton group={group} /> */}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <UnitsListTable
                      isSelected={false}
                      group={group}
                      toggleRefresh={toggleRefresh}
                      handlePagination={handlePagination}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </div>
        )}
      </div>
      {numFound && !hidePagination ? (
        <TablePagination
          component="div"
          className="material-table__pagination"
          count={numFound}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          dir="ltr"
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
        />
      ) : null}
    </Fragment>
  );
};

const List = (props) => {
  const { user, groups, numFound, toggleRefresh, handleRequestSort, filters } =
    props;
  //console.log(user);
  const { subscriptionPlan } = user;
  const currentPlan = subscriptionPlans.filter(
    (plan) => plan.id === subscriptionPlan
  )[0];
  return (
    <Col md={12} lg={12}>
      {/*<ShowGroups data={groups} toggleRefresh={toggleRefresh} />	*/}
      <Card>
        <CardBody className="unit_search">
          <div className="text-right mb-3">
            <AddCollectionButton {...props} add />
          </div>
          <UnitsSearchAppBar
            onRequestSort={handleRequestSort}
            _filters={filters}
            {...props}
            numToShow={7}
          />
          {groups.length === 0 ? (
            <Container>
              <Row className="no__data">
                <Col lg={12} xs={12} className="no__data-text">
                  Nothing here yet.
                </Col>
              </Row>
            </Container>
          ) : (
            <ShowExpanableGroups groupedData={groups} {...props} />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default List;
