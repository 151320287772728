import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "mdi-react/ArrowBackIcon";
import LinkIcon from "mdi-react/LinkIcon";
import DomainIcon from "mdi-react/DomainIcon";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import EditIcon from "mdi-react/PencilIcon";
import moment from "moment";
import OpenIcon from "mdi-react/FileDocumentOutlineIcon";

import { Grid, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";

import Analysis from "./Analysis";
import Sales from "./Sales";

import BuyersList from "./BuyersList";
import Profile from "./Profile";
import QA from "./QA";

import AddToBuyersListButton from "../../../shared/components/buttons/AddToBuyersListButton";
import EditProject from "../../../shared/components/edit/EditProject";
import ShareButton from "../../../shared/components/buttons/ShareButton";
import ReportButton from "../../../shared/components/buttons/ReportButton";
import OwnerListButton from "../../../shared/components/data_export/OwnerListButton";

import { bindActionCreators } from "redux";
import buyersListActions from "../../../redux/actions/buyersListActions";

import NewsTimeline from "../../News/components/NewsTimeline";

import ProjectFeed from "./Feed/";

import LinearLoading from "../../../shared/components/LinearLoading";

import Gallery from "./Gallery";
import SavedCount from "../../../shared/components/SavedCount";

import { Publisher } from "./../../../shared/pubsub/publisher.js";

import TrialLimit from "../../../shared/components/payment/TrialLimit";

import LendingHistory from "./Profile/components/LendingHistory";
import OfferingHistory from "./Profile/components/OfferingHistory";

import Permits from "./Permits";

import Contacts from "./Contacts";
import Violations from "../../BuildingDetail/components/Violations";

import BuildingIcons from "../../../shared/components/BuildingIcons";
import OfferingStatus from "../../../shared/components/OfferingStatus";

import { _hasPermission } from "../../../shared/helpers/access";
import EnterpriseGate from "../../../shared/components/payment/EnterpriseGate";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  StatusBadge,
  BuildingCurrentStatus,
  BuildingStageBadge,
  propertyExportFL,
  _validUnitSources,
  propertyExportFLRestricted,
  mailingListFL,
  _formatQueensAddress,
  _formatNameAddress,
} from "../../../shared/helpers/utils";
import BuildingTransfer from "./Profile/components/BuildingTransfer";
import UnitsSearchTable from "../../Sales/components/UnitsSearchTable";
import AddCollectionButton from "../../../shared/components/collections/AddCollectionButton";

const apiURL = process.env.API_ROOT + "/query/";
class BuildingTabContainer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        this.props.match && this.props.match.params.tab
          ? this.props.match.params.tab
          : "overview",
      unitsRes: false,
      statsRes: false,
      qaMounted: false,
      gatedView: false,
      transactionRes: false,
      transactionDocs: false,
      hpdRes: null,
    };
  }

  q = "";

  componentDidMount() {
    const { buildingRes, user, viewedBuildings } = this.props;
    const { viewBuilding } = this.props.actions;

    const viewedCount =
      viewedBuildings && Array.isArray(viewedBuildings)
        ? viewedBuildings.length
        : 0;
    const maxViews = process.env.MAX_TRIAL_BUILDING_VIEWS;
    const remainingViews = maxViews - viewedCount;
    if (
      user &&
      user.subscriptionStatus &&
      user.subscriptionStatus === "trialing" &&
      remainingViews <= 0 &&
      viewedBuildings.filter((item) => item.key === buildingRes.key).length <= 0
    ) {
      this.setState({ gatedView: true });
    } else if (
      user &&
      user.subscriptionStatus &&
      user.subscriptionStatus === "trialing" &&
      remainingViews < 0
    ) {
      this.setState({ gatedView: true });
    } else {
      //this._getBuildingOPData();
      //this._getBuildingTransactionData();
      //this._getBuildingHPDData();
      viewBuilding(buildingRes.key, user);
      Publisher.publish(`track.projectView.${this.state.activeTab}`, {
        building: buildingRes.key,
      });
      Publisher.publish(`track-mp.projectView.${this.state.activeTab}`, {
        user: user,
        project: buildingRes,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, viewedBuildings, buildingRes } = this.props;
    const { gatedView } = this.state;
    const { viewBuilding } = this.props.actions;
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      this.setState({ activeTab: this.props.match.params.tab });
    }

    if (
      user &&
      user.subscriptionStatus &&
      user.subscriptionStatus === "active" &&
      gatedView === true
    ) {
      this.setState({ gatedView: false });
      //this._getBuildingOPData();
      //this._getBuildingTransactionData();
      //this._getBuildingHPDData();
      viewBuilding(buildingRes.key, user);
      Publisher.publish(`track.buildingView.${this.state.activeTab}`, {
        building: buildingRes.key,
      });
      Publisher.publish(`track-mp.projectView.${this.state.activeTab}`, {
        user: user,
        project: buildingRes,
      });
    }

    if (window.instgrm) {
      console.log("window.instgrm.Embeds.process");
      window.instgrm.Embeds.process();
    }
  }

  toggle = (tab) => {
    const { buildingRes } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      const buildingURL =
        _getBuildingURL(buildingRes, "_id") + (tab ? `/tab/${tab}` : "");
      this.props.history.push(buildingURL);
      this.setState({
        activeTab: tab,
      });
      Publisher.publish(`track.buildingView.${tab}`, {
        building: buildingRes.key,
      });
    }
  };

  _getBuildingHPDData = async () => {
    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { buildingRes } = this.props;

    if (
      !buildingRes.address ||
      !buildingRes.address.alternate_addresses ||
      !buildingRes.address.zip
    ) {
      this.setState({ hpdRes: false });
      return false;
    }

    const addressQ =
      buildingRes.address && buildingRes.address.alternate_addresses
        ? buildingRes.address.alternate_addresses.join('" OR "')
        : false;

    let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.address.zip} OR borough:"${buildingRes.address.borough}"))`;

    let axiosData = {
      url: apiURL + "we3-hpd-multi-dwelling-registrations?cache=60",
      method: "post",
      query: {
        q: q,
        fq: [],
        sort: "last_registration_date desc",
        wt: "json",
        rows: 10,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (res && res.docs) {
          this.setState({ hpdRes: res.docs });
        } else {
          this.setState({ hpdRes: false });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  render() {
    const {
      buildingRes,
      rtl,
      user,
      viewedBuildings,
      offeringPlan,
      setBuilding,
    } = this.props;
    const {
      activeTab,
      statsRes,
      unitsRes,
      gatedView,
      transactionRes,
      hpdRes,
      transactionDocs,
    } = this.state;

    if (gatedView) {
      return <TrialLimit />;
    }

    /*
    if(!statsRes){
	    return <LinearLoading />;
    }
*/

    const { buildingNameOrAdr, adr, _boro, _hood } =
      _formatNameAddress(buildingRes);

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <Grid container className="card__title">
              <Grid item xs={12} md={12} sm={12} lg={6}>
                <div className="project_page_title">
                  <Tooltip title="Back to Buildings">
                    <IconButton
                      className="material-table__toolbar-button project_page_button"
                      onClick={() => this.props.history.go(-1)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                  <h3 className="page-title">
                    {buildingNameOrAdr} <BuildingIcons building={buildingRes} />
                  </h3>

                  {isMobile ? (
                    <Fragment>
                      <h3 className="page-subhead subhead subHead_style">
                        {buildingRes.name ? (
                          <span>
                            {_capitalizeText(adr)}
                            <br />
                          </span>
                        ) : (
                          ""
                        )}
                        {_capitalizeText(_hood)}, {_capitalizeText(_boro)}
                      </h3>
                    </Fragment>
                  ) : (
                    <h3 className="page-subhead subhead subHead_style">
                      {buildingRes.name ? _capitalizeText(adr) + ", " : ""}
                      {buildingRes.name && isMobile && <br />}{" "}
                      {_hood ? _capitalizeText(_hood) + ", " : ""}{" "}
                      {_capitalizeText(_boro)}
                    </h3>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                lg={7}
                className="top__toolbar project_page_buttons project_title_buttons"
              >
                <Tooltip title="See building profile on Marketproof">
                  <IconButton
                    className="material-table__toolbar-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `https://nyc.marketproof.com/linkto?building=${buildingRes["display_full_street_address"]}, ${buildingRes["address"]["borough"]}`
                      );
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
                <AddToBuyersListButton
                  projectKey={buildingRes.key}
                  type={"project"}
                />
                {_hasPermission(user, "admin-tools") && (
                  <Fragment>
                    {buildingRes.offering_plan_documents &&
                      buildingRes.offering_plan_documents[0] && (
                        <Tooltip title="Download Offering Plan (Admin Only)">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              window.location.href =
                                buildingRes.offering_plan_documents[0];
                            }}
                            className="material-table__toolbar-button"
                          >
                            <DocIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Fragment>
                )}

                {_hasPermission(user, "ag-link") &&
                  buildingRes.offering_file_number &&
                  buildingRes.offering_file_number !== "" && (
                    <Tooltip title="See Offering Plan on AG Website">
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.open(
                            `https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${buildingRes.offering_file_number}`
                          );
                        }}
                        className="material-table__toolbar-button"
                      >
                        <OpenIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                <EditProject
                  buildingRes={buildingRes}
                  setBuilding={setBuilding}
                />
                <EditProject
                  buildingRes={buildingRes}
                  setBuilding={setBuilding}
                  del
                />
                <ShareButton buildingRes={buildingRes} />
                {false &&
                  ["sponsor"].indexOf(buildingRes.building_status) >= 0 &&
                  _hasPermission(user, "stacked-report") && (
                    <ReportButton buildingRes={buildingRes} />
                  )}
                <AddCollectionButton buildingRes={buildingRes} />
              </Grid>
            </Grid>
            <div className="unit___project_badge">
              <StatusBadge
                status={buildingRes.offering_construction}
                building={buildingRes}
              />
            </div>
            <div className="tabs tabs--bordered-bottom tabs_header-set">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "overview",
                      })}
                      onClick={() => {
                        this.toggle("overview");
                      }}
                    >
                      <span className="tab-title">Overview</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "contacts",
                      })}
                      onClick={() => {
                        this.toggle("contacts");
                      }}
                    >
                      <span className="tab-title">Contacts</span>
                    </NavLink>
                  </NavItem>

                  <Fragment>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "timeline",
                        })}
                        onClick={() => {
                          this.toggle("timeline");
                        }}
                      >
                        <span className="tab-title">Timeline</span>
                      </NavLink>
                    </NavItem>
                    {buildingRes.offering_floorplans && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "plans",
                          })}
                          onClick={() => {
                            this.toggle("plans");
                          }}
                        >
                          <span className="tab-title">plans</span>
                        </NavLink>
                      </NavItem>
                    )}
                    <Fragment>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "permits",
                          })}
                          onClick={() => {
                            this.toggle("permits");
                          }}
                        >
                          <span className="tab-title">Permits</span>
                        </NavLink>
                      </NavItem>
                    </Fragment>
                    <Fragment>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "violations",
                          })}
                          onClick={() => {
                            this.toggle("violations");
                          }}
                        >
                          <span className="tab-title">
                            Violations & Complaints
                          </span>
                        </NavLink>
                      </NavItem>
                    </Fragment>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "transfers",
                        })}
                        onClick={() => {
                          this.toggle("transfers");
                        }}
                      >
                        <span className="tab-title">Sales & Transfers</span>
                      </NavLink>
                    </NavItem>
                    <Fragment>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "financing",
                          })}
                          onClick={() => {
                            this.toggle("financing");
                          }}
                        >
                          <span className="tab-title">Financing</span>
                        </NavLink>
                      </NavItem>
                    </Fragment>

                    {offeringPlan && offeringPlan[0] && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "offering",
                          })}
                          onClick={() => {
                            this.toggle("offering");
                          }}
                        >
                          <span className="tab-title">Offering</span>
                        </NavLink>
                      </NavItem>
                    )}

                    {/*  <NavItem>	                  
	                  <NavLink
	                      className={classnames({ active: activeTab === 'qa' })}
	                      onClick={() => {
	                        this.toggle('qa');
	                      }}
	                    >
	                      <span className="tab-title">Q & A</span>
	                    </NavLink> 
	                  </NavItem>*/}
                    {/*}   
	                  <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'buyerslist' })}
	                      onClick={() => {
	                        this.toggle('buyerslist');
	                      }}
	                    >
	                      <span className="tab-title">BuyersList</span>
	                    </NavLink>
	                  </NavItem>
	               */}
                  </Fragment>
                </Nav>
              </div>
            </div>
            <TabContent activeTab={activeTab}>
              {activeTab === "overview" && (
                <TabPane tabId="overview">
                  <Profile
                    statsRes={statsRes}
                    unitsRes={unitsRes}
                    {...this.props}
                    toggle={this.toggle}
                    hpdRes={hpdRes}
                    setBuilding={setBuilding.bind(this)}
                  />
                </TabPane>
              )}
              {activeTab === "contacts" && (
               
                <TabPane tabId="contacts">
                  {_hasPermission(user, "project-contacts") ? 
                    <Contacts
                      statsRes={statsRes}
                      unitsRes={unitsRes}
                      {...this.props}
                      transactionRes={transactionRes}
                      transactionDocs={transactionDocs}
                    />
                    :
                    <EnterpriseGate 
                      copy={<span>Access to Pipeline Contacts requires an upgrade to your subscription.</span>}
                      img={`https://marketproof.com/svgs/PLyes.svg`}
                      classes={"small-image"}
                    />
                  }
                </TabPane>
              )}

              {activeTab === "timeline" && (
                <TabPane tabId="timeline">
                  <Fragment>
                    <Container>
                      <ProjectFeed {...this.props} />
                    </Container>
                  </Fragment>
                </TabPane>
              )}
              {buildingRes.offering_floorplans && activeTab === "plans" && (
                <TabPane tabId="plans">
                  <Fragment>
                    <Row className="news__container">
                      <Gallery {...this.props} />
                    </Row>
                  </Fragment>
                </TabPane>
              )}
              {activeTab === "qa" && (
                <TabPane tabId="qa" className="qa__container">
                  <QA {...this.props} />
                </TabPane>
              )}
              {activeTab === "permits" && (
                <TabPane tabId="permits" className="permits__container">
                  <Permits {...this.props} />
                </TabPane>
              )}
              {activeTab === "violations" && (
                <TabPane tabId="violations" className="violations__container">
                  {_hasPermission(user, "violations-tab") ? 
                  <Violations buildingRes={buildingRes} {...this.props} />
                    :
                      <EnterpriseGate 
                        copy={<span>Access to Project Violations requires an upgrade to your subscription.</span>}
                      />
                    }
                </TabPane>
              )}
              {activeTab === "financing" && (
                <TabPane tabId="financing" className="financing__container">
                  {_hasPermission(user, "finance-tab") ? 
                    <LendingHistory {...this.props} />
                  :
                    <EnterpriseGate 
                      copy={<span>Access to Project Financing requires an upgrade to your subscription.</span>}
                    />
                  }
                </TabPane>
              )}
              {activeTab === "offering" && (
                <TabPane tabId="offering" className="offering__container">
                  <OfferingHistory {...this.props} />
                </TabPane>
              )}
              {activeTab === "transfers" && (
                <TabPane tabId="transfers" className="transfer__container">
                  {/* <BuildingTransfer {...this.props} /> */}
                  {_hasPermission(user, "transfers") ? 
                  <UnitsSearchTable
                    buildingView
                    building={buildingRes}
                    hideButtons
                  />
                  :
                    <EnterpriseGate 
                      copy={<span>Access to Project Transfers requires an upgrade to your subscription.</span>}
                    />
                  }
                </TabPane>
              )}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings)
      ? state.buyersLists.viewedBuildings.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((withRouter(BuildingTabContainer)));
