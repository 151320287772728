import axios from "axios";
import React, { Component } from 'react';
/*
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
*/

import JoditEditor, { Jodit } from "jodit-react";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_formatAddressAutoComplete
} from '../../../../../shared/helpers/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = (options, { inputValue }) => { return options }
const apiUrl = process.env.API_ROOT;  
const imageUploadUrl = process.env.API_ROOT + '/pipeline/imageupload';
const instagramEmbed = {
				tooltip: 'Instagram Embed',
				iconURL: 'https://marketproof-hosting.s3.amazonaws.com/assets/instagram-light.svg',
				popup: (editor, current, self, close) => {
					const form = editor.create.fromHTML(
						`<form>
							<div class="">
								<span class="jodit-ui-text-area__label">Instagram embed code</span>
								<textarea class="jodit-ui-instagram__input" name="instagram_code" type="text" required="true" placeholder="" rows="5" col="50"></textarea>
								
							</div>
							<button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_primary jodit-ui-button_text-icons_true" type="submit">
								<span class="jodit-ui-button__icon"></span><span class="jodit-ui-button__text">Insert</span>
							</button>
						 </form>`
					);
			
					editor.e.on(form, 'submit', (e) => {
						e.preventDefault();
						editor.s.insertHTML(form.querySelector('.jodit-ui-instagram__input').value);
/*
						if(window.instgrm){
							 window.instgrm.Embeds.process()
						}
*/
						close();
					});
			
					return form;
				}
			}
const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		height:500,
		allowResizeX: false,
		enableDragAndDropFileToEditor: true,
		buttons: [
	        'bold',
	        'strikethrough',
	        'underline',
	        'italic', '|',
	        'ul',
	        'ol', '|',
	        'outdent', 'indent',  '|',
	        'font',
	        'fontsize',
	        'brush',
	        'paragraph', '|',
	        'image',
	        'video',
	        instagramEmbed,
	        'table',
	        'link', '|',
	        'align', 'undo', 'redo', '|',
	        'hr',
	        'eraser',
	        'copyformat', '|',
	        'symbol', '|',
	        'source', '|',
	        
	    ],
	    colorPickerDefaultTab: 'text',
	    uploader: {
		    insertImageAsBase64URI: false,
	        url: imageUploadUrl,
	        format: 'json',
	        method: 'POST',
	        pathVariableName: 'path',
	        filesVariableName: function(i){
		     	return "file"; 
		    },	        
	        prepareData: function (data) {
	            return data;
	        },
	        isSuccess: function (resp) {
	            return !resp.error;
	        },
	        getMsg: function (resp) {
	            return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
	        },
	        process: function (resp) {
	            return {
	                file: resp || []
	            };
	        },
	        error: function (e) {
	            this.events.fire('errorPopap', [e.getMessage(), 'error', 4000]);
	        },
	        defaultHandlerSuccess: function (data, resp) {
		        console.log(data, resp)
	            var i; //, field = this.options.uploader.filesVariableName;
	            if (data['file'] && data['file'].length) {
	                for (i = 0; i < data['file'].length; i += 1) {
		                console.log(data['file'][i])
	                    this.selection.insertImage(data['file'][i]);
	                }
	            }
	        },
	        defaultHandlerError: function (resp) {
	            this.events.fire('errorPopap', [this.options.uploader.getMsg(resp)]);
	        }
	    }
}


class renderRichTextEditor extends Component {
	
  
  
  constructor(props) {
    super(props);
    this.state = { 
	    instagram : false
 
	};
/*
    
   
    let contentState = false;
    if(this.props.value){
	    const blocksFromHtml =  htmlToDraft(this.props.value); 
	    const { contentBlocks, entityMap } = blocksFromHtml;
	    contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    }
*/
    
    this.state = {
	   // editorState: contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty(),
	    content : this.props.value ? this.props.value : ""
	}
	
	this.editor = React.createRef();
    
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
/*
	 
	  if(window.instgrm && this.editor && !this.state.instagram){
			 window.instgrm.Embeds.process();
			 this.setState({
		        'instagram': true
		      });
	  }
*/
	  
  }

/*
  onEditorStateChange: Function = (editorState) => {
	const { onChange } = this.props;
    this.setState({
      editorState,
    });
    
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  };
*/

  render() {
    const { content } = this.state;
    const { onChange } = this.props;
    return (
      <div>
      	<JoditEditor
            	ref={this.editor}
                value={content}
                config={config}
				tabIndex={1} // tabIndex of textarea
				onBlur={newContent => this.setState({content : newContent})} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { onChange(newContent)} }
            />
        {/*<Editor
          editorState={editorState}
          wrapperClassName="rich-text-wrapper"
          editorClassName="rich-text-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />*/}
      </div>
    );
  }
}

export default renderRichTextEditor;