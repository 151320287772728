import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Form from "@rjsf/material-ui";
import LayoutField from './LayoutField';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber
} from '../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}

const schema = {
  title: "Project Profile",
  type: "object",
  required: ['name'],
  properties: {
    "pipeline_financing_status": {
      "type": "string",
      "title": "Financing Status",
      "enum": ["not-secured", "partially-secured", "fully-secured"],
	  "enumNames": ["Not Secured", "Partially Secured", "Fully Secured"],
    },
    "pipeline_total_cost": {
      "type": "number",
      "title": "Total Project Cost",
    },
    "pipeline_land_acqusition_cost": {
      "type": "number",
      "title": "Land Acqusition Cost",
    },
    "pipeline_air_rights_acqusition_cost": {
      "type": "number",
      "title": "Air Rights Acqusition Cost",
    },
    "financing": {
      'type': 'array',
      'title': 'Financing',
      "description" : "Add financing for this project.",
      'items' : {
	      'type': 'object',
	      'properties' : {
		      'Type' : {
			      'type' : 'string'
		      },
		      'Amount' : {
			      'type' : 'number'
		      },	      
		      'Lender' : {
			      'type' : 'string'
		      },
		      'Date' : {
			      'type' : 'string',
			      'format' : 'date'
		      },

		      
	      }
      }
    }
  },
  "definitions": {}
      
    
  
}

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField
}


const isFilled = (fieldName) => ({ formData }) => (formData[fieldName] && formData[fieldName].length) ? true : false
const isTrue = (fieldName) => ({ formData }) => (formData[fieldName])

const uiSchema = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: [
	    
	  { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <h5 className="section_title">Project Financials</h5>
              </div>
            )
          } }
        ] } },
      ] },
	  
       { 'ui:row': [
	      	{ 'ui:col': { md: 12, children: ['pipeline_financing_status'] } },
	      	
	      ]
	  },
	  { 'ui:row': [
		    { 'ui:col': { md: 4, children: ['pipeline_total_cost'] } },
	      	{ 'ui:col': { md: 4, children: ['pipeline_land_acqusition_cost'] } },
	      	{ 'ui:col': { md: 4, children: ['pipeline_air_rights_acqusition_cost'] } }
	      ]
	  },
	   { 'ui:row': [
	      	{ 'ui:col': { md: 12, children: ['financing'] } },
	      	
	      ]
	  },
     
    ] } },
  ] },
  'pipeline_occupancy_type': {
    'ui:widget': 'radio',
    'ui:options' :{
	    'inline' : true
    }
  },
  'pipeline_project_stage': {
    'ui:widget': 'radio',
    'ui:options' :{
	    'inline' : true
    }
  },
  'pipeline_financing_status': {
    'ui:widget': 'radio',
    'ui:options' :{
	    'inline' : true
    }
  },
  'occupancy_type' : {
	  'pipeline_occupancy_type': {
	    'ui:widget': 'radio',
	    'ui:options' :{
		    'inline' : true
	    }
	  },
	  "Project Components" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
			    'inline' : true
		  }
	  }
  },
  'project_stage': {
	  "pipeline_project_stage" : {
		  'ui:widget': 'radio',
		  'ui:options' :{
			    'inline' : true
		  }
	  },
	  "Construction Progress" : {
		  'ui:widget': 'radio',
		  'ui:options' :{
			    'inline' : true
		  }
	  }
  },
  'pipeline_project_description': {
    'ui:widget': 'textarea'
  },
  'image': {
    'ui:widget': 'ImageUpload'
  },
  'user': {
    'ui:field': 'layout_grid',
    'ui:layout_grid': { 'ui:row': [
        { 'ui:col': { md: 6, children: ['username'] } },
        { 'ui:col': { md: 6, children: ['password'] } },
    ] },

  }
}


class EditFinancials extends Component {

  constructor(props) {
    super(props);
    
  }

  render() {
    const {
     formData, onChange, onSubmit, toggle, saveInProgress
    } = this.props;
        
    return (
	    <div className="project_edit financials">
		    <Form
		        formData={formData}
		        schema={schema}
		        uiSchema={uiSchema}
		        fields={fields}
		        onChange={onChange}
		        noHtml5Validate={true}>
		        <ButtonToolbar className="modal__footer">
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				         disabled={saveInProgress}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
	            <Tooltip title="Save">
			        <Button
			        	 onClick={onSubmit}
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"		
				         disabled={saveInProgress}         
				     >	{saveInProgress ? "Saving..." : "Save" }
				     </Button>				 
			  	</Tooltip>
	         </ButtonToolbar>
	
			</Form>
		</div>
    );
  }
}



export default EditFinancials;
