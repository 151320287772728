import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row, Modal, ButtonToolbar } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { RTLProps, ThemeProps } from "../../../shared/prop-types/ReducerProps";
import buyersListActions from "../../../redux/actions/buyersListActions";
import { getFormValues } from "redux-form";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "mdi-react/PencilOutlineIcon";
import PhoneIcon from "mdi-react/PhoneOutlineIcon";
import LocationIcon from "mdi-react/MapMarkerIcon";
import InfoIcon from "mdi-react/NotebookOutlineIcon";
import ContactIcon from "mdi-react/BookmarkPlusOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";
import AgentIcon from "mdi-react/AccountOutlineIcon";
import AddIcon from "mdi-react/PlusCircleOutlineIcon";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";

import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";

import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Form from "@rjsf/material-ui";
import LayoutField from "./components/LayoutField";
import ImageUploadWidget from "./components/ImageUploadWidget";
import EditProfile from "./components/EditProfile";
import EditPhotos from "./components/EditPhotos";
import EditDiagrams from "./components/EditDiagrams";
import EditFinancials from "./components/EditFinancials";
import EditDetails from "./components/EditDetails";
import EditCompanies from "./components/EditCompanies";
import EditSummary from "./components/EditSummary";
import EditAddresses from "./components/EditAddresses";
import EditDocuments from "./components/EditDocuments";

import SnackBar from "../../../shared/components/SimpleSnackBar";

import { Publisher } from "./../../pubsub/publisher.js";

import { _axiosCall } from "../../../shared/helpers/apicalls";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  _formatUnitNumber,
  EstimateProjectStage,
} from "../../../shared/helpers/utils";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import EditMilestones from "./components/EditMilestones";
import EditMapPolygon from "./components/EditMapPolygon";
import MapModal from "../map/MapModal";

const apiUrl = process.env.API_ROOT; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log("titlefield", props);
  const { title, required, id } = props;

  let legend = (legend = `${title}${required ? "*" : ""}`);
  return (
    <label className="control-label" for={id}>
      {legend}
    </label>
  );
};
const addressFields = [
  "full_street_address",
  "address_2",
  "city",
  "state",
  "zip",
  "street_number",
  "street",
  "county",
  "geocode",
  "borough",
  "borough_code",
  "original",
  "normalized",
  "block",
  "lot",
  "bin",
  "goat_enriched",
];

const cleanFormData = (formData) => {
  let cleanData = Object.assign({}, formData);
  Object.keys(cleanData).map((key) => {
    if (cleanData[key] === -1) {
      cleanData[key] = null;
    }
  });

  cleanData["address_search"] = {
    display_full_street_address:
      cleanData["display_full_street_address"] &&
      cleanData["display_full_street_address"],
  };
  cleanData["borough"] =
    cleanData["address"] && cleanData["address"]["borough"]
      ? cleanData["address"]["borough"].toLowerCase()
      : null;
  cleanData["neighborhood"] =
    cleanData["address"] &&
    cleanData["address"]["neighborhood"] &&
    cleanData["address"]["neighborhood"][0]
      ? cleanData["address"]["neighborhood"].map((hood) =>
          _capitalizeText(hood)
        )[0]
      : null;

  cleanData["zip"] =
    cleanData["address"] && cleanData["address"]["zip"]
      ? cleanData["address"]["zip"]
      : null;
  cleanData["name"] = cleanData["name"]
    ? cleanData["name"]
    : cleanData["display_full_street_address"];
  cleanData["alternate_addresses"] =
    cleanData["address"] && cleanData["address"]["alternate_addresses"]
      ? cleanData["address"]["alternate_addresses"]
      : null;
  cleanData["bin"] =
    cleanData["address"] && cleanData["address"]["bin"]
      ? cleanData["address"]["bin"]
      : null;
  cleanData["block"] =
    cleanData["address"] && cleanData["address"]["block"]
      ? cleanData["address"]["block"]
      : null;
  cleanData["lot"] =
    cleanData["address"] && cleanData["address"]["lot"]
      ? cleanData["address"]["lot"]
      : null;

  if (!cleanData["occupancy_type"]) {
    cleanData["occupancy_type"] = {
      pipeline_occupancy_type: cleanData["pipeline_occupancy_type"],
    };
  }
  if (!cleanData["project_stage"]) {
    cleanData["project_stage"] = {
      pipeline_project_stage: cleanData["pipeline_project_stage"],
    };
  }

  if (!cleanData["construction_type"]) {
    cleanData["construction_type"] = {
      pipeline_construction_type: cleanData["pipeline_job_type"]
        ? cleanData["pipeline_job_type"] == "a1"
          ? "alteration"
          : cleanData["pipeline_job_type"] == "nb"
          ? "new-construction"
          : null
        : null,
    };
  }

  if (
    cleanData["total_units_sellable"] &&
    cleanData["pipeline_condo_units_proposed"] === undefined
  ) {
    cleanData["pipeline_condo_units_proposed"] = parseInt(
      cleanData["total_units_sellable"]
    );
  }
  if (
    cleanData["total_units_rental"] &&
    cleanData["pipeline_rental_units_proposed"] === undefined
  ) {
    cleanData["pipeline_rental_units_proposed"] = parseInt(
      cleanData["total_units_rental"]
    );
  }
  if (
    cleanData["total_units_rental_affordable"] &&
    cleanData["pipeline_affordable_rental_units_proposed"] === undefined
  ) {
    cleanData["pipeline_affordable_rental_units_proposed"] = parseInt(
      cleanData["total_units_rental_affordable"]
    );
  }
  if (
    cleanData["total_units_sellable_affordable"] &&
    cleanData["pipeline_affordable_condo_units_proposed"] === undefined
  ) {
    cleanData["pipeline_affordable_condo_units_proposed"] = parseInt(
      cleanData["total_units_sellable_affordable"]
    );
  }

  if (
    !cleanData["images"] ||
    cleanData["images"].filter((img) => img.image).length <= 0
  ) {
    let images = _getBuildingPhotos(cleanData);
    console.log(images);

    if (cleanData["image"]) {
      cleanData["images"] = [
        {
          Caption: "",
          image: [{ preview: cleanData["image"] }],
          Date: "",
        },
      ];
    } else {
      if (images && images.length > 0) {
        cleanData["images"] = images.map((img) => {
          return {
            Caption: "",
            image: [{ preview: img }],
            Date: "",
          };
        });
      } else {
        cleanData["images"] = [{}];
      }
    }
  }

  return cleanData;
};

const prepareFormDataForSave = (formData) => {
  let cleanData = Object.assign({}, formData);

  let skipKeys = ["meta", "meta_context"];
  let includeKeys = ["pipeline_project_stage"];

  if (cleanData["address_search"]) {
    if (cleanData["address_search"]["display_full_street_address"]) {
      cleanData["display_full_street_address"] =
        cleanData["address_search"]["display_full_street_address"];

      delete cleanData["address_search"]["_highlightResult"];
      cleanData["address"] = Object.assign(
        {},
        cleanData["address"] ? cleanData["address"] : {},
        cleanData["address_search"]
      );

      if (
        !cleanData["address"]["full_street_address"] &&
        cleanData["address"]["display_full_street_address"]
      ) {
        cleanData["address"]["full_street_address"] =
          cleanData["address"]["display_full_street_address"];
      }
      delete cleanData["address"]["_highlightResult"];
    }

    delete cleanData["address_search"];
  }

  if (!cleanData["address"]) {
    cleanData["address"] = {};
  }

  if (cleanData["neighborhood"]) {
    cleanData["address"]["neighborhood"] = !Array.isArray(
      cleanData["neighborhood"]
    )
      ? [cleanData["neighborhood"].toLowerCase()]
      : cleanData["neighborhood"].map((hood) => hood.toLowerCase());
    delete cleanData["neighborhood"];
  }
  if (cleanData["borough"]) {
    cleanData["address"]["borough"] = cleanData["borough"].toLowerCase();
    delete cleanData["borough"];
  }
  if (cleanData["zip"]) {
    cleanData["address"]["zip"] = cleanData["zip"].toLowerCase();
    delete cleanData["zip"];
  }
  if (cleanData["alternate_addresses"]) {
    cleanData["address"]["alternate_addresses"] =
      cleanData["alternate_addresses"].slice(0);
    delete cleanData["alternate_addresses"];
  }
  if (cleanData["bin"]) {
    cleanData["address"]["bin"] = cleanData["bin"];
    delete cleanData["bin"];
  }
  if (cleanData["block"]) {
    cleanData["address"]["block"] = cleanData["block"];
    delete cleanData["block"];
  }
  if (cleanData["lot"]) {
    cleanData["address"]["lot"] = cleanData["lot"];
    delete cleanData["lot"];
  }

  if (cleanData["project_stage"]) {
    Object.keys(cleanData["project_stage"])
      .filter((key) => skipKeys.indexOf(key) < 0)
      .map((key) => {
        if (cleanData[key] || includeKeys.indexOf(key) > -1) {
          cleanData[key] = cleanData["project_stage"][key];
        }
      });
  }

  if (cleanData["occupancy_type"]) {
    Object.keys(cleanData["occupancy_type"])
      .filter((key) => skipKeys.indexOf(key) < 0)
      .map((key) => {
        if (cleanData[key] || includeKeys.indexOf(key) > -1) {
          cleanData[key] = cleanData["occupancy_type"][key];
        }
      });
  }

  if (cleanData.occupancy_type) {
    if (
      cleanData.occupancy_type["Project Components"] &&
      cleanData.occupancy_type["Project Components"].length > 0
    ) {
      cleanData["pipeline_project_components"] = cleanData.occupancy_type[
        "Project Components"
      ].map((item) => item.toLowerCase());
    }

    if (cleanData.occupancy_type.pipeline_occupancy_type) {
      cleanData["pipeline_occupancy_type"] =
        cleanData.occupancy_type.pipeline_occupancy_type.toLowerCase();
    }
  }

  if (cleanData["images"]) {
    cleanData["images"] = cleanData["images"].filter((img) => img.image);
  }

  if (cleanData["meta"] && cleanData["meta"]["locked_fields"]) {
    delete cleanData["meta"]["locked_fields"];
  }

  if (cleanData["custom_fields"] && cleanData["custom_fields"].length > 0) {
    cleanData["custom_fields"].map((field) => {
      if (field["Type"] && field["Type"] == "number") {
        field["Value"] = parseInt(field["Value"]);
      }
    });
  }
  if (cleanData["milestone"] && cleanData["milestone"].length > 0) {
    cleanData["milestone"].forEach((field) => {
      if (field["Milestones"] === "pipeline_filing_date") {
        cleanData["pipeline_filing_date"] = new Date(
          field["Date"]
        ).toISOString();
      } else if (
        field["Milestones"] === "pipeline_permit_issuance_job_start_date"
      ) {
        cleanData["pipeline_permit_issuance_job_start_date"] = new Date(
          field["Date"]
        ).toISOString();
      } else if (field["Milestones"] === "pipeline_coo_issue_type") {
        cleanData["pipeline_coo_issuance_date"] = new Date(
          field["Date"]
        ).toISOString();
      }
    });
  }
  Object.keys(cleanData).forEach((key) => {
    if (cleanData[key] === undefined) {
      cleanData[key] = "";
    }
  });

  //update polygon
  if (cleanData.updatedPolygon) {
    cleanData.address["polygon_bbl_json"] = cleanData.updatedPolygon;
    cleanData["polygon_bbl_json"] = cleanData.updatedPolygon;
    delete cleanData.updatedPolygon;
  }
  return cleanData;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    width: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 180,
  },
});

function ShowErrors({ errors }) {
  if (!errors || errors.length < 1 || !Array.isArray(errors)) return null;

  return (
    <div className="edit-errors">
      <ul>{errors && errors.map((error) => <li>{error}</li>)}</ul>
    </div>
  );
}

class EditProject extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Update",
      modal: false,
      snackShow: false,
      snackMessage: false,
      snackType: "success",
      formData: cleanFormData(this.props.buildingRes),
      activeTab: "profile",
      value: 0,
      justDeleted: false,
      saveInProgress: false,
      key: null,
      standardView: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveProject = this.saveProject.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
  }

  saveProject() {
    const { authState, authService, dispatch, user, setBuilding } = this.props;
    const { formData } = this.state;
    const user_id = user.id;
    const user_email = user.email;
    this.setState({ saveInProgress: true });
    let saveData = prepareFormDataForSave(formData);
    let meta = {
      source_namespace:
        saveData.meta && saveData.meta.source_namespace
          ? saveData.meta.source_namespace
          : "MARKETPROOF-PIPELINE-APP",
      user_id: user_id,
      user_email: user_email,
    };
    if (this.state.key && !saveData["key"]) {
      saveData["key"] = this.state.key;
    }
    if (saveData["meta"]) {
      saveData["meta"] = Object.assign({}, saveData["meta"], meta);
    } else {
      saveData["meta"] = meta;
    }

    let userData = {
      url: apiUrl + "/pipeline-projects/",
      method: "post",
      query: {
        pipeline_project: JSON.stringify(saveData),
      },
    };

    _axiosCall(userData)
      .then((res) => {
        console.log("_getUserData res back ,", res);
        this.setState({
          saveInProgress: false,
          key: (res.data && res.data.key) || null,
        });
        if (res && res.success) {
          if (res.message && res.message == "no changes found") {
            this.snackOpen("Success! This project has been saved.");
          } else {
            this.snackOpen("Success! This project has been saved.");
          }
        } else {
          if (!res.success) {
            this.snackOpen(
              <Fragment>
                <span>
                  Oops, Your Profile Was Not Updated.{" "}
                  <p
                    className="small-text light-text"
                    style={{ marginLeft: "30px", color: "#FFF" }}
                  >
                    Oops, Something went wrong. {_capitalizeText(res.message)}
                  </p>
                </span>
              </Fragment>,
              "error"
            );
          } else {
            this.snackOpen(
              "Oops, something went wrong, project was not saved.",
              "error"
            );
          }
        }
      })
      .catch((error) => {
        console.log("_getUserData error ", error);
        this.snackOpen(
          "Oops, something went wrong, project was not saved.",
          "error"
        );
      });
  }

  deleteProject() {
    const { authState, authService, dispatch, user, setBuilding } = this.props;
    const { formData } = this.state;

    const user_id = user.id;
    const user_email = user.email;

    let meta = {
      source_namespace:
        formData.meta && formData.meta.source_namespace
          ? formData.meta.source_namespace
          : "MARKETPROOF-PIPELINE-APP",
      user_id: user_id,
      user_email: user_email,
    };

    let deleteData = {
      key: formData.key,
      meta: meta,
    };

    console.log(deleteData);

    //return false;
    let userData = {
      url: apiUrl + "/pipeline-projects/delete", //"http://52.70.164.132:8001/pipeline-projects/delete",
      method: "post",
      query: {
        pipeline_project: JSON.stringify(deleteData),
      },
    };

    _axiosCall(userData)
      .then((res) => {
        console.log("_getUserData res back ,", res);

        if (res && res.success) {
          if (res.message && res.message == "no changes found") {
            this.snackOpen("Success! This project has been deleted.");
          } else {
            this.snackOpen("Success! This project has been deleted.");
          }
          setTimeout(() => {
            this.setState({ justDeleted: true });
          }, 3000);
        } else {
          if (!res.success) {
            this.snackOpen(
              <Fragment>
                <span>
                  Oops, Your Profile Was Not Updated.{" "}
                  <p
                    className="small-text light-text"
                    style={{ marginLeft: "30px", color: "#FFF" }}
                  >
                    Oops, Something went wrong. {_capitalizeText(res.message)}
                  </p>
                </span>
              </Fragment>,
              "error"
            );
          } else {
            this.snackOpen(
              "Oops, something went wrong, project was not deleted.",
              "error"
            );
          }
        }
      })
      .catch((error) => {
        console.log("_getUserData error ", error);
        this.snackOpen(
          "Oops, something went wrong, project was not saved.",
          "error"
        );
      });
  }

  snackOpen = (message, type) => {
    this.setState({ snackShow: true, snackMessage: message, snackType: type });
  };

  snackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackShow: false, snackMessage: "" });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  toggle() {
    //this.snackOpen('Your Message Has Been Sent!', 'success');
    const { setBuilding, del } = this.props;
    if (this.state.modal && !del) {
      if (setBuilding && typeof setBuilding === "function") {
        setBuilding();
      }
    }

    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  onChange = ({ formData }, e) => {
    let newObj = Object.assign({}, formData);

    //console.log(newObj.address_search.display_full_street_address.toLowerCase(), this.state.formData.display_full_street_address.toLowerCase())
    if (
      newObj.address_search &&
      newObj.address_search.display_full_street_address &&
      (!this.state.formData.display_full_street_address ||
        newObj.address_search.display_full_street_address.toLowerCase() !=
          this.state.formData.display_full_street_address.toLowerCase())
    ) {
      newObj["display_full_street_address"] =
        newObj.address_search.display_full_street_address.toLowerCase();
      newObj.neighborhood =
        newObj.address_search.neighborhood &&
        newObj.address_search.neighborhood[0]
          ? newObj.address_search.neighborhood.map((hood) =>
              _capitalizeText(hood)
            )[0]
          : null;
      newObj.borough =
        newObj.address_search.borough &&
        newObj.address_search.borough.toLowerCase();
      newObj.zip = newObj.address_search.zip && newObj.address_search.zip;
    } else if (
      newObj.address_search &&
      typeof newObj.address_search === "string"
    ) {
      newObj["display_full_street_address"] =
        newObj.address_search.toLowerCase();
    }

    this.setState({ formData: newObj });
  };

  onSubmit = (errorList) => {
    if (!errorList || errorList.length < 1 || !Array.isArray(errorList)) {
      this.saveProject();
    } else {
      this.snackOpen(
        <Fragment>
          <span>Please correct the following errors</span>
          <br />
          <ShowErrors errors={errorList} />
        </Fragment>,
        "error"
      );
    }
  };

  toggleView = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ standardView: !this.state.standardView });
  };

  render() {
    const {
      actions,
      t,
      theme,
      rtl,
      buildingRes,
      user,
      property,
      savedBuildings,
      savedUnits,
      listing,
      classes,
      add,
      del,
    } = this.props;

    if (!(user && user.role.indexOf("admin") >= 0)) return null;

    if (isMobile) return null;

    const {
      modal,
      snackShow,
      snackMessage,
      snackType,
      formData,
      value,
      justDeleted,
      saveInProgress,
    } = this.state;

    if (justDeleted) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }

    const buildingNameOrAdr = buildingRes.name
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
      ? _capitalizeText(buildingRes.display_full_street_address)
      : _capitalizeText(buildingRes.full_street_address);
    const adr = buildingRes.display_full_street_address
      ? buildingRes.display_full_street_address
      : buildingRes.full_street_address;
    const hood = buildingRes.neighborhood
      ? _capitalizeText(buildingRes.neighborhood[0]) + ","
      : "";

    const unitKey = property ? property.key : false;
    const buildingKey = buildingRes.key;

    const saved = unitKey
      ? savedUnits.findIndex(
          (item) =>
            item.key === unitKey &&
            (typeof item.deleted === "undefined" || item.deleted === false)
        ) > -1
      : savedBuildings.findIndex(
          (item) =>
            item.key === buildingKey &&
            (typeof item.deleted === "undefined" || item.deleted === false)
        ) > -1;

    if (del) {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />
          <Tooltip title={`Delete this project (Admin Only)`}>
            <Button
              onClick={this.toggle}
              variant="contained"
              color="secondary"
              className="buyersList__secondary-button"
              startIcon={<DeleteIcon />}
            >
              {" "}
              Delete
            </Button>
          </Tooltip>
          <Modal
            isOpen={modal}
            toggle={this.toggle}
            modalClassName={`${rtl.direction}-support`}
            className={`modal-dialog contact__modal delete-project`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggle}
              />
            </div>
            <div className="modal__body background_white">
              <h4 className="contact__title">{`Delete Project: ${
                formData && formData["name"] ? formData["name"] : ""
              }`}</h4>
              <hr />
              <div>
                <p>Are you sure you want to delete this project?</p>
              </div>
              <ButtonToolbar className="modal__footer">
                <Tooltip title="Cancel">
                  <Button
                    onClick={this.toggle}
                    variant="outlined"
                    color=""
                    className=""
                    startIcon={null}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Tooltip>{" "}
                <Tooltip title="Confirm you want to delete this project">
                  <Button
                    onClick={() => this.deleteProject()}
                    variant="contained"
                    color="secondary"
                    className="buyersList__primary-button"
                    startIcon={<DeleteIcon />}
                  >
                    {" "}
                    Delete Project
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
          </Modal>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <SnackBar
          snackShow={snackShow}
          snackMessage={snackMessage}
          snackClose={this.snackClose}
          snackDuration={5000}
          snackType={snackType}
        />

        {add ? (
          <Tooltip title={`Add a new project (Admin Only)`}>
            <Button
              onClick={this.toggle}
              variant="contained"
              color="primary"
              className=""
              startIcon={<AddIcon />}
            >
              {" "}
              Add Project
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={`Update the details of this project  (Admin Only)`}>
            <Button
              onClick={this.toggle}
              variant="contained"
              color="secondary"
              className="buyersList__secondary-button"
              startIcon={<EditIcon />}
            >
              {" "}
              {this.state.buttonText}
            </Button>
          </Tooltip>
        )}

        <Fragment>
          <Modal
            isOpen={modal}
            toggle={this.toggle}
            modalClassName={`${rtl.direction}-support`}
            className={`modal-dialog contact__modal`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggle}
              />
            </div>

            <div className="modal__body background_white">
              <h4 className="contact__title">
                {add
                  ? `Add Project: ${
                      formData && formData["name"] ? formData["name"] : ""
                    }`
                  : `Update Project: ${
                      formData && formData["name"] ? formData["name"] : ""
                    }`}
              </h4>
              <hr />
              <div className={classes.root}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={this.handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Photos" {...a11yProps(1)} />
                  <Tab label="Diagrams" {...a11yProps(2)} />
                  <Tab label="Summary" {...a11yProps(3)} />
                  <Tab label="Financials" {...a11yProps(4)} />
                  <Tab label="Details" {...a11yProps(5)} />
                  <Tab label="Address & IDs" {...a11yProps(6)} />
                  <Tab label="Documents" {...a11yProps(7)} />
                  <Tab label="Milestones" {...a11yProps(8)} />
                  <Tab
                    label="Modify Polygon"
                    {...a11yProps(9)}
                    disabled={add ? true : false}
                  />
                  {/* <Tab label="Companies" {...a11yProps(5)} />
						        <Tab label="People" {...a11yProps(6)} />
						        <Tab label="Documents" {...a11yProps(5)} />
						        <Tab label="Tags" {...a11yProps(6)} />*/}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <EditProfile
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <EditPhotos
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <EditDiagrams
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <EditSummary
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <EditFinancials
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <EditDetails
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <EditAddresses
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <EditDocuments
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <EditMilestones
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={9}
                  style={{ position: "relative" }}
                >
                  <EditMapPolygon
                    {...this.props}
                    formData={formData}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    toggle={this.toggle}
                    saveInProgress={saveInProgress}
                    standardView={this.state.standardView}
                  />
                  <div className="editPolygonMapBtn">
                    <MapModal
                      {...this.props}
                      toggleView={this.toggleView}
                      standardView={this.state.standardView}
                      showMapViewButton
                    />
                  </div>
                </TabPanel>
                {/*    <TabPanel value={value} index={5}>
						        <EditCompanies
								 	formData={formData}				               
								 	onChange={this.onChange}
								 	onSubmit={this.onSubmit}
								 	toggle={this.toggle}
								 />
						      </TabPanel>
						      <TabPanel value={value} index={6}>
						        Add People to Project
						      </TabPanel> 
						      <TabPanel value={value} index={5}>
						        Upload Documents
						      </TabPanel>
						      <TabPanel value={value} index={6}>
						        Tag this Project
						      </TabPanel>*/}
              </div>
            </div>
          </Modal>
        </Fragment>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    formValues: getFormValues("contact_form")(state),
    savedBuildings: Array.isArray(state.buyersLists.savedBuildings)
      ? state.buyersLists.savedBuildings.slice()
      : [],
    savedUnits: Array.isArray(state.buyersLists.savedUnits)
      ? state.buyersLists.savedUnits.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EditProject));
