/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { NewOrderTableProps } from "../../../../shared/prop-types/TablesProps";
import moment from "moment";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import ArrowUpIcon from "mdi-react/ChevronUpCircleOutlineIcon";
import ArrowDownIcon from "mdi-react/ChevronDownCircleOutlineIcon";
import ArrowEqualIcon from "mdi-react/MinusCircleOutlineIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import SupplyIcon from "mdi-react/CurrencyUsdIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";

import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import AlertIcon from "mdi-react/InformationOutlineIcon";

import LinearLoading from "../../../../shared/components/LinearLoading";

import EnterpriseGate from "./../../../../shared/components/payment/EnterpriseGate";
import UnitsSearchAppBar from "./components/UnitsSearchAppBar";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";

import { _hasPermission } from "./../../../../shared/helpers/access";
import PermitTypeFilter from "./../../../../shared/components/unit_filters/PermitTypeFilter";
import Switch from "@material-ui/core/Switch";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _formatBed,
  _formatBath,
  _formatOwnerName,
  _formatPhoneNumber,
  jobTypes,
  workTypes,
  jobTypeList,
  permitteeTypes,
  _makePermitsUrl,
  syncURLFilters,
  setLocalStorage,
  deleteLocalStorage,
  _objectEqual,
  _isEqual,
  _tranformTagsQuery,
  getLocalStorage,
  syncStageFilters
} from "../../../../shared/helpers/utils";
import {
  _permitFilters,

} from "../../../../shared/helpers/filters";
import { _axiosCall } from "../../../../shared/helpers/apicalls";

import Panel from "../../../../shared/components/Panel";

const apiURL = process.env.API_ROOT + "/query/";

function mapStateToProps(authData) {
  return {
    authData,
  };
}
const unitStagesKey = "permitStage";

const _permitStageFilter = [
  {
    search_key: "permit_stage",
    type: "array",
    values: [
      {
        filter: "job filings",
      },
      {
        filter: "permit issuances",
      },
    ],
  },
];

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);
const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p>
        <DotsHorizontalIcon />
      </p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}>
        <DropdownItem>Messsage</DropdownItem>
      </Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const Trend = ({ last, current }) => {
  if (last.total_price > current.total_price) {
    return <ArrowDownIcon size={18} color="#f03434" />;
  } else if (last.total_price < current.total_price) {
    return <ArrowUpIcon size={18} color="#4ce1b6" />;
  }

  return <ArrowEqualIcon size={18} color="#999" />;
};

const processHistory = (offeringPlan) => {
  let lastPrice = offeringPlan.statCard_ppOnAccep
    ? parseFloat(
        offeringPlan.statCard_ppOnAccep.replace(/,/g, "").replace("$", "")
      ).toFixed(0)
    : offeringPlan.price_current;

  const newTotalPriceRegEx = /new\s+total\s+offering\s+price\s+\$[0-9\,]+/;
  const newTotalPriceReplaceRegEx = /new\s+total\s+offering\s+price\s+\$/;

  let amendments = [
    {
      number: 0,
      total_price: lastPrice,
      submitted_date: moment(offeringPlan.submitted_date).format("MM/DD/YYYY"),
      contents: offeringPlan.file_number.toUpperCase(),
    },
  ];

  offeringPlan.amendments.map((item) => {
    const newPriceMatch = item.contents.match(newTotalPriceRegEx);
    let newPrice = lastPrice;
    if (newPriceMatch && newPriceMatch[0]) {
      newPrice = parseInt(
        newPriceMatch[0]
          .replace(newTotalPriceReplaceRegEx, "")
          .replace(/\,/g, "")
      );
      lastPrice = newPrice;
    }
    let amendment = {
      number: item.amendmentnum,
      total_price: newPrice,
      submitted_date: item.submitteddate,
      contents: item.contents,
    };
    amendments.push(amendment);
  });

  return amendments;
};
let lsPre = "PP1_";
const subFilterKey = "subFilters";
const defaultSubFilter = {
  min_date: null,
  max_date: null,
};
class Permits extends PureComponent {
  defaultUnitStage = ["all"];
  constructor(props) {
    super(props);
    this.state = {
      lendingData: null,
      permitData: null,
      contactData: null,
      order: "desc",
      orderBy: "recorded_datetime",
      subFilters: syncURLFilters(this.props, _permitFilters, false, false, false),
      fetchInProgress: true,
      groupedView: getLocalStorage(lsPre, 'groupedView') === true ? true : false,
      unitStages: syncStageFilters(
        this.props,
        _permitStageFilter,
        false,
        false,
        this.defaultUnitStage
      ),
    };

    this._filterPermits = this._filterPermits.bind(this);
  }
  _propertyFilter = _permitFilters.filter((filter) => !filter.filter_type && !filter.hide_tab);
  q = "*:*";
  fq = [];
  sort = "";
  unitFq = null;
  
  fl = "*";
  _unitSubFilter = _permitFilters.filter(
    (filter) => !(this.props.buildingView && filter.building_hide)
  );

  componentDidMount() {
    this._getPermitNowData();
    this._getProjectContacts();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let unitStageChange = !_isEqual(
      prevState.unitStages,
      this.state.unitStages
    );
    let subFilterChanged =
    this._propertyFilter.filter(item => {
      return (
        Array.isArray(item.search_key) ? 
          item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
        :
          this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
      );
    }).length > 0;
    if (
     
      searchTagsChange ||
      subFilterChanged ||
      unitStageChange ||
      this.state.groupedView !== prevState.groupedView
    ) {
      this.q = "*:*";
      this._getPermitNowData();

    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };
  _setSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, this.state.subFilters, {
      [search_key]: search_val,
    });
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  };
  
  _setSubFilters = (subFilters) => {
    const subF = Object.assign({}, this.state.subFilters, subFilters);
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage
    });
    
    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  }
  _resetSubFilter = (search_key, search_val) => {
    const subF = !this.props.buildingView
      ? Object.assign({}, defaultSubFilter)
      : {};
    this.setState({
      unitStages: this.defaultUnitStage,
      subFilters: subF,
      groupedView: false,
      start: 0,
      order : "",
      orderBy : "",
      end: this.state.rowPerPage,
    });
    deleteLocalStorage(lsPre, unitStagesKey);
    deleteLocalStorage(lsPre, subFilterKey);
  };
  
  _resetActive = () => {
    let isDefault = true;
    if (this.props.buildingView) {
      isDefault =
        Object.keys(this.state.subFilters).length <= 0 &&
        _objectEqual(this.defaultUnitStage, this.state.unitStages);
    } else {
      isDefault =
        this.props.searchTags.length === 0 &&
        this.state.orderBy == "" &&
        this.state.groupedView == false &&
        _objectEqual(defaultSubFilter, this.state.subFilters) &&
        _objectEqual(this.defaultUnitStage, this.state.unitStages)        
    }
  
    return !isDefault;
  };
  _setUnitStage = (stage) => {
    let newStages = this.state.unitStages.slice();
    const index = newStages.indexOf(stage);		
    const indexAll = newStages.indexOf('all');
    
    
    if(index>=0){
      
      if(stage === "all"){
        newStages = this.defaultUnitStage
      }else{
        newStages.splice(index, 1);
      }
    }else{
      if(stage === "all"){
        newStages = ["all"]
      }else{
        if(indexAll>=0){
          newStages.splice(indexAll, 1);
        }
        newStages.push(stage)
      }
      
    }
    if(newStages.length === 0 ) newStages = this.defaultUnitStage;
    this.setState({
      unitStages: newStages,
      start: 0,
      end: this.state.rowPerPage
    });		
    setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
  }
  
  _getSpecificFilter = (key) => {
    let filter =
      _permitFilters &&
      _permitFilters.filter(
        (filter) =>
          filter.search_key === key ||
          (filter.search_key && filter.search_key.includes(key))
      )[0];
    return filter;
  };


  _getProjectContacts = async () => {
    const { buildingRes } = this.props;

    let mongoQuery = [
		{
			$match: {
				key: buildingRes.key,
			},
		},
		{
			"$lookup": {
				"from": "organizations",
				"localField": "key",
				"foreignField": "projects.key",
				"pipeline": [{
					"$match": {
						"meta.deleted": {
							"$exists": false
						}
					}
				},
				{
					"$project": {
						name: 1,
						key: 1,
						address: 1,
						email: 1,
						phone: 1,
						project_roles: 1,
						project_permit_roles: 1,
						meta: 1,
						name_list: 1
					}
				}
				],
				"as": "organizations"
			}
		},
		{
			"$lookup": {
				"from": "contacts",
				"localField": "key",
				"foreignField": "projects.key",
				"pipeline": [{
					"$match": {
						"meta.deleted": {
							"$exists": false
						}
					}
				},
				{
					"$project": {
						name: 1,
						first_name: 1,
						last_name: 1,
						key: 1,
						address: 1,
						email: 1,
						phone: 1,
						project_roles: 1,
						project_permit_roles: 1,
						organizations: {
							name: 1,
							key: 1,
							name_list: 1,
						},
						organization_associations: 1,
						meta: 1
					}
				}
				],
				"as": "contacts"
			}
		},
		{
			$group: {
				_id: "$_id",
				contacts: { $push: "$contacts" },
				organizations: { $push: "$organizations" },
			},
		},
		{
			$project: {
				name: 1,
				organizations: {
					name: 1,
					key: 1,
					address: 1,
					email: 1,
					phone: 1,
					project_roles: 1,
					project_permit_roles: 1,
					meta: 1,
					name_list: 1,
				},
				contacts: {
					name: 1,
					first_name: 1,
					last_name: 1,
					key: 1,
					address: 1,
					email: 1,
					phone: 1,
					project_roles: 1,
					project_permit_roles: 1,
					organizations: {
						name: 1,
						key: 1,
						name_list: 1,
					},
					organization_associations: 1,
					meta: 1,
				},
			},
		},
		{
			$sort: {
				"contacts.organizations.0.name": 1
			}
		}
	];

    let axiosData = {
      url: process.env.API_ROOT + "/pipeline/aggregate",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        console.log(res);
        if (res && res[0]) {
          this.setState({ contactData: res[0] });
        } else {
          this.setState({ contactData: [] });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };
  

  _getPermitData = async (filings_dobnow) => {
    const { buildingRes } = this.props;

    if (
      !buildingRes.address ||
      (!buildingRes.address.alternate_addresses && !buildingRes.address.full_street_address) ||
      !buildingRes.address.zip
    ) {
      this.setState({ permitData: [] });
      return false;
    }
    let bblQuery = buildingRes.address.block
      ? `(block:${buildingRes.address.block} AND lot:${buildingRes.address.lot} AND borough_code:${buildingRes.address.borough_code})`
      : false;
   const addressQ =
    buildingRes.address && buildingRes.address.alternate_addresses
     ? buildingRes.address.alternate_addresses.join('" OR "')
     : buildingRes.address.full_street_address;

    let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.address.zip} OR borough:"${buildingRes.address.borough}"))`;

    if (bblQuery) {
      q = `(${q}  OR ${bblQuery})`;
    }

    const fl = [
      "recorded_datetime",
      "document_amt",
      "document_id",
      "party*",
      "document_date",
      "doc_type*",
      "id",
      "source_providers",
      "address_2_normalized",
      "property_type",
      "work_type",
      "job*"
    ];

    const fq = ["pre_filing_date:[NOW-10YEARS TO NOW]", q];
    if(this.state.groupedView){
      fq.push("job_doc_number:(*01* *I1*)")
    }
    if(this.state.unitStages.indexOf("job filings")<0 && this.state.unitStages.indexOf("all")<0){
      fq.push("job_doc_number:X")
    }

    let axiosData = {
      url: apiURL + "we3-dob-permits-filings",
      method: "post",
      query: {
        q: this.q,
        fq: fq,
        //fl: fl.join(', '),
        sort: "pre_filing_date asc",
        wt: "json",
        rows: 9999,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        this._getPermitIssuances(res, filings_dobnow);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };

  _getPermitNowData = async () => {
    const { buildingRes } = this.props;
    const fl = [
      "recorded_datetime",
      "document_amt",
      "document_id",
      "party*",
      "document_date",
      "doc_type*",
      "id",
      "source_providers",
      "address_2_normalized",
      "property_type",
      "approved",
      "*status*",
      "*date*",
      "work_type",
      "job*"
    ];
    this.setState({fetchInProgress:true})
    
    const { searchTags } = this.props;
    const { orderBy, order, unitStages, buildingStages } = this.state;
    let locQuery = _tranformTagsQuery(searchTags, "building").replace("text:", "job_number:");
   
    this.unitFq = this.fq.slice();

    /*
    if (this.state.filterBorough !== "all_boroughs") {
      this.q += `(borough:"${this.state.filterBorough
        .replace("_", " ")
        .toLowerCase()}")`;
    }
*/
    if (locQuery && !this.props.buildingView) {
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += `${locQuery}`;
    }

    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          const theFilter = this._propertyFilter.filter(
            (filter) => filter.search_key === key
          );
          let qVal = null;

          if (theFilter && theFilter[0] && theFilter[0].text_filter) {
            if(theFilter[0].search_fields){
              qVal = subFilterVal.split(" ").map(term => {
                  return "(" + theFilter[0].search_fields.map(field => {
                    return `${field}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*)`
                  }).join(" OR ") + ")"
                }).join(" AND ");
                
              
              
            }else{
              qVal = subFilterVal.split(" ").map(term => {
                return `(${key}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*))`
              }).join(" AND ");
            }
            
            
            if(qVal!==null){						
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${qVal})`;
              
            }
          } else if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
            const qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => subFilterVal.indexOf(value.filter) > -1
              ).length > 0
                ? theFilter[0].values
                    .filter((value) => subFilterVal.indexOf(value.filter) > -1)
                    .map((value) => value.search_value)
                : null;
            if (qVal !== null) {
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${key}:(${qVal.join(" ")}))`;
            }
          } else {
            qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => value.filter === subFilterVal
              ).length > 0
                ? theFilter[0].values.filter(
                    (value) => value.filter === subFilterVal
                  )[0].search_value
                : null;
            if (qVal !== null) {
              if (theFilter[0].target && theFilter[0].target === "fq") {
                this.unitFq.push(qVal);
              } else {
                this.q += this.q.length > 0 ? " AND " : "";
                this.q += `(${key}:${qVal})`;
              }
            }
          }
        }
      });
    }
    const isAvailable =
      this.state.subFilters.current_status &&
      ["available", "active", "shadow"].indexOf(
        this.state.subFilters.current_status
      ) >= 0;

    /****Price & Date query***/
    let priceQ = {};
    let dateQ = {};
    
    let replaceKeys = {};
    let dateReplaceKeys = {};
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map(key => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          const qArrayFilter = this._propertyFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
          
          if(qArrayFilter.length>0){	
            
            
            const filterKey = qArrayFilter[0].filter_key;
            console.log("qArrayFilter", qArrayFilter);
            if(replaceKeys[filterKey]){
              replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
            }else{
              replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
            }				
            if(priceQ[filterKey]){
              let regex = new RegExp(`{${key}}`, "g");
              priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
            }else{
              if(isAvailable && filterKey == "asking_price"){
                priceQ[filterKey] = 'last_listed_sale_price_all:[{min_price} TO {max_price}]'.replace(`{${key}}`, subFilterVal);
              }else{
                priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
              }
              
            }											
          }	
          
          const dateArrayFilter = this._propertyFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
        
          if(dateArrayFilter.length>0){	
            const filterKey = dateArrayFilter[0].filter_key;
            
            if(dateReplaceKeys[filterKey]){
              dateReplaceKeys[filterKey]  = [...dateReplaceKeys[filterKey], ...dateArrayFilter[0].search_key];
            }else{
              dateReplaceKeys[filterKey] = [...[], ...dateArrayFilter[0].search_key]
            }	
            
                      
            if(dateQ[filterKey]){
              let regex = new RegExp(`{${key}}`, "g");
              dateQ[filterKey] = dateQ[filterKey].replace(regex, moment(subFilterVal).toISOString())
            }else{		
              dateQ[filterKey] = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
              
            }											
          }	
        }
      });		
    }	
    console.log("priceQ", priceQ, replaceKeys)
    if(priceQ && Object.keys(priceQ).length>0){
      
      Object.keys(priceQ).map(filterKey => {
        const qArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
        replaceKeys[filterKey].map(key => {
          let regex = new RegExp(`{${key}}`, "g");
          if(key.indexOf("min")>=0){
            priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
          }else{
            if(key.indexOf("max")>=0 && qArrayFilter && qArrayFilter.target && qArrayFilter.target=="fq"){
              priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
            }else{
              priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
            }
            
          }
          
        
        })
        
        if(qArrayFilter && qArrayFilter.target && qArrayFilter.target =="fq"){
          this.unitFq.push(priceQ[filterKey]);
        }else{
          this.q += this.q.length > 0 ? " AND " : "";
          this.q += '(';
          this.q += priceQ[filterKey];
          this.q += ')';	
        }
        
        
      })
          
    }
    console.log("dateQ", dateQ, dateReplaceKeys)
    if(dateQ && Object.keys(dateQ).length>0){
      Object.keys(dateQ).map(filterKey => {
        const dateArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
        dateReplaceKeys[filterKey].map(key => {
          let regex = new RegExp(`{${key}}`, "g");
          dateQ[filterKey] = dateQ[filterKey].replace(regex, '*')
          
        
        })
        
        if(dateArrayFilter && dateArrayFilter.target && dateArrayFilter.target =="fq"){
          this.unitFq.push(dateQ[filterKey]);
        }else{
          this.q += this.q.length > 0 ? " AND " : "";
          this.q += '(';
          this.q += dateQ[filterKey];
          this.q += ')';	
        }
      })
      
          
    }
    /****Price& Date query***/
    if (
      !buildingRes.address ||
      (!buildingRes.address.alternate_addresses && !buildingRes.address.full_street_address) ||
      !buildingRes.address.zip
    ) {
      this.setState({ permitNowData: [] });
      return false;
    }
    let bblQuery = buildingRes.address.block
      ? `(block:${buildingRes.address.block} AND lot:${buildingRes.address.lot} AND borough_code:${buildingRes.address.borough_code})`
      : false;
    const addressQ =
      buildingRes.address && buildingRes.address.alternate_addresses
        ? buildingRes.address.alternate_addresses.join('" OR "')
        : buildingRes.address.full_street_address;

    let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.address.zip} OR borough:"${buildingRes.address.borough}"))`;

    if (bblQuery) {
      q = `(${q} OR ${bblQuery})`;
    }
    const fq = ["filing_date:[NOW-10YEARS TO NOW]", q];
    if(this.state.unitStages.indexOf("job filings")<0 && this.state.unitStages.indexOf("all")<0){
      fq.push("job_doc_number:X")
    }
   
    if(this.state.groupedView){
      fq.push("job_doc_number:(*01* *I1*)")
    }

    let axiosData = {
      url: apiURL + "we3-dobnow-permits-filings",
      method: "post",
      query: {
        q: this.q,
        fq: fq,
        //fl: fl.join(', '),
        sort: "filing_date asc",
        wt: "json",
        rows: 9999,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        this._getPermitData(res);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };

  _getPermitIssuances = async (filings, filings_dobnow) => {
    const { buildingRes } = this.props;

    if (
      !buildingRes.address ||
      (!buildingRes.address.alternate_addresses && !buildingRes.address.full_street_address) ||
      !buildingRes.address.zip
    ) {
      this.setState({ permitNowData: [] });
      return false;
    }
    let bblQuery = buildingRes.address.block
      ? `(block:${buildingRes.address.block} AND lot:${buildingRes.address.lot} AND borough_code:${buildingRes.address.borough_code})`
      : false;
    const addressQ =
      buildingRes.address && buildingRes.address.alternate_addresses
        ? buildingRes.address.alternate_addresses.join('" OR "')
        : buildingRes.address.full_street_address;

    let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.address.zip} OR borough:"${buildingRes.address.borough}"))`;

    if (bblQuery) {
      q = `(${q} OR ${bblQuery})`;
    }
    const fq = ["filing_date:[NOW-20YEARS TO NOW]", q];
    if(this.state.groupedView){
      fq.push("job_doc_number:(*01* *I1*)")
    }
    if(this.state.unitStages.indexOf("permit issuances")<0 && this.state.unitStages.indexOf("all")<0){
      fq.push("job_doc_number:X");
    }
    let axiosData = {
      url: apiURL + "dob-permits",
      method: "post",
      query: {
        q: this.q,
        fq: fq,
        //fl: fl.join(', '),
        sort: "issuance_date asc",
        wt: "json",
        rows: 9999,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        this.setState({
          permitData: this._filterPermits(filings, res, filings_dobnow),
          fetchInProgress: false
        });
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };

  _filterPermits(filings, issuances, filings_dobnow) {
    var filteredPermits = {};
    if (filings && filings.docs && filings.docs.length > 0) {
      for (var i = 0; i < filings.docs.length; i++) {
        var permit = filings.docs[i];
        // 				var key = permit.job_number + '-' + permit.job_doc_number;
        if (filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]) {
          if (
            filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].job_doc_number >
            permit.job_doc_number
          ) {
            filteredPermits[`${permit.job_number}-${permit.job_doc_number}`] = permit;
          }
        } else {
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`] = permit;
        }
      }
    }

    if (
      filings_dobnow &&
      filings_dobnow.docs &&
      filings_dobnow.docs.length > 0
    ) {
      for (var i = 0; i < filings_dobnow.docs.length; i++) {
        var permit = filings_dobnow.docs[i];

        permit.pre_filing_date = permit.filing_date;
        // 				var key = permit.job_number + '-' + permit.job_doc_number;
        if (filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]) {
          // if (
          //   filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].job_doc_number >
          //   permit.job_doc_number
          // ) {
            filteredPermits[`${permit.job_number}-${permit.job_doc_number}`] = permit;
          // }
        } else {
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`] = permit;
        }
        console.log(`${permit.job_number}-${permit.job_doc_number}`, filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]['work_type'])

      }
    }

    if (issuances && issuances.docs && issuances.docs.length > 0) {
      for (var i = 0; i < issuances.docs.length; i++) {
        var permit = issuances.docs[i];
        if (filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]) {
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`] = Object.assign({},filteredPermits[`${permit.job_number}-${permit.job_doc_number}`], permit);
        } else {
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`] = permit;
        }
        
        if(!filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]['work_type'] && permit.permit_type){
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]['work_type'] = permit.permit_type;
        }
//console.log(`${permit.job_number}-${permit.job_doc_number}`, filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]['work_type'])
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].issued = true;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].issuance_date = permit.issuance_date;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].status = permit.filing_status;
    
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_business_name = permit.permittee_business_name;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_first_name = permit.permittee_first_name;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_last_name = permit.permittee_last_name;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_license_number = permit.permittee_license_number;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_license_type = permit.permittee_license_type;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_other_title = permit.permittee_other_title;
        filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].permittee_phone_number = permit.permittee_phone_number;
        
        if(permit.job_description){
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`]['job_description'] = permit.job_description;
        }
        
        if (
          !filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].pre_filing_date &&
          permit.filing_date
        ) {
          filteredPermits[`${permit.job_number}-${permit.job_doc_number}`].pre_filing_date =
            permit.filing_date;
        }
      }
    }
console.log(filteredPermits)
    var filteredPermitsArray = [];
    for (var key in filteredPermits) {
      
      filteredPermitsArray.push(filteredPermits[key]);
    }

    filteredPermitsArray.sort((a, b) =>
      a.pre_filing_date < b.pre_filing_date
        ? 1
        : b.pre_filing_date < a.pre_filing_date
        ? -1
        : 0
    );

    return filteredPermitsArray;
  }
  
  _extractPerson(contact, contactData) {
    if (contactData && contactData.contacts) {
      contactData.contacts.map((c) => {
        if (
          c["first_name"] == contact["first_name"] &&
          c["last_name"] == contact["last_name"]
        ) {
          contact["personData"] = Object.assign({}, c);
        }
      });
    }

    return contact;
  }

  _extractCompany(contact, contactData) {
    if (
      contactData &&
      contactData.organizations &&
      contactData.organizations[0]
    ) {
      contactData.organizations[0].map((c) => {
        if (
          c["name_list"] &&
          (c["name_list"].indexOf(contact["business_name"]) >= 0 ||
            c["name_list"]
              .map((n) => n.replace(/([^a-z0-9]+)/gi, ""))
              .indexOf(
                contact["business_name"].replace(/([^a-z0-9]+)/gi, "")
              ) >= 0)
        ) {
          contact["companyData"] = Object.assign({}, c);
        }
      });
    }

    return contact;
  }
  _extractContacts(permit, contactData) {
    let contacts = [];
    const permitTypes = {
      5: "New Building",
      4: "Alteration",
      6: "Alteration",
      nb: "New Building",
      a1: "Alteration",
      dm: "Demolition",
    };

    let contact = {};

    contact = {};
    contact["type"] = "filing representative";
    contact["first_name"] = permit.filing_applicant_first_name
      ? permit.filing_applicant_first_name
      : "";
    contact["last_name"] = permit.filing_applicant_last_name
      ? permit.filing_applicant_last_name
      : "";
    contact["business_name"] = permit.filing_applicant_business_name
      ? permit.filing_applicant_business_name
      : "";
    contact["phone"] = permit.filing_applicant_business_phone
      ? permit.filing_applicant_business_phone
      : "";
    contact["email"] = permit.filing_applicant_email
      ? permit.filing_applicant_email
      : "";
    contact["business_address"] = permit.filing_applicant_business_address
      ? permit.filing_applicant_business_address
      : "";
    contact["business_state"] = permit.filing_applicant_business_state
      ? permit.filing_applicant_business_state
      : "";
    contact["business_zip"] = permit.filing_applicant_business_zip
      ? permit.filing_applicant_business_zip
      : "";
    contact["mobile_phone"] = permit.filing_applicant_phone_mobile
      ? permit.filing_applicant_phone_mobile
      : "";
    contact["role"] = `Listed as filing representative on ${
      permitTypes[permit.job_type]
    } permit ${
      permit.pre_filing_date
        ? moment(permit.pre_filing_date).fromNow()
        : moment(permit.filing_date).fromNow()
    }`;
    contact["date"] = permit.pre_filing_date
      ? permit.pre_filing_date
      : permit.filing_date;
    if (contact["first_name"]) {
      contact = this._extractPerson(contact, contactData);
      contact = this._extractCompany(contact, contactData);
      contacts.push(contact);
    }

    contact = {};
    contact["type"] = "architect";
    contact["first_name"] = permit.applicant_first_name
      ? permit.applicant_first_name
      : "";
    contact["last_name"] = permit.applicant_last_name
      ? permit.applicant_last_name
      : "";
    contact["business_name"] = permit.applicant_business_name
      ? permit.applicant_business_name
      : "";
    contact["phone"] = permit.applicant_business_phone
      ? permit.applicant_business_phone
      : "";
    contact["email"] = permit.applicant_email ? permit.applicant_email : "";
    contact["business_address"] = permit.applicant_business_address
      ? permit.applicant_business_address
      : "";
    contact["business_state"] = permit.applicant_business_state
      ? permit.applicant_business_state
      : "";
    contact["business_zip"] = permit.applicant_business_zip
      ? permit.applicant_business_zip
      : "";
    contact["mobile_phone"] = permit.applicant_phone_mobile
      ? permit.applicant_phone_mobile
      : "";
    contact["role"] = `Listed as architect on ${
      permitTypes[permit.job_type]
    } permit ${
      permit.pre_filing_date
        ? moment(permit.pre_filing_date).fromNow()
        : moment(permit.filing_date).fromNow()
    }`;
    contact["date"] = permit.pre_filing_date
      ? permit.pre_filing_date
      : permit.filing_date;
    if (contact["first_name"]) {
      contact = this._extractPerson(contact, contactData);
      contact = this._extractCompany(contact, contactData);
      contacts.push(contact);
    }

    contact = {};
    contact["type"] = "owner";
    contact["first_name"] = permit.owner_first_name
      ? permit.owner_first_name
      : "";
    contact["last_name"] = permit.owner_last_name ? permit.owner_last_name : "";
    contact["business_name"] = permit.owner_business_name
      ? permit.owner_business_name
      : "";
    contact["phone"] = permit.owner_business_phone
      ? permit.owner_business_phone
      : permit.owner_phone_number
      ? permit.owner_phone_number
      : "";
    contact["email"] = permit.owner_business_email
      ? permit.owner_business_email
      : "";
    contact["business_address"] = permit.owner_business_address
      ? permit.owner_business_address
      : permit.owner_house_street_name
      ? permit.owner_house_street_name
      : "";
    contact["business_state"] = permit.owner_business_state
      ? permit.owner_business_state
      : "";
    contact["business_zip"] = permit.owner_business_zip
      ? permit.owner_business_zip
      : "";
    contact["mobile_phone"] = permit.owner_phone_mobile
      ? permit.owner_phone_mobile
      : "";
    contact["role"] = `Listed as owner on ${
      permitTypes[permit.job_type]
    } permit ${
      permit.pre_filing_date
        ? moment(permit.pre_filing_date).fromNow()
        : moment(permit.filing_date).fromNow()
    }`;
    contact["date"] = permit.pre_filing_date
      ? permit.pre_filing_date
      : permit.filing_date;
    if (contact["first_name"]) {
      contact = this._extractPerson(contact, contactData);
      contact = this._extractCompany(contact, contactData);
      contacts.push(contact);
    }
    if(permit.permittee_first_name){
      contact = {};
      contact["type"] = permitteeTypes[permit.permittee_license_type] ? permitteeTypes[permit.permittee_license_type] : permit.permittee_license_type.toString().toUpperCase();
      contact["first_name"] = permit.permittee_first_name
        ? permit.permittee_first_name
        : "";
      contact["last_name"] = permit.permittee_last_name ? permit.permittee_last_name : "";
      contact["business_name"] = permit.permittee_business_name
        ? permit.permittee_business_name
        : "";
      contact["phone"] = permit.permittee_business_phone
        ? permit.permittee_business_phone
        : permit.permittee_phone_number
        ? permit.permittee_phone_number
        : "";
      contact["email"] = permit.permittee_email
        ? permit.permittee_email
        : "";
      
      contact["role"] = permitteeTypes[permit.permittee_license_type] ? permitteeTypes[permit.permittee_license_type] : permit.permittee_license_type.toString().toUpperCase();
      contact["date"] = permit.issuance_date
        ? permit.issuance_date
        : permit.filing_date;
      if (contact["first_name"]) {
        contact = this._extractPerson(contact, contactData);
        contact = this._extractCompany(contact, contactData);
        contacts.push(contact);
      }
    }

    return contacts;
  }

  render() {
    const { offeringPlan, buildingRes, user, trxnRes } = this.props;
    const { permitData, contactData, order, orderBy, subFilters, fetchInProgress, groupedView, unitStages } = this.state;
    console.log(permitData);


    return (
      <React.Fragment>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody style={{ border: "none", padding: "5px" }}>
              <UnitsSearchAppBar
                handleSearch={this._filterTableByInput}
                handleDeleteSelected={this.handleDeleteSelected}
                onRequestSort={this.handleRequestSort}
                subFilters={subFilters}
                searchText={""}
                setSubFilter={this._setSubFilter}
                resetSubFilter={this._resetSubFilter}
                hideProjects
                _filters={this._propertyFilter}
                {...this.props}
                groupedView
                building={this.props.building}
                setSubFilters={this._setSubFilters}
                UnitStageFilter={false}
                // UnitStageFilter={"not required"}
                numToShow={5}
                resetActive={this._resetActive()}
                q={this.q}
                fq={this.unitFq}
                sort={this.sort}
                totalCount={0}
                showExport={false}
                core_name={"dob-permits"}
                export_name={`Permit Issuances`}
                title={"Export Permit Issuances (CSV)"}
                description={
                  <Fragment>
                    <h4>
                      <strong>Export past sales for your own analysis.</strong>
                    </h4>{" "}
                    <br /> The export will include details about unit sold
                    price, sold date, bed, bath and size.
                  </Fragment>
                }
              />
              <div className="material-table__header-info unit_search">
              <Row>
                <Col md={12} lg={12}>
                  {!isMobile &&
                   (
                     <div style={{display:"flex",alignItems:"center"}}>
                      <div className={`unit_toggle_filter`}>
                        <PermitTypeFilter
                          unitStages={unitStages}
                          setUnitStage={this._setUnitStage}
                        />
                      </div>
                      <div style={{marginRight:35,marginTop:-4}}>{' | '}</div>
                      <div className={`unit_toggle_filter`} style={{alignItem:"center"}}>
                      <span className="label" style={{marginRight:10,marginTop:5}}>Subsequent Filings:</span>
                      <div style={{marginTop:-3}}>
                        <span>{'Show'}</span>
                            <Tooltip title={`${this.state.groupedView ? "Show Only Initial Filing and Issuance" : "Show All Filings and Issuances"}`}>
                            <PurpleSwitch 
                              checked={this.state.groupedView}
                              onChange={() => {this.setState({groupedView : !groupedView})}}
                            />
                          </Tooltip>
                          <span>{'Hide'}</span>					      	
                      </div>
                      </div>
                     </div>
                  )}
                  </Col>
                </Row>
              </div>
              {(permitData === null || contactData === null || fetchInProgress) ? 
                 <LinearLoading />
              : (permitData !== null && permitData.length <= 0) ?
                 (
                  <Col md={12}>
                    <Card>
                      <CardBody className={`small`}>
                        <div className={`email-confirmation`}>
                          <div className="email-confirmation__icon">
                            <AlertIcon size={50} color={`#D6BAE8`} />
                          </div>
                          <h3 className="email-confirmation__title">No Permits Found</h3>
                          <p className="email-confirmation__sub"></p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )
                :
                <div className="material-table__wrap">
                  <div className="material-table expansion__table">
                    <Container>
                      <Row className="expansion__summary__header">
                        <Col lg={1} className="no-padding">
                          Filed
                        </Col>
                        <Col lg={2} xs={12} >	                          

					      Status
	
						</Col>
                        <Col lg={3} xs={12}>
                          Type
                        </Col>

                        <Col lg={1} className="no-padding">
                          Estimated Cost
                        </Col>
                        <Col lg={5} xs={12}>
                          Description
                        </Col>
                      </Row>
                    </Container>

                    {permitData.map((item, index) => {
                      const contacts = this._extractContacts(item, contactData);
                      let highLight = false;
                      if(trxnRes && trxnRes.job_number && trxnRes.job_number == item.job_number && trxnRes.job_doc_number && trxnRes.job_doc_number == item.job_doc_number){
                        highLight = true;
                      }
                      const isPersonalOwner = (!item.owner_business_name || item.owner_business_name == "" || item.owner_business_name == "pr" || item.owner_business_name == "n/a")

                      return (
                        <ExpansionPanel
                          TransitionProps={{ unmountOnExit: true }}
                          id={item.val}
                          className={`${highLight && 'highlight_row'}`}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls=""
                            id={item.id}
                            
                          >
                            <Container>
                              <Row className={`expansion__summary`}>
                                <Col lg={1} xs={12} className="no-padding">
                                  <span
                                    className={
                                      "mobile__show light-text small-text"
                                    }
                                  >
                                    Filed:
                                  </span>
                                  <span>
                                    {moment(
                                      item.pre_filing_date.split("T")[0],
                                      "YYYY-MM-DD"
                                    ).format("MM/DD/YYYY")}
                                  </span>
                                  <br />
                                  <span className={"light-text small-text"}>
                                    {_makePermitsUrl(item)}
                                  </span>
                                </Col>
                                <Col lg={2} xs={12} className="">
                                  <span
                                    className={
                                      "mobile__show light-text small-text"
                                    }
                                  >
                                    Status:
                                  </span>
                                  <span>
                                    {item.issuance_date ? 
                                      <Fragment>Issued<br/>
                                      <span className={"light-text small-text"}>
                                        ({moment(
                                            item.issuance_date.split("T")[0],
                                            "YYYY-MM-DD"
                                          ).format("MM/DD/YYYY")}{item.status && ` - ${_capitalizeText(item.status)}`})
                                      </span>
                                    </Fragment> 
                                    : item.approved ? 
                                    <Fragment>Approved<br/>
                                      <span className={"light-text small-text"}>
                                        ({moment(
                                            item.approved.split("T")[0],
                                            "YYYY-MM-DD"
                                          ).format("MM/DD/YYYY")})
                                      </span>
                                    </Fragment> : item.filing_status_description && item.current_status_date ? <Fragment>{_capitalizeText(item.filing_status_description)}<br/><span className={"light-text small-text"}>
                                      ({moment(
                                          item.current_status_date.split("T")[0],
                                          "YYYY-MM-DD"
                                        ).format("MM/DD/YYYY")})
                                    </span></Fragment> : '--'}
                                  </span>
                                  <br />
                              </Col>
                              
                                <Col lg={3} xs={12}>
                                  <span
                                    className={
                                      "mobile__show light-text small-text"
                                    }
                                  >
                                    Job Type:
                                  </span>
                                  <span>
                                    {item.job_type && jobTypes[item.job_type]
                                      ? jobTypes[item.job_type]
                                      : item.job_type}
                                  </span>
                                  <br />

                                  {item.work_type &&
                                  item.work_type
                                    .split("/")
                                    .filter((wt) => workTypes[wt]).length > 0 ? (
                                    <span className={"light-text small-text"}>
                                      {item.work_type
                                        .split("/")
                                        .map((wt) =>
                                          _capitalizeText(workTypes[wt])
                                        )
                                        .join(", ")}
                                    </span>
                                  ) : (
                                    <span className={"light-text small-text"}>
                                      {_capitalizeText(item.work_type)}
                                    </span>
                                  )}
                                </Col>

                                <Col lg={1} xs={12} className="no-padding">
                                  <span
                                    className={
                                      "mobile__show light-text small-text"
                                    }
                                  >
                                    Estimated Cost:
                                  </span>
                                  <span>
                                    {item.initial_cost && item.initial_cost > 1
                                      ? "$" + _formatNumber(item.initial_cost)
                                      : "--"}
                                  </span>
                                </Col>
                                <Col lg={5} xs={12}>
                                  <span
                                    className={
                                      "mobile__show light-text small-text"
                                    }
                                  >
                                    Description:
                                  </span>
                                  {item.job_description
                                    ? _capitalizeText(item.job_description)
                                    : "--"}
                                </Col>
                              </Row>
                            </Container>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Container>
                              <hr />
                              <Row>
                                {contacts &&
                                  contacts.map((contact) => (
                                    <Col lg={4} xs={12} className="mb-3">
                                      <div className={"bold-text"}>
                                        {_capitalizeText(contact.type)}
                                      </div>
                                      <div>
                                        Contact:{" "}
                                        {contact.personData ? (
                                          <Link
                                            className={"link-text"}
                                            to={`/contact/${contact.personData["key"]}`}
                                          >
                                            {_capitalizeText(
                                              contact.personData["name"]
                                            )}{" "}
                                          
                                          </Link>
                                        ) : (
                                          <span>
                                            {_capitalizeText(
                                              contact.first_name
                                            )}{" "}
                                            {_capitalizeText(contact.last_name)}
                                          </span>
                                        )}
                                      </div>
                                      <div>
                                        Company:{" "}
                                        {contact.companyData ? (
                                          <Link
                                            className={"link-text"}
                                            to={`/company/${contact.companyData["key"]}`}
                                          >
                                            {_capitalizeText(
                                              contact.companyData["name"]
                                            )}{" "}
                                            
                                          </Link>
                                        ) : (
                                          <span>
                                            {!isPersonalOwner && contact.business_name
                                              ? _capitalizeText(
                                                  contact.business_name
                                                )
                                              : "--"}
                                          </span>
                                        )}
                                      </div>
                                   {/*   <div>
                                        Phone:{" "}
                                        {contact.phone ? (
                                          <a
                                            className="link-text"
                                            href={`tel:${contact.phone}`}
                                            target="_blank"
                                          >
                                            <PhoneOutlineIcon
                                              size={11}
                                              style={{ marginTop: -2 }}
                                            />{" "}
                                            {_formatPhoneNumber(contact.phone)}
                                          </a>
                                        ) : (
                                          <span>--</span>
                                        )}
                                      </div>
                                      <div>
                                        Email:{" "}
                                        {contact.email ? (
                                          <a
                                            className="link-text"
                                            href={`mailto:${contact.email}`}
                                            target="_blank"
                                          >
                                            <EmailOutlineIcon
                                              size={11}
                                              style={{ marginTop: -2 }}
                                            />{" "}
                                            {contact.email}
                                          </a>
                                        ) : (
                                          <span>--</span>
                                        )}
                                      </div>*/}
                                    </Col>
                                  ))}
                              </Row>
                            </Container>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      );
                    })}
                  </div>
                </div>
              }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  mapStateToProps,
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags)
    ? state.search.searchTags.slice()
    : [],
}))(withRouter(Permits));
