import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import PhoneIcon from 'mdi-react/PhoneClassicIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import DownIcon from 'mdi-react/ChevronDownIcon';
import RightIcon from 'mdi-react/ChevronRightIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
  _capitalizeText,
  _truncateWords
} from '../helpers/utils';



class ShareContact extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
  this.unit = false;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
  
 
 
  render(){
    const { user, urlState, sharePageData, min, print } = this.props;
    const { collapse } = this.state;
    const contactForm = sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_form && sharePageData.custom_data.contact_form != "" ? sharePageData.custom_data.contact_form : false;
    
    if(!(urlState && urlState.ap) && !(sharePageData && sharePageData.custom_data && sharePageData.custom_data.message) && !contactForm) return null;
    return (
    <Row>
      <Col md={12} lg={12} xl={12}>
          <Card>
              <CardBody className="share_contact_container"> 
                  {((urlState && urlState.ap) || contactForm) &&
            <div className="contact__card">
                    <div className="topbar__avatar-img-container">
                      <div className="topbar__avatar-img" style={{backgroundImage : `url(${user.avatar})`}} alt="avatar" />
              
                    </div>
                  <div className="contact__left">
                    <p className="light-text small-text share__by">Property Shared by</p>
                    <h4 className="contact__title" style={{marginTop:10}}>{user.firstName} {user.lastName}</h4>
                    {user.title &&
                    <h5>{_capitalizeText(user.title.replace(/-/g, ' '))}
                      {user.company && 
                        <span> | {user.company}</span>
                      }
                  
                {!min && user.agentLicenseNumber && 
                    <span> {isMobile ? <br/> : " | "}License #: {user.agentLicenseNumber}</span>
                }
                    
                    </h5>
                    }
                    {!min && user.biography && 
                <div className="biography-link">
                    <span onClick={this.toggle}>About Me {collapse ? <DownIcon size={14} style={{marginTop:-2}} /> : <RightIcon size={14} style={{marginTop:-2}} />}</span>
                  </div>
                }
                    {!user.biography && !isMobile && user.streetAddress &&
                      <p className="small-text light-text mt-1">
                  
                        {user.streetAddress} 
                        {user.city && <span>, {user.city}</span>}
                        {user.state && <span>, {user.state}</span>}
                        {user.zipCode && <span> {user.zipCode}</span>}
                        {/*(sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_form && ["tour", "contact"].indexOf(sharePageData.custom_data.contact_form)>=0) &&
                      <Fragment>
                        {user.primaryPhone &&
                          <span> | {user.primaryPhone}</span>
                        }
                        
                        {user.email &&
                          <span> | {user.email}</span>
                        }
                      </Fragment>
                   */}
                      </p>
                  }
              
                  </div>
                  <div className="contact__right">
              {!(sharePageData.custom_data.contact_form && ["tour", "contact"].indexOf(contactForm)>=0) &&
                    <div className="top__toolbar">
                    {user.primaryPhone &&
                  <Tooltip title={`Call or text ${user && user.firstName} for more information`}>
                     <a className="" href={`tel:${user.primaryPhone}`} target="_blank">
                        <Button
                           
                           variant="contained"
                           color="secondary"
                           className="buyersList__secondary-button"		         
                           startIcon={<PhoneIcon />}
                       >	{user.primaryPhone}
                       </Button>	
                  </a>			 
                  </Tooltip>
               }
                  <Tooltip title={`Email ${user && user.firstName} for more information`}>
                     <a className="" href={`mailto:${user.email}`} target="_blank">
                        <Button								         
                           variant="contained"
                           color="secondary"
                           className="buyersList__secondary-button"		         
                           startIcon={<EmailIcon />}
                       >	{print ? user.email : "Email"}
                       </Button>	
                  </a>			 
                  </Tooltip>						  		
                </div>
              }
                  </div>
            </div>
            }
          {user.biography && 
 
               <Collapse isOpen={this.state.collapse}>
                 <Fragment>
                 <hr/>
                <div className="user_bio" >
                   
                   <div
                     dangerouslySetInnerHTML={{
                     __html: user.biography
                     }}>
                   </div>
                 </div>
                 </Fragment>
               </Collapse>
                 
            
          }
          {!min &&
            <Fragment>
              {((urlState && urlState.ap) || contactForm) && ((sharePageData.custom_data && sharePageData.custom_data.prepared_for) || (sharePageData && sharePageData.custom_data && sharePageData.custom_data.message)) && <hr/>}
              {(sharePageData.custom_data && sharePageData.custom_data.prepared_for) &&
                 <div className="share_message mb-3" >
                  <span className="bold-text">Prepared For:</span>  {sharePageData.custom_data.prepared_for}
                  
                 </div>
               }
                {sharePageData && sharePageData.custom_data && sharePageData.custom_data.message &&
                    
                    <div className="share_message" >
                      
                          
                    <div
                      dangerouslySetInnerHTML={{
                      __html: sharePageData.custom_data.message
                      }}>
                    </div>
                            
                      </div>
               }
              </Fragment>
          }
          
          
          
              </CardBody>
            </Card>
      </Col>
    </Row>
  
    );
  }
};
export default ShareContact;