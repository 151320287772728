import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import BuildingTabContainer from './components/BuildingTabContainer';
import { RTLProps } from '../../shared/prop-types/ReducerProps';
import LinearLoading from '../../shared/components/LinearLoading';
import ProjectViewsAlert from '../../shared/components/payment/ProjectViewsAlert';
import AlertIcon from 'mdi-react/AlertDecagramOutlineIcon';
import Alert from '../../shared/components/Alert';
import EnterpriseGate from "../../shared/components/payment/EnterpriseGate";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	buildingFL,
	GenerateBuildingSlug, 
	GenerateBuildingKey
} from '../../shared/helpers/utils';

import{
	_axiosCall,
	_makeBuildingAxiosData,
} from '../../shared/helpers/apicalls';

import { 
	_getUserProjectViews
} from '../../shared/components/payment/plans';

const apiURL = process.env.API_ROOT + '/query/';
const buildingUrl = process.env.API_ROOT + '/pipeline/';

class SingleBuilding extends PureComponent {
	
	
	_boro = _urlToName(this.props.match.params.borough);
    _hood = _urlToName(this.props.match.params.neighborhood);

	state = {
      buildingRes: null,
      permitFiling: false,
      fetchInProgress: true,
      tab: false,
	  mpndBuilding:false,
	  planViews:null
	}
	
	componentDidMount() {
		
		
		this._isMounted = true; 
		if(!this.state.buildingRes && this.state.fetchInProgress) {
			//this._getBuildingData();
			this._getMongoBuildingData();
		}
		if(window.instgrm && window.instgrm.Embeds){
			window.instgrm.Embeds.process();
		}
		
		console.log("componentDidMount SingleBuilding")
		
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {	
		console.log(this.props);	
		if(prevProps.match.params.building_slug !== this.props.match.params.building_slug) {
			//this._getBuildingData();
			this._getMongoBuildingData();
		}
		
		if(prevProps.match.params.tab !== this.props.match.params.tab){
			this.setState({tab:this.props.match.params.tab })
		}
		 
    }
    
    _getMongoBuildingData = () => {
     // console.log('_getBuildingData called', this.state)
	 const { user } = this.props;
     if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  const buildingSlug = this.props.match.params.building_slug || this.props.project_id;
	
	  
	  let axiosData = {
			url: buildingUrl + "query",
			method: "post",
			query: { query : { "_id" : `${buildingSlug}`, "meta.deleted" : {"$exists" : false } }}
			
			
	   	}
	  
	  _axiosCall(axiosData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    //if(this._isMounted && res && res.docs && res.docs[0]) {
		if(this._isMounted && res && res.docs && res.docs[0]) {
			let doc = res.docs[0];
		    //doc = Object.assign(doc, doc['attributes']);
			//delete(doc['attributes'])
			//doc = Object.assign(doc, doc['address']);
			//delete(doc['address']);
			doc['building_slugs'] = [GenerateBuildingSlug(doc)];
			doc['solr_key'] = doc.building_key ? doc.building_key : GenerateBuildingKey(doc);
			doc['master_key'] = doc['key'];
			if(doc['source_provider'] && doc['source_provider'].indexOf("mpnd")>-1){
				this._getMPNDBuilding(doc);
			}
			
			_getUserProjectViews(user, doc['key']).then(res => {
				const planViews = res;
				this.setState({planViews:planViews})
			})
			
			this._getBuildingPermitData(doc);
			
			  this.setState({
		      	buildingRes: doc,
		      	fetchInProgress: false
		      });
	    }else{
			this.setState({
				  buildingRes: false,
				  planViews: false,
				  fetchInProgress: false
			  });
		}
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}
	
	
	  _getMPNDBuilding = (buildingRes) => {		
		
		if(!(buildingRes.key_list && Array.isArray(buildingRes.key_list))) return false;
		let q = `master_key:(${buildingRes.key_list.filter(k => k!==null && k.length>0).join(" ")})`;
	  
	  
		let axiosData = {
		  url: apiURL + "we3-buildings?cache=60",
		  method: "post",
		  query: {
			q: q,
			fq : ["deleted:false", "ownership_type:(*condo*)", "-offering_verified_status:excluded"],
			sort : "solr_updated_date desc",
			wt: "json",
			rows: 999,
			
		  }
		};
	  
		let formattedData = _axiosCall(axiosData)
		  .then(res => {
			// console.log('Chart Data', res);
			if(res.docs && res.docs.length>0){
		   
			  this.setState({mpndBuilding: res.docs[0]});
			}	
		  })
		  .catch(error => {
			console.log("error: " + error);
		  });
		return formattedData;
	  };

	
	_getBuildingData = () => {
     // console.log('_getBuildingData called', this.state)
     if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  const buildingSlug = this.props.match.params.building_slug || this.props.project_id;
	  
    
/*
	  let buildingData = {
			url: apiURL + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: 'alternate_keys:*-'+buildingSlug,
				fq: 'deleted:false',
 				fl: buildingFL,
				wt: "json",
				rows: 1
			}
	  };
*/
	  
	  let buildingData = {
			url: process.env.API_ROOT + `/buildings/${buildingSlug}?type=minimum`,
			method: "get"
	  };
	  
	  _axiosCall(buildingData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    //if(this._isMounted && res && res.docs && res.docs[0]) {
		if(this._isMounted && res && res.building && res.building[0]) {
		  this._getBuildingPermitData(res.building[0]);
		  //If building is sold out override stats
			      this.setState({
	      	buildingRes: res.building[0],
	      	fetchInProgress: false
	      });
	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}
	
	 _getBuildingPermitData = async (buildingRes) => {
		 this.setState({permitFiling: false});
			return false;
		if(!buildingRes.address || !buildingRes.address.alternate_addresses || !buildingRes.address.zip){
			this.setState({permitFiling: false});
			return false;
		} 
		 		
		const addressQ = buildingRes.address && buildingRes.address.alternate_addresses ? buildingRes.address.alternate_addresses.join('" OR "') : false;
		
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.address.zip} OR borough:"${buildingRes.address.borough}"))`
		
		if(buildingRes.offering_file_number && buildingRes.offering_file_number != ""){
			q += ` OR id:${buildingRes.offering_file_number}`
		}

		let axiosData = {
			url: apiURL + "we3-dob-permits-filings",
			method: "post",
			query: {
				q: q,
				sort: "latest_action_date desc",
				wt: "json",
				rows: 999,
				
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){
					
					const  permitFiling = res.docs.map(item => {
						try{
							const data = JSON.parse(item.amendments);
							item.amendments = Object.keys(data).map(key => data[key]);
						}catch(e){
							item.amendments = false;
						}
						return item;					
					});
					this.setState({ permitFiling:  permitFiling});
				}	
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};
	
	setBuilding(building){
		console.log(building)
		//this.setState({buildingRes : building})
		this._getMongoBuildingData();
	}

	render(){

		const { buildingRes,  permitFiling, fetchInProgress, tab, mpndBuilding, planViews } = this.state;
		
		if(buildingRes === null || fetchInProgress || planViews === null){
			 return <LinearLoading />;
		}else if(buildingRes === false){
			return (
				<Container className="dashboard">
					<Row>
					  
					</Row>
					<Row>
						<Col md={12} className={'mb-3 text-center'}>
							<Card>
								<CardBody>
									<h4>Project Not Found</h4>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			)
		}
		
		if(planViews.limitReached){
			return (
				<Container className="dashboard">
					<Row>
					  <Col md={12} className={'mb-3'}>
						   <ProjectViewsAlert planViews={planViews} />        
					  </Col>
					</Row>
					<Row>
						<EnterpriseGate 
					  	copy={<span>You have reached the maxmium project views for your billing cycle.</span>}
						/>
					</Row>
				</Container>
			)
		}

		return (
			 <Container className="dashboard">
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<ProjectViewsAlert planViews={planViews} />        
			      </Col>
			    </Row>
			    {/*(buildingRes.offering_verified_status !== 'active' ) &&
			    <Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="vettingProgress" icon iconItem={<AlertIcon />} minimizeText={`Vetting in Progress`}>
				          <p>
				          	<span className="bold-text">{`Vetting in Progress`}</span> 
				            <div>{`Marketproof New Development is currently processing this building and verifying information.  More details will be added to this building shortly.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row>
			    */}
			    <Row>
			      <BuildingTabContainer buildingRes={buildingRes} mpndBuilding={mpndBuilding} permitFiling={permitFiling} tab={tab} setBuilding={this.setBuilding.bind(this)} />
			    </Row>
			</Container>
		)
		
	}
  };

export default connect(state => ({
  rtl: state.rtl,
  user: state.user,
}))((withRouter(SingleBuilding)));
