import React, { PureComponent, Fragment } from 'react';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import AveragePrice from './analysis/AveragePrice';
import AveragePPSF from './analysis/AveragePPSF';
import PriceRange from './analysis/PriceRange';

import Inventory from './analysis/Inventory';

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge
} from 'reactstrap';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus
} from '../../../../shared/helpers/utils';


export const BuildingAnalysisView = (props) => {
	const { buildingStats } = props;
	
	let resale = 0;
	let sponsor = 0;
	
	if(buildingStats.status && buildingStats.status.buckets){
		resale = buildingStats.status.buckets.filter(item => item.val == "resale") && buildingStats.status.buckets.filter(item => item.val == "resale")[0] ? buildingStats.status.buckets.filter(item => item.val == "resale")[0].count : 0;
		sponsor = buildingStats.status.buckets.filter(item => item.val == "sponsor") && buildingStats.status.buckets.filter(item => item.val == "sponsor")[0]  ? buildingStats.status.buckets.filter(item => item.val == "sponsor")[0].count : 0;
	}
	
	const isSponsor = sponsor >= resale;
	return (
	  <div>
		   <Row className="mt-4">
			{isSponsor ?
				<Inventory {...props} unsold />
			:
				<Inventory {...props} />
			}
			<Inventory {...props} active />
			<Inventory {...props} percent_sold />
					
			
			<PriceRange {...props} />
			
										   
		   </Row>
		   <hr className="mt-0 mb-4"/>
		</div>
	);
}

export const UnitsAnalysisView = (props) => {
	
	
	return (
	  <div>
		  
		 <Row className="mt-4">
		  <AveragePrice {...props} />
		  <AveragePPSF {...props} />
		  <AveragePrice {...props} sold />  
		  <AveragePPSF {...props} sold />
		 				                
		 </Row>
		 <hr className="mt-0 mb-4"/>
	  </div>
	);
}