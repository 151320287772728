import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Progress,
  Badge,
  Row,
  Container,
  Modal,
} from "reactstrap";

import BuildingDetail from "./../../BuildingDetail";

const PopupModal = (props) => {
  
 const { modal, toggle, data } = props;
 return (
	 
	 <Modal
		 isOpen={modal}
		 toggle={toggle}
		 modalClassName={`ltr-support`}
		 className={`modal-dialog contact__modal`}
	   >
		 <div className="modal__header">
		   <button
			 className="lnr lnr-cross modal__close-btn"
			 type="button"
			 onClick={toggle}
		   />
		 </div>
		 <div className="modal__body">
		   <BuildingDetail
			 trxnRes={data}
			 defaultTab={"permits"}
			 isShare
		   />
		 </div>
	   </Modal>
 ) 
}

export default PopupModal;