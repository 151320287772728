import React, { useState, Fragment } from "react";
import { Card, CardBody, Col, Row, ButtonToolbar } from "reactstrap";
import moment from "moment";
import HeartIcon from "mdi-react/HeartIcon";
import FormatLineWeightIcon from "mdi-react/FormatLineWeightIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import { Link } from "react-router-dom";
import ProfileGallery from "./ProfileGallery";
import images from "./imgs";
import ProfileTabs from "./ProfileTabs";
import ColorSelect from "./ColorSelect";
import classNames from "classnames";
import PercentSold from "./PercentSold";
import UnitMix from "./UnitMix";
import AddToBuyersListButton from "../../../../../shared/components/buttons/AddToBuyersListButton";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import SavedCount from "../../../../../shared/components/SavedCount";

import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
} from "../../../../../shared/helpers/utils";

const ListAmount = ({ lists }) => {
  const amountClass = classNames({
    "dashboard__table-orders-amount": true,
    "dashboard__table-orders-amount--medium": lists.count <= 5,
    "dashboard__table-orders-amount--low": lists.count <= 3,
  });
  return (
    <div className={amountClass}>
      {lists.map((list) => (
        <div />
      ))}
      <span>{lists.length} Members Saved this Building</span>
    </div>
  );
};

const ProfileCard = (props) => {
  const [color, setColor] = useState("white");

  const onLike = () => {
    if (color === "white") {
      setColor("#70bbfd");
    } else {
      setColor("white");
    }
  };
  const { buildingRes, statsRes, eventData } = props;

  const { event } = eventData;

  const mainConnections = event.main_connections;

  const applicantCompany =
    event.topic[0] === "permits" ? event.organizations[0] : false;
  const applicantPerson =
    event.topic[0] === "permits" ? event.people[0] : false;

  const sponsorCompany =
    event.topic[0] === "offeringplans"
      ? event.organizations.filter((item) => item.role === "developer")[0]
      : false;
  const sponsorPerson =
    event.topic[0] === "offeringplans"
      ? event.people.filter((item) => item.role === "sponsor principal")[0]
      : false;

  const lawFirmCompany =
    event.topic[0] === "offeringplans"
      ? event.organizations.filter((item) => item.role === "law firm")[0]
      : false;
  const lawFirmPerson =
    event.topic[0] === "offeringplans"
      ? event.people.filter((item) => item.role === "attorney")[0]
      : false;

  let images = _getBuildingPhotos(buildingRes);

  if (images && images.length > 0) {
    images = images.map((image) => {
      return { src: image };
    });
  }
  const buildingResUnits = _shouldShow(buildingRes.inventory_total)
    ? buildingRes.inventory_total
    : _shouldShow(buildingRes.total_units_sellable)
    ? buildingRes.total_units_sellable
    : _shouldShow(buildingRes.total_units_residential)
    ? buildingRes.total_units_residential
    : _shouldShow(buildingRes.residential_units)
    ? buildingRes.residential_units
    : _shouldShow(buildingRes.total_units)
    ? buildingRes.total_units
    : false;

  const buildingNameOrAdr = buildingRes.name
    ? _capitalizeText(buildingRes.name)
    : buildingRes.display_full_street_address
    ? _capitalizeText(buildingRes.display_full_street_address)
    : _capitalizeText(buildingRes.full_street_address);
  const adr = buildingRes.display_full_street_address
    ? buildingRes.display_full_street_address
    : buildingRes.full_street_address;
  const hood = buildingRes.neighborhood
    ? _capitalizeText(buildingRes.neighborhood[0]) + ","
    : "";

  let data = null;

  if (buildingRes.data) {
    if (typeof buildingRes.data === "string") {
      try {
        data = JSON.parse(buildingRes.data);
        
        
      } catch (e) {
        //cannot parse JSON
      }
    } else {
      data = buildingRes.data;
    }
  }

  
  if(buildingRes.topic[0] === "coo" && data.filings_data){
    data = data.filings_data
  }
  console.log(data);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="no-border no-padding">
          <div className="unit__badge">
            {buildingRes.topic[0] === "permits" &&
              (buildingRes.event_tags.indexOf("nb") >= 0 ||
                buildingRes.event_tags.indexOf("a1") >= 0) && (
                <Fragment>
                  <span
                    style={{
                      width: 12,
                      height: 12,
                      display: "inline-block",
                      background: "#FFD23F",
                      borderRadius: 3,
                      marginRight: 3,
                    }}
                  />{" "}
                  Permit Filed
                </Fragment>
              )}
            {buildingRes.topic[0] === "offeringplans" &&
              buildingRes.event_tags.indexOf("submitted") >= 0 && (
                <Fragment>
                  <span
                    style={{
                      width: 12,
                      height: 12,
                      display: "inline-block",
                      background: "#fc83ae",
                      borderRadius: 3,
                      marginRight: 3,
                    }}
                  />{" "}
                  Offering Submitted
                </Fragment>
              )}
            {buildingRes.topic[0] === "offeringplans" &&
              buildingRes.event_tags.indexOf("accepted") >= 0 && (
                <Fragment>
                  <span
                    style={{
                      width: 12,
                      height: 12,
                      display: "inline-block",
                      background: "#FF206E",
                      borderRadius: 3,
                      marginRight: 3,
                    }}
                  />{" "}
                  Offering Accepted
                </Fragment>
              )}
            {buildingRes.topic[0] === "offeringplans" &&
              buildingRes.event_tags.indexOf("effective") >= 0 && (
                <Fragment>
                  <span
                    style={{
                      width: 12,
                      height: 12,
                      display: "inline-block",
                      background: "#82012e",
                      borderRadius: 3,
                      marginRight: 3,
                    }}
                  />{" "}
                  Offering Effective
                </Fragment>
              )}
            {buildingRes.topic[0] === "coo" && (
              <Fragment>
                <span
                  style={{
                    width: 12,
                    height: 12,
                    display: "inline-block",
                    background: "#4ce1b6",
                    borderRadius: 3,
                    marginRight: 3,
                  }}
                />{" "}
                {buildingRes.event_tags.indexOf("coo-temporary") >= 0
                  ? "TCO Issued"
                  : "CO Issued"}
              </Fragment>
            )}
          </div>
          <h4 className="contact__title">{buildingNameOrAdr}</h4>
          <div className="light-text">
            {hood && hood} {_capitalizeText(buildingRes.borough)}
          </div>

          <hr />

          <div className="product-card">
            <ProfileGallery images={images} />

            {(buildingRes.topic[0] === "permits" || buildingRes.topic[0] === "coo") && (
              <div className="product-card__info">
                <h4 className="product-card__title">Planned Construction</h4>
                <Row className="product-card__top">
                  {data &&
                    data.building_stories_proposed &&
                    _shouldShow(data.building_stories_proposed) && (
                      <Col xl={2} lg={3} xs={3} className="mt-2">
                        <h3>{data.building_stories_proposed}</h3>
                        <span className="product-card__subtext">Floors</span>
                      </Col>
                    )}

                  {data &&
                    data.dwelling_units_proposed &&
                    _shouldShow(data.dwelling_units_proposed) && (
                      <Col xl={2} lg={3} xs={3} className="mt-2">
                        <h3>{data.dwelling_units_proposed}</h3>
                        <span className="product-card__subtext">Units</span>
                      </Col>
                    )}
                  {event.avg_res_unit_size && (
                    <Col xl={3} lg={3} xs={6} className="mt-2">
                      <h3>
                        {event.avg_res_unit_size.replace("SF", "")}{" "}
                        <span className="light-text small-text">Sq Ft</span>
                      </h3>
                      <span className="product-card__subtext">
                        Avg Unit Size
                      </span>
                    </Col>
                  )}
                  {data &&
                    data.zoning_area_residential &&
                    _shouldShow(data.zoning_area_residential) && (
                      <Col xl={4} lg={4} xs={6} className="mt-2">
                        <h3>
                          {_formatNumber(data.zoning_area_residential)}{" "}
                          <span className="light-text small-text">Sq Ft</span>
                        </h3>
                        <span className="product-card__subtext">
                          Residential Area
                        </span>
                      </Col>
                    )}
                </Row>

                <hr />
                <table className="product-summary__info">
                  <tbody>
                    <tr>
                      <td style={{ width: 180 }}>Construction Type:</td>
                      <th>
                        {buildingRes.event_tags.indexOf("nb") >= 0
                          ? _capitalizeText("New Building")
                          : _capitalizeText("Major Alteration")}
                      </th>
                    </tr>
                    <tr>
                      <td>Project Type:</td>
                      <th>
                        {data && data.mixed_use === true ? (
                          <Fragment>Mixed-Use</Fragment>
                        ) : (
                          <Fragment>Residential</Fragment>
                        )}
                      </th>
                    </tr>
                    {data && data.occupancy_classification_proposed && (
                      <tr>
                        <td>Occupancy:</td>
                        <th>
                          {data.occupancy_classification_proposed.indexOf(
                            ":"
                          ) >= 0
                            ? _capitalizeText(
                                data.occupancy_classification_proposed.split(
                                  ":"
                                )[1]
                              )
                            : _capitalizeText(
                                data.occupancy_classification_proposed
                              )}
                        </th>
                      </tr>
                    )}

                    {data &&
                    data.parking_spaces_proposed &&
                    data.parking_spaces_proposed > 0 ? (
                      <tr>
                        <td>Parking:</td>
                        <th>
                          {_formatNumber(data.parking_spaces_proposed)}
                          {data.parking_spaces_proposed > 1
                            ? " Parking Spaces"
                            : " Parking Space"}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.lot_area ? (
                      <tr>
                        <td>Lot Area:</td>
                        <th>{data.lot_area} Sq Ft</th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.far_proposed && data.far_proposed > 0 ? (
                      <tr>
                        <td>Floor Area Ratio:</td>
                        <th>{data.far_proposed}</th>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                <hr />
                <table className="product-summary__info">
                  <tbody>
                    {data && data.owner_business_name ? (
                      <tr>
                        <td style={{ width: 180 }}>Owner:</td>
                        <th>{_capitalizeText(data.owner_business_name)}</th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.owner_first_name ? (
                      <tr>
                        <td style={{ width: 180 }}>Owner Contact:</td>
                        <th>
                          {_capitalizeText(data.owner_first_name)}{" "}
                          {_capitalizeText(data.owner_last_name)}
                          {data.owner_relationship && (
                            <Fragment>
                              {" "}
                              ({_capitalizeText(data.owner_relationship)})
                            </Fragment>
                          )}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.owner_email ? (
                      <tr>
                        <td style={{ width: 180 }}>Owner Email:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`mailto:${data.owner_email}`}
                            target="_blank"
                          >
                            <EmailOutlineIcon size={18} /> {data.owner_email}
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.owner_phone_number ? (
                      <tr>
                        <td>Owner Phone:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`tel:${data.owner_phone_number}`}
                            target="_blank"
                          >
                            <PhoneOutlineIcon size={18} />{" "}
                            {data.owner_phone_number}
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
               {(applicantCompany ||  applicantPerson) && <hr />}
                <table className="product-summary__info">
                  <tbody>
                    {applicantCompany ? (
                      <tr>
                        <td style={{ width: 180 }}>Applicant:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`https://marketproof.com/company/${applicantCompany.company_slugs[0]}`}
                            target="_blank"
                          >
                            {_capitalizeText(applicantCompany.display_name)}{" "}
                            <OpenInNewIcon size={18} />
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}

                    {applicantPerson ? (
                      <tr>
                        <td style={{ width: 180 }}>
                          {_capitalizeText(
                            applicantPerson.role[
                              mainConnections[0].role.length - 1
                            ]
                          )}
                          :
                        </td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`https://marketproof.com/person/${applicantPerson.person_slugs[0]}`}
                            target="_blank"
                          >
                            {_capitalizeText(applicantPerson.display_name)}{" "}
                            <OpenInNewIcon size={18} />
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.applicant_email ? (
                      <tr>
                        <td>Contact Email:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`mailto:${data.applicant_email}`}
                            target="_blank"
                          >
                            <EmailOutlineIcon size={18} />{" "}
                            {data.applicant_email}
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data && data.applicant_business_phone ? (
                      <tr>
                        <td>Contact Phone:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`tel:${data.applicant_business_phone}`}
                            target="_blank"
                          >
                            <PhoneOutlineIcon size={18} />{" "}
                            {data.applicant_business_phone}
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>

                <hr />
                <div className="text-right">
                  <a
                    className="product__external-link"
                    href={`https://marketproof.com${_getBuildingURL(
                      buildingRes,
                      "building"
                    ).replace("buildings/", "building/")}?tab=overview`}
                    target="_blank"
                  >
                    More on marketproof.com <OpenInNewIcon size={13} />
                  </a>
                </div>
              </div>
            )}

            {buildingRes.topic[0] === "offeringplans" && (
              <div className="product-card__info">
                <h4 className="product-card__title">Planned Offering</h4>
                <Row className="product-card__top">
                  <Col xl={3} lg={3} xs={4} className="mt-2">
                    <h3>
                      {data.numunits_residential
                        ? data.numunits_residential
                        : "--"}
                    </h3>
                    <span className="product-card__subtext">Units</span>
                  </Col>
                  {data.price_current &&
                    _shouldShow(data.price_current) &&
                    data.numunits_residential > 0 && (
                      <Col xl={4} lg={3} xs={4} className="mt-2">
                        <h3>
                          {_formatPrice(
                            data.price_current / data.numunits_residential
                          )}
                        </h3>
                        <span className="product-card__subtext">
                          Avg Price / Unit
                        </span>
                      </Col>
                    )}

                  {data.price_current && _shouldShow(data.price_current) && (
                    <Col xl={5} lg={4} xs={4} className="mt-2">
                      <h3>{_formatPrice(data.price_current)}</h3>
                      <span className="product-card__subtext">
                        Total Sellout
                      </span>
                    </Col>
                  )}
                </Row>

                <hr />
                <table className="product-summary__info">
                  <tbody>
                    <tr>
                      <td style={{ width: 180 }}>Construction Type:</td>
                      <th>{_capitalizeText(data.construction_type)}</th>
                    </tr>
                    <tr>
                      <td style={{ width: 180 }}>Ownership Type:</td>
                      <th>{_capitalizeText(data.statCard_buildingType)}</th>
                    </tr>
                    {data.occupied && (
                      <tr>
                        <td>Occupancy:</td>
                        <th>
                          {_capitalizeText(data.occupied.replace("/", " / "))}
                        </th>
                      </tr>
                    )}

                    {data.numunits_parking && data.numunits_parking > 0 ? (
                      <tr>
                        <td>Parking:</td>
                        <th>
                          {_formatNumber(data.numunits_parking)}
                          {data.numunits_parking > 1
                            ? " Parking Spaces"
                            : " Parking Space"}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.numunits_storage && data.numunits_storage > 0 ? (
                      <tr>
                        <td>Storage:</td>
                        <th>
                          {_formatNumber(data.numunits_storage)}
                          {data.numunits_storage > 1
                            ? " Storage Units"
                            : " Storage Unit"}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.numunits_commercial &&
                    data.numunits_commercial > 0 ? (
                      <tr>
                        <td>Commercial:</td>
                        <th>
                          {_formatNumber(data.numunits_commercial)}
                          {data.numunits_commercial > 1
                            ? " Commercial Units"
                            : " Commercial Unit"}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.numunits_residential_regulated &&
                    data.numunits_residential_regulated > 0 ? (
                      <tr>
                        <td>Affordable:</td>
                        <th>
                          {_formatNumber(data.numunits_residential_regulated)}
                          {data.numunits_residential_regulated > 1
                            ? " Affordable Units"
                            : " Affordable Unit"}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.numunits_professional &&
                    data.numunits_professional > 0 ? (
                      <tr>
                        <td>Professional:</td>
                        <th>
                          {_formatNumber(data.numunits_professional)}
                          {data.numunits_professional > 1
                            ? " Professional Units"
                            : " Professional Unit"}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.lot_area ? (
                      <tr>
                        <td>Lot Area:</td>
                        <th>{data.lot_area} Sq Ft</th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.far_proposed && data.far_proposed > 0 ? (
                      <tr>
                        <td>Floor Area Ratio:</td>
                        <th>{data.far_proposed}</th>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                {(sponsorCompany || sponsorPerson) && <hr />}
                <table className="product-summary__info">
                  <tbody>
                    {sponsorCompany ? (
                      <tr>
                        <td style={{ width: 180 }}>Sponsor:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`https://marketproof.com/company/${sponsorCompany.company_slugs[0]}`}
                            target="_blank"
                          >
                            {_capitalizeText(sponsorCompany.display_name)}{" "}
                            <OpenInNewIcon size={18} />
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}

                    {sponsorPerson ? (
                      <tr>
                        <td style={{ width: 180 }}>
                          {_capitalizeText(sponsorPerson.title)}:
                        </td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`https://marketproof.com/person/${sponsorPerson.person_slugs[0]}`}
                            target="_blank"
                          >
                            {_capitalizeText(sponsorPerson.display_name)}{" "}
                            <OpenInNewIcon size={18} />
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                <hr />
                <table className="product-summary__info">
                  <tbody>
                    {lawFirmCompany ? (
                      <tr>
                        <td style={{ width: 180 }}>Law Firm:</td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`https://marketproof.com/company/${lawFirmCompany.company_slugs[0]}`}
                            target="_blank"
                          >
                            {_capitalizeText(lawFirmCompany.display_name)}{" "}
                            <OpenInNewIcon size={18} />
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}

                    {lawFirmPerson ? (
                      <tr>
                        <td style={{ width: 180 }}>
                          {_capitalizeText(lawFirmPerson.title)}:
                        </td>
                        <th>
                          <a
                            className="product__external-link"
                            href={`https://marketproof.com/person/${lawFirmPerson.person_slugs[0]}`}
                            target="_blank"
                          >
                            {_capitalizeText(lawFirmPerson.display_name)}{" "}
                            <OpenInNewIcon size={18} />
                          </a>
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>

                <hr />
                <div className="text-right">
                  <a
                    className="product__external-link"
                    href={`https://marketproof.com${_getBuildingURL(
                      buildingRes,
                      "building"
                    ).replace("buildings/", "building/")}?tab=overview`}
                    target="_blank"
                  >
                    More on marketproof.com <OpenInNewIcon size={13} />
                  </a>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileCard;
