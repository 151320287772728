import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import MenuIcon from "mdi-react/MenuIcon";
import PriceFilter from "./../../../shared/components/building_filters/PriceFilter";
import DateFilter from "./../../../shared/components/building_filters/DateFilter";
import TextFilter from "./../../../shared/components/building_filters/TextFilter";

import Alert from "../../../shared/components/Alert";
import AlertIcon from "mdi-react/InformationOutlineIcon";
import AdvancedFilertIcon from "mdi-react/FilterVariantIcon";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ResetIcon from "mdi-react/AutorenewIcon";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import MultiFilter from "../../../shared/components/building_filters/MultiFilter";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    fontSize: "12px",
  },
  formLabel: {
    fontSize: "14px",
  },
  menuItem: {
    fontSize: "14px",
  },
  listItemText: {
    fontSize: "12px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer: {
    padding: "5px 10px 10px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UnitsSearchTableFiltersSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    subFilters,
    setSubFilter,
    _filters,
    building,
    UnitStageFilter,
    resetSubFilter,
    resetActive,
    setSubFilters,
    advFilterName,
  } = props;
  const handleChange = (event) => {
    setSubFilter(event.target.name, event.target.value);
    //     setPersonName(event.target.value);
  };

  const setTitle = (search_key, search_value) => {
    if (
      _filters
        .filter((filter) => filter.search_key === search_key)[0]
        .values.filter((value) => value.filter === search_value).length > 0
    ) {
      return _filters
        .filter((filter) => filter.search_key === search_key)[0]
        .values.filter((value) => value.filter === search_value)[0].name;
    } else {
      return _filters[0].values[0].name;
    }
  };
  let theFilters = JSON.parse(JSON.stringify(_filters));
  theFilters = theFilters.map((filter, i) => {
    if (filter.search_key === "offering_status") {
      if (building && building.offering_construction === "conversion") {
        filter.values = filter.values.filter(
          (item) => item.filter !== "shadow"
        );
      } else {
        filter.values = filter.values.filter(
          (item) => item.filter !== "conversion"
        );
      }
    }

    return filter;
  });

  const SubFilters = ({ filters }) => {
    return filters.map((filter, i) => {
      if (filter.price_filter) {
        return <PriceFilter filter={filter} {...props} />;
      } else if (filter.date_filter) {
        return <DateFilter filter={filter} {...props} />;
      } else if (filter.text_filter) {
        return <TextFilter filter={filter} {...props} />;
      } else if (filter.multi_filter) {
        return <MultiFilter filter={filter} {...props} />;
      } else {
        return (
          <FormControl className={classes.formControl}>
            <InputLabel
              id={`${filter.search_key}_filter-label`}
              className={classes.formLabel}
            >
              {filter.default_name && !subFilters[filter.search_key]
                ? filter.default_name
                : filter.name}
            </InputLabel>
            <Select
              labelId={`${filter.search_key}-label`}
              id={`${filter.search_key}`}
              name={filter.search_key}
              value={
                subFilters[filter.search_key]
                  ? setTitle(filter.search_key, subFilters[filter.search_key])
                  : ""
              }
              onChange={handleChange}
              input={<Input id={`${filter.search_key}`} />}
              renderValue={(selected) => <div>{selected}</div>}
              MenuProps={MenuProps}
              className={classes.menuItem}
            >
              {filter.values.map((item) => (
                <MenuItem
                  key={item.filter}
                  value={item.filter}
                  className={classes.menuItem}
                >
                  {item.dropdown_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
    });
  };
  const slice = isMobile ? 99 : 7;
  const hasAdvanced = _filters.length > slice;
  return (
    <div className={classes.filterContainer}>
      {isMobile && (
        <div style={{ padding: 5, fontWeight: 500 }}>Filter By:</div>
      )}
      <div>
        <SubFilters filters={_filters.slice(0, slice)} />

        {!isMobile && (
          <span style={{ marginTop: 20, display: "inline-block" }}>
            {resetActive ? (
              <Tooltip title="Reset Filters to Default">
                <IconButton
                  aria-label="Reset Filters to Default"
                  onClick={resetSubFilter}
                  color="inherit"
                >
                  <ResetIcon style={{ color: "#9600BF" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                aria-label="Reset Filters to Default"
                onClick={resetSubFilter}
                color="inherit"
                disabled
              >
                <ResetIcon style={{ color: "#BFC4D1" }} />
              </IconButton>
            )}
          </span>
        )}
      </div>
      {isMobile && UnitStageFilter && (
        <div>
          <div style={{ padding: 5, fontWeight: 500 }}>
            <hr />
            Unit Stage:
          </div>
          <div
            className={`unit_toggle_filter`}
            style={{ marginLeft: "8px", marginTop: "8px" }}
          >
            {UnitStageFilter}
          </div>
        </div>
      )}
      {hasAdvanced && !isMobile && (
        <>
          <Alert
            color=""
            className="alert--bordered alert--advanced--search"
            search
            hideOnStart
            alertId="advancedMarketActivityFilters"
            minimizeText={`Advanced Options`}
            minimizeHelpText={`Advanced filters to further refine your results.`}
            condensedIcon={<AdvancedFilertIcon size={14} />}
          >
            <div>
              <SubFilters filters={_filters.slice(slice)} />
            </div>
          </Alert>
          <div
            className={`unit_toggle_filter`}
            style={{ marginLeft: "8px", marginTop: "8px" }}
          >
            {UnitStageFilter === "not required" ? null : UnitStageFilter}
          </div>
        </>
      )}
    </div>
  );
}
