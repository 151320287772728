import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	_formatPrice,
	buildingStageToolTips,
	_formatNumber,
	_formatDate
} from "../../../shared/helpers/utils";
const useStyles = makeStyles(theme => ({
 
  formControl: {
	margin: theme.spacing(1),
	minWidth: 200,
	maxWidth: 300,
	fontSize: '12px',
  },
  formControlPop: {
	margin: '6px',
	minWidth: 270,
	fontSize: '12px',
	'& .MuiTextField-root':{
		marginBottom:'5px'
	},
	'& .MuiInputLabel-root':{
		fontSize:'14px',
		paddingLeft:'10px'
	},
	'& .MuiInput-input': {
		fontSize:'14px',
		paddingLeft:'10px'
	},
	'& .MuiListItem-gutters':{
		paddingLeft:'10px'
	},
	'& .MuiMenuItem-root':{
		paddingTop:'3px',
		paddingBottom:'3px',
		minHeight: 0
	},
	'& .format':{
		fontSize:'12px',
		color:'#A3ADC2',
		paddingLeft:"10px",
		paddingTop:"5px"
	},
	'& .MuiInputLabel-formControl':{
		position:'relative'
	},
	'& label + .MuiInput-formControl':{
	
	}
	
  },
  formLabel: {
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px'
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
	display: 'flex',
	flexWrap: 'wrap',
	marginLeft: 5,
  },
  chip: {
	margin: 5,
	marginLeft: 5,
	width:120,
  },

  noLabel: {
	marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: '5px 10px 10px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
	style: {
	  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
	  width: 280,
	},
  },
};

const MultiMenuProps = {
  PaperProps: {
	style: {
	  marginTop: 0,
	  width: 280
	},
  },
  InputProps:{
	  style:{
		  fontSize:'12px'
	  }
  }
};

export default function TextFilter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, filter, _filters, setSubFilters} = props;
  const [ searchText, setSearchText ] = useState(subFilters[filter.search_key] ? subFilters[filter.search_key] : false);
  
  const [ searchTextFocus, setSearchTextFocus ] = useState(true);

  const [ minMaxValues, setMinMaxValues ] = useState([]);
  
  const [open, setOpen] = React.useState(false);
  
  const handleChange = event => {
	  console.log(event.target.name, event.target.value);
	  if(event.target.name === 'search-text'){
		  setSearchText(event.target.value);
	  }
  };
  const handleChangeMultiple = (searchText)  => {
	setOpen(false); 
	let search = {};

	search[filter.search_key] = searchText;
	
	setSubFilters(search);
  };
  
  const handleFocus = type => {
	  if(type === 'search-text'){
		  setSearchTextFocus(true);
	  }else if(type === 'max-date'){
		  setSearchTextFocus(false);
	  }
  }
  
  const handleSetMinDate = (min) => {
		  setSearchText(min);
	  
  }
  
  
  const handleClose = () => {
	setOpen(false); 
  };

  const handleOpen = () => {
	setOpen(true);
  };
  
  const setTitle = () => {
	  let title = filter.name;
	  if(searchText){
		  title = searchText;		  
	  }
	  return title;
	  
  }
	  
  return (
			  <FormControl className={classes.formControl}>
				<InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
					{setTitle()}
				</InputLabel>
				<Select
				  labelId={`${filter.search_key}-label`}
				  id={`${filter.search_key}`}
				  name={filter.search_key}
				  value={minMaxValues}		          
				  onClose={()=>{handleChangeMultiple(searchText)}}
				  open={open}
				  onOpen={handleOpen}
				  //onBlur={(e)=> {e.stopPropagation();e.preventDefault();}}
				  input={<Input id={`${filter.search_key}`} />}
				  renderValue={selected => (
					<div>			           
					   {selected.join(', ')}			
					</div>
				  )}
				  multiple
				  MenuProps={MultiMenuProps}
				  className={classes.menuItem} 
				>	
						
					<div></div>
					<FormControl className={classes.formControlPop}>
					  <TextField 
						id="search-text" 
						  label={filter.name} 
						  onFocus={()=>{handleFocus('search-text')}}
						  name="search-text"
						  value={searchText ? searchText : ''}
						  onChange={handleChange}
						  autoComplete={'off'}
					   />
					   
					</FormControl>
					 
					 <hr />
					 <div style={{padding:'0px 16px 10px',textAlign:'right'}}>	
						 <Tooltip title={`Clear search`}>
							<Button
								 onClick={()=>{setSearchText(false);}}
								 size="small"
								 style={{marginRight:5}}
							 >	Clear
							 </Button>				 
						  </Tooltip>		         
						 <Tooltip title={`Apply search`}>
							<Button
								 onClick={()=>{handleChangeMultiple(searchText)}}
								 variant="outlined"
								 size="small"
							 >	Search
							 </Button>				 
						  </Tooltip>
					  </div>
				</Select>
				
			</FormControl>	
		)	
  
}
