import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import ChangeIcon from 'mdi-react/RotateLeftIcon';

import TopBar from './../../TopBar'
import Footer from './../../Footer'

import './../Payment.scss'
import './landing.scss'

import { subscriptionPlans } from './../plans.js';

	
class PricingCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
	  const { user } = this.props;
	
	  return (
		 	<div className="plan__wrapper share__wrapper">
			  <TopBar />
			  <Container className="plan__container home-product">		   
			    <Row className="row block-2 row-position ">
					<Col md={12} className="no-padding">
						<h5 className="notify">Access to content on Marketproof Pipeline requires a subscription.</h5>
					</Col>
					<Col md={4} className="no-padding">
						
						<h3>Construction Pipeline</h3>
						<h5>Access over 20,000 residential and commercial projects in all phases of development, from land acquisition through construction.</h5>
						<a className="learn-more btn btn-primary" href="https://marketproof.com/pipeline">Learn more </a>
						<div className="existing-user">
							<Link to={`/mplogin`}>(Existing User Sign In)</Link>
						</div>
					</Col>
					<Col md={8} className="no-padding">
						<img className="img-fluid feature-img" src="https://marketproof.com/svgs/landing/hero-img.svg" alt=""/>
					</Col>
					
			   </Row>
			   
			  </Container>
			  <Footer />
			</div>
		)
   }
};
export default connect(state => ({
  user: state.user,
  plan: state.plan
}))(withOktaAuth(PricingCards));
