import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Container, Modal, Row } from "reactstrap";
import ProductCard from "./components/ProfileCard";
import RelatedBuildings from "./components/RelatedBuildings";
import ProfileTabs from "./components/ProfileTabs";
import BuildingAmenities from "./components/BuildingAmenities";

import BuildingManagement from "./components/BuildingManagement";
import BuildingDetails from "./components/BuildingDetails";

import CommercialPercent from "./components/CommercialPercent";
import OfferingHistory from "./components/OfferingHistory";

import LendingHistory from "./components/LendingHistory";

import ProjectFeed from "./../Feed/";
import ProjectProgress from "./components/ProjectProgress";

import ShowMap from "./../../../../shared/components/map";
import BuildingMapBox from "./../../../../shared/components/map/BuildingMapBox";
import ProfileGallery from "./components/ProfileGallery";

import Companies from "./../../../Companies/components/MatGridView";

import MapModal from "./../../../../shared/components/map/MapModal";
import {
  _capitalizeText,
  _getBuildingPhotos,
  _shouldShow,
  _formatNumber,
  _getOccupancy,
  _getBuildingURL,
  _fixGoogleImage,
  _firstLetterUpper
} from "./../../../../shared/helpers/utils";
import { _axiosCall } from "../../../../shared/helpers/apicalls";
import MatGridView from "../../../PipelineBuildings/components/MatGridView";
import BuildingDetail from "../../../BuildingDetail";

import MPNDBuilding from "./components/MPNDBuilding";
import GStreetMap from "../../../../shared/components/map/GStreetMap.jsx";

const buildingUrl = process.env.API_ROOT + "/pipeline/";

class Profile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      instagram: false,
      reactTableFiltered: [],
      standardView: false,
      modal: false,
      buildingData: false,
      streetView: false,
    };
  }
  componentDidMount() {
    if (
      window.instgrm &&
      document.getElementById("react-instagram-embed-script")
    ) {
      if (this.state.instagram == false) {
        window.instgrm.Embeds.process();
      }
    } else {
      // Create script element with Instagram embed JS lib
      const s = document.createElement("script");
      s.async = s.defer = true;
      s.src = `//www.instagram.com/embed.js`;
      s.id = "react-instagram-embed-script";
      const body = document.body;
      if (body) {
        body.appendChild(s);
      }

      // Run Instagram function to show embeds
      if (
        window.instgrm &&
        window.instgrm.Embeds &&
        this.state.instagram == false
      ) {
        window.instgrm.Embeds.process();
      }

      // Set IG state to true so the process doesn't run again
      this.setState({
        instagram: true,
      });
    }

    // fetching nearby buildings
    const { buildingRes } = this.props;
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      { key: { $ne: buildingRes.key } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          { "$and" : [{source_provider: "permits" }, {building_status: { "$ne" : "resale"}}]},
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      { $or : [{pipeline_occupancy_classification_proposed_code:{$nin:["u"]}}, {pipeline_total_construction_floor_area:{$gt:1000}}]}
    ];
    let locQuery = {};
    // geocode

    if (
      buildingRes.address &&
      buildingRes.address.geocode &&
      buildingRes.address.geocode.type
    ) {
      locQuery = {
        "address.geocode": {
          $near: {
            $geometry: buildingRes.address.geocode,
          },
        },
      };
    }
    // goat_geocode
    else if (
      buildingRes.address &&
      buildingRes.address.goat_geocode &&
      buildingRes.address.goat_geocode.lng
    ) {
      locQuery = {
        "address.goat_geocode": {
          $near: {
            $geometry: {
              type: "Point",
              coordinates: [
                buildingRes.address.goat_geocode.lng,
                buildingRes.address.goat_geocode.lat,
              ],
            },
          },
        },
      };
    }
    // neighborhod
    else if (buildingRes.address && buildingRes.address.neighborhood) {
      locQuery = {
        "address.neighborhood": { $in: buildingRes.address.neighborhood },
      };
    }
    //  zipcode
    else if (buildingRes.address && buildingRes.address.zip) {
      locQuery = {
        "address.zip": buildingRes.address.zip,
      };
    }
    searchFilters.push(locQuery);
    let relatedProjectQuery = {
      query: { $and: searchFilters },
      limit: 8,
    };

    let axiosData = {
      url: buildingUrl + "query",
      method: "post",
      query: relatedProjectQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs) {
          let newData = _fixGoogleImage(res);
          this.setState({ reactTableFiltered: newData.docs || [] });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  handleBuildingClick = (event, building) => {
    event.preventDefault();
    let buildingURL;
    if (building && building._id) {
      buildingURL = _getBuildingURL(building, "_id");
    } else {
      buildingURL = _getBuildingURL(building, "id");
    }

    window.open(buildingURL);
  };

  toggleView = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ standardView: !this.state.standardView });
  };

  toggle() {
    //this.snackOpen('Your Message Has Been Sent!', 'success');
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  _handleStreetView = (event) => {
    event.preventDefault();
    this.setState({ streetView: !this.state.streetView });
  };

  _selectedBuildingClick = (building) => {
    this.setState({ modal: true, buidlingData: building });
  };

  render() {
    const { buildingRes, isShare } = this.props;

    let images = _getBuildingPhotos(buildingRes);
    if (images && images.length > 0) {
      images = images.map((image) => {
        return { src: image };
      });
    }

    let resiArea = 0;

    if (buildingRes.pipeline_dwelling_units_proposed > 0) {
      resiArea =
        buildingRes && buildingRes.pipeline_zoning_area_residential > 0
          ? buildingRes.pipeline_zoning_area_residential
          : buildingRes.pipeline_zoning_sqft_proposed > 0
          ? buildingRes.pipeline_zoning_sqft_proposed
          : 0;
    } else {
      resiArea =
        buildingRes.pipeline_zoning_sqft_proposed > 0
          ? buildingRes.pipeline_zoning_sqft_proposed
          : 0;
    }
    const regex = /(style=".+?")/gm;
    return (
      <Container className="mobile-no-padding">
        <Row className="pipeline_main_info__top">
          <div className="contents no-padding">
            {buildingRes &&
            buildingRes.construction_type &&
            buildingRes.construction_type["pipeline_construction_type"] ? (
              
              <div className="main_info">
                <h6>
                  {_capitalizeText(buildingRes.construction_type["pipeline_construction_type"].replace(/-/g, " "))}
                </h6>
                <span className="product-card__subtext">Construction Type</span>
              </div>
            ) : 
              buildingRes &&
              buildingRes.pipeline_job_type &&
              ["a1", "nb"].indexOf(buildingRes.pipeline_job_type)>=0 ? (
                <div className="main_info">
                  <h6>
                   {buildingRes.pipeline_job_type == "a1" ? "Alteration" : "New Construction"}
                  </h6>
                  <span className="product-card__subtext">Construction Type</span>
                </div>
              ) : 
              null
            }
            {buildingRes &&
              buildingRes.occupancy_type &&
              buildingRes.occupancy_type["Project Components"] &&
              buildingRes.occupancy_type["Project Components"].length > 0 ? (
              <div className="main_info">
                <h6>
                  {buildingRes.occupancy_type["Project Components"].join(", ")}
                </h6>
                <span className="product-card__subtext">Occupancy / Use</span>
              </div>
            ) : buildingRes &&
              buildingRes.pipeline_occupancy_type &&
              _shouldShow(buildingRes.pipeline_occupancy_type) ? (
              <div className="main_info">
                <h6>
                  {_getOccupancy(
                    buildingRes.pipeline_occupancy_classification_proposed_code,
                    true
                  )
                    ? _getOccupancy(
                        buildingRes.pipeline_occupancy_classification_proposed_code,
                        true
                      )
                    : _capitalizeText(buildingRes.pipeline_occupancy_type)}
                </h6>

                <span className="product-card__subtext">Primary Use</span>
              </div>
            ) : null}
            {buildingRes &&
              buildingRes.pipeline_building_stories_proposed &&
              _shouldShow(buildingRes.pipeline_building_stories_proposed) ? (
                <div className="main_info">
                  <h6>{buildingRes.pipeline_building_stories_proposed}</h6>
                  <span className="product-card__subtext">Floors</span>
                </div>
              ) : null}

            {buildingRes &&
            buildingRes.pipeline_occupancy_type === "residential" &&
            buildingRes.pipeline_dwelling_units_proposed &&
            _shouldShow(buildingRes.pipeline_dwelling_units_proposed) ? (
              <div className="main_info">
                <h6>
                  {_formatNumber(buildingRes.pipeline_dwelling_units_proposed)}
                </h6>
                <span className="product-card__subtext">Units</span>
              </div>
            ) : buildingRes &&
              buildingRes.pipeline_total_construction_floor_area ? (
              <div className="main_info">
                <h6>
                  {_formatNumber(
                    buildingRes.pipeline_total_construction_floor_area
                  )}{" "}
                  <span className="light-text small-text">Sq Ft</span>
                </h6>
                <span className="product-card__subtext">Sq Ft</span>
              </div>
            ) : null}
          </div>
        </Row>
        <Row>
          <Col xl={12} lg={12} className="mb-2 no-padding">
            {this.state.streetView ? (
              <GStreetMap
                {...this.props}
                center={{
                  lat:
                    buildingRes.address &&
                    buildingRes.address.geocode &&
                    buildingRes.address.geocode.coordinates &&
                    buildingRes.address.geocode.coordinates[1],
                  lng:
                    buildingRes.address &&
                    buildingRes.address.geocode &&
                    buildingRes.address.geocode.coordinates &&
                    buildingRes.address.geocode.coordinates[0],
                }}
              />
            ) : (
              <BuildingMapBox
                {...this.props}
                mapContainerWidth={1000}
                fullSize={true}
                freez
                standardView={this.state.standardView}
                buildingClicked={this._selectedBuildingClick}
                handleBuildingClick={this.handleBuildingClick}
                isSharePage={this.props.isShare}
              />
            )}
            <ProfileGallery building={buildingRes} images={images} />
            <MapModal
              {...this.props}
              toggleView={this.toggleView}
              handleStreetView={this._handleStreetView} // for toggle street view
              standardView={this.state.standardView}
              streetView={this.state.streetView}
              showMapViewButton
              showStreetView
            />
            {/*  toggle view and street view added for map view change  */}

            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle.bind(this)}
              modalClassName={`ltr-support`}
              className={`modal-dialog contact__modal`}
            >
              <div className="modal__header">
                <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  onClick={this.toggle.bind(this)}
                />
              </div>
              <div className="modal__body">
                <BuildingDetail trxnRes={this.state.buidlingData} />
              </div>
            </Modal>

            <div className="light-text small-text mt-4">
              3D rendering based on lot area and proposed height of the project.
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} className="mb-2">
            <h3 className="section-title section-title--not-last project_page-heading">
              Project Stage
            </h3>
            <h3 className="section-title section-title--not-last subhead"></h3>
            {/* <hr /> */}

            <ProjectProgress {...this.props} />
          </Col>
        </Row>
        <hr />
        {/* <Companies  table={this.props.buildingRes.organizations} /> */}
        {buildingRes.project_summary ? (
          <Row className="project-summary">
            <Col xl={12} lg={12} className="mb-4">
              <h3 className="section-title section-title--not-last project_page-heading">
                Project Summary
              </h3>
              <h3 className="section-title section-title--not-last subhead">
                {buildingRes.project_summary_author && (
                  <span>By: {buildingRes.project_summary_author}</span>
                )}
                {buildingRes.project_summary_date && (
                  <span>
                    {" "}
                    | Updated:{" "}
                    {moment(buildingRes.project_summary_date).format(
                      "MM/DD/YYYY"
                    )}
                  </span>
                )}
              </h3>

              <div
                dangerouslySetInnerHTML={{
                  __html: buildingRes.project_summary.replace(/_blank/g, "_self").replace(regex, ""),
                }}
              />
            </Col>
          </Row>
        ) : buildingRes.pipeline_job_description ? (
          <Row className="project-summary">
            <Col xl={12} lg={12} className="mb-4">
              <h3 className="section-title section-title--not-last project_page-heading">
                Permit Description
              </h3>
              <h3 className="section-title section-title--not-last subhead">
               {buildingRes.pipeline_applicant_business_name ? (
                 <span>Filed By: {_capitalizeText(buildingRes.pipeline_applicant_business_name.replace(/[^\w\s]/gi, ''))}</span>
               ) : 
               buildingRes.pipeline_applicant_first_name ? (
                  <span>Filed By: {_capitalizeText(buildingRes.pipeline_applicant_first_name.replace(/[^\w\s]/gi, ''))} {_capitalizeText(buildingRes.pipeline_applicant_last_name.replace(/[^\w\s]/gi, ''))}</span>
                ) : null
             
               }
                {buildingRes.pipeline_filing_date && parseInt(moment(buildingRes.pipeline_filing_date).year()) > 2000 ? (
                  <span>
                    {" "}
                    | Date:{" "}
                    {moment(buildingRes.pipeline_filing_date).format(
                      "MM/DD/YYYY"
                    )}
                  </span>
                ) : buildingRes.pipeline_pre_filing_date && parseInt(moment(buildingRes.pipeline_pre_filing_date).year()) > 2000 ? (
                  <span>
                    {" "}
                    | Date:{" "}
                    {moment(buildingRes.pipeline_pre_filing_date).format(
                      "MM/DD/YYYY"
                    )}
                  </span>
                ) : null }
              </h3>
              <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: _firstLetterUpper(buildingRes.pipeline_job_description)
                }}
              />
              </div>
            </Col>
          </Row>
        ) : null}
        {(buildingRes.project_summary || buildingRes.pipeline_job_description) && <hr />}

        <Row>
          <Col xl={12} lg={12} className="details___style">
            <h3 className="section-title section-title--not-last project_page-heading">
              Project Details
            </h3>
            <h3 className="section-title section-title--not-last subhead">
            {buildingRes &&
             buildingRes.occupancy_type &&
             buildingRes.occupancy_type["Project Components"] &&
             buildingRes.occupancy_type["Project Components"].length > 0 ?
              <span>Project details gathered from a varity of sources such as news, public filiings and more.</span>
             :
              <span>Project based on available public filiings.</span>
            }
            </h3>
          </Col>

          <ProductCard {...this.props} />
          <Col xl={12} lg={12}>
            <MPNDBuilding {...this.props} />
          </Col>
        </Row>

        <BuildingAmenities {...this.props} />

        <Row className="mt-5">
          <Col xl={12} lg={12}>
            <ProjectFeed {...this.props} min={isShare ? false : true} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            <h3 className="section-title section-title--not-last project_page-heading">
              Nearby Projects
            </h3>
            <h3 className="section-title section-title--not-last subhead">
              Projects near{" "}
              {(buildingRes && buildingRes.name) ||
                (buildingRes.address && buildingRes.address.street_number) +
                  " " +
                  (buildingRes.address && buildingRes.address.street)}
            </h3>
          </Col>
        </Row>
        <Row>
          <MatGridView
            {...this.state}
            {...this.props}
            handleBuildingClick={this.handleBuildingClick}
            noPagination
            //   handleSelectAllClick={this.handleSelectAllClick}
            //   handleRequestSort={this.handleRequestSort}
            //   selectUnit={this._selectUnit.bind(this)}
          />
        </Row>

        <Row>
          <Col md={12}>
            <h3 className="section-title section-title--not-last project_page-heading"></h3>
            <h3 className="section-title section-title--not-last subhead">
              <p />{" "}
            </h3>
          </Col>
        </Row>

        {/*<Row className="mt-3">
			      <Col md={12}>
			        <h3 className="section-title section-title--not-last">Similar Bulidings</h3>
			        <h3 className="section-title section-title--not-last subhead"> </h3>
			      </Col>
			    </Row>
				<Row> 
			     <RelatedBuildings {...this.props} /> 
			    </Row>*/}
        <div>
          <span className="small-text light-text">
            Project Added:{" "}
            {buildingRes.date_added &&
            moment(buildingRes.date_added).year() > 1970
              ? _capitalizeText(moment(buildingRes.date_added).fromNow())
              : buildingRes.pipeline_filing_date &&
                moment(buildingRes.pipeline_filing_date).year() > 1970
              ? _capitalizeText(
                  moment(buildingRes.pipeline_filing_date).fromNow()
                )
              : "--"}
          </span>
          <span className="small-text light-text"> {" | "} </span>
          <span className="small-text light-text">
            Last Updated:{" "}
            {buildingRes.meta.ModificationTimestamp &&
            moment(buildingRes.meta.ModificationTimestamp).year() > 1970
              ? _capitalizeText(
                  moment(buildingRes.meta.ModificationTimestamp).fromNow()
                )
              : "--"}
          </span>
        </div>
      </Container>
    );
  }
}

export default connect((state) => ({
  user: state.user,
}))(Profile);
