import React from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import MainIcon from 'mdi-react/DomainIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import { Link } from 'react-router-dom';

const EmailConfirmationCard = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className={`text-center`}>
          <div className="email-confirmation__icon pt-5 pb-5">
            <MainIcon className="email-confirmation__mail" />
            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
          </div>
          <h3 className="email-confirmation__title">No Projects Found</h3>
          
        </div>
      </CardBody>
    </Card>
  </Col>
);


export default EmailConfirmationCard;
