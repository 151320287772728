import { recentCohortQuery } from "./utils";
import moment from "moment";

const today = new Date();
const new_building_start = today.getFullYear() - 7;

export const _companyFilters = [
  {
    //"Developer", "Marketing", "Expeditor",  "Engineer", "Architect", "Interior Designer", "Lender", "Construction", "Property Manager","Commercial Broker", "Residential Broker", "Other"
    //"developer", "marketing", "expeditor", "engineer", "architect", "interior designer", "lender", "construction", "commercial broker", "residential broker", "Other"
    name: "Category / Role",
    show_name: true,
    search_key: "organizations.categories",
    filter_type: "company",
    multi_filter: true,
    value_contains_key: true,
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Permit Applicant",
        filter: "applicant",
        dropdown_name: "Permit Applicant",
        search_value: {
          "organizations.project_roles": {
            $in: ["applicant", "permit applicant"],
          },
        },
      },
      {
        name: "Permit Filing Rep",
        filter: "filing-rep",
        dropdown_name: "Permit Filing Rep",
        search_value: {
          "organizations.project_roles": {
            $in: ["filing applicant", "permit filing representative"],
          },
        },
      },
      {
        name: "Project Owner",
        filter: "owner",
        dropdown_name: "Project Owner",
        search_value: { "organizations.project_roles": { $in: ["owner"] } },
      },
      {
        name: "Architect",
        filter: "architect",
        dropdown_name: "Architect",
        search_value: { "organizations.categories": { $in: ["architect"] } },
      },
      {
        name: "Commercial Broker",
        filter: "commercial-broker",
        dropdown_name: "Commercial Broker",
        search_value: {
          "organizations.categories": { $in: ["commercial broker"] },
        },
      },
      {
        name: "Construction",
        filter: "construction",
        dropdown_name: "Construction",
        search_value: { "organizations.categories": { $in: ["construction"] } },
      },
      {
        name: "Developer",
        filter: "developer",
        dropdown_name: "Developer",
        search_value: { "organizations.categories": { $in: ["developer"] } },
      },
      {
        name: "Engineer",
        filter: "engineer",
        dropdown_name: "Engineer",
        search_value: { "organizations.categories": { $in: ["engineer"] } },
      },
      {
        name: "Expeditor",
        filter: "expeditor",
        dropdown_name: "Expeditor",
        search_value: { "organizations.categories": { $in: ["expeditor"] } },
      },
   /*   {
        name: "Property Manager",
        filter: "property-manager",
        dropdown_name: "Property Manager",
        search_value: {
          "organizations.categories": { $in: ["property-manager"] },
        },
      }, 
      {
        name: "Residential Broker",
        filter: "residential-broker",
        dropdown_name: "Residential Broker",
        search_value: {
          "organizations.categories": { $in: ["residential broker"] },
        },
      }, */
    ],
  },
];
export const _peopleFilters = [
  {
    //"Developer", "Marketing", "Expeditor",  "Engineer", "Architect", "Interior Designer", "Lender", "Construction", "Property Manager","Commercial Broker", "Residential Broker", "Other"
    //"developer", "marketing", "expeditor", "engineer", "architect", "interior designer", "lender", "construction", "commercial broker", "residential broker", "Other"
    name: "Category / Role",
    show_name: true,
    search_key: "contacts.project_roles",
    filter_type: "company",
    multi_filter: true,
    value_contains_key: true,
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Permit Applicant",
        filter: "applicant",
        dropdown_name: "Permit Applicant",
        search_value: {
          "contacts.project_roles": { $in: ["applicant", "permit applicant"] },
        },
      },
      {
        name: "Permit Filing Rep",
        filter: "filing-rep",
        dropdown_name: "Permit Filing Rep",
        search_value: {
          "contacts.project_roles": {
            $in: ["filing applicant", "permit filing representative"],
          },
        },
      },
      {
        name: "Project Owner",
        filter: "owner",
        dropdown_name: "Project Owner",
        search_value: { "contacts.project_roles": { $in: ["owner"] } },
      },
      {
        name: "Architect",
        filter: "architect",
        dropdown_name: "Architect",
        search_value: { "contacts.project_roles": { $in: ["architect"] } },
      },
      {
        name: "Commercial Broker",
        filter: "commercial-broker",
        dropdown_name: "Commercial Broker",
        search_value: {
          "contacts.project_roles": { $in: ["commercial broker"] },
        },
      },
      {
        name: "Construction",
        filter: "construction",
        dropdown_name: "Construction",
        search_value: { "contacts.project_roles": { $in: ["construction"] } },
      },
      {
        name: "Developer",
        filter: "developer",
        dropdown_name: "Developer",
        search_value: { "contacts.project_roles": { $in: ["developer"] } },
      },
      {
        name: "Engineer",
        filter: "engineer",
        dropdown_name: "Engineer",
        search_value: { "contacts.project_roles": { $in: ["engineer"] } },
      },
      {
        name: "Expeditor",
        filter: "expeditor",
        dropdown_name: "Expeditor",
        search_value: { "contacts.project_roles": { $in: ["expeditor"] } },
      },
    /*  {
        name: "Property Manager",
        filter: "property-manager",
        dropdown_name: "Property Manager",
        search_value: {
          "contacts.project_roles": { $in: ["property-manager"] },
        },
      },
      {
        name: "Residential Broker",
        filter: "residential-broker",
        dropdown_name: "Residential Broker",
        search_value: {
          "contacts.project_roles": { $in: ["residential broker"] },
        },
      }, */
    ],
  },
];
export const _buildingAdminFilters = [
  {
    name: "Project Type",
    show_name: true,
    search_key: "pipeline_occupancy_type",
    filter_type: "building",
    multi_filter: true,
    value_contains_key: true,
    type: "residential",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Residential",
        filter: "residential",
        dropdown_name: "Residential",
        search_value: {
            "queryString": {
                "defaultPath": "pipeline_occupancy_type",
                "query": "(residential)"
            }
        }
      },
      {
        name: "Commercial",
        filter: "commercial",
        dropdown_name: "Commercial",
        search_value: {
            "queryString": {
                "defaultPath": "pipeline_occupancy_type",
                "query": "(commercial)"
            }
        }
      },
      {
        name: "Institutional",
        filter: "institutional",
        dropdown_name: "Institutional",
        search_value: {
            "queryString": {
                "defaultPath": "pipeline_occupancy_type",
                "query": "(institutional)"
            }
        }
      },
      {
        name: "Mixed-Use",
        filter: "mixed-use",
        dropdown_name: "Mixed-Use",
        search_value: {
            "queryString": {
                "defaultPath": "pipeline_occupancy_type",
                "query": "(mixed-use)"
            }
        }
      }
    ],
  },
  {
    name: "Components",
    show_name: true,
    search_key: "pipeline_project_components",
    filter_type: "building",
    multi_filter: true,
    value_contains_key: true,
    type: "residential",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Condo",
        filter: "condo",
        dropdown_name: "Condo",
        search_value: {
            "queryString": {
                "defaultPath": "pipeline_project_components",
                "query": "(condo OR condominium)"
            }
        }
      },
      {
        name: "Co-op",
        filter: "co-op",
        dropdown_name: "Co-op",
        search_value: {
            "queryString": {
                "defaultPath": "pipeline_project_components",
                "query":"(coop OR co-op OR cooperative)"
            }
        }
      },
      {
        name: "Rental",
        filter: "rental",
        dropdown_name: "Rental",
        search_value: {
            "compound": {
                "should":[
                  {
                    "queryString" : {
                      "defaultPath": "pipeline_project_components",
                      "query":"(rental)"
                    }
                  },
                  {
                    "compound" : {
                     "must" : [
                       {
                         "range" : {
                           "path": "pipeline_dwelling_units_proposed",
                           "gte": 2
                         }
                       },
                       {"queryString": {
                             "defaultPath": "pipeline_occupancy_type",
                             "query": "(residential)"
                         }
                       },
                       {"compound": {
                             "mustNot": {
                              "queryString": {
                                "defaultPath": "pipeline_project_components",
                                "query":"(coop OR co-op OR cooperative OR condo OR condominium OR condop)"
                              }
                            }
                         }
                        }
                       
                     ]
                    }
                  }
                  
                ]
            }
        }
      },
      {
        name: "Single Family",
        filter: "single-family",
        dropdown_name: "Single Family",
        search_value: {
          compound: {
            should: [
              {
                phrase: {
                  query: "(single family)",
                  path: "pipeline_project_components"
                }
              },
              {
                compound: {
                  must: [
                    {
                      range: {
                        path: "pipeline_dwelling_units_proposed",
                        gte: 1,
                        lte: 1
                      }
                    },
                    {
                      compound: {
                        mustNot: [
                          {
                            text: {
                              query: "commercial",
                              path: "pipeline_occupancy_type"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            minimumShouldMatch: 1
          }
        },
      },
      {
        name: "Multi-Family",
        filter: "multi-family",
        dropdown_name: "Multi-Family",
        search_value: {
          compound: {
            must: [
              {
                text: {
                  query: "residential",
                  path: "pipeline_occupancy_type"
                }
              },
              {
                compound: {
                  mustNot: [
                    {
                      text: {
                        query: ["condominium", "cooperative", "condo", "coop", "co-op"],
                        path: "pipeline_project_components"
                      }
                    }
                  ]
                }
              },
              {
                compound: {
                  should: [
                    {
                      text: {
                        query: "multi-family",
                        path: "pipeline_project_components"
                      }
                    },
                    {
                      range: {
                        path: "pipeline_dwelling_units_proposed",
                        gte: 2,
                        lte: 5
                      }
                    }
                  ],
                  minimumShouldMatch: 1
                }
              }
            ]
          }
        }
      },
      {
        name: "Affordable",
        filter: "affordable",
        dropdown_name: "Affordable",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: "affordable",
                  path: "pipeline_project_components"
                }
              },
              {
                range: {
                  path: "pipeline_affordable_rental_units_proposed",
                  gt: 0
                }
              },
              {
                range: {
                  path: "pipeline_affordable_condo_units_proposed",
                  gt: 0
                }
              }
            ],
            minimumShouldMatch: 1
          }
        },
      },
      {
        name: "Retail",
        filter: "retail",
        dropdown_name: "Retail",
        search_value: {
          compound: {
          should: [
            {
              text: {
                query: "retail",
                path: "pipeline_project_components"
              }
            },
            {
              compound: {
                must: [
                  {
                    text: {
                      query: ["a2", "m"],
                      path: "pipeline_occupancy_classification_proposed_code"
                    }
                  }
                ]
              }
            }
          ],
          minimumShouldMatch: 1
        }
      }
      },
      {
        name: "Office",
        filter: "office",
        dropdown_name: "Office",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: "office",
                  path: "pipeline_project_components"
                }
              },
              {
                compound: {
                  must: [
                    {
                      text: {
                        query: ["b"],
                        path: "pipeline_occupancy_classification_proposed_code"
                      }
                    }
                  ]
                }
              }
            ],
            minimumShouldMatch: 1
          }
        },
      },
      {
        name: "Hotel",
        filter: "hotel",
        dropdown_name: "Hotel",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: "hotel",
                  path: "pipeline_project_components"
                }
              },
              {
                compound: {
                  must: [
                    {
                      text: {
                        query: ["r1", "j1"],
                        path: "pipeline_occupancy_classification_proposed_code"
                      }
                    }
                  ]
                }
              }
            ],
            minimumShouldMatch: 1
          }
        },
      },
    ],
  },
  {
    name: "Total Units",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "pipeline_dwelling_units_proposed",
    search_key: ["min_units", "max_units"],
    labels: ["Min Units", "Max Units"],
    min_max_values: [25, 200],
    min_max_increment: 25,
    custom_min_max_values: {
      min: [1, 11, 31, 51, 101, 201],
      max: [10, 30, 50, 100, 200, 300],
    },
    format_type: "number",
    search_value: [
      "pipeline_dwelling_units_proposed:[{min_units} TO {max_units}]",
    ],
    value_prefix: "",
    title_suffix: "Units"
  },
  {
    name: "Total Floors",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "pipeline_building_stories_proposed",
    search_key: ["min_floors", "max_floors"],
    labels: ["Min Floors", "Max Floors"],
    min_max_values: [1, 41],
    min_max_increment: 10,
    custom_min_max_values: {
      min: [1, 6, 11, 21, 41],
      max: [5, 10, 20, 40],
    },
    format_type: "number",
    search_value: [
      "pipeline_building_stories_proposed:[{min_floors} TO {max_floors}]",
    ],
    value_prefix: "",
    title_suffix: "Floors"
  },
  {
    name: "Resi Area",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "pipeline_zoning_area_residential",
    search_key: ["min_sq_ft", "max_sq_ft"],
    labels: ["Min Sq Ft", "Max Sq Ft"],
    min_max_values: [5000, 100000],
    min_max_increment: 500,
    custom_min_max_values: {
      min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
      max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
    },
    format_type: "number",
    value_prefix: "",
    title_suffix: "SF",
    type: "residential"
  },
  {
    name: "Comm Area",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "pipeline_zoning_area_commercial",
    search_key: ["min_comm_sq_ft", "max_comm_sq_ft"],
    labels: ["Min Sq Ft", "Max Sq Ft"],
    min_max_values: [5000, 100000],
    min_max_increment: 500,
    custom_min_max_values: {
      min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
      max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
    },
    format_type: "number",
    value_prefix: "",
    title_suffix: "SF",
    type: "residential"
  },
  {
    name: "Total Area",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "pipeline_total_construction_floor_area",
    search_key: ["min_total_ft", "max_total_ft"],
    labels: ["Min Area", "Max Area"],
    min_max_values: [5000, 100000],
    min_max_increment: 500,
    custom_min_max_values: {
      min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
      max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
    },
    format_type: "number",
    value_prefix: "",
    title_suffix: "SF"
  },
  {
    name: "Permit Filed",
    show_name: true,
    exclude_stage: ["pipeline"],
    date_filter: true,
    search_key: ["min_date", "max_date"],
    filter_key: "pipeline_filing_date",
    value_contains_key: true,
    format_type: "date"
  },
  {
    name: "Offering Submitted",
    show_name: true,
    date_filter: true,
    search_key: ["min_offering_date", "max_offering_date"],
    filter_key: "offering_submitted_date",
    value_contains_key: true,
    format_type: "date"
   
  },
  {
    name: "TCO / CO",
    show_name: true,
    multi_filter: true,
    search_key: "pipeline_coo_issuance_date",
    filter_type: "building",
    value_contains_key: true,
    values: [
        {
          name: "Any",
          filter: "",
          dropdown_name: "Any",
          search_value: "",
        },
        {
          name: "No TCO / CO",
          filter: "no-co",
          dropdown_name: "No TCO / CO",
          search_value: {
            compound: {
              must: [
                {
                  compound: {
                    should: [
                      {
                        range: {
                          path: "pipeline_coo_issuance_date",
                          lt: `ISODate:${moment().add(-25, "year").toDate().toISOString()}`
                        }
                      },
                      { compound: {
                          mustNot: [
                            {
                              exists: {
                                path: "pipeline_coo_issuance_date",
                              }
                            }
                          ]
                          }
                      }
                    ],
                    minimumShouldMatch: 1
                  }
                },
                {
                  compound: {
                    mustNot: [
                      {
                        text: {
                          query: "completion",
                          path: "pipeline_project_stage"
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        },
        {
          name: "Has TCO / CO",
          filter: "has-co",
          dropdown_name: "Has TCO / CO",
          search_value:{
            compound: {
              must: [
                {
                  range: {
                    path: "pipeline_coo_issuance_date",
                    gte: `ISODate:${moment().add(-25, "year").toDate().toISOString()}`,
                    lte: `ISODate:${moment().toDate().toISOString()}`
                  }
                },
                {
                  text: {
                    query: "completion",
                    path: "pipeline_project_stage"
                  }
                }
              ]
            }
          }
        },
        {
          name: "Within 1 Year",
          filter: "1-year",
          dropdown_name: "Within 1 Year",
          search_value:
                {
                  compound: {
                    must: [
                      {
                        range: {
                          path: "pipeline_coo_issuance_date",
                          gte: `ISODate:${moment().add(-1, "year").toDate().toISOString()}`,
                          lte: `ISODate:${moment().toDate().toISOString()}`
                        }
                      },
                      {
                        text: {
                          query: "completion",
                          path: "pipeline_project_stage"
                        }
                      }
                    ]
                  }
          }
        },
        {
          name: "1 to 2 Years",
          filter: "2-year",
          dropdown_name: "Last 1 to 2 Years",
          search_value: {
            compound: {
              must: [
                {
                  range: {
                    path: "pipeline_coo_issuance_date",
                    gte: `ISODate:${moment().add(-2, "year").toDate().toISOString()}`,
                    lte: `ISODate:${moment().add(-1, "year").toDate().toISOString()}`
                  }
                },
                {
                  text: {
                    query: "completion",
                    path: "pipeline_project_stage"
                  }
                }
              ]
            }
          }
        },
        {
          name: "2 to 5 Years",
          filter: "2-5-year",
          dropdown_name: "Last 2 to 5 Years",
          search_value: {
            compound: {
              must: [
                {
                  range: {
                    path: "pipeline_coo_issuance_date",
                    gte: `ISODate:${moment().add(-5, "year").toDate().toISOString()}`,
                    lte: `ISODate:${moment().add(-2, "year").toDate().toISOString()}`
                  }
                },
                {
                  text: {
                    query: "completion",
                    path: "pipeline_project_stage"
                  }
                }
              ]
            }
          }
        },
        {
          name: "5 to 10 Years",
          filter: "5-10-year",
          dropdown_name: "Last 5 to 10 Years",
          search_value: {
            compound: {
              must: [
                {
                  range: {
                    path: "pipeline_coo_issuance_date",
                    gte: `ISODate:${moment().add(-10, "year").toDate().toISOString()}`,
                    lte: `ISODate:${moment().add(-5, "year").toDate().toISOString()}`
                  }
                },
                {
                  text: {
                    query: "completion",
                    path: "pipeline_project_stage"
                  }
                }
              ]
            }
          }
        },
        {
          name: "10 to 15 Years",
          filter: "10-15-year",
          dropdown_name: "Last 10 to 15 Years",
          search_value: {
            compound: {
              must: [
                {
                  range: {
                    path: "pipeline_coo_issuance_date",
                    gte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`,
                    lte: `ISODate:${moment().add(-10, "year").toDate().toISOString()}`
                  }
                },
                {
                  text: {
                    query: "completion",
                    path: "pipeline_project_stage"
                  }
                }
              ]
            }
          }
        },
        {
          name: "More Than 15 Years",
          filter: "more-15-year",
          dropdown_name: "More Than 15 Years Ago",
          search_value: {
            compound: {
              must: [
                {
                  range: {
                    path: "pipeline_coo_issuance_date",
                    lte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`,
                  }
                },
                {
                  text: {
                    query: "completion",
                    path: "pipeline_project_stage"
                  }
                }
              ]
            }
          }
        },
      ],
   
  },
  {
    name: "Tax Benefits",
    show_name: true,
    search_key: "tax_benefits",
    multi_filter : true,
    value_contains_key: true,
    values: [
      
      {
        name: "421a",
        filter: "421a",
        dropdown_name: "421a",
        search_value: {
          equals: {
            value: true,
            path: "tax_abatement_program_421a"
          }
        },
      },
      {
        name: "J51",
        filter: "j51",
        dropdown_name: "J51",
        search_value: {
          equals: {
            value: true,
            path: "tax_abatement_program_j51"
          }
        }
      }
      
    ]
  },
  {
    name: "Construction Type",
    show_name: true,
    search_key: "pipeline_construction_type",
    filter_type: "building",
    multi_filter: true,
    value_contains_key: true,
    type: "residential",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "New Construction",
        filter: "new-construction",
        dropdown_name: "New Construction",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: "new-construction",
                  path: "construction_type.pipeline_construction_type"
                }
              },
              {
                compound: {
                  must: [
                    {
                      text: {
                        query: "nb",
                        path: "pipeline_job_type"
                      }
                    },
                    
                  ],
                  mustNot:[
                    {
                      exists: {
                        path: "construction_type.pipeline_construction_type"
                      }
                    }
                  ]
                }
              }
            ],
            minimumShouldMatch: 1
          }
        },
      },
      {
        name: "Alteration",
        filter: "alteration",
        dropdown_name: "Alteration",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: "alteration",
                  path: "construction_type.pipeline_construction_type"
                }
              },
              {
                compound: {
                  must: [
                    {
                      text: {
                        query: "a1",
                        path: "pipeline_job_type"
                      }
                    },
                  ],
                  mustNot:[
                    {
                      exists: {
                        path: "construction_type.pipeline_construction_type"
                      }
                    }
                  ]
                }
              },
              {
                text: {
                  query: ["Office to Condo", "Office to Rental", "Office to Residential"],
                  path: "custom_fields.Value"
                }
              }
            ],
            minimumShouldMatch: 1
          }
        }
      },
     
      {
        name: "Office to Residential",
        filter: "office-to-resi",
        dropdown_name: "Office to Residential",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: ["Office to Condo", "Office to Rental", "Office to Residential"],
                  path: "custom_fields.Value"
                }
              }
            ],
            minimumShouldMatch: 1
          }
        },
      },
      {
        name: "Office to Condo",
        filter: "office-to-condo",
        dropdown_name: "Office to Condo",
        search_value: {
          compound: {
            should: [
              {
                phrase: {
                  query: "Office to Condo",
                  path: "custom_fields.Value"
                }
              }
            ],
            minimumShouldMatch: 1
          }
        }
      },
      {
        name: "Office to Rental",
        filter: "office-to-rental",
        dropdown_name: "Office to Rental",
        search_value: {
          compound: {
            should: [
              {
                text: {
                  query: ["Office to Rental"],
                  path: "custom_fields.Value"
                }
              }
            ],
            minimumShouldMatch: 1
          }
        }
      }
    ],
  },
  {
    name: "Project Progress",
    show_name: true,
    search_key: "project_progress",
    filter_type: "building",
    multi_filter: true,
    value_contains_key: true,
    type: "residential",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Active",
        filter: "active",
        dropdown_name: "Active",
        search_value: {
          compound: {
           
            mustNot:[
              {
                exists: {
                  path: "pipeline_project_stalled"
                }
              },
              {
                exists: {
                  path: "pipeline_project_abandoned"
                }
              }
            ],
          }
        }
      },
      {
        name: "Stalled",
        filter: "stalled",
        dropdown_name: "Stalled",
        search_value: { 
          equals: {
            path: "pipeline_project_stalled",
            value: true
          }
        },
      },
      {
        name: "Abandoned",
        filter: "abandoned",
        dropdown_name: "Abandoned",
        search_value: { 
          equals: {
            path: "pipeline_project_abandoned",
            value: true
          }
        }
      }
    ],
  },
];

export const _buildingAdminMatchFilters = [
	{
	  name: "Project Type",
	  show_name: true,
	  search_key: "pipeline_occupancy_type",
	  filter_type: "building",
	  multi_filter: true,
	  value_contains_key: true,
	  type: "residential",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "Residential",
		  filter: "residential",
		  dropdown_name: "Residential",
		  search_value: { pipeline_occupancy_type: { $in: ["residential"] } },
		},
		{
		  name: "Commercial",
		  filter: "commercial",
		  dropdown_name: "Commercial",
		  search_value: { pipeline_occupancy_type: { $in: ["commercial"] } },
		},
		{
		  name: "Institutional",
		  filter: "institutional",
		  dropdown_name: "Institutional",
		  search_value: { pipeline_occupancy_type: { $in: ["institutional"] } },
		},
		{
		  name: "Mixed-Use",
		  filter: "mixed-use",
		  dropdown_name: "Mixed-Use",
		  search_value: { pipeline_occupancy_type: { $in: ["mixed-use"] } },
		}
	  ],
	},
	{
	  name: "Components",
	  show_name: true,
	  search_key: "pipeline_project_components",
	  filter_type: "building",
	  multi_filter: true,
	  value_contains_key: true,
	  type: "residential",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "Condo",
		  filter: "condo",
		  dropdown_name: "Condo",
		  search_value: {
			pipeline_project_components: { $in: ["condo", "condominium"] },
		  },
		},
		{
		  name: "Co-op",
		  filter: "co-op",
		  dropdown_name: "Co-op",
		  search_value: {
			pipeline_project_components: {
			  $in: ["coop", "co-op", "cooperative"],
			},
		  },
		},
		{
		  name: "Rental",
		  filter: "rental",
		  dropdown_name: "Rental",
		  search_value: {
			$or: [
			  { pipeline_project_components: { $in: ["rental"] } },
			  { $and : [
				  {pipeline_dwelling_units_proposed: { $gte: 2 } }, 
				  {pipeline_occupancy_type: {$nin : ["commercial"]}}, 
				  {pipeline_project_components: {
				  $nin: ["coop", "co-op", "cooperative", "condo", "condominium", "condop"],
				  }}]
			  }
			],
		  },
		},
		{
		  name: "Single Family",
		  filter: "single-family",
		  dropdown_name: "Single Family",
		  search_value: {
			$or: [
			  { pipeline_project_components: { $in: ["single family"] } },
			  { $and : [{pipeline_dwelling_units_proposed: 1 }, {pipeline_occupancy_type: {$nin : ["commercial"]}}]}
			],
		  },
		},
		{
		  name: "Multi-Family",
		  filter: "multi-family",
		  dropdown_name: "Multi-Family",
		  search_value: {
			$and: [
			  { pipeline_occupancy_type: { $in: ["residential"] } },
			  {
				pipeline_project_components: {
				  $nin: ["condominium", "cooperative", "condo", "coop", "co-op"],
				},
			  },
			  {
				$or: [
				  { pipeline_project_components: { $in: ["multi-family"] } },
				  { pipeline_dwelling_units_proposed: { $gte: 2, $lte: 5 } },
				],
			  },
			],
		  },
		},
		{
		  name: "Affordable",
		  filter: "affordable",
		  dropdown_name: "Affordable",
		  search_value: {
			$or: [
			  { pipeline_project_components: { $in: ["affordable"] } },
			  { pipeline_affordable_rental_units_proposed: { $gt: 0 } },
			  { pipeline_affordable_condo_units_proposed: { $gt: 0 } },
			],
		  },
		},
		{
		  name: "Retail",
		  filter: "retail",
		  dropdown_name: "Retail",
		  search_value: {
			$or: [
			  { pipeline_project_components: { $in: ["retail"] } },
			  {
				"$and" : [ {pipeline_occupancy_classification_proposed_code: { $in: ["a2", "m"]} }, { "occupancy_type.Project Components" : { "$exists" : false } }  ]
			  },
			],
		  },
		},
		{
		  name: "Office",
		  filter: "office",
		  dropdown_name: "Office",
		  search_value: {
			$or: [
			  { pipeline_project_components: { $in: ["office"] } },
			  {
				"$and" : [ { pipeline_occupancy_classification_proposed_code: { $in: ["b"] } }, { "occupancy_type.Project Components" : { "$exists" : false } }  ]
			  },
			],
		  },
		},
		{
		  name: "Hotel",
		  filter: "hotel",
		  dropdown_name: "Hotel",
		  search_value: {
			$or: [
			  { pipeline_project_components: { $in: ["hotel"] } },
			  {
				"$and" : [ {pipeline_occupancy_classification_proposed_code: { $in: ["r1", "j1"]} }, { "occupancy_type.Project Components" : { "$exists" : false } }  ]
			  },
			],
		  },
		},
	  ],
	},
	{
	  name: "Total Units",
	  show_name: true,
	  price_filter: true,
	  value_contains_key: true,
	  filter_key: "pipeline_dwelling_units_proposed",
	  search_key: ["min_units", "max_units"],
	  labels: ["Min Units", "Max Units"],
	  min_max_values: [25, 200],
	  min_max_increment: 25,
	  custom_min_max_values: {
		min: [1, 11, 31, 51, 101, 201],
		max: [10, 30, 50, 100, 200, 300],
	  },
	  format_type: "number",
	  search_value: [
		"pipeline_dwelling_units_proposed:[{min_units} TO {max_units}]",
	  ],
	  value_prefix: "",
	  title_suffix: "Units",
	  values: [
		{
		  name: "Any Units",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "1-10 Total Units",
		  filter: "1-to-10",
		  dropdown_name: "1 to 10 Units",
		  search_value: { $gte: 1, $lte: 10 },
		},
  
		{
		  name: "11-30 Total Units",
		  filter: "11-to-30",
		  dropdown_name: "11 to 30 Units",
		  search_value: { $gte: 11, $lte: 30 },
		},
		{
		  name: "31-50 Total Units",
		  filter: "31-to-50",
		  dropdown_name: "31 to 50 Units",
		  search_value: { $gte: 31, $lte: 50 },
		},
		{
		  name: "51-100 Total Units",
		  filter: "51-to-100",
		  dropdown_name: "51 to 100 Units",
		  search_value: { $gte: 51, $lte: 100 },
		},
		{
		  name: "101-200 Total Units",
		  filter: "101-to-200",
		  dropdown_name: "101 to 200 Units",
		  search_value: { $gte: 101, $lte: 200 },
		},
		{
		  name: "201 + Total Units",
		  filter: "201-or-more",
		  dropdown_name: "201 or more Units",
		  search_value: { $gte: 201 },
		},
	  ],
	},
	{
	  name: "Total Floors",
	  show_name: true,
	  price_filter: true,
	  value_contains_key: true,
	  filter_key: "pipeline_building_stories_proposed",
	  search_key: ["min_floors", "max_floors"],
	  labels: ["Min Floors", "Max Floors"],
	  min_max_values: [1, 41],
	  min_max_increment: 10,
	  custom_min_max_values: {
		min: [1, 6, 11, 21, 41],
		max: [5, 10, 20, 40],
	  },
	  format_type: "number",
	  search_value: [
		"pipeline_building_stories_proposed:[{min_floors} TO {max_floors}]",
	  ],
	  value_prefix: "",
	  title_suffix: "Floors",
	  values: [
		{
		  name: "Any Units",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "1-5 Floors",
		  filter: "1-to-5",
		  dropdown_name: "1 to 5 Floors",
		  search_value: { $gte: 1, $lte: 5 },
		},
		{
		  name: "6-10 Floors",
		  filter: "6-to-10",
		  dropdown_name: "6 to 10 Floors",
		  search_value: { $gte: 6, $lte: 10 },
		},
		{
		  name: "11-20 Floors",
		  filter: "11-to-20",
		  dropdown_name: "11 to 20 Floors",
		  search_value: { $gte: 11, $lte: 20 },
		},
		{
		  name: "21-40 Floors",
		  filter: "21-to-40",
		  dropdown_name: "21 to 40 Floors",
		  search_value: { $gte: 21, $lte: 40 },
		},
  
		{
		  name: "41+ Floors",
		  filter: "41-or-more",
		  dropdown_name: "41+ Floors",
		  search_value: { $gte: 41 },
		},
	  ],
	},
	{
	  name: "Resi Area",
	  show_name: true,
	  price_filter: true,
	  value_contains_key: true,
	  filter_key: "pipeline_zoning_area_residential",
	  search_key: ["min_sq_ft", "max_sq_ft"],
	  labels: ["Min Sq Ft", "Max Sq Ft"],
	  min_max_values: [5000, 100000],
	  min_max_increment: 500,
	  custom_min_max_values: {
		min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
		max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
	  },
	  format_type: "number",
	  value_prefix: "",
	  title_suffix: "SF",
	  type: "residential",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "25,000 or More SF",
		  filter: "25-and-more",
		  dropdown_name: "25,000 or More SF",
		  search_value: { $gte: 25000 },
		},
		{
		  name: "5,000 SF or Less",
		  filter: "1",
		  dropdown_name: "5,000 SF or Less",
		  search_value: { $lte: 5000 },
		},
		{
		  name: "5,001 - 10,000 SF",
		  filter: "5-to-10",
		  dropdown_name: "5,001 - 10,000 SF",
		  search_value: { $gte: 5001, $lte: 10000 },
		},
		{
		  name: "10,001 - 25,000 SF",
		  filter: "10-to-25",
		  dropdown_name: "10,001 - 25,000 SF",
		  search_value: { $gte: 10001, $lte: 25000 },
		},
		{
		  name: "25,001 - 50,000 SF",
		  filter: "25-to-50",
		  dropdown_name: "25,001 - 50,000 SF",
		  search_value: { $gte: 25001, $lte: 50000 },
		},
		{
		  name: "50,001 - 100,000 SF",
		  filter: "50-to-100",
		  dropdown_name: "50,001 - 100,000 SF",
		  search_value: { $gte: 50001, $lte: 100000 },
		},
		{
		  name: "100,001 - 250,000 SF",
		  filter: "100-to-250",
		  dropdown_name: "100,001 - 250,000 SF",
		  search_value: { $gte: 100001, $lte: 250000 },
		},
		{
		  name: "250,001 - 500,000 SF",
		  filter: "250-to-500",
		  dropdown_name: "250,001 - 500,000 SF",
		  search_value: { $gte: 250001, $lte: 500000 },
		},
		{
		  name: "500,001 - 1M SF",
		  filter: "500-to-1m",
		  dropdown_name: "500,001 - 1M SF",
		  search_value: { $gte: 500001, $lte: 1000000 },
		},
		{
		  name: "More Than 1M SF",
		  filter: "1m-and-more",
		  dropdown_name: "More Than 1M SF",
		  search_value: { $gte: 1000001 },
		},
	  ],
	},
	{
	  name: "Comm Area",
	  show_name: true,
	  price_filter: true,
	  value_contains_key: true,
	  filter_key: "pipeline_zoning_area_commercial",
	  search_key: ["min_comm_sq_ft", "max_comm_sq_ft"],
	  labels: ["Min Sq Ft", "Max Sq Ft"],
	  min_max_values: [5000, 100000],
	  min_max_increment: 500,
	  custom_min_max_values: {
		min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
		max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
	  },
	  format_type: "number",
	  value_prefix: "",
	  title_suffix: "SF",
	  type: "residential",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "25,000 or More SF",
		  filter: "25-and-more",
		  dropdown_name: "25,000 or More SF",
		  search_value: { $gte: 25000 },
		},
		{
		  name: "5,000 SF or Less",
		  filter: "1",
		  dropdown_name: "5,000 SF or Less",
		  search_value: { $lte: 5000 },
		},
		{
		  name: "5,001 - 10,000 SF",
		  filter: "5-to-10",
		  dropdown_name: "5,001 - 10,000 SF",
		  search_value: { $gte: 5001, $lte: 10000 },
		},
		{
		  name: "10,001 - 25,000 SF",
		  filter: "10-to-25",
		  dropdown_name: "10,001 - 25,000 SF",
		  search_value: { $gte: 10001, $lte: 25000 },
		},
		{
		  name: "25,001 - 50,000 SF",
		  filter: "25-to-50",
		  dropdown_name: "25,001 - 50,000 SF",
		  search_value: { $gte: 25001, $lte: 50000 },
		},
		{
		  name: "50,001 - 100,000 SF",
		  filter: "50-to-100",
		  dropdown_name: "50,001 - 100,000 SF",
		  search_value: { $gte: 50001, $lte: 100000 },
		},
		{
		  name: "100,001 - 250,000 SF",
		  filter: "100-to-250",
		  dropdown_name: "100,001 - 250,000 SF",
		  search_value: { $gte: 100001, $lte: 250000 },
		},
		{
		  name: "250,001 - 500,000 SF",
		  filter: "250-to-500",
		  dropdown_name: "250,001 - 500,000 SF",
		  search_value: { $gte: 250001, $lte: 500000 },
		},
		{
		  name: "500,001 - 1M SF",
		  filter: "500-to-1m",
		  dropdown_name: "500,001 - 1M SF",
		  search_value: { $gte: 500001, $lte: 1000000 },
		},
		{
		  name: "More Than 1M SF",
		  filter: "1m-and-more",
		  dropdown_name: "More Than 1M SF",
		  search_value: { $gte: 1000001 },
		},
	  ],
	},
	{
	  name: "Total Area",
	  show_name: true,
	  price_filter: true,
	  value_contains_key: true,
	  filter_key: "pipeline_total_construction_floor_area",
	  search_key: ["min_total_ft", "max_total_ft"],
	  labels: ["Min Area", "Max Area"],
	  min_max_values: [5000, 100000],
	  min_max_increment: 500,
	  custom_min_max_values: {
		min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
		max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
	  },
	  format_type: "number",
	  value_prefix: "",
	  title_suffix: "SF",
	  values: [
		{
		  name: "25,000 or More SF",
		  filter: "25-and-more",
		  dropdown_name: "25,000 or More SF",
		  search_value: { $gte: 25000 },
		},
		{
		  name: "5,000 SF or Less",
		  filter: "1",
		  dropdown_name: "5,000 SF or Less",
		  search_value: { $lte: 5000 },
		},
		{
		  name: "5,001 - 10,000 SF",
		  filter: "5-to-10",
		  dropdown_name: "5,001 - 10,000 SF",
		  search_value: { $gte: 5001, $lte: 10000 },
		},
		{
		  name: "10,001 - 25,000 SF",
		  filter: "10-to-25",
		  dropdown_name: "10,001 - 25,000 SF",
		  search_value: { $gte: 10001, $lte: 25000 },
		},
		{
		  name: "25,001 - 50,000 SF",
		  filter: "25-to-50",
		  dropdown_name: "25,001 - 50,000 SF",
		  search_value: { $gte: 25001, $lte: 50000 },
		},
		{
		  name: "50,001 - 100,000 SF",
		  filter: "50-to-100",
		  dropdown_name: "50,001 - 100,000 SF",
		  search_value: { $gte: 50001, $lte: 100000 },
		},
		{
		  name: "100,001 - 250,000 SF",
		  filter: "100-to-250",
		  dropdown_name: "100,001 - 250,000 SF",
		  search_value: { $gte: 100001, $lte: 250000 },
		},
		{
		  name: "250,001 - 500,000 SF",
		  filter: "250-to-500",
		  dropdown_name: "250,001 - 500,000 SF",
		  search_value: { $gte: 250001, $lte: 500000 },
		},
		{
		  name: "500,001 - 1M SF",
		  filter: "500-to-1m",
		  dropdown_name: "500,001 - 1M SF",
		  search_value: { $gte: 500001, $lte: 1000000 },
		},
		{
		  name: "More Than 1M SF",
		  filter: "1m-and-more",
		  dropdown_name: "More Than 1M SF",
		  search_value: { $gte: 1000001 },
		},
	  ],
	},
	{
	  name: "Permit Filed",
	  show_name: true,
	  exclude_stage: ["pipeline"],
	  date_filter: true,
	  search_key: ["min_date", "max_date"],
	  filter_key: "pipeline_filing_date",
	  value_contains_key: true,
	  format_type: "date",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "Not Started",
		  filter: "not-started",
		  dropdown_name: "Not Started",
		  search_value: { $exists: false },
		},
		{
		  name: "Has Started",
		  filter: "has-started",
		  dropdown_name: "Has Started",
		  search_value: {
			$gte: moment().add(-25, "year").toDate().toISOString(),
			$lte: moment().toDate().toISOString(),
		  },
		},
		{
		  name: "Within 1 Year",
		  filter: "1-year",
		  dropdown_name: "Within 1 Year",
		  search_value: {
			$gte: moment().add(-1, "year").toDate().toISOString(),
			$lte: moment().toDate().toISOString(),
		  },
		},
		{
		  name: "1 to 2 Years",
		  filter: "2-year",
		  dropdown_name: "Last 1 to 2 Years",
		  search_value: {
			$gte: moment().add(-2, "year").toDate().toISOString(),
			$lte: moment().toDate().toISOString(),
		  },
		},
		{
		  name: "2 to 5 Years",
		  filter: "2-5-year",
		  dropdown_name: "Last 2 to 5 Years",
		  search_value: {
			$gte: moment().add(-5, "year").toDate().toISOString(),
			$lte: moment().add(-2, "year").toDate().toISOString(),
		  },
		},
		{
		  name: "5 to 10 Years",
		  filter: "5-10-year",
		  dropdown_name: "Last 5 to 10 Years",
		  search_value: {
			$gte: moment().add(-10, "year").toDate().toISOString(),
			$lte: moment().add(-5, "year").toDate().toISOString(),
		  },
		},
		{
		  name: "10 to 15 Years",
		  filter: "10-15-year",
		  dropdown_name: "Last 10 to 15 Years",
		  search_value: {
			$gte: moment().add(-15, "year").toDate().toISOString(),
			$lte: moment().add(-10, "year").toDate().toISOString(),
		  },
		},
		{
		  name: "More Than 15 Years",
		  filter: "more-15-year",
		  dropdown_name: "More Than 15 Years Ago",
		  search_value: {
			$gte: moment().add(-20, "year").toDate().toISOString(),
			$lte: moment().add(-15, "year").toDate().toISOString(),
		  },
		},
	  ],
	},
	{
	  name: "Offering Submitted",
	  show_name: true,
	  multi_filter: true,
	  search_key: "offering_submitted_date",
	  filter_type: "building",
	  multi_filter: true,
	  value_contains_key: true,
	  values: [
		  {
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: "",
		  },
		  {
			name: "No Offering Plan",
			filter: "no-offering",
			dropdown_name: "No Offering Plan",
			search_value: {            
				$or : [ 
				{"offering_submitted_date" : { $lt: `ISODate:${moment().add(-25, "year").toDate().toISOString()}`}},
				{"offering_submitted_date" : { $exists: false }}
			  ] 
			},
		  },
		  {
			name: "Has Offering Plan",
			filter: "has-offering",
			dropdown_name: "Has Offering Plan",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-25, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }}
			  ] 
			},
		  },
		  {
			name: "Within 1 Year",
			filter: "1-year",
			dropdown_name: "Within 1 Year",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-1, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }}
			  ] 
			},
		  },
		  {
			name: "1 to 2 Years",
			filter: "2-year",
			dropdown_name: "Last 1 to 2 Years",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-2, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-1, "year").toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }}
			  ] 
			}
		  },
		  {
			name: "2 to 5 Years",
			filter: "2-5-year",
			dropdown_name: "Last 2 to 5 Years",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-5, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-2, "year").toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }}
			  ] 
			}
		  },
		  {
			name: "5 to 10 Years",
			filter: "5-10-year",
			dropdown_name: "Last 5 to 10 Years",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-10, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-5, "year").toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }}
			  ] 
			}
		  },
		  {
			name: "10 to 15 Years",
			filter: "10-15-year",
			dropdown_name: "Last 10 to 15 Years",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-10, "year").toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }}
			  ] 
			}
		  },
		  {
			name: "More Than 15 Years",
			filter: "more-15-year",
			dropdown_name: "More Than 15 Years Ago",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`}},
				{"offering_file_number" : { $exists: true }},
				{"offering_file_number" : { $ne: "" }}
			  ] 
			}
		  },
		],
	 
	},
	{
	  name: "TCO / CO",
	  show_name: true,
	  multi_filter: true,
	  search_key: "pipeline_coo_issuance_date",
	  filter_type: "building",
	  value_contains_key: true,
	  values: [
		  {
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: "",
		  },
		  {
			name: "No TCO / CO",
			filter: "no-co",
			dropdown_name: "No TCO / CO",
			search_value: {   
			   $and : [          
				{ $or : [ 
				  {"pipeline_coo_issuance_date" : { $lt: `ISODate:${moment().add(-25, "year").toDate().toISOString()}`}},
				  {"pipeline_coo_issuance_date" : { $exists: false }},
				]},
				{"pipeline_project_stage" : {"$ne" : "completion"}}
			  ]
			},
		  },
		  {
			name: "Has TCO / CO",
			filter: "has-co",
			dropdown_name: "Has TCO / CO",
			search_value: {   
			  $or : [         
				{ $and : [ 
				{"pipeline_coo_issuance_date" : { $gte: `ISODate:${moment().add(-25, "year").toDate().toISOString()}`}}, 
				{"pipeline_coo_issuance_date" : { $lte: `ISODate:${moment().toDate().toISOString()}`}} ,
			   
			  ]},
			  {"pipeline_project_stage" : "completion"}
			  ] 
			},
		  },
		  {
			name: "Within 1 Year",
			filter: "1-year",
			dropdown_name: "Within 1 Year",
			search_value: {            
				$and : [ 
				{"pipeline_coo_issuance_date" : { $gte: `ISODate:${moment().add(-1, "year").toDate().toISOString()}`}}, 
				{"pipeline_coo_issuance_date" : { $lte: `ISODate:${moment().toDate().toISOString()}`}},
				{"pipeline_project_stage" : "completion"}
			  ] 
			},
		  },
		  {
			name: "1 to 2 Years",
			filter: "2-year",
			dropdown_name: "Last 1 to 2 Years",
			search_value: {            
				$and : [ 
				{"pipeline_coo_issuance_date" : { $gte: `ISODate:${moment().add(-2, "year").toDate().toISOString()}`}}, 
				{"pipeline_coo_issuance_date" : { $lte: `ISODate:${moment().add(-1, "year").toDate().toISOString()}`}},
				{"pipeline_project_stage" : "completion"}
			  ] 
			}
		  },
		  {
			name: "2 to 5 Years",
			filter: "2-5-year",
			dropdown_name: "Last 2 to 5 Years",
			search_value: {            
				$and : [ 
				{"pipeline_coo_issuance_date" : { $gte: `ISODate:${moment().add(-5, "year").toDate().toISOString()}`}}, 
				{"pipeline_coo_issuance_date" : { $lte: `ISODate:${moment().add(-2, "year").toDate().toISOString()}`}},
				{"pipeline_project_stage" : "completion"}
			  ] 
			}
		  },
		  {
			name: "5 to 10 Years",
			filter: "5-10-year",
			dropdown_name: "Last 5 to 10 Years",
			search_value: {            
				$and : [ 
				{"pipeline_coo_issuance_date" : { $gte: `ISODate:${moment().add(-10, "year").toDate().toISOString()}`}}, 
				{"pipeline_coo_issuance_date" : { $lte: `ISODate:${moment().add(-5, "year").toDate().toISOString()}`}},
				{"pipeline_project_stage" : "completion"}
			  ] 
			}
		  },
		  {
			name: "10 to 15 Years",
			filter: "10-15-year",
			dropdown_name: "Last 10 to 15 Years",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-10, "year").toDate().toISOString()}`}},
				{"pipeline_project_stage" : "completion"}
			  ] 
			}
		  },
		  {
			name: "More Than 15 Years",
			filter: "more-15-year",
			dropdown_name: "More Than 15 Years Ago",
			search_value: {            
				$and : [ 
				{"offering_submitted_date" : { $gte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`}}, 
				{"offering_submitted_date" : { $lte: `ISODate:${moment().add(-15, "year").toDate().toISOString()}`}},
				{"pipeline_project_stage" : "completion"}
			  ] 
			}
		  },
		],
	 
	},
	{
	  name: "Tax Benefits",
	  show_name: true,
	  search_key: "tax_benefits",
	  filter_type: "building",
	  multi_filter : true,
	  value_contains_key: true,
	  values: [
		{
		  name: "421a",
		  filter: "421a",
		  dropdown_name: "421a",
		  search_value: {$and:[{tax_abatement_program_421a:true}]},
		},
		{
		  name: "J51",
		  filter: "j51",
		  dropdown_name: "J51",
		  search_value: {$and:[{tax_abatement_program_j51:true}]},
		}
	  ]
	},
	{
	  name: "Construction Type",
	  show_name: true,
	  search_key: "pipeline_construction_type",
	  filter_type: "building",
	  multi_filter: true,
	  value_contains_key: true,
	  type: "residential",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "New Construction",
		  filter: "new-construction",
		  dropdown_name: "New Construction",
		  search_value: { 
			$or: [
			  { "construction_type.pipeline_construction_type": { $in: ["new-construction"] } },
			  {
				"$and" : [ { pipeline_job_type: { $in: ["nb"] } }, { "construction_type.pipeline_construction_type" : { "$exists" : false } }  ]
			  },
			] 
		  },
		},
		{
		  name: "Alteration",
		  filter: "alteration",
		  dropdown_name: "Alteration",
		  search_value: { 
			$or: [
			  { "construction_type.pipeline_construction_type": { $in: ["alteration"] } },
			  {
				"$and" : [ { pipeline_job_type: { $in: ["a1"] } }, { "construction_type.pipeline_construction_type" : { "$exists" : false } }  ]
			  },
			  { "custom_fields.Value": { $in: ["Office to Condo", "Office to Rental",  "Office to Residential"] } }
			] 
		   },
		},
	   
		{
		  name: "Office to Residential",
		  filter: "office-to-resi",
		  dropdown_name: "Office to Residential",
		  search_value: { 
			$or: [
			  { "custom_fields.Value": { $in: ["Office to Condo", "Office to Rental",  "Office to Residential"] } }
			] 
		   },
		},
		{
		  name: "Office to Condo",
		  filter: "office-to-condo",
		  dropdown_name: "Office to Condo",
		  search_value: { 
			$or: [
			  { "custom_fields.Value": { $in: ["Office to Condo"] } }
			] 
		   },
		},
		{
		  name: "Office to Rental",
		  filter: "office-to-rental",
		  dropdown_name: "Office to Rental",
		  search_value: { 
			$or: [
			  { "custom_fields.Value": { $in: ["Office to Rental"] } }
			] 
		   },
		}
	  ],
	},
	{
	  name: "Project Progress",
	  show_name: true,
	  search_key: "project_progress",
	  filter_type: "building",
	  multi_filter: true,
	  value_contains_key: true,
	  type: "residential",
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "Active",
		  filter: "active",
		  dropdown_name: "Active",
		  search_value: { 
			$and: [
			  { "$or" : [{"pipeline_project_stalled": { "$exists" : false } }, {"pipeline_project_stalled": false }] },
			  { "$or" : [{"pipeline_project_abandoned": { "$exists" : false } }, {"pipeline_project_abandoned": false }] },
			] 
		  },
		},
		{
		  name: "Stalled",
		  filter: "stalled",
		  dropdown_name: "Stalled",
		  search_value: { 
			$or: [
			  { "pipeline_project_stalled": true }
			] 
		  },
		},
		{
		  name: "Abandoned",
		  filter: "abandoned",
		  dropdown_name: "Abandoned",
		  search_value: { 
			$or: [
			  { "pipeline_project_abandoned": true }
			] 
		   },
		}
	  ],
	},
  ];
  
export const _pipelineMapFilters = [
  /*
	{
		name: "Ownership",
		show_name: true,
		search_key: "pipeline_project_components",
		filter_type: "building",
		type : "residential",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: {"$in" : ["condo", "condominium"]},
			},
			{
				name: "Co-op",
				filter: "co-op",
				dropdown_name: "Co-op",
				search_value: {"$in" : ["coop", "co-op", "cooperative"]},
			},
			{
				name: "Rental",
				filter: "rental",
				dropdown_name: "Rental",
				search_value: { "$nin" : [ "condominium", "cooperative", "condo", "coop", "co-op" ] },
			}
			
		]
	},
*/
  {
    name: "Total Units",
    show_name: true,
    search_key: "pipeline_dwelling_units_proposed",
    type: "residential",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1-10 Total Units",
        filter: "1-to-10",
        dropdown_name: "1 to 10 Units",
        search_value: [
          "all",
          [">=", "pipeline_dwelling_units_proposed", 1],
          ["<=", "pipeline_dwelling_units_proposed", 10],
        ],
      },
      {
        name: "11-30 Total Units",
        filter: "11-to-30",
        dropdown_name: "11 to 30 Units",
        search_value: [
          "all",
          [">=", "pipeline_dwelling_units_proposed", 11],
          ["<=", "pipeline_dwelling_units_proposed", 30],
        ],
      },
      {
        name: "31-50 Total Units",
        filter: "31-to-50",
        dropdown_name: "31 to 50 Units",
        search_value: [
          "all",
          [">=", "pipeline_dwelling_units_proposed", 31],
          ["<=", "pipeline_dwelling_units_proposed", 50],
        ],
      },
      {
        name: "51-100 Total Units",
        filter: "51-to-100",
        dropdown_name: "51 to 100 Units",
        search_value: [
          "all",
          [">=", "pipeline_dwelling_units_proposed", 51],
          ["<=", "pipeline_dwelling_units_proposed", 100],
        ],
      },
      {
        name: "101-200 Total Units",
        filter: "101-to-200",
        dropdown_name: "101 to 200 Units",
        search_value: [
          "all",
          [">=", "pipeline_dwelling_units_proposed", 101],
          ["<=", "pipeline_dwelling_units_proposed", 200],
        ],
      },
      {
        name: "201 + Total Units",
        filter: "201-or-more",
        dropdown_name: "201 or more Units",
        search_value: [">", "pipeline_dwelling_units_proposed", 200],
      },
    ],
  },
  {
    name: "Total Floors",
    show_name: true,
    search_key: "pipeline_building_stories_proposed",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1 Floor",
        filter: "1",
        dropdown_name: "1 Floor",
        search_value: ["==", "pipeline_building_stories_proposed", 1],
      },
      {
        name: "2 Floors",
        filter: "2",
        dropdown_name: "2 Floors",
        search_value: ["==", "pipeline_building_stories_proposed", 2],
      },
      {
        name: "3-5 Floors",
        filter: "3-to-5",
        dropdown_name: "3 to 5 Floors",
        search_value: [
          "all",
          [">=", "pipeline_building_stories_proposed", 3],
          ["<=", "pipeline_building_stories_proposed", 5],
        ],
      },
      {
        name: "6-10 Floors",
        filter: "6-to-10",
        dropdown_name: "6 to 10 Floors",
        search_value: [
          "all",
          [">=", "pipeline_building_stories_proposed", 6],
          ["<=", "pipeline_building_stories_proposed", 10],
        ],
      },
      {
        name: "11-20 Floors",
        filter: "11-to-20",
        dropdown_name: "11 to 20 Floors",
        search_value: [
          "all",
          [">=", "pipeline_building_stories_proposed", 11],
          ["<=", "pipeline_building_stories_proposed", 20],
        ],
      },
      {
        name: "21-40 Floors",
        filter: "21-to-40",
        dropdown_name: "21 to 40 Floors",
        search_value: [
          "all",
          [">=", "pipeline_building_stories_proposed", 21],
          ["<=", "pipeline_building_stories_proposed", 40],
        ],
      },
      {
        name: "41+ Floors",
        filter: "41-or-more",
        dropdown_name: "41+ Floors",
        search_value: [">", "pipeline_building_stories_proposed", 40],
      },
    ],
  },
  /*
	{
		name: "Resi Area",
		show_name: true,
		search_key: "pipeline_zoning_area_residential",
		type : "residential",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "25,000 or More SF",
				filter: "25-and-more",
				dropdown_name: "25,000 or More SF",
				search_value: { "$gte" : 25000 }
			},
			{
				name: "5,000 SF or Less",
				filter: "1",
				dropdown_name: "5,000 SF or Less",
				search_value: { "$lte" : 5000 }
			},
			{
				name: "5,001 - 10,000 SF",
				filter: "5-to-10",
				dropdown_name: "5,001 - 10,000 SF",
				search_value: { "$gte" : 5001, "$lte" : 10000 }
			},
			{
				name: "10,001 - 25,000 SF",
				filter: "10-to-25",
				dropdown_name: "10,001 - 20,000 SF",
				search_value: { "$gte" : 10001, "$lte" : 20000 }
			},
			{
				name: "25,001 - 50,000 SF",
				filter: "25-to-50",
				dropdown_name: "25,001 - 50,000 SF",
				search_value: { "$gte" : 20001, "$lte" : 50000 }
			},
			{
				name: "50,001 - 100,000 SF",
				filter: "50-to-100",
				dropdown_name: "50,001 - 100,000 SF",
				search_value: { "$gte" : 50001, "$lte" : 100000 }

			},
			{
				name: "100,001 - 250,000 SF",
				filter: "100-to-250",
				dropdown_name: "100,001 - 250,000 SF",
				search_value: { "$gte" : 100001, "$lte" : 250000 }
			},
			{
				name: "250,001 - 500,000 SF",
				filter: "250-to-500",
				dropdown_name: "250,001 - 500,000 SF",
				search_value: { "$gte" : 250001, "$lte" : 500000 }
			},
			{
				name: "500,001 - 1M SF",
				filter: "500-to-1m",
				dropdown_name: "500,001 - 1M SF",
				search_value: { "$gte" : 500001, "$lte" : 1000000 }
			},
			{
				name: "More Than 1M SF",
				filter: "1m-and-more",
				dropdown_name: "More Than 1M SF",
				search_value: { "$gte" : 1000001 }
			},
		]
	},
*/
  {
    name: "Total Area",
    show_name: true,
    search_key: "pipeline_total_construction_floor_area",
    values: [
      {
        name: "25,000 or More SF",
        filter: "25-and-more",
        dropdown_name: "25,000 or More SF",
        search_value: [">=", "pipeline_total_construction_floor_area", 25000],
      },
      {
        name: "5,000 SF or Less",
        filter: "1",
        dropdown_name: "5,000 SF or Less",
        search_value: ["<=", "pipeline_total_construction_floor_area", 5000],
      },
      {
        name: "5,001 - 10,000 SF",
        filter: "5-to-10",
        dropdown_name: "5,001 - 10,000 SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 5001],
          ["<=", "pipeline_total_construction_floor_area", 10000],
        ],
      },
      {
        name: "10,001 - 25,000 SF",
        filter: "10-to-25",
        dropdown_name: "10,001 - 25,000 SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 10001],
          ["<=", "pipeline_total_construction_floor_area", 25000],
        ],
      },
      {
        name: "25,001 - 50,000 SF",
        filter: "25-to-50",
        dropdown_name: "25,001 - 50,000 SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 25001],
          ["<=", "pipeline_total_construction_floor_area", 50000],
        ],
      },
      {
        name: "50,001 - 100,000 SF",
        filter: "50-to-100",
        dropdown_name: "50,001 - 100,000 SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 50001],
          ["<=", "pipeline_total_construction_floor_area", 100000],
        ],
      },
      {
        name: "100,001 - 250,000 SF",
        filter: "100-to-250",
        dropdown_name: "100,001 - 250,000 SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 100001],
          ["<=", "pipeline_total_construction_floor_area", 250000],
        ],
      },
      {
        name: "250,001 - 500,000 SF",
        filter: "250-to-500",
        dropdown_name: "250,001 - 500,000 SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 250001],
          ["<=", "pipeline_total_construction_floor_area", 500000],
        ],
      },
      {
        name: "500,001 - 1M SF",
        filter: "500-to-1m",
        dropdown_name: "500,001 - 1M SF",
        search_value: [
          "all",
          [">=", "pipeline_total_construction_floor_area", 500001],
          ["<=", "pipeline_total_construction_floor_area", 1000000],
        ],
      },
      {
        name: "More Than 1M SF",
        filter: "1m-and-more",
        dropdown_name: "More Than 1M SF",
        search_value: ["<=", "pipeline_total_construction_floor_area", 1000001],
      },
    ],
  },
  /*
	{
		name: "Permit Filed",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "pipeline_filing_date",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Not Started",
				filter: "not-started",
				dropdown_name: "Not Started",
				search_value: {"$exists" : false},
			},
			{
				name: "Has Started",
				filter: "has-started",
				dropdown_name: "Has Started",
				search_value: { "$gte" : moment().add(-25, "year").toDate().toISOString(), "$lte" : moment().toDate().toISOString() },
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: { "$gte" : moment().add(-1, "year").toDate().toISOString(), "$lte" : moment().toDate().toISOString() },
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: { "$gte" : moment().add(-2, "year").toDate().toISOString(), "$lte" : moment().toDate().toISOString() },
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: { "$gte" : moment().add(-5, "year").toDate().toISOString(), "$lte" : moment().add(-2, "year").toDate().toISOString() },
			},
			{
				name: "5 to 10 Years",
				filter: "5-10-year",
				dropdown_name: "Last 5 to 10 Years",
				search_value: { "$gte" : moment().add(-10, "year").toDate().toISOString(), "$lte" : moment().add(-5, "year").toDate().toISOString() },
			},
			{
				name: "10 to 15 Years",
				filter: "10-15-year",
				dropdown_name: "Last 10 to 15 Years",
				search_value: { "$gte" : moment().add(-15, "year").toDate().toISOString(), "$lte" : moment().add(-10, "year").toDate().toISOString() },
			},
			{
				name: "More Than 15 Years",
				filter: "more-15-year",
				dropdown_name: "More Than 15 Years Ago",
				search_value: { "$gte" : moment().add(-20, "year").toDate().toISOString(), "$lte" : moment().add(-15, "year").toDate().toISOString() },
			}			
		]
	}
*/
];

export const _marketActivityFilters = [
  {
    name: "Price",
    show_name: true,
    search_key: ["min_price", "max_price"],
    price_filter: true,
    search_value: ["price:[{min_price} TO {max_price}]"],
  },
  {
    name: "Bedrooms",
    show_name: true,
    search_key: "bedrooms",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Studio",
        filter: "studio",
        dropdown_name: "Studio",
        search_value: "0",
      },
      {
        name: "1 Bedroom",
        filter: "1-bedroom",
        dropdown_name: "1 Bedroom",
        search_value: "1",
      },
      {
        name: "2 Bedroom",
        filter: "2-bedroom",
        dropdown_name: "2 Bedroom",
        search_value: "2",
      },
      {
        name: "3 Bedroom",
        filter: "3-bedroom",
        dropdown_name: "3 Bedroom",
        search_value: "3",
      },
      {
        name: "4+ Bedroom",
        filter: "4-or-more",
        dropdown_name: "4+ Bedroom",
        search_value: "[4 TO *]",
      },
    ],
  },
  {
    name: "Baths",
    show_name: true,
    search_key: "baths",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1 Bath",
        filter: "1-bath",
        dropdown_name: "1 Bath",
        search_value: "1",
      },
      {
        name: "2 Bath",
        filter: "2-bath",
        dropdown_name: "2 Bath",
        search_value: "2",
      },
      {
        name: "3 Bath",
        filter: "3-bath",
        dropdown_name: "3 Bath",
        search_value: "3",
      },
      {
        name: "4+ Bath",
        filter: "4-or-more",
        dropdown_name: "4+ Bath",
        search_value: "[4 TO *]",
      },
    ],
  },
  {
    name: "Interior SF",
    show_name: true,
    search_key: "sq_ft",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "< 500 ",
        filter: "less-than-500",
        dropdown_name: "Less than 500 SF",
        search_value: "[10 TO 500]",
      },
      {
        name: "501 to 750 SF",
        filter: "501-to-750",
        dropdown_name: "501 to 750 SF",
        search_value: "[501 TO 750]",
      },
      {
        name: "751 to 1,000 SF",
        filter: "751-to-1000",
        dropdown_name: "751 to 1000 SF",
        search_value: "[751 TO 1000]",
      },
      {
        name: "1,001 to 1,500 SF",
        filter: "1001-to-1500",
        dropdown_name: "1,001 to 1,500 SF",
        search_value: "[1001 TO 1500]",
      },
      {
        name: "1,501 to 2,000 SF",
        filter: "1501-to-2000",
        dropdown_name: "1,501 to 2,000 SF",
        search_value: "[1501 TO 2000]",
      },
      {
        name: "2,001 to 3,000 SF",
        filter: "2001-to-3000",
        dropdown_name: "2,001 to 3,000 SF",
        search_value: "[2001 TO 3000]",
      },
      {
        name: "3,000+ SF",
        filter: "3001-or-more",
        dropdown_name: "More than 3000 SF",
        search_value: "[3001 TO *]",
      },
    ],
  },
  {
    name: "Exterior SF",
    show_name: true,
    search_key: "offering_exterior_sq_ft",
    filter_type: "property",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Has Exterior SF",
        filter: "has-exterior-sf",
        dropdown_name: "Has Exterior SF",
        search_value: "[1 TO *]",
      },
      {
        name: "No Exterior SF",
        filter: "no-exterior-sf",
        dropdown_name: "No Exterior SF",
        search_value: "[* TO 0]",
      },
      {
        name: "< 50 ",
        filter: "less-than-50",
        dropdown_name: "Less than 50 SF",
        search_value: "[0 TO 50]",
      },
      {
        name: "50 to 100 SF",
        filter: "50-to-100",
        dropdown_name: "50 to 100 SF",
        search_value: "[51 TO 100]",
      },
      {
        name: "101 to 200 SF",
        filter: "100-to-200",
        dropdown_name: "101 to 200 SF",
        search_value: "[101 TO 200]",
      },
      {
        name: "201 to 500 SF",
        filter: "200-to-500",
        dropdown_name: "201 to 500 SF",
        search_value: "[201 TO 500]",
      },
      {
        name: "500+ SF",
        filter: "500-more",
        dropdown_name: "More than 500 SF",
        search_value: "[501 TO *]",
      },
    ],
  },
  {
    name: "Year Built",
    show_name: true,
    search_key: "year_built",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: `New (After ${new_building_start})`,
        filter: "new-contruction",
        dropdown_name: `New (After ${new_building_start})`,
        search_value: `[ ${new_building_start + 1} TO *]`,
      },
      {
        name: `Recent (1991 to ${new_building_start})`,
        filter: "recent-construction",
        dropdown_name: `Recent (1991 to ${new_building_start})`,
        search_value: `[1991 TO ${new_building_start}]`,
      },
      {
        name: "Post-War (1946 to 1990)",
        filter: "post-war",
        dropdown_name: "Post-War (1946 to 1990)",
        search_value: "[1946 TO 1990]",
      },
      {
        name: "Pre-War (Before 1946)",
        filter: "pre-war",
        dropdown_name: "Pre-War (Before 1946)",
        search_value: "[1600 TO 1945]",
      },
    ],
  },
  {
    name: "Total Units",
    show_name: true,
    search_key: "residential_units",
    filter_type: "building",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1-10 Total Units",
        filter: "1-to-10",
        dropdown_name: "1 to 10 Units",
        search_value: "[1 TO 10]",
      },
      {
        name: "11-30 Total Units",
        filter: "11-to-30",
        dropdown_name: "11 to 30 Units",
        search_value: "[11 TO 30]",
      },
      {
        name: "31-50 Total Units",
        filter: "31-to-50",
        dropdown_name: "31 to 50 Units",
        search_value: "[31 TO 50]",
      },
      {
        name: "51-100 Total Units",
        filter: "51-to-100",
        dropdown_name: "51 to 100 Units",
        search_value: "[51 TO 100]",
      },
      {
        name: "101-200 Total Units",
        filter: "101-to-200",
        dropdown_name: "101 to 200 Units",
        search_value: "[101 TO 200]",
      },
      {
        name: "201 + Total Units",
        filter: "201-or-more",
        dropdown_name: "201 or more Units",
        search_value: "[201 TO *]",
      },
    ],
  },
  {
    name: "Unsold Units",
    show_name: true,
    exclude_stage: ["pipeline"],
    search_key: "inventory_unsold",
    filter_type: "building",
    values: [
      {
        name: "Any Unsold Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1-10 Unsold Units",
        filter: "1-to-10",
        dropdown_name: "1 to 10",
        search_value: "[1 TO 10]",
      },
      {
        name: "11-30 Unsold Units",
        filter: "11-to-30",
        dropdown_name: "11 to 30",
        search_value: "[11 TO 30]",
      },
      {
        name: "31-50 Unsold Units",
        filter: "31-to-50",
        dropdown_name: "31 to 50",
        search_value: "[31 TO 50]",
      },
      {
        name: "51-100 Unsold Units",
        filter: "51-to-100",
        dropdown_name: "51 to 100",
        search_value: "[51 TO 100]",
      },
      {
        name: "101-200 Unsold Units",
        filter: "101-to-200",
        dropdown_name: "101 to 200",
        search_value: "[101 TO 200]",
      },
      {
        name: "201 + Unsold Units",
        filter: "201-or-more",
        dropdown_name: "201 or more",
        search_value: "[201 TO *]",
      },
    ],
  },
  {
    name: "% Sold",
    show_name: true,
    exclude_stage: ["pipeline"],
    search_key: "inventory_pct_sold",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Has Sales",
        filter: "has-sales",
        dropdown_name: "Has Sales",
        search_value: '[1 TO *] OR offering_verified_status:"sold out"',
      },
      {
        name: "Has No Sales",
        filter: "no-sales",
        dropdown_name: "Has No Sales",
        search_value: '[* TO 0] AND -offering_verified_status:"sold out"',
      },
      {
        name: "Less Than 15%",
        filter: "0-to-15",
        dropdown_name: "Less Than 15%",
        search_value: "[0 TO 15]",
      },
      {
        name: "Less Than 35%",
        filter: "0-to-35",
        dropdown_name: "Less Than 35%",
        search_value: "[0 TO 34]",
      },
      {
        name: "Less Than 50%",
        filter: "0-to-49",
        dropdown_name: "Less Than 50%",
        search_value: "[0 TO 49]",
      },
      {
        name: "More Than 15%",
        filter: "15-to-100",
        dropdown_name: "More Than 15%",
        search_value: "[15 TO *]",
      },
      {
        name: "More Than 50%",
        filter: "50-to-100",
        dropdown_name: "More Than 50%",
        search_value: "[50 TO *]",
      },
      {
        name: "50% to 79%",
        filter: "50-to-79",
        dropdown_name: "50% to 79%",
        search_value: "[50 TO 79]",
      },
      {
        name: "80% to 99%",
        filter: "80-to-99",
        dropdown_name: "80% to 99%",
        search_value: "[80 TO 99]",
      },
      {
        name: "Sold Out",
        filter: "100 TO *",
        dropdown_name: "Sold Out",
        search_value: "[100 TO *]",
      },
    ],
  },
  {
    name: "Construction",
    show_name: true,
    search_key: "building_effective_start",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Move-in Ready",
        filter: "completed",
        dropdown_name: "Move-in Ready (Has CO/TCO)",
        search_value:
          '[NOW-25YEARS TO NOW] OR building_status:(resale) OR offering_verified_status:("sold out")',
      },
      {
        name: "Under Construction",
        filter: "not-completed",
        dropdown_name: "Under Construction (No CO/TCO)",
        search_value:
          '[* TO NOW-25YEARS] AND -building_status:(resale) AND -offering_verified_status:("sold out")',
      },
    ],
  },
  {
    name: "Offering Type",
    show_name: true,
    search_key: "offering_construction",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "New Construction",
        filter: "new-construction",
        dropdown_name: "New Construction",
        search_value: "new",
      },
      {
        name: "Conversion",
        filter: "conversion",
        dropdown_name: "Conversion",
        search_value: "conversion",
      },
      {
        name: "Rehab",
        filter: "rehab",
        dropdown_name: "Rehab",
        search_value: "rehab",
      },
      {
        name: "Exclude Conversion",
        filter: "exclude-conversion",
        dropdown_name: "Exclude Conversion",
        search_value: '(new rehab "")',
      },
    ],
  },
  {
    name: "Ownership",
    show_name: true,
    search_key: "ownership_type",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Condo",
        filter: "condo",
        dropdown_name: "Condo",
        search_value: "condo AND -ownership_type:cooperative",
      },
      {
        name: "Co-op",
        filter: "co-op",
        dropdown_name: "Co-op",
        search_value: "coop",
      },
    ],
  },
  {
    name: "Sales Started",
    show_name: true,
    admin_only: true,
    exclude_stage: ["pipeline"],
    search_key: "sales_start_date",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Not Started",
        filter: "not-started",
        dropdown_name: "Not Started",
        search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
      },
      {
        name: "Has Started",
        filter: "has-started",
        dropdown_name: "Has Started",
        search_value: "[NOW-15YEARS TO NOW]",
      },
      {
        name: "Within 1 Year",
        filter: "1-year",
        dropdown_name: "Within 1 Year",
        search_value: "[NOW-1YEARS TO NOW]",
      },
      {
        name: "1 to 2 Years",
        filter: "2-year",
        dropdown_name: "Last 1 to 2 Years",
        search_value: "[NOW-2YEARS TO NOW-1YEARS]",
      },
      {
        name: "2 to 5 Years",
        filter: "2-5-year",
        dropdown_name: "Last 2 to 5 Years",
        search_value: "[NOW-5YEARS TO NOW-2YEARS]",
      },
      {
        name: "5 to 10 Years",
        filter: "5-10-year",
        dropdown_name: "Last 5 to 10 Years",
        search_value: "[NOW-10YEARS TO NOW-5YEARS]",
      },
      {
        name: "10 to 15 Years",
        filter: "10-15-year",
        dropdown_name: "Last 10 to 15 Years",
        search_value: "[NOW-15YEARS TO NOW-10YEARS]",
      },
      {
        name: "More Than 15 Years",
        filter: "more-15-year",
        dropdown_name: "More Than 15 Years Ago",
        search_value: "[NOW-20YEARS TO NOW-15YEARS]",
      },
    ],
  },
  {
    name: "Last Listing",
    show_name: true,
    admin_only: true,
    exclude_stage: ["pipeline"],
    search_key: "last_listed_sale_date_formatted",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "No Activity",
        filter: "no-activity",
        dropdown_name: "No Activity",
        search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
      },
      {
        name: "Any Activity",
        filter: "any-activity",
        dropdown_name: "Any Activity",
        search_value: "[NOW-15YEARS TO NOW]",
      },
      {
        name: "Within 1 Year",
        filter: "1-year",
        dropdown_name: "Within 1 Year",
        search_value: "[NOW-1YEARS TO NOW]",
      },
      {
        name: "1 to 2 Years",
        filter: "2-year",
        dropdown_name: "Last 1 to 2 Years",
        search_value: "[NOW-2YEARS TO NOW-1YEARS]",
      },
      {
        name: "2 to 5 Years",
        filter: "2-5-year",
        dropdown_name: "Last 2 to 5 Years",
        search_value: "[NOW-5YEARS TO NOW-2YEARS]",
      },
      {
        name: "More Than 5 Years",
        filter: "more-5-year",
        dropdown_name: "More Than 5 Years Ago",
        search_value: "[NOW-15YEARS TO NOW-5YEARS]",
      },
    ],
  },
  {
    name: "Last Contract",
    show_name: true,
    admin_only: true,
    exclude_stage: ["pipeline"],
    search_key: "last_contract_date_formatted",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "No Activity",
        filter: "no-activity",
        dropdown_name: "No Activity",
        search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
      },
      {
        name: "Any Activity",
        filter: "any-activity",
        dropdown_name: "Any Activity",
        search_value: "[NOW-15YEARS TO NOW]",
      },
      {
        name: "Within 1 Year",
        filter: "1-year",
        dropdown_name: "Within 1 Year",
        search_value: "[NOW-1YEARS TO NOW]",
      },
      {
        name: "1 to 2 Years",
        filter: "2-year",
        dropdown_name: "Last 1 to 2 Years",
        search_value: "[NOW-2YEARS TO NOW-1YEARS]",
      },
      {
        name: "2 to 5 Years",
        filter: "2-5-year",
        dropdown_name: "Last 2 to 5 Years",
        search_value: "[NOW-5YEARS TO NOW-2YEARS]",
      },
      {
        name: "More Than 5 Years",
        filter: "more-5-year",
        dropdown_name: "More Than 5 Years Ago",
        search_value: "[NOW-15YEARS TO NOW-5YEARS]",
      },
    ],
  },
  {
    name: "Last Sale",
    show_name: true,
    admin_only: true,
    exclude_stage: ["pipeline"],
    search_key: "last_sold_date_formatted",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "No Activity",
        filter: "no-activity",
        dropdown_name: "No Activity",
        search_value:
          '[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -offering_verified_status:"sold out"',
      },
      {
        name: "Any Activity",
        filter: "any-activity",
        dropdown_name: "Any Activity",
        search_value:
          '[NOW-15YEARS TO NOW] OR offering_verified_status:"sold out"',
      },
      {
        name: "Within 1 Year",
        filter: "1-year",
        dropdown_name: "Within 1 Year",
        search_value: "[NOW-1YEARS TO NOW]",
      },
      {
        name: "1 to 2 Years",
        filter: "2-year",
        dropdown_name: "Last 1 to 2 Years",
        search_value: "[NOW-2YEARS TO NOW-1YEARS]",
      },
      {
        name: "2 to 5 Years",
        filter: "2-5-year",
        dropdown_name: "Last 2 to 5 Years",
        search_value: "[NOW-5YEARS TO NOW-2YEARS]",
      },
      {
        name: "More Than 5 Years",
        filter: "more-5-year",
        dropdown_name: "More Than 5 Years Ago",
        search_value: "[NOW-15YEARS TO NOW-5YEARS]",
      },
    ],
  },
  {
    name: "Condo Dec",
    show_name: true,
    exclude_stage: ["pipeline"],
    search_key: "cdec_date",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "No Condo Dec",
        filter: "no-cdec",
        dropdown_name: "No Condo Dec",
        search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
      },
      {
        name: "Has Condo Dec",
        filter: "has-cdec",
        dropdown_name: "Has Condo Dec",
        search_value: "[NOW-15YEARS TO NOW]",
      },
    ],
  },
  {
    name: "Offering Status",
    show_name: true,
    admin_only: true,
    search_key: "offering_submitted_date",
    filter_type: "building",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "All Submitted",
        filter: "submitted",
        dropdown_name: "All Submitted",
        search_value: "[NOW-25YEARS TO NOW]",
      },
      {
        name: "All Accepted",
        filter: "accepted",
        dropdown_name: "All Accepted",
        search_value:
          "[NOW-25YEARS TO NOW] AND offering_accepted_date:[NOW-25YEARS TO NOW]",
      },
      {
        name: "All Effective",
        filter: "effective",
        dropdown_name: "All Effective",
        search_value:
          "[NOW-25YEARS TO NOW] AND offering_effective_date:[NOW-25YEARS TO NOW]",
      },
      {
        name: "Only Submitted",
        filter: "submitted-only",
        dropdown_name: "Only Submitted",
        search_value:
          "[NOW-25YEARS TO NOW] AND offering_accepted_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
      },
      {
        name: "Only Accepted",
        filter: "accepted-only",
        dropdown_name: "Only Accepted",
        search_value:
          "[NOW-25YEARS TO NOW] AND offering_accepted_date:[NOW-25YEARS TO NOW] AND offering_effective_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
      },
      {
        name: "Only Effectiv Only",
        filter: "effective-only",
        dropdown_name: "Only Effective",
        search_value:
          "[NOW-25YEARS TO NOW] AND offering_effective_date:[NOW-25YEARS TO NOW]",
      },
    ],
  },
];

export const _pipeLineFilter = [
  /*	{
		name: "Stage",
		show_name: true,
		search_key: "event_tags",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Permit Filed",
				filter: "permit-filed",
				dropdown_name: "Permit Filed",
				search_value: "permit-filing",
			},
			{
				name: "Offering Submitted",
				filter: "offering-submitted",
				dropdown_name: "Offering Submitted",
				search_value: "submitted",
			},
			{
				name: "Offering Accepted",
				filter: "offering-accepted",
				dropdown_name: "Offering Accepted",
				search_value: "accepted",
			},
			{
				name: "Offering Effective",
				filter: "offering-effective",
				dropdown_name: "Offering Effective",
				search_value: "effective",
			},
			
		]
	},	*/
  {
    name: "Type",
    show_name: true,
    multi_filter: true,
    search_key: "topic",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Construction",
        filter: "new-building",
        dropdown_name: "New Building",
        search_value: "permits AND event_tags:(nb a1)",
      },
      {
        name: "Demolition",
        filter: "demolition",
        dropdown_name: "Demolition",
        search_value: "permits AND event_tags:dm",
      },
     {
       name: "Offering Plan",
       filter: "offering-submitted",
       dropdown_name: "Offering Plan",
       search_value: "offeringplans",
     },
      
      {
        name: "Certificate of Occupancy",
        filter: "coo",
        dropdown_name: "Certificate of Occupancy",
        search_value: "coo",
      }
    ],
  },
  {
    name: "Subtype",
    show_name: true,
    multi_filter: true,
    search_key: "event_tags",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "New Building Permit",
        filter: "new-building",
        dropdown_name: "New Building",
        search_value: "nb AND topic:permits",
      },
      {
        name: "Major Alteration Permit",
        filter: "major-alteration",
        dropdown_name: "Major Alteration",
        search_value: "a1 AND topic:permits",
      },
      {
        name: "Demolition Permit",
        filter: "demolition",
        dropdown_name: "Demolition",
        search_value: "dm AND topic:permits",
      },
      {
        name: "Offering Submitted",
        filter: "offering-submitted",
        dropdown_name: "Demolition",
        search_value: "submitted AND topic:offeringplans",
      },
      {
        name: "Offering Accepted",
        filter: "offering-accepted",
        dropdown_name: "Offering Accepted",
        search_value: "accepted AND topic:offeringplans",
      },
      {
        name: "Offering Effective",
        filter: "offering-effective",
        dropdown_name: "Offering Effective",
        search_value: "effective AND topic:offeringplans",
      },
     {
       name: "Final CO",
       filter: "coo-final",
       dropdown_name: "Final CO",
       search_value: "coo-final",
     },
      {
        name: "Temporary CO",
        filter: "coo-temporary",
        dropdown_name: "Temporary CO",
        search_value: "coo-temporary",
      }
    ],
  },
  {
    name: "Total Units",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "units",
    search_key: ["min_units", "max_units"],
    labels: ["Min Units", "Max Units"],
    min_max_values: [25, 200],
    min_max_increment: 25,
    custom_min_max_values: {
      min: [1, 11, 31, 51, 101, 201],
      max: [10, 30, 50, 100, 200, 300],
    },
    format_type: "number",
    search_value: [
      "units:[{min_units} TO {max_units}]",
    ],
    value_prefix: "",
    title_suffix: "Units",
    // search_key: "units",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "3-10 Units",
        filter: "3-to-10",
        dropdown_name: "3 to 10 Units",
        search_value: "[3 TO 10]",
      },
      {
        name: "11-30 Units",
        filter: "11-to-30",
        dropdown_name: "11 to 30 Units",
        search_value: "[11 TO 30]",
      },
      {
        name: "31-50 Units",
        filter: "31-to-50",
        dropdown_name: "31 to 50 Units",
        search_value: "[31 TO 50]",
      },
      {
        name: "51-100 Units",
        filter: "51-to-100",
        dropdown_name: "51 to 100 Units",
        search_value: "[51 TO 100]",
      },
      {
        name: "101-200 Units",
        filter: "101-to-200",
        dropdown_name: "101 to 200 Units",
        search_value: "[101 TO 200]",
      },
      {
        name: "201 + Units",
        filter: "201-or-more",
        dropdown_name: "201 or more Units",
        search_value: "[201 TO *]",
      },
    ],
  },
  {
    name: "Total Floors",
    show_name: true,
    price_filter: true,
    value_contains_key: true,
    filter_key: "floor",
    search_key: ["min_floors", "max_floors"],
    labels: ["Min Floors", "Max Floors"],
    min_max_values: [1, 41],
    min_max_increment: 10,
    custom_min_max_values: {
      min: [1, 6, 11, 21, 41],
      max: [5, 10, 20, 40],
    },
    format_type: "number",
    search_value: [
      "floor:[{min_floors} TO {max_floors}]",
    ],
    value_prefix: "",
    title_suffix: "Floors",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1-5 Floors",
        filter: "1-to-10",
        dropdown_name: "1 to 5 Floors",
        search_value: "[1 TO 5]",
      },
      {
        name: "6-10 Floors",
        filter: "6-to-10",
        dropdown_name: "6 to 10 Floors",
        search_value: "[6 TO 10]",
      },
      {
        name: "11-20 Floors",
        filter: "11-to-20",
        dropdown_name: "11 to 20 Floors",
        search_value: "[11 TO 20]",
      },
      {
        name: "21-30 Floors",
        filter: "21-to-30",
        dropdown_name: "21 to 30 Floors",
        search_value: "[21 TO 30]",
      },
      {
        name: "31-40 Floors",
        filter: "31-to-40",
        dropdown_name: "31 to 40 Floors",
        search_value: "[31 TO 40]",
      },
      {
        name: "40+ Floors",
        filter: "40-or-more",
        dropdown_name: "41 or More Floors",
        search_value: "[41 TO *]",
      },
    ],
  },
  {
    name: "Total Area",
    show_name: true,
    // search_key: "sq_ft",
    labels: ["Min Area", "Max Area"],
    price_filter: true,
    value_contains_key: true,
    filter_key: "sq_ft",
    search_key: ["min_total_ft", "max_total_ft"],
    min_max_values: [5000, 100000],
    min_max_increment: 500,
    custom_min_max_values: {
      min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
      max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
    },
    format_type: "number",
    search_value: [
      "sq_ft:[{min_total_ft} TO {max_total_ft}]",
    ],
    value_prefix: "",
    title_suffix: "SF",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "25,000 or More SF",
        filter: "25-and-more",
        dropdown_name: "25,000 or More SF",
        search_value: "[25000 TO *]",
      },
      {
        name: "5,000 SF or Less",
        filter: "1",
        dropdown_name: "5,000 SF or Less",
        search_value: "[* TO 5000]",
      },
      {
        name: "5,001 - 10,000 SF",
        filter: "5-to-10",
        dropdown_name: "5,001 - 10,000 SF",
        search_value: "[5001 TO 10000]",
      },
      {
        name: "10,001 - 25,000 SF",
        filter: "10-to-25",
        dropdown_name: "10,001 - 25,000 SF",
        search_value: "[10001 TO 25000]",
      },
      {
        name: "25,001 - 50,000 SF",
        filter: "25-to-50",
        dropdown_name: "25,001 - 50,000 SF",
        search_value: "[25001 TO 50000]",
      },
      {
        name: "50,001 - 100,000 SF",
        filter: "50-to-100",
        dropdown_name: "50,001 - 100,000 SF",
        search_value: "[50001 TO 100000]",
      },
      {
        name: "100,001 - 250,000 SF",
        filter: "100-to-250",
        dropdown_name: "100,001 - 250,000 SF",
       search_value: "[100001 TO 250000]",
      },
      {
        name: "250,001 - 500,000 SF",
        filter: "250-to-500",
        dropdown_name: "250,001 - 500,000 SF",
        search_value: "[250001 TO 500000]",
      },
      {
        name: "500,001 - 1M SF",
        filter: "500-to-1m",
        dropdown_name: "500,001 - 1M SF",
        search_value: "[500001 TO 1000000]",
      },
      {
        name: "More Than 1M SF",
        filter: "1m-and-more",
        dropdown_name: "More Than 1M SF",
        search_value: "[1000000 TO *]",
      },
    ],
  },
  {
    name: "Activity Date",
    show_name: "true",
    search_key: ["start_date", "end_date"],
    date_filter: true,
    search_value: ["time:[{start_date} TO {end_date}]"],
  }
  
 /* {
    name: "Offering Type",
    show_name: true,
    search_key: "event_tags",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "New Condo",
        filter: "new-condo",
        dropdown_name: "New Condo",
        search_value: "new-condo",
      },
      {
        name: "Condo Conversion",
        filter: "condo-conversion",
        dropdown_name: "Condo Conversion",
        search_value: "condo-conversion",
      },
      {
        name: "New Co-op",
        filter: "new-coop",
        dropdown_name: "New Co-op",
        search_value: "new-coop",
      },
      {
        name: "Co-op Conversion ",
        filter: "coop-conversion",
        dropdown_name: "Co-op Conversion",
        search_value: "coop-conversion",
      },
    ],
  },
  {
    name: "Offering Sellout",
    show_name: true,
    search_key: "price",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Under $10M",
        filter: "under-10m",
        dropdown_name: "Under $10M",
        search_value: "[* TO 10000000]",
      },
      {
        name: "$10M to $50M",
        filter: "10-to-50",
        dropdown_name: "$10M to $50M",
        search_value: "[10000000 TO 50000000]",
      },
      {
        name: "$50M to $100M",
        filter: "50-to-100",
        dropdown_name: "$50M to $100M",
        search_value: "[50000000 TO 100000000]",
      },
      {
        name: "$100M to $200M",
        filter: "100-to-200",
        dropdown_name: "$100M to $200M",
        search_value: "[100000000 TO 200000000]",
      },
      {
        name: "More Than $200M",
        filter: "more-than-200",
        dropdown_name: "More Than $200M",
        search_value: "[200000000 TO *]",
      },
    ],
  },*/
];

export const _buildingAdminFiltersRental = [
  {
    name: "Borough",
    show_name: true,
    default_name: "All Boroughs",
    search_key: "borough",
    values: [
      {
        name: "All Boroughs",
        filter: "",
        dropdown_name: "All Boroughs",
        search_value: "",
      },
      {
        name: "Manhattan",
        filter: "manhattan",
        dropdown_name: "Manhattan",
        search_value: "manhattan",
      },
      {
        name: "Brooklyn",
        filter: "brooklyn",
        dropdown_name: "Brooklyn",
        search_value: "brooklyn",
      },
      {
        name: "Queens",
        filter: "queens",
        dropdown_name: "Queens",
        search_value: "queens",
      },
      {
        name: "Bronx",
        filter: "bronx",
        dropdown_name: "Bronx",
        search_value: "bronx",
      },
      {
        name: "Staten Island",
        filter: "staten-island",
        dropdown_name: "Staten Island",
        search_value: "staten-island",
      },
    ],
  },
  {
    name: "Type",
    show_name: true,
    search_key: "ownership_type",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Hybrid - Condo",
        filter: "hybrid",
        dropdown_name: "Hybrid - Condo",
        search_value:
          "* AND ((ownership_type:*condo* AND ownership_occupancy_sub_types:*rental*) OR (ownership_occupancy_sub_types:*condo* AND ownership_type:*rental*))",
      },
      {
        name: "OP Filed",
        filter: "op-filed",
        dropdown_name: "Offering Plan Filed",
        search_value: "* AND (offering_submitted_date:[NOW-15YEARS TO NOW])",
      },
    ],
  },
  {
    name: "Total Floors",
    show_name: true,
    search_key: "total_floors",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1-5 Floors",
        filter: "1-to-10",
        dropdown_name: "1 to 5 Floors",
        search_value: "[1 TO 5]",
      },
      {
        name: "6-10 Floors",
        filter: "6-to-10",
        dropdown_name: "6 to 10 Floors",
        search_value: "[6 TO 10]",
      },
      {
        name: "11-20 Floors",
        filter: "11-to-20",
        dropdown_name: "11 to 20 Floors",
        search_value: "[11 TO 20]",
      },
      {
        name: "21-30 Floors",
        filter: "21-to-30",
        dropdown_name: "21 to 30 Floors",
        search_value: "[21 TO 30]",
      },
      {
        name: "31-40 Floors",
        filter: "31-to-40",
        dropdown_name: "31 to 40 Floors",
        search_value: "[31 TO 40]",
      },
      {
        name: "40+ Floors",
        filter: "40-or-more",
        dropdown_name: "41 or More Floors",
        search_value: "[41 TO *]",
      },
    ],
  },
  /*
	{
		name: "Construction",
		show_name: true,
		search_key: "building_effective_start",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction",
				search_value: "[* TO NOW-15YEARS]",
			}
			
		]
	},
*/
  {
    name: "Total Units",
    show_name: true,
    search_key: "residential_units",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "1-10 Total Units",
        filter: "1-to-10",
        dropdown_name: "1 to 10 Units",
        search_value: "[1 TO 10]",
      },
      {
        name: "11-30 Total Units",
        filter: "11-to-30",
        dropdown_name: "11 to 30 Units",
        search_value: "[11 TO 30]",
      },
      {
        name: "31-50 Total Units",
        filter: "31-to-50",
        dropdown_name: "31 to 50 Units",
        search_value: "[31 TO 50]",
      },
      {
        name: "51-100 Total Units",
        filter: "51-to-100",
        dropdown_name: "51 to 100 Units",
        search_value: "[51 TO 100]",
      },
      {
        name: "101-200 Total Units",
        filter: "101-to-200",
        dropdown_name: "101 to 200 Units",
        search_value: "[101 TO 200]",
      },
      {
        name: "201 + Total Units",
        filter: "201-or-more",
        dropdown_name: "201 or more Units",
        search_value: "[201 TO *]",
      },
    ],
  },
];

export const _buildingStageFilter = [
  {
    search_key: "building_stage",
    type: "array",
    values: [
      {
        filter: "pipeline",
      },
      {
        filter: "sponsor",
      },
      {
        filter: "resale",
      },
      {
        filter: "all",
      },
    ],
  },
];

export const _unitStageFilter = [
  {
    search_key: "unit_stage",
    type: "array",
    values: [
      {
        filter: "sponsor",
      },
      {
        filter: "resale",
      },
    ],
  },
];

export const _unitSalesFilter = [
  {
    search_key: "ownership_type",
    type: "array",
    values: [
      {
        filter: "sales",
      },
    ],
  },
];

export const _unitMapListFilter = [
  {
    search_key: "viewType",
    type: "array",
    values: [
      {
        filter: "map",
      },
      {
        filter: "list",
      },
    ],
  },
]
export const _salesFilters = [
  {
    name: "Property Type",
    show_name: true,
    search_key: "ownership_type",
    multi_filter: true,
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Vacant Land",
        filter: "vacant_land",
        dropdown_name: "Vacant Land",
        search_value: "(vacant?land)",
      },
      {
        name: "Condo / Coop Buildings",
        filter: "condo_crop_building",
        dropdown_name: "Condo / Coop Buildings",
        search_value:
          "condo co?op coop",
      },
      {
        name: "Rental Buildings",
        filter: "rental_buildings",
        dropdown_name: "Rental Buildings",
        search_value:
          "(rental apartment)",
      },
      {
        name: "Houses",
        filter: "houses",
        dropdown_name: "Houses",
        search_value:
          "townhouse single?family multi?family two?family three?family four?family five?to?six?family",
      },
      {
        name: "Commercial",
        filter: "commercial",
        dropdown_name: "Commercial",
        search_value:
          "commercial office retail public?facilities industrial vacant?land parking garage hotel",
      },
      {
        name: "Public Facilities",
        filter: "public_facilities",
        dropdown_name: "Public Facilities",
        search_value: "public?facilities religious",
      },
    ],
  },
  
  /*	{
		name: "Sold Date",
		show_name: true,
		search_key: "close_date_formatted",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Last 3 Months",
				filter: "3-month",
				dropdown_name: "Last 3 Months",
				search_value: "[NOW-3MONTHS TO NOW]",
			},
			{
				name: "Last 6 Months",
				filter: "6-month",
				dropdown_name: "Last 6 Months",
				search_value: "[NOW-6MONTHS TO NOW]",
			},
			{
				name: "Last 1 Year",
				filter: "1-year",
				dropdown_name: "Last 1 Year",
				search_value: "[NOW-1YEAR TO NOW]",
			},
			{
				name: "Last 2 Years",
				filter: "2-year",
				dropdown_name: "Last 2 Year",
				search_value: "[NOW-2YEAR TO NOW]",
			},
			{
				name: "Last 5 Years",
				filter: "5-year",
				dropdown_name: "Last 5 Year",
				search_value: "[NOW-5YEAR TO NOW]",
			},
			{
				name: "1 - 2 Years",
				filter: "1-2-year",
				dropdown_name: "1 - 2 Years",
				search_value: "[NOW-2YEAR TO NOW-1YEAR]",
			},
			{
				name: "2 - 5 Years",
				filter: "2-5-year",
				dropdown_name: "2 - 5 Years",
				search_value: "[NOW-5YEAR TO NOW-2YEAR]",
			},
			{
				name: "5 - 10 Years",
				filter: "5-10-year",
				dropdown_name: "5 - 10 Years",
				search_value: "[NOW-10YEAR TO NOW-5YEAR]",
			},
			{
				name: "More Than 10 Years",
				filter: "more-10-year",
				dropdown_name: "More Than 10 Years",
				search_value: "[* TO NOW-10YEAR]",
			},
		]
	},*/
  {
    name: "Sold Date",
    show_name: true,
    search_key: ["min_date", "max_date"],
    date_filter: true,
    search_value: ["close_date_formatted:[{min_date} TO {max_date}]"],
  },
  {
    name: "Price",
    show_name: true,
    value_contains_key: true,
    filter_key: "price",
    format_type: "number",
    search_key: ["min_price", "max_price"],
    price_filter: true,
    search_value: ["price:[{min_price} TO {max_price}]"],
  },
  // {
  //   name: "Bedrooms",
  //   show_name: true,
  //   search_key: "bedrooms",
  //   values: [
  //     {
  //       name: "Any",
  //       filter: "",
  //       dropdown_name: "Any",
  //       search_value: "",
  //     },
  //     {
  //       name: "Studio",
  //       filter: "studio",
  //       dropdown_name: "Studio",
  //       search_value: "0",
  //     },
  //     {
  //       name: "1 Bedroom",
  //       filter: "1-bedroom",
  //       dropdown_name: "1 Bedroom",
  //       search_value: "1",
  //     },
  //     {
  //       name: "2 Bedroom",
  //       filter: "2-bedroom",
  //       dropdown_name: "2 Bedroom",
  //       search_value: "2",
  //     },
  //     {
  //       name: "3 Bedroom",
  //       filter: "3-bedroom",
  //       dropdown_name: "3 Bedroom",
  //       search_value: "3",
  //     },
  //     {
  //       name: "4+ Bedroom",
  //       filter: "4-or-more",
  //       dropdown_name: "4+ Bedroom",
  //       search_value: "[4 TO *]",
  //     },
  //   ],
  // },
  // {
  //   name: "Baths",
  //   show_name: true,
  //   search_key: "baths",
  //   values: [
  //     {
  //       name: "Any",
  //       filter: "",
  //       dropdown_name: "Any",
  //       search_value: "",
  //     },
  //     {
  //       name: "1 Bath",
  //       filter: "1-bath",
  //       dropdown_name: "1 Bath",
  //       search_value: "1",
  //     },
  //     {
  //       name: "2 Bath",
  //       filter: "2-bath",
  //       dropdown_name: "2 Bath",
  //       search_value: "2",
  //     },
  //     {
  //       name: "3 Bath",
  //       filter: "3-bath",
  //       dropdown_name: "3 Bath",
  //       search_value: "3",
  //     },
  //     {
  //       name: "4+ Bath",
  //       filter: "4-or-more",
  //       dropdown_name: "4+ Bath",
  //       search_value: "[4 TO *]",
  //     },
  //   ],
  // },
  {
    name: "Sq Ft",
    show_name: true,
    labels: ["Min SqFt", "Max SqFt"],
    price_filter: true,
    value_contains_key: true,
    filter_key: "sq_ft",
    search_key: ["min_sq_ft", "max_sq_ft"],
    min_max_values: [500, 3000],
    min_max_increment: 250,
    custom_min_max_values: {
      min: [0, 5001, 10001, 25001, 50001, 100001, 250001, 500001, 1000001],
      max: [5000, 10000, 25000, 50000, 100000, 250000, 500000, 500001],
    },
    format_type: "number",
    search_value: [
      "sq_ft:[{min_sq_ft} TO {max_sq_ft}]",
    ],
    value_prefix: "",
    title_suffix: "SF",
    // search_key: "offering_sq_ft",
    values: [
      {
        name: "Any Units",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "< 500 ",
        filter: "less-than-500",
        dropdown_name: "Less than 500 SF",
        search_value: "[10 TO 500] OR sq_ft:[10 TO 500] ",
      },
      {
        name: "501 to 750 SF",
        filter: "501-to-750",
        dropdown_name: "501 to 750 SF",
        search_value: "[501 TO 750] OR sq_ft:[501 TO 750] ",
      },
      {
        name: "751 to 1,000 SF",
        filter: "751-to-1000",
        dropdown_name: "751 to 1000 SF",
        search_value: "[751 TO 1000] OR sq_ft:[751 TO 1000]",
      },
      {
        name: "1,001 to 1,500 SF",
        filter: "1001-to-1500",
        dropdown_name: "1,001 to 1,500 SF",
        search_value: "[1001 TO 1500] OR sq_ft:[1001 TO 1500]",
      },
      {
        name: "1,501 to 2,000 SF",
        filter: "1501-to-2000",
        dropdown_name: "1,501 to 2,000 SF",
        search_value: "[1501 TO 2000] OR sq_ft:[1501 TO 2000]",
      },
      {
        name: "2,001 to 3,000 SF",
        filter: "2001-to-3000",
        dropdown_name: "2,001 to 3,000 SF",
        search_value: "[2001 TO 3000] OR sq_ft:[2001 TO 3000]",
      },
      {
        name: "3,000+ SF",
        filter: "3001-or-more",
        dropdown_name: "More than 3000 SF",
        search_value: "[3001 TO *] OR sq_ft:[3001 TO *]",
      },
    ],
  },
  {
    name: "Sold PPSF",
    show_name: true,
    // search_key: "price_per_sqft",
    show_name: true,
    labels: ["Min PPSF", "Max PPSF"],
    price_filter: true,
    value_contains_key: true,
    filter_key: "price_per_sqft",
    search_key: ["min_ppsf", "max_ppsf"],
    min_max_values: [500, 3000],
    min_max_increment: 250,
    custom_min_max_values: {
      min: [0, 101, 251, 501, 751, 1001, 1501, 2001, 2501, 5001],
      max: [100, 250, 500, 750, 1000, 1500, 2000, 2500, 5000],
    },
    format_type: "number",
    search_value: [
      "price_per_sqft:[{min_ppsf} TO {max_ppsf}]",
    ],
    value_prefix: "$",
    title_suffix: ""
  },
  {
    name: "Transfer Type",
    show_name: true,
    multi_filter: true,
    search_key: "parcels_count",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Market Transfer",
        filter: "market-transfer",
        dropdown_name: "Market Transfer",
        search_value: "* AND (price:[5001 TO *] OR price:[* TO -1])",
      },

      {
        name: "Non-Market Transfer",
        filter: "non-market",
        dropdown_name: "Non-Market Transfer",
        search_value: "* AND price:[* TO 5000]",
      },
      {
        name: "Bulk Transfer (Any)",
        filter: "bulk-transfer",
        dropdown_name: "Bulk Transfer (Any)",
        search_value:
          "[2 TO *] AND parcels_count:[* TO 0]) OR (parcels_count:[2 TO *]",
      },
      {
        name: "Bulk (2 to 5 Parcels)",
        filter: "bulk-transfer-2-to-5",
        dropdown_name: "Bulk  (2 to 5 Parcels)",
        search_value:
          "[2 TO 5] AND parcels_count:[* TO 0]) OR (parcels_count:[2 TO 5]",
      },
      {
        name: "Bulk (6 to 10 Parcels)",
        filter: "bulk-transfer-6-to-5",
        dropdown_name: "Bulk  (6 to 10 Parcels)",
        search_value:
          "[6 TO 10] AND parcels_count:[* TO 0]) OR (parcels_count:[6 TO 10]",
      },
      {
        name: "Bulk (11 to 20 Parcels)",
        filter: "bulk-transfer-11-to-20",
        dropdown_name: "Bulk  (11 to 20 Parcels)",
        search_value:
          "[11 TO 20] AND parcels_count:[* TO 0]) OR (parcels_count:[11 TO 20]",
      },
      {
        name: "Bulk (More Than 20 Parcels)",
        filter: "bulk-transfer-more-20",
        dropdown_name: "Bulk (More Than 20 Parcels)",
        search_value:
          "[21 TO *] AND parcels_count:[* TO 0]) OR (parcels_count:[21 TO *]",
      },
    ],
  },
 
  // {
  //   name: "Ownership",
  //   show_name: true,
  //   search_key: "ownership_type",
  //   values: [
  //     {
  //       name: "Any",
  //       filter: "",
  //       dropdown_name: "Any",
  //       search_value: "",
  //     },
  //     {
  //       name: "Condo",
  //       filter: "condo",
  //       dropdown_name: "Condo",
  //       search_value: "condo AND -ownership_type:cooperative",
  //     },
  //     {
  //       name: "Co-op",
  //       filter: "co-op",
  //       dropdown_name: "Co-op",
  //       search_value: "coop",
  //     },
  //   ],
  // },
  // {
  //   name: "Discount",
  //   show_name: true,
  //   search_key: "discount",
  //   values: [
  //     {
  //       name: "Any",
  //       filter: "",
  //       dropdown_name: "Any",
  //       search_value: "",
  //     },
  //     {
  //       name: "Has Discount",
  //       filter: "has-discount",
  //       dropdown_name: "Has Discount",
  //       search_value: "[-90 TO -.01]",
  //     },
  //     {
  //       name: "1% to 3%",
  //       filter: "1-to-3",
  //       dropdown_name: "1% to 3%",
  //       search_value: "[-3 TO -1]",
  //     },
  //     {
  //       name: "3% to 5%",
  //       filter: "3-to-5",
  //       dropdown_name: "3% to 5%",
  //       search_value: "[-5 TO -3]",
  //     },
  //     {
  //       name: "5% to 10%",
  //       filter: "5-to-10",
  //       dropdown_name: "5% to 10%",
  //       search_value: "[-10 TO -5]",
  //     },
  //     {
  //       name: "10% to 15%",
  //       filter: "10-to-15",
  //       dropdown_name: "10% to 15%",
  //       search_value: "[-15 TO -10]",
  //     },
  //     {
  //       name: "More Than 15%",
  //       filter: "more-15",
  //       dropdown_name: "More Than 15%",
  //       search_value: "[-90 TO -15]",
  //     },
  //     {
  //       name: "Sold for More Than Asking",
  //       filter: "more-than",
  //       dropdown_name: "Sold for More Than Asking",
  //       search_value: "[.01 TO 90]",
  //     },
  //   ],
  // },
  {
    name: "% Financed",
    show_name: true,
    // search_key: "percent_financed",
    labels: ["Min %", "Max %"],
    price_filter: true,
    value_contains_key: true,
    filter_key: "percent_financed",
    search_key: ["min_financed", "max_financed"],
    min_max_values: [1, 100],
    min_max_increment: 10,
    custom_min_max_values: {
      min: [1, 31, 51, 71, 81, 91],
      max: [30, 50, 70, 80, 90, 100],
    },
    format_type: "number",
    search_value: [
      "percent_financed:[{min_financed} TO {max_financed}]",
    ],
    value_prefix: "",
    value_suffix : "%",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Has Financing",
        filter: "has-financing",
        dropdown_name: "Has Financing",
        search_value: "[.01 TO 100]",
      },
      {
        name: "90% to 100%",
        filter: "90-to-100",
        dropdown_name: "90% to 100%",
        search_value: "[90 TO 100]",
      },
      {
        name: "80% to 90%",
        filter: "80-to-90",
        dropdown_name: "80% to 90%",
        search_value: "[80 TO 90]",
      },
      {
        name: "70% to 80%",
        filter: "70-to-80",
        dropdown_name: "70% to 80%",
        search_value: "[70 TO 80]",
      },
      {
        name: "50% to 70%",
        filter: "50-to-70",
        dropdown_name: "50% to 70%",
        search_value: "[50 TO 70]",
      },
      {
        name: "30% to 50%",
        filter: "30-to-50",
        dropdown_name: "30% to 50%",
        search_value: "[30 TO 50]",
      },
      {
        name: "Less than 30%",
        filter: "1-to-30",
        dropdown_name: "Less than 30%",
        search_value: "[1 TO 30]",
      },
    ],
  },
  {
    name: "Owner Type",
    show_name: true,
    search_key: "type",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Original Owner",
        filter: "original",
        dropdown_name: "Original Owner",
        search_value: "* AND owner:* AND sponsored_sale:false",
      },
      {
        name: "Corporate Owner",
        filter: "corporate",
        dropdown_name: "Corporate Owner",
        search_value:
          "* AND owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)",
      },
    ],
  },

  {
    name: "Sale Includes",
    show_name: true,
    search_key: "parcels_property_types",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: "",
      },
      {
        name: "Includes Parking",
        filter: "parking",
        dropdown_name: "Includes Parking",
        search_value: "ps",
      },
      {
        name: "Includes Storage",
        filter: "storage",
        dropdown_name: "Includes Storage",
        search_value: "sr",
      },
      {
        name: "Includes Parking & Storage",
        filter: "parking-storage",
        dropdown_name: "Includes Parking & Storage",
        search_value: "ps AND parcels_property_types:sr",
      },
      {
        name: "Excludes Parking",
        filter: "exclude-parking",
        dropdown_name: "Excludes Parking",
        search_value: "* AND -parcels_property_types:ps",
      },
      {
        name: "Excludes Storage",
        filter: "exclude-storage",
        dropdown_name: "Excludes Storage",
        search_value: "* AND -parcels_property_types:sr",
      },
      {
        name: "Excludes Parking & Storage",
        filter: "exclude-parking-storage",
        dropdown_name: "Excludes Parking & Storage",
        search_value:
          "* AND -parcels_property_types:ps AND -parcels_property_types:sr",
      },
    ],
  },
  {
    name: "Buyer Name",
    show_name: true,
    search_key: "owner",
    text_filter: true,
  },
  {
    name: "Seller Name",
    show_name: true,
    search_key: "seller",
    text_filter: true,
  },
  {
    name: "Lender Name",
    show_name: true,
    search_key: "lender",
    text_filter: true,
  },
];

export const _reportFilters = [
  {
    name: "Share Type",
    show_name: true,
    search_key: "share_type",
    values: [
      {
        name: "Any",
        filter: "",
        dropdown_name: "Any",
        search_value: ""
      },
      {
        name: "Projects",
        filter: "projects",
        dropdown_name: "Projects",
        search_value: {"$and" : [ {"report_type" : "single-project"} ]}
      },
      {
        name: "Buildings",
        filter: "buildings",
        dropdown_name: "Buildings",
        search_value: {"$and" : [ {"report_type" : "existing-building"} ]}
      },
      {
        name: "Collections",
        filter: "collections",
        dropdown_name: "Collections",
        search_value: {"report_type" : {"$in" : ["comp", "grid"]}},
      }	
    ]
  }];
  
  
  export const _permitFilters = [
    {
      name: "Filing Date",
      show_name: true,
      search_key: ["filed_min_date", "filed_max_date"],
      filter_key: 'filing_date',
      date_filter: true,
      search_value: ["filing_date:[{filed_min_date} TO {filed_min_date}]"],
    },
    {
      name: "Issuance Date",
      show_name: true,
      search_key: ["min_date", "max_date"],
      filter_key: 'issuance_date',
      date_filter: true,
      search_value: ["issuance_date:[{min_date} TO {max_date}]"],
    },
    {
      name: "Job Type",
      show_name: true,
      search_key: "job_type",
      multi_filter: true,
      values: [
        {
          name: "Any",
          filter: "",
          dropdown_name: "Any",
          search_value: "",
        },
        {
          name: "New Building",
          filter: "nb",
          dropdown_name: "New Building",
          search_value: "(nb 5 6)",
        },
        {
          name: "Demolition",
          filter: "dm",
          dropdown_name: "Demolition",
          search_value: "(dm 7)",
        },
        {
          name: "Major Alteration",
          filter: "a1",
          dropdown_name: "Major Alteration",
          search_value: "(a1 4)",
        },
        {
          name: "Minor Alteration",
          filter: "a3",
          dropdown_name: "Minor Alteration",
          search_value: "(a3 alt 1)",
        }
      ],
    },
    {
      name: "Work Type",
      show_name: true,
      search_key: "work_type",
      multi_filter: true,
      values: [
        {
          name: "Any",
          filter: "",
          dropdown_name: "Any",
          search_value: "",
        },
        {
          name: "General Construction",
          filter: "gc",
          dropdown_name: "General Construction",
          search_value: "(gc *general*const*)",
        },
        {
          name: "Plumbing",
          filter: "pl",
          dropdown_name: "Plumbing",
          search_value: "(pl *plumbing*)",
        },
        {
          name: "Construction Equipment",
          filter: "eq",
          dropdown_name: "Construction Equipment",
          search_value: "(eq */eq* *eq/*)",
        },
        {
          name: "Foundation/Earthwork",
          filter: "fo",
          dropdown_name: "Foundation/Earthwork",
          search_value: "(fo */fo* *fo/* foundation ea)",
        },
        {
          name: "Sidewalk Shed",
          filter: "sh",
          dropdown_name: "Sidewalk Shed",
          search_value: "(sh */sh* *sh/* sidewalk*shed)",
        },
        {
          name: "Scaffolding",
          filter: "sf",
          dropdown_name: "Scaffolding",
          search_value: "(sf */sf* *sf/* supported*scaffold sc */sc *sc/)",
        },
        {
          name: "Boiler",
          filter: "bl",
          dropdown_name: "Boiler",
          search_value: "(bl */bl* *bl/* boiler)",
        },
        {
          name: "Construction Fence",
          filter: "fn",
          dropdown_name: "Construction Fence",
          search_value: "(fn */fn *fn/* construction*fence)",
        },
        {
          name: "Mechanical Systems",
          filter: "mh",
          dropdown_name: "Mechanical Systems",
          search_value: "(mh */md *md/* mechanical*systems)",
        },
        {
          name: "Standpipe",
          filter: "sd",
          dropdown_name: "Standpipe",
          search_value: "(sd */sd *sd/* standpipe)",
        },
        
        {
          name: "Fire Suppression",
          filter: "fp",
          dropdown_name: "Fire Suppression",
          search_value: "(fp */fp* *fp/* fire*suppression)",
        },
        {
          name: "Sprinklers",
          filter: "sp",
          dropdown_name: "Sprinklers",
          search_value: "(*/sp* *sp/* sp sprinklers)",
        },
        {
          name: "Fuel Storage",
          filter: "fs",
          dropdown_name: "Fuel Storage",
          search_value: "(fs */fs* *fs/* fuel*storage)",
        },
        {
          name: "Fuel Burning",
          filter: "fb",
          dropdown_name: "Fuel Burning",
          search_value: "(fb */fb* *fb/* fuel*burning)",
        },
        {
          name: "Sign",
          filter: "sg",
          dropdown_name: "Sign",
          search_value: "(sg */sg* *sg/* *sign*)",
        },
        {
          name: "Electrical",
          filter: "el",
          dropdown_name: "Electrical",
          search_value: "(el */el* *el/* *electrical*)",
        },
        {
          name: "Curb Cut",
          filter: "cc",
          dropdown_name: "Curb Cut",
          search_value: "(cc */cc* *cc/* curb*cut)",
        },
        {
          name: "Protection and Mechanical Methods",
          filter: "pm",
          dropdown_name: "Protection and Mechanical Methods",
          search_value: "(pm pmm */pm* *pm/*)",
        }
      ],
    },
    {
      name: "Status",
      show_name: true,
      search_key: "filing_status",
      multi_filter: true,
      hide_issuance:false,
      values: [
        {
          name: "Any",
          filter: "",
          dropdown_name: "Any",
          search_value: "",
        },
        {
          name: "Assigned",
          filter: "assign",
          dropdown_name: "Assigned",
          search_value: "(3 25 33 67 84 7)",
        },
        {
          name: "Review",
          filter: "review",
          dropdown_name: "Review",
          search_value: "(4 26 5 68 13 2 71 85 24 25 8 47 10 11 50 52 72)",
        },
        {
          name: "Objections",
          filter: "objections",
          dropdown_name: "Objections",
          search_value: "(19 70 8 86 16 14)",
        },
        {
          name: "Approved",
          filter: "approved",
          dropdown_name: "Approved",
          search_value: "(6 8 16 37 13 102 15 52)",
        },
        {
          name: "Issued - Initial",
          filter: "issued-initial",
          dropdown_name: "Issued - Initial",
          search_value: "(initial)",
        },
        {
          name: "Issued - Renewal",
          filter: "issued-renewal",
          dropdown_name: "Issued - Renewal",
          search_value: "(renewal)",
        }
      ],
    },
    {
      name: "Applicant Company Name",
      show_name: true,
      search_key: "applicant_business_name",
      text_filter: true,
      hide_issuance:false
    },
    {
      name: "Applicant Contact Name",
      show_name: true,
      search_key: "applicant_contact_name",
      search_fields: ["applicant_first_name", "applicant_first_name"],
      text_filter: true,
      hide_issuance:false
    },
    {
      name: "Filing Rep Company Name",
      show_name: true,
      search_key: "filing_applicant_business_name",
      text_filter: true,
      hide_issuance:false
    },
    {
      name: "Filing Rep Contact Name",
      show_name: true,
      search_key: "filing_applicant_contact_name",
      search_fields: ["filing_applicant_irst_name", "filing_applicant_first_name"],
      text_filter: true,
      hide_issuance:true
    },
    {
      name: "Permittee Company Name",
      show_name: true,
      search_key: "permittee_business_name",
      text_filter: true,
    },
    {
      name: "Permittee Contact Name",
      show_name: true,
      search_key: "permittee_contact_name",
      search_fields: ["permittee_first_name", "permittee_last_name"],
      text_filter: true,
    },
    {
      name: "Owner Company Name",
      show_name: true,
      search_key: "owner_business_name",
      text_filter: true,
    },
    {
      name: "Owner Contact Name",
      show_name: true,
      search_key: "owner_contact_name",
      search_fields: ["owner_first_name","owner_last_name"],
      text_filter: true,
    },
  ];