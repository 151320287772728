import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Progress,
  Badge,
  Row,
  Container,
  Modal,
} from "reactstrap";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import UnitsSearchTableHead from "./UnitsSearchTableHead";
import MatTableToolbar from "./MatTableToolbar";
import UnitsListTable from "./UnitsListTable";
import LinearLoading from "../../../shared/components/LinearLoading";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import BuildingSearchAppBar from "../../../shared/components/building_filters/UnitsSearchAppBar";
import UnitsSearchAppBar from "./UnitsSearchAppBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

//icons
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import MapViewIcon from "mdi-react/MapOutlineIcon";
import CertificateOutlineIcon from "mdi-react/CertificateIcon";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import ContactIcon from "mdi-react/EmailOutlineIcon";
import BuildingIcon from "mdi-react/HomeCityOutlineIcon";
import ListIcon from "mdi-react/HomeVariantOutlineIcon";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { Tooltip } from "@material-ui/core";
import queryString from "query-string";
import moment from "moment";

import GroupedUnitsSearchTableHead from "./GroupedUnitsSearchTableHead";

import BuildingIcons from "../../../shared/components/BuildingIcons";
import OfferingStatus from "../../../shared/components/OfferingStatus";
import PermitTypeFilter from "../../../shared/components/unit_filters/PermitTypeFilter";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import PermitsMapView from "../../../shared/components/map/PermitsMapView";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _formatBed,
  propertyFL,
  SalesProgress,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  mpndCohortQuery,
  syncGenericFilter,
  _objectEqual,
  salesExportFL,
  getCohortQuery,
} from "../../../shared/helpers/utils";

import {
  _permitFilters,
  _buildingStageFilter,
  _unitSalesFilter,
} from "../../../shared/helpers/filters";
import Project from "../../Pipeline/components/Project";
import BuildingDetail from "./../../BuildingDetail";
import SalesStageFilter from "../../../shared/components/unit_filters/SalesStageFilter";
import { getBoundFromUrl } from "../../../shared/helpers/mapUtil";

const buildingUrl = process.env.API_ROOT + "/query/";

let lsPre = "P1_";
const subFilterKey = "subFilters";
const unitStagesKey = "permitStage";
const buildingStagesKey = "buildingStages";
const defaultStage = [];
const defaultSubFilter = {};


function mapStateToProps(authData) {
  return {
    authData,
  };
}

const _permitStageFilter = [
  {
    search_key: "permit_stage",
    type: "array",
    values: [
      {
        filter: "job filings",
      },
      {
        filter: "permit issuances",
      },
    ],
  },
];

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

class UnitsSearchTable extends PureComponent {
  defaultUnitStage = ["all"];

  state = {
    order: syncGenericFilter("order", lsPre, "order", ""),
    orderBy: syncGenericFilter("order_by", lsPre, "orderBy", ""),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    clearInput: false,
    end: 30,
    filterBorough: "all_boroughs",
    searchText: syncURLSearch(this.props, "searchText"),
    viewType: this.props.buildingView
      ? "list"
      : syncGenericFilter(
          "viewType",
          lsPre,
          "viewType",
          getLocalStorage(lsPre, "viewType") || "list"
        ),
    futureInventoryData: false,
    inventoryData: false,
    originalData: false,
    ovelayMinHeight: false,
    reactTableFiltered: [],
    showCounts: { desktop: 3, tablet: 2, mobile: 1 },
    hoodTags: [],
    boroTags: [],
    start: 0,
    stats: {},
    unitStages: syncStageFilters(
      this.props,
      _permitStageFilter,
      false,
      false,
      this.defaultUnitStage
    ),
    subFilters: !this.props.buildingView
      ? syncURLFilters(
          this.props,
          _permitFilters,
          subFilterKey,
          lsPre,
          defaultSubFilter
        )
      : syncURLFilters(this.props, _permitFilters, false, false, false),
    tableData: false,
    totalUnsoldUnits: false,
    totalCount: 0,
    groupedView: getLocalStorage(lsPre, 'groupedView') === true ? true : false,
    groupedData: false,
    groupedCount: false,
    buildingKey: false,
    unitListData: false,
    modal: false,
    permitRes: false,
    width: window.innerWidth,
    height: window.innerHeight,
    boroughs: ["Brooklyn", "Bronx", "Manhattan", "Staten Island", "Queens"],
    majorMarkets: {},
    neighborhoods: [],
    authData: {
      appState: { viewType: "map" },
      authenticated: false,
      idToken: null,
      myFeed: { isMyFeed: false },
      userData: { ...this.props.user },
    },
    buildingStages: [],
    mapBounds: getBoundFromUrl(),
    mobileMapMoving: false,
    preUrl: "",
  };

  defaultQ =
    (this.props.building &&
      this.props.building.offering_verified_status === "active") ||
    !this.props.buildingView
      ? "" //"(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:*)"
      : "*:*"; //-address_2_normalized:""';

  defaultBuidingQ = getCohortQuery(this.props.user);

  _propertyFilter = _permitFilters.filter((filter) => !filter.filter_type && !filter.hide_issuance);
  _buildingFilter = _permitFilters.filter(
    (filter) => filter.filter_type && filter.filter_type === "building"
  );

  q = "";
  fq = [
      '-job_number:""',
      'geocode:*'
      ];
  sort = "";
  unitFq = null;

  fl = propertyFL;
  _unitSubFilter = _permitFilters.filter(
    (filter) => !(this.props.buildingView && filter.building_hide)
  );
  collection = [];

  componentDidMount() {
    window.addEventListener("resize", this._handleWindowSizeChange);
    this._getInventoryUnits();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let unitStageChange = !_isEqual(
      prevState.unitStages,
      this.state.unitStages
    );
    let buildingStageChange = !_isEqual(
      prevState.buildingStages,
      this.state.buildingStages
    );

    let subFilterChanged =
    this._unitSubFilter.filter(item => {
      return (
        Array.isArray(item.search_key) ? 
          item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
        :
          this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
      );
    }).length > 0;
    if (
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.searchText !== prevState.searchText ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.page !== prevState.page ||
      this.state.rowsPerPage !== prevState.rowsPerPage ||
      this.state.groupedView !== prevState.groupedView ||
      (this.state.buildingKey !== prevState.buildingKey &&
        this.state.buildingKey !== false) ||
      searchTagsChange ||
      subFilterChanged ||
      unitStageChange ||
      buildingStageChange
    ) {
      const resetPage =
        this.state.page === prevState.page ||
        this.state.rowsPerPage !== prevState.rowsPerPage;
      console.log("component update", prevState, this.state);
      this.defaultBuidingQ = getCohortQuery(this.props.user);
      this._getInventoryUnits(resetPage);
    }
  }

  componentWillUnmount() {
    console.log("LS unmounting");
    window.removeEventListener("resize", this._handleWindowSizeChange);
  }

  _handleWindowSizeChange = () => {
    // console.log('handleWindowSizeChange fired', window.innerWidth)
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };



  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  handleExpandPanel(e, expanded, buildingKey) {
    if (expanded) {
      this.setState({ buildingKey: buildingKey });
    }
  }

  

  _getSpecificFilter = (key) => {
    let filter =
      _permitFilters &&
      _permitFilters.filter(
        (filter) =>
          filter.search_key === key ||
          (filter.search_key && filter.search_key.includes(key))
      )[0];
    return filter;
  };

  _toggleView = (type) => {
    this.setState({ viewType: type });
    setURLFromState(this.props, this.state, type);
    setLocalStorage(lsPre, "viewType", type);
  };

  _getInventoryUnits = async (resetPage) => {
    this.q = this.defaultQ;
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({
        fetchInProgress: true,
        groupedData: false,
        tableData: false,
        reactTableFiltered: false,
      }));
    }

    const { searchTags } = this.props;
    const { orderBy, order, unitStages, buildingStages } = this.state;
    let locQuery = _tranformTagsQuery(searchTags, "building").replace("text:", "job_number:");

    this.unitFq = this.fq.slice();

    /*
		if (this.state.filterBorough !== "all_boroughs") {
			this.q += `(borough:"${this.state.filterBorough
				.replace("_", " ")
				.toLowerCase()}")`;
		}
*/
    if (locQuery && !this.props.buildingView) {
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += `${locQuery}`;
    }

    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          const theFilter = this._propertyFilter.filter(
            (filter) => filter.search_key === key
          );
          let qVal = null;

          if (theFilter && theFilter[0] && theFilter[0].text_filter) {
            if(theFilter[0].search_fields){
              qVal = subFilterVal.split(" ").map(term => {
                  return "(" + theFilter[0].search_fields.map(field => {
                    return `${field}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*)`
                  }).join(" OR ") + ")"
                }).join(" AND ");
                
              
              
            }else{
              qVal = subFilterVal.split(" ").map(term => {
                return `(${key}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*))`
              }).join(" AND ");
            }
            
            
            if(qVal!==null){						
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${qVal})`;
              
            }
          } else if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
            const qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => subFilterVal.indexOf(value.filter) > -1
              ).length > 0
                ? theFilter[0].values
                    .filter((value) => subFilterVal.indexOf(value.filter) > -1)
                    .map((value) => value.search_value)
                : null;
            if (qVal !== null) {
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${key}:(${qVal.join(" ")}))`;
            }
          } else {
            qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => value.filter === subFilterVal
              ).length > 0
                ? theFilter[0].values.filter(
                    (value) => value.filter === subFilterVal
                  )[0].search_value
                : null;
            if (qVal !== null) {
              if (theFilter[0].target && theFilter[0].target === "fq") {
                this.unitFq.push(qVal);
              } else {
                this.q += this.q.length > 0 ? " AND " : "";
                this.q += `(${key}:${qVal})`;
              }
            }
          }
        }
      });
    }
    const isAvailable =
      this.state.subFilters.current_status &&
      ["available", "active", "shadow"].indexOf(
        this.state.subFilters.current_status
      ) >= 0;

    /****Price & Date query***/
    let priceQ = {};
    let dateQ = {};
    
    let replaceKeys = {};
    let dateReplaceKeys = {};
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map(key => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          const qArrayFilter = this._propertyFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
          
          if(qArrayFilter.length>0){	
            
            
            const filterKey = qArrayFilter[0].filter_key;
            console.log("qArrayFilter", qArrayFilter);
            if(replaceKeys[filterKey]){
              replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
            }else{
              replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
            }				
            if(priceQ[filterKey]){
              let regex = new RegExp(`{${key}}`, "g");
              priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
            }else{
              if(isAvailable && filterKey == "asking_price"){
                priceQ[filterKey] = 'last_listed_sale_price_all:[{min_price} TO {max_price}]'.replace(`{${key}}`, subFilterVal);
              }else{
                priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
              }
              
            }											
          }	
          
          const dateArrayFilter = this._propertyFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
        
          if(dateArrayFilter.length>0){	
            const filterKey = dateArrayFilter[0].filter_key;
            
            if(dateReplaceKeys[filterKey]){
              dateReplaceKeys[filterKey]  = [...dateReplaceKeys[filterKey], ...dateArrayFilter[0].search_key];
            }else{
              dateReplaceKeys[filterKey] = [...[], ...dateArrayFilter[0].search_key]
            }	
            
                      
            if(dateQ[filterKey]){
              let regex = new RegExp(`{${key}}`, "g");
              dateQ[filterKey] = dateQ[filterKey].replace(regex, moment(subFilterVal).toISOString())
            }else{		
              dateQ[filterKey] = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
              
            }											
          }	
        }
      });		
    }	
    console.log("priceQ", priceQ, replaceKeys)
    if(priceQ && Object.keys(priceQ).length>0){
      
      Object.keys(priceQ).map(filterKey => {
        const qArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
        replaceKeys[filterKey].map(key => {
          let regex = new RegExp(`{${key}}`, "g");
          if(key.indexOf("min")>=0){
            priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
          }else{
            if(key.indexOf("max")>=0 && qArrayFilter && qArrayFilter.target && qArrayFilter.target=="fq"){
              priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
            }else{
              priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
            }
            
          }
          
        
        })
        
        if(qArrayFilter && qArrayFilter.target && qArrayFilter.target =="fq"){
          this.unitFq.push(priceQ[filterKey]);
        }else{
          this.q += this.q.length > 0 ? " AND " : "";
          this.q += '(';
          this.q += priceQ[filterKey];
          this.q += ')';	
        }
        
        
      })
          
    }
    console.log("dateQ", dateQ, dateReplaceKeys)
    if(dateQ && Object.keys(dateQ).length>0){
      Object.keys(dateQ).map(filterKey => {
        const dateArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
        dateReplaceKeys[filterKey].map(key => {
          let regex = new RegExp(`{${key}}`, "g");
          dateQ[filterKey] = dateQ[filterKey].replace(regex, '*')
          
        
        })
        
        if(dateArrayFilter && dateArrayFilter.target && dateArrayFilter.target =="fq"){
          this.unitFq.push(dateQ[filterKey]);
        }else{
          this.q += this.q.length > 0 ? " AND " : "";
          this.q += '(';
          this.q += dateQ[filterKey];
          this.q += ')';	
        }
      })
      
          
    }
    /****Price& Date query***/
   

    setURLFromState(this.props, this.state);

    if (this.state.searchText && this.state.searchText.length > 0) {
      const text = this.state.searchText.replace(/ /g, "*").toLowerCase();
      const textCaps = _capitalizeText(
        this.state.searchText.toLowerCase(),
        false
      ).replace(/ /g, "*");
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += `(job_number:*${text.toLowerCase()}*)`;
    }
    this.q = this.q.length > 0 ? this.q : "*:*";

    let sort = "filing_date desc";

    if (orderBy && orderBy.length > 0) {
      sort = `${orderBy} ${order}`;
    }

    this.sort = !this.state.groupedView ? sort : null;
    
    this.collection = [];  //"we3-dobnow-permits-filings,dob-permits,we3-dob-permits-filings";
    if(this.state.unitStages.indexOf("job filings")>=0 || this.state.unitStages.indexOf("all")>=0){
      this.collection.push("we3-dobnow-permits-filings");
      this.collection.push("we3-dob-permits-filings");
    }
    if(this.state.unitStages.indexOf("permit issuances")>=0 || this.state.unitStages.indexOf("all")>=0){
      this.collection.push("dob-permits");
    }
    
    const excludeFilingFields = ["min_date", "max_date", "permittee_business_name", "permittee_contact_name", "work_type"];
    const excludeFilingSort = ["issuance_date", "permittee_business_name"];
    const excludeBISFilingFields = ["filing_status"];
    if(Object.keys(this.state.subFilters).filter(f => excludeFilingFields.includes(f)).length>0 || excludeFilingSort.includes(orderBy)){
      this.collection = this.collection.filter(c => c != "we3-dobnow-permits-filings" && c != "we3-dob-permits-filings")
    }
    if(Object.keys(this.state.subFilters).filter(f => excludeBISFilingFields.includes(f)).length>0){
      this.collection = this.collection.filter(c => c != "we3-dob-permits-filings")
    }
    
    if(this.state.groupedView){
      this.unitFq.push("job_doc_number:(*01* *I1*)")
    }

    let axiosData = {
      url: buildingUrl + "dob-permits?cache=60",
      method: "post",
      query: {
        q: this.q,
        fq: this.unitFq,
        sort: sort,
        collection: this.collection.join(","), 
        //fl: this.fl,
        rows: this.state.rowsPerPage,
        start: resetPage ? 0 : this.state.start,
        wt: "json",

      },
    };

 
    let response = await _axiosCall(axiosData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("error: " + error);
      });
   
      let formattedData =
        response && response.docs
          ? response && response.docs && response.docs.slice(0)
          : this.state.reactTableFiltered;

      let docs =
        formattedData &&
        formattedData.map((doc, i) => {
          let item = Object.assign({}, doc);
          return item;
        });

      this.setState((prevState) => ({
        fetchInProgress: false,
        tableData: docs,
        reactTableFiltered: docs,
        totalCount: response.numFound,
        groupedCount: false,
        start: resetPage ? 0 : this.state.start,
        page: resetPage ? 0 : this.state.page,
      }));
    
  };


  _formatStats = (response, redoHiddenFields = false) => {
    console.log(
      "_formatStats response",
      response,
      "redoHiddenFields",
      redoHiddenFields
    );
    console.log("_formatStats state", this.state);
    if (!response && !redoHiddenFields) {
      return;
    }
  };

  _handleMapBounds = (mapBounds) => this.setState({ mapBounds, page: 0 });

  _filterTableByInput = (name, eValue) => {
    console.log("_filterTableByInput name", name, "eValue", eValue);
    this.setState({ searchText: eValue });
  };

  _filterColumn = (columnId, value, display) => {
    const newfilter = {
      display: display,
      id: columnId,
      value,
    };

    const filtersWhithoutNew = this.state.reactTableFiltered.filter(
      (item) => item.id !== columnId
    );
    this.setState({
      reactTableFiltered: [...filtersWhithoutNew, newfilter],
    });
  };

  _setSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, this.state.subFilters, {
      [search_key]: search_val,
    });
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  };

  _setSubFilters = (subFilters) => {
    const subF = Object.assign({}, this.state.subFilters, subFilters);
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage
    });
    
    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  }
  _setBuildingStage = (stage) => {
    let newStages = this.state.buildingStages.slice();
    const index = newStages.indexOf(stage);
    const indexAll = newStages.indexOf("Show Unit Sales");

    if (index >= 0) {
      if (stage === "Show Unit Sales") {
        newStages = defaultStage;
      } else {
        newStages.splice(index, 1);
      }
    } else {
      if (stage === "Show Unit Sales") {
        newStages = ["Show Unit Sales"];
      } else {
        if (indexAll >= 0) {
          newStages.splice(indexAll, 1);
        }
        newStages.push(stage);
      }
    }
  
  
    if (newStages.length === 0) newStages = ["all"];

    this.setState({
      buildingStages: newStages,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
  };

  _setUnitStage = (stage) => {
    let newStages = this.state.unitStages.slice();
    const index = newStages.indexOf(stage);		
    const indexAll = newStages.indexOf('all');
    
    
    if(index>=0){
      
      if(stage === "all"){
        newStages = this.defaultUnitStage
      }else{
        newStages.splice(index, 1);
      }
    }else{
      if(stage === "all"){
        newStages = ["all"]
      }else{
        if(indexAll>=0){
          newStages.splice(indexAll, 1);
        }
        newStages.push(stage)
      }
      
    }
    if(newStages.length === 0 ) newStages = this.defaultUnitStage;
    this.setState({
      unitStages: newStages,
      start: 0,
      end: this.state.rowPerPage
    });		
    setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
  }

  _resetSubFilter = (search_key, search_val) => {
    const subF = !this.props.buildingView
      ? Object.assign({}, defaultSubFilter)
      : {};
    this.setState({
      buildingStages: this.props.buildingView ? defaultStage : [],
      unitStages: this.defaultUnitStage,
      subFilters: subF,
      groupedView: false,
      start: 0,
      order : "",
      orderBy : "",
      end: this.state.rowPerPage,
    });
    deleteLocalStorage(lsPre, buildingStagesKey);
    deleteLocalStorage(lsPre, unitStagesKey);
    deleteLocalStorage(lsPre, subFilterKey);
  };

  _resetActive = () => {
    let isDefault = true;
    if (this.props.buildingView) {
      isDefault =
        Object.keys(this.state.subFilters).length <= 0 &&
        _objectEqual(this.defaultUnitStage, this.state.unitStages);
    } else {
      isDefault =
        this.props.searchTags.length === 0 &&
        this.state.orderBy == "" &&
        this.state.groupedView == false &&
        _objectEqual(defaultSubFilter, this.state.subFilters) &&
        _objectEqual(this.defaultUnitStage, this.state.unitStages) &&
        _objectEqual(defaultStage, this.state.buildingStages);
        
    }

    return !isDefault;
  };


  _setBorough = (search_val) => {
    this.setState({
      filterBorough: search_val,
      start: 0,
      end: this.state.rowPerPage,
    });
  };

  _mobileMapMove = (mapNotMoving = false) => {
    console.log("_mobileMapMove fired", mapNotMoving);
    if (!mapNotMoving && !this.state.mobileMapMoving) {
      this.setState({ mobileMapMoving: true });
    } else if (mapNotMoving && this.state.mobileMapMoving) {
      this.setState({ mobileMapMoving: false });
    }
  };

  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      tableData,
      reactTableFiltered,
      subFilters,
      totalCount,
      groupedData,
      groupedCount,
      unitListData,
      fetchInProgress,
      searchText,
      unitStages,
      modal,
      viewType,
      permitRes,
      groupedView
    } = this.state;
    const { user, building } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;

    let containerHeight;
    if (viewType === "map") {
      const header =
        document.getElementsByClassName("topbar").length > 0
          ? document.getElementsByClassName("topbar")[0].offsetHeight
          : 0;

      const secondary_header =
        document.getElementsByClassName("c_seconday-nav-container").length > 0
          ? document.getElementsByClassName("c_seconday-nav-container")[0]
              .offsetHeight
          : 0;

      const search_container =
        document.getElementById("searchbar_filter_box") && !this.props.isDesktop
          ? document.getElementById("searchbar_filter_box").offsetHeight
          : 0;
      const researchbar_container =
        document.getElementById("research_bar") && !this.props.isDesktop
          ? document.getElementById("research_bar").offsetHeight
          : 0;
      containerHeight =
        this.state.height -
        header -
        secondary_header -
        search_container -
        researchbar_container -
        168;
    }
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding: 0 }} className="unit_search">
            
              <UnitsSearchAppBar
                handleSearch={this._filterTableByInput}
                numSelected={[...selected].filter((el) => el[1]).length}
                handleDeleteSelected={this.handleDeleteSelected}
                onRequestSort={this.handleRequestSort}
                subFilters={subFilters}
                searchText={searchText}
                setSubFilter={this._setSubFilter}
                resetSubFilter={this._resetSubFilter}
                hideProjects
                _filters={
                  user && user.role.indexOf("admin") >= 0
                    ? this._propertyFilter
                    : this._propertyFilter.filter((item) => !item.admin_only)
                }
                {...this.props}
                groupedView
                building={this.props.building}
                setSubFilters={this._setSubFilters}
                PermitTypeFilter={false}
                // PermitTypeFilter={"not required"}
                numToShow={5}
                resetActive={this._resetActive()}
                q={this.q}
                fq={this.unitFq}
                fl={salesExportFL}
                sort={this.sort}
                totalCount={totalCount}
                showExport
                core_name={"dob-permits"}
                export_name={`Permit Issuances`}
                title={"Export Permit Issuances (CSV)"}
                description={
                  <Fragment>
                    <h4>
                      <strong>Export past sales for your own analysis.</strong>
                    </h4>{" "}
                    <br /> The export will include details about unit sold
                    price, sold date, bed, bath and size.
                  </Fragment>
                }
              />
            

            <div className="material-table__header-info unit_search">
              <Row>
                <Col md={6} lg={6}>
                 {!isMobile &&
                     (
                       <div style={{display:"flex",alignItems:"center"}}>
                        <div className={`unit_toggle_filter`}>
                          <PermitTypeFilter
                            unitStages={unitStages}
                            setUnitStage={this._setUnitStage}
                          />
                        </div>
                        <div style={{marginRight:35,marginTop:-4}}>{' | '}</div>
                        <div className={`unit_toggle_filter`} style={{alignItem:"center"}}>
                        <span className="label" style={{marginRight:10,marginTop:5}}>Subsequent Filings:</span>
                        <div style={{marginTop:-3}}>
                          <span>{'Show'}</span>
                              <Tooltip title={`${this.state.groupedView ? "Show Only Initial Filing and Issuance" : "Show All Filings and Issuances"}`}>
                              <PurpleSwitch 
                                checked={this.state.groupedView}
                                onChange={() => {this.setState({groupedView : !groupedView})}}
                              />
                            </Tooltip>
                            <span>{'Hide'}</span>					      	
                        </div>
                        </div>
                       </div>
                    )}
                  {viewType != "map" && (
                    <div className={`count_toggle`}>
                      <div>
                        <span className="bold-text">
                          {totalCount > 0 ? _formatNumber(totalCount) : "--"}
                        </span>
                        {` `}Permit Issuances{" "}
                        
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={6} lg={6} className="fillingButtonGRP">
                  {!this.props.hideButtons && (
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      <Tooltip title={`See projects on a map`}>
                        <Button
                          disabled={viewType == "map"}
                          onClick={() => {
                            this._toggleView("map");
                          }}
                          startIcon={
                            <MapViewIcon
                              size={14}
                              color={viewType != "map" ? "#780F9E" : "#A3ADC2"}
                            />
                          }
                        >
                          Map
                        </Button>
                      </Tooltip>
                      <Tooltip title={`See projects in a list`}>
                        <Button
                          disabled={viewType == "list"}
                          onClick={() => {
                            this._toggleView("list");
                          }}
                          startIcon={
                            <ListViewIcon
                              size={14}
                              color={viewType != "list" ? "#780F9E" : "#A3ADC2"}
                            />
                          }
                        >
                          List
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  )}
                </Col>
              </Row>
            </div>

            <div className="material-table__wrap">
              {viewType == "list" && (
                <>
                  <UnitsListTable
                    isSelected={this.isSelected}
                    handleBuildingClick={this.handleBuildingClick}
                    handleClick={this.handleClick}
                    handleRequestSort={this.handleRequestSort}
                    handleSelectAllClick={this.handleSelectAllClick}
                    {...this.state}
                    {...this.props}
                  />
                  {paginationCount ? (
                    <TablePagination
                      component="div"
                      className="material-table__pagination"
                      count={paginationCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      rowsPerPageOptions={[25, 50, 100]}
                      dir="ltr"
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                    />
                  ) : null}
                </>
              )}
              {viewType == "map" && (
                <PermitsMapView
                  captureMobileMapMove={this._mobileMapMove}
                  hoveredListing={this.state.hoveredListing}
                  currentListing={
                    this.state.currentListing
                      ? this.state.currentListing.listing
                      : null
                  }
                  handleMapBounds={this._handleMapBounds}
                  authData={this.state.authData}
                  handleBuildingClick={this.handleBuildingClick}
                  containerHeight={containerHeight}
                  width={this.state.width}
                  boroughs={this.state.boroughs}
                  majorMarkets={this.state.majorMarkets}
                  neighborhoods={this.state.neighborhoods}
                  q={this.q}
                  fq={this.unitFq}
                  collection={this.collection}
                  {...this.state}
                  {...this.props}
                  isfilingPage
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect((state) => ({
  mapStateToProps,
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags)
    ? state.search.searchTags.slice()
    : [],
}))(withRouter(UnitsSearchTable));
