import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import renderPhotoUploadField from './fields/PhotoUpload';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';



import Form from "@rjsf/material-ui";
import LayoutField from './LayoutField';

import AssocationStepper from './AssocationStepper';

import renderCompanySearch from './fields/CompanySearch';
import renderRichTextEditor from './fields/RichTextEditor';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_CreateGuid
} from '../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}

let schema = {
  title: "Project Profile",
  type: "object",
  required: ['name'],
  properties: {
    'company_search' : {
	      'type' : 'string',
	      'description' : 'Search for the company name, if not found add as a new company.'
    },
    'project_role' : {
	      'title' : 'Project Role',
	      'type' : 'string',
	      'description' : 'Primary role on this project.  Ex: Developer.',
	       "enum": ["developer", "marketing", "expeditor", "engineer", "architect", "architect-landscape", "architect-design","architect-former", "interior designer", "lender", "construction","property manager", "commercial broker", "residential broker", "filing applicant", "owner", "owner-former", "owner entity", "owner entity-former", "developer-former", "marketing-former", "lender-former", "other"],
		   "enumNames": ["Developer", "Marketing", "Expeditor",  "Engineer", "Architect", "Architect (Landscape)",  "Architect (Design)", "Architect (Former)", "Interior Designer", "Lender", "Construction", "Property Manager", "Commercial Broker", "Residential Broker",  "Filing Applicant", "Owner", "Owner (Former)", "Owner Entity", "Owner Entity (Former)", "Developer (Former)", "Marketing (Former)", "Lender (Former)",  "Other"],
    },    
    'name' : {
	      'type' : 'string',
	      'description' : 'Company name. Ex: Metro Developers.'
    },
    'logo': {
      'type': 'string',
      'title': 'Logo Image',
      'description' : 'Company Logo'
    },    
     'phone' : {
	      'title' : 'Phone Number',
	      'type' : 'array',
	      'description' : 'Phone Numbers',
	      'items': {
		    'type': "string"
		  }
    },
    'email' : {
	      'title' : 'Email',
	      'type' : 'array',
	      'description' : 'Email Addresses',
	      'items': {
		    'type': "string"
		  }
    },
    'website' : {
	      'title' : 'Website',
	      'type' : 'string',
	      'description' : 'Website of this company'
    },
    'categories' : {
	      'title' : 'Business Categories',
	      'type' : 'array',
		  "items" : {
		              "type" : "string",
		              "enum": ["developer", "marketing", "expeditor", "engineer", "architect", "interior designer", "lender", "construction", "property manager", "commercial broker", "residential broker", "code consultant", "Other"],
					  "enumNames": ["Developer", "Marketing", "Expeditor",  "Engineer", "Architect", "Interior Designer", "Lender", "Construction", "Property Manager","Commercial Broker", "Residential Broker", "Code Consultant", "Other"],		          
					},
	      'description' : 'Types of services or lines of business offered by this company.  Ex: Marketing, Property Manager.',
	      "uniqueItems": true
    },
    "full_street_address": {
      'type': 'string',
      "title" : "Full Street Address",
      "description" : "Main address of this company. Ex: 123 5th Avenue"
    },
    "address_2": {
      'type': 'string',
      "title" : "Address 2",
      "description" : "Apt/Suite/Floor. Ex: Suite 101"
    },
    "city": {
      'type': 'string',
      "title" : "City",
      "description" : "City"
    },
    "state": {
      'type': 'string',
      "title" : "State",
      "description" : "State"
    },
    "zip": {
      'type': 'string',
      "title" : "Zip Code",
      "description" : "Zipcode of this project. Ex: 10001"
    },
    'linkedin' : {
	      'title' : 'LinkedIn',
	      'type' : 'string',
	      'description' : 'LinkedIn page of this company'
    },
    'twitter' : {
	      'title' : 'Twitter',
	      'type' : 'string',
	      'description' : 'Twitter page of this company'
    },
    'facebook' : {
	      'title' : 'Facebook',
	      'type' : 'string',
	      'description' : 'Facebook page of this company'
    },
    'instagram' : {
	      'title' : 'Instagram',
	      'type' : 'string',
	      'description' : 'Instagram page of this company'
    },
    'description' : {
	  'type': 'string',
      'title': 'Description',
      "description" : "Discription of this company, shown on the company profile." 
    },
  },
  "definitions": {}
      
    
  
}

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField  
}


const isFilled = (fieldName) => ({ formData }) => (formData[fieldName] && formData[fieldName].length) ? true : false
const isTrue = (fieldName) => ({ formData }) => (formData[fieldName])

const widgets = {
  CompanySearch: renderCompanySearch,
  ImageUpload: renderPhotoUploadField,
  RichTextEditor: renderRichTextEditor
}

let associateSchema = [{ 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Search for a company and select the role the company plays for this project.</p>
              </div>
            )
          } }
        ] } },
      ] },
	  { 'ui:row': [
        { 'ui:col': { md: 8, children: ['company_search'] } }, 
        { 'ui:col': { md: 4, children: ['project_role'] } },       
      ] }]

let noAssociationSchema = [{ 'ui:row': [
		  { 'ui:col': { md: 12, children: [
			{ render: (props) => {
			  const { formData, errorSchema } = props
	  
			  return (
				<div>
				  <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Add a new company (search for existing first)</p>
				</div>
			  )
			} }
		  ] } },
		] },
		{ 'ui:row': [
		  { 'ui:col': { md: 12, children: ['company_search'] } }, 
		] }]
		
let companyDetailSchema = [
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
               
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Edit company details.  This will update the company information across all associated projects.</p>
              </div>
            )
          } }
        ] } },
      ] },
	  { 'ui:row': [
       
        { 'ui:col': { md: 2, children: ['logo'] } },
        { 'ui:col': { md: 10, children: ['name'] } }, 
      ] }, 
      { 'ui:row': [
        { 'ui:col': { md: 12, children: ['categories'] } }, 
      ] },
       { 'ui:row': [
        { 'ui:col': { md: 12, children: ['website'] } }, 
      ] },
     
      { 'ui:row': [
        { 'ui:col': { md: 6, children: ['linkedin'] } }, 
        { 'ui:col': { md: 6, children: ['twitter'] } },
      ] },
       { 'ui:row': [
        { 'ui:col': { md: 6, children: ['instagram'] } }, 
        { 'ui:col': { md: 6, children: ['facebook'] } },
      ] },
     { 'ui:row': [
        { 'ui:col': { md: 8, children: ['full_street_address'] } }, 
        { 'ui:col': { md: 4, children: ['address_2'] } },
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 5, children: ['city'] } }, 
        { 'ui:col': { md: 4, children: ['state'] } }, 
        { 'ui:col': { md: 3, children: ['zip'] } }, 
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
               
                <p className="MuiTypography-root MuiTypography-caption MuiTypography-colorTextSecondary">Company Description</p>
              </div>
            )
          } }
        ] } },
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: ['description'] } }
      ] }]

const schema0 = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: associateSchema } },
  ] },

	 'company_search' : {
		  'ui:widget': 'CompanySearch'
	  },
	  'logo': {
	  	'ui:widget': 'ImageUpload'   
	  },
	  "categories" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
			    'inline' : true
		  }  
	  },
	  'description': {
	  	'ui:widget': 'RichTextEditor'   
	  },
}

const schema0noassociation = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
	{ 'ui:col': { md: 12, children: noAssociationSchema } },
  ] },

	 'company_search' : {
		  'ui:widget': 'CompanySearch'
	  },
	  'logo': {
		  'ui:widget': 'ImageUpload'   
	  },
	  "categories" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
				'inline' : true
		  }  
	  },
	  'description': {
		  'ui:widget': 'RichTextEditor'   
	  },
}


const schema0edit = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: associateSchema } },
  ] },

	 'company_search' : {
		  'ui:widget': 'CompanySearch',
		  'ui:disabled': true
	  },
	  'logo': {
	  	'ui:widget': 'ImageUpload'   
	  },
	  "categories" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
			    'inline' : true
		  }  
	  },
	  'description': {
	  	'ui:widget': 'RichTextEditor'   
	  },
}

const schema1 = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: companyDetailSchema } },
  ] },

	 'company_search' : {
		  'ui:widget': 'CompanySearch'
	  },
	  'logo': {
	  	'ui:widget': 'ImageUpload'   
	  },
	  "categories" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
			    'inline' : true
		  }  
	  },
	  'description': {
	  	'ui:widget': 'RichTextEditor'   
	  },
}

//uiSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'] = [...uiSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'], ...companyDetailSchema];

class EditCompanies extends Component {

  constructor(props) {
    super(props);
    this.state={
	    activeStep : 0
    }
    this.doValidateSubmit = this.doValidateSubmit.bind(this);
    this.setActiveStep = this.setActiveStep.bind(this);
    
  }
  
   doValidateSubmit = () => {
	  const { formData, onSubmit } = this.props;
	  
	  const errorList = [];
	  if (!formData.name || formData.name == "") {
	    errorList.push("Company name is required");
	  }
	  
	  
	  
	  onSubmit(errorList);
	  
  } 
  
  setActiveStep(step){
	 const {
     formData, onChange, onSubmit, toggle, buildingRes, add, noAssociation
    } = this.props; 
	  
/*
	  
	  if(step == 0){
		  defaultSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'] = associateSchema;
	  }else if(step == 1){
		  defaultSchema['ui:layout_grid']['ui:row'][0]['ui:col']['children'] = companyDetailSchema;
	  }
*/
	  if(step === 1){
		  if(formData.company_search && (!formData["name"] || formData["name"]=="")){
			    let newObj = Object.assign({}, formData);
				newObj["name"] = newObj.company_search
				//newObj["key"] = _CreateGuid();
				
				onChange({formData : newObj})
			}
		  
	  }
	  
	  this.setState({ activeStep : step });
  }

  render() {
    const {
     formData, onChange, onSubmit, toggle, buildingRes, add, single, saveInProgress, noAssociation
    } = this.props;
    
    const { activeStep, uiSchema } = this.state;
    
    
    if(single){
	    
	    return (
		    <div className="contact_edit company">
		    	<Form
			        formData={formData}
			        schema={schema}
			        uiSchema={schema1}
			        fields={fields}
			        widgets={widgets}
			        onChange={onChange}
			        noHtml5Validate={true}>
			       <ButtonToolbar className="modal__footer">
		            <Tooltip title="Cancel">
				        <Button
					         onClick={toggle}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					         disabled={saveInProgress}
					     >	Cancel
					     </Button>				 
				  	</Tooltip>{' '}
		            <Tooltip title="Save">
				        <Button
				        	 onClick={this.doValidateSubmit}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"
					         disabled={saveInProgress}		         
					     >	{saveInProgress ? "Saving..." : "Save" }
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>	
			   </Form>
		    
		    </div>
	    )
    }
        
    return (
	    <div className="contact_edit company">
	    	<AssocationStepper add={add===true ? true : false} activeStep={activeStep} setActiveStep={this.setActiveStep} onSubmit={this.doValidateSubmit} saveInProgress={saveInProgress}>
	    	{activeStep == 0 ?
			    <Form
			        formData={formData}
			        schema={schema}
			        uiSchema={add===true ? noAssociation ? schema0noassociation : schema0 : schema0edit}
			        fields={fields}
			        widgets={widgets}
			        onChange={onChange}
			        noHtml5Validate={true}>
			        <br/>
			       
			      {/*  <ButtonToolbar className="modal__footer">
		            <Tooltip title="Cancel">
				        <Button
					         onClick={toggle}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					     >	Cancel
					     </Button>				 
				  	</Tooltip>{' '}
		            <Tooltip title="Send">
				        <Button
				        	 onClick={onSubmit}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					     >	Save
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>*/}
	
			   </Form>
			: activeStep == 1 ?
				<Form
			        formData={formData}
			        schema={schema}
			        uiSchema={schema1}
			        fields={fields}
			        widgets={widgets}
			        onChange={onChange}
			        noHtml5Validate={true}>
			       <br/>
			        {/*   <ButtonToolbar className="modal__footer">
		            <Tooltip title="Cancel">
				        <Button
					         onClick={toggle}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					     >	Cancel
					     </Button>				 
				  	</Tooltip>{' '}
		            <Tooltip title="Send">
				        <Button
				        	 onClick={onSubmit}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					     >	Save
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>*/}
	
			   </Form>
			: activeStep == 2 ?
				<div className="confirm_association confirm_dialog">
					{noAssociation ? 
					<p>Completing this step will create a new company with no associations to pipeline projects.</p>	
					:
					<p>Completing this step will associate <strong>{formData.name}</strong> to <strong>{buildingRes && buildingRes["name"] ? _capitalizeText(buildingRes["name"]) : _capitalizeText(buildingRes["display_full_street_address"])}</strong><br/> and update any changed company details across all project.</p>
					}
			
				
				</div>
				
			: null
			}
			</AssocationStepper>
		</div>
    );
  }
}



export default EditCompanies;
