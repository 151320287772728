import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import ActiveIcon from 'mdi-react/CheckCircleOutlineIcon';
import VetIcon from 'mdi-react/AlertDecagramOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';


import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class PermitDetails extends PureComponent {
	
  constructor(props) {
	super(props);
	this.state = {
	  modal:false
	};
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  const { toggle } = this.props;
	  e.preventDefault();
	  e.stopPropagation();
	  toggle();
  }
  
 

  render(){
	  const { permit, modal } = this.props;
	  
	  if(!permit) return null;
	  
	  const modalClass = classNames({
		  'modal-dialog--colored': false,
		  'modal-dialog--header': true,
	   });
	   //<a href={`https://www.nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/${transaction.verified_loan_officer_nmls_id[0]}`} className="link-text" target="_blank">
	   if(permit ){
			 return (
			   <Fragment>
				 
					   <Modal
						 isOpen={modal}
						 toggle={this.toggle}
						 modalClassName={`ltr-support`}
						 className={`modal-dialog--primary ${modalClass}`}
					   >
					   <div className="modal__header">
						   <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
						   <h4 className="text-modal  modal__title">Permit Details</h4>
						 </div>
						 <div className="modal__body">
							 <p style={{fontSize:16}}></p>
							 <hr/>
							 
						 </div>
						 
						 <ButtonToolbar className="modal__footer offering-status">
						   {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
						   <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
							   Ok
						   </Button>
						 </ButtonToolbar>
					   </Modal>
				   </Fragment>
			 )
		}else{
		 return null;
	 }
 }
	
};


export default PermitDetails;
