import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import { Link } from "react-router-dom";

import NewsItem from './NewsItem';
import { _axiosCall, _buildingIDs } from '../../../../../../shared/helpers/apicalls';
import moment from 'moment';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import Slider from 'react-slick';
import Panel from '../../../../../../shared/components/Panel';

import {
	buildingFL,
	getCohortQuery
} from "../../../../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + '/query/';
const buildingUrl = process.env.API_ROOT + '/pipeline/';


class TimelineHistory extends PureComponent {  
  state = {
		rowsPerPage: 10,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		data: [],
		noMoreResults:false
  }
	
  componentDidMount() {
  	  this._getNews();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  if(this.state.start !== prevState.start){
		  this._getNews();
	  }  
  }
  
  _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
  }
  
  _getNews = async () => {
	  	const { user } = this.props;  
	  	if(this.state.fetchInProgress === true){
		  	return false;
	  	}
	  	this.setState({ fetchInProgress: true });
	  
	   const fq = [
		    "(topic:(stories))",
		    "hierarchy:[0 TO 2]",
			"-siblings:*",		    
		    `time:[* TO NOW]`,		    
		    `{!join from=key fromIndex=we3-buildings to=building}(pipeline_permit_source_id:* AND -pipeline_permit_source_id:"" AND deleted:false)`
	    ]	    
	    const q = this.props.buildingRes ? `building:${this.props.buildingRes.key}` : '*:*';
		const axiosData = {
			url: apiURL + "we3-activities-3?cache=30",
			method: "post",
			query: {
				q,
				fq,
				sort: "time desc",
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start
			}
		};

		const formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					this._getPipelineBuildings(res);
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false })
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};
	
	_getPipelineBuildings = async (newsRes) => {
	
		 
	  if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
	  }
	  
	  let searchFilters = [{"meta.deleted" : { "$exists" : false }}];
	  
	  let buildingKeys = [];
	  if(newsRes && newsRes.docs && newsRes.docs.length>0){
		  newsRes.docs.forEach(news => {
			  
			  if(news.alternate_buildings && Array.isArray(news.alternate_buildings)){
				  buildingKeys = [...buildingKeys, ...news.alternate_buildings]
			  }else if(news.building && Array.isArray(news.building)){
				  buildingKeys = [...buildingKeys, ...news.building]
			  }
			  
		  })
		  
		  
	  }
	  if(buildingKeys.length>0){
			  searchFilters.push({ "$or" : [{"building_key" : { "$in" : buildingKeys}}, {"address.key": { "$in" : buildingKeys}}]});
	  }
	  
	  let mongoQuery = {
			"query": { "$and" : searchFilters },
		    "projection" : {
			    	"_id": 1,
		            "name": 1,
		            "display_full_street_address" : 1,
		            "address" : 1,
		            "key" : 1,		 
		            "image" : 1,
		            "images" : 1,       
		            "pipeline_zoning_area_residential" : 1,
		            "pipeline_total_construction_floor_area" : 1,
		            "pipeline_building_stories_proposed" : 1,
		            "pipeline_dwelling_units_proposed" : 1,
		            "pipeline_filing_date" : 1,
		            "pipeline_pre_filing_date" : 1,
		            "pipeline_project_stage" : 1,
		            "pipeline_coo_issuance_date" : 1,
		            "pipeline_permit_issuance_job_start_date" : 1,
		            "pipeline_permit_issuance_filing_date" : 1,
		            "occupancy_type" : 1,
		            "pipeline_occupancy_type" : 1,
		            "pipeline_project_components" : 1,
		            "date_added" : 1,
		            "pipeline_occupancy_classification_proposed_code" : 1,
		            "building_key" : 1
		    },
		    "limit" : this.state.rowsPerPage    
		}
	  
	  let axiosData = {
			url: buildingUrl + "query",
			method: "post",
			query: mongoQuery
			
			
	   		}

	   
	   _axiosCall(axiosData)
		.then(res => {
			// console.log('Chart Data', res);
			let docs = [];
			if(res && res.docs){
				docs = res.docs;
			}
			newsRes.docs.forEach(news => {
				if(news.alternate_buildings && Array.isArray(news.alternate_buildings)){
				  news.alternate_buildings.forEach(blg => {
					  if(docs.filter(doc => (doc.building_key === blg || (doc.address && doc.address.key==blg))).length>0){
						  news['pipeline_project'] = docs.filter(doc => (doc.building_key === blg || (doc.address && doc.address.key==blg)))[0]
					  }					  
				  })
				  
				  
				}else if(news.building && Array.isArray(news.building)){
					  news.building.forEach(blg => {
					  if(docs.filter(doc => (doc.building_key === blg || (doc.address && doc.address.key==blg))).length>0){
						  news['pipeline_project'] = docs.filter(doc => (doc.building_key === blg || (doc.address && doc.address.key==blg)))[0]
					  }					  
				  })
				}
			})
			
			this.setState( { data: [...this.state.data, ...newsRes.docs], fetchInProgress: false, buildingsFetchInProgress: false, })
			 
		})
		.catch(error => {
			console.log("error: " + error);
		});
	   
	   
	  
	  
  }


  render() {
	  
	  const { data } = this.state;	  
	  	  
	  const settings = {
		  dots: false,
		  infinite: false,
		  initialSlide:0,
		  speed: 500,
		  autoplay: false,
		  swipeToSlide: true,
		  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
		  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
		  responsive: [
			{ breakpoint: 768, settings: { slidesToShow: 1 } },
		    { breakpoint: 992, settings: { slidesToShow: 1 } },
		    { breakpoint: 1200, settings: { slidesToShow: 2 } },
		    { breakpoint: 1536, settings: { slidesToShow: 3 } },
		    { breakpoint: 100000, settings: { slidesToShow: 3 } },
		  ],
	  };
 
	  return (
			 <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={'Latest News'}
		  	subhead={''}
		  	label={null}
		  >
			    <div className="news__container">
			    {data ? 
			    <Slider {...settings} className="timeline">
				    {data.map((item, index) => {
					    try{			
							const data = JSON.parse(item.data);
							item.data = data;	
						}catch(e){
							//ToDo
						}
					    
					    return <NewsItem item={item} key={index}/>
						       				    
				    })}
			    </Slider>
			    : null
			    }
			    </div>
			     <div className="unit__load text-right semi-bold-text">			   		
			    <Tooltip title="See more news">
			        <Link to="/news">See More News &raquo;</Link>			 
			  	</Tooltip>				
		    </div>
			  </Panel>
	    )
   };
}

export default TimelineHistory;
