import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import CrosshairsGpsIcon from 'mdi-react/CrosshairsGpsIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../shared/components/Panel';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  swipeToSlide: true,
  slidesToScroll: 1,
  responsive: [
    { breakpoint: 992, settings: { slidesToShow: 1 } },
    { breakpoint: 1200, settings: { slidesToShow: 1 } },
    { breakpoint: 1536, settings: { slidesToShow: 1 } },
    { breakpoint: 100000, settings: { slidesToShow: 1 } },
  ],
};

const ShortReminders = ({ t }) => (
  <Panel md={12} lg={12} xl={6} sm={12} xs={12} noButtons>
    <Slider {...settings} className="dashboard__carousel">      
      <div>
        <Link to={`/transaction-center`}>
        <div className="dashboard__carousel-slide slide__link">
          <CrosshairsGpsIcon />
          <p className="dashboard__carousel-title">Transaction Center <br/><span className="light-text smaller-text">Get the Deal Done</span></p>
          <p>{`Connect with professionals who can help complete your transaction`}.</p>
        </div>
        </Link>
      </div>
   {/*   <div>
        <div className="dashboard__carousel-slide dashboard__carousel-slide--red">
          <CrosshairsGpsIcon />
          <p className="dashboard__carousel-title">Promo 1 <span className="light-text smaller-text">BuyersList Exclusive</span></p>
          <p>Bring 2 or more buyers to our new building and receive a special offer.</p>
        </div>
      </div>*/}
    </Slider>
  </Panel>
);

ShortReminders.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ShortReminders);
