import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Form from "@rjsf/material-ui";
import LayoutField from './LayoutField';
import ImageUploadWidget from './ImageUploadWidget'

import renderPhotoUploadField from './fields/PhotoUpload';
import renderRichTextEditor from './fields/RichTextEditor';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber
} from '../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}

const today = moment().format("YYYY-MM-DD");
const schema = {
  title: "Project Photos",
  type: "object",
  properties: {
    'main_photo': {
      'type': 'string',
      'title': 'Featured Image'
    },
    'title': {
      'type': 'string',
      'title': 'Title',
      "description" : "Headline for this project update, shown on the profile page."
    },
    'excerpt': {
      'type': 'string',
      'title': 'Excerpt',
      "description" : "If added, replaces main content shown on project update."
    },
    'article' : {
	  'type': 'string',
      'title': 'Summary',
      "description" : "Full content of this project project." 
    },
    'project_update_author': {
      'type': 'string',
      'title': 'Published By',
      "description" : "Summary author, shown on the profile page.",
      "default" : "Marketproof Research"
    },
    'time': {
      'type': 'string',
      'format' : 'date',
      'title': 'Published Date',
      "description" : "Last updated date, shown on the profile page.",
      "default" : today
    },
  }
}

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField
}

const widgets = {
  ImageUpload: renderPhotoUploadField,
  RichTextEditor: renderRichTextEditor
}


const isFilled = (fieldName) => ({ formData }) => (formData[fieldName] && formData[fieldName].length) ? true : false
const isTrue = (fieldName) => ({ formData }) => (formData[fieldName])

const uiSchema = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: [
	  { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <label className="MuiFormLabel-root">Featured Image</label>
              </div>
            )
          } }
        ] } },
      ] },

	  { 'ui:row': [
        { 'ui:col': { md: 12, children: ['main_photo'] } },        
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: ['title'] } },        
      ] },
      
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <label className="MuiFormLabel-root mt-5">Main Content</label>
              </div>
            )
          } }
        ] } },
      ] },
       { 'ui:row': [
        { 'ui:col': { md: 12, children: ['article'] } },        
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <hr/>
            )
          } }
        ] } },
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: ['excerpt'] } },        
      ] },
       { 'ui:row': [
	    { 'ui:col': { md:4, children: ['project_update_author'] } }, 
        { 'ui:col': { md:4, children: ['time'] } },        
      ] },
    ] } },
  ] },
 
  'main_photo': {
  	'ui:widget': 'ImageUpload'   
  },
  'excerpt': {
    'ui:widget': 'textarea'
  },
  'article': {
    'ui:widget': 'RichTextEditor'
  },
}


class EditFeedItem extends Component {

  constructor(props) {
    super(props);
    
  }

  render() {
    const {
     formData, onChange, onSubmit, toggle
    } = this.props;
        
    return (
	    <div className="project_edit summary feed">
		    <Form
		        formData={formData}
		        schema={schema}
		        uiSchema={uiSchema}
		        fields={fields}
		        widgets={widgets}
		        onChange={onChange}
		        noHtml5Validate={true}>
		        <ButtonToolbar className="modal__footer">
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
	            <Tooltip title="Send">
			        <Button
			        	 onClick={onSubmit}
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"		         
				     >	Save
				     </Button>				 
			  	</Tooltip>
	         </ButtonToolbar>
	
			</Form>
		</div>
    );
  }
}



export default EditFeedItem;
