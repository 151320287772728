/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/first: off */
import React, { Component } from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
import L from "leaflet";
import { polygon, polygons, multiPolygon } from "@turf/helpers";
import center from "@turf/center";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import _ from "lodash";
import PitchToggle from "./PitchToggle";
import * as MapUtil from "./../../helpers/mapUtil";
import {
  _isEqual,
  _capitalizeText,
  _getBuildingPhotos,
  _formatQueensAddress,
  _urlToName,
  _nameToUrl,
  _replaceAbbreviations,
  _shouldShow,
  _generateRandomAPI,
  StatusBadge,
  BuildingStageBadge,
  _formatNumber,
  _getStreetPhoto,
  GenerateBuildingKeyMap,
} from "./../../helpers/utils";
import {
  boroughTileset,
  majorMarketTileset,
  neighborhoodTileset,
  pipeline_tileset,
} from "../../helpers/commonMapFilters";
import LegendToggle from "../../../containers/PipelineMap/components/LegendToggle";
import {
  defaultOccupancyTypes,
  pipelineStyle,
  TooltipBuilding,
  TooltipMPND,
} from "./CommonMapComponent";
import StarIcon from "mdi-react/StarCircleIcon";
const building_tileset = [
  {
    id: "mp-building-manhattan",
    layer_id: "mp-building-manhattan-layer",
    url: "mapbox://ningzhou.cjv5grhlg3xlr2ro21g760vdd-63y9y",
    source_layer: "nyc-building-manhattan",
  },
  {
    id: "mp-building-staten",
    layer_id: "mp-building-staten-layer",
    url: "mapbox://ningzhou.cjvg8q7680exe2xqhzcej54qd-9xeuw",
    source_layer: "nyc-building-staten",
  },
  {
    id: "mp-building-bronx",
    layer_id: "mp-building-bronx-layer",
    url: "mapbox://ningzhou.cjvgrtx1u0htl2rpnfdchg13v-1aseh",
    source_layer: "nyc-building-bronx",
  },
  {
    id: "mp-building-brooklyn",
    layer_id: "mp-building-brooklyn-layer",
    url: "mapbox://ningzhou.cjvfch80a12cl2xs98iwejfnx-0s7ec",
    source_layer: "nyc-building-brooklyn",
  },
  {
    id: "mp-buildings-queens-0-4000",
    layer_id: "mp-buildings-queens-0-4000-layer",
    url: "mapbox://ningzhou.cjvlyvou11dzg2ws1sspk9u4k-5t9og",
    source_layer: "nyc-buildings-queens-0-4000",
  },
  {
    id: "mp-buildings-queens-4001-8000",
    layer_id: "mp-buildings-queens-4001-8000-layer",
    url: "mapbox://ningzhou.cjvmg56vh1jng2ykw5rikl569-1e9gm",
    source_layer: "nyc-buildings-queens-4001-8000",
  },
  {
    id: "mp-buildings-queens-8001-12000",
    layer_id: "mp-buildings-queens-8001-12000-layer",
    url: "mapbox://ningzhou.cjvmkfknk0vgi31s37e954hpa-9u6m1",
    source_layer: "nyc-buildings-queens-8001-12000",
  },
  {
    id: "mp-buildings-queens-12001",
    layer_id: "mp-buildings-queens-12001-layer",
    url: "mapbox://ningzhou.cjvmw4b5615xe33o9dqcpo0ae-9mm6a",
    source_layer: "nyc-buildings-queens-12001",
  },
];

mapboxgl.accessToken =
  "pk.eyJ1IjoibmluZ3pob3UiLCJhIjoiY2lwaXBiaWtyMDFxZXVnbmpkaWR4dXd0MSJ9.GJX2c6TviB6opu84mCEOIg";

const buildingStyle = {
  fillColor: "#A3ADC2",
  fillOpacity: 0.45,
};

const buildingHighlightStyle = {
  fillColor: "#780F9E",
  fillOpacity: 0.8,
};

class BuildingMapBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingsSelected: [],
      lat: 40.7379366,
      lng: -73.9949712,
      mapContainerWidth: this.props.mapContainerWidth,
      mapLoading: false,
      mapLoaded: false,
      mapUnsupported: false,
      zoom: 17,
      occupancyTypes: defaultOccupancyTypes,
      mapBounds: [],
    };

    this._calcDefaultZoomLevel = this._calcDefaultZoomLevel.bind(this);
    this._setOccupancyFilter = this._setOccupancyFilter.bind(this);
  }

  hoveredStateId = null;
  hoveredLayerId = null;
  tooltipContainer;
  tooltip;
  map;
  marker;
  layersAdded = [];

  _setTooltip(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(Tooltip, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _setTooltipBuilding(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(TooltipBuilding, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _setTooltipMPND(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(TooltipMPND, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _isMPND(features) {
    let result = false;
    features.map((feature) => {
      const building_status = feature.properties.building_status
        ? feature.properties.building_status
        : false;
      const offering_verified_status = feature.properties
        .offering_verified_status
        ? feature.properties.offering_verified_status
        : false;
      if (
        building_status &&
        ["pipeline", "sponsor", "resale"].indexOf(building_status) >= 0 &&
        offering_verified_status != "" &&
        offering_verified_status != "excluded"
      ) {
        result = feature;
      }
    });
    return result;
  }

  _onMouseMove(e) {
    if (
      e.features.length > 0 &&
      e.features[0].properties &&
      e.features[0].properties.key
    ) {
      //var mapLayer = this.map.getLayer('mp-building-layer-hover');

      //e.features[0].state = {"hover":true};
      this.map.getCanvas().style.cursor = "pointer";
      //
      this.tooltip && e.lngLat && this.tooltip.setLngLat(e.lngLat);

      let feature = this._isMPND(e.features);

      const key =
        feature && feature.properties.key
          ? feature.properties.key
          : e.features[0].properties.key
          ? e.features[0].properties.key
          : false;

      if (key && key.indexOf("b-") >= 0) {
        if (
          this.hoveredBuildingStateId !== key &&
          this.hoveredStateId === null
        ) {
          this._setTooltipBuilding(e.features);
          //console.log(this.hoveredStateId, e.features[0].properties.master_key);
          this.hoveredBuildingStateId = key;
          this.hoveredBuildingLayerId = e.features[0].layer.id + "-hover";
          if (this.map.getZoom() > 15) {
            //console.log(this.hoveredBuildingLayerId, this.hoveredBuildingLayerId);
            this.map.setFilter(this.hoveredBuildingLayerId, [
              "==",
              "key",
              this.hoveredBuildingStateId,
            ]);
          }
        }
      } else {
        if (this.hoveredStateId !== key) {
          //console.log(this.hoveredStateId, e.features[0].properties.master_key);

          console.log("_isMPND", feature);
          if (feature) {
            this._setTooltipMPND([feature]);
            this.hoveredStateId = key;
            this.hoveredLayerId =
              feature.layer.id.replace("-3d", "") + "-hover";
            this.hovered3DLayerId =
              feature.layer.id.replace("-3d", "") + "-3d-hover";
          } else {
            this._setTooltip(e.features);
            this.hoveredStateId = key;
            this.hoveredLayerId =
              e.features[0].layer.id.replace("-3d", "") + "-hover";
            this.hovered3DLayerId =
              e.features[0].layer.id.replace("-3d", "") + "-3d-hover";
          }

          //console.log(this.hoveredLayerId, this.hoveredStateId);
          this.map.setFilter(this.hoveredLayerId, [
            "==",
            "key",
            this.hoveredStateId,
          ]);
          this.map.setFilter(this.hovered3DLayerId, [
            "==",
            "key",
            this.hoveredStateId,
          ]);
        }
      }
    } else {
      this.map.getCanvas().style.cursor = "";
    }
  }

  _onMouseOut() {
    if (this.hoveredStateId && this.hoveredLayerId) {
      this.map.setFilter(this.hoveredLayerId, ["==", "key", false]);
    }
    if (this.hoveredStateId && this.hovered3DLayerId) {
      this.map.setFilter(this.hovered3DLayerId, ["==", "key", false]);
    }
    if (this.hoveredBuildingStateId && this.hoveredBuildingLayerId) {
      this.map.setFilter(this.hoveredBuildingLayerId, ["==", "key", false]);
    }

    this.hoveredStateId = null;
    this.hoveredBuildingStateId = null;

    this.map.getCanvas().style.cursor = "";

    ReactDOM.unmountComponentAtNode(this.tooltipContainer);
  }

  _setMouseClick(e) {
    const { handleBuildingClick } = this.props;
    let features = this.map.queryRenderedFeatures(e.point, {
      layers: this.layersAdded,
    });

    let feature = this._isMPND(features);

    let buildings = [];

    if (this.state.buildingsSelected.length > 0) {
      buildings = this.state.buildingsSelected.slice(0);
    }
    let building = Object.assign({}, e.features[0].properties);
    try {
      building.building_slugs = JSON.parse(building.building_slugs);
      building.neighborhood = JSON.parse(building.neighborhood);
    } catch (e) {
      console.log(e);
    }

    building["id"] =
      building && building.building_slugs && building.building_slugs[0];
    building["key"] = building.key;

    buildings = buildings.filter((existing) => {
      return existing.key != building.key;
    });
    buildings.unshift(building);

    if (
      features.length > 0 &&
      features[0].properties &&
      features[0].properties.key
    ) {
      if (feature) {
        console.log("isMPND", feature);
        this._openMPND(feature.properties);
      } else {
        console.log("Not isMPND");
        handleBuildingClick(e, features[0].properties);
      }
    } else {
      if (this.props.buildingClicked) {
        if (building && building.alternate_addresses) {
          building.alternate_addresses = JSON.parse(
            building.alternate_addresses
          );
        }
        this.props.buildingClicked(building);
      } else {
        this._openBuilding(building);
      }
    }
  }

  _openMPND(building) {
    let slug = building.building_key.substring(4);
    // window.open(
    //   "/building/" +
    //     _nameToUrl(building.borough) +
    //     "/" +
    //     _nameToUrl(building.neighborhood) +
    //     "/" +
    //     building.key
    // );
    window.open(
      "https://newdev.marketproof.com/buildings/" +
        _nameToUrl(building.borough) +
        "/" +
        _nameToUrl(building.neighborhood) +
        "/" +
        slug
    );
  }

  _calcDefaultZoomLevel() {
    const { buildingRes } = this.props;

    let zoom = 16;
    if (buildingRes && buildingRes.pipeline_height_proposed) {
      if (buildingRes.pipeline_height_proposed < 50) {
        zoom = 19;
      } else if (buildingRes.pipeline_height_proposed < 100) {
        zoom = 18;
      } else if (buildingRes.pipeline_height_proposed < 200) {
        zoom = 17;
      }
    }
    return zoom;
  }

  _openBuilding(building) {
    let address = _replaceAbbreviations(
      building.display_full_street_address
    ).toLowerCase();
    if (address.indexOf("garage") < 0 && address.indexOf("rear") < 0) {
      window.open(
        "/building/" +
          _nameToUrl(building.borough) +
          "/" +
          _nameToUrl(building.main_neighborhood) +
          "/" +
          building.key
      );
      // window.open(
      //   "https://nyc.marketproof.com/building/" +
      //     _nameToUrl(building.borough) +
      //     "/" +
      //     _nameToUrl(building.main_neighborhood) +
      //     "/" +
      //     building.main_building_slug +
      //     "?tab=overview"
      // );
    }
  }

  _showBuilding(building) {
    const gmapApiArr = process.env.GMAP_API_KEY;
    const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
    let buildingImages = _getBuildingPhotos(building, gApiKey);
    let image = `https://maps.googleapis.com/maps/api/streetview?${gApiKey}&size=700x700&location=${
      building.display_full_street_address
        ? building.display_full_street_address
        : building.full_street_address
    },${building.zip}&fov=90&pitch=10&source=outdoor`;
    if (buildingImages.length == 0) {
      buildingImages.push(image);
    }
    let adr = building.display_full_street_address
      ? _capitalizeText(building.display_full_street_address).trim()
      : building.primary_full_street_address
      ? _capitalizeText(building.primary_full_street_address).trim()
      : building.full_street_address
      ? _capitalizeText(building.full_street_address).trim()
      : false;

    if (
      building.borough == "queens" &&
      buildingName &&
      building.alternate_addresses
    ) {
      adr = _formatQueensAddress(adr, building.alternate_addresses);
    }

    let buildingName =
      building.name && building.name != building.display_full_street_address
        ? _capitalizeText(_urlToName(building.name.replace("corporation", "")))
        : false;

    return (
      <div
        className={`c_map-building-container`}
        key={`${building.key}`}
        onClick={(e) =>
          e.target.id != "mp_close" && this._openBuilding(building)
        }
      >
        <div className={`row`}>
          <div className={`col-xs-5 u_pd-rt-none`}>
            <div
              className={`img-responsive c_building-img`}
              style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}
            >
              <span className={`c_building-type`}>
                {_capitalizeText(_urlToName(building.ownership_type))}
              </span>
            </div>
          </div>
          <div className={`col-xs-7 u_pd-left-none c_building-info`}>
            <div className={`u_pd-5px`}>
              <div className="c_title u_600-wgt">
                {buildingName ? buildingName : adr}{" "}
                <i
                  id="mp_close"
                  className="fa fa-times u_font-20"
                  aria-hidden="true"
                  onClick={() => this.setState({ buildingsSelected: [] })}
                ></i>
              </div>
              <div className="u_font-11">{buildingName ? adr + ", " : ""}</div>
              <div className="u_font-11">
                {building.main_neighborhood
                  ? _capitalizeText(building.main_neighborhood) + ", "
                  : ""}
                {_capitalizeText(building.borough)}
              </div>

              <div className="u_font-12 u_pd-top-5px">
                {_shouldShow(building.year_built)
                  ? moment().year() < building.year_built
                    ? "Proj Completion " + building.year_built
                    : "Year Built " + building.year_built
                  : ""}
              </div>
              <div className="u_font-12">
                {building.total_units &&
                  (building.total_units > 1
                    ? building.total_units + " Units"
                    : "")}
                {building.total_floors && building.total_floors > 0
                  ? building.total_units > 1
                    ? " | " +
                      (building.total_floors > 1
                        ? building.total_floors + " Floors"
                        : building.total_floors + " Floor")
                    : building.total_floors > 1
                    ? building.total_floors + " Floors"
                    : building.total_floors + " Floor"
                  : false}
              </div>
            </div>
            <div className="c_tooltip-hint u_font-11">
              Click for details{" "}
              <i className="fa fa-external-link" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _setOccupancyFilter(type) {
    const { occupancyTypes } = this.state;

    let newOccupancyTypes = occupancyTypes.slice();
    if (newOccupancyTypes.indexOf(type) > -1) {
      newOccupancyTypes.splice(newOccupancyTypes.indexOf(type), 1);
    } else {
      newOccupancyTypes.push(type);
    }

    this.setState({ occupancyTypes: newOccupancyTypes });
  }

  _addPipelineTiles() {
    let filter = this._getPipelineFitlers();
    pipeline_tileset.forEach((tileset) => {
      if (!this.map.getSource(tileset.id)) {
        this.map.addSource(tileset.id, {
          type: "vector",
          url: tileset.url,
        });
      }
    });

    pipeline_tileset.forEach((tileset, i) => {
      // ALL buildings footprints - adds a layer for all the building outlines

      const default_filter = tileset.default_filter
        ? tileset.default_filter
        : false;
      let theFilter = filter.slice(0);
      if (default_filter) {
        theFilter = ["all", default_filter, filter];
      }
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 18,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-opacity": pipelineStyle.fillOpacity,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id);
      this.map.addLayer({
        id: tileset.layer_id + `-3d`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-extrusion-opacity": 0.8,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id + `-3d`);
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-hover",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", false],
      });
      this.map.addLayer({
        id: tileset.layer_id + `-3d-hover`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": "#FFD23F",
          "fill-extrusion-opacity": 1,
        },
        filter: ["==", "key", false],
      });
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-select",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "fill",
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", "FALSE"],
      });

      this.map.addLayer({
        id: tileset.layer_id + `-3d-select`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": "#FFD23F",
          "fill-extrusion-opacity": 1,
        },
        filter: ["==", "key", "FALSE"],
      });
    });

    pipeline_tileset.forEach((tileset) => {
      //Skip mouse events for mobile

      this.map.on(
        "mousemove",
        tileset.layer_id,
        function (e) {
          //console.log(tileset)
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mousemove",
        tileset.layer_id + "-3d",
        function (e) {
          console.log(tileset);
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id,
        function (e) {
          this._onMouseOut();
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id + "-3d",
        function (e) {
          this._onMouseOut(e);
        }.bind(this)
      );

      this.map.on(
        "click",
        tileset.layer_id,
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
      this.map.on(
        "click",
        tileset.layer_id + "-3d",
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
    });
  }

  _getPipelineFitlers() {
    const { occupancyTypes } = this.state;
    let filter = ["all"];
    if (occupancyTypes.length < 3) {
      let occupancyFilter = ["in", "pipeline_occupancy_type"].concat(
        occupancyTypes
      );
      filter.push(occupancyFilter);
    }
    if (filter.length === 1) {
      filter = ["has", "key"];
    }
    return filter;
  }
  /*
	shouldComponentUpdate(nextProps, nextState) {
		// console.log('should this.state.mapContainerWidth', this.state.mapContainerWidth, 'nextState.mapContainerWidth', nextState.mapContainerWidth)
		if (
			this.state.mapLoaded !== nextState.mapLoaded ||
			this.state.mapLoading !== nextState.mapLoading ||
			this.state.mapContainerWidth === 0 ||
			!_isEqual(this.state.buildingsSelected, nextState.buildingsSelected) ||
			!_isEqual(nextProps.buildingTags, this.props.buildingTags)
			// || this.props.parentFetchInProgress !== nextProps.parentFetchInProgress
		) {
			return true;
		} else {
			return false;
		}
	}
*/

  componentDidMount() {
    // console.log("BMapBox didUMount", this.props, this.state);

    this.tooltipContainer = document.createElement("div");
    const buildingRes = this.props.buildingRes
      ? this.props.buildingRes
      : this.props.axios &&
        this.props.axios.buildingAxios &&
        this.props.axios.buildingAxios[0]
      ? this.props.axios.buildingAxios[0]
      : false;

    if (
      buildingRes &&
      !buildingRes.future_building &&
      !this.state.mapLoaded &&
      this.props.mapContainerWidth !== 0
    ) {
      this._makeMap();
    }

    // forcing an update for listings bc container size is 0
    if (this.props.mapContainerWidth === 0) {
      this.setState({ mapContainerWidth: this.props.mapContainerWidth });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mapUnsupported === true) return;

    const buildingRes = this.props.buildingRes
      ? this.props.buildingRes
      : this.props.axios &&
        this.props.axios.buildingAxios &&
        this.props.axios.buildingAxios[0]
      ? this.props.axios.buildingAxios[0]
      : false;

    if (
      !_isEqual(this.props.buildingTags, prevProps.buildingTags) ||
      (buildingRes.future_building &&
        prevProps.parentFetchInProgress &&
        !this.props.parentFetchInProgress)
      // ||
      // !this.state.mapLoaded
    ) {
    }

    if (
      (!this.state.mapLoading && !this.state.mapLoaded) ||
      !_.isEqual(this.props.standardView, prevProps.standardView)
    ) {
      this._makeMap();
      if (this.state.mapContainerWidth !== this.props.mapContainerWidth) {
        this.setState((prevState) => ({
          mapContainerWidth: this.props.mapContainerWidth,
        }));
      }
    }
  }

  componentWillUnmount() {
    console.log("BMapBox unmounting");
  }

  _addSourceAndLayers(tileset) {
    this.map.addSource(tileset.id, {
      type: "vector",
      url: tileset.url,
    });

    this.map.addLayer({
      id: `${tileset.id}-layer-transparent`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-outline-color": "#000",
        "fill-color": "#363347",
        "fill-opacity": 0,
      },
    });

    this.map.addLayer({
      id: `${tileset.id}-layer`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#ccc",
        "fill-opacity": 0.1,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    this.map.addLayer({
      id: `${tileset.id}-layer-highlighted`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#6674AD",
        "fill-opacity": 0.3,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    const minWidth = 1;
    const minZoom = 10;

    this.map.addLayer({
      id: `${tileset.id}-layer-outline`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "line",
      paint: {
        "line-color": "#abb3d4",
        "line-width": 2,
        // 'line-width': {
        //   "type": "exponential",
        //   "base": 1.4
        //   ,
        //   "stops": [
        //     [9, minWidth * Math.pow(1.4, (9 - minZoom))],
        //     [15, minWidth * Math.pow(1.4, (15 - minZoom))],
        //   ]
        // }
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    const cb1 = (e) => {
      if (e.features.length > 0 && this.map.getZoom() < 14) {
        this._updateLocationMarker(e);

        if (
          !this.currentlyHoveredLocation ||
          (tileset.id.includes("boroughs") &&
            e.features[0].properties.boro_name !==
              this.currentlyHoveredLocation) ||
          e.features[0].properties.id !== this.currentlyHoveredLocation
        ) {
          this.currentlyHoveredLocation = tileset.id.includes("boroughs")
            ? e.features[0].properties.boro_name
            : e.features[0].properties.id;

          const highlightFilter = tileset.id.includes("boroughs")
            ? ["in", "boro_name", e.features[0].properties.boro_name]
            : ["in", "id", e.features[0].properties.id];

          this.map.setFilter(
            `${tileset.id}-layer-highlighted`,
            highlightFilter
          );
        }
      }
    };

    const cb2 = (e) => {
      this._updateLocationMarker(e);
      this.map.setFilter(`${tileset.id}-layer-highlighted`, [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ]);
      this.currentlyHoveredLocation = null;
    };

    const cb3 = (e) => {
      console.log("DUETING");
      this.map.off("mousemove", `${tileset.id}-layer`, cb1);
      this.map.off("mouseleave", `${tileset.id}-layer`, cb2);
      this.map.off("touchstart", `${tileset.id}-layer`, cb3);
    };

    this.map.on("mousemove", `${tileset.id}-layer`, cb1);
    this.map.on("mouseleave", `${tileset.id}-layer`, cb2);
    this.map.on("touchstart", `${tileset.id}-layer`, cb3);

    this.map.on("click", `${tileset.id}-layer`, (e) => {
      if (!this.justClickedSelectedMarker && !this.justClickedMarker) {
        const srcFilter = tileset.id.includes("boroughs")
          ? ["in", "boro_name", e.features[0].properties.boro_name]
          : ["in", "id", e.features[0].properties.id];

        const srcFeatures = this.map.querySourceFeatures(tileset.id, {
          sourceLayer: [tileset.source_layer],
          filter: srcFilter,
        });

        const poly = this._createPolygonsObj(srcFeatures, "single");

        const plygn = multiPolygon(poly);
        const boundBox = bbox(plygn);
        const boundPolygon = bboxPolygon(boundBox);
        const cent = center(boundPolygon);

        console.log("click zoom", this.map.getZoom());
        const zoom = tileset.id.includes("boroughs")
          ? 12.1
          : tileset.id.includes("markets")
          ? 13.1
          : 15.1;
        console.log("click zoom", tileset, zoom);
        const centerVal =
          this.map.getZoom() < 12
            ? cent.geometry.coordinates
            : e.lngLat.toArray();

        if (this.map.getZoom() < 14) {
          if (
            tileset.id.includes("boroughs") ||
            (tileset.id.includes("neighborhoods") &&
              this.map.getZoom() >= 12) ||
            this.props.isMobile ||
            tileset.id.includes("markets")
          ) {
            console.log("click zoom", centerVal, zoom);
            this.map.easeTo({
              center: centerVal,
              zoom,
            });
          } else {
            const padding =
              e.features[0].properties.boro_name === "Queens"
                ? 0.028
                : e.features[0].properties.id === "upper_manhattan_ny"
                ? 0.01
                : 0;

            this.map.fitBounds([
              [boundBox[0] + padding, boundBox[1] + padding],
              [boundBox[2] - padding, boundBox[3] - padding],
            ]);
          }
        }
      }
      this.justClickedSelectedMarker = false;
      this.justClickedMarker = false;
    });
  }

  _makeMap = () => {
    console.log("_makeMap called");
    // DOM not loaded yet so return out - unless it's a listing then call and resize
    if (
      this.props.mapContainerWidth === 0 &&
      this.state.mapContainerWidth === 0 &&
      !this.props.isListingForMobileHeroCarousel
    ) {
      console.log("returning out of _makeMap w/o calling map");
      return;
    }

    if (!mapboxgl.supported()) {
      return this.setState({ mapUnsupported: true });
    }

    this.setState({ mapLoading: true });

    const defaultZoom = this._calcDefaultZoomLevel();
    const { lng, lat, zoom } = this.state;
    const buildingRes = this.props.buildingRes
      ? this.props.buildingRes
      : this.props.axios &&
        this.props.axios.buildingAxios &&
        this.props.axios.buildingAxios[0]
      ? this.props.axios.buildingAxios[0]
      : false;

    let bounds = false;
    let mapData;
    if (!this.props.standardView) {
      mapData = {
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/ckyuong66001614ofy64tjg7c",
        zoom: this.props.fullSize ? 17 : 17,
        minZoom: 12,
        pitch: 30, // pitch in degrees
        bearing: -10, // bearing in degrees
      };
    } else {
      mapData = {
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/cjxxu65iq088b1cqgzu7sbxy2",
        zoom: this.props.fullSize ? 17 : 17,
        minZoom: 12,
        pitch: 30, // pitch in degrees
        bearing: -10, // bearing in degrees
      };
    }

    if (buildingRes.address && buildingRes.address.polygon_bbl_json) {
      var polygon_json = JSON.parse(buildingRes.address.polygon_bbl_json);
      var polygon = polygon_json.coordinates;
      // console.log('polygon', polygon)
      var fit = new L.Polygon(polygon).getBounds();
      var southWest = new mapboxgl.LngLat(
        fit["_southWest"]["lat"],
        fit["_southWest"]["lng"]
      );
      var northEast = new mapboxgl.LngLat(
        fit["_northEast"]["lat"],
        fit["_northEast"]["lng"]
      );

      // console.log("southWest", southWest, "northEast", northEast)
      var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
      // console.log("boundBox", boundBox)

      bounds = [
        boundBox.getSouthWest().toArray(),
        boundBox.getNorthEast().toArray(),
      ];
      mapData.bounds = bounds;
    } else if (buildingRes.address && buildingRes.address.geocode) {
      var geocode = MapUtil._parseCoordinates(buildingRes.address.geocode);
      var lngLat = new mapboxgl.LngLat(geocode[1], geocode[0]);
      mapData.center = lngLat;

      var polygon_json = MapUtil.circlePoly([geocode[1], geocode[0]], 0.02);
      var polygon = polygon_json.geometry.coordinates;
      var fit = new L.Polygon(polygon).getBounds();
      var southWest = new mapboxgl.LngLat(
        fit["_southWest"]["lat"],
        fit["_southWest"]["lng"]
      );
      var northEast = new mapboxgl.LngLat(
        fit["_northEast"]["lat"],
        fit["_northEast"]["lng"]
      );

      // console.log("southWest", southWest, "northEast", northEast)
      var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);

      bounds = [
        boundBox.getSouthWest().toArray(),
        boundBox.getNorthEast().toArray(),
      ];
      mapData.bounds = bounds;
    }

    const properties = {
      height: buildingRes.pipeline_height_proposed
        ? buildingRes.pipeline_height_proposed * 0.3048
        : buildingRes.pipeline_building_stories_proposed
        ? buildingRes.pipeline_building_stories_proposed * 3.5
        : 0,
    };
    this.map = new mapboxgl.Map(mapData);
    (!this.props.fullSize || this.props.freez) && this.map.scrollZoom.disable();
    //this.props.fullSize && this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    this.map.addControl(new mapboxgl.NavigationControl(), "top-right");
    this.map.addControl(
      new PitchToggle({
        minpitchzoom: this.props.fullSize ? 18 : 17,
        buildingRes: buildingRes,
      }),
      "top-right"
    );
    this.map.addControl(
      new mapboxgl.FullscreenControl({
        container: document.querySelector("#pipeline-map"),
      })
    );
    const canvas = this.map ? this.map.getCanvas() : undefined;
    if (canvas) {
      console.log("canvas.offsetWidth", canvas.offsetWidth);
    }

    if (
      (this.props.mapContainerWidth > 0 &&
        this.state.mapContainerWidth === 0) ||
      (this.props.mapContainerWidth === 0 &&
        canvas &&
        canvas.offsetWidth !== undefined &&
        (canvas.offsetWidth === 0 || canvas.offsetWidth === 400)) ||
      (this.props.mapContainerWidth === 0 &&
        canvas &&
        canvas.offsetWidth &&
        canvas.offsetWidth < this.props.mapContainerWidth - 32)
    ) {
      var map = L.map(this.mapContainer);
      map.invalidateSize();
      if (canvas) {
        console.log("canvas.offsetWidth", canvas.offsetWidth);
      }
    }

    this.map.on(
      "load",
      function () {
        // no map scroll on mobile views
        if (this.props.killMobileScroll) {
          this.map.dragPan.disable();
        }
        if (_.isEmpty(this.state.mapBounds)) {
          this.map.flyTo({ zoom: zoom });
        } else {
          this.map.flyTo({ center: this.state.mapBounds, zoom: zoom });
        }
        const layers = this.map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === "symbol" && layer.layout["text-field"]
        ).id;
        !this.props.isSharePage && this._addPipelineTiles();
        this._addSourceAndLayers(boroughTileset);
        this._addSourceAndLayers(majorMarketTileset);
        this._addSourceAndLayers(neighborhoodTileset);
        this.map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 14,
            layout: {
              visibility: "none",
            },
            paint: {
              "fill-extrusion-color": "#aaa",
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.7,
            },
          },
          labelLayerId
        );

        this.tooltip = new mapboxgl.Marker(this.tooltipContainer, {
          offset: [0, -165],
        })
          .setLngLat([0, 0])
          .addTo(this.map);
        building_tileset.filter((tileset) => {
          if (
            tileset.id.includes(buildingRes.address.borough) ||
            (buildingRes.address.borough == "staten island" &&
              tileset.id.includes("staten"))
          ) {
            // adding tilesets
            this.map.addSource(tileset.id, {
              type: "vector",
              url: tileset.url,
            });

            // ALL buildings footprints - adds a layer for all the building outlines
            this.map.addLayer({
              id: tileset.layer_id,
              type: "fill",
              source: tileset.id,
              "source-layer": tileset.source_layer,
              minZoom: 16,
              paint: {
                "fill-outline-color": "#000",
                "fill-color": buildingStyle.fillColor,
                "fill-opacity": buildingStyle.fillOpacity,
              },
            });

            // lot footprint
            if (polygon && polygon.length > 0) {
              this.map.addLayer({
                id: `mp-building-bbl-fill-${buildingRes.key}`,
                type: "fill",
                source: {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "Polygon",
                      coordinates: polygon,
                    },
                    properties: properties,
                  },
                },
                minZoom: 16,
                paint: {
                  "fill-outline-color": "#FFD23F",
                  "fill-color": "#FFD23F",
                  "fill-opacity": 0.5,
                },
              });
              this.map.addLayer({
                id: `mp-building-bbl-extrude-${buildingRes.key}`,
                type: "fill-extrusion",
                source: {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "Polygon",
                      coordinates: polygon,
                    },
                    properties: properties,
                  },
                },
                layout: {
                  visibility: "visible",
                },
                minZoom: 16,
                paint: {
                  "fill-extrusion-height": ["get", "height"],
                  "fill-extrusion-base": 0,
                  "fill-extrusion-color": "#FFD23F",
                  "fill-extrusion-opacity": 0.95,
                },
              });
              this.map.addLayer({
                id: `mp-building-bbl-${buildingRes.key}`,
                type: "line",
                source: {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "Polygon",
                      coordinates: polygon,
                    },
                    properties: properties,
                  },
                },
                minZoom: 16,
                paint: {
                  "line-color": "#000",
                  "line-width": this.props.fullSize ? 2 : 1,
                  "line-dasharray": this.props.fullSize ? [5, 5] : [1],
                },
              });
            }

            this.map.addLayer({
              id: tileset.layer_id + "-hover",
              type: "fill",
              source: tileset.id,
              "source-layer": tileset.source_layer,
              minzoom: 16,
              paint: {
                "fill-outline-color": "#000",
                "fill-color": "#9600BF",
                "fill-opacity": 0.8,
              },
              filter: ["==", "key", false],
            });

            // this building footprint
            this.map.addLayer({
              id: tileset.layer_id + "-select",
              source: tileset.id,
              "source-layer": tileset.source_layer,
              zoom: 16,
              minZoom: 16,
              maxZoom: 17,
              type: "fill",
              paint: {
                "fill-outline-color": "#000",
                "fill-color": "#9600BF",
                "fill-opacity": 0.6,
              },
              filter: [
                "==",
                "key",
                buildingRes && buildingRes.key ? buildingRes.key : "FALSE",
              ],
            });

            if (this.props.fullSize) {
              if (!this.props.isMobile && !this.props.isSharePage) {
                this.map.on(
                  "mousemove",
                  tileset.layer_id,
                  function (e) {
                    this._onMouseMove(e);
                  }.bind(this)
                );
                this.map.on(
                  "mouseout",
                  tileset.layer_id,
                  function (e) {
                    this._onMouseOut();
                  }.bind(this)
                );
              }
              if (!this.props.isSharePage) {
                this.map.on(
                  "click",
                  tileset.layer_id,
                  function (e) {
                    this._setMouseClick(e);
                  }.bind(this)
                );
              }
            }
          }
        });

        // future_building marker
        if (lngLat) {
          var map = this.map;
          var geojson = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: MapUtil._parseCoordinates(lngLat),
                },
                properties: {
                  title: "Future Building",
                  description: buildingRes.display_full_street_address
                    ? buildingRes.display_full_street_address
                    : buildingRes.full_street_address,
                },
              },
            ],
          };

          geojson.features.forEach(function (marker) {
            // create a HTML element for each feature

            const el = document.createElement("div");
            //el.className = 'fa fa-map-marker mapbox-marker';
            ReactDOM.render(<MapMarkerIcon color={"#780F9E"} />, el);
            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates)
              .addTo(map);
          });
        }
        this.setState({ mapLoaded: true, mapLoading: false });
      }.bind(this)
    );

    this.map.on("move", () => {
      const { lng, lat } = this.map.getCenter();

      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2),
        mapBounds: [lng, lat],
      });
    });

    this.map.on("zoom", () => {
      if (this.map.getZoom() < 15.5) {
        if (
          !this.marker &&
          buildingRes.address &&
          buildingRes.address.geocode
        ) {
          const el = document.createElement("div");
          //el.className = 'fa fa-map-marker mapbox-marker';
          ReactDOM.render(<MapMarkerIcon />, el);

          //console.log(buildingRes.geocode, this.map.getZoom());

          this.marker = new mapboxgl.Marker(el)
            .setLngLat(MapUtil._parseCoordinates(buildingRes.address.geocode))
            .addTo(this.map);
          let lotOutlineLayer = `mp-building-bbl-${
            buildingRes && buildingRes.bbl ? buildingRes.bbl : buildingRes.key
          }`;
          if (typeof this.map.getLayer(lotOutlineLayer) !== "undefined") {
            this.map.setLayoutProperty(lotOutlineLayer, "visibility", "none");
          }

          building_tileset.filter((tileset) => {
            let buildingSelectLayer = tileset.layer_id + "-select";
            if (typeof this.map.getLayer(buildingSelectLayer) !== "undefined") {
              this.map.setLayoutProperty(
                buildingSelectLayer,
                "visibility",
                "none"
              );
            }
          });
        }
      } else {
        if (this.marker) {
          this.marker.remove();
          this.marker = false;
          let lotOutlineLayer = `mp-building-bbl-${
            buildingRes && buildingRes.bbl ? buildingRes.bbl : buildingRes.key
          }`;
          if (typeof this.map.getLayer(lotOutlineLayer) !== "undefined") {
            this.map.setLayoutProperty(
              lotOutlineLayer,
              "visibility",
              "visible"
            );
          }

          building_tileset.filter((tileset) => {
            let buildingSelectLayer = tileset.layer_id + "-select";
            if (this.map.getLayer(buildingSelectLayer)) {
              this.map.setLayoutProperty(
                buildingSelectLayer,
                "visibility",
                "visible"
              );
            }
          });
        }
      }
    });

    /*
		this.map.on("click", e => {
			// console.log("this.map click")
			this.props.history.push(`/buildings?view=map&building=${this.props.buildingTags[0].slug}`)
		});
*/
  };

  render() {
    let dragging = false;
    var settings = {
      dots: false,
      infinite: true,
      speed: 200,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
    };

    if (this.state.mapUnsupported === true) {
      return (
        <div
          className={`c_map-wrapper ${this.props.isMobile ? "is-mobile" : ""}`}
          style={{
            position: "relative",
            height: this.props.fullSize
              ? "100%"
              : this.props.mapBoxSize
              ? this.props.mapBoxSize
              : "400px",
            width: "100%",
            background: "#363347",
            padding: "10px",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          <h4>Oops, your browser is not compatible with our maps.</h4>
          <p>Update your browser for the best experience on Marketproof.</p>
          <p>
            <a href="https://browsehappy.com/" target="_blank">
              <button className="c_primary-btn">Update Browser</button>
            </a>
          </p>
        </div>
      );
    }

    return (
      <div
        className={`c_map-wrapper ${this.props.isMobile ? "is-mobile" : ""}`}
        style={{
          position: "relative",
          height: this.props.height ? this.props.height : "400px",
          width: "100%",
        }}
      >
        <div
          className={`c_map-container ${
            this.props.isMobile ? "is-mobile" : ""
          }`}
          ref={(el) => (this.mapContainer = el)}
          style={{
            height: this.props.fullSize
              ? "100%"
              : this.props.height
              ? this.props.height
              : "400px",
            width: "100%",
          }}
        />
        {this.props.fullSize &&
          this.props.isMobile &&
          this.state.buildingsSelected && (
            <div className={`c_map-mobile-slider-outer`}>
              {this.state.buildingsSelected.map((building) => {
                return this._showBuilding(building);
              })}
            </div>
          )}
      </div>
    );
  }
}

class Tooltip extends React.Component {
  render() {
    const { features } = this.props;
    const renderFeature = (feature, i) => {
      console.log(feature);

      let buildingName =
        feature.properties.name &&
        feature.properties.name !=
          feature.properties.display_full_street_address
          ? _capitalizeText(
              _urlToName(feature.properties.name.replace("corporation", ""))
            )
          : false;
      const gmapApiArr = process.env.GMAP_API_KEY;
      const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];

      feature.properties["solr_key"] = GenerateBuildingKeyMap(
        feature.properties
      );

      let buildingImage = _getStreetPhoto(feature.properties, gApiKey);

      return (
        <div key={i} className="c_map-tooltip">
          <div className="c_img">
            <div
              style={{ backgroundImage: "url('" + buildingImage + "')" }}
              className="c_tooltip-img"
            >
              <StatusBadge building={feature.properties} />
              <span className={`c_building-type`}>
                <StarIcon
                  size={16}
                  color={"#FFD23F"}
                  style={{ marginTop: -3 }}
                />{" "}
                Pipeline Project
              </span>
            </div>
          </div>
          <div className="c_info">
            <div className="c_tooltip-content">
              {buildingName ? (
                <div className="">
                  <h5 className="c_header">{buildingName}</h5>
                  {buildingName.toLowerCase() !==
                    feature.properties.display_full_street_address.toLowerCase() && (
                    <div className="u_font-12">
                      {_capitalizeText(
                        _replaceAbbreviations(
                          feature.properties.display_full_street_address
                        )
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <h5 className="c_header">
                  {_capitalizeText(
                    _replaceAbbreviations(
                      feature.properties.display_full_street_address
                    )
                  )}
                </h5>
              )}
              <div style={{ marginLeft: -10, marginBottom: 5 }}>
                <BuildingStageBadge item={feature.properties} small />
              </div>
              <div className="">
                <span className="light-text small-text">
                  {feature.properties.pipeline_zoning_area_residential > 0
                    ? _formatNumber(
                        feature.properties.pipeline_zoning_area_residential
                      ) + " Resi SF"
                    : ""}
                </span>
                {feature.properties.pipeline_zoning_area_residential > 0 &&
                  feature.properties.pipeline_total_construction_floor_area >
                    0 && <span className="small-divider">|</span>}
                <span className="light-text small-text">
                  {feature.properties.pipeline_total_construction_floor_area > 0
                    ? _formatNumber(
                        feature.properties
                          .pipeline_total_construction_floor_area
                      ) + " Total SF"
                    : ""}
                </span>
              </div>
              <span className="light-text small-text">
                {_formatNumber(
                  feature.properties.pipeline_building_stories_proposed
                )}{" "}
                Floors
              </span>

              {feature.properties.pipeline_dwelling_units_proposed > 0 && (
                <>
                  <span className="small-divider">|</span>
                  <span className="light-text small-text">
                    {feature.properties.pipeline_dwelling_units_proposed > 0
                      ? _formatNumber(
                          feature.properties.pipeline_dwelling_units_proposed
                        )
                      : ""}{" "}
                    Units
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="c_tooltip-hint u_font-11">
            Click for details{" "}
            <i className="fa fa-external-link" aria-hidden="true" />
          </div>
        </div>
      );
    };
    return <div>{features && features.slice(0, 1).map(renderFeature)}</div>;
  }
}

export default BuildingMapBox;
