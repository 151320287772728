export const _hasPermission = (user, features) => {
  if(!user) return false;
  const _roleMap = {
	   "all":[],
	   "pipeline-basic":[
		   "pipeline-search",
		   "pipeline-map",
	   ],
	   "pipeline-complete":[
	    	"pipeline-search",
			"pipeline-map",
			"company-search",
			"people-search",
			"pipeline-analysis",
			"permits-filings",
			"transfers",
			"project-sharing",
			"collections",
			"project-contacts",
			"violations-tab",
			"finance-tab",
			"offering-tab",
			"change-branding",
			"ag-link"
	   ],
	   "pipeline-enterprise":[
	     	"pipeline-search",
	   		"pipeline-map",
	   		"pipeline-analysis",
	   		"company-search",
	   		"people-search",
	   		"pipeline-analysis",
	   		"permits-filings",
	   		"transfers",
	   		"project-sharing",
	   		"collections",
			"project-contacts",
	   		"violations-tab",
	   		"finance-tab",
	   		"offering-tab",
	   		"admin-tools",
	   		"download-image",
	   		"change-branding",
	   		"ag-link"
		  
	   ],
	   "pipeline":[
				"pipeline-search",
				  "pipeline-map",
				  "pipeline-analysis",
				  "company-search",
				  "people-search",
				  "pipeline-analysis",
				  "permits-filings",
				  "transfers",
				  "project-sharing",
				  "collections",
			   "project-contacts",
				  "violations-tab",
				  "finance-tab",
				  "offering-tab",
				  "admin-tools",
				  "download-image",
				  "change-branding",
				  "ag-link"
			 
		  ],
	   "admin":[
	     "pipeline-search",
		 "pipeline-map",
		 "pipeline-analysis",
		 "company-search",
		 "people-search",
		 "pipeline-analysis",
		 "permits-filings",
		 "transfers",
		 "project-sharing",
		 "collections",
	     "project-contacts",
		 "violations-tab",
		 "finance-tab",
		 "offering-tab",
		 "admin-tools",
		 "download-image",
		 "change-branding",
		 "ag-link"
	   ],
	   "data-export":[
	      "data-export"
	   ]}
  
  features = !Array.isArray(features) ? [features] : features;
  
  const userRoles = !Array.isArray(user.role) ? [] : user.role.slice(0);
  
  userRoles.push('all');
  
  if(!_isNewUser(user)){
	  userRoles.push('advanced');
  }
  
  let hasPermission = false;
 
  if(userRoles && Array.isArray(userRoles)){ 	  
	  userRoles.map(role => {
		  if(_roleMap[role] && Array.isArray(_roleMap[role])){
			  _roleMap[role].map(feature => {
				  if(features.indexOf(feature)>=0){
					  hasPermission = true;
				  }				  
			  });			  
		  }		  
	  });		 		 
  }
  
  return hasPermission;
};

export const _isNewUser = (user) => {
		
	if(user && user.trialStart && user.trialStart > 1607558400){
		return true;
	}
	return false;	
}

export const _shareThemes = {
	"douglas-elliman" : {
		"display_name" : "Douglas Elliman",
		"header_link" : "https://www.elliman.com/"
	},
	"eklund-gomes" : {
		"display_name" : "Eklund | Gomes",
		"header_link" : "https://theeklundgomesteam.elliman.com"
	},
	"nestseekers" : {
		"display_name" : "NestSeekers",
		"header_link" : "https://www.nestseekers.com"
	},
	"serhant" : {
		"display_name" : "SERHANT",
		"header_link" : "https://www.serhant.com"
	},
	"triumph" : {
		"display_name" : "TRIUMPH",
		"header_link" : "https://www.triumphproperty.com/"
	}
}