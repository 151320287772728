import axios from "axios";
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_formatAddressAutoComplete
} from '../../../../../shared/helpers/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = (options, { inputValue }) => { return options }
const apiUrl = process.env.API_ROOT;  



const renderNeighborhoodSearch = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState(null);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    axios({ method:'get', url: `${apiUrl}/pipeline/autocomplete/neighborhood?input=${inputValue}`   }).then((results) => {
      if (active) {
        let newOptions = [];


        if (results && results.data) {
          newOptions = [...newOptions, ...results.data];
        }
		if(newOptions.length>0){
			newOptions = newOptions.map(option => {
				return option.neighborhood
				
			});
		}
		
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  React.useEffect(() => {
  	  setValue(props.value);
  }, [props.value]);

  return (
    <Autocomplete
      freeSolo
      id="asynchronous-demo"
      style={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={filterOptions}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => _capitalizeText(option)}
      options={options}
      loading={false}
      value={value}
      label={inputValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        props.onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputValue && inputValue ? _capitalizeText(inputValue) : "Neighborhood"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {false ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        return (
          <div>{_capitalizeText(option)}</div>
        );
      }}
    />
  );
}

export default renderNeighborhoodSearch;