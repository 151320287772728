import React from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Polygon,
  Marker
} from "react-google-maps";

const styles = {
  default: [
    { stylers: [{ hue: "#009ECB" }, { saturation: -60 }] },
    {
      //points of interest
      featureType: "poi.business",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }]
    }
  ],
  listing: [
    { stylers: [{ hue: "#009ECB" }, { saturation: -60 }] },
    {
      //points of interest
      featureType: "poi.business",
      stylers: [{ visibility: "on" }]
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }]
    }
  ]
};

const map_marker = {
  path:
    "M242.606,0C142.124,0,60.651,81.473,60.651,181.955s151.631,303.257,181.956,303.257c30.326,0,181.955-202.775,181.955-303.257S343.089,0,242.606,0zM242.606,303.257c-66.9,0-121.302-54.433-121.302-121.302S175.706,60.651,242.606,60.651c66.902,0,121.302,54.435,121.302,121.304S309.509,303.257,242.606,303.257z",
  fillColor: "#376ee2",
  fillOpacity: 0.7,
  strokeWeight: 1,
  scale: 0.08
};

const listingMarker = {
  path:
    "m1152,640q0,-106 -75,-181t-181,-75t-181,75t-75,181t75,181t181,75t181,-75t75,-181zm256,0q0,109 -33,179l-364,774q-16,33 -47.5,52t-67.5,19t-67.5,-19t-46.5,-52l-365,-774q-33,-70 -33,-179q0,-212 150,-362t362,-150t362,150t150,362z",
  fillColor: "#780F9E",
  fillOpacity: .9,
  // strokeWeight: 1,
  scale: 0.025,
};


const GMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      // onBoundsChanged={someEventHandler.bind(this)}
      defaultZoom={17}
      defaultCenter={{ lat: props.lat, lng: props.long }}
      defaultOptions={ {
              styles: !props.isListing ? styles.default : styles.listing,             
              scrollwheel: false,
          }}
    >

        <Marker
          position={{ lat: props.lat, lng: props.long }}
          icon={listingMarker}
          onClick={props.buildingData 
            ? () => window.open(`https://www.google.com/maps/search/?api=1&query=${props.buildingData.display_full_street_address ? props.buildingData.display_full_street_address : props.buildingData.full_street_address}, ${props.buildingData.zip}`)
            : null
          }
        />
        

    </GoogleMap>
  ))
);

export default GMap;
