import React, { Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _checkBuildingAltAdr,
  _showAltAdrs,
  _getCrossStreet,
} from "../../../../../shared/helpers/utils";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import { relativeToValue } from "@amcharts/amcharts4/.internal/core/utils/Utils";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 13px)",
    right: "calc(50% + 13px)",
  },
  active: {
    "& $line": {
      borderColor: "#9600BF",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#9600BF",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    position: 'relative',
    width: '42px',
    border: '2px solid #9600BF',
    height: '42px',
    textAlign: 'center',
    padding: '0px',
    display: 'block',
    lineHeight: '36px',
    borderRadius: '100%',
    top: '-10px',
    left: '-6px',
    zIndex: '1',
  },
  circleError :{
    backgroundColor: '#ffffff',
    position: 'relative',
    width: '42px',
    border: '2px solid #F53246',
    height: '42px',
    textAlign: 'center',
    padding: '0px',
    display: 'block',
    lineHeight: '36px',
    borderRadius: '100%',
    top: '-10px',
    left: '-6px',
    zIndex: '1',
  },
  circle: {
    width: '42px',
    border: '2px solid #DBE0E8',
    height: '42px',
    position: 'relative',
    borderRadius: '100%',
    backgroundColor: '#fff',
    top: '-2px',
    left: '-2px',
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "12px",
      height: "12px",
      backgroundColor: "#F5F7FA",
      borderRadius: "100%",
      transform: "translate(-50%,-50%)",
    },
  },
  active: {
    color: "#9600BF",
    position: "relative",
    "& > $circle": {
      border: "1px solid #9600BF",
      "&::before": {
        display: "none",
      },
    },
  },
  error: {
    color: "#F53246",
    
    position: "relative",
    "& > $circle": {
      border: "1px solid #F53246",
      "&::before": {
        display: "none",
      },
    },
  },
  completed: {
    color: "#9600BF",
    zIndex: 1,
    fontSize: 18,
    position: 'relative',
    

  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiStepIcon-active": { color: "#780F9E" },
    "& .MuiStepIcon-completed": { color: "#9600BF" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#A3ADC2" },
    textAlign: "center",
    "& > .MuiStepper-root" : {
      padding:"24px 0"
    }
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Pre-development", "Construction", "Operation"];
}

const stages = {
  planning: 0,
  construction: 1,
  completion: 2,
};

const mileStonesStage = {
  land_acquired_date: 0,
  loan_secured_date: 1,
  pipeline_filing_date: 2,
  pipeline_permit_issuance_job_start_date: 3,
  pipeline_coo_issue_type: 4,
};

export default function ProjectProgress(props) {
  const classes = useStyles();
  const { buildingRes } = props;
  const stage =
    buildingRes.project_stage &&
    buildingRes.project_stage.pipeline_project_stage
      ? buildingRes.project_stage.pipeline_project_stage
      : buildingRes.pipeline_project_stage;

  const [activeStep, setActiveStep] = React.useState((buildingRes.pipeline_project_abandoned === true || buildingRes.pipeline_project_stalled === true) ? stages[stage]+1 : stages[stage]);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  if( buildingRes.pipeline_project_abandoned === true ){
    steps.splice(activeStep, 0, "Abandoned");
  }else if( buildingRes.pipeline_project_stalled === true ){
    steps.splice(activeStep, 0, "Stalled");
  }
  
  
  
  const customSteps = buildingRes && buildingRes.milestone && buildingRes.milestone.length > 1 ?  buildingRes.milestone : false;

  const _getMilstones = (data, state) => {
    let returnedElement = [];
    for (const mile of data) {
      if (mile.Milestones === "land_acquired_date" && state === 1) {
        returnedElement.push(
          <span >
            
            {`Land Acquired ${_capitalizeText(moment(mile.Date).fromNow())}`}
          </span>
        );
      }
      if (mile.Milestones === "loan_secured_date" && state === 1) {
        returnedElement.push(
          <span >
            
            {`Financing Secured Date ${_capitalizeText(
              moment(mile.Date).fromNow()
            )}`}
          </span>
        );
      }
    }
    return returnedElement;

    // data.forEach((mile) => {

    // });
  };

  const stageInfo = (step) => {
    if (step == "Pre-development") {
      return (
        <Fragment>
          {buildingRes.pipeline_filing_date &&
          parseInt(moment(buildingRes.pipeline_filing_date).format("YYYY")) >
            1970
            ? `Construction Permits Filed ${_capitalizeText(
                moment(buildingRes.pipeline_filing_date).fromNow()
              )}`
            : buildingRes.pipeline_pre_filing_date &&
              parseInt(
                moment(buildingRes.pipeline_pre_filing_date).format("YYYY")
              ) > 1970
            ? `Construction Permit Filed ${_capitalizeText(
                moment(buildingRes.pipeline_pre_filing_date).fromNow()
              )}`
            : activeStep > 0
            ? "Construction Permits Filed"
            : "Construction Permits Not Yet Filed"}
          {buildingRes.pipeline_filing_date &&
          parseInt(moment(buildingRes.pipeline_filing_date).format("YYYY")) >
            1970 ? (
            <span >
              
              {_capitalizeText(
                moment(buildingRes.pipeline_filing_date).format("MM/DD/YYYY")
              )}
            </span>
          ) : buildingRes.pipeline_pre_filing_date &&
            parseInt(
              moment(buildingRes.pipeline_pre_filing_date).format("YYYY")
            ) > 1970 ? (
            <span >
              
              {_capitalizeText(
                moment(buildingRes.pipeline_pre_filing_date).format(
                  "MM/DD/YYYY"
                )
              )}
            </span>
          ) : null}
          {buildingRes &&
            buildingRes.milestone &&
            buildingRes.milestone.length > 0 && (
              <>
                {_getMilstones(buildingRes.milestone, 1).map(
                  (element, index) => {
                    return <Fragment key={index}>{element}</Fragment>;
                  }
                )}
              </>
            )}
        </Fragment>
      );
    } else if (step == "Construction") {
      return (
        <Fragment>
          {buildingRes.pipeline_permit_issuance_issuance_date &&
          parseInt(
            moment(buildingRes.pipeline_permit_issuance_issuance_date).format(
              "YYYY"
            )
          ) > 1970
            ? `Construction Permits Issued ${_capitalizeText(
                moment(
                  buildingRes.pipeline_permit_issuance_issuance_date
                ).fromNow()
              )}`
            : buildingRes.pipeline_permit_issuance_job_start_date &&
            parseInt(
              moment(
                buildingRes.pipeline_permit_issuance_job_start_date
              ).format("YYYY")
            ) > 1970 ?
              `Construction Permits Issued ${_capitalizeText(
                moment(
                  buildingRes.pipeline_permit_issuance_job_start_date
                ).fromNow()
              )}`
            : buildingRes.pipeline_demolition_issuance_date &&
            parseInt(
              moment(
                buildingRes.pipeline_demolition_issuance_date
              ).format("YYYY")
            ) > 1970 ?
              `Demolition Permits Issued ${_capitalizeText(
                moment(
                  buildingRes.pipeline_demolition_issuance_date
                ).fromNow()
              )}`
            : activeStep >= 1
            ? "Permits Issued"
            : "Permits Not Yet Issued "}
          {buildingRes.pipeline_permit_issuance_issuance_date &&
            parseInt(
              moment(
                buildingRes.pipeline_permit_issuance_issuance_date
              ).format("YYYY")
            ) > 1970 ? (
              <span >
                
                {_capitalizeText(
                  moment(
                    buildingRes.pipeline_permit_issuance_issuance_date
                  ).format("MM/DD/YYYY")
                )}
              </span>
            ) : buildingRes.pipeline_permit_issuance_job_start_date &&
            parseInt(
              moment(
                buildingRes.pipeline_permit_issuance_job_start_date
              ).format("YYYY")
            ) > 1970 ? (
              <span >
                
                {_capitalizeText(
                  moment(
                    buildingRes.pipeline_permit_issuance_job_start_date
                  ).format("MM/DD/YYYY")
                )}
              </span>
            ) : buildingRes.pipeline_demolition_issuance_date &&
            parseInt(
              moment(
                buildingRes.pipeline_demolition_issuance_date
              ).format("YYYY")
            ) > 1970 ? (
              <span >
                
                {_capitalizeText(
                  moment(
                    buildingRes.pipeline_demolition_issuance_date
                  ).format("MM/DD/YYYY")
                )}
              </span>
            ) : null
          
          }
        </Fragment>
      );
    } else if (step == "Operation") {
      return (
        <Fragment>
          {buildingRes.pipeline_coo_issuance_date &&
          parseInt(
            moment(buildingRes.pipeline_coo_issuance_date).format("YYYY")
          ) > 1970
            ? `TCO / CO Issued ${_capitalizeText(
                moment(buildingRes.pipeline_coo_issuance_date).fromNow()
              )}`
            : activeStep >= 2
            ? "TCO / CO Issued"
            : "TCO / CO Not Yet Issued "}
          {buildingRes.pipeline_coo_issuance_date &&
            parseInt(
              moment(buildingRes.pipeline_coo_issuance_date).format("YYYY")
            ) > 1970 && (
              <span >
                
                {_capitalizeText(
                  moment(buildingRes.pipeline_coo_issuance_date).format(
                    "MM/DD/YYYY"
                  )
                )}
              </span>
            )}
        </Fragment>
      );
    }else if (step == "Abandoned") {
      return (
        <Fragment>
            No Progress Made
            <span>
            (Extended Time Period)
            </span>           
        </Fragment>
      );
    }else if (step == "Stalled") {
      return (
        <Fragment>   
            No Progress Made
            <span>
             (No Recent Activity)
            </span>          
        </Fragment>
      );
    }
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed, error } = props;
    if(error){
      return (
        <div
          className={clsx(classes.root, {
            [classes.error]: error,
          }) }
        >
          <Close
            className={classes.completed}
          />
        </div>
      );
    }
    
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        }) }
      >
        {completed ? (
          <Check
            className={classes.completed}
          />
        )  : (
          <div className={classes.circle} />
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      
        {customSteps && customSteps.length> 0 ? 
            <Stepper
              activeStep={customSteps.filter(milestone => milestone.Completed == true).length-1}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {customSteps.map((milestone, index) => {
                const stepProps = {};
                const labelProps = {};
                labelProps.optional = (
                  <p variant="caption" className="project_contact_bar_subheading">
                    {milestone.Completed && milestone.Date ? _capitalizeText(
                        moment(milestone.Date).format(
                          "MM/DD/YYYY"
                        )
                      ) : milestone.Completed ? "Milestone Reached" : "Milestone Not Yet Reached"}
                  </p>
                );
                labelProps.completed = milestone.Completed;
                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps} StepIconComponent={QontoStepIcon}>
                      <h3 className="project_contact_bar_title">{milestone.Description ? milestone.Description : _capitalizeText(milestone.Milestone.replace(/_/g, " "))}</h3>
                    </StepLabel>
                  </Step>
                );
              })}              
          </Stepper>
          :
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<QontoConnector />}
          >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            labelProps.optional = (
              <p variant="caption" className="project_contact_bar_subheading">
                {stageInfo(label)}
              </p>
            );
            
            if(["Abandoned", "Stalled"].indexOf(label)>=0){
              
              labelProps.error = true;
            }else{
              labelProps.completed = index <= activeStep;
            }
            return (  
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} StepIconComponent={QontoStepIcon}>
                  <h3 className="project_contact_bar_title">{label} </h3>
                </StepLabel>
              </Step>
              
            );
            
          })}
          </Stepper>
        }
    </div>
  );
}
