import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CardBody } from 'reactstrap';

import GMap from "./GMap";

import {
	_formatLatLong,
} from "./../../helpers/utils";

const ShowMap = ( { item, height } ) => {
console.log(item, height);	

	let geocode = item.geocode ? item.geocode : item.address.geocode ? item.address.geocode : false;
	
	if(!geocode) return null;
	const mapPoints = _formatLatLong(geocode); 	
console.log(mapPoints)	
	return( 
	  
      <GMap 
        buildingData={item}
        isListing={true}
        isMarkerShown={true}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEYB3e7_LMx9R5aEx3y8w99Cv2s3XJy_o&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: height ? height : `300px` }} />}
        lat={mapPoints ? mapPoints.lat : null}
        long={mapPoints ? mapPoints.long : null}
        mapPadding={undefined}
      />

    );
};

export default ShowMap;
