import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { connect } from "react-redux";
import { withOktaAuth } from "@okta/okta-react";
import { setUserProfile } from "../../../redux/actions/authActions";
import { Publisher } from "./../../../shared/pubsub/publisher.js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import { _hasPermission } from "./../../../shared/helpers/access";
import{
  _doSSOLogout,
  _setSSOToken,
  _getSSOToken
} from './../../../shared/components/auth/sso/ssoutils';
class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { authenticated: null };
    this.logout = this.logout.bind(this);
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  async logout() {
    const { authState, authService, dispatch, user, dataSet } = this.props;
    if(!authState.isPending && authState.isAuthenticated){
      authService.logout('/');
      dispatch(setUserProfile(null));
      
      Publisher.publish("logout", {});
    }else if(user.authType === "sso"){
      _doSSOLogout(this.props, setUserProfile);
      
    }
   
  }

  sideRoleContent = (user) => {
    if (!user) return null;
    if (!user.accountType) user.accountType = "buyer";

    switch (user.accountType) {
      default:
        return (
          <Fragment>
            <ul className="sidebar__block sidebar__bold">
              <SidebarCategory title="My Dashboard" icon="heart" collapse>
                <SidebarLink
                  title="Overview"
                  route="/dashboard"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="News"
                  route="/news"
                  onClick={this.hideSidebar}
                />
                
                <SidebarLink
                  title="Saved"
                  route="/saved"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Shared"
                  route="/my-shared"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Collections"
                  route="/my-collections"
                  
                  onClick={this.hideSidebar}
                /> 
                <SidebarLink
                  title="My Contacts"
                  route="/my-contacts"
                  
                  onClick={this.hideSidebar}
                />
                {/*<SidebarLink title="My Downloads" route="/my-exports" onClick={this.hideSidebar} />*/}
              </SidebarCategory>
            </ul>

            {/*<ul className="sidebar__block">
						<SidebarLink title="Transaction Center" icon="cart" route="/transaction-center" onClick={this.hideSidebar} />
						<SidebarLink title="Community Center" icon="bubble" route="/community-center" onClick={this.hideSidebar} />
					</ul>*/}
            {isMobile ? (
              <Fragment>
                <ul className="sidebar__block sidebar__bold">
                  <SidebarLink
                    title="Pipeline"
                    route="/pipeline-search"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    title="Companies"
                    route="/companies"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    title="People"
                    route="/people"
                    onClick={this.hideSidebar}
                  />
                </ul>
              </Fragment>
            ) : (
              <ul className="sidebar__block sidebar__bold">
                <SidebarCategory
                  title="Pipeline Projects"
                  newLink
                  icon="construction"
                  collapse
                >
                  <SidebarLink
                    title="Search"
                    route="/pipeline-search"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    title="Map"
                    route="/pipeline-map"
                    onClick={this.hideSidebar}
                  />
                 
                </SidebarCategory>
                <SidebarCategory title="Contacts"  icon="users" collapse>
                  <SidebarLink
                    title="Companies"
                    route="/companies"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    title="People"
                    route="/people"
                    onClick={this.hideSidebar}
                  />
                </SidebarCategory>

                <SidebarCategory
                  title="Research Tools"
                  newLink
                  icon="chart-bars"
                  collapse
                >
                  <SidebarLink
                    
                    title="Pipeline Analysis"
                    route="/pipeline-analysis"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    title="Filing Activity"
                    route="/filings"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    title="Transfers"
                    route="/transfers"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    newLink
                    title="Permits"
                    route="/permits"
                    onClick={this.hideSidebar}
                  />
                  {/* <SidebarLink title="Developers" route="/developers" onClick={this.hideSidebar} />
								<SidebarLink title="Architects" route="/architects" onClick={this.hideSidebar} />
								<SidebarLink title="Lenders" route="/lenders" onClick={this.hideSidebar} />*/}
                </SidebarCategory>
                {/* <SidebarCategory
                  title="Permit Search"
                  newLink
                  icon="chart-bars"
                  collapse
                >
                  <SidebarLink
                    newLink
                    title="Permits Filed"
                    route="/permits"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    newLink
                    title="Permits Issued"
                    route="/filings"
                    onClick={this.hideSidebar}
                  />
                
                </SidebarCategory> */}
              </ul>
            )}
          </Fragment>
        );

        break;
    }
  };

  render() {
    const { changeToDark, changeToLight, user } = this.props;
    return (
      <div className="sidebar__content">
        {this.sideRoleContent(user)}

        <ul className="sidebar__block">
          {/*<SidebarCategory title="Settings" icon="cog">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Mode</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Mode</p>
            </button>
          </SidebarCategory>*/}
          {/*<SidebarLink title="Log Out" icon="exit" onClick={this.logout} />
          <SidebarLink title="My Account" icon="user" route="/my-account"  onClick={this.hideSidebar} />*/}
          <SidebarCategory title="My Account" icon="user">
            <SidebarLink
              title="Profile & Password"
              route="/my-account"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Subscription & Billing"
              route="/my-subscription"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>

          <li className="sidebar__link" onClick={this.logout}>
            <span className={`sidebar__link-icon lnr lnr-exit`} />
            <p className="sidebar__link-title">Log Out</p>
          </li>

          {isMobile && (
            <div
              style={{
                margin: 10,
                padding: "10px 30px",
                background: "#fafbfe",
                fontSize: "11px",
              }}
            >
              For access to all tools,
              <br /> use the desktop version.
            </div>
          )}
        </ul>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.user,
}))(withOktaAuth(SidebarContent));
