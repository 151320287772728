import React, { PureComponent, Fragment } from 'react';
import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Modal
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import AddToBuyersListButton from '../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../shared/components/buttons/ShareButton';

import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import moment from "moment";
import divr from 'react-slick';

import ShareTopBar from './../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../shared/components/ShareContact'
import ShareFooter from './../../../../shared/components/ShareFooter'
import UnitIcons from './../../../../shared/components/UnitIcons';

import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';

import ExportIcon from 'mdi-react/FilePdfBoxIcon';

import { FreezeTableBuildings, FreezeTableUnits } from './components/FreezeTables';
import { BuildingGridView, UnitsGridView } from './components/GridViews';

import { BuildingAnalysisView, UnitsAnalysisView } from './components/AnalysisViews';
import Fab from '@material-ui/core/Fab';

import { withOktaAuth } from '@okta/okta-react';

import BuildingProfile from './components/BuildingProfile';
import BuildingStats from './components/BuildingStats';
import BuildingMatrix from './components/BuildingMatrix';
import CompMatrix from './components/CompMatrix';

import { Publisher } from './../../../../shared/pubsub/publisher.js';

import UnitMix from './components/analysis/UnitMix';
import UnitBreakdown from './components/analysis/UnitBreakdown';
import Map from './components/Map';
import StatBox from './components/analysis/StatBox';
import Borough from './components/analysis/Borough';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_validUnitSources,
	propertyExportFL,
	pipelineCohortQuery,
	mpndCohortQuery,
	resaleCohortQuery
} from '../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../shared/components/auth/sso/ssoutils';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


	  
import './pdf.scss';
const apiURL = process.env.API_ROOT + '/query/';
const buildingUrl = process.env.API_ROOT + "/";

const oktaToken = process.env.OKTA_TOKEN;


const settings = {
	  dots: false,
	  infinite: false,
	  speed: 500,
	  autoplay: false,
	  swipeTodiv: true,
	  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
	  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
	  responsive: [
		{ breakpoint: 768, settings: { divsToShow: 1, divsToScroll: 1 } },
		{ breakpoint: 992, settings: { divsToShow: 2, divsToScroll: 2 } },
		{ breakpoint: 1200, settings: { divsToShow: 3, divsToScroll: 3 } },
		{ breakpoint: 1536, settings: { divsToShow: 4, divsToScroll: 4 } },
		{ breakpoint: 100000, settings: { divsToShow: 4, divsToScroll: 4} },
	  ],
  };	

const checkElement = async selector => {
	while ( document.querySelector(selector) === null) {
	  await new Promise( resolve =>  requestAnimationFrame(resolve) )
	}
	return document.querySelector(selector); 
};
  
class CompReportShare extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  activeTab:  'buildings',      
	  fetchInProgress: false,
	  buildingRes: null,
	  unitRes: null,
	  userRes:null,
	  listingRes: null,
	  buildingsFetchInProgress: false,
	  modal: false,
	  modalShareData:false,
	  shareType:false,
	  unitStats: false,
	  buildingStats: false,
	  statsRes: null,
	  isPrint: false,
	  divNumber: 0,
	  marketStats: null,
	  exportProgress: false,
	  contentLoaded: false
	};
	this.buildingSlug = false;
	this.urlState = {};
	this._mapLoaded = this._mapLoaded.bind(this);
  }
  
  componentDidMount() {
	  
	const { sharePageData } = this.props;
	
	if (sharePageData) {
	  this.buildingSlug = sharePageData.building_key;
	
	  this.urlState = sharePageData.custom_data
		? sharePageData.custom_data
		: {};
	  if (
		!(
		  sharePageData &&
		  sharePageData.custom_data &&
		  sharePageData.custom_data.group &&
		  sharePageData.custom_data.group.group_data &&
		  sharePageData.custom_data.group.group_data.projects &&
		  sharePageData.custom_data.group.group_data.projects.length > 0
		)
	  ) {
		this.setState({ activeTab: "buildings" });
	  }
	}
	
	this._getBuildings();
	this._getUserData();
	const isPrint = this.props.location && this.props.location.search && this.props.location.search.indexOf("?exportMode=true")>=0;
	
	 this.setState({isPrint:true})
		
	 
	Publisher.publish(`track-mp.shareView.collection`, {collection: sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true });   
	
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {	
	 const { sharePageData } = this.props;
	 const { statsRes, userRes, buildingRes, unitRes, isPrinted} = this.state;
	 if(!prevState.buildingRes && this.state.buildingRes){
		Publisher.publish(`track-mp.shareView.collection`, {collection: sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true }); 
	 }	
	 
	
	 
  }
  
  _mapLoaded(loaded){
	  this.setState({contentLoaded:loaded});
  }


  
  _getUserData = (key) => {
	 // console.log('_getBuildingData called', this.state)
	  const { sharePageData, updateShareData } = this.props;
	  if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
	  }
			
	  let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  
	  let userKey = sharePageData && sharePageData.user_key ? sharePageData.user_key : key;
	  
	  if(!userKey && sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.user_key){
		  userKey = sharePageData.custom_data.group.user_key;
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${userKey}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  
		  if(res && res.errorCode && res.errorCode == "E0000007"){
			  _getUser({id:userKey}).then(ssoUser  => {
				  console.log(ssoUser)
				  if(ssoUser){
					  this.setState({
						  userRes:ssoUser,
						  fetchInProgress: false
					  })
					  
					  updateShareData(Object.assign({}, sharePageData, {user:ssoUser} ))
				  }
				  
			  })
		  }else{
				this.setState({
					userRes:res && res.profile ? res.profile : {},
					fetchInProgress: false
				})
				
				updateShareData(Object.assign({}, sharePageData, {user:res.profile} ))
		  }
		  

	  })
	  .catch(error => {
		console.log("_getUserData error ", error);
		this.setState({
		  fetchInProgress: false
		})
	  });
	}

  
  _getBuildings = () => {
	  const { sharePageData } = this.props;
	  const { user } = sharePageData;
	  if (
		!(
		  sharePageData &&
		  sharePageData.custom_data &&
		  sharePageData.custom_data.group &&
		  sharePageData.custom_data.group.group_data &&
		  sharePageData.custom_data.group.group_data.projects &&
		  sharePageData.custom_data.group.group_data.projects.length > 0
		)
	  ) {
		return false;
	  }
  
	  if (!this.state.buildingsFetchInProgress) {
		this.setState((prevState) => ({ buildingsFetchInProgress: true }));
	  }
  
	  let searchFilters = [
		{ "meta.deleted": { $exists: false } },
		{
		  $or: [
			{ source_provider: { $ne: "mpnd" } },
			{
			  $and: [
				{ source_provider: "permits" },
				{ building_status: { $ne: "resale" } },
			  ],
			},
			{ building_status: "sponsor" },
			{ building_status: "pipeline" },
		  ],
		},
		{
		  key: { $in: [...sharePageData.custom_data.group.group_data.projects] },
		},
	  ];
  
	  let mongoQuery = {
		query: { $and: searchFilters },
		// projection: {
		//   _id: 1,
		//   name: 1,
		//   display_full_street_address: 1,
		//   address: 1,
		//   key: 1,
		//   image: 1,
		//   images: 1,
		//   meta: 1,
		//   pipeline_zoning_area_residential: 1,
		//   pipeline_total_construction_floor_area: 1,
		//   pipeline_building_stories_proposed: 1,
		//   pipeline_dwelling_units_proposed: 1,
		//   pipeline_filing_date: 1,
		//   pipeline_pre_filing_date: 1,
		//   pipeline_project_stage: 1,
		//   pipeline_coo_issuance_date: 1,
		//   pipeline_permit_issuance_job_start_date: 1,
		//   pipeline_permit_issuance_filing_date: 1,
		//   occupancy_type: 1,
		//   pipeline_occupancy_type: 1,
		//   pipeline_project_components: 1,
		//   date_added: 1,
		//   pipeline_occupancy_classification_proposed_code: 1,
		//   building_key: 1,
		//   project_stage: 1,
		//   pipeline_project_stalled: 1,
		//   pipeline_project_abandoned: 1,
		// },
		limit: 50,
	  };
	  // var q =
	  //   "key:(" +
	  //   sharePageData.custom_data.group.group_data.buildings.join(" ") +
	  //   ")";
  
	  // var fq = ["deleted:false"];
  
	  //console.log(user && user.role )
	  let axiosData = {
		url: buildingUrl + "pipeline/query",
		method: "post",
		query: mongoQuery,
	  };
  
	  let formattedData = _axiosCall(axiosData)
		.then((res) => {
		  //this._setBuyersList(res);
		  if (res && res.docs) {
			this.setState({
			  buildingRes: res.docs,
			  buildingStats: res.facets,
			  fetchInProgress: false,
			  buildingsFetchInProgress: false,
			});
		  } else {
			this.setState({
			  buildingRes: [],
			  buildingStats: {},
			  fetchInProgress: false,
			  buildingsFetchInProgress: false,
			});
		  }
		})
		.catch((error) => {
		  console.log("error: " + error);
		});
  
	  // console.log(formattedData)
	  return formattedData;
	};
	
	
	 
	 
   
   toggle = (tab) => {
	   const { activeTab } = this.state;
	   this.setState({activeTab: tab})
   };
   
   toggleModal(type, data) {
	   //this.snackOpen('Your Message Has Been Sent!', 'success');
	   const { sharePageData } = this.props;
	   
	   let modalShareData = JSON.parse(JSON.stringify(sharePageData));
	   
	   delete modalShareData['group'];
	   modalShareData['report_type'] = "single";
	   if(type == "building"){
		   modalShareData['building_key'] = data.key;
		   modalShareData['building'] = data;
		   
	   }else if(type == "unit"){
		   modalShareData['building_key'] = data.building;
		   modalShareData['property_key'] = data.key;
		   modalShareData['property'] = data; 
	   }
	   
	   this.setState(prevState => ({ modal: !prevState.modal, modalShareData: modalShareData, shareType: type }));
   }

  _openPrint(){
	  var getUrl = window.location;
	  var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1] + "/" + getUrl.pathname.split('/')[2];
	  window.open(baseUrl+'?exportMode=true', "print", "width=1366,height=768");
  }
  
  _template = ({ divNumber, numberOfdivs }) => {
	 const {sharePageData} = this.props;
	 this.setState({divNumber:divNumber})
	return (<div color={"secondary"}
	  justifyContent="space-between"
	  position="absolute"
	  bottom={0}
	  width={1}
	  backgroundColor="#5C6178"
	  className="bottom-bar"
	>
	  <div padding="0 1em" color="background">
		<a className="mp-branding" style={{pointerEvents:"all", cursor:"pointer"}} href="https://marketproof.com/new-development" target="_blank">
		  <img src="/img/marketproof-newdev-logo-white.png" />
		</a>
		<span className="divider"> | </span>
		<span>
			{sharePageData.custom_data && sharePageData.custom_data.report_title &&
				 <span className="bold-text">{sharePageData.custom_data.report_title}</span>
			 } ({divNumber} of {numberOfdivs})
		</span>
	  </div>
  
	  <div padding="0em 2em">
		  
	
			  
		  <Tooltip title="Print Presentation">
			<IconButton onClick={()=>{this.printDocument()}}  style={{pointerEvents:"all", cursor:"pointer"}}>
			  <ExportIcon color={"#FFF"}/>
			</IconButton>
		  </Tooltip>
		  
	  </div>
	</div>
  )};
  
  printDocument() {
   const {sharePageData} = this.props;
	this.setState({exportProgress:true});
	const input = document.getElementsByClassName('pdf-report')[0];
	const pages = document.querySelectorAll('.pdf-page').length;
	
	const reportName = sharePageData.custom_data && sharePageData.custom_data.report_title ? `${sharePageData.custom_data.report_title}.pdf` : sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_name ?  `${sharePageData.custom_data.group.group_name}.pdf` : "Pipeline Collection Report.pdf";
	
	
	html2canvas(input, {
		//allowTaint: true,
		//useCORS: true,
		logging:true,
		proxy: process.env.API_ROOT+"/proxy"
	}).then((canvas) => {
		
		  const imgData = canvas.toDataURL('image/png');
		  
		  const imgWidth = canvas.width;
		  const pageHeight = canvas.height/pages;
		  const imgHeight = ((canvas.height * imgWidth) / canvas.width);
		  
		  //console.log(canvas.height, canvas.width, imgHeight);
		  let heightLeft = imgHeight;
		  const doc = new jsPDF('l', 'px', [imgWidth, (pageHeight+2)]);
		  let position = 0;
		  doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
		  heightLeft -= pageHeight;
		  while (heightLeft >= 20) {
			  position = heightLeft - imgHeight;
			  doc.addPage();
			  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
			  heightLeft -= pageHeight;
		  }
		  doc.save(reportName);
		  this.setState({exportProgress:false});
		// const imgData = canvas.toDataURL('image/png');
		// const pdf = new jsPDF('l', 'mm', [297, 210], true);
		// pdf.addImage(imgData, 'PNG', 0, 0);
		// // pdf.output('dataurlnewwindow');
		// pdf.save("download.pdf");
	  })
	;
  }
  
	
  render() {
	const { rtl, sharePageData, preview } = this.props;
	const { buildingRes, unitRes, unitStats, buildingStats, urlState, userRes, fetchInProgress, buildingsFetchInProgress, activeTab, modal, modalShareData, shareType, statsRes, isPrint, divNumber, marketStats, exportProgress, contentLoaded } = this.state;
	
	const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
	}	
	
	const user = Object.assign({}, defaultUser, userRes);
	
	if( userRes === null || buildingRes === null || fetchInProgress || buildingsFetchInProgress){
		return <LinearLoading />;
	}
	
	const reportName = sharePageData.custom_data && sharePageData.custom_data.report_title ? `${sharePageData.custom_data.report_title}` : sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_name ?  `${sharePageData.custom_data.group.group_name}` : "Property Report";
	
	const group = sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false;
	const showTabs = group && group.group_data && group.group_data.buildings && group.group_data.buildings.length>0 && group.group_data.units && group.group_data.units.length>0
	const theme = {
	  colors: {
		primary: '#171F26',
		secondary: '#171F26',
		background : "#FFF"
	  },
	  fontSizes: {
		header: '24px',
		paragraph: '28px'
	  },
	  fontFamily:{
		header: 'Montserrat'
	  },
	  space: [0, 0, 0],
	  sizes: {
		  width: 1000,
		  height: 1000
	  }
	};
	// SPECTACLE_CLI_TEMPLATE_START
	const bldgPerPage = 6;
	let mapdiv = Math.ceil(buildingRes.length / bldgPerPage)+2;
	if(sharePageData.custom_data && sharePageData.custom_data.report_title) mapdiv++;
	//console.log(mapdiv)
	return (
		<Fragment>
		
		<div  className={`share__wrapper presentation pdf-export`}>
		<div className={"pdf-export-loader"} style={{display: exportProgress ? "block" : "none"}}>
			<LinearLoading />
			<h2>{!contentLoaded ? "Loading Report..." : "Generating PDF, Please Wait..."}</h2>
		</div>
		<div className="pdf-report">
		<div className={"pdf-page"}>
			<div className="flex-container" padding="0" height="100%">
			  <div padding="0" className="title-left">
				  <div className="branding" />
				  <h2 class="bold-text text-center">{reportName}</h2>
				  {sharePageData.custom_data.report_subtitle &&
				  <h3 class="bold-text mb-3 text-center">{sharePageData.custom_data.report_subtitle}</h3>
					}
				  <h5 class="bold-text mb-3 text-center">{moment().format('MMMM YYYY')}</h5>
				  
				  <Row>
					<Col md={12}>
						  <p className={"small-text light-text mt-4 mb-2 text-left pl-4 pr-4 disclaimer"} style={{color:'#858AA1',paddingTop:"20%"}}>All information is from sources deemed reliable but is subject to errors and omissions. No representation is made as to the accuracy of any information. All information is subject to change. All rights to content, photographs, and graphics are reserved for the content source.</p>
					  </Col>
				   </Row>
			  </div>
			  
			  <div padding="0" className="title-right">  
			  {sharePageData && sharePageData.custom_data && sharePageData.custom_data.message ?
				  <div className="user-message"
					  dangerouslySetInnerHTML={{
						__html: sharePageData.custom_data.message 
					  }}>
				  </div>
				  
				:
				<Fragment>
				<div>
					<h3 className="page-title mb-3 mt-3">Projects</h3>
		
					<ul>
						{buildingRes.map(building => {
							
							const buildingNameOrAdr = building.name 
							  ? _capitalizeText(building.name)
							  : building.display_full_street_address
								? _capitalizeText(building.display_full_street_address)
								: _capitalizeText(building.full_street_address);
							const adr = building.display_full_street_address ? building.display_full_street_address : building.full_street_address;
							return <li>{buildingNameOrAdr.replace("Of", "of").replace("By", "by")}{building.name  ? <span> - {building.display_full_street_address}</span> : ""}</li>
						})}
					</ul>
					
				</div>
		
				</Fragment>	
				}
				
						
						{sharePageData && sharePageData.custom_data && sharePageData.custom_data.ap &&
						<div className="contact-info">
						<ShareContact  user={user} urlState={this.urlState} sharePageData={sharePageData} print />
						</div>
						}
					</div>
			      
			   
			</div>
		</div>
		
		
		
		{buildingRes && buildingRes.length>0 &&
			<Fragment>
			{(Array.from(Array(Math.ceil(buildingRes.length / bldgPerPage)).keys())).map(i => {
				return (<div className={"pdf-page"}>
						  	{!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
						  	<div padding=".5em 2em 1em 1em">
							  	<FreezeTableBuildings buildingRes={buildingRes.slice(i*bldgPerPage,(i+1)*bldgPerPage)} toggleModal={this.toggleModal.bind(this)} />
						  	</div>
					  	</div>
						)
			})}
			</Fragment>
		}
		<div className={"pdf-page"}>
			  {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
			  <Container className="share dashboard map" style={{background:"#FFF",width:"100%"}}>
			  <div padding="1em 2em 1em 1em" style={{background:"#FFF"}}>
					
				  <Row style={{background:"#FFF"}}>
					<Col xl={9} lg={9} >
						<div className="report-container">
							<h4>Construction Stage</h4>
							<ul className="status-legend">
							
								<li className="building pre-development"><span className={`status`} /> Pre-development</li>
								<li className="building construction"><span className={`status`} /> Construction</li>
								<li className="building operation"><span className={`status`} /> Operation</li>
							</ul>
						</div>
					  { (isPrint || divNumber == mapdiv) && <Map divNumber={divNumber} locations={buildingRes} height={660} mapLoaded={this._mapLoaded} /> }
					</Col>
					<Col xl={3} lg={3} style={{background:"#FFF"}} >
						<h4>Projects</h4>
						<ol>
						{buildingRes.map((loc, i) => {
							const buildingNameOrAdr = loc.name 
							  ? _capitalizeText(loc.name)
							  : loc.display_full_street_address
								? _capitalizeText(loc.display_full_street_address)
								: _capitalizeText(loc.full_street_address);
							return (<li>{buildingNameOrAdr}</li>);
						})}
						</ol>
					</Col>
				  </Row>
				
			  </div>
			  </Container>
		</div>
		
	
		   {buildingRes.map(building => {
			   return (
				   <Fragment>
						   <div className={"pdf-page"}>
								 {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
								 <div padding="1em 2em 1em 1em">
									 <BuildingProfile sharePageData={{"building_key": building.key, "building" : building}} isModal />
								 </div>
						   </div>
								 
				   </Fragment>
				   
			   )
		   })}
		   
		   
		   {false && unitRes && unitRes.length>0 &&
			   <Fragment>
			   {(Array.from(Array(Math.ceil(unitRes.length / 8)).keys())).map(i => {
				   return (<div className={"pdf-page"}>
								 {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
								 <div className="unit-grid">
									 <UnitsGridView unitRes={unitRes.slice(i*8,(i+1)*8)} toggleModal={this.toggleModal.bind(this)} />
								 </div>
							 </div>
						   )
			   })}
			   </Fragment>
		   }
		   
		
		
		</div>
		
			
			</div>
			{contentLoaded &&
			<Tooltip title={`Export Report as PDF`}>
				<Fab
				  variant="extended"
				  size="medium"
				  color="secondary"
				  aria-label="add"
				  style={{fontWeight:400,background:'#780F9E', position:'fixed',bottom:"20px",right:"20px",zIndex:999}}
				  onClick={()=>{this.printDocument()}}
				  className={"print-button"}
				>
				  <ExportIcon style={{marginRight:5}} /> {` `} Export PDF
				</Fab>
			</Tooltip>
			}
		 </Fragment>
	  );
	
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(withOktaAuth(CompReportShare))));
