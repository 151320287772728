import React, { Fragment } from "react";
import moment from "moment";
import {
    BuildingStageBadge,
  GenerateBuildingKeyMap,
  ShowBadge,
  StatusBadge,
  _capitalizeText,
  _formatNumber,
  _generateRandomAPI,
  _getBuildingPhotos,
  _getStreetPhoto,
  _replaceAbbreviations,
  _shouldShow,
  _urlToName,
} from "../../helpers/utils";
import MPNDIcon from "mdi-react/CheckDecagramIcon";
import BldgIcon from "mdi-react/DomainIcon";
import StarIcon from "mdi-react/StarCircleIcon";

export class TooltipMPND extends React.Component {
  render() {
    const { features } = this.props;
    const renderFeature = (feature, i) => {
      let buildingName =
        feature.properties.name &&
        feature.properties.name !=
          feature.properties.display_full_street_address &&
        feature.properties.name != "-1"
          ? _capitalizeText(
              _urlToName(feature.properties.name.replace("corporation", ""))
            )
          : false;
      const gmapApiArr = process.env.GMAP_API_KEY;
      const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
      let bldg = Object.assign({}, feature.properties);
      bldg.key = bldg.building_key;
      let buildingImages = _getBuildingPhotos(bldg, gApiKey);
      return (
        <div key={i} className="c_map-tooltip">
          <div className="c_img">
            <div
              style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}
              className="c_tooltip-img"
            >
              <span className={`c_building-type`}>
                <MPNDIcon
                  size={16}
                  color={"#FFD23F"}
                  style={{ marginTop: -3 }}
                />
                {feature.properties &&
                feature.properties.building_status &&
                feature.properties.building_status == "resale"
                  ? " Resale "
                  : " New Development "}
                {_capitalizeText(_urlToName(feature.properties.ownership_type))}
              </span>
            </div>
          </div>
          <div className="c_info">
            <div className="c_tooltip-content">
              {buildingName ? (
                <div className="">
                  <h5 className="c_header">{buildingName}</h5>
                  {buildingName.toLowerCase() !==
                    feature.properties.display_full_street_address.toLowerCase() && (
                    <div className="u_font-12">
                      {_capitalizeText(
                        _replaceAbbreviations(
                          feature.properties.display_full_street_address
                        )
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <h5 className="c_header">
                  {_capitalizeText(
                    _replaceAbbreviations(
                      feature.properties.display_full_street_address
                    )
                  )}
                </h5>
              )}

              <div style={{ marginLeft: -10, marginBottom: 5 }}>
                <ShowBadge
                  text={_capitalizeText(
                    _urlToName(feature.properties.ownership_type)
                  )}
                  small
                />
              </div>
              <div
                className="light-text small-text"
                style={{ paddingTop: "3px" }}
              >
                {_shouldShow(feature.properties.year_built)
                  ? moment().year() < feature.properties.year_built
                    ? "Proj Completion " + feature.properties.year_built
                    : "Year Built " + feature.properties.year_built
                  : ""}
              </div>
              <div className="light-text small-text">
                {feature.properties.total_units &&
                feature.properties.total_units > 1
                  ? feature.properties.total_units + " Units"
                  : ""}
                {feature.properties.total_units &&
                feature.properties.total_units > 1 &&
                feature.properties.total_floors &&
                feature.properties.total_floors > 1
                  ? " | "
                  : ""}
                {feature.properties.total_floors &&
                feature.properties.total_floors > 1
                  ? feature.properties.total_floors + " Floors"
                  : ""}
              </div>
            </div>
            <div className="c_tooltip-hint u_font-11">
              Click for details{" "}
              <i className="fa fa-external-link" aria-hidden="true" />
            </div>
          </div>
        </div>
      );
    };
    return <div>{features && features.slice(0, 1).map(renderFeature)}</div>;
  }
}

export class TooltipBuilding extends React.Component {
  render() {
    const { features } = this.props;
    const renderFeature = (feature, i) => {
      let buildingName =
        feature.properties.name &&
        feature.properties.name !=
          feature.properties.display_full_street_address
          ? _capitalizeText(
              _urlToName(feature.properties.name.replace("corporation", ""))
            )
          : false;
      const gmapApiArr = process.env.GMAP_API_KEY;
      const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
      let buildingImages = _getBuildingPhotos(feature.properties, gApiKey);
      return (
        <div key={i} className="c_map-tooltip">
          <div className="c_img">
            <div
              style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}
              className="c_tooltip-img"
            >
              <span className={`c_building-type`}>
                <BldgIcon size={16} style={{ marginTop: -3 }} /> Existing
                Building
              </span>
            </div>
          </div>
          <div className="c_info">
            <div className="c_tooltip-content">
              {buildingName ? (
                <div className="">
                  <h5 className="c_header">{buildingName}</h5>
                  {buildingName.toLowerCase() !==
                    feature.properties.display_full_street_address.toLowerCase() && (
                    <div className="u_font-12">
                      {_capitalizeText(
                        _replaceAbbreviations(
                          feature.properties.display_full_street_address
                        )
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <h5 className="c_header">
                  {_capitalizeText(
                    _replaceAbbreviations(
                      feature.properties.display_full_street_address
                    )
                  )}
                </h5>
              )}

              <div style={{ marginLeft: -10, marginBottom: 5 }}>
                <ShowBadge
                  text={_capitalizeText(
                    _urlToName(feature.properties.ownership_type)
                  )}
                  small
                />
              </div>
              <div
                className="light-text small-text"
                style={{ paddingTop: "3px" }}
              >
                {_shouldShow(feature.properties.year_built)
                  ? moment().year() < feature.properties.year_built
                    ? "Proj Completion " + feature.properties.year_built
                    : "Year Built " + feature.properties.year_built
                  : ""}
              </div>
              <div className="light-text small-text">
                {feature.properties.total_units &&
                feature.properties.total_units > 1
                  ? feature.properties.total_units + " Units"
                  : ""}
                {feature.properties.total_units &&
                feature.properties.total_units > 1 &&
                feature.properties.total_floors &&
                feature.properties.total_floors > 1
                  ? " | "
                  : ""}
                {feature.properties.total_floors &&
                feature.properties.total_floors > 1
                  ? feature.properties.total_floors + " Floors"
                  : ""}
              </div>
            </div>
            <div className="c_tooltip-hint u_font-11">
              Click for details{" "}
              <i className="fa fa-external-link" aria-hidden="true" />
            </div>
          </div>
        </div>
      );
    };
    return <div>{features && features.slice(0, 1).map(renderFeature)}</div>;
  }
}

export class Tooltip extends React.Component {
  render() {
    const { features } = this.props;

    const renderFeature = (feature, i) => {
      console.log(feature);

      let buildingName =
        feature.properties.name &&
        feature.properties.name !=
          feature.properties.display_full_street_address
          ? _capitalizeText(
              _urlToName(feature.properties.name.replace("corporation", ""))
            )
          : false;
      const gmapApiArr = process.env.GMAP_API_KEY;
      const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];

      feature.properties["solr_key"] = GenerateBuildingKeyMap(
        feature.properties
      );

      let buildingImage = _getStreetPhoto(feature.properties, gApiKey);
      console.log(buildingImage);

      return (
        <div key={i} className="c_map-tooltip">
          <div className="c_img">
            <div
              style={{ backgroundImage: "url('" + buildingImage + "')" }}
              className="c_tooltip-img"
            >
              <StatusBadge building={feature.properties} />
              <span className={`c_building-type`}>
                <StarIcon
                  size={16}
                  color={"#FFD23F"}
                  style={{ marginTop: -3 }}
                />{" "}
                Pipeline Project
              </span>
            </div>
          </div>
          <div className="c_info">
            <div className="c_tooltip-content">
              {buildingName ? (
                <div className="">
                  <h5 className="c_header">{buildingName}</h5>
                  {buildingName.toLowerCase() !==
                    feature.properties.display_full_street_address.toLowerCase() && (
                    <div className="u_font-12">
                      {_capitalizeText(
                        _replaceAbbreviations(
                          feature.properties.display_full_street_address
                        )
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <h5 className="c_header">
                  {_capitalizeText(
                    _replaceAbbreviations(
                      feature.properties.display_full_street_address
                    )
                  )}
                </h5>
              )}
              <div style={{ marginLeft: -10, marginBottom: 5 }}>
                <BuildingStageBadge item={feature.properties} small />
              </div>
              <div className="">
                <span className="light-text small-text">
                  {feature.properties.pipeline_zoning_area_residential > 0
                    ? _formatNumber(
                        feature.properties.pipeline_zoning_area_residential
                      ) + " Resi SF"
                    : ""}
                </span>
                {feature.properties.pipeline_zoning_area_residential > 0 &&
                  feature.properties.pipeline_total_construction_floor_area >
                    0 && <span className="small-divider">|</span>}
                <span className="light-text small-text">
                  {feature.properties.pipeline_total_construction_floor_area > 0
                    ? _formatNumber(
                        feature.properties
                          .pipeline_total_construction_floor_area
                      ) + " Total SF"
                    : ""}
                </span>
              </div>
              <span className="light-text small-text">
                {_formatNumber(
                  feature.properties.pipeline_building_stories_proposed
                )}{" "}
                Floors
              </span>

              {feature.properties.pipeline_dwelling_units_proposed > 0 && (
                <Fragment>
                  <span className="small-divider">|</span>
                  <span className="light-text small-text">
                    {feature.properties.pipeline_dwelling_units_proposed > 0
                      ? _formatNumber(
                          feature.properties.pipeline_dwelling_units_proposed
                        )
                      : ""}{" "}
                    Units
                  </span>
                </Fragment>
              )}
            </div>
          </div>
          <div className="c_tooltip-hint u_font-11">
            Click for details{" "}
            <i className="fa fa-external-link" aria-hidden="true" />
          </div>
        </div>
      );
    };
    return <div>{features && features.slice(0, 1).map(renderFeature)}</div>;
  }
}

export const containerStyle = {
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
};

export const LocationsPadding = {
  "Flushing Meadows Corona Park": [-0.005, 0],
  Flushing: [0, -0.005],
  "Sheepshead Bay": [-0.005, 0],
  "Port Morris": [0, -0.002],
};

export const buildingStyle = {
  fillColor: "#A3ADC2",
  fillOpacity: 0.45,
};

export const buildingHighlightStyle = {
  fillColor: "#780F9E",
  fillOpacity: 0.8,
};

export const pipelineStyle = {
  fillColor: "#780F9E",
  fillOpacity: 0.7,
};

export const defaultOccupancyTypes = ["residential", "commercial", "mixed-use"];
