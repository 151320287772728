import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import buyersListActions from '../../../../redux/actions/buyersListActions';
import buyersListCard from './../../types';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import EmptyBuyersList from './../../components/EmptyBuyersList';
import SavedUnitItems from './components/SavedUnitItems';
import TrialAlert from '../../../../shared/components/payment/TrialAlert';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { _axiosCall } from '../../../../shared/helpers/apicalls';

import {
	buildingFL
} from "../../../../shared/helpers/utils";

const apiRoot = process.env.API_ROOT;

const buildingUrl = apiRoot + '/query/';


class SavedUnits extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    buyerslist: PropTypes.arrayOf(buyersListCard).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      fetchInProgress: false,
      savedUnits: null,
      buyersListAnalytics: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this._getSavedUnits(nextProps);
  }
  
  componentDidMount() {
  	this._getSavedUnits(this.props);
  }
  
 componentDidUpdate(prevProps, prevState, snapshot){
	  

	if(this.props.dataSet && this.props.savedUnits && this.state.savedUnits && this.props.savedUnits.filter(item => !item.deleted).length !== this.state.savedUnits.length){
		console.log('calling again');
			this._getSavedUnits(this.props);
	}

	  
	 
  }
  
  
  _getSavedUnits = async (props) => {

		
		const _propertyIDs = props.savedUnits.filter(item => !item.deleted).map(item => item.key).join(' ');
		
		if(!props.dataSet || this.state.fetchInProgress){
			return false;
		}
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		var q = "*:*";

		const defaultFq = [
		    'deleted:false',
		    "key:("+_propertyIDs+")"
		  
		  ];
				 
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=60",
			method: "post",
			query: {
				q: q,
				fq: defaultFq,
				sort: null,
				fl: null,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: 0				
			}
		};
		
		this._getUnitAnalytics(_propertyIDs);

		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});

		let formattedData = response && response.docs
			? response.docs.slice(0)
			: [];
		
		let docs = formattedData.map((doc, i) => {
			let item = Object.assign({}, doc);			
			return item;
		});
		
		this.setState({ savedUnits : docs, fetchInProgress : false });

				
	};
	
	_getUnitAnalytics = async (_propertyIDs) => {
						
		var q = "*:*";
		
		var query = [{
				"$facet": {
					"unitKeys": [{
							"$unwind": "$savedUnits"
						},
						{
							"$unwind": "$savedUnits.key"
						},
						{
							"$match": {
								"$and": [{
										"$or": [{
												"savedUnits.deleted": {
													"$exists": false
												}
											},
											{
												"savedUnits.deleted": false
											}
										]
									},
									{
										"savedUnits.key": {
											"$in": _propertyIDs.split(' ')
										}
									}
								]
							}
						},
						{
							"$group": {
								"_id": {
									"key": "$savedUnits.key"
								},
								"total": {
									"$sum": 1
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": "$_id.key",
								"total": 1
							}
						}
					]
				}
			}]


		let axiosData = {
			url: apiRoot+ "/buyerslist/analytics/aggregate",
			method: "post",
			query: query
			
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log(res[0].unitKeys);
				if(res && res[0] && res[0].unitKeys){
					this.setState({ buyersListAnalytics : res[0].unitKeys.slice() });
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };

 

  render() {
    const {
      actions, t, theme, rtl,
    } = this.props;

    const { savedUnits, buyersListAnalytics } = this.state;

	if(savedUnits === null){
		return null;
	}

	
	if(savedUnits.length <=0){
		return (
			<Fragment>
				<Container className="todo-app dashboard">
				<Row>
		          <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    </Container>
				<EmptyBuyersList savedUnits />
			</Fragment>
		)
	}
	
    return (
      <Container className={`todo-app dashboard ${isMobile && "p-3"}`}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`My Units`}</h3>
            <h3 className="page-subhead subhead">Manage your units</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'mb-3'}>
	       	<TrialAlert />        
	      </Col>
	    </Row>

        <Row>
          <Col md={12} xl={12}>
          	<Col md={12}>
              <div className="todo-app__divider">
                <div className="todo-app__divider-line" />
              </div>
            </Col>
            <SavedUnitItems items={savedUnits} analytics={buyersListAnalytics} />
         </Col>        
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],  
	  dataSet : state.buyersLists.dataSet  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SavedUnits));
