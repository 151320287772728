import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

const rows = [
  {
     id: "type",
     disablePadding: false,
     label: "Type",
   },
 {
   id: "filing_date",
   disablePadding: false,
   label: "Filing Date",
 },
  {
    id: "issuance_date",
    disablePadding: false,
    label: "Issuance Date",
  },
  
  {
    id: "job_type",
    disablePadding: false,
    label: "Job Type",
  },
  {
    id: "work_type",
    disablePadding: false,
    label: "Work Type",
  },
  {
    id: "full_street_address",
    disablePadding: false,
    label: "Address",
  },
  {
    id: "applicant_business_name",
    disablePadding: false,
    label: "Applicant",
  },
  {
    id: "permittee_business_name",
    disablePadding: false,
    label: "Permittee",
  },
  {
    id: "owner",
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "filing_applicant_business_name",
    disablePadding: false,
    label: "Filing Rep",
  },
  
 
];

if (isMobile) rows.splice(0, 1);

class UnitsSearchTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rtl } =
      this.props;

    return (
      <TableHead>
        <TableRow>
          {/*<TableCell padding="checkbox">
          </TableCell>*/}
          {rows.map(
            (row) => (
              <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-right"
                key={row.id}
                align={rtl.direction === "rtl" ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.noSort ? (
                  <span>{row.label}</span>
                ) : (
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                    className="material-table__sort-label"
                  >
                    {row.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(UnitsSearchTableHead);
