import axios from "axios";
import React, { Fragment } from 'react';
import { 
	BuildingStageBadge,
	ShowBadge
} from "./../../helpers/utils";

import IconButton from '@material-ui/core/IconButton';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Tooltip } from '@material-ui/core';
import StarIcon from 'mdi-react/StarCircleIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_formatAddressAutoComplete,
	_selectBestSlug,
	_validStrTags,
	_nameToUrl,
	_checkBuildingNameVsAdr,
	_replaceAbbreviations,
	_validArrTags,
	_fuzzyProjectCount
} from '../../../shared/helpers/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = (options, { inputValue }) => { return options }
const apiUrl = process.env.API_ROOT;  
  
const ProjectSearch = (props) => {
  const { onSelect, addBuilding, query, type, hideAddContacts, hitsPerPage } = props;

  const [hits, setHits] = React.useState([]);

  React.useEffect(() => {
     let active = true;
     
     
     axios({ method:'get', url: `${apiUrl}/pipeline/autocomplete/people?input=${query}`}).then((results) => {
      if (active) {
        let newOptions = [];


        if (results && results.data) {
          newOptions = [...newOptions, ...results.data.slice(0, 10)];
        }
				
        setHits(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [query]);

  let slice = hitsPerPage ? hitsPerPage : 999;
  return (
   
  		 <Fragment>
			 {hits && hits.length>0 &&
			   <div className={`c_search-results ${query === "" ? "hidden" : ""}`}>
			    <h5 className="">PEOPLE</h5>
			    {hits.slice(0, slice)
			      .map(hit => {
		
			
			        return (
			          <div className="c_result" key={hit["_id"]}>
			            <div
			              onClick={onSelect.bind(
			                this,
			                {},
			                hit,
			                false
			              )}
			              id="tags"
			              className="c_search-hit"
			            >
			            	{!hideAddContacts && <span className={'c_blg-add'}>
			                	<Tooltip title="Add this person to search">
			                      	<IconButton
							            onClick={(e) => {
								            e.stopPropagation();
								            e.preventDefault();
								            onSelect({}, hit, true);
								            
								            }}
			
							            className=""
							            size={'small'}
							            style={{background:"transparent"}} 
							          >
									  <AddIcon size={20} style={{ marginRight:"3px"}} color={'#780F9E'}  />
								  	</IconButton>
							  	</Tooltip>	
							</span>
							}
							<span className={`c_bldg_container`}>
				                <span className={`c_bldg`}>
				                {hit.name
				                  ? _capitalizeText(hit.name)
				                  : _capitalizeText(
				                      _replaceAbbreviations(hit.name)
				                    )}
				                </span>
				                {hit.project_count && <span className="unit-type_badge">{_fuzzyProjectCount(hit.project_count)} Project{hit.project_count > 1 && "s"}</span>}
				                <span className="c_search-hit-details">{hit.organizations && hit.organizations[0] && hit.organizations[0].name ? _capitalizeText(hit.organizations[0].name) : ""}</span>
				            </span>
			            </div>
			          </div>
			        );
			      })}
			  </div>
			  }
			  </Fragment> 
    );
}

export default ProjectSearch;