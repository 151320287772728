import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import TrialAlert from "../../shared/components/payment/TrialAlert";
import {
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from "../../shared/helpers/utils";

import {
	_axiosCall,
	_makeBuildingAxiosData,
} from "../../shared/helpers/apicalls";
import LinearLoading from "../../shared/components/LinearLoading";
import ContactProfilePage from "./components/ContactProfilePage";
import ContactTabContainer from "./components/ContactTabContainer";

import { _hasPermission } from "../../shared/helpers/access";
import EnterpriseGate from "../../shared/components/payment/EnterpriseGate";


const apiURL = process.env.API_ROOT + "/";
class SingleContact extends PureComponent {
	state = {
		contactRes: false,
		fetchInProgress: false,
		changed: false,
		organizations: [],
		pipeline_projects: [],
		organizationRes: false,
	};

	componentDidMount() {
		this._isMounted = true;
		this._getMongoOrganizationData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.changed !== prevState.changed) {
			this._getMongoOrganizationData();
		}
	}

	_getMongoOrganizationData = async () => {
		const key = this.props.match.params.key
			? this.props.match.params.key
			: false;

		if (!key) return false;
		const { formData } = this.state;
		if (!this.state.fetchInProgress) {
			this.setState((prevState) => ({ fetchInProgress: true }));
		}

		let mongoQuery = [
			{
				$match: {
					key: key,
					"meta.deleted": { $exists: false },
				},
			},
			{
				"$lookup": {
					"from": "organizations",
					"localField": "organizations.key",
					"foreignField": "key",
					"pipeline": [{
						"$match": {
							"meta.deleted": {
								"$exists": false
							}
						}
					},
					{
						"$project": {
							"_id": 0,
							"key": 1,
							"name": 1,
							"phone": 1,
							"email": 1,
							"address": 1,
							"meta": 1,
							"project_roles": 1,
							"photo": 1
						}
					}
					],
					"as": "organizations"
				}
			},
			{
				"$lookup": {
					"from": "pipeline-projects",
					"localField": "projects.key",
					"foreignField": "key",
					"pipeline": [
						{
							"$match": {
								"meta.deleted": {
									"$exists": false
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": 1,
								"name": 1,
								"meta": 1
							}
						}
					],
					"as": "pipeline_projects"
				}
			},
			{
				"$project": {
					"_id": 0,
					"contact": "$$ROOT",
					"organizations": "$organizations",
					"pipeline_projects": "$pipeline_projects"
				}
			},
			{
				"$addFields": {
					"contact.project_keys": {
						"$map": {
							"input": "$pipeline_projects",
							"as": "project",
							"in": "$$project.key",
						},
					},
				},
			},
			{
				"$unset": [
					"contact.organizations", "contact.projects", "contact.pipeline_projects"
				]
			}
		];

		let axiosData = {
			url: apiURL + "pipeline/aggregate/contacts",
			method: "post",
			query: mongoQuery,
		};

		let formattedData = await _axiosCall(axiosData)
			.then((res) => {
				// console.log('Chart Data', res);
				if (this._isMounted && res && res[0] && res[0].contact) {
					if ( !res[0].contact.projects && res[0].pipeline_projects ) res[0].contact.projects = res[0].pipeline_projects; // Added projects back
					
					let orgRes = null;
					let contact = res[0].contact;
					if (contact.organization_associations && contact.organization_associations.length > 0 && contact.organization_associations.filter(o => o.Name).length > 0) {
						let primary = contact.organization_associations.filter(o => o.Name && o.Type && o.Type == "primary");
						if (primary.length > 0) {
							primary = primary[0]['Name'];
						} else {
							primary = contact.organization_associations.filter(o => o.Name)[0]['Name'];
						}

						orgRes = res[0].organizations &&
							res[0].organizations.filter((org) => org && org.key && org.key == primary.key).length > 0
							? res[0].organizations.filter((org) => org && org.key && org.key == primary.key)[0]
							: false
					} else {
						orgRes = res[0].organizations &&
							res[0].organizations.filter((org) => org && org.key && org.key).length > 0
							? res[0].organizations.filter((org) => org && org.key && org.key)[
							res[0].organizations.filter((org) => org && org.key && org.key).length - 1
							]
							: false
					}

					this.setState({
						contactRes: contact,
						fetchInProgress: false,
						organizations: res[0].organizations ? res[0].organizations : [],
						organizationRes: orgRes,
						pipeline_projects: res[0].pipeline_projects
							? res[0].pipeline_projects
							: [],
					});
				} else {
					this.setState({ contactRes: {} });
				}
			})
			.catch((error) => {
				console.log("error: " + error);
			});
		// console.log(formattedData)
		return formattedData;
	};

	setChanged() {
		const { changed } = this.state;

		this.setState({ changed: !changed });
	}

	render() {
		const {
			contactRes,
			fetchInProgress,
			contacts,
			pipeline_projects,
			organizationRes,
		} = this.state;

		const { user } = this.props;

		if (!_hasPermission(user, "project-contacts")) {
			return (
				<div className="dashboard">
					<Container className="todo-app">
						<Row>
							<EnterpriseGate
								copy={<span>Access to Pipeline Contacts requires an upgrade to your subscription.</span>}
								img={`https://marketproof.com/svgs/PLyes.svg`}
								classes={"small-image"}
							/>
						</Row>
					</Container>
				</div>
			)
		}
		if (!contactRes || fetchInProgress) {
			return <LinearLoading />;
		}

		return (
			<Container className="dashboard">
				<Row>
					<Col md={12} className={"mb-3"}>
						<TrialAlert />
					</Col>
				</Row>
				<Row>
					<ContactTabContainer
						{...this.props}
						contacts={contacts}
						pipeline_projects={pipeline_projects}
						contactRes={contactRes}
						organizationRes={organizationRes}
						setChanged={this.setChanged.bind(this)}
					/>

					{/* <ContactTabContainer
            {...this.props}
            contacts={contacts}
            pipeline_projects={pipeline_projects}
            contactRes={contactRes}
            organizationRes={organizationRes}
            setChanged={this.setChanged.bind(this)}
          /> */}
				</Row>
			</Container>
		);
	}
}

export default connect((state) => ({
	user: state.user,
}))(withOktaAuth(withRouter(SingleContact)));
