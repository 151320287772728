import React, { Component, Fragment } from "react";
import { Col, Container, Row, Modal, ButtonToolbar, Badge } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import moment from "moment";

import { RTLProps, ThemeProps } from "../../../shared/prop-types/ReducerProps";
import buyersListActions from "../../../redux/actions/buyersListActions";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import AddIcon from "mdi-react/PlusCircleOutlineIcon";
import PhoneIcon from "mdi-react/PhoneOutlineIcon";
import LocationIcon from "mdi-react/MapMarkerIcon";
import InfoIcon from "mdi-react/NotebookOutlineIcon";
import ContactIcon from "mdi-react/BookmarkPlusOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";

import Button from "@material-ui/core/Button";

import AlertIcon from "mdi-react/InformationOutlineIcon";
import UnitIcons from "../../../shared/components/UnitIcons";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import JSONIcon from "mdi-react/ContentCopyIcon";
import Alert from "./../Alert";

import MergeProjectsTableHead from "./MergeProjectsTableHead";

import { makeStyles } from "@material-ui/core/styles";
import CallMergeIcon from "mdi-react/CallMergeIcon";

import Fab from "@material-ui/core/Fab";

import ShareForm from "../form/contact/ShareForm";
import Search from "../algolia/Search";
import { Publisher } from "./../../pubsub/publisher.js";
import SnackBar from "../../../shared/components/SimpleSnackBar";
import BuildingIcons from "../../../shared/components/BuildingIcons";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  _formatUnitNumber,
  _slugifyText,
  UnitCurrentStatus,
  _nameToUrl,
  _replaceAbbreviations,
  _generateModelKeys,
  StatusBadge,
} from "../../../shared/helpers/utils";

import { _axiosCall } from "../../../shared/helpers/apicalls";

const apiUrl = process.env.API_ROOT + "/query/";

const CurrentStatus = ({ item }) => {
  if (!item.status || item.status === "active") {
    return (
      <div>
        <Badge color="success">Active</Badge>
      </div>
    );
  }

  return (
    <div>
      <Badge>{_capitalizeText(item.status)}</Badge>
    </div>
  );
};

const emptyUnitDoc = {
  id: null,
  building: null,
  property: null,
  alternate_addresses: [],
  zip: null,
  alternate_unitcodes: [],
  primary_unitcode: null,
  validated: true,
  solr_created: 0,
  solr_created_date: null,
  solr_updated: 0,
  solr_updated_date: null,
  borough: null,
  borough_code: 0,
};

class MergeProjectButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Contact",
      modal: false,
      primaryItem: false,
      existingUnitCodes: false,
      updateUnitCodes: false,
      snackShow: false,
      snackMessage: false,
      snackType: "success",
      alternateAddressDocuments: [],
      dataPreview: false,
    };
    this.toggle = this.toggle.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._submitMerge = this._submitMerge.bind(this);
    this.snackOpen = this.snackOpen.bind(this);
    this.snackClose = this.snackClose.bind(this);
    this._generateMergeData = this._generateMergeData.bind(this);
    this._generateId = this._generateId.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.state.modal && this.state.modal !== prevState.modal) ||
      this.state.primaryItem !== prevState.primaryItem
    ) {
      //this._generateMergeData();
    }
  }

  handleSubmit(event, buildingKey) {
    event.preventDefault();
  }

  snackOpen = (message, type) => {
    this.setState({ snackShow: true, snackMessage: message, snackType: type });
  };

  snackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackShow: false, snackMessage: "" });
  };

  _generateId = (address) => {
    const timestamp = Math.floor(Date.now() / 1000);

    let id = `a-${address.borough_code}-${address.block}-${address.lot}-${address.bin[0]}-${timestamp}-u`;

    return id;
  };

  _generateMergeData = () => {
    const { selectedItems, type } = this.props;
    const { primaryItem } = this.state;
    const dataType = type === "people" ? "contacts" : "organizations";
    let data = {};

    data = {
      pipeline_project: {
        key: primaryItem,
        meta: {
          source_namespace:
            selectedItems[primaryItem]["meta"]["source_namespace"],
        },
      },
      pipeline_projects_deprecated: Object.keys(selectedItems)
        .filter((key) => key !== primaryItem)
        .map((key) => {
          return {
            key: key,
            meta: {
              source_namespace: selectedItems[key]["meta"]["source_namespace"],
            },
          };
        }),
    };

    return data;
  };

  _submitMerge = async () => {
    const { clearSelected, type } = this.props;
    const mergeData = this._generateMergeData();

    console.log(mergeData);
    //return false;

    let axiosData = {
      url: process.env.API_ROOT + `/pipeline-projects/merge`,
      method: "post",
      query: mergeData,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.success) {
          this.toggle();
          this.snackOpen("Merge Successful.");
          setTimeout(() => {
            clearSelected();
          }, 5000);
        } else {
          this.snackOpen("Merge Failed.", "error");
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        this.snackOpen("Merge Failed.", "error");
      });
  };

  toggle() {
    const { buildingRes, property, selectedItems } = this.props;
    const { primaryItem, modal } = this.state;

    if (!modal) {
      const primary = primaryItem
        ? primaryItem
        : selectedItems[Object.keys(selectedItems)[0]].key;
      this.setState((prevState) => ({
        primaryItem: primary,
        modal: !prevState.modal,
      }));
    } else {
      this.setState((prevState) => ({
        primaryItem: false,
        modal: !prevState.modal,
      }));
    }
  }

  togglePreview() {
    this.setState((prevState) => ({ dataPreview: !prevState.dataPreview }));
  }

  handleChange(event) {
    this.setState({ primaryItem: event.target.value });
  }

  render() {
    const {
      actions,
      t,
      theme,
      rtl,
      buildingRes,
      user,
      selectedItems,
      building,
      clearSelected,
      type,
    } = this.props;

    const {
      modal,
      primaryItem,
      existingUnitCodes,
      snackShow,
      snackMessage,
      snackType,
      updateUnitCodes,
      dataPreview,
      alternateAddressDocuments,
    } = this.state;

    if (!selectedItems || Object.keys(selectedItems).length <= 0) return null;


    const primary = primaryItem
      ? primaryItem
      : selectedItems[Object.keys(selectedItems)[0]].key;

    return (
      <Fragment>
        <SnackBar
          snackShow={snackShow}
          snackMessage={snackMessage}
          snackClose={this.snackClose}
          snackDuration={5000}
          snackType={snackType}
        />

        {Object.keys(selectedItems).length > 1 && (
          <div style={{ position: "fixed", bottom: 50, right: 50, zIndex: 99 }}>
          {(user && user.role.indexOf("admin") >= 0) &&
            <Tooltip title={`Merge Selected ${_capitalizeText(type)}`}>
              <Fab
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
                style={{ fontWeight: 400 }}
                onClick={this.toggle}
              >
                <CallMergeIcon style={{ marginRight: 5 }} /> {` `}Merge{" "}
                {_capitalizeText(type)}
              </Fab>
            </Tooltip>
          }
            <Tooltip title={`Clear Selected ${_capitalizeText(type)}`}>
              <Fab
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="clear"
                style={{ fontWeight: 400, marginLeft: "10px" }}
                onClick={clearSelected}
              >
                Clear
              </Fab>
            </Tooltip>
          </div>
        )}

        {modal && (
          <Fragment>
            <Modal
              isOpen={modal}
              toggle={this.toggle}
              modalClassName={`${rtl.direction}-support`}
              className={`modal-dialog contact__modal share__modal`}
            >
              <div className="modal__header">
                <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  onClick={this.toggle}
                />
              </div>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div className="modal__body background_white">
                    <h4 className="contact__title">
                      Merge {_capitalizeText(type)}
                    </h4>
                    <Row>
                      <Col md={12} lg={12} className={"text-right"}>
                        {/*dataPreview ?
						            	    <Tooltip title={`Back to Merge View`}>
											        <Button
												         variant="contained"
												         color=""
												         className="buyersList__secondary-button"		         
												         startIcon={<CallMergeIcon />}
												         onClick={this.togglePreview}
												     >	Merge View
												     </Button>	
										  	</Tooltip>

						            	 : 
						            	 	<Tooltip title={`Preview JSON to be saved`}>
											        <Button
												         variant="contained"
												         color=""
												         className="buyersList__secondary-button"		         
												         startIcon={<JSONIcon />}
												         onClick={this.togglePreview}
												     > JSON View
												     </Button>	
										  	</Tooltip>
								  	 */}
                      </Col>
                    </Row>
                    <hr />

                    <div>
                      <div
                        className="material-table__wrap"
                        style={{ overflowY: "hidden" }}
                      >
                        <RadioGroup
                          aria-label="unit merge"
                          name="unitMerge"
                          value={primary}
                          onChange={this.handleChange}
                        >
                          <Table className="material-table">
                            <MergeProjectsTableHead />

                            <TableBody>
                              {Object.keys(selectedItems).map((key) => {
                                const d = selectedItems[key];
                                const buildingNameOrAdr = d.name
                                  ? _capitalizeText(d.name)
                                  : d.display_full_street_address
                                  ? _capitalizeText(
                                      d.display_full_street_address
                                    )
                                  : _capitalizeText(
                                      d.address.full_street_address
                                    );
                                const adr = d.display_full_street_address
                                  ? d.display_full_street_address
                                  : d.full_street_address;
                                const _boro = _urlToName(
                                  d.address.borough && d.address.borough
                                );
                                const _hood = _urlToName(
                                  d.address.neighborhood &&
                                    d.address.neighborhood[0]
                                );
                                return (
                                  <TableRow
                                    className="material-table__row"
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={d.key}
                                  >
                                    <TableCell
                                      className="material-table__cell"
                                      padding=""
                                    >
                                      <FormControlLabel
                                        value={d.key}
                                        control={<Radio />}
                                        label=""
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="material-table__cell material-table__cell-right"
                                      component="th"
                                      scope="row"
                                      padding="none"
                                      style={{ padding: 10 }}
                                    >
                                      <span>
                                        {buildingNameOrAdr}{" "}
                                        <BuildingIcons building={d} />
                                      </span>
                                      <br />
                                      <span className="light-text small-text">
                                        {d.name ? (
                                          <span>
                                            {_capitalizeText(adr)}
                                            <br />
                                          </span>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {_capitalizeText(_hood)},{" "}
                                        {_capitalizeText(_boro)}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className="material-table__cell material-table__cell-right"
                                      size="small"
                                    >
                                      <div
                                        style={{
                                          maxHeight: 60,
                                          overflowY: "auto",
                                        }}
                                      >
                                        <span className="light-text small-text">
                                          <ul>
                                            {d.address &&
                                              d.address.alternate_addresses &&
                                              d.address.alternate_addresses.map(
                                                (address) => <li>{address}</li>
                                              )}
                                          </ul>
                                        </span>
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      className="material-table__cell material-table__cell-right"
                                      size="small"
                                    >
                                      <StatusBadge building={d} />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </RadioGroup>
                      </div>
                    </div>
                    <hr />

                    <div style={{ textAlign: "right" }}>
                      <Tooltip
                        title={`Confirm Merge of Selected ${_capitalizeText(
                          type
                        )}`}
                      >
                        <Button
                          onClick={this._submitMerge}
                          variant="contained"
                          color="secondary"
                          className="buyersList__primary-button"
                          startIcon={<CallMergeIcon />}
                        >
                          {" "}
                          Confirm Merge
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation("common")(MergeProjectButton));
