import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Form from "@rjsf/material-ui";
import LayoutField from './LayoutField';

import renderCustomFieldSearch from './fields/CustomFieldSearch';


import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber
} from '../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}

const schema = {
  title: "Project Profile",
  type: "object",
  required: ['name'],
  properties: {
    "pipeline_financing_status": {
      "type": "string",
      "title": "Financing Status",
      "enum": ["not-secured", "partially-secured", "fully-secured"],
	  "enumNames": ["Not Secured", "Partially Secured", "Fully Secured"],
    },
    'custom_fields': {
      'type': 'array',
      'title': 'Custom Fields',
      "description" : "Use custom fields to capture additional information about this project.",
      'items' : {
	      'type': 'object',
	      'properties' : {		      
		      'Name' : {
			      'type' : 'string'
		      },
		      'Type' : {
			      'type' : 'string',
			       "enum": ["text", "number"],
				   "enumNames": ["Text", "Number"],
				   "default" : "text"
		      },
		      'Value' : {
			      'type' : 'string',
		      },

		      
	      }
      }
    }
  },
  "definitions": {}
      
    
  
}

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField
}


const isFilled = (fieldName) => ({ formData }) => (formData[fieldName] && formData[fieldName].length) ? true : false
const isTrue = (fieldName) => ({ formData }) => (formData[fieldName])

const widgets = {
  CustomFieldSearch: renderCustomFieldSearch
}


const uiSchema = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: [
	    
	  { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <h5 className="section_title">Project Details</h5>
              </div>
            )
          } }
        ] } },
      ] },
	   { 'ui:row': [
        { 'ui:col': { md: 12, children: ['custom_fields'] } },        
      ] },
     
    ] } },
  ] },
  'custom_fields': {
	  'items' : {
		 'Name' : {
			  'ui:widget': 'CustomFieldSearch'
		  }
 
	  }
  }
}


class EditDetails extends Component {

  constructor(props) {
    super(props);
    
  }

  render() {
    const {
     formData, onChange, onSubmit, toggle, saveInProgress
    } = this.props;
        
    return (
	    <div className="project_edit details">
		    <Form
		        formData={formData}
		        schema={schema}
		        uiSchema={uiSchema}
		        fields={fields}
		        widgets={widgets}
		        onChange={onChange}
		        noHtml5Validate={true}>
		        <ButtonToolbar className="modal__footer">
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				         disabled={saveInProgress}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
	            <Tooltip title="Save">
			        <Button
			        	 onClick={onSubmit}
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"		
				         disabled={saveInProgress}         
				     >	{saveInProgress ? "Saving..." : "Save" }
				     </Button>				 
			  	</Tooltip>
	         </ButtonToolbar>
	
			</Form>
		</div>
    );
  }
}



export default EditDetails;
