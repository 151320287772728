import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PlanCard from './components/PlanCard';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import ChangeIcon from 'mdi-react/RotateLeftIcon';
import FreeAccount from './components/FreeAccount';

import TopBar from './../../TopBar'
import Footer from './../../Footer'

import './../Payment.scss'

import { subscriptionPlans, _currentPipelinePlan } from './../plans.js';

	
class PricingCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
	  const { user } = this.props;
	  
	 
	  
	  let currentPlan =  _currentPipelinePlan(user);
	  
	  
	  return (
		 	<div className="plan__wrapper share__wrapper">
			  <TopBar />
			  <FreeAccount />
			  <Container className="plan__container">			   
			    <Row>
			      <Col md={12}>
			        <h3 className="page-title">Subscription Plans
			        
			        </h3>
			        <h3 className="page-subhead subhead">
			        	Request a trial to to learn more about the plans that best fit your needs
			        	{currentPlan &&
				        	<span style={{float:"right",fontSize:13}}>
				        		<Link to="/dashboard" className="link-text"><ChangeIcon size={13} /> My Dashboard</Link>
				        		</span>
						}
			        </h3>
			        
			      </Col>
			    </Row>
			    <Row>
			    {subscriptionPlans.map(plan => {
				    return <PlanCard plan={plan} user={user} currentPlan={currentPlan} />
			    })}
			    </Row>
			  </Container>
			  <Footer />
			</div>
		)
   }
};
export default connect(state => ({
  user: state.user,
  plan: state.plan
}))(withOktaAuth(PricingCards));
