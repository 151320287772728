import React, { useState, Fragment } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import CommercialPercent from "./CommercialPercent";

import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _checkBuildingAltAdr,
  _showAltAdrs,
  _getCrossStreet,
  _getOccupancy,
  _getProjectType
} from "../../../../../shared/helpers/utils";
import { Grid } from "@material-ui/core";
import FeatureIcon from 'mdi-react/CheckCircleOutlineIcon';
import NoFeatureIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import LinkIcon from 'mdi-react/InformationOutlineIcon';
import MapIcon from 'mdi-react/MapMarkerCircleIcon';
import TaxAbatement from '../../../../../shared/components/TaxAbatement';


const showScheduleA = (schedule_a_proposed) => {
  if (schedule_a_proposed !== undefined) {
    // source_article += '<br/><br/>According to the filing, the breakdown of each floor is as follows: <br/>';
    var floor_breakdown = {};

    for (var key in schedule_a_proposed) {
      var floor_data = schedule_a_proposed[key];
      if (floor_data["floors frm - to"] !== undefined) {
        var floor = "";
        if (floor_data["floors frm - to"].indexOf("bas") >= 0) {
          floor = "Basement";
        } else if (floor_data["floors frm - to"].indexOf("rof") >= 0) {
          floor = "Roof";
        } else if (floor_data["floors frm - to"].indexOf("att") >= 0) {
          floor = "Attic";
        } else if (floor_data["floors frm - to"].indexOf("cel") >= 0) {
          floor = "Cellar";
        } else if (floor_data["floors frm - to"].indexOf("mez") >= 0) {
          floor = "Mezzanine";
        } else {
          var from_to = floor_data["floors frm - to"].split("-");

          var fl_from = "";
          var fl_to = "";
          if (Array.isArray(from_to) && from_to.length > 1) {
            if (from_to[0] !== undefined && from_to[0].trim != "") {
              fl_from = parseInt(from_to[0]);
            }
            if (from_to[1] !== undefined && from_to[1].trim != "") {
              fl_to = parseInt(from_to[1]);
            }

            if (fl_from == fl_to) {
              floor = "Floor " + fl_from;
            } else {
              if (!isNaN(fl_from) && !isNaN(fl_to)) {
                floor = "Floor " + fl_from + " - " + " Floor " + fl_to;
              }
            }
          }
        }
        if (floor != "") {
          if (floor_breakdown[floor] === undefined) {
            floor_breakdown[floor] = "";
          }

          if (
            floor_data["comments"] !== undefined &&
            floor_data["comments"] != ""
          ) {
            var comment = floor_data["comments"]
              .replace(/\([^)]*\)/gi, "")
              .replace(/class [a-z]'?/gi, "");
            if (comment !== undefined && comment != "undefined") {
              floor_breakdown[floor] += comment.trim() + ", ";
            }
          }
        }
      }
    }

    return (
      <Fragment>
        <Table responsive striped className="dashboard__table-orders">
          <thead>
            <tr>
              <th style={{ width: 200 }}>Floor</th>
              <th>Construction</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(floor_breakdown).map((key) => {
              return (
                <tr>
                  <td>{key}</td>
                  <td>
                    {_capitalizeText(floor_breakdown[key].replace(/,\s*$/, ""))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }
};

const MainInfoUnits = (props) => {
  const { buildingRes } = props;
  let first_close_year = buildingRes.first_closing_date
    ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
    : 0;

  if (first_close_year <= 1970) {
    first_close_year = 9999;
  }

  if (
    !buildingRes.pipeline_total_units_proposed &&
    buildingRes.pipeline_dwelling_units_proposed
  ) {
    buildingRes.pipeline_total_units_proposed =
      buildingRes.pipeline_dwelling_units_proposed;
  }
  return (
    <Fragment>
      <div className="product-summary__info project-product-summary_info">
        <div>
         {buildingRes &&
          buildingRes.construction_type &&
          buildingRes.construction_type["pipeline_construction_type"] ? (
            <p className={`has-info`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info"/>
              Construction Type:
              <span className="project_details-text_style">
                <Fragment>
                  {_capitalizeText(buildingRes.construction_type["pipeline_construction_type"].replace(/-/g, " "))}
                </Fragment>
              </span>
            </p>
          ) : 
            buildingRes &&
            buildingRes.pipeline_job_type &&
            ["a1", "nb"].indexOf(buildingRes.pipeline_job_type)>=0 ? (
              <p className={`has-info`}>
                <FeatureIcon className="has-info" />
                <NoFeatureIcon className="no-info"/>
                Construction Type:
                <span className="project_details-text_style">
                  <Fragment>
                    {buildingRes.pipeline_job_type == "a1" ? "Alteration" : "New Construction"}
                  </Fragment>
                </span>
              </p>
            ) : 
            null
          }
          
          
          
          
          
         {buildingRes &&
         buildingRes.occupancy_type &&
         buildingRes.occupancy_type["Project Components"] &&
         buildingRes.occupancy_type["Project Components"].length > 0 ? (
           <p className={`${buildingRes && buildingRes.occupancy_type && buildingRes.occupancy_type["Project Components"] ? "has-info" : "no-info"}`}>
             <FeatureIcon className="has-info" />
             <NoFeatureIcon className="no-info"/>
             Project Components:
             <span className="project_details-text_style">
               <Fragment>
                 {buildingRes.occupancy_type["Project Components"].join(", ")}
               </Fragment>
             </span>
           </p>
         ) : (
           <Fragment>
           <p className={`has-info`}>
             <FeatureIcon className="has-info" />
             <NoFeatureIcon className="no-info"/>
             Project Type:
             <span className="project_details-text_style">
               <Fragment>
                 {_capitalizeText(_getProjectType(buildingRes))} {_getProjectType(buildingRes) == "rental" && buildingRes.pipeline_project_stage !== "completion" && <span className="small-text light-text"> (Projected)</span>}
               </Fragment>
             </span> 
           </p>
          
            </Fragment>
         )}
         {buildingRes && buildingRes.pipeline_occupancy_classification_proposed_code ?
         <p className={`has-info`}>
           <FeatureIcon className="has-info" />
           <NoFeatureIcon className="no-info"/>
           DOB Code:
           <span className="project_details-text_style">
             {buildingRes &&
             buildingRes.pipeline_occupancy_classification_proposed_code ? (
               <Fragment>
                  {buildingRes.pipeline_occupancy_classification_proposed_code.toUpperCase()}{" "}
                  <span className="small-text light-text">
                   - {_getOccupancy(
                    buildingRes.pipeline_occupancy_classification_proposed_code
                  )}
                  </span>
                </Fragment>
             ): (
                <span className="no-info">--</span>
             )}
           </span>
         </p> :
         <p className={`no-info`}>
           <NoFeatureIcon className="no-info"/>
           DOB Code:
           <span className="no-info">--</span>
             
         </p> 
         
         }
          <p className={`${buildingRes && buildingRes.pipeline_building_stories_proposed && buildingRes.pipeline_building_stories_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Floors:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_building_stories_proposed && buildingRes.pipeline_building_stories_proposed > 0 ? (
                <Fragment>
                  {_formatNumber(
                    buildingRes.pipeline_building_stories_proposed
                  )}{" "}{buildingRes.pipeline_building_stories_existing !== undefined && buildingRes.pipeline_building_stories_existing >0 && (
                    <span className="small-text light-text">
                    {' '}  (Existing Floors: {buildingRes.pipeline_building_stories_existing})
                    </span>
                  )}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_total_units_proposed && buildingRes.pipeline_total_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Total Units:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_total_units_proposed !== undefined && buildingRes.pipeline_total_units_proposed > 0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_total_units_proposed)}
                  {buildingRes.pipeline_dwelling_units_existing !== undefined &&
                    
                    <span className="small-text light-text">
                    {' '}  (Existing Units: {buildingRes.pipeline_dwelling_units_existing})
                    </span>
                  }
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_height_proposed && buildingRes.pipeline_height_proposed > 0 ? "has-info" : "no-info"}`}>
           <FeatureIcon className="has-info" />
           <NoFeatureIcon className="no-info"/>
            Building Height:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_height_proposed >0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_height_proposed)} <span className="small-text light-text">{" Ft "}</span>
                  {buildingRes.pipeline_height_existing !== undefined && buildingRes.pipeline_height_existing>0 &&
                    
                    <span className="small-text light-text">
                    {' '}  (Existing Height: {buildingRes.pipeline_height_existing} Ft)
                    </span>
                  }
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_total_construction_floor_area && buildingRes.pipeline_total_construction_floor_area > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Total Area:
            <span className="project_details-text_style">
              {buildingRes &&
              buildingRes.pipeline_total_construction_floor_area ? (
                <Fragment>
                  {_formatNumber(
                    buildingRes.pipeline_total_construction_floor_area
                  )}{" "}
                  <span className="small-text light-text">Sq Ft</span>
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          
          <TaxAbatement {...props} />
         
         
        </div>
      </div>
    </Fragment>
  );
};

const MainInfoArea = (props) => {
  const { buildingRes } = props;

  let first_close_year = buildingRes.first_closing_date
    ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
    : 0;

  if (first_close_year <= 1970) {
    first_close_year = 9999;
  }
  return (
    <Fragment>
      <div className="product-summary__info project-product-summary_info">
        <div>
          <p className={`${buildingRes && buildingRes.pipeline_zoning_area_residential && buildingRes.pipeline_zoning_area_residential > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Residential Area:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_zoning_area_residential && buildingRes.pipeline_zoning_area_residential > 0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_zoning_area_residential)}{" "}
                  <span className="small-text light-text">Sq Ft</span>
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_dwelling_units_proposed && buildingRes.pipeline_dwelling_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Residential Units:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_dwelling_units_proposed && buildingRes.pipeline_dwelling_units_proposed > 0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_dwelling_units_proposed)}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_condo_units_proposed && buildingRes.pipeline_condo_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
             Sellable Units:
            <span className="project_details-text_style">
              {buildingRes &&
              buildingRes.pipeline_condo_units_proposed && buildingRes.pipeline_condo_units_proposed > 0 ? (
                <Fragment>
                  {_formatNumber(
                    buildingRes.pipeline_condo_units_proposed
                  )}
                  {buildingRes &&
                  buildingRes.pipeline_affordable_condo_units_proposed && buildingRes.pipeline_affordable_condo_units_proposed > 0 && (
                    <span className="small-text light-text">
                    {' '}({_formatNumber(
                        buildingRes.pipeline_affordable_condo_units_proposed
                      )} Affordable)
                    </span>
                  )}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
         <p className={`${buildingRes && buildingRes.pipeline_rental_units_proposed && buildingRes.pipeline_rental_units_proposed > 0 ? "has-info" : "no-info"}`}>
           <FeatureIcon className="has-info" />
           <NoFeatureIcon className="no-info"/>
            Rental Units:
           <span className="project_details-text_style">
             {buildingRes &&
             buildingRes.pipeline_rental_units_proposed && buildingRes.pipeline_rental_units_proposed > 0 ? (
               <Fragment>
                 {_formatNumber(
                   buildingRes.pipeline_rental_units_proposed
                 )}
                 {buildingRes &&
                 buildingRes.pipeline_affordable_rental_units_proposed && buildingRes.pipeline_affordable_rental_units_proposed > 0 && (
                   <span className="small-text light-text">
                   {' '}({_formatNumber(
                       buildingRes.pipeline_affordable_rental_units_proposed
                     )} Affordable)
                   </span>
                 )}
               </Fragment>
             ) : (
               <span className="no-info">--</span>
             )}
           </span>
         </p>
         <p className={`${buildingRes && buildingRes.pipeline_hotel_units_proposed && buildingRes.pipeline_hotel_units_proposed > 0 ? "has-info" : "no-info"}`}>
           <FeatureIcon className="has-info" />
           <NoFeatureIcon className="no-info"/>
           Hotel Units:
           <span className="project_details-text_style">
             {buildingRes && buildingRes.pipeline_hotel_units_proposed ? (
               <Fragment>
                 {_formatNumber(buildingRes.pipeline_hotel_units_proposed)}
               </Fragment>
             ) : (
               <span className="no-info">--</span>
             )}
           </span>
         </p>
          <p className={`${buildingRes && buildingRes.pipeline_storage_units_proposed && buildingRes.pipeline_storage_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Storage Units:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_storage_units_proposed ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_storage_units_proposed)}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          
          
          <p className={`${buildingRes && buildingRes.pipeline_parking_units_proposed && buildingRes.pipeline_parking_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Parking Spaces:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_parking_units_proposed && buildingRes.pipeline_parking_units_proposed > 0  ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_parking_units_proposed)}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

const MainInfoAreaNew = (props) => {
  const { buildingRes } = props;
  let first_close_year = buildingRes.first_closing_date
    ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
    : 0;

  if (first_close_year <= 1970) {
    first_close_year = 9999;
  }
  return (
    <Fragment>
      <div className="product-summary__info project-product-summary_info">
        <div>
          <p className={`${buildingRes && buildingRes.pipeline_zoning_area_commercial && buildingRes.pipeline_zoning_area_commercial > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Commercial Area:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_zoning_area_commercial && buildingRes.pipeline_zoning_area_commercial > 0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_zoning_area_commercial)}{" "}
                  <span className="small-text light-text">Sq Ft</span>
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_zoning_area_retail && buildingRes.pipeline_zoning_area_retail > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Retail Area:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_zoning_area_retail && buildingRes.pipeline_zoning_area_retail > 0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_zoning_area_retail)}{" "}
                  <span className="small-text light-text">Sq Ft</span>
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_zoning_area_community && buildingRes.pipeline_zoning_area_community > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Community Area:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_zoning_area_community && buildingRes.pipeline_zoning_area_community > 0 ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_zoning_area_community)}{" "}
                  <span className="small-text light-text">Sq Ft</span>
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_zoning_area_garage && buildingRes.pipeline_zoning_area_garage > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Garage Area:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_zoning_area_garage && buildingRes.pipeline_zoning_area_garage > 0  ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_zoning_area_garage)}{" "}
                  <span className="small-text light-text">Sq Ft</span>
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          <p className={`${buildingRes && buildingRes.pipeline_office_units_proposed && buildingRes.pipeline_office_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Office Units:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_office_units_proposed && buildingRes.pipeline_office_units_proposed > 0  ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_office_units_proposed)}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
          
          <p className={`${buildingRes && buildingRes.pipeline_retail_units_proposed && buildingRes.pipeline_retail_units_proposed > 0 ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info"/>
            Retail Units:
            <span className="project_details-text_style">
              {buildingRes && buildingRes.pipeline_retail_units_proposed ? (
                <Fragment>
                  {_formatNumber(buildingRes.pipeline_retail_units_proposed)}
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
         
          
          
          
         
          
          
         
        </div>
      </div>
    </Fragment>
  );
};

const QuickLinks = (props) => {
  const { buildingRes, statsRes, share } = props;
  
  return (
      <div className="quick-links">

        <div className="link-section">
          <p className="title"><LinkIcon className="has-info" />More Information:</p>
          {buildingRes.website &&
          <p className="link">
            <a href={`${buildingRes.website}`} target="_blank" className="link-text"> Website <OpenInNewIcon size={14} style={{ marginTop: -2 }} /></a>
          </p>
          }
          {buildingRes.address && buildingRes.address.borough_code && buildingRes.address.block &&
          <p className="link">
            <a href={`https://zola.planning.nyc.gov/lot/${buildingRes.address.borough_code}/${buildingRes.address.block}/${buildingRes.address.lot}`} target="_blank" className="link-text"> Zoning Map <OpenInNewIcon size={14} style={{ marginTop: -2 }} /></a>
          </p>
          }
          {buildingRes.address && buildingRes.address.borough && (buildingRes.community_board || buildingRes.pipeline_community_board) &&
          <p className="link">
           <a href={`https://communityprofiles.planning.nyc.gov/${buildingRes.address.borough.toLowerCase()}/${buildingRes.pipeline_community_board ? buildingRes.pipeline_community_board.toString().slice(1, 3) : buildingRes.community_board }`} target="_blank" className="link-text">Community District <OpenInNewIcon size={14} style={{ marginTop: -2 }} /></a>
          </p>
          }
        </div>

      </div>
    )
  
}

const AltAddresses = (props) => {
  const { buildingRes, statsRes, share } = props;
  const buildingAltAdr = _checkBuildingAltAdr(buildingRes.address);
  console.log(buildingAltAdr)
  if(buildingAltAdr && buildingAltAdr.length > 0){
    return (
      <div className="quick-links address">
    
        <div className="link-section">
          <p className="title"><MapIcon className="has-info" />Alternate Addresses:</p>
          {Array.isArray(buildingAltAdr) ? 
            <Fragment>
              {buildingAltAdr.map(addy => {
                return <p className="link address">{addy}</p>
              })}
            </Fragment>
            : typeof buildingAltAdr === "string" ?
            <p className="link address">{buildingAltAdr}</p>
            : null
          }
        </div>
    
      </div>
    )
  }else{
    return null;
  }
  
  
}

const ProfileCard = (props) => {
  const [color, setColor] = useState("white");

  const onLike = () => {
    if (color === "white") {
      setColor("#70bbfd");
    } else {
      setColor("white");
    }
  };
  const { buildingRes, statsRes, share } = props;

  let images = _getBuildingPhotos(buildingRes);

  if (images && images.length > 0) {
    images = images.map((image) => {
      return { src: image };
    });
  }
  let buildingResUnits = _shouldShow(buildingRes.inventory_total)
    ? buildingRes.inventory_total
    : _shouldShow(buildingRes.total_units_sellable)
    ? buildingRes.total_units_sellable
    : _shouldShow(buildingRes.total_units_residential)
    ? buildingRes.total_units_residential
    : _shouldShow(buildingRes.residential_units)
    ? buildingRes.residential_units
    : _shouldShow(buildingRes.total_units)
    ? buildingRes.total_units
    : false;

  if (
    buildingRes.number_of_buildings > 1 &&
    buildingRes.building_status === "resale" &&
    buildingRes.tax_assessed_residential_units > 1
  ) {
    buildingResUnits = buildingRes.tax_assessed_residential_units;
  }

  let resiArea = 0;

  if (buildingRes.pipeline_dwelling_units_proposed > 0) {
    resiArea =
      buildingRes && buildingRes.pipeline_zoning_area_residential > 0
        ? buildingRes.pipeline_zoning_area_residential
        : buildingRes.pipeline_zoning_sqft_proposed > 0
        ? buildingRes.pipeline_zoning_sqft_proposed
        : 0;
  } else {
    resiArea =
      buildingRes.pipeline_zoning_sqft_proposed > 0
        ? buildingRes.pipeline_zoning_sqft_proposed
        : 0;
  }

  let permitSource = null;
  let schedule_a_proposed = null;
  if (buildingRes.pipeline_permit_json) {
    try {
      permitSource = JSON.parse(buildingRes.pipeline_permit_json);
      if (permitSource && permitSource.schedule_a_proposed) {
        try {
          schedule_a_proposed = JSON.parse(permitSource.schedule_a_proposed);
        } catch (e) {}
      }
    } catch (e) {}
  }
  const bldgArea = {
    totalArea:
      buildingRes && buildingRes.pipeline_total_construction_floor_area
        ? parseInt(
            buildingRes.pipeline_total_construction_floor_area
              .toString()
              .replace(/,/g, "")
          )
        : 0,
    totalAreaResi:
      buildingRes && buildingRes.pipeline_zoning_area_residential
        ? parseInt(
            buildingRes.pipeline_zoning_area_residential
              .toString()
              .replace(/,/g, "")
          )
        : 0,
    totalAreaRetail:
      buildingRes && buildingRes.pipeline_zoning_area_retail
        ? parseInt(
            buildingRes.pipeline_zoning_area_retail.toString().replace(/,/g, "")
          )
        : 0,
    totalAreaComm:
      buildingRes && buildingRes.pipeline_zoning_area_commercial
        ? parseInt(
            buildingRes.pipeline_zoning_area_commercial
              .toString()
              .replace(/,/g, "")
          )
        : 0,
    totalAreaGarage:
      buildingRes && buildingRes.pipeline_zoning_area_garage
        ? parseInt(
            buildingRes.pipeline_zoning_area_garage.toString().replace(/,/g, "")
          )
        : 0,
    totalAreaFactory:
      buildingRes && buildingRes.pipeline_zoning_area_factory
        ? parseInt(
            buildingRes.pipeline_zoning_area_factory
              .toString()
              .replace(/,/g, "")
          )
        : 0,
  };

  if (
    bldgArea.totalArea <= 0 &&
    buildingRes.pipeline_total_construction_floor_area &&
    buildingRes.pipeline_total_construction_floor_area > 1000
  ) {
    bldgArea.totalArea = buildingRes.pipeline_total_construction_floor_area;
  }

  if (
    bldgArea.totalArea < bldgArea.totalAreaResi ||
    bldgArea.totalArea < bldgArea.totalAreaComm ||
    bldgArea.totalArea < bldgArea.totalAreaRetail
  ) {
    bldgArea.totalArea =
      bldgArea.totalAreaResi +
      bldgArea.totalAreaRetail +
      bldgArea.totalAreaComm +
      bldgArea.totalAreaFactory;
  }
  bldgArea["totalCommercial"] = bldgArea.totalAreaComm;
  if (bldgArea.totalAreaRetail > bldgArea.totalAreaComm) {
    bldgArea["totalCommercial"] = bldgArea.totalAreaRetail;
  }

  if (
    bldgArea.totalAreaResi > 0 &&
    bldgArea.totalArea > 0 &&
    bldgArea.totalArea - bldgArea.totalAreaResi > bldgArea["totalCommercial"]
  ) {
    bldgArea["totalCommercial"] = bldgArea.totalArea - bldgArea.totalAreaResi;
  }

  if (
    buildingRes.pipeline_occupancy_type == "commercial" &&
    !bldgArea.totalAreaResi &&
    !bldgArea["totalCommercial"] &&
    bldgArea.totalArea
  ) {
    bldgArea["totalCommercial"] = bldgArea.totalArea;
  }

  bldgArea["totalOther"] = bldgArea.totalArea - bldgArea["totalCommercial"];

  console.log(permitSource);
 
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="no-border no-padding">
          
            
          <div className="profile-card d-block">
            <Grid className="profile-card__info" container>
             
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <MainInfoUnits {...props} />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <MainInfoArea {...props} />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <MainInfoAreaNew {...props} />
              </Grid>
            </Grid>
          </div>
          <AltAddresses {...props} />
          <QuickLinks {...props} />
          {schedule_a_proposed && (
            <div>
              <Row className="mt-5">
                <Col md={12}>
                  <h4 className="product-card__title">Plan by Floor</h4>
                  <h3 className="section-title section-title--not-last subhead">
                    {" "}
                  </h3>
                </Col>
              </Row>
              {showScheduleA(schedule_a_proposed)}
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileCard;
