import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col } from "reactstrap";
import MatMapView from "./MatMapView";
import MatAppBar from "./MatAppBar";
import EditProject from "../../../shared/components/edit/EditProject";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  syncGenericFilter,
  _objectEqual,
  GenerateBuildingSlug,
  GenerateBuildingKey,
  _tranformTagsMongoQuery,
} from "../../../shared/helpers/utils";
import {
  _pipelineMapFilters,
  _buildingStageFilter,
} from "../../../shared/helpers/filters";
import MapModal from "../../../shared/components/map/MapModal";

const buildingUrl = process.env.API_ROOT + "/pipeline/";
const subFilterKey = "subFilters";
const buildingStagesKey = "buildingStages";
const defaultSubFilter = {};
const defaultStage = ["all"];

const getSubFilters = ({ stage, match }) => {
  const type = match.params.type ? match.params.type : "residential";

  let filters = _pipelineMapFilters.filter(
    (filter) =>
      !(filter.exclude_stage && filter.exclude_stage.indexOf(stage) >= 0)
  );

  if (type === "commercial") {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "residential")
    );
  } else {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "commercial")
    );
  }
  return filters;
};

class MatTable extends PureComponent {
  lsPre = `PIPE_DB_`;
  buildingSubFilters = getSubFilters(this.props);

  state = {
    order: syncGenericFilter("order", this.lsPre, "order", "desc"),
    orderBy: syncGenericFilter(
      "order_by",
      this.lsPre,
      "orderBy",
      "pipeline_dwelling_units_proposed"
    ),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 40,
    buildingsFetchInProgress: false,
    clearInput: false,
    end: 30,
    filterBorough: "all_boroughs",
    // filterFetchInProgress: false,
    filterStage: "all_projects",
    futureInventoryData: false,
    // headerHeight: 'auto',
    inventoryData: false,
    originalData: false,
    ovelayMinHeight: false,
    reactTableFiltered: [],
    // signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
    showCounts: { desktop: 3, tablet: 2, mobile: 1 },
    start: 0,
    stats: {},
    subFilters: syncURLFilters(
      this.props,
      this.buildingSubFilters,
      subFilterKey,
      this.lsPre,
      defaultSubFilter
    ),
    tableData: false,
    totalUnsoldUnits: false,
    searchText: syncURLSearch(this.props, "searchText"),
    viewType: "map",
    facetData: null,
    buildingStages: syncStageFilters(
      this.props,
      _buildingStageFilter,
      buildingStagesKey,
      this.lsPre,
      defaultStage
    ),
    type:
      this.props.match && this.props.match.params.type
        ? this.props.match.params.type
        : "residential",
    standardView: true,
  };

  q = "";
  fq = "";
  sort = "";

  componentDidMount() {
    //this._getInventoryStats();
    //this._getPipelineBuildings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let buildingStageChange = !_isEqual(
      prevState.buildingStages,
      this.state.buildingStages
    );
    let subFilterChanged =
      this.buildingSubFilters.filter((item) => {
        return (
          this.state.subFilters[item.search_key] !==
          prevState.subFilters[item.search_key]
        );
      }).length > 0;
      let multiSubFilterChanged =
      this.buildingSubFilters.filter((item) => {
        return (
          this.state.subFilters[item.search_key && item.search_key[0]] !==
          prevState.subFilters[item.search_key && item.search_key[0]]
        );
      }).length > 0;
    if (
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.filterStage !== prevState.filterStage ||
      this.state.searchText !== prevState.searchText ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.page !== prevState.page ||
      this.state.rowsPerPage !== prevState.rowsPerPage ||
      searchTagsChange ||
      subFilterChanged ||
      buildingStageChange ||
      multiSubFilterChanged
    ) {
      const resetPage = this.state.page === prevState.page;
      console.log("component update", prevState, this.state);
      this._getPipelineBuildings();
    }
  }

  handleRequestSort = (event, property, setOrder) => {
    const orderBy = property;
    let order = setOrder ? setOrder : "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc" && !setOrder) {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  shouldLinkToMP = (building) => {
    const { user } = this.props;
    return (
      (user.role &&
        Array.isArray(user.role) &&
        user.role.indexOf("admin") >= 0) ||
      [
        "active",
        "coming soon",
        "offering plan required",
        "offering plan acquired",
        "condo declaration vetted",
      ].indexOf(building.offering_verified_status) < 0
    );
  };

  handleBuildingClick = (event, building) => {
    console.log(building);
    this.handleBuildingPreviewClick(event, building);

    /*
	 if(this.shouldLinkToMP(building)){
		 const buildingURL = _getBuildingURL(building, 'building_slugs');	 
		 window.open(`https://marketproof.com${buildingURL.replace('buildings', 'building')}?tab=overview`)
	 }else{
		 this.handleBuildingPreviewClick(event, building);

	 }
*/

    //this.props.history.push(buildingURL);
  };

  handleBuildingPreviewClick = (event, building) => {
    /*
	 event.preventDefault();
	 event.stopPropagation();
*/
    const buildingURL = _getBuildingURL(building, "id");

    window.open(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0, start: 0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  _getPipelineBuildings = async (query, resetPage, obj) => {
    const { user, searchTags, organizationRes, contactRes } = this.props;
    const { buildingStages, type, start, rowsPerPage, page, orderBy, order } =
      this.state;
    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let searchFilters = [{ "meta.deleted": { $exists: false } }];
    let baseQuery = {
      pipeline_occupancy_type: { $in: ["residential", "mixed-use"] },
    };

    if (type === "commercial") {
      baseQuery = {
        pipeline_occupancy_type: { $in: ["commercial", "mixed-use"] },
      };
    }
    if (organizationRes && organizationRes.projects) {
      baseQuery = { key: { $in: organizationRes.projects } };
    } else if (contactRes && contactRes.projects) {
      baseQuery = { key: { $in: contactRes.projects } };
    }

    searchFilters.push(baseQuery);

    let locQuery = _tranformTagsMongoQuery(searchTags);

    if (locQuery) {
      searchFilters.push(locQuery);
    }

    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          let qVal = this.buildingSubFilters.filter(
            (filter) => filter.search_key === key
          )[0];
          qVal =
            qVal &&
            qVal.values.filter((value) => value.filter === subFilterVal)[0];
          qVal = qVal && qVal.search_value;
          const addQuery = {};
          addQuery[key] = qVal;
          searchFilters.push(addQuery);
        }
      });
    }

    const buildingStageFilters = {
      "pre-development": { pipeline_project_stage: "planning" },
      construction: { pipeline_project_stage: "construction" },
      operation: { pipeline_project_stage: "completion" },
    };

    if (buildingStages && buildingStages.length > 0) {
      let stageQ = [];
      if (buildingStages.indexOf("all") < 0) {
        buildingStages.map((stage) => {
          stageQ.push(buildingStageFilters[stage]);
        });

        if (stageQ.length > 1) {
          searchFilters.push({ $or: stageQ });
        } else if (stageQ.length === 1) {
          searchFilters.push(stageQ[0]);
        }
      }
    }
    let sort = {};

    sort[orderBy] = order === "asc" ? 1 : -1;
    setURLFromState(this.props, this.state);
    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
      },
      sort: sort,
      limit: parseInt(rowsPerPage),
      skip: parseInt(start),
    };

    /*
	  let atlasQuery = [{
					"$search": {
							"index": "default",
				            "compound" : {
				                "must" : searchFilters
				            }
							
						}
					},
					{
						"$project": {
							"name": 1,
				            "display_full_street_address" : 1,
				            "key" : 1,
				            "address" : {
					            "full_street_address" : 1,
				                "neighborhood" : 1,
				                "borough" : 1,
				                "city" : 1,
				                "zip" : 1               
				            },
				            "attributes" : 1
						}
					},
					{
						"$sort": {
				            "pipeline_dwelling_units_proposed" : -1
						}
					},
				    {
				        "$facet" : {
				            "paginatedResults": [{ "$skip": start }, { "$limit": rowsPerPage }],
				            "totalCount" : [
				                {
				                    "$count" : "key"
				                }
				            ]
				        }
				    }]
*/
    let axiosData = {
      url: buildingUrl + "query",
      method: "post",
      query: mongoQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        let docs = [];
        if (res && res.docs) {
          docs = res.docs;
        }
        docs.forEach((doc) => {
          doc["building_slugs"] = [GenerateBuildingSlug(doc)];
          doc["solr_key"] = GenerateBuildingKey(doc);
          doc["master_key"] = doc["key"];
        });
        this.setState((prevState) => ({
          buildingsFetchInProgress: false,
          tableData: docs,
          reactTableFiltered: docs,
          totalCount: res.count && res.count, //res[0].totalCount[0].key,
          start: resetPage ? 0 : start,
          page: resetPage ? 0 : page,
          stats: {},
          facetData: {},
        }));
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _filterTableByInput = (name, eValue) => {
    console.log("_filterTableByInput name", name, "eValue", eValue);
    console.log("_filterTableByInput name", name, "eValue", eValue);
    this.setState({ searchText: eValue });
  };

  _filterResponseTableData = (eValue) => {
    console.log("_filterResponseTableData eValue", eValue);
    if (
      !this.state.tableData ||
      (this.state.tableData && this.state.tableData.length === 0)
    ) {
      return;
    }

    const filteredData =
      eValue.length > 0
        ? this.state.tableData.filter((item) => {
            const inputVal = eValue.toLowerCase();
            let nameVal, adrVal, hoodVal;
            if (item.name) {
              nameVal = item.name.toLowerCase();
            }

            if (
              (item.display_full_street_address &&
                _shouldShow(item.display_full_street_address)) ||
              (item.full_street_address &&
                _shouldShow(item.full_street_address))
            ) {
              const field = item.display_full_street_address
                ? "display_full_street_address"
                : "full_street_address";

              adrVal = item[field].toLowerCase();
            }

            if (item.neighborhood && _shouldShow(item.neighborhood)) {
              const hood =
                Array.isArray(item.neighborhood) &&
                _shouldShow(item.neighborhood[0])
                  ? item.neighborhood[0]
                  : item.neighborhood;
              hoodVal = hood.toLowerCase();
            }

            // console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
            // searching name and ONE adr field - could update to search both display and full and all alt adrs
            return (
              (nameVal && nameVal.indexOf(inputVal) >= 0) ||
              (adrVal && adrVal.indexOf(inputVal) >= 0) ||
              (hoodVal && hoodVal.indexOf(inputVal) >= 0)
            );
          })
        : this.state.tableData;

    console.log(
      "filteredData",
      filteredData,
      "this.state.tableData",
      this.state.tableData
    );
    this.setState((prevState) => ({
      // buildingsFetchInProgress: false,
      reactTableFiltered: filteredData,
      //tableData: filteredData,
      //originalData: response,
      //filterFetchInProgress:false
    }));
  };

  _filterColumn = (columnId, value, display) => {
    const newfilter = {
      display: display,
      id: columnId,
      value,
    };

    const filtersWhithoutNew = this.state.reactTableFiltered.filter(
      (item) => item.id !== columnId
    );
    this.setState({
      reactTableFiltered: [...filtersWhithoutNew, newfilter],
    });
  };

  _setSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, this.state.subFilters, {
      [search_key]: search_val,
    });
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });

    setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
  };

  _setBuildingStage = (stage) => {
    let newStages = this.state.buildingStages.slice();
    const index = newStages.indexOf(stage);
    const indexAll = newStages.indexOf("all");

    if (index >= 0) {
      if (stage === "all") {
        newStages = ["sponsor"];
      } else {
        newStages.splice(index, 1);
      }
    } else {
      if (stage === "all") {
        newStages = ["all"];
      } else {
        if (indexAll >= 0) {
          newStages.splice(indexAll, 1);
        }
        newStages.push(stage);
      }
    }
    if (newStages.length === 0) newStages = ["all"];
    this.setState({
      buildingStages: newStages,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(this.lsPre, buildingStagesKey, JSON.stringify(newStages));
  };

  _resetSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, defaultSubFilter);
    this.setState({
      buildingStages: defaultStage,
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    deleteLocalStorage(this.lsPre, buildingStagesKey);
    deleteLocalStorage(this.lsPre, subFilterKey);
  };

  _resetActive = () => {
    let isDefault =
      this.props.searchTags.length === 0 &&
      _objectEqual(defaultSubFilter, this.state.subFilters) &&
      _objectEqual(defaultStage, this.state.buildingStages);
    return !isDefault;
  };

  _setBorough = (search_val) => {
    this.setState({
      filterBorough: search_val,
      start: 0,
      end: this.state.rowPerPage,
    });
  };

  _toggleView = (type) => {
    this.setState({ viewType: type });
  };

  toggleMapView = (event) => {
    event.preventDefault();
    this.setState({ standardView: !this.state.standardView });
  };

  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      tableData,
      reactTableFiltered,
      subFilters,
      totalCount,
      viewType,
      buildingsFetchInProgress,
      type,
    } = this.state;
    const { user, isSingle } = this.props;

    const tableCount = totalCount;

    return (
      <Fragment>
        {false && !isSingle && (
          <Row>
            <Col md={12} className={"mb-3 text-right"}>
              <EditProject
                buildingRes={{
                  occupancy_type: { pipeline_occupancy_type: type },
                }}
                add
              />
            </Col>
          </Row>
        )}
        <Row>
          <Card>
            <CardBody className="pipeline-map-card-height">
              {/*<div className="card__title">
		              <h5 className="bold-text">Select and Analyze</h5>
		            </div>*/}
              <MapModal
                {...this.props}
                toggleView={this.toggleMapView}
                standardView={this.state.standardView}
                showMapViewButton
              />
              <div id="pipeline-map">
                <MatAppBar
                  handleSearch={this._filterTableByInput}
                  numSelected={[...selected].filter((el) => el[1]).length}
                  handleDeleteSelected={this.handleDeleteSelected}
                  onRequestSort={this.handleRequestSort}
                  subFilters={subFilters}
                  resetSubFilter={this._resetSubFilter}
                  buildingStages={this.state.buildingStages}
                  setBuildingStage={this._setBuildingStage}
                  setSubFilter={this._setSubFilter}
                  _filters={
                    user && user.role.indexOf("admin") >= 0
                      ? this.buildingSubFilters
                      : this.buildingSubFilters.filter(
                          (item) => !item.admin_only
                        )
                  }
                  placeHolder={"Project, Address, Neighborhood or Borough"}
                  buildingAttributes={[]}
                  addBuilding={false}
                  resetActive={this._resetActive()}
                  type={type}
                />

                <MatMapView
                  {...this.state}
                  {...this.props}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  isSelected={this.isSelected}
                  handleBuildingClick={this.handleBuildingClick}
                  handleSelectAllClick={this.handleSelectAllClick}
                  handleRequestSort={this.handleRequestSort}
                  standardView={this.state.standardView}
                  key={this.state.standardView}
                />
                
              </div>
            </CardBody>
          </Card>
        </Row>
      </Fragment>
    );
  }
}
export default connect((state) => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags)
    ? state.search.searchTags.slice()
    : [],
}))(withRouter(MatTable));
