import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Badge,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";
import {
  FreezeTableBuildings,
  FreezeTableUnits,
} from "./components/FreezeTables";
import { BuildingsGridView, ProjectGridView } from "./components/GridViews";

import {
  BuildingAnalysisView,
  UnitsAnalysisView,
} from "./components/AnalysisViews";
import { withOktaAuth } from "@okta/okta-react";

// import SingleUnitShare from "../../../SingleUnit/components/Share/index";
// import SingleBuildingShare from "../../../SingleBuilding/components/Share/index";

import { Publisher } from "../../../shared/pubsub/publisher.js";
import { isMobile, isTablet } from "react-device-detect";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  buildingFL,
  propertyFL,
  _privateKey,
  _replaceAbbreviations,
  _getUnitFloorPlan,
  UnitCurrentStatus,
} from "../../../shared/helpers/utils";
import { _getUser } from "../../../shared/components/auth/sso/ssoutils";
import LinearLoading from "../../../shared/components/LinearLoading";
import "./custom.scss";
import SingleProjectShare from "../../SingleProject/components/Share/index";
import BuildingContainer from "../../BuildingDetail/components/BuildingContainer";
import BuildingViewPopup from "./BuildingViewPopup";

const apiURL = process.env.API_ROOT + "/query/";
const buildingUrl = process.env.API_ROOT + "/";
const oktaToken = process.env.OKTA_TOKEN;

// const settings = {
// 	  dots: false,
// 	  infinite: false,
// 	  speed: 500,
// 	  autoplay: false,
// 	  swipeToSlide: true,
// 	  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
// 	  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
// 	  responsive: [
// 		{ breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
// 		{ breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
// 		{ breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
// 		{ breakpoint: 1536, settings: { slidesToShow: 4, slidesToScroll: 4 } },
// 		{ breakpoint: 100000, settings: { slidesToShow: 4, slidesToScroll: 4} },
// 	  ],
//   };

class CompReportShare extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        this.props.match && this.props.match.params.tab
          ? this.props.match.params.tab
          : "projects",
      fetchInProgress: false,
      buildingRes: null,
      unitRes: null,
      userRes: null,
      listingRes: null,
      buildingsFetchInProgress: false,
      modal: false,
      modalShareData: false,
      shareType: false,
      unitStats: false,
      buildingStats: false,
      popupBuildingSlug: false,
    };
    this.buildingSlug = false;

    this.urlState = {};
  }

  componentDidMount() {
    const { sharePageData } = this.props;

    if (sharePageData) {
      this.buildingSlug = sharePageData.building_key;

      this.urlState = sharePageData.custom_data
        ? sharePageData.custom_data
        : {};
      if (
        !(
          sharePageData &&
          sharePageData.custom_data &&
          sharePageData.custom_data.group &&
          sharePageData.custom_data.group.group_data &&
          sharePageData.custom_data.group.group_data.projects &&
          sharePageData.custom_data.group.group_data.projects.length > 0
        )
      ) {
        this.setState({ activeTab: "buildings" });
      }
    }

    this._getBuildings();
    this._getUnits();
    this._getUserData();

    Publisher.publish(`track-mp.shareView.collection`, {
      collection:
        sharePageData &&
        sharePageData.custom_data &&
        sharePageData.custom_data.group
          ? sharePageData.custom_data.group
          : false,
      user: sharePageData && sharePageData.user ? sharePageData.user : false,
      report:
        sharePageData && sharePageData._id ? { _id: sharePageData._id } : false,
      checkDupURL: true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { sharePageData } = this.props;
    if (!prevState.buildingRes && this.state.buildingRes) {
      Publisher.publish(`track-mp.shareView.collection`, {
        collection:
          sharePageData &&
          sharePageData.custom_data &&
          sharePageData.custom_data.group
            ? sharePageData.custom_data.group
            : false,
        user: sharePageData && sharePageData.user ? sharePageData.user : false,
        report:
          sharePageData && sharePageData._id
            ? { _id: sharePageData._id }
            : false,
        checkDupURL: true,
      });
    }
  }

  _getUserData = (key) => {
    // console.log('_getBuildingData called', this.state)
    const { sharePageData, updateShareData } = this.props;
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    let headers = {
      Authorization: `SSWS ${oktaToken}`,
    };
    let userData = {
      url: `https://dev-397854.okta.com/api/v1/users/${
        sharePageData && sharePageData.user_key ? sharePageData.user_key : key
      }`,
      method: "get",
      headers: headers,
    };

    _axiosCall(userData)
      .then((res) => {
        console.log("_getUserData res back ,", res);

        if (res && res.errorCode && res.errorCode == "E0000007") {
          _getUser({
            id:
              sharePageData && sharePageData.user_key
                ? sharePageData.user_key
                : key,
          }).then((ssoUser) => {
            console.log(ssoUser);
            if (ssoUser) {
              this.setState({
                userRes: ssoUser,
                fetchInProgress: false,
              });

              updateShareData(
                Object.assign({}, sharePageData, { user: ssoUser })
              );
            }
          });
        } else {
          this.setState({
            userRes: res.profile,
            fetchInProgress: false,
          });

          updateShareData(
            Object.assign({}, sharePageData, { user: res.profile })
          );
        }
      })
      .catch((error) => {
        console.log("_getUserData error ", error);
        this.setState({
          fetchInProgress: false,
        });
      });
  };

  _getBuildings = () => {
    const { sharePageData } = this.props;
    const { user } = sharePageData;
    if (
      !(
        sharePageData &&
        sharePageData.custom_data &&
        sharePageData.custom_data.group &&
        sharePageData.custom_data.group.group_data &&
        sharePageData.custom_data.group.group_data.projects &&
        sharePageData.custom_data.group.group_data.projects.length > 0
      )
    ) {
      return false;
    }

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      {
        key: { $in: [...sharePageData.custom_data.group.group_data.projects] },
      },
    ];

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        meta: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
        project_stage: 1,
        pipeline_project_stalled: 1,
        pipeline_project_abandoned: 1,
      },
      limit: 50,
    };
    // var q =
    //   "key:(" +
    //   sharePageData.custom_data.group.group_data.buildings.join(" ") +
    //   ")";

    // var fq = ["deleted:false"];

    //console.log(user && user.role )
    let axiosData = {
      url: buildingUrl + "pipeline/query",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = _axiosCall(axiosData)
      .then((res) => {
        //this._setBuyersList(res);
        if (res && res.docs) {
          this.setState({
            buildingRes: res.docs,
            buildingStats: res.facets,
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        } else {
          this.setState({
            buildingRes: [],
            buildingStats: {},
            fetchInProgress: false,
            buildingsFetchInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _getUnits = () => {
    const { sharePageData } = this.props;
    const { user } = sharePageData;
    if (
      !(
        sharePageData &&
        sharePageData.custom_data &&
        sharePageData.custom_data.group &&
        sharePageData.custom_data.group.group_data &&
        sharePageData.custom_data.group.group_data.buildings &&
        sharePageData.custom_data.group.group_data.buildings.length > 0
      )
    ) {
      return false;
    }

    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    let fq = [
      "key:(" +
        sharePageData.custom_data.group.group_data.buildings.join(" ") +
        ")",
      "deleted:false",
    ];

    let axiosData = {
      url: apiURL + "/we3-buildings",
      method: "post",
      query: {
        q: "*:*",
        fq: fq,
        // fl: [...propertyFL],
        wt: "json",
        // sort: "building_name asc, display_full_street_address asc, address_2_normalized asc",
        rows: user && user.role && user.role.indexOf("admin") >= 0 ? 999 : 10,
      },
    };

    if (sharePageData.custom_data && sharePageData.custom_data.show_stats) {
      axiosData.query["json.facet"] = {
        asking_price: {
          type: "query",
          q: `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *]`,
          facet: {
            low: `min(last_listed_sale_price_all)`,
            high: `max(last_listed_sale_price_all)`,
            average: `avg(last_listed_sale_price_all)`,
            sum: `sum(last_listed_sale_price_all)`,
          },
        },
        asking_ppsf: {
          type: "query",
          q: `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *]`,
          facet: {
            low: `min(div(last_listed_sale_price_all, sq_ft))`,
            high: `max(div(last_listed_sale_price_all, sq_ft))`,
            average: `avg(div(last_listed_sale_price_all, sq_ft))`,
          },
        },
        sold_price: {
          type: "query",
          q: `last_sold_price:[100000 TO *] AND sq_ft:[100 TO *]`,
          facet: {
            low: `min(last_sold_price)`,
            high: `max(last_sold_price)`,
            average: `avg(last_sold_price)`,
            sum: `sum(last_sold_price)`,
          },
        },
        sold_ppsf: {
          type: "query",
          q: `last_sold_price:[100000 TO *] AND sq_ft:[100 TO *]`,
          facet: {
            low: `min(div(last_sold_price, sq_ft))`,
            high: `max(div(last_sold_price, sq_ft))`,
            average: `avg(div(last_sold_price, sq_ft))`,
          },
        },
        total_sold: {
          type: "query",
          q: `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)`,
          facet: {
            low_price: "min(last_listed_sale_price_all)",
            high_price: "max(last_listed_sale_price_all)",
            average_price: "avg(last_listed_sale_price_all)",
            average_sq_ft: "avg(last_listed_sale_price_all)",
            sum_price: "sum(last_listed_sale_price_all)",
            low_sq_ft: "min(sq_ft)",
            high_sq_ft: "max(sq_ft)",
            low_bed: "min(bedrooms)",
            high_bed: "max(bedrooms)",
          },
        },
        sq_ft: {
          type: "query",
          q: `sq_ft:[1 TO *]  AND -property_type:"resident manager unit"`,
          facet: {
            avg_sq_ft: "avg(sq_ft)",
            sum_sq_ft: "sum(sq_ft)",
          },
        },
        original_owner: {
          type: "query",
          q: `times_exchanged:[0 TO *] AND owner:*`,
          facet: {
            times_sold: {
              type: "terms",
              field: "times_sold",
            },
            times_exchanged: {
              type: "terms",
              field: "times_exchanged",
            },
          },
        },
        corp_owner: {
          type: "query",
          q: `(owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c))`,
        },
      };
    }

    let formattedData = _axiosCall(axiosData)
      .then((res) => {
        //this._setBuyersList(res);
        if (res && res.docs) {
          let keys = res.docs.map((unit) => unit.alternate_keys);
          if (!keys || keys.length <= 0) {
            this.setState({
              unitRes: res.docs,
              unitStats: res.facets ? res.facets : {},
              fetchInProgress: false,
              fetchInProgress: false,
            });
          } else {
            this._getListingData(res.docs, keys, res.facets);
          }
        } else {
          this.setState({
            unitRes: [],
            fetchInProgress: false,
            fetchInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _getListingData = async (unitRes, keys, unitStats) => {
    if (!keys || keys.length <= 0) {
      return false;
    }

    let q = "key:(" + keys.join(" ") + ")";

    let axiosData = {
      url: apiURL + "search-listings?cache=15",
      method: "post",
      query: {
        q: q,
        fq: ["deleted:false"],
        sort: "solr_updated_date desc",
        wt: "json",
        rows: 10,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (res.docs && res.docs.length > 0) {
          unitRes.map((unit) => {
            unit["listing"] =
              res.docs.filter((l) => l.key == unit["last_listed_sale_key_all"])
                .length > 0
                ? res.docs.filter(
                    (l) => l.key == unit["last_listed_sale_key_all"]
                  )[0]
                : false;
          });
          this.setState({
            unitRes: unitRes,
            unitStats: unitStats ? unitStats : {},
          });
        } else {
          this.setState({
            unitRes: unitRes,
            unitStats: unitStats ? unitStats : {},
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    return formattedData;
  };

  _getACRISData = async () => {
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { buildingRes } = this.props;
    const unit = this.props.match.params.unit
      .replace("apt-", "apt\\ ")
      .replace(/-/g, "*");
    const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join(
      '" OR "'
    )}") AND zip:${buildingRes.zip}) AND address_2_normalized:(${unit})`;

    let axiosData = {
      url: apiURL + "we3-transactions?cache=15",
      method: "post",
      query: {
        q: q,
        fq: ["source_providers:*ACRIS*"],
        sort: "close_date_formatted desc",
        wt: "json",
        rows: 10,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (res.docs && res.docs.length > 0) {
          this.setState({ transactions: res.docs, fetchInProgress: false });
        } else {
          this.setState({ transactions: false, fetchInProgress: false });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        this.setState({ transactions: false, fetchInProgress: false });
      });
    return formattedData;
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    this.setState({ activeTab: tab });
  };

  toggleModal(type, data) {
    //this.snackOpen('Your Message Has Been Sent!', 'success');
    const { sharePageData } = this.props;

    let modalShareData = JSON.parse(JSON.stringify(sharePageData));

    delete modalShareData["group"];
    modalShareData["report_type"] = "single";
    if (type == "building") {
      modalShareData["building_key"] = data.key;
      modalShareData["building"] = data;
    } else if (type == "unit") {
      modalShareData["building_key"] = data.building;
      modalShareData["property_key"] = data.key;
      modalShareData["property"] = data;
    }

    this.setState((prevState) => ({
      modal: !prevState.modal,
      modalShareData: modalShareData,
      shareType: type,
      popupBuildingSlug: data && data.building_slugs && data.building_slugs[0],
    }));
  }

  render() {
    const { rtl, sharePageData, preview } = this.props;
    const {
      buildingRes,
      unitRes,
      unitStats,
      buildingStats,
      urlState,
      userRes,
      fetchInProgress,
      buildingsFetchInProgress,
      activeTab,
      modal,
      modalShareData,
      shareType,
    } = this.state;

    const defaultUser = {
      title: "Real Estate Agent",
      avatar: `${process.env.PUBLIC_URL}/img/account.png`,
      role: "buyer",
    };

    const user = Object.assign({}, defaultUser, userRes);

    if (userRes === null || fetchInProgress || buildingsFetchInProgress) {
      return <LinearLoading />;
    }

    const group =
      sharePageData &&
      sharePageData.custom_data &&
      sharePageData.custom_data.group
        ? sharePageData.custom_data.group
        : false;
    const showTabs =
      group &&
      group.group_data &&
      group.group_data.projects &&
      group.group_data.projects.length > 0 &&
      group.group_data.buildings &&
      group.group_data.buildings.length > 0;

    return (
      <div>
        {/*buildingRes && buildingRes.length > 0 &&
					<ShowBuildingsTable data={buildingRes} title={"Manhattan"} color={"#4ce1b6"} />
				*/}
        {((sharePageData.custom_data &&
          sharePageData.custom_data.report_title) ||
          sharePageData.custom_data.prepared_for) && (
          <div className={`mb-3 ${isMobile ? "" : "mt-5"}`}>
            {sharePageData.custom_data &&
              sharePageData.custom_data.report_title && (
                <h3 className="page-title mb-1">
                  {sharePageData.custom_data.report_title}
                </h3>
              )}
            {sharePageData.custom_data &&
              sharePageData.custom_data.report_subtitle && (
                <h4 className="mb-1">
                  {sharePageData.custom_data.report_subtitle}
                </h4>
              )}
            <hr />
          </div>
        )}

        {showTabs && (
          <div className="tabs tabs--bordered-bottom mb-0">
            <div className="tabs__wrap">
              <Nav tabs>
                {group &&
                  group.group_data &&
                  group.group_data["projects"] &&
                  group.group_data["projects"].length > 0 && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "projects",
                        })}
                        onClick={() => {
                          this.toggle("projects");
                        }}
                      >
                        <span className="tab-title">Projects</span>
                      </NavLink>
                    </NavItem>
                  )}
                {group &&
                  group.group_data &&
                  group.group_data["buildings"] &&
                  group.group_data["buildings"].length > 0 && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "buildings",
                        })}
                        onClick={() => {
                          this.toggle("buildings");
                        }}
                      >
                        <span className="tab-title">Buildings</span>
                      </NavLink>
                    </NavItem>
                  )}
              </Nav>
            </div>
          </div>
        )}
        <TabContent activeTab={activeTab}>
          {activeTab === "projects" && (
            <TabPane tabId="projects">
              {buildingRes && buildingRes.length > 0 && (
                <div className="mb-5">
                  {sharePageData.custom_data &&
                    sharePageData.custom_data.show_stats && (
                      <BuildingAnalysisView buildingStats={buildingStats} />
                    )}
                  {sharePageData.report_type == "comp" && !isMobile ? (
                    <FreezeTableBuildings
                      buildingRes={buildingRes}
                      toggleModal={this.toggleModal.bind(this)}
                    />
                  ) : (
                    <ProjectGridView
                      buildingRes={buildingRes}
                      toggleModal={this.toggleModal.bind(this)}
                    />
                  )}
                </div>
              )}
            </TabPane>
          )}
          {activeTab === "buildings" && (
            <TabPane tabId="buildings">
              {unitRes && unitRes.length > 0 && (
                <div className="mb-5">
                  {sharePageData.custom_data &&
                    sharePageData.custom_data.show_stats && (
                      <UnitsAnalysisView unitStats={unitStats} />
                    )}
                  {sharePageData.report_type == "comp" && !isMobile ? (
                    <FreezeTableUnits
                      unitRes={unitRes}
                      toggleModal={this.toggleModal.bind(this)}
                    />
                  ) : (
                    <BuildingsGridView
                      unitRes={unitRes}
                      toggleModal={this.toggleModal.bind(this)}
                    />
                  )}
                </div>
              )}
            </TabPane>
          )}
        </TabContent>
        <Row>
          <Col md={12}>
            <p
              className={"small-text light-text mt-4 mb-2"}
              style={{ color: "#858AA1" }}
            >
              All information is from sources deemed reliable but is subject to
              errors and omissions. No representation is made as to the accuracy
              of any information. All information is subject to change. All
              rights to content, photographs, and graphics are reserved for the
              content source.
            </p>
          </Col>
        </Row>
        {/* {!preview && <ShareFooter sharePageData={sharePageData} />} */}
        <Fragment>
          <Modal
            isOpen={modal}
            toggle={this.toggleModal.bind(this)}
            modalClassName={`ltr-support`}
            className={`modal-dialog contact__modal`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggleModal.bind(this)}
              />
            </div>
            <div className="share modal__body">
              {shareType == "building" ? (
                <SingleProjectShare
                  sharePageData={modalShareData}
                  mpndBuilding={modalShareData.building}
                  permitFiling={false}
                  setBuilding={this._getBuildings}
                  user={user}
                />
              ) : (
                <BuildingViewPopup
                  building_slug={this.state.popupBuildingSlug}
                  user={user}
                  hideAddCollectionBtn={true}
                  isShare={true}
                  // removeCss
                  // mainTab={{ key: "construction", name: "Construction" }}
                />
              )}
            </div>
          </Modal>
        </Fragment>
      </div>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
  user: state.user,
}))(withTranslation("common")(withRouter(withOktaAuth(CompReportShare))));
