import React, { useState, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  Badge,
  Row,
  Collapse,
} from "reactstrap";
import moment from "moment";
import HeartIcon from "mdi-react/HeartIcon";
import StarIcon from "mdi-react/StarIcon";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import { Link } from "react-router-dom";
import ProfileGallery from "./ProfileGallery";
import classNames from "classnames";
import AddToBuyersListButton from "../../../../../shared/components/buttons/AddToBuyersListButton";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import SavedCount from "../../../../../shared/components/SavedCount";

import EmailIcon from "mdi-react/EmailIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import WebIcon from "mdi-react/WebIcon";

import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";

import TrendDownIcon from "mdi-react/TrendingDownIcon";
import TrendUpIcon from "mdi-react/TrendingUpIcon";

import EnterpriseGate from "./../../../../../shared/components/payment/EnterpriseGate";
import { _hasPermission } from "./../../../../../shared/helpers/access";
import ViewContactButton from "../../../../../shared/components/buttons/ViewContactButton";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _formatBed,
  _formatBath,
  _getBuildingURL,
  _nameToUrl,
  _formatUnitNumber,
  _formatOwnerName,
  _isResiUnit,
  _formatPhoneNumber,
  _PickOrgRole,
  _formatEmailWithHide, 
  _formatPhoneWithHide
} from "../../../../../shared/helpers/utils";

const ProfileCard = (props) => {
  const { organizationRes } = props;
  const [show, setShow] = useState(false);
  let roles = [];
  if (
    organizationRes &&
    organizationRes.project_roles &&
    Object.keys(organizationRes.project_roles).length > 0
  ) {
    if (
      organizationRes.project_keys &&
      organizationRes.project_keys.length == 1
    ) {
      roles = _PickOrgRole(organizationRes, organizationRes.project_keys[0]);
    } else {
      Object.keys(organizationRes.project_roles).forEach((key) => {
        const p = organizationRes.project_roles[key];
        p.map((r) => {
          r =
            r === "applicant"
              ? "permit applicant"
              : r === "filing_applicant"
              ? "permit applicant"
              : r;
          if (roles.indexOf(r) < 0) {
            roles.push(r);
          }
        });
      });
    }
  }

  return (
    <Col md={12} lg={12}>
      <Card className="com_card_style">
        <CardBody className="no-border no-padding">
          <div>
            <Row>
              <Col xl={6} lg={6} md={6} className="mb-2">
                {organizationRes && organizationRes.website ? (
                  <div>
                    <h5 className="com_head_weight">Website:</h5>
                    <p>
                      <a
                        className="product__external-link com_product_link_size"
                        href={`${organizationRes.website}`}
                        target="_blank"
                      >
                        {organizationRes.website}
                        <OpenInNewIcon
                          size={20}
                          style={{ marginLeft: 10, marginTop: -2 }}
                        />
                      </a>
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {organizationRes &&
                organizationRes.categories &&
                organizationRes.categories.length > 0 ? (
                  <div
                    className={organizationRes.website ? "com_div_margin" : ""}
                  >
                    <h5 className="com_head_weight">Business Categories:</h5>

                    <p className="com_pera_style">
                      {organizationRes.categories.sort().map(c => _capitalizeText(c.replace(/-/g, " "))).join(", ")}
                    </p>
                  </div>
                ) : roles.length > 0 ? (
                  <div
                    className={
                      organizationRes && organizationRes.website
                        ? "contact_dev_margin"
                        : "mt-0"
                    }
                  >
                    <h5 className="com_head_weight">Project Roles:</h5>
                    <p className="com_pera_style">
                      {roles.map((u) =>
                        _capitalizeText(u.toString().replace("_", " "))
                      ).join(", ")}
                    </p>
                  </div>
                ) : null}

                {(organizationRes && (
                  (organizationRes.email_addresses && organizationRes.email_addresses.length>0)
                  || (organizationRes.email && organizationRes.email.length>0))) ? (
                  <div className="com_div_margin">
                    <h5 className="com_head_weight">
                      Email{" "}
                      {organizationRes.email.length > 1
                        ? "Addresses"
                        : "Address"}
                      :
                    </h5>
                    <p>
                      {(organizationRes.email_addresses &&
                        organizationRes.email_addresses.length) > 0 ? (
                        <Fragment>
                          {organizationRes.email_addresses.map((email) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link com_product_link_size"
                                  href={`mailto:${email["Email"]}`}
                                  target="_blank"
                                >
                                  {/* <EmailOutlineIcon size={18} />{" "} */}
                                  {!show
                                  ? _formatEmailWithHide(email["Email"])
                                  : email["Email"]}
                                </a>
                                <span className="small-text light-text">
                                  {email["Type"] &&
                                    `(${_capitalizeText(email["Type"])})`}
                                </span>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : Array.isArray(organizationRes.email) ? (
                        <Fragment>
                          {organizationRes.email.map((email) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link com_product_link_size"
                                  href={`mailto:${!show ? _formatEmailWithHide(email) : email}`}
                                  target="_blank"
                                >
                                  {/* <EmailOutlineIcon size={18} /> */}
                                  {!show ? _formatEmailWithHide(email) : email}
                                </a>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <a
                          className="product__external-link com_product_link_size"
                          href={`mailto:${!show
                            ? _formatEmailWithHide(organizationRes.email)
                            : organizationRes.email}`}
                          target="_blank"
                        >
                          {/* <EmailOutlineIcon size={18} />  */}
                          {!show
                          ? _formatEmailWithHide(organizationRes.email)
                          : organizationRes.email}
                        </a>
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col xl={6} lg={6} md={6} className="mb-2">
                {organizationRes && organizationRes.address && (
                  <div>
                    <h5 className="com_head_weight com_head_text">
                      Business Address:
                    </h5>
                    <p className="com_pera_style">
                      {organizationRes.address.full_street_address
                        ? _capitalizeText(
                            organizationRes.address.full_street_address
                          )
                        : " "}
                      {organizationRes.address.address_2
                        ? `,  ${_capitalizeText(
                            organizationRes.address.address_2
                          )}`
                        : ""}
                      <br/>
                      {organizationRes.address.city
                        ? `${_capitalizeText(organizationRes.address.city)}`
                        : ""}
                      {organizationRes.address.state
                        ? `, ${
                            organizationRes.address.state.length > 2
                              ? _capitalizeText(organizationRes.address.state)
                              : organizationRes.address.state
                                  .toString()
                                  .toUpperCase()
                          }`
                        : ""}
                      {organizationRes.address.zip
                        ? ` ${organizationRes.address.zip}`
                        : ""}
                    </p>
                  </div>
                )}
                {organizationRes &&
                ((organizationRes.phone &&
                organizationRes.phone.length > 0)
                || (organizationRes.phone_numbers &&
                  organizationRes.phone_numbers.length > 0)) ? (
                  <div className="com_div_margin">
                    <h5 className="com_head_weight">
                      Phone{" "}
                      {organizationRes.phone.length > 1 ? "Numbers" : "Number"}:
                    </h5>
                    <p>
                      {(organizationRes.phone_numbers &&
                        organizationRes.phone_numbers.length) > 0 ? (
                        <Fragment>
                          {organizationRes.phone_numbers.map((phone) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link com_product_link_size"
                                  href={`tel:${!show ? _formatPhoneWithHide(phone["Phone"]) : phone["Phone"]}`}
                                  target="_blank"
                                >
                                  {/* <PhoneOutlineIcon size={18} />{" "} */}
                                  {_formatPhoneNumber(phone["Phone"])
                                     ? !show
                                       ? _formatPhoneWithHide(phone["Phone"])
                                       : _formatPhoneNumber(phone["Phone"])
                                     : !show
                                     ? _formatPhoneWithHide(phone["Phone"])
                                     : phone["Phone"]}
                                   {phone["Extension"] &&
                                     `x${phone["Extension"]}`}
                                </a>
                                <span className="small-text light-text">
                                  {phone["Type"] &&
                                    `(${_capitalizeText(phone["Type"])})`}
                                </span>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : Array.isArray(organizationRes.phone) ? (
                        <Fragment>
                          {organizationRes.phone.map((phone) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link com_product_link_size"
                                  href={`tel:${!show
                                     ? _formatPhoneWithHide(phone)
                                     : _formatPhoneNumber(phone)}`}
                                  target="_blank"
                                >
                                  {/* <PhoneOutlineIcon size={18} />{" "} */}
                                 {_formatPhoneNumber(phone)
                                 ? !show
                                   ? _formatPhoneWithHide(phone)
                                   : _formatPhoneNumber(phone)
                                 : !show
                                 ? _formatPhoneWithHide(phone)
                                 : phone}
                                </a>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <a
                          className="product__external-link com_product_link_size"
                          href={`tel:${!show
                            ? _formatPhoneWithHide(organizationRes.phone)
                            : _formatPhoneNumber(organizationRes.phone)}`}
                          target="_blank"
                        >
                          {/* <PhoneOutlineIcon size={18} />{" "} */}
                          {_formatPhoneNumber(organizationRes.phone)
                          ? !show
                            ? _formatPhoneWithHide(organizationRes.phone)
                            : _formatPhoneNumber(organizationRes.phone)
                          : !show
                          ? _formatPhoneWithHide(organizationRes.phone)
                          : organizationRes.phone}
                        </a>
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          {organizationRes.description && (
            <Row className="project-summary">
              <Col xl={12} lg={12} className="mb-2">
                <h5 className="section-title section-title--not-last com_head_weight">
                  Description
                </h5>
                <h5 className="section-title section-title--not-last subhead com_head_weight"></h5>
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: organizationRes.description,
                  }}
                />
              </Col>
            </Row>
          )}
          <div>
            <ViewContactButton contact={organizationRes} setShow={setShow} show={show} companyCard />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileCard;
