import React, { Fragment } from 'react';

import { Col, Container, Row } from 'reactstrap';
import MatTable from './components/MatTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import EditProject from '../../shared/components/edit/EditProject';

import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
} from '../../shared/helpers/utils';

const Companies = ( {user, match } ) => {
	  const type = match && match.params.type ? match.params.type : 'residential';

	  return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Company Search</h3>
		       {!isMobile && <h3 className="page-subhead subhead">Search Companies Associated with New Construction Projects</h3> }
		       
		       
		      </Col>
		    </Row>
		    {_hasPermission(user, 'company-search') ?
			    <Fragment>
				    <Row>
				      <Col md={12} className={'mb-3'}>
				       	<TrialAlert />        
				      </Col>
				    </Row>
				     <Row>
				    	<Col md={12} className={'mb-3'}>
							<Alert color="success" className="alert--bordered"  alertId="buildingDatabase" icon iconItem={<AlertIcon />} minimizeText={`About Company Database`} hideOnStart>
					          <p>
					          	<span className="bold-text">{`About Company Database`}</span> 
					            <div>{`The Company Database is provided 'As-Is'. The information provided herein is provisional and subject to change.`}</div>
					          </p>
					        </Alert>
						</Col>
				    </Row>
				    
				    
				      
				    <MatTable />
	
			    </Fragment>
			    :
			     <Row>
				    <EnterpriseGate
						img={`https://marketproof.com/svgs/landing/Table.svg`}
						classes={"small-image"}
					 />
				 </Row>
		    }
		  </Container>
		  )

};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(withRouter(Companies)));

