import React, { Fragment } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LoadingIcon from "mdi-react/DotsHorizontalIcon";
import HideIcon from "mdi-react/EyeOffIcon";

import EditFeed from "../../../../../shared/components/edit/EditFeed";

import NewsItemView from "./NewsItemView";

import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { CardBody, Row, Col } from "reactstrap";
import moment from "moment";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  _capitalizeText,
  _getBuildingURL,
  _truncateWords,
  _getDefaultBuildingPhoto,
  _nameToUrl,
} from "../../../../../shared/helpers/utils";

import Collapse from "../../../../../shared/components/Collapse";
function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
const TimeLineIcon = ({ type, date }) => {
  let Icon;

  switch (type) {
    case "new-york-yimby":
      Icon = <span className="">NYY</span>;
      break;
    case "the-new-york-post":
      Icon = (
        <div className="timeline__icon">
          <img
            src={`${process.env.PUBLIC_URL}/img/publisher/the-new-york-post.png`}
            alt="img"
          />
        </div>
      );
      break;
    case "file":
      Icon = <span className="lnr lnr-file-add" />;
      break;
    default:
      Icon = <span className=""></span>;
      break;
  }

  return <div className={`timeline__icon ${type}`}>{Icon}</div>;
};

const _clickNews = (e, item) => {
  const url = `${item.url}`;

  let topic = item.topic && item.topic[0] ? item.topic[0] : "";

  if (topic === "stories") {
    if (item.url) {
      window.open(item.url);
    } else if (item.data.originId) {
      window.open(item.data.originId);
    }
  } 
};

const _handleBuildingClick = (item) => {
  const buildingURL = _getBuildingURL(item, "building");
  window.open(buildingURL);
};

const _handleUnitClick = (item) => {
  const buildingURL =
    _getBuildingURL(item, "building") +
    "/" +
    _nameToUrl(item.address_2_normalized);
  //this.props.history.push(buildingURL);
  window.open(buildingURL);
};

const _showExcerpt = (item) => {
  const content = item.content
    ? item.content
        .replace(/(<([^>]+)>)/gi, " ")
        .replace(/[^\w\s\,\'\’\-\.]/gi, " ")
    : item.article
    ? item.article.replace(/(<([^>]+)>)/gi, " ")
    : null;

  if (!content) return null;

  return (
    <Collapse title={`${_truncateWords(content, isMobile ? 15 : 35)}`}>
      <p>
        {content && _truncateWords(content, 160).trim()}{" "}
        {item.content ? (
          <a
            className="timeline__link"
            href="javascript:void(0)"
            onClick={(e) => _clickNews(e, item)}
          >
            Read More
          </a>
        ) : item.external_url ? (
          <a
            className="timeline__link"
            href={`${item.external_url}`}
            target="blank"
          >
            Learn More
          </a>
        ) : null}
      </p>
    </Collapse>
  );
};

const _translateTopic = (item) => {
  let topic = item.topic[0];

  if (topic === "listings") {
    if (item.event_tags.indexOf("contract-signed") >= 0) {
      topic = "contract signed";
    } else if (item.event_tags.indexOf("just-listed") >= 0) {
      topic = "just listed";
    } else if (item.event_tags.indexOf("price-increase") >= 0) {
      topic = "price increase";
    } else if (item.event_tags.indexOf("price-decrease") >= 0) {
      topic = "price decrease";
    }

    if (item.event_tags.indexOf("rent") >= 0) {
      topic = "for rent - " + topic;
    } else {
      topic = "for sale - " + topic;
    }
  } else if (topic === "transactions") {
    if (item.event_tags.indexOf("recorded-sale") >= 0) {
      topic = "recorded sale";
    } else {
      topic = "sold - not yet recorded";
    }
  } else if (topic === "coo") {
    topic = "Certifcate of Occupancy";
  } else if (topic === "stories") {
    topic = "Article";
  } else if (topic === "offeringplans") {
    topic = "Offering Plan";
  }

  return topic;
};

TimeLineIcon.propTypes = {
  type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
  type: "",
};

const NewsItem = ({
  type,
  img,
  title,
  date,
  children,
  item,
  history,
  buildingRes,
  setChanged,
  setBuilding,
  snackOpen,
  index,
}) => {
  const topic = item.topic && item.topic[0];
  const hasPhoto =
    (item.main_photo && !topic) || (item.main_photo && topic == "stories");
  let content = item.content
    ? item.content
        .replace(/(<([^>]+)>)/gi, " ")
        .replace(/[^\w\s\,\'\’\-\.]/gi, " ")
    : item.article
    ? item.article.replace(/(<([^>]+)>)/gi, " ")
    : null;
    
  content = decodeHtml(content);
  return (
    <TimelineItem className={item.hide && "hidden"} key={index}>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          {moment(item.time).format("M/D/YYYY")}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot></TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent className="feed-content news-content-design">
        <Paper elevation={0} className="news-paper-design">
          <div className="edit-tools">
            <EditFeed
              edit
              item={item}
              buildingRes={buildingRes}
              setChanged={setChanged}
              setBuilding={setBuilding}
              snackOpen={snackOpen}
            />
          </div>
          {item.project_update_author ? (
            <div className="timeline__publisher">
              {item.project_update_author}
            </div>
          ) : (
            <div className="timeline__publisher">
              {topic == "stories" && item.company_slugs && item.company_slugs[0]
                ? _capitalizeText(item.company_slugs[0].replace(/-/g, " "))
                : "Marketproof Research"}
            </div>
          )}
          {topic == "stories" ? (
            <h4
              className="timeline__title timeline__clickable"
              onClick={(e) => _clickNews(e, item)}
            >
              {item.hide && <HideIcon size={18} color={"#BFC4D1"} />}{" "}
              {item.title}
            </h4>
          ) : (
            <Fragment>
              {
                <h4
                  className="timeline__title timeline__clickable"
                  onClick={(e) => _clickNews(e, item)}
                >
                  {item.hide && <HideIcon size={18} color={"#BFC4D1"} />}{" "}
                  {item.title}
                </h4>
              }
            </Fragment>
          )}

          <div>
            <div className="feed-item feed-text_style">
              {hasPhoto && (
                <div
                  className="feed__image"
                  style={{
                    backgroundImage: `url(${
                      item.main_photo
                    }), url(${_getDefaultBuildingPhoto(item)})`,
                  }}
                  onClick={(e) => _clickNews(e, item)}
                />
              )}

              {item.excerpt ? item.excerpt : _truncateWords(content, isMobile ? 35 : 80)}

              <NewsItemView item={item} />

              <div style={{ clear: "both" }} />
            </div>
          </div>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

NewsItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.element.isRequired,
};

NewsItem.defaultProps = {
  type: "",
  img: "",
  title: "",
  date: "",
};

export default withRouter(NewsItem);
