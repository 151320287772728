import React, { PureComponent } from 'react';
import { Progress, Table, Row } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Line, ComposedChart } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import Panel from '../../../../../shared/components/Panel';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const apiURL = process.env.API_ROOT + '/query/';


let bedTypes = [];

class ListingTrendsPrice extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    themeName: PropTypes.string.isRequired,
  };
 
  
  constructor() {
    super();
    this.state = {
      data: false,
    };
  }
   _setbedrooms = (bedrooms, lastItem) => {
	  
	  let stats = {
		  'studio': lastItem && lastItem['studio'] ? lastItem['studio'] : null,
		  '1 bed': lastItem && lastItem['1 bed'] ? lastItem['1 bed'] : null,
		  '2 bed': lastItem && lastItem['2 bed'] ? lastItem['2 bed'] : null,
		  '3 bed': lastItem && lastItem['3 bed'] ? lastItem['3 bed'] :  null,
		  '4+ bed': lastItem && lastItem['4+ bed'] ? lastItem['4+ bed'] :  null,
	  };
	  for(let i = 0; i < bedrooms.length; i++) {
	  	  const bedroom = bedrooms[i];
	  	  
		  if(bedroom.val === 0) {
			  stats['studio'] = bedroom.avg_price ? bedroom.avg_price : stats['studio'];
			  bedTypes.push('0_studio');
		  }else if(bedroom.val > 0){
			  stats[bedroom.val+' bed'] = bedroom.avg_price ? bedroom.avg_price : stats[bedroom.val+' bed'];
			   bedTypes.push(bedroom.val+' bed');
		  }/*
else if(bedroom.val >= 4){
			  stats['4+ bed'] = bedroom.avg_price ? bedroom.avg_price : stats['4+ bed'];
			  bedTypes.push('4+ bed');
		  }
*/
		  
	  }	
	  bedTypes = [...new Set(bedTypes)];  
	  return stats;
	  
  }

  
  _getListingTrend = async (buildingRes) => { 
		const addressQ = buildingRes.alternate_addresses.join('" OR "');					
		const q = `alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}")`;
		const fq = [
			"deleted:false",
			"listing_type:sale",
			"price:[100000 TO *]",
			`source_namespace:(${process.env.LISTING_NAMESPACES})`
		]
		let axiosData = {
			url: apiURL + "search-listings",
			method: "post",
			query: {
				q: q,
				sort: "solr_updated_date desc",
				wt: "json",
				fq: fq,
				rows: 1,
				"json.facet": {
	            listing_price: {
	              range: {
	                field: "active_date_start",
	                start: buildingRes.sales_start_date,
	                end: "NOW",
	                gap: "+1MONTH",
	                facet: {
	                  by_bedrooms: {
	                    type: "terms",
	                    field: "bedrooms",
	                    limit: 50,
	                    facet: {
	                      avg_price: "avg(price)"
	                    }
	                  },
	                  avg_price: "avg(price)",
	                }
	              }   
			    },
			  }
				
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				//this.setState({statsRes: res.facets, unitsRes: res.docs});
				const { buckets } = res.facets.listing_price;	
				const data = [];
				for(let i = 0; i < buckets.length; i++) {
					const bucket = buckets[i];
					const bedrooms = bucket.by_bedrooms && bucket.by_bedrooms.buckets && bucket.by_bedrooms.buckets[0] ? bucket.by_bedrooms.buckets : [];
					const bedroomStats = this._setbedrooms(bedrooms, data[i-1]);
					let item = {
						month : moment(bucket.val).format('MM/YYYY'),	
						avg_price: bucket.avg_price ? bucket.avg_price : data[i-1].avg_price,					
					}; 
					
					
					item = Object.assign(item, bedroomStats);
					data.push(item);
				}
				this.setState({ data: data });
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};

  
  componentDidMount() {	
	const { buildingRes } = this.props;  
	this._getListingTrend(buildingRes);
  }
  
  render() {
      const { t, dir, themeName, lmi } = this.props;    
      const { data } = this.state;
	  if(!data){
		  return null;
	  }
	  return (
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={'Average Listing Price'}
		  	subhead={'Monthly average price by bedroom since sales started'}
		  >
		    <div dir="ltr">
		      <ResponsiveContainer height={350} className="dashboard__area">
		        <ComposedChart data={data} margin={{ top: 0, left: -10, bottom: 20, right:20 }}>
			       
		           <XAxis dataKey="month" tickLine={false} reversed={dir === 'rtl'} dx={5} angle={45} tickSize={20} height={60} tickMargin={10} />
			       <YAxis  yAxisId="left" tickLine={false} tickLine={false}  orientation={'left'} tickFormatter={value =>  _formatPrice(value)} />
			      {/* <YAxis yAxisId="right" 
			        tickLine={false} 
			        orientation={'right'} 
			       	tickFormatter={value => ['$', new Intl.NumberFormat('en').format(value)].join('')}/> */}
			       <Tooltip formatter={value => '$'+_formatNumber(value)} />
			       <Legend />
			       <CartesianGrid vertical={false}/>
			      {/*  */}
			       {bedTypes.sort().map((key, i) => {
				       
				       return <Line yAxisId="left" dot={{ strokeWidth:2 }} type="monotone" dataKey={key.replace('0_', '')} fill="#BFC4D1" stroke={getDefaultChartColors[i]} strokeWidth={1} fillOpacity={0} />
			       })}
			       
			        

			      
			      </ComposedChart>
		      </ResponsiveContainer>
		    </div>
		  </Panel>
		);
	}
}

export default connect(state => ({
  rtl: state.rtl,
  dir: PropTypes.string.isRequired,
  theme: state.theme,
}))(withTranslation('common')(ListingTrendsPrice));
