import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../Layout/index";
import { connect } from "react-redux";
import PipelineBuildings from "../PipelineBuildings/index";
import PipelineMap from "../PipelineMap/index";
import PipelineAnalysis from "../PipelineAnalysis/index";

import Companies from "../PipelineCompanies/index";
import People from "../PipelinePeople/index";

import SingleProject from "../SingleProject/index";
import BuyersLists from "../BuyersLists/index";
//import SingleUnit from '../SingleUnit/index';
//import SavedUnits from '../BuyersLists/components/SavedUnits/index';
import Dashboard from "../Dashboard/index";
import MyContacts from "../MyContacts/index";
//import BuyerLeads from '../Dashboard/components/BuyerLeads/index';
import News from "../News/index";
//import TransactionCenter from '../TransactionCenter/index';
//import KnowledgeCenter from '../KnowledgeCenter/index';

//import DataExport from '../DataExport/index';

//import Developers from '../Developers/index';
//import Architects from '../Architects/index';
//import Lenders from '../Lenders/index';
import Acquisitions from "../Sales/index";
import Pipeline from "../Pipeline/index";
import SingleOrganization from "../SingleOrganization/index";
import SingleContact from "../SingleContact/index";
import Permits from "../Permits/index";

import SingleBuilding from "../BuildingDetail/components/SingleBuilding";

import Profile from "../Profile/index";
import Billing from "../Billing/index";
import MyReports from "../MyReports/index";
import SharePage from '../SharePage/index';

import { Security, LoginCallback } from "@okta/okta-react";
import { withOktaAuth } from "@okta/okta-react";
import { setUserProfile } from "../../redux/actions/authActions";
import buyersListActions from "../../redux/actions/buyersListActions";
import { _axiosCall, _getUserEvents } from "../../shared/helpers/apicalls";
import {
  _getUserSSO,
  _setSSOToken,
  _getSSOToken,
  _getMPUserSSO,
} from "../../shared/components/auth/sso/ssoutils";
import { _hasPermission } from "../../shared/helpers/access";
import { setLocalStorage } from "../../shared/helpers/utils";
import MyGroups from "../MyGroups";
import { _isPipelineSubscriber } from '../../shared/components/payment/plans';
const oktaToken = process.env.OKTA_TOKEN;

const onAuthRequired = (props) => {
  //alert("auth required");
  //console.log(props);
  //history.push('/login');

  return <Redirect to={{ pathname: `/login` }} />;
};

const config = {
  issuer: `https://${process.env.OKTA_DOMAIN}/oauth2/default`,
  redirectUri: window.location.origin + "/implicit/callback",
  clientId: `${process.env.OKTA_CLIENT_ID}`,
  onAuthRequired: onAuthRequired,
  pkce: true,
};

const Pages = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/project/:building_slug" component={SingleProject} />
    <Route
      exact
      path="/project/:building_slug/tab/:tab/:subtab?"
      component={SingleProject}
    />
    <Route
      exact
      path="/buildings/:borough/:neighborhood/:building_slug"
      component={SingleProject}
    />
    <Route
      exact
      path="/buildings/:borough/:neighborhood/:building_slug/tab/:tab/:subtab?"
      component={SingleProject}
    />

    <Route path="/pipeline-search" component={PipelineBuildings} />
    <Route path="/pipeline-map" component={PipelineMap} />
    <Route path="/pipeline-analysis" component={PipelineAnalysis} />
      
    <Route path="/companies" component={Companies} />
    <Route path="/people" component={People} />

    <Route path="/company/:key/tab/:tab/" component={SingleOrganization} />
    <Route path="/company/:key" component={SingleOrganization} />

    <Route path="/contact/:key/tab/:tab/" component={SingleContact} />
    <Route path="/contact/:key" component={SingleContact} />

    <Route path="/saved/:subtab?" component={BuyersLists} />
    <Route path="/my-contacts/:subtab?" component={MyContacts} />
    <Route path="/dashboard" component={Dashboard} />

    <Route path="/news/:subtab?" component={News} />

    <Route path="/my-account" component={Profile} />
    <Route path="/my-subscription" component={Billing} />
    <Route path="/my-shared" component={MyReports} />
    <Route path="/my-collections" component={MyGroups} />

    <Route path="/transfers" component={Acquisitions} />
    <Route path="/permits" component={Permits} />
    <Route exact path="/filings" component={Pipeline} />
    {/* <Route path="/filings/:key" component={Pipeline} /> */}
    <Route exact path="/building/:borough/:neighborhood/:building_slug" component={SingleBuilding} />
    <Route
      exact
      path="/building/:borough/:neighborhood/:building_slug/tab/:tab/:subtab?"
      component={SingleBuilding}
    />
    <Route exact path="/private-report/:custom_link" component={SharePage} />
    {/*
	<Route exact path="/buildings/:borough/:neighborhood/:building_slug/:unit" component={SingleUnit} />
	  <Route exact path="/buildings/:borough/:neighborhood/:building_slug/:unit/tab/:tab" component={SingleUnit} />
	<Route path="/my-exports" component={DataExport} />	
	<Route path="/transaction-center" component={TransactionCenter} />
	<Route path="/community-center" component={KnowledgeCenter} /> 
	<Route path="/my-leads" component={BuyerLeads} /> 
	<Route path="/developers" component={Developers} />
	<Route path="/architects" component={Architects} />
	<Route path="/lenders" component={Lenders} />
	<Route path="/my-units" component={SavedUnits} />
	*/}
  </Switch>
);

class wrappedRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      fetchInProgress: false,
    };
  }

  componentDidMount() {
    const { authState, authService, dispatch, user, dataSet } = this.props;
    const ssoToken = _getSSOToken();

    if (!authState.isPending && !authState.isAuthenticated && !ssoToken) {
      // When user isn't authenticated, forget any user info
      //setUserInfo(null);
      dispatch(setUserProfile(null));
    } else {
      if (!authState.isPending && !user && !ssoToken) {
        authService.getUser().then(
          (user) => {
            this._getUserOktaData(user);
            if (!dataSet) {
              this._getUserMongoData(user);
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else if (ssoToken && ssoToken.access_token && !user) {
        _getUserSSO(ssoToken, this.props, setUserProfile).then(() => {});
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { authState, authService, dispatch, dataSet, user } = this.props;
    /*
	  if (!authState.isPending && !authState.isAuthenticated) {
	      // When user isn't authenticated, forget any user info
	      //setUserInfo(null);
	      dispatch(setUserProfile(null));
	   } else {
		   

		  if(!authState.isPending && !user){
			  authService.getUser().then((user) => {
	        
		        this._getUserOktaData(user);
		        if(!dataSet){
			        this._getUserMongoData(user); 
		        }
		         
		     }, error => {
			      console.error(error);
		      });
			  
		  }
	      

	  }
*/
  }

  _getUserOktaData = (user) => {
    // console.log('_getBuildingData called', this.state)
    if (!user) return false;
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const { authState, authService, dispatch } = this.props;

    let headers = {
      Authorization: `SSWS ${oktaToken}`,
    };
    let userData = {
      url: `https://dev-397854.okta.com/api/v1/users/${user.sub}`,
      method: "get",
      headers: headers,
    };

    _axiosCall(userData)
      .then((res) => {
        console.log("_getUserData res back ,", res);
        if (res) {
          this.setState({ fetchInProgress: false });
          let oktaUser = Object.assign({}, res.profile);
          oktaUser["id"] = res.id;
          dispatch(setUserProfile(oktaUser));
        }
      })
      .catch((error) => {
        console.log("_getUserData error ", error);
        this.setState({ fetchInProgress: false });
        dispatch(setUserProfile(user));
      });
  };

  _getUserMongoData = (user) => {
    if (!user) return false;
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const { authState, authService, dispatch } = this.props;

    let axiosData = {
      url: `${process.env.API_ROOT}/buyerslist/userdata/${user.sub}`,
      method: "get",
    };

    _axiosCall(axiosData)
      .then((res) => {
        console.log("Data", res);
        if (res && res[0]) {
          this.setState({ fetchInProgress: false });
          let mongoUser = Object.assign({}, res[0]);
          _getUserEvents(mongoUser).then((results) => {
            if (results && results[0] && results[0].shown) {
              mongoUser = Object.assign({}, mongoUser, {
                contactsShown: results[0].shown,
              });
            }
            dispatch(buyersListActions.setUserData(mongoUser));
          });
        } else {
          dispatch(buyersListActions.setUserData({}));
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  render() {
    const { authService, authState, user, component, nochrome } = this.props;
    const ssoToken = _getSSOToken();

    console.log(ssoToken, user);
    if (authState.isPending || (ssoToken && !user)) return null;
    if (authState.isAuthenticated || (user && user.authType === "sso")) {
      if (user === null) return null;
     
      if (!_isPipelineSubscriber(user) || !user.subscriptionPlan) {
        return <Redirect to={{ pathname: "/learn-more" }} />;
      }

      if (nochrome && component) {
        return <div>{component}</div>;
      }
      
      if(window.location.pathname && window.location.pathname.indexOf("/private-report/")>=0){
         
         return(
           <Route path="/" component={Pages} />
        )
      }else{
        return (
          <div>
            <Layout />
            <div
              className="container__wrap"
              style={{
                userSelect: _hasPermission(user, "admin-tools")
                  ? "initial"
                  : "none",
              }}
            >
              <Route path="/" component={Pages} />
              <div className="disclaimer">
                Marketproof, Inc. &copy; {new Date().getFullYear()}
                {` `} | {` `}
                <a href="https://marketproof.com/terms-of-use" target="_blank">
                  Terms of Service
                </a>
                {` `} | {` `}
                <a href="https://marketproof.com/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (window.location.pathname && window.location.pathname != "/login") {
        setLocalStorage("mp", "redirect", window.location.pathname);
      }

      return <Redirect to={{ pathname: `/learn-more` }} />;
    }
  }
}

export default connect((state) => ({
  user: state.user,
  dataSet: state.buyersLists.dataSet,
}))(withOktaAuth(wrappedRoutes));
