import React, { useEffect, useState, Fragment } from "react";
import { _axiosCall } from "../../shared/helpers/apicalls";
import SingleProjectShare from "../SingleProject/components/Share/index";
import ShareTopBar from "./../../shared/components/ShareTopBar";
import ShareContact from "./../../shared/components/ShareContact";
import ShareFooter from "./../../shared/components/ShareFooter";
import ShareContactButton from "./components/ShareContactButton";
import BuildingDetail from "../BuildingDetail";
import { Container } from "reactstrap";
import { getLocalStorage } from "./../../shared/helpers/utils";
import CompReportShare from "./report/index";
import PDFReport from './reports/pdf';

const apiURL = process.env.API_ROOT + "/buyerslist";
const oktaToken = process.env.OKTA_TOKEN;

const SharePage = (props) => {
  const [sharePageProject, setSharePageProject] = useState(false);
  const [sharePageData, setSharePageData] = useState(false);
  const [user, setUser] = useState(false);
  const [badLink, setBadLink] = useState(false);

  useEffect(() => {
    const customLink =
      props.match && props.match.params && props.match.params.custom_link
        ? props.match.params.custom_link
        : false;
    const isPreview =
      props.location &&
      props.location.search &&
      props.location.search == "?preview=true";

    if (isPreview) {
      let previewData = getLocalStorage("share_", customLink);
      console.log(previewData);
      if (previewData && previewData.custom_link) {
        setSharePageData(previewData);
        if (previewData.project) {
          setSharePageProject(previewData.project);
        }

        getUserData(previewData);
      } else {
        setBadLink(true);
      }
    } else {
      _getSharePage(customLink);
    }
  }, []);

  const _getSharePage = async (customLink) => {
    let axiosData = {
      url: apiURL + "/sharepage/query",
      method: "post",
      query: {
        query: {
          custom_link: customLink,
          deleted: { $exists: false },
        },
      },
    };

    await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.numFound > 0) {
          setSharePageData(res.docs[0]);
          setSharePageProject(res.docs[0].project);
          getUserData(res.docs[0]);
        } else {
          setBadLink(true);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setBadLink(true);
      });
  };

  const getUserData = (sharePage) => {
    if (!(sharePage && sharePage.user_key)) {
      return false;
    }

    let headers = {
      Authorization: `SSWS ${oktaToken}`,
    };
    let userData = {
      url: `https://dev-397854.okta.com/api/v1/users/${sharePage.user_key}`,
      method: "get",
      headers: headers,
    };

    _axiosCall(userData)
      .then((res) => {
        console.log("_getUserData res back ,", res);
        if (res && res.profile) {
          setUser(res.profile);
        }
      })
      .catch((error) => {
        console.log("_getUserData error ", error);
        this.setState({
          fetchInProgress: false,
        });
      });
  };

  const _updateShareData = (sharePageProject) => {
    setSharePageProject(sharePageProject);
  };
  
  
  let isPDF = sharePageData.custom_data && sharePageData.custom_data.share_type == "pdf";
  if(["pdf"].indexOf(sharePageData.report_type)>=0){
    return (
      <div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
        <PDFReport sharePageData={sharePageData} updateShareData={_updateShareData} />
      </div>
    )
  }
  return (
    <div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
    <div className={`plan__wrapper share__wrapper`}>
      <ShareTopBar
        user={user}
        urlState={
          sharePageData && sharePageData.custom_data
            ? sharePageData.custom_data
            : {}
        }
        sharePageData={sharePageData}
      />
      <Container className="share dashboard">
        <ShareContact
          user={user}
          urlState={
            sharePageData && sharePageData.custom_data
              ? sharePageData.custom_data
              : {}
          }
          sharePageData={sharePageData}
        />
        {sharePageData && sharePageData.report_type == "single-project" ? (
          <Fragment>
            {sharePageProject && (
              <SingleProjectShare
                sharePageData={sharePageData}
                mpndBuilding={sharePageProject}
                permitFiling={false}
                setBuilding={setSharePageProject}
                user={user}
              />
            )}
          </Fragment>
        ) : sharePageData &&
          sharePageData.report_type == "existing-building" ? (
          <Fragment>
            <BuildingDetail
              sharePageData={sharePageData}
              user={user}
              trxnRes={sharePageData.building}
              isShare={true}
            />
          </Fragment>
        ) : sharePageData &&
          ["comp", "grid"].indexOf(sharePageData.report_type) >= 0 ? (
          <CompReportShare
            sharePageData={sharePageData}
            updateShareData={_updateShareData}
          />
        ) : null}
      </Container>
      {/*<ShareFooter sharePageData={sharePageData} />*/}
      <ShareContactButton sharePageData={sharePageData} />
    </div>
    </div>
  );
};

export default SharePage;
