import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFormValues } from 'redux-form';

import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import ShareIcon from 'mdi-react/AccountPlusOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ExportIcon from 'mdi-react/CloudDownloadIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import HelpIcon from 'mdi-react/HelpCircleOutlineIcon';
import ContactIcon from 'mdi-react/ForumOutlineIcon';
import LockIcon from 'mdi-react/LockOutlineIcon';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import moment from "moment";




import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_slugifyText,
	_formatNumberDecimal,
	_PickContactOrg,
	  _PickOrgName,
	  _PickOrgRole,
	_formatPhoneNumber
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT;





class ExportButton extends Component {
  constructor(props) {
    super(props);
    this.state = { 
	};
	this.exportToCsv = this.exportToCsv.bind(this);
	this.downloadFile = this.downloadFile.bind(this);
  }
  
   handleSubmit(values) {
	    
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	 
  }
  
  downloadFile = ({ data, fileName, fileType }) => {
	const blob = new Blob([data], { type: fileType });
  
	const a = document.createElement("a");
	a.download = fileName;
	a.href = window.URL.createObjectURL(blob);
	const clickEvt = new MouseEvent("click", {
	  view: window,
	  bubbles: true,
	  cancelable: true,
	});
	a.dispatchEvent(clickEvt);
	a.remove();
  };
  

  
  exportToCsv = e => {
	const { activeTab, contacts, companies } = this.props;
	e.preventDefault();
	let headers = [];
    let usersCsv = [];
	let fileName = "";
  	if(activeTab == "people"){
		headers = ["First_Name,Last_Name,Company,Email,Phone,Date_Added"];
		fileName = `Marketproof My Contacts (People)-${moment().format('MM-DD-YYYY')}.csv`;
		usersCsv = contacts.filter((contact) => contact && contact.meta && !contact.meta.deleted).map((contact, index) => {
			let first_name = contact.first_name ? _capitalizeText(contact.first_name) : "";
			let last_name = contact.last_name ? _capitalizeText(contact.last_name) : "";
			let company = _PickContactOrg(contact) && _PickContactOrg(contact).name ? _capitalizeText(_PickContactOrg(contact).name) : "";
			if(!company) {company = ""}
			let email = contact.email &&
			Array.isArray(contact.email) &&
			contact.email[0] &&
			contact.email[0].length ? contact.email[0] : "";
			
			let phone = contact.phone &&
			Array.isArray(contact.phone) &&
			contact.phone[0] &&
			contact.phone[0].length ? _formatPhoneNumber(contact.phone[0]) : "";
			let date = contact.timestamp ? moment(contact.timestamp).format("MM/DD/YYYY") : "";
		  	return `${first_name ? first_name.toString().replace(/,/g, '') : ""},${last_name ? last_name.toString().replace(/,/g, '') : ""},${company ? company.toString().replace(/,/g, '') : ""},${email ? email.toString().replace(/,/g, '') : ""},${phone ? phone.toString().replace(/,/g, '') : ""},${date ? date.toString().replace(/,/g, '') : ""}`;
		})  
	}else if(activeTab == "companies"){
		headers = ["Company,Category,Email,Phone,Date_Added"];
		fileName = `Marketproof My Contacts (Companies)-${moment().format('MM-DD-YYYY')}.csv`;
		usersCsv = companies.filter((companies) => companies && companies.meta && !companies.meta.deleted).map((contact, index) => {
			const roles = [];
			  if (
				contact &&
				contact.project_roles &&
				Object.keys(contact.project_roles).length > 0
			  ) {
				Object.keys(contact.project_roles).forEach((key) => {
				  const p = contact.project_roles[key];
				  p.map((r) => {
					r =
					  r === "applicant"
						? "permit applicant"
						: r === "filing_applicant"
						? "permit applicant"
						: r;
					if (roles.indexOf(r) < 0) {
					  roles.push(r);
					}
				  });
				});
			  }
			let name = _capitalizeText(contact.name);;
			let category = roles && roles[0] ? `${_capitalizeText(roles[0])}` : "";
			let email = contact.email &&
			Array.isArray(contact.email) &&
			contact.email[0] &&
			contact.email[0].length > 0 &&
			contact.email[0].indexOf("@") >= 0 ? contact.email[0] : contact.email &&
			  contact.email.length > 0 &&
			  contact.email.indexOf("@") >= 0 ? contact.email : "";
			let phone = contact.phone &&
			Array.isArray(contact.phone) &&
			contact.phone[0] &&
			contact.phone[0].length ? _formatPhoneNumber(contact.phone[0]) : 
				contact.phone && contact.phone.length ? contact.phone : "";
			let date = contact.timestamp ? moment(contact.timestamp).format("MM/DD/YYYY") : "";
			return `${name ? name.replace(/,/g, '') : ""},${category ? category.replace(/,/g, '') : ""},${email ? email.replace(/,/g, '') : ""},${phone ? phone.replace(/,/g, '') : ""},${date ? date.replace(/,/g, '') : ""}`;
		})  
	}
	
  
	this.downloadFile({
	  data: [...headers, ...usersCsv].join("\n"),
	  fileName: fileName,
	  fileType: "text/csv",
	});
  };
  


  render() {
    const { activeTab, contacts, companies } = this.props; 
	if(activeTab == "people" && (!contacts || contacts.length<=0)) return null;
	if(activeTab == "companies" && (!companies || companies.length<=0)) return null;
    return (
	    <Fragment>
	    	<Tooltip title={`Export My Contacts (${activeTab === "people" ? "People" : "Companies"}) to CSV`}>
				<IconButton
					color="inherit"
					onClick={this.exportToCsv}
				>	<ExportIcon />
				 </IconButton>				 
			  </Tooltip>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("export_form")(state) 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ExportButton));
