import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col, Badge } from "reactstrap";
import MatTableFilterButton from "./MatTableFilterButton";
import MatAnalysisView from "./MatAnalysisView";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { Button as ReactButton } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";

import MatAppBar from "../../../shared/components/building_filters/UnitsSearchAppBar";
import LinearLoading from "../../../shared/components/LinearLoading";
import EditProject from "../../../shared/components/edit/EditProject";
import MergeProjectsButton from "../../../shared/components/buttons/MergeProjectsButton";

import { isMobile, isTablet } from "react-device-detect";

import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import {
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	GenerateBuildingSlug,
	GenerateBuildingKey,
	_tranformTagsMongoQuery,
	_fixGoogleImage,
} from "../../../shared/helpers/utils";

import {
	_buildingAdminMatchFilters,
	_buildingStageFilter,
} from "../../../shared/helpers/filters";

const buildingUrl = process.env.API_ROOT + "/pipeline/";

const subFilterKey = "subFilters";
const buildingStagesKey = "buildingStages";
const defaultSubFilter = {};
const defaultStage = ["all"];

const getSubFilters = ({ stage, match }) => {
	const type = match.params.type ? match.params.type : "residential";

	let filters = _buildingAdminMatchFilters.filter(
		(filter) =>
			!(filter.exclude_stage && filter.exclude_stage.indexOf(stage) >= 0)
	);

	if (type === "commercial") {
		filters = filters.filter(
			(filter) => !(filter.type && filter.type === "residential")
		);
	} else {
		filters = filters.filter(
			(filter) => !(filter.type && filter.type === "commercial")
		);
	}
	return filters;
};

class MatTable extends PureComponent {
	lsPre = `PIPE_DB_`;
	buildingSubFilters = getSubFilters(this.props);

	state = {

		selected: new Map([]),
		page: 0,
		rowsPerPage: this.props.isSingle ? 8 : 40,
		buildingsFetchInProgress: false,
		clearInput: false,
		end: 30,
		filterBorough: "all_boroughs",
		// filterFetchInProgress: false,
		filterStage: "all_projects",
		futureInventoryData: false,
		// headerHeight: 'auto',
		inventoryData: false,
		originalData: false,
		ovelayMinHeight: false,
		reactTableFiltered: [],
		// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
		showCounts: { desktop: 3, tablet: 2, mobile: 1 },
		start: 0,
		stats: {},
		numeric: false,
		subFilters: this.props.isSingle
			? {}
			: syncURLFilters(
				this.props,
				this.buildingSubFilters,
				subFilterKey,
				this.lsPre,
				defaultSubFilter
			),
		tableData: false,
		totalUnsoldUnits: false,
		searchText: syncURLSearch(this.props, "searchText"),
		viewType: syncGenericFilter("viewType", "viewType", this.lsPre, "grid"),
		facetData: null,
		buildingStages: this.props.isSingle
			? "all"
			: syncStageFilters(
				this.props,
				_buildingStageFilter,
				buildingStagesKey,
				this.lsPre,
				defaultStage
			),
		type:
			this.props.match && this.props.match.params.type
				? this.props.match.params.type
				: "residential",
		selectedItems: {},
		analysisType: "count",
		searchFilters: {}
	};

	q = "";
	fq = "";
	sort = "";

	componentDidMount() {
		//this._getInventoryStats();
		this._getPipelineBuildings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let searchTagsChange = !_isEqual(
			prevProps.searchTags,
			this.props.searchTags
		);

		let buildingStageChange = !_isEqual(
			prevState.buildingStages,
			this.state.buildingStages
		);
		let subFilterChanged =
			this.buildingSubFilters.filter((item) => {
				return (
					this.state.subFilters[item.search_key] !==
					prevState.subFilters[item.search_key]
				);
			}).length > 0;
		let multiSubFilterChanged =
			this.buildingSubFilters.filter((item) => {
				return (
					this.state.subFilters[item.search_key && item.search_key[0]] !==
					prevState.subFilters[item.search_key && item.search_key[0]]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.analysisType !== prevState.analysisType ||
			searchTagsChange ||
			subFilterChanged ||
			buildingStageChange ||
			multiSubFilterChanged ||
			this.state.numeric !== prevState.numeric
			// ||
			// Object.keys( this.state.subFilters ).length
		) {
			const resetPage = this.state.page === prevState.page;
			console.log("component update", prevState, this.state);
			this._getPipelineBuildings();
		}
	}



	handleClick = (event, id) => {
		event.stopPropagation();
		const { selected } = this.state;
		const newSelected = new Map(selected);
		const value = newSelected.get(id);
		let isActive = true;
		if (value) {
			isActive = false;
		}
		newSelected.set(id, isActive);
		this.setState({ selected: newSelected });
	};

	shouldLinkToMP = (building) => {
		const { user } = this.props;
		return (
			(user.role &&
				Array.isArray(user.role) &&
				user.role.indexOf("admin") >= 0) ||
			[
				"active",
				"coming soon",
				"offering plan required",
				"offering plan acquired",
				"condo declaration vetted",
			].indexOf(building.offering_verified_status) < 0
		);
	};

	handleBuildingClick = (event, building) => {
		this.handleBuildingPreviewClick(event, building);

		/*
				 if(this.shouldLinkToMP(building)){
					 const buildingURL = _getBuildingURL(building, 'building_slugs');	 
					 window.open(`https://marketproof.com${buildingURL.replace('buildings', 'building')}?tab=overview`)
				 }else{
					 this.handleBuildingPreviewClick(event, building);
			
				 }
			*/

		//this.props.history.push(buildingURL);
	};

	handleBuildingPreviewClick = (event, building) => {
		event.preventDefault();
		event.stopPropagation();
		const buildingURL = _getBuildingURL(building, "_id");
		this.props.history.push(buildingURL);
		//window.open(buildingURL);
	};

	setAnalysisType = (type) => {
		this.setState({ analysisType: type });
	}

	_addFilterQuery = (min, max, query_key, searchFilters) => {
		let qVal = {};
		if (min && max) {
			qVal = {
				[query_key]: { $gte: min, $lte: max },
			};
		} else if (min && !max) {
			qVal = {
				[query_key]: { $gte: min },
			};
		} else if (!min && max) {
			qVal = {
				[query_key]: { $lte: max },
			};
		}
		if (Object.keys(qVal).length > 0) searchFilters.push(qVal);
		return;
	};

	_getSpecificFilter = (key) => {
		let filter =
			this &&
			this.buildingSubFilters &&
			this.buildingSubFilters.filter(
				(filter) =>
					filter.search_key === key ||
					(filter.search_key && filter.search_key.includes(key))
			)[0];
		return filter;
	};

	_getPipelineBuildings = async (query, resetPage, obj) => {
		const { user, searchTags, organizationRes, contactRes, isSingle } =
			this.props;
		const { buildingStages, type, analysisType } =
			this.state;
		if (!this.state.buildingsFetchInProgress) {
			this.setState((prevState) => ({ buildingsFetchInProgress: true }));
		}

		let searchFilters = [
			{ "meta.deleted": { $exists: false } },
			{
				$or: [
					{ source_provider: { $ne: "mpnd" } },
					{ "$and": [{ source_provider: "permits" }, { building_status: { "$ne": "resale" } }] },
					{ building_status: "sponsor" },
					{ building_status: "pipeline" },
				],
			},
			{ $or: [{ pipeline_occupancy_classification_proposed_code: { $nin: ["u"] } }, { pipeline_total_construction_floor_area: { $gt: 1000 } }] }
		];

		let baseQuery = false;
		if (organizationRes && organizationRes.projects) {
			baseQuery = {
				key: { $in: organizationRes.projects.filter(p => p && p.key).map((p) => p && p.key) },
			};
		} else if (contactRes && contactRes.projects) {
			baseQuery = { key: { $in: contactRes.projects.map((p) => p && p.key) } };
		}
		if (baseQuery) {
			searchFilters.push(baseQuery);
		}
		let locQuery = _tranformTagsMongoQuery(searchTags);

		if (locQuery) {
			searchFilters.push(locQuery);
		}

		if (searchTags.filter((tag) => tag.type == "text").length > 0) {
			searchTags
				.filter((tag) => tag.type == "text")
				.map((tag) => {
					let textQuery = {
						$and: tag.data.split(" ").map((token) => {
							return {
								$or: [
									{
										display_full_street_address: {
											$regex: `${token}`,
											$options: "i",
										},
									},
									{ name: { $regex: `${token}`, $options: "i" } },
								],
							};
						}),
					};
					searchFilters.push(textQuery);
				});
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map((key) => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const filter =
						this &&
						this.buildingSubFilters &&
						this.buildingSubFilters.filter(
							(filter) =>
								filter.search_key === key ||
								(filter.search_key && filter.search_key.includes(key))
						)[0];
					let result = [];
					let qVal = {};
					let search_query = [];

					if (filter && !filter.format_type) {
						const qVal1 = filter.values.filter(
							// (value) => value.filter === subFilterVal
							(value) =>
								subFilterVal.indexOf(value.filter) === -1 ? false : true
						);

						qVal1.forEach((data) => {
							if (key === "pipeline_building_stories_proposed") {
								if (data.search_value) {
									search_query.push({ [key]: data.search_value });
								}
							}
							if (data && data.search_value && data.search_value[key])
								result.push(...data.search_value[key]["$in"]);
							if (
								data &&
								data.search_value &&
								(data.search_value.$or || data.search_value.$and)
							) {
								searchFilters.push(data.search_value);
							}
						});
					}
					if (search_query.length) {
						qVal = { $or: search_query };
					} else if (result.length) {
						qVal = { [key]: { $in: result } };
					}
					if (filter.value_contains_key) {
						if (Object.keys(qVal).length > 0) searchFilters.push(qVal);
					} else {
						const addQuery = {};
						addQuery[key] = qVal;
						searchFilters.push(addQuery);
					}
				}
			});
		}

		// for numeric & price filter queries
		let qVal = {};
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			let query_key,
				queryCount = {};
			Object.keys(this.state.subFilters).map((key) => {
				let subFilterVal = this.state.subFilters[key];

				const filter = this._getSpecificFilter(key);
				query_key = filter && filter.filter_key;

				if (filter && filter.format_type && !filter.date_filter) {
					queryCount[key] = parseInt(subFilterVal);
					if (query_key in queryCount) {
						queryCount[query_key] += 1;
					} else {
						queryCount[query_key] = 1;
					}

					if (queryCount[query_key] === 2) {
						this._addFilterQuery(
							queryCount[filter.search_key[0]], // add minimum value
							queryCount[[filter.search_key[1]]], // add maximum value
							query_key,
							searchFilters
						);
					}
				}
			});
		}

		// for date filter
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			let query_key,
				queryCount = {};
			Object.keys(this.state.subFilters).map((key) => {
				let subFilterVal = this.state.subFilters[key];

				const filter = this._getSpecificFilter(key);
				query_key = filter && filter.filter_key;

				if (filter && filter.date_filter) {
					queryCount[key] = subFilterVal;
					if (query_key in queryCount) {
						queryCount[query_key] += 1;
					} else {
						queryCount[query_key] = 1;
					}

					if (queryCount[query_key] === 2) {
						if (queryCount.min_date || queryCount.max_date) {
							this._addFilterQuery(
								queryCount.min_date && queryCount.min_date !== "0"
									? 'ISODate:' + moment(queryCount.min_date).toDate().toISOString()
									: false,
								queryCount.max_date && queryCount.max_date !== "0"
									? 'ISODate:' + moment(queryCount.max_date).toDate().toISOString()
									: false,
								query_key,
								searchFilters
							);
						}
					}
				}
			});
		}

		const buildingStageFilters = {
			"pre-development": { pipeline_project_stage: "planning" },
			construction: { pipeline_project_stage: "construction" },
			operation: { pipeline_project_stage: "completion" },
		};

		if (buildingStages && buildingStages.length > 0) {
			let stageQ = [];
			if (buildingStages.indexOf("all") < 0) {
				buildingStages.map((stage) => {
					stageQ.push(buildingStageFilters[stage]);
				});

				if (stageQ.length > 1) {
					searchFilters.push({ $or: stageQ });
				} else if (stageQ.length === 1) {
					searchFilters.push(stageQ[0]);
				}
			}
		}
		let sort = {};

		sort[analysisType] = -1;
		if (!isSingle) {
			setURLFromState(this.props, this.state);
		}
		let atlasQuery = [
			{ $match: { $and: searchFilters } },
			{
				"$project": {
					"_id": 0,
					"key": 1,
					"organizations": 1,
					"pipeline_occupancy_type": 1,
					"pipeline_project_stage": 1,
					"pipeline_dwelling_units_proposed": 1,
					"pipeline_dwelling_units_existing": 1,
					"pipeline_total_construction_floor_area": 1,
					"pipeline_project_components": 1,
					"address": 1,
					"occupancy_type": 1,
					"pipeline_occupancy_classification_proposed_code": 1
				}
			}, {
				"$facet": {
					"resi_project_count": [{
						"$match": {
							"pipeline_occupancy_type": {
								"$in": ["residential"]
							}
						}
					},
					{
						"$group": {
							"_id": null,
							"count": {
								"$sum": 1
							},
							"units": {
								"$sum": "$pipeline_dwelling_units_proposed"
							},
							"units_existing": {
								"$sum": "$pipeline_dwelling_units_existing"
							},
							"sq_ft": {
								"$sum": "$pipeline_total_construction_floor_area"
							}
						}
					},
					{
						"$project": {
							"_id": 0
						}
					}
					],
					"comm_project_count": [{
						"$match": {
							"pipeline_occupancy_type": {
								"$in": ["commercial"]
							}
						}
					},
					{
						"$group": {
							"_id": null,
							"count": {
								"$sum": 1
							},
							"units": {
								"$sum": "$pipeline_dwelling_units_proposed"
							},

							"sq_ft": {
								"$sum": "$pipeline_total_construction_floor_area"
							}
						}
					},
					{
						"$project": {
							"_id": 0
						}
					}
					],
					"mixed_project_count": [{
						"$match": {
							"pipeline_occupancy_type": {
								"$in": ["mixed-use"]
							}
						}
					},
					{
						"$group": {
							"_id": null,
							"count": {
								"$sum": 1
							},
							"units": {
								"$sum": "$pipeline_dwelling_units_proposed"
							},
							"units_existing": {
								"$sum": "$pipeline_dwelling_units_existing"
							},
							"sq_ft": {
								"$sum": "$pipeline_total_construction_floor_area"
							}
						}
					},
					{
						"$project": {
							"_id": 0
						}
					}
					],
					"by_stage": [
						{
							"$group": {
								"_id": "$pipeline_project_stage",
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}
					],
					"resi_by_stage": [
						{
							"$match": {
								"pipeline_occupancy_type": {
									"$in": ["residential"]
								}
							}
						},
						{
							"$group": {
								"_id": "$pipeline_project_stage",
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}
					],
					"by_borough": [
						{
							"$group": {
								"_id": "$address.borough",
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"resi_by_borough": [
						{
							"$match": {
								"pipeline_occupancy_type": {
									"$in": ["residential"]
								}
							}
						},
						{
							"$group": {
								"_id": "$address.borough",
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"by_neighborhood": [
						{
							"$group": {
								"_id": { "neighborhood": "$address.neighborhood", "borough": "$address.borough" },
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"resi_by_neighborhood": [
						{
							"$match": {
								"pipeline_occupancy_type": {
									"$in": ["residential"]
								}
							}
						},
						{
							"$group": {
								"_id": { "neighborhood": "$address.neighborhood", "borough": "$address.borough" },
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"condo_coop": [
						{
							"$match": {
								pipeline_project_components: { $in: ["condo", "condominium", "coop", "co-op", "cooperative"] }
							}
						},
						{
							"$group": {
								"_id": null,
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"rental": [
						{
							"$match": {
								$or: [
									{ pipeline_project_components: { $in: ["rental"] } },
									{
										$and: [
											{ pipeline_dwelling_units_proposed: { $gte: 6 } },
											{ pipeline_occupancy_type: { $nin: ["commercial"] } },
											{
												pipeline_project_components: {
													$nin: ["coop", "co-op", "cooperative", "condo", "condominium", "condop"],
												}
											}]
									}
								]
							}
						},
						{
							"$group": {
								"_id": null,
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"multi_family": [
						{
							"$match": {
								$and: [
									{ pipeline_occupancy_type: { $in: ["residential"] } },
									{
										pipeline_project_components: {
											$nin: ["condominium", "cooperative", "condo", "coop", "co-op"],
										},
									},
									{
										$or: [
											{ pipeline_project_components: { $in: ["multi-family"] } },
											{ pipeline_dwelling_units_proposed: { $gte: 2, $lte: 5 } },
										],
									},
								],
							}
						},
						{
							"$group": {
								"_id": null,
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"single_family": [
						{
							"$match": {
								$or: [
									{ pipeline_project_components: { $in: ["single family"] } },
									{ $and: [{ pipeline_dwelling_units_proposed: 1 }, { pipeline_occupancy_type: { $nin: ["commercial"] } }] }
								],
							}
						},
						{
							"$group": {
								"_id": null,
								"count": {
									"$sum": 1
								},
								"units": {
									"$sum": "$pipeline_dwelling_units_proposed"
								},
								"units_existing": {
									"$sum": "$pipeline_dwelling_units_existing"
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					],
					"office_retail": [
						{
							"$match": {
								"$or": [{
									"$or": [{
										"pipeline_project_components": {
											"$in": ["retail"]
										}
									}, {
										"$and": [{
											"pipeline_occupancy_classification_proposed_code": {
												"$in": ["a2", "m"]
											}
										}, {
											"occupancy_type.Project Components": {
												"$exists": false
											}
										}]
									}]
								}, {
									"$or": [{
										"pipeline_project_components": {
											"$in": ["office"]
										}
									}, {
										"$and": [{
											"pipeline_occupancy_classification_proposed_code": {
												"$in": ["b"]
											}
										}, {
											"occupancy_type.Project Components": {
												"$exists": false
											}
										}]
									}]
								}],
							}
						},
						{
							"$group": {
								"_id": null,
								"count": {
									"$sum": 1
								},
								"sq_ft": {
									"$sum": "$pipeline_total_construction_floor_area"
								}
							}
						}, {
							"$sort": sort
						}, {
							"$limit": 20
						}
					]
				}
			}
		];
		let axiosData = {
			url: buildingUrl + "aggregate-search",
			method: "post",
			query: atlasQuery,
		};


		_axiosCall(axiosData)
			.then((res) => {
				if (res && res[0]) {
					this.setState({ facetData: res[0], buildingsFetchInProgress: false, searchFilters: searchFilters })
				} else {
					this.setState({ facetData: false, buildingsFetchInProgress: false, searchFilters: searchFilters })
				}

			})
			.catch((error) => {
				console.log("error: " + error);
				this.setState({ facetData: false, buildingsFetchInProgress: false, searchFilters: searchFilters })
			});
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({ searchText: eValue });
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	__setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			numeric: true,
			end: this.state.rowPerPage,
		});

		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	};

	_filterResponseTableData = (eValue) => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter((item) => {
					// console.log("filterData item", item)
					const inputVal = eValue.toLowerCase();
					let nameVal, adrVal, hoodVal;
					if (item.name) {
						// console.log('item has name')
						nameVal = item.name.toLowerCase();
					}

					if (
						(item.display_full_street_address &&
							_shouldShow(item.display_full_street_address)) ||
						(item.full_street_address &&
							_shouldShow(item.full_street_address))
					) {
						const field = item.display_full_street_address
							? "display_full_street_address"
							: "full_street_address";
						// console.log('item has adr', field)

						adrVal = item[field].toLowerCase();
					}

					if (item.neighborhood && _shouldShow(item.neighborhood)) {
						const hood =
							Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
								? item.neighborhood[0]
								: item.neighborhood;
						hoodVal = hood.toLowerCase();
					}

					// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
					// searching name and ONE adr field - could update to search both display and full and all alt adrs
					return (
						(nameVal && nameVal.indexOf(inputVal) >= 0) ||
						(adrVal && adrVal.indexOf(inputVal) >= 0) ||
						(hoodVal && hoodVal.indexOf(inputVal) >= 0)
					);
				})
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState((prevState) => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
			//tableData: filteredData,
			//originalData: response,
			//filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value,
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			(item) => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter],
		});
	};

	_setSubFilter = (search_key, search_val) => {
		const subF = Object.assign({}, this.state.subFilters, {
			[search_key]: search_val,
		});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage,
		});

		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	};

	_setBuildingStage = (stage) => {
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);
		const indexAll = newStages.indexOf("all");

		if (index >= 0) {
			if (stage === "all") {
				newStages = ["sponsor"];
			} else {
				newStages.splice(index, 1);
			}
		} else {
			if (stage === "all") {
				newStages = ["all"];
			} else {
				if (indexAll >= 0) {
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage);
			}
		}
		if (newStages.length === 0) newStages = ["all"];
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage,
		});
		setLocalStorage(this.lsPre, buildingStagesKey, JSON.stringify(newStages));
	};

	_resetSubFilter = (search_key, search_val) => {
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages: defaultStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage,
		});
		deleteLocalStorage(this.lsPre, buildingStagesKey);
		deleteLocalStorage(this.lsPre, subFilterKey);
	};

	_resetActive = () => {
		let isDefault =
			this.props.searchTags.length === 0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultStage, this.state.buildingStages);
		return !isDefault;
	};

	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage,
		});
	};

	_toggleView = (type) => {
		this.setState({ viewType: type });
		setLocalStorage(this.lsPre, "viewType", type);
	};

	_selectUnit(property) {
		const { selectedItems } = this.state;
		const newSelected = Object.assign({}, selectedItems);

		if (newSelected[property.key]) {
			delete newSelected[property.key];
		} else {
			newSelected[property.key] = property;
		}

		this.setState({ selectedItems: Object.assign({}, newSelected) });
	}
	_clearSelect() {
		this.setState({ selectedItems: {} });
	}

	render() {
		const {
			data,
			order,
			orderBy,
			selected,
			rowsPerPage,
			page,
			facetData,
			reactTableFiltered,
			subFilters,
			totalCount,
			viewType,
			buildingsFetchInProgress,
			type,
			selectedItems,
		} = this.state;
		const { user, isSingle, min, toggle } = this.props;

		const tableCount = totalCount;



		return (
			<Fragment>
				<MergeProjectsButton
					selectedItems={selectedItems}
					clearSelected={this._clearSelect.bind(this)}
					type={"projects"}
				/>

				<Row>
					<Col md={12} lg={12}>
						<Card>
							<CardBody className="p-0">
								{/*<div className="card__title">
		              <h5 className="bold-text">Select and Analyze</h5>
		            </div>*/}
								<MatAppBar
									handleSearch={this._filterTableByInput}
									numSelected={[...selected].filter((el) => el[1]).length}
									handleDeleteSelected={this.handleDeleteSelected}
									onRequestSort={this.handleRequestSort}
									subFilters={subFilters}
									resetSubFilter={this._resetSubFilter}
									buildingStages={this.state.buildingStages}
									setBuildingStage={this._setBuildingStage}
									setSubFilter={this._setSubFilter}
									_filters={
										user && user.role.indexOf("admin") >= 0
											? this.buildingSubFilters
											: this.buildingSubFilters.filter(
												(item) => !item.admin_only
											)
									}
									placeHolder={"Project, Address, Neighborhood or Borough"}
									buildingAttributes={[]}
									goToBuilding={true}
									hideBuilding={true}
									resetActive={this._resetActive()}
									type={type}
									q={this.q}
									fq={this.fq}
									fl={buildingExportFL}
									sort={this.sort}
									totalCount={totalCount}
									hideOrganizations
									hidePeople
									addBuilding={true}
									allowTextSearch
									setSubFilters={this.__setSubFilters}
								/>
								<div>
									{buildingsFetchInProgress ? (
										<LinearLoading />
									) : (
										<div style={{ height: isMobile ? 0 : 20 }} />
									)}
								</div>

								<MatAnalysisView {...this.state} {...this.props} facetData={facetData} setAnalysisType={this.setAnalysisType} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}
export default connect((state) => ({
	user: state.user,
	searchTags: Array.isArray(state.search.searchTags)
		? state.search.searchTags.slice()
		: [],
}))(withRouter(MatTable));
