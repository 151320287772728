import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import StairsIcon from 'mdi-react/StairsIcon';

const TotalFloors = (props) => (
	  <Col md={12} xl={2} lg={6} xs={6}>
	    <Card>
	    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
	      <CardBody className="dashboard__booking-card card__link">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            {props.buildingRes.total_floors > 0 ? props.buildingRes.total_floors : '--'}
	          </h5>
	          <StairsIcon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Floors</h5>	        
	      </CardBody>
	      </Link>
	    </Card>
	    
	  </Col>
);

export default TotalFloors;
