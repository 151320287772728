import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ShareIcon from "mdi-react/AccountPlusOutlineIcon";

import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles,
} from "@material-ui/core";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  buildingFL,
  propertyFL,
  _privateKey,
  BuildingPhoto,
  BuildingStageBadge,
  BuildingCurrentStatus,
  _replaceAbbreviations,
  SalesProgress,
  _getUnitFloorPlan,
  UnitCurrentStatus,
  _formatNameAddress,
  _getProjectType
} from "../../../../../shared/helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
	width: "100%",
	marginTop: theme.spacing(3),
  },
  head: {
	backgroundColor: "#fff",
	minWidth: "50px",
  },
  tableContainer: {
	maxHeight: "none",
  },
  cell: {
	minWidth: "160px",
	maxWidth: "160px",
  },
  freezCell: {
	minWidth: "100px",
	fontWeight: 500,
	textAlign: "right",
	padding: "5px 0",
	float: "right",
  },
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
	backgroundColor: theme.palette.common.black,
	color: theme.palette.common.white,
	left: 0,
	position: "sticky",
	zIndex: 99,
  },
  body: {
	backgroundColor: "#ddd",
	minWidth: "100px",
	left: 0,
	position: "sticky",
	zIndex: 98,
	fontWeight: 500,
	width: "150px",
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
	backgroundColor: theme.palette.common.black,
	color: theme.palette.common.white,
  },
  body: {
	fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
	"&:nth-of-type(odd)": {
	  backgroundColor: theme.palette.action.hover,
	},
  },
}))(TableRow);

export const FreezeTableBuildings = ({ buildingRes, toggleModal }) => {
  const classes = useStyles();

  const hashMap = {};
  buildingRes.map((bldg) => {
	hashMap[bldg.key] = bldg;
  });

  const compData = Object.assign(
	...Array.from(
	  new Set(buildingRes.reduce((keys, o) => keys.concat(Object.keys(o)), [])),
	  (key) => ({ [key]: buildingRes.map((o) => o[key]) })
	)
  );
  console.log(compData);
  return (
	<div className="comp-report">
	  <TableContainer className={classes.tableContainer}>
		<Table stickyHeader>
		  <TableBody>
			<StyledTableRow key={"photo"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				></StyledTableCell>
			  </StickyTableCell>
			  {compData.key &&
				compData.key.map((key) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					  onClick={() => toggleModal("building", hashMap[key])}
					>
					  <div className="building-photo">
						<BuildingPhoto item={hashMap[key]} />
						
						{/* <BuildingCurrentStatus item={hashMap[key]} /> */}
					  </div>
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>

			<StyledTableRow key={"unit"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>Stage</StyledTableCell>
			  </StickyTableCell>
			  {compData.key &&
				compData.key.map((key) => {
				  const item = hashMap[key];
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  <StatusBadge building={hashMap[key]} />
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"name"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Name
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.key &&
				compData.key.map((key) => {
				  const item = hashMap[key];
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  <span className="bold-text">
						{item.name
						  ? _capitalizeText(item.name)
						  : _capitalizeText(item.display_full_street_address)}
					  </span>
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>

			<StyledTableRow key={"address"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Address
				</StyledTableCell>
			  </StickyTableCell>
			  {compData &&
				compData.display_full_street_address &&
				compData.display_full_street_address.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {_capitalizeText(val)}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"neighborhood"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Neighborhood
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.address &&
				compData.address.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {Array.isArray(val.neighborhood)
						? _capitalizeText(val.neighborhood[0])
						: _capitalizeText(val.neighborhood)}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"neighborhood"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Borough
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.address &&
				compData.address.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {_capitalizeText(val.borough)}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow> 
			<StyledTableRow key={"type"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Construction Type
				</StyledTableCell>
			  </StickyTableCell>
			 {compData.key &&
			 compData.key.map((key) => {
			   const item = hashMap[key];
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {item.pipeline_job_type == "a1" ? "Alteration" : "New Construction"}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow> 
			<StyledTableRow key={"type"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Project Type
				</StyledTableCell>
			  </StickyTableCell>
			 {compData.key &&
			 compData.key.map((key) => {
			   const item = hashMap[key];
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {_capitalizeText(_getProjectType(item))} 
					</StyledTableCell>
				  );
				})}
			</StyledTableRow> 
			<StyledTableRow key={"sales_start"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Floors
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.pipeline_building_stories_proposed &&
				compData.pipeline_building_stories_proposed.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {_formatNumber(val)}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"sales_start"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Building Height
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.key &&
			   compData.key.map((key) => {
				 const item = hashMap[key];
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {_formatNumber(item.pipeline_height_proposed)} FT
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"developer"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Units
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.pipeline_dwelling_units_proposed &&
				compData.pipeline_dwelling_units_proposed.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {_formatNumber(val)}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"architect"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Residential Area
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.pipeline_zoning_area_residential &&
				compData.pipeline_zoning_area_residential.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {val > 0 ? _formatNumber(val) + " Resi SF" : "-"}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"interior"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Total Area
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.pipeline_total_construction_floor_area &&
				compData.pipeline_total_construction_floor_area.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {val > 0 ? _formatNumber(val) + " Total SF" : "-"}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
			<StyledTableRow key={"inventory"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Last Updated
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.meta &&
				compData.meta.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {moment(val.ModificationTimestamp).year() > 1970
						? 
							moment(val.ModificationTimestamp).format("M/YYYY")
						  
						: "--"}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>

			<StyledTableRow key={"pct_sold"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Permit Filled
				</StyledTableCell>
			  </StickyTableCell>
			  {compData.pipeline_filing_date &&
				compData.pipeline_filing_date.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {val && moment(val).year() > 1970
						? moment(val).format("M/YYYY")
						: "--"}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>
		{/*	<StyledTableRow key={"inventory"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Permit Issued
				</StyledTableCell>
			  </StickyTableCell>
			  {compData &&
				compData.pipeline_permit_issuance_job_start_date &&
				compData.pipeline_permit_issuance_job_start_date.map((val) => {
				  return (
					<StyledTableCell
					  numeric
					  align="center"
					  className={classes.cell}
					>
					  {val && moment(val).year() > 1970
						? _capitalizeText(moment(val).fromNow())
						: "--"}
					</StyledTableCell>
				  );
				})}
			</StyledTableRow>*/}
			
		  </TableBody>
		</Table>
	  </TableContainer>
	</div>
  );
};

