import React, { useState, Fragment } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import {
  _capitalizeText,
  _formatEmailWithHide,
  _formatPhoneNumber,
  _formatPhoneWithHide,
  _formatUnitNumber,
  _PickOrgRole,
} from "../../../shared/helpers/utils";
import WebIcon from "mdi-react/WebIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import ViewContactButton from "../../../shared/components/buttons/ViewContactButton";

const PersonPageCard = (props) => {
  const { contactRes, organizationRes } = props;
  const [show, setShow] = useState(false);

  return (
    <Col md={12} lg={12}>
      <Card className="com_card_style">
        <CardBody className="no-border no-padding">
          <div>
            <Row>
              <Col xl={6} lg={6} md={6} className="mb-2">
                {contactRes && contactRes.website ? (
                  <div>
                    <h5 className="com_head_weight">Website:</h5>
                    <p>
                      <a
                        className="product__external-link com_product_link_size"
                        href={`${contactRes.website}`}
                        target="_blank"
                      >
                        {contactRes.website}
                        <OpenInNewIcon
                          size={14}
                          style={{ marginLeft: 0, marginTop: -2 }}
                        />
                      </a>
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {contactRes &&
                contactRes.categories &&
                contactRes.categories.length > 0 ? (
                  <div
                    className={contactRes.website ? "contact_dev_margin" : ""}
                  >
                    <h5 className="com_head_weight">Business Categories:</h5>

                    <p className="com_pera_style">
                      {contactRes.categories.map((u) => _capitalizeText(u))}
                    </p>
                  </div>
                ) : null}

                {contactRes && contactRes.email ? (
                  <div
                    className={
                      contactRes.website ||
                      (contactRes.categories &&
                        contactRes.categories.length > 0)
                        ? "contact_dev_margin"
                        : ""
                    }
                  >
                    <h5 className="com_head_weight">
                      Email{" "}
                      {contactRes.email.length > 1 ? "Addresses" : "Address"}:
                    </h5>
                    <p>
                      {(contactRes.email_addresses &&
                        contactRes.email_addresses.length) > 0 ? (
                        <Fragment>
                          {contactRes.email_addresses.map((email) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link com_product_link_size"
                                  href={`mailto:${email["Email"]}`}
                                  target="_blank"
                                >
                                  {/* <EmailOutlineIcon size={18} />{" "} */}
                                  {!show
                                    ? _formatEmailWithHide(email["Email"])
                                    : email["Email"]}
                                </a>
                                <span className="small-text light-text">
                                  {email["Type"] &&
                                    `(${_capitalizeText(email["Type"])})`}
                                </span>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : Array.isArray(contactRes.email) ? (
                        <Fragment>
                          {contactRes.email.map((email) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link com_product_link_size"
                                  href={`mailto:${email}`}
                                  target="_blank"
                                >
                                  {/* <EmailOutlineIcon size={18} /> */}
                                  {!show ? _formatEmailWithHide(email) : email}
                                </a>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <a
                          className="product__external-link com_product_link_size"
                          href={`mailto:${contactRes.email}`}
                          target="_blank"
                        >
                          {/* <EmailOutlineIcon size={18} />  */}
                          {!show
                            ? _formatEmailWithHide(contactRes.email)
                            : contactRes.email}
                        </a>
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col xl={6} lg={6} md={6} className="mb-2">
                {contactRes && contactRes.address && (
                  <div>
                    <h5 className="com_head_weight com_head_text">
                      Business Address:
                    </h5>
                    <p className="com_pera_style">
                      {contactRes.address.full_street_address
                        ? _capitalizeText(
                            contactRes.address.full_street_address
                          )
                        : ""}
                      {contactRes.address.address_2
                        ? `,  ${_capitalizeText(
                            contactRes.address.address_2
                          )}`
                        : ""}
                        <br/>
                      {contactRes.address.city
                        ? `${_capitalizeText(contactRes.address.city)}`
                        : ""}
                      {contactRes.address.state
                        ? `, ${
                            contactRes.address.state.length > 2
                              ? _capitalizeText(contactRes.address.state)
                              : contactRes.address.state
                                  .toString()
                                  .toUpperCase()
                          }`
                        : ""}
                      {contactRes.address.zip
                        ? ` ${contactRes.address.zip}`
                        : ""}
                    </p>
                  </div>
                )}
                {contactRes &&
                contactRes.phone &&
                contactRes.phone.length > 0 ? (
                  <div className="contact_dev_margin">
                    <h5 className="com_head_weight">
                      Phone {contactRes.phone.length > 1 ? "Numbers" : "Number"}
                      :
                    </h5>
                    <p>
                      {(contactRes.phone_numbers &&
                        contactRes.phone_numbers.length) > 0 ? (
                        <Fragment>
                          {contactRes.phone_numbers.map((phone) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link"
                                  href={`tel:${phone["Phone"]}`}
                                  target="_blank"
                                >
                                  {/* <PhoneOutlineIcon size={18} />{" "} */}
                                  {_formatPhoneNumber(phone["Phone"])
                                    ? !show
                                      ? _formatPhoneWithHide(phone["Phone"])
                                      : _formatPhoneNumber(phone["Phone"])
                                    : !show
                                    ? _formatPhoneWithHide(phone["Phone"])
                                    : phone["Phone"]}
                                  {phone["Extension"] &&
                                    `x${phone["Extension"]}`}
                                </a>
                                <span className="small-text light-text">
                                  {phone["Type"] &&
                                    `(${_capitalizeText(phone["Type"])})`}
                                </span>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : Array.isArray(contactRes.phone) ? (
                        <Fragment>
                          {contactRes.phone.map((phone) => {
                            return (
                              <div className="text_setter">
                                <a
                                  className="product__external-link"
                                  href={`tel:${phone}`}
                                  target="_blank"
                                >
                                  {_formatPhoneNumber(phone)
                                    ? !show
                                      ? _formatPhoneWithHide(phone)
                                      : _formatPhoneNumber(phone)
                                    : !show
                                    ? _formatPhoneWithHide(phone)
                                    : phone}
                                </a>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <a
                          className="product__external-link"
                          href={`tel:${contactRes.phone}`}
                          target="_blank"
                        >
                          {/* <PhoneOutlineIcon size={18} />{" "} */}
                          {_formatPhoneNumber(contactRes.phone)
                            ? !show
                              ? _formatPhoneWithHide(contactRes.phone)
                              : _formatPhoneNumber(contactRes.phone)
                            : !show
                            ? _formatPhoneWithHide(contactRes.phone)
                            : contactRes.phone}
                        </a>
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          {organizationRes.description && (
            <Row className="project-summary">
              <Col xl={12} lg={12} className="mb-2">
                <h5 className="section-title section-title--not-last com_head_weight">
                  Description
                </h5>
                <h5 className="section-title section-title--not-last subhead com_head_weight"></h5>
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: organizationRes.description,
                  }}
                />
              </Col>
            </Row>
          )}
          <div>
            <ViewContactButton contact={contactRes} setShow={setShow} show={show} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PersonPageCard;
