import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Container } from "reactstrap";
import { _axiosCall } from "../../../shared/helpers/apicalls";
import BuildingContainer from "../../BuildingDetail/components/BuildingContainer";

const pipelineUrl = process.env.API_ROOT + "/pipeline/";

const BuildingViewPopup = (props) => {
  const { user, building_slug, hideAddCollectionBtn } = props;
  const [buildingRes, setBuildingRes] = useState(null);

  const getBuildingData = (buildingSlug) => {
    if (!buildingSlug) return;
    let axiosData = {
      url:
        process.env.API_ROOT +
        `/buildings/${buildingSlug}?type=minimum&cache=15`,
      method: "get",
    };
    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.building && res.building[0]) {
          setBuildingRes(res.building[0]);
          getPipelineBuildings(res.building[0]);
        } else {
          setBuildingRes(null);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setBuildingRes(null);
      });
  };

  const getPipelineBuildings = async (buildingRes) => {
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      {
        $or: [
          { pipeline_occupancy_classification_proposed_code: { $nin: ["u"] } },
          { pipeline_total_construction_floor_area: { $gt: 1000 } },
        ],
      },
    ];

    let buildingKeys = [];

    if (
      buildingRes.alternate_keys &&
      Array.isArray(buildingRes.alternate_keys)
    ) {
      buildingKeys = [...buildingKeys, ...buildingRes.alternate_keys];
    } else if (buildingRes.key && Array.isArray(buildingRes.key)) {
      buildingKeys = [...buildingKeys, ...buildingRes.key];
    }

    if (buildingKeys.length > 0) {
      searchFilters.push({ building_key: { $in: buildingKeys } });
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
      },
      limit: 1,
    };

    let axiosData = {
      url: pipelineUrl + "query",
      method: "post",
      query: mongoQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        let docs = [];
        if (res && res.docs && res.docs[0]) {
          setBuildingRes(
            Object.assign({}, buildingRes, { pipeline_project: res.docs[0] })
          );
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  useEffect(() => {
    getBuildingData(building_slug);
  }, []);

  return (
    <Container>
      <Row>
        {buildingRes && (
          <BuildingContainer
            buildingRes={buildingRes}
            eventData={buildingRes}
            user={user}
            removeCss
            hideAddCollectionBtn={hideAddCollectionBtn}
            isSharePage={props.isShare}
            // mainTab={{ key: "construction", name: "Construction" }}
          />
        )}
      </Row>
    </Container>
  );
};

export default connect((state) => ({
  user: state.user,
}))(BuildingViewPopup);
