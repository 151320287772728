import React, { Fragment } from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import MainIcon from 'mdi-react/FinanceIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../shared/helpers/utils";

const NumberStat = (props) => {
	  const { number, title, icon, link } = props;
	  if(number == null) return null;
	  const content = (
		  <CardBody className="dashboard__booking-card card__link">
			  <div className="dashboard__booking-total-container">
				<h5 className="dashboard__booking-total-title">
				{number ? _formatNumber(number) : "--"}
				 
				</h5>
				{icon ? 
					<Fragment>
						{icon}
					</Fragment>
					:
						<MainIcon className="dashboard__trend-icon" />
				}
			  </div>
			  <h5 className="dashboard__booking-total-description">{title}</h5>
			  
			</CardBody>)
	  return (
		    <Card>
				{link ? 
					<Link to={link}>
						{content}
					</Link>
					:
					<Fragment>
						{content}
					</Fragment>
				}
		    </Card>
	  )
};

export default NumberStat;
