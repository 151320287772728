import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user, isShare } = this.props;
	    if(!user) return null;

		return(
		  <Container>
		    <Row className="c_seconday-nav-container">
		      <Col md={12}>
		        <h3 className="page-title"> Permits</h3>
		        {!isMobile && <h3 className="page-subhead subhead">Search construction permit issuances</h3> }
		      </Col>
		    </Row>
		     {isShare || _hasPermission(user, 'transfers') ?
			   <Fragment>
			     <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    <Row id="research_bar">
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
				          <p>
				          	<span className="bold-text">{`About The Data`}</span> 
				            <div>{`The information provided herein is provisional and subject to change.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row>
			   
			    <Row>
			      <UnitsSearchTable />
			    </Row>
		    </Fragment>
		    :
			     <Row>
				    <EnterpriseGate 
				    	copy={<span>Access to Permit Search requires an upgrade to your subscription.</span>}
			    		upgrade={false}
						user={user}
						img={`https://marketproof.com/svgs/PL1.svg`}
						classes={"small-image"}
						/>
				 </Row>
			}
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



