import { _axiosCall } from '../../helpers/apicalls';
import { buildingExportFL, propertyExportFL } from '../../helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; 

export const Marketproof = (function () {
	
	const sanitizeObject = ( data, trackedKeys ) => {		
		
		if(!data || typeof data !== "object") return false;
		
		const keyArray = ["key", "id", "user_key"];
		
		const sanitized = {};
		Object.keys(data).forEach(key => {
			
			if(trackedKeys.indexOf(key)>=0 || keyArray.indexOf(key)>=0){
				if(data[key]){
					if(typeof data[key] === 'object'){
						sanitized[key] = Object.assign({}, data[key]);
					}else if(Array.isArray(data[key]) && data[key][0]){
						if(keyArray.indexOf(key)<0){
							sanitized[key] = data[key][0].toString().toLowerCase();
						}else{
							sanitized[key] = data[key][0];
						}
						
					}else{
						sanitized[key] = data[key].toString().toLowerCase();
						if(keyArray.indexOf(key)<0){
							sanitized[key] = data[key].toString().toLowerCase();
						}else{
							sanitized[key] = data[key];
						}
					}
				}
				
			}
			
		})
		return sanitized
	}
	
    const trackEvent = (event, data) => {
	    console.log('trackEvent', event, data);
	    const allowedLocalEvents = ["showContactInfo","showCompanyInfo"];
	    
	    
	    /**workaround for multiple mounting of component */
	    if(data.checkDupURL && window && window.location.href){
		    
		  if(window.last_tracked_mp_url && window.last_tracked_mp_url == window.location.href){
			  return false; // do not track
		  }
		}
	    const events = event.split(".");
	    if(events[0] == 'track-mp'){
		    events.shift();
	    }
	    const event_type = events[0];	
		
		if(window.location.hostname.includes('localhost')){
			if(allowedLocalEvents.indexOf(event_type)<0){
				return false;
			}
		}
		    
	    const event_sub_type = events[1] ? events[1] : null;	
		const { user, project, organization, contact, report } = data;
		const trackedUserKeys = ["email", "id", "firstName", "lastName"];
		
		const trackedProjectKeys = ["key", "_id", "address", "pipeline_project_stage", "name", "display_full_street_address", "pipeline_occupancy_type"];
		
		const trackedOrgKeys = ["key", "_id", "name"];
		const trackedContactKeys = ["key", "_id", "name", "first_name", "last_name"];
		
		const sanitizedUser = sanitizeObject(user, trackedUserKeys);
		const sanitizedProject = sanitizeObject(project, trackedProjectKeys)
		const sanitizedOrganization = sanitizeObject(organization, trackedOrgKeys)
		const sanitizedContact = sanitizeObject(contact, trackedContactKeys)
		const customData = {}
		const saveData = {
			event_type: event_type,
			event_sub_type: event_sub_type,
			user: sanitizedUser,
			project: sanitizedProject,
			organization: sanitizedOrganization,
			contact: sanitizedContact,
			report: report ? report : false,
			source: "PIPELINE"
		}
		console.log("saveData", saveData)	
		let apiData = {
				url: apiUrl+'event/track',
				method: "post",
				query: saveData
		  	};
		  
		  
		_axiosCall(apiData)
		    .then(res => {
			    console.log(res)
			 if(window && window.location.href){
				 
				 window.last_tracked_mp_url = window.location.href;
				 console.log("store mp url", window.last_tracked_mp_url)
			 }
			  
		})

	}
	
	return {
	    trackEvent : trackEvent
    }
})()