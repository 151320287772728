import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ShareIcon from "mdi-react/AccountPlusOutlineIcon";

import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles,
} from "@material-ui/core";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  buildingFL,
  propertyFL,
  _privateKey,
  BuildingPhoto,
  BuildingStageBadge,
  BuildingCurrentStatus,
  _replaceAbbreviations,
  SalesProgress,
  _getUnitFloorPlan,
  UnitCurrentStatus,
  _formatNameAddress,
} from "../../../../shared/helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: "#fff",
    minWidth: "50px",
  },
  tableContainer: {
    maxHeight: "none",
  },
  cell: {
    minWidth: "160px",
    maxWidth: "160px",
  },
  freezCell: {
    minWidth: "100px",
    fontWeight: 500,
    textAlign: "right",
    padding: "5px 0",
    float: "right",
  },
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    left: 0,
    position: "sticky",
    zIndex: 99,
  },
  body: {
    backgroundColor: "#ddd",
    minWidth: "100px",
    left: 0,
    position: "sticky",
    zIndex: 98,
    fontWeight: 500,
    width: "150px",
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const FreezeTableBuildings = ({ buildingRes, toggleModal }) => {
  const classes = useStyles();

  const hashMap = {};
  buildingRes.map((bldg) => {
    hashMap[bldg.key] = bldg;
  });

  const compData = Object.assign(
    ...Array.from(
      new Set(buildingRes.reduce((keys, o) => keys.concat(Object.keys(o)), [])),
      (key) => ({ [key]: buildingRes.map((o) => o[key]) })
    )
  );
  return (
    <div className="comp-report">
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableBody>
            <StyledTableRow key={"photo"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                ></StyledTableCell>
              </StickyTableCell>
              {compData.key &&
                compData.key.map((key) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                      onClick={() => toggleModal("building", hashMap[key])}
                    >
                      <div className="building-photo">
                        <BuildingPhoto item={hashMap[key]} />
                        <StatusBadge building={hashMap[key]} />
                        {/* <BuildingCurrentStatus item={hashMap[key]} /> */}
                      </div>
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>

            <StyledTableRow key={"unit"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                ></StyledTableCell>
              </StickyTableCell>
              {compData.key &&
                compData.key.map((key) => {
                  const item = hashMap[key];
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      <Tooltip title={`View project details`}>
                        <Button
                          onClick={() => toggleModal("building", hashMap[key])}
                          variant="outlined"
                          size="small"
                          color="primary"
                          className="detail-view"
                        >
                          {" "}
                          See Details
                        </Button>
                      </Tooltip>
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"name"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Name
                </StyledTableCell>
              </StickyTableCell>
              {compData.key &&
                compData.key.map((key) => {
                  const item = hashMap[key];
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      <span className="bold-text">
                        {item.name
                          ? _capitalizeText(item.name)
                          : _capitalizeText(item.display_full_street_address)}
                      </span>
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>

            <StyledTableRow key={"address"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Address
                </StyledTableCell>
              </StickyTableCell>
              {compData &&
                compData.display_full_street_address &&
                compData.display_full_street_address.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_capitalizeText(val)}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"neighborhood"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Neighborhood
                </StyledTableCell>
              </StickyTableCell>
              {compData.address &&
                compData.address.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {Array.isArray(val.neighborhood)
                        ? _capitalizeText(val.neighborhood[0])
                        : _capitalizeText(val.neighborhood)}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"neighborhood"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Borough
                </StyledTableCell>
              </StickyTableCell>
              {compData.address &&
                compData.address.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_capitalizeText(val.borough)}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"sales_start"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Floors
                </StyledTableCell>
              </StickyTableCell>
              {compData.pipeline_building_stories_proposed &&
                compData.pipeline_building_stories_proposed.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_formatNumber(val)}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"developer"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Units
                </StyledTableCell>
              </StickyTableCell>
              {compData.pipeline_dwelling_units_proposed &&
                compData.pipeline_dwelling_units_proposed.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_formatNumber(val)}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"architect"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Residential Area
                </StyledTableCell>
              </StickyTableCell>
              {compData.pipeline_zoning_area_residential &&
                compData.pipeline_zoning_area_residential.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val > 0 ? _formatNumber(val) + " Resi SF" : "-"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"interior"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Total Area
                </StyledTableCell>
              </StickyTableCell>
              {compData.pipeline_total_construction_floor_area &&
                compData.pipeline_total_construction_floor_area.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val > 0 ? _formatNumber(val) + " Total SF" : "-"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"inventory"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Last Updated
                </StyledTableCell>
              </StickyTableCell>
              {compData.meta &&
                compData.meta.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {moment(val.ModificationTimestamp).year() > 1970
                        ? _capitalizeText(
                            moment(val.ModificationTimestamp).fromNow()
                          )
                        : "--"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>

            <StyledTableRow key={"pct_sold"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Permit Filled
                </StyledTableCell>
              </StickyTableCell>
              {compData.pipeline_filing_date &&
                compData.pipeline_filing_date.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val && moment(val).year() > 1970
                        ? _capitalizeText(moment(val).fromNow())
                        : "--"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"inventory"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Permit Issued
                </StyledTableCell>
              </StickyTableCell>
              {compData &&
                compData.pipeline_permit_issuance_job_start_date &&
                compData.pipeline_permit_issuance_job_start_date.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val && moment(val).year() > 1970
                        ? _capitalizeText(moment(val).fromNow())
                        : "--"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"range"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  CO/TCO Issued
                </StyledTableCell>
              </StickyTableCell>
              {compData.pipeline_coo_issuance_date &&
                compData.pipeline_coo_issuance_date.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val && moment(val).year() > 1970
                        ? _capitalizeText(moment(val).fromNow())
                        : "--"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const FreezeTableUnits = ({ unitRes, toggleModal }) => {
  const classes = useStyles();

  const hashMap = {};
  unitRes.map((bldg) => {
    hashMap[bldg.key] = bldg;
  });

  const compData = Object.assign(
    ...Array.from(
      new Set(unitRes.reduce((keys, o) => keys.concat(Object.keys(o)), [])),
      (key) => ({ [key]: unitRes.map((o) => o[key]) })
    )
  );
  return (
    <div className="comp-report">
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableBody>
            <StyledTableRow key={"photo"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                ></StyledTableCell>
              </StickyTableCell>
              {compData.key.map((key) => {
                const item = hashMap[key];
                return (
                  <StyledTableCell
                    numeric
                    align="center"
                    className={classes.cell}
                    onClick={() => toggleModal("unit", hashMap[key])}
                  >
                    <div className="building-photo">
                      <BuildingPhoto item={item} />
                    </div>
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow key={"unit"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                ></StyledTableCell>
              </StickyTableCell>
              {compData.key.map((key) => {
                const item = hashMap[key];
                return (
                  <StyledTableCell
                    numeric
                    align="center"
                    className={classes.cell}
                  >
                    <Tooltip title={`View Building details`}>
                      <Button
                        onClick={() => toggleModal("unit", hashMap[key])}
                        variant="outlined"
                        size="small"
                        color="primary"
                        className="detail-view"
                      >
                        {" "}
                        See Details
                      </Button>
                    </Tooltip>
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow key={"building"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Name
                </StyledTableCell>
              </StickyTableCell>
              {compData.name.map((val) => {
                return (
                  <StyledTableCell
                    numeric
                    align="center"
                    className={classes.cell}
                  >
                    {val ? _capitalizeText(val) : "--"}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow key={"address"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Address
                </StyledTableCell>
              </StickyTableCell>
              {compData.display_full_street_address.map((val) => {
                return (
                  <StyledTableCell
                    numeric
                    align="center"
                    className={classes.cell}
                  >
                    {_capitalizeText(val)}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>

            <StyledTableRow key={"stage"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Type
                </StyledTableCell>
              </StickyTableCell>
              {compData.ownership_type.map((val) => {
                return (
                  <StyledTableCell
                    numeric
                    align="center"
                    className={classes.cell}
                  >
                    {val
                      ? _capitalizeText(val.toString().toLowerCase().trim())
                      : "--"}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow key={"last_listed"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Year Build
                </StyledTableCell>
              </StickyTableCell>
              {compData.year_built &&
                compData.year_built.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"last_sold"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Zip Code
                </StyledTableCell>
              </StickyTableCell>
              {compData.zip &&
                compData.zip.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"neighborhood"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Neighborhood
                </StyledTableCell>
              </StickyTableCell>
              {compData.neighborhood &&
                compData.neighborhood.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_capitalizeText(val[0])}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"borough"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Borough
                </StyledTableCell>
              </StickyTableCell>
              {compData.borough &&
                compData.borough.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_capitalizeText(val)}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"floors"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Floors
                </StyledTableCell>
              </StickyTableCell>
              {compData.number_of_floors &&
                compData.number_of_floors.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_formatNumber(val) + " Floors"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"baths"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Residential Units
                </StyledTableCell>
              </StickyTableCell>
              {compData.residential_units &&
                compData.residential_units.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {_formatNumber(val) + " Units"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
            <StyledTableRow key={"sf"}>
              <StickyTableCell>
                <StyledTableCell
                  numeric
                  align="right"
                  className={classes.freezCell}
                >
                  Sq Ft
                </StyledTableCell>
              </StickyTableCell>
              {compData.sq_ft &&
                compData.sq_ft.map((val) => {
                  return (
                    <StyledTableCell
                      numeric
                      align="center"
                      className={classes.cell}
                    >
                      {val > 0 ? _formatNumber(val) + " Sq Ft" : "--"}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
