import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import MatTableHead from "./MatTableHead";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Progress,
  Badge,
  Container,
} from "reactstrap";
import TablePagination from "@material-ui/core/TablePagination";
import BuildingIcons from "../../../shared/components/BuildingIcons";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

import AddToBuyersListButton from "../../../shared/components/buttons/AddToBuyersListButton";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  ConstructionProgress,
  BuildingStatus,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  mpndCohortQuery,
  syncGenericFilter,
  _objectEqual,
  buildingExportFL,
  getCohortQuery,
  StatusBadge,
  BuildingPhoto,
  BuildingCurrentStatus,
  _replaceAbbreviations,
  SalesProgress,
  _formatNameAddress,
  GetStage
} from "../../../shared/helpers/utils";

class MatGridView extends PureComponent {
  getBuildingLink = (item) => {
    return _getBuildingURL(item);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      rtl,
      user,
      reactTableFiltered,
      selected,
      isSelected,
      tableData,
      totalCount,
      rowsPerPage,
      page,
      handleBuildingClick,
      handleChangePage,
      handleChangeRowsPerPage,
      handleSelectAllClick,
      handleRequestSort,
      type,
      noPagination,
      selectedItems,
      selectUnit,
      handleCSS,
      isShare
    } = this.props;
    const table = reactTableFiltered;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
    const paginationCount = totalCount;
    
    const ShowCard = ({item}) => {
      const { buildingNameOrAdr, adr, _boro, _hood } = _formatNameAddress(item);
      const stage = GetStage(item);
      return (
        <>
        <div
          className="catalog-item__img-wrap"
          style={{ border: "none" }}
        >
          {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
          <BuildingPhoto item={item} />
          {selectedItems &&
          user &&
          user.role.indexOf("admin") >= 0 ? (
            <div className="check-box">
              <Checkbox
                checked={
                  selectedItems[item.key] === undefined ? false : true
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectUnit(item);
                }}
                className="material-table__checkbox"
              />
              <AddToBuyersListButton
                projectKey={item.key}
                type={"project"}
                removeOnly
              />
            </div>
          ) : (
            <AddToBuyersListButton
              projectKey={item.key}
              type={"project"}
              removeOnly
            />
          )}
        </div>
        <div className="badge-holder">
        <StatusBadge building={item} />
        </div>
        <div className="catalog-item__info">
          <h4
            className="catalog-item__price"
            style={{ marginLeft: "15px", fontSize: "14px" }}
          >
            {buildingNameOrAdr}
            <div style={{ marginLeft: -10, marginBottom: 5 }}>
              <BuildingIcons building={item} />
            </div>
        
            <div
              className="light-text small-text"
              style={{ color: "#858AA1" }}
            >
              {_capitalizeText(adr)}
              {_hood
                ? `, ${_capitalizeText(_hood)}, `
                : ""}{" "}
              {_capitalizeText(_boro)}
            </div>
          </h4>
          <h5
            className="product-card__title"
            style={{ marginLeft: "15px" }}
          >
            <div className="light-text">
              <span className="light-text small-text">
                {item.pipeline_zoning_area_residential > 0
                  ? _formatNumber(
                      item.pipeline_zoning_area_residential
                    ) + " Resi SF"
                  : ""}
              </span>
              {item.pipeline_zoning_area_residential > 0 &&
                item.pipeline_total_construction_floor_area > 0 && (
                  <span className="small-divider">|</span>
                )}
              <span className="light-text small-text">
                {item.pipeline_total_construction_floor_area > 0
                  ? _formatNumber(
                      item.pipeline_total_construction_floor_area
                    ) + " Total SF"
                  : ""}
              </span>
            </div>
            <span className="light-text small-text">
              {_formatNumber(item.pipeline_building_stories_proposed)}{" "}
              Floors
            </span>
            {type !== "commercial" &&
              item.pipeline_dwelling_units_proposed > 0 && (
                <Fragment>
                  <span className="small-divider">|</span>
                  <span className="light-text small-text">
                    {item.pipeline_dwelling_units_proposed > 0
                      ? _formatNumber(
                          item.pipeline_dwelling_units_proposed
                        )
                      : ""}{" "}
                    Units
                    {item.pipeline_hotel_units_proposed > 0 &&
                      <span> ({_formatNumber(item.pipeline_hotel_units_proposed)} Hotel) </span>
                      
                    }
                  </span>
                </Fragment>
              )}
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              margin: "0px 10px 20px 0px",
            }}
          >
            <span
              className="light-text small-text"
              style={{
                color: "#858AA1",
                textAlign: "center",
                fontSize: 11,
              }}
            >
             
              {item.pipeline_filing_date && moment(item.pipeline_filing_date).year() > 1970
                ? "Plans Filed: " + _capitalizeText(moment(item.pipeline_filing_date).fromNow())
                
                : item.pipeline_pre_filing_date && moment(item.pipeline_pre_filing_date).year() > 1970 
                ?  "Plans Filed: " + _capitalizeText(moment(item.pipeline_pre_filing_date).fromNow())               
                : ['construction', 'completion'].indexOf(stage)>=0 ? "Plans Filed" : "Plans Not Filed"}
            </span>
          </div>
        </div>
        </>
      )
    }
    
    return (
      <>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {table.map((item, i) => {
              
              return (
              <Col lg={3} className="col-mdi">
                <CardBody
                  style={{ padding: "0px" }}
                  className={
                    handleCSS
                      ? "catalog-item card__link update_card_css"
                      : "catalog-item card__link"
                  }
                  key={i}
                 
                >
                  {isShare ? 
                    <ShowCard item={item} />
                    :
                    <Link to={_getBuildingURL(item, "_id")}>
                      <ShowCard item={item} />
                    </Link>
                  }
                  
                </CardBody>
              </Col>
            )})}
          </div>

          {paginationCount && !noPagination ? (
            <Fragment>
              <TablePagination
                component="div"
                className="material-table__pagination"
                count={paginationCount}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[8, 20, 40, 80, 120]}
                dir="ltr"
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
              />
            </Fragment>
          ) : null}
        </div>
      </>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
  user: state.user,
}))(MatGridView);
