import React, { PureComponent, useState, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import TotalFloors from './components/TotalFloors';
import TotalUnits from './components/TotalUnits';
import AveragePPSF from './components/AveragePPSF';
import PriceRange from './components/PriceRange';
import TotalUnsold from './components/TotalUnsold';

import AveragePPSFSold from './components/AveragePPSFSold';
import TotalSqFt from './components/TotalSqFt';
import AvgUnitSqFt from './components/AvgUnitSqFt';
import PriceRangeSold from './components/PriceRangeSold';


import CurrentProgress from './components/CurrentProgress';

import SalesVelocity from './components/SalesVelocity';
import AbsorptionRate from './components/AbsorptionRate';
import PercentShadow from './components/PercentShadow';

import PercentFinanced from './components/PercentFinanced';
import AverageDiscount from './components/AverageDiscount';
import AverageDOM from './components/AverageDOM';

import UnitMix from './components/UnitMix';
import UnitBreakdown from './components/UnitBreakdown';

import ListingTrends from './components/ListingTrends';
import ListingTrendsPrice from './components/ListingTrendsPrice';

import StackedUnits from './../../../Reports/StackedUnits/components/StackedUnits';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';

const Overview = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'summary');
    const { unitsRes, buildingRes, statsRes, user } = props;  
    const _boro = buildingRes.borough;
    const _hood = buildingRes.neighborhood[0];
    const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
    const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
    const summaryTitle = 'Building Analysis - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
    const _toggleTab = (tab) => {
	    if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/analysis/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
	      setActiveTab(tab)
	      Publisher.publish(`track.buildingAnalysisView.${tab}`, {building: buildingRes.key});
	    }
	  };
    const Summary = () => {
	    return (
		    <div>
			   <Row>
			    <TotalUnits {...props} />
			    <TotalFloors {...props} /> 
			    <AvgUnitSqFt {...props} />   
			    <TotalSqFt {...props} /> 				                
			   </Row>
			   <Row>
			   	<AveragePPSF {...props} />
			   	<AveragePPSFSold {...props} />
			    <PriceRange {...props} />	
			   </Row>
			   {props.buildingRes && props.buildingRes.building_status === 'sponsor' &&
			   <Row>
			   	  <CurrentProgress {...props} />
			   </Row>
			   }
			   {props.user && ['seller', 'enterprise'].indexOf(props.user.role) > -1 &&
			   <Row>
			   	  <ListingTrends buildingRes={props.buildingRes} statsRes={props.statsRes} />
			   	{/*  <ListingTrendsPrice buildingRes={props.buildingRes} statsRes={props.statsRes} /> */}
			   </Row>
			   }
			   {!(props.buildingRes && props.buildingRes.offering_construction === 'conversion') &&  (props.buildingRes && props.buildingRes.building_status === 'sponsor') &&
			   <Row>
			   	  <SalesVelocity {...props} />
			   	  <AbsorptionRate {...props} />
			   	  <PercentShadow {...props} />
			   </Row>
			   }
			   {(props.buildingRes && props.buildingRes.building_status === 'resale') &&
			   <Row>
			   	  <PercentFinanced {...props} />
			   	  <AverageDiscount {...props} />
			   	  <AverageDOM {...props} />
			   </Row>
			   }
			   {props.statsRes.count>0 &&
			   <Row>
			   		<UnitMix {...props} />
			   		<UnitBreakdown {...props} />
			   </Row>
			   }
			</div>		    
	    )
    } 
	
	return(
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		{['sponsor', 'resale', 'pipeline'].indexOf(buildingRes.building_status)>=0 &&   
			<div className="tabs"> 	
            <div className="tabs__wrap mb-2">
                <Nav tabs>
                   <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'summary' })}
                      onClick={() => {
                        _toggleTab('summary');
                      }}
                    >
                      <span className="tab-title">Summary</span>
                    </NavLink>
                  </NavItem>
                   <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'status' })}
                      onClick={() => {
                        _toggleTab('status');
                      }}
                    >
                      <span className="tab-title">Stacking Plan</span>
                    </NavLink>
                  </NavItem>
				  {['resale'].indexOf(buildingRes.building_status)>=0 ?
					  <Fragment>
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'sold' })}
		                      onClick={() => {
		                        _toggleTab('sold');
		                      }}
		                    >
		                      <span className="tab-title">Sold PPSF</span>
		                    </NavLink>
		                  </NavItem>
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'ownership' })}
		                      onClick={() => {
		                        _toggleTab('ownership');
		                      }}
		                    >
		                      <span className="tab-title">Ownership Years</span>
		                    </NavLink>
		                  </NavItem>
		             </Fragment>
                  :
                  	 <Fragment>
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'pricing' })}
		                      onClick={() => {
		                        _toggleTab('pricing');
		                      }}
		                    >
		                      <span className="tab-title">Asking PPSF</span>
		                    </NavLink>
		                  </NavItem>
		                  
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'discount' })}
		                      onClick={() => {
		                        _toggleTab('discount');
		                      }}
		                    >
		                      <span className="tab-title">Discounts</span>
		                    </NavLink>
		                  </NavItem>
                     </Fragment>
                  
                  }
                </Nav>
              </div>
            </div>
        }
		
		 {activeTab === 'summary' ?
			<div>
				<ExportComponentButton {...props} title={summaryTitle} component={<Summary {...props} activeTab={activeTab} />} />
				<Summary />
			</div>
			:
			<StackedUnits {...props} activeTab={activeTab} />
		}
			  
		</Container>	  
	    </div>
	    
	)
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Overview));