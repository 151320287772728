import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Badge,
} from "reactstrap";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  UnitCurrentStatus,
  propertyFL,
  _isResiUnit,
  _getContactURL,
  ProfileAvatar,
  _formatPhoneNumber,
  ProfileAvatarExtraLarge,
  _PickOrgName,
} from "../../../shared/helpers/utils";
import MatTable from "../../PipelineBuildings/components/MatTable";
import EditContact from "../../../shared/components/edit/EditContact";
import { bindActionCreators } from "redux";
import buyersListActions from "../../../redux/actions/buyersListActions";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import WebIcon from "mdi-react/WebIcon";
import AddToBuyersListButton from "../../../shared/components/buttons/AddToBuyersListButton";
import Profile from "../../SingleOrganization/components/Profile";
import { Grid } from "@material-ui/core";

const ContactProfilePage = (props) => {
  const { contactRes, setChanged, organizationRes } = props;
  const roles = [];

  if (
    contactRes &&
    contactRes.project_roles &&
    Object.keys(contactRes.project_roles).length > 0
  ) {
    Object.keys(contactRes.project_roles).forEach((key) => {
      const p = contactRes.project_roles[key];
      p.map((r) => {
        r =
          r === "applicant"
            ? "permit applicant"
            : r === "filing_applicant"
            ? "permit applicant"
            : r;
        if (roles.indexOf(r) < 0) {
          roles.push(r);
        }
      });
    });
  }
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody style={{ padding: "0px" }}>
          <div className="card__title">
            <div className="card_button">
              <div className="top__toolbar d-flex">
                <AddToBuyersListButton
                  contactKey={contactRes.key}
                  type={"contact"}
                />
                <EditContact
                  item={contactRes}
                  setChanged={setChanged}
                  single
                  edit
                />
                <EditContact
                  item={contactRes}
                  setChanged={setChanged}
                  single
                  del
                />
              </div>
            </div>
            <div className="new_card_title">
              <img src="/img/company_header.svg" />
              <div className="contact_logo_image">
                <div className="unit__badge">
                  {/* {organizationRes &&
                  organizationRes.logo &&
                  organizationRes.logo[0] &&
                  organizationRes.logo[0].preview ? (
                    <div
                      className="company_logo"
                      style={{
                        backgroundImage: `url(${organizationRes.logo[0].preview})`,
                      }}
                    />
                  ) : organizationRes.project_keys &&
                    organizationRes.project_keys.length == 1 ? (
                    <span>
                      {_capitalizeText(
                        _PickOrgName(
                          organizationRes,
                          organizationRes.project_keys[0]
                        )
                      )}
                    </span>
                  ) : (
                    <span>{organizationRes.name}</span>
                  )} */}
                  <div
                    className="contact_header"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="image">
                      <ProfileAvatarExtraLarge contact={contactRes} large />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Grid
            item
            xs={12}
            className="contact_page_common_margin"
            style={{ marginTop: "80px" }}
          >
            <div>
              <h3 className="contact_page-title">{contactRes.name}</h3>
             
              <Row className="contact_head_margin">
                <Col xl={6} lg={6} md={6}>
                  {organizationRes && organizationRes.name ? (
                    <div className="contact_dev_margin">
                      <h5 className="com_head_weight">Company:</h5>
                      <p>
                        <a
                          href={`/company/${organizationRes["key"]}`}
                          target="_blank"
                          className="product__external-link com_product_link_size"
                        >
                          {_capitalizeText(organizationRes.name)}
                        </a>
                      </p>
                    </div>
                  ) : null}
                </Col>
                <Col xl={6} lg={6} md={6}>
                  
                  {roles.length > 0 ? (
                    <div className="contact_dev_margin">
                      <h5 className="com_head_weight">Project Roles:</h5>
                      <p className="com_pera_style">
                        {roles.filter(u => typeof u === 'string').map((u) => (
                          <Badge style={{ marginRight: 5 }} color="shadow">
                            {_capitalizeText(u.toString().replace("_", " "))}
                          </Badge>
                        ))}
                      </p>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Grid>
          <hr />
          <Grid item className="contact_page_common_margin" xs={12}>
            <Profile {...props} personPage={true} />
            {/* personPage props added for seprate contact page ui */}
          </Grid>
          <hr />

          <Grid item xs={12} className="contact_page_common_margin">
            <div className={"d-flex"}>
              <div>
                <h3 className="section-title section-title--not-last contact_page_pro_heading">
                  Projects
                </h3>
                <h3 className="section-title section-title--not-last subhead contact_page_pro_sub-heading">
                  Recent projects associated with {contactRes.name}{" "}
                </h3>
              </div>
            </div>

            <Col className="contact_page-le-pad">
              <MatTable contactRes={contactRes} isSingle min />
            </Col>
          </Grid>
        </CardBody>
      </Card>
    </Col>
  );
};

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings)
      ? state.buyersLists.viewedBuildings.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(withRouter(ContactProfilePage)));
