import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import {
	Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Table
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../redux/actions/buyersListActions';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../shared/components/Panel';
import LinearLoading from '../../../../shared/components/LinearLoading';
import { getDefaultChartColors } from '../../../../shared/helpers';

import { _axiosCall } from '../../../../shared/helpers/apicalls';
import {
	buildingFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl,
	_isEqual,
	_formatBigNumber
} from "../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const buildingUrl = process.env.API_ROOT + "/pipeline/";
const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		//marginLeft:"-8px",
		marginBottom: "15px",
		alignItems: 'left',
		'& > *': {

		},
	},
	button: {
		'&.selected': {
			background: "#D1D6E0"
		},
		fontSize: 11,
		width: 90
	}
});

const ShowUnits = ({ data, title, color, analysisType, searchParams }) => {
	const header = {
		"count": "Number of Projects",
		"units": "Number of Residential Units",
		"sq_ft": "Total Square Feet"
	}

	return (
		<Card>
			<CardBody style={{ padding: 15 }}>
				<div className="card__title">
					<h5 className="dashboard__booking-total-description">{title}</h5>
				</div>
				<Table responsive className="table-striped">
					<tbody>
						<tr>
							<th colSpan="2" className="light-text"><h5 className="dashboard__booking-total-description">{header[analysisType]}</h5></th>
						</tr>
						{data && data.map((item, i) => {
							const count = analysisType == "units" ? item.units : analysisType == "sq_ft" ? item.sq_ft : item.count;
							return (

								<tr key={i} style={{ border: 0, cursor: "pointer" }}>
									<td style={{ width: 100 }}>
										<Badge style={{ width: 70, background: color }}>
											<span className="bold-text">{count > 1000000 ? _formatBigNumber(count) : _formatNumber(count)}</span>
										</Badge>
									</td>
									<td>
										<Link to={`/company/${item.organizations.key}/tab/projects${searchParams}`}>
											<div>{_capitalizeText(item.name)}</div>
											<span className="light-text small-text" style={{ color: "#858AA1" }}>
											</span>
										</Link>
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>

			</CardBody>
		</Card>
	)
}
class TopCompanies extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			fetchInProgress: false,
			data: null,
			period: "7-day"
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!_isEqual(this.props.searchFilters, prevProps.searchFilters) ||
			this.props.analysisType != prevProps.analysisType) {
			this._getTopCompanies();
		}

	}

	componentDidMount() {
		this._getTopCompanies();


	}
	_setBuyersList = (res) => {
		const list = res.docs ? res.docs.map(building => {
			return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		}) : []
		this.setState({
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),
		});
	}
	_getTopCompanies = async () => {
		const { period } = this.state;
		const { analysisType, searchFilters } = this.props;

		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		let sort = {};

		sort[analysisType] = -1;
		let atlasQuery = [
			{ $match: { $and: searchFilters } },
			{ $project: { _id: 0, key: 1, organizations: 1, pipeline_dwelling_units_proposed: 1, pipeline_total_construction_floor_area: 1 } },
			{
				$facet: {
					developer: [

						{ $unwind: "$organizations" },
						{ $project: { _id: 0, key: 1, organizations: 1, pipeline_dwelling_units_proposed: 1, pipeline_total_construction_floor_area: 1 } },
						{ $unwind: "$organizations" },
						{
							$match: {
								"$or": [
									{
										"organizations.project_roles": {
											"$in": ["developer"]
										}
									},
									{
										"organizations.categories": {
											"$in": ["developer"]
										}
									}
								]
							}
						},
						{
							$project: {
								_id: 0,
								key: 1,
								"organizations.key": 1,
								"organizations.name": 1,
								"organizations.project_roles": 1,
								"pipeline_dwelling_units_proposed": 1,
								"pipeline_total_construction_floor_area": 1
							},
						},
						{
							$group: {
								_id: { key: "$organizations.key" },
								name: { $first: "$organizations.name" },
								count: { $sum: 1 },
								units: { "$sum": "$pipeline_dwelling_units_proposed" },
								sq_ft: { "$sum": "$pipeline_total_construction_floor_area" },
							},
						},
						{ $project: { _id: 0, organizations: "$_id", name: 1, count: 1, units: 1, sq_ft: 1 } },
						{ $sort: sort },
						{ $limit: 10 },
						{ $skip: 0 },
					],
					architect: [

						{ $unwind: "$organizations" },
						{ $project: { _id: 0, key: 1, organizations: 1, pipeline_dwelling_units_proposed: 1, pipeline_total_construction_floor_area: 1 } },
						{ $unwind: "$organizations" },
						{
							$match: {
								"$or": [
									{
										"organizations.project_roles": {
											"$in": ["architect"]
										}
									},
									{
										"organizations.categories": {
											"$in": ["architect"]
										}
									}
								]
							}
						},
						{
							$project: {
								_id: 0,
								key: 1,
								"organizations.key": 1,
								"organizations.name": 1,
								"organizations.project_roles": 1,
								"pipeline_dwelling_units_proposed": 1,
								"pipeline_total_construction_floor_area": 1
							},
						},
						{
							$group: {
								_id: { key: "$organizations.key" },
								name: { $first: "$organizations.name" },
								count: { $sum: 1 },
								units: { "$sum": "$pipeline_dwelling_units_proposed" },
								sq_ft: { "$sum": "$pipeline_total_construction_floor_area" },
							},
						},
						{ $project: { _id: 0, organizations: "$_id", name: 1, count: 1, units: 1, sq_ft: 1 } },
						{ $sort: sort },
						{ $limit: 10 },
						{ $skip: 0 },
					]
				},
			},
		];

		let axiosData = {
			url: buildingUrl + "aggregate-search",
			method: "post",
			query: atlasQuery,
		};


		_axiosCall(axiosData)
			.then((res) => {
				if (res && res[0]) {
					this.setState({ facetData: res[0], fetchInProgress: false })
				} else {
					this.setState({ facetData: false, fetchInProgress: false })
				}

			})
			.catch((error) => {
				console.log("error: " + error);
				this.setState({ facetData: false, fetchInProgress: false })
			});

	};

	handleBuildingClick = (event, building, tab) => {
		event.stopPropagation();
		const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
		this.props.history.push(buildingURL);
	};

	setPeriod = (period) => {
		this.setState({ period: period })
	}

	render() {
		const { savedUnits, totalCount, period, facetData, buildingsFetchInProgress, fetchInProgress } = this.state;
		const { classes, analysisType, type, baseURL } = this.props;


		let searchParams = window.location && window.location.search ? window.location.search : "";

		const title = {
			"count": "Top Companies by Number of Projects",
			"units": "Top Companies by Number of Residential Units",
			"sq_ft": "Top Companies by Total Square Feet"
		}
		return (
			<Card>
				<CardBody className="dashboard__booking-card">
					<Row>
						<Col md={12} className={"mb-4"}>
							<div className="dashboard__booking-total-container">
								<h5 className="dashboard__booking-total-description">{title[analysisType]}</h5>
								{/*<h5 className="subhead">Top Listings, Contracts and Closing for The {type == "resale" ? "Resale Condo Market" : 'New Development Market'}</h5>*/}
							</div>

						</Col>
					</Row>

					{(!facetData || fetchInProgress || buildingsFetchInProgress) ?
						<div>
							<LinearLoading />
							<div style={{ height: 360 }}>

							</div>
						</div>

						:

						<Row>
							<Col md={6} className={"mb-2"}>
								<ShowUnits data={facetData.developer && facetData.developer.length > 0 ? facetData.developer : false} title={"Developers"} color={getDefaultChartColors[1]} analysisType={analysisType} searchParams={searchParams} />
							</Col>
							<Col md={6} className={"mb-2"}>
								<ShowUnits data={facetData.architect && facetData.architect.length > 0 ? facetData.architect : false} title={"Architects"} color={getDefaultChartColors[3]} analysisType={analysisType} searchParams={searchParams} />
							</Col>


						</Row>
					}

					<div className="unit__load text-right semi-bold-text">

					</div>
				</CardBody>
			</Card>
		)
	}
};

TopCompanies.propTypes = {
	t: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
	const buyersLists = state.buyersLists.priorityFilter
		? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
		: state.buyersLists.buyersLists;
	return {
		buyersLists, theme: state.theme, rtl: state.rtl,
		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],
		dataSet: state.buyersLists.dataSet
	};
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withStyles(styles, { withTheme: true })(TopCompanies)));
