import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Badge,
} from "reactstrap";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  buildingFL,
  propertyFL,
  _privateKey,
  BuildingPhoto,
  BuildingStageBadge,
  BuildingCurrentStatus,
  _replaceAbbreviations,
  SalesProgress,
  _getUnitFloorPlan,
  UnitCurrentStatus,
  _formatNameAddress,
} from "../../../../shared/helpers/utils";
import BuildingIcons from "../../../../shared/components/BuildingIcons";

export const ProjectGridView = ({ buildingRes, toggleModal }) => {
  return (
    <div className="catalog-items__wrap">
      <div className="catalog-items collection-card">
        {buildingRes.map((item, i) => (
          <CardBody
            className="catalog-item collection-card-item"
            key={i}
            onClick={() => toggleModal("building", item)}
          >
            <div className="catalog-item__img-wrap">
              <BuildingPhoto item={item} />
            </div>
            <StatusBadge building={item} />
            <div className="catalog-item__info">
              <h4 className="catalog-item__price">
                {item.name
                  ? _capitalizeText(item.name)
                  : _capitalizeText(
                      _replaceAbbreviations(item.display_full_street_address)
                    )}
                {item.building_name && (
                  <div>
                    {_capitalizeText(
                      _replaceAbbreviations(item.display_full_street_address)
                    )}
                  </div>
                )}
                <div
                  className="light-text small-text"
                  style={{ color: "#858AA1" }}
                >
                  {item.neighborhood && item.neighborhood[0]
                    ? `${_capitalizeText(item.neighborhood[0])}, `
                    : ""}{" "}
                  {_capitalizeText(item.borough)}
                </div>
              </h4>
              <h5 className="product-card__title">
                <div className="light-text">
                  <span className="light-text small-text">
                    {item.pipeline_zoning_area_residential > 0
                      ? _formatNumber(item.pipeline_zoning_area_residential) +
                        " Resi SF"
                      : ""}
                  </span>
                  {item.pipeline_zoning_area_residential > 0 &&
                    item.pipeline_total_construction_floor_area > 0 && (
                      <span className="small-divider">|</span>
                    )}
                  <span className="light-text small-text">
                    {item.pipeline_total_construction_floor_area > 0
                      ? _formatNumber(
                          item.pipeline_total_construction_floor_area
                        ) + " Total SF"
                      : ""}
                  </span>
                </div>
                <span className="light-text small-text">
                  {_formatNumber(item.pipeline_building_stories_proposed)}{" "}
                  Floors
                </span>
                {item.pipeline_dwelling_units_proposed > 0 && (
                  <Fragment>
                    <span className="small-divider">|</span>
                    <span className="light-text small-text">
                      {item.pipeline_dwelling_units_proposed > 0
                        ? _formatNumber(item.pipeline_dwelling_units_proposed)
                        : ""}{" "}
                      Units
                    </span>
                  </Fragment>
                )}
              </h5>
              {/* <div className="mt-2">
                {item.offering_construction === "conversion" ? (
                  <SalesProgress
                    label={"Units Sold"}
                    progress={parseFloat(
                      ((item.inventory_total - item.inventory_unsold) /
                        item.inventory_total) *
                        100
                    ).toFixed(0)}
                    inventory_unsold={
                      item.inventory_total - item.inventory_unsold
                    }
                    inventory_total={item.inventory_total}
                    color={"grey"}
                    maxWidth="85%"
                    size={"middle"}
                  />
                ) : item.building_status === "resale" ? (
                  <SalesProgress
                    label={"Units Sold"}
                    progress={parseFloat(item.inventory_pct_unsold).toFixed(0)}
                    inventory_unsold={item.inventory_unsold}
                    inventory_total={item.inventory_total}
                    min
                    color={"grey"}
                    resale={item.building_status === "resale"}
                    maxWidth="85%"
                    size={"middle"}
                  />
                ) : (
                  <SalesProgress
                    label={"Units Sold"}
                    progress={parseFloat(
                      ((item.inventory_total - item.inventory_unsold) /
                        item.inventory_total) *
                        100
                    ).toFixed(0)}
                    inventory_unsold={
                      item.inventory_total - item.inventory_unsold
                    }
                    inventory_total={item.inventory_total}
                    maxWidth="85%"
                    size={"middle"}
                  />
                )}
              </div> */}
              <div className="share__card_div">
                <span
                  className="light-text small-text"
                  style={{
                    color: "#858AA1",
                    textAlign: "center",
                    fontSize: 11,
                  }}
                >
                  Plans Filed{": "}
                  {item.pipeline_filing_date &&
                  moment(item.pipeline_filing_date).year() > 1970
                    ? _capitalizeText(
                        moment(item.pipeline_filing_date).fromNow()
                      )
                    : "--"}
                </span>
              </div>
            </div>
          </CardBody>
        ))}
      </div>
    </div>
  );
};

export const BuildingsGridView = ({ unitRes, toggleModal }) => {
  return (
    <div className="catalog-items__wrap">
      <div className="catalog-items collection-card">
        {unitRes.map((item, i) => {
          const { buildingNameOrAdr, adr, _boro, _hood } =
            _formatNameAddress(item);

          return (
            <CardBody
              className="catalog-item card__link collection-card-item"
              key={i}
              onClick={() => toggleModal("unit", item)}
            >
              <div className="catalog-item__img-wrap">
                <BuildingPhoto item={item} />
              </div>
              <StatusBadge building={item} />
              <div className="catalog-item__info">
                <h4 className="catalog-item__price">
                  {buildingNameOrAdr}
                  <div>
                    <BuildingIcons building={item} />
                  </div>

                  <div
                    className="light-text small-text"
                    style={{ color: "#858AA1" }}
                  >
                    {_capitalizeText(adr)}
                    {_hood ? `, ${_capitalizeText(_hood)}, ` : ""}{" "}
                    {_capitalizeText(_boro)}
                  </div>
                </h4>
                <h5 className="product-card__title">
                  <div className="light-text">
                    <span className="light-text small-text">
                      {item.pipeline_zoning_area_residential > 0
                        ? _formatNumber(item.pipeline_zoning_area_residential) +
                          " Resi SF"
                        : ""}
                    </span>
                    {item.pipeline_zoning_area_residential > 0 &&
                      item.pipeline_total_construction_floor_area > 0 && (
                        <span className="small-divider">|</span>
                      )}
                    <span className="light-text small-text">
                      {item.pipeline_total_construction_floor_area > 0
                        ? _formatNumber(
                            item.pipeline_total_construction_floor_area
                          ) + " Total SF"
                        : ""}
                    </span>
                  </div>
                  <span className="light-text small-text">
                    {_formatNumber(item.pipeline_building_stories_proposed)}{" "}
                    Floors
                  </span>
                  {item.pipeline_dwelling_units_proposed > 0 && (
                    <Fragment>
                      <span className="small-divider">|</span>
                      <span className="light-text small-text">
                        {item.pipeline_dwelling_units_proposed > 0
                          ? _formatNumber(item.pipeline_dwelling_units_proposed)
                          : ""}{" "}
                        Units
                      </span>
                    </Fragment>
                  )}
                </h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px 10px 20px 0px",
                  }}
                >
                  <span
                    className="light-text small-text"
                    style={{
                      color: "#858AA1",
                      textAlign: "center",
                      fontSize: 11,
                    }}
                  >
                    Plans Filed{": "}
                    {item.pipeline_filing_date &&
                    moment(item.pipeline_filing_date).year() > 1970
                      ? _capitalizeText(
                          moment(item.pipeline_filing_date).fromNow()
                        )
                      : "--"}
                  </span>
                </div>
              </div>
            </CardBody>
          );
        })}
      </div>
    </div>
  );
};
