/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Sector
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTooltipStyles, getDefaultChartColors } from '../../../../shared/helpers';
import StatIcon from 'mdi-react/MapSearchOutlineIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_formatBigNumber
} from '../../../../shared/helpers/utils';

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { right: 0 };
  return ({
	...left,
	width: 150,
	lineHeight: '24px',
	position: 'absolute',
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
	{
	  payload.map((entry, index) => (
		<li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
	  ))
	}
  </ul>
);

const RADIAN = Math.PI / 180; 
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, x, y, value, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const ix = cx + radius * Math.cos(-midAngle * RADIAN);
  const iy = cy  + radius * Math.sin(-midAngle * RADIAN);
  return (
	<Fragment>		
		<text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				<tspan fontWeight="bold">{`${_capitalizeText(name)}`}</tspan> 
				<tspan>{` (${_formatBigNumber(value)})`}</tspan> 
		</text>	 

	</Fragment>
  );
};

const renderActiveShape = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
	fill, payload, percent, value } = props;

  return (
	<g>
	  <Sector
		cx={cx}
		cy={cy}
		innerRadius={innerRadius}
		outerRadius={outerRadius+5}
		startAngle={startAngle}
		endAngle={endAngle}
		fill={fill}
	  />
	</g>
  );
};  

class BoroughMix extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	dir: PropTypes.string.isRequired,
	themeName: PropTypes.string.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  x: 0,
	  y: 0,
	  activeIndex: false
	};
  }

  onMouseMove = (e) => {
	const { dir } = this.props;
	if (e.tooltipPosition) {
	  this.setState({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
	}
  };
  
  _generateBoroughMixData = (facetData) => {
	 const { analysisType } = this.props;   
	 const boroughs = analysisType == "units" ? facetData.resi_by_borough : facetData.by_borough; 
	 const data = [];
	 const validBoros = ["manhattan", "brooklyn", "queens", "bronx", "staten island"];
	 
	 boroughs.map(boro => {
		 	if(boro["_id"] && validBoros.indexOf(boro["_id"])>=0){
				 let count = analysisType == "units" ? boro.units : analysisType == "sq_ft" ? boro.sq_ft : boro.count;
				 if(analysisType == "units"){
					 count -= boro.units_existing ? boro.units_existing : 0;
				 }
				 const boroData = {
					 name: boro["_id"],
					 value: count,
					 fill: getDefaultChartColors[data.length+1],
					 search : boro["_id"],
					 cursor: 'pointer'
				 
				 }
				 
				 data.push(boroData);
			 }
				
			
			//data.sort((a, b) => (a.count > b.count ? -1 : 1));  	
		 
	 })
	
	 
	 return data;
	  
  }
  
  handleClick(data, index) {
	  console.log(data, index);
	  
	  const { baseURL, history } = this.props;
	  window.location = `${baseURL}&borough=${data.search}`;
  }
  
  onPieEnter(data, index) {
	this.setState({
	  activeIndex: index,
	});
  }


  render() {
	const { t, dir, themeName, facetData, buildingsFetchInProgress, analysisType } = this.props;
	const { x, y } = this.state;
	
	if(!facetData){
		return null;
	}
	const title =  {
		  "count" : "Number of Projects by Borough",
		  "units" : "Residential Units by Borough",
		  "sq_ft" : "Total Square Feet by Borough"
	}
	const data = buildingsFetchInProgress ? [] :  this._generateBoroughMixData(facetData);
	return (
		<Card>
		  <CardBody className="dashboard__booking-card">
			<div className="dashboard__booking-total-container">
				  <h5 className="dashboard__booking-total-description"> {title[analysisType]}</h5>
				  <StatIcon className="dashboard__trend-icon" onClick={() => this.handleDownload()} />
			  </div>
			<div dir={dir}>
			  	<ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--commerce">
					<PieChart className="dashboard__chart-pie-container" style={{ cursor: "pointer" }}  height={80}>
					{/*  <Tooltip position={{ x, y }} {...getTooltipStyles(themeName)} onClick={this.handleClick.bind(this)} /> */}
				  	<Pie
						data={data}
						activeIndex={this.state.activeIndex}
						activeShape={renderActiveShape}
						dataKey="value"
						cy={130}
						isAnimationActive={false}
						innerRadius={30}
						outerRadius={90}
						label={renderCustomizedLabel}
						onMouseEnter={this.onPieEnter.bind(this)}
						onClick={this.handleClick.bind(this)}
				  	/>
				  	{/*<Legend layout="horizontal" verticalAlign="bottom" wrapperStyle={style(dir)} content={renderLegend} />*/}
					</PieChart>
			  	</ResponsiveContainer>
			</div>
		</CardBody>
		</Card>
	);
  }
}

export default connect(state => ({ themeName: state.theme.className }))(BoroughMix);
