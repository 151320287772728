import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/TagOutlineIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";

const AveragePPSF = (props) => {
	

		return (
			 <Col md={12} xl={4} lg={6} xs={12}>
			    <Card>
			    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
			      <CardBody className="dashboard__booking-card card__link">
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            ${props.statsRes && props.statsRes.ppsf && props.statsRes.ppsf.avg_ppsf ? 
				            _formatNumber(props.statsRes.ppsf.avg_ppsf) 
				            : props.buildingRes.inventory_stats_listings_average_ppsf && props.buildingRes.inventory_stats_listings_average_ppsf > 0 ? _formatNumber(props.buildingRes.inventory_stats_listings_average_ppsf)
				            : '--'}
			          </h5>
			          <TagOutlineIcon className="dashboard__trend-icon" />
			        </div>
			        <h5 className="dashboard__booking-total-description">Avg Asking PPSF</h5>
			      </CardBody>
			    </Link>
			    </Card>
			  </Col>
		)
		
	
	 	
		
	
};

export default AveragePPSF;
