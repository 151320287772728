import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import MenuIcon from "mdi-react/MenuIcon";
import Alert from "../../../shared/components/Alert";
import AlertIcon from "mdi-react/InformationOutlineIcon";
import AdvancedFilertIcon from "mdi-react/FilterVariantIcon";
import HelpCircleOutlineIcon from "mdi-react/HelpCircleOutlineIcon";
import Button from "@material-ui/core/Button";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import DoneIcon from "mdi-react/DoneIcon";
import {
  _capitalizeText,
  _formatPrice,
  buildingStageToolTips,
  _formatNumber,
} from "../../../shared/helpers/utils";
import { isNumber } from "lodash";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 130,
    maxWidth: 300,
    fontSize: "12px",
  },
  formControlPop: {
    margin: "6px",
    minWidth: 150,
    fontSize: "12px",
    "& .MuiTextField-root": {
      marginBottom: "10px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      paddingLeft: "10px",
    },
    "& .MuiInput-input": {
      fontSize: "14px",
      paddingLeft: "10px",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "10px",
    },
    "& .MuiMenuItem-root": {
      paddingTop: "3px",
      paddingBottom: "3px",
      minHeight: 0,
    },
  },
  formLabel: {
    fontSize: "14px",
  },
  menuItem: {
    fontSize: "14px",
  },
  listItemText: {
    fontSize: "12px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width: 120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer: {
    padding: "5px 10px 10px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiMenuProps = {
  PaperProps: {
    style: {
      marginTop: 0,
    },
  },
  InputProps: {
    style: {
      fontSize: "12px",
    },
  },
};

export default function PriceFilter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, filter, _filters, setSubFilters } = props;
  const [minPrice, setMinPrice] = useState(
    subFilters[filter.search_key[0]] ? subFilters[filter.search_key[0]] : false
  );
  const [maxPrice, setMaxPrice] = useState(
    subFilters[filter.search_key[1]] ? subFilters[filter.search_key[1]] : false
  );

  const [minPriceFocus, setMinPriceFocus] = useState(true);
  const [maxPriceFocus, setMaxPriceFocus] = useState(false);

  const [minMaxValues, setMinMaxValues] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    if (event.target.name === "min-price") {
      setMinPrice(parseInt(event.target.value.toString().replace(/\D/g, "")));
    } else if (event.target.name === "max-price") {
      setMaxPrice(parseInt(event.target.value.toString().replace(/\D/g, "")));
    }
  };
  const handleChangeMultiple = (min, max) => {
    setOpen(false);
    let prices = {};

    if (max < min) {
      max = 0;
    }
    else if (max> 0 && isNumber(max) && !min) {
      min = 0;
    }
    prices[filter.search_key[0]] = min;
    prices[filter.search_key[1]] = max;

    setSubFilters(prices);
  };

  const handleFocus = (type) => {
    if (type === "min-price") {
      setMinPriceFocus(true);
      setMaxPriceFocus(false);
    } else if (type === "max-price") {
      setMinPriceFocus(false);
      setMaxPriceFocus(true);
    }
  };

  const handleSetMinPrice = (min) => {
    min = parseInt(min);
    if (min <= parseInt(maxPrice) || !maxPrice) {
      setMinPrice(min);
    }
  };

  const handleSetMaxPrice = (max) => {
    max = parseInt(max);
    if (max >= parseInt(minPrice)) {
      setMaxPrice(max);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleKeyDown = (e) => {
    //console.log(e.key, e.keyCode, e.target.name )
    if (e.key === "Enter") {
      handleChangeMultiple(minPrice, maxPrice);
    }
    /*
    var TABKEY = 9;
    
    if(e.keyCode == TABKEY && e.target.name === 'min-price') {
       handleFocus("max-price");
    }
*/
  };

  const setTitle = () => {
    let title = filter.name;
    if (minPrice > 0 || maxPrice > 0) {
      if (minPrice > 0) {
        title = formatValue(minPrice);
      } else {
        title = "Any";
      }
      title += " - ";

      if (maxPrice > 0) {
        title += formatValue(maxPrice);
      } else {
        title += "Any";
      }
      if (filter.title_suffix) {
        title += ` ${filter.title_suffix}`;
      }
    }

    return title;
  };

  const constructPrice = (start, isMax) => {
    const priceArray = [];

    if (
      filter.custom_min_max_values &&
      filter.custom_min_max_values.min &&
      filter.custom_min_max_values.max
    ) {
      /*
		   let val = start ? parseInt(start) : filter.custom_min_max_values.min[0];
		   let max = filter.custom_min_max_values.min[filter.custom_min_max_values.min.length-1];
*/
      if (isMax) {
        filter.custom_min_max_values.max.map((v) => {
          if (v > start) {
            priceArray.push(v);
          }
        });
      } else {
        filter.custom_min_max_values.min.map((v) => {
          priceArray.push(v);
        });
      }

      return priceArray;
    }

    const filter_min =
      filter.min_max_values && filter.min_max_values.length > 1
        ? filter.min_max_values[0]
        : 250000;
    const filter_max =
      filter.min_max_values && filter.min_max_values.length > 1
        ? filter.min_max_values[1]
        : 4000000;
    const increment = filter.min_max_increment
      ? filter.min_max_increment
      : 250000;

    let val = start ? parseInt(start) : filter_min;
    const max = isMax ? val + filter_max : filter_max;

    while (val <= max) {
      priceArray.push(val);
      if (val < 1000000) {
        val += increment;
      } else if (val < 3000000) {
        val += 500000;
      } else {
        val += 1000000;
      }
    }

    if (!isMax) {
      priceArray.unshift(0);
    } else {
      priceArray.push(0);
    }

    return priceArray;
  };

  const formatValue = (value, isText) => {
    let returnVal;
    let valuePrefix =
      filter.value_prefix !== undefined ? filter.value_prefix : "$";
    let valueSuffix =
      filter.value_suffix !== undefined ? filter.value_suffix : "";
    if (isText || (filter.format_type && filter.format_type == "number")) {
      returnVal =
        value > 0
          ? valuePrefix + _formatNumber(value) + valueSuffix
          : valuePrefix + "0" + valueSuffix;
    } else {
      returnVal = value > 0 ? _formatPrice(value) : valuePrefix + "0";
    }
    return returnVal;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id={`${filter.search_key}_filter-label`}
        className={classes.formLabel}
      >
        {minPrice || maxPrice ? filter.name : setTitle()}
      </InputLabel>
      <Select
        labelId={`${filter.search_key}-label`}
        id={`${filter.search_key}`}
        name={filter.search_key}
        value={minPrice || maxPrice ? [setTitle()] : []}
        onClose={() => {
          handleChangeMultiple(minPrice, maxPrice);
        }}
        open={open}
        onOpen={handleOpen}
        //onBlur={(e)=> {e.stopPropagation();e.preventDefault();}}
        input={<Input id={`${filter.search_key}`} />}
        renderValue={(selected) => <div>{selected}</div>}
        multiple
        MenuProps={MultiMenuProps}
        className={classes.menuItem}
      >
        <div></div>
        <FormControl className={classes.formControlPop}>
          <TextField
            id="min-price"
            label={
              filter.labels && filter.labels.length > 1
                ? filter.labels[0]
                : "Min Price"
            }
            onFocus={() => {
              handleFocus("min-price");
            }}
            name="min-price"
            value={minPrice ? formatValue(minPrice, true) : ""}
            onChange={handleChange}
            autoComplete={"off"}
            autoFocus
            onKeyDown={handleKeyDown}
          />
          {minPriceFocus &&
            constructPrice().map((val) => (
              <MenuItem
                key={val}
                value={val}
                className={classes.menuItem}
                onClick={() => {
                  handleSetMinPrice(val);
                  handleFocus("max-price");
                }}
              >
                {val > 0 ? formatValue(val) + "+" : formatValue(0) + "+"}
              </MenuItem>
            ))}
        </FormControl>
        <FormControl className={classes.formControlPop}>
          <TextField
            id="max-price"
            label={
              filter.labels && filter.labels.length > 1
                ? filter.labels[1]
                : "Max Price"
            }
            name="max-price"
            onFocus={() => {
              handleFocus("max-price");
            }}
            value={maxPrice ? formatValue(maxPrice, true) : ""}
            onChange={handleChange}
            autoComplete={"off"}
            onKeyDown={handleKeyDown}
          />
          {maxPriceFocus &&
            constructPrice(minPrice, true).map((val) => (
              <MenuItem
                key={val}
                value={val}
                className={classes.menuItem}
                onClick={() => {
                  handleSetMaxPrice(val);
                  handleChangeMultiple(minPrice, val);
                }}
              >
                {val > 0 ? formatValue(val) : "No Max"}
              </MenuItem>
            ))}
        </FormControl>
        <hr style={{ marginTop: "0px" }} />
        <div style={{ padding: "0px 16px 10px", textAlign: "right" }}>
          <Tooltip title={`Reset price filter`}>
            <Button
              onClick={() => {
                setMinPrice("");
                setMaxPrice("");
              }}
              size="small"
              style={{ marginRight: 5 }}
            >
              {" "}
              Reset
            </Button>
          </Tooltip>
          <Tooltip title={`Apply price filter`}>
            <Button
              onClick={() => {
                handleChangeMultiple(minPrice, maxPrice);
              }}
              variant="outlined"
              size="small"
            >
              {" "}
              Apply
            </Button>
          </Tooltip>
        </div>
      </Select>
    </FormControl>
  );
}
