import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

let rows = [
  {
    id: 'project_count', disablePadding: false, label: 'Projects',
  },
  {
    id: 'name', disablePadding: false, label: 'Company',
  },
  {
    id: 'project_roles', disablePadding: false, label: 'Category / Role',
  },
  {
    id: 'address', disablePadding: false, label: 'Location',
  },
  {
    id: 'date_added', disablePadding: false, label: 'Date Added',
  },
  
  
 
];

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, user, type
    } = this.props;
    
    let filteredRows = (user && user.role.indexOf('admin')>=0) ?  rows : rows.filter(item => !item.admin_only);
    if(type === "commercial"){
		filteredRows = rows.filter(filter => !(filter.type && filter.type === 'residential'));
	}else{
		filteredRows = rows.filter(filter => !(filter.type && filter.type === 'commercial'));
	}
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/*<Checkbox
              className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />*/}
          </TableCell>
          {filteredRows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
                className="material-table__sort-label"
                dir="ltr"
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(MatTableHead);
