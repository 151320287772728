import React, { Fragment } from 'react';

import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import EditProject from '../../shared/components/edit/EditProject';

import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

import MatTable from './components/MatTable';
import MatAppBar from '../../shared/components/building_filters/UnitsSearchAppBar';



import{
	_capitalizeText,
} from '../../shared/helpers/utils';

const Buildings = ( {user, match } ) => {
	  const type = match && match.params.type ? match.params.type : 'residential';

	  return(
		  <Container>
		   {/* <Row className="map-header">
		      <Col md={12}>
		        <h3 className="page-title">Pipeline Map</h3>
		       {!isMobile && <h3 className="page-subhead subhead">Map of New Construction Projects</h3> }
		       
		       
		      </Col>
		    </Row>*/}
		    {_hasPermission(user, 'pipeline-map') ?
			    <Fragment>
				    
				      <MatTable />
			    </Fragment>
			    :
			     <Row>
				    <EnterpriseGate />
				 </Row>
		    }
		  </Container>
		  )

};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(withRouter(Buildings)));

