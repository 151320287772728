import React, { PureComponent, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Progress,
  Badge,
  Row,
  Container,
} from "reactstrap";
import { connect } from "react-redux";

import UnitsSearchTableHead from "./UnitsSearchTableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";

import LinearLoading from "../../../shared/components/LinearLoading";
import Button from "@material-ui/core/Button";
import LoadMoreIcon from "mdi-react/RefreshIcon";
import LoadingIcon from "mdi-react/DotsHorizontalIcon";

import CharityIcon from "mdi-react/CharityIcon";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";

import AlertIcon from "mdi-react/InformationOutlineIcon";
import UnitIcons from "../../../shared/components/UnitIcons";
import TrendDownIcon from "mdi-react/TrendingDownIcon";
import TrendUpIcon from "mdi-react/TrendingUpIcon";

import EnterpriseGate from "../../../shared/components/payment/EnterpriseGate";
import { _hasPermission } from "../../../shared/helpers/access";

import PermitDetails from "../../../shared/components/PermitDetails";
import PopupModal from "./PopupModal";


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _objectEqual,
  StatusBadge,
  _getUnitFloorPlan,
  UnitCurrentStatus,
  _formatOwnerName,
  jobTypes,
  workTypes,
  jobTypeList,
  permitteeTypes,
  _makePermitsUrl,
  applicantTypes
} from "../../../shared/helpers/utils";

import { _axiosCall } from "../../../shared/helpers/apicalls";
const buildingUrl = process.env.API_ROOT + "/query/";

class UnitsListTable extends PureComponent {
  state = {
    order: "",
    orderBy: "",
    selected: new Map([]),
    page: 0,
    rowsPerPage: 20,
    fetchInProgress: false,
    buildingKey: false,
    unitListData: [],
    start: 0,
    noMoreResults: false,
    modal: false,
    permit: null,
  };

  componentDidMount() {
    if (this.props.buildingKey) {
      this.queryUnits();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.buildingKey) {
      if (
        !_objectEqual(this.props.q, prevProps.q) ||
        this.props.buildingKey !== prevProps.buildingKey ||
        this.state.orderBy !== prevState.orderBy ||
        this.state.order !== prevState.order ||
        this.state.start !== prevState.start
      ) {
        const reset =
          this.state.orderBy !== prevState.orderBy ||
          this.state.order !== prevState.order;
        this.queryUnits(reset);
      }
    }
  }

  _loadMore = () => {
    if (this.state.fetchInProgress === true) {
      return false;
    }
    this.setState({ start: this.state.start + this.state.rowsPerPage });
  };

  _handleRequestUnitsSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    console.log(event, property);
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy, start: 0 });
  };
  
  _toggleModal = (permit) => {
    const { modal } = this.state;
    if(modal){
      this.setState({permit : null, modal : !modal})
    }else{
      this.setState({permit : permit, modal : !modal})
    }
   
  }

  queryUnits = async (reset) => {
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const { buildingKey, q, fq, fl } = this.props;
    const { orderBy, order } = this.state;

    const isContract =
      this.props.subFilters.offering_status &&
      this.props.subFilters.offering_status === "in-contract";

    let sort = "floor asc, address_2_normalized asc";
    if (this.state.orderBy && this.state.orderBy.length > 0) {
      if (orderBy === "address_2_normalized") {
        sort = `floor ${order}, ${orderBy} ${order}`;
      } else if (orderBy === "offering_price") {
        sort = `last_listed_sale_price_all ${order}, ${orderBy} ${order}`;
      } else if (orderBy === "last_sold_date") {
        if (isContract) {
          sort = `last_contract_date ${order}`;
        } else {
          sort = `${orderBy} ${order}, last_contract_date ${order}`;
        }
      } else {
        sort = `${orderBy} ${order}`;
      }
    }

    const unitsFq = [...fq, `building:${buildingKey}`];
    let axiosData = {
      url: buildingUrl + "we3-properties?cache=60",
      method: "post",
      query: {
        q: q,
        fq: unitsFq,
        sort: sort,
        fl,
        wt: "json",
        rows: this.state.rowsPerPage,
        start: this.state.start,
      },
    };

    let response = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length > 0) {
          const newUnitListData = reset
            ? res.docs
            : [...this.state.unitListData, ...res.docs];
          this.setState({
            unitListData: newUnitListData,
            fetchInProgress: false,
            buildingKey: false,
          });
        } else if (res.docs.length === 0) {
          this.setState({
            noMoreResults: true,
            fetchInProgress: false,
            buildingKey: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  render() {
    const {
      reactTableFiltered,
      handleBuildingClick,
      isSelected,
      handleClick,
      order,
      orderBy,
      selected,
      emptyRows,
      handleSelectAllClick,
      handleRequestSort,
      isGrouped,
      user,
      building,
      buildingView
    } = this.props;
    const {
      modal,
      permit
    } = this.state;
    if (
      !reactTableFiltered &&
      this.state.start === 0 &&
      (this.props.fetchInProgress)
    ) {
      return <LinearLoading />;
    }

    const data = reactTableFiltered || this.state.unitListData;

    if (data.length <= 0) {
      return (
        <Col md={12}>
          <Card>
            <CardBody className={`small`}>
              <div className={`email-confirmation`}>
                <div className="email-confirmation__icon">
                  <AlertIcon size={50} color={`#D6BAE8`} />
                </div>
                <h3 className="email-confirmation__title">No Permits Found</h3>
                <p className="email-confirmation__sub"></p>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return (
      <Fragment>
        <div>
          <Table className="material-table">
            <UnitsSearchTableHead
              numSelected={[...selected].filter((el) => el[1]).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={
                isGrouped ? this._handleRequestUnitsSort : handleRequestSort
              }
              rowCount={reactTableFiltered.length}
            />
            <TableBody>
              {data.map((d) => {
                const itemSelected = isSelected(d.id);
                
                const isPersonalOwner = (!d.owner_business_name || d.owner_business_name == "" || d.owner_business_name == "pr" || d.owner_business_name == "n/a")
               
                return (
                  <TableRow
                    className="material-table__row"
                    role="checkbox"
                    onClick={(event) => this._toggleModal(d)}
                    aria-checked={itemSelected}
                    tabIndex={-1}
                    key={d.id}
                    selected={itemSelected}
                  >
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >{d.issuance_date || d.job_start_date ?
                      <span className="">
                        Permit Issuance
                      </span>
                      :
                      <span className="">
                        Job Filing
                      </span>
                      }
                      <br />
                     <span className={"light-text small-text"}>
                       {_makePermitsUrl(d)}
                     </span>
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >{d.filing_date ?
                      <span className="">
                      
                        {moment(
                          d.filing_date.split("T")[0],
                          "YYYY-MM-DD"
                        ).format("MM/DD/YYYY")}
                      </span>  
                      : d.pre_filing_date ?
                      <span className="">
                      
                        {moment(
                          d.pre_filing_date.split("T")[0],
                          "YYYY-MM-DD"
                        ).format("MM/DD/YYYY")}
                      </span> 
                      :<span className="">--</span>
                      }
                      <br />
                     
                    </TableCell>
                  
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >{d.issuance_date ?
                      <span className="">
                      
                        {moment(
                          d.issuance_date.split("T")[0],
                          "YYYY-MM-DD"
                        ).format("MM/DD/YYYY")}
                      </span>
                     : d.job_start_date ?
                      <span className="">
                      
                        {moment(
                          d.job_start_date.split("T")[0],
                          "YYYY-MM-DD"
                        ).format("MM/DD/YYYY")}
                      </span>
                      :
                       <span className="">--</span>}
                      <br />
                      
                    </TableCell>
                    
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >
                      <span className="" style={{maxWidth:280, whiteSpace:"wrap"}}>
                       {d.job_type && jobTypes[d.job_type]
                       ? _capitalizeText(jobTypes[d.job_type])
                       : d.job_type}
                      </span>
                      <br />
                      {d.issuance_date ? 
                      <span
                        className="light-text small-text"
                       
                      >
                      ({d.filing_status == "initial" ? "Issuance - Initial" : "Issuance - " + _capitalizeText(d.filing_status)})
                      </span>
                      : d.job_status_description ?
                      <span
                        className="light-text small-text"
                       
                      >
                      (Filing Status - {_capitalizeText(d.job_status_description)})
                      </span>
                      : null
                    }
                    </TableCell>
                   <TableCell
                     className="material-table__cell material-table__cell-right"
                     size="small"
                   >
                     <span className="" style={{maxWidth:280, whiteSpace:"wrap"}}>
                     {d.work_type ?
                       <Fragment>
                      {d.work_type &&
                      d.work_type
                        .split("/")
                        .filter((wt) => workTypes[wt]).length > 0 ? (
                        <span className={""}>
                          {d.work_type
                            .split("/")
                            .map((wt) =>
                              _capitalizeText(workTypes[wt])
                            )
                            .join(", ")}
                        </span>
                      ) : (
                        <span className={""}>
                          {_capitalizeText(d.work_type)}
                        </span>
                      )}
                       </Fragment>
                       :
                       <span className={""}>--</span>
                     }
                     </span>
                   </TableCell>

                    <TableCell
                      className="material-table__cell material-table__cell-right nowrap"
                      component="td"
                      scope="row"
                      padding=""
                    >
                      
                        <div className="icon__floorplan">
                          <span>
                            {_capitalizeText(
                                  _replaceAbbreviations(
                                    d.display_full_street_address
                                      ? d.display_full_street_address
                                      : d.full_street_address
                                  )
                                )}
                          </span>
                          <br />
                          <span
                            className="light-text small-text"
                            style={{ color: "#858AA1" }}
                          >
                            {d.neighborhood && d.neighborhood[0]
                              ? `${_capitalizeText(d.neighborhood[0])}, `
                              : ""}{" "}
                            {_capitalizeText(d.borough)}
                          </span>
                        </div>
                    
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >
                      <span className="">
                        {d.applicant_business_name && <Fragment>{_capitalizeText(d.applicant_business_name)}</Fragment> }
                        
                      </span>
                      {d.applicant_license_type &&
                      <span
                        className="light-text small-text"
                       
                      ><br/>
                      { applicantTypes[d.applicant_license_type] ? applicantTypes[d.applicant_license_type] : d.applicant_license_type ? d.applicant_license_type.toString().toUpperCase() : "--"}
                      </span>
                      }
                      {d.applicant_first_name && <span className="light-text small-text" style={{ color: "#858AA1" }}><br/>{_capitalizeText(d.applicant_first_name)} {_capitalizeText(d.applicant_last_name)}</span> }
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >
                      <span className="">
                        {d.permittee_business_name && <Fragment>{_capitalizeText(d.permittee_business_name)}<br/></Fragment> }
                        
                      </span>
                    
                      <span
                        className="light-text small-text"
                       
                      >
                      { permitteeTypes[d.permittee_license_type] ? permitteeTypes[d.permittee_license_type] : d.permittee_license_type ? d.permittee_license_type.toString().toUpperCase() : "--"}
                      </span>
                      {d.permittee_first_name && <span className="light-text small-text" style={{ color: "#858AA1" }}><br/>{_capitalizeText(d.permittee_first_name)} {_capitalizeText(d.permittee_last_name)}</span> }
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >
                      <span className="">
                        {<Fragment>{(isPersonalOwner) ? _capitalizeText(d.owner_first_name) + " " + _capitalizeText(d.owner_last_name): _capitalizeText(d.owner_business_name)}<br/></Fragment> }
                        {d.owner_first_name && !(isPersonalOwner) ? <span className="light-text small-text">{_capitalizeText(d.owner_first_name)} {_capitalizeText(d.owner_last_name)}<br/></span> : <span className="light-text small-text"></span> }
                      </span>
                    
                     
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right"
                      size="small"
                    >
                      <span className="">
                        {d.filing_applicant_business_name && <Fragment>{_capitalizeText(d.filing_applicant_business_name)}</Fragment> }
                        
                      </span>
                      {d.filing_applicant_license_type && d.filing_applicant_license_type != "" &&
                      <span
                        className="light-text small-text"
                       
                      ><br/>
                      { applicantTypes[d.filing_applicant_license_type] ? applicantTypes[d.filing_applicant_license_type] : d.filing_applicant_license_type ? d.filing_applicant_license_type.toString().toUpperCase() : "--"}
                      </span>
                      }
                      {d.filing_applicant_first_name && <span className="light-text small-text" style={{ color: "#858AA1" }}><br/>{_capitalizeText(d.filing_applicant_first_name)} {_capitalizeText(d.filing_applicant_last_name)}</span> }
                    </TableCell>
                   
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {!reactTableFiltered && (
          <div className="unit__load">
            <Tooltip title="Load more units">
              <Button
                onClick={(e) => {
                  this._loadMore(e);
                }}
                variant="contained"
                color="news"
                className={`timeline__load-more`}
                startIcon={<LoadMoreIcon />}
                disabled={
                  this.state.fetchInProgress || this.state.noMoreResults
                }
              >
                {" "}
                {`${
                  this.state.fetchInProgress
                    ? "Loading..."
                    : this.state.noMoreResults
                    ? "No More Units"
                    : "Load More"
                }`}
              </Button>
            </Tooltip>
          </div>
        )}
        <PopupModal toggle={this._toggleModal} modal={modal} data={permit} />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.user,
}))(UnitsListTable);
