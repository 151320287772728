import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';
import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteOutlineIcon';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import HideIcon from 'mdi-react/EyeOffIcon';
import UnHideIcon from 'mdi-react/EyeIcon';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Form from "@rjsf/material-ui";
import LayoutField from './components/LayoutField';
import ImageUploadWidget from './components/ImageUploadWidget'

import EditFeedItem from './components/EditFeedItem'

import { Publisher } from './../../pubsub/publisher.js';
import { _hasPermission	} from "../../../shared/helpers/access";

import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	EstimateProjectStage
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT; //'http://localhost:8000/buyerslist/';


const CreateGuid = () => {  
   function _p8(s) {  
      var p = (Math.random().toString(16)+"000000000").substr(2,8);  
      return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
   }  
   return _p8() + _p8(true) + _p8(true) + _p8();  
}  


export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}

const cleanFormData = (formData) => {
	let cleanData = Object.assign({}, formData);
	
	Object.keys(cleanData).map(key => {
		if(cleanData[key] === -1){
			cleanData[key] = null;
		}
	});
	
	
	if(cleanData['time']){
		cleanData['time'] = moment(cleanData['time']).format('YYYY-MM-DD')
	}
		
	
	return cleanData;
}

const prepareFormDataForSave = (formData, buildingRes, add) => {
	let cleanData = {
		'project_updates' : []
	}
	let now = moment();
	let saveFields = ["id", "title", "excerpt", "article", "project_update_author", "time", "main_photo"];
	
	let saveFormData = Object.assign({}, formData);
	
	Object.keys(saveFormData).map(key => {
		if(saveFields.indexOf(key) < 0){
			delete saveFormData[key];
		}
	});
	
	if(saveFormData['time']){
		
		let dateString = moment(saveFormData['time']).toISOString();
		if(add){
			let theDate = moment(saveFormData['time']);
			theDate.set({
				hour:now.hour(),
				minute: now.minutes()
			});
			dateString = theDate.toISOString();
		}
		
		saveFormData['time'] = dateString;
	}else{
		saveFormData['time'] = now.toISOString();
	}
	
	if(saveFormData.main_photo && saveFormData.main_photo[0] && saveFormData.main_photo[0].preview){
		saveFormData.main_photo = saveFormData.main_photo[0].preview;
	}
	
	if(buildingRes['project_updates'] && Array.isArray(buildingRes['project_updates'])){
		
		cleanData['project_updates'] = buildingRes['project_updates'].slice();
		if(saveFormData.id){
			let found = false;
			cleanData['project_updates'].map(update => {
				if(update.id == saveFormData.id){
					update = Object.assign(update, saveFormData);
					found = true;
				}
			});
			
			if(!found){
				cleanData['project_updates'].push(saveFormData);
			}
		}else{
			saveFormData['id'] = CreateGuid();
			cleanData['project_updates'].push(saveFormData);
		}
		
	}else{
		if(saveFormData.id){
			cleanData['project_updates'].push(saveFormData);
		}else{
			saveFormData['id'] = CreateGuid();
			cleanData['project_updates'].push(saveFormData);
		}
	}
	
	
	return cleanData;
}

const prepareFormDataForHide = (formData, buildingRes, unHide) => {
	let cleanData = {
		'project_updates' : []
	}
	
	let saveFields = ["id"];
	
	let saveFormData = Object.assign({}, formData);
	
	Object.keys(saveFormData).map(key => {
		if(saveFields.indexOf(key) < 0){
			delete saveFormData[key];
		}
	});
	
	saveFormData['hide'] = unHide ? false : true;
	
	console.log(saveFormData);
	if(buildingRes['project_updates'] && Array.isArray(buildingRes['project_updates'])){
		
		cleanData['project_updates'] = buildingRes['project_updates'].slice();
		if(saveFormData.id){
			let found = false;
			cleanData['project_updates'].map(update => {
				if(update.id == saveFormData.id){
					update['hide'] = unHide ? false : true;
					found = true;
					console.log(update, saveFormData)
				}
			});
			
			if(!found){
				cleanData['project_updates'].push(saveFormData);
			}
		}
		
	}else{
		if(saveFormData.id){
			cleanData['project_updates'].push(saveFormData);
		}
	}
/*
	cleanData['project_updates'] = cleanData['project_updates'].filter((thing, index, self) =>
		index === self.findIndex((t) => (
	    t.id === thing.id
	)) )
*/

	
	return cleanData;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
    width: "100%"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth:180
  },
});


class EditFeed extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: 'Update Project',
	    modal: false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
	    formData : cleanFormData(this.props.item),
	    activeTab: 'profile',
	    value: 0,
	    justDeleted: false
	    
	};
	this.toggle = this.toggle.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	this.onChange = this.onChange.bind(this);
	this.saveProject = this.saveProject.bind(this);
	this.deleteProject = this.deleteProject.bind(this);
    
  }
  
  saveProject(){           
      const { authState, authService, dispatch, user, setBuilding, buildingRes, setChanged, snackOpen, add } = this.props;	
      const { formData } = this.state;
      
	  const user_id = user.id;
	  const user_email = user.email;
	  
	  let saveData = prepareFormDataForSave(formData, buildingRes, add);	  
	  let meta = {
		  'source_namespace' : buildingRes.meta && buildingRes.meta.source_namespace ? buildingRes.meta.source_namespace : 'MARKETPROOF-PIPELINE-APP',
		  'user_id' : user_id,
		  'user_email' : user_email
		  
	  }
	  if(saveData['meta']){
		  saveData['meta'] = Object.assign({}, saveData['meta'], meta)
	  }else{
		  saveData['meta'] = meta;
	  }
	  
	  saveData['key'] = buildingRes['key'];
	  
	  console.log(saveData);
	  
	//  return false;
	  let userData = {
			url: apiUrl + '/pipeline-projects/',
			method: "post",
			query: {
				"pipeline_project": JSON.stringify(saveData)
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.success){
		      
		      if(res.message && res.message == "no changes found"){
			      console.log("snackOpen('Success! Project news has been saved.'); ")
			    snackOpen('Success! Project news has been saved.'); 
		      }else{
			      			      console.log("snackOpen('Success! Project news has been saved.'); ")

			    snackOpen('Success! Project news has been saved.');
			  	
		      }
			  setTimeout(() => { 
				  if(add){
					  setBuilding();
				  }else{
					  setChanged();
				  }
			  }, 3000);	
		  }else{
			  if(!res.success){
				snackOpen(<Fragment><span>Oops, Your Profile Was Not Updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>Oops, Something went wrong.  {_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				snackOpen('Oops, something went wrong, project was not saved.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    snackOpen('Oops, something went wrong, project was not saved.', 'error');
	  });
	}
	
	deleteProject(item, unHide){           
      const { authState, authService, dispatch, user, setChanged, buildingRes, setBuilding, snackOpen } = this.props;	
	  const user_id = user.id;
	  const user_email = user.email;
	  let saveData = prepareFormDataForHide(item, buildingRes, unHide);
	  
	  let meta = {
		  'source_namespace' : buildingRes.meta && buildingRes.meta.source_namespace ? buildingRes.meta.source_namespace : 'MARKETPROOF-PIPELINE-APP',
		  'user_id' : user_id,
		  'user_email' : user_email
		  
	  }
	  
	  if(saveData['meta']){
		  saveData['meta'] = Object.assign({}, saveData['meta'], meta)
	  }else{
		  saveData['meta'] = meta;
	  }
	  saveData['key'] = buildingRes['key'];
	  saveData['_id'] = buildingRes['_id'];
	  console.log(saveData);
	  
	  //return false;
	  let userData = {
			url: apiUrl + '/pipeline-projects/', //"http://52.70.164.132:8001/pipeline-projects/delete",
			method: "post",
			query: {
				"pipeline_project": JSON.stringify(saveData)
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.success){
		      
		      if(res.message && res.message == "no changes found"){
			    snackOpen(`Success! Project news has been ${unHide ? "unhidden" : "hidden"}.`); 
		      }else{
			    snackOpen(`Success! Project news has been ${unHide ? "unhidden" : "hidden"}.`); 
			  	
		      }
		      setTimeout(() => { setBuilding();setChanged(); }, 3000);
			 	
		  }else{
			  if(!res.success){
				snackOpen(<Fragment><span>Oops, Your Profile Was Not Updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>Oops, Something went wrong.  {_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				snackOpen('Oops, something went wrong, request not saved.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    snackOpen('Oops, something went wrong, request not saved.', 'error');
	  });
	}
   
  	
	handleChange = (event, newValue)  => {
	 	this.setState( { value: newValue } )
  	};

	
  toggle() {
	//snackOpen('Your Message Has Been Sent!', 'success');
	const { setBuilding, del } = this.props;	
/*
	if(this.state.modal && !del){
		if(setBuilding && typeof setBuilding === 'function'){
		  	setBuilding();
	  	} 
	}
*/
	
    this.setState(prevState => ({ modal: !prevState.modal }));
    
  }
  
  onChange = ({formData}, e) => {
	  let newObj = Object.assign({}, formData);
	  
	 
	  //console.log("on change")
	  

	  this.setState({ formData : newObj });
  }
  onSubmit = ({formData}, e) => {
	 // this.setState({formData : formData });
	  
	  this.saveProject();
  }
  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, listing, classes, add, del, item
    } = this.props;
    
    if(!_hasPermission(user, "admin-tools")) return null;
    
    const { modal, snackShow, snackMessage, snackType, formData, value, justDeleted} = this.state;
    
    
    
 console.log("render")   
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const hood = buildingRes.neighborhood ? _capitalizeText(buildingRes.neighborhood[0])+',' : '';
	
	const unitKey = property ? property.key : false;
	const buildingKey = buildingRes.key;
	
	const saved = unitKey ? 
    			savedUnits.findIndex(item => (item.key === unitKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 
    			: 
    			savedBuildings.findIndex(item => (item.key === buildingKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
    
    		
    
    return (
	    <Fragment>
	    					
			{add ? 
				
			  	<ButtonGroup size="small" aria-label="small outlined button group">
			    	<Tooltip title={`Add news item to this project (Admin Only)`}>
			        	<Button onClick={this.toggle} startIcon={<AddIcon size={20} />}>Add News</Button>
			        </Tooltip>
			        
			    </ButtonGroup>

		  	:
			    <ButtonGroup size="small" aria-label="small outlined button group">
			    	<Tooltip title={`Edit this news item (Admin Only)`}>
			        	<Button onClick={this.toggle} startIcon={<EditIcon size={11} />}>Edit</Button>
			        </Tooltip>
			       
			        	{item.hide ?
				        	<Tooltip title={`Unhide this news item from appearing (Admin Only)`}>
				        		<Button onClick={() => { this.deleteProject(item, true)}} startIcon={<UnHideIcon size={11} />}>Unhide</Button>
				        	</Tooltip>
				        	:
				        	<Tooltip title={`Hide this news item from appearing (Admin Only)`}>
				        		<Button onClick={() => { this.deleteProject(item, false)}} startIcon={<HideIcon size={11} />}>Hide</Button>
				        	</Tooltip>
			        	}
			        	
			        
			    </ButtonGroup>
		  	}
		  	
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
		
						<div className="modal__body background_white">
							<h4 className="contact__title">{add ? `Add Project Update: ${buildingRes && buildingRes["name"] ? buildingRes["name"] : ""}` : `Edit Project Update: ${buildingRes && buildingRes["name"] ? buildingRes["name"] : ""}`}</h4>
							 <hr/>
							 <div className={classes.root}>
						      
						        <EditFeedItem
								 	formData={formData}				               
								 	onChange={this.onChange}
								 	onSubmit={this.onSubmit}
								 	toggle={this.toggle}
								 />
						     
						     
						    </div>
							
				                 
				             
						    
				        </div>	
		
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("contact_form")(state),
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [] 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditFeed));
