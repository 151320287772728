import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import ContactTabContainer from "./components/ContactTabContainer";
import { RTLProps } from "../../shared/prop-types/ReducerProps";

import TrialAlert from "../../shared/components/payment/TrialAlert";
import AlertIcon from "mdi-react/AlertDecagramOutlineIcon";
import Alert from "../../shared/components/Alert";

import { _hasPermission } from "../../shared/helpers/access";
import EnterpriseGate from "../../shared/components/payment/EnterpriseGate";

import {
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	buildingFL,
} from "../../shared/helpers/utils";

import {
	_axiosCall,
	_makeBuildingAxiosData,
} from "../../shared/helpers/apicalls";
import LinearLoading from "../../shared/components/LinearLoading";

const apiURL = process.env.API_ROOT + "/";
class SingleContact extends PureComponent {
	state = {
		organizationRes: false,
		fetchInProgress: false,
		changed: false,
		contacts: [],
		pipeline_projects: [],
	};

	componentDidMount() {
		this._isMounted = true;
		this._getMongoOrganizationData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.changed !== prevState.changed) {
			this._getMongoOrganizationData();
		}
	}

	_getMongoOrganizationData = async () => {
		const key = this.props.match.params.key
			? this.props.match.params.key
			: false;

		if (!key) return false;
		const { formData } = this.state;
		if (!this.state.fetchInProgress) {
			this.setState((prevState) => ({ fetchInProgress: true }));
		}

		let mongoQuery = [
			{
				$match: {
					$and: [{ key: key }, { "meta.deleted": { $exists: false } }]
				},
			},
			{
				"$lookup": {
					"from": "contacts",
					"localField": "key",
					"foreignField": "organizations.key",
					"pipeline": [{
						"$match": {
							"meta.deleted": {
								"$exists": false
							}
						}
					},
					{
						"$project": {
							"_id": 0,
							"key": 1,
							"name": 1,
							"title": 1,
							"organiations": 1,
							"organization_associations": 1,
							"phone": 1,
							"email": 1,
							"address": 1,
							"first_name": 1,
							"last_name": 1,
							"meta": 1,
							"project_roles": 1,
							"photo": 1
						}
					}
					],
					"as": "contacts"
				}
			},
			{
				"$lookup": {
					"from": "pipeline-projects",
					"localField": "projects.key",
					"foreignField": "key",
					"pipeline": [
						{
							"$match": {
								"meta.deleted": {
									"$exists": false
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": 1,
								"name": 1,
								"meta": 1
							}
						}
					],
					"as": "pipeline_projects"
				}
			},
			{
				"$project": {
					"_id": 0,
					"organization": "$$ROOT",
					"contacts": "$contacts",
					"pipeline_projects": "$pipeline_projects"
				}
			},
			{
				"$addFields": {
					"organization.project_keys": {
						"$map": {
							"input": "$pipeline_projects",
							"as": "project",
							"in": "$$project.key",
						},
					},
				},
			},
			{
				"$unset": [
					"organization.contacts", "organization.projects", "organization.pipeline_projects"
				]
			}
		]

		let axiosData = {
			url: apiURL + "pipeline/aggregate/organizations",
			method: "post",
			query: mongoQuery,
		};

		let formattedData = await _axiosCall(axiosData)
			.then((res) => {
				if (this._isMounted && res && res[0] && res[0].organization) {
					if ( !res[0].organization.projects && res[0].pipeline_projects ) res[0].organization.projects = res[0].pipeline_projects; // Added projects back

					this.setState({
						organizationRes: res[0].organization,
						fetchInProgress: false,
						contacts: res[0].contacts ? res[0].contacts : [],
						pipeline_projects: res[0].pipeline_projects
							? res[0].pipeline_projects
							: [],
					});
				} else {
					this.setState({ organizationRes: {} });
				}
			})
			.catch((error) => {
				console.log("error: " + error);
			});
		// console.log(formattedData)
		return formattedData;
	};

	setChanged() {
		const { changed } = this.state;

		this.setState({ changed: !changed });
	}

	render() {
		const { organizationRes, fetchInProgress, contacts, pipeline_projects } =
			this.state;
		const { user } = this.props;

		if (!_hasPermission(user, "project-contacts")) {
			return (
				<div className="dashboard">
					<Container className="todo-app">
						<Row>
							<EnterpriseGate
								copy={<span>Access to Pipeline Contacts requires an upgrade to your subscription.</span>}
								img={`https://marketproof.com/svgs/PLyes.svg`}
								classes={"small-image"}
							/>
						</Row>
					</Container>
				</div>
			)
		}
		if (!organizationRes || fetchInProgress) {
			return <LinearLoading />;
		}

		return (
			<Container className="dashboard">
				<Row>
					<Col md={12} className={"mb-3"}>
						<TrialAlert />
					</Col>
				</Row>
				<Row>
					<ContactTabContainer
						{...this.props}
						getMongoOrganizationData={this._getMongoOrganizationData.bind(this)}
						contacts={contacts}
						pipeline_projects={pipeline_projects}
						organizationRes={organizationRes}
						setChanged={this.setChanged.bind(this)}
					/>
				</Row>
			</Container>
		);
	}
}

export default connect((state) => ({
	user: state.user,
}))(withOktaAuth(withRouter(SingleContact)));
