import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "mdi-react/ArrowBackIcon";
import LinkIcon from "mdi-react/LinkIcon";
import DomainIcon from "mdi-react/DomainIcon";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import EditIcon from "mdi-react/PencilIcon";
import ContractIcon from "mdi-react/ClipboardCheckOutlineIcon";
import SoldIcon from "mdi-react/CurrencyUsdCircleOutlineIcon";
import LinearLoading from "../../../shared/components/LinearLoading";
import { Grid } from "@material-ui/core";

import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";

import { bindActionCreators } from "redux";
import buyersListActions from "../../../redux/actions/buyersListActions";

import Profile from "./Profile";
import Contacts from "./Contacts";
import AddToBuyersListButton from "../../../shared/components/buttons/AddToBuyersListButton";
import EditOrganization from "../../../shared/components/edit/EditOrganization";

import TrialLimit from "../../../shared/components/payment/TrialLimit";

import UnitIcons from "../../../shared/components/UnitIcons";

import { Publisher } from "./../../../shared/pubsub/publisher.js";

import BuildingIcons from "../../../shared/components/BuildingIcons";
import OfferingStatus from "../../../shared/components/OfferingStatus";

import MatTable from "./../../PipelineBuildings/components/MatTable";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  UnitCurrentStatus,
  propertyFL,
  _isResiUnit,
  _getCompanyURL,
  _PickOrgName,
  ProfileAvatar,
} from "../../../shared/helpers/utils";
import ContactCard from "./ContactCard";
import CardHeaderData from "./CardHeaderData";
import PeopleCardView from "./Contacts/components/PeopleCardView";

const apiURL = process.env.API_ROOT + "/query/";

class ContactTabContainer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        this.props.match && this.props.match.params.tab
          ? this.props.match.params.tab
          : "overview",
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    console.log(this.props.match);
    const { organizationRes, user } = this.props;

    Publisher.publish(`track.organizationView.${this.state.activeTab}`, {
      organization: organizationRes.key,
    });
    Publisher.publish(`track-mp.organizationView.${this.state.activeTab}`, {
      user: user,
      organization: organizationRes,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      this.setState({ activeTab: this.props.match.params.tab });
    }
  }

  toggle = (tab) => {
    const { organizationRes } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      const newUrl =
        _getCompanyURL(organizationRes, "key") + (tab ? `/tab/${tab}` : "");
      this.props.history.push(newUrl);
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { organizationRes, setChanged, getMongoOrganizationData, contacts } =
      this.props;
    const { activeTab } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="p-0">
            <div className="card__title">
              <div className="card_button">
                {/* <Tooltip title="Back to Buildings">
                  <IconButton
                    className="material-table__toolbar-button"
                    onClick={() => this.props.history.go(-1)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip> */}

                <div className="top__toolbar co_toolbar_sty card-body-top__toolbar">
                  <AddToBuyersListButton
                    organizationKey={organizationRes.key}
                    type={"organization"}
                  />
                  <EditOrganization
                    item={organizationRes}
                    setChanged={setChanged}
                    single
                    edit
                  />
                  <EditOrganization
                    item={organizationRes}
                    setChanged={setChanged}
                    single
                    del
                  />
                </div>
              </div>
              <div className="new_card_title company">
                <img src="/img/company_header.svg" />
                <div className="company_image">
                  <div className="unit__badge">
                    {organizationRes &&
                    organizationRes.logo &&
                    organizationRes.logo[0] &&
                    organizationRes.logo[0].preview ? (
                      <div
                        className="company_logo"
                        style={{
                          backgroundImage: `url(${organizationRes.logo[0].preview})`,
                        }}
                      />
                    ) : organizationRes.project_keys &&
                      organizationRes.project_keys.length == 1 ? (
                      <span>
                        {_capitalizeText(
                          _PickOrgName(
                            organizationRes,
                            organizationRes.project_keys[0]
                          )
                        )}
                      </span>
                    ) : (
                      <span>{_capitalizeText(organizationRes.name)}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <CardHeaderData {...this.props} />
            <div className="tabs tabs--bordered-bottom tabs-header-set">
             
              <div className="tabs__wrap tabs_header">
                <Nav tabs className="company_tab_style">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "overview",
                      })}
                      onClick={() => {
                        this.toggle("overview");
                      }}
                    >
                      <span
                        className={
                          activeTab === "overview"
                            ? "tab-title"
                            : "unselect-tab-title"
                        }
                      >
                        Overview
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navBar_title">
                    <NavLink
                      className={classnames({ active: activeTab === "people" })}
                      onClick={() => {
                        this.toggle("people");
                      }}
                    >
                      <span
                        className={
                          activeTab === "people"
                            ? "tab-title"
                            : "unselect-tab-title"
                        }
                      >
                        People
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navBar_title">
                    <NavLink
                      className={classnames({
                        active: activeTab === "projects",
                      })}
                      onClick={() => {
                        this.toggle("projects");
                      }}
                    >
                      <span
                        className={
                          activeTab === "projects"
                            ? "tab-title"
                            : "unselect-tab-title"
                        }
                      >
                        Projects
                      </span>
                    </NavLink>
                  </NavItem>
                  {/*   <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'news' })}
	                      onClick={() => {
	                        this.toggle('news');
	                      }}
	                    >
	                      <span className="tab-title">News</span>
	                    </NavLink>
	                  </NavItem>   */}
                </Nav>
              </div>
            </div>
            {activeTab === "overview" && (
              <TabContent activeTab={activeTab} className="card_ipad_view">
                <TabPane tabId="overview">
                  <Row>
                    <Col md={12}>
                      <Profile {...this.props} setTab={this.toggle} />
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h3 className="section-title section-title--not-last content_set heading__text_style">
                        People
                      </h3>
                      <p className="section-title section-title--not-last subhead heading__subText">
                        People associated with {organizationRes.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="caontact_card_padding">
                      <PeopleCardView
                        contacts={contacts.slice(0, 5)}
                        showLink
                        toggle={this.toggle}
                      />
                      {/* <ContactCard {...this.props} toggle={this.toggle} /> */}
                      {/* <Contacts {...this.props} toggle={this.toggle} min />  old contact list */}
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h3 className="section-title section-title--not-last heading__text_style">
                        Recent Projects
                      </h3>
                      <p className="section-title section-title--not-last subhead heading__subText">
                        Recent associated with {organizationRes.name}
                      </p>
                    </Col>
                  </Row>
                  <Row className="projects-head-top-p">
                    <Col md={12}>
                      <MatTable
                        organizationRes={organizationRes}
                        toggle={this.toggle}
                        isSingle
                        min
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            )}
            {activeTab === "people" && (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="people">
                  <Contacts organizationRes={organizationRes} {...this.props} />
                </TabPane>
              </TabContent>
            )}
            {activeTab === "projects" && (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="projects">
                  <Grid item xs={12} className="pl-4 pr-4">
                  <MatTable organizationRes={organizationRes} isSingle />
                  </Grid>
                </TabPane>
              </TabContent>
            )}
            {activeTab === "news" && (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="news">[Associated news]</TabPane>
              </TabContent>
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings)
      ? state.buyersLists.viewedBuildings.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(withRouter(ContactTabContainer)));
