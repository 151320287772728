import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';

import ChangePlan from './../payment/ChangePlan';
import { subscriptionPlans } from './../payment/plans.js';


import searchActions from '../../../redux/actions/searchActions';
import OfferingStatus from '../../../shared/components/OfferingStatus';
import ProjectSearch from './ProjectSearch';
import OrganizationSearch from './OrganizationSearch';
import PeopleSearch from './PeopleSearch';


import StarIcon from 'mdi-react/StarCircleOutlineIcon';
import MPNDIcon from 'mdi-react/CheckDecagramIcon';
import BldgIcon from 'mdi-react/DomainIcon';
import SearchIcon from 'mdi-react/MagnifyIcon';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import algoliasearch from 'algoliasearch/lite';

import SearchTags from './SearchTags';
import {   
  connectStateResults,
  connectHits,
  InstantSearch,
  Hits,
  Configure,
  SearchBox,
  Index } from 'react-instantsearch-dom';
import 'instantsearch.css/themes/reset.css';
import ASearch from "./ASearch";
import {
  _isEqual,
  _sortDataObj,
  _validArrTags,
  _validStrTags,
  _nameToUrl,
  _urlToName,
  _formatAddressAutoComplete,
  _capitalizeText,
  _shouldShow,
  _checkBuildingNameVsAdr,
  _replaceAbbreviations,
  _getBuildingURL,
  _selectBestSlug,
  _relatedHoods,
  BuildingStageBadge,
  ShowBadge,
  _getBuildingURLBase
} from '../../../shared/helpers/utils';
import { 
	_hasPermission
} from "../../../shared/helpers/access";

import { _getNeighborhoods } from "../../../shared/helpers/apicalls";

const searchClient = algoliasearch('0X3WLXN3NU', 'a1e5007fe4f76f333a047b8caaaa4941');

const buildingUrl = process.env.API_ROOT + '/query/';

const AllResults = connectStateResults(
  ({
    allSearchResults,
    children,
  }) => {
    const noResults =
      allSearchResults &&
      Object.values(allSearchResults).every(
        results => results.nbHits === 0
      );

    return noResults ? (
      <div>
        
      </div>
    ) : (
      children
    );
  }
);

const IndexResults = connectStateResults(
  ({ searchState, searchResults, children, refresh }) => {
    if (refresh && searchState.query) {
      searchState.query = "";
    }

    return searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="" />
    );
  }
);
const BoroughHits = connectHits(
  ({ hits, boroTags, boroTagClick, hoodTags }) => (
	<Fragment>
	 {hits && hits.length>0 &&
	    <div className="c_search-results">
	      <h5 className="">BOROUGHS</h5>
	      {hits.map(hit => {
	        // const urlTopic = topicTags.length === 1 ? topicInfo[topicTags[0]].urlHash : '';
	
	        return (
	          <div className="c_result" key={hit.objectID}>
	            <div
	              onClick={boroTagClick.bind(this, hit.name.toLowerCase())}
	              id="tags"
	              className="c_search-hit"
	            >
	              <span>{hit.name}</span>
	            </div>
	          </div>
	        );
	      })}
	    </div>
    }
    </Fragment>
  )
);


//hoods and boros are data type STRINGS for hood Index
const HoodHits = connectHits(
  ({ hits, hoodTagClick, hoodTags, boroTags, query }) => (
    <Fragment>
     {hits && hits.length>0 &&
	    <div className={`c_search-results ${query === "" ? "hidden" : ""}`}>
	      <h5 className="">NEIGHBORHOODS</h5>
	      {hits.map(hit => {
	        // const urlTopic = topicTags.length === 1 ? topicInfo[topicTags[0]].urlHash : '';
	        const boro = _validStrTags(hit.borough);
	        const hood = _validStrTags(hit.neighborhood);
	        // console.log(hit);
	        let urlBoro = boro ? _nameToUrl(boro) : "new-york";
	        let urlHood = hood ? hood : "nyc";
	        // takes out all hoods that we don't want/have in our uiValidation list
	        // if(!hoodsWithBoros[hood]){
	        //   return;
	        // }
	
	        return (
	          <div className="c_result" key={hit.objectID}>
	            <div
	              onClick={hoodTagClick.bind(this, {
	                hood: _urlToName(urlHood),
	                boro: _urlToName(urlBoro)
	              })}
	              id="tags"
	              className="c_search-hit"
	            >
	              <span>
	                {hit.neighborhood_display
	                  ? hit.neighborhood_display
	                  : hit.neighborhood}
	                , {hit.borough}
	              </span>
	            </div>
	          </div>
	        );
	      })}
	    </div>
	}
	</Fragment>
  )
);

const BuildingHits = connectHits(({ hits, onSelect, query, tabInfo, addBuilding, goToBuilding }) => (
 <Fragment>
 {hits && hits.length>0 &&
   <div className={`c_search-results ${query === "" ? "hidden" : ""}`}>
    <h5 className="">ADDRESS / BUILDING</h5>
    {hits
      .filter(hit => hit.display_full_street_address)
      .map(hit => {
        hit.primary_adr = true;
        hit = _formatAddressAutoComplete(hit, query);
        hit = _selectBestSlug(hit);
        const boro = _validStrTags(hit.borough);
        const hood = _validArrTags(hit.neighborhood);
        const urlBoro = boro ? _nameToUrl(boro) : "new-york";
        const urlHood = hood ? _nameToUrl(hood) : "nyc";
        const buildingName = _checkBuildingNameVsAdr(hit);
        const address = hood ? hood + ", " + boro : boro;

        return (
          <div className="c_result" key={hit.objectID}>
            <div
              onClick={onSelect.bind(
                this,
                {
                  building: {
                    slug: hit.building_slugs[0],
                    display_adr: hit.display_full_street_address,
                    hood: _urlToName(urlHood),
                    boro: _urlToName(urlBoro)
                  },
                  // tab: Object.assign(tabInfo.building.tabs[0], {
                  //   data: false,
                  //   type: "building"
                  // })
                },
                hit,
                !goToBuilding
              )}
              id="tags"
              className="c_search-hit"
            >
              {/*<Highlight attributeName="display_text" hit={hit} tagName="strong" /> */}
                {addBuilding && <span className={'c_blg-add'}>
                	<Tooltip title="Add this building to search">
                      	<IconButton
				            onClick={(e) => {e.stopPropagation();e.preventDefault();onSelect(				                
				                {
				                  building: {
				                    slug: hit.building_slugs[0],
				                    display_adr: hit.display_full_street_address,
				                    hood: _urlToName(urlHood),
				                    boro: _urlToName(urlBoro)
				                  },
				                  // tab: Object.assign(tabInfo.building.tabs[0], {
				                  //   data: false,
				                  //   type: "building"
				                  // })
				                },
				                hit,
				                true
				              )}}

				            className=""
				            size={'small'}
				            style={{background:"transparent"}} 
				          >
						  <AddIcon size={20} style={{ marginRight:"3px"}} color={'#780F9E'}  />
					  	</IconButton>
				  	</Tooltip>	
				</span>
				}
				
				<span className={`c_bldg_container`}>
	                <span className={`c_bldg`}>
	                {hit.building_display
	                  ? _capitalizeText(hit.building_display)
	                  : _capitalizeText(
	                      _replaceAbbreviations(hit.display_full_street_address)
	                    )}
	                </span>
	                <span className={`c_name`}>
	                	{_shouldShow(buildingName)
	                  ? " (" + _capitalizeText(buildingName) + ")"
	                  : ""}
	                </span>
	                 {false && !isMobile && <OfferingStatus building={hit} /> }
	                 {false && !isMobile && hit.building_status && <BuildingStageBadge item={hit} small /> }
	                 {hit.building_status === "sponsor" ? 
		                 <Fragment>
		                 <ShowBadge text={`New ${_capitalizeText(hit.ownership_type)}`} small />
		                 {/*<Tooltip title="New Development Building">
				               <IconButton size={'small'} style={{background:"transparent"}} >
							   	<MPNDIcon size={16} style={{ marginRight:"3px"}} color={'#FFD23F'}  />
							  </IconButton>
				         </Tooltip>*/}
		                 </Fragment>
		                 : <ShowBadge text={`${_capitalizeText(hit.ownership_type)}`} small />
	                 }
	              
	                <span className="c_search-hit-details">{address}</span>
	            </span>
            </div>
          </div>
        );
      })}
  </div>
  }
  </Fragment>
));

const AttributeHit = connectStateResults(
  ({ searchResults, onSelect, query, attribute, title, doCaps, hideInitial }) => {

	const data = searchResults && searchResults.facets && searchResults.facets[0] && Object.keys(searchResults.facets[0].data).map(key => {
				 		if(key.toLowerCase().indexOf(query.toLowerCase())<0) return null;
						return key;
				 }).filter(item => item !== null);
	  
	if(!data || data.length <=0 ) return null;
	return(  
	    <div className={`c_search-results ${hideInitial && query === "" ? "hidden" : ""}`}>
	      <h5 className="">{title}</h5>
	      {data.map(item => {
	        return (
	          <div className="c_result">
	            <div
	              id="tags"
	              className="c_search-hit"
	               onClick={onSelect.bind(
	                this,
	                item,
	                attribute
	              )}
	            >
	              <span>{doCaps ? _capitalizeText(item) : item}</span>
	            </div>
	          </div>
	        );
	      })}
	    </div>
	); 
});


class Search extends Component {
	
	
  constructor(props) {
	super(props);
	this.state = {
      acTop: "50px",
      displayFullIndices: false,
      modalIsOpen: false,
      pTop: "",
      refresh: false,
      searchClicked: false,
      searchOpen: false,
      searchQuery: "",
      secondL: "",
      showMoreTags: false,
      width: "28px",
      updatePlaceholder: false,
      searchTags:[],
      modal: false,
      nextPlan: false,
      modalMessage: "Access to requires an upgrade to your current subscription"
    }; 
    
    this.onSearchStateChange = this.onSearchStateChange.bind(this);
    this._handleTags = this._handleTags.bind(this);
    this._handleHoodTags = this._handleHoodTags.bind(this);
    this._handleBoroTags = this._handleBoroTags.bind(this);
    this._handleBuildingTags = this._handleBuildingTags.bind(this);
    this._searchClicked = this._searchClicked.bind(this);
    this._getActiveEl = this._getActiveEl.bind(this);
    this._handleAttrTags = this._handleAttrTags.bind(this);
    this._setModal = this._setModal.bind(this);
    this._handleOrganizationTags = this._handleOrganizationTags.bind(this);
    this._handlePeopleTags = this._handlePeopleTags.bind(this);
    this._handleSearchText = this._handleSearchText.bind(this);
	this._handleProjectTags = this._handleProjectTags.bind(this);
  }
  
  componentDidMount() {
    // console.log('AlgoSB didMount calling _move');
    const { clearAttributes } = this.props.actions;
    clearAttributes();
    window.addEventListener("click", this._getActiveEl);
    
  }

  componentWillUnmount() {
    window.removeEventListener("click", this._getActiveEl);
  }

  _getActiveEl() {
	  
    const alInputEl = document.getElementById("tag-input");
    const alTagsContainerEl = document.getElementById("tags");
    const expandSearch = document.getElementById("expand-search");
    let isFocused =
      document.activeElement === alInputEl ||
      document.activeElement === alTagsContainerEl ||
      document.activeElement === expandSearch;
    this.setState({
      searchOpen: isFocused,
      searchClicked: isFocused,
      showMoreTags: isFocused
    });
  }

  onSearchStateChange(searchState) {
    //searchState (algolia controlled obj that keeps track of all widget states)
    const oldQuery = searchState.query;
    //takes the - out of user types inputs before sending it to algolia
    const validQuery = searchState.query
      ? searchState.query.replace("-", "")
      : "";
    //updates aloglia obj sending the cleaned text to the search engine
    searchState.query = validQuery;

    //keeps the uncleaned text to show the user in the input
    this.setState({
      searchQuery: oldQuery,
      refresh: false
    });
  }

  
  
  _searchClicked(e) {
    if (e.target.tagName === "I") {
      return document.getElementById("tag-input").blur();
    }

    this.setState({
      searchClicked: true
    });
  }
  
   _closeAutoSearch() {
    this.setState({
      searchClicked: false,
      searchQuery: "",
      refresh: true
    });
    //blur focus here
  }
  
   _handleTags(obj) {
    this._closeAutoSearch();
    return this.props.tagSelection(obj);
  }

  //pass hood tags
  _handleHoodTags(obj) {
	const { addTag } = this.props.actions;
    this._closeAutoSearch();
    
    //return this.props.hoodTagClick(obj);
    //const newTags = this.state.searchTags.slice(0);    
    const id = _nameToUrl(obj.hood + '_' + obj.boro);
    if(_relatedHoods[id] && _relatedHoods[id].length>0){
	    _relatedHoods[id].map(hood =>{
		    addTag(hood);
	    })
    }else{
	   addTag({type:'neighborhood', data: obj, id: id, name: obj.hood}); 
    }
    //return this.props.handleSearch('neighborhood', obj);
  }
  
  _setModal(modal) {
    this.setState({modal: modal})
  }

  //pass building tags
  _handleProjectTags(obj, hit, doAdd) {
	  
	const { buildingTab, addBuilding, user } = this.props;
	const { addTag } = this.props.actions;
	
	console.log(obj, hit, doAdd)
	  
    this._closeAutoSearch();
    
    if(doAdd){
	    console.log(obj, hit);
	    const id = _nameToUrl(hit.key);
	    addTag({type:'building', data: hit, id: id, name: hit.name ?_capitalizeText(hit.name) : _capitalizeText(hit.display_full_street_address) });	   
    }else{
	    let buildingURL = _getBuildingURL(hit, '_id');
	    if(buildingTab){
		    buildingURL += '/tab/'+buildingTab;
	    }    
		this.props.history.push(buildingURL);
    }
  }
  
  _handleBuildingTags(obj, hit, doAdd) {
		
	  const { buildingTab, addBuilding, user } = this.props;
	  const { addTag } = this.props.actions;
	  
	  console.log(obj, hit, doAdd)
		
	  this._closeAutoSearch();
	  
	  if(doAdd){
		  console.log(obj, hit);
		  const id = _nameToUrl(hit.key);
		  addTag({type:'building', data: hit, id: id, name: hit.name ?_capitalizeText(hit.name) : _capitalizeText(hit.display_full_street_address) });	   
	  }else{
		  let buildingURL = '/building/'+_getBuildingURLBase(hit);
		 
		  this.props.history.push(buildingURL);
	  }
	}
  
  _handleOrganizationTags(obj, hit, doAdd) {
	 console.log(obj, hit, doAdd);
	const { buildingTab, addBuilding, user } = this.props;
	const { addTag } = this.props.actions;
	
	
	  
    this._closeAutoSearch();
    
    if(doAdd){
	  //  console.log(obj, hit);
	    const id = _nameToUrl(hit.key);
	    addTag({type:'company', data: hit, id: id, name: _capitalizeText(hit.name)});	   
    }else{

    	let url = `/company/${hit.key}`;
		this.props.history.push(url);
	}
    
    

  }
  _handlePeopleTags(obj, hit, doAdd) {
	  
	const { buildingTab, addBuilding, user } = this.props;
	const { addTag } = this.props.actions;
	
	
	  
    this._closeAutoSearch();
    
	if(doAdd){
	  //  console.log(obj, hit);
	    const id = _nameToUrl(hit.key);
	    addTag({type:'contact', data: hit, id: id, name: _capitalizeText(hit.name)});	   
    }else{
	    let url = `/contact/${hit.key}`;
		this.props.history.push(url);
	}
    
    

  }
  //pass borough tags
  _handleBoroTags(obj) {
	const { addTag } = this.props.actions;
    this._closeAutoSearch();
    //const newTags = this.state.searchTags.slice(0);    
    //newTags.push({type:'borough', data: obj, id: obj, name: obj});   
    //this.setState( { searchTags : newTags } )    
    
    const id = _nameToUrl(obj);
    addTag({type:'borough', data: obj, id: id, name: obj});
    //return this.props.handleSearch('borough', obj);
  }
  
  _handleAttrTags(item, attribute) {
	const { addTag } = this.props.actions;
    this._closeAutoSearch();
    //const newTags = this.state.searchTags.slice(0);    
    //newTags.push({type:'borough', data: obj, id: obj, name: obj});   
    //this.setState( { searchTags : newTags } )    
    
    const id = _nameToUrl(item);
    addTag({type:attribute, data: item, id: id, name: item});
    //return this.props.handleSearch('borough', obj);
  }
  
  _handleSearchText(text){
	  const { addTag } = this.props.actions;
	  const { allowTextSearch } = this.props;
	  
	  if(allowTextSearch){
		   this._closeAutoSearch();
		   addTag({type:'text', data: text, id: _nameToUrl(text), name: `Search: ${text}`});
		   
	  }
  }
  
  render(){
	const { buildingFilter, buildingAttribute, buildingAttributeTitle, placeHolder, doCaps, hideBuilding, buildingAttributes, hideInitial, addBuilding, type, hideProjects, hideOrganizations, hidePeople, hideNeighborhood, hideBorough, alwaysAddTag, allowTextSearch, addProject, goToBuilding, hideAddContacts, hitsPerPage } = this.props;
	const {  modal, nextPlan, modalMessage, searchQuery } = this.state;
	
	const placeHolderText = placeHolder ? placeHolder : "Neighborhood, Borough or Building";
	
	let filters = buildingFilter ?  buildingFilter : '(offering_verified_status:"active" OR offering_verified_status:"coming soon" OR offering_verified_status:"offering plan required" OR  offering_verified_status:"condo declaration vetted") AND (NOT offering_accepted_date:1 TO 1199145600)';
	
	return (
		<Fragment>
			<InstantSearch 
				searchClient={searchClient} 
				indexName="we3-buildings-search-3"
				refresh={this.state.refresh}
				onSearchStateChange={this.onSearchStateChange}			
				>
			    <ASearch
		          value={this.state.searchQuery}
		          onIcon={null}
		          onFocus={null}
		          height={this.state.height}
		          isDesktop={this.props.isDesktop}
		          isListing={this.props.isListing}
		          isMobile={this.props.isMobile}
		          pTop={this.state.pTop}
		          placeholder={isMobile ? "Search.." : placeHolderText}
		          openAConClick={this._searchClicked.bind(this)}
		          resetTags={this._resetTags}
		          showMoreTags={this.state.showMoreTags}
		          handleSearchText={this._handleSearchText}
		        />
			    <div
		          className={`c_search-result-container ${isMobile || isTablet ? 'c_mobile' : ''} ${
		            this.state.searchOpen || this.state.searchClicked
		              ? "s_active-show"
		              : "hidden"
		          }`}
		          id="c_search-result-container"
		         
		        >
		        
		          <SearchTags />
		          <div>
		          
		          		{allowTextSearch && searchQuery.length > 0 && 
			          		<div className="c_search-results">
			          			<h5 className="">TEXT SEARCH</h5>
					          	<div className="c_result" key={"text-search"}>
						            <div
						              onClick={() => this._handleSearchText(searchQuery)}
						              id="tags"
						              className="c_search-hit"
						            >
						            
						              <span className={'c_blg-add'}>
						                	<Tooltip title={`Search ${searchQuery} as text`}>
						                      	<IconButton
										        						
										            className=""
										            size={'small'}
										            style={{background:"transparent"}} 
										          >
												  <SearchIcon size={20} style={{ marginRight:"3px"}} color={'#780F9E'}  />
											  	</IconButton>
										  	</Tooltip>	
										</span>
						              <span>Search: {searchQuery}</span>
						            </div>
						        </div>
						    </div>
					    }
		          		{!hideProjects && <ProjectSearch
						  onSelect={this._handleProjectTags}
		                  query={this.state.searchQuery}
		                  tabInfo={this.props.tabInfo}
		                  addBuilding={addBuilding}
		                  type={type}
			              alwaysAddTag={alwaysAddTag}
						  addProject={addProject}
						  hitsPerPage={hitsPerPage}
			            />}
			            
			            
			            {!hideOrganizations && <OrganizationSearch
						  onSelect={this._handleOrganizationTags}
		                  query={this.state.searchQuery}
		                  tabInfo={this.props.tabInfo}
		                  addBuilding={false}
		                  type={type}
						  hideAddContacts={hideAddContacts}
						  hitsPerPage={hitsPerPage}
			            
			            />}
			            
			             {!hidePeople && <PeopleSearch
						  onSelect={this._handlePeopleTags}
		                  query={this.state.searchQuery}
		                  tabInfo={this.props.tabInfo}
		                  addBuilding={false}
		                  type={type}
						  hideAddContacts={hideAddContacts}
						  hitsPerPage={hitsPerPage}
			            
			            />}
			            
		          		{false && buildingAttribute && <Index indexName="we3-buildings-search-3" indexId="building-attribute">
			                <Configure hitsPerPage={0} filters={filters} restrictSearchableAttributes={[buildingAttribute]} facets={[buildingAttribute]} maxValuesPerFacet={3} />
							<AttributeHit
			                  attribute={buildingAttribute}
			                  title={buildingAttributeTitle ? buildingAttributeTitle : 'RESULTS'}
			                  query={this.state.searchQuery}
			                  onSelect={this._handleAttrTags}
			                  doCaps
			                  hideInitial
			                />
			            </Index>}
			            
			            {false && buildingAttributes && buildingAttributes.length>0 &&
				            <Fragment>		            
				            {buildingAttributes.map(item => {
					            return (
						            <Index indexName="we3-buildings-search-3" indexId={`building-${item.buildingAttribute}`}>
						                <Configure hitsPerPage={0} filters={filters} restrictSearchableAttributes={[item.buildingAttribute]} facets={[item.buildingAttribute]} maxValuesPerFacet={3} />
										<AttributeHit
						                  attribute={item.buildingAttribute}
						                  title={item.buildingAttributeTitle ? item.buildingAttributeTitle : 'RESULTS'}
						                  query={this.state.searchQuery}
						                  onSelect={this._handleAttrTags}
						                  doCaps
						                  hideInitial
						                />
						            </Index>			            
					            )
				            })}
				            </Fragment>			            
			            }
			            
		          		{!hideBuilding && <Index indexName="we3-buildings-search-3" indexId="building">
			                <Configure hitsPerPage={3} />
							<BuildingHits
			                  onSelect={this._handleBuildingTags}
			                  query={this.state.searchQuery}
			                  tabInfo={this.props.tabInfo}
			                  addBuilding={addBuilding}
							  goToBuilding={goToBuilding}
			                />
			            </Index>}
			            
			           
				        
			            {!hideNeighborhood && <Index indexName="we3-neighborhoods">
			                <Configure hitsPerPage={3} />
			                <HoodHits
			                  hoodTagClick={this._handleHoodTags}
			                  hoodTags={this.props.hoodTags}
			                  boroTags={this.props.boroTags}
			                  query={this.state.searchQuery}
			                />
			            </Index>}
			            
			            
			            {!hideBorough && <Index indexName="we3-boroughs">
			                <Configure hitsPerPage={5} />
			                <BoroughHits
			                  boroTagClick={this._handleBoroTags}
			                  boroTags={this.props.boroTags}
			                  hoodTags={this.props.hoodTags}
			                />
			            </Index>}
					    
			        </div>
			    
	            </div>
			</InstantSearch>
			<ChangePlan modal={modal} setModal={this._setModal} changePlan={nextPlan} message={modalMessage} />
		</Fragment>
	)
	  
  }
  
};

function mapStateToProps(state) {
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
