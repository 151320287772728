import axios from "axios";
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_formatAddressAutoComplete
} from '../../../../../shared/helpers/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = (options, { inputValue }) => { return options }
const apiUrl = process.env.API_ROOT;  



const renderCompanySearch = (props) => {
	console.log("renderCompanySearch props", props)
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState(props.value);
  const loading = open && options.length === 0;
  
  const { onChange, onSelectItem, disabled, uiSchema } = props;

  React.useEffect(() => {
    let active = true;
    
    let addOption = {
	          type : "add",
              name: ``
         }
/*
    if (inputValue === '') {
      setOptions(value ? [...value, [addOption]] : [addOption]);
      return undefined;
    }
*/
    axios({ method:'get', url: `${apiUrl}/pipeline/autocomplete/companies?input=${inputValue}`   }).then((results) => {
      if (active) {
        let newOptions = [];


        if (results && results.data) {
          newOptions = [...newOptions, ...results.data];
        }
        if(inputValue && uiSchema['ui:widget']!=="PersonCompanySearch"){
	        newOptions.push(addOption);
        }
        
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue]);

/*
  React.useEffect(() => {
  	  setValue(props.value);
  }, [props.value]);
*/

  return (
    <Autocomplete
      freeSolo
      id="asynchronous-demo"
      style={{ width: '100%' }}
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={filterOptions}
      getOptionSelected={(option, value) => option === value.name}
      getOptionLabel={(option) =>  { 
	      if(option.name){
		      return  _capitalizeText(option.name)
		  }else if(option.company_search){
			  return option.company_search;
		  }
	  }}
      options={options}
      loading={false}
      value={value}
      label={value ? value : inputValue}
      onChange={(event, newValue) => {
	      console.log("Autocompletz onChange", newValue)
	    if(newValue && (!newValue.type || newValue.type !== "add")){
		      setOptions(newValue ? [newValue, ...options] : options);
	        setValue(newValue);
	        console.log(newValue)
	        onChange(newValue);
	    }else if(newValue && newValue.type == "add"){
		    
		    let newObj = {name:inputValue, type:"add"}
		    setOptions(newObj ? [newObj, ...options] : options);
	        setValue(newObj);
	        console.log(newValue)
	        onChange(newObj);
	    }
        
      }}
      onInputChange={(event, newInputValue) => {
	       console.log("Autocompletz onInputChange", newInputValue)	  
		     setInputValue(newInputValue);
			   //onChange(newInputValue);	    
       
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputValue && typeof inputValue === "string"  ? _capitalizeText(inputValue) : "Search Company Name"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {false ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
	    if(option.type=="add"){
		   return (
		     <div className="autocomplete-search"><AddIcon size={20} style={{ marginRight:"3px", marginTop:"-2px"}} color={'#780F9E'}  />{inputValue ? <span>Add <strong>{_capitalizeText(inputValue)}</strong> As New Company</span> : <span>Add New Company</span>}</div>
		   );
	    }else{
		   return (
	          <div className="autocomplete-search">{_capitalizeText(option.name)}</div>
	        ); 
	    }
	      
        
      }}
    />
  );
}

export default renderCompanySearch;