import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import BuyersListInput from "./components/BuyersListInput";
import BuyersList from "./components/BuyersList";
import buyersListActions from "../../../../../../redux/actions/buyersListActions";
import buyersListCard from "./types";
import {
  RTLProps,
  ThemeProps,
} from "../../../../../../shared/prop-types/ReducerProps";
import EmptyBuyersList from "./components/EmptyBuyersList";

import Panel from "../../../../../../shared/components/Panel";

import { _axiosCall } from "../../../../../../shared/helpers/apicalls";

import {
  buildingFL,
  getCohortQuery,
  _capitalizeText,
  GenerateBuildingSlug,
  GenerateBuildingKey,
  _fixGoogleImage,
} from "../../../../../../shared/helpers/utils";
const buildingUrl = process.env.API_ROOT + "/pipeline/";

class MyBuyersList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    buyerslist: PropTypes.arrayOf(buyersListCard).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      incompleteMyBuyersLists: false,
      completedMyBuyersLists: false,
      buildingsFetchInProgress: false,
      buildingIds: [], //props.buyersLists.map(item => item.key),
      buyersList: null,
      totalCount: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this._getBuildings(nextProps);
  }

  componentDidMount() {
    this._getBuildings(this.props);
  }

  componentDidUpdate() {}

  _setBuyersList = (res) => {
    const totalCount = res.count && res.count;

    let docs = [];
    if (res && res.docs) {
      docs = res.docs;
    }

    docs.forEach((doc) => {
      doc["building_slugs"] = [GenerateBuildingSlug(doc)];
      doc["solr_key"] = doc.building_key
        ? doc.building_key
        : GenerateBuildingKey(doc);
      doc["master_key"] = doc["key"];
    });

    this.setState({
      buyersList: docs,
      totalCount: totalCount,
      buildingsFetchInProgress: false
    });
  };

  _getBuildings = async (props) => {
    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { user, type } = this.props;
    const _buildingIDs = props.savedBuildings
      .filter((item) => !item.deleted)
      .map((item) => item.key)
      .join(" ");

    if (this.state.buildingsFetchInProgress) {
      return false;
    }

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          { "$and" : [{source_provider: "permits" }, {building_status: { "$ne" : "resale"}}]},
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      { $or : [{pipeline_occupancy_classification_proposed_code:{$nin:["u"]}}, {pipeline_total_construction_floor_area:{$gt:1000}}]}
    ];
    let baseQuery = {
      pipeline_occupancy_type: { $in: ["residential", "mixed-use"] },
    };
    if (type === "commercial") {
      baseQuery = {
        pipeline_occupancy_type: { $in: ["commercial"] },
      };
    }
    searchFilters.push(baseQuery);
    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        meta:1,
        key: 1,
        image: 1,
        images: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        date_added: 1,
        building_key: 1,
      },
      sort: { "pipeline_filing_date": -1 },
      limit: parseInt(3),
      skip: parseInt(0),
    };

    let axiosData = {
      url: buildingUrl + "query",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        let newData = res;
        if (res && res.docs) {
          newData = _fixGoogleImage(res);
        }
        this._setBuyersList(newData);
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  render() {
    const { actions, t, theme, rtl, type } = this.props;

    const {
      incompleteMyBuyersLists,
      completedMyBuyersLists,
      buyersList,
      totalCount,
    } = this.state;

    if (buyersList === null) {
      return (
        <Panel
          lg={6}
          md={12}
          title={`${_capitalizeText(type)} Projects`}
          subhead={`Recently updated residential projects`}
        ></Panel>
      );
    }

    if (buyersList.length <= 0) {
      return (
        <Panel
          lg={6}
          md={12}
          title={`${_capitalizeText(type)} Projects`}
          subhead={`Recently updated commercial projects`}
        >
          <EmptyBuyersList small={true} />
        </Panel>
      );
    }

    if (this.state.buyersList===null) {
      return null;
      {
        /*<EmptyBuyersList />;*/
      }
    }
    return (
      <Panel
        lg={6}
        md={12}
        title={`${_capitalizeText(type)} Projects`}
        subhead={`Recently filed permits`}
        label={null}
      >
        <BuyersList completed actions={actions} list={buyersList} type={type} />
        <div className="unit__load text-right semi-bold-text">
          <Tooltip title="See latest projects">
            <Link
              to={`/pipeline-search?pipeline_occupancy_type=${type == "residential" ? "residential,mixed-use" : type}&building_stage=all&order=desc&order_by=pipeline_filing_date`}
            >
              See All {_capitalizeText(type)} Projects &raquo;
            </Link>
          </Tooltip>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    savedBuildings: Array.isArray(state.buyersLists.savedBuildings)
      ? state.buyersLists.savedBuildings.slice()
      : [],
    dataSet: state.buyersLists.dataSet,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(MyBuyersList));
