import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SortIcon from "mdi-react/SortIcon";
import { Tooltip } from '@material-ui/core';

class MatTableFilterButton extends React.Component {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
  };

  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSort = (orderBy, order) => (event) => {
    const { handleRequestSort } = this.props;
    handleRequestSort(orderBy, order);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div >
        <Tooltip title={`Sort Projects by Different Attributes`}>
          <IconButton
            className=""
            color="inherit"
            aria-owns={anchorEl ? "simple-menu" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          
          >
            <SortIcon color="#DBE0E8" />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <MenuItem
            onClick={this.handleSort("timestamp", "desc")}
            className="material-table__filter-menu-item"
          >
            Unlocked (Newest)
          </MenuItem>
          <MenuItem
            onClick={this.handleSort("timestamp", "asc")}
            className="material-table__filter-menu-item"
          >
            Unlocked (Oldest)
          </MenuItem>
          <MenuItem
            onClick={this.handleSort("first_name", "asc")}
            className="material-table__filter-menu-item"
          >
             Name (A-Z)
          </MenuItem>
          <MenuItem
            onClick={this.handleSort("first_name", "desc")}
            className="material-table__filter-menu-item"
          >
             Name (Z-A)
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default MatTableFilterButton;
