import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import { getFormValues } from 'redux-form';



import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	EstimateProjectStage,
	_getDefaultBuildingPhoto
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT; //'http://localhost:8000/buyerslist/';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
    width: "100%"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth:180
  },
});

const _clickNews = (e, item) => {
	const url = `${item.url}`;
	
	let topic = item.topic[0];	
	
	if(topic === 'stories'){
		if(item.url){
			window.open(item.url);
		}else if(item.data.originId){
			window.open(item.data.originId);
		}
	}
	
	
	
}
class NewsItemView extends Component {


  constructor(props) {
    super(props);
    this.state = { 
	    instagram : false
 
	};
	    
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

  	if(this.state.modal && this.props.modal !== prevProps.modal){
	  	if (window.instgrm || document.getElementById('react-instagram-embed-script')) {
	      if(this.state.instagram == false)
	      {
	        window.instgrm.Embeds.process()
	      }
	    } else {
	
	      // Create script element with Instagram embed JS lib
	      const s = document.createElement('script')
	      s.async = s.defer = true
	      s.src = `//www.instagram.com/embed.js`
	      s.id = 'react-instagram-embed-script'
	      const body: HTMLElement | null = document.body
	      if (body) {
	        body.appendChild(s)
	      }
	
	      // Run Instagram function to show embeds
	      if (window.instgrm && this.state.instagram == false) {
	        window.instgrm.Embeds.process()
	      }
	
	      // Set IG state to true so the process doesn't run again
	      this.setState({
	        'instagram': true
	      });
	    }	
	  	
  	}
	
  	
  }
	
  toggle() {
	//this.snackOpen('Your Message Has Been Sent!', 'success');
	
    this.setState(prevState => ({ modal: !prevState.modal }));
    
  }
   render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, listing, classes, add, del, item, _clickNews, toggle, modal
    } = this.props;
    
 
    
    const { snackShow, snackMessage, snackType, formData, value, justDeleted} = this.state;
	const topic = item.topic && item.topic[0];
	const hasPhoto = ((item.main_photo && !topic) || (item.main_photo && topic == "stories"));	    		
    const content = item.content && topic == "stories" ? item.content : item.article ? item.article : null;
    const contentHasPhoto = content.indexOf("<img") >= 0 ? true : false;
    
    return (
		  	<Fragment>
		  		<span className={"read-article link-text"} onClick={toggle}>Read More</span>
		        <Modal
		          isOpen={modal}
		          toggle={toggle}
		          modalClassName={`ltr-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header ">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
		          </div>
		
						<div className="modal__body background_white modal_news">
							<h4 className="contact__title">{item.title}</h4>
							<h3 className="section-title section-title--not-last subhead"> 
							 	By: {topic == 'stories' && item.company_slugs && item.company_slugs[0] ? _capitalizeText(item.company_slugs[0].replace(/-/g, ' ')) : 'Marketproof Research'}
							 	{item.time && <span> | Date: {moment(item.time).format('MM/DD/YYYY')}</span>}
							 </h3>
							 <div>
							      <span className="timeline__location" onClick={(e) => _clickNews(e, item)}>{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(item.display_full_street_address)}</span>
							      {item.address_2_normalized && 
								    <Fragment>
								      {', '}
								      <span className="timeline__location" onClick={(e) => _clickNews(e, item)}>{item.address_2_normalized}</span>
								    </Fragment>
								  }
							      {` - `}
							      
							      <span className="light-text" style={{color:"#858AA1"}}>
							      	{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
							      </span>
						     </div>
							 <hr/>
							 <div>
							    
									<div className="main-photo"> 
										{item.main_photo && item.event_tags.indexOf('recorded-sale')<0 ?    	 	     
											<div className="timeline__image" style={{ backgroundImage: `url(${item.main_photo}), url(${_getDefaultBuildingPhoto(item)})` }} />
	
										:
											<div className="timeline__image" style={{ backgroundImage: `url(${_getDefaultBuildingPhoto(item)})` }} />
							     	    }
							 		</div>
							    
						      <div className="feed-content">
						      	<div dangerouslySetInnerHTML={{ __html: content }} />
									  
								<div className="mt-3 text-right">{item.url  ? <a className="timeline__link link-text" href={`${item.url}`}  target="blank">Read More <OpenInNewIcon size={16} /></a> 
									  : item.external_url ? <a className="timeline__link link-text" href={`${item.external_url}`} target="blank">Read More <OpenInNewIcon size={16} /></a> 
									  : null}  
								</div>
						      </div>
						      
						       
						     
						 </div>
						 <hr/>
				           <ButtonToolbar className="modal__footer">
				            <Tooltip title="Close">
						        <Button
						        	 onClick={toggle}
							         variant="outlined"
							         color=""
							         className="buyersList__primary-button"		         
							     >	Close
							     </Button>
							      			 
						  	</Tooltip>
				            <Tooltip title="See Project">
						        <Button
						        	 onClick={(e) => _clickNews(e, item)}
							         variant="contained"
							         color="primary"
							         className="buyersList__primary-button"		         
							     >	See This Project
							     </Button>
							      			 
						  	</Tooltip>
						  	
				         </ButtonToolbar>      
				             
						    
				        </div>	
						
		        </Modal>
		    </Fragment>

    );
  }
}


export default (NewsItemView);
