import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "mdi-react/ArrowBackIcon";
import LinkIcon from "mdi-react/LinkIcon";
import DomainIcon from "mdi-react/DomainIcon";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import EditIcon from "mdi-react/PencilIcon";
import moment from "moment";
import OpenIcon from "mdi-react/FileDocumentOutlineIcon";

import { Grid, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { _buildingIDs, _axiosCall } from "../../../../../shared/helpers/apicalls";
import { RTLProps } from "../../../../../shared/prop-types/ReducerProps";

import Analysis from "./../../../../SingleProject/components/Analysis";
import Sales from "./../../../../SingleProject/components/Sales";

import BuyersList from "./../../../../SingleProject/components/BuyersList";
import ProductCard from "./../../../../SingleProject/components/Profile/components/ProfileCard";
import QA from "./../../../../SingleProject/components/QA";

import { bindActionCreators } from "redux";
import buyersListActions from "../../../../../redux/actions/buyersListActions";

import NewsTimeline from "../../../../News/components/NewsTimeline";

import ProjectFeed from "./../../../../SingleProject/components/Feed/";

import LinearLoading from "../../../../../shared/components/LinearLoading";

import Gallery from "./../../../../SingleProject/components/Gallery";

import { Publisher } from "./../../../../../shared/pubsub/publisher.js";


import Permits from "./../../../../SingleProject/components/Permits";
import ProjectProgress from "./../../../../SingleProject/components/Profile/components/ProjectProgress";

import Contacts from "./../../../../SingleProject/components/Contacts";
import Violations from "../../../../BuildingDetail/components/Violations";

import BuildingIcons from "../../../../../shared/components/BuildingIcons";
import OfferingStatus from "../../../../../shared/components/OfferingStatus";

import { _hasPermission } from "../../../../../shared/helpers/access";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  StatusBadge,
  BuildingCurrentStatus,
  BuildingStageBadge,
  propertyExportFL,
  _validUnitSources,
  propertyExportFLRestricted,
  mailingListFL,
  _formatQueensAddress,
  _formatNameAddress,
  GenerateBuildingKey,
  GenerateBuildingSlug,
  BuildingPhotoBuildingPhoto,
  BuildingPhoto,
  _firstLetterUpper
} from "../../../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + "/query/";
const buildingUrl = process.env.API_ROOT + "/pipeline/";

class ShareProject extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  fetchInProgress: false,
	  buildingRes: null,
	};
  }

  q = "";

  componentDidMount() {
	const { buildingRes, user, viewedBuildings, building, sharePageData } = this.props;
	this.setState({buildingRes:sharePageData.building});

	//this._getMongoBuildingData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
	const { user, viewedBuildings, buildingRes } = this.props;
	const { gatedView } = this.state;
	const { viewBuilding } = this.props.actions;
	if (prevProps.match.params.tab !== this.props.match.params.tab) {
	  this.setState({ activeTab: this.props.match.params.tab });
	}

	if (
	  user &&
	  user.subscriptionStatus &&
	  user.subscriptionStatus === "active" &&
	  gatedView === true
	) {
	  this.setState({ gatedView: false });
	  //this._getBuildingOPData();
	  //this._getBuildingTransactionData();
	  //this._getBuildingHPDData();
	  viewBuilding(buildingRes.key, user);
	  Publisher.publish(`track.buildingView.${this.state.activeTab}`, {
		building: buildingRes.key,
	  });
	  Publisher.publish(`track-mp.projectView.${this.state.activeTab}`, {
		user: user,
		project: buildingRes,
	  });
	}

	if (window.instgrm) {
	  console.log("window.instgrm.Embeds.process");
	  window.instgrm.Embeds.process();
	}
  }

  toggle = (tab) => {
	const { buildingRes } = this.props;
	const { activeTab } = this.state;
	if (activeTab !== tab) {
	  const buildingURL =
		_getBuildingURL(buildingRes, "_id") + (tab ? `/tab/${tab}` : "");
	  this.props.history.push(buildingURL);
	  this.setState({
		activeTab: tab,
	  });
	  Publisher.publish(`track.buildingView.${tab}`, {
		building: buildingRes.key,
	  });
	}
  };

  _getMongoBuildingData = () => {
	// console.log('_getBuildingData called', this.state)
	if (!this.state.fetchInProgress) {
	  this.setState((prevState) => ({ fetchInProgress: true }));
	}
	const { user, sharePageData } = this.props;
	const buildingSlug =
	  sharePageData && sharePageData.project_key
		? sharePageData.project_key
		: sharePageData && sharePageData.building_key
		? sharePageData.building_key
		: false;

	if (!buildingSlug) return false;

	let axiosData = {
	  url: buildingUrl + "query",
	  method: "post",
	  query: { query: { key: `${buildingSlug}` } },
	};

	_axiosCall(axiosData)
	  .then((res) => {
		// console.log("_getBuildingData building res back ,", res);

		//if(this._isMounted && res && res.docs && res.docs[0]) {
		if (res && res.docs) {
		  let doc = res.docs[0];
		  //doc = Object.assign(doc, doc['attributes']);
		  //delete(doc['attributes'])
		  //doc = Object.assign(doc, doc['address']);
		  //delete(doc['address']);
		  doc["building_slugs"] = [GenerateBuildingSlug(doc)];
		  doc["solr_key"] = doc.building_key
			? doc.building_key
			: GenerateBuildingKey(doc);
		  doc["master_key"] = doc["key"];

		  this.setState({
			buildingRes: doc,
			fetchInProgress: false,
		  });
		}
	  })
	  .catch((error) => {
		console.log("BlBuildingPage error ", error);
		this.setState({
		  fetchInProgress: false,
		});
	  });
  };

  render() {
	const {
	  rtl,
	  user,
	  viewedBuildings,
	  offeringPlan,
	  setBuilding,
	  fetchInProgress,
	} = this.props;
	const {
	  activeTab,
	  statsRes,
	  unitsRes,
	  gatedView,
	  transactionRes,
	  hpdRes,
	  transactionDocs,
	  buildingRes,
	} = this.state;

	if (fetchInProgress || !buildingRes) {
	  return <LinearLoading />;
	}

	const { buildingNameOrAdr, adr, _boro, _hood } =
	  _formatNameAddress(buildingRes);
	const regex = /(style=".+?")/gm;
	return (
	  <Col md={12} lg={12} xl={12}>
	  	<Row xs={12} md={12} sm={12} lg={12}>
		  <div className="project-top">
	  	  	<BuildingPhoto item={buildingRes} />
				
	  		<div className="project_page_title pl-0">
				<h3 className="page-title">
		  		{buildingNameOrAdr} <BuildingIcons building={buildingRes} />
				</h3>
	
				{isMobile ? (
		  		<Fragment>
					<h3 className="page-subhead subhead subHead_style">
			  		{buildingRes.name ? (
						<span>
				  		{_capitalizeText(adr)}
				  		<br />
						</span>
			  		) : (
						""
			  		)}
			  		{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
					</h3>
		  		</Fragment>
				) : (
		  		<h3 className="page-subhead subhead subHead_style">
					{buildingRes.name ? _capitalizeText(adr) + ", " : ""}
					{buildingRes.name && isMobile && <br />}{" "}
					{_hood ? _capitalizeText(_hood) + ", " : ""}{" "}
					{_capitalizeText(_boro)}
		  		</h3>
				)}
				<div className="unit___project_badge">
		  		<StatusBadge
					status={buildingRes.offering_construction}
					building={buildingRes}
		  		/>
				</div>
		  	</div>
		  </div>
		</Row>
		

		<div className="mt-2">
		  <div className="project-stage">
			  <h3 className="section-title section-title--not-last project_page-heading">
				Project Stage
			  </h3>
			  <h3 className="section-title section-title--not-last subhead"></h3>
			  {/* <hr /> */}
		  
			  <ProjectProgress {...this.props} {...this.state} />
		  </div>
		 
		  <Row>
		  
			<Col xl={7} lg={7} className="details___style">
			  <h3 className="section-title section-title--not-last project_page-heading">
				Project Details
			  </h3>
			  <h3 className="section-title section-title--not-last subhead">
			  {buildingRes &&
			   buildingRes.occupancy_type &&
			   buildingRes.occupancy_type["Project Components"] &&
			   buildingRes.occupancy_type["Project Components"].length > 0 ?
				<span>Project details gathered from a varity of sources such as news, public filiings and more.</span>
			   :
				<span>Project based on available public filiings.</span>
			  }
			  </h3>
			  <ProductCard {...this.props} {...this.state} />
			</Col>
		    <Col xl={5} lg={5} className="summary___style">
			 	{buildingRes.project_summary ? (
				   <Row className="project-summary">
					 <Col xl={12} lg={12} className="mb-4">
					   <h3 className="section-title section-title--not-last project_page-heading">
						 Project Summary
					   </h3>
					   <h3 className="section-title section-title--not-last subhead">
						 {buildingRes.project_summary_author && (
						   <span>By: {buildingRes.project_summary_author}</span>
						 )}
						 {buildingRes.project_summary_date && (
						   <span>
							 {" "}
							 | Updated:{" "}
							 {moment(buildingRes.project_summary_date).format(
							   "MM/DD/YYYY"
							 )}
						   </span>
						 )}
					   </h3>
				 
					   <div
						 dangerouslySetInnerHTML={{
						   __html: buildingRes.project_summary.replace(/_blank/g, "_self").replace(regex, ""),
						 }}
					   />
					 </Col>
				   </Row>
				 ) : buildingRes.pipeline_job_description ? (
				   <Row className="project-summary">
					 <Col xl={12} lg={12} className="mb-4">
					   <h3 className="section-title section-title--not-last project_page-heading">
						 Permit Description
					   </h3>
					   <h3 className="section-title section-title--not-last subhead">
						{buildingRes.pipeline_applicant_business_name ? (
						  <span>Filed By: {_capitalizeText(buildingRes.pipeline_applicant_business_name.replace(/[^\w\s]/gi, ''))}</span>
						) : 
						buildingRes.pipeline_applicant_first_name ? (
						   <span>Filed By: {_capitalizeText(buildingRes.pipeline_applicant_first_name.replace(/[^\w\s]/gi, ''))} {_capitalizeText(buildingRes.pipeline_applicant_last_name.replace(/[^\w\s]/gi, ''))}</span>
						 ) : null
					  
						}
						 {buildingRes.pipeline_filing_date && parseInt(moment(buildingRes.pipeline_filing_date).year()) > 2000 ? (
						   <span>
							 {" "}
							 | Date:{" "}
							 {moment(buildingRes.pipeline_filing_date).format(
							   "MM/DD/YYYY"
							 )}
						   </span>
						 ) : buildingRes.pipeline_pre_filing_date && parseInt(moment(buildingRes.pipeline_pre_filing_date).year()) > 2000 ? (
						   <span>
							 {" "}
							 | Date:{" "}
							 {moment(buildingRes.pipeline_pre_filing_date).format(
							   "MM/DD/YYYY"
							 )}
						   </span>
						 ) : null }
					   </h3>
					   <div>
					   <p
						 dangerouslySetInnerHTML={{
						   __html: _firstLetterUpper(buildingRes.pipeline_job_description)
						 }}
					   />
					   </div>
					 </Col>
				   </Row>
				 ) : null}
			</Col>
			
			
		  </Row>
		</div>
	  </Col>
	);
  }
}

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
	? state.buyersLists.buyersLists.filter(
		(item) => item.priority === state.buyersLists.priorityFilter
	  )
	: state.buyersLists.buyersLists;
  return {
	buyersLists,
	theme: state.theme,
	rtl: state.rtl,
	user: state.user,
	viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings)
	  ? state.buyersLists.viewedBuildings.slice()
	  : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(withRouter(ShareProject)));
