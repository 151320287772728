import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

let rows = [
  {
    id: 'phase', disablePadding: false, disableSort:true, label: 'Phase',
  },
  {
    id: 'display_full_street_address', disablePadding: false, label: 'Address',
  },
/*
  {
    id: 'inventory_pct_unsold', disablePadding: false, label: 'Construction Progress',
  },
*/
  {
    id: 'pipeline_building_stories_proposed', disablePadding: false, label: 'Floors',
  },
  {
    id: 'pipeline_dwelling_units_proposed', disablePadding: false, label: 'Units', type: "residential"
  },
  {
    id: 'pipeline_total_construction_floor_area', disablePadding: false, label: 'Total Area'
  },
  {
    id: 'date_added', disablePadding: false, label: 'Date Added',
  },
  {
    id: 'pipeline_filing_date', disablePadding: false, label: 'Permit Filed',
  },
  {
    id: 'pipeline_permit_issuance_job_start_date', disablePadding: false, label: 'Permit Issued',
  },
  {
    id: 'building_effective_start', disablePadding: false, label: 'CO/TCO Issued',
  },
  {
    id: 'offering_submitted_date', disablePadding: false, label: 'OP Submitted',
  }
  
 
];

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, user, type
    } = this.props;
    
    let filteredRows = (user && user.role.indexOf('admin')>=0) ?  rows : rows.filter(item => !item.admin_only);
    if(type === "commercial"){
		filteredRows = rows.filter(filter => !(filter.type && filter.type === 'residential'));
	}else{
		filteredRows = rows.filter(filter => !(filter.type && filter.type === 'commercial'));
	}
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/*<Checkbox
              className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />*/}
          </TableCell>
          {filteredRows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
             	{row.disableSort ? 
	            	<span>{row.label}</span>	            	
	            	:
	            	<TableSortLabel
		                active={orderBy === row.id}
		                direction={order}
		                onClick={this.createSortHandler(row.id)}
		                className="material-table__sort-label"
		                dir="ltr"
		              >
		                {row.label}
		            </TableSortLabel>
            	}
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(MatTableHead);
