import React, { useState, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { CardBody, Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import { 
	_capitalizeText,
	_getBuildingURL,
	_truncateWords,
	_getDefaultBuildingPhoto,
	_nameToUrl
} from "./../../../../../../shared/helpers/utils";

import NewsItemView from './../../../../../News/components/NewsItemView';


const TimeLineIcon = ({ type, date }) => {
  let Icon;

  switch (type) {
    case 'new-york-yimby':
      Icon = <span className="">NYY</span>;
      break;
    case 'the-new-york-post':
      Icon = <div className="timeline__icon"><img src={`${process.env.PUBLIC_URL}/img/publisher/the-new-york-post.png`} alt="img" /></div>;
      break;
    case 'file':
      Icon = <span className="lnr lnr-file-add" />;
      break;
    default:
      Icon = <span className=""></span>
      break;
  }

  return (
    <div className={`timeline__icon ${type}`}>
      {Icon}
    </div>
  );
};

const _clickNews = (e, item) => {
	
	
	 e.preventDefault();
	 e.stopPropagation();
	 
	 if(item.pipeline_project && item.pipeline_project["_id"]){
		 const buildingURL = _getBuildingURL(item.pipeline_project, '_id');
	 
		 window.location = buildingURL;
		 return false;
	 }
	 
  
	
	
	const url = `${item.url}`;
	
	let topic = item.topic[0];	
	
	if(topic === 'stories'){
		if(item.url){
			window.open(item.url);
		}else if(item.data.originId){
				window.open(item.data.originId);
		}
	}else{
		if(item.address_2_normalized){
			_handleUnitClick(item);
		}else{
			_handleBuildingClick(item);
		}
	}
	
	
	
}

const _handleBuildingClick = (item) => {
	 const buildingURL = _getBuildingURL(item, 'building');
	 window.open(buildingURL);
};

const _handleUnitClick = (item) => {
	const buildingURL = _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
	 //this.props.history.push(buildingURL);
	window.open(buildingURL);
};

const _showExcerpt = (item) => {
	const content = item.content ? item.content.replace(/(<([^>]+)>)/ig," ").replace(/[^\w\s\,\'\’\-\.]/gi, " ") : item.article ? item.article.replace(/(<([^>]+)>)/ig," ") : null;
	
	if(!content) return null;
	
	return (
		<div className="collapse__content" style={{paddingLeft:0, height:"90px", overflow:"hidden"}}>
	      <p>      
	      	
	      	{content && _truncateWords(content, 20).trim()} {' '}
	      	
	      </p>
	      
	    </div>
    )
}

const _translateTopic = (item) => {
	let topic = item.topic[0];
	
	if(topic === 'listings'){
		if(item.event_tags.indexOf('contract-signed')>=0){
			topic = 'contract signed';
		}else if(item.event_tags.indexOf('just-listed')>=0){
			topic = 'just listed';
		}else if(item.event_tags.indexOf('price-increase')>=0){
			topic = 'price increase';
		}else if(item.event_tags.indexOf('price-decrease')>=0){
			topic = 'price decrease';
		}
		
		if(item.event_tags.indexOf('rent')>=0){
			topic = 'for rent - ' + topic;
		}else{
			topic = 'for sale - ' + topic;
		}
		
		
	}else if(topic === 'transactions'){
		if(item.event_tags.indexOf('recorded-sale')>=0){
			
			topic = 'recorded sale';
		}else{
			topic = 'sold - not yet recorded';
		}
		
	}else if(topic === 'coo'){
		topic = 'Certifcate of Occupancy';	
	}else if(topic === 'stories'){
		topic = 'Article';	
	}else if(topic === 'offeringplans'){
		topic = 'Offering Plan';	
	}
	
	return topic;
}

TimeLineIcon.propTypes = {
  type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
  type: '',
};

const NewsItem = ({
  type, img, title, date, children, item, history, key
}) => {
	
	const [ modal, setModal ] = useState(false);
	const topic = item.topic[0];
	
	const toggle = () => {
	
    	setModal(!modal);
    }
	
	return(
		  <div className="timeline__item" key={key}>
		   {/* <TimeLineIcon type={item.company_slugs[0]} date={moment(item.time).fromNow()} /> */}
		    <CardBody className="timeline__content timeline__clickable" onClick={toggle}>
		     <Row>
		     	 {item.main_photo && item.event_tags.indexOf('recorded-sale')<0 ?    	 	     
			      <Col sm={12} md={12} lg={12}>
			      	<div className="timeline__image" style={{height:200, backgroundImage: `url(${item.main_photo}), url(${_getDefaultBuildingPhoto(item)})` }}  />
			      </Col>
			      :
			      <Col sm={12} md={12} lg={12}>
			      	<div className="timeline__image" style={{height:200, backgroundImage: `url(${_getDefaultBuildingPhoto(item)})` }}  />
			      </Col>
			     }
			      <Col sm={12} md={12} lg={12}>
			      	  <div className="subhead" style={{marginTop:10}}>
			      	  	<div className="topic__tag">{_translateTopic(item)}</div>
				  	  	<h4 className="timeline__date">{moment(item.time).fromNow()}</h4>
				  	  </div>
				  	  {topic == 'stories' ?
					  	  <h4 className="timeline__title" >{item.title}</h4>
					  	  :
					  	  <Fragment>
					  	    {['permits', 'listings', 'transactions'].indexOf(topic)>-1 ? 
						  	    <h4 className="timeline__title" >{item.headline1}: {item.headline2}</h4>
						  	    :
						  		<h4 className="timeline__title" >{item.headline3}</h4>
						  	}
					  	  	
					  	  </Fragment>
				      }
				      <div className="timeline__publisher newtitle__style">{topic == 'stories' && item.company_slugs && item.company_slugs[0] ? _capitalizeText(item.company_slugs[0].replace(/-/g, ' ')) : 'Marketproof Research'}</div>
				      <div>
					      <span className="timeline__location" >{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(item.display_full_street_address)}</span>
					      {item.address_2_normalized && 
						    <Fragment>
						      {', '}
						      <span className="timeline__location" >{item.address_2_normalized}</span>
						    </Fragment>
						  }
					      <br/>
					      
					      <span className="light-text" style={{color:"#858AA1"}}>
					      	{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
					      </span>
				      </div>
				      {_showExcerpt(item)}
				      <NewsItemView item={item} _clickNews={_clickNews} toggle={toggle} modal={modal} />
				  </Col>
			  </Row>
		    </CardBody>
		  </div>
)};

NewsItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.element.isRequired,
};

NewsItem.defaultProps = {
  type: '',
  img: '',
  title: '',
  date: '',
};

export default withRouter(NewsItem);
