import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "mdi-react/ArrowBackIcon";
import LinkIcon from "mdi-react/LinkIcon";
import DomainIcon from "mdi-react/DomainIcon";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import EditIcon from "mdi-react/PencilIcon";
import ContractIcon from "mdi-react/ClipboardCheckOutlineIcon";
import SoldIcon from "mdi-react/CurrencyUsdCircleOutlineIcon";
import LinearLoading from "../../../shared/components/LinearLoading";
import { Grid } from "@material-ui/core";

import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";

import { bindActionCreators } from "redux";
import buyersListActions from "../../../redux/actions/buyersListActions";

import Profile from "../../SingleOrganization/components/Profile";
import Contacts from "./Contacts";

import OrganizationProfile from "./../../SingleOrganization/components/Profile";
import AddToBuyersListButton from "../../../shared/components/buttons/AddToBuyersListButton";

import EditContact from "../../../shared/components/edit/EditContact";

import TrialLimit from "../../../shared/components/payment/TrialLimit";

import UnitIcons from "../../../shared/components/UnitIcons";

import { Publisher } from "./../../../shared/pubsub/publisher.js";

import BuildingIcons from "../../../shared/components/BuildingIcons";
import OfferingStatus from "../../../shared/components/OfferingStatus";

import MatTable from "./../../PipelineBuildings/components/MatTable";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _formatUnitNumber,
  _urlToName,
  _getBuildingURL,
  _nameToUrl,
  StatusBadge,
  UnitCurrentStatus,
  propertyFL,
  _isResiUnit,
  _getContactURL,
  ProfileAvatar,
  ProfileAvatarExtraLarge,
  _PickContactOrg
} from "../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + "/query/";

class ContactTabContainer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        this.props.match && this.props.match.params.tab
          ? this.props.match.params.tab
          : "overview",
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    console.log(this.props.match);
    const { contactRes, user } = this.props;
    Publisher.publish(`track.contactView.${this.state.activeTab}`, {
      organization: contactRes.key
    });
    Publisher.publish(`track-mp.contactView.${this.state.activeTab}`, {user : user, contact: contactRes});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps, this.props);
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      this.setState({ activeTab: this.props.match.params.tab });
    }
  }

  toggle = (tab) => {
    const { contactRes } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      const newUrl =
        _getContactURL(contactRes, "key") + (tab ? `/tab/${tab}` : "");
      this.props.history.push(newUrl);
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { contactRes, setChanged, organizationRes } = this.props;
    const { activeTab } = this.state;
    const roles = [];
    
    if (
      contactRes &&
      contactRes.project_roles &&
      Object.keys(contactRes.project_roles).length > 0
    ) {
      Object.keys(contactRes.project_roles).forEach((key) => {
        const p = contactRes.project_roles[key];
        p.map((r) => {
          r =
            r === "applicant"
              ? "permit applicant"
              : r === "filing_applicant"
              ? "permit applicant"
              : r;
          if (roles.indexOf(r) < 0) {
            roles.push(r);
          }
        });
      });
    }
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="p-0">
            <div className="card__title">
              <div className="card_button">
                <div className="top__toolbar d-flex">
                  <AddToBuyersListButton
                    contactKey={contactRes.key}
                    type={"contact"}
                  />
                  <EditContact
                    item={contactRes}
                    setChanged={setChanged}
                    single
                    edit
                  />
                  <EditContact
                    item={contactRes}
                    setChanged={setChanged}
                    single
                    del
                  />
                </div>
              </div>
              <div className="new_card_title">
                <img src="/img/company_header.svg" />
                <div className="contact_logo_image">
                  <div className="unit__badge">
                    {/* {organizationRes &&
                    organizationRes.logo &&
                    organizationRes.logo[0] &&
                    organizationRes.logo[0].preview ? (
                      <div
                        className="company_logo"
                        style={{
                          backgroundImage: `url(${organizationRes.logo[0].preview})`,
                        }}
                      />
                    ) : organizationRes.project_keys &&
                      organizationRes.project_keys.length == 1 ? (
                      <span>
                        {_capitalizeText(
                          _PickOrgName(
                            organizationRes,
                            organizationRes.project_keys[0]
                          )
                        )}
                      </span>
                    ) : (
                      <span>{organizationRes.name}</span>
                    )} */}
                    <div
                      className="contact_header"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="image">
                        <ProfileAvatarExtraLarge contact={contactRes} large />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <Grid
              item
              xs={12}
              className="contact_page_common_margin"
              style={{ marginTop: "80px" }}
            >
              <div>
                <h3 className="contact_page-title">{contactRes.name}</h3>
                {contactRes && contactRes.title && (
                   
                    <h5 className="mt-2">{_capitalizeText(contactRes.title)}</h5>
                )}
                <Row className="contact_head_margin">
                  <Col xl={6} lg={6} md={6}>
                    {_PickContactOrg(contactRes) &&
                    _PickContactOrg(contactRes).name ? (
                      <div className="contact_dev_margin">
                      <h5 className="com_head_weight">Company:</h5>
                      <p>
                      <Link
                        to={`/company/${
                          _PickContactOrg(contactRes)["key"]
                        }`}
                        className="product__external-link com_product_link_size"
                      >
                        {_capitalizeText(_PickContactOrg(contactRes).name)}
                        
                      </Link>
                      </p>
                      </div>
                    ) : organizationRes && organizationRes.name ? (
                      <div className="contact_dev_margin">
                        <h5 className="com_head_weight">Company:</h5>
                        <p>
                          <Link
                            to={`/company/${organizationRes["key"]}`}
                           
                            className="product__external-link com_product_link_size"
                          >
                            {_capitalizeText(organizationRes.name)}
                          </Link>
                        </p>
                      </div>
                    ) : (
                      null
                    )}
                    
                  </Col>
                  <Col xl={6} lg={6} md={6}>
                    {roles.length > 0 ? (
                      <div className="contact_dev_margin">
                        <h5 className="com_head_weight">Project Roles:</h5>
                        <p className="com_pera_style">
                          {roles.filter(u => typeof u === 'string').map((u) => (
                            <Badge style={{ marginRight: 5 }} color="shadow">
                              {_capitalizeText(u.toString().replace("_", " "))}
                            </Badge>
                          ))}
                        </p>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Grid>

            <div className="tabs tabs--bordered-bottom tabs_header-set">
              <div className="tabs__wrap">
                <Nav tabs className="">
                  <NavItem className="navBar_title">
                    <NavLink
                      className={classnames({
                        active: activeTab === "overview",
                      })}
                      onClick={() => {
                        this.toggle("overview");
                      }}
                    >
                      <span className="tab-title">Overview</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navBar_title">
                    <NavLink
                      className={classnames({
                        active: activeTab === "projects",
                      })}
                      onClick={() => {
                        this.toggle("projects");
                      }}
                    >
                      <span className="tab-title">Projects</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            {activeTab === "overview" && (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="overview">
                  <Grid item className="contact_page_common_margin" xs={12}>
                    <Profile {...this.props} personPage={true} />
                    {/* personPage props added for seprate contact page ui */}
                  </Grid>

                  <hr />
                  
                  <Grid item xs={12} className="pl-4 pr-4 pb-5">
                    <div className={"d-flex"}>
                      <div>
                        <h3 className="section-title section-title--not-last contact_page_pro_heading">
                          Projects
                        </h3>
                        <h3 className="section-title section-title--not-last subhead contact_page_pro_sub-heading">
                          Recent projects associated with {contactRes.name}{" "}
                        </h3>
                      </div>
                    </div>
                  <Row>
                    <Col md={12}>
                      <MatTable
                        contactRes={contactRes}
                        toggle={this.toggle}
                        isSingle
                        min
                      />
                    </Col>
                  </Row>
                  </Grid>
                </TabPane>
              </TabContent>
            )}

            {activeTab === "projects" && (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="projects">
                  <Grid item xs={12} className="pl-4 pr-4">
                  <MatTable contactRes={contactRes} isSingle />
                  </Grid>
                </TabPane>
              </TabContent>
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings)
      ? state.buyersLists.viewedBuildings.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(withRouter(ContactTabContainer)));
