import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Progress,
  Badge,
  Row,
  Container,
  Modal,
} from "reactstrap";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import UnitsSearchTableHead from "./UnitsSearchTableHead";
import MatTableToolbar from "./MatTableToolbar";
import UnitsListTable from "./UnitsListTable";
import LinearLoading from "../../../shared/components/LinearLoading";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import BuildingSearchAppBar from "../../../shared/components/building_filters/UnitsSearchAppBar";
import UnitsSearchAppBar from "./UnitsSearchAppBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

//icons
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import MapViewIcon from "mdi-react/MapOutlineIcon";
import CertificateOutlineIcon from "mdi-react/CertificateIcon";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import ContactIcon from "mdi-react/EmailOutlineIcon";
import BuildingIcon from "mdi-react/HomeCityOutlineIcon";
import ListIcon from "mdi-react/HomeVariantOutlineIcon";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { Tooltip } from "@material-ui/core";
import queryString from "query-string";
import moment from "moment";

import GroupedUnitsSearchTableHead from "./GroupedUnitsSearchTableHead";

import BuildingIcons from "../../../shared/components/BuildingIcons";
import OfferingStatus from "../../../shared/components/OfferingStatus";
import UnitStageFilter from "../../../shared/components/unit_filters/UnitStageFilter";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import SalesMapView from "../../../shared/components/map/SalesMapView";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _formatBed,
  propertyFL,
  SalesProgress,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  mpndCohortQuery,
  syncGenericFilter,
  _objectEqual,
  salesExportFL,
  getCohortQuery,
} from "../../../shared/helpers/utils";

import {
  _salesFilters,
  _buildingStageFilter,
  _unitSalesFilter,
} from "../../../shared/helpers/filters";
import Project from "../../Pipeline/components/Project";
import BuildingDetail from "./../../BuildingDetail";
import SalesStageFilter from "../../../shared/components/unit_filters/SalesStageFilter";
import { getBoundFromUrl } from "../../../shared/helpers/mapUtil";

const buildingUrl = process.env.API_ROOT + "/query/";

let lsPre = "S1_";
const subFilterKey = "subFilters";
const unitStagesKey = "unitStages";
const buildingStagesKey = "buildingStages";
const defaultStage = ["Show Unit Sales"];
const defaultSubFilter = {
  min_date: null,
  max_date: null,
};

const groupedView = false; //syncGenericFilter('group_view', lsPre, 'groupedView', false);

function mapStateToProps(authData) {
  return {
    authData,
  };
}

class UnitsSearchTable extends PureComponent {
  defaultUnitStage = this.props.buildingView
    ? []
    : ["Show Unit Sales"];

  state = {
    order:
      !this.props.buildingView && groupedView === true
        ? syncGenericFilter("order", lsPre, "order", "desc")
        : syncGenericFilter("order", lsPre, "order", ""),
    orderBy:
      !this.props.buildingView && groupedView === true
        ? syncGenericFilter("order_by", lsPre, "orderBy", "count")
        : syncGenericFilter("order_by", lsPre, "orderBy", ""),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    clearInput: false,
    end: 30,
    filterBorough: "all_boroughs",
    searchText: syncURLSearch(this.props, "searchText"),
    viewType: this.props.buildingView
      ? "list"
      : syncGenericFilter(
          "viewType",
          lsPre,
          "viewType",
          getLocalStorage(lsPre, "viewType") || "list"
        ),
    futureInventoryData: false,
    inventoryData: false,
    originalData: false,
    ovelayMinHeight: false,
    reactTableFiltered: [],
    showCounts: { desktop: 3, tablet: 2, mobile: 1 },
    hoodTags: [],
    boroTags: [],
    start: 0,
    stats: {},
    subFilters: !this.props.buildingView
      ? syncURLFilters(
          this.props,
          _salesFilters,
          subFilterKey,
          lsPre,
          defaultSubFilter
        )
      : syncURLFilters(this.props, _salesFilters, false, false, false),
    tableData: false,
    totalUnsoldUnits: false,
    totalCount: 0,
    groupedView: this.props.buildingView ? false : groupedView, //getLocalStorage(lsPre, 'groupedView') === false ? false : true,
    groupedData: false,
    groupedCount: false,
    buildingKey: false,
    unitListData: false,
    modal: false,
    trxnRes: false,
    width: window.innerWidth,
    height: window.innerHeight,
    boroughs: ["Brooklyn", "Bronx", "Manhattan", "Staten Island", "Queens"],
    majorMarkets: {},
    neighborhoods: [],
    authData: {
      appState: { viewType: "map" },
      authenticated: false,
      idToken: null,
      myFeed: { isMyFeed: false },
      userData: { ...this.props.user },
    },
    unitStages: !this.props.buildingView
      ? syncStageFilters(
          this.props,
          _unitSalesFilter,
          unitStagesKey,
          lsPre,
          this.defaultUnitStage
        )
      : syncStageFilters(
          this.props,
          _unitSalesFilter,
          false,
          false,
          this.defaultUnitStage
        ),
    buildingStages: [],
    mapBounds: getBoundFromUrl(),
    mobileMapMoving: false,
    preUrl: "",
  };

  defaultQ =
    (this.props.building &&
      this.props.building.offering_verified_status === "active") ||
    !this.props.buildingView
      ? "" //"(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:*)"
      : "*:*"; //-address_2_normalized:""';

  defaultBuidingQ = getCohortQuery(this.props.user);

  _propertyFilter = _salesFilters.filter((filter) => !filter.filter_type);
  _buildingFilter = _salesFilters.filter(
    (filter) => filter.filter_type && filter.filter_type === "building"
  );

  q = "";
  fq = this.props.buildingView
    ? [
        this.props.building && this.props.building.alternate_addresses
          ? `alternate_addresses:("${this.props.building.alternate_addresses.join(
              '" OR "'
            )}") AND zip:${this.props.building.zip}`
          : `alternate_addresses:("${this.props.building.address.alternate_addresses.join(
              '" OR "'
            )}") AND zip:${this.props.building.address.zip}`,

        `status:sold`,
        `transaction_source:ACRIS*`
      ]
    : [
        //`offering_verified_status:active`,
        `status:sold`,
        `transaction_source:ACRIS*`,
      ];
  sort = "";
  unitFq = null;

  fl = propertyFL;
  _unitSubFilter = _salesFilters.filter(
    (filter) => !(this.props.buildingView && filter.building_hide)
  );

  componentDidMount() {
    window.addEventListener("resize", this._handleWindowSizeChange);
    this._getInventoryUnits();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let unitStageChange = !_isEqual(
      prevState.unitStages,
      this.state.unitStages
    );
    let buildingStageChange = !_isEqual(
      prevState.buildingStages,
      this.state.buildingStages
    );

    let subFilterChanged =
      this._unitSubFilter.filter((item) => {
        return Array.isArray(item.search_key)
          ? item.search_key.filter(
              (key) => this.state.subFilters[key] !== prevState.subFilters[key]
            ).length > 0
          : this.state.subFilters[item.search_key] !==
              prevState.subFilters[item.search_key];
      }).length > 0;
    if (
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.searchText !== prevState.searchText ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.page !== prevState.page ||
      this.state.rowsPerPage !== prevState.rowsPerPage ||
      this.state.groupedView !== prevState.groupedView ||
      (this.state.buildingKey !== prevState.buildingKey &&
        this.state.buildingKey !== false) ||
      searchTagsChange ||
      subFilterChanged ||
      unitStageChange ||
      buildingStageChange
    ) {
      const resetPage =
        this.state.page === prevState.page ||
        this.state.rowsPerPage !== prevState.rowsPerPage;
      console.log("component update", prevState, this.state);
      this.defaultBuidingQ = getCohortQuery(this.props.user);
      this._getInventoryUnits(resetPage);
    }
  }

  componentWillUnmount() {
    console.log("LS unmounting");
    window.removeEventListener("resize", this._handleWindowSizeChange);
  }

  _handleWindowSizeChange = () => {
    // console.log('handleWindowSizeChange fired', window.innerWidth)
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleBuildingClick = (event, trxn) => {
    const { buildingView} = this.props;
    if(!buildingView){
      this.setState({ modal: true, trxnRes: trxn });
    }
    
  };

  handleBuildingPreviewClick = (event, building) => {
    const buildingURL = _getBuildingURL(building, "id");

    window.open(buildingURL);
  };

  handleGroupedBuildingClick = (event, building) => {
    event.stopPropagation();
    console.log(building);
    const buildingURL = _getBuildingURL(building, "key");

    this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  handleExpandPanel(e, expanded, buildingKey) {
    if (expanded) {
      this.setState({ buildingKey: buildingKey });
    }
  }

  toggle() {
    //this.snackOpen('Your Message Has Been Sent!', 'success');
    this.setState((prevState) => ({ modal: !prevState.modal }));
    this.props.history.push(`/transfers${this.state.preUrl}`);
  }

  _getSpecificFilter = (key) => {
    let filter =
      _salesFilters &&
      _salesFilters.filter(
        (filter) =>
          filter.search_key === key ||
          (filter.search_key && filter.search_key.includes(key))
      )[0];
    return filter;
  };

  _toggleView = (type) => {
    this.setState({ viewType: type });
    setURLFromState(this.props, this.state, type);
    setLocalStorage(lsPre, "viewType", type);
  };

  _getInventoryUnits = async (resetPage) => {
    this.q = this.defaultQ;
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({
        fetchInProgress: true,
        groupedData: false,
        tableData: false,
        reactTableFiltered: false,
      }));
    }

    const { searchTags } = this.props;
    const { orderBy, order, unitStages, buildingStages } = this.state;
    let locQuery = _tranformTagsQuery(searchTags, "building");

    this.unitFq = this.fq.slice();

    /*
		if (this.state.filterBorough !== "all_boroughs") {
			this.q += `(borough:"${this.state.filterBorough
				.replace("_", " ")
				.toLowerCase()}")`;
		}
*/
    if (locQuery && !this.props.buildingView) {
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += `${locQuery}`;
    }

    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          const theFilter = this._propertyFilter.filter(
            (filter) => filter.search_key === key
          );
          let qVal = null;

          if (theFilter && theFilter[0] && theFilter[0].text_filter) {
            qVal = subFilterVal
              .split(" ")
              .map((term) => {
                return `(${key}:*${term.toLowerCase()}*)`;
              })
              .join(" AND ");

            if (qVal !== null) {
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${qVal})`;
            }
          } else if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
            const qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => subFilterVal.indexOf(value.filter) > -1
              ).length > 0
                ? theFilter[0].values
                    .filter((value) => subFilterVal.indexOf(value.filter) > -1)
                    .map((value) => value.search_value)
                : null;
            if (qVal !== null) {
              this.q += this.q.length > 0 ? " AND " : "";
              this.q += `(${key}:(${qVal.join(" ")}))`;
            }
          } else {
            qVal =
              theFilter.length > 0 &&
              theFilter[0].values.length > 0 &&
              theFilter[0].values.filter(
                (value) => value.filter === subFilterVal
              ).length > 0
                ? theFilter[0].values.filter(
                    (value) => value.filter === subFilterVal
                  )[0].search_value
                : null;
            if (qVal !== null) {
              if (theFilter[0].target && theFilter[0].target === "fq") {
                this.unitFq.push(qVal);
              } else {
                this.q += this.q.length > 0 ? " AND " : "";
                this.q += `(${key}:${qVal})`;
              }
            }
          }
        }
      });
    }
    const isAvailable =
      this.state.subFilters.current_status &&
      ["available", "active", "shadow"].indexOf(
        this.state.subFilters.current_status
      ) >= 0;

    /****Price & Date query***/
    let priceQ = "";
    let dateQ = "";
    let replaceKeys = [];
    let dateReplaceKeys = [];
    if (
      this.state.subFilters &&
      Object.keys(this.state.subFilters).length > 0
    ) {
      let query_key,
        queryCount = {};
      Object.keys(this.state.subFilters).map((key) => {
        let subFilterVal = this.state.subFilters[key];

        const filter = this._getSpecificFilter(key);
        query_key = filter && filter.filter_key;

        if (filter && filter.format_type && !filter.date_filter) {
          queryCount[key] = parseInt(subFilterVal);
          if (query_key in queryCount) {
            queryCount[query_key] += 1;
          } else {
            queryCount[query_key] = 1;
            queryCount[query_key + "Q"] = "";
          }
          const qArrayFilter = this._propertyFilter.filter(
            (filter) =>
              filter.price_filter &&
              Array.isArray(filter.search_key) &&
              filter.search_key.filter((skey) => skey === key).length > 0
          );
          if (qArrayFilter.length > 0) {
            if (queryCount[query_key + "Q"]) {
              let regex = new RegExp(`{${key}}`, "g");
              if (String(subFilterVal) == "NaN" || subFilterVal == "0") {
                queryCount[query_key + "Q"] = queryCount[
                  query_key + "Q"
                ].replace(regex, "*");
              } else {
                queryCount[query_key + "Q"] = queryCount[
                  query_key + "Q"
                ].replace(regex, subFilterVal);
              }
            } else {
              if (String(subFilterVal) == "NaN" || subFilterVal == "0") {
                queryCount[query_key + "Q"] = qArrayFilter[0].search_value
                  .map((search) => search.replace(`{${key}}`, "*"))
                  .join(" OR ");
              } else {
                queryCount[query_key + "Q"] = qArrayFilter[0].search_value
                  .map((search) => search.replace(`{${key}}`, subFilterVal))
                  .join(" OR ");
              }
            }
          }
          if (
            queryCount[query_key] === 2 &&
            !queryCount[query_key + "Q"].includes("[* TO *]") &&
            !queryCount[query_key + "Q"].includes("[ TO ]")
          ) {
            this.q += this.q.length > 0 ? " AND " : "";
            this.q += "(";
            this.q += queryCount[query_key + "Q"];
            this.q += ")";
          }
        }

      
        if (subFilterVal && subFilterVal != "") {
          const dateArrayFilter = this._propertyFilter.filter(
            (filter) =>
              filter.date_filter &&
              Array.isArray(filter.search_key) &&
              filter.search_key.filter((skey) => skey === key).length > 0
          );

          if (dateArrayFilter.length > 0) {
            dateReplaceKeys = [
              ...dateReplaceKeys,
              ...dateArrayFilter[0].search_key,
            ];
            if (dateQ) {
              let regex = new RegExp(`{${key}}`, "g");
              dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString());
            } else {
              if (subFilterVal !== "0") {
                dateQ = dateArrayFilter[0].search_value
                  .map((search) =>
                    search.replace(
                      `{${key}}`,
                      moment(subFilterVal).toISOString()
                    )
                  )
                  .join(" OR ");
              }
            }
          }
        }
      });
    }

    if (dateQ) {
      dateReplaceKeys.map((key) => {
        let regex = new RegExp(`{${key}}`, "g");
        dateQ = dateQ.replace(regex, "*");
      });
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += "(";
      this.q += dateQ;
      this.q += ")";
    }
    /****Price & Date query***/
    if (unitStages && unitStages.length > 0) {
      if (unitStages.indexOf("Show Unit Sales") < 0) {
        this.unitFq.splice("Show Unit Sales", 1);
      } else {
        this.unitFq.push('address_2_normalized:"" AND -property_type:(sc sp)');
      }
    }

    setURLFromState(this.props, this.state);

    if (this.state.searchText && this.state.searchText.length > 0) {
      const text = this.state.searchText.replace(/ /g, "*").toLowerCase();
      const textCaps = _capitalizeText(
        this.state.searchText.toLowerCase(),
        false
      ).replace(/ /g, "*");
      this.q += this.q.length > 0 ? " AND " : "";
      this.q += this.props.buildingView
        ? `(address_2_normalized:apt*${text}* OR (-address_2:apt* AND address_2:*${text}*))`
        : `(building_name:(*${text}* OR *${textCaps}*)  OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
    }
    this.q = this.q.length > 0 ? this.q : "*:*";

    let sort = this.state.groupedView
      ? "count desc"
      : "close_date_formatted desc";

    if (orderBy && orderBy.length > 0) {
      if (orderBy === "address_2_normalized") {
        sort = `floor ${order}, ${orderBy} ${order}`;
      } else {
        sort = `${orderBy} ${order}`;
      }
      if (orderBy && orderBy !== "count") {
        this.sort = sort;
      }
      if (orderBy === "recorded_datetime") {
        this.unitFq.push(`recorded_datetime:*`);
      } else if (orderBy === "discount") {
        this.unitFq.push(`discount:[-100 TO 100]`);
      } else if (orderBy === "percent_financed") {
        this.unitFq.push(`percent_financed:[.01 TO 100]`);
      } else if (orderBy === "days_to_contract") {
        this.unitFq.push(`days_to_contract:[1 TO 1460]`);
      }
    }

    this.sort = !this.state.groupedView ? sort : null;

    let axiosData = {
      url: buildingUrl + "we3-transactions?cache=60",
      method: "post",
      query: {
        q: this.q,
        fq: this.unitFq,
        sort: !this.state.groupedView ? sort : null,
        //fl: this.fl,
        rows: this.state.rowsPerPage,
        start: resetPage ? 0 : this.state.start,
        wt: "json",

        /*
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"group.field":"building",
				"group.sort":"offering_price asc",
				"group.ngroups": true,
				"group":this.state.groupedView,	
*/
      },
    };

    if (this.state.groupedView) {
      axiosData.query.rows = 0;
      axiosData.query.start = 0;
      axiosData.query["json.facet"] = {
        building_count: "unique(building)",
        by_building: {
          type: "terms",
          field: "building",
          sort: sort,
          offset: resetPage ? 0 : this.state.start,
          limit: this.state.rowsPerPage,
          facet: {
            non_zero_price: {
              type: "query",
              q: "offering_price:[1 TO *] AND offering_bedrooms:[0 TO *]",
              facet: {
                low_price: "min(price)",
                high_price: "max(price)",
                average_price: "avg(price)",
                low_sq_ft: "min(sq_ft)",
                high_sq_ft: "max(sq_ft)",
                low_bed: "min(bedrooms)",
                high_bed: "max(bedrooms)",
              },
            },
            /*
							"by_beds" : {
								"type" : "terms",
								"field" : "bedrooms"
							},
*/
            avg_price: "avg(price)",
            avg_sq_ft: "avg(price)",
            low_bed: "min(bedrooms)",
          },
        },
      };
    }

    let response = await _axiosCall(axiosData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    if (this.state.groupedView) {
      let formattedDataBuilding =
        response &&
        response.facets &&
        response.facets.by_building &&
        response.facets.by_building.buckets
          ? response.facets.by_building.buckets.slice(0)
          : false;

      const _matchedBuildingIds = [];

      if (formattedDataBuilding) {
        formattedDataBuilding.map((item) => {
          /*
					if(item.val === 'b-3-280-cadman-plz-w-11201'){
						item.val = 'b-3-1-clinton-st-11201';
					}
*/

          _matchedBuildingIds.push(item.val);
        });
      }

      if (_matchedBuildingIds.length > 0) {
        this._getInventoryBuildings(
          _matchedBuildingIds.join(" "),
          function (res) {
            if (res && res.docs) {
              res.docs.map((building) => {
                formattedDataBuilding.filter(
                  (item) => item.val === building.key
                )[0]["building"] = building;
              });
            }

            this.setState((prevState) => ({
              fetchInProgress: false,
              groupedData: formattedDataBuilding,
              groupedCount: response.facets.building_count, //response.building.ngroups,
              totalCount: response.numFound,
              start: resetPage ? 0 : this.state.start,
              page: resetPage ? 0 : this.state.page,
            }));
          }.bind(this)
        );
      } else {
        this.setState((prevState) => ({
          fetchInProgress: false,
          groupedData: [],
          groupedCount: 0, //response.building.ngroups,
          totalCount: 0,
          start: resetPage ? 0 : this.state.start,
          page: resetPage ? 0 : this.state.page,
        }));
      }
    } else {
      let formattedData =
        response && response.docs
          ? response && response.docs && response.docs.slice(0)
          : this.state.reactTableFiltered;

      let docs =
        formattedData &&
        formattedData.map((doc, i) => {
          let item = Object.assign({}, doc);
          return item;
        });

      this.setState((prevState) => ({
        fetchInProgress: false,
        tableData: docs,
        reactTableFiltered: docs,
        totalCount: response.numFound,
        groupedCount: false,
        start: resetPage ? 0 : this.state.start,
        page: resetPage ? 0 : this.state.page,
      }));
    }
  };

  _getInventoryBuildings = async (_matchedBuildingIds, callback) => {
    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }
    var fq = ["key:(" + _matchedBuildingIds + ")", "deleted:false"];

    let axiosData = {
      url: buildingUrl + "we3-buildings?cache=15",
      method: "post",
      query: {
        q: "*:*",
        fq: fq,
        sort: "inventory_unsold desc",
        fl: buildingFL,
        wt: "json",
        rows: 999,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (typeof callback === "function") {
          let formattedRes = callback(res);
          return formattedRes;
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _formatStats = (response, redoHiddenFields = false) => {
    console.log(
      "_formatStats response",
      response,
      "redoHiddenFields",
      redoHiddenFields
    );
    console.log("_formatStats state", this.state);
    if (!response && !redoHiddenFields) {
      return;
    }
  };

  _handleMapBounds = (mapBounds) => this.setState({ mapBounds, page: 0 });

  _filterTableByInput = (name, eValue) => {
    console.log("_filterTableByInput name", name, "eValue", eValue);
    this.setState({ searchText: eValue });
  };

  _filterColumn = (columnId, value, display) => {
    const newfilter = {
      display: display,
      id: columnId,
      value,
    };

    const filtersWhithoutNew = this.state.reactTableFiltered.filter(
      (item) => item.id !== columnId
    );
    this.setState({
      reactTableFiltered: [...filtersWhithoutNew, newfilter],
    });
  };

  _setSubFilter = (search_key, search_val) => {
    const subF = Object.assign({}, this.state.subFilters, {
      [search_key]: search_val,
    });
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  };

  _setSubFilters = (subFilters) => {
    const subF = Object.assign({}, this.state.subFilters, subFilters);
    this.setState({
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
  };

  _setBuildingStage = (stage) => {
    let newStages = this.state.buildingStages.slice();
    const index = newStages.indexOf(stage);
    const indexAll = newStages.indexOf("Show Unit Sales");

    if (index >= 0) {
      if (stage === "Show Unit Sales") {
        newStages = defaultStage;
      } else {
        newStages.splice(index, 1);
      }
    } else {
      if (stage === "Show Unit Sales") {
        newStages = ["Show Unit Sales"];
      } else {
        if (indexAll >= 0) {
          newStages.splice(indexAll, 1);
        }
        newStages.push(stage);
      }
    }

    if (newStages.length === 0) newStages = ["all"];

    this.setState({
      buildingStages: newStages,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
  };

  _setUnitStage = (stage) => {
    let newStages = this.state.unitStages.slice();
    // const index = newStages.indexOf(stage);
    // if (index >= 0) {
    if (stage === "Show Unit Sales") {
      newStages = [];
    } else {
      newStages = ["Show Unit Sales"];
    }
    // }else{
    //   newStages = []
    // }
    this.setState({
      unitStages: newStages,
      start: 0,
      end: this.state.rowPerPage,
    });
    setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
  };

  _resetSubFilter = (search_key, search_val) => {
    const subF = !this.props.buildingView
      ? Object.assign({}, defaultSubFilter)
      : {};
    this.setState({
      buildingStages: this.props.buildingView ? defaultStage : [],
      unitStages: this.defaultUnitStage,
      subFilters: subF,
      start: 0,
      end: this.state.rowPerPage,
    });
    deleteLocalStorage(lsPre, buildingStagesKey);
    deleteLocalStorage(lsPre, unitStagesKey);
    deleteLocalStorage(lsPre, subFilterKey);
  };

  _resetActive = () => {
    let isDefault = true;
    if (this.props.buildingView) {
      isDefault =
        Object.keys(this.state.subFilters).length <= 0 &&
        _objectEqual(this.defaultUnitStage, this.state.unitStages);
    } else {
      isDefault =
        this.props.searchTags.length === 0 &&
        _objectEqual(defaultSubFilter, this.state.subFilters) &&
        _objectEqual(this.defaultUnitStage, this.state.unitStages) &&
        _objectEqual(defaultStage, this.state.buildingStages);
    }

    return !isDefault;
  };

  _setCurrentListing = (urlObj, listing) => () => {
    this.setState({
      modal: true,
      trxnRes: listing,
      preUrl: this.props.history.location.search,
    });
  };

  _setBorough = (search_val) => {
    this.setState({
      filterBorough: search_val,
      start: 0,
      end: this.state.rowPerPage,
    });
  };

  _mobileMapMove = (mapNotMoving = false) => {
    console.log("_mobileMapMove fired", mapNotMoving);
    if (!mapNotMoving && !this.state.mobileMapMoving) {
      this.setState({ mobileMapMoving: true });
    } else if (mapNotMoving && this.state.mobileMapMoving) {
      this.setState({ mobileMapMoving: false });
    }
  };

  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      tableData,
      reactTableFiltered,
      subFilters,
      totalCount,
      groupedData,
      groupedCount,
      unitListData,
      fetchInProgress,
      searchText,
      unitStages,
      modal,
      viewType,
      trxnRes,
    } = this.state;
    const { user, building } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;

    let containerHeight;
    if (viewType === "map") {
      const header =
        document.getElementsByClassName("topbar").length > 0
          ? document.getElementsByClassName("topbar")[0].offsetHeight
          : 0;

      const secondary_header =
        document.getElementsByClassName("c_seconday-nav-container").length > 0
          ? document.getElementsByClassName("c_seconday-nav-container")[0]
              .offsetHeight
          : 0;

      const search_container =
        document.getElementById("searchbar_filter_box") && !this.props.isDesktop
          ? document.getElementById("searchbar_filter_box").offsetHeight
          : 0;
      const researchbar_container =
        document.getElementById("research_bar") && !this.props.isDesktop
          ? document.getElementById("research_bar").offsetHeight
          : 0;
      containerHeight =
        this.state.height -
        header -
        secondary_header -
        search_container -
        researchbar_container -
        168;
    }
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding: 0 }} className="unit_search">
            {!this.props.buildingView ? (
              <BuildingSearchAppBar
                handleSearch={this._filterTableByInput}
                numSelected={[...selected].filter((el) => el[1]).length}
                handleDeleteSelected={this.handleDeleteSelected}
                onRequestSort={this.handleRequestSort}
                subFilters={subFilters}
                searchText={searchText}
                setSubFilter={this._setSubFilter}
                resetSubFilter={this._resetSubFilter}
                _filters={
                  user && user.role.indexOf("admin") >= 0
                    ? this._unitSubFilter
                    : this._unitSubFilter.filter((item) => !item.admin_only)
                }
                {...this.props}
                groupedView
                building={this.props.building}
                placeHolder={"Building, Neighborhood or Borough"}
                unitStages={unitStages}
                setUnitStage={this._setUnitStage}
                buildingStages={this.state.buildingStages}
                setBuildingStage={this._setBuildingStage}
                buildingAttributes={[]}
                goToBuilding
                numToShow={7}
                UnitStageFilter={
                  <SalesStageFilter
                    unitStages={unitStages}
                    setUnitStage={this._setUnitStage}
                  />
                }
                // UnitStageFilter={"not required"} for removing stage filter (checkbox)
                setSubFilters={this._setSubFilters}
                buildingTab={"units"}
                resetActive={this._resetActive()}
                q={this.q}
                fq={this.unitFq}
                fl={salesExportFL}
                sort={this.sort}
                totalCount={totalCount}
                showExport
                core_name={"we3-transactions"}
                export_name={"Past Sales Search"}
                title={"Export This Sales Search (CSV)"}
                description={
                  <Fragment>
                    <h4>
                      <strong>
                        Export this past sales search for your own analysis.
                      </strong>
                    </h4>{" "}
                    <br /> The export will include details about unit sold
                    price, sold date, bed, bath and size.
                  </Fragment>
                }
                hideOrganizations
                hidePeople
                hideProjects
              />
            ) : !this.props.unitView ? (
              <UnitsSearchAppBar
                handleSearch={this._filterTableByInput}
                numSelected={[...selected].filter((el) => el[1]).length}
                handleDeleteSelected={this.handleDeleteSelected}
                onRequestSort={this.handleRequestSort}
                subFilters={subFilters}
                searchText={searchText}
                setSubFilter={this._setSubFilter}
                resetSubFilter={this._resetSubFilter}
                _filters={
                  user && user.role.indexOf("admin") >= 0
                    ? this._propertyFilter
                    : this._propertyFilter.filter((item) => !item.admin_only)
                }
                {...this.props}
                groupedView
                building={this.props.building}
                setSubFilters={this._setSubFilters}
                UnitStageFilter={
                  <SalesStageFilter
                    unitStages={unitStages}
                    setUnitStage={this._setUnitStage}
                    defaultChecked
                  />
                }
                // UnitStageFilter={"not required"}
                numToShow={7}
                resetActive={this._resetActive()}
                q={this.q}
                fq={this.unitFq}
                fl={salesExportFL}
                sort={this.sort}
                totalCount={totalCount}
                showExport
                core_name={"we3-transactions"}
                export_name={`${
                  building && building.name
                    ? _capitalizeText(building.name)
                    : _capitalizeText(building.display_full_street_address)
                } Sales`}
                title={"Export Sales in This Building (CSV)"}
                description={
                  <Fragment>
                    <h4>
                      <strong>Export past sales for your own analysis.</strong>
                    </h4>{" "}
                    <br /> The export will include details about unit sold
                    price, sold date, bed, bath and size.
                  </Fragment>
                }
              />
            ) : null}

            <div className="material-table__header-info unit_search">
              <Row>
                <Col md={6} lg={6}>
                  {/* {!isMobile &&
                    this.props.buildingView &&
                    !this.props.unitView && (
                      <div className={`unit_toggle_filter`}>
                        <UnitStageFilter
                          unitStages={unitStages}
                          setUnitStage={this._setUnitStage}
                        />
                      </div>
                    )} */}
                  {viewType != "map" && (
                    <div className={`count_toggle`}>
                      <div>
                        <span className="bold-text">
                          {totalCount > 0 ? _formatNumber(totalCount) : "--"}
                        </span>
                        {` `}Transfers{" "}
                        {_formatNumber(groupedCount) ? (
                          <Fragment>
                            {" "}
                            in{" "}
                            <span className="bold-text">
                              {_formatNumber(groupedCount)}
                            </span>{" "}
                            Buildings
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={6} lg={6} className="fillingButtonGRP">
                  {!this.props.hideButtons && (
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      <Tooltip title={`See projects on a map`}>
                        <Button
                          disabled={viewType == "map"}
                          onClick={() => {
                            this._toggleView("map");
                          }}
                          startIcon={
                            <MapViewIcon
                              size={14}
                              color={viewType != "map" ? "#780F9E" : "#A3ADC2"}
                            />
                          }
                        >
                          Map
                        </Button>
                      </Tooltip>
                      <Tooltip title={`See projects in a list`}>
                        <Button
                          disabled={viewType == "list"}
                          onClick={() => {
                            this._toggleView("list");
                          }}
                          startIcon={
                            <ListViewIcon
                              size={14}
                              color={viewType != "list" ? "#780F9E" : "#A3ADC2"}
                            />
                          }
                        >
                          List
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  )}
                </Col>
              </Row>
            </div>

            <div className="material-table__wrap">
              {fetchInProgress && this.state.groupedView && <LinearLoading />}
              {viewType == "list" && (
                <>
                  <UnitsListTable
                    isSelected={this.isSelected}
                    handleBuildingClick={this.handleBuildingClick}
                    handleClick={this.handleClick}
                    handleRequestSort={this.handleRequestSort}
                    handleSelectAllClick={this.handleSelectAllClick}
                    {...this.state}
                    {...this.props}
                  />
                  {paginationCount ? (
                    <TablePagination
                      component="div"
                      className="material-table__pagination"
                      count={paginationCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      rowsPerPageOptions={[25, 50, 100]}
                      dir="ltr"
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                    />
                  ) : null}
                </>
              )}
              {viewType == "map" && (
                <SalesMapView
                  captureMobileMapMove={this._mobileMapMove}
                  hoveredListing={this.state.hoveredListing}
                  setCurrentListing={this._setCurrentListing}
                  currentListing={
                    this.state.currentListing
                      ? this.state.currentListing.listing
                      : null
                  }
                  handleMapBounds={this._handleMapBounds}
                  authData={this.state.authData}
                  handleBuildingClick={this.handleBuildingPreviewClick}
                  containerHeight={containerHeight}
                  width={this.state.width}
                  boroughs={this.state.boroughs}
                  majorMarkets={this.state.majorMarkets}
                  neighborhoods={this.state.neighborhoods}
                  q={this.q}
                  fq={this.unitFq}
                  {...this.state}
                  {...this.props}
                />
              )}
            </div>

            <Fragment>
              <Modal
                isOpen={modal}
                toggle={this.toggle.bind(this)}
                modalClassName={`ltr-support`}
                className={`modal-dialog contact__modal`}
              >
                <div className="modal__header">
                  <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={this.toggle.bind(this)}
                  />
                </div>
                <div className="modal__body">
                  <BuildingDetail
                    trxnRes={trxnRes}
                    mainTab={{ key: "transfer", name: "Transfer" }}
                    isShare
                  />
                </div>
              </Modal>
            </Fragment>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect((state) => ({
  mapStateToProps,
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags)
    ? state.search.searchTags.slice()
    : [],
}))(withRouter(UnitsSearchTable));
