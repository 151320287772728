import React, { useState, Fragment, useRef } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Badge,  Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';

import HeartIcon from 'mdi-react/HeartIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import { Link } from 'react-router-dom';
import images from './imgs';
import ProfileTabs from './ProfileTabs';
import ColorSelect from './ColorSelect';
import classnames from 'classnames';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import AlertIcon from 'mdi-react/InformationOutlineIcon';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import EnterpriseGate from '../../../../shared/components/payment/EnterpriseGate';

import BedFilter from './BedFilter';
import ViewTypeButton from './ViewTypeButton';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_formatBath,
	_getBuildingURL,
	_nameToUrl,
	_formatUnitNumber,
	_privateKey,
	_slugifyText,
	_isResiUnit
} from '../../../../shared/helpers/utils';

import { _hasPermission	} from "../../../../shared/helpers/access";

const _minSqFt = 500;
const _uniformSqFt = 600;
const sqPxConversion = .18;


const _calcUnitWidth = ( unit, viewType ) => {
	if(viewType === 'uniform') return _uniformSqFt;
	
	let totalSqFt = unit.sq_ft > _minSqFt ? unit.sq_ft : _minSqFt;
	
	if(viewType === 'size') return totalSqFt * sqPxConversion;
	//totalSqFt += unit.offering_exterior_sq_ft > 0 ? unit.offering_exterior_sq_ft : unit.exterior_sq_ft > 0 ? unit.exterior_sq_ft : 0;	
	let relativeWidth = Math.round(totalSqFt / _minSqFt);
	if(_isResiUnit(unit)){
		if(relativeWidth == 2){
			totalSqFt = totalSqFt * .65;
		}else if(relativeWidth == 3){
			totalSqFt = totalSqFt * .55;
		}else if(relativeWidth == 4){
			totalSqFt = totalSqFt * .45;
		}else if(relativeWidth > 4){
			totalSqFt = totalSqFt = totalSqFt * .35;
		}

	}
		//totalSqFt = totalSqFt * ((10 - (relativeWidth - 1)) / 10);
	return totalSqFt;
}

const generateShareLink = ( building, unit ) => {		 

	 let building_slug =  building.key.substr(4);

	 let shareBaseURL = `https://share.marketproof.com/building/`
	
	 const encryptedBuildingSlug = btoa(CryptoJS.AES.encrypt(building_slug, _privateKey));
	 let shareLink = `${building_slug}`;
	 if(unit && unit.address_2_normalized){			 
		 shareBaseURL = `https://share.marketproof.com/unit/`;
		 shareLink = `${building_slug}/${_slugifyText(unit.address_2_normalized)}`;
	 }
	 shareLink = btoa(CryptoJS.AES.encrypt(shareLink, _privateKey));
	 
	 return shareBaseURL + shareLink;
}

const unitSort = ( a, b ) => {
	if(a.floor == b.floor){
		if(_isResiUnit(a) !== _isResiUnit(b)){
			return _isResiUnit(b) - _isResiUnit(a);
		}else{
			return a.address_2_normalized > b.address_2_normalized ? 1 : -1;
		}
	}
	
	return a.floor > b.floor ? -1 : 1;
}

const handleBuildingClick = (building, unit) => {
	
 	const buildingURL = _getBuildingURL(building, 'building_slugs') + '/' + _nameToUrl(unit.address_2_normalized); //generateShareLink(building, unit); 
 	window.open(buildingURL);
};

const formatStackedUnits = ( units ) => {
	const stackedUnits = {};
	
	units.forEach(unit => {
/*
		if(unit.floor <= 0){
			unit.floor = 0;
		}
*/
		if(unit.floor && unit.floor > -1000){
			stackedUnits[unit.floor] = Array.isArray(stackedUnits[unit.floor]) ? [...stackedUnits[unit.floor], ...[unit]] : [unit];			
		}
		
	});
	
	return stackedUnits;
}

const formatPlans = ( building ) => {
	const tags = [
	  { tag: 'floor', title: 'Floor Plates', show: false },
	  { tag: 'elevation-map', title: 'Elevation Maps', show: false }, 
	  { tag: 'site-plan', title: 'Site Plans', show: false },
	  { tag: 'zoning', title: 'Zoning', show: false },
	  { tag: 'storage', title: 'Storage', show: false },
	  { tag: 'maisonette', title: 'Maisonette', show: false },
	  { tag: 'bulkhead', title: 'Bulkhead', show: false },
	  { tag: 'mechanical', title: 'Mechanical', show: false },
	  { tag: 'elevator', title: 'Elevator', show: false },
	  { tag: 'roof', title: 'Roof', show: false },
	  { tag: 'lobby', title: 'Lobby', show: false },
	  { tag: 'other', title: 'Other', show: false },
	];
	
	let plans = null;
	if(building.offering_floorplans){		
		try{
			plans = JSON.parse(building.offering_floorplans);
		}catch(e){
			
		}
		
	}
	if(!plans){
		return null;
	}
	const sortTypes = ['elevation-map', 'site-plan', 'zoning', 'floor','storage'];
	const allPlans = plans.map(plan => {
		 plan['src'] = plan['url'];
		 if(tags.filter(tag => tag.tag === plan.type).length>0){
			 tags.filter(tag => tag.tag === plan.type)[0].show=true;
			 plan['tag'] = plan['type'];
		 }else{
			 tags.filter(tag => tag.tag === 'other')[0].show=true;
			 plan['tag'] = 'other';
		 }
		 let sortScore = sortTypes.indexOf(plan.type)>-1 ? (sortTypes.indexOf(plan.type)+1)*1000 : 10000;
		 if(plan.type === 'floor'){
			 
			const typeIds = plan.type_id.split(',');
			if(!isNaN(typeIds[0])){
				sortScore+= parseInt(typeIds[0]);
				if(typeIds.length > 1){
					sortScore+=1;
				}
			}else{
				sortScore+=200;
			}
			
		 }		 			 	 
		 plan['sort'] = sortScore;
		 return plan;
	})
	
	return allPlans;

}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const bedFilterDisplay = ( unit, unitBeds ) => {
	
	let active = false;
	
	unitBeds.forEach( bed => {
		
		if(bed == "all units"){
			active = true;
		}
		
		if(bed == "studios" && unit.bedrooms == 0){
			active = true;
		}else if(bed == "1 beds" && unit.bedrooms == 1){
			active = true;
		}else if(bed == "2 beds" && unit.bedrooms == 2){
			active = true;
		}else if(bed == "3 beds" && unit.bedrooms == 3){
			active = true;
		}else if(bed == "4+ bedss" && unit.bedrooms >= 4){
			active = true;
		}
		
	})
	
	return active ? "on" : "off"	
	
}
const UnitStatus = ( { building, unit, floorUnits,  totalSize, unitDisplay, viewType } ) =>{
	let totalSqFt = _calcUnitWidth(unit, viewType);		
	let w = viewType == 'size' ? {minWidth:`${totalSqFt}px`} : {minWidth:'85px', maxWidth:`${(((totalSqFt) / totalSize)*100)}%`};
	let isResi = _isResiUnit(unit);
	let offering_submitted_year = parseInt(moment(unit.offering_submitted_date).format('YYYY'));
	if(offering_submitted_year <= 2010 && unit.current_status === "shadow"){
		unit.current_status = "sold";
	}
	return (
		<div className={`unit  ${totalSqFt} ${isResi ? (unit.current_status == "transfer" && unit.times_sold > 0) ? "sold" : unit.current_status : "none"}  ${unitDisplay}`} style={w} onClick={() => handleBuildingClick(building, unit)}>
				<div className="unit-number"><span className={`status`} /> {_formatUnitNumber(unit.address_2_normalized, building).replace("Unit", "")}</div>
				<div className="unit-details">
					{unit.baths > -1 ?
						<Fragment>
							{unit.bedrooms > -1 ? unit.bedrooms === 0 ? 'Studio' : unit.bedrooms+' Bed' : '--'}
							{' '}|{' '}
							{unit.baths > -1 ? unit.baths+' Bath' : '--'}
						</Fragment>
						: unit.property_type ? 
						<Fragment>
							{_capitalizeText(unit.property_type)}
						</Fragment>
						: null
						
					}
					<br/>
					({unit.sq_ft && unit.sq_ft>-1 ? _formatNumber(unit.sq_ft) : '--'} SF)<br/>
					{unit.offering_exterior_sq_ft > 0 ? `(${_formatNumber(unit.offering_exterior_sq_ft)} Ext SF)` : unit.exterior_sq_ft > 0 ? `(${_formatNumber(unit.exterior_sq_ft)} Ext SF)` : '--'}
				</div>
				
		  </div>
	)
}

const UnitPrice = ( { building, unit, floorUnits, increment, low_ppsf, totalSize, unitDisplay, viewType} ) =>{
	let totalSqFt = _calcUnitWidth(unit, viewType);	
	let w = viewType == 'size' ? {minWidth:`${totalSqFt}px`} : {minWidth:'85px', maxWidth:`${(((totalSqFt)  / totalSize)*100)}%`};
	
	let tier = Math.floor((unit['div(last_listed_sale_price_all, sq_ft)'] - low_ppsf) / increment);
	if(tier < 0) tier = 0;
	if(unit['div(last_listed_sale_price_all, sq_ft)'] <= 10) tier = 999;
	return (
		<div className={`unit price tier-${tier} ${unitDisplay}`} style={w} onClick={() => handleBuildingClick(building, unit)}>
				<div className="unit-number"><span className={``} /> {_formatUnitNumber(unit.address_2_normalized, building).replace("Unit", "")}</div>
				<div className="unit-details">					
				{unit['div(last_listed_sale_price_all, sq_ft)'] > 10 ? `$${_formatNumber(unit['div(last_listed_sale_price_all, sq_ft)'])} PPSF` : '--'}
				<br/>({unit.sq_ft && unit.sq_ft>-1 ? _formatNumber(unit.sq_ft) : '--'} SF)<br/>
				{unit.offering_exterior_sq_ft > 0 ? `(${_formatNumber(unit.offering_exterior_sq_ft)} Ext SF)` : unit.exterior_sq_ft > 0 ? `(${_formatNumber(unit.exterior_sq_ft)} Ext SF)` : '--'}
				</div>
		  </div>
	)
}

const UnitSold = ( { building, unit, floorUnits, increment, low_ppsf, totalSize, unitDisplay, viewType} ) =>{
	
	let totalSqFt = _calcUnitWidth(unit, viewType);	
	let w = viewType == 'size' ? {minWidth:`${totalSqFt}px`} : {minWidth:'85px', maxWidth:`${(((totalSqFt)  / totalSize)*100)}%`};
	
	let tier = Math.floor((unit['div(last_sold_price, sq_ft)'] - low_ppsf) / increment);
	if(tier < 0) tier = 0;
	if(unit['div(last_sold_price, sq_ft)'] <= 10) tier = 999;
	let time = unit['last_sold_date'] !== 0 ? moment.unix(unit['last_sold_date']).format('M/Y') : '--';
	return (
		<div className={`unit sold-price tier-${tier} ${unitDisplay}`}  style={w} onClick={() => handleBuildingClick(building, unit)}>
				<div className="unit-number"><span className={``} /> {_formatUnitNumber(unit.address_2_normalized, building).replace("Unit", "")}</div>
				<div className="unit-details">					
				{unit['sq_ft']> 10 && unit['last_sold_price'] > 10 ? `$${_formatNumber(unit['last_sold_price']/unit['sq_ft'])} PPSF` : ''} <br/>(Sold: {time})
				</div>
		  </div>
	)
}

const UnitDiscount = ( { building, unit, floorUnits, increment, low_ppsf, totalSize, unitDisplay, viewType} ) =>{
	let totalSqFt = _calcUnitWidth(unit, viewType);
	let w = viewType == 'size' ? {minWidth:`${totalSqFt}px`} : {minWidth:'85px', maxWidth:`${(((totalSqFt)  / totalSize)*100)}%`};
	let discount = Math.round(unit.discount * 100) / 100; //(unit.last_sold_price>0 && unit.last_listed_sale_price_all>0 && unit.last_sold_date > unit.last_listed_sale_date_all) ? (((unit.last_sold_price - unit.last_listed_sale_price_all) / unit.last_listed_sale_price_all) * 100).toFixed(2) : 0;
	let tier = unit.last_sold_date === 0 ? 999 : discount > 0 ? 0 :  Math.ceil(Math.abs(discount) / (increment));
	return (
		<div className={`unit discount tier-${tier} ${unitDisplay}`} style={w} onClick={() => handleBuildingClick(building, unit)}>
				<div className="unit-number"><span className={``} /> {_formatUnitNumber(unit.address_2_normalized, building).replace("Unit", "")}</div>
				<div className="unit-details">					
					 {discount > 0 ? '+' : ''}{unit.last_sold_date !== 0 ? discount + '%' : '--'}
					 <br/>
					 {unit.bedrooms < 0 && unit.sq_ft > 10 ?
						 <Fragment>
						 	({unit.sq_ft && unit.sq_ft>-1 ? _formatNumber(unit.sq_ft) : '--'} SF)
						 </Fragment>
						 
					 :
					 <Fragment>
					 	({unit.bedrooms > -1 ? unit.bedrooms === 0 ? 'Studio' : unit.bedrooms+' Bed' : '--'}
					 	{' '}|{' '}
					 	{unit.baths > -1 ? unit.baths+' Bath' : '--'})
					</Fragment>
					}
				</div>
		  </div>
	)
}

const UnitOwnershipAge = ( { building, unit, floorUnits, increment, high, totalSize, unitDisplay, viewType} ) =>{
	let totalSqFt = _calcUnitWidth(unit, viewType);
	let w = viewType == 'size' ? {minWidth:`${totalSqFt}px`} : {minWidth:'85px', maxWidth:`${(((totalSqFt)  / totalSize)*100)}%`};
	let tier = unit['last_exchanged_date'] !== 0 && unit['last_exchanged_date'] !== -1 ? Math.ceil((high - unit['last_exchanged_date']) / increment) : 999;
	return (
		<div className={`unit price tier-${tier} ${unitDisplay}`} style={w} onClick={() => handleBuildingClick(building, unit)}>
				<div className="unit-number"><span className={``} /> {_formatUnitNumber(unit.address_2_normalized, building).replace("Unit", "")}</div>
				{unit['last_sold_date'] !== 0 ?
					<div className="unit-details">					
						{moment.unix(unit['last_exchanged_date']).fromNow().replace('ago', '')}<br/> (Since: {moment.unix(unit['last_exchanged_date']).format('M/Y')})
					</div>
				:
					<div className="unit-details">					
						--
					</div>

				}
		  </div>
	)
}

const StackedUnitsReport = ( props) => {
	const { unitsRes, buildingRes, statsRes, activeTab, user, unitBeds, setUnitBed, isDownload, viewType } = props;
	
		
	const stackedUnits = formatStackedUnits( unitsRes.sort(unitSort) );
		
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

      
    const low_ppsf = Math.min.apply(Math, unitsRes.filter(o => o['div(last_listed_sale_price_all, sq_ft)'] > 100).map(function(o) { return o['div(last_listed_sale_price_all, sq_ft)']; })); 
    const high_ppsf = Math.max.apply(Math, unitsRes.filter(o => o['div(last_listed_sale_price_all, sq_ft)'] > 100).map(function(o) { return o['div(last_listed_sale_price_all, sq_ft)']; }));
    const increment = (high_ppsf - low_ppsf) / 9;
  
    const low_discount = Math.min.apply(Math, unitsRes.filter(o => o.discount).map(function(o) { return o.discount; })); //statsRes && statsRes.discount && statsRes.discount.min ? statsRes.discount.min : 0;
    const high_discount = Math.max.apply(Math, unitsRes.filter(o => o.discount).map(function(o) { return o.discount; })); //statsRes && statsRes.discount && statsRes.discount.max ? statsRes.discount.max : 0;
    const increment_d = isFinite(low_discount) ? Math.abs(low_discount) / 4 : 0;
  
/*
    const low_sold = Math.min.apply(Math, unitsRes.filter(o => o.last_sold_price > 10000).map(function(o) { return o.last_sold_price; })); 
    const high_sold = Math.max.apply(Math, unitsRes.filter(o => o.last_sold_price > 10000).map(function(o) { return o.last_sold_price; })); 
    const increment_s = (high_sold - low_sold) / 9;
*/
    
    const low_sold = Math.min.apply(Math, unitsRes.filter(o => o['div(last_sold_price, sq_ft)'] > 100 && o['div(last_sold_price, sq_ft)'] < 9999).map(function(o) { return o['div(last_sold_price, sq_ft)']; })); 
    const high_sold = Math.max.apply(Math, unitsRes.filter(o => o['div(last_sold_price, sq_ft)'] > 100 && o['div(last_sold_price, sq_ft)'] < 9999).map(function(o) { return o['div(last_sold_price, sq_ft)']; }));
    const increment_s = (high_sold - low_sold) / 9;
  
    const low_year = Math.min.apply(Math, unitsRes.filter(o => o.last_exchanged_date !== 0 && o.last_exchanged_date !== -1).map(function(o) { return o.last_exchanged_date; })); //statsRes && statsRes.discount && statsRes.discount.min ? statsRes.discount.min : 0;
    const high_year = Math.max.apply(Math, unitsRes.filter(o => o.last_exchanged_date !== 0 && o.last_exchanged_date !== -1).map(function(o) { return o.last_exchanged_date; })); //statsRes && statsRes.discount && statsRes.discount.max ? statsRes.discount.max : 0;
    const increment_o = (high_year - low_year) / 9;
    
    
    
    
    if(!_hasPermission(user, 'stacked-report') && user.email.toString().indexOf("elegran.com")<0){
	    return (
		    <div style={{marginTop:50}}>
				<EnterpriseGate 
					copy={<span>Access to Stacking Plans requires an upgrade to your subscription.</span>}
					img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-stacking-plan-${activeTab}.jpg`}
					upgrade={true}
					user={user}
					nextPlan={"complete"}
				/>
				
			</div>
		    
	    )
	 }
	 let largestFloor = 0;
	 let mostUnits = 0;
	 Object.keys(stackedUnits).sort((a, b) => b-a).map(floor => {
	         const floorUnits = stackedUnits[floor];
	         const totalSize = floorUnits.reduce((a, b) => a + _calcUnitWidth(b, viewType) , 0);	         
	         if(totalSize > largestFloor) largestFloor = totalSize;
	         if(floorUnits.length > mostUnits) mostUnits = floorUnits.length;
	 })
	 
	 const windowOffset = isMobile ? 0 : 420;
	 const winWidth = windowDimensions.width - windowOffset;
	 let floorWidth = windowDimensions.width - windowOffset;
	 if(floorWidth < (largestFloor * sqPxConversion)){
		 floorWidth = largestFloor * sqPxConversion;
	 }
   
    return (
	    <div className={`report-container ${activeTab} ${isMobile ? 'is-mobile' : ''}`}>
	    {!isDownload &&
		  <Fragment>
		   	<ViewTypeButton {...props} />
		   	<hr/>
		  </Fragment>
		  }
          <div className="legend">
          	{(activeTab === 'pricing') ? 
			    <div className="lengend">
			    	<div className="legend-label">
			    		<span className="left">${_formatNumber(low_ppsf)} PPSF</span>
			    		<span className="middle">Asking PPSF</span>

						<span className="right">${_formatNumber(high_ppsf)} PPSF</span>
			    	</div>
			    	{[...Array(10).keys()].map(tier => (
				    	<span className={`unit price tier-${tier}`}></span>
				    ))}
			    	
			    </div>
			 :( activeTab === 'sold') ? 
			    <div className="lengend">
			    	<div className="legend-label">
			    		<span className="left">{_formatPrice(low_sold)}</span>
			    		<span className="middle">Last Sold PPSF</span>
						<span className="right">{_formatPrice(high_sold)}</span>
			    	</div>
			    	{[...Array(10).keys()].map(tier => (
				    	<span className={`unit sold-price tier-${tier}`}></span>
				    ))}
			    	
			    </div>
			 :( activeTab === 'ownership') ? 
			    <div className="lengend">
			    	<div className="legend-label">
			    		<span className="left">{_capitalizeText(moment.unix(high_year).fromNow().replace('ago', ''))}</span>
			    		<span className="middle">Years Owned</span>
						<span className="right">{_capitalizeText(moment.unix(low_year).fromNow().replace('ago', ''))}</span>
			    	</div>
			    	{[...Array(10).keys()].map(tier => (
				    	<span className={`unit price tier-${tier}`}></span>
				    ))}
			    	
			    </div>
			 : (activeTab === 'discount') ? 
			 
			    <div className="lengend">
			    	<div className="legend-label">
			    		<span className="left">{high_discount > 0 ? '+' : ''}{isFinite(high_discount) ? high_discount : '--'} %</span>
			    		<span className="middle">Discount from Listed Price</span>
						<span className="right">{isFinite(low_discount) ? low_discount : '--'} %</span>
			    	</div>
			    	{[...Array(5).keys()].slice(high_discount > 0 ? 0 : 1).map(tier => (
				    	<span className={`unit discount tier-${tier}`}></span>
				    ))}
			    	
			    </div>
			: 	
				<div className="lengend">
					<div className="legend-label">
				    		<span className="left">Current Status</span>
				    </div>
				    <ul className="status-legend">
				    	<li className="unit "><span className={`status`} /> {buildingRes && buildingRes.offering_construction == 'conversion' ? 'Conversion' : 'Shadow'}</li>
				    	<li className="unit active"><span className={`status`} /> Active</li>
				    	<li className="unit contract"><span className={`status`} /> Contract</li>
				    	<li className="unit sold"><span className={`status`} /> Sold</li>
				    	<li className="unit transfer"><span className={`status`} /> Transferred</li>
				    </ul>
				</div>
			 
			 }
			 
          </div>
          <div className={"sub-menu"}>
	          {!isDownload && !isMobile && <BedFilter {...props} unitBeds={unitBeds} setUnitBed={setUnitBed}  />}
	          <BuildingPlans {...props} />
	      </div>
          <div className="stacked-container">
          {Object.keys(stackedUnits).sort((a, b) => b-a).map(floor => {
	          const floorUnits = stackedUnits[floor];
	          const length = floorUnits.length;
	          const n = 999; //Go back to single row //Math.ceil( length / Math.ceil(length / 8));
	          
	          const floorSplit = new Array(Math.ceil(floorUnits.length / n))
				  .fill()
				  .map(_ => floorUnits.splice(0, n))
				  
				  return (
						<div className="floor-container">	  
							<div className={`floor-number`}>{floor < 200 ? floor <=0 ? `B${Math.abs(floor)}` : floor : 'PH'}</div>
							<div className={`split-floor`}>
							 {floorSplit.map(floorSplitUnits => {
								 		const totalSize = floorSplitUnits.reduce((a, b) => a + _calcUnitWidth(b, viewType) , 0); //largestFloor style={{width:floorWidth.toString()+'px'}};
								 		
								 		let flrWidth = totalSize;
								 		 //style={{width:flrWidth+'px'}}
								 		 
								 		 if(viewType === 'uniform'){
									 		 flrWidth = (totalSize * sqPxConversion);
									 		 flrWidth += 'px';
								 		 }else if(viewType === 'fit'){
/*
									 		 flrWidth = (totalSize * sqPxConversion);
									 		 flrWidth = Math.floor(flrWidth/_minSqFt) * _minSqFt;
									 		 flrWidth = flrWidth < (winWidth) ? `100%` : `${flrWidth}px`;
*/
									 		 
									 		 flrWidth = '100%';
								 		 }else if(viewType === 'size'){
									 		 flrWidth += 'px';
								 		 }
								 		 
										 return (
								          	<div className="floor" style={{width:flrWidth}}>								          								          		
								          		{floorSplitUnits.map(unit => {
									          		let unitDisplay = bedFilterDisplay(unit, unitBeds);
									          		if(activeTab === 'pricing'){
										          		return <UnitPrice unit={unit} building={buildingRes} floorUnits={floorSplitUnits} stats={statsRes} increment={increment} low_ppsf={low_ppsf}  totalSize={totalSize} unitDisplay={unitDisplay} viewType={viewType} />
									          		}else if(activeTab === 'sold'){		          		
										          		return <UnitSold unit={unit} building={buildingRes} floorUnits={floorSplitUnits} stats={statsRes} increment={increment_s} low_ppsf={low_sold}  totalSize={totalSize} unitDisplay={unitDisplay} viewType={viewType} />
										          	}else if(activeTab === 'ownership'){		          		
										          		return <UnitOwnershipAge unit={unit} building={buildingRes} floorUnits={floorSplitUnits} stats={statsRes} increment={increment_o} high={high_year}  totalSize={totalSize} unitDisplay={unitDisplay} viewType={viewType}/>
										          	}else if(activeTab === 'discount'){
										          		return <UnitDiscount unit={unit} building={buildingRes} floorUnits={floorSplitUnits} stats={statsRes} increment={increment_d} low_discount={low_discount} high_discount={high_discount}  totalSize={totalSize} unitDisplay={unitDisplay} viewType={viewType} />
									          		}else{
										          		return <UnitStatus unit={unit} building={buildingRes} floorUnits={floorSplitUnits} stats={statsRes} totalSize={totalSize} unitDisplay={unitDisplay} viewType={viewType} />
									          		}		          		
									          		
									          		
								          		})}
								          
								            </div>
								          )
								 
								 
							 })}
							 </div>
						</div>
					)
	          
	          
	          
          })}
          </div>
        </div>
    )

	
}

const BuildingPlans = (props) => {
	const { buildingRes, isDownload } = props;
	
	if(isDownload) return null;
	const plans = formatPlans(buildingRes);
	const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (`/tab/plans/elevation-map`);
	if(plans && plans.length > 0 && plans.filter(plan => plan.type == 'elevation-map').length > 0 ){
		return (
			
			<div className="building-plans">
				<a href={`${buildingURL}`} className="link-text" target="_blank">See Elevation Maps <OpenInNewIcon size={12} style={{marginTop:"-2px", color:"#780F9E"}}/></a>
			</div>
			
		)
	}else{
		return null;
		
	}

	
}


const StackedUnits = (props) => {

  
  const { unitsRes, buildingRes, statsRes, activeTab, transactionDocs } = props;
  const defaultUnitBed = ["all units"];
  const [ unitBeds, setUnitBed ] = useState(defaultUnitBed);
  
  const [ viewType, setViewType ] = useState("fit");



  let exportTitle = 'Stacking Plan Status';
  if(activeTab === 'pricing'){
	  exportTitle = 'Stacking Plan PPSF'
  }else if(activeTab === 'sold'){
	  exportTitle = 'Stacking Plan Sold Price'
  }else if(activeTab === 'discount'){
	  exportTitle = 'Stacking Plan Discount'
  }else if(activeTab === 'ownership'){
	  exportTitle = 'Stacking Plan Ownership Age'
  }
  
  const _setUnitBed = (stage) => {
	
		let newStages = unitBeds.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all units');
	console.log(stage, index, indexAll)	
		
		if(index>=0){
			
			if(stage === "all units"){
				newStages = defaultUnitBed
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all units"){
				newStages = ["all units"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		console.log(newStages)
		if(newStages.length === 0 ) newStages = defaultUnitBed;
		setUnitBed(newStages);		
  }
  
  if(!unitsRes || unitsRes.length <= 0 || unitsRes.length < (buildingRes.inventory_total * .7)) {
	  return (
			  <Col md={12}>
			    <Card>
			      <CardBody className={`small`}>
			        <div className={`email-confirmation`} >
			          <div className="email-confirmation__icon">
			            <AlertIcon size={50} color={`#D6BAE8`} />			            
			          </div>
			          <h3 className="email-confirmation__title">Coming Soon</h3>
			          <p className="email-confirmation__sub">Not enough information is avaible to generate a {exportTitle} report at this time. <br/> We are hard at work gathering more information about this building.</p>
			          
			        </div>
			      </CardBody>
			    </Card>
			  </Col>
			  )
  }
  
  
  unitsRes.forEach(unit => {
	  let discount = (unit.last_sold_price>0 && unit.last_listed_sale_price_all>0 && unit.last_sold_date > unit.last_listed_sale_date_all && (unit.last_listed_sale_date_all === 0 || (unit.last_sold_date - unit.last_listed_sale_date_all)/86400 < 1095) ) ? (((unit.last_sold_price - unit.last_listed_sale_price_all) / unit.last_listed_sale_price_all) * 100).toFixed(2) : 0;
	  const transaction = transactionDocs && transactionDocs.filter(t => t.address_2_normalized == unit.address_2_normalized && t.discount > -1000);
	  
	  unit['discount'] = transaction && transaction.length>0 ? transaction[0].discount : discount;
  })
  
  const _boro = buildingRes.borough;
  const _hood = buildingRes.neighborhood[0];
  const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
  const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
  
  
     
  const title = exportTitle + ' - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
  return (   	
	<Fragment>
		<ExportComponentButton  {...props} title={title} buttonStyle={{float:'right'}} component={<StackedUnitsReport {...props} activeTab={activeTab} isDownload unitBeds={unitBeds} setUnitBed={_setUnitBed} viewType={viewType} />} />
		<StackedUnitsReport {...props} activeTab={activeTab}  unitBeds={unitBeds} setUnitBed={_setUnitBed} viewType={viewType} setViewType={setViewType} />

    </Fragment>

  );
};

export default StackedUnits;
