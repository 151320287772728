/* eslint-disable react/no-children-prop */
import React, { Fragment, useState } from 'react';
import {
  Card, CardBody, Row, Col, ButtonToolbar, Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Tooltip } from '@material-ui/core';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from '../../../shared/components/Modal';

import TrialSubscribe from './../../../shared/components/payment/TrialSubscribe';
import ChangePlan from '../../../shared/components/payment/ChangePlan';
import{
	_capitalizeText,
	_formatNumber
} from '../../../shared/helpers/utils';

import { subscriptionPlans, appFeatures, DataCreditUsageProgress, getCreditsAllowed } from '../../../shared/components/payment/plans.js';



const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined

const renderTextField = ({
  input, label, meta: { touched, error, warning }, children, select,
}) => (
  <Fragment>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    type="password"
	  />
	  {touched && ((error && <div className="field-error">{error}</div>) || (warning && <div className="field-error warn">{warning}</div>))}
  </Fragment>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const DataUsage = ( props ) => {
	
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ subscribe, setSubscribe ] = useState(false); 
	const [ planOpen, setPlanOpen ] = useState(false);
	
	const { cancelSubscription, reset, user, subscription, reactivateSubscription, userCreditUsed } = props;
	const { subscriptionPlan } = user;
	const currentPlan = subscriptionPlans.filter(plan => plan.stripeProductId === subscriptionPlan)[0];
	
	const creditsAllowed = getCreditsAllowed(user);

	
	return(
		
	  <Col md={12} lg={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'Pipeline Tokens'}</h5>
	          <div className="page-subhead subhead"></div>
	          <hr/>
	        </div>
	          <div className={"mb-3"}>
	        	<div className={"mb-1"}> Tokens Used This Period</div>
	        	<DataCreditUsageProgress credit_total={creditsAllowed} credit_used={userCreditUsed} usage />
			  </div>	        
	      </CardBody>
	    </Card>
	  </Col>
	)
};

const form = 'password_form';

export default connect(
  (state, props) => {
  const initalValues = Object.assign({}, props.user);
  console.log(initalValues);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
})(DataUsage));
