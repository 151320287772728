import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import { Link } from "react-router-dom";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { Tooltip } from '@material-ui/core';
import moment from "moment";

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	 ConstructionProgress,
	BuildingStatus,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	getCohortQuery,
	StatusBadge
} from "../../../shared/helpers/utils";

class MatTableView extends PureComponent {
  
    render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, user, tableFiltered, selected, isSelected, tableData, totalCount, rowsPerPage, page, handleBuildingClick,
      handleChangePage, handleChangeRowsPerPage, handleSelectAllClick, handleRequestSort, type
    } = this.props;
	const table = tableFiltered;
	const emptyRows = rowsPerPage - tableFiltered.length;
    const tableCount = totalCount;
    const paginationCount = totalCount;

    return (
       <Fragment>
        <div className="material-table__wrap">
          <Table className="material-table">
            <MatTableHead
              numSelected={[...selected].filter(el => el[1]).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              type={type}
            />
            
            <TableBody>
              {table && table
                  .map((d) => {
                  const thisSelected = isSelected(d.id);
                  //If building is sold out override stats
/*
                  if(d.offering_verified_status === 'sold out'){
                      d.inventory_unsold = 0;
                      d.inventory_pct_unsold = 0;
                  }
                  
*/
				  let inventory_pct_unsold = d.inventory_pct_bulk_unsold ? d.inventory_pct_bulk_unsold : d.inventory_pct_unsold;
				  let inventory_unsold = d.inventory_bulk_unsold ? d.inventory_bulk_unsold : d.inventory_unsold;
				  let offering_effective_year = d.offering_effective_date ?  parseInt(moment(d.offering_effective_date).format('YYYY')) : 0;
				  if(offering_effective_year <= 1970){
					  offering_effective_year = 0;
				  }
				  let current_year = new Date().getFullYear();
                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={event => handleBuildingClick(event, d)}
                      aria-checked={thisSelected}
                      tabIndex={-1}
                      key={d.id}
                      selected={thisSelected}
                    >
                      <TableCell className="material-table__cell" padding="checkbox">
                        {/*<Tooltip title="Add to Cohort">
                        	<Checkbox checked={isSelected} className="material-table__checkbox" />
                        </Tooltip>*/}
                        {/*user.role && Array.isArray(user.role) 
                            && user.role.indexOf('admin')>=0 
                            && d.offering_file_number && d.offering_file_number &&
					  		<Fragment>
					  			<IconButton onClick={(e) => { e.preventDefault();e.stopPropagation(); window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`)}} >
					  				<OpenIcon color={'#BFC4D1'} />
					  			</IconButton>
					  		</Fragment>
				  		*/}
                        
                      </TableCell>
                      <TableCell  className="material-table__cell material-table__cell-right"
                        size="small" >

                    	<StatusBadge building={d} />
                      </TableCell>
                      
                       
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        component="th"
                        scope="row"
                        padding="none"
                      >
                      <Link to={_getBuildingURL(d, "_id")}>
                        <span className={"building_name"}>{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
                        	                            									  	
						  	<BuildingIcons building={d} />
						  	

                        </span>
                        <br/><span className="light-text small-text">{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
                      </Link>
                      </TableCell>
                     
                     {/* <TableCell
                        className="material-table__cell material-table__cell-right"
                        size="small"                          
                      >
                      	<ConstructionProgress building={d}/>
                      </TableCell> */}
                      
                      <TableCell
                        className="material-table__cell light-text small-text"
                        size="small" 
                        align="center"
                      >
                      	  {d.pipeline_building_stories_proposed > 0 ?		 
                          	                         	  
			                <span className="light-text small-text">{ _formatNumber(d.pipeline_building_stories_proposed) }</span>
			                :
							<span className="light-text small-text">--</span>
						  }
		                  
                      </TableCell> 
                      {type !== "commercial" &&                      
	                      <TableCell
	                        className="material-table__cell light-text small-text"
	                        size="small" 
	                        align=""
	                      >
	                       <span className="light-text small-text" >
	                      {d.pipeline_dwelling_units_proposed > 0 ? _formatNumber(d.pipeline_dwelling_units_proposed) : '' }
	                       </span>
	                      </TableCell>
	                  }
                       <TableCell
                        className="material-table__cell"
                        align=""
                      >
                      <span className="light-text small-text" >
                      	{d.pipeline_zoning_area_residential > 0 ? _formatNumber(d.pipeline_zoning_area_residential) + ' Resi SF' : '' }
                      	<br/>
                      	<span className="light-text small-text" style={{color:"#858AA1"}}>
                      	{d.pipeline_total_construction_floor_area > 0 ? _formatNumber(d.pipeline_total_construction_floor_area) + ' Total SF' : '' }
                      	</span>
                      </span>
                      </TableCell> 
                      <TableCell
                        className="material-table__cell"
                        align=""
                      ><span className="light-text small-text">{moment(d.date_added).year() > 1970 ? _capitalizeText(moment(d.date_added).fromNow()) : '--'}</span>
                      </TableCell> 
                      <TableCell
                        className="material-table__cell"
                        align=""
                      ><span className="light-text small-text">{d.pipeline_filing_date && moment(d.pipeline_filing_date).year() > 1970 ? _capitalizeText(moment(d.pipeline_filing_date).fromNow()) : '--'}</span>
                      </TableCell> 
                      <TableCell
                        className="material-table__cell"
                        align=""
                      ><span className="light-text small-text">{d.pipeline_permit_issuance_job_start_date && moment(d.pipeline_permit_issuance_job_start_date).year() > 1970 ? _capitalizeText(moment(d.pipeline_permit_issuance_job_start_date).fromNow()) : '--'}</span>
                      </TableCell> 
                     
                                            
					  <TableCell
                        className="material-table__cell"
                        align=""
                      ><span className="light-text small-text">{d.pipeline_coo_issuance_date && moment(d.pipeline_coo_issuance_date).year() > 1970 ? _capitalizeText(moment(d.pipeline_coo_issuance_date).fromNow()) : '--'}</span>
                      </TableCell>
                       <TableCell
                        className="material-table__cell"
                        align=""
                      ><span className="light-text small-text">{d.offering_submitted_date && moment(d.offering_submitted_date).year() > 1970 ? _capitalizeText(moment(d.offering_submitted_date).fromNow()) : '--'}</span>
                      </TableCell>
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {paginationCount ? <TablePagination
          component="div"
          className="material-table__pagination"
          count={paginationCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[20, 40, 80, 120]}
          dir="ltr"
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        /> : null}
    </Fragment>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(MatTableView);