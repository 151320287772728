import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NewsTimeline from './components/NewsTimeline';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const Timeline = ({ t }) => (
  <Container className="news__container">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{'Pipeline News'}</h3>
        {!isMobile && <h3 className="page-subhead subhead">Latest Construction and New Development News</h3>}
      </Col>
    </Row>
    <Row>
      <Col md={12} className={'mb-3'}>
       	<TrialAlert />        
      </Col>
    </Row>
    <Row>
      <NewsTimeline />
    </Row>
    <Row>
      <Col md={12}>
        <h3 className="page-title"></h3>
        <h3 className="page-subhead subhead"></h3>
      </Col>
    </Row>
  </Container>
);

Timeline.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Timeline);
