import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Row, Col, Badge } from "reactstrap";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import BuildingIcons from '../../../shared/components/BuildingIcons';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import moment from "moment";
import AddToBuyersListButton from '../../../shared/components/buttons/AddToBuyersListButton';

import CurrentProgress from './analysis/CurrentProgress'
import NumberStat from './analysis/NumberStat'
import NeighborhoodAnalysis from './analysis/NeighborhoodAnalysis'
import PercentStat from './analysis/PercentStat'
import BoroughMix from './analysis/BoroughMix'
import TypeChart from './analysis/TypeChart'
import TopCompanies from './analysis/TopCompanies'

import HomeIcon from 'mdi-react/HomeVariantOutlineIcon';
import MixedIcon from 'mdi-react/HomeCityOutlineIcon';
import BuildingIcon from 'mdi-react/DomainIcon';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { isMobile, isTablet } from "react-device-detect";
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	 ConstructionProgress,
	BuildingStatus,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	getCohortQuery,
	StatusBadge
} from "../../../shared/helpers/utils";


const styles = theme => ({
  root: {
  display: 'flex',
  flexDirection: 'column',
  //marginLeft:"-8px",
  marginBottom:"15px",
  alignItems: 'left',
  '& > *': {
     
  },
  },
  button:{
  '&.selected' :{
    background:"#D1D6E0"
  },
  fontSize:11,
  width:120
  }
});
class MatAnalysisView extends PureComponent {
  
    render() {
    const {
      facetData
    } = this.props;
    
    if(!facetData) return null;
    
    const { classes, analysisType, setAnalysisType } = this.props;
    
    const baseURL =  '/pipeline-search' + (window.location && window.location.search ? window.location.search : "?");
	
    return (
      <div className={`dashboard container ${isMobile && "pd-2 mt-3"}`}>
        <Card>
         <CardBody>
          
       
         
          <Row>
            <Col md={12} xl={3} lg={6} xs={12}>
              <NumberStat 
                number={facetData.resi_project_count && facetData.resi_project_count[0] && facetData.resi_project_count[0].count ? facetData.resi_project_count[0].count : 0} 
                title={"Residential Projects"} 
                icon={<HomeIcon className="dashboard__trend-icon" />}
                link={`${baseURL}&pipeline_occupancy_type=residential`}
              />
            </Col>
            <Col md={12} xl={3} lg={6} xs={12}>
              <NumberStat 
                number={facetData.resi_project_count && facetData.resi_project_count[0] && facetData.resi_project_count[0].units ? (facetData.resi_project_count[0].units - (facetData.resi_project_count[0].units_existing ? facetData.resi_project_count[0].units_existing : 0)) : 0} 
                title={"Residential Units"} 
                icon={<HomeIcon className="dashboard__trend-icon" />}
                link={`${baseURL}&pipeline_occupancy_type=residential`}
              />
            </Col>
            <Col md={12} xl={3} lg={6} xs={12}>
              <NumberStat 
                number={facetData.comm_project_count && facetData.comm_project_count[0] && facetData.comm_project_count[0].count ? facetData.comm_project_count[0].count : 0} 
                title={"Commercial Projects"} 
                icon={<BuildingIcon className="dashboard__trend-icon" />}
                link={`${baseURL}&pipeline_occupancy_type=commercial`}
              />
            </Col>
            <Col md={12} xl={3} lg={6} xs={12}>
              <NumberStat 
                number={facetData.mixed_project_count && facetData.mixed_project_count[0] && facetData.mixed_project_count[0].count ? facetData.mixed_project_count[0].count : 0} 
                title={"Mixed-use Projects"} 
                icon={<MixedIcon className="dashboard__trend-icon" />}
                link={`${baseURL}&pipeline_occupancy_type=mixed-use`}
              />
            </Col>
            
          </Row>
          <Row>
            <Col md={12} xl={12} lg={12} xs={12}>
            <hr/>
            </Col>
          </Row>
             <Row>
                 <Col md={12} className={"mb-2"}>
                   <h5 className="dashboard__booking-total-description mb-3">
                        Analyze Pipeline by Metric Type
                    </h5>
                    <div className={classes.root}>
                           
                      <ButtonGroup size="small" aria-label="small button group" >							        
                        <Tooltip title="Analyze by number of projects" aria-label="Analyze by number of projects">
                          <Button onClick={()=> setAnalysisType("count")} className={analysisType === "count" ? classNames(classes.button, "selected") : classNames(classes.button)}>Projects</Button>
                        </Tooltip>
                        {/*<Tooltip title="Most viewed buildings yesterday" aria-label="Most viewed buildings yesterday">
                          <Button onClick={()=> this.setPeriod("yesterday")} className={period === "yesterday" ? classNames(classes.button, "selected") : classNames(classes.button)}>Yesterday</Button>
                        </Tooltip>*/}
                        <Tooltip title="Analyze by number of residential units" aria-label="Analyze by number of residential units">
                            <Button onClick={()=> setAnalysisType("units")} className={analysisType === "units" ? classNames(classes.button, "selected") : classNames(classes.button)}>Resi Units</Button>
                         </Tooltip>	
                         <Tooltip title="Analyze by number of square feet" aria-label="Analyze by number of square feet">
                            <Button onClick={()=> setAnalysisType("sq_ft")} className={analysisType === "sq_ft" ? classNames(classes.button, "selected") : classNames(classes.button)}>Square Feet</Button>
                         </Tooltip>
                      </ButtonGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} xs={12}>
                    <CurrentProgress {...this.props} baseURL={`${baseURL}`} />
                  </Col>
                </Row>
                <Row>
                    <Col md={12} xl={6} lg={6} xs={12}>
                     <BoroughMix {...this.props} baseURL={`${baseURL}`} />
                    </Col>
                    <Col md={12} xl={6} lg={6} xs={12}>
                     <TypeChart {...this.props} baseURL={`${baseURL}`} />
                    </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} xs={12}>
                    <NeighborhoodAnalysis {...this.props} baseURL={`${baseURL}`} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} xs={12}>
                    <TopCompanies {...this.props}  baseURL={`${baseURL}`}/>
                  </Col>
                </Row>
          </CardBody>
          </Card>
        </div>
       
    );
  }
}

export default withStyles(styles,  { withTheme: true })(MatAnalysisView);