/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment, useState } from "react";
import { Card, CardBody, Col, Row, Nav, NavLink, NavItem, Container, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import AlertIcon from "mdi-react/InformationOutlineIcon";
import LinearLoading from "../../../../shared/components/LinearLoading";
import { _hasPermission } from "../../../../shared/helpers/access";
import EditOrganization from "../../../../shared/components/edit/EditOrganization";
import EditContact from "../../../../shared/components/edit/EditContact";
import classnames from 'classnames';

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _formatBed,
  _formatBath,
  _formatOwnerName,
  _formatPhoneNumber,
  ProfileAvatar,
  _PickOrgName,
  _PickOrgRole,
  syncGenericFilter,
  setLocalStorage,
  _formatPhoneWithHide,
  _formatEmailWithHide,
  _PickContactOrg,
  _getBuildingURL,
  _PickPermitRoles
} from "../../../../shared/helpers/utils";
import { _axiosCall } from "../../../../shared/helpers/apicalls";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import PeopleCardView from "../../../SingleOrganization/components/Contacts/components/PeopleCardView";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Button as ReactStrapButton } from "reactstrap";
import ViewContactButton from "../../../../shared/components/buttons/ViewContactButton";

const apiURL = process.env.API_ROOT + "/";

const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p>
        <DotsHorizontalIcon />
      </p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}>
        <DropdownItem>Messsage</DropdownItem>
      </Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};



const RenderSingleContact = ({ contact, buildingRes, setChanged }) => {
  const [show, setShow] = useState(false);
  return (
    <tr>
      <td>
        <ProfileAvatar contact={contact} />
      </td>
      <td className="contact-table-head-fstyle">
        <Link to={`/contact/${contact["key"]}`} >
          {contact.first_name
            ? `${_capitalizeText(contact.first_name)} ${_capitalizeText(
                contact.last_name
              )}`
            : "--"}
          
        </Link>
      </td>
      <td className="contact-table-fstyle" style={{ width:200 }}>
        {contact.project_roles && contact.project_roles[buildingRes.key]
          ? _capitalizeText(
            _PickOrgRole(
              contact,
              buildingRes.key
            )
              .map((item) => {
                if(typeof item === "string"){
                  return item.replace(/_/g, " ")
                }else{
                  return "";
                }
              }).filter(Boolean)
              .join(", ")
          
            )
          : "--"}
          {contact.permit_roles &&
            <div className="small-text light-text" style={{width:300}}>
              <br/>
            
              {contact.permit_roles.sort((a,b) => a.issuance_date > b.issuance_date ? -1 : 1).map(r => <div style={{display:"flex",marginBottom:5}}><span style={{width:12,fontSize:12}}>&#8692;</span><span>{`${r.issuance_date && moment(r.issuance_date.split("T")[0], "YYYY-MM-DD").format("MM/YYYY").trim()} - ${r.work_type ? _capitalizeText(r.work_type.replace("filed herewith", "")) : r.job_description ? _capitalizeText(r.job_description.replace("filed herewith", "")) : ""}  `}</span></div>)}
            </div>
          }
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {_PickContactOrg(contact) &&
        _PickContactOrg(contact).name ? (
          <a
            href={`/company/${
              _PickContactOrg(contact)["key"]
            }`}
            // target="_blank"
          >
            {_capitalizeText(_PickContactOrg(contact).name)}
            
          </a>
        ) : (
          "--"
        )}
      </td>

      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.email &&
        Array.isArray(contact.email) &&
        contact.email[0] &&
        contact.email[0].length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${!show ? _formatEmailWithHide(contact.email[0]) : contact.email[0]}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} />{" "}
            {!show ? _formatEmailWithHide(contact.email[0]) : contact.email[0]}
          </a>
        ) : contact.email && contact.email.length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${contact.email}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} />{" "}
            {!show ? _formatEmailWithHide(contact.email) : contact.email}
          </a>
        ) : null}
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.phone &&
        Array.isArray(contact.phone) &&
        contact.phone[0] &&
        contact.phone[0].length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${!show
              ? _formatPhoneWithHide(contact.phone[0])
              : _formatPhoneNumber(contact.phone[0])}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {!show
              ? _formatPhoneWithHide(contact.phone[0])
              : _formatPhoneNumber(contact.phone[0])}
          </a>
        ) : contact.phone && contact.phone.length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${contact.phone[0]}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {!show
              ? _formatPhoneWithHide(contact.phone)
              : _formatPhoneNumber(contact.phone)}
          </a>
        ) : null}
      </td>
      <td className="dashboard__table-orders-total">
        <ViewContactButton
          contact={contact}
          setShow={setShow}
          show={show}
          tableView
        />
        {/* table view props is for changing button css b/w card and table view */}
      </td>
      <td className="contact_edit_buttons line_item d-table-cell">
        <EditContact
          buildingRes={buildingRes}
          item={contact}
          setChanged={setChanged}
        />
      </td>
      
    </tr>
  );
};

const RenderSingleOrganization = ({ contact, buildingRes, setChanged }) => {
  const [show, setShow] = useState(false);
  return (
    <tr>
      <td
        className="dashboard__table-orders-total contact-table-head-fstyle"
        dir="ltr"
      >
        <Link
          to={`/company/${contact["key"]}`}
          // target="_blank"
        >
          {contact.name
            ? _capitalizeText(
                _PickOrgName(
                  contact,
                  buildingRes.key
                )
              )
            : "--"}
         
        </Link>
      </td>
      <td className="contact-table-fstyle">
        {contact.project_roles &&
        contact.project_roles[buildingRes.key]
          ? _capitalizeText(
              _PickOrgRole(
                contact,
                buildingRes.key
              )
                .map((item) => {
                  if(typeof item === "string"){
                    return item.replace(/_/g, " ")
                  }else{
                    return false;
                  }
                }).filter(Boolean)
                .join(", ")
            )
          : "--"}
          {contact.permit_roles &&
            <div className="small-text light-text" style={{width:300}}>
              <br/>
            
              {contact.permit_roles.sort((a,b) => a.issuance_date > b.issuance_date ? -1 : 1).map(r => <li>{`${r.issuance_date && moment(r.issuance_date.split("T")[0], "YYYY-MM-DD").format("MM/YYYY")} - ${r.work_type ? _capitalizeText(r.work_type.replace("filed herewith", "")) : r.job_description ? _capitalizeText(r.job_description.replace("filed herewith", "")) : ""}  `}</li>)}
            </div>
          }
      </td>
    
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.email &&
        Array.isArray(contact.email) &&
        contact.email[0] &&
        contact.email[0].length>0  && contact.email[0].indexOf("@")>=0 ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${!show ? _formatEmailWithHide(contact.email[0]) : contact.email[0]}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} />{" "}
            {!show ? _formatEmailWithHide(contact.email[0]) : contact.email[0]}
          </a>
        ) : contact.email && contact.email.length>0 && contact.email.indexOf("@")>=0 ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${contact.email}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} />{" "}
            {!show ? _formatEmailWithHide(contact.email) : contact.email}
          </a>
        ) : null}
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.phone &&
        Array.isArray(contact.phone) &&
        contact.phone[0] &&
        contact.phone[0].length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${!show
              ? _formatPhoneWithHide(contact.phone[0])
              : _formatPhoneNumber(contact.phone[0])}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {!show
              ? _formatPhoneWithHide(contact.phone[0])
              : _formatPhoneNumber(contact.phone[0])}
          </a>
        ) : contact.phone && contact.phone.length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${contact.phone[0]}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {!show
              ? _formatPhoneWithHide(contact.phone)
              : _formatPhoneNumber(contact.phone)}
          </a>
        ) : null}
      </td>
     
      <td className="dashboard__table-orders-total">
        <ViewContactButton
          contact={contact}
          setShow={setShow}
          show={show}
          tableView
          companyCard
        />
        {/* table view props is for changing button css b/w card and table view */}
      </td>
      <td className="contact_edit_buttons line_item d-table-cell">
        <EditOrganization
          buildingRes={buildingRes}
          item={contact}
          setChanged={setChanged}
        />
      </td>
    </tr>
  )
}
class Contacts extends PureComponent {
  lsPre = `PIPE_COM_CONTACT_DB_`;
  constructor(props) {
    super(props);
    this.state = {
      lendingData: null,
      permitData: null,
      permitNowData: null,
      algoData: null,
      contacts: false,
      algoPeople: [],
      algoCompany: [],
      order: "desc",
      orderBy: "recorded_datetime",
      changed: false,
      viewType: syncGenericFilter("viewType", "viewType", this.lsPre, "list"),
      comViewType: syncGenericFilter(
        "comViewType",
        "comViewType",
        this.lsPre,
        "list"
      ),
      activeTab : props.match && props.match.params.subtab ? props.match.params.subtab : 'people'
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this._getProjectContacts();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.changed !== prevState.changed) {
      this._getProjectContacts();
    }
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  _getProjectContacts = async () => {
    const { buildingRes } = this.props;

    let mongoQuery = [
		{
			$match: {
				key: buildingRes.key,
			},
		},
		{
			"$lookup": {
				"from": "organizations",
				"localField": "key",
				"foreignField": "projects.key",
				"pipeline": [{
					"$match": {
						"meta.deleted": {
							"$exists": false
						}
					}
				},
				{
					"$project": {
						name: 1,
						key: 1,
						address: 1,
						email: 1,
						phone: 1,
						project_roles: 1,
						project_permit_roles: 1,
						meta: 1,
						name_list: 1
					}
				}
				],
				"as": "organizations"
			}
		},
		{
			"$lookup": {
				"from": "contacts",
				"localField": "key",
				"foreignField": "projects.key",
				"pipeline": [{
					"$match": {
						"meta.deleted": {
							"$exists": false
						}
					}
				},
				{
					"$project": {
						name: 1,
						first_name: 1,
						last_name: 1,
						key: 1,
						address: 1,
						email: 1,
						phone: 1,
						project_roles: 1,
						project_permit_roles: 1,
						organizations: {
							name: 1,
							key: 1,
							name_list: 1,
						},
						organization_associations: 1,
						meta: 1
					}
				}
				],
				"as": "contacts"
			}
		},
		{
			$group: {
				_id: "$_id",
				contacts: { $push: "$contacts" },
				organizations: { $push: "$organizations" },
			},
		},
		{
			$project: {
				name: 1,
				organizations: {
					name: 1,
					key: 1,
					address: 1,
					email: 1,
					phone: 1,
					project_roles: 1,
					project_permit_roles: 1,
					meta: 1,
					name_list: 1,
				},
				contacts: {
					name: 1,
					first_name: 1,
					last_name: 1,
					key: 1,
					address: 1,
					email: 1,
					phone: 1,
					project_roles: 1,
					project_permit_roles: 1,
					organizations: {
						name: 1,
						key: 1,
						name_list: 1,
					},
					organization_associations: 1,
					meta: 1,
				},
			},
		},
		{
			$sort: {
				"contacts.organizations.0.name": 1
			}
		}
	];

    let axiosData = {
      url: apiURL + "pipeline/aggregate",
      method: "post",
      query: mongoQuery,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        if (res && Array.isArray(res) && res.length>0 && res[0]) {
          this.setState({ contacts: res[0] });
        } else {
          this.setState({ contacts: [] });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    // console.log(formattedData)
    return formattedData;
  };

  _extractContacts(permit) {
    let contacts = [];
    const permitTypes = {
      5: "New Building",
      4: "Alteration",
      6: "Alteration",
      nb: "New Building",
      a1: "Alteration",
      dm: "Demolition",
    };

    let contact = {};
    contact["type"] = "owner";
    contact["first_name"] = permit.owner_first_name
      ? permit.owner_first_name
      : "";
    contact["last_name"] = permit.owner_last_name ? permit.owner_last_name : "";
    contact["business_name"] = permit.owner_business_name
      ? permit.owner_business_name
      : "";
    contact["phone"] = permit.owner_business_phone
      ? permit.owner_business_phone
      : permit.owner_phone_number
      ? permit.owner_phone_number
      : "";
    contact["email"] = permit.owner_business_email
      ? permit.owner_business_email
      : "";
    contact["business_address"] = permit.owner_business_address
      ? permit.owner_business_address
      : permit.owner_house_street_name
      ? permit.owner_house_street_name
      : "";
    contact["business_state"] = permit.owner_business_state
      ? permit.owner_business_state
      : "";
    contact["business_zip"] = permit.owner_business_zip
      ? permit.owner_business_zip
      : "";
    contact["mobile_phone"] = permit.owner_phone_mobile
      ? permit.owner_phone_mobile
      : "";
    contact["role"] = `Listed as owner on ${
      permitTypes[permit.job_type]
    } permit ${
      permit.pre_filing_date
        ? moment(permit.pre_filing_date).fromNow()
        : moment(permit.filing_date).fromNow()
    }`;
    contact["date"] = permit.pre_filing_date
      ? permit.pre_filing_date
      : permit.filing_date;
    if (contact["first_name"]) {
      contacts.push(contact);
    }

    contact = {};
    contact["type"] = "architect";
    contact["first_name"] = permit.applicant_first_name
      ? permit.applicant_first_name
      : "";
    contact["last_name"] = permit.applicant_last_name
      ? permit.applicant_last_name
      : "";
    contact["business_name"] = permit.applicant_business_name
      ? permit.applicant_business_name
      : "";
    contact["phone"] = permit.applicant_business_phone
      ? permit.applicant_business_phone
      : "";
    contact["email"] = permit.applicant_email ? permit.applicant_email : "";
    contact["business_address"] = permit.applicant_business_address
      ? permit.applicant_business_address
      : "";
    contact["business_state"] = permit.applicant_business_state
      ? permit.applicant_business_state
      : "";
    contact["business_zip"] = permit.applicant_business_zip
      ? permit.applicant_business_zip
      : "";
    contact["mobile_phone"] = permit.applicant_phone_mobile
      ? permit.applicant_phone_mobile
      : "";
    contact["role"] = `Listed as architect on ${
      permitTypes[permit.job_type]
    } permit ${
      permit.pre_filing_date
        ? moment(permit.pre_filing_date).fromNow()
        : moment(permit.filing_date).fromNow()
    }`;
    contact["date"] = permit.pre_filing_date
      ? permit.pre_filing_date
      : permit.filing_date;
    if (contact["first_name"]) {
      contacts.push(contact);
    }

    contact = {};
    contact["type"] = "permit filing rep";
    contact["first_name"] = permit.filing_applicant_first_name
      ? permit.filing_applicant_first_name
      : "";
    contact["last_name"] = permit.filing_applicant_last_name
      ? permit.filing_applicant_last_name
      : "";
    contact["business_name"] = permit.filing_applicant_business_name
      ? permit.filing_applicant_business_name
      : "";
    contact["phone"] = permit.filing_applicant_business_phone
      ? permit.filing_applicant_business_phone
      : "";
    contact["email"] = permit.filing_applicant_email
      ? permit.filing_applicant_email
      : "";
    contact["business_address"] = permit.filing_applicant_business_address
      ? permit.filing_applicant_business_address
      : "";
    contact["business_state"] = permit.filing_applicant_business_state
      ? permit.filing_applicant_business_state
      : "";
    contact["business_zip"] = permit.filing_applicant_business_zip
      ? permit.filing_applicant_business_zip
      : "";
    contact["mobile_phone"] = permit.filing_applicant_phone_mobile
      ? permit.filing_applicant_phone_mobile
      : "";
    contact["role"] = `Listed as filing representative on ${
      permitTypes[permit.job_type]
    } permit ${
      permit.pre_filing_date
        ? moment(permit.pre_filing_date).fromNow()
        : moment(permit.filing_date).fromNow()
    }`;
    contact["date"] = permit.pre_filing_date
      ? permit.pre_filing_date
      : permit.filing_date;
    if (contact["first_name"]) {
      contacts.push(contact);
    }

    return contacts;
  }
  setChanged() {
    const { changed } = this.state;

    this.setState({ changed: !changed });
  }

  _toggleView = (type) => {
    this.setState({ viewType: type });
    setLocalStorage(this.lsPre, "viewType", type);
  };

  _toggleCompanyView = (type) => {
    this.setState({ comViewType: type });
    setLocalStorage(this.lsPre, "comViewType", type);
  };

  toggle = (tab) => {
    const { organizationRes } = this.props;
    // const { activeTab } = this.state;
    // if (activeTab !== tab) {
    //   const newUrl =
    //     _getCompanyURL(organizationRes, "key") + (tab ? `/tab/${tab}` : "");
    //   this.props.history.push(newUrl);
    //   this.setState({
    //     activeTab: tab,
    //   });
    // }
  };
  _toggleTab = (tab) => {
    const { buildingRes } = this.props;
    if (this.state.activeTab !== tab) {
      const buildingURL =
        _getBuildingURL(buildingRes, "_id") + (tab ? `/tab/contacts/${tab}` : "");
      window.history && window.history.pushState({}, null, buildingURL);
      this.setState({activeTab:tab});
   
      
    }
  };
  
  // render() {
  //   const { offeringPlan, buildingRes, user } = this.props;
  //   const {
  //     lendingData,
  //     order,
  //     orderBy,
  //     permitData,
  //     permitNowData,
  //     contacts,
  //     algoPeople,
  //     algoCompany,
  //     algoData,
  //     viewType,
  //     comViewType,
  //   } = this.state;
  // 
  //   if (!contacts) {
  //     return <LinearLoading />;
  //   }
  //   
  //   let people, companies = [];
  //   if(true || buildingRes.source_provider && buildingRes.source_provider.indexOf("mpnd")>-1){
  //     
  //     if(contacts.contacts && contacts.contacts[0] && contacts.contacts[0].length){
  //         people = contacts.contacts[0]
  //         .filter((contact) => !contact.meta.deleted && ((contact.project_roles && contact.project_roles[buildingRes.key] && contact.project_roles[buildingRes.key].indexOf("marketing")<0) || !contact.project_roles || !contact.project_roles[buildingRes.key]))
  //       }
  //     if(contacts.organizations && contacts.organizations[0] && contacts.organizations[0].length){
  //       companies = contacts.organizations[0]
  //       .filter((contact) => !contact.meta.deleted && ((contact.project_roles &&  contact.project_roles[buildingRes.key] && contact.project_roles[buildingRes.key].indexOf("marketing")<0)|| !contact.project_roles || !contact.project_roles[buildingRes.key]))
  //     }
  //   }else{
  //     if(contacts.contacts && contacts.contacts[0] && contacts.contacts[0].length){
  //         people = contacts.contacts[0]
  //         .filter((contact) => !contact.meta.deleted)
  //       }
  //     if(contacts.organizations && contacts.organizations[0] && contacts.organizations[0].length){
  //       companies = contacts.organizations[0]
  //       .filter((contact) => !contact.meta.deleted)
  //     }
  //   }
  //   
  //   console.log(people,companies)
  //   
  //   
  //   if (!contacts.contacts && !contacts.organizations) {
  //     return (
  //       <Fragment>
  //         <div className="contact_edit_buttons">
  //           <EditOrganization
  //             buildingRes={buildingRes}
  //             setBuilding={null}
  //             add
  //             setChanged={this.setChanged.bind(this)}
  //           />
  //           <EditContact
  //             buildingRes={buildingRes}
  //             setBuilding={null}
  //             add
  //             setChanged={this.setChanged.bind(this)}
  //           />
  //           <hr />
  //         </div>
  //         <Row>
  //           <Col md={12}>
  //             <Card>
  //               <CardBody className={`small`}>
  //                 <div className={`email-confirmation`}>
  //                   <div className="email-confirmation__icon">
  //                     <AlertIcon size={50} color={`#D6BAE8`} />
  //                   </div>
  //                   <h3 className="email-confirmation__title">
  //                     No Contacts Found
  //                   </h3>
  //                   <p className="email-confirmation__sub"></p>
  //                 </div>
  //               </CardBody>
  //             </Card>
  //           </Col>
  //         </Row>
  //       </Fragment>
  //     );
  //   }
  // 
  //   return (
  //     <Fragment>
  //       <div className="contact_edit_buttons">
  //         <EditOrganization
  //           buildingRes={buildingRes}
  //           setBuilding={null}
  //           add
  //           setChanged={this.setChanged.bind(this)}
  //         />
  //         <EditContact
  //           buildingRes={buildingRes}
  //           setBuilding={null}
  //           add
  //           setChanged={this.setChanged.bind(this)}
  //         />
  //         <hr />
  //       </div>
  //       {people && people.length > 0 && (
  //           <Fragment>
  //             <Row>
  //               <Col md={12}>
  //                 <Card>
  //                   <CardBody className="inner_card_body">
  //                     <div className="card__title d-flex inner_contact_card_div">
  //                       <h5 className="contact-heading">{`Contacts Associated with This Project`}</h5>
  //                       <ButtonGroup
  //                         size="small"
  //                         className="d-block"
  //                         aria-label="small outlined button group"
  //                         style={{ marginLeft: "15px" }}
  //                       >
  //                         <Tooltip title={`See projects in a photo grid`}>
  //                           <Button
  //                             disabled={viewType == "grid"}
  //                             onClick={() => {
  //                               this._toggleView("grid");
  //                             }}
  //                             startIcon={
  //                               <GridViewIcon
  //                                 size={14}
  //                                 color={
  //                                   viewType != "grid" ? "#780F9E" : "#A3ADC2"
  //                                 }
  //                               />
  //                             }
  //                           >
  //                             Grid
  //                           </Button>
  //                         </Tooltip>
  //                         <Tooltip title={`See projects in a list`}>
  //                           <Button
  //                             disabled={viewType == "list"}
  //                             onClick={() => {
  //                               this._toggleView("list");
  //                             }}
  //                             startIcon={
  //                               <ListViewIcon
  //                                 size={14}
  //                                 color={
  //                                   viewType != "list" ? "#780F9E" : "#A3ADC2"
  //                                 }
  //                               />
  //                             }
  //                           >
  //                             List
  //                           </Button>
  //                         </Tooltip>
  //                       </ButtonGroup>
  //                     </div>
  //                     <div className="material-table__wrap">
  //                       <div className="material-table expansion__table">
  //                         {viewType == "grid" ? (
  //                           <PeopleCardView
  //                             contacts={people && people}
  //                             
  //                             toggle={this.toggle}
  //                           />
  //                         ) : (
  //                           <Table
  //                             responsive
  //                             striped
  //                             className="dashboard__table-orders"
  //                           >
  //                             <thead>
  //                               <tr>
  //                                 <th />
  //                                 <th>Contact Name</th>
  //                                 <th style={{ width: 200 }}>Role</th>
  //                                 <th>Company</th>
  // 
  //                                 <th style={{ width: 150 }}>Email</th>
  //                                 <th style={{ width: 160 }}>Phone</th>
  //                                 <th></th>
  //                                 <th></th>
  //                               </tr>
  //                             </thead>
  //                             <tbody>
  //                               {people.map((contact, index) => (
  //                                   <RenderSingleContact
  //                                     buildingRes={buildingRes}
  //                                     key={index}
  //                                     contact={contact}
  //                                     setChanged={this.setChanged.bind(this)}
  //                                   />
  //                                 ))}
  //                             </tbody>
  //                           </Table>
  //                         )}
  //                       </div>
  //                     </div>
  //                   </CardBody>
  //                 </Card>
  //               </Col>
  //             </Row>
  //           </Fragment>
  //         )}
  //       {companies && companies.length > 0 && (
  //           <Fragment>
  //             <Row>
  //               <Col md={12}>
  //                 <Card>
  //                   <CardBody className="inner_card_body">
  //                     <div className="card__title d-flex inner_contact_card_div">
  //                       <h5 className="contact-heading">{`Companies Associated with This Project`}</h5>
  //                       <ButtonGroup
  //                         size="small"
  //                         className="d-block"
  //                         aria-label="small outlined button group"
  //                         style={{ marginLeft: "15px" }}
  //                       >
  //                         <Tooltip title={`See projects in a photo grid`}>
  //                           <Button
  //                             disabled={comViewType == "grid"}
  //                             onClick={() => {
  //                               this._toggleCompanyView("grid");
  //                             }}
  //                             startIcon={
  //                               <GridViewIcon
  //                                 size={14}
  //                                 color={
  //                                   comViewType != "grid"
  //                                     ? "#780F9E"
  //                                     : "#A3ADC2"
  //                                 }
  //                               />
  //                             }
  //                           >
  //                             Grid
  //                           </Button>
  //                         </Tooltip>
  //                         <Tooltip title={`See projects in a list`}>
  //                           <Button
  //                             disabled={comViewType == "list"}
  //                             onClick={() => {
  //                               this._toggleCompanyView("list");
  //                             }}
  //                             startIcon={
  //                               <ListViewIcon
  //                                 size={14}
  //                                 color={
  //                                   comViewType != "list"
  //                                     ? "#780F9E"
  //                                     : "#A3ADC2"
  //                                 }
  //                               />
  //                             }
  //                           >
  //                             List
  //                           </Button>
  //                         </Tooltip>
  //                       </ButtonGroup>
  //                     </div>
  //                     <div className="material-table__wrap">
  //                       <div className="material-table expansion__table">
  //                         {comViewType == "grid" ? (
  //                           <PeopleCardView
  //                             contacts={companies}
  //                             
  //                             toggle={this.toggle}
  //                             companyCard={true}
  //                           />
  //                         ) : (
  //                           <Table
  //                             responsive
  //                             striped
  //                             className="dashboard__table-orders"
  //                           >
  //                             <thead>
  //                               <tr>
  //                                 <th style={{ width: 200 }}>Company</th>
  //                                 <th style={{ width: 200 }}>Role</th>
  // 
  //                                 <th style={{ width: 150 }}>Email</th>
  //                                 <th style={{ width: 160 }}>Phone</th>
  //                                 {/*<th style={{ width: 200 }}>
  //                                    Address
  //                                 </th>*/}
  //                                 <th></th>
  //                                 <th></th>
  //                               </tr>
  //                             </thead>
  //                             <tbody>
  //                               {companies.map((contact, index) => (
  //                                   <RenderSingleOrganization
  //                                     buildingRes={buildingRes}
  //                                     key={index}
  //                                     contact={contact}
  //                                     setChanged={this.setChanged.bind(this)}
  //                                   />
  //                                 ))}
  //                             </tbody>
  //                           </Table>
  //                         )}
  //                       </div>
  //                     </div>
  //                   </CardBody>
  //                 </Card>
  //               </Col>
  //             </Row>
  //           </Fragment>
  //         )}
  //     </Fragment>
  //   );
  // }
  render() {
    const { offeringPlan, buildingRes, user } = this.props;
    const {
      lendingData,
      order,
      orderBy,
      permitData,
      permitNowData,
      contacts,
      algoPeople,
      algoCompany,
      algoData,
      viewType,
      comViewType,
      activeTab
    } = this.state;

    if (!contacts) {
      return <LinearLoading />;
    }
    
    let people, companies = [];
    if(true || buildingRes.source_provider && buildingRes.source_provider.indexOf("mpnd")>-1){
      
      if(contacts.contacts && contacts.contacts[0] && contacts.contacts[0].length){
          people = contacts.contacts[0]
          .filter((contact) => !contact.meta.deleted && ((contact.project_roles && contact.project_roles[buildingRes.key] && contact.project_roles[buildingRes.key].indexOf("marketing")<0) || !contact.project_roles || !contact.project_roles[buildingRes.key]))
        }
      if(contacts.organizations && contacts.organizations[0] && contacts.organizations[0].length){
        companies = contacts.organizations[0]
        .filter((contact) => !contact.meta.deleted && ((contact.project_roles &&  contact.project_roles[buildingRes.key] && contact.project_roles[buildingRes.key].indexOf("marketing")<0)|| !contact.project_roles || !contact.project_roles[buildingRes.key]))
      }
    }else{
      if(contacts.contacts && contacts.contacts[0] && contacts.contacts[0].length){
          people = contacts.contacts[0]
          .filter((contact) => !contact.meta.deleted)
        }
      if(contacts.organizations && contacts.organizations[0] && contacts.organizations[0].length){
        companies = contacts.organizations[0]
        .filter((contact) => !contact.meta.deleted)
      }
    }
    
    if(people && people.length>0){
      people.map(p => {
        p['roles'] = _PickOrgRole(p, buildingRes.key);
        p['permit_roles'] = _PickPermitRoles(p, buildingRes.key);
      })
      people.sort((a, b) => {
          // First, sort by the first role
          const roleA = a.roles && a.roles[0] ? a.roles[0].toLowerCase() : "";
          const roleB = b.roles && b.roles[0] ? b.roles[0].toLowerCase() : "";
      

          if (roleA < roleB) return -1;
          if (roleA > roleB) return 1;
      
          // If roles are equal, then sort by first name
          const firstNameA = a.first_name.toLowerCase();
          const firstNameB = b.first_name.toLowerCase();
      
          if (firstNameA < firstNameB) return -1;
          if (firstNameA > firstNameB) return 1;
      
          // If first names are equal, sort by last name
          const lastNameA = a.last_name.toLowerCase();
          const lastNameB = b.last_name.toLowerCase();
      
          if (lastNameA < lastNameB) return -1;
          if (lastNameA > lastNameB) return 1;
      
          return 0; // names and roles are equal
      })
    }
    if(companies && companies.length>0){
      companies.map(c => {
        c['roles'] = _PickOrgRole(c, buildingRes.key);
        c['permit_roles'] = _PickPermitRoles(c, buildingRes.key);
      })
      companies.sort((a, b) => {
          // First, sort by the first role

          const roleA = a.roles && a.roles[0] ? a.roles[0].toLowerCase() : "";
          const roleB = b.roles && b.roles[0] ? b.roles[0].toLowerCase() : "";

      
          if (roleA < roleB) return -1;
          if (roleA > roleB) return 1;
      
          // If roles are equal, then sort by first name
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
      
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
      
          return 0; // names and roles are equal
      })
    }
   console.log(people, companies)
    
    
    return (
      <Fragment>
      <Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
        
        <div className="tabs"> 	
        <div className="tabs__wrap mb-2">
            <Nav tabs>
               
               <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'people' })}
                  onClick={() => {
                    this._toggleTab('people');
                  }}
                >
                  <span className="tab-title">People</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'companies' })}
                  onClick={() => {
                    this._toggleTab('companies');
                  }}
                >
                  <span className="tab-title">Companies</span>
                </NavLink>
              </NavItem>
            </Nav>
              </div>
            </div>
        
        {activeTab === 'people' &&
          <TabPane tabId="people">
          <div className="contact_edit_buttons">
            <EditContact
              buildingRes={buildingRes}
              setBuilding={null}
              add
              setChanged={this.setChanged.bind(this)}
            />
            <hr />
          </div>
          {people && people.length > 0 ? (
              <Fragment>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="inner_card_body">
                        <div className="card__title d-flex inner_contact_card_div">
                          <h5 className="contact-heading">{`People Associated with This Project`}</h5>
                          <ButtonGroup
                            size="small"
                            className="d-block"
                            aria-label="small outlined button group"
                            style={{ marginLeft: "15px" }}
                          >
                            <Tooltip title={`See projects in a photo grid`}>
                              <Button
                                disabled={viewType == "grid"}
                                onClick={() => {
                                  this._toggleView("grid");
                                }}
                                startIcon={
                                  <GridViewIcon
                                    size={14}
                                    color={
                                      viewType != "grid" ? "#780F9E" : "#A3ADC2"
                                    }
                                  />
                                }
                              >
                                Grid
                              </Button>
                            </Tooltip>
                            <Tooltip title={`See projects in a list`}>
                              <Button
                                disabled={viewType == "list"}
                                onClick={() => {
                                  this._toggleView("list");
                                }}
                                startIcon={
                                  <ListViewIcon
                                    size={14}
                                    color={
                                      viewType != "list" ? "#780F9E" : "#A3ADC2"
                                    }
                                  />
                                }
                              >
                                List
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        </div>
                        <div className="material-table__wrap">
                          <div className="material-table expansion__table">
                            {viewType == "grid" ? (
                              <PeopleCardView
                                contacts={people && people}
                                
                                toggle={this.toggle}
                              />
                            ) : (
                              <Table
                                responsive
                                striped
                                className="dashboard__table-orders"
                              >
                                <thead>
                                  <tr>
                                    <th />
                                    <th>Contact Name</th>
                                    <th style={{ width: 200 }}>Role</th>
                                    <th>Company</th>
  
                                    <th style={{ width: 150 }}>Email</th>
                                    <th style={{ width: 160 }}>Phone</th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {people.map((contact, index) => (
                                      <RenderSingleContact
                                        buildingRes={buildingRes}
                                        key={index}
                                        contact={contact}
                                        setChanged={this.setChanged.bind(this)}
                                      />
                                    ))}
                                </tbody>
                              </Table>
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Fragment>)
              :
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody className={`small`}>
                      <div className={`email-confirmation`}>
                        <div className="email-confirmation__icon">
                          <AlertIcon size={50} color={`#D6BAE8`} />
                        </div>
                        <h3 className="email-confirmation__title">
                          No Contacts Found
                        </h3>
                        <p className="email-confirmation__sub"></p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              
            }
          </TabPane>
        }
        {activeTab === 'companies' &&
        <TabPane tabId="companies">
        <div className="contact_edit_buttons">
          <EditOrganization
            buildingRes={buildingRes}
            setBuilding={null}
            add
            setChanged={this.setChanged.bind(this)}
          />
          <hr />
        </div>
        {companies && companies.length > 0 ? (
            <Fragment>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody className="inner_card_body">
                      <div className="card__title d-flex inner_contact_card_div">
                        <h5 className="contact-heading">{`Companies Associated with This Project`}</h5>
                        <ButtonGroup
                          size="small"
                          className="d-block"
                          aria-label="small outlined button group"
                          style={{ marginLeft: "15px" }}
                        >
                          <Tooltip title={`See projects in a photo grid`}>
                            <Button
                              disabled={comViewType == "grid"}
                              onClick={() => {
                                this._toggleCompanyView("grid");
                              }}
                              startIcon={
                                <GridViewIcon
                                  size={14}
                                  color={
                                    comViewType != "grid"
                                      ? "#780F9E"
                                      : "#A3ADC2"
                                  }
                                />
                              }
                            >
                              Grid
                            </Button>
                          </Tooltip>
                          <Tooltip title={`See projects in a list`}>
                            <Button
                              disabled={comViewType == "list"}
                              onClick={() => {
                                this._toggleCompanyView("list");
                              }}
                              startIcon={
                                <ListViewIcon
                                  size={14}
                                  color={
                                    comViewType != "list"
                                      ? "#780F9E"
                                      : "#A3ADC2"
                                  }
                                />
                              }
                            >
                              List
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </div>
                      <div className="material-table__wrap">
                        <div className="material-table expansion__table">
                          {comViewType == "grid" ? (
                            <PeopleCardView
                              contacts={companies}
                              
                              toggle={this.toggle}
                              companyCard={true}
                            />
                          ) : (
                            <Table
                              responsive
                              striped
                              className="dashboard__table-orders"
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: 200 }}>Company</th>
                                  <th style={{ width: 200 }}>Role</th>

                                  <th style={{ width: 150 }}>Email</th>
                                  <th style={{ width: 160 }}>Phone</th>
                                  {/*<th style={{ width: 200 }}>
                                     Address
                                  </th>*/}
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {companies.map((contact, index) => (
                                    <RenderSingleOrganization
                                      buildingRes={buildingRes}
                                      key={index}
                                      contact={contact}
                                      setChanged={this.setChanged.bind(this)}
                                    />
                                  ))}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              
            </Fragment>
          ):
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className={`small`}>
                  <div className={`email-confirmation`}>
                    <div className="email-confirmation__icon">
                      <AlertIcon size={50} color={`#D6BAE8`} />
                    </div>
                    <h3 className="email-confirmation__title">
                      No Contacts Found
                    </h3>
                    <p className="email-confirmation__sub"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>}
        </TabPane>
        }
          </Container>	  
      </Fragment>
    );
  }
}

export default Contacts;
