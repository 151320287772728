import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/TagOutlineIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import HomeVariantOutlineIcon from 'mdi-react/HomeVariantOutlineIcon';
import GaugeIcon from 'mdi-react/GaugeIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";

const AveragePPSF = (props) => {
		if(props.unitStats){
			return (
				 <Col md={12} xl={3} lg={6} xs={12}>
				    <Card>
				      <CardBody className="dashboard__booking-card card__link">
				        <div className="dashboard__booking-total-container">
						 {props.sold ?
							<h5 className="dashboard__booking-total-title">
								${props.unitStats.sold_price && props.unitStats.sold_price.average > 0 ? _formatNumber(props.unitStats.sold_price.average)
									: '--'}
							  </h5>
							:
				          	<h5 className="dashboard__booking-total-title">
				            	${props.unitStats.asking_price && props.unitStats.asking_price.average > 0 ? _formatNumber(props.unitStats.asking_price.average)
					            	: '--'}
				          	</h5>
						  }
						  {props.sold ? 
							<CurrencyUsdIcon className="dashboard__trend-icon" />  
							  :
				          	<TagOutlineIcon className="dashboard__trend-icon" />
						  }
				        </div>
				        <h5 className="dashboard__booking-total-description">Avg {props.sold ? "Sold" : "Asking"} Price</h5>
				      </CardBody>
				    </Card>
				  </Col>
			)
		}

		return null;
		
	
	 	
		
	
};

export default AveragePPSF;
