import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import BuildingIcons from '../../../../../shared/components/BuildingIcons';
import Slider from 'react-slick';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import moment from "moment";
import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
	buildingFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	StatusBadge,
	_formatNameAddress
} from "../../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const apiURL = process.env.API_ROOT;
const buildingUrl = process.env.API_ROOT + "/pipeline/";
const styles = theme => ({
	  root: {
	    display: 'flex',
	    flexDirection: 'column',
	    //marginLeft:"-8px",
	    marginBottom:"15px",
	    alignItems: 'left',
	    '& > *': {
		   
	    },
	  },
	  button:{
		'&.selected' :{
		    background:"#D1D6E0"
	    },
	    fontSize:11,
	    width:90
	  }
	});
const settings = {
	  dots: false,
	  infinite: false,
	  speed: 500,
	  autoplay: false,
	  swipeToSlide: true,
	  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
	  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
	  responsive: [
		{ breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
	    { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
	    { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
	    { breakpoint: 1536, settings: { slidesToShow: 4, slidesToScroll: 4 } },
	    { breakpoint: 100000, settings: { slidesToShow: 4, slidesToScroll: 4} },
	  ],
  };	
const ShowUnits = ({data, title, color, period}) => {
	let baseline = moment().hour() * 3;
	if(period == "yesterday"){
		baseline = 42;
	}else if(period == "this-week"){
		baseline = moment().isoWeekday() * 47;
	}else if(period == "this-month"){
		baseline = moment().date() * 49;
	}
	
	return (
		<Card>
		<CardBody style={{padding:15}}>
		 
	    <Table responsive className="table-striped trend-buildings">
	      <tbody>
	      <tr>
	      	<th colSpan={2}><h5 className="bold-text">{title}</h5></th>
	      	<th className="views"><h5 className="bold-text">Rank</h5></th>
	      </tr>
	      {data && Array.isArray(data) && data.slice(0, 3).map((bldg, i) => {
		      
		        const item = bldg.building ? bldg.building : false;
		        
		        if(!item) return null;
		      	const buildingNameOrAdr = item.name 
			      ? _capitalizeText(item.name)
			      : item.display_full_street_address
			        ? _capitalizeText(item.display_full_street_address)
			        : _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building_slugs');
		      return (
			 
				      <tr key={i} style={{border:0,cursor:"pointer"}}>
				      	<td className="photo">
				  			<Link to={unitURL}>
				  			<BuildingPhoto item={item} />
				  			</Link>	
						</td>      				       	
						<td>
							<Link to={unitURL}>
								<div>{buildingNameOrAdr}</div>
								<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])} ` : ''}
								 {/*_capitalizeText(item.borough)*/}
								 </span>
								<br/> 	
								<StatusBadge building={item} small />	
							</Link>	
							
						</td>   
						<td className="views">
							<span className="view-count">
				  				{i+1} {/*bldg.count + baseline*/}
				  			</span> 
				  			
						</td>
						    
			          </tr>
			      
			    )
		      
	      })}
	        
	       
	      </tbody>
	    </Table>
	    
		</CardBody>
		</Card>

    )
}

const ShowBuildings = ({data, title, color, period}) => {
	let baseline = moment().hour() * 3;
	if(period == "yesterday"){
		baseline = 42;
	}else if(period == "this-week"){
		baseline = moment().isoWeekday() * 47;
	}else if(period == "this-month"){
		baseline = moment().date() * 49;
	}
	return (
			<div className="catalog-items__buildings catalog-items__wrap">
	        <div className="catalog-items">
	          <Slider {...settings} className="">
	          {data && Array.isArray(data) && data.map((bldg, i) => {
		        const item = bldg.project && bldg.project[0] ? bldg.project[0] : false;
        
		        if(!item) return null;
		      
				const buildingURL = _getBuildingURL(item, '_id');  
		        const { buildingNameOrAdr, adr, _boro, _hood } = _formatNameAddress(item);  			
				return (
		            <CardBody className="catalog-item" key={i}>
		              <Link className="catalog-item__link" to={buildingURL}>
		               <div className="catalog-item__img-wrap">
		                  <BuildingPhoto item={item} />				  	
		                </div>
						<div className="badge-holder">
		                <StatusBadge building={item} />  
						</div>
		                 <div className="saved-buyer-indicator">
		                	<span className="saved-buyers-small">
			                	<span style={{fontWeight:200}}>#</span> {i+1} {/*bldg.count + baseline*/}	
			                </span> Over {_capitalizeText(period.replace("-", " "))}
			                {/*Views {_capitalizeText(period)}*/}
			            </div>
		                <div className="catalog-item__info">
		                   <h4 className="catalog-item__price">
		                   	{buildingNameOrAdr ? _capitalizeText(buildingNameOrAdr) : _capitalizeText(_replaceAbbreviations(adr))}
							 <BuildingStageBadge item={item} small />
						   	{/*item.name && <div className="light-text" >{_capitalizeText(_replaceAbbreviations(item.display_full_street_address))}</div>*/}
						   	<div className="light-text small-text" style={{color:"#858AA1"}}>
			                  	{_hood ? `${_capitalizeText(_hood)}, ` : ''} {_capitalizeText(_boro)}
			                </div>	                  
		                   </h4>
		                   <h5 className="product-card__title">
				              	<div className="light-text">
									<span className="light-text small-text">
									  {item.pipeline_zoning_area_residential > 0
										? _formatNumber(
											item.pipeline_zoning_area_residential
										  ) + " Resi SF"
										: ""}
									</span>
									{item.pipeline_zoning_area_residential > 0 &&
									  item.pipeline_total_construction_floor_area > 0 && (
										<span className="small-divider">|</span>
									  )}
									<span className="light-text small-text">
									  {item.pipeline_total_construction_floor_area > 0
										? _formatNumber(
											item.pipeline_total_construction_floor_area
										  ) + " Total SF"
										: ""}
									</span>
								  </div>
								  <span className="light-text small-text">
									{_formatNumber(item.pipeline_building_stories_proposed)}{" "}
									Floors
								  </span>
								  {item.pipeline_dwelling_units_proposed > 0 && (
									  <Fragment>
										<span className="small-divider">|</span>
										<span className="light-text small-text">
										  {item.pipeline_dwelling_units_proposed > 0
											? _formatNumber(
												item.pipeline_dwelling_units_proposed
											  )
											: ""}{" "}
										  Units
										</span>
									  </Fragment>
									)}
				            </h5>
				          {/*  <SalesProgress progress={parseFloat(item.inventory_pct_unsold).toFixed(0)} inventory_unsold={item.inventory_unsold} inventory_total={item.inventory_total} maxWidth={'90%'} label={'Unsold Units'} /> */}
		
		                  
		                 
		                 </div>
		              </Link>
		            </CardBody>
	          )})}
	           </Slider>
	        </div>
	      </div>
	 
	)
}
class TrendingBuildings extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      data: null,
	      period: "1-day"
	    };
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.period !== prevState.period){
			this._getActivity();
		}
		
	}

	componentDidMount() {
		this._getActivity();
		
	  	
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
    }
	_getActivity = async () => {
			
		const { period } = this.state;
		const { type } = this.props;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		
		
		let dateQuery = {
			"$gte": `ISODate:${moment().add(-24, 'hour').format('YYYY-MM-DD')}`
		}
		
		if(period == "yesterday"){
			dateQuery = {
				"$gte": `ISODate:${moment().add(-1, 'days').format('YYYY-MM-DD')}`, "$lt" : `ISODate:${moment().format('YYYY-MM-DD')}`
			}
		}else if(period == "7-days"){
			dateQuery = {
				"$gte": `ISODate:${moment().add(-7, 'days').format('YYYY-MM-DD')}`
			}
		}else if(period == "30-days"){
			dateQuery = {
				"$gte": `ISODate:${moment().add(-30, 'days').format('YYYY-MM-DD')}`
			}
		}
		
		
		// 
		// const queryData = [{
		// 		"$addFields": {
		// 			"formattedDate": {
		// 				"$dateToString": {
		// 					"format": "%Y-%m-%d",
		// 					"date": "$created_timestamp"
		// 				}
		// 			}
		// 		}
		// 	},
		// 	{
		// 		"$match": {
		// 			"formattedDate": dateQuery,
		// 			"event_type": "projectView"
		// 		}
		// 	},
		// 	{
		// 		"$unwind": {
		// 			"path": "$project"
		// 		}
		// 	},
		// 	{
		// 		"$facet": {
		// 			"all": [{
		// 					"$match": {
		// 						"project.address.borough": { "$exists" : 1 }
		// 					}
		// 				},
		// 				{
		// 					"$group": {
		// 						"_id": {
		// 							"project": "$project.key"
		// 						},
		// 						"count": {
		// 							"$sum": 1
		// 						}
		// 					}
		// 
		// 				},
		// 				{
		// 					"$sort": {
		// 						"count": -1
		// 					}
		// 				},
		// 				{
		// 					"$limit": 20
		// 				}
		// 			]
		// 			
		// 		}
		// 	}
		// 
		// 
		// ]
		
		const queryData = [
			{
				"$match": {
					"created_timestamp": dateQuery,
					"event_type": "projectView"
				}
			},
			{
				"$group": {
					"_id": "$project.key",
					"count": {
						"$sum": 1
					}
				}
			},
			{
				"$project": {
					"_id": 0,
					"project_key": "$_id",
					"count": 1
				}
			},
			{
				"$sort": {
					"count": -1
				}
			},
			{
				"$limit": 20
			},
			{
				"$lookup": {
					"from": "pipeline-projects",
					"localField": "project_key",
					"foreignField": "key",
					"as": "project",
					"pipeline": [
						{
							"$match": {
								"meta.deleted": {
									"$exists": false
								}
							}
						},
				{
					"$project": {
						"_id": 1,
						"name": 1,
						"display_full_street_address": 1,
						"address": 1,
						"key": 1,
						"image": 1,
						"images": 1,
						"meta": 1,
						"pipeline_zoning_area_residential": 1,
						"pipeline_total_construction_floor_area": 1,
						"pipeline_building_stories_proposed": 1,
						"pipeline_dwelling_units_proposed": 1,
						"pipeline_filing_date": 1,
						"pipeline_pre_filing_date": 1,
						"pipeline_project_stage": 1,
						"pipeline_coo_issuance_date": 1,
						"pipeline_permit_issuance_job_start_date": 1,
						"pipeline_permit_issuance_filing_date": 1,
						"occupancy_type": 1,
						"pipeline_occupancy_type": 1,
						"pipeline_project_components": 1,
						"date_added": 1,
						"pipeline_occupancy_classification_proposed_code": 1,
						"building_key": 1,
						"project_stage": 1,
						"pipeline_project_stalled": 1,
						"pipeline_project_abandoned": 1
					}
				}                
					]
				}
			}
		]
				 
		let axiosData = {
			url: apiURL + "/buyerslist/event/aggregate-search?cache_time=180&always-cache=true",
			method: "post",
			query: queryData
		};

		let response = await _axiosCall(axiosData)
		.then(res => {
			
			// const buildingIDs = [];
			// if(res && res[0]){
			// 	Object.keys(res[0]).map(borough => {
			// 		 res[0][borough].map(project => {
			// 			 buildingIDs.push(project._id.project);
			// 		 })
			// 	})
			// 	console.log(buildingIDs)
			// 	this._getBuildings(buildingIDs, res[0]);
			// }
			this.setState({ data : res, fetchInProgress : false, buildingsFetchInProgress: false });
			
			
		})
		.catch(error => {
			console.log("error: " + error);
		});
		
		
  };
  
  _getBuildings = async (_buildingIDs, resData) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
			
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		let mongoQuery = {
		  query: { "$and" : [ { "key" : { "$in" : _buildingIDs} }, {"meta.deleted" : { "$exists" : false }}] },
		  projection: {
			_id: 1,
			name: 1,
			display_full_street_address: 1,
			address: 1,
			key: 1,
			image: 1,
			images: 1,
			meta: 1,
			pipeline_zoning_area_residential: 1,
			pipeline_total_construction_floor_area: 1,
			pipeline_building_stories_proposed: 1,
			pipeline_dwelling_units_proposed: 1,
			pipeline_filing_date: 1,
			pipeline_pre_filing_date: 1,
			pipeline_project_stage: 1,
			pipeline_coo_issuance_date: 1,
			pipeline_permit_issuance_job_start_date: 1,
			pipeline_permit_issuance_filing_date: 1,
			occupancy_type: 1,
			pipeline_occupancy_type: 1,
			pipeline_project_components: 1,
			date_added: 1,
			pipeline_occupancy_classification_proposed_code: 1,
			building_key: 1,
			project_stage: 1,
			pipeline_project_stalled: 1,
			pipeline_project_abandoned: 1
		  },
		  limit: 50,
		};


		let axiosData = {
		  url: buildingUrl + "query",
		  method: "post",
		  query: mongoQuery,
		};
		

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				//this._setBuyersList(res);
				if(res && res.docs){
					res.docs.map(bldg => {
						/* if(resData[bldg.borough].filter(b => b._id.building == bldg.key).length>0){
							resData[bldg.borough].filter(b => b._id.building == bldg.key)[0]['building'] = bldg;
						} */
						
						if(resData['all'].filter(b => b._id.project == bldg.key).length>0){
							resData['all'].filter(b => b._id.project == bldg.key)[0]['project'] = bldg;
						}
						
					});
					this.setState({ data : resData, fetchInProgress : false, buildingsFetchInProgress: false });
				}else{
					this.setState({ data : resData, fetchInProgress : false, buildingsFetchInProgress: false });
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
			

		// console.log(formattedData)
		return formattedData;
  };
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  setPeriod = (period) => {
	  this.setState({period:period})
  }
  
  render(){
	const { savedUnits, totalCount, period, data, fetchInProgress } = this.state;
	const { classes, type } = this.props;
	//if(!data) return null;
	return (
		<Card>
		   <CardBody>
			   <Row>
			   		<Col md={12} className={"mb-2"}>
			   			<div className="card__title">
			              <h5 className="bold-text">{'Trending Projects (Most Viewed)'}</h5>
			              
			            </div>
			   			<div className={classes.root}>
			   			  	
					      <ButtonGroup size="small" aria-label="small button group" >							        
					      	<Tooltip title="Most viewed buildings today" aria-label="Most viewed buildings today">
					        	<Button onClick={()=> this.setPeriod("1-day")} className={period === "1-day" ? classNames(classes.button, "selected") : classNames(classes.button)}>1-Day</Button>
					        </Tooltip>
					        {/*<Tooltip title="Most viewed buildings yesterday" aria-label="Most viewed buildings yesterday">
					        	<Button onClick={()=> this.setPeriod("yesterday")} className={period === "yesterday" ? classNames(classes.button, "selected") : classNames(classes.button)}>Yesterday</Button>
					        </Tooltip>*/}
					        <Tooltip title="Most viewed buildings this week" aria-label="Most viewed buildings this week">
					       	 	<Button onClick={()=> this.setPeriod("7-days")} className={period === "7-days" ? classNames(classes.button, "selected") : classNames(classes.button)}>7-Day</Button>
					       	</Tooltip>	
					         <Tooltip title="Most viewed buildings this month" aria-label="Most viewed buildings this month">
					       	 	<Button onClick={()=> this.setPeriod("30-days")} className={period === "30-days" ? classNames(classes.button, "selected") : classNames(classes.button)}>30-Day</Button>
					       	</Tooltip>
					      </ButtonGroup>
					  </div>
			   		</Col>
			   </Row>
			   
			   {(!data || fetchInProgress) ?
				   <div>
				   <LinearLoading />
				   <div style={{height:357}}>
				   	
				   </div>
				   </div>
				   
				   :
				   
				   <ShowBuildings data={data ? data : false} title={""} color={"#4ce1b6"} period={period} />
				
			   }
			   
			      {/* <Row>
			   		<Col md={4} className={"mb-2"}>
			   			<ShowUnits data={data ? data["manhattan"] : false} title={"Manhattan"} color={"#4ce1b6"} period={period} />
			   		</Col>
			   		<Col md={4} className={"mb-2"}>
			   			<ShowUnits data={data ? data["brooklyn"] : false} title={"Brooklyn"} color={"#FFD23F"} period={period} />
			   		</Col>
			   		<Col md={4} className={"mb-2"}>
			   			<ShowUnits data={data ? data["queens"] : false} title={"Queens"}  color={"#28C2FF"}  period={period} />
			   		</Col>					   	  
				   	 
				   </Row>*/}
			   
			    <div className="unit__load text-right semi-bold-text">			   		
				    <Tooltip title="See More">
				        <Link to={`/pipeline-search`}>See All Projects &raquo;</Link>			 
				  	</Tooltip>				
			    </div>
			 </CardBody>
		</Card>
	
	)
	
   }
};

TrendingBuildings.propTypes = {
  t: PropTypes.func.isRequired,
};


export default withStyles(styles,  { withTheme: true })(TrendingBuildings);
