import React, { PureComponent, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import NewsItem from "./NewsItem";
import { _axiosCall, _buildingIDs } from "../../../shared/helpers/apicalls";
import moment from "moment";

import LinearLoading from "../../../shared/components/LinearLoading";
import classnames from "classnames";

import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LoadMoreIcon from "mdi-react/RefreshIcon";
import LoadingIcon from "mdi-react/DotsHorizontalIcon";

import { Publisher } from "../../../shared/pubsub/publisher.js";

import AlertIcon from "mdi-react/InformationOutlineIcon";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  mpndCohortQuery,
  pipelineCohortQuery,
  getCohortQuery,
  GenerateBuildingSlug,
  GenerateBuildingKey,
} from "../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + "/query/";
const buildingUrl = process.env.API_ROOT + "/pipeline/";

class TimelineHistory extends PureComponent {
  state = {
    rowsPerPage: 25,
    fetchInProgress: false,
    clearInput: false,
    start: 0,
    data: [],
    noMoreResults: false,
    activeTab:
      this.props.match && this.props.match.params.subtab
        ? this.props.match.params.subtab
        : "summary",
    buildingsFetchInProgress: false,
    buildingData: false,
  };

  fqMap = {
    summary: !this.props.buildingRes
      ? "(topic:(stories OR offeringplans OR coo)) OR (topic:permits AND event_tags:(nb a1))"
      : "(topic:(stories OR offeringplans OR coo OR listings OR transactions)) OR (topic:permits AND event_tags:(nb a1 dm))",
    articles: "topic:stories",
    construction:
      "topic:(offeringplans OR coo) OR (topic:permits AND event_tags:(nb a1))",
    listings: !this.props.buildingRes
      ? "topic:listings AND event_tags:just-listed AND -event_tags:rent"
      : "topic:listings AND event_tags:just-listed",
    contracts:
      "topic:listings AND event_tags:contract-signed AND -event_tags:rent",
    sales: "topic:transactions",
  };

  componentDidMount() {
    this._getNews();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.start !== prevState.start ||
      this.state.activeTab !== prevState.activeTab
    ) {
      this._getNews();
    }
  }

  _loadMore = () => {
    if (this.state.fetchInProgress === true) {
      return false;
    }
    this.setState({ start: this.state.start + this.state.rowsPerPage });
  };

  _setActiveTab(tab) {
    const { buildingRes } = this.props;
    const newURL = buildingRes
      ? _getBuildingURL(buildingRes, "_id") + (tab ? `/tab/news/${tab}` : "")
      : `/news/${tab}`;
    //props.history.replace(buildingURL);
    window.history && window.history.pushState({}, null, newURL);
    Publisher.publish(`track.NewsView.${tab}`, {});
    this.setState({ activeTab: tab, data: [] });
  }

  _getNews = async () => {
    if (this.state.fetchInProgress === true) {
      return false;
    }
    this.setState({ fetchInProgress: true });
    const { buildingRes, user } = this.props;
    const { activeTab } = this.state;

    const fq = [
      this.fqMap[activeTab],
      "hierarchy:[0 TO 2]",
      `time:[* TO NOW]`,
      "-siblings:*",
    ];

    let q = "*:*";
    if (buildingRes) {
      const addressQ =
        buildingRes.address && buildingRes.address.alternate_addresses
          ? buildingRes.address.alternate_addresses
              .map((a) => a.toLowerCase())
              .join('" OR "')
          : false;

      q = `(full_street_address:("${addressQ}") AND (zip:${
        buildingRes.address.zip
      } OR borough:"${buildingRes.address.borough.toLowerCase()}"))`;
      if (buildingRes.building_slugs && buildingRes.building_slugs.length > 0) {
        q += ` OR building_slugs:(${buildingRes.building_slugs.join(" ")})`;
      }
      //fq[0] = '(topic:(stories OR offeringplans OR coo OR listings OR transactions)) OR (topic:permits AND event_tags:nb)';
    } else {
      fq.push(
        `{!join from=key fromIndex=we3-buildings to=building}(pipeline_permit_source_id:* AND -pipeline_permit_source_id:"" AND deleted:false)`
      );
    }

    const axiosData = {
      url: apiURL + "we3-activities-3",
      method: "post",
      query: {
        q,
        fq,
        sort: activeTab === "articles" ? "time desc" : "time desc",
        wt: "json",
        rows: this.state.rowsPerPage,
        start: this.state.start,
      },
    };

    const formattedData = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length > 0) {
          this._getPipelineBuildings(res);
        } else if (res.docs.length === 0) {
          this.setState({ noMoreResults: true, fetchInProgress: false });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    return formattedData;
  };

  _getPipelineBuildings = async (newsRes) => {
    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let searchFilters = [{ "meta.deleted": { $exists: false } }];

    let buildingKeys = [];
    if (newsRes && newsRes.docs && newsRes.docs.length > 0) {
      newsRes.docs.forEach((news) => {
        if (
          news.alternate_buildings &&
          Array.isArray(news.alternate_buildings)
        ) {
          buildingKeys = [...buildingKeys, ...news.alternate_buildings];
        } else if (news.building && Array.isArray(news.building)) {
          buildingKeys = [...buildingKeys, ...news.building];
        }
      });
    }
    if (buildingKeys.length > 0) {
      searchFilters.push({
        $or: [
          { building_key: { $in: buildingKeys } },
          { "address.key": { $in: buildingKeys } },
        ],
      });
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
      },
      limit: 99,
    };

    let axiosData = {
      url: buildingUrl + "query",
      method: "post",
      query: mongoQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        let docs = [];
        if (res && res.docs) {
          docs = res.docs;
        }
        newsRes.docs.forEach((news) => {
          if (
            news.alternate_buildings &&
            Array.isArray(news.alternate_buildings)
          ) {
            news.alternate_buildings.forEach((blg) => {
              if (
                docs.filter(
                  (doc) =>
                    doc.building_key === blg ||
                    (doc.address && doc.address.key == blg)
                ).length > 0
              ) {
                news["pipeline_project"] = docs.filter(
                  (doc) =>
                    doc.building_key === blg ||
                    (doc.address && doc.address.key == blg)
                )[0];
              }
            });
          } else if (news.building && Array.isArray(news.building)) {
            news.building.forEach((blg) => {
              if (
                docs.filter(
                  (doc) =>
                    doc.building_key === blg ||
                    (doc.address && doc.address.key == blg)
                ).length > 0
              ) {
                news["pipeline_project"] = docs.filter(
                  (doc) =>
                    doc.building_key === blg ||
                    (doc.address && doc.address.key == blg)
                )[0];
              }
            });
          }
        });
        console.log(newsRes.docs.filter((news) => !news["pipeline_project"]));
        this.setState({
          data: [...this.state.data, ...newsRes.docs],
          fetchInProgress: false,
          buildingsFetchInProgress: false,
        });
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  render() {
    const { data, fetchInProgress, activeTab } = this.state;
    if (!data) {
      return null;
    }

    return (
      <Col md={12}>
        <div
          className={`tabs ${
            !this.props.buildingRes ? "tabs--bordered-bottom" : ""
          }`}
        >
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "summary" })}
                  onClick={() => {
                    this._setActiveTab("summary");
                  }}
                >
                  <span className="tab-title">Summary</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "articles" })}
                  onClick={() => {
                    this._setActiveTab("articles");
                  }}
                >
                  <span className="tab-title">Articles</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "construction",
                  })}
                  onClick={() => {
                    this._setActiveTab("construction");
                  }}
                >
                  <span className="tab-title">Construction</span>
                </NavLink>
              </NavItem>
              {/*<NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'listings' })}
		                      onClick={() => {
		                        this._setActiveTab('listings');
		                      }}
		                    >
		                      <span className="tab-title">Listings</span>
		                    </NavLink>
		                  </NavItem>
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'contracts' })}
		                      onClick={() => {
		                        this._setActiveTab('contracts');
		                      }}
		                    >
		                      <span className="tab-title">Contracts</span>
		                    </NavLink>
		                  </NavItem>
		                  
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'sales' })}
		                      onClick={() => {
		                        this._setActiveTab('sales');
		                      }}
		                    >
		                      <span className="tab-title">Sales</span>
		                    </NavLink>
		                  </NavItem>*/}
            </Nav>
          </div>
        </div>
        {fetchInProgress && data.length <= 0 ? (
          <Row>
            <LinearLoading />
          </Row>
        ) : !fetchInProgress && data.length <= 0 ? (
          <div className={`email-confirmation`}>
            <div className="email-confirmation__icon">
              <AlertIcon size={50} color={`#D6BAE8`} />
            </div>
            <h3 className="email-confirmation__title">No News Yet</h3>
            <p className="email-confirmation__sub">
              Marketproof Pipeline is connecting buildings with news daily,
              check back soon.
            </p>
          </div>
        ) : (
          <Fragment>
            <div className="timeline">
              {/*<span className="timeline__start"/>*/}
              {data.map((item) => {
                try {
                  const data = JSON.parse(item.data);
                  item.data = data;
                } catch (e) {
                  //ToDo
                }

                return <NewsItem item={item} />;
              })}
            </div>
            <div className="timeline_load">
              <Tooltip title="Load more news">
                <Button
                  onClick={(e) => {
                    this._loadMore(e);
                  }}
                  variant="contained"
                  color="news"
                  className={`timeline__load-more`}
                  startIcon={<LoadMoreIcon />}
                  disabled={
                    this.state.fetchInProgress || this.state.noMoreResults
                  }
                >
                  {" "}
                  {`${
                    this.state.fetchInProgress
                      ? "Loading..."
                      : this.state.noMoreResults
                      ? "That's All The News"
                      : "Load More"
                  }`}
                </Button>
              </Tooltip>
            </div>
          </Fragment>
        )}
      </Col>
    );
  }
}

export default withRouter(TimelineHistory);
