import React, { Component } from 'react';
import { Redirect, withRouter, Link, Route, useLocation } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import OktaAuth from '@okta/okta-auth-js';

import pkceChallenge from 'pkce-challenge';
import WithTracker from './../../../../containers/App/WithTracker';

import FreeAccount from './../../payment/PricingCards/components/FreeAccount';

import TopBar from './../../TopBar'
import Footer from './../../Footer'
import { _axiosCall } from '../../../helpers/apicalls';
import axios from "axios";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Collapse
} from 'reactstrap';
import{
	setLocalStorage,
	getLocalStorage
} from '../../../helpers/utils';

import{
	_getUserSSO,
	_setSSOToken,
	_getSSOToken
} from './ssoutils';


export default withRouter(class Login extends Component {
  constructor(props) {
	super(props);
	this.onSuccess = this.onSuccess.bind(this);
	this.onError = this.onError.bind(this);
	this.state = {
	  authenticated: null,
	  fetchInProgress: false,
	  authToken: false
	};

  }
  
  componentDidMount() {
	   if(window.location.href.indexOf('redirect')<0){
		   const codes = pkceChallenge();
		   sessionStorage.setItem('code_challenge', codes.code_challenge);
		   sessionStorage.setItem('code_verifier', codes.code_verifier);
		   const AUTH_URL = `${process.env.MP_BASE_URL}authorize?client_id=${process.env.MP_CLIENT_ID}&code_challenge=${codes.code_challenge}&code_challenge_method=S256&redirect_uri=${process.env.MP_REDIRECT_URI}&response_mode=fragment&response_type=code&state=testing&scope=openid email profile`;
		   window.location.href = AUTH_URL;
		   
		   
	   }else if(window.location.href.indexOf('redirect')>=0){   
		   var hash = window.location.hash.substr(1);
		   
		   var hashObject = hash.split('&').reduce(function (res, item) {
			   var parts = item.split('=');
			   res[parts[0]] = parts[1];
			   return res;
		   }, {});
		   
		   console.log(hashObject);
		   this._getAuthToken(hashObject)
	   }
  }
  
  _getAuthToken = (hashObject) => {
	   // console.log('_getBuildingData called', this.state)
	   if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const params = new URLSearchParams();
		params.append('client_id', process.env.MP_CLIENT_ID);
		params.append('redirect_uri', process.env.MP_REDIRECT_URI);
		params.append('grant_type', "authorization_code");
		params.append('code', hashObject.code);
		params.append('code_verifier', sessionStorage.getItem('code_verifier'));
		axios.post(`${process.env.MP_BASE_URL}token`, params).then(token => {
						
			if(token.data && token.data.id_token){
				_setSSOToken(token.data, 'MPSSO');
				console.log(token.data);
				this.setState({authToken : token.data})
				
			}
		})
		.catch(error => {
			console.log("error: " + error);
		});
		
	
	}

 
  onSuccess(res) {
	if (res.status === 'SUCCESS') {
	  return this.props.authService.redirect({
		sessionToken: res.session.token
	  });
   } else {
	
	}
  }

  onError(err) {
	console.log('error logging in', err);
  }

  render() {
	const { location } = this.props;
	const { authToken } = this.state;
	console.log(authToken)
	if(authToken){
		
		return <Redirect to={{ pathname: `/`}} />
	}
	if(window.location.href.indexOf('sso')>=0) return null;
	const isError = this.props.location && this.props.location.search && this.props.location.search == "?error=unauthorized";
	return (
		<div className="plan__wrapper share__wrapper">
			
		</div>
	)
	
	  

		
  }
});