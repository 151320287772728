import React, { PureComponent, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';

import classNames from 'classnames';
import './Payment.scss'
import moment from 'moment';

import { bindActionCreators } from 'redux';

import buyersListActions from '../../../redux/actions/buyersListActions';
import { setUserProfile } from '../../../redux/actions/authActions';

import SnackBar from '../../components/SimpleSnackBar';
import LinearLoading from '../../components/LinearLoading';

import { Publisher } from "./../../pubsub/publisher.js";


import{
	subscriptionPlans,
	_capitalizeText
} from '../../helpers/utils';
import{
	_axiosCall
} from '../../helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/buyerslist/';
//const apiUrl = 'http://localhost:8000/buyerslist/';

class TrialSubscribe extends PureComponent { 
	constructor(props) {
	    super(props);
	    this.state={
		    error:false,
		    fetchInProgress:false,
		    customer:false,
		    subscription:false,
		    paymentMethod:false,
		    snackShow: false,
			snackMessage: false,
			snackType: 'success'
	    }
	    
	    this._getSubscriptionDetails = this._getSubscriptionDetails.bind(this);
	    this._updateSubscriptionDetails = this._updateSubscriptionDetails.bind(this);
	    this._updateUserProfile = this._updateUserProfile.bind(this);
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);

    }
    
    componentDidUpdate() {
	    const { subscribe } = this.props;
	    const { subscription } = this.state;
	    if(subscribe && !subscription){
		    this._getSubscriptionDetails();
	    }
		
	}
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
 
  
    _getSubscriptionDetails = () => {

	  const { subscriptionId } = this.props.user;
	  
	  if(!subscriptionId) return false;
	
	  let userData = {
			url: apiUrl+'stripe/get-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.customer){	
		      this.setState(
			      {
				      customer: res.customer,
				      subscription: res.subscription,
				      paymentMethod: res.paymentMethod
			      }
		      )
		  }else{
			  this.setState({
				  customer: this.props.user,
			      subscription: {},
			      paymentMethod: {}
		      });		  
						  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
	}
	
	_updateSubscriptionDetails = () => {

	  const { subscriptionId } = this.props.user;
	  
	  if(!subscriptionId) return false;
	  
	  if(this.state.fetchInProgress) return false;
	  
	  this.setState({fetchInProgress : true});
	
	  let userData = {
			url: apiUrl+'stripe/update-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId,
				subscriptionData: {
					trial_end: 'now'
				}
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.subscription && res.subscription.status === 'active'){	
		     
		     let updateProfile = { subscriptionStatus:res.subscription.status };
		     if(res.subscription.trial_end){
			     updateProfile['trialEnd'] = res.subscription.trial_end;
			 }
		     
		     this._updateUserProfile(updateProfile);
		      
		  }else{
			 if(res && res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not activated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
			 }		  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
	}
	
	_updateUserProfile= (values) => {
     // console.log('_getBuildingData called', this.state)
           
      const { dispatch, user } = this.props;	  	  
      
	  let profile = Object.assign({}, values);
	  const user_id = user.id;
	  let userData = {
			url: apiUrl+'user/update',
			method: "post",
			query: {
				id: user_id,
				profile: profile
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      this.setState({fetchInProgress : false});
	      if(res && res.profile){
			 
		      this.snackOpen('Your subscription has been activated!');
		      this.toggle();
		      setTimeout(function(){  
			       let oktaUser = Object.assign({}, res.profile);
				   oktaUser['id']= res.id;
				   dispatch(setUserProfile(oktaUser));		
				   Publisher.publish("user-update", oktaUser);	      
			  }.bind(this), 3000);
		     
		      
		  }else{
			  if(res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not activated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
	    this.setState({fetchInProgress : false});
	  });
	}


  
   toggle = () => {
	  const { user, subscribe, setSubscribe } = this.props;
	  setSubscribe(!subscribe);
   }
  
    modalClass = classNames({
      'modal-dialog--colored': false,
      'modal-dialog--header': true,
   });
   
   
    render(){
	    const { user, subscribe, setSubscribe, dataDownload } = this.props;
	    const { error, fetchInProgress, subscription, paymentMethod, snackShow, snackMessage, snackType } = this.state;
	    const { subscriptionPlan } = user;
	    const currentPlan = subscriptionPlans.filter(plan => plan.id === subscriptionPlan)[0];
	    
	    
	    return (
			<div>
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={3000} 
					snackType={snackType} 
				/>
			    <Modal
			      isOpen={subscribe}
			      toggle={this.toggle}
			      modalClassName={`ltr-support`}
			      className={`modal-dialog--primary ${this.modalClass}`}
			    >
			      
				      <div className="modal__header">
				        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				        <h4 className="text-modal  modal__title">Activate Your Subscription</h4>
				      </div>
				      {subscription ?
				      <div className="modal__body" style={{ minHeight:150 }}>
				      	{dataDownload &&
					      	<Fragment>
						      	<div style={{fontSize:16,background:"#F5F7FA",padding:10,marginTop:20,border:'1px solid #780F9E'}}>
						      		<p style={{fontSize:16}}>{`To use the data export features, you must activate your subscription.`}</p>
						      	</div>
						      	<hr/>
					      	</Fragment>
					      	
				      	}
		       
				      	<p style={{fontSize:16}}>{`Activate your subscription to get full access to Marketproof New Development.`} Upon activation, your <strong>{_capitalizeText(paymentMethod.card.brand)} Card</strong> ending in <strong>{paymentMethod.card.last4}</strong> will be charged. {`Thank you joining!`}</p>
				       </div> 
				       :
				        <div className="modal__body" style={{ height:150 }}>
				        	<LinearLoading />
				        </div>
				      }
				      
				      <ButtonToolbar className="modal__footer">
				        <Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}
				        <Button className="modal_ok" type="button" style={{ width:200 }} color={'primary'} onClick={() => { this._updateSubscriptionDetails() }}>
				        	{fetchInProgress ? <span>Processing...	</span> : <span>Activate Subscription</span>}
				        </Button>
				      </ButtonToolbar>
			    </Modal>
			</div>
		    
		  );
	    
    }  
}

function mapStateToProps(state) {
	console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings) ? state.buyersLists.viewedBuildings.slice() : [],
	  		dataSet : state.buyersLists.dataSet
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch), dispatch: dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialSubscribe);