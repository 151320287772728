/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import CloseIcon from "mdi-react/CloseIcon";
import FullscreenIcon from "mdi-react/FullscreenIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";

const CustomHeader = ({ innerProps, currentView, modalProps, isModal }) => {
  console.log(innerProps, currentView);
  const download = () => {
    var element = document.createElement("a");
    var file = new Blob([currentView.url], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = "floorplan.jpg";
    element.click();
  };
  if (!isModal) {
    return null;
  } else {
    return (
      <div className="react-images__custom-header">
        <Tooltip title={`Close`}>
          <IconButton onClick={modalProps.onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Expand Fullscreen`}>
          <IconButton onClick={modalProps.toggleFullscreen}>
            <FullscreenIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Open In New Tab`}>
          <IconButton onClick={() => window.open(`${currentView.src}`)}>
            <OpenInNewIcon size={22} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
};

export default class ProfileGallery extends PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
      })
    ).isRequired,
  };

  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      currentImagePreview: 0,
    };
  }

  changeImg = (i, e) => {
    e.preventDefault();
    this.setState({
      currentImagePreview: i,
      currentImage: i,
    });
  };

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImagePreview: index,
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState((prevState) => ({
      currentImage: prevState.currentImagePreview,
      lightboxIsOpen: false,
    }));
  };

  gotoPrevious = () => {
    this.setState((prevState) => ({
      currentImage: prevState.currentImage - 1,
    }));
  };

  gotoNext = () => {
    this.setState((prevState) => ({
      currentImage: prevState.currentImage + 1,
    }));
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  handleClickImage = () => {
    const { images, building } = this.props;
    const { currentImage } = this.state;
    if (currentImage === images.length - 1) return;
    this.gotoNext();
  };

  render() {
    const { images, button, building } = this.props;
    const { currentImage, currentImagePreview, lightboxIsOpen } = this.state;

    let showImages = [];
    
    if (building && building.images && building.images.length > 0) {
      
      if (
        building.address &&
        building.address.geocode &&
        building.address.geocode.type
      ) {
        let lat = Number(building.address.geocode.coordinates[1]);
        let long = Number(building.address.geocode.coordinates[0]);
        building.images &&
          building.images.forEach((EachImage) => {
            EachImage.image.map((image) => {
              if (image.preview.includes("maps.googleapis.com")) {
                if (!image.preview.includes(`location=${lat},${long}`)) {
                  image.preview = image.preview.replace(
                    "location=",
                    `location=${lat},${long}`
                  );
                }
              }
              return image;
            });
          });
      }

      building.images.map((img) => {
        if (img.image && img.image[0]) {
          let caption = img.Caption ? img.Caption : false;
          if (img.Date) {
            caption += ` (${moment(img.Date).format("M/D/YYYY")})`;
          }
          if(!img.image[0].preview.includes("maps.googleapis.com")){
            showImages.push({ src: img.image[0].preview, caption: caption });
          }
          
        }
      });
    }
    showImages = showImages.length == 0 ? images : showImages;

    if (building && building.diagrams && building.diagrams.length > 0) {
      building.diagrams.map((img) => {
        if (img.image && img.image[0]) {
          let caption = img.Type ? img.Type : false;
          if (img.Date) {
            caption += ` (${moment(img.Date).format("M/D/YYYY")})`;
          }
          showImages.push({ src: img.image[0].preview, caption: caption });
        }
      });
    }
    const slice = 3;
    return (
      <div className="product-gallery">
        {button}
        {/*  <a
          className="product-gallery__current-img"
          onClick={e => this.openLightbox(currentImage, e)}
          href={showImages[currentImage].src}
        >
        {showImages[currentImagePreview].src.indexOf('googleapis')>=0 ? 
	      <img src={`${images[currentImagePreview].src}`} />
	      :
          <div style={{ backgroundImage : `url("${showImages[currentImagePreview].src}")` }} className="product-img" />
        }
        </a> */}
        <div className="product_gallery__gallery">
          {showImages.length > slice ? (
            <Fragment>
              {showImages.slice(0, slice).map((img, i) => (
                <button
                  type="button"
                  key={i}
                  onClick={(e) => this.openLightbox(i, e)}
                  className="product-gallery__img-preview"
                >
                  <img src={img.src} alt="product-img" />
                </button>
              ))}
              <button
                type="button"
                key={5}
                onClick={(e) => this.openLightbox(slice, e)}
                className="product-gallery__img-preview preview-more"
                style={{ backgroundImage: `url(${showImages[slice].src})` }}
              >
                <span>+ {showImages.length - slice}</span>
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {showImages.map((img, i) => (
                <button
                  type="button"
                  key={i}
                  onClick={(e) => this.openLightbox(i, e)}
                  className="product-gallery__img-preview"
                >
                  <img src={img.src} alt="product-img" />
                </button>
              ))}
            </Fragment>
          )}
        </div>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Carousel
                components={{ Header: CustomHeader }}
                currentIndex={currentImage}
                views={showImages}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {/*   <Lightbox
          currentImage={currentImage}
          views={images}
          isOpen={lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        /> */}
      </div>
    );
  }
}
