import React, { PureComponent } from 'react';
import {
  Bar, CartesianGrid, ComposedChart, BarChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList, Cell,
} from 'recharts';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';
import StatIcon from 'mdi-react/FloorPlanIcon';
import ToolTip from '../../../../shared/components/ToolTip';

import { getDefaultChartColors } from '../../../../shared/helpers';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	setURLFromState,
	_formatBigNumber
} from "../../../../shared/helpers/utils";

const CustomizedAxisTick = ( {x, y, stroke, payload} ) => {
		
	   return (
		<g transform={`translate(${x},${y})`}>
		<text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">{_capitalizeText(payload.value)}</text>
	  </g>
	);
}
const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
	<g>
	 {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#780F9E" />*/}
	  <text x={x + width / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle">
		{value === 0 ? '' : value > 1000000 ? _formatBigNumber(value) : _formatNumber(value)}
	  </text>
	</g>
  );
};

const _generateTypeChartData = (statsRes, analysisType) => {
	 if(!statsRes){
		  return [];
	  }
	//const count = analysisType == "units" ? boro.units : analysisType == "sq_ft" ? boro.sq_ft : boro.count;
	let condo_coop = statsRes.condo_coop && statsRes.condo_coop[0] ? 
		analysisType == "units" ? statsRes.condo_coop[0].units : analysisType == "sq_ft" ? statsRes.condo_coop[0].sq_ft : statsRes.condo_coop[0].count : 0;
		
	let rental = statsRes.rental && statsRes.rental[0] ? 
	analysisType == "units" ? statsRes.rental[0].units : analysisType == "sq_ft" ? statsRes.rental[0].sq_ft : statsRes.rental[0].count : 0;
	
	let multi_family = statsRes.multi_family && statsRes.multi_family[0] ? 
	analysisType == "units" ? statsRes.multi_family[0].units : analysisType == "sq_ft" ? statsRes.multi_family[0].sq_ft : statsRes.multi_family[0].count : 0;
	
	let single_family = statsRes.single_family && statsRes.single_family[0] ? 
	analysisType == "units" ? statsRes.single_family[0].units : analysisType == "sq_ft" ? statsRes.single_family[0].sq_ft : statsRes.single_family[0].count : 0;
	
	let office_retail = statsRes.office_retail && statsRes.office_retail[0] ? 
	analysisType == "units" ? 0 : analysisType == "sq_ft" ? statsRes.office_retail[0].sq_ft : statsRes.office_retail[0].count : 0;
	
	if(analysisType == "units"){
		 condo_coop -= statsRes.condo_coop && statsRes.condo_coop[0] && statsRes.condo_coop[0].units_existing ? statsRes.condo_coop[0].units_existing : 0;
		 rental -= statsRes.rental && statsRes.rental[0] && statsRes.rental[0].units_existing ? statsRes.rental[0].units_existing : 0;
		 multi_family -= statsRes.multi_family && statsRes.multi_family[0] && statsRes.multi_family[0].units_existing ? statsRes.multi_family[0].units_existing : 0;
		 single_family -= statsRes.single_family && statsRes.single_family[0] && statsRes.single_family[0].units_existing ? statsRes.single_family[0].units_existing : 0;
		 
	}
	
	let data = [
	  {
		'name': 'Condo / Co-op',
		'value': condo_coop,
		'color': getDefaultChartColors[0],
		'search' : 'pipeline_project_components=condo,co-op'
	  },
	  {
		'name': 'Rental (6+ Units)',
		'value': rental,
		'color': getDefaultChartColors[1],
		'search' : 'pipeline_project_components=rental&min_units=6&max_units=0'
	  },
	  {
		  'name': 'Multi-family (2-5 Units)',
		  'value': multi_family,
		  'color': getDefaultChartColors[2],
		  'search' : 'pipeline_project_components=multi-family'
		},
	  {
		'name': 'Single Family',
		'value': single_family,
		'color': getDefaultChartColors[3],
		'search' : 'pipeline_project_components=single-family'
	  },
	  {
		'name': 'Office / Retail',
		'value': office_retail,
		'color': getDefaultChartColors[4],
		'search' : 'pipeline_project_components=office,retail'
	  }
	 
	];

	return data;
		   
  }


class TypeChart extends PureComponent {
  
  toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  handleClick(data, index) {
	  console.log(data, index);
	  const { history, baseURL } = this.props;
	
	  
	  window.location = `${baseURL}&${data.search}`;
  }

  render() {
	const { t, dir, themeName, facetData, buildingsFetchInProgress, analysisType } = this.props;
	if(!facetData){
		return null;
	}
	const title =  {
		  "count" : "Number of Projects by Project Type",
		  "units" : "Residential Units by Project Type",
		  "sq_ft" : "Total Square Feet by Project Type"
	}
	const stat = buildingsFetchInProgress ? [{
			'name': '',
			'value': 0,
			'color': getDefaultChartColors[0],
			'search' : ''
		  }] :  _generateTypeChartData(facetData, analysisType);
	   
   


	return ( 		  	
		<Card>
			<CardBody className="dashboard__booking-card">
			  <div className="dashboard__booking-total-container">
				  <h5 className="dashboard__booking-total-description">{title[analysisType]}
					  {/*<ToolTip 
						title={`Based on ${this.props.groupedCount} Marketproof Vetted buildings`} 
						body={`Unit mix based on ${_formatNumber(facetData.count)} new development units within ${this.props.groupedCount} Marketproof Vetted buildings `}
					 />*/}
				  </h5>
				  <StatIcon className="dashboard__trend-icon" />
			  </div>
			  <ResponsiveContainer height={280} className="dashboard__area">
				<BarChart data={stat} margin={{ top:20, left: -20, bottom: 38 }}>
				  <XAxis dataKey="name" interval={0} tickLine={false} reversed={false}  tick={<CustomizedAxisTick/>}  padding={{ left:25, right:25 }} height={60} />
				  {/*<YAxis tickLine={false} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} orientation={dir === 'rtl' ? 'right' : 'left'} />
				  <Tooltip content={<TopCensusTooltipContent colorForKey={{ uv: '#555555' }} theme={themeName} />} />*/}
				 {/* <CartesianGrid vertical={false} />*/}
				  <Tooltip formatter={(value, name, props) => ( _formatNumber(value) )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1}}/>

				  <Bar dataKey="value"  name="Count" barSize={50} onClick={this.handleClick.bind(this)} cursor="pointer">
					{stat.map((item, index) => <Cell fill={item.color} key={index} />)})}
					<LabelList dataKey="value" content={renderCustomizedLabel} />
				  </Bar>
				</BarChart>
			  </ResponsiveContainer>
	   </CardBody>
	</Card>
	);
  }
}

export default (TypeChart);
