import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from 'mdi-react/SortIcon';
import FilterListIcon from 'mdi-react/FilterListIcon';
import { Tooltip } from '@material-ui/core';


class MatTableFilterButton extends React.Component {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
  };

  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSort = (property, order) => (event) => {
    const { handleRequestSort } = this.props;
    handleRequestSort(event, property, order);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
	const { type } = this.props;
    return (
      <span>
      	<Tooltip title={`Sort Projects by Different Attributes`}>
	        <IconButton
	          className=""
	          color="inherit"
	          aria-owns={anchorEl ? 'simple-menu' : null}
	          aria-haspopup="true"
	          onClick={this.handleClick}
	          
	        >
	          <SortIcon color="#DBE0E8" />
	        </IconButton>
	    </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <div className="pl-4 pr-4">Sort By:</div>
          <hr className="mt-1 mb-1"/> 
          
          <MenuItem onClick={this.handleSort('meta.modified', 'desc')} className="material-table__filter-menu-item">Last Updated (Newest)</MenuItem>
          <MenuItem onClick={this.handleSort('meta.modified', 'asc')} className="material-table__filter-menu-item">Last Updated (Old)</MenuItem> 
          <MenuItem onClick={this.handleSort('pipeline_filing_date', 'desc')} className="material-table__filter-menu-item">Permit Filed (Newest)</MenuItem>
          <MenuItem onClick={this.handleSort('pipeline_filing_date', 'asc')} className="material-table__filter-menu-item">Permit Filed (Old)</MenuItem>        
		  {type !== "commercial" && 
			  <Fragment>
			  	<MenuItem onClick={this.handleSort('pipeline_dwelling_units_proposed', 'desc')} className="material-table__filter-menu-item">Units (Most)</MenuItem>
			  	<MenuItem onClick={this.handleSort('pipeline_dwelling_units_proposed', 'asc')} className="material-table__filter-menu-item">Units (Least)</MenuItem>
			  </Fragment>
          }
          

          <MenuItem onClick={this.handleSort('pipeline_building_stories_proposed', 'desc')} className="material-table__filter-menu-item">Floors (Most)</MenuItem>
          <MenuItem onClick={this.handleSort('pipeline_building_stories_proposed', 'asc')} className="material-table__filter-menu-item">Floors (Least)</MenuItem>
          <MenuItem onClick={this.handleSort('pipeline_total_construction_floor_area', 'desc')} className="material-table__filter-menu-item">Total Area (Most)</MenuItem>
          <MenuItem onClick={this.handleSort('pipeline_total_construction_floor_area', 'asc')} className="material-table__filter-menu-item">Total Area (Least)</MenuItem>
         
          {/*<MenuItem onClick={this.handleSort('pipeline_permit_issuance_job_start_date', 'asc')} className="material-table__filter-menu-item">Permit Issued (0-9)</MenuItem>
          <MenuItem onClick={this.handleSort('pipeline_permit_issuance_job_start_date', 'desc')} className="material-table__filter-menu-item">Permit Issued (9-0)</MenuItem>
          <MenuItem onClick={this.handleSort('building_effective_start', 'asc')} className="material-table__filter-menu-item">CO/TCO Issued (0-9)</MenuItem>
          <MenuItem onClick={this.handleSort('building_effective_start', 'desc')} className="material-table__filter-menu-item">CO/TCO Issued (9-0)</MenuItem>
          <MenuItem onClick={this.handleSort('offering_submitted_date', 'asc')} className="material-table__filter-menu-item">OP Submitted (0-9)</MenuItem>
          <MenuItem onClick={this.handleSort('offering_submitted_date', 'desc')} className="material-table__filter-menu-item">OP Submitted (9-0)</MenuItem>
          <MenuItem onClick={this.handleSort('display_full_street_address', 'asc')} className="material-table__filter-menu-item">Address (A-Z)</MenuItem>
          <MenuItem onClick={this.handleSort('display_full_street_address', 'desc')} className="material-table__filter-menu-item">Address (Z-A)</MenuItem>*/}
        </Menu>
      </span>
    );
  }
}

export default MatTableFilterButton;
