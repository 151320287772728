import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

import { setUserProfile } from '../../../../redux/actions/authActions';
import buyersListActions from '../../../../redux/actions/buyersListActions';
import{
	_axiosCall
} from '../../../../shared/helpers/apicalls';
import{
	getLocalStorage,
	deleteLocalStorage
} from '../../../../shared/helpers/utils';
import {
  _getUserSSO,
  _setSSOToken,
  _getSSOToken,
  _getMPUserSSO,
} from "../../../../shared/components/auth/sso/ssoutils";
import { Publisher } from "./../../../pubsub/publisher.js";

import { _isPipelineSubscriber } from '../../../../shared/components/payment/plans';

const oktaToken = process.env.OKTA_TOKEN;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchInProgress: false
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }
  async login() {
    this.props.authService.login('/');
  }

  async logout() {
    this.props.authService.logout('/');
  }
  
  componentDidMount(){
	  const { authState, authService, dispatch, user, dataSet } = this.props;
	  const ssoToken = _getSSOToken();
	  console.log(authState, buyersListActions);
	   if (!authState.isPending && !authState.isAuthenticated && !ssoToken) {
	      // When user isn't authenticated, forget any user info
	      //setUserInfo(null);
	      dispatch(setUserProfile(null));
	   } else {
		  if (!authState.isPending && !user && !ssoToken) {
		 	authService.getUser().then((user) => {	        
		        this._getUserOktaData(user);
		        if(!dataSet){
			        this._getUserMongoData(user); 
		        }
		         
		      }, error => {
			      console.error(error);
		      });
			} else if (ssoToken && ssoToken.access_token && !user) {
				_getUserSSO(ssoToken, this.props, setUserProfile).then(() => {});
			}
	      
	  }
  }
  
  _getUserOktaData = (user) => {
     // console.log('_getBuildingData called', this.state)
     if(!user) return false;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
      
      const { authState, authService, dispatch } = this.props;
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://${process.env.OKTA_DOMAIN}/api/v1/users/${user.sub}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res){
		      this.setState({ fetchInProgress : false });
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
		      
	      }
		  

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({ fetchInProgress : false });
	    dispatch(setUserProfile(user));
	  });
  }
  
  _getUserMongoData = (user) => {
  		if(!user) return false;
        if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
         }
      
        const { authState, authService, dispatch } = this.props;
      
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/userdata/${user.sub}`,
			method: "get"
		};

		 _axiosCall(axiosData)
			.then(res => {
				console.log('Data', res);
				if(res && res[0]){
					this.setState({ fetchInProgress : false });
					let mongoUser = Object.assign({}, res[0]);
					dispatch(buyersListActions.setUserData(mongoUser))
					
				}else{
					dispatch(buyersListActions.setUserData({}))
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

   }

  render() {
  
  		const { authService, authState, user } = this.props;
  		
  		
  		const ssoToken = _getSSOToken();	
  		if (authState.isPending || (ssoToken && !user)) return null;
  		if (!authState.isAuthenticated && !(user && user.authType === 'sso')) return <Redirect  to={{ pathname: '/mplogin' }}/>;
  		if(user === null) return null;
		 		
  		if(user && !_isPipelineSubscriber(user)){
	  		return <Redirect to={{ pathname: "/learn-more" }} />
  		}else{
	  		Publisher.publish("login", user);	
	  		const redirect = getLocalStorage("mp", "redirect");	
	  		if(redirect){
		  		deleteLocalStorage("mp", "redirect");
		  		return <Redirect  to={{ pathname: redirect }}/> 
	  		}
	  		
	  		return <Redirect  to={{ pathname: '/dashboard' }}/> 
  		}
  }

}

export default connect(state => ({
  user: state.user
}))(withOktaAuth(Home))