import React, { Component, Fragment } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import buyersListActions from "../../redux/actions/buyersListActions";
import buyersListCard from "./types";
import { RTLProps, ThemeProps } from "../../shared/prop-types/ReducerProps";
import EmptyBuyersList from "./components/EmptyBuyersList";
import { _axiosCall } from "../../shared/helpers/apicalls";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import LinearLoading from "../../shared/components/LinearLoading";
import ContactUnlockedIcon from "mdi-react/AccountCheckIcon";
import MatAppBar from "./components/MatAppBar";
import { _hasPermission } from "../../shared/helpers/access";
import EnterpriseGate from "../../shared/components/payment/EnterpriseGate";

import moment from "moment";
import {
  buildingFL,
  getCohortQuery,
  ProfileAvatar,
  setLocalStorage,
  sortArray,
  syncGenericFilter,
  _capitalizeText,
  _fixGoogleImage,
  _formatPhoneNumber,
  _getBuildingURL,
  _isEqual,
  _PickContactOrg,
  _PickOrgName,
  _PickOrgRole,
} from "../../shared/helpers/utils";
import PeopleCardView from "../SingleOrganization/components/Contacts/components/PeopleCardView";
import { Tooltip } from "@material-ui/core";
import MatTableFilterButton from "./components/MatTableFilterButton";

const buildingUrl = process.env.API_ROOT + "/pipeline/";

const RenderSingleContact = ({ contact, buildingRes }) => {
  const roles = [];
  if (
    contact &&
    contact.project_roles &&
    Object.keys(contact.project_roles).length > 0
  ) {
    Object.keys(contact.project_roles).forEach((key) => {
      const p = contact.project_roles[key];
      p.map((r) => {
        r =
          r === "applicant"
            ? "permit applicant"
            : r === "filing_applicant"
            ? "permit applicant"
            : r;
        if (roles.indexOf(r) < 0) {
          roles.push(r);
        }
      });
    });
  }
  return (
    <tr>
      <td>
        <ProfileAvatar contact={contact} />
      </td>
      <td className="contact-table-head-fstyle">
        <a href={`/contact/${contact["key"]}`}>
          {contact.first_name
            ? `${_capitalizeText(contact.first_name)} ${_capitalizeText(
                contact.last_name
              )}`
            : "--"}
        </a>
        <div className="light-text small-text mt-2">Added {moment(contact.timestamp).fromNow()}</div>
      </td>
      <td className="contact-table-fstyle" style={{ whiteSpace: "nowrap" }}>
        {roles && roles[0] ? `${_capitalizeText(roles[0])}` : "--"}
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {_PickContactOrg(contact) && _PickContactOrg(contact).name ? (
          <a
            href={`/company/${_PickContactOrg(contact)["key"]}`}
            // target="_blank"
          >
            {_capitalizeText(_PickContactOrg(contact).name)}
          </a>
        ) : (
          "--"
        )}
      </td>

      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.email &&
        Array.isArray(contact.email) &&
        contact.email[0] &&
        contact.email[0].length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${contact.email[0]}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} /> {contact.email[0]}
          </a>
        ) : contact.email && contact.email.length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${contact.email}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} /> {contact.email}
          </a>
        ) : null}
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.phone &&
        Array.isArray(contact.phone) &&
        contact.phone[0] &&
        contact.phone[0].length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${contact.phone[0]}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {_formatPhoneNumber(contact.phone[0])}
          </a>
        ) : contact.phone && contact.phone.length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${contact.phone[0]}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} /> {_formatPhoneNumber(contact.phone)}
          </a>
        ) : null}
      </td>
     
    </tr>
  );
};

const RenderSingleOrganization = ({ contact, buildingRes }) => {
  const roles = [];
  if (
    contact &&
    contact.project_roles &&
    Object.keys(contact.project_roles).length > 0
  ) {
    Object.keys(contact.project_roles).forEach((key) => {
      const p = contact.project_roles[key];
      p.map((r) => {
        r =
          r === "applicant"
            ? "permit applicant"
            : r === "filing_applicant"
            ? "permit applicant"
            : r;
        if (roles.indexOf(r) < 0) {
          roles.push(r);
        }
      });
    });
  }
  return (
    <tr>
      <td
        className="dashboard__table-orders-total contact-table-head-fstyle"
        dir="ltr"
      >
        <a
          href={`/company/${contact["key"]}`}
          // target="_blank"
        >
          {contact.name
            ? _capitalizeText(_PickOrgName(contact, buildingRes.key))
            : "--"}
        </a>
        <div className="light-text small-text  mt-2">Added {moment(contact.timestamp).fromNow()}</div>
      </td>
      <td className="contact-table-fstyle">
        {roles && roles[0] ? `${_capitalizeText(roles[0])}` : "--"}
      </td>

      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.email &&
        Array.isArray(contact.email) &&
        contact.email[0] &&
        contact.email[0].length > 0 &&
        contact.email[0].indexOf("@") >= 0 ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${contact.email[0]}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} /> {contact.email[0]}
          </a>
        ) : contact.email &&
          contact.email.length > 0 &&
          contact.email.indexOf("@") >= 0 ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`mailto:${contact.email}`}
            target="_blank"
          >
            <EmailOutlineIcon size={18} /> {contact.email}
          </a>
        ) : null}
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        {contact.phone &&
        Array.isArray(contact.phone) &&
        contact.phone[0] &&
        contact.phone[0].length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${contact.phone[0]}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {_formatPhoneNumber(contact.phone[0])}
          </a>
        ) : contact.phone && contact.phone.length ? (
          <a
            className="product__external-link contact-table-fstyle"
            href={`tel:${contact.phone[0]}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} /> {_formatPhoneNumber(contact.phone)}
          </a>
        ) : null}
      </td>
      <td
        className="dashboard__table-orders-total contact-table-fstyle"
        dir="ltr"
      >
        
      </td>
    </tr>
  );
};

const _setTimestamp = (results, contactsShown, orderBy) => {
  
  if(orderBy === "timestamp"){
    return contactsShown.map(item => {
        item['timestamp'] = item['created_timestamp'];
        item = Object.assign({}, item, results.filter(r => r.key == item._id.contact)[0]);
        
        return item;
    })
  }else{
    return results.map(item => {
        item['timestamp'] = contactsShown.filter(s => item.key == s._id.contact)[0].created_timestamp;
        return item;
    })
  }
  
  
}

class MyContacts extends Component {
  lsPre = `MYCONTACT_DB_`;
  static propTypes = {
    actions: PropTypes.shape({
      editMyContacts: PropTypes.func,
      completeMyContacts: PropTypes.func,
      deleteMyContacts: PropTypes.func,
      addMyContacts: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    buyerslist: PropTypes.arrayOf(buyersListCard).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      incompleteMyContactss: false,
      completedMyContactss: false,
      buildingsFetchInProgress: false,
      buyersList: null,
      buyersListAnalytics: null,
      activeTab: this.props.match && this.props.match.params.subtab ? this.props.match.params.subtab : "people",
      contacts: null,
      companies: null,
      viewType: syncGenericFilter("viewType", "viewType", this.lsPre, "list"),
      order: syncGenericFilter("order", this.lsPre, "order", "desc"),
      orderBy: syncGenericFilter(
        "order_by",
        this.lsPre,
        "orderBy",
        "timestamp"
      ),
      searchText: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    // this._getPipelineBuildings(nextProps);
  }

  componentDidMount() {
    this._getUserContacts();
    //this._getUnlockedContacts(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contacts, companies } = this.state;
    if (
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.activeTab !== prevState.activeTab ||
      this.state.searchText !== prevState.searchText
    ) {
      {
        {/* this.state.activeTab == "people"
          ? this._getUnlockedContacts(this.props)
          : this._getUnlockedCompanies(this.props); */}
        this._getUserContacts();
      }
    }
  }

  handleBuildingClick = (event, building) => {
    event.preventDefault();
    event.stopPropagation();
    const buildingURL = _getBuildingURL(building, "_id");

    window.open(buildingURL);
  };
  
  _getUserContacts = () => {
    const { user } = this.props;
    const { activeTab, order } = this.state;
    let dateQuery = {
    "$gte": moment().add(-30, 'days').format('YYYY-MM-DD')
    };
    let mongoQuery = [{
        "$addFields": {
          "formattedDate": {
            "$dateToString": {
              "format": "%Y-%m-%d",
              "date": "$created_timestamp"
            }
          }
        }
      },
      {
        "$match": {
       //   "formattedDate": dateQuery,
          "event_type": {"$in" : ["showContactInfo", "showCompanyInfo"]},
          "user_key" : user.id,
          "meta.deleted": { $exists: false }
        }
      },
      {
        "$facet": {
          "shown": [{
              "$group": {
                "_id": {
                  "contact": "$contact.key"
                },
                "created_timestamp": { $max: "$created_timestamp" },
  
                "count": {
                  "$sum": 1
                }
              }
            },
            {
              "$sort": {
                "created_timestamp": order === "asc" ? 1 : -1
              }
            },
           {
              "$limit": 9999
            }
          ]
    
        }
      }
    
    ]	 
    let axiosData = {
      url: `${process.env.API_ROOT}/buyerslist/event/aggregate`,
      method: "post",
      query: mongoQuery,
    };
  
    _axiosCall(axiosData).then(res => {
      console.log(res);
      if(res[0] && res[0].shown && res[0].shown.length>0){
        if(activeTab == "people"){
          this._getUnlockedContacts(res[0].shown)
        }else{
          this._getUnlockedCompanies(res[0].shown);
        }
      }else{
        this.setState({ contacts: [], companies:[] })
      }
      
    });
  
    //return response;
  };

  _getUnlockedContacts = async (contactsShown) => {
    
    const { orderBy, searchText } = this.state;
    let contactKeys = contactsShown
      .filter((item) => !item.deleted)
      .map((item) => item._id.contact);

    let reverseContactKeys = [...contactKeys].reverse();
    let searchFilters = [{ "meta.deleted": { $exists: false } }];

    if (contactKeys.length > 0) {
      searchFilters.push({ key: { $in: contactKeys } });
    } else {
      return false;
    }
    
    if(searchText && searchText.length>0){
      searchFilters.push({ "$or" : [{"first_name" : { "$regex" : searchText.toLowerCase()}}, {"last_name" : { "$regex" : searchText.toLowerCase()}}, { "organizations.name" : { "$regex" : searchText.toLowerCase()} }]})
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        key: 1,
        name: 1,
        title: 1,
        organizations: 1,
        organization_associations: 1,
        phone: 1,
        email: 1,
        address: 1,
        first_name: 1,
        last_name: 1,
        meta: 1,
        project_roles: 1,
        photo: 1,
      },

      limit: 9999,
    };
    if (this.state.orderBy === "first_name") {
      mongoQuery["sort"] = {
        first_name: this.state.order === "asc" ? 1 : -1,
        last_name: this.state.order === "asc" ? 1 : -1,
      };
    }

    let axiosData = {
      url: buildingUrl + "query/contacts",
      method: "post",
      query: mongoQuery,
    };
    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length>0) {
          
          this.setState({ contacts: _setTimestamp(res.docs, contactsShown, orderBy) });
        }else{
          this.setState({ contacts: [] })
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };
  
  _getUnlockedCompanies = async (contactsShown) => {
    const { orderBy, searchText } = this.state;
    const contactKeys = contactsShown
      .filter((item) => !item.deleted)
      .map((item) => item._id.contact);

    let reverseContactKeys = [...contactKeys].reverse();
    let searchFilters = [{ "meta.deleted": { $exists: false } }];

    if (contactKeys.length > 0) {
      searchFilters.push({ key: { $in: contactKeys } });
    } else {
      return false;
    }
    if(searchText && searchText.length>0){
      searchFilters.push({ "$or" : [{"name" : { "$regex" : searchText.toLowerCase()}}]})
    }
    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        key: 1,
        name: 1,
        title: 1,
        organizations: 1,
        organization_associations: 1,
        phone: 1,
        email: 1,
        address: 1,
        first_name: 1,
        last_name: 1,
        meta: 1,
        project_roles: 1,
        photo: 1,
      },
      limit: 9999,
    };

    if (this.state.orderBy === "first_name") {
      mongoQuery["sort"] = {
        name: this.state.order === "asc" ? 1 : -1
      };
    }

    let axiosData = {
      url: buildingUrl + "query/organizations",
      method: "post",
      query: mongoQuery,
    };
    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length>0) {
          
          this.setState({ companies: _setTimestamp(res.docs, contactsShown, orderBy) });
        }else{
          this.setState({ companies: [] })
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _toggleView = (type) => {
    this.setState({ viewType: type });
    setLocalStorage(this.lsPre, "viewType", type);
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    const newURL = `/my-contacts/${tab}`;
    window.history && window.history.pushState({}, null, newURL);
  };

  handleRequestSort = (orderBy, order) => {
    console.log(orderBy, order);

    this.setState({ orderBy: orderBy, order: order });
  };
  
  _resetActive = () => {
    let isDefault = true;
    return !isDefault;
  };
  _filterTableByInput = (name, eValue) => {
    console.log("_filterTableByInput name", name, "eValue", eValue);
    this.setState({searchText: eValue})
  
  };

  render() {
    const { user } = this.props;
    const { contacts, companies, viewType, activeTab } = this.state;
    
    if(!_hasPermission(user, 'project-contacts')){
     return (
        <div className="dashboard">
          <Container className="todo-app">
            <Row>
              <EnterpriseGate 
                copy={<span>Access to Contacts requires an upgrade to your subscription.</span>}
                upgrade={false}
                user={user}
                img={`https://marketproof.com/svgs/PLyes.svg`}
                classes={"small-image"}
              />
            </Row>
          </Container>
          </div>
      )
    }

    return (
      <div className="dashboard">
        <Container className="todo-app">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{`My Contacts`}</h3>
              <h3 className="page-subhead subhead">People and companies with contact information</h3>
            </Col>
          </Row>

          <div className="tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "people" })}
                    onClick={() => {
                      this.toggle("people");
                    }}
                  >
                    <span className="tab-title">People</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "companies",
                    })}
                    onClick={() => {
                      this.toggle("companies");
                    }}
                  >
                    <span className="tab-title">Companies</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <Card>
            <CardBody>
              <div className="mb-3" >
              <MatAppBar
                handleSearch={this._filterTableByInput}
                numSelected={0}
                handleDeleteSelected={this.handleDeleteSelected}
                onRequestSort={this.handleRequestSort}
                subFilters={{}}
                resetSubFilter={this._resetSubFilter}
                buildingStages={[]}
                setBuildingStage={this._setBuildingStage}
                setSubFilter={this._setSubFilter}
                _filters={
                  []
                }
                buildingAttributes={[]}
                goToBuilding={true}
                hideBuilding={true}
                resetActive={this._resetActive()}
                type={""}
                q={this.q}
                fq={this.fq}
                fl={""}
                sort={this.sort}
                totalCount={0}
                hidePeople
                hideProjects
                addBuilding
                allowTextSearch
                activeTab={activeTab}
                companies={companies}
                contacts={contacts}
              />
              </div>
              <div className="d-flex justify-content-end inner_contact_card_div">
                <ButtonGroup
                  size="small"
                  className="d-block"
                  aria-label="small outlined button group"
                  style={{ marginLeft: "15px" }}
                >
                  <Tooltip title={`See projects in a photo grid`}>
                    <Button
                      disabled={viewType == "grid"}
                      onClick={() => {
                        this._toggleView("grid");
                      }}
                      startIcon={
                        <GridViewIcon
                          size={14}
                          color={viewType != "grid" ? "#780F9E" : "#A3ADC2"}
                        />
                      }
                    >
                      Grid
                    </Button>
                  </Tooltip>
                  <Tooltip title={`See projects in a list`}>
                    <Button
                      disabled={viewType == "list"}
                      onClick={() => {
                        this._toggleView("list");
                      }}
                      startIcon={
                        <ListViewIcon
                          size={14}
                          color={viewType != "list" ? "#780F9E" : "#A3ADC2"}
                        />
                      }
                    >
                      List
                    </Button>
                  </Tooltip>
                </ButtonGroup>
               
              </div>

              <TabContent activeTab={activeTab}>
                {activeTab == "people" && (
                  <TabPane tabId="people">
                    {contacts === null ? (
                      <LinearLoading />
                    ) : contacts.length <= 0 ? (
                      <Row className="mt-5">
                        <EmptyBuyersList />
                      </Row>
                    ) : viewType == "grid" ? (
                      <PeopleCardView
                        contacts={contacts}
                        viewType={"MyContacts"}
                      />
                    ) : (
                      <div class="card">
                        <div className="material-table__wrap mt-4 bg-white">
                          <div className="material-table expansion__table">
                            <Table
                              responsive
                              striped
                              className="dashboard__table-orders"
                            >
                              <thead>
                                <tr>
                                  <th />
                                  <th>Contact Name</th>
                                  <th >Role</th>
                                  <th>Company</th>

                                  <th style={{ width: 150 }}>Email</th>
                                  <th >Phone</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {contacts.filter((contact) => contact && contact.meta && !contact.meta.deleted).map((contact, index) => (
                                  <RenderSingleContact
                                    buildingRes={contact}
                                    key={index}
                                    contact={contact}
                                  />
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPane>
                )}
                {activeTab == "companies" && (
                  <TabPane tabId="companies">
                    {companies === null ? (
                      <LinearLoading />
                    ) : companies.length <= 0 ? (
                      <Row className="mt-5">
                        <EmptyBuyersList />
                      </Row>
                    ) : viewType == "grid" ? (
                      <PeopleCardView
                        contacts={companies}
                        viewType={"MyContacts"}
                        companyCard={true}
                      />
                    ) : (
                      <div class="card">
                        <div className="material-table__wrap mt-4 bg-white">
                          <div className="material-table expansion__table">
                            <Table
                              responsive
                              striped
                              className="dashboard__table-orders"
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: 200 }}>Company</th>
                                  <th style={{ width: 200 }}>Role</th>

                                  <th style={{ width: 150 }}>Email</th>
                                  <th style={{ width: 160 }}>Phone</th>
                                </tr>
                              </thead>
                              <tbody>
                                {companies.filter((contact) => contact && contact.meta && !contact.meta.deleted).map((company, index) => (
                                  <RenderSingleOrganization
                                    buildingRes={company}
                                    key={index}
                                    contact={company}
                                  />
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPane>
                )}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    savedBuildings: Array.isArray(state.buyersLists.savedBuildings)
      ? state.buyersLists.savedBuildings.slice()
      : [],
    savedProjects: Array.isArray(state.buyersLists.savedProjects)
      ? state.buyersLists.savedProjects.slice()
      : [],
    savedContacts: Array.isArray(state.buyersLists.savedContacts)
      ? state.buyersLists.savedContacts.slice()
      : [],
    savedOrganizations: Array.isArray(state.buyersLists.savedOrganizations)
      ? state.buyersLists.savedOrganizations.slice()
      : [],
    contactsShown: Array.isArray(state.buyersLists.contactsShown)
      ? state.buyersLists.contactsShown.slice()
      : [],
    dataSet: state.buyersLists.dataSet,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(MyContacts));
