import React, { PureComponent, Fragment } from 'react';
import { Progress, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';
import { getDefaultChartColors } from '../../../../../../shared/helpers';
import moment from "moment";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_formatUnitNumber
} from '../../../../../../shared/helpers/utils';

const types = [0, 1, 2, 3, 4];
	
class UnitList extends PureComponent {
  

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }
 
  handleClick(e,unit) {
  	
  	const { history, location } = this.props; 
  	const base_url = location.pathname.replace('analysis', 'units');	
  	history.push(`${base_url}?offering_bedrooms=${unit.search}`);

  }

  
  render() {
	const { t, unitData, title } = this.props;
    if(!unitData){
	    return null;
    }

	return (
			<Fragment>
			<h4 class="bold-text mt-1">{title ? title : "Unit List (Sorted by Status, Bedroom, and Price)"} </h4>
		    <Table responsive className="table--bordered dashboard__audience-table table--unit__matrix">
		      <thead>
			  	<tr>
					<th />
					<th className="th-sec unit-info" colSpan="8">Unit Information</th>
					<th className="th-sec status-info" colSpan="9">Status Information</th>
				</tr>
		        <tr>
				  <th className="th" >Unit</th>
				  <th className="th">Bed</th>
		          <th className="th">Bath</th>
		          <th className="th">Int. SF</th>
		          <th className="th">Ext. SF</th>
		          <th className="th">Monthly</th>
		          <th className="th">Monthly/SF</th>
				  <th className="th">OP</th>
				  <th className="th">Sale Type</th>
				  <th className="th">Status</th>
				  <th className="th">Ask Price</th>
				  <th className="th">Sale Price</th>
				  <th className="th">PPSF</th>
				  <th className="th">Signed</th>
				  <th className="th">Closed</th>
				  <th className="th">Listed</th>
				  <th className="th">DOM</th>
		        </tr>
				
		      </thead>
		      <tbody>
		        {unitData.map(d => 
		        <tr>
		         	<td className="td all">{_formatUnitNumber(d.address_2_normalized).replace("#", "Apt")}</td>
					<td className="td">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</td>
					<td className="td">{d.baths > -1 ? d.baths+' Bath' : '--'}</td>
					<td className="td">{_shouldShow(d.sq_ft) ? _formatNumber(d.sq_ft)+' sf' : '--'}</td>
					<td className="td">{_shouldShow(d.offering_exterior_sq_ft) ? <React.Fragment>{_formatNumber(d.offering_exterior_sq_ft)} sf</React.Fragment>  : `--`}</td>
					<td className="td">{_shouldShow(d.offering_monthly_carrying_charges) ? `$${_formatNumber(d.offering_monthly_carrying_charges)}` : "--"}</td>
					<td className="td">{_shouldShow(d.offering_monthly_carrying_charges) && _shouldShow(d.sq_ft) ? `$${Math.round((d.offering_monthly_carrying_charges/d.sq_ft)*100)/100}` : "--"}</td>
					<td className="td">{_shouldShow(d.offering_price) ? `$${_formatNumber(d.offering_price)}` : "--"}</td>
					<td className="td">{d.times_sold >= 2 ? "Resale" : "Sponsor"}</td>
					<td className="td">{["", "expired", "tom"].indexOf(d.offering_status)>=0 ? "Shadow" : _capitalizeText(d.offering_status.replace(/_/g, " "))}</td>
					<td className="td">{_shouldShow(d.last_listed_sale_price_all) ? `$${_formatNumber(d.last_listed_sale_price_all)}` : "--"}</td>
					<td className="td">{_shouldShow(d.last_sold_price) ? `$${_formatNumber(d.last_sold_price)}` : "--"}</td>
					<td className="td">{_shouldShow(d.last_sold_price) && _shouldShow(d.sq_ft) ? `$${_formatNumber(d.last_sold_price/d.sq_ft)}` : "--"}</td>
					<td className="td">{_shouldShow(d.last_contract_price) ? `$${_formatNumber(d.last_contract_price)}` : "--"}</td>
					<td className="td">{parseInt(moment.unix(d.last_sold_date).format('YYYY')) > 1970 ? moment.unix(d.last_sold_date).format('MM/DD/YYYY') : "--"}</td>
					<td className="td">{parseInt(moment.unix(d.last_listed_sale_date_all).format('YYYY')) > 1970 ? moment.unix(d.last_listed_sale_date_all).format('MM/DD/YYYY') : "--"}</td>
					<td className="td">{parseInt(moment.unix(d.last_listed_sale_date_all).format('YYYY')) > 1970 && d.last_contract_date > d.last_listed_sale_date_all ? `${Math.round((d.last_contract_date - d.last_listed_sale_date_all)/60/60/24)} days` : "--"}</td>
		        </tr>
		        )}
		      </tbody>
		    </Table>
			</Fragment>
	  )
	  
  }
}

export default UnitList;
