import React, { PureComponent, Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import NewsItem from "./components/NewsItem";
import { _axiosCall, _buildingIDs } from "../../../../shared/helpers/apicalls";
import moment from "moment";

import LinearLoading from "../../../../shared/components/LinearLoading";
import classnames from "classnames";

import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LoadMoreIcon from "mdi-react/RefreshIcon";
import LoadingIcon from "mdi-react/DotsHorizontalIcon";

import { Publisher } from "../../../../shared/pubsub/publisher.js";
import EditFeed from "../../../../shared/components/edit/EditFeed";
import { _hasPermission } from "../../../../shared/helpers/access";
import SnackBar from "../../../../shared/components/SimpleSnackBar";
import AlertIcon from "mdi-react/InformationOutlineIcon";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  mpndCohortQuery,
  pipelineCohortQuery,
  getCohortQuery,
} from "../../../../shared/helpers/utils";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import { Button as ReactButton } from "reactstrap";

const apiURL = process.env.API_ROOT + "/query/";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
}));

class ProjectFeed extends PureComponent {
  state = {
    rowsPerPage: 25,
    fetchInProgress: false,
    clearInput: false,
    start: 0,
    data: null,
    noMoreResults: false,
    activeTab:
      this.props.match && this.props.match.params.subtab
        ? this.props.match.params.subtab
        : "summary",
    changed: false,
    showHidden: false,
    snackShow: false,
    snackMessage: false,
    snackType: "success",
  };

  fqMap = {
    summary: !this.props.buildingRes
      ? "(topic:(stories OR offeringplans OR coo)) OR (topic:permits AND event_tags:(nb a1 dm))"
      : "(topic:(stories OR offeringplans OR coo OR transactions)) OR (topic:permits AND event_tags:(nb a1 dm))",
    articles: "topic:stories",
    construction:
      "topic:(offeringplans OR coo) OR (topic:permits AND event_tags:(nb a1 dm))",
    listings: !this.props.buildingRes
      ? "topic:listings AND event_tags:just-listed AND -event_tags:rent"
      : "topic:listings AND event_tags:just-listed",
    contracts:
      "topic:listings AND event_tags:contract-signed AND -event_tags:rent",
    sales: "topic:transactions",
  };

  componentDidMount() {
    this._getNews();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.start !== prevState.start ||
      this.state.activeTab !== prevState.activeTab ||
      this.state.changed !== prevState.changed ||
      this.state.showHidden !== prevState.showHidden
    ) {
      this._getNews();
    }
  }

  _loadMore = () => {
    if (this.state.fetchInProgress === true) {
      return false;
    }
    this.setState({ start: this.state.start + this.state.rowsPerPage });
  };

  _setActiveTab(tab) {
    const { buildingRes } = this.props;
    const newURL = buildingRes
      ? _getBuildingURL(buildingRes, "_id") + (tab ? `/tab/news/${tab}` : "")
      : `/news/${tab}`;
    //props.history.replace(buildingURL);
    window.history && window.history.pushState({}, null, newURL);
    Publisher.publish(`track.NewsView.${tab}`, {});
    this.setState({ activeTab: tab, data: [] });
  }

  _combineFeed = (data) => {
    const { buildingRes } = this.props;
    const feed = data && Array.isArray(data) ? data.slice() : [];

    if (
      buildingRes["project_updates"] &&
      Array.isArray(buildingRes["project_updates"])
    ) {
      buildingRes["project_updates"].map((update) => {
        let found = false;
        feed.map((item) => {
          if (update.id == item.id) {
            item = Object.assign(item, update);
            found = true;
          }
        });

        if (!found) {
          feed.push(update);
        }
      });
    }
    //feed = feed.filter(item => !item.hide);
    feed.sort((a, b) => (a.time > b.time ? -1 : 1));
    return feed;
  };

  _getNews = async () => {
    if (this.state.fetchInProgress === true) {
      return false;
    }
    this.setState({ fetchInProgress: true });
    const { buildingRes, user } = this.props;
    const { activeTab } = this.state;

    const fq = [
      this.fqMap[activeTab],
      "hierarchy:[0 TO 2]",
      `time:[* TO NOW]`,
      "-siblings:*",
      'address_2_normalized:""',
    ];

    let q = "*:*";
    if (buildingRes) {
      const addressQ =
        buildingRes.address && buildingRes.address.alternate_addresses
          ? buildingRes.address.alternate_addresses
              .map((a) => a.toLowerCase())
              .join('" OR "')
          : false;

      if (buildingRes.address && buildingRes.address.borough) {
        q = `(full_street_address:("${addressQ}") AND (zip:${
          buildingRes.address.zip
        } OR borough:"${buildingRes.address.borough.toLowerCase()}"))`;
      }
      if (buildingRes.building_slugs && buildingRes.building_slugs.length > 0) {
        q += ` OR building_slugs:(${buildingRes.building_slugs.join(" ")})`;
      }
      //fq[0] = '(topic:(stories OR offeringplans OR coo OR listings OR transactions)) OR (topic:permits AND event_tags:nb)';
    } else {
      fq.push(
        `{!join from=key fromIndex=we3-buildings to=building}(${getCohortQuery(
          user
        )})`
      );
    }

    const axiosData = {
      url: apiURL + "we3-activities-3",
      method: "post",
      query: {
        q,
        fq,
        sort: activeTab === "articles" ? "time desc" : "time desc",
        wt: "json",
        rows: this.state.rowsPerPage,
        start: this.state.start,
      },
    };

    const formattedData = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length > 0) {
          this.setState({
            data: this._combineFeed(res.docs),
            fetchInProgress: false,
          });
        } else if (res.docs.length === 0) {
          this.setState({
            data: this._combineFeed([]),
            noMoreResults: true,
            fetchInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    return formattedData;
  };

  setChanged() {
    const { changed } = this.state;

    this.setState({ changed: !changed });
  }

  snackOpen = (message, type) => {
    type = type ? type : "success";
    this.setState({ snackShow: true, snackMessage: message, snackType: type });
  };

  snackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackShow: false, snackMessage: "" });
  };

  render() {
    const {
      data,
      fetchInProgress,
      activeTab,
      showHidden,
      snackShow,
      snackMessage,
      snackType,
    } = this.state;
    const { buildingRes, setBuilding, min, toggle, user, title } = this.props;
    if (data === null) {
      return null;
    }

    let feed = !showHidden ? data.filter((item) => !item["hide"]) : data;

    if (min) {
      feed = feed.slice(0, 5);
    }

    if (data.length === 0) {
      return (
        <div
          className={`project-feed ${isMobile ? "is-mobile" : "is-desktop"}`}
        >
          <Row>
            <Col xl={12} lg={12} className="mb-2">
              {_hasPermission(user, "admin-tools") && (
                <div className="edit-tools">
                  <EditFeed
                    add
                    buildingRes={buildingRes}
                    setChanged={this.setChanged.bind(this)}
                    setBuilding={setBuilding}
                    snackOpen={this.snackOpen.bind(this)}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div className={`project-feed ${isMobile ? "is-mobile" : "is-desktop"}`}>
        <SnackBar
          snackShow={snackShow}
          snackMessage={snackMessage}
          snackClose={this.snackClose}
          snackDuration={5000}
          snackType={snackType}
        />

        <Row>
          <Col xl={12} lg={12} className="mb-2">
            {_hasPermission(user, "admin-tools") && (
              <div className="edit-tools">
                <Tooltip title={`Show hidden news items (Admin Only)`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showHidden}
                        onChange={() => {
                          this.setState({ showHidden: !showHidden });
                        }}
                        name="showHidden"
                      />
                    }
                    label="Show Hidden"
                  />
                </Tooltip>
                <EditFeed
                  add
                  buildingRes={buildingRes}
                  setChanged={this.setChanged.bind(this)}
                  setBuilding={setBuilding}
                  snackOpen={this.snackOpen.bind(this)}
                />
              </div>
            )}
            <h3 className="section-title section-title--not-last project_page-heading">
              {title ? title : "Project Timeline"}
            </h3>
            <h3 className="section-title section-title--not-last subhead">
              {feed && feed[0] && (
                <span>
                  Lastest News: {moment(feed[0].time).format("MM/DD/YYYY")}{" "}
                </span>
              )}
            </h3>
            {/* <hr /> */}
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12}>
            <Timeline align="" className="pl-0 pr-0">
              {feed.map((item, index) => {
                try {
                  const data = JSON.parse(item.data);
                  item.data = data;
                } catch (e) {
                  //ToDo
                }

                return (
                  <NewsItem
                    item={item}
                    index={index}
                    buildingRes={buildingRes}
                    setChanged={this.setChanged.bind(this)}
                    setBuilding={setBuilding}
                    snackOpen={this.snackOpen.bind(this)}
                  />
                );
              })}
            </Timeline>
          </Col>
        </Row>
        {min && (
          <Row>
            <Col xl={12} lg={12} className="mt-3">
              <div className="timeline_load">
                <Tooltip title="See complete timeline">
                  <ReactButton
                    onClick={() => toggle("timeline")}
                    variant="contained"
                    color="#780F9E"
                    style={{ color: "#780F9E" }}
                  >
                    See Complete Timeline
                  </ReactButton>
                </Tooltip>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );

    return (
      <Fragment>
        {fetchInProgress && data.length <= 0 ? (
          <Row>
            <LinearLoading />
          </Row>
        ) : !fetchInProgress && data.length <= 0 ? (
          <div className={`email-confirmation`}>
            <div className="email-confirmation__icon">
              <AlertIcon size={50} color={`#D6BAE8`} />
            </div>
            <h3 className="email-confirmation__title">No News Yet</h3>
            <p className="email-confirmation__sub">
              Marketproof Pipeline is connecting buildings with news daily,
              check back soon.
            </p>
          </div>
        ) : (
          <Fragment>
            <div className="">
              {/*<span className="timeline__start"/>*/}
              {data
                .filter((item) => item["hide"])
                .map((item) => {
                  console.log(item["hide"]);
                  try {
                    const data = JSON.parse(item.data);
                    item.data = data;
                  } catch (e) {
                    //ToDo
                  }

                  return <NewsItem item={item} buildingRes={buildingRes} />;
                })}
            </div>
            <div className="timeline_load">
              <Tooltip title="Load more news">
                <Button
                  onClick={(e) => {
                    this._loadMore(e);
                  }}
                  variant="contained"
                  color="news"
                  className={`timeline__load-more`}
                  startIcon={<LoadMoreIcon />}
                  disabled={
                    this.state.fetchInProgress || this.state.noMoreResults
                  }
                >
                  {" "}
                  {`${
                    this.state.fetchInProgress
                      ? "Loading..."
                      : this.state.noMoreResults
                      ? "That's All The News"
                      : "Load More"
                  }`}
                </Button>
              </Tooltip>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withRouter(ProjectFeed);
