/* eslint-disable react/no-children-prop */
import React, { Fragment } from 'react';
import {
  Card, CardBody, Row, Col, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux';


import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import renderDropZoneField from './DropZone';

import { ThemeProvider } from '@material-ui/core/styles';
import{
	matTheme,
} from '../../../shared/helpers/utils';


const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
  


const renderTextField = ({
  input, label, meta: { touched, error, warning }, children, select, disabled
}) => (
  <Fragment>
  <ThemeProvider theme={matTheme}>

	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    variant="outlined"
	    color="#9600BF"
	    disabled={disabled}
	  />
	  {touched && ((error && <div className="field-error">{error}</div>) || (warning && <div className="field-error warn">{warning}</div>))}
	</ThemeProvider>
  </Fragment>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const ProfileForm = ( props ) => {
	const { handleSubmit, reset, user } = props;
	//console.log(user);
	return(
	  <Row>
	  <Col md={12} lg={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'Update Profile'}</h5>
	          <div className="page-subhead subhead"></div>
	          <hr/>
	        </div>
	        <form className="material-form" onSubmit={handleSubmit}>
	        <Row>
	        	<Col md={12} lg={12}>
	        	<div className="profile-edit__topbar">
	        	  <Field
		            name="avatar"
		            component={renderDropZoneField}
		          />
		          <div className="topbar__avatar-name">
		          	<h3>{user.firstName} {user.lastName}</h3>
		          	<p className="title">{user.title}</p>
		          	<p className="title">{user.email}</p>
		          </div>
		          </div>
		        </Col>
	        </Row>
	        
	        <Row>
	        	<Col md={12} lg={12} className={"mb-3"}>
		        	<hr/>
		        	<div>Profile Information</div>
		        	
		        </Col>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">First Name<sup className="required">*</sup></span>
		            <Field
		              name="firstName"
		              component={renderTextField}
		              placeholder="First Name"
		              validate={[ required ]}
		            />
		          </div>
		        </Col>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Last Name<sup className="required">*</sup></span>
		            <Field
		              name="lastName"
		              component={renderTextField}
		              placeholder="Last Name"
		              validate={[ required ]}
		            />
		          </div>
		        </Col>
{/*
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Email (Read-only)</span>
		            <Field
		              name="email"
		              component={renderTextField}
		              placeholder="example@mail.com"
		              type="email"
		              validate={email}
		              disabled
		            />
		          </div>
		        </Col>
*/}
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Phone</span>
		            <Field
		              name="primaryPhone"
		              component={renderTextField}
		              placeholder="917-555-1234"
		              type="phone"
		            />
		          </div>
		        </Col>
		         <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Company Name</span>
		            <Field
		              name="company"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		            />
		          </div>
		        </Col>
		         <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Title</span>
		            <Field
		              name="title"
		              component={renderTextField}
		              placeholder="Real Estate Agentt"
		            />
		          </div>
		        </Col>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">NYS Real Estate License #</span>
		            <Field
		              name="agentLicenseNumber"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		            />
		          </div>
		        </Col>
		        <Col md={12} lg={12} className={"mb-3"}>
		        	<hr/>
		        	<div>Company or Contact Address</div>
		        	
		        </Col>
		        <Col md={12} lg={12}>
		          <div>
		            <span className="material-form__label">Address (Ex. 123 Fifth Ave, Suite 111)</span>
		            <Field
		              name="streetAddress"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		            />
		          </div>
		        </Col>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">City</span>
		            <Field
		              name="city"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		            />
		          </div>
		        </Col>
		        <Col md={2} lg={2}>
		          <div>
		            <span className="material-form__label">State (Ex. NY)</span>
		            <Field
		              name="state"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		            />
		          </div>
		        </Col>
		        <Col md={4} lg={4}>
		          <div>
		            <span className="material-form__label">Zip</span>
		            <Field
		              name="zipCode"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		            />
		          </div>
		        </Col>
		       
		        		        
		        
		        <Col md={12} lg={12}>  
		          <ButtonToolbar className="form__button-toolbar">		           
		             <Tooltip title="Update Profile Information">
				        <Button
					         variant="contained"
					         color="primary"
					         className="primary mr-3"		         
					         startIcon={null}
					         type="submit"
					     >	Save
					     </Button>				 
				  	</Tooltip>
		            <Tooltip title="Discard Changes">
				        <Button
					         onClick={reset}
					         variant="outlined"
					         color=""
					         className=""		         
					         startIcon={null}
					     >	Reset
					     </Button>				 
				  	</Tooltip>{' '}
		           
		          </ButtonToolbar>
		        </Col>
	          </Row>
	        </form>
	      </CardBody>
	    </Card>
	  </Col>
	</Row>
	)
};

const form = 'profile_form';

export default connect(
  (state, props) => {
  const initalValues = Object.assign({}, props.user);
  console.log(initalValues);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
})(ProfileForm));
