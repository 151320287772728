/* eslint-disable react/no-children-prop */
import React, { Fragment } from "react";
import { Card, CardBody, Col, Row, Container } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import { Tooltip } from "@material-ui/core";
import PreviewIcon from "mdi-react/EyeIcon";
import IconButton from "@material-ui/core/IconButton";
import IconShare from "mdi-react/AccountPlusOutlineIcon";
import ShareButton from "../../../shared/components/buttons/ShareButton";
import AddListIcon from "mdi-react/ViewGridPlusIcon";
import Table from "@material-ui/core/Table";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MatTableHead from "./MatTableHead";
import UnitsSearchAppBar from "./UnitsSearchAppBar";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import BuildingIcons from "../../../shared/components/BuildingIcons";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  subscriptionPlans,
  _capitalizeText,
  _formatNumber,
  _slugifyText,
  BuildingStageBadge,
  BuildingCurrentStatus,
  _replaceAbbreviations,
  _getBuildingURL,
  _formatPrice,
  BuildingPhoto,
  UnitCurrentStatus,
  _getUnitFloorPlan,
  _nameToUrl,
  _formatNameAddress,
} from "../../../shared/helpers/utils";
import { _shareThemes } from "../../../shared/helpers/access";
import ReportButton from "../../../shared/components/buttons/ReportButton";
import CollectionReportButton from "../../SharePage/CollectionReportButton";

const styles = (theme) => ({
  root: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    //marginLeft:"-8px",
    marginBottom: "15px",
    alignItems: "left",
    "& > *": {},
  },
  button: {
    "&.selected": {
      background: "#D1D6E0",
    },
    fontSize: 13,
    width: 120,
  },
});
const generateShareLink = (report) => {
  let shareBaseURL =
    window.location.protocol + "//" + window.location.host + "/share/";

  if (report && report.custom_link) {
    return shareBaseURL + report.custom_link;
  }

  return shareBaseURL;
};

const GetGroupImage = ({ group, unitRes, buildingRes }) => {
  let bldg = false;
  let buil = false;
  if (
    buildingRes &&
    group.group_data &&
    group.group_data.projects &&
    group.group_data.projects[0]
  ) {
    bldg = buildingRes.filter((b) => b.key == group.group_data.projects[0]);
  } else if (
    unitRes &&
    group.group_data &&
    group.group_data.buildings &&
    group.group_data.buildings[0]
  ) {
    buil = unitRes.filter((u) => u.key == group.group_data.buildings[0]);
  }
  //console.log(bldg, unit)
  if (bldg && bldg[0]) {
    return <BuildingPhoto item={bldg[0]} />;
  } else if (buil && buil[0] && _getUnitFloorPlan(buil[0])) {
    return (
      <div
        class="todo__lead-image fp"
        style={{ backgroundImage: `url(${_getUnitFloorPlan(buil[0])[0].src})` }}
      />
    );
  } else {
    return <AddListIcon />;
  }
};
const ShowReports = ({
  data,
  numFound,
  orderBy,
  order,
  rowsPerPage,
  page,
  paginationCount,
  toggleRefresh,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  unitRes,
  buildingRes,
  hidePagination
}) => {
  return (
    <Fragment>
      <div className="material-table__wrap">
        {data && (
          <Table className="material-table trend-buildings">
            <MatTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={null}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />

            <TableBody>
              {data &&
                Array.isArray(data) &&
                data.map((report, i) => {
                  const item = report.project
                    ? report.project
                    : report.building
                    ? report.building
                    : report.custom_data && report.custom_data.group
                    ? report.custom_data.group
                    : false;
                  if (!item) return null;
                  const shareLink = generateShareLink(report);
                  const { buildingNameOrAdr, adr, _boro, _hood } =
                    _formatNameAddress(item);
                  let unitURL;
                  if (item && item._id) {
                    unitURL = _getBuildingURL(item, "_id");
                  } else {
                    unitURL = _getBuildingURL(item, "id");
                  }
                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                        //window.open(shareLink);
                      }}
                      aria-checked={false}
                      tabIndex={-1}
                      key={report.link_id}
                      selected={false}
                    >
                      <TableCell className="material-table__cell" padding="">
                        <div className="report-subject">
                          {report.project || report.building ? (
                            <Fragment>
                              <div>
                                <Link to={unitURL}>
                                  <BuildingPhoto item={item} />
                                </Link>
                              </div>
                              <div className="subject">
                                <div>
                                  <Link to={unitURL}>{buildingNameOrAdr}</Link>
                                  <br />
                                  <div
                                    className="light-text small-text mb-2"
                                    style={{ color: "#858AA1" }}
                                  >
                                    {_hood ? `${_capitalizeText(_hood)}, ` : ""}
                                    {_capitalizeText(_boro)}
                                  </div>
                                  <div style={{ marginLeft: -5 }}>
                                    <BuildingIcons building={item} />
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div className="c-image">
                                <GetGroupImage
                                  group={report.custom_data.group}
                                  unitRes={unitRes}
                                  buildingRes={buildingRes}
                                />
                                <span className="c-icon">
                                  <AddListIcon size="12" />
                                </span>
                              </div>
                              <div className="subject">
                                {report.custom_data &&
                                report.custom_data.report_title
                                  ? _capitalizeText(
                                      report.custom_data.report_title
                                        .toString()
                                        .replace(/\-/g, " ")
                                    )
                                  : "Untitled"}
                                <br />
                                <span className="light-text small-text">
                                  Collection:{" "}
                                  {report.custom_data &&
                                  report.custom_data.group
                                    ? _capitalizeText(
                                        report.custom_data.group.group_name
                                      )
                                    : "--"}
                                </span>

                                <div>
                                  <span className="small-text light-text">
                                    Pipeline:{" "}
                                    {report &&
                                      report.custom_data &&
                                      report.custom_data.group &&
                                      report.custom_data.group.group_data &&
                                      report.custom_data.group.group_data
                                        .projects &&
                                      report.custom_data.group.group_data
                                        .projects.length}{" "}
                                  </span>
                                  <span className="small-text light-text">
                                    {" | "}
                                  </span>
                                  <span className="small-text light-text">
                                    Existing:{" "}
                                    {report &&
                                      report.custom_data &&
                                      report.custom_data.group &&
                                      report.custom_data.group.group_data &&
                                      report.custom_data.group.group_data
                                        .buildings &&
                                      report.custom_data.group.group_data.buildings
                                        .length}{" "}
                                  </span>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </TableCell>

                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        size="small"
                      >
                        <span className="small-text">
                          <IconShare
                            color={"#BFC4D1"}
                            size={20}
                            style={{ marginRight: 5, marginTop: -3 }}
                          />{" "}
                          {report.property
                            ? "Single Unit"
                            : report.building
                            ? "Single Building"
                            : _capitalizeText(
                                report.report_type
                                  .toString()
                                  .replace(/\-/g, " ")
                              ) + " Report"}
                        </span>
                        <br />
                        <span
                          className="light-text small-text"
                          style={{ color: "#858AA1" }}
                        >
                          Link:{" "}
                          <a
                            href={shareLink}
                            className="link-text"
                            target="_blank"
                          >
                            {report.custom_link}
                          </a>
                        </span>
                        <br />
                        {report.custom_data && report.custom_data.prepared_for && (
                          <span
                            className="light-text small-text"
                            style={{ color: "#858AA1" }}
                          >
                            Prepared For: {report.custom_data.prepared_for}
                          </span>
                        )}
                        {/*
							<br/>
							<span className="light-text small-text" style={{color:"#858AA1"}}>Theme:{' '} 
							   {report.custom_data && _shareThemes[report.custom_data.report_theme] ? _shareThemes[report.custom_data.report_theme]['display_name'] : "Marketproof"}
							</span>
							*/}
                      </TableCell>

                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        size="small"
                      >
                        <span
                          className="light-text small-text"
                          style={{ color: "#858AA1" }}
                        >
                          {moment(report.created_timestamp).format(
                            "M/D/YYYY hh:mm A"
                          )}
                        </span>
                      </TableCell>

                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        size="small"
                      >
                        <span
                          className="light-text small-text"
                          style={{ color: "#858AA1" }}
                        >
                          {moment(report.modified_timestamp).format(
                            "M/D/YYYY hh:mm A"
                          )}
                        </span>
                      </TableCell>

                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        size="small"
                      >
                        {!report.report_type ||
                        [
                          "single-project",
                          "existing-building",
                          "grid",
                          "comp",
                        ].indexOf(report.report_type) >= 0 ? (
                          // <CollectionReportButton group={item} />
                          <ShareButton
                            buildingRes={item}
                            linkID={report.link_id}
                            property={report.property ? report.property : false}
                            report={report}
                            toggleRefresh={toggleRefresh}
                            shareLink={shareLink}
                          />
                        ) : (
                          <ReportButton
                            buildingRes={item}
                            group={report.custom_data.group}
                            linkID={report.link_id}
                            report={report}
                            toggleRefresh={toggleRefresh}
                            shareLink={shareLink}
                          />
                        )}

                        <Tooltip title="View This Shared Page">
                          <IconButton
                            className="material-table__toolbar-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(shareLink);
                            }}
                          >
                            <PreviewIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </div>
      {numFound   ? (
        <TablePagination
          component="div"
          className="material-table__pagination"
          count={numFound}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          dir="ltr"
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
        />
      ) : null}
    </Fragment>
  );
};

const List = (props) => {
  const {
    user,
    reports,
    toggleRefresh,
    handleRequestSort,
    filters,
    subTab,
    classes,
    viewType,
    setSubFilter,
    subFilters,
  } = props;
  //console.log(user);
  const { subscriptionPlan } = user;
  const currentPlan = subscriptionPlans.filter(
    (plan) => plan.id === subscriptionPlan
  )[0];
  return (
    <Col md={12} lg={12}>
      {!subTab ? (
        <Card>
          <CardBody className="unit_search">
            <UnitsSearchAppBar
              onRequestSort={handleRequestSort}
              _filters={filters}
              {...props}
              numToShow={7}
            />
            {!isMobile && filters.length > 0 && (
              <Row>
                <Col md={12}>
                  <div className={classes.root}>
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      {filters
                        .filter((f) => f.search_key == "share_type")[0]
                        .values.map((val) => {
                          return (
                            <Tooltip title={`See events grouped by buildings`}>
                              <Button
                                onClick={() => {
                                  setSubFilter("share_type", val.filter);
                                }}
                                className={
                                  subFilters["share_type"] === val.filter ||
                                  (!subFilters["share_type"] &&
                                    val.filter == "")
                                    ? classNames(classes.button, "selected")
                                    : classNames(classes.button)
                                }
                              >
                                {val.dropdown_name}
                              </Button>
                            </Tooltip>
                          );
                        })}
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            )}
            {!reports || reports.length === 0 ? (
              <Container>
                <Row className="no__data">
                  <Col lg={12} xs={12} className="no__data-text">
                    Nothing here yet.
                  </Col>
                </Row>
              </Container>
            ) : (
              <ShowReports data={reports} {...props} />
            )}
          </CardBody>
        </Card>
      ) : (
        <Fragment>
          {!isMobile && filters.length > 0 && (
            <Row>
              <Col md={12}>
                <div className={classes.root}>
                  <ButtonGroup
                    size="small"
                    aria-label="small outlined button group"
                  >
                    {filters
                      .filter((f) => f.search_key == "share_type")[0]
                      .values.map((val) => {
                        return (
                          <Tooltip title={`See events grouped by buildings`}>
                            <Button
                              onClick={() => {
                                setSubFilter("share_type", val.filter);
                              }}
                              className={
                                subFilters["share_type"] === val.filter ||
                                (!subFilters["share_type"] && val.filter == "")
                                  ? classNames(classes.button, "selected")
                                  : classNames(classes.button)
                              }
                            >
                              {val.dropdown_name}
                            </Button>
                          </Tooltip>
                        );
                      })}
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
          )}
          {!reports || reports.length === 0 ? (
            <Container>
              <Row className="no__data">
                <Col lg={12} xs={12} className="no__data-text">
                  Nothing here yet.
                </Col>
              </Row>
            </Container>
          ) : (
            <ShowReports data={reports} {...props} />
          )}
        </Fragment>
      )}
    </Col>
  );
};

export default withStyles(styles, { withTheme: true })(List);
