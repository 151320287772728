import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import { withOktaAuth } from '@okta/okta-react';
import WithTracker from './../../../../containers/App/WithTracker';
import OpenInNew from 'mdi-react/OpenInNewIcon';
import { connect } from 'react-redux';
import queryString from "query-string";

import CheckIcon from 'mdi-react/CheckCircleOutlineIcon';
import CircleIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import ChangeIcon from 'mdi-react/RotateLeftIcon';

import { setPlan } from '../../../../redux/actions/planActions';
import { subscriptionPlans, appFeatures } from './../../payment/plans.js';

import TopBar from './../../TopBar'
import Footer from './../../Footer'


import{
	_capitalizeText,
	_formatNumber
} from '../../../helpers/utils';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Collapse
} from 'reactstrap';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      authenticated: null
    };
    
  }
  
  componentDidMount() {
	   const { dispatch, match,  history, location } = this.props;	  

	   let urlState = { ...queryString.parse(location.search) }	   
	   let plan = urlState && urlState.plan && subscriptionPlans.filter(plan => plan.id === urlState.plan).length > 0 ? urlState.plan : false;
	   if(!plan && localStorage && localStorage.getItem("mpnd-plan")){
			let plan_id = localStorage.getItem("mpnd-plan");
			plan = subscriptionPlans.filter(subPlan => subPlan.id === plan_id).length>0 ? plan_id: false;
	   }
	   dispatch(setPlan(plan));
  }

 
  onSuccess(res) {
    if (res.status === 'SUCCESS') {
      return this.props.authService.redirect({
        sessionToken: res.session.token
      });
   } else {
    
    }
  }

  onError(err) {
    console.log('error logging in', err);
  }

  render() {
	const { location, plan } = this.props;
	
		
	const resetToken = this.props.match.params.reset_token ? this.props.match.params.reset_token : false;
	
	const currentPlan = plan && plan.id ? subscriptionPlans.filter(subPlan => subPlan.id === plan.id)[0] : false;
	
    if (this.props.authState.isPending) return null;
    if(this.props.authState.isAuthenticated) {	    
	    return <Redirect to={{ pathname: '/' }}/>  	    
	}else if(!currentPlan){		
		return <Redirect to={{ pathname: '/pricing' }}/> 
	}else {
		return (
			<div className="plan__wrapper share__wrapper">
				<TopBar goTo={'plans'} buttonText={'Change Plan'} />
					<Row className="login-container signup">
			    		<Col lg={6} className="pt-0 signin">
			    		<div className="desktop-hide text-center ">
			    			<a href="https://marketproof.com/new-development" target="_blank"><h3 className="mt-3">{`Start your 7-day`} {`free trial`}</h3></a>
							
			    			<h3 style={{marginBottom:5}}><span style={{color:'#9600BF', fontWeight:800}}>
			    				${currentPlan.pricePerMonth % 1 != 0 ? currentPlan.pricePerMonth : _formatNumber(currentPlan.pricePerMonth)} / month</span>	   
			     			</h3>
			     			<h6 style={{marginBottom:20}}><span style={{fontSize:15,fontWeight:600,color:'#363347',textTransform:'uppercase'}}>{currentPlan.name} PLAN</span></h6>
							<div style={{marginTop:5}}>
				    				<Link to={`/pricing`} style={{color:'#9600BF', textAlign:'right'}}><ChangeIcon size={16} style={{marginTop:-2}} /> Change Plan </Link>
				    		</div>
			    		</div>
			    		<div className={"widget-container"}>
					      <OktaSignInWidget
					        baseUrl={this.props.baseUrl}
					        onSuccess={this.onSuccess}
					        onError={this.onError}
					        resetToken={resetToken}
					        {...this.props}
					    />
						<div className={'signin-toggle'}>{`Already have an account?`} <Link to={`/`}>Sign In</Link></div>      
						<p className="disclaimer">By creating an account, you agree to our{` `}<a href="https://marketproof.com/terms-of-use" target="_blank">Terms of Service</a>{` `}and 
					    {` `}<a href="https://marketproof.com/privacy-policy" target="_blank">Privacy Policy</a>.</p> 
						</div>
					      
					        
					    </Col>
			    		<Col lg={6} className="signin-hero-container">
			    			<div className="signin-hero">
				    			<a href="https://marketproof.com/new-development" target="_blank"><h1>{`Start your 7-day`}<br/>{`free trial`}</h1></a>
				    			
				    			<h3 style={{marginBottom:0}}><span style={{color:'#9600BF', fontWeight:800}}>
				    				${currentPlan.pricePerMonth % 1 != 0 ? currentPlan.pricePerMonth : _formatNumber(currentPlan.pricePerMonth)} / month</span>
				    			</h3>
				    			<h6 style={{marginBottom:20}}><span style={{fontSize:15,fontWeight:600,color:'#363347',textTransform:'uppercase'}}>{currentPlan.name} PLAN</span></h6>
				    			<hr/>
				    			<div className="plan-features">
				    				{appFeatures.map(feature => {
									     if(feature.plans.indexOf(plan.id)>=0){
										     return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {feature.name}</p>
									     }else{
										     return <p className="pricing-card__feature pricing-card__feature--inactive"><CircleIcon size={16} style={{marginTop:-3}}  /> {feature.name}</p>
									     }
									    
								      })}
				    			
				    			</div>
				    			<hr/>
				    			<Link to={`/pricing`} style={{color:'#9600BF', textAlign:'right'}}><ChangeIcon size={16} style={{marginTop:-2}} /> Change Plan </Link>						    			
				    		</div>
				    		
					    </Col>
					</Row>
				<Footer />
			</div>

		)
		
	}
      

    	
  }
};


export default connect(state => ({
  plan: state.plan
}))(withRouter(WithTracker(withOktaAuth(SignUp))))