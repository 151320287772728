import React from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import PersonPageCard from "../../../SingleContact/components/PersonPageCard";
import ProductCard from "./components/ProfileCard";

const Profile = (props) => (
  <div>
    <Row>
      {props.personPage ? (
        <PersonPageCard {...props} />
      ) : (
        <ProductCard {...props} />
      )}
    </Row>
  </div>
);

export default Profile;
