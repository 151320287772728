/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/first: off */
import React, { Component, Fragment } from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
import L from "leaflet";
import { polygon, polygons, multiPolygon } from "@turf/helpers";
import center from "@turf/center";
import area from "@turf/area";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import PitchToggle from "./PitchToggle";
import LegendToggle from "./LegendToggle";
import LayerToggle from "./LayerToggle";

// import { Publisher } from "./../../pubsub/publisher.js";
import * as MapUtil from "./../../../shared/helpers/mapUtil";

import { Modal } from "reactstrap";
import BuildingDetail from "../../BuildingDetail";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  ConstructionProgress,
  BuildingStatus,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  mpndCohortQuery,
  syncGenericFilter,
  _objectEqual,
  buildingExportFL,
  getCohortQuery,
  StatusBadge,
  GenerateBuildingSlug,
  GenerateBuildingKeyMap,
  _tranformTagsMongoQuery,
  _urlToName,
  _generateRandomAPI,
  _getBuildingPhotos,
  _getStreetPhoto,
  _replaceAbbreviations,
  _formatQueensAddress,
  BuildingStageBadge,
  ShowBadge,
} from "./../../../shared/helpers/utils";

import {
  _pipelineMapFilters,
  _buildingStageFilter,
} from "../../../shared/helpers/filters";
import {
  _getGeocoding,
  _getNeighborhoods,
  _axiosCall,
} from "./../../../shared/helpers/apicalls";
import {
  boroughTileset,
  building_tileset,
  majorMarketTileset,
  neighborhoodTileset,
  _addZoningLayer,
  _addCommercialOverlayLayer,
  _addZoningAmendmentsLayer,
  _addRezoning2020,
  _addRezoning2015,
  _addRezoning2010,
  _addRezoningBefore2010,
  _addPendingRezoning,
  _addHistoricDistrinctLayer,
  _addFloodZoneLayer,
  _addSubwayLayer
} from "../../../shared/helpers/commonMapFilters";
import {
  buildingHighlightStyle,
  buildingStyle,
  containerStyle,
  defaultOccupancyTypes,
  LocationsPadding,
  pipelineStyle,
  Tooltip,
  TooltipBuilding,
  TooltipMPND,
} from "../../../shared/components/map/CommonMapComponent";
// import "./../static/styles/_mapboxgl.css";

const apiURL = process.env.API_ROOT + "/query/";

const layers = [
  {header: "Zoning"},
  {name: "Zoning District", id: "nyzd", fun: _addZoningLayer, tooltip: "Areas regulated for land use - Residential (R), Commercial (C) and Manufacturing (M)"},
  {name: "Commercial Overlay", id: "nyco", fun: _addCommercialOverlayLayer, tooltip: "Area located in a residential zoning district that allows for commercial use"},
  {name: "Historic Districts", id: "hisdist", fun: _addHistoricDistrinctLayer, tooltip: "Landmark Preservation Commission designed historic areas"},
  {header: "Rezoning"},
  {name: "Rezoning (Pending)", id: "nyzmapending", fun: _addPendingRezoning, tooltip: "Pending zoning amendments"},
  {name: "Rezoning (After 2020)", id: "nyzma2020", fun: _addRezoning2020, tooltip: "Zoning amendments adopted after year 2020"},
  {name: "Rezoning (2015 - 2020)", id: "nyzma2015", fun: _addRezoning2015, tooltip: "Zoning amendments adopted from 2015 to 2020"},
  {name: "Rezoning (2010 - 2015)", id: "nyzma2010", fun: _addRezoning2010, tooltip: "Zoning amendments adopted from 2010 to 2015"},
  {name: "Rezoning (Before 2010)", id: "nyzmabefore2010", fun: _addRezoningBefore2010, tooltip: "Zoning amendments adopted before year 2010"},
  {header: "Transportation"},
  {name: "Flood Zones", id: "flood", fun: _addFloodZoneLayer, tooltip: "100-Year Floodplain for the 2020s based on FEMA's Preliminary Work Map data and the New York Panel on Climate Change's 90th Percentile Projects for Sea-Level Rise"},
  {name: "Subways", id: "subway", fun: _addSubwayLayer, tooltip: "MTA subway lines and stations"},
]


const BOROUGHS = {
  Brooklyn: {
    center: [-73.949997, 40.650002],
  },
  Bronx: {
    center: [-73.865433, 40.837048],
  },
  Manhattan: {
    center: [-73.984016, 40.754932],
  },
  "Staten Island": {
    center: [-74.151535, 40.579021],
  },
  Queens: {
    center: [-73.769417, 40.742054],
  },
};

const WordWrapLocations = {
  "Prospect Park South And Flatbush": true,
  "Park Slope And Prospect Park": true,
  "Red Hook To Boerum Hill": true,
  "Williamsburg And Greenpoint": true,
  "Downtown West Manhattan": true,
  "Downtown East Manhattan": true,
  "Flushing Meadows Corona Park": true,
  "Prospect Lefferts Gardens": true,
};

const pipeline_tileset = [
  {
    id: "mp-pipeline-buildings",
    layer_id: "mp-pipeline-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project?version=1.1",
    source_layer: "pipeline",
  },
  {
    id: "mp-sponsor-buildings",
    layer_id: "mp-sponsor-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project?version=1.1",
    source_layer: "mpnd",
    default_filter: ["==", "building_status", "sponsor"],
  },
];

const pipeline_lot_tileset = [
  {
    id: "mp-pipeline-lots",
    layer_id: "mp-pipeline-lot-layer",
    url: "mapbox://ningzhou.pipeline-project?version=1.1",
    source_layer: "lot",
    default_filter: [
      "any",
      ["==", "source_provider", "permits"],
      [
        "all",
        ["==", "source_provider", "mpnd"],
        ["==", "building_status", "sponsor"],
      ],
    ],
  }
];

const neighborhood_tileset = {
  id: "mp-neighborhoods-2",
  url: "mapbox://ningzhou.cjyhuu6sg05a52onyjsix2wm3-2t5i9",
  source_layer: "nyc-neighborhoods-v3",
};

mapboxgl.accessToken =
  "pk.eyJ1IjoibmluZ3pob3UiLCJhIjoiY2lwaXBiaWtyMDFxZXVnbmpkaWR4dXd0MSJ9.GJX2c6TviB6opu84mCEOIg";

const defaultMap = {
  lng: -73.9949712,
  lat: 40.7379366,
  zoom: 16,
};

const getSubFilters = ({ stage, match }) => {
  const type = match.params.type ? match.params.type : "residential";

  let filters = _pipelineMapFilters.filter(
    (filter) =>
      !(filter.exclude_stage && filter.exclude_stage.indexOf(stage) >= 0)
  );

  if (type === "commercial") {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "residential")
    );
  } else {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "commercial")
    );
  }
  return filters;
};

const subFilterKey = "subFilters";
const buildingStagesKey = "buildingStages";
const defaultSubFilter = {};
const defaultStage = ["all"];
const url = process.env.API_ROOT + "/query/";
class MatMapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fetchInProgress: false,
      lng: -73.9949712,
      lat: 40.7379366,
      zoom: 14,
      mapLoaded: false,
      hoveredStateId: null,
      buildingsSelected: [],
      viewType: "map",
      facetData: null,
      buildingStages: syncStageFilters(
        this.props,
        _buildingStageFilter,
        buildingStagesKey,
        this.lsPre,
        defaultStage
      ),
      styleLoaded: false,
      tilesAndSourcesLoaded: false,
      mapUnsupported: false,
      mapRendered: false,
      polyHoods: [],
      occupancyTypes: defaultOccupancyTypes,
      modal: false,
      building: false,
      prevUrl: "",
      showLayers : []
    };

    this.currentlyHoveredLocation = null;
    this.currentMarkerKey = null;
    this.customMarker = null;
    this.geoLocate = null;
    this.hoveredStateId = null;
    this.justClickedMarker = false;
    this.justClickedSelectedMarker = false;
    this.justFetched = false;
    this.justResized = false;
    this.justSetMapBounds = false;
    this.justToggledPopup = false;
    this.map = null;
    this.markerCount = 0;
    this.mobilePopupContainer = null;
    this.mobilePopupContainerKey = null;
    this.oneClickMarker = null;
    this.popupTimeouts = {};
    this.resizeTimeout = false;
    this.selectedMarker = null;
    this.callQueue = [];

    this._buildingData =
      this.props.buildingData && this.props.buildingTags.length === 1
        ? this.props.buildingData
        : this.props.axios &&
          this.props.axios.buildingAxios &&
          this.props.axios.buildingAxios[0]
        ? this.props.axios.buildingAxios[0]
        : false;

    this._hoods = [];
    this._loadLayers = this._loadLayers.bind(this);
    this._handlePage = this._handlePage.bind(this);
    this._setNeighborhood = this._setNeighborhood.bind(this);
    this._setRenderStates = this._setRenderStates.bind(this);
    this._setBuilding = this._setBuilding.bind(this);
    this._resetMap = this._resetMap.bind(this);
    this._filterMap = this._filterMap.bind(this);
    this._openBuilding = this._openBuilding.bind(this);
    this._setMapBoundaries = this._setMapBoundaries.bind(this);
    this._setOccupancyFilter = this._setOccupancyFilter.bind(this);
    this._addPipelineLotTiles = this._addPipelineLotTiles.bind(this);
    this._toggleLayer = this._toggleLayer.bind(this);
    this._clearAllLayers = this._clearAllLayers.bind(this);
  }
  lsPre = `PIPE_DB_`;
  buildingSubFilters = getSubFilters(this.props);
  tooltipContainer;
  tooltip;
  map;
  geoLocate;
  hoveredStateId = null;
  hoveredLayerId = null;
  hovered3DLayerId = null;
  hoveredBuildingStateId = null;
  hoveredBuildingLayerId = null;
  popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  layersAdded = [];

  hoods = [];

  _loadLayers() {}

  _handlePage() {}

  _setTooltip(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(Tooltip, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _setTooltipBuilding(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(TooltipBuilding, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _setTooltipMPND(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(TooltipMPND, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _isMPND(features) {
    let result = false;
    features.map((feature) => {
      const building_status = feature.properties.building_status
        ? feature.properties.building_status
        : false;
      const offering_verified_status = feature.properties
        .offering_verified_status
        ? feature.properties.offering_verified_status
        : false;
      if (
        building_status &&
        ["pipeline", "sponsor", "resale"].indexOf(building_status) >= 0 &&
        offering_verified_status != "" &&
        offering_verified_status != "excluded"
      ) {
        result = feature;
      }
    });
    return result;
  }

  _onMouseMove(e) {
    //this._onMouseOut();
    console.log(e.features);
    if (
      e.features.length > 0 &&
      e.features[0].properties &&
      e.features[0].properties.key
    ) {
      //var mapLayer = this.map.getLayer('mp-building-layer-hover');

      //e.features[0].state = {"hover":true};
      this.map.getCanvas().style.cursor = "pointer";
      //
      this.tooltip && e.lngLat && this.tooltip.setLngLat(e.lngLat);

      let feature = this._isMPND(e.features);

      const key =
        feature && feature.properties.key
          ? feature.properties.key
          : e.features[0].properties.key
          ? e.features[0].properties.key
          : false;

      if (key && key.indexOf("b-") >= 0) {
        if (
          this.hoveredBuildingStateId !== key &&
          this.hoveredStateId === null
        ) {
          this._setTooltipBuilding(e.features);
          //console.log(this.hoveredStateId, e.features[0].properties.master_key);
          this.hoveredBuildingStateId = key;
          this.hoveredBuildingLayerId = e.features[0].layer.id + "-hover";
          if (this.map.getZoom() > 15) {
            //console.log(this.hoveredBuildingLayerId, this.hoveredBuildingLayerId);
            this.map.setFilter(this.hoveredBuildingLayerId, [
              "==",
              "key",
              this.hoveredBuildingStateId,
            ]);
          }
        }
      } else {
        if (this.hoveredStateId !== key) {
          //console.log(this.hoveredStateId, e.features[0].properties.master_key);

          console.log("_isMPND", feature);
          if (feature) {
            this._setTooltipMPND([feature]);
            this.hoveredStateId = key;
            this.hoveredLayerId =
              feature.layer.id.replace("-3d", "") + "-hover";
            this.hovered3DLayerId =
              feature.layer.id.replace("-3d", "") + "-3d-hover";
          } else {
            this._setTooltip(e.features);
            this.hoveredStateId = key;
            this.hoveredLayerId =
              e.features[0].layer.id.replace("-3d", "") + "-hover";
            this.hovered3DLayerId =
              e.features[0].layer.id.replace("-3d", "") + "-3d-hover";
          }

          //console.log(this.hoveredLayerId, this.hoveredStateId);
          this.map.setFilter(this.hoveredLayerId, [
            "==",
            "key",
            this.hoveredStateId,
          ]);
          this.map.setFilter(this.hovered3DLayerId, [
            "==",
            "key",
            this.hoveredStateId,
          ]);
        }
      }
    } else {
      this.map.getCanvas().style.cursor = "";
    }
  }

  _onMouseOut() {
    if (this.hoveredStateId && this.hoveredLayerId) {
      this.map.setFilter(this.hoveredLayerId, ["==", "key", false]);
    }
    if (this.hoveredStateId && this.hovered3DLayerId) {
      this.map.setFilter(this.hovered3DLayerId, ["==", "key", false]);
    }
    if (this.hoveredBuildingStateId && this.hoveredBuildingLayerId) {
      this.map.setFilter(this.hoveredBuildingLayerId, ["==", "key", false]);
    }

    this.hoveredStateId = null;
    this.hoveredBuildingStateId = null;

    this.map.getCanvas().style.cursor = "";

    ReactDOM.unmountComponentAtNode(this.tooltipContainer);
  }

  _openBuilding(building) {
    this.setState({
      building: building,
      prevUrl: window ? window.location.search : "",
    });
    this.toggle();

    return null;
    let address = _replaceAbbreviations(
      building.display_full_street_address
    ).toLowerCase();
    if (address.indexOf("garage") < 0 && address.indexOf("rear") < 0) {
      window.open(
        "/building/" +
          _nameToUrl(building.borough) +
          "/" +
          _nameToUrl(building.main_neighborhood) +
          "/" +
          building.key
      );
      // window.open(
      //   "https://nyc.marketproof.com/building/" +
      //     _nameToUrl(building.borough) +
      //     "/" +
      //     _nameToUrl(building.main_neighborhood) +
      //     "/" +
      //     building.main_building_slug +
      //     "?tab=overview"
      // );
    }
  }
  _openMPND(building) {
    let slug = building.building_key.substring(4);
    console.log("_openMPND", building);
    // window.open(
    //   "/building/" +
    //     _nameToUrl(building.borough) +
    //     "/" +
    //     _nameToUrl(building.neighborhood) +
    //     "/" +
    //     building.slug
    // );
    window.open(
      "https://newdev.marketproof.com/buildings/" +
        _nameToUrl(building.borough) +
        "/" +
        _nameToUrl(building.neighborhood) +
        "/" +
        slug
    );
  }

  _setMouseClick(e) {
    const { handleBuildingClick } = this.props;
    let features = this.map.queryRenderedFeatures(e.point, {
      layers: this.layersAdded,
    });

    let feature = this._isMPND(features);
    const key =
      feature && feature.properties.key
        ? feature.properties.key
        : features[0].properties.key
        ? features[0].properties.key
        : false;
    console.log("_setMouseClick feature", feature); //topmost feature
    if (key && key.indexOf("b-") >= 0) {
      this._openBuilding(features[0].properties);
    } else {
      if (
        features.length > 0 &&
        features[0].properties &&
        features[0].properties.key
      ) {
        if (feature) {
          console.log("isMPND", feature);
          this._openMPND(feature.properties);
        } else {
          console.log("Not isMPND");
          handleBuildingClick(e, features[0].properties);
        }
      }
    }
  }

  _setOccupancyFilter(type) {
    const { occupancyTypes } = this.state;

    let newOccupancyTypes = occupancyTypes.slice();
    if (newOccupancyTypes.indexOf(type) > -1) {
      newOccupancyTypes.splice(newOccupancyTypes.indexOf(type), 1);
    } else {
      newOccupancyTypes.push(type);
    }

    this.setState({ occupancyTypes: newOccupancyTypes });
  }

  _getMapFilters() {
    var current_year = new Date().getFullYear();
    var filters = [];
    var filterBuildingSearchTags = {
      "condos-and-coops": ["in", "ownership_type", "condo", "coop", "condop"],
      condos: ["in", "ownership_type", "condo", "condo-retail"],
      coops: ["in", "ownership_type", "coop"],
      condops: ["in", "ownership_type", "condop"],
      houses: [
        "in",
        "ownership_type",
        "townhouse",
        "single-family",
        "multi-family",
        "two-family",
        "three-family",
        "four-family",
        "five-to-six-family",
      ],
      "single-family": ["in", "ownership_type", "single-family"],
      "multi-family": [
        "in",
        "ownership_type",
        "multi-family",
        "two-family",
        "three-family",
        "four-family",
        "five-to-six-family",
      ],
      rental: ["in", "ownership_type", "rental", "apartment"],
      commercial: [
        "any",
        [
          "in",
          "ownership_type",
          "commercial",
          "office",
          "retail",
          "public-facilities",
          "industrial",
          "vacant-land",
          "parking",
          "garage",
          "hotel",
        ],
        [
          "in",
          "building_category",
          "commercial",
          "office",
          "retail",
          "public-facilities",
          "industrial",
          "vacant-land",
          "parking",
          "garage",
          "hotel",
        ],
      ],
      retail: [
        "any",
        ["in", "ownership_type", "retail"],
        ["in", "building_category", "retail"],
      ],
      office: [
        "any",
        ["in", "ownership_type", "office"],
        ["in", "building_category", "office"],
      ],
      industrial: [
        "any",
        ["in", "ownership_type", "industrial"],
        ["in", "building_category", "industrial"],
      ],
      hotel: [
        "any",
        ["in", "ownership_type", "hotel"],
        ["in", "building_category", "hotel"],
      ],
      parking: [
        "any",
        ["in", "ownership_type", "parking", "garage"],
        ["in", "building_category", "parking", "garage"],
      ],
      "public-facilities": [
        "any",
        ["in", "ownership_type", "public-facilities"],
        ["in", "building_category", "public-facilities", "regligious"],
      ],
      "vacant-land": [
        "any",
        ["in", "ownership_type", "vacant-land", "vacant lot"],
        ["in", "building_category", "vacant-land", "vacant lot"],
      ],
      landmarks: null,
      "historic-districts": null,
    };

    var filterAgeSearchTags = {
      age: {
        "pre construction": [">=", "year_built", current_year],
        "new development": [
          "all",
          [">=", "year_built", current_year - 5],
          ["<", "year_built", current_year],
        ],
        "recently built": [
          "all",
          [">=", "year_built", 2000],
          ["<", "year_built", current_year - 5],
        ],
        "post war": [
          "all",
          [">=", "year_built", 1945],
          ["<", "year_built", 2000],
        ],
        "pre war": [
          "all",
          [">=", "year_built", 1600],
          ["<", "year_built", 1945],
        ],
      },
      units: {
        "units 1": ["==", "residential_units", 1],
        "units 2 3": [
          "all",
          [">=", "residential_units", 2],
          ["<=", "residential_units", 3],
        ],
        "units 4 6": [
          "all",
          [">=", "residential_units", 4],
          ["<=", "residential_units", 6],
        ],
        "units 7 10": [
          "all",
          [">=", "residential_units", 7],
          ["<=", "residential_units", 10],
        ],
        "units 11 30": [
          "all",
          [">=", "residential_units", 11],
          ["<=", "residential_units", 30],
        ],
        "units 31 50": [
          "all",
          [">=", "residential_units", 31],
          ["<=", "residential_units", 50],
        ],
        "units 51 100": [
          "all",
          [">=", "residential_units", 51],
          ["<=", "residential_units", 100],
        ],
        "units 101 200": [
          "all",
          [">=", "residential_units", 101],
          ["<=", "residential_units", 200],
        ],
        "units 201 or more": ["all", [">=", "residential_units", 201]],
      },
      floors: {
        "floors 1": ["==", "total_floors", 1],
        "floors 2": ["==", "total_floors", 2],
        "floors 3 5": [
          "all",
          [">=", "total_floors", 3],
          ["<=", "total_floors", 5],
        ],
        "floors 6 10": [
          "all",
          [">=", "total_floors", 6],
          ["<=", "total_floors", 10],
        ],
        "floors 11 20": [
          "all",
          [">=", "total_floors", 11],
          ["<=", "total_floors", 20],
        ],
        "floors 21 40": [
          "all",
          [">=", "total_floors", 21],
          ["<=", "total_floors", 40],
        ],
        "floors 41 or more": ["all", [">=", "total_floors", 41]],
      },
      features: {
        doorman: ["in", "service_level", "doorman", "doorman-full-time"],
        elevator: ["in", "building_access", "elevator"],
        gym: ["in", "amenities_health", "gym", "fitness"],
        "no fee": ["in", "amenities_other", "no-fee"],
        parking: ["in", "amenities_auto", "parking", "garage"],
        "pets allowed": ["in", "building_policies", "pets"],
        storage: ["in", "amenities_common_space", "storage"],
        "outdoor space": [
          "in",
          "amenities_outdoor_space",
          "outdoor",
          "garden",
          "roof",
        ],
        pool: ["in", "amenities_health", "pool"],
      },
    };

    if (
      this.props.buildingSearchTags &&
      filterBuildingSearchTags[this.props.buildingSearchTags[0]]
    ) {
      filters.push(filterBuildingSearchTags[this.props.buildingSearchTags[0]]);
    }

    if (
      this.props.authData &&
      this.props.authData.appState.neighborhoods &&
      this.props.authData.appState.neighborhoods.length > 0
    ) {
      let hood_keys = this.props.authData.appState.neighborhoods.map((tag) =>
        tag.name.toLowerCase()
      );
      let filter = ["in", "main_neighborhood", ...hood_keys];
      filters.push(filter);
    } /*
else{
		  let filter = ["in", "main_neighborhood", "FALSE"];
		  filters.push(filter);
	  }
*/
    console.log(this.props.buildingSearchFilterTags);
    let searchFilters = {};
    if (
      this.props.buildingSearchFilterTags &&
      this.props.buildingSearchFilterTags.length > 0
    ) {
      this.props.buildingSearchFilterTags.map((item) => {
        if (filterAgeSearchTags[item.filter]) {
          if (Array.isArray(item.val)) {
            searchFilters[item.filter] = item.val.map((item_key) => {
              return filterAgeSearchTags[item.filter][item_key];
            });
          } else {
            searchFilters[item.filter] = [
              filterAgeSearchTags[item.filter][item.val],
            ];
          }
        }
      });
    }
    console.log(searchFilters);
    for (var key in searchFilters) {
      if (searchFilters[key].length > 0) {
        filters.push(["any"].concat(searchFilters[key]));
      }
    }

    var queryFilter = false;
    if (filters && filters.length > 0) {
      if (filters.length > 1) {
        queryFilter = ["all"].concat(filters);
      } else {
        queryFilter = filters[0];
      }
    }

    if (queryFilter.length == 2 && queryFilter[0] == "any") {
      queryFilter = queryFilter[1];
    }

    console.log(queryFilter);
    if (queryFilter) {
      return {
        filter: queryFilter,
        fillOpacity: buildingHighlightStyle.fillOpacity,
        fillColor: buildingHighlightStyle.fillColor,
      };
    } else {
      return false;
    }
  }

  _setRenderStates() {
    if (!this.map.loaded()) {
      return;
    }
    if (_isEqual(this.props.hoodTags, this.hoods)) {
      return;
    }
    if (!this.props.hoodTags || this.props.hoodTags.length == 0) {
      return;
    }

    var features = this.map.querySourceFeatures(neighborhood_tileset.id, {
      sourceLayer: [neighborhood_tileset.source_layer],
      filter: [
        "in",
        "name",
        _capitalizeText(
          this.props.hoodTags[this.props.hoodTags.length - 1].hood
        ),
      ],
    });
    if (!features) {
      return;
    }
    this.hoods = this.props.hoodTags.map((tag) => _capitalizeText(tag.hood));

    var polygon = features[0].geometry.coordinates;
    var fit = new L.Polygon(polygon).getBounds();
    var southWest = new mapboxgl.LngLat(
      fit["_southWest"]["lat"],
      fit["_southWest"]["lng"]
    );
    var northEast = new mapboxgl.LngLat(
      fit["_northEast"]["lat"],
      fit["_northEast"]["lng"]
    );
    var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
    var center = boundBox.getCenter();
    this.map.fitBounds(new mapboxgl.LngLatBounds(southWest, northEast));
    this.map.off("render", "mp-neighborhood-layer", this._setRenderStates);
    this.map.off(
      "render",
      "mp-neighborhood-layer-outline",
      this._setRenderStates
    );
  }

  _setNeighborhood(prevProps = {}) {
    const { polyHoods } = this.state;
    const { searchTags } = this.props;
    //const hoodTags = this.props.hoodTags.map(tag => tag.hood.toLowerCase().trim());
    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags
            .filter((item) => item.type === "borough")
            .map((item) => item.name)
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    let hoods = polyHoods && polyHoods.length > 0 ? polyHoods : [];

    const hood_keys = hoods.map((tag) => tag.key);

    if (hoods.length && !boroTags.length) {
      const polygons = hoods.map(({ coordinates }) => [
        coordinates.slice(0, coordinates.length - 1),
      ]);

      // L.Polygon returns lat and lng values reversed
      // meaning "lat" key maps to long value / "lng" key maps to lat value
      const fit = new L.Polygon(polygons).getBounds();
      const { lat: swLng, lng: swLat } = fit.getSouthWest();
      const { lat: neLng, lng: neLat } = fit.getNorthEast();

      const boundsPoly = polygon([
        [
          [fit.getSouthWest().lat, fit.getSouthWest().lng],
          [fit.getSouthEast().lat, fit.getSouthEast().lng],
          [fit.getNorthEast().lat, fit.getNorthEast().lng],
          [fit.getNorthWest().lat, fit.getNorthWest().lng],
          [fit.getSouthWest().lat, fit.getSouthWest().lng],
        ],
      ]);
      const areaHood = area(boundsPoly);

      if (areaHood > 3000000 && areaHood < 15000000 && polygons.length <= 5) {
        console.log("zoomCenter", areaHood);
        const southWest = new mapboxgl.LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new mapboxgl.LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );
        const boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
        const center = boundBox.getCenter();
        this.map.flyTo({ center: center, zoom: 14.01, pitch: 0 });
      } else {
        console.log("fitBounds", areaHood);
        const bDif = 0.0; //polygons.length === 1 ? 0.0005 : 0.0001;
        const bounds = [
          [swLng - bDif, swLat - bDif],
          [neLng + bDif, neLat + bDif],
        ];
        this.map.fitBounds(bounds);
      }
    }
  }

  _setBuilding(buildingTags) {
    console.log(buildingTags);

    const buildingData =
      buildingTags &&
      buildingTags.length > 0 &&
      buildingTags[buildingTags.length - 1].data
        ? buildingTags[buildingTags.length - 1].data
        : false;
    const zoom = this.map.getZoom();

    if (buildingData) {
      if (buildingData.key && buildingData.key.indexOf("b-") >= 0) {
        this._setExistingBuilding(buildingTags);
      } else {
        //Set pipeline building

        if (buildingData.polygon_bbl_json) {
          var polygon_json = JSON.parse(buildingData.polygon_bbl_json);
          var polygon = polygon_json.coordinates;
          var fit = new L.Polygon(polygon).getBounds();
          var southWest = new mapboxgl.LngLat(
            fit["_southWest"]["lat"],
            fit["_southWest"]["lng"]
          );
          var northEast = new mapboxgl.LngLat(
            fit["_northEast"]["lat"],
            fit["_northEast"]["lng"]
          );
          var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
          var center = boundBox.getCenter();

          console.log(center);
          this.map.flyTo({ center: center, zoom: 17 });
        } else if (buildingData.geocode) {
          console.log("buildingData.geocode", buildingData.geocode);
          var geocode = MapUtil._parseCoordinates(buildingData.geocode);
          var lngLat = new mapboxgl.LngLat(geocode[1], geocode[0]);
          // var lngLat = new mapboxgl.LngLat(geocode[1],geocode[0]);

          this.map.flyTo({ center: lngLat, zoom: 17 });
        }

        // checks for past markers and removes all
        pipeline_tileset.forEach((tileset, i) => {
          const keys = buildingTags.map((bldg) => bldg.data.key);
          const filter = keys
            ? ["in", "key"].concat(keys)
            : ["==", "key", "FALSE"];
          console.log(filter);
          this.map.setFilter(tileset.layer_id + "-select", filter);
          this.map.setFilter(tileset.layer_id + "-3d-select", filter);
        });
      }
    } else {
      pipeline_tileset.forEach((tileset) => {
        this.map.setFilter(tileset.layer_id + "-select", [
          "==",
          "key",
          "FALSE",
        ]);
        this.map.setFilter(tileset.layer_id + "-3d-select", [
          "==",
          "key",
          "FALSE",
        ]);
      });
      building_tileset.forEach((tileset, i) => {
        this.map.setFilter(tileset.layer_id + "-select", [
          "==",
          "key",
          "FALSE",
        ]);
      });
    }
  }

  _setExistingBuilding(buildingTags) {
    const buildingData =
      buildingTags &&
      buildingTags.length > 0 &&
      buildingTags[buildingTags.length - 1].data
        ? buildingTags[buildingTags.length - 1].data
        : false;

    if (buildingData) {
      this._getBuildingData(buildingData.key, {
        callback: function (res) {
          if (res.docs && res.docs[0] && res.docs[0].geocode) {
            //mapbox version
            // if (res && res.features && res.features[0]) {
            console.log("getGeocoding res", res);
            buildingData["geocode"] = MapUtil._parseCoordinates(
              res.docs[0].geocode
            );
            var lngLat = new mapboxgl.LngLat(
              buildingData["geocode"][0],
              buildingData["geocode"][1]
            );
            // var lngLat = new mapboxgl.LngLat(geocode[1],geocode[0]);
            console.log(lngLat);

            this.map.flyTo({ center: lngLat, zoom: 17 });
            const keys = buildingTags.map((bldg) => bldg.data.key);

            building_tileset.forEach((tileset, i) => {
              const filter = keys
                ? ["in", "key"].concat(keys)
                : ["==", "key", "FALSE"];
              console.log(filter);
              this.map.setFilter(tileset.layer_id + "-select", filter);
            });

            pipeline_tileset.forEach((tileset, i) => {
              const filter = keys
                ? ["in", "building_key"].concat(keys)
                : ["==", "key", "FALSE"];
              console.log(filter);
              this.map.setFilter(tileset.layer_id + "-select", filter);
              this.map.setFilter(tileset.layer_id + "-3d-select", filter);
            });
          }
        }.bind(this),
      });
    }
  }

  _resetMap() {
    const { lng, lat, zoom } = defaultMap;
    this.map.flyTo({ center: [lng, lat], zoom: zoom });
  }

  _filterMap() {
    let updateMap = this._getMapFilters();

    /*
	  building_tileset.forEach(tileset => {
	  
		  if(updateMap){
			  this.map.setFilter(tileset.layer_id,updateMap.filter);	  
			  this.map.setPaintProperty(tileset.layer_id, 'fill-opacity', updateMap.fillOpacity);
			  this.map.setPaintProperty(tileset.layer_id, 'fill-color', updateMap.fillColor);
		  }else{
			  this.map.setFilter(tileset.layer_id,null);
			  this.map.setPaintProperty(tileset.layer_id, 'fill-opacity', buildingStyle.fillOpacity);
			  this.map.setPaintProperty(tileset.layer_id, 'fill-color', buildingStyle.fillColor);
			  //this._resetMap();
		  }
	  });
*/
  }

  _createPolyQueryKey(feature, featureType) {
    switch (featureType) {
      case "single":
        return "single";
      case "borough":
        return `borough:"${feature.properties.boro_name.toLowerCase()}"`;
      case "market":
        return `markets_major:"${feature.properties.name.toLowerCase()}"`;
      case "neighborhood":
        return `neighborhood:"${feature.properties.name.toLowerCase()}" AND borough:"${feature.properties.region.toLowerCase()}"`;
    }
  }

  _createPolygonsObj(features = [], featureType) {
    const poly = {};
    features.forEach((feature) => {
      const key = this._createPolyQueryKey(feature, featureType);
      if (!poly[key]) poly[key] = [];

      if (feature.geometry.type === "MultiPolygon")
        feature.geometry.coordinates.forEach((polygon) =>
          poly[key].push(polygon)
        );
      else poly[key].push(feature.geometry.coordinates);
    });

    return poly.single || poly;
  }

  _createPolyDataInfoObj(polygons, featureType, key) {
    const locationName = key.split('"')[1].toLowerCase();

    if (featureType === "borough") {
      return {
        location: _capitalizeText(locationName),
        geocode: BOROUGHS[_capitalizeText(locationName)].geocode
          ? BOROUGHS[_capitalizeText(locationName)].geocode
              .slice()
              .reverse()
              .join(", ")
          : BOROUGHS[_capitalizeText(locationName)].center
              .slice()
              .reverse()
              .join(", "),
        fontSize: 24,
        offset: [0, -1.5],
      };
    } else {
      const polyDataInfo = {
        fontSize: this.map.getZoom() < 11 || this.map.getZoom() >= 14 ? 16 : 12,
      };
      const multiPlygn = multiPolygon(polygons);

      if (featureType === "neighborhood" && this.map.getZoom() >= 11) {
        const mapBounds = this.map.getBounds();
        const boundsPoly = polygon([
          [
            mapBounds.getSouthWest().toArray(),
            mapBounds.getSouthEast().toArray(),
            mapBounds.getNorthEast().toArray(),
            mapBounds.getNorthWest().toArray(),
            mapBounds.getSouthWest().toArray(),
          ],
        ]);
        const areaPercent = MapUtil._areaPercentage(
          area(multiPlygn),
          area(boundsPoly)
        );

        const hoodTags = []; //this.props.hoodTags.map(tag => tag.hood.toLowerCase());

        polyDataInfo.location =
          areaPercent > 1.7 || hoodTags.includes(locationName)
            ? _capitalizeText(locationName)
            : "";
      } else {
        polyDataInfo.location = _capitalizeText(locationName);
      }

      const geoCenter = center(multiPlygn).geometry.coordinates.slice();
      if (LocationsPadding[polyDataInfo.location]) {
        geoCenter[0] =
          geoCenter[0] + LocationsPadding[polyDataInfo.location][0];
        geoCenter[1] =
          geoCenter[1] + LocationsPadding[polyDataInfo.location][1];
      }
      polyDataInfo.geocode = geoCenter.reverse().join(", ");

      // let count = 0;
      // for (let i = 0; i < locationName.length; i++) {
      //   if (locationName[i] !== " ") count += 1;
      // }
      // polyDataInfo.offset = count > 15 ? [0, -2.2] : [0, -1.6];

      return polyDataInfo;
    }
  }
  _setSourceFilters(boros = [], markets = [], hoods = []) {
    this.map.setFilter("mp-boroughs-layer", ["in", "boro_name", ...boros]);

    this.map.setFilter("mp-boroughs-layer-outline", [
      "in",
      "boro_name",
      ...boros,
    ]);

    this.map.setFilter("mp-major-markets-layer", ["in", "name", ...markets]);

    this.map.setFilter("mp-major-markets-layer-outline", [
      "in",
      "name",
      ...markets,
    ]);

    this.map.setFilter("mp-neighborhoods-layer", ["in", "id", ...hoods]);

    this.map.setFilter("mp-neighborhoods-layer-outline", [
      "in",
      "id",
      ...hoods,
    ]);
  }
  _setLocationNames(plygnDataArr = []) {
    /*
	   console.log(plygnDataArr);
	   console.log(MapUtil._parseToGeoJSON(plygnDataArr));
*/
    this.map
      .getSource("location-names")
      .setData(MapUtil._parseToGeoJSON(plygnDataArr));
  }

  _getPolyHoods() {
    const { searchTags } = this.props;
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    if (hoodTags.length > 0) {
      _getNeighborhoods(searchTags, {
        callback: function (hoods) {
          //this.props.updateNeighborhoods({ neighborhoods: hoods });
          this.setState({ polyHoods: hoods });
        }.bind(this),
        url: url,
      });
    } else {
      this.setState({ polyHoods: [] });
    }
  }

  _setMapBoundaries() {
    const { searchTags } = this.props;
    const { polyHoods } = this.state;
    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags
            .filter((item) => item.type === "borough")
            .map((item) => item.name)
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    if (
      this.state.mapLoaded &&
      this.state.styleLoaded &&
      this.state.mapRendered &&
      this.state.tilesAndSourcesLoaded
    ) {
      //console.log("compent update zoom", boroTags, hoodTags)
      const zoom = this.map.getZoom();
      const polygonData = {};
      let polygonQueries =
        zoom < 14
          ? MapUtil._createPolygonQueries(
              this.map.getBounds(),
              this.map.getZoom(),
              false,
              false
            )
          : MapUtil._createPolygonQuery(this.map.getBounds(), false);
      if (this.map.getZoom() < 15) polygonQueries.groupQuery = {};
      if (this.map.getZoom() < 11) {
        const features = this.map.queryRenderedFeatures({
          layers: ["mp-boroughs-layer-transparent"],
          filter:
            hoodTags && hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "boro_name"]
              : [
                  "in",
                  "boro_name",
                  ...boroTags.map((tag) => _capitalizeText(tag)),
                ],
        });

        const poly = this._createPolygonsObj(features, "borough");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "borough",
              key
            ))
        );

        //const hoodTags = []; //this.props.hoodTags.map(tag => tag.hood.toLowerCase().trim());

        let hoods = [];

        const hood_keys = polyHoods.map((tag) => tag.key);
        if (polyHoods) {
          polyHoods.forEach((hood) => {
            const key = this._createPolyQueryKey(
              { properties: hood },
              "neighborhood"
            );
            polygonData[key] = {};
            const plygn = polygon([
              hood.coordinates.map((coord) => [
                Number(coord[0]),
                Number(coord[1]),
              ]),
            ]);
            const cent = center(plygn);

            polygonData[key].location = _capitalizeText(hood.name);
            polygonData[key].geocode = cent.geometry.coordinates
              .reverse()
              .join(", ");
            polygonData[key].fontSize = 16;
            polygonData[key].offset = [0, -1.5];
          });
        }

        const boroNames = features.map((feat) => feat.properties.boro_name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        // this._setLocationNames(plygnDataArr);

        this._setSourceFilters(boroNames, [], hood_keys);

        console.log("DUET BOROUGHS");
      } else if (this.map.getZoom() < 13) {
        const features1 = this.map.queryRenderedFeatures({
          layers: ["mp-major-markets-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "name"]
              : ["in", "region", ...boroTags],
        });

        const hood_keys = polyHoods.map((tag) => tag.key);

        const features2 = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["!in", "region", "manhattan", "brooklyn"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  [
                    "in",
                    "region",
                    ...boroTags.filter(
                      (tag) => tag !== "manhattan" && tag !== "brooklyn"
                    ),
                  ],
                ],
        });

        const poly = this._createPolygonsObj(features1, "market");
        const poly2 = this._createPolygonsObj(features2, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "market",
              key
            ))
        );
        Object.keys(poly2).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly2[key],
              "neighborhood",
              key
            ))
        );

        const majorMarkets = features1.map((feat) => feat.properties.name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });

        this._setSourceFilters([], majorMarkets, [
          ...hood_keys,
          ...features2.map((feat) => feat.properties.id),
        ]);

        console.log("DUET MAJOR MARKETS");
      } else if (this.map.getZoom() < 15) {
        const hood_keys = polyHoods.map((tag) => tag.key);

        const features = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "id"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  ["in", "region", ...boroTags],
                ],
        });

        const poly = this._createPolygonsObj(features, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "neighborhood",
              key
            ))
        );

        const hoodNames = features.map((feat) => feat.properties.name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        // console.log(plygnDataArr)
        //this._setLocationNames(plygnDataArr);

        this._setSourceFilters(
          [],
          [],
          [...hood_keys, ...features.map((feat) => feat.properties.id)]
        );

        console.log("DUET NEIGHBORHOODS");
      } else {
        console.log("DUET LISTINGS");

        const hood_keys = polyHoods.map((tag) => tag.key);

        const features = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "id"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  ["in", "region", ...boroTags],
                ],
        });

        const poly = this._createPolygonsObj(features, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "neighborhood",
              key
            ))
        );

        const plygnDataArr = Object.keys(polygonData).map(
          (key) => polygonData[key]
        );
        this._setLocationNames(plygnDataArr);

        this._setSourceFilters(
          [],
          [],
          [...hood_keys, ...features.map((feat) => feat.properties.id)]
        );
      }
      const plygnDataArr = Object.keys(polygonData).map(
        (key) => polygonData[key]
      );
      this._setLocationNames(plygnDataArr);
    }
  }
  

  _addSourceAndLayers(tileset) {
    this.map.addSource(tileset.id, {
      type: "vector",
      url: tileset.url,
    });

    this.map.addLayer({
      id: `${tileset.id}-layer-transparent`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-outline-color": "#000",
        "fill-color": "#363347",
        "fill-opacity": 0,
      },
    });

    this.map.addLayer({
      id: `${tileset.id}-layer`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#ccc",
        "fill-opacity": 0.1,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    this.map.addLayer({
      id: `${tileset.id}-layer-highlighted`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#6674AD",
        "fill-opacity": 0.3,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    const minWidth = 1;
    const minZoom = 10;

    this.map.addLayer({
      id: `${tileset.id}-layer-outline`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "line",
      paint: {
        "line-color": "#abb3d4",
        "line-width": 2,
        // 'line-width': {
        //   "type": "exponential",
        //   "base": 1.4
        //   ,
        //   "stops": [
        //     [9, minWidth * Math.pow(1.4, (9 - minZoom))],
        //     [15, minWidth * Math.pow(1.4, (15 - minZoom))],
        //   ]
        // }
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    const cb1 = (e) => {
      if (e.features.length > 0 && this.map.getZoom() < 14) {
        this._updateLocationMarker(e);

        if (
          !this.currentlyHoveredLocation ||
          (tileset.id.includes("boroughs") &&
            e.features[0].properties.boro_name !==
              this.currentlyHoveredLocation) ||
          e.features[0].properties.id !== this.currentlyHoveredLocation
        ) {
          this.currentlyHoveredLocation = tileset.id.includes("boroughs")
            ? e.features[0].properties.boro_name
            : e.features[0].properties.id;

          const highlightFilter = tileset.id.includes("boroughs")
            ? ["in", "boro_name", e.features[0].properties.boro_name]
            : ["in", "id", e.features[0].properties.id];

          this.map.setFilter(
            `${tileset.id}-layer-highlighted`,
            highlightFilter
          );
        }
      }
    };

    const cb2 = (e) => {
      this._updateLocationMarker(e);
      this.map.setFilter(`${tileset.id}-layer-highlighted`, [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ]);
      this.currentlyHoveredLocation = null;
    };

    const cb3 = (e) => {
      console.log("DUETING");
      this.map.off("mousemove", `${tileset.id}-layer`, cb1);
      this.map.off("mouseleave", `${tileset.id}-layer`, cb2);
      this.map.off("touchstart", `${tileset.id}-layer`, cb3);
    };

    this.map.on("mousemove", `${tileset.id}-layer`, cb1);
    this.map.on("mouseleave", `${tileset.id}-layer`, cb2);
    this.map.on("touchstart", `${tileset.id}-layer`, cb3);

    this.map.on("click", `${tileset.id}-layer`, (e) => {
      if (!this.justClickedSelectedMarker && !this.justClickedMarker) {
        const srcFilter = tileset.id.includes("boroughs")
          ? ["in", "boro_name", e.features[0].properties.boro_name]
          : ["in", "id", e.features[0].properties.id];

        const srcFeatures = this.map.querySourceFeatures(tileset.id, {
          sourceLayer: [tileset.source_layer],
          filter: srcFilter,
        });

        const poly = this._createPolygonsObj(srcFeatures, "single");

        const plygn = multiPolygon(poly);
        const boundBox = bbox(plygn);
        const boundPolygon = bboxPolygon(boundBox);
        const cent = center(boundPolygon);

        console.log("click zoom", this.map.getZoom());
        const zoom = tileset.id.includes("boroughs")
          ? 12.1
          : tileset.id.includes("markets")
          ? 13.1
          : 15.1;
        console.log("click zoom", tileset, zoom);
        const centerVal =
          this.map.getZoom() < 12
            ? cent.geometry.coordinates
            : e.lngLat.toArray();

        if (this.map.getZoom() < 14) {
          if (
            tileset.id.includes("boroughs") ||
            (tileset.id.includes("neighborhoods") &&
              this.map.getZoom() >= 12) ||
            this.props.isMobile ||
            tileset.id.includes("markets")
          ) {
            console.log("click zoom", centerVal, zoom);
            this.map.easeTo({
              center: centerVal,
              zoom,
            });
          } else {
            const padding =
              e.features[0].properties.boro_name === "Queens"
                ? 0.028
                : e.features[0].properties.id === "upper_manhattan_ny"
                ? 0.01
                : 0;

            this.map.fitBounds([
              [boundBox[0] + padding, boundBox[1] + padding],
              [boundBox[2] - padding, boundBox[3] - padding],
            ]);
          }
        }
      }
      this.justClickedSelectedMarker = false;
      this.justClickedMarker = false;
    });
  }
  _updateLocationMarker(e) {
    if (this.locationMarker) {
      const el = this.locationMarker.getElement();

      if (e.features && e.features[0]) {
        el.innerText = e.features[0].properties.boro_name
          ? _capitalizeText(e.features[0].properties.boro_name)
          : _capitalizeText(e.features[0].properties.name);
        el.style.background = "#FFFFFF";
        el.style.border = "1px solid #5C6178";
        el.style.fontSize = "12px";
        el.style.padding = "5px 8px";
        el.style.boxShadow = "0 0 2px #A3ADC2";

        this.locationMarker.setLngLat(e.lngLat);
        this.locationMarker.addTo(this.map);
      } else {
        this.locationMarker.remove();
      }
    }
  }

  _addLocationNames(data) {
    this.map.addSource("location-names", {
      type: "geojson",
      data,
    });

    this.map.addLayer({
      id: "location-names",
      type: "symbol",
      source: "location-names",
      layout: {
        "text-field": ["get", "location"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": ["get", "fontSize"],
        "text-offset": ["get", "offset"],
      },
      paint: {
        "text-color": "#171F26",
      },
    });
  }

  _addPipelineTiles() {
    let filter = this._getPipelineFitlers();
    pipeline_tileset.forEach((tileset) => {
      this.map.addSource(tileset.id, {
        type: "vector",
        url: tileset.url,
      });
    });

    pipeline_tileset.forEach((tileset, i) => {
      // ALL buildings footprints - adds a layer for all the building outlines

      const default_filter = tileset.default_filter
        ? tileset.default_filter
        : false;
      let theFilter = filter.slice(0);
      if (default_filter) {
        theFilter = ["all", default_filter, filter];
      }
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 18,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-opacity": pipelineStyle.fillOpacity,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id);
      this.map.addLayer({
        id: tileset.layer_id + `-3d`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-extrusion-opacity": 0.8,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id + `-3d`);
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-hover",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", false],
      });
      this.map.addLayer({
        id: tileset.layer_id + `-3d-hover`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": "#FFD23F",
          "fill-extrusion-opacity": 1,
        },
        filter: ["==", "key", false],
      });
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-select",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "fill",
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", "FALSE"],
      });

      this.map.addLayer({
        id: tileset.layer_id + `-3d-select`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": "#FFD23F",
          "fill-extrusion-opacity": 1,
        },
        filter: ["==", "key", "FALSE"],
      });
    });

    pipeline_tileset.forEach((tileset) => {
      //Skip mouse events for mobile

      this.map.on(
        "mousemove",
        tileset.layer_id,
        function (e) {
          //console.log(tileset)
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mousemove",
        tileset.layer_id + "-3d",
        function (e) {
          console.log(tileset);
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id,
        function (e) {
          this._onMouseOut();
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id + "-3d",
        function (e) {
          this._onMouseOut(e);
        }.bind(this)
      );

      this.map.on(
        "click",
        tileset.layer_id,
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
      this.map.on(
        "click",
        tileset.layer_id + "-3d",
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
    });
  }

  _addPipelineLotTiles() {
    const filter = this._getPipelineFitlers();
    pipeline_lot_tileset.forEach((tileset) => {
      this.map.addSource(tileset.id, {
        type: "vector",
        url: tileset.url,
      });
    });

    pipeline_lot_tileset.forEach((tileset, i) => {
      // ALL buildings footprints - adds a layer for all the building outlines
      let theFilter = filter.slice(0);
      const default_filter = tileset.default_filter
        ? tileset.default_filter
        : false;
      if (default_filter) {
        theFilter = ["all", default_filter, filter];
      }
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#DBE0E8",
          "fill-opacity": 0.3,
        },
        filter: theFilter,
      });
    });
  }

  _addBuildingTiles() {
    building_tileset.filter((tileset) => {
      // adding tilesets
      this.map.addSource(tileset.id, {
        type: "vector",
        url: tileset.url,
      });

      // ALL buildings footprints - adds a layer for all the building outlines
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minzoom: 16,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": buildingStyle.fillColor,
          "fill-opacity": buildingStyle.fillOpacity,
        },
      });

      this.layersAdded.push(tileset.layer_id);

      this.map.addLayer({
        id: tileset.layer_id + "-hover",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minzoom: 16,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#9600BF",
          "fill-opacity": 0.8,
        },
        filter: ["==", "key", false],
      });

      this.map.addLayer({
        id: tileset.layer_id + "-select",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minzoom: 16,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 0.8,
        },
        filter: ["==", "key", false],
      });

      this.map.on(
        "mousemove",
        tileset.layer_id,
        function (e) {
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id,
        function (e) {
          this._onMouseOut();
        }.bind(this)
      );

      this.map.on(
        "click",
        tileset.layer_id,
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
    });
  }

  _getPipelineFitlers() {
    const { occupancyTypes, polyHoods } = this.state;
    const { searchTags, subFilters, buildingStages } = this.props;
    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags
            .filter((item) => item.type === "borough")
            .map((item) => item.name)
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    let filter = ["all"];

    if (occupancyTypes.length < 3) {
      let occupancyFilter = ["in", "pipeline_occupancy_type"].concat(
        occupancyTypes
      );
      filter.push(occupancyFilter);
    }

    if (boroTags.length > 0) {
      let boroFilter = ["in", "borough"].concat(boroTags);
      filter.push(boroFilter);
    }
    if (hoodTags.length > 0) {
      let hoodFilter = ["in", "neighbhrood"].concat(hoodTags);
      //filter.push(hoodFilter);  Neighborhood not functional
    }

    if (subFilters && Object.keys(subFilters).length > 0) {
      Object.keys(subFilters).map((key) => {
        let subFilterVal = subFilters[key];
        if (subFilterVal && subFilterVal != "") {
          let qVal = this.buildingSubFilters
            .filter((filter) => filter.search_key === key)[0]
            .values.filter((value) => value.filter === subFilterVal)[0];
          qVal = qVal && qVal.search_value;
          if (qVal) filter.push(qVal);
        }
      });
    }
    const buildingStageFilters = {
      "pre-development": "planning" ,
      construction: "construction" ,
      operation: "completion"
    };
    if (buildingStages && buildingStages.length > 0) {
      if (buildingStages.indexOf("all") < 0) {
        let stageQ = ["in", "pipeline_project_stage"];
        if (stageQ.length > 1) {
          stageQ = stageQ.concat(buildingStages.filter(stage => buildingStageFilters[stage]).map(stage => buildingStageFilters[stage] ));
          filter.push(stageQ);
        }
      }
    }

    if (filter.length === 1) {
      filter = ["has", "key"];
    }
    return filter;
  }

  _filterPipelineTiles() {
    //return null;
    if (
      this.state.mapLoaded &&
      this.state.styleLoaded &&
      this.state.mapRendered &&
      this.state.tilesAndSourcesLoaded
    ) {
      let filter = this._getPipelineFitlers();

      console.log(filter);
      pipeline_tileset.forEach((tileset) => {
        let theFilter = filter.slice(0);
        const default_filter = tileset.default_filter
          ? tileset.default_filter
          : false;
        if (default_filter) {
          theFilter = ["all", default_filter, filter];
        }
        this.map.setFilter(tileset.layer_id, theFilter);
        this.map.setFilter(tileset.layer_id + "-3d", theFilter);
      });

      pipeline_lot_tileset.forEach((tileset) => {
        let theFilter = filter.slice(0);
        const default_filter = tileset.default_filter
          ? tileset.default_filter
          : false;
        if (default_filter) {
          theFilter = ["all", default_filter, filter];
        }
        this.map.setFilter(tileset.layer_id, theFilter);
      });
    }
  }

  componentDidMount() {
    console.log("MapSearch didMount", this.props);
    let singleBuildingLot = false;

    const { searchTags } = this.props;

    this._getPolyHoods();

    const { lng, lat, zoom } = this.state;
    this.tooltipContainer = document.createElement("div");
    if (!this.props.standardView) {
      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/ckyuong66001614ofy64tjg7c",
        center: [lng, lat],
        zoom,
        minZoom: 9,
      });
    } else {
      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/cjvpf64h318z01cn8axood9r9",
        center: [lng, lat],
        zoom,
        minZoom: 9,
      });
    }

    //this.map.scrollZoom.disable();
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(
      new PitchToggle({
        minpitchzoom: this.props.fullSize ? 18 : 17,
        trxnRes: null,
      }),
      "top-right"
    );
    this.map.addControl(
      new mapboxgl.FullscreenControl({
        container: document.querySelector("#pipeline-map"),
      })
    );
    this.geoLocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      fitBoundsOptions: {
        maxZoom: 17,
      },
      trackUserLocation: false,
    });

    this.map.addControl(this.geoLocate);

    this.map.once("styledata", () => this.setState({ styleLoaded: true }));
    this.map.once("render", () => this.setState({ mapRendered: true }));
    this.map.on(
      "load",
      function () {
        console.log("Map Loaded", this.map);
        this.tooltip = new mapboxgl.Marker(this.tooltipContainer, {
          offset: [0, -165],
        })
          .setLngLat([0, 0])
          .addTo(this.map);

        //this.geoLocate.trigger();
        this._addBuildingTiles();

        this._addPipelineLotTiles();

        const layers = this.map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === "symbol" && layer.layout["text-field"]
        ).id;
        this.map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 14,
            layout: {
              visibility: "none",
            },
            paint: {
              "fill-extrusion-color": "#aaa",
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          labelLayerId
        );

        /*
		  	building_tileset.forEach(tileset => {
			  	this.map.addSource(tileset.id, {
					"type": "vector",
					"url": tileset.url
			  	});
			});
*/

        this._addSourceAndLayers(boroughTileset);
        this._addSourceAndLayers(majorMarketTileset);
        this._addSourceAndLayers(neighborhoodTileset);
       //_addZoningLayer(this.map, 'visible')
       //_addCommercialOverlayLayer(this.map, 'visible')
        this._addLocationNames(MapUtil._parseToGeoJSON([]));

        this._addPipelineTiles();

        const buildingTags =
          searchTags.filter((item) => item.type === "building").length > 0
            ? searchTags.filter((item) => item.type === "building")
            : [];

        if (buildingTags.length > 0) {
          this._setBuilding(buildingTags);
        }

        //this.map.on("resize", this._resizeCb);

        this.map.on("move", () => {
          this.map.setFilter("mp-boroughs-layer-highlighted", [
            "in",
            "name",
            "FALSE",
          ]);
          this.map.setFilter("mp-major-markets-layer-highlighted", [
            "in",
            "name",
            "FALSE",
          ]);
          this.map.setFilter("mp-neighborhoods-layer-highlighted", [
            "in",
            "name",
            "FALSE",
          ]);
          //this._updateLocationMarker({});
          const { lng, lat } = this.map.getCenter();

          this.setState({
            lng: lng.toFixed(4),
            lat: lat.toFixed(4),
            zoom: this.map.getZoom().toFixed(2),
          });
        });

        this.map.on("moveend", () => {
          if (!this.justFetched) {
            console.log("CURRENT MAP INFO");
            console.log(this.map.getCenter());
            console.log(this.map.getZoom());

            if (!this.state.locationChange && !this.justResized) {
              this.justSetMapBounds = true;
              console.log("justSetMapBounds", this.map.getZoom());
              // this.props.handleMapBounds(MapUtil._createMapBounds(this.map.getBounds()));
            }
          }
          // if (this.state.locationChange) this.setState({ locationChange: false });

          // forcing one more update on move end for dragging/panning
          this.setState({ locationChange: !this.state.locationChange });
          this.currentlyFlying = false;
        });

        this.setState({ mapLoaded: true });
        this.map.resize();
        this.checkTilesAndSourcesInterval = setInterval(() => {
          if (
            this.map.areTilesLoaded() &&
            this.map.isSourceLoaded("mp-boroughs") &&
            this.map.isSourceLoaded("mp-major-markets") &&
            this.map.isSourceLoaded("mp-neighborhoods") 
          ) {
            clearInterval(this.checkTilesAndSourcesInterval);
            this.setState({ tilesAndSourcesLoaded: true });
          }
        }, 100);
      }.bind(this)
    );
  }

  _getBuildingData = (key, obj) => {
    // console.log('_getBuildingData called', this.state)

    let buildingCall = {
      url: apiURL + "we3-buildings?cache=60",
      method: "post",
      query: {
        q: "key:" + key,
        fq: "deleted:false",
        fl: "geocode, polygon_json",
        wt: "json",
        rows: 1,
      },
    };

    _axiosCall(buildingCall)
      .then((res) => {
        if (typeof obj.callback === "function") {
          obj.callback(res);
        }
      })
      .catch((error) => {
        console.log("BlBuildingPage error ", error);
        this.setState({
          fetchInProgress: false,
        });
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchTags } = this.props;
    const prevSearchTags = prevProps.searchTags;

    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags.filter((item) => item.type === "borough")
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags.filter((item) => item.type === "neighborhood")
        : [];
    const buildingTags =
      searchTags.filter((item) => item.type === "building").length > 0
        ? searchTags.filter((item) => item.type === "building")
        : [];

    const prevBoroTags =
      prevSearchTags.filter((item) => item.type === "borough").length > 0
        ? prevSearchTags.filter((item) => item.type === "borough")
        : [];
    const prevHoodTags =
      prevSearchTags.filter((item) => item.type === "neighborhood").length > 0
        ? prevSearchTags.filter((item) => item.type === "neighborhood")
        : [];
    const prevBuildingTags =
      prevSearchTags.filter((item) => item.type === "building").length > 0
        ? prevSearchTags.filter((item) => item.type === "building")
        : [];

    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let buildingStageChange = !_isEqual(
      prevProps.buildingStages,
      this.props.buildingStages
    );
    let subFilterChanged =
      this.buildingSubFilters.filter((item) => {
        return (
          this.props.subFilters[item.search_key] !==
          prevProps.subFilters[item.search_key]
        );
      }).length > 0;

    if (
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.filterStage !== prevState.filterStage ||
      this.state.searchText !== prevState.searchText ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.page !== prevState.page ||
      this.state.rowsPerPage !== prevState.rowsPerPage ||
      searchTagsChange ||
      subFilterChanged ||
      buildingStageChange
    ) {
      const resetPage = this.state.page === prevState.page;

      if (this.state.mapLoaded) {
        this._filterMap();
      }
    }

    if (
      !_isEqual(boroTags, prevBoroTags) ||
      !_isEqual(hoodTags, prevHoodTags)
    ) {
      console.log("AlgoSB didUpate calling _getNeighborhoods");
      this._getPolyHoods();
    }

    if (!_isEqual(prevState.polyHoods, this.state.polyHoods)) {
      this._setNeighborhood();
    } else if (!_isEqual(prevBoroTags, boroTags) && boroTags.length > 0) {
      const tag = boroTags[boroTags.length - 1].name;
      if (!this.currentlyFlying) {
        this.currentlyFlying = true;
        this.map.flyTo({
          center: BOROUGHS[_capitalizeText(tag)].center,
          zoom: 12.01,
        });
      }
    }

    if (!_isEqual(buildingTags, prevBuildingTags)) {
      this._setBuilding(buildingTags);
    }

    this._setMapBoundaries();

    this._filterPipelineTiles();
  }

  componentWillUnmount() {
    console.log("BS unmounting");
    this._isMounted = false;
    this.map.remove();
    if (this.props.isMobile) {
      //NZ Super hacky code to keep map fixed on mobile IOS
      if (
        document.getElementsByClassName("c_map-wrapper") &&
        document.getElementsByClassName("c_seconday-nav-container")[0] &&
        document.getElementById("search-container")
      ) {
        document.getElementsByClassName("c_map-wrapper")[0].style.position =
          null;
        document.getElementsByClassName("c_map-wrapper")[0].style.top = null;
        document.getElementsByClassName("c_map-wrapper")[0].style.zIndex = null;
        document.getElementsByClassName("c_map-wrapper")[0].style.width = null;
        document.getElementsByClassName(
          "c_seconday-nav-container"
        )[0].style.position = null;
        document.getElementsByClassName(
          "c_seconday-nav-container"
        )[0].style.top = null;
        document.getElementsByClassName(
          "c_seconday-nav-container"
        )[0].style.zIndex = null;
        document.getElementsByClassName(
          "c_seconday-nav-container"
        )[0].style.width = null;
        document.getElementById("search-container").style.position = null;
        document.getElementById("search-container").style.top = null;
        document.getElementById("search-container").style.zIndex = null;
        document.getElementById("search-container").style.width = null;
      }
    }
  }

  _showBuilding(building) {
    const gmapApiArr = process.env.GMAP_API_KEY;
    const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
    let buildingImages = _getBuildingPhotos(building, gApiKey);
    let image = `https://maps.googleapis.com/maps/api/streetview?${gApiKey}&size=700x700&location=${
      building.display_full_street_address
        ? building.display_full_street_address
        : building.full_street_address
    },${building.zip}&fov=90&pitch=10&source=outdoor`;
    if (buildingImages.length == 0) {
      buildingImages.push(image);
    }
    let adr = building.display_full_street_address
      ? _capitalizeText(building.display_full_street_address).trim()
      : building.primary_full_street_address
      ? _capitalizeText(building.primary_full_street_address).trim()
      : building.full_street_address
      ? _capitalizeText(building.full_street_address).trim()
      : false;

    if (
      building.borough == "queens" &&
      buildingName &&
      building.alternate_addresses
    ) {
      adr = _formatQueensAddress(adr, building.alternate_addresses);
    }

    let buildingName =
      building.name && building.name != building.display_full_street_address
        ? _capitalizeText(_urlToName(building.name.replace("corporation", "")))
        : false;
    return (
      <div
        className={`c_map-building-container`}
        key={`${building.key}`}
        onClick={(e) =>
          e.target.id != "mp_close" && this._openBuilding(building)
        }
      >
        <div className={`row`}>
          <div className={`col-xs-5 u_pd-rt-none`}>
            <div
              className={`img-responsive c_building-img`}
              style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}
            >
              <span className={`c_building-type`}>
                {_capitalizeText(_urlToName(building.ownership_type))}
              </span>
            </div>
          </div>
          <div className={`col-xs-7 u_pd-left-none c_building-info`}>
            <div className={`u_pd-5px`}>
              <div className="c_title u_600-wgt">
                {buildingName ? buildingName : adr}{" "}
                <i
                  id="mp_close"
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() =>
                    this.props.updateBuildings({ buildingsSelected: [] })
                  }
                ></i>
              </div>
              <div className="u_font-11">{buildingName ? adr + ", " : ""}</div>
              <div className="u_font-11">
                {building.main_neighborhood
                  ? _capitalizeText(building.main_neighborhood) + ", "
                  : ""}
                {_capitalizeText(building.borough)}
              </div>

              <div className="u_font-12 u_pd-top-5px">
                {_shouldShow(building.year_built)
                  ? moment().year() < building.year_built
                    ? "Proj Completion " + building.year_built
                    : "Year Built " + building.year_built
                  : ""}
              </div>
              <div className="u_font-12">
                {building.total_units &&
                  (building.total_units > 1
                    ? building.total_units + " Units"
                    : "")}
                {building.total_floors && building.total_floors > 0
                  ? building.total_units > 1
                    ? " | " +
                      (building.total_floors > 1
                        ? building.total_floors + " Floors"
                        : building.total_floors + " Floor")
                    : building.total_floors > 1
                    ? building.total_floors + " Floors"
                    : building.total_floors + " Floor"
                  : false}
              </div>
            </div>
            <div className="c_tooltip-hint u_font-11">
              Click for details{" "}
              <i className="fa fa-external-link" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggle() {
    //this.snackOpen('Your Message Has Been Sent!', 'success');
    const { modal, prevUrl } = this.state;
    if (modal) {
      this.props.history.push(`/pipeline-map${prevUrl}`);
    }
    this.setState({ modal: !modal });
  }
  
  _toggleLayer(layerId){
    const { showLayers } = this.state;
    const index = showLayers.indexOf(layerId);
    const layerObj = layers.filter(l => l.id && l.id == layerId)[0];
    
    console.log(layerObj, index, layerId)
    if(index>=0){
      let newLayers = showLayers.slice();
      newLayers.splice(index,1).filter(item => item);
      layerObj.fun(this.map, "none");
      this.setState({showLayers: newLayers});
    }else{
      layerObj.fun(this.map, "visible");
      this.setState({showLayers: [...showLayers, layerId]});
    }
    
    
  }
  _clearAllLayers(){
    layers.forEach(layerObj => {
      if(layerObj.fun){
        layerObj.fun(this.map, "none");
      }
      
    })
    this.setState({showLayers: []});
  }

  render() {
    const { lng, lat, zoom, occupancyTypes, modal, building, showLayers } = this.state;
    const header = 120;

    const secondary_header =
      document.getElementsByClassName("mat-app-bar").length > 0
        ? document.getElementsByClassName("mat-app-bar")[0].offsetHeight
        : 0;

    const search_container =
      document.getElementsByClassName("map-header").length > 0
        ? document.getElementsByClassName("map-header")[0].offsetHeight
        : 0;

    const mapHeight =
      window.innerHeight - header - secondary_header - search_container;

    let bottomOffset = 20;
    let sideBarHeight = 0;
    let showShadow = false;

    if (
      this.props.authData &&
      this.props.authData.appState &&
      this.props.authData.appState &&
      this.props.authData.appState.buildingsSelected &&
      this.props.authData.appState.buildingsSelected.length > 0
    ) {
      sideBarHeight =
        110 * this.props.authData.appState.buildingsSelected.length;

      if (sideBarHeight >= mapHeight - bottomOffset) {
        sideBarHeight = mapHeight - bottomOffset;
        showShadow = true;
      }
    }

    let dragging = false;
    var settings = {
      dots: false,
      infinite: true,
      speed: 200,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
    };

    return (
      <Fragment>
        <div
          id="pipeline-map-container"
          className={`pipeline-map-container ${isMobile ? "mobile-map-view" : ""}`}
          style={{ height: "100%" }}
        >
          <LegendToggle
            occupancyTypes={occupancyTypes}
            setOccupancyFilter={this._setOccupancyFilter}
          />
          <LayerToggle showLayers={showLayers} layers={layers} clearAllLayers={this._clearAllLayers} toggleLayer={this._toggleLayer}/>
          <div
            className={`c_map-wrapper ${
              this.props.isMobile ? "is-mobile" : ""
            }`}
            style={{ position: "relative", height: "100%" }}
          >
            <div
              className={`c_map-container ${
                this.props.isMobile ? "is-mobile" : ""
              }`}
              ref={(el) => (this.mapContainer = el)}
              style={{
                ...containerStyle,
                minHeight: mapHeight,
                height: "100%",
              }}
            ></div>
            {this.props.isMobile && sideBarHeight > 0 && (
              <div className={`c_map-mobile-slider-outer`}>
                {/*<Slider {...Object.assign({}, settings, {slidesToShow:1})}>	
	      		{this.props.authData.appState.buildingsSelected.map(building => {
				  		return this._showBuilding(building);
						})}
	    		</Slider>*/}
              </div>
            )}
          </div>
        </div>
        <Modal
          isOpen={modal}
          toggle={this.toggle.bind(this)}
          modalClassName={`ltr-support`}
          className={`modal-dialog contact__modal`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.toggle.bind(this)}
            />
          </div>
          <div className="modal__body">
            <BuildingDetail trxnRes={building} />
            {/* <Project buildingRes={buildingRes} /> */}
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default MatMapView;
