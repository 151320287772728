import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Progress, Card, CardBody } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ComposedChart, Text, LabelList } from 'recharts';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../shared/helpers';
import Panel from '../../../../shared/components/Panel';
import StatIcon from 'mdi-react/MapMarkerOutlineIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_formatBigNumber
} from '../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../shared/helpers';

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
      <text x={x + width / 2} y={y - radius} fill="#780F9E" textAnchor="middle" dominantBaseline="middle">
        {value === 0 ? 'N/A' : value > 1000000 ? _formatBigNumber(value) : _formatNumber(value)}
      </text>
    </g>
  );
}

const colors = {
	shadow: "#858AA1",
	active: "#4ce1b6",
	contract: "#B070CC",
	sold: "#D6BAE8",
	recorded: "#FF206E",
	resale: "#28C2FF",
	unsold: "#780F9E"
}

const CustomizedAxisTick = ( {x, y, stroke, payload} ) => {
		
   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">{_capitalizeText(payload.value)}</text>
      </g>
    );
}

const svgToPng = (svg, width, height) => {
    return new Promise((resolve, reject) => {

      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');

      // Set background to white
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, width, height);

      let xml = new XMLSerializer().serializeToString(svg);
      let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
      let img = new Image(width, height);

      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        let imageData = canvas.toDataURL('image/png', 1.0);

        resolve(imageData)
      }

      img.onerror = () => reject();

      img.src = dataUrl;
    });
};

class NeighborhoodAnalysis extends PureComponent {
    
  constructor(props) {
    super(props);
	this.state = {
	  fetchInProgress: false,
	  data: null,
	  period: "1-day"
	};
    
  }
  currentChart;
  
  _constructNeighborhoodData = (facetData) => {
	const { analysisType } = this.props;  
	const neighborhoods = analysisType == "units" ? facetData.resi_by_neighborhood : facetData.by_neighborhood; 
	
	const data = [];
	
	neighborhoods.map(hood => {
		
		let count = hood.count;
		
 	  	const hoodData = {
	 	  	name: hood["_id"]["neighborhood"][0],
	 	  	Projects: count,
			Units : hood.units - (hood.units_existing ? hood.units_existing : 0),
			"Square Feet" : hood.sq_ft,
			search: hood["_id"]["neighborhood"][0].toLowerCase().replace(/ /g, "-") + "_" + hood["_id"]["borough"].toLowerCase().replace(/ /g, "-")
	 	
 	  	}
 	  	
 	  	data.push(hoodData);	
 	  	if(analysisType == "units" ){
			   data.sort((a, b) => (a.Units > b.Units ? -1 : 1));
		}
 	  	//data.sort((a, b) => (a.count > b.count ? -1 : 1));  	
		
	})
	return data; 
    
  }
  
  handleClick(data, index) {
  	const { history, baseURL } = this.props;
	//return false
	window.location = `${baseURL}&neighborhood=${data.search}`;
  	
  	
  }
  
  handleDownload = async () => {
	  
	return false;
    const chart = this.currentChart

    let chartSVG = ReactDOM.findDOMNode(chart).children[0]

    const pngData = await svgToPng(chartSVG, 1200, 1600)
    document.write('<img src="'+pngData+'"/>') // <------
  }
 

  
  render() {
      const { facetData, classes, analysisType, buildingsFetchInProgress } = this.props;    
	  const { period } = this.state;
      if(!facetData){
	    return null;
      }
      
      const data = buildingsFetchInProgress ? [] : this._constructNeighborhoodData( facetData );
	  const title =  {
		  "count" : "Top Neighborhoods by Number of Projects",
		  "units" : "Top Neighborhoods by Number of Residential Units",
		  "sq_ft" : "Top Neighborhoods by Total Square Feet"
	  }
	  return (
	  	<Card>
		    <CardBody className="dashboard__booking-card">
		      <div className="dashboard__booking-total-container">
	          	<h5 className="dashboard__booking-total-description"> {title[analysisType]}</h5>
			  	<StatIcon className="dashboard__trend-icon" onClick={() => this.handleDownload()} />
	          </div>
			  
			  
		      <ResponsiveContainer  height={400} className="dashboard__area">
		        <BarChart ref={(chart) => this.currentChart = chart} data={data} margin={{ top: 50, left: -20, bottom: 20, right:20 }}>
			       
		           <XAxis dataKey="name" interval={0} tickLine={false} reversed={false} tick={<CustomizedAxisTick/>} height={60} />				
			       <YAxis  yAxisId="left" tickLine={true} orientation={'left'} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} />
			       <Tooltip formatter={(value, name, props) => ( _formatNumber(value) )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1 }}/>
			       <Legend />
			       <CartesianGrid vertical={false}/>
				   {analysisType == "sq_ft" ? 
			       		<Bar dataKey={"Square Feet"} yAxisId="left" stackId="a" barSize={35} fill={colors['unsold']} onClick={this.handleClick.bind(this)} cursor="pointer">
			       				<LabelList dataKey="Square Feet" content={renderCustomizedLabel} />
			       		</Bar>
						:
					analysisType == "units" ? 
						<Bar dataKey={"Units"} yAxisId="left" stackId="a" barSize={35} fill={colors['unsold']} onClick={this.handleClick.bind(this)} cursor="pointer">
								   <LabelList dataKey="Units" content={renderCustomizedLabel} />
						</Bar>
					:
						<Bar dataKey={"Projects"} yAxisId="left" stackId="a" barSize={35} fill={colors['unsold']} onClick={this.handleClick.bind(this)} cursor="pointer">
								   <LabelList dataKey="Projects" content={renderCustomizedLabel} />
						</Bar>
					}
			       {/*<Bar dataKey={"Contract"} yAxisId="left" stackId="a" barSize={35} fill={colors['contract']} onClick={this.handleClick.bind(this)} cursor="pointer"/>
			       <Bar dataKey={"Sold"} yAxisId="left" stackId="a" barSize={35} fill={colors['sold']} onClick={this.handleClick.bind(this)} cursor="pointer" >
			       	 
			       </Bar>*/}
			      </BarChart>
		      </ResponsiveContainer>
			 </CardBody>
		</Card>
		);
	}
}

export default NeighborhoodAnalysis;
