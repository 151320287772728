import React, { Component, Fragment } from "react";
import { Col, Row, Modal, ButtonToolbar } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { RTLProps, ThemeProps } from "../../../shared/prop-types/ReducerProps";
import buyersListActions from "../../../redux/actions/buyersListActions";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "mdi-react/DeleteIcon";
import EditIcon from "mdi-react/EditIcon";
import PlusIcon from "mdi-react/PlusIcon";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";

import { getFormValues } from "redux-form";
import SnackBar from "../../../shared/components/SimpleSnackBar";
import AddIcon from "mdi-react/PlusCircleIcon";
import CollectionList from "./CollectionList";
import AddListIcon from "mdi-react/ViewGridPlusIcon";
import TextField from "@material-ui/core/TextField";
import HelpCircleOutlineIcon from "mdi-react/HelpCircleOutlineIcon";

import NotifyOnIcon from "mdi-react/BellIcon";
import NotifyOffIcon from "mdi-react/BellOffIcon";

import { _hasPermission } from "../../../shared/helpers/access";
import ChangePlan from "./../payment/ChangePlan";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  _formatUnitNumber,
  _slugifyText,
} from "../../../shared/helpers/utils";

import { _axiosCall } from "../../../shared/helpers/apicalls";

const apiUrl = process.env.API_ROOT + "/buyerslist/"; //'http://localhost:8000/buyerslist/';

const newGroup = {
  group_name: "My New Collection",
  group_data: {
    buildings: [],
    projects: [],
  },
  notify: true,
  edit: true,
};
class AddCollectionButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Contact",
      modal: false,
      saved: false,
      groups: this.props.add
        ? [newGroup]
        : this.props.edit
        ? [this.props.group]
        : false,
      snackShow: false,
      snackMessage: false,
      snackType: "success",
      deleteModal: false,
      toggleAdd: false,
      orginalGroups: this.props.edit ? [this.props.group] : false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getCollections = this.getCollections.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.handleRemoveItems = this.handleRemoveItems.bind(this);
    this.orginalGroups = null;
  }

  handleSave(preview) {
    const {
      authState,
      authService,
      dispatch,
      user,
      formValues,
      buildingRes,
      property,
      listing,
      toggleRefresh,
    } = this.props;
    const { groups, originalGroups } = this.state;
    let saveData = [];

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    saveData = groups.map((group) => {

      if (group && group.group_data) {
        if (group.group_data.buildings && group.group_data.buildings.length > 0) {
          group.group_data.buildings = group.group_data.buildings.filter(onlyUnique);
        }
        if (
          group.group_data.projects &&
          group.group_data.projects.length > 0
        ) {
          group.group_data.projects =
            group.group_data.projects.filter(onlyUnique);
        }
      }
      let saveGroup = Object.assign({}, group);

      if (saveGroup.group_id) {
        const originalGroup =
          Array.isArray(originalGroups) &&
          originalGroups.filter((og) => og.group_id == group.group_id);
        if (originalGroup.length > 0) {
          //console.log(JSON.stringify(saveGroup['group_data']), JSON.stringify(originalGroup[0]['group_data']))
          if (
            JSON.stringify(saveGroup["group_data"]) ==
              JSON.stringify(originalGroup[0]["group_data"]) &&
            saveGroup["group_name"] == originalGroup[0]["group_name"]
            && saveGroup['notify'] == originalGroup[0]['notify']
          ) {
            return false;
          }
        }
      }
      saveGroup["user"] = user;
      saveGroup["group_data"] = JSON.stringify(saveGroup["group_data"]);
      saveGroup["source"] = "PIPELINE-APP";
      delete saveGroup["edit"];
      return saveGroup;
    });
    saveData = saveData.filter((g) => g !== false);
    console.log(saveData);
    //return false;
    let apiData = {
      url: apiUrl + "groups/save",
      method: "post",
      query: saveData,
    };

    _axiosCall(apiData).then((res) => {
      console.log(res);
      if (res && res.result && res.result) {
        this.snackOpen(
          <span>
            Success, go to{" "}
            <a
              href="/my-collections"
              style={{
                fontWeight: 500,
                color: "#FFF",
                textDecoration: "underline",
              }}
            >
              My Collections
            </a>{" "}
            to share and manage this collection.{" "}
          </span>,
          "success"
        );
        if (toggleRefresh) {
          setTimeout(() => {
            toggleRefresh();
            this.setState({ modal: false });
          }, 2000);
        } else {
          this.getCollections();
          setTimeout(() => {
            this.setState({ modal: false });
          }, 2000);
        }
      } else if (res && res.result && res.result.error) {
        if (res.result.message && res.result.message == "link_exists") {
          this.snackOpen(`Something went wrong, try again.`, "error");
        }
      }
    });
    // addMyBuyersList(buildingKey, priority);
    // this.setState({ buttonText: 'Building Saved'})
  }

  handleDelete() {
    // event.preventDefault();

    const {
      authState,
      authService,
      dispatch,
      user,
      formValues,
      buildingRes,
      property,
      listing,
      group,
      toggleRefresh,
    } = this.props;

    const saveData = {
      group_id: group.group_id,
      user: user,
      deleted: true,
    };

    let apiData = {
      url: apiUrl + "group/save",
      method: "post",
      query: saveData,
    };

    _axiosCall(apiData).then((res) => {
      console.log(res);
      if (res && res.result && res.result.group_id) {
        this.snackOpen("Your Collection Has Been Deleted.", "success");
        if (toggleRefresh) {
          setTimeout(() => {
            toggleRefresh();
            this.setState({ deleteModal: false });
          }, 2000);
        } else {
          this.getCollections();
        }
      } else if (res && res.result && res.result.error) {
        this.snackOpen(`Delete error, please try again.`, "error");
      }
    });
    // addMyBuyersList(buildingKey, priority);
    // this.setState({ buttonText: 'Building Saved'})
  }

  handleRemoveItems() {
    // event.preventDefault();

    const {
      authState,
      authService,
      dispatch,
      user,
      formValues,
      buildingRes,
      property,
      listing,
      group,
      toggleRefresh,
      selectedUnits,
      groupType,
    } = this.props;

    let saveGroup = JSON.parse(JSON.stringify(group));

    if (selectedUnits && Object.keys(selectedUnits)) {
      if (saveGroup["group_data"]) {
        if (saveGroup["group_data"]["projects"]) {
          saveGroup["group_data"]["projects"] = saveGroup["group_data"][
            "projects"
          ].filter((key) => Object.keys(selectedUnits).indexOf(key) < 0);
        }
        if (saveGroup["group_data"]["buildings"]) {
          saveGroup["group_data"]["buildings"] = saveGroup["group_data"][
            "buildings"
          ].filter((key) => Object.keys(selectedUnits).indexOf(key) < 0);
        }
      }
    }
    //console.log(saveGroup);
    //return false;
    saveGroup["user"] = user;
    saveGroup["group_data"] = JSON.stringify(saveGroup["group_data"]);
    let apiData = {
      url: apiUrl + "group/save",
      method: "post",
      query: saveGroup,
    };

    _axiosCall(apiData).then((res) => {
      if (res && res.result && res.result.group_id) {
        this.snackOpen(
          `${Object.keys(selectedUnits).length} ${
            groupType == "projects" ? "project" : "building"
          }${
            Object.keys(selectedUnits).length !== 1 ? "s" : ""
          } removed from this collection`,
          "success"
        );
        if (toggleRefresh) {
          setTimeout(() => {
            toggleRefresh();
            this.setState({ deleteModal: false });
          }, 2000);
        } else {
          this.getCollections();
        }
      } else if (res && res.result && res.result.error) {
        this.snackOpen(`Remove error, please try again.`, "error");
      }
    });
    // addMyBuyersList(buildingKey, priority);
    // this.setState({ buttonText: 'Building Saved'})
  }

  getCollections() {
    const {
      authState,
      authService,
      dispatch,
      user,
      formValues,
      buildingRes,
      property,
      listing,
    } = this.props;
    const { link_id } = this.state;

    let query = {
      user_key: user.id,
      deleted: { $exists: false },
      source: "PIPELINE-APP",
    };

    let apiData = {
      url: apiUrl + "groups/query",
      method: "post",
      query: {
        query: query,
      },
    };

    _axiosCall(apiData).then((res) => {
      if (res && res.numFound > 0) {
        this.setState({
          groups: res.docs,
          originalGroups: res.docs.map((og) => JSON.parse(JSON.stringify(og))),
        });
      } else {
        const firstGroup = [
          {
            group_name: "My First Collection",
            group_data: {
              buildings: [],
              projects: [],
            },
            edit: true,
          },
        ];
        if (property) {
          firstGroup[0]["group_data"]["buildings"].push(property.key);
        } else if (buildingRes) {
          firstGroup[0]["group_data"]["projects"].push(buildingRes.key);
        }
        this.setState({ groups: firstGroup, originalGroups: [] });
      }
    });
  }
  snackOpen = (message, type) => {
    this.setState({ snackShow: true, snackMessage: message, snackType: type });
  };

  snackClose = (event, reason) => {
    event && event.stopPropagation();
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackShow: false, snackMessage: "" });
  };

  updateGroups = (groups) => {
    this.setState({ groups: groups });
  };

  toggle(event) {
    event && event.stopPropagation();
    const { buildingRes, property, toggleRefresh, report, add } = this.props;

    const { modal } = this.state;

    if (modal && report) {
      toggleRefresh();
    }

    if (!modal && !add) {
      this.getCollections();
    }
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  toggleDelete(event) {
    event && event.stopPropagation();
    const { buildingRes, property, toggleRefresh, report } = this.props;

    const { deleteModal } = this.state;

    if (deleteModal && report) {
      toggleRefresh();
    }
    this.setState((prevState) => ({ deleteModal: !prevState.deleteModal }));
  }

  toggleAdd() {
    const { buildingRes, property, addMultiple, selectedUnits } = this.props;
    const { groups } = this.state;
    //this.setState(prevState => ({ toggleAdd: !prevState.toggleAdd }));
    let newGroupState = groups.slice();
    const addGroup = Object.assign({}, newGroup);

    if (addMultiple) {
      Object.keys(selectedUnits).map((key) => {
        const item = selectedUnits[key];

        if (item.address_2_normalized !== undefined) {
          addGroup["group_data"]["buildings"].push(item.key);
        } else {
          addGroup["group_data"]["projects"].push(item.key);
        }
      });
    } else {
      if (property) {
        addGroup["group_data"]["buildings"].push(property.key);
      } else if (buildingRes) {
        addGroup["group_data"]["projects"].push(buildingRes.key);
      }
    }
    newGroupState.unshift(addGroup);
    this.setState({ groups: newGroupState });
  }

  generateShareBase = () => {
    //let shareBaseURL = `https://newdev.marketproof.com/share/`
    let shareBaseURL =
      window.location.protocol + "//" + window.location.host + "/share/";

    return shareBaseURL;
  };
  generateShareLink = () => {
    const {
      buildingRes,
      property,
      formValues,
      initialValues,
      generateShareBase,
    } = this.props;

    let shareBaseURL = this.generateShareBase();

    if (formValues && formValues.customLink) {
      return shareBaseURL + formValues.customLink;
    } else if (initialValues && initialValues.customLink) {
      return shareBaseURL + initialValues.customLink;
    }

    return shareBaseURL; // + formValues.customLink;
  };

  render() {
    const {
      actions,
      t,
      theme,
      rtl,
      buildingRes,
      user,
      property,
      listing,
      report,
      shareLink,
      edit,
      group,
      add,
      removeSelectFromGroup,
      selectedUnits,
      groupType,
      addMultiple,
      clearSelected,
    } = this.props;

    const {
      modal,
      snackShow,
      snackMessage,
      saved,
      snackType,
      deleteModal,
      toggleAdd,
      groups,
    } = this.state;
    
    if(!_hasPermission(user, "collections")) return null;

    const upgradeMessage = (
      <div>
        <span>
          Access to Collections requires an upgrade to your subscription.
        </span>
        <p>
          <a
            href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo"
            style={{ color: "#780F9E", fontSize: 16 }}
            target="_blank"
          >
            Learn about Collections{" "}
            <OpenInNewIcon size={16} style={{ marginTop: -2 }} />
          </a>
        </p>
      </div>
    );

    if (addMultiple) {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />
          {Object.keys(selectedUnits).length > 0 && (
            <div
              style={{
                position: "fixed",
                bottom: 50,
                right:
                  selectedUnits && Object.keys(selectedUnits).length > 1 && user && user.role.indexOf('admin') >= 0 
                    ? 338
                    : selectedUnits && Object.keys(selectedUnits).length > 1 ?
                    131
                    : 50,
                zIndex: 9999,
              }}
            >
              <Tooltip title={`Add Selected to Collection`}>
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  aria-label="add"
                  style={{ fontWeight: 400, background: "#780F9E" }}
                  onClick={this.toggle}
                >
                  <AddListIcon style={{ marginRight: 5 }} /> {` `}Add to
                  Collection ({Object.keys(selectedUnits).length})
                </Fab>
              </Tooltip>
              {selectedUnits && Object.keys(selectedUnits).length === 1 && (
                <Tooltip title={`Remove All Selected from Collection`}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="secondary"
                    aria-label="add"
                    style={{
                      fontWeight: 400,
                      marginLeft: "5px",
                    }}
                    onClick={clearSelected}
                  >
                    Clear
                  </Fab>
                </Tooltip>
              )}
            </div>
          )}
          {!_hasPermission(user, "collections") ? (
            <ChangePlan
              modal={modal}
              setModal={this.toggle}
              changePlan={"complete-199"}
              message={upgradeMessage}
            />
          ) : (
            <Fragment>
              <Modal
                isOpen={modal}
                toggle={this.toggle}
                modalClassName={`${rtl.direction}-support`}
                className={`modal-dialog contact__modal share__modal collection__modal`}
              >
                <div className="modal__header">
                  <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={this.toggle}
                  />
                </div>
                <Row>
                  <Col xl={12} lg={12} md={12}>
                    <div className="modal__body background_white">
                      <h4 className="contact__title">
                        Add {Object.keys(selectedUnits).length}{" "}
                        {groupType == "projects" ? "Project" : "Building"}
                        {Object.keys(selectedUnits).length !== 1 ? "s" : ""} to
                        Collection
                      </h4>
                      <hr />

                      <Fragment>
                        <p className="help-text mb-3">
                          Select an one or more collections or create a new
                          collection.
                        </p>

                        <div
                          className="add-collection"
                          onClick={this.toggleAdd}
                        >
                          <AddIcon color={"#9600BF"} size={20} /> Create a New
                          Collection
                        </div>
                        <h5 className="contact__title mt-4">My Collections</h5>
                        <hr className="mb-2" />
                        <CollectionList
                          {...this.props}
                          groups={groups}
                          updateGroups={this.updateGroups}
                        />
                      </Fragment>

                      <br />
                      <br />
                      <div
                        style={{
                          paddingBottom: 10,
                          marginBottom: 20,
                          textAlign: "right",
                          borderBottom: "1px solid #DBE0E8",
                        }}
                      >
                        What can I do with collections?{" "}
                        <a
                          href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo"
                          target="_blank"
                          className="link-text"
                        >
                          Learn More{" "}
                          <HelpCircleOutlineIcon
                            size={12}
                            style={{ marginTop: "-3px" }}
                          />
                        </a>
                      </div>
                      <ButtonToolbar className="modal__footer">
                        {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
                        <Tooltip title="Cancel">
                          <Button
                            onClick={this.toggle}
                            variant="outlined"
                            color=""
                            className=""
                            startIcon={null}
                          >
                            {" "}
                            Cancel
                          </Button>
                        </Tooltip>{" "}
                        <Tooltip title="Add to Collection">
                          <Button
                            onClick={this.handleSave}
                            variant="contained"
                            color="primary"
                            className="buyersList__primary-button"
                            startIcon={null}
                          >
                            {" "}
                            {toggleAdd ? "Add New Collection" : "Save"}
                          </Button>
                        </Tooltip>{" "}
                      </ButtonToolbar>
                    </div>
                  </Col>
                </Row>
              </Modal>
            </Fragment>
          )}
        </Fragment>
      );
    }

    if (removeSelectFromGroup) {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />
          <div style={{ height: 50 }}>
            {Object.keys(selectedUnits).length > 0 && (
              <Tooltip title="Remove From This Collection">
                <IconButton
                  className="material-table__toolbar-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleDelete();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>

          <Fragment>
            <Modal
              isOpen={deleteModal}
              toggle={this.toggleDelete}
              modalClassName={`${rtl.direction}-support`}
              className={`modal-dialog contact__modal share__modal collection__modal`}
            >
              <div className="modal__header">
                <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  onClick={this.toggleDelete}
                />
              </div>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div className="modal__body background_white">
                    <h4 className="contact__title">Remove From Collection</h4>

                    <hr />
                    <div style={{ fontSize: 16 }} className="mb-3">
                      <p>
                        You will remove {Object.keys(selectedUnits).length}{" "}
                        {groupType == "projects" ? "project" : "building"}
                        {Object.keys(selectedUnits).length !== 1
                          ? "s"
                          : ""}{" "}
                        from this collection.
                      </p>
                      <p>Are you sure you want to continue?</p>
                    </div>
                    <hr />
                    {/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}
                    <ButtonToolbar className="modal__footer">
                      {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
                      <Tooltip title="Cancel">
                        <Button
                          onClick={this.toggleDelete}
                          variant="outlined"
                          color=""
                          className=""
                          startIcon={null}
                        >
                          {" "}
                          Cancel
                        </Button>
                      </Tooltip>{" "}
                      <Tooltip title="Confirm Removal">
                        <Button
                          onClick={this.handleRemoveItems}
                          variant="contained"
                          color="secondary"
                          className="buyersList__primary-button"
                          startIcon={<DeleteIcon />}
                        >
                          {" "}
                          Remove
                        </Button>
                      </Tooltip>{" "}
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Modal>
          </Fragment>
        </Fragment>
      );
    }

    if (add) {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />

          <Tooltip title={`Add a New Group`}>
            <Button
              onClick={this.toggle}
              variant="contained"
              color="primary"
              className="buyersList__secondary-button"
              startIcon={<PlusIcon />}
            >
              {" "}
              Add Collection
            </Button>
          </Tooltip>

          <Fragment>
            <Modal
              isOpen={modal}
              toggle={this.toggle}
              modalClassName={`${rtl.direction}-support`}
              className={`modal-dialog contact__modal share__modal collection__modal`}
            >
              <div className="modal__header">
                <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  onClick={this.toggle}
                />
              </div>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div className="modal__body background_white">
                    <h4 className="contact__title">Add a New Collection</h4>

                    <hr />
                    <Fragment>
                      <h5 className="contact__title mt-4">My Collections</h5>
                      <hr className="mb-2" />
                      <CollectionList
                        {...this.props}
                        groups={groups}
                        updateGroups={this.updateGroups}
                      />
                    </Fragment>

                    <br />
                    <br />
                    <div
                      style={{
                        paddingBottom: 10,
                        marginBottom: 20,
                        textAlign: "right",
                        borderBottom: "1px solid #DBE0E8",
                      }}
                    >
                      What can I do with collections?{" "}
                      <a
                        href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo"
                        target="_blank"
                        className="link-text"
                      >
                        Learn More{" "}
                        <HelpCircleOutlineIcon
                          size={12}
                          style={{ marginTop: "-3px" }}
                        />
                      </a>
                    </div>
                    <ButtonToolbar className="modal__footer">
                      {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
                      <Tooltip title="Cancel">
                        <Button
                          onClick={this.toggle}
                          variant="outlined"
                          color=""
                          className=""
                          startIcon={null}
                        >
                          {" "}
                          Cancel
                        </Button>
                      </Tooltip>{" "}
                      <Tooltip title="Save to Group">
                        <Button
                          onClick={this.handleSave}
                          variant="contained"
                          color="primary"
                          className="buyersList__primary-button"
                          startIcon={null}
                        >
                          {" "}
                          {toggleAdd ? "Add New Collection" : "Save"}
                        </Button>
                      </Tooltip>{" "}
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Modal>
          </Fragment>
        </Fragment>
      );
    }

    if (edit) {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />
          {group.notify ?
          <Tooltip title="Change Notification Settings">
            <IconButton
              className="material-table__toolbar-button"
              onClick={(e) => {
                e.stopPropagation();
                this.toggle();
              }}
            >
              <NotifyOnIcon />
            </IconButton>
          </Tooltip>
          :
          <Tooltip title="Change Notification Settings">
            <IconButton
              className="material-table__toolbar-button"
              onClick={(e) => {
                e.stopPropagation();
                this.toggle();
              }}
            >
              <NotifyOffIcon />
            </IconButton>
          </Tooltip>
          }
          <Tooltip title="Edit This Collection">
            <IconButton
              className="material-table__toolbar-button"
              onClick={(e) => {
                e.stopPropagation();
                this.toggle();
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete This Collection">
            <IconButton
              className="material-table__toolbar-button"
              onClick={(e) => {
                e.stopPropagation();
                this.toggleDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Modal
            isOpen={modal}
            toggle={this.toggle}
            modalClassName={`${rtl.direction}-support`}
            className={`modal-dialog contact__modal share__modal collection__modal`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggle}
              />
            </div>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="modal__body background_white">
                  <h4 className="contact__title">Edit Collection</h4>

                  <hr />
                  <Fragment>
                    <h5 className="contact__title mt-4">My Collections</h5>
                    <hr className="mb-2" />
                    <CollectionList
                      {...this.props}
                      groups={[group]}
                      updateGroups={this.updateGroups}
                      edit
                    />
                  </Fragment>

                  <br />
                  <br />
                  <div
                    style={{
                      paddingBottom: 10,
                      marginBottom: 20,
                      textAlign: "right",
                      borderBottom: "1px solid #DBE0E8",
                    }}
                  >
                    What can I do with collections?{" "}
                    <a
                      href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo"
                      target="_blank"
                      className="link-text"
                    >
                      Learn More{" "}
                      <HelpCircleOutlineIcon
                        size={12}
                        style={{ marginTop: "-3px" }}
                      />
                    </a>
                  </div>
                  <ButtonToolbar className="modal__footer">
                    {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
                    <Tooltip title="Cancel">
                      <Button
                        onClick={this.toggle}
                        variant="outlined"
                        color=""
                        className=""
                        startIcon={null}
                      >
                        {" "}
                        Cancel
                      </Button>
                    </Tooltip>{" "}
                    <Tooltip title="Add to Collection">
                      <Button
                        onClick={this.handleSave}
                        variant="contained"
                        color="primary"
                        className="buyersList__primary-button"
                        startIcon={null}
                      >
                        {" "}
                        {toggleAdd ? "Add New Collection" : "Save"}
                      </Button>
                    </Tooltip>{" "}
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
          </Modal>
          <Modal
            isOpen={deleteModal}
            toggle={this.toggleDelete}
            modalClassName={`${rtl.direction}-support`}
            className={`modal-dialog contact__modal share__modal collection__modal`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggleDelete}
              />
            </div>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="modal__body background_white">
                  <h4 className="contact__title">
                    Delete Collection: {group.group_name}
                  </h4>

                  <hr />
                  <div style={{ fontSize: 16 }} className="mb-3">
                    <p>Properties added to this collection will be lost.</p>
                    <p>Are you sure you want to delete this collection?</p>
                  </div>
                  <hr />
                  {/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}
                  <ButtonToolbar className="modal__footer">
                    {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
                    <Tooltip title="Cancel">
                      <Button
                        onClick={this.toggleDelete}
                        variant="outlined"
                        color=""
                        className=""
                        startIcon={null}
                      >
                        {" "}
                        Cancel
                      </Button>
                    </Tooltip>{" "}
                    <Tooltip title="Confirm Delete of Report">
                      <Button
                        onClick={this.handleDelete}
                        variant="contained"
                        color="secondary"
                        className="buyersList__primary-button"
                        startIcon={<DeleteIcon />}
                      >
                        {" "}
                        Delete
                      </Button>
                    </Tooltip>{" "}
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
          </Modal>
        </Fragment>
      );
    }

    let buildingNameOrAdr = false;
    let adr = false;
    if (buildingRes) {
      buildingNameOrAdr = buildingRes.name
        ? _capitalizeText(buildingRes.name)
        : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
      adr = buildingRes.display_full_street_address
        ? buildingRes.display_full_street_address
        : buildingRes.full_street_address;
    }

    return (
      <Fragment>
        <SnackBar
          snackShow={snackShow}
          snackMessage={snackMessage}
          snackClose={this.snackClose}
          snackDuration={5000}
          snackType={snackType}
        />

        <Tooltip
          title={`Add This ${property ? "Unit" : "Project"} to a Collection`}
        >
          <Button
            onClick={this.toggle}
            variant="contained"
            color="secondary"
            className="buyersList__secondary-button"
            startIcon={<AddListIcon />}
          >
            {" "}
            Add
          </Button>
        </Tooltip>

        {!_hasPermission(user, "collections") ? (
          <ChangePlan
            modal={modal}
            setModal={this.toggle}
            changePlan={"complete-199"}
            message={upgradeMessage}
          />
        ) : (
          <Fragment>
            <Modal
              isOpen={modal}
              toggle={this.toggle}
              modalClassName={`${rtl.direction}-support`}
              className={`modal-dialog contact__modal share__modal collection__modal`}
            >
              <div className="modal__header">
                <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  onClick={this.toggle}
                />
              </div>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div className="modal__body background_white">
                    <h4 className="contact__title">
                      Add {property ? "Unit" : "Building"} to Collection
                    </h4>
                    <h5 className="contact__sub-title">
                      {buildingNameOrAdr}
                      {property && (
                        <span>
                          {" "}
                          - {_formatUnitNumber(property.address_2_normalized)}
                        </span>
                      )}
                    </h5>
                    {property && (
                      <h5 className="contact__sub-title">
                        {property.offering_price &&
                        property.offering_price > 0 ? (
                          <Fragment>
                            ${_formatNumber(property.offering_price)}
                            <span className="small-divider">|</span>
                          </Fragment>
                        ) : null}

                        {property.bedrooms >= 0 && property.baths > 0 && (
                          <Fragment>
                            {property.bedrooms === 0
                              ? "Studio"
                              : property.bedrooms}
                            <span className="sub-text">
                              {property.bedrooms === 0
                                ? ""
                                : property.bedrooms === 1
                                ? " Bed"
                                : " Beds"}
                            </span>
                            <span className="small-divider">|</span>
                            {property.baths}
                            <span className="sub-text">
                              {property.baths === 1 ? " Bath" : " Baths"}
                            </span>
                            <span className="small-divider">|</span>
                          </Fragment>
                        )}
                        {_formatNumber(property.sq_ft)}
                        <span className="sub-text"> Sq Ft</span>
                      </h5>
                    )}

                    <hr />

                    <Fragment>
                      <p className="help-text mb-3">
                        Select an one or more collections or create a new
                        collection.
                      </p>
                      <div className="add-collection" onClick={this.toggleAdd}>
                        <AddIcon color={"#9600BF"} size={20} /> Create a New
                        Collection
                      </div>
                      <h5 className="contact__title mt-4">My Collections</h5>
                      <hr className="mb-2" />
                      <CollectionList
                        {...this.props}
                        groups={groups}
                        updateGroups={this.updateGroups}
                      />
                    </Fragment>

                    <br />
                    <br />
                    <div
                      style={{
                        paddingBottom: 10,
                        marginBottom: 20,
                        textAlign: "right",
                        borderBottom: "1px solid #DBE0E8",
                      }}
                    >
                      What can I do with collections?{" "}
                      <a
                        href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo"
                        target="_blank"
                        className="link-text"
                      >
                        Learn More{" "}
                        <HelpCircleOutlineIcon
                          size={12}
                          style={{ marginTop: "-3px" }}
                        />
                      </a>
                    </div>
                    <ButtonToolbar className="modal__footer">
                      {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
                      <Tooltip title="Cancel">
                        <Button
                          onClick={this.toggle}
                          variant="outlined"
                          color=""
                          className=""
                          startIcon={null}
                        >
                          {" "}
                          Cancel
                        </Button>
                      </Tooltip>{" "}
                      <Tooltip title="Add to Collection">
                        <Button
                          onClick={this.handleSave}
                          variant="contained"
                          color="primary"
                          className="buyersList__primary-button"
                          startIcon={null}
                        >
                          {" "}
                          {toggleAdd ? "Add New Collection" : "Save"}
                        </Button>
                      </Tooltip>{" "}
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Modal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    formValues: getFormValues("share_form")(state),
    rtl: state.rtl,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(AddCollectionButton));
