import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col, Badge } from "reactstrap";
import MatTableFilterButton from "./MatTableFilterButton";
import MatTableView from "./MatTableView";
import MatGridView from "./MatGridView";
import MatMapView from "./MatMapView";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { Button as ReactButton } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";

import MatAppBar from "../../../shared/components/building_filters/UnitsSearchAppBar";
import LinearLoading from "../../../shared/components/LinearLoading";
import EditProject from "../../../shared/components/edit/EditProject";
import MergeProjectsButton from "../../../shared/components/buttons/MergeProjectsButton";
import AddCollectionButton from "../../../shared/components/collections/AddCollectionButton";

import { isMobile, isTablet } from "react-device-detect";

import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import {
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	GenerateBuildingSlug,
	GenerateBuildingKey,
	_tranformTagsMongoQuery,
	_fixGoogleImage,
	_tranformTagsMongoAtlasQuery
} from "../../../shared/helpers/utils";

import {
	_buildingAdminFilters,
	_buildingStageFilter,
} from "../../../shared/helpers/filters";

const buildingUrl = process.env.API_ROOT + "/pipeline/";

const subFilterKey = "subFilters";
const buildingStagesKey = "buildingStages";
const defaultSubFilter = {};
const defaultStage = ["all"];

const getSubFilters = ({ stage, match }) => {
	const type = match.params.type ? match.params.type : "residential";

	let filters = _buildingAdminFilters.filter(
		(filter) =>
			!(filter.exclude_stage && filter.exclude_stage.indexOf(stage) >= 0)
	);

	if (type === "commercial") {
		filters = filters.filter(
			(filter) => !(filter.type && filter.type === "residential")
		);
	} else {
		filters = filters.filter(
			(filter) => !(filter.type && filter.type === "commercial")
		);
	}
	return filters;
};

class MatTable extends PureComponent {
	lsPre = `PIPE_DB_`;
	buildingSubFilters = getSubFilters(this.props);

	state = {
		order: this.props.isSingle
			? "desc"
			: syncGenericFilter("order", this.lsPre, "order", "desc"),
		orderBy: this.props.isSingle
			? "pipeline_filing_date"
			: syncGenericFilter(
				"order_by",
				this.lsPre,
				"orderBy",
				"pipeline_dwelling_units_proposed"
			),
		selected: new Map([]),
		page: 0,
		rowsPerPage: this.props.isSingle ? 8 : 40,
		buildingsFetchInProgress: false,
		clearInput: false,
		end: 30,
		filterBorough: "all_boroughs",
		// filterFetchInProgress: false,
		filterStage: "all_projects",
		futureInventoryData: false,
		// headerHeight: 'auto',
		inventoryData: false,
		originalData: false,
		ovelayMinHeight: false,
		reactTableFiltered: [],
		// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
		showCounts: { desktop: 3, tablet: 2, mobile: 1 },
		start: 0,
		stats: {},
		numeric: false,
		subFilters: syncURLFilters(
			this.props,
			this.buildingSubFilters,
			subFilterKey,
			this.lsPre,
			defaultSubFilter
		),
		tableData: false,
		totalUnsoldUnits: false,
		searchText: syncURLSearch(this.props, "searchText"),
		viewType: syncGenericFilter("viewType", "viewType", this.lsPre, "grid"),
		facetData: null,
		buildingStages: syncStageFilters(
			this.props,
			_buildingStageFilter,
			buildingStagesKey,
			this.lsPre,
			defaultStage
		),
		type:
			this.props.match && this.props.match.params.type
				? this.props.match.params.type
				: "residential",
		selectedItems: {},
	};

	q = "";
	fq = "";
	sort = "";

	componentDidMount() {
		//this._getInventoryStats();
		this._getPipelineBuildings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let searchTagsChange = !_isEqual(
			prevProps.searchTags,
			this.props.searchTags
		);

		let buildingStageChange = !_isEqual(
			prevState.buildingStages,
			this.state.buildingStages
		);
		let subFilterChanged =
			this.buildingSubFilters.filter((item) => {
				return (
					this.state.subFilters[item.search_key] !==
					prevState.subFilters[item.search_key]
				);
			}).length > 0;
		let multiSubFilterChanged =
			this.buildingSubFilters.filter((item) => {
				return (
					this.state.subFilters[item.search_key && item.search_key[0]] !==
					prevState.subFilters[item.search_key && item.search_key[0]]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			searchTagsChange ||
			subFilterChanged ||
			buildingStageChange ||
			multiSubFilterChanged ||
			this.state.numeric !== prevState.numeric
			// ||
			// Object.keys( this.state.subFilters ).length
		) {
			const resetPage = this.state.page === prevState.page;
			console.log("component update", prevState, this.state);
			this._getPipelineBuildings();
		}
	}

	handleRequestSort = (event, property, setOrder) => {
		const orderBy = property;
		let order = setOrder ? setOrder : "desc";
		const { orderBy: stateOrderBy, order: stateOrder } = this.state;

		if (stateOrderBy === property && stateOrder === "desc" && !setOrder) {
			order = "asc";
		}

		this.setState({ order, orderBy });
	};

	handleSelectAllClick = (event, checked) => {
		if (checked) {
			const { tableData } = this.state;
			const newSelected = new Map();
			tableData.map((n) => newSelected.set(n.id, true));
			this.setState({ selected: newSelected });
			return;
		}
		this.setState({ selected: new Map([]) });
	};

	handleClick = (event, id) => {
		event.stopPropagation();
		const { selected } = this.state;
		const newSelected = new Map(selected);
		const value = newSelected.get(id);
		let isActive = true;
		if (value) {
			isActive = false;
		}
		newSelected.set(id, isActive);
		this.setState({ selected: newSelected });
	};

	shouldLinkToMP = (building) => {
		const { user } = this.props;
		return (
			(user.role &&
				Array.isArray(user.role) &&
				user.role.indexOf("admin") >= 0) ||
			[
				"active",
				"coming soon",
				"offering plan required",
				"offering plan acquired",
				"condo declaration vetted",
			].indexOf(building.offering_verified_status) < 0
		);
	};

	handleBuildingClick = (event, building) => {
		this.handleBuildingPreviewClick(event, building);

		/*
				 if(this.shouldLinkToMP(building)){
					 const buildingURL = _getBuildingURL(building, 'building_slugs');	 
					 window.open(`https://marketproof.com${buildingURL.replace('buildings', 'building')}?tab=overview`)
				 }else{
					 this.handleBuildingPreviewClick(event, building);
			
				 }
			*/

		//this.props.history.push(buildingURL);
	};

	handleBuildingPreviewClick = (event, building) => {
		event.preventDefault();
		event.stopPropagation();
		const buildingURL = _getBuildingURL(building, "_id");
		this.props.history.push(buildingURL);
		//window.open(buildingURL);
	};

	handleChangePage = (event, page) => {
		this.setState({ page: page, start: page * this.state.rowsPerPage });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0, start: 0 });
	};

	handleDeleteSelected = () => {
		const { tableData } = this.state;
		let copyData = [...tableData];
		const { selected } = this.state;

		for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
			copyData = copyData.filter((obj) => obj.id !== selected[i]);
		}

		this.setState({ tableData: copyData, selected: new Map([]) });
	};

	isSelected = (id) => {
		const { selected } = this.state;
		return !!selected.get(id);
	};

	_addFilterQuery = (min, max, query_key, searchFilters) => {
		let qVal = {};
		if (min && max) {
			qVal = { gte: min, lte: max };
		} else if (min && !max) {
			qVal = { gte: min };
		} else if (!min && max) {
			qVal = { lte: max };
		}
		if (Object.keys(qVal).length > 0) {

			searchFilters.push({ "range": Object.assign({}, { path: query_key }, qVal) });
		}
		return;
	};

	_getSpecificFilter = (key) => {
		let filter =
			this &&
			this.buildingSubFilters &&
			this.buildingSubFilters.filter(
				(filter) =>
					filter.search_key === key ||
					(filter.search_key && filter.search_key.includes(key))
			)[0];
		return filter;
	};

	_getPipelineBuildings = async (query, resetPage, obj) => {
		const { user, searchTags, organizationRes, contactRes, isSingle } =
			this.props;
		const { buildingStages, type, start, rowsPerPage, page, orderBy, order } =
			this.state;
		if (!this.state.buildingsFetchInProgress) {
			this.setState((prevState) => ({ buildingsFetchInProgress: true }));
		}

		let searchFilters = [{
			"compound": {
				"should": [
					{
						"compound": {
							"mustNot": [
								{
									"queryString": {
										"defaultPath": "source_provider",
										"query": "mpnd"
									}
								}
							]
						}
					},
					{
						"queryString": {
							"defaultPath": "source_provider",
							"query": "permits AND building_status:(NOT resale)"
						}
					},
					{
						"queryString": {
							"defaultPath": "building_status",
							"query": "sponsor OR pipeline"
						}
					}
				]
			}
		}];

		/*
		{
			"queryString": {
				"defaultPath": "source_provider",
				"query": "source_provider:(NOT mpnd) OR (source_provider:permits AND building_status:(NOT resale)) OR (building_status:(sponsor OR pipeline))"
			}
		}
		*/

		let baseQuery = false;
		if (organizationRes && Array.isArray(organizationRes.projects) && organizationRes.projects.length > 0 ) {
			baseQuery = { queryString: { defaultPath: "key", query: `("${organizationRes.projects.map((p) => p && p.key).join('" OR "')}")` } };
		} else if (organizationRes && Array.isArray(organizationRes.project_keys) && organizationRes.project_keys.length > 0 ) {
			baseQuery = { queryString: { defaultPath: "key", query: `("${organizationRes.project_keys.join('" OR "')}")` } };
		} else if (contactRes && Array.isArray(contactRes.projects) && contactRes.projects.length > 0 ) {
			baseQuery = { queryString: { defaultPath: "key", query: `("${contactRes.projects.map((p) => p && p.key).join('" OR "')}")` } };
		} else if (contactRes && Array.isArray(contactRes.project_keys) && contactRes.project_keys.length > 0 ) {
			baseQuery = { queryString: { defaultPath: "key", query: `("${contactRes.project_keys.join('" OR "')}")` } };
		}

		if (baseQuery) {
			searchFilters.push(baseQuery);
		}
		let locQuery = _tranformTagsMongoAtlasQuery(searchTags);

		if (locQuery) {
			searchFilters.push(locQuery);
		}

		if (searchTags.filter((tag) => tag.type == "text").length > 0) {
			searchTags
				.filter((tag) => tag.type == "text")
				.map((tag) => {
					// let textQuery = {
					//   $and: tag.data.split(" ").map((token) => {
					//     return {
					//       $or: [
					//         {
					//           display_full_street_address: {
					//             $regex: `${token}`,
					//             $options: "i",
					//           },
					//         },
					//         { name: { $regex: `${token}`, $options: "i" } },
					//       ],
					//     };
					//   }),
					// };
					let textQuery = {
						compound: {
							should: [
								{ regex: { path: "display_full_street_address", query: tag.data, allowAnalyzedField: true } },
								{ regex: { path: "name", query: tag.data, allowAnalyzedField: true } },
							]
						}
					}
					searchFilters.push(textQuery);
				});
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map((key) => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const filter =
						this &&
						this.buildingSubFilters &&
						this.buildingSubFilters.filter(
							(filter) =>
								filter.search_key === key ||
								(filter.search_key && filter.search_key.includes(key))
						)[0];
					let result = [];
					let qVal = {};
					let search_query = [];

					if (filter && !filter.format_type) {
						const qVal1 = filter.values.filter(
							// (value) => value.filter === subFilterVal
							(value) =>
								subFilterVal.indexOf(value.filter) === -1 ? false : true
						);
						let multiFilters = [];
						qVal1.forEach((data) => {
							if (key === "pipeline_building_stories_proposed") {
								if (data.search_value) {
									search_query.push({ [key]: data.search_value });
								}
							}
							if (data && data.search_value && data.search_value[key])
								result.push(...data.search_value[key]["$in"]);
							if (data && data.search_value && filter.multi_filter) {
								console.log(data);
								//searchFilters.push(data.search_value);
								multiFilters.push(data.search_value);
							}
						});
						if (multiFilters.length > 0) {
							searchFilters.push({
								"compound": { "should": multiFilters }
							});
						}
					}

				}
			});
		}
		// for numeric & price filter queries
		let qVal = {};
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			let query_key,
				queryCount = {};
			Object.keys(this.state.subFilters).map((key) => {
				let subFilterVal = this.state.subFilters[key];

				const filter = this._getSpecificFilter(key);
				query_key = filter && filter.filter_key;

				if (filter && filter.format_type && !filter.date_filter) {
					queryCount[key] = parseInt(subFilterVal);
					if (query_key in queryCount) {
						queryCount[query_key] += 1;
					} else {
						queryCount[query_key] = 1;
					}
					if (queryCount[query_key] === 2) {
						this._addFilterQuery(
							queryCount[filter.search_key[0]], // add minimum value
							queryCount[[filter.search_key[1]]], // add maximum value
							query_key,
							searchFilters
						);
					}
				}
			});
		}

		// for date filter
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			let query_key,
				queryCount = {};
			Object.keys(this.state.subFilters).map((key) => {
				let subFilterVal = this.state.subFilters[key];

				const filter = this._getSpecificFilter(key);
				query_key = filter && filter.filter_key;
				if (filter && filter.date_filter) {
					queryCount[key] = subFilterVal;


					if (query_key in queryCount) {
						queryCount[query_key] += 1;
					} else {
						queryCount[query_key] = 1;
					}
					if (queryCount[query_key] === 2) {
						if (queryCount.min_date || queryCount.max_date) {
							this._addFilterQuery(
								queryCount.min_date && queryCount.min_date !== "0"
									? `ISODate:${moment(queryCount.min_date)
										.toDate()
										.toISOString()}`
									: false,
								queryCount.max_date && queryCount.max_date !== "0"
									? `ISODate:${moment(queryCount.max_date)
										.toDate()
										.toISOString()}`
									: false,
								query_key,
								searchFilters
							);
						}
						if (queryCount.min_offering_date || queryCount.max_offering_date) {
							this._addFilterQuery(
								queryCount.min_offering_date && queryCount.min_offering_date !== "0"
									? `ISODate:${moment(queryCount.min_offering_date)
										.toDate()
										.toISOString()}`
									: false,
								queryCount.max_offering_date && queryCount.max_offering_date !== "0"
									? `ISODate:${moment(queryCount.max_offering_date)
										.toDate()
										.toISOString()}`
									: false,
								query_key,
								searchFilters
							);
						}
					}
				}
			});
		}

		const buildingStageFilters = {
			"pre-development": { text: { path: "pipeline_project_stage", query: "planning" } },
			construction: { text: { path: "pipeline_project_stage", query: "construction" } },
			operation: { text: { path: "pipeline_project_stage", query: "completion" } }
		};

		if (buildingStages && buildingStages.length > 0) {
			let stageQ = [];
			if (buildingStages.indexOf("all") < 0) {
				buildingStages.map((stage) => {
					if (buildingStageFilters[stage]) {
						stageQ.push(buildingStageFilters[stage]);
					}

				});

				if (stageQ.length > 1) {
					searchFilters.push({ compound: { should: stageQ } });
				} else if (stageQ.length === 1) {
					searchFilters.push(stageQ[0]);
				}
			}
		}
		let sort = {};
		{
			/* 
		  if(["pipeline_dwelling_units_proposed", "pipeline_building_stories_proposed", "pipeline_total_construction_floor_area"].includes(orderBy)){
			let sortFilter = {};
			sortFilter[orderBy] = {"$gte" : 0 }
			searchFilters.push(sortFilter);
		  }else if(["pipeline_filing_date"].includes(orderBy)){
			let sortFilter = {};
			sortFilter[orderBy] = {"$gt" : "ISODate:1970-01-01" }
			searchFilters.push(sortFilter); 
		  }
		  */
		}

		sort[orderBy] = order === "asc" ? 1 : -1;
		if (!isSingle) {
			setURLFromState(this.props, this.state);
		}
		let mongoQuery = {
			query: { $and: searchFilters },
			projection: {
				_id: 1,
				name: 1,
				display_full_street_address: 1,
				address: 1,
				key: 1,
				image: 1,
				images: 1,
				meta: 1,
				pipeline_zoning_area_residential: 1,
				pipeline_total_construction_floor_area: 1,
				pipeline_zoning_area_commercial: 1,
				pipeline_building_stories_proposed: 1,
				pipeline_dwelling_units_proposed: 1,
				pipeline_hotel_units_proposed: 1,
				pipeline_filing_date: 1,
				pipeline_pre_filing_date: 1,
				pipeline_project_stage: 1,
				pipeline_coo_issuance_date: 1,
				pipeline_permit_issuance_job_start_date: 1,
				pipeline_permit_issuance_filing_date: 1,
				occupancy_type: 1,
				pipeline_occupancy_type: 1,
				pipeline_project_components: 1,
				date_added: 1,
				pipeline_occupancy_classification_proposed_code: 1,
				building_key: 1,
				project_stage: 1,
				pipeline_project_stalled: 1,
				pipeline_project_abandoned: 1,
				pipeline_permit_issuance_issuance_date: 1
			},
			sort: sort,
			limit: parseInt(rowsPerPage),
			skip: parseInt(start),
		};


		let atlasQuery = [
			{
				"$search": {
					"index": "default",
					"compound": {
						"mustNot": [
							{
								"exists": {
									"path": "meta.deleted"
								}
							}
						],
						"must": searchFilters
					},
					"sort": sort,
					"count": {
						"type": "total"
					}
				}
			},
			{
				"$project": {
					"search_meta": "$$SEARCH_META",
					"_id": 1,
					"name": 1,
					"display_full_street_address": 1,
					"address": 1,
					"key": 1,
					"image": 1,
					"images": 1,
					"meta": 1,
					"pipeline_zoning_area_residential": 1,
					"pipeline_total_construction_floor_area": 1,
					"pipeline_zoning_area_commercial": 1,
					"pipeline_building_stories_proposed": 1,
					"pipeline_dwelling_units_proposed": 1,
					"pipeline_hotel_units_proposed": 1,
					"pipeline_filing_date": 1,
					"pipeline_pre_filing_date": 1,
					"pipeline_project_stage": 1,
					"pipeline_coo_issuance_date": 1,
					"pipeline_permit_issuance_job_start_date": 1,
					"pipeline_permit_issuance_filing_date": 1,
					"occupancy_type": 1,
					"pipeline_occupancy_type": 1,
					"pipeline_project_components": 1,
					"date_added": 1,
					"pipeline_occupancy_classification_proposed_code": 1,
					"building_key": 1,
					"project_stage": 1,
					"pipeline_project_stalled": 1,
					"pipeline_project_abandoned": 1,
					"pipeline_permit_issuance_issuance_date": 1
				}
			},
			{
				"$skip": parseInt(start)
			},
			{
				"$limit": parseInt(rowsPerPage)
			}
		]

		let axiosData = {
			url: buildingUrl + "aggregate-search", // "query",
			method: "post",
			query: atlasQuery //mongoQuery,
		};

		_axiosCall(axiosData)
			.then((res) => {
				// res = _fixGoogleImage(res);
				let docs = [];
				if (res && res.length > 0) {
					docs = res;
				}
				docs.forEach((doc) => {
					//doc = Object.assign(doc, doc['attributes']);
					//delete(doc['attributes'])
					//doc = Object.assign(doc, doc['address']);
					//delete(doc['address']);
					doc["building_slugs"] = [GenerateBuildingSlug(doc)];
					doc["solr_key"] = doc.building_key
						? doc.building_key
						: GenerateBuildingKey(doc);
					doc["master_key"] = doc["key"];
				});
				this.setState((prevState) => ({
					buildingsFetchInProgress: false,
					tableData: docs,
					reactTableFiltered: docs,
					totalCount: res && res[0] && res[0]["search_meta"] && res[0]["search_meta"]["count"]["total"], //res[0].totalCount[0].key,
					start: resetPage ? 0 : start,
					page: resetPage ? 0 : page,
					stats: {},
					facetData: {},
					numeric: false,
				}));
			})
			.catch((error) => {
				console.log("error: " + error);
			});
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({ searchText: eValue });
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	__setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			numeric: true,
			end: this.state.rowPerPage,
		});

		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	};

	_filterResponseTableData = (eValue) => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter((item) => {
					// console.log("filterData item", item)
					const inputVal = eValue.toLowerCase();
					let nameVal, adrVal, hoodVal;
					if (item.name) {
						// console.log('item has name')
						nameVal = item.name.toLowerCase();
					}

					if (
						(item.display_full_street_address &&
							_shouldShow(item.display_full_street_address)) ||
						(item.full_street_address &&
							_shouldShow(item.full_street_address))
					) {
						const field = item.display_full_street_address
							? "display_full_street_address"
							: "full_street_address";
						// console.log('item has adr', field)

						adrVal = item[field].toLowerCase();
					}

					if (item.neighborhood && _shouldShow(item.neighborhood)) {
						const hood =
							Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
								? item.neighborhood[0]
								: item.neighborhood;
						hoodVal = hood.toLowerCase();
					}

					// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
					// searching name and ONE adr field - could update to search both display and full and all alt adrs
					return (
						(nameVal && nameVal.indexOf(inputVal) >= 0) ||
						(adrVal && adrVal.indexOf(inputVal) >= 0) ||
						(hoodVal && hoodVal.indexOf(inputVal) >= 0)
					);
				})
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState((prevState) => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
			//tableData: filteredData,
			//originalData: response,
			//filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value,
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			(item) => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter],
		});
	};

	_setSubFilter = (search_key, search_val) => {
		const subF = Object.assign({}, this.state.subFilters, {
			[search_key]: search_val,
		});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage,
		});

		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	};

	_setBuildingStage = (stage) => {
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);
		const indexAll = newStages.indexOf("all");

		if (index >= 0) {
			if (stage === "all") {
				newStages = ["sponsor"];
			} else {
				newStages.splice(index, 1);
			}
		} else {
			if (stage === "all") {
				newStages = ["all"];
			} else {
				if (indexAll >= 0) {
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage);
			}
		}
		if (newStages.length === 0) newStages = ["all"];
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage,
		});
		setLocalStorage(this.lsPre, buildingStagesKey, JSON.stringify(newStages));
	};

	_resetSubFilter = (search_key, search_val) => {
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages: defaultStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage,
		});
		deleteLocalStorage(this.lsPre, buildingStagesKey);
		deleteLocalStorage(this.lsPre, subFilterKey);
	};

	_resetActive = () => {
		let isDefault =
			this.props.searchTags.length === 0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultStage, this.state.buildingStages);
		return !isDefault;
	};

	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage,
		});
	};

	_toggleView = (type) => {
		this.setState({ viewType: type });
		setLocalStorage(this.lsPre, "viewType", type);
	};

	_selectUnit(property) {
		const { selectedItems } = this.state;
		const newSelected = Object.assign({}, selectedItems);

		if (newSelected[property.key]) {
			delete newSelected[property.key];
		} else {
			newSelected[property.key] = property;
		}

		this.setState({ selectedItems: Object.assign({}, newSelected) });
	}
	_clearSelect() {
		this.setState({ selectedItems: {} });
	}

	render() {
		const {
			data,
			order,
			orderBy,
			selected,
			rowsPerPage,
			page,
			tableData,
			reactTableFiltered,
			subFilters,
			totalCount,
			viewType,
			buildingsFetchInProgress,
			type,
			selectedItems,
		} = this.state;
		const { user, isSingle, min, toggle } = this.props;

		const tableCount = totalCount;

		if (min) {
			return (
				<div>
					<Row>
						<MatGridView
							{...this.state}
							{...this.props}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={this.handleChangeRowsPerPage}
							isSelected={this.isSelected}
							handleBuildingClick={this.handleBuildingClick}
							handleSelectAllClick={this.handleSelectAllClick}
							handleRequestSort={this.handleRequestSort}
							noPagination
							selectUnit={this._selectUnit.bind(this)}
						/>
					</Row>
					<Row>
						<Col xl={12} lg={12} className="text-center">
							<div className="timeline_load">
								<Tooltip title="See all projects">
									<ReactButton
										onClick={() => toggle("projects")}
										variant="contained"
										color="#780F9E"
										style={{ color: "#780F9E" }}
									>
										See All Projects
									</ReactButton>
								</Tooltip>
							</div>
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<Fragment>
				<AddCollectionButton
					selectedUnits={selectedItems}
					groupType={"projects"}
					clearSelected={this._clearSelect.bind(this)}
					{...this.props}
					addMultiple
				/>
				<MergeProjectsButton
					selectedItems={selectedItems}
					clearSelected={this._clearSelect.bind(this)}
					type={"projects"}
				/>
				{!isSingle && (
					<Row>
						<Col md={12} className={"mb-3 text-right"}>
							<EditProject
								buildingRes={{
									occupancy_type: { pipeline_occupancy_type: type },
								}}
								add
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col md={12} lg={12}>
						<Card>
							<CardBody className="p-0">
								{/*<div className="card__title">
		              <h5 className="bold-text">Select and Analyze</h5>
		            </div>*/}
								<MatAppBar
									handleSearch={this._filterTableByInput}
									numSelected={[...selected].filter((el) => el[1]).length}
									handleDeleteSelected={this.handleDeleteSelected}
									onRequestSort={this.handleRequestSort}
									subFilters={subFilters}
									resetSubFilter={this._resetSubFilter}
									buildingStages={this.state.buildingStages}
									setBuildingStage={this._setBuildingStage}
									setSubFilter={this._setSubFilter}
									_filters={
										user && user.role.indexOf("admin") >= 0
											? this.buildingSubFilters
											: this.buildingSubFilters.filter(
												(item) => !item.admin_only
											)
									}
									placeHolder={"Project, Address, Neighborhood or Borough"}
									buildingAttributes={[]}
									goToBuilding={true}
									hideBuilding={false}
									resetActive={this._resetActive()}
									type={type}
									q={this.q}
									fq={this.fq}
									fl={buildingExportFL}
									sort={this.sort}
									totalCount={totalCount}
									hideOrganizations
									hidePeople
									addProject={true}
									addBuilding={false}
									allowTextSearch
									setSubFilters={this.__setSubFilters}
								/>
								<div>
									{buildingsFetchInProgress ? (
										<LinearLoading />
									) : (
										<div style={{ height: isMobile ? 0 : 20 }} />
									)}
								</div>
								<div className="material-table__header-info">
									<Row>
										<Col md={6} lg={6}>
											<div
												className={"count_subheader"}
												style={{ marginTop: 10 }}
											>
												<span className="bold-text">
													{tableCount > 0 ? _formatNumber(tableCount) : "--"}
												</span>
												{` `}Projects
											</div>
										</Col>
										<Col md={6} lg={6} className="text-right">
											{/*<span>
								  
								  <GridViewIcon color="#DBE0E8" />		
						          <Tooltip title={`${this.state.gridView ? "Switch to List View" : "Switch to Grid View"}`}>
							        <PurpleSwitch 
							            checked={!this.state.gridView}
							        	onChange={this._toggleView}
							        />
							      </Tooltip>			
							      <ListViewIcon color="#DBE0E8" />		      	
							      		       
							    </span>*/}

											<ButtonGroup
												size="small"
												aria-label="small outlined button group"
											>
												<Tooltip title={`See projects in a photo grid`}>
													<Button
														disabled={viewType == "grid"}
														onClick={() => {
															this._toggleView("grid");
														}}
														startIcon={
															<GridViewIcon
																size={14}
																color={
																	viewType != "grid" ? "#780F9E" : "#A3ADC2"
																}
															/>
														}
													>
														Grid
													</Button>
												</Tooltip>
												<Tooltip title={`See projects in a list`}>
													<Button
														disabled={viewType == "list"}
														onClick={() => {
															this._toggleView("list");
														}}
														startIcon={
															<ListViewIcon
																size={14}
																color={
																	viewType != "list" ? "#780F9E" : "#A3ADC2"
																}
															/>
														}
													>
														List
													</Button>
												</Tooltip>
												{/*<Tooltip title={`See projects on a map`}>
							        		<Button disabled={viewType=="map"} onClick={() => {this._toggleView("map")}} startIcon={<MapViewIcon size={14} color={viewType!="map" ? "#780F9E" : "#A3ADC2"} />}>Map</Button>
							        	</Tooltip>*/}
											</ButtonGroup>
											<span
												className="small-divider"
												style={{ marginLeft: 15 }}
											>
												|
											</span>
											<MatTableFilterButton
												handleRequestSort={this.handleRequestSort}
												type={type}
											/>
										</Col>
									</Row>
								</div>

								{viewType == "map" ? (
									<MatMapView
										{...this.state}
										{...this.props}
										handleChangePage={this.handleChangePage}
										handleChangeRowsPerPage={this.handleChangeRowsPerPage}
										isSelected={this.isSelected}
										handleBuildingClick={this.handleBuildingClick}
										handleSelectAllClick={this.handleSelectAllClick}
										handleRequestSort={this.handleRequestSort}
									/>
								) : viewType == "grid" ? (
									<MatGridView
										{...this.state}
										{...this.props}
										handleChangePage={this.handleChangePage}
										handleChangeRowsPerPage={this.handleChangeRowsPerPage}
										isSelected={this.isSelected}
										handleBuildingClick={this.handleBuildingClick}
										handleSelectAllClick={this.handleSelectAllClick}
										handleRequestSort={this.handleRequestSort}
										selectUnit={this._selectUnit.bind(this)}
									/>
								) : (
									<MatTableView
										{...this.state}
										{...this.props}
										handleChangePage={this.handleChangePage}
										handleChangeRowsPerPage={this.handleChangeRowsPerPage}
										isSelected={this.isSelected}
										handleBuildingClick={this.handleBuildingClick}
										handleSelectAllClick={this.handleSelectAllClick}
										handleRequestSort={this.handleRequestSort}
										selectUnit={this._selectUnit.bind(this)}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}
export default connect((state) => ({
	user: state.user,
	searchTags: Array.isArray(state.search.searchTags)
		? state.search.searchTags.slice()
		: [],
}))(withRouter(MatTable));
