import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import { withRouter, Link } from 'react-router-dom';
import { Card, CardBody, Row, Col, Progress, Badge, Container } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import BuildingIcons from '../../../shared/components/BuildingIcons';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { Tooltip } from '@material-ui/core';
import moment from "moment";

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	 ConstructionProgress,
	BuildingStatus,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	getCohortQuery,
	StatusBadge,
	BuildingPhoto,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress
} from "../../../shared/helpers/utils";

class MatGridView extends PureComponent {
  	 getBuildingLink = (item) => {
		 return  _getBuildingURL(item);
	  };

    render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, user, reactTableFiltered, selected, isSelected, tableData, totalCount, rowsPerPage, page, handleBuildingClick,
      handleChangePage, handleChangeRowsPerPage, handleSelectAllClick, handleRequestSort, type, noPagination
    } = this.props;
	const table = reactTableFiltered;
	const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
    const paginationCount = totalCount;

    return (
       <Container>
	    <div className="catalog-items__wrap">
	        <div className="catalog-items">
	          {table.map((item, i) => (
	            <CardBody className="catalog-item card__link" key={i} onClick={event => handleBuildingClick(event, item)}>
	                <div className="catalog-item__img-wrap">
	                  {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
					  	<BuildingPhoto item={item} />
					  	
	                </div> 
	                <StatusBadge building={item} />               
	                <div className="catalog-item__info">
	                   <h4 className="catalog-item__price">
	                   	{item.name ? _capitalizeText(item.name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address))}
	                   	<div style={{marginLeft:-10,marginBottom:5}}><BuildingIcons building={item} /></div>
					   	
					   	<div className="light-text small-text" style={{color:"#858AA1"}}>
					   		{item.name && <div>{_capitalizeText(_replaceAbbreviations(item.display_full_street_address))}</div>}
		                  	{item.address && item.address.neighborhood && item.address.neighborhood[0] ? `${_capitalizeText(item.address.neighborhood[0])}, ` : ''} {_capitalizeText(item.address && item.address.borough)}
		                </div>	                  
	                   </h4>
	                   <h5 className="product-card__title">
			              	<div className="">		              	
			              		<span className="light-text small-text" >
		                      	{item.pipeline_zoning_area_residential > 0 ? _formatNumber(item.pipeline_zoning_area_residential) + ' Resi SF' : '' }
		                      	</span>
		                      	{item.pipeline_zoning_area_residential > 0 && item.pipeline_total_construction_floor_area > 0 && 
			                      	<span className="small-divider">|</span>
			                    }
		                      	<span className="light-text small-text">
		                      	{item.pipeline_total_construction_floor_area > 0 ? _formatNumber(item.pipeline_total_construction_floor_area) + ' Total SF' : '' }
		                      	</span>
		                      	

			              	</div>
			              	
			              	
			              	<span className="light-text small-text">{ _formatNumber(item.pipeline_building_stories_proposed) } Floors</span>
			              	
			              	{type !== "commercial" && item.pipeline_dwelling_units_proposed > 0 &&
				              <Fragment>
				              	<span className="small-divider">|</span>
							  	<span className="light-text small-text">{item.pipeline_dwelling_units_proposed > 0 ? _formatNumber(item.pipeline_dwelling_units_proposed) : '' } Units</span>
							  </Fragment>
			              	}
			            </h5>
			            <div style={{display:"flex",justifyContent:"space-between"}}>
			            	{/*<div style={{minWidth:"50%"}}>
			            		<ConstructionProgress building={item} />
							</div>*/}
							<div/>
				            <span className="light-text small-text" style={{color:"#858AA1",textAlign:"center",fontSize:11,marginTop:15}}>
				            	Added{" "} 
								{item.date_added && moment(item.date_added).year() > 1970 ? _capitalizeText(moment(item.date_added).fromNow()) : 
				                	item.pipeline_filing_date && moment(item.pipeline_filing_date).year() > 1970 ? _capitalizeText(moment(item.pipeline_filing_date).fromNow()) : 
									'--' }
				            </span>
			            </div>
	
	                  
	                 
	                 </div>
	            </CardBody>
	          ))}
	        </div>
	        
	        {paginationCount && !noPagination ? 
		        <Fragment>
			        <TablePagination
			          component="div"
			          className="material-table__pagination"
			          count={paginationCount}
			          rowsPerPage={rowsPerPage}
			          page={page}
			          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
			          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
			          onChangePage={handleChangePage}
			          onChangeRowsPerPage={handleChangeRowsPerPage}
			          rowsPerPageOptions={[20, 40, 80, 120]}
			          dir="ltr"
			          SelectProps={{
			            inputProps: { 'aria-label': 'rows per page' },
			            native: true,
			          }}
			        />
		        </Fragment> : null}
	      </div>

    </Container>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(MatGridView);