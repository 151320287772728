import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Container } from "reactstrap";
import BuildingContainer from "./BuildingContainer";
import queryString from "query-string";
import { _axiosCall } from "../../../shared/helpers/apicalls";
import LinearLoading from "../../../shared/components/LinearLoading";

const buildingUrl = process.env.API_ROOT + "/query/";
const pipelineUrl = process.env.API_ROOT + "/pipeline/";

const SingleBuilding = (props) => {
  const { user } = props;
  const { building_slug } = props.match && props.match.params;
  const buildingId = queryString.parse(props.location.search);
  const isTransfertab = buildingId && buildingId.transfer ? true : false;
  const isConstructionTab =
    buildingId && buildingId.construction ? true : false;

  const [buildingRes, setBuildingRes] = useState(null);
  const [transferRes, setTransferRes] = useState(null);
  const [constructonRes, setConstructonRes] = useState(null);

  const getBuildingData = (buildingSlug) => {
    if (!buildingSlug) return;
    //let fq = [`key:${buildingKey}`, "deleted:false"];
    let axiosData = {
      url:
        process.env.API_ROOT +
        `/buildings/${buildingSlug}?type=minimum&cache=15`,
      method: "get",
    };
    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.building && res.building[0]) {
          setBuildingRes(res.building[0]);
          getPipelineBuildings(res.building[0]);
          isTransfertab && getTransactionDetails(buildingId);
          isConstructionTab && getConstructionDetails(buildingId);
        } else {
          setBuildingRes(null);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setBuildingRes(null);
      });
  };

  const getPipelineBuildings = async (buildingRes) => {
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
          { "meta.source_namespace" : "MARKETPROOF-PIPELINE-APP"}
        ],
      },
      {
        $or: [
          { pipeline_occupancy_classification_proposed_code: { $nin: ["u"] } },
          { pipeline_total_construction_floor_area: { $gt: 1000 } },
          { "meta.source_namespace" : "MARKETPROOF-PIPELINE-APP"}
        ],
      },
    ];

    let buildingKeys = [];
    let addressQueries = [];
    let projectQuery = {
      $or : []
    }
    if (
      buildingRes.alternate_keys &&
      Array.isArray(buildingRes.alternate_keys)
    ) {
      buildingKeys = [...buildingKeys, ...buildingRes.alternate_keys];
    } else if (buildingRes.key && Array.isArray(buildingRes.key)) {
      buildingKeys = [...buildingKeys, ...buildingRes.key];
    }
    
    if (
      buildingRes.alternate_addresses &&
      Array.isArray(buildingRes.alternate_addresses)
    ) {
       projectQuery['$or'].push({ $and : [{"address.alternate_addresses" : { "$in" : buildingRes.alternate_addresses}}, {"address.borough" : buildingRes.borough.toLowerCase()}] });
      
    }

    if (buildingKeys.length > 0) {
      projectQuery['$or'].push({ building_key: { $in: buildingKeys } });
      projectQuery['$or'].push({ "address.key": { $in: buildingKeys } })
      searchFilters.push(projectQuery);
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
      },
      limit: 1,
    };

    let axiosData = {
      url: pipelineUrl + "query",
      method: "post",
      query: mongoQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        // console.log('Chart Data', res);
        let docs = [];
        if (res && res.docs && res.docs[0]) {
          setBuildingRes(
            Object.assign({}, buildingRes, { pipeline_project: res.docs[0] })
          );
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  const getTransactionDetails = async (buildingId) => {
    let fq = [
      "id:(" + buildingId.transfer + ")",
      // `building:("${buildingId.transfer}")`,
      "transaction_source:ACRIS*",
      "status:sold",
    ];
    let axiosData = {
      url: buildingUrl + "we3-transactions",
      method: "post",
      query: {
        q: "*:*",
        fq: fq,
        start: 0,
        wt: "json",
      },
    };

    _axiosCall(axiosData)
      .then((res) => {
        let docs = [];
        if (res && res.docs && res.docs[0]) {
          setTransferRes(Object.assign({}, res.docs[0]));
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  const getConstructionDetails = async (buildingId) => {
    let key = buildingId.construction;
    let axiosData = {
      url: buildingUrl + "we3-activities-3",
      method: "post",
      query: {
        q: `id:"${key}"`,
        wt: "json",
      },
    };

    _axiosCall(axiosData)
      .then((res) => {
        let docs = [];
        if (res && res.docs && res.docs[0]) {
          setConstructonRes(Object.assign({}, res.docs[0]));
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  useEffect(() => {
    getBuildingData(building_slug);
  }, []);
  if (!buildingRes) {
    return <LinearLoading />;
  } else {
    return (
      <Container className="dashboard">
        <Row>
          {buildingRes &&
            (isTransfertab ? (
              <BuildingContainer
                trxnRes={transferRes}
                buildingRes={buildingRes}
                eventData={buildingRes}
                user={user}
                removeCss
                mainTab={{ key: "transfer", name: "Transfer" }}
              />
            ) : isConstructionTab && constructonRes ? (
              <BuildingContainer
                trxnRes={constructonRes}
                buildingRes={buildingRes}
                eventData={constructonRes}
                removeCss
                mainTab={{ key: "construction", name: "Construction" }}
              />
            ) : (
              <BuildingContainer
                buildingRes={buildingRes}
                eventData={buildingRes}
                user={user}
                removeCss
              />
            ))}
        </Row>
      </Container>
    );
  }
};

export default connect((state) => ({
  user: state.user,
}))(SingleBuilding);
