import React, { Component, Fragment } from "react";
import { Col, Container, Row, Modal, ButtonToolbar } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { RTLProps, ThemeProps } from "../../../shared/prop-types/ReducerProps";
import buyersListActions from "../../../redux/actions/buyersListActions";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import ReportIcon from "mdi-react/FileDocumentOutlineIcon";
import PhoneIcon from "mdi-react/PhoneOutlineIcon";
import LocationIcon from "mdi-react/MapMarkerIcon";
import InfoIcon from "mdi-react/NotebookOutlineIcon";
import ContactIcon from "mdi-react/BookmarkPlusOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";

import Button from "@material-ui/core/Button";

import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ReportForm from "../form/contact/ReportForm";

import { Publisher } from "./../../pubsub/publisher.js";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  _formatUnitNumber,
  _slugifyText,
} from "../../../shared/helpers/utils";

class ReportButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Contact",
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  handleSubmit(event, buildingKey) {
    event.preventDefault();
    const { addMyBuyersList } = this.props.actions;
    const priority = "high";
    // addMyBuyersList(buildingKey, priority);
    // this.setState({ buttonText: 'Building Saved'})
  }
  toggle() {
    const { buildingRes, property } = this.props;
    this.setState((prevState) => ({ modal: !prevState.modal }));

    if (property) {
      Publisher.publish("track.share.unit", {
        building: buildingRes.key,
        unit: property.key,
      });
    } else {
      Publisher.publish("track.share.building", { building: buildingRes.key });
    }
  }

  render() {
    const { actions, t, theme, rtl, buildingRes, user, property, listing } =
      this.props;

    const { modal } = this.state;
    const buildingNameOrAdr =
      buildingRes && buildingRes.name
        ? _capitalizeText(buildingRes.name)
        : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
    const adr = buildingRes.display_full_street_address
      ? buildingRes.display_full_street_address
      : buildingRes.full_street_address;

    let salesOffice = false;

    try {
      salesOffice = JSON.parse(buildingRes.sales_office_details);
    } catch (e) {}

    return (
      <Fragment>
        <Tooltip
          title={`Reports Available for this ${property ? "Unit" : "Building"}`}
        >
          <Button
            onClick={this.toggle}
            variant="contained"
            color="secondary"
            className="buyersList__secondary-button"
            startIcon={<ReportIcon />}
          >
            {" "}
            Reports
          </Button>
        </Tooltip>

        <Fragment>
          <Modal
            isOpen={modal}
            toggle={this.toggle}
            modalClassName={`${rtl.direction}-support`}
            className={`modal-dialog contact__modal share__modal`}
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={this.toggle}
              />
            </div>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="modal__body background_white">
                  <h4 className="contact__title">
                    {buildingNameOrAdr} Reports
                    {property && (
                      <span>
                        {" "}
                        - {_formatUnitNumber(property.address_2_normalized)}
                      </span>
                    )}
                  </h4>

                  {property && (
                    <h5 className="contact__sub-title">
                      {property.offering_price &&
                      property.offering_price > 0 ? (
                        <Fragment>
                          ${_formatNumber(property.offering_price)}
                          <span className="small-divider">|</span>
                        </Fragment>
                      ) : null}

                      {property.bedrooms === 0 ? "Studio" : property.bedrooms}
                      <span className="sub-text">
                        {property.bedrooms === 0
                          ? ""
                          : property.bedrooms === 1
                          ? " Bed"
                          : " Beds"}
                      </span>
                      <span className="small-divider">|</span>
                      {property.baths}
                      <span className="sub-text">
                        {property.baths === 1 ? " Bath" : " Baths"}
                      </span>
                      <span className="small-divider">|</span>
                      {_formatNumber(property.sq_ft)}
                      <span className="sub-text"> Sq Ft</span>
                    </h5>
                  )}

                  <hr />
                  {/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}
                  <ReportForm
                    buildingRes={buildingRes}
                    user={user}
                    property={property}
                    toggle={this.toggle}
                    listing={listing}
                  />
                </div>
              </Col>
            </Row>
          </Modal>
        </Fragment>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ReportButton));
