import React, { Component, Fragment } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import BuyersListInput from "./components/BuyersListInput";
import BuyersList from "./components/BuyersList";
import buyersListActions from "../../redux/actions/buyersListActions";
import buyersListCard from "./types";
import { RTLProps, ThemeProps } from "../../shared/prop-types/ReducerProps";
import EmptyBuyersList from "./components/EmptyBuyersList";
import { _axiosCall } from "../../shared/helpers/apicalls";
import classnames from "classnames";

import TrialAlert from "../../shared/components/payment/TrialAlert";

import MatGridView from "./../PipelineBuildings/components/MatGridView";

import { _hasPermission } from "../../shared/helpers/access";
import EnterpriseGate from "../../shared/components/payment/EnterpriseGate";
import {
  buildingFL,
  getCohortQuery,
  _fixGoogleImage,
  _getBuildingURL,
} from "../../shared/helpers/utils";
import PeopleCardView from "../SingleOrganization/components/Contacts/components/PeopleCardView";
const apiRoot = process.env.API_ROOT;
const buildingUrl = process.env.API_ROOT + "/pipeline/";

class MyBuyersList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    buyerslist: PropTypes.arrayOf(buyersListCard).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      incompleteMyBuyersLists: false,
      completedMyBuyersLists: false,
      buildingsFetchInProgress: false,
      buyersList: null,
      buyersListAnalytics: null,
      activeTab:
        this.props.match && this.props.match.params.subtab
          ? this.props.match.params.subtab
          : "projects",
      contacts: [],
      companies: [],
      urlsync: false,
      rowsPerPage: 999
    };
  }

  componentWillReceiveProps(nextProps) {
    // this._getPipelineBuildings(nextProps);
  }

  componentDidMount() {
    if (this.state.activeTab === "people") {
      this._getSavedContacts(this.props);
    } else if (this.state.activeTab === "companies") {
      this._getSavedCompanies(this.props);
    }else{
      this._getPipelineBuildings(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.dataSet &&
      this.props.savedProjects &&
      this.state.buyersList &&
      this.props.savedProjects.filter((item) => !item.deleted).length !==
        this.state.buyersList.length && 
      !this.state.urlsync

    ) {
      console.log("calling again");
      if (this.state.activeTab === "people") {
        this._getSavedContacts(this.props);
      } else if (this.state.activeTab === "companies") {
        this._getSavedCompanies(this.props);
      }else{
        this._getPipelineBuildings(this.props);
      }
      this.setState({urlsync: true})
    }
  }

  _setBuyersList = (res) => {
    let list = res.docs
      ? res.docs.map((building) => {
          return Object.assign(
            {},
            building,
            this.props.savedBuildings.filter(
              (item) => item.key === building.key
            )[0]
          );
        })
      : [];

    this.setState({
      buyersList: list,
      buildingsFetchInProgress: false,
    });
  };

  handleBuildingClick = (event, building) => {
    event.preventDefault();
    event.stopPropagation();
    const buildingURL = _getBuildingURL(building, "_id");

    window.open(buildingURL);
  };

  _getSavedCompanies = async (props) => {
    const { user } = this.props;
    const savedOrganizations = props.savedOrganizations.length>0 ? props.savedOrganizations : user.savedOrganizations.length>0 ? user.savedOrganizations : [];
    
    const companyKeys = savedOrganizations
      .filter((item) => !item.deleted)
      .map((item) => item.key);

    let searchFilters = [{ "meta.deleted": { $exists: false } }];

    if (companyKeys.length > 0) {
      searchFilters.push({ key: { $in: companyKeys } });
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      limit: this.state.rowsPerPage,
    };

    let axiosData = {
      url: buildingUrl + "query/organizations",
      method: "post",
      query: mongoQuery,
    };
    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs) {
          this.setState({ companies: res.docs });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _getSavedContacts = async (props) => {
    const { user } = this.props;
    const savedContacts = props.savedContacts.length>0 ? props.savedContacts : user.savedContacts.length>0 ? user.savedContacts : [];

    const contactKeys = props.savedContacts
      .filter((item) => !item.deleted)
      .map((item) => item.key);

    let searchFilters = [{ "meta.deleted": { $exists: false } }];

    if (contactKeys.length > 0) {
      searchFilters.push({ key: { $in: contactKeys } });
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      limit: this.state.rowsPerPage,
    };

    let axiosData = {
      url: buildingUrl + "query/contacts",
      method: "post",
      query: mongoQuery,
    };
    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs) {
          this.setState({ contacts: res.docs });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _getPipelineBuildings = async (props) => {
    //console.log(props)
    const { user } = this.props;
    
    const savedProjects = props.savedProjects.length>0 ? props.savedProjects : user.savedProjects.length>0 ? user.savedProjects : [];
    const buildingKeys = savedProjects
      .filter((item) => !item.deleted)
      .map((item) => item.key);

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }

    let searchFilters = [{ "meta.deleted": { $exists: false } }];

    if (buildingKeys.length > 0) {
      searchFilters.push({ key: { $in: buildingKeys } });
    }else{
      searchFilters.push({ key: { $in: [] } });
    }

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
      },
      limit: this.state.rowsPerPage,
    };

    let axiosData = {
      url: buildingUrl + "query",
      method: "post",
      query: mongoQuery,
    };

    this._getBuildingAnalytics(buildingKeys);
    _axiosCall(axiosData)
      .then((res) => {
        let newData = res;
        if (res && res.docs) {
          newData = _fixGoogleImage(res);
        }
        let docs = [];
        if (res && res.docs) {
          this._setBuyersList(newData);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  _getBuildings = async (props) => {
    const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
    const { user } = this.props;
    const _buildingIDs = props.savedBuildings
      .filter((item) => !item.deleted)
      .map((item) => item.key)
      .join(" ");

    console.log(_buildingIDs);

    if (!props.dataSet || this.state.buildingsFetchInProgress) {
      return false;
    }

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }
    var q = "*:*";

    var fq = [
      "deleted:false",
      "key:(" + _buildingIDs + ")",
      getCohortQuery(user),
    ];

    let axiosData = {
      url: buildingUrl + "we3-buildings?cache=60",
      method: "post",
      query: {
        q: q,
        fq: fq,
        sort: "inventory_unsold desc",
        fl: buildingFL,
        wt: "json",
        rows: signedIn ? 999 : 6,
        "json.facet": {
          total_unsold: "sum(inventory_unsold)",
        },
      },
    };

    this._getBuildingAnalytics(_buildingIDs);

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        this._setBuyersList(res);
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  _getBuildingAnalytics = async (buildingKeys) => {
    var q = "*:*";

    var query = [
      {
        $facet: {
          buildingKeys: [
            {
              $unwind: "$savedBuildings",
            },
            {
              $unwind: "$savedBuildings.key",
            },
            {
              $match: {
                $and: [
                  {
                    $or: [
                      {
                        "savedBuildings.deleted": {
                          $exists: false,
                        },
                      },
                      {
                        "savedBuildings.deleted": false,
                      },
                    ],
                  },
                  {
                    "savedBuildings.key": {
                      $in: buildingKeys,
                    },
                  },
                ],
              },
            },
            {
              $group: {
                _id: {
                  key: "$savedBuildings.key",
                },
                total: {
                  $sum: 1,
                },
              },
            },
            {
              $project: {
                _id: 0,
                key: "$_id.key",
                total: 1,
              },
            },
            {
              $sort: {
                "savedBuildings.created_timestamp": -1,
              },
            },
          ],
        },
      },
    ];

    let axiosData = {
      url: apiRoot + "/buyerslist/analytics/aggregate",
      method: "post",
      query: query,
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        console.log(res[0].buildingKeys);
        if (res && res[0] && res[0].buildingKeys) {
          this.setState({ buyersListAnalytics: res[0].buildingKeys.slice() });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    // console.log(formattedData)
    return formattedData;
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    const newURL = `/saved/${tab}`;
    //props.history.replace(buildingURL);
    window.history && window.history.pushState({}, null, newURL);
    if (tab === "people") {
      this._getSavedContacts(this.props);
    } else if (tab === "companies") {
      this._getSavedCompanies(this.props);
    }
  };
  render() {
    const { activeTab, buyersList, contacts, companies } = this.state;
    const { user } = this.props;

    if (buyersList === null && contacts === null && companies === null) {
      return null;
    }

    if ((buyersList && buyersList.length <= 0) && (contacts && contacts.length <= 0) && (companies && companies.length <= 0)) {
      return (
        <div className="dashboard">
          <Container className="todo-app">
            <Row>
              <Col md={12} className={"mb-3"}>
                <TrialAlert />
              </Col>
            </Row>
          </Container>
          <EmptyBuyersList />
        </div>
      );
    }
    return (
      <div className="dashboard">
        <Container className="todo-app">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{`Saved`}</h3>
              <h3 className="page-subhead subhead">
                Saved projects, company and people
              </h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={"mb-3"}>
              <TrialAlert />
            </Col>
          </Row>

          <div className="tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "projects" })}
                    onClick={() => {
                      this.toggle("projects");
                    }}
                  >
                    <span className="tab-title">Projects</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "companies",
                    })}
                    onClick={() => {
                      this.toggle("companies");
                    }}
                  >
                    <span className="tab-title">Companies</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "people" })}
                    onClick={() => {
                      this.toggle("people");
                    }}
                  >
                    <span className="tab-title">People</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <Card>
            <CardBody>
              <TabContent activeTab={activeTab}>
                {activeTab == "projects" && buyersList && (
                  <TabPane tabId="projects">
                    {buyersList && buyersList.length <= 0 ? (
                      <div className="mt-5">
                        <EmptyBuyersList />
                      </div>
                    ) : (
                      <MatGridView
                        reactTableFiltered={buyersList}
                        handleBuildingClick={this.handleBuildingClick}
                        noPagination
                      />
                    )}
                  </TabPane>
                )}
                {activeTab == "companies" && companies && (
                  <TabPane tabId="companies">
                    {_hasPermission(user, "project-contacts") ? (
                      <Fragment>
                        {companies.length <= 0 ? (
                          <div className="mt-5">
                            {/* <EmptyBuyersList /> */}
                          </div>
                        ) : (
                          <PeopleCardView
                            contacts={companies}
                            companyCard={true}
                          /> //company card props is for differentiate between company and person card
                        )}
                      </Fragment>
                    ) : (
                      <EnterpriseGate
                        copy={
                          <span>
                            Access to Pipeline Contacts requires an upgrade to
                            your subscription.
                          </span>
                        }
                        img={`https://marketproof.com/svgs/PLyes.svg`}
                        classes={"small-image"}
                      />
                    )}
                  </TabPane>
                )}
                {activeTab == "people" &&  contacts && (
                  <TabPane tabId="people">
                    {_hasPermission(user, "project-contacts") ? (
                      <Fragment>
                        {contacts.length <= 0 ? (
                          <div className="mt-5">
                            {/* <EmptyBuyersList /> */}
                          </div>
                        ) : (
                          <PeopleCardView contacts={contacts} />
                        )}
                      </Fragment>
                    ) : (
                      <EnterpriseGate
                        copy={
                          <span>
                            Access to Pipeline Contacts requires an upgrade to
                            your subscription.
                          </span>
                        }
                        img={`https://marketproof.com/svgs/PLyes.svg`}
                        classes={"small-image"}
                      />
                    )}
                  </TabPane>
                )}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    savedBuildings: Array.isArray(state.buyersLists.savedBuildings)
      ? state.buyersLists.savedBuildings.slice()
      : [],
    savedProjects: Array.isArray(state.buyersLists.savedProjects)
      ? state.buyersLists.savedProjects.slice()
      : [],
    savedContacts: Array.isArray(state.buyersLists.savedContacts)
      ? state.buyersLists.savedContacts.slice()
      : [],
    savedOrganizations: Array.isArray(state.buyersLists.savedOrganizations)
      ? state.buyersLists.savedOrganizations.slice()
      : [],
    dataSet: state.buyersLists.dataSet,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(MyBuyersList));