/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Sector
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../shared/components/Panel';
import getTooltipStyles from '../../../../../shared/helpers';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const data01 = [{ name: 'Sony Xperia Z', value: 12934, fill: '#4ce1b6' },
		  { name: 'Xiaomi Mi 6', value: 9934, fill: '#70bbfd' },
		  { name: 'Apple iPhone 7 Plus', value: 20432, fill: '#f6da6e' },
		  { name: 'Apple iPhone 6 Plus', value: 15432, fill: '#ff4861' }];

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { right: 0 };
  return ({
    ...left,
    width: 150,
    lineHeight: '24px',
    position: 'absolute',
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

const RADIAN = Math.PI / 180; 
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, x, y, value, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const ix = cx + radius * Math.cos(-midAngle * RADIAN);
  const iy = cy  + radius * Math.sin(-midAngle * RADIAN);
  return (
	<Fragment>		
		<text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
		    	<tspan fontWeight="bold">{`${(percent * 100).toFixed(1)}%`}</tspan> {`(${name})`}
	    </text>	 

    </Fragment>
  );
};

const renderActiveShape = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius+5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};  

class UnitMix extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dir: PropTypes.string.isRequired,
    themeName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      activeIndex: false
    };
  }

  onMouseMove = (e) => {
    const { dir } = this.props;
    if (e.tooltipPosition) {
      this.setState({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };
  
  _generateUnitMixData = (statsRes) => {
	  
	  if(!statsRes.has_bed || !statsRes.has_bed.unit_mix || !statsRes.has_bed.unit_mix.buckets){
		  return [];
	  }
	 const total = statsRes.has_bed.unit_mix.buckets.map(bucket => bucket.count).reduce((accumulator, current) => { return accumulator + current}, 0 );
	 let other = 0;
	 let data = statsRes.has_bed.unit_mix.buckets.sort((a, b) =>  a.val - b.val).map((bucket, i) => {
		 if(bucket.count / total < .1){
			 other += bucket.count;
			 return false;
		 }
		 return {name: bucket.val > 0 ? `${bucket.val} Bed` : `Studio`,
			 	 value:  bucket.count,
			 	 fill: getDefaultChartColors[i],
			 	 search : bucket.val > 0 ? `${bucket.val}-bedroom` : `studio`,
			 	 cursor: 'pointer'
			 	 }
	 });
	 data = data.filter(item => item !== false);
	 
	 if(other > 0){
		 data.push({
			 name: 'Other',
			 value: other,
			 fill: getDefaultChartColors[data.length+1],
			 search : '4-or-more',
			 cursor: 'pointer'
		 });
	 }
	 
	 return data;
	  
  }
  
  handleClick(data, index) {
  	console.log(data, index);
  	const { history, location } = this.props;
  	const base_url = location.pathname.replace('analysis', 'units');
  	history.push(`${base_url}?offering_bedrooms=${data.search}`);
  }
  
  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }


  render() {
    const { t, dir, themeName, statsRes, unitsRes, buildingRes } = this.props;
    const { x, y } = this.state;
    
    if(!statsRes){
	    return null;
    }

	const data = this._generateUnitMixData(statsRes);
    return (
      <Panel lg={6} xl={5} md={12} xs={12} title={`Unit Mix`}>
        <div dir={dir}>
	          <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--commerce">
	            <PieChart className="dashboard__chart-pie-container" style={{ cursor: "pointer" }}  height={80}>
	            {/*  <Tooltip position={{ x, y }} {...getTooltipStyles(themeName)} onClick={this.handleClick.bind(this)} /> */}
	              <Pie
	                data={data}
	                activeIndex={this.state.activeIndex}
					activeShape={renderActiveShape}
	                dataKey="value"
	                cy={140}
	                isAnimationActive={false}
	                innerRadius={30}
	                outerRadius={80}
	                label={renderCustomizedLabel}
	                onMouseEnter={this.onPieEnter.bind(this)}
	                onClick={this.handleClick.bind(this)}
	              />
	              {/*<Legend layout="horizontal" verticalAlign="bottom" wrapperStyle={style(dir)} content={renderLegend} />*/}
	            </PieChart>
	          </ResponsiveContainer>
        </div>
      </Panel>
    );
  }
}

export default connect(state => ({ themeName: state.theme.className }))(UnitMix);
