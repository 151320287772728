import React, { PureComponent, Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Listed from "./components/Listed";
import ListedPrice from "./components/ListedPrice";
import Sold from "./components/Sold";
import SoldPrice from "./components/SoldPrice";

import NewUnitsGraph from "./components/NewUnitsGraph";
import NewBuildingsGraph from "./components/NewBuildingsGraph";
import TopSellingProducts from "./components/TopSellingProducts";
import BasicCard from "./components/BasicCard";
import SalesStatistic from "./components/SalesStatistic";
import MyUnits from "./components/MyUnits";
import ProductSales from "./components/ProductSales";
import NewOrders from "./components/NewOrders";
import SalesStatistisBar from "./components/SalesStatistisBar";
import MyTodos from "./components/MyTodos";
import Emails from "./components/Emails";
import SalesReport from "./components/SalesReport";
import Promotions from "./components/Promotions";
import InternalPromotion from "./components/InternalPromotion";
import ProjectCount from "./components/ProjectCount";
import ProjectStage from "./components/ProjectStage";

import CurrentInventory from "./components/CurrentInventory";
import ShadowInventory from "./components/ShadowInventory";

import MarketActivity from "./components/MarketActivity";
import DiscountAnalysis from "./components/DiscountAnalysis";
import TrendingBuildings from "./components/TrendingBuildings";

import RecentlyAdded from "./components/RecentlyAdded";
import ComingSoon from "./components/ComingSoon";
import Alert from "../../../../shared/components/Alert";
import { Link } from "react-router-dom";
import AlertIcon from "mdi-react/GiftOutlineIcon";

import { NewOrderTableProps } from "../../../../shared/prop-types/TablesProps";
import { RTLProps } from "../../../../shared/prop-types/ReducerProps";

import NewUnits from "./components/NewUnits/index";
import LatestProjects from "./components/LatestProjects/index";
import News from "./components/News/index";

import PopularBuildings from "./components/PopularBuildings/index";
import GeneralBuildingSearchBar from '../../../../shared/components/building_filters/GeneralBuildingSearchBar';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import TrialAlert from "../../../../shared/components/payment/TrialAlert";

import moment from "moment";

import { _buildingIDs, _axiosCall } from "../../../../shared/helpers/apicalls";
import {
  propertyFL,
  mpndCohortQuery,
  getCohortQuery,
  recentCohortQuery,
  _validUnitSources,
} from "../../../../shared/helpers/utils";
import RecentHistory from "./components/RecentHistory";
const buildingUrl = process.env.API_ROOT + "/pipeline/";

class BuyersListDashboard extends PureComponent {
  static propTypes = {
    newOrder: NewOrderTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  state = {
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    facetData: false,
  };

  defaultQ = `((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR (verification_source:(${_validUnitSources}) AND -address_2_normalized:\"\") OR building_class:R3)`;
  q = "";
  fq = [
    "deleted:false",
    `{!join from=alternate_keys fromIndex=we3-buildings to=building}${getCohortQuery(
      this.props.user
    )}`,
    "-offering_unit_abandoned:true",
    "building_class:R3 OR address_2_normalized:?*",
  ];

  fl = propertyFL;

  onDeleteRow = (index, e) => {
    const { dispatch, newOrder } = this.props;

    e.preventDefault();
    const arrayCopy = [...newOrder];
    arrayCopy.splice(index, 1);
  };

  componentDidMount() {
    this._getInventoryUnits();
  }

  _getInventoryUnits = async (resetPage) => {
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      {
        $or: [
          { pipeline_occupancy_classification_proposed_code: { $nin: ["u"] } },
          { pipeline_total_construction_floor_area: { $gt: 1000 } },
        ],
      },
    ];
    let atlasQuery = [
      { $match: { $and: searchFilters } },
      {
        $project: {
          _id: 0,
          key: 1,
          organizations: 1,
          pipeline_occupancy_type: 1,
          pipeline_project_stage: 1,
          pipeline_dwelling_units_proposed: 1,
          pipeline_dwelling_units_existing: 1,
          pipeline_total_construction_floor_area: 1,
        },
      },
      {
        $facet: {
          resi_project_count: [
            {
              $match: {
                pipeline_occupancy_type: {
                  $in: ["residential"],
                },
              },
            },
            {
              $group: {
                _id: null,
                count: {
                  $sum: 1,
                },
                units: {
                  $sum: "$pipeline_dwelling_units_proposed",
                },
                units_existing: {
                  $sum: "$pipeline_dwelling_units_existing",
                },
                sq_ft: {
                  $sum: "$pipeline_total_construction_floor_area",
                },
              },
            },
            {
              $project: {
                _id: 0,
              },
            },
          ],
          comm_project_count: [
            {
              $match: {
                pipeline_occupancy_type: {
                  $in: ["commercial"],
                },
              },
            },
            {
              $group: {
                _id: null,
                count: {
                  $sum: 1,
                },
                units: {
                  $sum: "$pipeline_dwelling_units_proposed",
                },
                sq_ft: {
                  $sum: "$pipeline_total_construction_floor_area",
                },
              },
            },
            {
              $project: {
                _id: 0,
              },
            },
          ],
          mixed_project_count: [
            {
              $match: {
                pipeline_occupancy_type: {
                  $in: ["mixed-use"],
                },
              },
            },
            {
              $group: {
                _id: null,
                count: {
                  $sum: 1,
                },
                units: {
                  $sum: "$pipeline_dwelling_units_proposed",
                },
                units_existing: {
                  $sum: "$pipeline_dwelling_units_existing",
                },
                sq_ft: {
                  $sum: "$pipeline_total_construction_floor_area",
                },
              },
            },
            {
              $project: {
                _id: 0,
              },
            },
          ],
          by_stage: [
            {
              $group: {
                _id: "$pipeline_project_stage",
                count: {
                  $sum: 1,
                },
                units: {
                  $sum: "$pipeline_dwelling_units_proposed",
                },
                sq_ft: {
                  $sum: "$pipeline_total_construction_floor_area",
                },
              },
            },
          ],
        },
      },
    ];
    let axiosData = {
      url: buildingUrl + "aggregate?cache_time=1440&always-cache=true",
      method: "post",
      query: atlasQuery,
    };

    _axiosCall(axiosData)
      .then((results) => {
        if (results && results[0]) {
          this.setState((prevState) => ({
            facetData: results[0],
          }));
        } else {
          this.setState((prevState) => ({
            facetData: false,
          }));
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  render() {
    const { t, newOrder, rtl } = this.props;

    const { facetData } = this.state;
    // if(!facetData) return null;

    return (
      <div className="dashboard">
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">{`Dashboard`}</h3>
              <h3 className="page-subhead subhead">Building Pipeline</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={"mb-3"}>
              <TrialAlert />
              {/*<Alert color="success" className="alert--bordered" alertId="dashboardGetOfferingPlan" icon iconItem={<AlertIcon />} minimizeText={'Have an offering plan?'}>
		          <p>
		          	<span className="bold-text">{`Have an Offering Plan?`} </span> 
		          	<div><Link to="/coming-soon?offering_verified_status=submit-offering-plan">Get a free month &raquo;</Link></div>
		          </p>
		         
		        </Alert>*/}
            </Col>
          </Row>
          {/*!isMobile && (
            <Row>
              <Col md={12} className={"text-right"}>
                <div
                  style={{
                    fontSize: "11px",
                    marginBottom: "10px",
                    color: "#858AA1",
                  }}
                >
                  Need a refresher? {` `}
                  <a
                    href="javascript:void(0)"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.Intercom("startTour", 181184);
                    }}
                    style={{ color: "#9600BF" }}
                  >
                    Take a quick tour
                  </a>
                  .
                </div>
              </Col>
            </Row>
          )*/}
          
          <Row className={"mb-3"}>
          <Col md={12} >
              <GeneralBuildingSearchBar      
            handleSearch={null}
            buildingAttributes={[]}
            placeHolder={'Project, Address, Person or Company'}
            addBuilding={false}
            buildingTab={null}
            hideNeighborhood={true}
            hideBorough={true}
            hideAddIcon={true}
          />
          </Col>
          </Row>

          <Row>
            <ProjectCount data={facetData} type={"residential"} />
            <ProjectCount data={facetData} type={"commercial"} />
            <ProjectStage data={facetData} />
          </Row>
          <TrendingBuildings />
          <RecentHistory {...this.props} />
          <Row>
            <LatestProjects type={"residential"} />
            <LatestProjects type={"commercial"} />
          </Row>
          <Row>
            <News />
          </Row>

          {/* <Row>
        	<PopularBuildings />
        </Row>*/}

          {/* <Row>
        	<Col lg={12} className="mb-3" >
        		<hr/>
        	</Col>
        </Row>*/}

          {/*  <Row>
          
          <NewUnitsGraph />
          <NewBuildingsGraph />
          <SalesReport />
        </Row>

        
        <Row>
        <ProductSales rtl={rtl.direction} />
          <BasicCard />
          <SalesStatistic />
          <MyTodos />
          <SalesStatistisBar />
          <SalesReport />
          <Emails />
          <TopSellingProducts dir={rtl.direction} />
          {/*<NewOrders newOrder={newOrder} onDeleteRow={this.onDeleteRow} />
         
        </Row>*/}
        </Container>
      </div>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(withTranslation("common")(BuyersListDashboard));
