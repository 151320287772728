import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';

import{
	_capitalizeText,
} from '../helpers/utils';

class ShareTopBar extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
	this.unit = false;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
 
  render(){
	  const { user, urlState } = this.props;
	  const { collapse } = this.state;
	  return (
	    <div className="topbar">
	        <div className="topbar__wrapper container">
	          <div className="topbar__left">
	            
	            <a className="topbar__logo" href="https://marketproof.com/pipeline" target="_blank"/>
	          </div>
	          <div className="topbar__right">
	          {urlState && urlState.ap && user && 
	          		<div className="topbar__profile">
			         <button type="button" className="topbar__avatar" onClick={this.toggle}>
				      <div className="topbar__avatar-img-container">
				          <img className="topbar__avatar-img" src={user.avatar} alt="avatar" />
				      </div>
			          <p className="topbar__avatar-name">
			          	{user.firstName} {user.lastName}
					  	<br/><span className="small-text light-text">{user && user.title ? _capitalizeText(user.title.replace(/-/g, ' ')) : ""}</span>
			          </p>          
			          <DownIcon className="topbar__icon" />
			        </button>
			        
			        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
			        <Collapse isOpen={collapse} className="topbar__menu-wrap">
			          <div className="topbar__menu">
			            {/*<TopbarMenuLink title="Agent View" icon="user" action={() => this.toggleUser('agent')} />
			            <TopbarMenuLink title="Sales View" icon="phone" action={() => this.toggleUser('sales')} /> */}
						{user.primaryPhone &&
			            <a className="topbar__link" href={`tel:${user.primaryPhone}`}>
			            	<span className="topbar__link-icon lnr lnr-phone"></span>
			            	<p className="topbar__link-title">{user.primaryPhone}</p>
			            </a>
			            }
			            
			            <a className="topbar__link" href={`mailto:${user.email}`}>
			            	<span className="topbar__link-icon lnr lnr-envelope"></span>
			            	<p className="topbar__link-title">{user.email}</p>
			            </a>
	
			          </div>
			        </Collapse>
			      </div>
				 }
	          </div>
	        </div>
	    </div>
	
	  );
	}
};
export default ShareTopBar;