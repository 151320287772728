import React, { useState, useEffect } from "react";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  ProfileAvatar,
  _capitalizeText,
  _formatEmailWithHide,
  _formatPhoneNumber,
  _formatPhoneWithHide,
  _pickPrimaryCompany
} from "../../../../../shared/helpers/utils";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import { Button } from "reactstrap";
import { Grid } from "@material-ui/core";
import AddToBuyersListButton from "../../../../../shared/components/buttons/AddToBuyersListButton";
import ViewContactButton from "../../../../../shared/components/buttons/ViewContactButton";


const PeopleCardContent = ({
  contact,
  index,
  roles,
  handleShow,
  companyCard,
  viewType,
  timestamp
}) => {
  const [show, setShow] = useState(false);
  const primaryOrg = _pickPrimaryCompany(contact);
  
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      sm={6}
      key={index}
      className="main_contactcard_container contact_card_col_padding"
    >
      <div className="card_profile_people_clm">
        <div className="card_profile_inner">
          <div className="d-flex">
            <div className="card_name card_avatar_style">
              <ProfileAvatar
                contact={contact}
                large
                companyCard={companyCard}
              />
            </div>
            <Grid item xs={12} className="card_content">
              <p>
                {companyCard ? (
                  <Link
                    className="product__external-link product__font_link_size"
                    to={`/company/${contact["key"]}`}
                   
                  >
                    {contact.name ? `${_capitalizeText(contact.name)} ` : "--"}
                  
                  </Link>
                ) : (
                  <Link
                    className="product__external-link product__font_link_size"
                    to={`/contact/${contact["key"]}`}
                  >
                    {contact.first_name
                      ? `${_capitalizeText(
                          contact.first_name
                        )} ${_capitalizeText(contact.last_name)}`
                      : "--"}
                   
                  </Link>
                )}
              </p>

              <div className="contact-span-paragraph">
                {contact.title
                  ? `${_capitalizeText(contact.title)}`
                  : roles[0]
                  ? `${_capitalizeText(roles[0])}`
                  : "--"}
              </div>
              {primaryOrg && primaryOrg.name &&
              <div className="contact-span-paragraph mt-1">
                <a className="link-text" href={`/company/${primaryOrg.key}`} target="_blank">
                  {_capitalizeText(primaryOrg.name)} <OpenInNewIcon
                    size={10}
                    style={{ marginLeft: 3, marginTop: -2 }}
                  />
                </a>
              </div>
               }
            </Grid>
            {viewType !== "MyContacts" &&
            <div className="contact_card_save_btn">
              {companyCard ? (
                <AddToBuyersListButton
                  organizationKey={contact.key}
                  type={"organization"}
                  removeOnly
                />
              ) : (
                <AddToBuyersListButton
                  contactKey={contact.key}
                  type={"contact"}
                  removeOnly
                />
              )}
            </div>
            }
          </div>

          <Grid
            item
            xs={12}
            className="dashboard__table-orders-total contact_card_data_style"
            dir="ltr"
          >
            {contact.email && Array.isArray(contact.email) && contact.email[0] && (
              <>
                <EmailOutlineIcon size={24} style={{color: "#858aa1"}} />
                <a
                  className="contact__external-link"
                  href={`mailto:${!show 
                    ? _formatEmailWithHide(contact.email[0])
                    : contact.email[0]}`}
                  target="_blank"
                >
                  {!show 
                    ? _formatEmailWithHide(contact.email[0])
                    : contact.email[0]}
                </a>
              </>
            )}
          </Grid>

          <Grid item xs={12} className="contact_card_phone_style" dir="ltr">
            {contact && contact.phone && Array.isArray(contact.phone) && contact.phone[0] && (
              <>
                <PhoneOutlineIcon size={24} style={{color: "#858aa1"}}/>{" "}
                <a
                  className="contact__external-link"
                  href={`tel:${!show 
                    ? _formatPhoneWithHide(contact.phone[0])
                    : _formatPhoneNumber(contact.phone[0])}`}
                  target="_blank"
                >
                  {!show 
                    ? _formatPhoneWithHide(contact.phone[0])
                    : _formatPhoneNumber(contact.phone[0])}
                </a>
              </>
            )}
          </Grid>
        </div>
        {timestamp && <Grid item xs={12} className="card_content text-right"><span className="light-text small-text mt-2">Added {moment(timestamp).fromNow()}</span></Grid>}
        {((contact.phone && contact.phone.length>0) || (contact.email &&  contact.email.length>0)) &&  (
          <div className="card-button-center">
            <ViewContactButton contact={contact} setShow={setShow} show={show} companyCard={companyCard ===true ? companyCard : false} />
          </div>
        )}
      </div>
    </Grid>
  );
};

export default PeopleCardContent;
