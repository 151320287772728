import React, { PureComponent, Fragment, useState } from "react";
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import ListViewIcon from "mdi-react/FormatListBulletedIcon";
import GridViewIcon from "mdi-react/GridIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Button as ReactStrapButton } from "reactstrap";
import LoadMoreIcon from "mdi-react/RefreshIcon";
import EditContact from "../../../../shared/components/edit/EditContact";
import { Link } from "react-router-dom";
import ViewContactButton from "../../../../shared/components/buttons/ViewContactButton";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _formatBed,
  _formatBath,
  _formatOwnerName,
  _formatPhoneNumber,
  ProfileAvatar,
  syncGenericFilter,
  setLocalStorage,
  _formatEmailWithHide,
  _formatPhoneWithHide,
} from "../../../../shared/helpers/utils";
import PeopleCardView from "./components/PeopleCardView";

const RenderSingleContact = ({ contact }) => {
  const [show, setShow] = useState(false);

  const roles = [];
  if (
    contact &&
    contact.project_roles &&
    Object.keys(contact.project_roles).length > 0
  ) {
    Object.keys(contact.project_roles).forEach((key) => {
      const p = contact.project_roles[key];
      p.map((r) => {
        r =
          r === "applicant"
            ? "permit applicant"
            : r === "filing_applicant"
            ? "permit applicant"
            : r;
        if (roles.indexOf(r) < 0) {
          roles.push(r);
        }
      });
    });
  }
  return (
    <tr>
      <td>
        <ProfileAvatar contact={contact} />
      </td>
      <td className="contact-table-head-fstyle" style={{ paddingRight: 0 }}>
        <Link to={`/contact/${contact["key"]}`}>
          {contact.first_name
            ? `${_capitalizeText(contact.first_name)} ${_capitalizeText(
                contact.last_name
              )}`
            : "--"}
        </Link>
      </td>
      <td className="" style={{ whiteSpace: "nowrap" }}>
        {contact.title
          ? `${_capitalizeText(contact.title)}`
          : roles[0]
          ? `${_capitalizeText(roles[0])}`
          : "--"}
      </td>

      <td className="dashboard__table-orders-total" dir="ltr">
        {contact.email && Array.isArray(contact.email) && contact.email[0] && contact.email[0].indexOf("@")>=0 && (
          <a
            className="product__external-link"
            href={`mailto:${!show ? _formatEmailWithHide(contact.email[0]) : contact.email[0]}`}
            target="_blank"
          >
           
            {!show ? _formatEmailWithHide(contact.email[0]) : contact.email[0]}
          </a>
        )}
      </td>
      <td className="" dir="ltr">
        {contact.phone && Array.isArray(contact.phone) && contact.phone[0] && (
          <a
            className="product__external-link"
            href={`tel:${!show
              ? _formatPhoneWithHide(contact.phone[0])
              : _formatPhoneNumber(contact.phone[0])}`}
            target="_blank"
          >
            <PhoneOutlineIcon size={18} />{" "}
            {!show
              ? _formatPhoneWithHide(contact.phone[0])
              : _formatPhoneNumber(contact.phone[0])}
          </a>
        )}
      </td>
      {/* <td>
            {contact.address && (
              <Fragment>
                {contact.address.full_street_address
                  ? _capitalizeText(contact.address.full_street_address)
                  : ""}
                {contact.address.city
                  ? `, ${_capitalizeText(contact.address.city)}`
                  : ""}
                {contact.address.state
                  ? `, ${_capitalizeText(contact.address.state)}`
                  : ""}
                {contact.address.zip ? ` ${contact.address.zip}` : ""}
              </Fragment>
            )}
          </td> */}
      <td className="dashboard__table-orders-total">
        <ViewContactButton
          contact={contact}
          setShow={setShow}
          show={show}
          tableView
        />
        {/* table view props is for changing button css b/w card and table view */}
      </td>
    </tr>
  );
};

const RenderContacts = ({ contacts }) => {
  return (
    <Table
      responsive
      striped
      className="dashboard__table-orders"
      style={{ borderTop: "none" }}
    >
      <thead>
        <tr>
          <th />
          <th>Contact Name</th>
          <th style={{ width: 200 }}>Title / Role</th>
          <th style={{ width: 150 }}>Email</th>
          <th style={{ width: 160 }}>Phone</th>
          {/* <th>Business Address</th> */}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {contacts
          .filter((contact) => !contact.meta.deleted)
          .map((contact, index) => (
            <RenderSingleContact key={index} contact={contact} />
          ))}
      </tbody>
    </Table>
  );
};

class Contacts extends PureComponent {
  lsPre = `PIPE_COMPANY_DB_`;
  constructor(props) {
    super(props);
    this.state = {
      viewType: syncGenericFilter("viewType", "viewType", this.lsPre, "grid"),
    };
  }

  _toggleView = (type) => {
    this.setState({ viewType: type });
    setLocalStorage(this.lsPre, "viewType", type);
  };

  render() {
    const { contacts, min, toggle, organizationRes, setChanged } = this.props;
    const { viewType } = this.state;
    if (!contacts) {
      return null;
    }

    if (min) {
      return (
        <div>
          <RenderContacts contacts={contacts.slice(0, 5)} />
          <Row>
            <Col xl={12} lg={12} className="mt-3 text-center">
              <div className="timeline_load">
                <Tooltip title="See all contacts">
                  <Button
                    onClick={() => toggle("people")}
                    variant="contained"
                    color="news"
                    className={`timeline__load-more`}
                    startIcon={<LoadMoreIcon />}
                  >
                    {" "}
                    See All People
                  </Button>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <Fragment>
        <div className="contact_card_title">
          <div className="card__title">
            <h5
              className="bold-text"
              style={{ fontWeight: "400", color: "#858AA1" }}
            >{`People Associated with ${organizationRes.name}`}</h5>
          </div>
          <div className="d-flex">
            <div>
              <EditContact
                buildingRes={null}
                organizationRes={organizationRes}
                setBuilding={null}
                add
                company
                setChanged={setChanged}
              />
            </div>
            <ButtonGroup
              size="small"
              aria-label="small outlined button group"
              style={{ marginLeft: "15px", display: "block" }}
            >
              <Tooltip title={`See projects in a photo grid`}>
                <Button
                  disabled={viewType == "grid"}
                  onClick={() => {
                    this._toggleView("grid");
                  }}
                  startIcon={
                    <GridViewIcon
                      size={14}
                      color={viewType != "grid" ? "#780F9E" : "#A3ADC2"}
                    />
                  }
                >
                  Grid
                </Button>
              </Tooltip>
              <Tooltip title={`See projects in a list`}>
                <Button
                  disabled={viewType == "list"}
                  onClick={() => {
                    this._toggleView("list");
                  }}
                  startIcon={
                    <ListViewIcon
                      size={14}
                      color={viewType != "list" ? "#780F9E" : "#A3ADC2"}
                    />
                  }
                >
                  List
                </Button>
              </Tooltip>
              {/* <MatTableFilterButton
                handleRequestSort={this.handleRequestSort}
                type={"contact"}
              /> */}
            </ButtonGroup>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <Card>
              <CardBody style={{ border: "none" }}>
                {viewType == "grid" ? (
                  <PeopleCardView {...this.state} {...this.props} />
                ) : (
                  <div className="material-table__wrap">
                    <div className="material-table expansion__table">
                      <RenderContacts contacts={contacts} />
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Contacts;
