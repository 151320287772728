import axios from "axios";
import React, { Fragment } from 'react';
import { 
	BuildingStageBadge,
	ShowBadge
} from "./../../helpers/utils";

import IconButton from '@material-ui/core/IconButton';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Tooltip } from '@material-ui/core';
import StarIcon from 'mdi-react/StarCircleIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_formatAddressAutoCompletePipeline,
	_selectBestSlug,
	_validStrTags,
	_nameToUrl,
	_checkBuildingNameVsAdr,
	_replaceAbbreviations,
	_validArrTags
} from '../../../shared/helpers/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = (options, { inputValue }) => { return options }
const apiUrl = process.env.API_ROOT;  
  
const ProjectSearch = (props) => {
  const { onSelect, addBuilding, query, type, alwaysAddTag, addProject, hitsPerPage} = props;

  const [hits, setHits] = React.useState([]);

  React.useEffect(() => {
     let active = true;
     
     
     axios({ method:'get', url: `${apiUrl}/pipeline/autocomplete/projects?input=${query}&type=${type}`}).then((results) => {
      if (active) {
        let newOptions = [];


        if (results && results.data && Array.isArray(results.data)) {
          newOptions = [...newOptions, ...results.data.slice(0, 5)];
        }
		if(props.query && newOptions.length>0){
			newOptions = newOptions.map(option => {
				
				
				option = Object.assign({}, option, option['address']);
				let bestAddress = _formatAddressAutoCompletePipeline(option, query);
				
				if(bestAddress.building_display){
					option.display_full_street_address = bestAddress.building_display;
				}
				
				return option;
				
			});
		}
		
        setHits(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [query]);

  let slice = hitsPerPage ? hitsPerPage : 999;
  return (
   
  		 <Fragment>
			 {hits && hits.length>0 &&
			   <div className={`c_search-results ${query === "" ? "hidden" : ""}`}>
			    <h5 className="">PIPELINE PROJECTS</h5>
			    {hits
			      .filter(hit => hit.display_full_street_address).slice(0, slice)
			      .map(hit => {
			        hit.primary_adr = true;
			        hit = _formatAddressAutoCompletePipeline(hit, query);
			        //hit = _selectBestSlug(hit);
			        const boro = _validStrTags(hit.borough);
			        const hood = _validArrTags(hit.neighborhood);
			        const urlBoro = boro ? _nameToUrl(boro) : "new-york";
			        const urlHood = hood ? _nameToUrl(hood) : "nyc";
			        const buildingName = _checkBuildingNameVsAdr(hit);
			        const address = hood ? hood + ", " + boro : boro;
			
			        return (
			          <div className="c_result" key={hit["_id"]}>
			            <div
			              onClick={onSelect.bind(
			                this,
			                {
			                  building: {
			                    _id: hit["_id"],
			                    display_adr: hit.display_full_street_address,
			                    hood: _urlToName(urlHood),
			                    boro: _urlToName(urlBoro)
			                  },
			                  // tab: Object.assign(tabInfo.building.tabs[0], {
			                  //   data: false,
			                  //   type: "building"
			                  // })
			                },
			                hit,
			                alwaysAddTag ? true : false
			              )}
			              id="tags"
			              className="c_search-hit"
			            >
			              {/*<Highlight attributeName="display_text" hit={hit} tagName="strong" /> */}
			                {addProject && <span className={'c_blg-add'}>
			                	<Tooltip title="Add this building to search">
			                      	<IconButton
							            onClick={(e) => {e.stopPropagation();e.preventDefault();onSelect(				                
							                {
							                  building: {
							                    _id: hit["_id"],
							                    display_adr: hit.display_full_street_address,
							                    hood: _urlToName(urlHood),
							                    boro: _urlToName(urlBoro)
							                  },
							                  // tab: Object.assign(tabInfo.building.tabs[0], {
							                  //   data: false,
							                  //   type: "building"
							                  // })
							                },
							                hit,
							                true
							              )}}
			
							            className=""
							            size={'small'}
							            style={{background:"transparent"}} 
							          >
									  <AddIcon size={20} style={{ marginRight:"3px"}} color={'#780F9E'}  />
								  	</IconButton>
							  	</Tooltip>	
							</span>
							}
							<span className={`c_bldg_container`}>
				                <span className={`c_bldg`}>
				                {hit.building_display
				                  ? _capitalizeText(hit.building_display)
				                  : _capitalizeText(
				                      _replaceAbbreviations(hit.display_full_street_address)
				                    )}
				                </span>
				                <span className={`c_name`}>
				                	{_shouldShow(buildingName)
				                  ? " (" + _capitalizeText(buildingName) + ")"
				                  : ""}
				                </span>
				                       
				                <span className="c_search-hit-details">{address}</span>
				                <BuildingStageBadge item={hit} small />
				                {/*<Tooltip title="Pipeline Project">
				                	<IconButton size={'small'} style={{background:"transparent"}} >
				                		<StarIcon size={16} style={{ marginRight:"3px"}} color={'#FFD23F'}  />
				                	</IconButton>
				                </Tooltip>*/}
				            </span>
			            </div>
			          </div>
			        );
			      })}
			  </div>
			  }
			  </Fragment> 
    );
}

export default ProjectSearch;