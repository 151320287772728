import React from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import MainIcon from 'mdi-react/ViewGridPlusIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/MagnifyIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import AddCollectionButton from '../../../../../shared/components/collections/AddCollectionButton';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const EmptyList = ( props ) => {

  return (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className={`email-confirmation`}>
          <div className="email-confirmation__icon">
            <MainIcon className="email-confirmation__mail" />
          </div>
          <h3 className="email-confirmation__title">{`No Collections`}</h3>
         
         
          <ButtonToolbar className="email-confirmation__buttons">
          
          <AddCollectionButton {...props} add /> 
        
		     
          </ButtonToolbar>
        </div>
      </CardBody>
    </Card>
  </Col>
  )
};


export default EmptyList;
