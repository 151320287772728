import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Row, Col, Progress, Badge, Container, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableToolbar from './MatTableToolbar';
import MatTableFiltersSelect from './MatTableFiltersSelect';
import MatTableFilterButton from './MatTableFilterButton';

import MatTableView from './MatTableView';
import MatGridView from './MatGridView';
import MatMapView from './MatMapView';

import MatAppBar from './MatAppBar';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import PreviewIcon from 'mdi-react/EyeOutlineIcon';
import OpenInNew from 'mdi-react/OpenInNewIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import LinearLoading from '../../../shared/components/LinearLoading';


import ListViewIcon from 'mdi-react/FormatListBulletedIcon';
import GridViewIcon from 'mdi-react/GridIcon';
import MapViewIcon from 'mdi-react/MapOutlineIcon';


import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CondoDecIcon from 'mdi-react/AlphaCCircleOutlineIcon';

import BuildingIcons from '../../../shared/components/BuildingIcons';

import EditOrganization from '../../../shared/components/edit/EditOrganization';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";


import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	 ConstructionProgress,
	BuildingStatus,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	getCohortQuery,
	StatusBadge,
	GenerateBuildingSlug, 
	GenerateBuildingKey,
	_tranformTagsMongoQuery
} from "../../../shared/helpers/utils";

import { 
	_buildingAdminFilters,
	_buildingStageFilter
} from "../../../shared/helpers/filters";

import queryString from "query-string";


let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}
const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(buildingRes.offering_verified_status === 'abandoned'){
		return "--";
	}
	const sales_year = parseInt(moment(buildingRes.sales_start_date).format('YYYY'));
	if(buildingRes.building_status === 'resale'){
		
		if(!buildingRes.sales_start_date || sales_year <= 1970){
			return "--";
		}
		
		const offering_effective_year = buildingRes.offering_effective_date ?  parseInt(moment(buildingRes.offering_effective_date).format('YYYY')) : 0;
		const first_closing_year = buildingRes.first_closing_date ?  parseInt(moment(buildingRes.first_closing_date).format('YYYY')) : 0;
		
		if(sales_year > offering_effective_year){
			return "--";
		}
		if(sales_year > first_closing_year){
			return "--";
		}
	}
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}
const calcuateTCODate = (buildingRes, type) => {		
	
	if(buildingRes.offering_verified_status === 'abandoned'){
		return "--";
	}
	const tco_year = parseInt(moment(buildingRes.building_effective_start).format('YYYY'));
	if(buildingRes.building_status === 'resale'){
		
		if(!buildingRes.sales_start_date || tco_year <= 1970){
			return "--";
		}
		
		const offering_effective_year = buildingRes.offering_effective_date ?  parseInt(moment(buildingRes.offering_effective_date).format('YYYY')) : 0;
		const first_closing_year = buildingRes.first_closing_date ?  parseInt(moment(buildingRes.first_closing_date).format('YYYY')) : 0;
		
		if(tco_year > offering_effective_year){
			return "--";
		}
		if(tco_year > first_closing_year){
			return "--";
		}
	}
	if(!buildingRes.building_effective_start || parseInt(moment(buildingRes.building_effective_start).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return '--';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.building_effective_start).fromNow();
	}	
	return `(${moment(buildingRes.building_effective_start).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/pipeline/';

const subFilterKey = 'subFilters';
const buildingStagesKey = 'buildingStages';
const defaultSubFilter = {};
const defaultStage = ["all"];


const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
	 <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};

const getSubFilters = ( { stage, match } ) => {
	
	
	return [];
}

class MatTable extends PureComponent {
	
  lsPre = `COMPANY_LIST_DB`;
  buildingSubFilters = getSubFilters( this.props );
  
  state = {
    order: syncGenericFilter('order', this.lsPre, 'order', "desc"),
    orderBy: syncGenericFilter('order_by', this.lsPre, 'orderBy', "name"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: this.props.isSingle ? 8 : this.props.maxCount < 40 ? 20 : 40,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	// filterFetchInProgress: false,
	filterStage: "all_projects",
	futureInventoryData: false,
	// headerHeight: 'auto',
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	tableFiltered: false,
	// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, this.buildingSubFilters, subFilterKey, this.lsPre, defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	searchText: syncURLSearch(this.props, 'searchText'),
	viewType: "list",
	facetData: null,
	buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, this.lsPre, defaultStage),
	type: this.props.match && this.props.match.params.type ? this.props.match.params.type : 'residential',
  };
  
  q = '';
  fq = '';
  sort = '';
  
  componentDidMount() {
  	//this._getInventoryStats();
  	this._getPipelineBuildings();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.q, this.props.q);

			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			searchTagsChange
			
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)	
			  this._getPipelineBuildings();
		}

	  
  }

  handleRequestSort = (event, property, setOrder) => {
    const orderBy = property;
    let order = setOrder ? setOrder : 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc' && !setOrder) { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  
  shouldLinkToMP = (building) => {
	   const { user } = this.props;
	   return user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 || ['active', 'coming soon', 'offering plan required', 'offering plan acquired', 'condo declaration vetted'].indexOf(building.offering_verified_status) < 0
  }
  
  handleBuildingClick = (event, building) => {
	
	 this.handleBuildingPreviewClick(event, building);

  };
  
  handleBuildingPreviewClick = (event, contact) => {
	 event.preventDefault();
	 event.stopPropagation();
	 const url = `/project/${contact["_id"]}`;
	 
	this.props.history.push(url);
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  _getPipelineBuildings = async (query, resetPage, obj) => {
	  const { user, searchTags, organizationRes, contactRes, companyKey, q } = this.props;
	  const { buildingStages, type, start, rowsPerPage, page, orderBy, order } = this.state;
	  if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
	  }
	  
	  let searchFilters = q.slice(0);
	  let baseQuery = { "organizations.key": { "$in" : [companyKey]} }
	  
	
	  
	  searchFilters.push(baseQuery);
	  		
	  let sort = {};
	  
	  sort[orderBy] = order === "asc" ? 1 : -1;
	  setURLFromState(this.props, this.state);
	  let mongoQuery = {
			"query": { "$and" : searchFilters },		    
		    "sort" : sort,
		    "limit" : parseInt(rowsPerPage),
		    "skip" : parseInt(start)	    
		}

	  let axiosData = {
			url: buildingUrl + "query",
			method: "post",
			query: mongoQuery		
	   		}

	   
	   _axiosCall(axiosData)
		.then(res => {
			// console.log('Chart Data', res);
			let docs = [];
			if(res && res.docs){
				docs = res.docs;
			}
/*
			if(res && res[0] && res[0].paginatedResults){
				docs = res[0].paginatedResults;
				
			}
*/
			docs.forEach(doc => {
				//doc = Object.assign(doc, doc['attributes']);
				//delete(doc['attributes'])
				//doc = Object.assign(doc, doc['address']);
				//delete(doc['address']);
				doc['building_slugs'] = [GenerateBuildingSlug(doc)];
				doc['solr_key'] = doc.building_key ? doc.building_key : GenerateBuildingKey(doc);
				doc['master_key'] = doc['key'];
			})
			 this.setState(prevState => ({
				buildingsFetchInProgress: false,
				tableData:  docs,
				tableFiltered:  docs,
				totalCount: res.count && res.count, //res[0].totalCount[0].key,
				start: resetPage ? 0 : start,
				page: resetPage ? 0 : page,
				stats: {},
				facetData: {}
				
			}));
		})
		.catch(error => {
			console.log("error: " + error);
		});
	   
	   
	  
	  
  }

	
	
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	_filterResponseTableData = eValue => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter(item => {
						// console.log("filterData item", item)
						const inputVal = eValue.toLowerCase();
						let nameVal, adrVal, hoodVal;
						if (item.name) {
							// console.log('item has name')
							nameVal = item.name.toLowerCase();
						}

						if (
							(item.display_full_street_address &&
								_shouldShow(item.display_full_street_address)) ||
							(item.full_street_address &&
								_shouldShow(item.full_street_address))
						) {
							const field = item.display_full_street_address
								? "display_full_street_address"
								: "full_street_address";
							// console.log('item has adr', field)

							adrVal = item[field].toLowerCase();
						}

						if (item.neighborhood && _shouldShow(item.neighborhood)) {
							const hood =
								Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
									? item.neighborhood[0]
									: item.neighborhood;
							hoodVal = hood.toLowerCase();
						}

						// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
						// searching name and ONE adr field - could update to search both display and full and all alt adrs
						return (
							(nameVal && nameVal.indexOf(inputVal) >= 0) ||
							(adrVal && adrVal.indexOf(inputVal) >= 0) ||
							(hoodVal && hoodVal.indexOf(inputVal) >= 0)
						);
				  })
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState(prevState => ({
			// buildingsFetchInProgress: false,
			tableFiltered: filteredData,
		    //tableData: filteredData,
		    //originalData: response,
		    //filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.tableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			tableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = ["sponsor"]
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = ['all'];
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(this.lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages : defaultStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(this.lsPre, buildingStagesKey);
		deleteLocalStorage(this.lsPre, subFilterKey);
	}
	
	_resetActive = () => {
		
		let isDefault = this.props.searchTags.length===0 &&
						_objectEqual(defaultSubFilter, this.state.subFilters) &&
	  					_objectEqual(defaultStage, this.state.buildingStages);
	    return !isDefault;
		
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = (type) => {

		this.setState( { viewType : type } )
			
	}


  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, tableFiltered, subFilters, totalCount, viewType, buildingsFetchInProgress, type
    } = this.state;
    const { user, isSingle, min, toggle } = this.props;
    if(!tableData){
	    return null;
    }
    const tableCount = totalCount;
    
	if(tableFiltered === false){
		return  <LinearLoading />
	}

    return (
	  <Fragment>
	  
		  <Row>
		      <Col md={12} lg={12}>
		        <Card>
		          <CardBody style={{ padding:0 }} >
		           
		            
		            {  viewType == "map" ? 
			            <MatMapView 
			           		{...this.state} 
			           		{...this.props} 
			           		handleChangePage={this.handleChangePage} 
			           		handleChangeRowsPerPage={this.handleChangeRowsPerPage} 
			           		isSelected={this.isSelected}
			           		handleBuildingClick={this.handleBuildingClick}
			           		handleSelectAllClick={this.handleSelectAllClick}
			           		handleRequestSort={this.handleRequestSort}
			           	/>
			         : viewType == "grid" ?
			            <MatGridView 
			           		{...this.state} 
			           		{...this.props} 
			           		handleChangePage={this.handleChangePage} 
			           		handleChangeRowsPerPage={this.handleChangeRowsPerPage} 
			           		isSelected={this.isSelected}
			           		handleBuildingClick={this.handleBuildingClick}
			           		handleSelectAllClick={this.handleSelectAllClick}
			           		handleRequestSort={this.handleRequestSort}
			           	/>
			            
			         : 
			           <MatTableView 
			           		{...this.state} 
			           		{...this.props} 
			           		handleChangePage={this.handleChangePage} 
			           		handleChangeRowsPerPage={this.handleChangeRowsPerPage} 
			           		isSelected={this.isSelected}
			           		handleBuildingClick={this.handleBuildingClick}
			           		handleSelectAllClick={this.handleSelectAllClick}
			           		handleRequestSort={this.handleRequestSort}
			           		
			           	/>
		           
			        }
		          </CardBody>
		        </Card>
		      </Col>
		    </Row>
	    </Fragment>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(MatTable));