import React, { useState, Fragment, useRef } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar, Badge,  Nav, NavLink, NavItem, TabContent, TabPane, Container, Modal, 
} from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import HeartIcon from 'mdi-react/HeartIcon';
import ImageIcon from 'mdi-react/DownloadIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import classnames from 'classnames';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';


import moment from 'moment';

import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';

import { _hasPermission	} from "../../helpers/access";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_slugifyText,
	_makeFileName
} from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  formControl:{
	  width: '100%',
	  marginBottom:'20px'
  },
  button:{
	  color: '#780F9E',
	  marginBottom:'10px'
  },
  radio:{
  	focused: {
		 color:'#780F9E' 
    },
    root:{
	    '& > *': {
		    fontSize:'12px',
		    flexDirection: 'initial'
		}
    }  
  }
}));

const MPCheckbox = withStyles({
  root: {
    color: '#5C6178',
    '&$checked': {
      color: '#780F9E',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const MPRadio = withStyles({
  root: {
    color: '#5C6178',
    '&$checked': {
      color: '#780F9E',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const ExportComponentArea = React.forwardRef(( props, ref) => {
	const { component, exportTitle, branding } = props;
	return (
		<div className={`export-container`}>
		    <div className={`export-area`} ref={ref} style={{ padding:'50px 70px 50px 50px', position:'fixed', zIndex:-9999, bottom:0, right:0, background:'#FFF'}}>
		    	{exportTitle && <div className={`title`}>
		    		<h3>{exportTitle}</h3>
		    		<h5>Data as of: {moment().format('MM/DD/YYYY')}</h5>
		    		<hr/>
		    	</div>
		    	}
		    	{component}
		    	
		    	<Row className="export-footer mt-3">			  			        	
					<Col xl={6} lg={6} md={6}>
						{branding === 'elegran' && 
					    	<div className={`branding left elegran-export`}>
				    			<div className={'export__logo'} />
							</div>
						}
					</Col>
		    		<Col xl={6} lg={6} md={6}>
				    	 
					    	<div className={`branding`}>
				    			<div className={'export__logo'} />
				    			<div>
				    				<a href="https://marketproof.com/new-development" className="link-text">{`https://marketproof.com/new-development`}</a>
				    			</div>
							</div>
						
						
					</Col>
				</Row>
		    	
		    </div>
		</div>
	 )
})


const ExportComponentButton = (props) => {
	
  const { title, buttonStyle, user } = props;
  const [modal, setModal] = useState(false); 
  const [branding, setBranding] = useState('marketproof');
  const [buttonText, setButtonText] = useState('Download PNG'); 
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [exportTitle, setExportTitle] = useState(title); 
  const classes = useStyles();

  const componentRef = useRef();
  const toggleModal = () => {
	  if(modal){
		setModal(false) 
	  }else{
		setExportTitle(title)
		setModal(true)
	  }
  }
  const handleChange = event => {
	  if(event.target.name === 'title'){
		  setExportTitle(event.target.value);
	  }
	  if(event.target.name === 'branding'){
		  setBranding(event.target.value)
	  }
	  if(event.target.name === 'branding-checkbox'){
		  if(branding == 'elegran'){
			  setBranding('marketproof')
		  }else{
			  setBranding('elegran')
		  }
		  
	  }
	 

  };
  
  const doExport = () => {
	  setButtonText('Processing...');
	  setButtonDisabled(true);
	  exportComponentAsPNG(componentRef, {fileName : _makeFileName(exportTitle), type:null } );
	  setTimeout(()=>{ setButtonText('Download PNG'); setButtonDisabled(false);}, 1200);

	  
  }
  return (   
	  <Fragment>
		   {_hasPermission(user, 'download-image')	? 
				<Fragment>
			    	<div style={{textAlign:"right"}}>
						<Tooltip title={`Export this section as a PNG image`}>
					        <Button
						         onClick={toggleModal}
						         color="secondary"
						         	         
						         startIcon={<ImageIcon />}
						         style={buttonStyle && buttonStyle}
						         size={'small'}
						         className={classes.button}
						     >	Download
						     </Button>				 
					  	</Tooltip>
					</div>
					{modal &&
						  <ExportComponentArea {...props} exportTitle={exportTitle} ref={componentRef} branding={branding} />				  
					 }
					<Fragment>
				        <Modal
				          isOpen={modal}
				          toggle={toggleModal}
				          modalClassName={`ltr-support`}
				          className={`modal-dialog contact__modal share__modal`}
				        >
				        <div className="modal__header">
				            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
				          </div>
						  <Row>			  			        	
							<Col xl={12} lg={12} md={12}>
								<div className="modal__body background_white">
									<h4 className="contact__title">
										Download as PNG								
									</h4>
									<hr/> 
									<FormControl className={classes.formControl}>
						        	  <TextField 
						        	    id="title" 
						        	  	label={'Title'} 
						        	  	onFocus={()=>{}}
						        	  	name="title"
						        	  	value={exportTitle ? exportTitle : ''}
						        	  	onChange={handleChange}
						        	  	autoComplete={'off'}
						        	  	variant="outlined"
						        	   />
						        	   
							        </FormControl>
							        {false && _hasPermission(user, 'change-branding') &&
								        <Fragment>
									       {/*<FormControl component="fieldset" className={classes.radio}>
										      <FormLabel component="legend" className={classes.radio}>Branding (Enterprise Subscriptions Only)</FormLabel>
											      <RadioGroup aria-label="branding" name="branding" value={branding} onClick={handleChange} style={{flexDirection:'initial'}} >
											        <FormControlLabel value="marketproof" control={<MPRadio />} label="Marketproof" />
											        <FormControlLabel value="elegran" control={<MPRadio />} label="Add Elegran Logo (Elegran Agents Only)" />
											        <FormControlLabel value="no-branding" control={<MPRadio />} label="No Branding" />
											      </RadioGroup>
										    </FormControl> */}
									    <FormControlLabel
									        control={<MPCheckbox checked={branding == "elegran"} onChange={handleChange} name="branding-checkbox" />}
									        label="Add Elegran Branding (Elegran Agents Only)"
									    />
									    </Fragment>
								    }
							        <br/>
							        <br/>
									<ButtonToolbar className="modal__footer">
						            <Tooltip title="Cancel">
								        <Button
									         onClick={toggleModal}
									         variant="outlined"
									         color=""
									         className=""		         
									         startIcon={null}
									     >	Cancel
									     </Button>				 
								  	</Tooltip>{' '}
						            <Tooltip title="Download PNG">		
								        <Button
									         variant="contained"
									         color="primary"
									         className="buyersList__primary-button"		         
									         startIcon={<ImageIcon />}
									         onClick={doExport}
									         style={{width:'180px'}}
									         disabled={buttonDisabled}
									     >	{buttonText}
									     </Button>	
							 
								  	</Tooltip>
						         </ButtonToolbar>
						            
						        </div>				        	        	
							</Col>				  	
						  </Row>
						
				        </Modal>
				         
				    </Fragment>
			    </Fragment>
			    :
			    null
			}
		</Fragment>
  );
};

export default ExportComponentButton;
