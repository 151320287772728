import React, { Fragment } from "react";
import { Container, Col, Row, ButtonToolbar } from "reactstrap";

import FeatureIcon from "mdi-react/CheckCircleOutlineIcon";

import {
  _capitalizeText,
  _chunkArray,
  _getFeaturesArr,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
} from "./../../../../../shared/helpers/utils.js";

import { amenitiesList } from "./../../../../../shared/helpers/bp-utils.js";
import { userEditFieldsObj } from "./../../../../../shared/helpers/userEditFields.js";

export default function BuildingAmenities(props) {
  const { isHouse, buildingRes, small } = props;
  const compiledFeatureList = _getFeaturesArr("building", isHouse, buildingRes);
  const highlightedAmenities = amenitiesList.filter((item) =>
    compiledFeatureList.some((el) => item.values.includes(el))
  );
  // console.log('SSR highlightedAmenities', highlightedAmenities)
  const buildingAmenitiesFiltered = compiledFeatureList.filter(
    (item) => !amenitiesList.some((el) => el.values.includes(item))
  );
  // console.log('SSR buildingAmenitiesFiltered', buildingAmenitiesFiltered)

  // const buildingAmenities = amenitiesList.filter(item => amenitiesData.some(el => item.values.includes(el)));
  const rowCount =
    highlightedAmenities && highlightedAmenities.length > 0
      ? highlightedAmenities.length < 5
        ? highlightedAmenities.length
        : highlightedAmenities.length < 9
        ? Math.ceil(highlightedAmenities.length / 3)
        : 4
      : false;

  const rows = rowCount ? _chunkArray(highlightedAmenities, rowCount) : false;

  let otherAmenities =
    buildingRes &&
    _shouldShow(buildingRes.amenities_other) &&
    Array.isArray(buildingRes.amenities_other)
      ? buildingRes.amenities_other.filter(
          (item) => !userEditFieldsObj.filterOtherAmenities.includes(item)
        )
      : false;
  // const mainFeatures = buildingAmenitiesFiltered && buildingAmenitiesFiltered.length > 0
  // 	? buildingAmenitiesFiltered.filter(item => userEditFieldsObj.buildingAmenities.includes(item))
  // 	: false;

  const mainFeatures = [].concat
    .apply(
      [],
      userEditFieldsObj.buildingAmenities
        .map((feature, i) => {
          if (
            feature.values.filter((item) => {
              return (
                buildingAmenitiesFiltered &&
                (buildingAmenitiesFiltered.includes(item.key) ||
                  (item.building &&
                    buildingAmenitiesFiltered.includes(item.building.key)))
              );
            }).length > 0
          ) {
            return feature.values
              .filter((item) => {
                return (
                  buildingAmenitiesFiltered &&
                  (buildingAmenitiesFiltered.includes(item.key) ||
                    (item.building &&
                      buildingAmenitiesFiltered.includes(item.building.key)))
                );
              })
              .map((item, i) => {
                return item.display;
              });
          }
        })
        .filter((el) => el)
    )
    .filter((el, i, arr) => arr.indexOf(el) === i);

  const featuresLen =
    mainFeatures && mainFeatures.length > 0
      ? otherAmenities && otherAmenities.length > 0
        ? mainFeatures.length + otherAmenities.length
        : mainFeatures.length
      : otherAmenities && otherAmenities.length > 0
      ? otherAmenities.length
      : 0;

  const amenitiesLenGreaterThanTwo =
    featuresLen > 1 ||
    (highlightedAmenities && highlightedAmenities.length > 1) ||
    featuresLen + highlightedAmenities.length > 1;

  // console.log('BuildingAmenities amenitiesLenGreaterThanTwo', amenitiesLenGreaterThanTwo, 'mainFeatures', mainFeatures, 'highlightedAmenities', highlightedAmenities)
  // console.log('BuildingAmenities rowCount', rowCount, "rows", rows, otherAmenities)
  if (!amenitiesLenGreaterThanTwo) {
    // if((!rowCount || (rowCount && highlightedAmenities.length < 2 )) && ((!otherAmenities || otherAmenities && otherAmenities.length < 2) && !showFeatures)) {
    return null;
  } else {
    return (
      <Fragment>
        <Container className="mobile-no-padding">
          {rows &&
            rows.map((row, i) => (
              <Row>
                {row.map((item, i) => (
                  <Col
                    xl={12 / rowCount}
                    md={6}
                    className="amenity"
                    key={`bp-${item.name}-${i}`}
                  >
                    {props.isDesktop ? (
                      <Fragment>
                        {item.icon}
                        <h5>{_capitalizeText(item.name)}</h5>
                      </Fragment>
                    ) : (
                      <h5>
                        {item.icon} {_capitalizeText(item.name)}
                      </h5>
                    )}
                  </Col>
                ))}
              </Row>
            ))}

          {((mainFeatures && mainFeatures.length > 0) ||
            (otherAmenities && otherAmenities.length > 0)) && (
            <Fragment>
              {/*<p>&nbsp;</p>
								<h3>Additional Amenities</h3>*/}
              <Row className="more-amenities">
                {mainFeatures &&
                  mainFeatures.length > 0 &&
                  mainFeatures.map((item, i) => {
                    return (
                      <Col
                        xl={small ? 4 : 2}
                        lg={small ? 4 : 3}
                        md={4}
                        sm={6}
                        xs={6}
                        className="amenity-small"
                        key={`bp-amenities-small-${i}`}
                      >
                        <span>
                          <FeatureIcon /> {_capitalizeText(item)}
                        </span>
                      </Col>
                    );
                  })}

                {otherAmenities && otherAmenities.length > 0 && (
                  <Fragment>
                    {otherAmenities.map((item, i) => {
                      return (
                        item && (
                          <Col
                            xl={small ? 4 : 2}
                            lg={small ? 4 : 3}
                            md={4}
                            sm={6}
                            xs={6}
                            className="amenity-small"
                            key={`bp-amenities-other-${i}`}
                          >
                            <span>
                              <FeatureIcon />{" "}
                              {_capitalizeText(
                                _urlToName(item.replace(/_/g, " "))
                              )}
                            </span>
                          </Col>
                        )
                      );
                    })}
                  </Fragment>
                )}
              </Row>
            </Fragment>
          )}

          <p>&nbsp;</p>
        </Container>
      </Fragment>
    );
  }
}
