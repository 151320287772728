/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/CircleSlice7Icon';
import Panel from './../../../../../shared/components/Panel';

import { 
	_formatPrice,
	_formatNumber
} from "../../../../../shared/helpers/utils";

import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';

const ShadowInventory = ( { data } ) => {

  if(!data) return null;
	
  return (	
	  <Col md={12} xl={4} lg={4} xs={12}>
	    <Card>
	        <CardBody className="dashboard__card-widget card__link">
		        <Link to={`/inventory?offering_status=shadow`}>
		            <div className="card__title">
		              <h5 className="bold-text">{`Shadow Inventory`}</h5>
		            </div>
		        
		      <div className="mobile-app-widget">
		          <div className="mobile-app-widget__top-line">
		            <p className="mobile-app-widget__total-stat">{_formatNumber(data.shadow.count)}</p>
		            <Icon className="dashboard__trend-icon" />
		          </div>
		          <div className="mobile-app-widget__title">
		            <h5>{'Off-market Units'}</h5>
		          </div>
		           <p className="progress__label"><span style={{ color:"#9600BF" }}>{(((data.shadow.count) / data.unsold.count) * 100).toFixed(0)}%</span>  <span className="small-text light-text"> of Unsold Units Are Off-Market</span></p>  
		          {/*<div className="progress-wrap progress-wrap--small
		          progress-wrap--turquoise-gradient progress-wrap--label-top"
		          >
		            <Progress value={62}>
		            	<div style={{ position:"absolute", right:0, fontSize:"10px", bottom:"10px", color:"#4ce1b6" }}>62%</div>
		            		<p className="progress__label">8,850 <span className="small-text light-text">Unsold</span></p> 
		            </Progress>
		          </div>*/}
	          </div>
	          </Link>
			</CardBody>
	    </Card>
	  </Col>
  )
};


export default withTranslation('common')(ShadowInventory);
