import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import React, { useState } from "react";
import {
  ProfileAvatar,
  _capitalizeText,
  _formatEmailWithHide,
  _formatPhoneNumber,
  _formatPhoneWithHide,
} from "../../../../../shared/helpers/utils";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PhoneOutlineIcon from "mdi-react/PhoneOutlineIcon";
import { Button } from "reactstrap";
import PeopleCardContent from "./PeopleCardContent";
import { Grid } from "@material-ui/core";

export default function PeopleCardView({
  contacts,
  toggle,
  showLink,
  companyCard,
  viewType
}) {
  return (
    <Grid container className="card_profile_wrap">
      {contacts
        .filter((contact) => contact && contact.meta && !contact.meta.deleted)
        .map((contact, index) => {
          const roles = [];
          if (
            contact &&
            contact.project_roles &&
            Object.keys(contact.project_roles).length > 0
          ) {
            Object.keys(contact.project_roles).forEach((key) => {
              const p = contact.project_roles[key];
              p.map((r) => {
                r =
                  r === "applicant"
                    ? "permit applicant"
                    : r === "filing_applicant"
                    ? "permit applicant"
                    : r;
                if (roles.indexOf(r) < 0) {
                  roles.push(r);
                }
              });
            });
          }
          return (
            <PeopleCardContent
              contact={contact}
              index
              roles={roles}
              companyCard={companyCard}
              viewType={viewType ? viewType : false}
              timestamp={contact.timestamp ? contact.timestamp : false}
            />
          );
        })}
      {showLink && (
        <Button
          onClick={() => toggle("people")}
          variant="contained"
          color="#780F9E"
          style={{ color: "#780F9E" }}
        >
          See All People
        </Button>
      )}
    </Grid>
  );
}
