import React, { Fragment } from "react";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "mdi-react/MenuIcon";
import SearchIcon from "mdi-react/SearchIcon";
import AccountCircle from "mdi-react/AccountCircleIcon";
import ResetIcon from "mdi-react/AutorenewIcon";
import NotificationsIcon from "mdi-react/NotificationsIcon";
import MoreIcon from "mdi-react/MoreVertIcon";
import ChartIcon from "mdi-react/ChartPieIcon";

import UnitsSearchTableFiltersSelect from "./UnitsSearchTableFiltersSelect";

import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";

import Search from "../../../../../shared/components/algolia/Search";

import ExportButton from "../../../../../shared/components/data_export/ExportButton";

import {
  _capitalizeText,
  searchBarStyles,
} from "../../../../../shared/helpers/utils";

export default function UnitsSearchAppBar(props) {
  const classes = searchBarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const {
    handleSearch,
    numSelected,
    handleDeleteSelected,
    onRequestSort,
    subFilters,
    setSubFilter,
    resetSubFilter,
    _filters,
    searchText,
    building,
    buildingView,
    setSubFilters,
    advFilterName,
    UnitStageFilter,
  } = props;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <div style={{ textAlign: "right" }}>
        <IconButton
          aria-label="Reset Filters"
          onClick={resetSubFilter}
          color="inherit"
        >
          <ResetIcon style={{ color: "#BFC4D1" }} />
        </IconButton>
        <IconButton
          aria-label="Close Filters"
          onClick={handleMobileMenuClose}
          color="inherit"
        >
          <CloseCircleOutlineIcon style={{ color: "#BFC4D1" }} />
        </IconButton>
      </div>
      <UnitsSearchTableFiltersSelect
        subFilters={subFilters}
        setSubFilter={setSubFilter}
        _filters={_filters}
        building={building}
        {...props}
        advFilterName={advFilterName}
      />
    </Menu>
  );
  let timeout = null;
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/*   <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            {buildingView ? (
              <InputBase
                placeholder={`Address, Neighborhood, Borough or Job Number…`}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onKeyUp={(e) => {
                  clearTimeout(timeout);
                  const searchText = e.target.value;
                  console.log("key up", e.target.value);
                  timeout = setTimeout(
                    function (searchText) {
                      console.log("handle search", searchText);
                      handleSearch("name", searchText);
                    }.bind(this, searchText),
                    800
                  );
                }}
              />
            ) : (
              <Search
                handleSearch={handleSearch}
                placeHolder={`Search Job Number`}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                hideProjects
                hideOrganizations
                hidePeople
                hideBuilding
                hideBorough
                hideNeighborhood
                allowTextSearch
              />
            )}
          </div>

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {/*<ExportButton {...props} />
            <UnitsSearchTableSortButton onRequestSort={onRequestSort} {...props} />*/}
          </div>
          {/*         <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div> */}
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.sectionDesktop}>
        <UnitsSearchTableFiltersSelect
          subFilters={subFilters}
          setSubFilter={setSubFilter}
          _filters={_filters}
          {...props}
          advFilterName={advFilterName}
          UnitStageFilter={UnitStageFilter}
        />
      </div>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
