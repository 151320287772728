import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';
import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/InformationOutlineIcon';
import ContactIcon from 'mdi-react/CalendarIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';
import Fab from '@material-ui/core/Fab';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ContactForm from './ContactForm';
import ShareContact from '../../../shared/components/ShareContact'

import SnackBar from '../../../shared/components/SimpleSnackBar';

import { Publisher } from '../../../shared/pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/pipeline/'; //'http://localhost:8000/buyerslist/';

class ShareContactButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: 'Schedule a Tour',
	    modal: false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
		errors: false,
		showButon: false
	    
	};
	this.toggle = this.toggle.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
    
  }
  
  componentDidMount() {
	  
	  setTimeout(() => {
	  	console.log(window.document.body.offsetHeight, window.innerHeight)
	  	{/* if(document.body.clientHeight > window.innerHeight){
		  	window.addEventListener("scroll", (e) => {this.setState({showButon:true})});
	  	}else{
		  	this.setState({showButon:true});
	  	} */}
		this.setState({showButon:true});
	}, 3000);
	  
	  
  }
  
  componentWillUnmount(){
	  window.removeEventListener("scroll", (e) => {this.setState({showButon:false})});
  }
  
   handleSubmit(values) {
   		 const { authState, authService, dispatch, user, formValues, sharePageData } = this.props;	
   		 const { saveBuilding, saveUnit } = this.props.actions; 
   		 let propertyName = '';
   		  	  
   		//console.log(values, user, formValues);
		if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_form && ["tour", "contact"].indexOf(sharePageData.custom_data.contact_form)>=0)){
			return null;
		}
		
		const contactTitle = sharePageData.custom_data.contact_form == "tour" ? "Schedule a Tour" : "Request Information";   
		//return false;
   		 let unitType = [];
   		 if(formValues.studio) unitType.push('Studios');
   		 if(formValues.one_bed) unitType.push('1 Beds');
   		 if(formValues.two_bed) unitType.push('2 Beds');
   		 if(formValues.three_bed) unitType.push('3 Beds');
   		 if(formValues.four_bed) unitType.push('4 or More Beds');
   		 
   		 let interestedIn = [];
   		 if(formValues.info) interestedIn.push('Request Information');
   		 if(formValues.tour) interestedIn.push('Schedule Tour');
   		 if(formValues.offer) interestedIn.push('Make an Offer');
   		 
		const contactUser = sharePageData.user && sharePageData.user.email ? sharePageData.user : false;
		if(!contactUser) return false;
		
   		 let salesContact = {
	   		  "Email" : contactUser.email,
	   		  "Name" : contactUser.firstName + " " + contactUser.lastName
   		 }
   		 
   		 let subject = `Share Page: ${contactTitle}`;
   		 if(sharePageData.custom_data && sharePageData.custom_data.report_title){
				subject += ' - ' + sharePageData.custom_data.report_title;
		 }else if(sharePageData.property){
			 	const _boro = sharePageData.property.borough;
				 const _hood = sharePageData.property.neighborhood[0];
				 const buildingNameOrAdr = sharePageData.property.building_name 
				   ? _capitalizeText(sharePageData.property.building_name)
				   : sharePageData.property.display_full_street_address
					 ? _capitalizeText(sharePageData.property.display_full_street_address)
					 : _capitalizeText(sharePageData.property.full_street_address);
				 const adr = sharePageData.property.display_full_street_address ? sharePageData.property.display_full_street_address : sharePageData.property.full_street_address;
				 
				 const unitNumber = _formatUnitNumber(sharePageData.property.address_2_normalized);
			 	subject += ' - ' + buildingNameOrAdr + ', ' + unitNumber + ', ' + _hood;
		 }else if(sharePageData.building){
			   const _boro = sharePageData.building.borough;
			   const _hood = sharePageData.building.neighborhood[0];
			   const buildingNameOrAdr = sharePageData.building.name 
				  ? _capitalizeText(sharePageData.building.name)
				  : sharePageData.building.display_full_street_address
					? _capitalizeText(sharePageData.building.display_full_street_address)
					: _capitalizeText(sharePageData.building.full_street_address);
			   const adr = sharePageData.building.display_full_street_address ? sharePageData.building.display_full_street_address : sharePageData.building.full_street_address;

				subject += ' - ' + buildingNameOrAdr + ', ' + _hood;
			 
		 }
   		    		 
   		 let contactData = {
	   		 to: [salesContact],
	   		 from: [{
		   		 "Email" : formValues.email,
		   		 "Name" : formValues.fullName
		   		}],
	   		 subject: subject,
	   		 variables: {
		   		 fullName : formValues.fullName,
		   		 phone: formValues.primaryPhone ? formValues.primaryPhone : 'Not provided',
		   		 email: formValues.email ? formValues.email : 'Not provided',
				 interestedIn: contactTitle,
		   		 message: formValues.message ? formValues.message : 'Not provided',
				 source: window && window.location.href
		   		
	   		 },
	   		 user: user
   		 }
   		 if(formValues.ccme === true){
	   		 contactData['cc'] = [{
		   		 "Email" : formValues.email,
		   		 "Name" : formValues.fullName
		   		}]
   		 }

   		 let apiData = {
			url: apiUrl+'share-page-contact',
			method: "post",
			query: contactData
	  	  };
	  
	  
		  _axiosCall(apiData)
		    .then(res => {
		      console.log("_getUserData res back ,", res);
		      
		      if(res && res.result && res.result.body && res.result.body.Messages && res.result.body.Messages[0] && res.result.body.Messages[0].Status && res.result.body.Messages[0].Status === "success"){		      
			      this.snackOpen('Your Message Has Been Sent.', 'success');	
				  Publisher.publish('track.contact.page', {page: sharePageData.link_id});
				     
				  /*window.gtag('config', 'AW-763613129');
				  window.gtag('event', 'conversion', {'send_to': 'AW-763613129/DCxQCObzhsUDEMmfj-wC'});*/
				   
				  this.setState(prevState => ({ modal: false }));
			  }else{			  
				 if(res.message){
					this.snackOpen(<Fragment><span>Oops, Your Message Was Not Sent Successfully</span></Fragment>, 'error');
				 }else{
					this.snackOpen(<Fragment><span>Oops, Your Message Was Not Sent Successfully</span></Fragment>, 'error');
				 }
				  
			  }
		  })
		  .catch((error) => {
		    console.log("_getUserData error ", error);
		    this.snackOpen('Oops, Your Message Was Not Sent Successfully.', 'error');
		  }); 

    
   }
  
  	snackOpen = (message, type) => {
		this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	};
	
	snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: '' } )
	};
	
	
	
	
  toggle() {
	//this.snackOpen('Your Message Has Been Sent!', 'success');
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, sharePageData
    } = this.props;
    
    const { modal, snackShow, snackMessage, snackType, errors, showButon } = this.state;
	
	if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_form && ["tour", "contact"].indexOf(sharePageData.custom_data.contact_form)>=0)){
		return null;
	}
	
	if(!showButon){
		return null;
	}
	
	const contactTitle = sharePageData.custom_data.contact_form == "tour" ? "Schedule a Tour" : "Request Information";
	
	let subText = '';
	if(sharePageData.custom_data && sharePageData.custom_data.report_title){
			subText = sharePageData.custom_data.report_title;
	 }else if(sharePageData.property){
			 const _boro = sharePageData.property.borough;
			 const _hood = sharePageData.property.neighborhood[0];
			 const buildingNameOrAdr = sharePageData.property.building_name 
			   ? _capitalizeText(sharePageData.property.building_name)
			   : sharePageData.property.display_full_street_address
				 ? _capitalizeText(sharePageData.property.display_full_street_address)
				 : _capitalizeText(sharePageData.property.full_street_address);
			 const adr = sharePageData.property.display_full_street_address ? sharePageData.property.display_full_street_address : sharePageData.property.full_street_address;
			 
			 const unitNumber = _formatUnitNumber(sharePageData.property.address_2_normalized);
			 subText = buildingNameOrAdr + ', ' + unitNumber + ', ' + _hood + ', ' + _boro;
	 }else if(sharePageData.building){
		   const _boro = sharePageData.building.borough;
		   const _hood = sharePageData.building.neighborhood[0];
		   const buildingNameOrAdr = sharePageData.building.name 
			  ? _capitalizeText(sharePageData.building.name)
			  : sharePageData.building.display_full_street_address
				? _capitalizeText(sharePageData.building.display_full_street_address)
				: _capitalizeText(sharePageData.building.full_street_address);
		   const adr = sharePageData.building.display_full_street_address ? sharePageData.building.display_full_street_address : sharePageData.building.full_street_address;
	
			subText = buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
		 
	 }

	let salesOffice = false;
	let listingAgents = false;
    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				<div className={`contact__form ${modal ? 'is-contact-open' : ''}`}>
					<div className="headline">
						<h3>{contactTitle}</h3>
						<h4>{subText}</h4>
					</div>
					<div>
				  		<Button
							variant="contained"
							 color="primary"
							 className="buyersList__primary-button"		
							onClick={this.toggle}
				  		>
							{sharePageData.custom_data.contact_form == "tour" ?
								<ContactIcon style={{marginRight:5}} /> 
								:
								<InfoIcon style={{marginRight:5}} />
							}		
							{contactTitle}
				  		</Button>
				  </div>
		  		</div>
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support share_contact`}
		          className={`modal-dialog contact__modal share_contact__modal ${sharePageData.custom_data.contact_form}`}
				 
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
				  <Row>
		        	
					<Col xl={12} lg={12} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">{contactTitle}
							</h4>
							
							{subText &&
								<h5 className="contact__sub-title">
									{subText}	              	
				               </h5>
					            
				            }
							<ShareContact  user={sharePageData.user} sharePageData={sharePageData} min /> 
				            <hr/>     
							 
				             <ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} handleSubmit={this.handleSubmit} errors={errors} contactTitle={contactTitle} icon={sharePageData.custom_data.contact_form == "tour" ?
									 <ContactIcon /> 
									 :
									 <InfoIcon />
								 }  />				             
				             
				        </div>	
				        	        	
					</Col>
				  </Row>
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("share_contact_form")(state),
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [] 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ShareContactButton));
