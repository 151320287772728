import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

const ResidentialCheckbox = withStyles({
  root: {
    color: "#780F9E",
    '&$checked': {
      color: "#780F9E",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CommercialCheckbox = withStyles({
  root: {
    color: "#FF206E",
    '&$checked': {
      color: "#FF206E",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MixedUseCheckbox = withStyles({
  root: {
    color: "#28C2FF",
    '&$checked': {
      color: "#28C2FF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function LegendToggle(props) {
	
  const { occupancyTypes, setOccupancyFilter } = props;
  const [state, setState] = React.useState({
    residential: true,
    commercial: true,
    mixed: true
  });

  const handleChange = (event) => {
    setOccupancyFilter(event.target.name);
  };

  return (
	<div className="legend-toggle">
	    <FormGroup row>
	      <FormControlLabel
	        control={<ResidentialCheckbox checked={occupancyTypes.indexOf("residential")>-1} onChange={handleChange} name="residential" />}
	        label="Residential"
	      />
	      <FormControlLabel
	        control={<CommercialCheckbox checked={occupancyTypes.indexOf("commercial")>-1} onChange={handleChange} name="commercial" />}
	        label="Commercial"
	      />
	      <FormControlLabel
	        control={<MixedUseCheckbox checked={occupancyTypes.indexOf("mixed-use")>-1} onChange={handleChange} name="mixed-use" />}
	        label="Mixed-Use"
	      />
	    </FormGroup>
	</div>
  );
}
