import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import Icon from 'mdi-react/CurrencyUsdIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";

const AveragePPSFSold = (props) => {
	if(!props) return null;
	
	return (
		 <Col md={12} xl={4} lg={6} xs={12}>
		    <Card>
		    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
		      <CardBody className="dashboard__booking-card card__link">
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            ${props.transactionRes && props.transactionRes.ppsf && props.transactionRes.ppsf.avg_ppsf ? 
			            _formatNumber(props.transactionRes.ppsf.avg_ppsf) 				       
			            : '--'}
		          </h5>
		          <Icon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">Avg Sold PPSF</h5>
		      </CardBody>
		    </Link>
		    </Card>
		  </Col>
	)
		

	 	
		
	
};

export default AveragePPSFSold;
