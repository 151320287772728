import React from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import area from "@turf/area";
import { polygon, polygons, multiPolygon } from "@turf/helpers";
import _ from "lodash";
import center from "@turf/center";
import moment from 'moment';
import {
  _capitalizeText,
  _formatPrice,
  _isEqual,
  _shouldShow,
  _formatNumber,
  _nameToUrl,
  _urlToName,
  _generateRandomAPI,
  _getBuildingPhotos,
  _replaceAbbreviations,
  ShowBadge,
  _findPopUpColor,
  jobTypes,
  workTypes,
  jobTypeList,
  permitteeTypes,
  _makePermitsUrl
} from "../../helpers/utils";
import * as MapUtil from "../../helpers/mapUtil";
import {
  _axiosCall,
  _filingSearchMapListings,
  _getNeighborhoods,
  _searchMapListings,
} from "../../helpers/apicalls";
import {
  _getListingAdr,
  _getListingTagInfo,
  _getListingUnitTag,
  _getUnitDisplay,
  _getUrlObj,
} from "../../helpers/listingUtils";
import L from "leaflet";
import {
  boroughTileset,
  building_tileset,
  majorMarketTileset,
  neighborhoodTileset,
  pipeline_lot_tileset,
} from "../../helpers/commonMapFilters";

import {
  buildingStyle,
  containerStyle,
  defaultOccupancyTypes,
  LocationsPadding,
  pipelineStyle,
  Tooltip,
  TooltipBuilding,
  TooltipMPND,
} from "./CommonMapComponent";
import LegendToggle from "../../../containers/PipelineMap/components/LegendToggle";
import { _pipelineMapFilters } from "../../helpers/filters";
import PitchToggle from "../../../containers/PipelineMap/components/PitchToggle";
import PermitDetails from "../PermitDetails";
import PopupModal from "./../../../containers/Permits/components/PopupModal";


const BOROUGHS = {
  Brooklyn: { center: [-73.949997, 40.650002] },
  Bronx: { center: [-73.865433, 40.837048] },
  Manhattan: { center: [-73.984016, 40.754932] },
  // "Staten Island": { center: [-74.151535, 40.579021] },
  "Staten Island": {
    center: [-74.151535, 40.579021],
    geocode: [-74.133224, 40.591618],
  },
  Queens: { center: [-73.8448, 40.7181] },
};

const buildingUrl = process.env.API_ROOT + "/query/";
const pipeline_tileset = [
  {
    id: "mp-pipeline-buildings",
    layer_id: "mp-pipeline-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project",
    source_layer: "pipeline",
  },
  {
    id: "mp-sponsor-buildings",
    layer_id: "mp-sponsor-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project",
    source_layer: "mpnd",
    default_filter: ["==", "building_status", "sponsor"],
  },
];

const WordWrapLocations = {
  "Prospect Park South And Flatbush": true,
  "Park Slope And Prospect Park": true,
  "Red Hook To Boerum Hill": true,
  "Williamsburg And Greenpoint": true,
  "Downtown West Manhattan": true,
  "Downtown East Manhattan": true,
  "Flushing Meadows Corona Park": true,
  "Prospect Lefferts Gardens": true,
};

mapboxgl.accessToken =
  "pk.eyJ1IjoibmluZ3pob3UiLCJhIjoiY2lwaXBiaWtyMDFxZXVnbmpkaWR4dXd0MSJ9.GJX2c6TviB6opu84mCEOIg";

if (window.innerWidth < 768) containerStyle.height = window.innerHeight;

const getSubFilters = ({ stage, match }) => {
  const type = match.params.type ? match.params.type : "residential";

  let filters = _pipelineMapFilters.filter(
    (filter) =>
      !(filter.exclude_stage && filter.exclude_stage.indexOf(stage) >= 0)
  );

  if (type === "commercial") {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "residential")
    );
  } else {
    filters = filters.filter(
      (filter) => !(filter.type && filter.type === "commercial")
    );
  }
  return filters;
};

const ListingMarker = ({ combinedDoc }) => {
  function _displayText() {
 
      return combinedDoc.length > 1
        ? `${combinedDoc.length} Permits`
        : _formatFilingType(combinedDoc[0]);
    }
   
  

  return <div className="u_font-12">{_displayText()}</div>;
};

const _formatFilingType = (listing) => {
  if(!listing) return "";
  let text = ""
  if(listing.issuance_date){
    text = "Issued: "
  }else{
    text = "Filed: "
  }
  text += listing.job_type && jobTypes[listing.job_type]
   ? jobTypes[listing.job_type]
   : listing.job_type;
   
  return text;
};
export const PermitIsssuancePopup = ({
  authData,
  fromTabbedContainer,
  isMobile,
  listings,
  onClick,
  tabInfo,
}) => {
  function _displayAddress(adr, unit, showUnit) {
    if (adr && unit && showUnit & !fromTabbedContainer) {
      return (
        <div className="map-listing-popup-address">
          {/* <div className="c_title u_600-wgt">{unit}</div> */}
          <div className="u_font-12">{adr}</div>
        </div>
      );
    } else if (adr && !fromTabbedContainer) {
      return <div className="c_title u_600-wgt">{adr}</div>;
    }
  }

  function _listingItem(listing) {
    const hood = _shouldShow(listing.neighborhood)
      ? Array.isArray(listing.neighborhood)
        ? listing.neighborhood[0]
        : listing.neighborhood
      : "nyc";

    const type = "building";



   
    const adr = _getListingAdr(listing);
    
    let listingData = listing.data ? listing.data : false;

    if (listing && listing.data && listing.data.filings_data) {
      listingData = listing.data.filings_data;
    }
    return (
      <li
        key={listing.id}
        className={`map-listing-popup-list-item ${
          isMobile ? "is-mobile" : "is-desktop"
        }`}
        onClick={(event)=>{onClick(listing)}}
      >
        <div
          className={`map-listing-popup-img-container ${
            isMobile ? "mobile-popup-padding" : "u_pd-5px"
          }`}
        >
          <div className="ribbon">
            <span>
              {listing.job_type && jobTypes[listing.job_type]
               ? jobTypes[listing.job_type]
               : listing.job_type}
            </span>
          </div>

          <img
            src={`https://newsapi.marketproof.com/buildings/pictures/${listing.building}`}
          />
        </div>

        <div
          className={`map-listing-popup-list-item-info ${
            isMobile ? "mobile-popup-padding" : ""
          }`}
          style={{ marginLeft: "10px" }}
        >
          <div className="c_title map-listing-popup-price u_pd-top-5px">
            <span className={"bold-text"}>
              { listing.work_type && workTypes[listing.work_type] ? _capitalizeText( workTypes[listing.work_type]) : _capitalizeText(listing.work_type)}
            </span>
          </div>
          <div className="c_title map-listing-popup-price u_pd-top-5px">
            <span>
              {""}
            </span>
          </div>

          <div>
          {listing.issuance_date ?
            <span>
              Issued: {_shouldShow(listing.issuance_date) &&
                moment(
                  listing.issuance_date.split("T")[0],
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY")}{" "}
            </span>
          : listing.filing_date ? 
            <span>
              Filed: {_shouldShow(listing.filing_date) &&
                moment(
                  listing.filing_date.split("T")[0],
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY")}{" "}
            </span>
          : listing.pre_filing_date ? 
          <span>
            Filed: {_shouldShow(listing.pre_filing_date) &&
              moment(
                listing.pre_filing_date.split("T")[0],
                "YYYY-MM-DD"
              ).format("MM/DD/YYYY")}{" "}
          </span>
          : null }
          </div>

          {_displayAddress(adr, false, false)}

          {false && <div className="u_font-11">{_capitalizeText(hood)}</div>}

          <div className="u_font-11">
              <span className="link-text u_font-11">(Click for Details)</span>
          </div>
        </div>
      </li>
    );
  }

  return (
    <ul className="map-listing-popup-list">
      {listings
        .sort((a, b) =>
          a.close_date_formatted > b.close_date_formatted ? -1 : 1
        )
        .map((listing) => _listingItem(listing))}
    </ul>
  );
};
export default class PermitsMapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationChange: false,
      mapLoaded: false,
      mapRendered: false,
      markers: {},
      mobilePopupInfo: {},
      styleLoaded: false,
      tilesAndSourcesLoaded: false,
      mapUnsupported: false,
      polyHoods: [],
      occupancyTypes: defaultOccupancyTypes,
      handlePopup: true,
      modal: false,
      permit: null,
    };
    this.currentlyHoveredLocation = null;
    this.currentMarkerKey = null;
    this.customMarker = null;
    this.hoveredStateId = null;
    this.justClickedMarker = false;
    this.justClickedSelectedMarker = false;
    this.justFetched = false;
    this.justResized = false;
    this.justSetMapBounds = false;
    this.justToggledPopup = false;
    this.markerCount = 0;
    this.mobilePopupContainer = null;
    this.mobilePopupContainerKey = null;
    this.oneClickMarker = null;
    this.popupTimeouts = {};
    this.selectedMarker = null;
    this.callQueue = [];
    this.mapContainer = React.createRef();
    this._setOccupancyFilter = this._setOccupancyFilter.bind(this);
    this._addPipelineLotTiles = this._addPipelineLotTiles.bind(this);
    this._setNeighborhood = this._setNeighborhood.bind(this);
  }
  layersAdded = [];
  tooltipContainer;
  tooltip;
  map;
  geoLocate;
  hoveredStateId = null;
  hoveredLayerId = null;
  hovered3DLayerId = null;
  hoveredBuildingStateId = null;
  hoveredBuildingLayerId = null;
  buildingSubFilters = getSubFilters(this.props);
  popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  _setOccupancyFilter(type) {
    const { occupancyTypes } = this.state;

    let newOccupancyTypes = occupancyTypes.slice();
    if (newOccupancyTypes.indexOf(type) > -1) {
      newOccupancyTypes.splice(newOccupancyTypes.indexOf(type), 1);
    } else {
      newOccupancyTypes.push(type);
    }

    this.setState({ occupancyTypes: newOccupancyTypes });
  }
  _toggleModal = (permit) => {
    const { modal } = this.state;
    console.log(permit)
    if(modal){
      this.setState({permit : null, modal : !modal})
    }else{
      this.setState({permit : permit, modal : !modal})
    }
   
  }


  _resizeCb = () => {
    this.justResized = true;
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.justResized = false;
      console.log("Finished Resizing");
    }, 300);
  };

  _updateLocationMarker(e) {
    if (this.locationMarker) {
      const el = this.locationMarker.getElement();

      if (e.features && e.features[0]) {
        el.innerText = e.features[0].properties.boro_name
          ? _capitalizeText(e.features[0].properties.boro_name)
          : _capitalizeText(e.features[0].properties.name);
        el.style.background = "#FFFFFF";
        el.style.border = "1px solid #5C6178";
        el.style.fontSize = "12px";
        el.style.padding = "5px 8px";
        el.style.boxShadow = "0 0 2px #A3ADC2";

        this.locationMarker.setLngLat(e.lngLat);
        this.locationMarker.addTo(this.map);
      } else {
        this.locationMarker.remove();
      }
    }
  }

  _addAggregatedClusters(data) {
    this.map.addSource("aggregated-clusters", {
      type: "geojson",
      data,
    });

    this.map.addLayer({
      id: "aggregated-clusters",
      type: "circle",
      source: "aggregated-clusters",
      filter: [">", "count", 0],
      paint: {
        "circle-color": "#780F9E",
        "circle-opacity": 0.7,
        "circle-radius": [
          "step",
          ["get", "count"],
          12,
          100,
          15,
          500,
          19,
          1000,
          23,
          3000,
          27,
        ],
      },
    });

    this.map.addLayer({
      id: "aggregated-cluster-count",
      type: "symbol",
      source: "aggregated-clusters",
      filter: [">", "count", 0],
      layout: {
        "text-field": [
          "concat",
          ["format",
            ["get", "count"], { "min-fraction-digits": 0, "max-fraction-digits": 0 }
          ],
          ""
        ],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 13,
        "text-allow-overlap": true,
      },
      paint: { "text-color": "#FFFFFF" },
    });

    // inspect a cluster on click
    this.map.on("click", "aggregated-clusters", (e) => {
      if (!this.justClickedSelectedMarker) {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ["aggregated-clusters"],
        });

        const zoom = this.map.getZoom();
        const zoomTo = zoom < 11 ? 12.01 : zoom < 12 ? 13.01 : 15.01;

        this.map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoomTo,
        });
      }
      this.justClickedSelectedMarker = false;
    });

    this.map.on("mouseenter", "aggregated-clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "aggregated-clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  _createMarkerContent(combinedDoc, key) {
    const el = document.createElement("div");
    if (this.props.isfilingPage) {
      let listingPopUpColor = "#9600bf";
      if (combinedDoc && combinedDoc[0]) {
        if(combinedDoc[0].issuance_date){
          listingPopUpColor = "#28C2FF";
        }
      }
      el.setAttribute("style", `border-left: 2px solid ${listingPopUpColor}`);
    }

    el.classList.add("map-listing-marker-container");

    if (combinedDoc.length === 1 && !this.props.isMobile) {
      const { authData, tabInfo } = this.props;
      const listing = combinedDoc[0];

      el.classList.add(listing.listing_type);
      const hood = _shouldShow(listing.neighborhood)
        ? Array.isArray(listing.neighborhood)
          ? listing.neighborhood[0]
          : listing.neighborhood
        : "nyc";
     const listingKey =
        listing.web_id && _shouldShow(listing.web_id) ? listing.web_id : false;

      const type =
        (((listing && listing.type && listing.type.includes("townhouse")) ||
          (listing && listing.type && listing.type.includes("family")) ||
          (listing && listing.type && listing.type.includes("families")) ||
          (listing && listing.type && listing.type.includes("building"))) &&
          listing &&
          listing.listing_type == "sale") ||
        !listing.address_2_normalized
          ? //  (listing.address_2_normalized &&
            //    (listing.address_2_normalized === 'th' ||
            //      listing.address_2_normalized === 'building' ||
            //      listing.address_2_normalized === 'house'
            //    )
            //  )
            "building"
          : "unit";

      const unit = _getUnitDisplay(listing, type);
      const adr = _getListingAdr(listing);
      const listingTagInfo = _getListingTagInfo(type, listing, adr, unit);
      const unitObj = _getListingUnitTag(listingTagInfo, type);
      const urlObj = _getUrlObj(listing, listingTagInfo, hood);

      const cb = () => this._toggleModal(listing);

      el.addEventListener("click", () => {
        if (el !== this.oneClickMarker) {
          this.oneClickMarker = el;
          cb();
        }
      });
    }

    ReactDOM.render(
      <ListingMarker
        combinedDoc={combinedDoc}
      />,
      el
    );

    const triEl = document.createElement("div");
    triEl.classList.add("marker-arrow-down-larger");
    const triEl2 = document.createElement("div");
    triEl2.classList.add("marker-arrow-down");

    el.appendChild(triEl);
    el.appendChild(triEl2);

    if (key) {
      if (this.props.isMobile) {
        el.addEventListener("click", () => {
          this.justClickedMarker = true;
          this._togglePopups(key);
        });
      } else {
        const cb1 = () => this._togglePopupsHover(key, "show");
        const cb2 = () =>
          (this.popupTimeouts[key] = setTimeout(
            () => this._togglePopupsHover(key, "hide"),
            400
          ));
        const cb3 = () => {
          this.justClickedMarker = true;
          this._togglePopups(key);
        };

        el.addEventListener("touchstart", () => {
          el.removeEventListener("mouseenter", cb1);
          el.removeEventListener("mouseleave", cb2);
          el.addEventListener("click", cb3);
        });

        el.addEventListener("mouseenter", cb1);
        el.addEventListener("mouseleave", cb2);
      }
    }

    return el;
  }

  _createMarkersAndPopups(docs, signedIn) {
    const { isMobile } = this.props;
    const combinedDocs = MapUtil._combineDocs(docs);
    this.markerCount = Object.keys(combinedDocs).length;
    const markers = {};
    const mobilePopupInfo = {};
    Object.keys(combinedDocs).forEach((key) => {
      const oldMarker = this.state.markers[key];
      let markerText;
      markerText =
        combinedDocs[key].length > 1
          ? `${combinedDocs[key].length} Activities`
          : _formatFilingType(combinedDocs[key][0]);
     
      if (
        !oldMarker ||
        (oldMarker &&
          oldMarker.getElement().innerText.trim().toLowerCase() !==
            markerText.trim().toLowerCase()) ||
        signedIn
      ) {
        markers[key] = new mapboxgl.Marker(
          this._createMarkerContent(combinedDocs[key], key)
        ).setLngLat(MapUtil._parseCoordinates(key));

        if (!isMobile) {
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 15,
            maxWidth: "320px",
            className: "u_pd-none c_listing-popup-outer",
          }).setDOMContent(this._createPopupContent(combinedDocs[key], key));
          markers[key].setPopup(popup).addTo(this.map);
        } else {
          markers[key].addTo(this.map);
          mobilePopupInfo[key] = combinedDocs[key];
        }
      } else {
        markers[key] = this.state.markers[key];
        if (isMobile) mobilePopupInfo[key] = this.state.mobilePopupInfo[key];
      }
    });

    this._removeMarkersAndPopups(markers, signedIn);
    if (this.map.getZoom() !== 10) {
      this.setState({ markers, mobilePopupInfo });
    }
  }

  _addSourceAndLayers(tileset) {
    this.map.addSource(tileset.id, {
      type: "vector",
      url: tileset.url,
    });

    this.map.addLayer({
      id: `${tileset.id}-layer-transparent`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-outline-color": "#000",
        "fill-color": "#363347",
        "fill-opacity": 0,
      },
    });

    this.map.addLayer({
      id: `${tileset.id}-layer`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#ccc",
        "fill-opacity": 0.1,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    this.map.addLayer({
      id: `${tileset.id}-layer-highlighted`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#6674AD",
        "fill-opacity": 0.3,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    const minWidth = 1;
    const minZoom = 10;

    this.map.addLayer({
      id: `${tileset.id}-layer-outline`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "line",
      paint: {
        "line-color": "#abb3d4",
        "line-width": 2,
        // 'line-width': {
        //   "type": "exponential",
        //   "base": 1.4
        //   ,
        //   "stops": [
        //     [9, minWidth * Math.pow(1.4, (9 - minZoom))],
        //     [15, minWidth * Math.pow(1.4, (15 - minZoom))],
        //   ]
        // }
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });

    const cb1 = (e) => {
      if (e.features.length > 0 && this.map.getZoom() < 14) {
        this._updateLocationMarker(e);

        if (
          !this.currentlyHoveredLocation ||
          (tileset.id.includes("boroughs") &&
            e.features[0].properties.boro_name !==
              this.currentlyHoveredLocation) ||
          e.features[0].properties.id !== this.currentlyHoveredLocation
        ) {
          this.currentlyHoveredLocation = tileset.id.includes("boroughs")
            ? e.features[0].properties.boro_name
            : e.features[0].properties.id;

          const highlightFilter = tileset.id.includes("boroughs")
            ? ["in", "boro_name", e.features[0].properties.boro_name]
            : ["in", "id", e.features[0].properties.id];

          this.map.setFilter(
            `${tileset.id}-layer-highlighted`,
            highlightFilter
          );
        }
      }
    };

    const cb2 = (e) => {
      this._updateLocationMarker(e);
      this.map.setFilter(`${tileset.id}-layer-highlighted`, [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ]);
      this.currentlyHoveredLocation = null;
    };

    const cb3 = (e) => {
      console.log("DUETING");
      this.map.off("mousemove", `${tileset.id}-layer`, cb1);
      this.map.off("mouseleave", `${tileset.id}-layer`, cb2);
      this.map.off("touchstart", `${tileset.id}-layer`, cb3);
    };

    this.map.on("mousemove", `${tileset.id}-layer`, cb1);
    this.map.on("mouseleave", `${tileset.id}-layer`, cb2);
    this.map.on("touchstart", `${tileset.id}-layer`, cb3);
  }

  _addLocationNames(data) {
    this.map.addSource("location-names", {
      type: "geojson",
      data,
    });

    this.map.addLayer({
      id: "location-names",
      type: "symbol",
      source: "location-names",
      layout: {
        "text-field": ["get", "location"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": ["get", "fontSize"],
        "text-offset": ["get", "offset"],
      },
      paint: {
        "text-color": "#171F26",
      },
    });
  }

  _getMapListings(polygonQueries) {
    
      const building = this.props.fromTabbedContainer
        ? this.props.building
        : false;

      const obj = {
        callback: (data) => data,
        url: buildingUrl + "dob-permits?cache=60",
        rows: polygonQueries.rows,
        collection: this.props.collection,
        sort : 'solr_updated_date desc',
        start: 0,
      };
      if (polygonQueries.rows && polygonQueries.q) {
        obj.googleCodes = polygonQueries.q;
        obj.q = this.props.q;
      } else {
        obj.groupLimit = polygonQueries.groupLimit;
        obj.groupQuery = Object.keys(polygonQueries.groupQuery).map(
          (key) => polygonQueries.groupQuery[key]
        );
        obj.q = this.props.q;
        obj.fq = this.props.fq;
      }
      return _searchMapListings(building, obj, this.props, this.props.fq.slice());
    
  }

  _createPopupContent(docs, key = null) {
    const { authData, fromTabbedContainer, isMobile, handleBuildingClick } = this.props;
    const el = document.createElement("div");
    el.classList.add(
      isMobile ? "mobile-popup-container" : "map-listing-popup-container"
    );

    if (!isMobile) {
      const cb1 = () => this._togglePopupsHover(key, "show");
      const cb2 = () =>
        (this.popupTimeouts[key] = setTimeout(
          () => this._togglePopupsHover(key, "hide"),
          400
        ));

      el.addEventListener("mouseenter", cb1);
      el.addEventListener("mouseleave", cb2);
    }

      ReactDOM.render(
        <PermitIsssuancePopup
          authData={authData}
          fromTabbedContainer={fromTabbedContainer}
          isMobile={isMobile}
          listings={docs}
          onClick={this._toggleModal}
          tabInfo={this.props.tabInfo}
        />,
        el
      );

      return el;
   
  }

  _togglePopupsHover(markerKey = null, action) {
    const marker = this.state.markers[markerKey];
    const popup = marker ? marker.getPopup() : null;

    if (marker && popup) {
      switch (action) {
        case "show":
          clearTimeout(this.popupTimeouts[markerKey]);
          this.popupTimeouts[markerKey] = null;
          this.setState({ handlePopup: false });
          if (
            this.currentMarkerKey &&
            this.currentMarkerKey !== markerKey &&
            this.state.markers[this.currentMarkerKey]
          ) {
            clearTimeout(this.popupTimeouts[this.currentMarkerKey]);
            this.popupTimeouts[this.currentMarkerKey] = setTimeout(() => {
              const oldMarker = this.state.markers[this.currentMarkerKey];
              const oldPopup = oldMarker ? oldMarker.getPopup() : null;
              if (oldPopup && oldPopup.isOpen()) oldMarker.togglePopup();

              this.currentMarkerKey = markerKey;
              if (!popup.isOpen()) marker.togglePopup();
            }, 200);
          } else {
            this.currentMarkerKey = markerKey;
            if (!popup.isOpen()) marker.togglePopup();
          }
          break;
        case "hide":
          if (popup.isOpen()) marker.togglePopup();
          this.setState({ handlePopup: true });
          if (this.currentMarkerKey === markerKey) this.currentMarkerKey = null;
          break;
      }
    }
  }

  _displayThrottleMessage(throttleAmt, totalAmt) {
    this.throttleEl.innerText = `${throttleAmt} out of ${_formatNumber(
      totalAmt
    )} permits.  Zoom in to see more.`;
    this.throttleEl.classList.add("show-throttle-message");
  }

  _togglePopups(markerKey = null) {
    const { isMobile, authData } = this.props;
    const { mobilePopupInfo, markers } = this.state;

    if (!isMobile) {
      Object.keys(markers).forEach((key) => {
        if (key !== markerKey && markers[key].getPopup().isOpen()) {
          markers[key].togglePopup();
        }
      });
    } else if (markerKey) {
      if (markerKey === this.mobilePopupContainerKey) {
        this._removeMobilePopup();
      } else {
        if (
          this.mobilePopupContainerKey &&
          markerKey !== this.mobilePopupContainerKey
        ) {
          this._removeMobilePopup();
        }
        this.mobilePopupContainer = this._createPopupContent(
          this.state.mobilePopupInfo[markerKey]
        );
        this.mobilePopupContainerKey = markerKey;
        document.querySelector("body").appendChild(this.mobilePopupContainer);
      }
    } else if (this.mobilePopupContainer) {
      this._removeMobilePopup();
    }

    this.justToggledPopup = true;
  }

  _openBuilding(building) {
    let address = _replaceAbbreviations(
      building.display_full_street_address
    ).toLowerCase();
    if (address.indexOf("garage") < 0 && address.indexOf("rear") < 0) {
      window.open(
        "/building/" +
          _nameToUrl(building.borough) +
          "/" +
          _nameToUrl(building.main_neighborhood) +
          "/" +
          building.key
      );
    }
  }

  _setMouseClick(e) {
    const { handleBuildingClick } = this.props;

    let features = this.map.queryRenderedFeatures(e.point, {
      layers: this.layersAdded,
    });

    let feature = this._isMPND(features);
    const key =
      feature && feature.properties.key
        ? feature.properties.key
        : features[0].properties.key
        ? features[0].properties.key
        : false;

    if (key && key.indexOf("b-") >= 0) {
      this._openBuilding(features[0].properties);
    } else {
      if (
        features.length > 0 &&
        features[0].properties &&
        features[0].properties.key
      ) {
        if (feature) {
          this._openMPND(feature.properties);
        } else {
          handleBuildingClick(e, features[0].properties);
        }
      }
    }
  }

  _openMPND(building) {
    if (building && building.key && building.neighborhood) {
      window.open(
        "/building/" +
          _nameToUrl(building.borough) +
          "/" +
          _nameToUrl(building.neighborhood) +
          "/" +
          building.key
      );
    }
  }

  _addBuildingTiles() {
    building_tileset.filter((tileset) => {
      // adding tilesets
      this.map.addSource(tileset.id, {
        type: "vector",
        url: tileset.url,
      });

      // ALL buildings footprints - adds a layer for all the building outlines
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minzoom: 16,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": buildingStyle.fillColor,
          "fill-opacity": buildingStyle.fillOpacity,
        },
      });

      this.layersAdded.push(tileset.layer_id);

      this.map.addLayer({
        id: tileset.layer_id + "-hover",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minzoom: 16,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#9600BF",
          "fill-opacity": 0.8,
        },
        filter: ["==", "key", false],
      });

      this.map.addLayer({
        id: tileset.layer_id + "-select",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minzoom: 16,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 0.8,
        },
        filter: ["==", "key", false],
      });

      this.map.on(
        "mousemove",
        tileset.layer_id,
        function (e) {
          if (this.state.handlePopup) this._onMouseMove(e); // for handling two popups
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id,
        function (e) {
          this._onMouseOut();
        }.bind(this)
      );

      this.map.on(
        "click",
        tileset.layer_id,
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
    });
  }

  _setTooltipBuilding(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(TooltipBuilding, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _onMouseOut() {
    if (this.hoveredStateId && this.hoveredLayerId) {
      this.map.setFilter(this.hoveredLayerId, ["==", "key", false]);
    }
    if (this.hoveredStateId && this.hovered3DLayerId) {
      this.map.setFilter(this.hovered3DLayerId, ["==", "key", false]);
    }
    if (this.hoveredBuildingStateId && this.hoveredBuildingLayerId) {
      this.map.setFilter(this.hoveredBuildingLayerId, ["==", "key", false]);
    }

    this.hoveredStateId = null;
    this.hoveredBuildingStateId = null;

    this.map.getCanvas().style.cursor = "";

    ReactDOM.unmountComponentAtNode(this.tooltipContainer);
  }

  _getPipelineFitlers() {
    const { occupancyTypes, polyHoods } = this.state;
    const { searchTags, subFilters, buildingStages } = this.props;
    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags
            .filter((item) => item.type === "borough")
            .map((item) => item.name)
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    let filter = ["all"];

    if (occupancyTypes.length < 3) {
      let occupancyFilter = ["in", "pipeline_occupancy_type"].concat(
        occupancyTypes
      );
      filter.push(occupancyFilter);
    }

    if (boroTags.length > 0) {
      let boroFilter = ["in", "borough"].concat(boroTags);
      filter.push(boroFilter);
    }
    if (hoodTags.length > 0) {
      let hoodFilter = ["in", "neighbhrood"].concat(hoodTags);
      //filter.push(hoodFilter);  Neighborhood not functional
    }

    if (subFilters && Object.keys(subFilters).length > 0) {
      Object.keys(subFilters).map((key) => {
        let subFilterVal = subFilters[key];
        if (
          subFilterVal &&
          subFilterVal != "" &&
          this.buildingSubFilters.filter((filter) => filter.search_key === key)
            .length > 0
        ) {
          let qVal = this.buildingSubFilters
            .filter((filter) => filter.search_key === key)[0]
            .values.filter((value) => value.filter === subFilterVal)[0];
          qVal = qVal && qVal.search_value;

          filter.push(qVal);
        }
      });
    }

    if (buildingStages && buildingStages.length > 0) {
      if (buildingStages.indexOf("all") < 0) {
        let stageQ = ["in", "pipeline_project_stage"];
        if (stageQ.length > 1) {
          stageQ = stageQ.concat(buildingStages);
          filter.push(stageQ);
        }
      }
    }

    if (filter.length === 1) {
      filter = ["has", "key"];
    }

    return filter;
  }

  _addPipelineLotTiles() {
    const filter = this._getPipelineFitlers();

    pipeline_lot_tileset.forEach((tileset) => {
      this.map.addSource(tileset.id, {
        type: "vector",
        url: tileset.url,
      });
    });

    pipeline_lot_tileset.forEach((tileset, i) => {
      // ALL buildings footprints - adds a layer for all the building outlines
      let theFilter = filter.slice(0);
      const default_filter = tileset.default_filter
        ? tileset.default_filter
        : false;
      if (default_filter) {
        theFilter = ["all", default_filter, filter];
      }
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#DBE0E8",
          "fill-opacity": 0.3,
        },
        filter: theFilter,
      });
    });
  }

  _addPipelineTiles() {
    let filter = this._getPipelineFitlers();
    pipeline_tileset.forEach((tileset) => {
      this.map.addSource(tileset.id, {
        type: "vector",
        url: tileset.url,
      });
    });
    pipeline_tileset.forEach((tileset, i) => {
      // ALL buildings footprints - adds a layer for all the building outlines

      const default_filter = tileset.default_filter
        ? tileset.default_filter
        : false;
      let theFilter = filter.slice(0);
      if (default_filter) {
        theFilter = ["all", default_filter, filter];
      }
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 18,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-opacity": pipelineStyle.fillOpacity,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id);
      this.map.addLayer({
        id: tileset.layer_id + `-3d`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-extrusion-opacity": 0.8,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id + `-3d`);
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-hover",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", false],
      });
      this.map.addLayer({
        id: tileset.layer_id + `-3d-hover`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": "#FFD23F",
          "fill-extrusion-opacity": 1,
        },
        filter: ["==", "key", false],
      });
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-select",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "fill",
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", "FALSE"],
      });

      this.map.addLayer({
        id: tileset.layer_id + `-3d-select`,
        type: "fill-extrusion",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 14,
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            0,
          ],
          "fill-extrusion-color": "#FFD23F",
          "fill-extrusion-opacity": 1,
        },
        filter: ["==", "key", "FALSE"],
      });
    });

    pipeline_tileset.forEach((tileset) => {
      //Skip mouse events for mobile

      this.map.on(
        "mousemove",
        tileset.layer_id,
        function (e) {
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mousemove",
        tileset.layer_id + "-3d",
        function (e) {
          this._onMouseMove(e);
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id,
        function (e) {
          this._onMouseOut();
        }.bind(this)
      );
      this.map.on(
        "mouseout",
        tileset.layer_id + "-3d",
        function (e) {
          this._onMouseOut(e);
        }.bind(this)
      );

      this.map.on(
        "click",
        tileset.layer_id,
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
      this.map.on(
        "click",
        tileset.layer_id + "-3d",
        function (e) {
          this._setMouseClick(e);
        }.bind(this)
      );
    });
  }

  _filterPipelineTiles() {
    //return null;
    if (
      this.state.mapLoaded &&
      this.state.styleLoaded &&
      this.state.mapRendered &&
      this.state.tilesAndSourcesLoaded
    ) {
      let filter = this._getPipelineFitlers();
      pipeline_tileset.forEach((tileset) => {
        let theFilter = filter.slice(0);
        const default_filter = tileset.default_filter
          ? tileset.default_filter
          : false;
        if (default_filter) {
          theFilter = ["all", default_filter, filter];
        }
        this.map.setFilter(tileset.layer_id, theFilter);
        this.map.setFilter(tileset.layer_id + "-3d", theFilter);
      });

      pipeline_lot_tileset.forEach((tileset) => {
        let theFilter = filter.slice(0);
        const default_filter = tileset.default_filter
          ? tileset.default_filter
          : false;
        if (default_filter) {
          theFilter = ["all", default_filter, filter];
        }
        this.map.setFilter(tileset.layer_id, theFilter);
      });
    }
  }

  _setTooltip(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(Tooltip, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  _onMouseMove(e) {
    //this._onMouseOut();
    if (
      e.features.length > 0 &&
      e.features[0].properties &&
      e.features[0].properties.key
    ) {
      //var mapLayer = this.map.getLayer('mp-building-layer-hover');

      //e.features[0].state = {"hover":true};
      this.map.getCanvas().style.cursor = "pointer";
      //
      this.tooltip && e.lngLat && this.tooltip.setLngLat(e.lngLat);

      let feature = this._isMPND(e.features);

      const key =
        feature && feature.properties.key
          ? feature.properties.key
          : e.features[0].properties.key
          ? e.features[0].properties.key
          : false;

      if (key && key.indexOf("b-") >= 0) {
        if (
          this.hoveredBuildingStateId !== key &&
          this.hoveredStateId === null
        ) {
          this._setTooltipBuilding(e.features);

          this.hoveredBuildingStateId = key;
          this.hoveredBuildingLayerId = e.features[0].layer.id + "-hover";
          if (this.map.getZoom() > 15) {
            this.map.setFilter(this.hoveredBuildingLayerId, [
              "==",
              "key",
              this.hoveredBuildingStateId,
            ]);
          }
        }
      } else {
        if (this.hoveredStateId !== key) {
          if (feature) {
            this._setTooltipMPND([feature]);
            this.hoveredStateId = key;
            this.hoveredLayerId =
              feature.layer.id.replace("-3d", "") + "-hover";
            this.hovered3DLayerId =
              feature.layer.id.replace("-3d", "") + "-3d-hover";
          } else {
            this._setTooltip(e.features);
            this.hoveredStateId = key;
            this.hoveredLayerId =
              e.features[0].layer.id.replace("-3d", "") + "-hover";
            this.hovered3DLayerId =
              e.features[0].layer.id.replace("-3d", "") + "-3d-hover";
          }

          this.map.setFilter(this.hoveredLayerId, [
            "==",
            "key",
            this.hoveredStateId,
          ]);
          this.map.setFilter(this.hovered3DLayerId, [
            "==",
            "key",
            this.hoveredStateId,
          ]);
        }
      }
    } else {
      this.map.getCanvas().style.cursor = "";
    }
  }

  _isMPND(features) {
    let result = false;
    features.map((feature) => {
      const building_status = feature.properties.building_status
        ? feature.properties.building_status
        : false;
      const offering_verified_status = feature.properties
        .offering_verified_status
        ? feature.properties.offering_verified_status
        : false;
      if (
        building_status &&
        ["pipeline", "sponsor", "resale"].indexOf(building_status) >= 0 &&
        offering_verified_status != "" &&
        offering_verified_status != "excluded"
      ) {
        result = feature;
      }
    });
    return result;
  }

  _setTooltipMPND(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(TooltipMPND, {
          features,
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  componentDidMount() {
    if (!mapboxgl.supported()) {
      return this.setState({ mapUnsupported: true });
    }

    const config = {
      lng: -73.9499187465866,
      lat: 40.71945296340479,
      zoom: 10,
      minZoom: 9,
      maxZoom: 19,
    };
    const { mapBounds } = this.props;

    // this._getPolyHoods();
    if (mapBounds && mapBounds.sw) {
      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        // style: "mapbox://styles/ningzhou/cjv2ya86kblpr1frvc4vwb6tx",
        style: "mapbox://styles/ningzhou/cjxxu65iq088b1cqgzu7sbxy2",
        bounds: [mapBounds.sw, mapBounds.ne],
        minZoom: config.minZoom,
        maxZoom: config.maxZoom,
      });
    } else {
      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/cjvpf64h318z01cn8axood9r9",
        center: [config.lng, config.lat],
        zoom: config.zoom,
        minZoom: config.minZoom,
        maxZoom: config.maxZoom,
      });
    }
    this.tooltipContainer = document.createElement("div");
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(
      new PitchToggle({
        minpitchzoom: this.props.fullSize ? 18 : 17,
        trxnRes: null,
      }),
      "top-right"
    );
    this.map.addControl(
      new mapboxgl.FullscreenControl({
        container: document.querySelector("#pipeline-map"),
      })
    );
    this.geoLocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      fitBoundsOptions: {
        maxZoom: 17,
      },
      trackUserLocation: true,
    });
    this.map.addControl(this.geoLocate);

    this.map.once("styledata", () => this.setState({ styleLoaded: true }));
    this.map.once("render", () => this.setState({ mapRendered: true }));

    this.map.on("load", () => {
      this.throttleEl = document.createElement("div");
      this.throttleEl.classList.add("throttle-message");

      const mapContainerEl = this.map.getContainer();
      mapContainerEl.appendChild(this.throttleEl);

      const el = document.createElement("div");
      el.classList.add("map-listing-location-marker-container");
      this.locationMarker = new mapboxgl.Marker(el);
      this.tooltip = new mapboxgl.Marker(this.tooltipContainer, {
        offset: [0, -165],
      })
        .setLngLat([0, 0])
        .addTo(this.map);
      this._addBuildingTiles();
      this._addPipelineLotTiles();
      this.locationMarker.setOffset([0, 50]);

      const layers = this.map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;
      this.map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 14,
          layout: {
            visibility: "none",
          },
          paint: {
            "fill-extrusion-color": "#aaa",
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
      this._addSourceAndLayers(boroughTileset);
      this._addSourceAndLayers(majorMarketTileset);
      this._addSourceAndLayers(neighborhoodTileset);

      this._addPipelineTiles();

      this._addAggregatedClusters(MapUtil._parseToGeoJSON([]));
      this._addLocationNames(MapUtil._parseToGeoJSON([]));
      this.map.on("click", (e) => {
        if (!this.justToggledPopup) this._togglePopups();
        this.justToggledPopup = false;
      });

      this.map.on("resize", this._resizeCb);

      this.map.on("move", () => {
        this.map.setFilter("mp-boroughs-layer-highlighted", [
          "in",
          "name",
          "FALSE",
        ]);
        this.map.setFilter("mp-major-markets-layer-highlighted", [
          "in",
          "name",
          "FALSE",
        ]);
        this.map.setFilter("mp-neighborhoods-layer-highlighted", [
          "in",
          "name",
          "FALSE",
        ]);
        this._updateLocationMarker({});

        if (this.selectedMarker) {
          this.selectedMarker.remove();
          ReactDOM.unmountComponentAtNode(this.selectedMarker.getElement());
          this.selectedMarker = null;
        }
      });

      this.map.on("moveend", () => {
        if (!this.justFetched) {
          if (!this.state.locationChange && !this.justResized) {
            this.justSetMapBounds = true;
            this.props.handleMapBounds(
              MapUtil._createMapBounds(this.map.getBounds())
            );
          }
        }

        // forcing one more update on move end for dragging/panning
        this.setState({ locationChange: !this.state.locationChange });
        this.currentlyFlying = false;

        if (this.props.isMobile) {
          this.props.captureMobileMapMove(true);
        }
      });

      this.setState({ mapLoaded: true });
      this.checkTilesAndSourcesInterval = setInterval(() => {
        if (
          this.map.areTilesLoaded() &&
          this.map.isSourceLoaded("mp-boroughs") &&
          this.map.isSourceLoaded("mp-major-markets") &&
          this.map.isSourceLoaded("mp-neighborhoods")
        ) {
          clearInterval(this.checkTilesAndSourcesInterval);
          this.setState({ tilesAndSourcesLoaded: true });
        }
      }, 100);
    });
  }

  _createPolyQueryKey(feature, featureType) {
    switch (featureType) {
      case "single":
        return "single";
      case "borough":
        return `borough:"${feature.properties.boro_name.toLowerCase()}"`;
      case "market":
        return `markets_major:"${feature.properties.name.toLowerCase()}"`;
      case "neighborhood":
        return `neighborhood:"${feature.properties.name.toLowerCase()}" AND borough:"${feature.properties.region.toLowerCase()}"`;
    }
  }

  _createDynamicHoverSelectedMarkers(prevProps) {
    if (
      this.props.hoveredListing !== prevProps.hoveredListing &&
      this.state.mapLoaded
    ) {
      if (prevProps.hoveredListing) {
        const prevMarker =
          this.customMarker ||
          this.state.markers[prevProps.hoveredListing.geocode];
        const prevMarkerEl = prevMarker ? prevMarker.getElement() : null;

        if (this.customMarker && prevMarkerEl) {
          prevMarker.remove();
          ReactDOM.unmountComponentAtNode(prevMarkerEl);
          this.customMarker = null;
        } else if (prevMarkerEl) {
          prevMarkerEl.style.background = null;
          prevMarkerEl.style.zIndex = null;
          prevMarkerEl.childNodes[2].style.borderTopColor = null;
        }
      }

      if (this.props.hoveredListing) {
        let marker = this.state.markers[this.props.hoveredListing.geocode];

        if (!marker) {
          const el = document.createElement("div");
          el.classList.add("map-listing-marker-container");

          const priceEl = document.createElement("div");
          priceEl.innerText = _formatPrice(this.props.hoveredListing.price);
          priceEl.classList.add("u_font-12");

          const triEl = document.createElement("div");
          triEl.classList.add("marker-arrow-down-larger");
          const triEl2 = document.createElement("div");
          triEl2.classList.add("marker-arrow-down");

          el.appendChild(priceEl);
          el.appendChild(triEl);
          el.appendChild(triEl2);

          marker = new mapboxgl.Marker(el).setLngLat(
            MapUtil._parseCoordinates(this.props.hoveredListing.geocode)
          );
          this.customMarker = marker;
        }

        const markerEl = marker.getElement();
        if (Object.keys(this.state.markers).length) {
          markerEl.style.background = "#D6BAE8";
          markerEl.style.zIndex = 1;
          markerEl.childNodes[2].style.borderTopColor = "#D6BAE8";
        }

        if (this.customMarker) this.customMarker.addTo(this.map);
      }
    }

    if (prevProps.currentListing) {
      const prevMarker =
        this.selectedMarker ||
        this.state.markers[prevProps.currentListing.geocode];
      const prevMarkerEl = prevMarker ? prevMarker.getElement() : null;

      if (this.selectedMarker && prevMarkerEl) {
        prevMarker.remove();
        ReactDOM.unmountComponentAtNode(prevMarkerEl);
        this.selectedMarker = null;
      } else if (prevMarkerEl) {
        this._togglePopups(
          this.props.currentListing ? this.props.currentListing.geocode : ""
        );
        this.oneClickMarker = null;
        prevMarkerEl.style.background = null;
        prevMarkerEl.style.zIndex = null;
        prevMarkerEl.childNodes[2].style.borderTopColor = null;
      }
    }

    if (this.props.currentListing) {
      let marker = this.state.markers[this.props.currentListing.geocode];

      if (!marker && this.map.getZoom() < 16) {
        const el = document.createElement("div");
        el.classList.add("map-listing-marker-container");

        const priceEl = document.createElement("div");
        priceEl.innerText = _formatPrice(this.props.currentListing.price);
        priceEl.classList.add("u_font-12");

        const triEl = document.createElement("div");
        triEl.classList.add("marker-arrow-down-larger");
        const triEl2 = document.createElement("div");
        triEl2.classList.add("marker-arrow-down");

        el.appendChild(priceEl);
        el.appendChild(triEl);
        el.appendChild(triEl2);

        el.addEventListener("click", () => {
          this.justClickedSelectedMarker = true;
          this.map.easeTo({
            center: MapUtil._parseCoordinates(
              this.props.currentListing.geocode
            ),
            zoom: 14.01,
          });
        });

        marker = new mapboxgl.Marker(el).setLngLat(
          MapUtil._parseCoordinates(this.props.currentListing.geocode)
        );
        this.selectedMarker = marker;
      }

      if (marker) {
        // this._togglePopups(this.props.currentListing.geocode);
        // if (marker.getPopup() && !marker.getPopup().isOpen()) marker.togglePopup();
        this.justToggledPopup = false;

        const markerEl = marker.getElement();
        this.oneClickMarker = markerEl;
        if (Object.keys(this.state.markers).length) {
          markerEl.style.background = "#D6BAE8";
          markerEl.style.zIndex = 1;
          markerEl.childNodes[2].style.borderTopColor = "#D6BAE8";
        }
      }

      if (this.selectedMarker) this.selectedMarker.addTo(this.map);
    }
  }

  _removeMarkersAndPopups(newMarkers = {}, signedIn) {
    Object.keys(this.state.markers).forEach((key) => {
      const oldMarker = this.state.markers[key];
      const newMarker = newMarkers[key];

      if (
        !newMarker ||
        (newMarker &&
          newMarker.getElement().innerText.trim().toLowerCase() !==
            oldMarker.getElement().innerText.trim().toLowerCase()) ||
        signedIn
      ) {
        const marker = this.state.markers[key];
        const markerEl = marker.getElement();
        const popup = marker.getPopup();
        const popupEl = popup ? popup._content.childNodes[0] : null;

        marker.remove();
        ReactDOM.unmountComponentAtNode(markerEl);
        if (popup) {
          popup.remove();
          ReactDOM.unmountComponentAtNode(popupEl);
        }
      }
    });

    const oldMarker = this.state.markers[this.mobilePopupContainerKey];
    const newMarker = newMarkers[this.mobilePopupContainerKey];
    if (
      !newMarker ||
      (newMarker &&
        newMarker.getElement().innerText.trim().toLowerCase() !==
          oldMarker.getElement().innerText.trim().toLowerCase()) ||
      signedIn
    )
      this._removeMobilePopup();
  }

  _keyIsBoro(key) {
    return (
      key === `borough:"bronx"` ||
      key === `borough:"brooklyn"` ||
      key === `borough:"manhattan"` ||
      key === `borough:"staten island"` ||
      key === `borough:"queens"`
    );
  }

  _createClusters(data, polygonData = {}, signedIn) {
    const features = [];

    Object.keys(data).forEach((key) => {
      if (key !== "from_cache" && data[key].doclist.numFound) {
        // const pad = WordWrapLocations[polygonData[key].location] ? -0.6 : 0;
        const pad = 0;

        if (data[key].doclist.numFound >= 3000)
          polygonData[key].offset = this._keyIsBoro(key)
            ? [0, -1.7]
            : [0, -2.6 + pad];
        else if (data[key].doclist.numFound >= 1000)
          polygonData[key].offset = this._keyIsBoro(key)
            ? [0, -1.6]
            : [0, -2.4 + pad];
        else if (data[key].doclist.numFound >= 500)
          polygonData[key].offset = this._keyIsBoro(key)
            ? [0, -1.5]
            : [0, -2.2 + pad];
        else if (data[key].doclist.numFound >= 100)
          polygonData[key].offset = this._keyIsBoro(key)
            ? [0, -1.21]
            : [0, -1.9 + pad];
        else
          polygonData[key].offset = this._keyIsBoro(key)
            ? [0, -1.0]
            : [0, -1.6 + pad];

        features.push(
          MapUtil._createFacet(data[key], undefined, polygonData[key].geocode)
        );
        // features.push(MapUtil._createFacet(data[key], undefined, polygonData[key] && polygonData[key].geocode));
      } else if (key !== "from_cache" && data[key].doclist.numFound === 0) {
        polygonData[key].offset = [0, 0];
      }
    });

    const clusters = [];
    const docs = [];
    features.forEach((feature) =>
      feature.count === 1 ? docs.push(feature.docs[0]) : clusters.push(feature)
    );

    this._createMarkersAndPopups(docs, signedIn);
    this.map
      .getSource("aggregated-clusters")
      .setData(MapUtil._parseToGeoJSON(clusters));
  }

  _removeMobilePopup() {
    if (this.props.isMobile && this.mobilePopupContainer && !this.justFetched) {
      ReactDOM.unmountComponentAtNode(this.mobilePopupContainer);
      this.mobilePopupContainer.parentNode.removeChild(
        this.mobilePopupContainer
      );
      this.mobilePopupContainer = null;
      this.mobilePopupContainerKey = null;
    }
  }

  _createPolyDataInfoObj(polygons, featureType, key) {
    const locationName = key.split('"')[1].toLowerCase();

    if (featureType === "borough") {
      return {
        location: _capitalizeText(locationName),
        geocode: BOROUGHS[_capitalizeText(locationName)].geocode
          ? BOROUGHS[_capitalizeText(locationName)].geocode
              .slice()
              .reverse()
              .join(", ")
          : BOROUGHS[_capitalizeText(locationName)].center
              .slice()
              .reverse()
              .join(", "),
        fontSize: 24,
        offset: [0, -1.5],
      };
    } else {
      const polyDataInfo = {
        fontSize: this.map.getZoom() < 11 || this.map.getZoom() >= 14 ? 16 : 12,
      };
      const multiPlygn = multiPolygon(polygons);

      if (featureType === "neighborhood" && this.map.getZoom() >= 11) {
        const mapBounds = this.map.getBounds();
        const boundsPoly = polygon([
          [
            mapBounds.getSouthWest().toArray(),
            mapBounds.getSouthEast().toArray(),
            mapBounds.getNorthEast().toArray(),
            mapBounds.getNorthWest().toArray(),
            mapBounds.getSouthWest().toArray(),
          ],
        ]);
        const areaPercent = MapUtil._areaPercentage(
          area(multiPlygn),
          area(boundsPoly)
        );

        const hoodTags = this.props.hoodTags.map((tag) =>
          tag.hood.toLowerCase()
        );

        polyDataInfo.location =
          areaPercent > 1.7 || hoodTags.includes(locationName)
            ? _capitalizeText(locationName)
            : "";
      } else {
        polyDataInfo.location = _capitalizeText(locationName);
      }

      const geoCenter = center(multiPlygn).geometry.coordinates.slice();
      if (LocationsPadding[polyDataInfo.location]) {
        geoCenter[0] =
          geoCenter[0] + LocationsPadding[polyDataInfo.location][0];
        geoCenter[1] =
          geoCenter[1] + LocationsPadding[polyDataInfo.location][1];
      }
      polyDataInfo.geocode = geoCenter.reverse().join(", ");

      // let count = 0;
      // for (let i = 0; i < locationName.length; i++) {
      //   if (locationName[i] !== " ") count += 1;
      // }
      // polyDataInfo.offset = count > 15 ? [0, -2.2] : [0, -1.6];

      return polyDataInfo;
    }
  }

  _setNeighborhood(prevProps = {}) {
    const { polyHoods } = this.state;
    const { searchTags } = this.props;

    //const hoodTags = this.props.hoodTags.map(tag => tag.hood.toLowerCase().trim());
    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags
            .filter((item) => item.type === "borough")
            .map((item) => item.name)
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    let hoods = polyHoods && polyHoods.length > 0 ? polyHoods : [];

    if (hoods.length && !boroTags.length) {
      const polygons = hoods.map(({ coordinates }) => [
        coordinates.slice(0, coordinates.length - 1),
      ]);

      // L.Polygon returns lat and lng values reversed
      // meaning "lat" key maps to long value / "lng" key maps to lat value
      const fit = new L.Polygon(polygons).getBounds();
      const { lat: swLng, lng: swLat } = fit.getSouthWest();
      const { lat: neLng, lng: neLat } = fit.getNorthEast();

      const boundsPoly = polygon([
        [
          [fit.getSouthWest().lat, fit.getSouthWest().lng],
          [fit.getSouthEast().lat, fit.getSouthEast().lng],
          [fit.getNorthEast().lat, fit.getNorthEast().lng],
          [fit.getNorthWest().lat, fit.getNorthWest().lng],
          [fit.getSouthWest().lat, fit.getSouthWest().lng],
        ],
      ]);
      const areaHood = area(boundsPoly);

      if (areaHood > 3000000 && areaHood < 15000000 && polygons.length <= 5) {
        const southWest = new mapboxgl.LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new mapboxgl.LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );
        const boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
        const center = boundBox.getCenter();
        this.map.flyTo({ center: center, zoom: 14.01, pitch: 0 });
      } else {
        const bDif = 0.0; //polygons.length === 1 ? 0.0005 : 0.0001;
        const bounds = [
          [swLng - bDif, swLat - bDif],
          [neLng + bDif, neLat + bDif],
        ];
        this.map.fitBounds(bounds);
      }
    }
  }

  _setLocationNames(plygnDataArr = []) {
    this.map
      .getSource("location-names")
      .setData(MapUtil._parseToGeoJSON(plygnDataArr));
  }

  _setSourceFilters(boros = [], markets = [], hoods = []) {
    this.map.setFilter("mp-boroughs-layer", ["in", "boro_name", ...boros]);

    this.map.setFilter("mp-boroughs-layer-outline", [
      "in",
      "boro_name",
      ...boros,
    ]);

    this.map.setFilter("mp-major-markets-layer", ["in", "name", ...markets]);

    this.map.setFilter("mp-major-markets-layer-outline", [
      "in",
      "name",
      ...markets,
    ]);

    this.map.setFilter("mp-neighborhoods-layer", ["in", "id", ...hoods]);

    this.map.setFilter("mp-neighborhoods-layer-outline", [
      "in",
      "id",
      ...hoods,
    ]);
  }

  _getPolyHoods() {
    const { searchTags } = this.props;
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags
            .filter((item) => item.type === "neighborhood")
            .map((item) => item.name)
        : [];

    if (hoodTags.length > 0) {
      _getNeighborhoods(searchTags, {
        callback: function (hoods) {
          //this.props.updateNeighborhoods({ neighborhoods: hoods });
          this.setState({ polyHoods: hoods });
        }.bind(this),
        url: buildingUrl,
      });
    } else {
      this.setState({ polyHoods: [] });
    }
  }

  _getBuildingData = (key, obj) => {
    let buildingCall = {
      url: buildingUrl + "we3-buildings?cache=60",
      method: "post",
      query: {
        q: "key:" + key,
        fq: "deleted:false",
        fl: "geocode, polygon_json",
        wt: "json",
        rows: 1,
      },
    };

    _axiosCall(buildingCall)
      .then((res) => {
        if (typeof obj.callback === "function") {
          obj.callback(res);
        }
      })
      .catch((error) => {
        console.log("BlBuildingPage error ", error);
        this.setState({
          fetchInProgress: false,
        });
      });
  };

  _setExistingBuilding(buildingTags) {
    const buildingData =
      buildingTags &&
      buildingTags.length > 0 &&
      buildingTags[buildingTags.length - 1].data
        ? buildingTags[buildingTags.length - 1].data
        : false;

    if (buildingData) {
      this._getBuildingData(buildingData.key, {
        callback: function (res) {
          if (res.docs && res.docs[0] && res.docs[0].geocode) {
            //mapbox version
            // if (res && res.features && res.features[0]) {
            buildingData["geocode"] = MapUtil._parseCoordinates(
              res.docs[0].geocode
            );
            var lngLat = new mapboxgl.LngLat(
              buildingData["geocode"][0],
              buildingData["geocode"][1]
            );
            // var lngLat = new mapboxgl.LngLat(geocode[1],geocode[0]);

            this.map.flyTo({ center: lngLat, zoom: 17 });
            const keys = buildingTags.map((bldg) => bldg.data.key);

            building_tileset.forEach((tileset, i) => {
              const filter = keys
                ? ["in", "key"].concat(keys)
                : ["==", "key", "FALSE"];
              this.map.setFilter(tileset.layer_id + "-select", filter);
            });

            pipeline_tileset.forEach((tileset, i) => {
              const filter = keys
                ? ["in", "building_key"].concat(keys)
                : ["==", "key", "FALSE"];
              this.map.setFilter(tileset.layer_id + "-select", filter);
              this.map.setFilter(tileset.layer_id + "-3d-select", filter);
            });
          }
        }.bind(this),
      });
    }
  }

  _setBuilding(buildingTags) {
    const buildingData =
      buildingTags &&
      buildingTags.length > 0 &&
      buildingTags[buildingTags.length - 1].data
        ? buildingTags[buildingTags.length - 1].data
        : false;
    const zoom = this.map.getZoom();

    if (buildingData) {
      if (buildingData.key && buildingData.key.indexOf("b-") >= 0) {
        this._setExistingBuilding(buildingTags);
      } else {
        //Set pipeline building

        if (buildingData.polygon_bbl_json) {
          var polygon_json = JSON.parse(buildingData.polygon_bbl_json);
          var polygon = polygon_json.coordinates;
          var fit = new L.Polygon(polygon).getBounds();
          var southWest = new mapboxgl.LngLat(
            fit["_southWest"]["lat"],
            fit["_southWest"]["lng"]
          );
          var northEast = new mapboxgl.LngLat(
            fit["_northEast"]["lat"],
            fit["_northEast"]["lng"]
          );
          var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);
          var center = boundBox.getCenter();

          this.map.flyTo({ center: center, zoom: 17 });
        } else if (buildingData.geocode) {
          var geocode = MapUtil._parseCoordinates(buildingData.geocode);
          var lngLat = new mapboxgl.LngLat(geocode[1], geocode[0]);

          this.map.flyTo({ center: lngLat, zoom: 17 });
        }

        // checks for past markers and removes all
        pipeline_tileset.forEach((tileset, i) => {
          const keys = buildingTags.map((bldg) => bldg.data.key);
          const filter = keys
            ? ["in", "key"].concat(keys)
            : ["==", "key", "FALSE"];

          this.map.setFilter(tileset.layer_id + "-select", filter);
          this.map.setFilter(tileset.layer_id + "-3d-select", filter);
        });
      }
    } else {
      pipeline_tileset.forEach((tileset) => {
        this.map.setFilter(tileset.layer_id + "-select", [
          "==",
          "key",
          "FALSE",
        ]);
        this.map.setFilter(tileset.layer_id + "-3d-select", [
          "==",
          "key",
          "FALSE",
        ]);
      });
      building_tileset.forEach((tileset, i) => {
        this.map.setFilter(tileset.layer_id + "-select", [
          "==",
          "key",
          "FALSE",
        ]);
      });
    }
  }

  _createPolygonsObj(features = [], featureType) {
    const poly = {};
    features.forEach((feature) => {
      const key = this._createPolyQueryKey(feature, featureType);
      if (!poly[key]) poly[key] = [];

      if (feature.geometry.type === "MultiPolygon")
        feature.geometry.coordinates.forEach((polygon) =>
          poly[key].push(polygon)
        );
      else poly[key].push(feature.geometry.coordinates);
    });
    return poly.single || poly;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mapUnsupported === true) return;
    const { searchTags } = this.props;
    const prevSearchTags = prevProps.searchTags;
    const boroTags =
      searchTags.filter((item) => item.type === "borough").length > 0
        ? searchTags.filter((item) => item.type === "borough")
        : [];
    const hoodTags =
      searchTags.filter((item) => item.type === "neighborhood").length > 0
        ? searchTags.filter((item) => item.type === "neighborhood")
        : [];
    const buildingTags =
      searchTags.filter((item) => item.type === "building").length > 0
        ? searchTags.filter((item) => item.type === "building")
        : [];

    const prevBoroTags =
      prevSearchTags.filter((item) => item.type === "borough").length > 0
        ? prevSearchTags.filter((item) => item.type === "borough")
        : [];
    const prevHoodTags =
      prevSearchTags.filter((item) => item.type === "neighborhood").length > 0
        ? prevSearchTags.filter((item) => item.type === "neighborhood")
        : [];
    const prevBuildingTags =
      prevSearchTags.filter((item) => item.type === "building").length > 0
        ? prevSearchTags.filter((item) => item.type === "building")
        : [];

    const differentTags =
      !_.isEqual(prevProps, this.props) ||
      !_isEqual(prevProps.subTabTags, this.props.subTabTags) ||
      !MapUtil._boundsEqual(this.props.mapBounds, prevProps.mapBounds);

    this._createDynamicHoverSelectedMarkers(prevProps);

    const canvas = this.map.getCanvas();
    const width =
      this.props.isMobile || this.props.width < 768
        ? this.props.width
        : this.props.width > 767 && this.props.width < 1179
        ? this.props.width - 400
        : this.props.width - 780;
    if (
      (this.props.width && this.props.width !== prevProps.width) ||
      (this.props.height && this.props.height !== prevProps.height) ||
      (canvas &&
        canvas.offsetHeight &&
        canvas.offsetHeight < this.props.containerHeight) ||
      (canvas && canvas.offsetWidth && canvas.offsetWidth < width)
    ) {
      this.map.resize();
    }

    if (
      !_.isEqual(boroTags, prevBoroTags) ||
      !_.isEqual(hoodTags, prevHoodTags)
    ) {
      this._getPolyHoods();
    }

    if (!_.isEqual(prevState.polyHoods, this.state.polyHoods)) {
      this._setNeighborhood();
    } else if (!_isEqual(prevBoroTags, boroTags) && boroTags.length > 0) {
      let tag;
      if (boroTags[boroTags.length - 1] && boroTags[boroTags.length - 1].name) {
        tag = boroTags[boroTags.length - 1].name;
      } else {
        tag = boroTags[boroTags.length - 1];
      }
      if (!this.currentlyFlying) {
        this.currentlyFlying = true;
        this.map.flyTo({
          center: BOROUGHS[_capitalizeText(tag)].center,
          zoom: 12.01,
        });
      }
    }
    if (
      buildingTags &&
      buildingTags.length > 0 &&
      !_.isEqual(buildingTags, prevBuildingTags)
    ) {
      this._setBuilding(buildingTags);
    }
    if (
      this.state.mapLoaded &&
      this.state.styleLoaded &&
      this.state.mapRendered &&
      this.state.tilesAndSourcesLoaded &&
      !this.justFetched &&
      (differentTags ||
        this.state.mapLoaded !== prevState.mapLoaded ||
        this.state.styleLoaded !== prevState.styleLoaded ||
        this.state.mapRendered !== prevState.mapRendered ||
        this.state.tilesAndSourcesLoaded !== prevState.tilesAndSourcesLoaded ||
        this.state.locationChange !== prevState.locationChange)
    ) {
      const zoom = this.map.getZoom();
      const polygonData = {};
      let polygonQueries =
        zoom < 14
          ? MapUtil._createPolygonQueries(
              this.map.getBounds(),
              this.map.getZoom(),
              this.props.isMobile,
              false
            )
          : MapUtil._createPolygonQuery(
              this.map.getBounds(),
              this.props.isMobile
            );
      if (this.map.getZoom() < 15) polygonQueries.groupQuery = {};
      if (this.map.getZoom() < 11) {
        if (this.props.hideMapLayer) this.props.hideMapLayer();
        MapUtil.removeMapBoundsfromUrl();
        const features = this.map.queryRenderedFeatures({
          layers: ["mp-boroughs-layer-transparent"],
          filter:
            hoodTags && hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "boro_name"]
              : [
                  "in",
                  "boro_name",
                  ...boroTags.map((tag) => _capitalizeText(tag)),
                ],
        });

        const poly = this._createPolygonsObj(features, "borough");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "borough",
              key
            ))
        );

        const hood_keys = this.state.polyHoods.map((tag) => tag.key);

        if (this.state.polyHoods) {
          this.state.polyHoods.forEach((hood) => {
            const key = this._createPolyQueryKey(
              { properties: hood },
              "neighborhood"
            );
            polygonData[key] = {};
            const plygn = polygon([
              hood.coordinates.map((coord) => [
                Number(coord[0]),
                Number(coord[1]),
              ]),
            ]);
            const cent = center(plygn);

            polygonData[key].location = _capitalizeText(hood.name);
            polygonData[key].geocode = cent.geometry.coordinates
              .reverse()
              .join(", ");
            polygonData[key].fontSize = 16;
            polygonData[key].offset = [0, -1.5];
          });
        }

        const boroNames = features.map((feat) => feat.properties.boro_name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        this._setLocationNames(plygnDataArr);

        this._setSourceFilters(boroNames, [], hood_keys);
      } else if (this.map.getZoom() < 13) {
        if (this.props.hideMapLayer) this.props.hideMapLayer();
        const features1 = this.map.queryRenderedFeatures({
          layers: ["mp-major-markets-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "name"]
              : ["in", "region", ...boroTags],
        });
        const hood_keys = this.state.polyHoods.map((tag) => tag.key);

        const features2 = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["!in", "region", "manhattan", "brooklyn"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  [
                    "in",
                    "region",
                    ...boroTags.filter(
                      (tag) => tag !== "manhattan" && tag !== "brooklyn"
                    ),
                  ],
                ],
        });

        const poly = this._createPolygonsObj(features1, "market");
        const poly2 = this._createPolygonsObj(features2, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "market",
              key
            ))
        );
        Object.keys(poly2).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly2[key],
              "neighborhood",
              key
            ))
        );

        const majorMarkets = features1.map((feat) => feat.properties.name);
        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        // this._setLocationNames(plygnDataArr);

        this._setSourceFilters([], majorMarkets, [
          ...hood_keys,
          ...features2.map((feat) => feat.properties.id),
        ]);
        MapUtil.setURLForMapBounds(
          this.props,
          MapUtil._createMapBounds(this.map.getBounds())
        );
        console.log("DUET MAJOR MARKETS");
      } else if (this.map.getZoom() < 15) {
        const hood_keys = this.state.polyHoods.map((tag) => tag.key);
        if (this.props.showMapLayer && this.map.getZoom() >= 14)
          this.props.showMapLayer();
        const features = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "id"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  ["in", "region", ...boroTags],
                ],
        });
        const poly = this._createPolygonsObj(features, "neighborhood");

        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "neighborhood",
              key
            ))
        );

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        this._setLocationNames(plygnDataArr);

        this._setSourceFilters(
          [],
          [],
          [...hood_keys, ...features.map((feat) => feat.properties.id)]
        );
        MapUtil.setURLForMapBounds(
          this.props,
          MapUtil._createMapBounds(this.map.getBounds())
        );
      } else {
        console.log("DUET LISTINGS");
        if (this.props.showMapLayer) this.props.showMapLayer();
        const hood_keys = this.state.polyHoods.map((tag) => tag.key);

        const features = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "id"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  ["in", "region", ...boroTags],
                ],
        });
        const poly = this._createPolygonsObj(features, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "neighborhood",
              key
            ))
        );

        const plygnDataArr = Object.keys(polygonData).map(
          (key) => polygonData[key]
        );
        this._setLocationNames(plygnDataArr);

        this._setSourceFilters(
          [],
          [],
          [...hood_keys, ...features.map((feat) => feat.properties.id)]
        );
        MapUtil.setURLForMapBounds(
          this.props,
          MapUtil._createMapBounds(this.map.getBounds())
        );
      }

      //this.justFetched = true;
      const polyStr = JSON.stringify(polygonQueries);
      this.callQueue.push(polyStr);
      this._getMapListings(polygonQueries)
        .then((data) => {
          // let callPosition = this.callQueue.indexOf(polyStr);
          // if (callPosition < this.callQueue.length - 1) {
          //   console.log("Subsequent Call Made - Do Nothing");
          //   return null;
          // }
          const throttleAmt = this.props.isMobile ? 100 : 500;
          if (data.docs) {
            if (data.numFound > throttleAmt) {
              this._displayThrottleMessage(throttleAmt, data.numFound);
            } else {
              this.throttleEl.classList.remove("show-throttle-message");
            }
            this.map
              .getSource("aggregated-clusters")
              .setData({ type: "FeatureCollection", features: [] });
            this._createMarkersAndPopups(
              data.docs,
              this.props.authData &&
                this.props.authData.authenticated !== prevProps.authData &&
                prevProps.authData.authenticated
            );
          } else {
            this.throttleEl.classList.remove("show-throttle-message");
            if (prevState.mapLoaded || this.state.mapLoaded) {
              this.markerCount = 0;
              this._createClusters(
                data,
                polygonData,
                this.props.authData &&
                  this.props.authData.authenticated !== prevProps.authData &&
                  prevProps.authData.authenticated
              );
              const plygnDataArr = Object.keys(polygonData).map(
                (key) => polygonData[key]
              );
              this._setLocationNames(plygnDataArr);
            }
          }
          this.callQueue = [];
        })
        .catch((err) => console.log(err));
    }
    this._filterPipelineTiles();
  }

  componentWillUnmount() {
    if (this.state.mapUnsupported === true) return;
    this._removeMarkersAndPopups();
    clearTimeout(this.resizeTimeout);
    clearInterval(this.checkTilesAndSourcesInterval);
    this._isMounted = false;
    this.map.remove();
  }

  render() {
    const { occupancyTypes, modal, permit } = this.state;
    const cStyle = {
      width: "100%",
      // minWidth:
      //   this.props.isMobile || this.props.width < 768
      //     ? this.props.width
      //     : this.props.width > 767 && this.props.width < 1179
      //     ? this.props.width - 400
      //     : this.props.width - 780,
      // maxWidth:
      //   this.props.isMobile || this.props.width < 768
      //     ? this.props.width
      //     : this.props.width > 767 && this.props.width < 1179
      //     ? this.props.width - 400
      //     : this.props.width - 780,
      height: this.props.containerHeight,
      // height: 900,
      marginTop: this.props.isMobile ? 10 : 0,
    };
    return (
      <div
        id="pipeline-map-container"
        className="pipeline-map-container"
        style={{ height: this.props.isfilingPage ? "33%" : "100%" }}
      >
        {!this.props.isfilingPage && (
          <LegendToggle
            occupancyTypes={occupancyTypes}
            setOccupancyFilter={this._setOccupancyFilter}
          />
        )}
        <div
          className={`c_map-container ${
            this.props.isMobile ? "is-mobile" : ""
          }`}
          ref={(el) => (this.mapContainer = el)}
          style={cStyle}
        />
        <PopupModal toggle={this._toggleModal} modal={modal} data={permit} />
      </div>
    );
  }
}
