import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';

import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon';
import HeartIcon from 'mdi-react/HeartIcon';
import TrashCanIcon from 'mdi-react/TrashCanOutlineIcon';

import { Publisher } from './../../pubsub/publisher.js';



class AddToBuyersListButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    projectKey: PropTypes.string,
    units: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = { buttonText: 'Save', saved:false };
  }
  
  componentDidMount() {
	  	
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	  console.log(this.props)
  }
    
  handleSubmit(event, projectKey, organizationKey, contactKey) {
	event.stopPropagation();
    event.preventDefault();
    
    const { user, type } = this.props;
    const { addMyBuyersList, saveProject, saveOrganization, saveContact } = this.props.actions;
    
    if(type == "project"){
	    //addMyBuyersList(buildingKey, units, user);
	   saveProject(projectKey, user);
     Publisher.publish('track.save.project', {project: projectKey});
    }else if(type == "organization"){
      //addMyBuyersList(buildingKey, units, user);
     saveOrganization(organizationKey, user);
     Publisher.publish('track.save.organization', {organization: organizationKey});
    }else if(type == "contact"){
      //addMyBuyersList(buildingKey, units, user);
     saveContact(contactKey, user);
     Publisher.publish('track.save.contact', {contact: contactKey});
    }
  		
    
    //this.setState({ buttonText: 'Saved', saved:true })
    
  }

  render() {
    const {
      actions, t, theme, rtl, projectKey, units, buyersLists, savedProjects, savedOrganizations, savedContacts, organizationKey, contactKey, unitSave, removeOnly, type
    } = this.props;
    
    let saved = false;
    let typeText = "Projects";
    if(type == "project"){
      saved = savedProjects.findIndex(item => (item.key === projectKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
    }else if(type == "organization"){
      saved = savedOrganizations.findIndex(item => (item.key === organizationKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
      typeText = "Companies";
    }else if(type == "contact"){
      saved = savedContacts.findIndex(item => (item.key === contactKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
      typeText = "Contacts";
    }
    
    			
    return (
		    <Tooltip title={saved ? `Removed from Saved ${typeText} ` : `Save this ${typeText}`}>
		        <IconButton
			        onClick={(e) => {this.handleSubmit(e, projectKey, organizationKey, contactKey)}}
			         variant=""
			         color=""
			         className={`material-table__toolbar-button save-buyerslist`}		         
			     >	{saved ? removeOnly ? <TrashCanIcon /> : <HeartIcon /> : <HeartOutlineIcon />}
			     </IconButton>				 
		  	</Tooltip>
    );
  }
}

function mapStateToProps(state) {
	//console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		savedProjects: Array.isArray(state.buyersLists.savedProjects) ? state.buyersLists.savedProjects.slice() : [],
	  		savedOrganizations: Array.isArray(state.buyersLists.savedOrganizations) ? state.buyersLists.savedOrganizations.slice() : [],
        savedContacts: Array.isArray(state.buyersLists.savedContacts) ? state.buyersLists.savedContacts.slice() : []  
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddToBuyersListButton));

