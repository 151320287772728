import React, { Component, } from "react";
import GoogleAnalytics from "react-ga";

GoogleAnalytics.initialize(process.env.GA_TRACKING_ID);

const WithTracker = (WrappedComponent, options = {}, moreProps) => {
  const trackPage = page => {
    
    return false;
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
    if(window){
	     window.last_url = page;
	     window.Intercom('update');
    }
    console.log('withTracker Track Page', page);
  };
  
  const shouldTrack = page => {
	  
	  if(!window.last_url){
	      return true;
      }else if(window.last_url && window.last_url !== page){
	      return true;
	      
      }	
      return false;
	  
  }

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      if(this.props && this.props.location){
	      let page = this.props.location.pathname + this.props.location.search;
	      if(['?', '/'].indexOf(page[page.length-1])>=0){
		      page = page.substring(0, page.length - 1);
	      }
	      
	      if(shouldTrack(page)){
		      console.log(page);
		      trackPage(page);
	      }	  
	  }   
      
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      let nextPage =
        this.props.location.pathname + this.props.location.search;
      
      if(['?', '/'].indexOf(nextPage[nextPage.length-1])>=0){
		      nextPage = nextPage.substring(0, nextPage.length - 1);
	  }

      if (currentPage !== nextPage) {
	      if(shouldTrack(nextPage)){
		       console.log(nextPage);
		      trackPage(nextPage);		      
	      }       
      }
    }

    render() {
      return <WrappedComponent {...this.props} {...moreProps} />;
    }
  };

  return HOC;
};

export default WithTracker;