import React from "react";
import { ButtonToolbar } from "reactstrap";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Form from "@rjsf/material-ui";
import LayoutField from "./LayoutField";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _getBuildingPhotos,
  _makeSlugifiedArr,
  _shouldShow,
  _urlToName,
  _getBuildingURL,
  _qaThreadMap,
  _formatUnitNumber,
} from "../../../../shared/helpers/utils";

const apiUrl = process.env.API_ROOT + "/buyerslist/"; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  const { title, required, id } = props;

  let legend = (legend = `${title}${required ? "*" : ""}`);
  return (
    <label className="control-label" for={id}>
      {legend}
    </label>
  );
};

const schema = {
  title: "Project Profile",
  type: "object",
  required: ["name"],
  properties: {
    milestone: {
      type: "array",
      title: "Milestones",
      description: "Add milestones for this project.",
      items: {
        type: "object",
        properties: {
          Milestone: {
            type: "string",
            enum: [
              "custom",
              "pipeline_filing_date",
              "pipeline_permit_issuance_job_start_date",
              "pipeline_coo_issue_type",
              "land_acquired_date",
              "loan_secured_date",
            ],
            enumNames: [
              "Custom Milestone",
              "Permit Filed Date",
              "Permit Issued Date",
              "Certificate of Occupancy Date",
              "Land Acquired",
              "Financing Secured",
            ],
            default: "custom",
          },
          Description: {
            type: "string",
          },
          Date: {
            type: "string",
            format: "date",
          },
          Completed: {
            type: "boolean",
          },
        },
      },
    },
  },
  definitions: {},
};

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField,
};

const uiSchema = {
  "ui:field": "layout_grid",
  "ui:layout_grid": {
    "ui:row": [
      {
        "ui:col": {
          md: 12,
          children: [
            {
              "ui:row": [
                {
                  "ui:col": {
                    md: 12,
                    children: [
                      {
                        render: (props) => {
                          const { formData, errorSchema } = props;

                          return (
                            <div>
                              <h5 className="section_title">
                                Project Milestones
                              </h5>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                },
              ],
            },
            { "ui:row": [{ "ui:col": { md: 12, children: ["milestone"] } }] },
          ],
        },
      },
    ],
  },
};

const EditMilestones = (props) => {
  const { formData, onChange, onSubmit, toggle, saveInProgress } = props;

  return (
    <div className="project_edit milestones">
      <Form
        formData={formData}
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        onChange={onChange}
        noHtml5Validate={true}
      >
        <ButtonToolbar className="modal__footer">
          <Tooltip title="Cancel">
            <Button
              onClick={toggle}
              variant="outlined"
              color=""
              className=""
              startIcon={null}
              disabled={saveInProgress}
            >
              {" "}
              Cancel
            </Button>
          </Tooltip>{" "}
          <Tooltip title="Save">
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              className="buyersList__primary-button"
              disabled={saveInProgress}
            >
              {" "}
              {saveInProgress ? "Saving..." : "Save"}
            </Button>
          </Tooltip>
        </ButtonToolbar>
      </Form>
    </div>
  );
};

export default EditMilestones;
