import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';

import{
	_capitalizeText,
} from '../helpers/utils';

class ShareFooter extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
	this.unit = false;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
 
  render(){
	  const { user, urlState } = this.props;
	  const { collapse } = this.state;
	  return (
	    <div className="footer">
	        <div className="footer__wrapper container">
	          <div className="c_footer-section footer__left">
	            
	            <a className="footer__logo" href="https://marketproof.com/pipeline" target="_blank"/>
	          </div>
	          
	          <div className="c_footer-section">
				   <h4>About Us</h4>
				   <ul>
				      <li><a href="https://marketproof.com/about" target="_blank">Marketproof</a></li>
				      <li><a href="https://marketproof.com/new-development" target="_blank">New Development</a></li>
					  <li><a href="https://marketproof.com/pipeline" target="_blank">Pipeline</a></li>
				      <li><a href="https://help.marketproof.com/" target="_blank">Help</a></li>
				      <li><a href="https://www.indeedjobs.com/marketproof-inc/_hl/en_US" target="_blank">Careers</a></li>
				   </ul>
			  </div>
			  <div className="c_footer-section">
				   <h4>Resources</h4>
				   <ul>
				      <li><a href="https://marketproof.com/privacy-policy" target="_blank">Privacy Policy</a></li>
				      <li><a href="https://marketproof.com/terms-of-use" target="_blank">Terms of Use</a></li>
				      <li><a href="https://marketproof.com/fair-housing" target="_blank">Fair Housing</a></li>
				   </ul>
			  </div>
			  <div className="break" />
			  <div className="copyright">Marketproof, Inc. &copy; {new Date().getFullYear()}</div>
			</div>
			
	    </div>
	
	  );
	}
};
export default ShareFooter;