import { _axiosCall } from '../../shared/helpers/apicalls';

const initialState = {
  buyersLists: [
  {
    id: 1,
    listCount: 6,
    key:'b-1-240-south-street-10002',
    units:["apt 10a", "apt 10h", "apt 10d", "apt 10e", "apt 11d"],
  },
 {
    id: 3,
    listCount: 2,
    key: 'b-4-2315-44th-dr-11101',
    units:[],
  },
 {
    id: 4,
    listCount: 7,
    key: 'b-3-51-york-st-11201',
    units:[],
  },
 {
    id: 5,
    listCount: 6,
    key: 'b-1-128-william-st-10038',
    units:["apt 16d","apt 28d","apt 29b"],
  },
  {
	id: 4,
    listCount: 8,
    key: 'b-3-464-wythe-ave-11249',
    units:[],  
  }],
  priorityFilter: '',
  savedProjects: [],
  savedContacts: [],
  viewedBuildings: [],
  savedOrganizations: [],
  contactsShown: null,
  dataSet: false
};

const _saveUser = async (user, data, callback) => {
		
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/userdata/${user.id}`,
			method: "post",
			query: data
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log('Data', res);
				if (typeof callback === "function") {
					let formattedRes = callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
};





function getId(state) {
  return state.buyersLists.reduce((maxId, buyersList) => Math.max(buyersList.id, maxId), -1) + 1;
}
function addItem(state, action) {
	
  const existingItemIndex = state.buyersLists.findIndex(item => item.key === action.key);
  let user = action.user;
  let data = null;
  let newState = Object.assign({}, state);
  if(existingItemIndex > -1){
	  //do update  	  
	  console.log("do")
	  newState.buyersLists[existingItemIndex].units = [...new Set([...newState.buyersLists[existingItemIndex].units, ...action.units])];
	    
  }else{
	  //do add
	  newState = Object.assign({}, state, {
        buyersLists: [{
          key: action.key,
          listCount: Math.floor(Math.random() * Math.floor(10)),
          id: getId(state),
          units: action.units
        }, ...state.buyersLists],
      });
  }
  _saveUser(user, newState, function(res){
	  console.log(res);
	  
  })
  console.log(newState);
  return newState;
  
   
}

function saveProject(state, action) {	
  const existingItemIndex = Array.isArray(state.savedProjects) ? state.savedProjects.findIndex(item => item.key === action.key) : -1;
  let user = action.user;
  let newState = Object.assign({}, state);
  if(!newState.savedProjects && user.savedProjects){
    newState.savedProjects =   Object.assign({}, user.savedProjects);
  }
  
  if(existingItemIndex > -1){
	  //do update  	  
	  if(newState.savedProjects[existingItemIndex].deleted){
		  delete newState.savedProjects[existingItemIndex].deleted;
		  delete newState.savedProjects[existingItemIndex].deleted_timestamp;
		  newState.savedProjects[existingItemIndex].created_timestamp = Date.now()
	  }else{
		  newState.savedProjects[existingItemIndex].deleted = true;
		  newState.savedProjects[existingItemIndex].deleted_timestamp = Date.now();		  
	  }	    
  }else{
	  //do add
	  const project = {
		  key: action.key,
		  created_timestamp : Date.now(),
		  source_namespace: 'MARKETPROOF-PIPELINE-APP'
	  }
	  newState = Object.assign({}, state, {
        savedProjects: Array.isArray(state.savedProjects) ? [project, ...state.savedProjects] : [project],
      });
  }
  _saveUser(user, newState, function(res){
	  console.log(res);
	  
  })
  console.log(newState);
  return newState; 
}

function saveOrganization(state, action) {	
  const existingItemIndex = Array.isArray(state.savedOrganizations) ? state.savedOrganizations.findIndex(item => item.key === action.key) : -1;
  let user = action.user;
  let newState = Object.assign({}, state);
  if(!newState.savedOrganizations && user.savedOrganizations){
    newState.savedOrganizations =   Object.assign({}, user.savedOrganizations);
  }
  if(existingItemIndex > -1){
    //do update  	  
    if(newState.savedOrganizations[existingItemIndex].deleted){
      delete newState.savedOrganizations[existingItemIndex].deleted;
      delete newState.savedOrganizations[existingItemIndex].deleted_timestamp;
      newState.savedOrganizations[existingItemIndex].created_timestamp = Date.now()
    }else{
      newState.savedOrganizations[existingItemIndex].deleted = true;
      newState.savedOrganizations[existingItemIndex].deleted_timestamp = Date.now();		  
    }	    
  }else{
    //do add
    const organization = {
      key: action.key,
      created_timestamp : Date.now(),
      source_namespace: 'MARKETPROOF-PIPELINE-APP'
    }
    newState = Object.assign({}, state, {
        savedOrganizations: Array.isArray(state.savedOrganizations) ? [organization, ...state.savedOrganizations] : [organization],
      });
  }
  _saveUser(user, newState, function(res){
    console.log(res);
    
  })
  console.log(newState);
  return newState; 
}
  
function saveContact(state, action) {	
  const existingItemIndex = Array.isArray(state.savedContacts) ? state.savedContacts.findIndex(item => item.key === action.key) : -1;
  let user = action.user;
  let newState = Object.assign({}, state);
  if(!newState.savedContacts && user.savedContacts){
    newState.savedContacts =   Object.assign({}, user.savedContacts);
  }
  if(existingItemIndex > -1){
    //do update  	  
    if(newState.savedContacts[existingItemIndex].deleted){
      delete newState.savedContacts[existingItemIndex].deleted;
      delete newState.savedContacts[existingItemIndex].deleted_timestamp;
      newState.savedContacts[existingItemIndex].created_timestamp = Date.now()
    }else{
      newState.savedContacts[existingItemIndex].deleted = true;
      newState.savedContacts[existingItemIndex].deleted_timestamp = Date.now();		  
    }	    
  }else{
    //do add
    const contact = {
      key: action.key,
      created_timestamp : Date.now(),
      source_namespace: 'MARKETPROOF-PIPELINE-APP'
    }
    newState = Object.assign({}, state, {
        savedContacts: Array.isArray(state.savedContacts) ? [contact, ...state.savedContacts] : [contact],
      });
  }
  _saveUser(user, newState, function(res){
    console.log(res);
    
  })
  console.log(newState);
  return newState; 
}
function viewBuilding(state, action) {	
  return false;
  const existingItemIndex = Array.isArray(state.viewedBuildings) ? state.viewedBuildings.findIndex(item => item.key === action.key) : -1;
  let user = action.user;
  let newState = Object.assign({}, state);
  if(existingItemIndex > -1){
		  newState.viewedBuildings[existingItemIndex].views = newState.viewedBuildings[existingItemIndex].views + 1;
		  newState.viewedBuildings[existingItemIndex].last_viewed_timestamp = Date.now();		  
	  	    
  }else{
	  //do add
	  const building = {
		  key: action.key,
		  first_viewed_timestamp : Date.now(),
		  last_viewed_timestamp : Date.now(),
		  views: 1
	  }
	  newState = Object.assign({}, state, {
        viewedBuildings: Array.isArray(state.viewedBuildings) ? [building, ...state.viewedBuildings] : [building],
      });
  }
  _saveUser(user, newState, function(res){
	  console.log(res);
	  
  })
  console.log(newState);
  return newState; 
}


const buyersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TODO':
      return addItem(state, action);      
    case 'SAVE_PROJECT':
      return saveProject(state, action);
    case 'VIEW_BUILDING':
      return viewBuilding(state, action);
    case 'SAVE_ORGANIZATION':
      return saveOrganization(state, action);
    case 'SAVE_CONTACT':
      return saveContact(state, action);
    case 'SET_DATA': 
    	  const data = Object.assign({}, action.user, {dataSet: true});  
		  return  data;   
    case 'COMPLETE_TODO':
      return Object.assign({}, state, {
        buyersLists: state.buyersLists.map(buyersList => (buyersList.id === action.id
          ? Object.assign({}, buyersList, {
            updated: false,
            completed: !buyersList.completed,
          }) : buyersList)),
      });
    case 'DELETE_TODO':
      return Object.assign({}, state, {
        buyersLists: state.buyersLists.filter(buyersList => buyersList.id !== action.id),
      });
    case 'PRIORITY_FILTER':
      return Object.assign({}, state, {
        priorityFilter: action.priorityFilter,
      });
    default:
      return state;
  }
};
export default buyersListReducer;
