import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import buyersListActions from "../../../redux/actions/buyersListActions";

import {
  Card,
  CardBody,
  Col,
  Row,
  Nav,
  NavLink,
  NavItem,
  TabPane,
} from "reactstrap";
import moment from "moment";
import classnames from "classnames";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { Grid, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import StarIcon from "mdi-react/StarCircleIcon";
import IconButton from "@material-ui/core/IconButton";
import OpenIcon from "mdi-react/FileDocumentOutlineIcon";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";

import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _getCrossStreet,
  _isResidential,
  _isHouse,
  _formatNum,
  _getServiceLevel,
  _validateLink,
  _showFeatures,
  _ordinalSuffix,
  _titleCase,
  _formatDate,
  _formatOwnerName,
  _getMPNDBuildingPopupURL,
  _fixGoogleImage,
  _mapGOATData,
  _mapPLUTOData,
  _formatLatLong,
  _getBuildingURLBase,
} from "../../../shared/helpers/utils";

import { _parseCoordinates } from "../../../shared/helpers/mapUtil";
import ProfileGallery from "./../../SingleProject/components/Profile/components/ProfileGallery";
import MapModal from "../../../shared/components/map/MapModal";
import SalesMapBox from "../../../shared/components/map/SalesMapBox";
import GStreetMap from "../../../shared/components/map/GStreetMap.jsx";
import Permits from "./../../SingleProject/components/Permits";
import Feed from "./../../SingleProject/components/Feed";
import LendingHistory from "./../../SingleProject/components/Profile/components/LendingHistory";
import BuildingMapBox from "../../../shared/components/map/BuildingMapBox";

import MatGridView from "./../../PipelineBuildings/components/MatGridView";

import Transfer from "./Transfer";
import BuildingProfile from "./BuildingProfile";
import BuildingOverview from "./BuildingOverview";
import Construction from "./Construction";
import { _hasPermission } from "../../../shared/helpers/access";
import {
  _getGeocodesForMapBox,
  _getGeocoding,
  _axiosCall,
} from "../../../shared/helpers/apicalls";
import { useEffect } from "react";
import Violations from "./Violations";
import UnitsSearchTable from "../../Sales/components/UnitsSearchTable";
import ShareButton from "../../../shared/components/buttons/ShareButton";
import AddCollectionButton from "../../../shared/components/collections/AddCollectionButton";
import ProductCard from "../../SingleProject/components/Profile/components/ProfileCard";
import BuildingDetailsCard from "./BuildingDetailsCard";

import EnterpriseGate from "../../../shared/components/payment/EnterpriseGate";

const buildingUrl = process.env.API_ROOT + "/pipeline/";
const queryUrl = process.env.API_ROOT + "/query/";

const defaultTabs = [
  { key: "overview", name: "Overview" },
  { key: "news", name: "News" },
  { key: "permits", name: "Permits" },
  { key: "voilation", name: "Violations & Complaints" },
  { key: "transfers", name: "Sales & Transfers" },
  { key: "financing", name: "Financing" },
  { key: "property", name: "Details" },
];

const SeeProject = ({ item, display }) => {
  if (!(item && item.pipeline_project)) return null;
  const proj = item.pipeline_project;
  var stage =
    proj.project_stage && proj.project_stage.pipeline_project_stage
      ? proj.project_stage.pipeline_project_stage
      : proj.pipeline_project_stage;

  const stageMap = {
    completion: "Operational",
    construction: "Under construction",
    planning: "Pre-development",
  };

  const _seeProject = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.pipeline_project && item.pipeline_project["_id"]) {
      const buildingURL = _getBuildingURL(item.pipeline_project, "_id");

      window.location = buildingURL;
      return false;
    }
  };
  return (
    <Card>
      <CardBody className="proj-link">
        <p>
          <StarIcon color={"#FFD23F"} style={{ marginTop: -2 }} />{" "}
          {stageMap[stage]} pipline project at {display}.
        </p>
        <Tooltip title="See Project">
          <Button
            onClick={(e) => _seeProject(e, item)}
            variant="contained"
            color="primary"
            className="buyersList__primary-button"
            endIcon={<OpenInNewIcon />}
          >
            {" "}
            See This Project
          </Button>
        </Tooltip>
      </CardBody>
    </Card>
  );
};

const BuildingContainer = (props) => {
  const {
    trxnRes,
    buildingRes,
    user,
    mainTab,
    eventData,
    removeCss,
    isShare,
    hideAddCollectionBtn,
    defaultTab
  } = props;

  const tabs = defaultTabs.slice();
  if (mainTab) tabs.unshift(mainTab);
  const [activeTab, setActiveTab] = useState(
    props.match && props.match.params.tab ? props.match.params.tab : defaultTab ? defaultTab : tabs[0].key
  );
  const [streetView, setStreetView] = useState(true);
  const [standardView, setStandardView] = useState(false);
  const [mapPos, setMapPos] = useState(false);
  const [reactTableFiltered, setreactTableFiltered] = useState(false);
  const buildingDetails = buildingRes;
  const boro = _shouldShow(buildingDetails.borough)
    ? Array.isArray(buildingDetails.borough)
      ? buildingDetails.borough[0].toLowerCase().trim()
      : buildingDetails.borough.toLowerCase().trim()
    : "new york";
  const hood = _shouldShow(buildingDetails.neighborhood)
    ? Array.isArray(buildingDetails.neighborhood)
      ? buildingDetails.neighborhood[0].toLowerCase().trim()
      : buildingDetails.neighborhood.toLowerCase().trim()
    : "nyc";

  const handleBuildingClick = (event, building) => {
    const buildingURL = _getBuildingURL(building, "id");

    window.open(buildingURL);
  };

  const _getGOAT = async (buildingRes) => {
    var address = "";

    if (buildingRes.address) {
      address =
        (buildingRes.display_full_street_address ? buildingRes.display_full_street_address.toLowerCase() : buildingRes.full_street_address.toLowerCase()) +
        ", " +
        (buildingRes.address.borough
          ? buildingRes.address.borough.toLowerCase()
          : "new york");
    } else {
      var street_address =
        buildingRes && buildingRes.display_full_street_address
          ? buildingRes.display_full_street_address.toLowerCase()
          : buildingRes.full_street_address
          ? buildingRes.full_street_address.toLowerCase()
          : "";
      address =
        street_address +
        ", " +
        (buildingRes.borough ? buildingRes.borough.toLowerCase() : "new york");
    }
    var query = encodeURIComponent(address);
    var goat_url = queryUrl + "goat?input=" + query;

    if (!buildingRes.polygon_json || buildingRes.polygon_json == "") {
      goat_url += "&pluto=true";
    }

    // console.log(goat_url);
    var goatCallData = {
      url: goat_url,
      method: "get",
    };

    let formattedData = await _axiosCall(goatCallData);

    // console.log(formattedData)
    return formattedData;
  };

  const nearByBuildings = () => {
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      {
        $or: [
          { pipeline_occupancy_classification_proposed_code: { $nin: ["u"] } },
          { pipeline_total_construction_floor_area: { $gt: 1000 } },
        ],
      },
    ];
    let locQuery = {};
    // geocode

    if (buildingRes && buildingRes.geocode) {
      const coords = _parseCoordinates(buildingRes.geocode);
      locQuery = {
        "address.geocode": {
          $near: {
            $geometry: {
              type: "Point",
              coordinates: [coords[0], coords[1]],
            },
            $maxDistance: 800,
          },
        },
      };
    }
    // goat_geocode
    else if (
      buildingRes.address &&
      buildingRes.address.goat_geocode &&
      buildingRes.address.goat_geocode.lng
    ) {
      locQuery = {
        "address.goat_geocode": {
          $near: {
            $geometry: {
              type: "Point",
              coordinates: [
                buildingRes.address.goat_geocode.lng,
                buildingRes.address.goat_geocode.lat,
              ],
            },
          },
        },
      };
    }
    // neighborhod
    else if (buildingRes.address && buildingRes.address.neighborhood) {
      locQuery = {
        "address.neighborhood": { $in: buildingRes.address.neighborhood },
      };
    }
    //  zipcode
    else if (buildingRes.address && buildingRes.address.zip) {
      locQuery = {
        "address.zip": buildingRes.address.zip,
      };
    }
    searchFilters.push(locQuery);
    let relatedProjectQuery = {
      query: { $and: searchFilters },
      limit: 8,
    };

    let axiosData = {
      url: buildingUrl + "query",
      method: "post",
      query: relatedProjectQuery,
    };

    _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs) {
          let newData = _fixGoogleImage(res);
          setreactTableFiltered(newData.docs);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  useEffect(() => {
    const buildingAdr = buildingRes.display_full_street_address
      ? buildingRes.display_full_street_address
      : buildingRes.full_street_address;
    // const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${buildingAdr}, ${
    //   buildingRes.borough ? buildingRes.borough : "new york"
    // }, ${buildingRes.zip}.json`;
    _getGOAT(buildingRes).then((res) => {
      console.log(res);

      if (res && res.results && res.results[0]) {
        let mainBuildingData = Object.assign({}, buildingRes);
        mainBuildingData["goatData"] = res.results[0].response;
        mainBuildingData = _mapGOATData(
          mainBuildingData,
          mainBuildingData["goatData"]
        );
        var mapData = {
          lat: mainBuildingData["goatData"].latitude
            ? mainBuildingData["goatData"].latitude
            : false,
          long: mainBuildingData["goatData"].longitude
            ? mainBuildingData["goatData"].longitude
            : false,
        };

        if (mapData.lat == false && mainBuildingData.geocode) {
          mapData = _formatLatLong(mainBuildingData.geocode);
        }
        console.log(mapData);
        if(window.google){
          var astorPlace = new window.google.maps.LatLng(
            mapData.lat,
            mapData.long
          );
          var webService = new window.google.maps.StreetViewService();
          let geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: "100 jay street, brooklyn, ny" },
            function (results, status) {
              if (status == "OK") {
                console.log(results[0].geometry.location);
                var checkaround = 50;
                webService.getPanoramaByLocation(
                  results[0].geometry.location,
                  checkaround,
                  checkNearestStreetView
                );
              } else {
                alert(
                  "Geocode was not successful for the following reason: " + status
                );
              }
            }
          );
          
        }
        
        /**Check in a perimeter of 50 meters**/

        function checkNearestStreetView(panoData) {
          if (panoData) {
            if (panoData.location) {
              if (panoData.location.latLng) {
                const latt = panoData.location.latLng.lat();
                const longg = panoData.location.latLng.lng();
                setMapPos([longg, latt]);
              }
            }
          }
        }

        if (res.pluto) {
          mainBuildingData["plutoData"] = res.pluto;
          mainBuildingData = _mapPLUTOData(
            mainBuildingData,
            mainBuildingData["plutoData"]
          );
        }
      }
    });

    nearByBuildings();

    // _getGeocodesForMapBox({
    //   callback: function (res) {
    //     if (
    //       res.features &&
    //       res.features[0] &&
    //       res.features[0].geometry &&
    //       res.features[0].geometry.coordinates
    //     ) {
    //       // debugger
    //       const resp = res.features[0].geometry.coordinates;
    //       console.log("map coordinates.....", resp);
    //       setMapPos(resp);
    //     }
    //   },
    //   url: url,
    // });
  }, []);

  let images = _getBuildingPhotos(buildingDetails);

  if (images && images.length > 0) {
    images = images.map((image) => {
      return { src: image };
    });
  }

  const buildingNameOrAdr = buildingDetails.name
    ? _capitalizeText(buildingDetails.name)
    : buildingDetails.display_full_street_address
    ? _capitalizeText(buildingDetails.display_full_street_address)
    : _capitalizeText(buildingDetails.full_street_address);

  const adr = buildingDetails.display_full_street_address
    ? buildingDetails.display_full_street_address
    : buildingDetails.full_street_address;

  if (buildingDetails) buildingDetails["address"] = buildingDetails;

  const _handleStreetView = (event) => {
    event.preventDefault();
    setStreetView(!streetView);
  };

  const toggleView = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setStandardView(!standardView);
  };

  const _selectedBuildingClick = (building) => {
    let buildingURL;
    if (building && building._id) {
      buildingURL = _getMPNDBuildingPopupURL(building, "key");
    } else {
      buildingURL = _getMPNDBuildingPopupURL(building, "key");
    }

    window.open(buildingURL);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className={`${removeCss ? "" : "no-border no-padding"}`}>
          <Grid container className="card__title">
            <Grid item xs={12} md={12} sm={12} lg={6}>
              <h3 className="page-title">{buildingNameOrAdr}</h3>
              <h3 className="page-subhead subhead subHead_style">
                {hood && _capitalizeText(hood)},{" "}
                {_capitalizeText(buildingDetails.borough)}
              </h3>
            </Grid>
            {!isShare && (
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                lg={7}
                className="top__toolbar project_page_buttons project_title_buttons"
              >
                <div className="top__toolbar project_page_buttons project_title_buttons">
                  <Tooltip title="See building profile on Marketproof">
                    <IconButton
                      className="material-table__toolbar-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `https://nyc.marketproof.com/linkto?building=${buildingRes["display_full_street_address"]}, ${buildingRes["address"]["borough"]}`
                        );
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>

                  {_hasPermission(user, "admin-tools") && (
                    <Fragment>
                      {buildingRes.offering_plan_documents &&
                        buildingRes.offering_plan_documents[0] && (
                          <Tooltip title="Download Offering Plan (Admin Only)">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                window.location.href =
                                  buildingRes.offering_plan_documents[0];
                              }}
                              className="material-table__toolbar-button"
                            >
                              <DocIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Fragment>
                  )}

                  {_hasPermission(user, "ag-link") &&
                    buildingRes.offering_file_number &&
                    buildingRes.offering_file_number !== "" && (
                      <Tooltip title="See Offering Plan on AG Website">
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(
                              `https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${buildingRes.offering_file_number}`
                            );
                          }}
                          className="material-table__toolbar-button"
                        >
                          <OpenIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  <ShareButton
                    buildingRes={buildingRes}
                    shareType={"existing-building"}
                  />
                  {!hideAddCollectionBtn && (
                    <AddCollectionButton property={buildingRes} />
                  )}
                </div>
              </Grid>
            )}
          </Grid>
          <hr />

          <Row>
            <Col md={12} xs={12} lg={12} xl={12}>
              {streetView ? (
                <GStreetMap
                  {...props}
                  center={{
                    lat: mapPos
                      ? mapPos[1]
                      : buildingRes.address &&
                        buildingRes.address.geocode &&
                        _parseCoordinates(buildingRes.address.geocode)[1],
                    lng: mapPos
                      ? mapPos[0]
                      : buildingRes.address &&
                        buildingRes.address.geocode &&
                        _parseCoordinates(buildingRes.address.geocode)[0],
                  }}
                />
              ) : (
                <SalesMapBox
                  {...props}
                  mapContainerWidth={1000}
                  fullSize={true}
                  freez
                  standardView={standardView}
                  buildingClicked={_selectedBuildingClick}
                  handleBuildingClick={handleBuildingClick}
                  isSharePage={props.isSharePage}
                />
              )}
              <ProfileGallery building={buildingRes} images={images} />
              <MapModal
                {...props}
                toggleView={toggleView}
                handleStreetView={_handleStreetView} // for toggle street view
                standardView={standardView}
                streetView={streetView}
                section
                showMapViewButton
                showStreetView
              />
            </Col>
          </Row>

          <div className="tabs tabs--bordered-bottom mt-5">
            <div className="tabs__wrap">
              <Nav tabs>
                {tabs.map((t) => {
                  return (
                    <NavItem key={t.key}>
                      <NavLink
                        className={classnames({ active: activeTab === t.key })}
                        onClick={() => {
                          setActiveTab(t.key);
                          const buildingURL =
                         '/building/' + _getBuildingURLBase(buildingRes, "building_slugs") + (t.key ? `/tab/${t.key}` : "");
                          if(!isShare) {
                            props.history.push(buildingURL);
                          }
                        }}
                      >
                        <span className="tab-title">{t.name}</span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </div>
          </div>
          <div className="">
            {activeTab === "overview" && (
              <TabPane tabId="dashboard">
                <Fragment>
                  <SeeProject
                    item={eventData}
                    display={
                      buildingNameOrAdr +
                      ", " +
                      _capitalizeText(hood) +
                      ", " +
                      _capitalizeText(boro)
                    }
                  />

                  <BuildingDetailsCard
                    buildingRes={buildingDetails}
                    hood={hood}
                    boro={boro}
                  />

                  {/* <BuildingOverview
                    buildingDetails={buildingDetails}
                    hood={hood}
                    boro={boro}
                  /> */}

                  <Feed
                    buildingRes={buildingRes}
                    min={isShare ? false : true}
                    title={"Latest News"}
                    toggle={() => {
                      setActiveTab("news");
                    }}
                  />
                  {reactTableFiltered && (
                    <Fragment>
                      <Row>
                        <Col md={12}>
                          <h3 className="section-title section-title--not-last project_page-heading">
                            Nearby Projects
                          </h3>
                          <h3 className="section-title section-title--not-last subhead">
                            Projects near{" "}
                            {(buildingRes && buildingRes.name) ||
                              (buildingRes.address &&
                                buildingRes.address.street_number) +
                                " " +
                                (buildingRes.address &&
                                  buildingRes.address.street)}
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <MatGridView
                          {...props}
                          reactTableFiltered={
                            reactTableFiltered ? reactTableFiltered : []
                          }
                          handleBuildingClick={null}
                          noPagination
                        />
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              </TabPane>
            )}
            {activeTab === "transfer" && (
              <TabPane tabId="transfer">
                <div>
                  <Col xl={12} lg={12} className="mb-0">
                    <h3 className="section-title section-title--not-last">
                      Transfer Details
                    </h3>
                    <h3 className="section-title section-title--not-last subhead">
                      Information about the transfer on{" "}
                      {trxnRes && trxnRes.close_date_formatted
                        ? moment(trxnRes.close_date_formatted).format(
                            "MM/DD/YYYY"
                          )
                        : ""}{" "}
                    </h3>

                    <Transfer
                      trxnRes={trxnRes}
                      buildingDetails={buildingDetails}
                      hood={hood}
                      boro={boro}
                    />

                    <Feed
                      buildingRes={buildingRes}
                      min
                      title={"Latest News"}
                      toggle={() => {
                        setActiveTab("news");
                      }}
                    />
                  </Col>
                </div>
              </TabPane>
            )}
            {activeTab === "construction" && (
              <TabPane tabId="construction">
                <Fragment>
                  <SeeProject
                    item={eventData}
                    display={
                      buildingNameOrAdr +
                      ", " +
                      _capitalizeText(hood) +
                      ", " +
                      _capitalizeText(boro)
                    }
                  />
                  <h3 className="section-title section-title--not-last">
                    Construction Details
                  </h3>
                  <h3 className="section-title section-title--not-last subhead">
                    Information about the construction event on{" "}
                    {eventData && eventData.event_time
                      ? moment(eventData.event_time).format("MM/DD/YYYY")
                      : ""}{" "}
                  </h3>
                  <Construction {...props} />

                  <Feed
                    buildingRes={buildingRes}
                    min
                    title={"Latest News"}
                    toggle={() => {
                      setActiveTab("news");
                    }}
                  />
                </Fragment>
              </TabPane>
            )}
            {activeTab === "property" && (
              <TabPane tabId="property">
                <Fragment>
                  <SeeProject
                    item={eventData}
                    display={
                      buildingNameOrAdr +
                      ", " +
                      _capitalizeText(hood) +
                      ", " +
                      _capitalizeText(boro)
                    }
                  />
                  <BuildingProfile
                    buildingDetails={buildingDetails}
                    hood={hood}
                    boro={boro}
                    {...props}
                  />
                </Fragment>
              </TabPane>
            )}
            {activeTab === "news" && (
              <TabPane tabId="news">
                <Fragment>
                  <Feed
                    buildingRes={buildingRes}
                    {...props}
                    title={"Property News"}
                  />
                </Fragment>
              </TabPane>
            )}
            {activeTab === "permits" && (
              <TabPane tabId="permits">
                <Fragment>
                  <Permits buildingRes={buildingRes} {...props} />
                </Fragment>
              </TabPane>
            )}
            {activeTab === "financing" && (
              <TabPane tabId="financing">
                {(isShare || _hasPermission(user, "finance-tab")) ? (
                  <Fragment>
                    <LendingHistory
                      buildingRes={buildingRes}
                      user={user}
                      {...props}
                    />
                  </Fragment>
                ) : (
                  <EnterpriseGate
                    copy={
                      <span>
                        Access to Project Financing requires an upgrade to your
                        subscription.
                      </span>
                    }
                  />
                )}
              </TabPane>
            )}
            {activeTab === "voilation" && (
              <TabPane tabId="voilation">
                {(isShare || _hasPermission(user, "violations-tab")) ? (
                  <Fragment>
                    <Violations
                      buildingRes={buildingRes}
                      user={user}
                      {...props}
                    />
                  </Fragment>
                ) : (
                  <EnterpriseGate
                    copy={
                      <span>
                        Access to Project Violations requires an upgrade to your
                        subscription.
                      </span>
                    }
                  />
                )}
              </TabPane>
            )}
            {activeTab === "transfers" && (
              <TabPane tabId="transfers" className="transfer__container">
                {(isShare || _hasPermission(user, "transfers")) ? (
                  <UnitsSearchTable
                    buildingView
                    building={buildingRes}
                    hideButtons
                    {...props}
                  />
                ) : (
                  <EnterpriseGate
                    copy={
                      <span>
                        Access to Project Transfers requires an upgrade to your
                        subscription.
                      </span>
                    }
                  />
                )}
              </TabPane>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings)
      ? state.buyersLists.viewedBuildings.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuildingContainer));
