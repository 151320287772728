import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/PencilIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Form from "@rjsf/material-ui";
import LayoutField from './LayoutField';

import renderAddressSearch from './fields/AddressSearch';
import renderNeighborhoodSearch from './fields/NeighborhoodSearch';


import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber
} from '../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  let legend = legend = `${title}${(required ? '*' : '')}`
  return <label className="control-label" for={id}>{legend}</label>
}


const schema = {
  title: "Project Profile",
  type: "object",
  properties: {
    "name": {
      'type': 'string',
      "title" : "Project Name",
      "description" : "Name of the project. Ex: New NYC Tower Development.",
    },
    "display_full_street_address": {
      'type': 'string',
      "title" : "Project Location",
      "description" : "Main address of this project. Ex: 123 5th Avenue.",
    },
    "address_search": {
      'type': 'string',
      "title" : "Project Location",
      "description" : "Main address of this project. Ex: 123 5th Avenue."
    },
     "neighborhood": {
      'type': 'string',
      "title" : "Neighborhood",
      "description" : "Neighborhood of the project"
    },
    "borough": {
      'type': 'string',
      "title" : "Borough",
      "description" : "Borough of this project. Ex: Manhattan.",
      "enum": ["manhattan", "brooklyn", "queens", "bronx", "staten island"],
	  "enumNames": ["Manhattan", "Brooklyn", "Queens", "Bronx", "Staten Island"],
    },
    "zip": {
      'type': 'string',
      "title" : "Zip Code",
      "description" : "Zipcode of this project. Ex: 10001."
    },
    "website": {
      'type': 'string',
      "title" : "Project Website",
      "description" : "Website of the project. Ex: https://megadevelpment.com",
    },	
/*
    "address": {
      'type': 'object',
      "title" : "Address Search",
      "description" : "Main address of this project. Ex: 123 5th Avenue.",
      "properties" : {
		    "neighborhood": {
		      'type': 'string',
		      "title" : "Neighborhood",
		      "description" : "Neighborhood of the project"
		    },

		    "borough": {
		      'type': 'string',
		      "title" : "Borough",
		      "description" : "Borough of this project. Ex: Manhattan.",
		      "enum": ["manhattan", "brooklyn", "queens", "bronx", "staten island"],
			  "enumNames": ["Manhattan", "Brooklyn", "Queens", "Bronx", "Staten Island"],
		    }
	    }	
     },
*/

     "pipeline_building_stories_proposed": {
      "type": "number",
      "title": "Number of Floors",
    },
    "pipeline_height_proposed" : {
	  "type": "number",
      "title": "Building Height (Ft)",
    },
    "pipeline_project_start": {
      "type": "number",
      "title": "Project Start Year",
    },
    "pipeline_project_end": {
      "type": "number",
      "title": "Expected Completion Year",
    },
    "pipeline_total_units_proposed": {
      "type": "number",
      "title": "Total Units",
    },
    "pipeline_dwelling_units_proposed": {
      "type": "number",
      "title": "Residential Units",
    },
    "pipeline_retail_units_proposed": {
      "type": "number",
      "title": "Retail Units",
    },
    "pipeline_office_units_proposed": {
      "type": "number",
      "title": "Office Units",
    },
     "pipeline_hotel_units_proposed": {
      "type": "number",
      "title": "Hotel Units",
    },
    "pipeline_rental_units_proposed": {
      "type": "number",
      "title": "Rental Units",
    },
    "pipeline_condo_units_proposed": {
      "type": "number",
      "title": "Sellable Units",
    },
    "pipeline_affordable_rental_units_proposed": {
      "type": "number",
      "title": "Affordable Rental Units",
    },
    "pipeline_affordable_condo_units_proposed": {
      "type": "number",
      "title": "Affordable Sellable Units",
    },
    "pipeline_storage_units_proposed": {
      "type": "number",
      "title": "Storage Units",
    },
    "pipeline_parking_units_proposed": {
      "type": "number",
      "title": "Parking Units",
    },
    "pipeline_total_construction_floor_area": {
      "type": "number",
      "title": "Total Construction Area",
    },
    "pipeline_zoning_area_residential": {
      "type": "number",
      "title": "Residential Area",
    },
    "pipeline_zoning_area_retail": {
      "type": "number",
      "title": "Retail Area",
    }, 
    "pipeline_zoning_area_commercial": {
      "type": "number",
      "title": "Commercial Area",
    },  
    "pipeline_zoning_area_community": {
      "type": "number",
      "title": "Community Area",
    },
    "pipeline_zoning_area_garage": {
      "type": "number",
      "title": "Garage Area",
    },  
    "pipeline_project_stage": {
      "type": "string",
      "title": "Project Stage",
      "enum": ["planning",  "construction", "completion"],
	  "enumNames": ["Planning", "Construction", "Completion"],
    },
    "pipeline_financing_status": {
      "type": "string",
      "title": "Financing Status",
      "enum": ["not-secured", "partially-secured", "fully-secured"],
	  "enumNames": ["Not Secured", "Partially Secured", "Fully Secured"],
    },
    "pipeline_project_stalled": {
      "type": "boolean",
      "title": "Project Stalled",
      "description": "Has this project stalled for any reason?"
    },
    "pipeline_project_abandoned": {
      "type": "boolean",
      "title": "Project Abandoned",
      "description": "Has this project been abandoned?"
    },
    "pipeline_project_description": {
      "type": "string",
      "title": "Project Description",
    },
    "occupancy_type": {
      "title": "",
      "$ref": "#/definitions/pipeline_occupancy_type"
    },
    "project_stage": {
      "title": "",
      "$ref": "#/definitions/pipeline_project_stage"
    },
    "construction_type": {
      "title": "",
      "$ref": "#/definitions/pipeline_construction_type"
    },
  },
  "definitions": {
    "pipeline_project_stage": {
      "title": "Project Stage",
      "type": "object",
      "properties": {
        "pipeline_project_stage": {
          "type": "string",
          "title": "Project Stage",
	       "enum": ["planning",  "construction", "completion"],
		   "enumNames": ["Pre-development", "Construction", "Operation"],
        }
      },
      "required": [
        "pipeline_project_stage"
      ],
      "dependencies": {
        "pipeline_project_stage": {
          "oneOf": [
            {
              "properties": {
                "pipeline_project_stage": {
                  "enum": [
                    "construction"
                  ]
                },
                "Construction Progress": {
                  "type": "string",
                  "enum": [
                    "Site Preparation",
                    "Structural Work",
                    "Topped Out"
                   ]
                }
              },
              "required": [
                "Construction Progress"
              ]
            }
          ]
        }
      }
    },
    "pipeline_occupancy_type": {
      "title": "Project Type",
      "type": "object",
      "properties": {
        "pipeline_occupancy_type": {
          "type": "string",
          "title": "Project Type",
	       "enum": ["residential", "commercial", "institutional", "mixed-use"],
		     "enumNames": ["Residential", "Commercial", "Institutional", "Mixed-Use"],
        }
      },
      "required": [
        "pipeline_occupancy_type"
      ],
      "dependencies": {
        "pipeline_occupancy_type": {
          "oneOf": [
            {
              "properties": {
                "pipeline_occupancy_type": {
                  "enum": [
                    "residential"
                  ]
                },
                "Project Components": {
                  "type": "array",
                  "items" : {
	                  "type" : "string",
	                  "enum": [
                      "Apartments",
	                    "Condo",
	                    "Co-op",
	                    "Condop",
	                    "Rental",
	                    "Single Family",
	                    "Multi-Family",
	                    "Affordable",
	                    "Retail",
	                    "Office",
	                    "Hotel",
	                    "Industrial",
	                    "Medical",
	                    "Educational",
	                    "Civic",
	                    "Religious",
	                    "Government",
	                    "Military",
	                    "Transportation",
	                    "Utility",
                                 
	                  ]
	               },
	               "uniqueItems": true
                }
              },
            },
            {
              "properties": {
                "pipeline_occupancy_type": {
                  "enum": [
                    "commercial"
                  ]
                },
                "Project Components": {
                  "type": "array",
                  "items" : {
	                  "type" : "string",
	                  "enum": [		                
	                    "Retail",
	                    "Office",
	                    "Hotel",
	                    "Industrial",
	                    "Medical",
	                    "Educational",
	                    "Civic",
	                    "Religious",
	                    "Government",
	                    "Military",
	                    "Transportation",
	                    "Utility",
	                    "Condo",
	                    "Co-op",
	                    "Condop",
	                    "Rental",
	                    "Single Family",
	                    "Multi-Family",
	                    "Affordable"                                    
	                  ]
                  },
                  "uniqueItems": true
                  
                }
              }
            },
            {
              "properties": {
                "pipeline_occupancy_type": {
                  "enum": [
                    "institutional"
                  ]
                },
                "Project Components": {
                  "type": "array",
                  "items" : {
	                  "type" : "string",
	                  "enum": [
		                "Industrial",
	                    "Medical",
	                    "Educational",
	                    "Civic",
	                    "Religious",
	                    "Government",
	                    "Military",
	                    "Transportation",
                      "Apartments",
		                "Condo",
	                    "Co-op",
	                    "Condop",
	                    "Rental",
	                    "Multi-Family",
	                    "Affordable",
	                    "Hotel",
	                    "Retail",
	                    "Office",	                   
	                    "Utility"	                                    
	                  ]
                  },
                  "uniqueItems": true
                  
                }
              }
            },
            {
              "properties": {
                "pipeline_occupancy_type": {
                  "enum": [
                    "mixed-use"
                  ]
                },
                "Project Components": {
                  "type": "array",
                  "items" : {
	                  "type" : "string",
	                  "enum": [
                      "Apartments",
		                "Condo",
	                    "Co-op",
	                    "Condop",
	                    "Rental",
	                    "Multi-Family",
	                    "Affordable",
	                    "Hotel",
	                    "Retail",
	                    "Office",
	                    "Industrial",
	                    "Medical",
	                    "Educational",
	                    "Civic",
	                    "Religious",
	                    "Government",
	                    "Military",
	                    "Transportation",
	                    "Utility"	                                    
	                  ]
                  },
                  "uniqueItems": true
                  
                }
              }
            }
          ]
        }
      }
    },
    "pipeline_construction_type": {
      "title": "Construction Type",
      "type": "object",
      "properties": {
        "pipeline_construction_type": {
          "type": "string",
          "title": "Construction Type",
         "enum": ["new-construction",  "alteration"],
       "enumNames": ["New Construction", "Alteration"],
        }
      }
    }
  }
}

const fields = {
  layout_grid: LayoutField,
  TitleField: TitleField
}


const isFilled = (fieldName) => ({ formData }) => (formData[fieldName] && formData[fieldName].length) ? true : false
const isTrue = (fieldName) => ({ formData }) => (formData[fieldName])



const widgets = {
  AddressSearch: renderAddressSearch,
  NeighborhoodSearch: renderNeighborhoodSearch
}

const uiSchema = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
    { 'ui:col': { md: 12, children: [
	    
	  { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <h5 className="section_title">Overview</h5>
              </div>
            )
          } }
        ] } },
      ] },
	  { 'ui:row': [
        { 'ui:col': { md: 12, children: ['name'] } },        
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 12, children: ['address_search'] } }, 
      ] },
      { 'ui:row': [
        { 'ui:col': { md: 5, children: ['neighborhood'] } }, 
        { 'ui:col': { md: 4, children: ['borough'] } }, 
        { 'ui:col': { md: 3, children: ['zip'] } }, 
      ] },
       { 'ui:row': [
        { 'ui:col': { md: 12, children: ['website'] } },        
      ] },
/*

      { 'ui:row': [
        { 'ui:col': { md: 8, children: ['address'] } }, 
      ] },
*/
      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <h5 className="section_title">Characterstics</h5>
              </div>
            )
          } }
        ] } },
      ] },
      { 'ui:row': [
	      	{ 'ui:col': { md: 12, children: ['occupancy_type'] } }
	      ]
	  },
    { 'ui:row': [
          { 'ui:col': { md: 12, children: ['construction_type'] } }
        ]
    },
      { 'ui:row': [
        { 'ui:col': { md: 4, children: ['pipeline_building_stories_proposed', 'pipeline_total_units_proposed', 'pipeline_dwelling_units_proposed', 'pipeline_retail_units_proposed', 'pipeline_office_units_proposed', 'pipeline_hotel_units_proposed' ] } },
        { 'ui:col': { md: 4, children: [  'pipeline_rental_units_proposed', 'pipeline_condo_units_proposed', 'pipeline_affordable_rental_units_proposed', 'pipeline_affordable_condo_units_proposed', 'pipeline_storage_units_proposed', 'pipeline_parking_units_proposed'] } },
        { 'ui:col': { md: 4, children: ['pipeline_height_proposed', 'pipeline_total_construction_floor_area', 'pipeline_zoning_area_residential', 'pipeline_zoning_area_retail', 'pipeline_zoning_area_commercial', 'pipeline_zoning_area_community'] } },
      ] },

       

      { 'ui:row': [
        { 'ui:col': { md: 12, children: [
          { render: (props) => {
            const { formData, errorSchema } = props

            return (
              <div>
                <h5 className="section_title">Progress</h5>
              </div>
            )
          } }
        ] } },
      ] },
       { 'ui:row': [
	      	{ 'ui:col': { md: 12, children: ['project_stage'] } },
	      	{ 'ui:col': { md: 4, children: ['pipeline_project_stalled'] } },
          { 'ui:col': { md: 4, children: ['pipeline_project_abandoned'] } },
// 	      	{ 'ui:col': { md: 12, children: ['pipeline_financing_status'] } },
	      	
	      ]
	  },
	  { 'ui:row': [
	      	{ 'ui:col': { md: 4, children: ['pipeline_project_start'] } },
	      	{ 'ui:col': { md: 4, children: ['pipeline_project_end'] } }	      	
	      ]
	  },
     
    ] } },
  ] },
  'pipeline_occupancy_type': {
    'ui:widget': 'radio',
    'ui:options' :{
	    'inline' : true
    }
  },
  'pipeline_project_stage': {
    'ui:widget': 'radio',
    'ui:options' :{
	    'inline' : true
    }
  },
  'pipeline_financing_status': {
    'ui:widget': 'radio',
    'ui:options' :{
	    'inline' : true
    }
  },
  'occupancy_type' : {
	  'pipeline_occupancy_type': {
	    'ui:widget': 'radio',
	    'ui:options' :{
		    'inline' : true
	    }
	  },
	  "Project Components" : {
		  'ui:widget': 'checkboxes',
		  'ui:options' :{
			    'inline' : true
		  }
	  }
  },
  'project_stage': {
	  "pipeline_project_stage" : {
		  'ui:widget': 'radio',
		  'ui:options' :{
			    'inline' : true
		  }
	  },
	  "Construction Progress" : {
		  'ui:widget': 'radio',
		  'ui:options' :{
			    'inline' : true
		  }
	  }
  },
  'pipeline_project_description': {
    'ui:widget': 'textarea'
  },
  'address_search': {
	  'ui:widget': 'AddressSearch'
    
  },
  'neighborhood': {
	  'ui:widget': 'NeighborhoodSearch'
    
  },
  'user': {
    'ui:field': 'layout_grid',
    'ui:layout_grid': { 'ui:row': [
        { 'ui:col': { md: 6, children: ['username'] } },
        { 'ui:col': { md: 6, children: ['password'] } },
    ] },

  },
  'construction_type' : {
    'pipeline_construction_type': {
      'ui:widget': 'radio',
      'ui:options' :{
        'inline' : true
      }
    }
  }
}

class EditProfile extends Component {

  constructor(props) {
    super(props);
    this.doValidateSubmit = this.doValidateSubmit.bind(this);
  }
  
  
  doValidateSubmit = () => {
	  const { formData, onSubmit } = this.props;
	  
	  const errorList = [];
	  if (!formData.name || formData.name == "") {
	    errorList.push("Project name is required");
	  }
	  
	  if (!formData.display_full_street_address || formData.display_full_street_address == "") {
	    errorList.push("Project address is required");
	  }
	  
	  
	  onSubmit(errorList);
	  
  } 
  
  

  render() {
    const {
     formData, onChange, onSubmit, toggle, saveInProgress
    } = this.props;
    
    return (
	    <div className="project_edit profile">
		    <Form
		        formData={formData}
		        schema={schema}
		        uiSchema={uiSchema}
		        widgets={widgets}
		        fields={fields}
		        onChange={onChange}
		        noHtml5Validate={true}>
		        
		        <ButtonToolbar className="modal__footer">
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				         disabled={saveInProgress}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
	            <Tooltip title="Save">
			        <Button
			        	 onClick={this.doValidateSubmit}
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"		
				         disabled={saveInProgress}         
				     >	{saveInProgress ? "Saving..." : "Save" }
				     </Button>				 
			  	</Tooltip>
	         </ButtonToolbar>
	
			</Form>
		</div>
    );
  }
}



export default EditProfile;
