import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { RTLProps, ThemeProps } from '../../../../../shared/prop-types/ReducerProps';
import { getFormValues } from 'redux-form';



import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	EstimateProjectStage,
	_getDefaultBuildingPhoto
} from '../../../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT; //'http://localhost:8000/buyerslist/';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
    width: "100%"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth:180
  },
});


class NewsItemView extends Component {


  constructor(props) {
    super(props);
    this.state = { 
	    modal: false,
	    instagram : false
 
	};
	this.toggle = this.toggle.bind(this);
	    
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

  	if(this.state.modal && this.state.modal !== prevState.modal){
	  	if (window.instgrm || document.getElementById('react-instagram-embed-script')) {
	      if(this.state.instagram == false)
	      {
	        window.instgrm.Embeds.process()
	      }
	    } else {
	
	      // Create script element with Instagram embed JS lib
	      const s = document.createElement('script')
	      s.async = s.defer = true
	      s.src = `//www.instagram.com/embed.js`
	      s.id = 'react-instagram-embed-script'
	      const body = document.body
	      if (body) {
	        body.appendChild(s)
	      }
	
	      // Run Instagram function to show embeds
	      if (window.instgrm && this.state.instagram == false) {
	        window.instgrm.Embeds.process()
	      }
	
	      // Set IG state to true so the process doesn't run again
	      this.setState({
	        'instagram': true
	      });
	    }	
	  	
  	}
	
  	
  }
	
  toggle() {
	//this.snackOpen('Your Message Has Been Sent!', 'success');
	
    this.setState(prevState => ({ modal: !prevState.modal }));
    
  }
   render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, listing, classes, add, del, item
    } = this.props;
    
 
    
    const { modal, snackShow, snackMessage, snackType, formData, value, justDeleted} = this.state;
	const topic = item.topic && item.topic[0];
	const hasPhoto = ((item.main_photo && !topic) || (item.main_photo && topic == "stories"));	    		
    const content = item.content && topic == "stories" ? item.content : item.article ? item.article : null;
    return (
		  	<Fragment>
		  		<span className={"read-article link-text"} onClick={this.toggle}>Read More</span>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`ltr-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header ">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
		
						<div className="modal__body background_white modal_news">
							<h4 className="contact__title">{item.title}</h4>
							<h3 className="section-title section-title--not-last subhead"> 
							 	<span>By: {item.project_update_author ? item.project_update_author : topic == 'stories' && item.company_slugs && item.company_slugs[0] ? _capitalizeText(item.company_slugs[0].replace(/-/g, ' ')) : 'Marketproof Research'}</span>
							 	{item.time && <span> | Date: {moment(item.time).format('MM/DD/YYYY')}</span>}
							 </h3>
							 <hr/>
							 <div>
							 <div className="main-photo"> 
								{item.main_photo || (item.event_tags && item.event_tags.indexOf('recorded-sale')<0) ?    	 	     
									<div className="timeline__image" style={{ backgroundImage: `url(${item.main_photo}), url(${_getDefaultBuildingPhoto(item)})` }} />

								: 
									<div className="timeline__image" style={{ backgroundImage: `url(${_getDefaultBuildingPhoto(item)})` }} />
					     	    }
					 		</div>
						      <div className="feed-content">
						      	<div dangerouslySetInnerHTML={{ __html: content }} />
						      </div>
						      {item.external_url && <a className="timeline__link link-text" href={`${item.external_url}`} target="blank">See More on Website</a>}
						       
						     
						 </div>
						 <hr/>
				           <ButtonToolbar className="modal__footer">
				            
				            <Tooltip title="Send">
						        <Button
						        	 onClick={this.toggle}
							         variant="contained"
							         color="primary"
							         className="buyersList__primary-button"		         
							     >	Close
							     </Button>				 
						  	</Tooltip>
				         </ButtonToolbar>      
				             
						    
				        </div>	
						
		        </Modal>
		    </Fragment>

    );
  }
}


export default (NewsItemView);
