import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import {
  DatePicker,
  KeyboardDatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	_formatPrice,
	buildingStageToolTips,
	_formatNumber,
	_formatDate
} from "../../../shared/helpers/utils";

const useStyles = makeStyles(theme => ({
 
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    maxWidth: 300,
    fontSize: '12px',
  },
  formControlPop: {
    margin: '6px',
    marginTop:"15px",
    minWidth: 180,
    fontSize: '12px',
    '& .MuiTextField-root':{
	    marginBottom:'5px'
    },
    '& .MuiInputLabel-root':{
	    fontSize:'14px',
	    paddingLeft:'10px'
    },
    '& .MuiInput-input': {
		fontSize:'14px',
		paddingLeft:'10px'
	},
	'& .MuiListItem-gutters':{
		paddingLeft:'10px'
	},
	'& .MuiMenuItem-root':{
		paddingTop:'3px',
		paddingBottom:'3px',
		minHeight: 0
	},
	'& .format':{
		fontSize:'12px',
		color:'#A3ADC2',
		paddingLeft:"10px",
		paddingTop:"5px"
	},
	'& .MuiInputLabel-formControl':{
		position:'relative'
	},
	'& label + .MuiInput-formControl':{
	
	}
	
  },
  
  pickerControl:{
	  '& .MuiPickersToolbar-toolbar':{
		  backgroundColor:"#780F9E"
	  }
  },
  
  formLabel: {
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px'
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: '5px 10px 10px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiMenuProps = {
  PaperProps: {
    style: {
      marginTop: 0 
    },
  },
  InputProps:{
	  style:{
		  fontSize:'12px'
	  }
  }
};

export default function DateFilter(props) {
	

  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, filter, _filters, setSubFilters} = props;
  
 
  const [ minDate, setMinDate ] = useState(subFilters[filter.search_key[0]] ? subFilters[filter.search_key[0]] : null);
  const [ maxDate, setMaxDate ] = useState(subFilters[filter.search_key[1]] ? subFilters[filter.search_key[1]] : null);

  const [ minDateFocus, setMinDateFocus ] = useState(true);
  const [ maxDateFocus, setMaxDateFocus ] = useState(false);

  const [ minMaxValues, setMinMaxValues ] = useState([]);
  
  const [open, setOpen] = React.useState(false);
  
  const handleChange = event => {
	  console.log(event.target.name, event.target.value);
	  if(event.target.name === 'min-date'){
		  setMinDate(_formatDate(event.target.value));
	  }else if(event.target.name === 'max_date'){		  
		  if(minDate && !moment(_formatDate(event.target.value)).isBefore(minDate)){
			  setMaxDate(_formatDate(event.target.value));
		  }else{
			  setMaxDate(false)
		  }		  
	  }
  };
  const handleChangeMultiple = (min, max)  => {
	setOpen(false); 
    let dates = {};
    if((max < min || !max) && max !== ""){
	    max = 0;
    }
	else if(max && !min){
		min = 0;
	  }
	dates[filter.search_key[0]] = min;
   	dates[filter.search_key[1]] = max;
    setSubFilters(dates);
  };
  
  const handleFocus = type => {
	  if(type === 'min-date'){
		  setMinDateFocus(true);
		  setMaxDateFocus(false);
	  }else if(type === 'max-date'){
		  setMinDateFocus(false);
		  setMaxDateFocus(true);
	  }
  }
  
  const handleSetMinDate = (min) => {
		  
	  if(min && ((maxDate && !moment(min).isAfter(maxDate)) || (!maxDate && min))){
		  setMinDate(moment(min).format('YYYY-MM-DD'));
	  }else{
		  setMinDate(null)
	  }	
	  
  }
  
  const handleSetMaxDate = (max) => { 
	  if(max && ((minDate && !moment(max).isBefore(minDate)) || (!minDate && max))){
		  setMaxDate(moment(max).format('YYYY-MM-DD'));
	  }else{
		  setMaxDate(null)
	  }	 
		 
  }
  
  const handleClose = () => {
    setOpen(false); 
  };

  const handleOpen = () => {
    setOpen(true);
  };
  
  const setTitle = () => {
	  let title = filter.name;
	  if(minDate || maxDate ){
		  if(minDate && minDate !== "0"){
			  title = moment(minDate).format('M/D/YYYY');
		  }else{
			  title = 'Any'
		  }
		  title+=' - ';
		  
		  if(maxDate && maxDate !== "0"){
			//   title +=moment(maxDate, 'YYYY-MM-DD').format('M/D/YYYY');
			  title +=moment(maxDate).format('M/D/YYYY');
		  }else{
			  title += 'Today'
		  }
		  
	  }
	  return title;
	  
  }
  
  const constructDates = (isMax) => {	  
	 const dates = [
		 {
			 name : "Today",
			 val : moment().format("YYYY-MM-DD")
		 },
		  {
			 name : "1 Week Ago",
			 val : moment().subtract(1, 'weeks').format("YYYY-MM-DD")
		 },
		 {
			 name : "1 Month Ago",
			 val : moment().subtract(1, 'months').format("YYYY-MM-DD")
		 },
		  {
			 name : "2 Months Ago",
			 val : moment().subtract(2, 'months').format("YYYY-MM-DD")
		 },
		 {
			 name : "3 Months Ago",
			 val : moment().subtract(3, 'months').format("YYYY-MM-DD")
		 },
		 {
			 name : "6 Months Ago",
			 val : moment().subtract(6, 'months').format("YYYY-MM-DD")
		 },
		 {
			 name : "1 Year Ago",
			 val : moment().subtract(1, 'years').format("YYYY-MM-DD")
		 },
		 {
			 name : "3 Years Ago",
			 val : moment().subtract(3, 'years').format("YYYY-MM-DD")
		 },
		 {
			 name : "5 Years Ago",
			 val : moment().subtract(5, 'years').format("YYYY-MM-DD")
		 },
		 {
			 name : "10 Years Ago",
			 val : moment().subtract(5, 'years').format("YYYY-MM-DD")
		 },
		 {
			 name : "20 Years Ago",
			 val : moment().subtract(20, 'years').format("YYYY-MM-DD")
		 }
	 ]
	 
	 if(isMax){
		 const index = dates.findIndex(item => item.val === minDate);
		 if(index && index >= 0){
			 return dates.splice(0, index)
		 }
		 
		 
	 }else{
		 const index = dates.findIndex(item => item.val === maxDate);
		 
		 if(index && index >= 0){
			 return dates.splice(index)
		 }
		 
	 }	
	 
	 return dates;  
  }
    
  return (
	  		<FormControl className={classes.formControl}>
		        <InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
		        	{setTitle()}
		        </InputLabel>
		        <Select
		          labelId={`${filter.search_key}-label`}
		          id={`${filter.search_key}`}
		          name={filter.search_key}
				  value={minMaxValues}		          
				  onClose={()=>{handleChangeMultiple(minDate, maxDate)}}
				  open={open}
				  onOpen={handleOpen}
				  //onBlur={(e)=> {e.stopPropagation();e.preventDefault();}}
		          input={<Input id={`${filter.search_key}`} />}
		          renderValue={selected => (
		            <div>			           
		               {selected.join(', ')}			
		            </div>
		          )}
		          multiple
		          MenuProps={MultiMenuProps}
		          className={classes.menuItem} 
		        >	
		        		
					<div></div>
					<MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerControl}>
			        	<FormControl className={classes.formControlPop}>
			        	  <div className="format">Start Date</div>
			        	 {/* <TextField 
			        	    id="min-date" 
			        	  	label="" 
			        	  	onFocus={()=>{handleFocus('min-date')}}
			        	  	name="min-date"
			        	  	value={minDate ? minDate : ''}
			        	  	onChange={handleChange}
			        	  	autoComplete={'off'}
			        	  	type="date"
			        	   /> */}
			        	   <DatePicker 
			        	    autoOk
					        clearable
					        disableFuture
			        	    placeholder="M/D/YYYY"
			        	   	value={minDate ? minDate : null} 
			        	   	onChange={date => handleSetMinDate(date)} 
			        	   	format="M/D/YYYY"
			        	   	className={classes.pickerControl}
			        	   />
			        	   
				            {minDateFocus && constructDates().map((item) => (
				            <MenuItem  key={item.name} value={item.name} className={classes.menuItem} onClick={()=>{handleSetMinDate(item.val);handleFocus('max-date')}}>
				              {item.name}
				            </MenuItem>
				          ))}
				         
				         </FormControl>
				         <FormControl className={classes.formControlPop}>
				          <div className="format">End Date</div>
				          {/*<TextField 
				          	id="max_date" 
				          	label=""
				          	name="max_date" 
				          	onFocus={()=>{handleFocus('max-date')}}
				          	value={maxDate ? maxDate : ''}
				          	onChange={handleChange}
				          	autoComplete={'off'}
				          	type="date"
				          />*/}
				            <DatePicker 
				        	    autoOk
						        clearable
						        disableFuture
				        	    placeholder="M/D/YYYY"
				        	   	value={maxDate ? maxDate : null} 
				        	   	onChange={date => handleSetMaxDate(date)} 
				        	   	format="M/D/YYYY"
				        	/>
						   {maxDateFocus && constructDates(true).map((item) => (
				            <MenuItem  key={item.name} value={item.name} className={classes.menuItem} onClick={()=>{handleSetMaxDate(item.val);handleChangeMultiple(minDate, item.val)}}>
				              {item.name}
				            </MenuItem>
				          ))}
				         </FormControl>
			         </MuiPickersUtilsProvider>
			        {/* <hr />
			         <h4>Common Date Ranges</h4>
			         {constructDates().map((item) => (
			            <MenuItem  key={item.name} value={item.name} className={classes.menuItem} onClick={()=>{handleSetMinDate(item.minDate);handleSetMaxDate(item.maxDate);}}>
			              {item.name}
			            </MenuItem>
			          ))}*/}
			         <hr />
			         <div style={{padding:'0px 16px 10px',textAlign:'right'}}>	
			         	<Tooltip title={`Reset date filter`}>
					        <Button
						         onClick={()=>{setMinDate("");setMaxDate("");}}
						         size="small"
						         style={{marginRight:5}}
						     >	Reset
						     </Button>				 
					  	</Tooltip>		         
				         <Tooltip title={`Apply date filter`}>
					        <Button
						         onClick={()=>{handleChangeMultiple(minDate, maxDate)}}
						         variant="outlined"
						         size="small"
						     >	Apply
						     </Button>				 
					  	</Tooltip>
				  	</div>
		        </Select>
		        
		    </FormControl>	
	    )	
  
}
