import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";

import UnitsSearchTableHead from "./UnitsSearchTableHead";
import MatTableHead from "./MatTableHead";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

import LinearLoading from "../../../shared/components/LinearLoading";
import Button from "@material-ui/core/Button";
import LoadMoreIcon from "mdi-react/RefreshIcon";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";

import AlertIcon from "mdi-react/InformationOutlineIcon";
import UnitIcons from "../../../shared/components/UnitIcons";
import BuildingIcons from "../../../shared/components/BuildingIcons";
import IconButton from "@material-ui/core/IconButton";
import OpenIcon from "mdi-react/FileDocumentOutlineIcon";
import AddCollectionButton from "../../../shared/components/collections/AddCollectionButton";

import MyReports from "../../MyReports";

import { isMobile } from "react-device-detect";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _objectEqual,
  StatusBadge,
  _getUnitFloorPlan,
  UnitCurrentStatus,
  _formatUnitNumber,
  propertyExportFL,
  propertyFL,
  SalesProgress,
  calcuateTCODate,
  calcuateSalesStart,
  BuildingStatus,
  CurrentStatus,
  _formatNameAddress,
  _formatNum,
  _getBuildingURLBase,
} from "../../../shared/helpers/utils";

import { _axiosCall } from "../../../shared/helpers/apicalls";
import NewsItem from "../../News/components/NewsItem";
const buildingUrl = process.env.API_ROOT + "/";

class UnitsListTable extends PureComponent {
  state = {
    order: "",
    orderBy: "",
    selected: new Map([]),
    page: 0,
    rowsPerPage: 20,
    fetchInProgress: false,
    buildingKey: false,
    unitListData: [],
    start: 0,
    noMoreResults: false,
    selected: new Map([]),
    activeTab: "projects",
    selectedUnits: {},
    news: [],
  };

  componentDidMount() {
    const { group } = this.props;

    if (
      group &&
      group.group_data &&
      group.group_data["projects"] &&
      group.group_data["projects"].length > 0
    ) {
      this.queryBuildings();
    } else if (
      group &&
      group.group_data &&
      group.group_data["buildings"] &&
      group.group_data["buildings"].length > 0
    ) {
      this.queryUnits();
      this.setState({ activeTab: "buildings" });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeTab } = this.state;
    const { group } = this.props;

    if (
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      this.state.start !== prevState.start
    ) {
      const reset =
        this.state.orderBy !== prevState.orderBy ||
        this.state.order !== prevState.order;
      if (activeTab == "projects") {
        this.queryBuildings(reset);
      } else if (activeTab == "buildings") {
        this.queryUnits(reset);
      }
    }
  }

  _loadMore = () => {
    if (this.state.fetchInProgress === true) {
      return false;
    }
    this.setState({ start: this.state.start + this.state.rowsPerPage });
  };

  _handleProjectClick = (event, building) => {
    const buildingURL =
      _getBuildingURL(building, "_id") +
      "/" +
      _nameToUrl(
        building.address_2_normalized ? building.address_2_normalized : ""
      );
    window.open(buildingURL);
    //this.props.history.push(buildingURL);
  };
  _handleBuildingClick = (event, building) => {
    const buildingURL = "building/" + _getBuildingURLBase(building);
    window.open(buildingURL);
    //this.props.history.push(buildingURL);
  };
  _handleRequestUnitsSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    console.log(event, property);
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy, start: 0 });
  };

  queryUnits = async (reset) => {
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const { group } = this.props;
    const { orderBy, order } = this.state;
    let sort = "floor asc, address_2_normalized asc";
    if (this.state.orderBy && this.state.orderBy.length > 0) {
      if (orderBy === "address_2_normalized") {
        sort = `floor ${order}, ${orderBy} ${order}`;
      } else if (orderBy === "offering_price") {
        sort = `last_listed_sale_price_all ${order}, ${orderBy} ${order}`;
      } else if (orderBy === "last_sold_date") {
        sort = `${orderBy} ${order}, last_contract_date ${order}`;
      } else {
        sort = `${orderBy} ${order}`;
      }
    }
    const fq = [
      `key:(${group.group_data["buildings"].join(" ")})`,
      "deleted:false",
    ];

    let axiosData = {
      url: buildingUrl + "query/we3-buildings",
      method: "post",
      query: {
        q: "*:*",
        fq: fq,
        wt: "json",
        rows: this.state.rowsPerPage,
        start: this.state.start,
      },
    };

    let response = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length > 0) {
          const newUnitListData = reset
            ? res.docs
            : [...this.state.unitListData, ...res.docs];
          this.setState({
            unitListData: newUnitListData,
            fetchInProgress: false,
            buildingKey: false,
            noMoreResults: res.docs.length == res.count,
            start: this.state.start + this.state.rowsPerPage,
          });
        } else if (res.docs.length === 0) {
          this.setState({
            noMoreResults: true,
            fetchInProgress: false,
            buildingKey: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  queryBuildings = async (reset) => {
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    const { group } = this.props;
    const { orderBy, order } = this.state;
    let sort = {};
    if(orderBy){
      sort[orderBy] = order == "asc" ? 1 : -1;
    }else{
      sort = {_id: -1};
    }
    
    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      { key: { $in: [...group.group_data["projects"]] } },
    ];

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        meta: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
        project_stage: 1,
        pipeline_project_stalled: 1,
        pipeline_project_abandoned: 1,
      },
      skip: parseInt(this.state.start),
      limit: parseInt(this.state.rowsPerPage),
      sort: sort,
    };

    let axiosData = {
      url: buildingUrl + "pipeline/query",
      method: "post",
      query: mongoQuery,
    };

    let response = await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length > 0) {
          const newUnitListData = reset
            ? res.docs
            : [...this.state.unitListData, ...res.docs];
          this.setState({
            unitListData: newUnitListData,
            fetchInProgress: false,
            buildingKey: false,
            noMoreResults: res.docs.length == res.count,
          });
        } else if (res.docs.length === 0) {
          this.setState({
            noMoreResults: true,
            fetchInProgress: false,
            buildingKey: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  queryNews = async () => {
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }
    const { group } = this.props;
    let projects = [];
    let buildings = [];
    let queries = [];

    const { orderBy, order } = this.state;
    let sort = {};
    sort[orderBy] = order == "asc" ? 1 : -1;
    
    const buildingfq = [
      `key:(${group.group_data["buildings"].join(" ")})`,
      "deleted:false",
    ];

    let buildingData = {
      url: buildingUrl + "query/we3-buildings",
      method: "post",
      query: {
        q: "*:*",
        fq: buildingfq,
        wt: "json",
        rows: group.group_data["buildings"].length,
        start: 0,
      },
    };
    await _axiosCall(buildingData).then((res) => {
      if (res && res.docs && res.docs.length > 0) {
        buildings = res.docs;
        for (let project of res.docs) {
          queries.push(
            "(borough:" +
              project.borough +
              ' AND full_street_address:("' +
              project.alternate_addresses.join('" OR "') +
              '"))'
          );
        }
      }
    });

    let searchFilters = [
      { "meta.deleted": { $exists: false } },
      {
        $or: [
          { source_provider: { $ne: "mpnd" } },
          {
            $and: [
              { source_provider: "permits" },
              { building_status: { $ne: "resale" } },
            ],
          },
          { building_status: "sponsor" },
          { building_status: "pipeline" },
        ],
      },
      { key: { $in: [...group.group_data["projects"]] } },
    ];
    

    let mongoQuery = {
      query: { $and: searchFilters },
      projection: {
        _id: 1,
        name: 1,
        display_full_street_address: 1,
        address: 1,
        key: 1,
        image: 1,
        images: 1,
        meta: 1,
        pipeline_zoning_area_residential: 1,
        pipeline_total_construction_floor_area: 1,
        pipeline_building_stories_proposed: 1,
        pipeline_dwelling_units_proposed: 1,
        pipeline_filing_date: 1,
        pipeline_pre_filing_date: 1,
        pipeline_project_stage: 1,
        pipeline_coo_issuance_date: 1,
        pipeline_permit_issuance_job_start_date: 1,
        pipeline_permit_issuance_filing_date: 1,
        occupancy_type: 1,
        pipeline_occupancy_type: 1,
        pipeline_project_components: 1,
        date_added: 1,
        pipeline_occupancy_classification_proposed_code: 1,
        building_key: 1,
        project_stage: 1,
        pipeline_project_stalled: 1,
        pipeline_project_abandoned: 1,
      },
      skip: parseInt(0),
      limit: group.group_data["projects"].length,
      sort: sort,
    };

    let projectData = {
      url: buildingUrl + "pipeline/query",
      method: "post",
      query: mongoQuery,
    };

    await _axiosCall(projectData).then((res) => {
      if (res && res.docs && res.docs.length > 0) {
        for (let building of res.docs) {
          queries.push(
            "(borough:" +
              building.address.borough +
              ' AND full_street_address:("' +
              building.address.alternate_addresses.join('" OR "') +
              '"))'
          );
        }
      }
    });

    let q = "";
    if (queries && queries.length) {
      q = queries.join(" OR ");
    }
    let fq = ["hierarchy:[0 TO 2]", "address_2_normalized:\"\"", "event_time:[* TO NOW]"]
    let axiosData = {
      url: buildingUrl + "query/we3-activities-3",
      method: "post",
      query: {
        q: q,
         fq: fq,
        wt: "json",
        sort : "event_time desc"
      },
    };

    await _axiosCall(axiosData)
      .then((res) => {
        if (res && res.docs && res.docs.length > 0) {
          const newsData = res.docs;
          this.setState({
            news: newsData,
            fetchInProgress: false,
            buildingKey: false,
            noMoreResults: res.docs.length == res.count,
          });
        } else if (res.docs.length === 0) {
          this.setState({
            noMoreResults: true,
            fetchInProgress: false,
            buildingKey: false,
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      if (tab == "buildings") {
        this.queryUnits(true);
        this.props.handlePagination(false);
      } else if (tab == "projects") {
        this.queryBuildings(true);
        this.props.handlePagination(false);
      } else if (tab == "shared") {
        this.props.handlePagination(true);
      } else if (tab == "news") {
        this.props.handlePagination(false);
        this.queryNews();
      }
      this.setState({
        activeTab: tab,
        unitListData: [],
        start: 0,
        page: 0,
        noMoreResults: false,
        selectedUnits: {},
      });
    }
  };

  _selectUnit(property) {
    const { selectedUnits } = this.state;
    const newSelected = Object.assign({}, selectedUnits);

    if (newSelected[property.key]) {
      delete newSelected[property.key];
    } else {
      newSelected[property.key] = property;
    }

    this.setState({ selectedUnits: Object.assign({}, newSelected) });
  }

  _handleSelectAllClick() {
    const { selectedUnits, unitListData } = this.state;
    let newSelected = {};

    if (Object.keys(selectedUnits).length < unitListData.length) {
      unitListData.forEach((item) => {
        newSelected[item.key] = item;
      });
    }

    this.setState({ selectedUnits: newSelected });
  }
  render() {
    const {
      reactTableFiltered,
      isSelected,
      handleClick,
      emptyRows,
      handleSelectAllClick,
      handleRequestSort,
      isGrouped,
      user,
      building,
      group,
    } = this.props;
    const {
      selected,
      unitListData,
      fetchInProgress,
      activeTab,
      selectedUnits,
      order,
      orderBy,
    } = this.state;
    if (
      this.state.start === 0 &&
      (unitListData === 0 || this.props.fetchInProgress)
    ) {
      return <LinearLoading />;
    }

    const data = unitListData;

    if (
      !fetchInProgress &&
      data.length <= 0 &&
      ["projects", "buildings"].indexOf(activeTab) >= 0
    ) {
      return (
        <Col md={12}>
          <Card>
            <CardBody className={`small`}>
              <div className={`email-confirmation`}>
                <div className="email-confirmation__icon">
                  <AlertIcon size={50} color={`#D6BAE8`} />
                </div>
                <h3 className="email-confirmation__title">
                  No Projects or Buildings Added to This Collection
                </h3>

                <p className="email-confirmation__sub">
                  <a
                    href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo"
                    className="link-text"
                    target="_blank"
                  >
                    Learn About Collections{" "}
                    <OpenInNewIcon size={16} style={{ marginTop: -3 }} />
                  </a>
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return (
      <Fragment>
        <div className="tabs tabs--bordered-bottom mb-0">
          <div className="tabs__wrap">
            <Nav tabs>
              {group &&
                group.group_data &&
                group.group_data["projects"] &&
                group.group_data["projects"].length > 0 && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "projects",
                      })}
                      onClick={() => {
                        this.toggle("projects");
                      }}
                    >
                      <span className="tab-title">Pipeline</span>
                    </NavLink>
                  </NavItem>
                )}
              {group &&
                group.group_data &&
                group.group_data["buildings"] &&
                group.group_data["buildings"].length > 0 && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "buildings",
                      })}
                      onClick={() => {
                        this.toggle("buildings");
                      }}
                    >
                      <span className="tab-title">Existing</span>
                    </NavLink>
                  </NavItem>
                )}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "shared" })}
                  onClick={() => {
                    this.toggle("shared");
                  }}
                >
                  <span className="tab-title">Shared</span>
                </NavLink>
              </NavItem>
           {/*   <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "news" })}
                  onClick={() => {
                    this.toggle("news");
                  }}
                >
                  <span className="tab-title">News</span>
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>
        </div>

        <TabContent activeTab={activeTab}>
          {activeTab === "shared" && (
            <TabPane tabId="shared">
              <div className="mt-5">
                <MyReports group={group} subTab hidePagination />
              </div>
            </TabPane>
          )}

          {activeTab === "projects" && (
            <TabPane tabId="projects">
              <div className="material-table__wrap">
                <AddCollectionButton
                  selectedUnits={selectedUnits}
                  groupType={"projects"}
                  {...this.props}
                  removeSelectFromGroup
                />
                <Table className="material-table">
                  <MatTableHead
                    numSelected={Object.keys(selectedUnits).length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this._handleSelectAllClick.bind(this)}
                    onRequestSort={this._handleRequestUnitsSort.bind(this)}
                    rowCount={data.length}
                  />

                  <TableBody>
                    {data.map((d) => {
                      const isSelected = false; //this.isSelected(d.id);
                      //If building is sold out override stats
                      /*
								  if(d.offering_verified_status === 'sold out'){
									  d.inventory_unsold = 0;
									  d.inventory_pct_unsold = 0;
								  }
								  
		*/
                      let inventory_pct_unsold = d.inventory_pct_bulk_unsold
                        ? d.inventory_pct_bulk_unsold
                        : d.inventory_pct_unsold;
                      let inventory_unsold = d.inventory_bulk_unsold
                        ? d.inventory_bulk_unsold
                        : d.inventory_unsold;
                      let offering_effective_year = d.offering_effective_date
                        ? parseInt(
                            moment(d.offering_effective_date).format("YYYY")
                          )
                        : 0;
                      if (offering_effective_year <= 1970) {
                        offering_effective_year = 0;
                      }
                      let current_year = new Date().getFullYear();
                      const { buildingNameOrAdr, adr, _boro, _hood } =
                        _formatNameAddress(d);
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={(event) =>
                            this._handleProjectClick(event, d)
                          }
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={isSelected}
                        >
                          <TableCell
                            className="material-table__cell"
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={
                                selectedUnits[d.key] === undefined
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this._selectUnit(d);
                              }}
                              className={`material-table__checkbox ${
                                selectedUnits[d.key] !== undefined &&
                                "material-table__checkbox--checked"
                              }`}
                            />

                            {user.role &&
                              Array.isArray(user.role) &&
                              user.role.indexOf("admin") >= 0 &&
                              d.offering_file_number &&
                              d.offering_file_number && (
                                <Fragment>
                                  <IconButton
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      window.open(
                                        `https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`
                                      );
                                    }}
                                  >
                                    <OpenIcon color={"#BFC4D1"} />
                                  </IconButton>
                                </Fragment>
                              )}
                          </TableCell>

                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                          >
                            <StatusBadge building={d} />
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <span className={"building_name"}>
                              {buildingNameOrAdr
                                ? _capitalizeText(buildingNameOrAdr)
                                : _capitalizeText(adr)}

                              <BuildingIcons building={d} />
                            </span>
                            <br />
                            <span className="light-text small-text">
                              {_hood ? `${_capitalizeText(_hood)}, ` : ""}{" "}
                              {_capitalizeText(_boro)}
                            </span>
                          </TableCell>

                          <TableCell
                            className="material-table__cell light-text small-text"
                            size="small"
                            align="center"
                          >
                            {d.pipeline_building_stories_proposed > 0 ? (
                              <span className="light-text small-text">
                                {_formatNumber(
                                  d.pipeline_building_stories_proposed
                                )}
                              </span>
                            ) : (
                              <span className="light-text small-text">--</span>
                            )}
                          </TableCell>
                          <TableCell
                            className="material-table__cell light-text small-text"
                            size="small"
                            align=""
                          >
                            <span className="light-text small-text">
                              {d.pipeline_dwelling_units_proposed > 0
                                ? _formatNumber(
                                    d.pipeline_dwelling_units_proposed
                                  )
                                : ""}
                            </span>
                          </TableCell>
                          <TableCell className="material-table__cell" align="">
                            <span className="light-text small-text">
                              {d.pipeline_zoning_area_residential > 0
                                ? _formatNumber(
                                    d.pipeline_zoning_area_residential
                                  ) + " Resi SF"
                                : ""}
                              <br />
                              <span
                                className="light-text small-text"
                                style={{ color: "#858AA1" }}
                              >
                                {d.pipeline_total_construction_floor_area > 0
                                  ? _formatNumber(
                                      d.pipeline_total_construction_floor_area
                                    ) + " Total SF"
                                  : ""}
                              </span>
                            </span>
                          </TableCell>
                          <TableCell className="material-table__cell" align="">
                            <span className="light-text small-text">
                              {moment(d.meta.ModificationTimestamp).year() >
                              1970
                                ? _capitalizeText(
                                    moment(
                                      d.meta.ModificationTimestamp
                                    ).fromNow()
                                  )
                                : "--"}
                            </span>
                          </TableCell>
                          <TableCell className="material-table__cell" align="">
                            <span className="light-text small-text">
                              {d.pipeline_filing_date &&
                              moment(d.pipeline_filing_date).year() > 1970
                                ? _capitalizeText(
                                    moment(d.pipeline_filing_date).fromNow()
                                  )
                                : "--"}
                            </span>
                          </TableCell>
                          <TableCell className="material-table__cell" align="">
                            <span className="light-text small-text">
                              {d.pipeline_permit_issuance_job_start_date &&
                              moment(
                                d.pipeline_permit_issuance_job_start_date
                              ).year() > 1970
                                ? _capitalizeText(
                                    moment(
                                      d.pipeline_permit_issuance_job_start_date
                                    ).fromNow()
                                  )
                                : "--"}
                            </span>
                          </TableCell>

                          <TableCell className="material-table__cell" align="">
                            <span className="light-text small-text">
                              {d.pipeline_coo_issuance_date &&
                              moment(d.pipeline_coo_issuance_date).year() > 1970
                                ? _capitalizeText(
                                    moment(
                                      d.pipeline_coo_issuance_date
                                    ).fromNow()
                                  )
                                : "--"}
                            </span>
                          </TableCell>
                          <TableCell className="material-table__cell" align="">
                            <span className="light-text small-text">
                              {d.offering_submitted_date &&
                              moment(d.offering_submitted_date).year() > 1970
                                ? _capitalizeText(
                                    moment(d.offering_submitted_date).fromNow()
                                  )
                                : "--"}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TabPane>
          )}
          {activeTab === "buildings" && (
            <TabPane tabId="buildings">
              <div>
                <AddCollectionButton
                  selectedUnits={selectedUnits}
                  groupType={"buildings"}
                  {...this.props}
                  removeSelectFromGroup
                />
                <Table className="material-table">
                  <UnitsSearchTableHead
                    numSelected={Object.keys(selectedUnits).length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this._handleSelectAllClick.bind(this)}
                    onRequestSort={this._handleRequestUnitsSort.bind(this)}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {data.map((d) => {
                      const itemSelected = false; //isSelected(d.id);
                      const floorplan = false; //_getUnitFloorPlan(d);
                      const buildingData = d;
                      //If building is sold out override stats
                      const { buildingNameOrAdr, adr, _boro, _hood } =
                        _formatNameAddress(d);
                      const buildingOwnershipType =
                        d.ownership_type && _shouldShow(d.ownership_type)
                          ? d.ownership_type.toString().toLowerCase().trim()
                          : false;
                      if (
                        building &&
                        building.offering_verified_status === "sold out"
                      ) {
                        if (
                          [
                            "active",
                            "not_yet_recorded",
                            "sold",
                            "in contract",
                            "resale",
                          ].indexOf(d.offering_status) < 0
                        ) {
                          d.offering_status = "sold";
                        }
                      }

                      if (d.baths % 1 == 0 && d.half_baths > 0) {
                        d.baths = d.baths + 0.5;
                      }
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={(event) =>
                            this._handleBuildingClick(event, d)
                          }
                          aria-checked={itemSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={itemSelected}
                        >
                          <TableCell
                            className="material-table__cell"
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={
                                selectedUnits[d.key] === undefined
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this._selectUnit(d);
                              }}
                              className={`material-table__checkbox ${
                                selectedUnits[d.key] !== undefined &&
                                "material-table__checkbox--checked"
                              }`}
                            />
                          </TableCell>
                          {!isMobile && (
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                              size="small"
                              scope="row"
                            >
                              {d.name ? _capitalizeText(d.name) : "--"}
                            </TableCell>
                          )}

                          <TableCell
                            className="material-table__cell material-table__cell-right nowrap"
                            component="td"
                            scope="row"
                            padding=""
                          >
                            {isGrouped || this.props.buildingView ? (
                              <div className="icon__floorplan">
                                <span>
                                  {_formatUnitNumber(
                                    d.address_2_normalized,
                                    building
                                  ).replace("#", "Apt")}
                                </span>

                                {/* <UnitIcons building={building} unit={d} /> */}
                                <Fragment>
                                  <OpenInNewIcon
                                    size={14}
                                    style={{
                                      marginTop: "-3px",
                                      marginLeft: "5px",
                                    }}
                                    color={"#BFC4D1"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      window.open(
                                        `${_getBuildingURL(
                                          buildingData,
                                          "_id"
                                        )}/${_nameToUrl(
                                          d.address_2_normalized
                                        )}`
                                      );
                                    }}
                                  />
                                </Fragment>
                              </div>
                            ) : (
                              <div className="icon__floorplan">
                                <span>
                                  {buildingNameOrAdr
                                    ? _capitalizeText(buildingNameOrAdr)
                                    : _capitalizeText(adr)}
                                </span>

                                {/* <UnitIcons building={building} unit={d} /> */}
                                {!isMobile && (
                                  <Fragment>
                                    <OpenInNewIcon
                                      size={14}
                                      style={{
                                        marginTop: "-3px",
                                        marginLeft: "5px",
                                      }}
                                      color={"#BFC4D1"}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.open(
                                          `${_getBuildingURL(
                                            buildingData,
                                            "_id"
                                          )}/${_nameToUrl(
                                            d.address_2_normalized
                                          )}`
                                        );
                                      }}
                                    />
                                  </Fragment>
                                )}
                                <br />
                                <span
                                  className="light-text small-text"
                                  style={{ color: "#858AA1" }}
                                >
                                  {d.neighborhood && d.neighborhood[0]
                                    ? `${_capitalizeText(d.neighborhood[0])}, `
                                    : ""}{" "}
                                  {_capitalizeText(d.borough)}
                                </span>
                              </div>
                            )}
                          </TableCell>

                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                          >
                            <span className="small-text">
                              {buildingOwnershipType
                                ? _capitalizeText(buildingOwnershipType)
                                : "--"}
                            </span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                          >
                            <span className=" small-text">
                              {d.year_built ? d.year_built : "--"}
                            </span>
                          </TableCell>

                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                            align="center"
                          >
                            {d.zip ? d.zip : "--"}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                            align="center"
                          >
                            {_hood === "nyc" ? (
                              "NYC"
                            ) : (
                              <span
                                className="u_link"
                                // onClick={props.hoodTagClick.bind(this, {
                                //   hood: hood,
                                //   boro: boro,
                                // })}
                              >
                                {_capitalizeText(_hood)}
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                            align="center"
                          >
                            {_boro === "new york" ? (
                              "New York"
                            ) : (
                              <span
                                className="u_link"
                                // onClick={props.boroTagClick.bind(this, boro)}
                              >
                                {_capitalizeText(_boro)}
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                            align="center"
                          >
                            {d.total_floors
                              ? _formatNum(d.total_floors) + " floors"
                              : "--"}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"
                          >
                            {d.residential_units
                              ? _formatNum(d.residential_units) + " units"
                              : ""}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TabPane>
          )}
          {activeTab === "news" && (
            <TabPane tabId="news" className="news__container">
              <div className="timeline mt-3">
                {this.state.news.length ? (
                  this.state.news.map((item) => {
                    try {
                      const data = JSON.parse(item.data);
                      item.data = data;
                    } catch (e) {
                      //ToDo
                    }

                    return <NewsItem item={item} />;
                  })
                ) : (
                  <LinearLoading />
                )}
              </div>
            </TabPane>
          )}
        </TabContent>
        {!reactTableFiltered &&
          ["projects", "buildings"].indexOf(activeTab) >= 0 && (
            <div className="unit__load">
              <Tooltip title="Load more buildings">
                <Button
                  onClick={(e) => {
                    this._loadMore(e);
                  }}
                  variant="contained"
                  color="news"
                  className={`timeline__load-more`}
                  startIcon={<LoadMoreIcon />}
                  disabled={
                    this.state.fetchInProgress || this.state.noMoreResults
                  }
                >
                  {" "}
                  {`${
                    this.state.fetchInProgress
                      ? "Loading..."
                      : this.state.noMoreResults
                      ? `No More ${_capitalizeText(activeTab)}`
                      : "Load More"
                  }`}
                </Button>
              </Tooltip>
            </div>
          )}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.user,
}))(withRouter(UnitsListTable));
