import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import CircleSlice4Icon from 'mdi-react/CircleSlice4Icon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';


  
const calculateVelocity = (buildingRes) => {	
	
	if(!buildingRes.sales_start_date) return '--';
	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);	
	const velocity = (totalSold / months).toFixed(1);
	
	return velocity;
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes) => {	
	
	const listed = buildingRes.inventory_listed;
	const shadow = 	buildingRes.inventory_shadow;
	return [{ value: listed, fill: getDefaultChartColors[2] },
  				{ value: shadow, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const PercentShadow = (props) => {	
	const { buildingRes, location } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	
	return (
	  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
	    <Card>
	    <Link to={`${base_url}`}>
	      <CardBody className="dashboard__health-chart-card card__link">
	        <div className="card__title">
	          <h5 className="bold-text card__title-center">{`Listed vs. Shadow`}</h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={calculateBarData(props.buildingRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="dashboard__health-chart-info">
	            <CircleSlice4Icon style={{ fill: getDefaultChartColors[2] }} />
	            <p className="dashboard__health-chart-number">{parseFloat(props.buildingRes.inventory_pct_listed).toFixed(1)}<span className="small-text">%</span></p>
	            <p className="dashboard__health-chart-units">{props.buildingRes.inventory_listed} units listed</p>
	          </div>
	        </div>
	        <p className="dashboard__goal">{props.buildingRes.inventory_shadow} of {props.buildingRes.inventory_total} Units Never Listed</p>
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
    )
};

export default PercentShadow;
