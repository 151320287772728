import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { getDefaultChartColors } from '../../../../shared/helpers';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_formatBigNumber
} from '../../../../shared/helpers/utils';


const CurrentProgress = (props) => {
	if(!props.facetData || !props.facetData.by_stage) return null;
	const { analysisType, baseURL } = props;
	const { by_stage, resi_by_stage } = props.facetData;
	
	let stage = analysisType == "units" ? resi_by_stage.slice() : by_stage.slice();
	
	let planning = stage.filter(s => s['_id'] == "planning").length>0 ? stage.filter(s => s['_id'] == "planning")[0][analysisType] : 0;
	planning += stage.filter(s => s['_id'] == "").length>0 ? stage.filter(s => s['_id'] == "")[0][analysisType] : 0;
	let construction = stage.filter(s => s['_id'] == "construction").length>0 ? stage.filter(s => s['_id'] == "construction")[0][analysisType] : 0;
	let completion = stage.filter(s => s['_id'] == "completion").length>0 ? stage.filter(s => s['_id'] == "completion")[0][analysisType] : 0;
	
	if(analysisType == "units"){
		planning -= stage.filter(s => s['_id'] == "planning").length>0 ? stage.filter(s => s['_id'] == "planning")[0]["units_existing"] : 0;
		planning -= stage.filter(s => s['_id'] == "").length>0 ? stage.filter(s => s['_id'] == "")[0]["units_existing"] : 0;
		construction -= stage.filter(s => s['_id'] == "construction").length>0 ? stage.filter(s => s['_id'] == "construction")[0]["units_existing"] : 0;
		completion -= stage.filter(s => s['_id'] == "completion").length>0 ? stage.filter(s => s['_id'] == "completion")[0]["units_existing"] : 0;
	}

	const total_count = planning+construction+completion;
	
	const title =  {
		"count" : "Total Pipeline Projects",
		"units" : "Total Residential Unit",
		"sq_ft" : "Total Construction Square Feet"
	}
	
	let qString = baseURL.split("?");
	let params = qString.length>1 ? new URLSearchParams(qString[1]) : new URLSearchParams(qString[0]);
	params.delete("building_stage");
	let noStageURL = qString[0] + '?'+ params.toString();
	return (
		    <Card>
		      <CardBody className="dashboard__booking-card">
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            {_formatNumber(total_count)} 
		          </h5>
		          <TrendingUpIcon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">
		        	 {title[analysisType]}
		        </h5>	 
		        <div className="progress-wrap progress-wrap--big progress-long no-hover">
		        	<p className="dashboard__booking-card-progress-label">
		        		Analyze by Stage
		        	</p>
				    <Progress multi>
						{planning>0 &&
							
								<Progress bar color="resale" value={planning} max={total_count}>
									<Link to={`${noStageURL}&building_stage=pre-development`}>
									{planning > 1000000 ? _formatBigNumber(planning) : _formatNumber(planning)} Pre-Development		
									</Link>
								</Progress>
							
						}
						{construction>0 &&
							<Progress bar color="contract" value={construction} max={total_count}>
								<Link to={`${noStageURL}&building_stage=construction`}>
								{construction > 1000000 ? _formatBigNumber(construction) : _formatNumber(construction)} Construction
								</Link>		
							</Progress>
						}
						{completion>0 &&
							<Progress bar color="active" value={completion} max={total_count}>
								<Link to={`${noStageURL}&building_stage=operation`}>
								{completion > 1000000 ? _formatBigNumber(completion) : _formatNumber(completion)} Operation
								</Link>	
							</Progress>
						}
				    </Progress> 				    
				</div>     
		      </CardBody>
		    </Card>
	)
};

export default CurrentProgress;
