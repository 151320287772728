/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NewOrderTableProps } from '../../../../../shared/prop-types/TablesProps';
import moment from "moment"; 
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import ArrowEqualIcon from 'mdi-react/MinusCircleOutlineIcon';

import EnterpriseGate from './../../../../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "./../../../../../shared/helpers/access";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_formatBath,
} from '../../../../../shared/helpers/utils';
import { _axiosCall } from '../../../../../shared/helpers/apicalls';

import Panel from '../../../../../shared/components/Panel';

const apiURL = process.env.API_ROOT + '/query/';


const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}><DropdownItem>Messsage</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);


DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const Trend = ({ last, current }) => {
	
	if(last && last.total_price > current.total_price){		
		return (
			<ArrowDownIcon size={18} color="#f03434" />
		);		
	}else if(last && last.total_price < current.total_price){
		return (
			<ArrowUpIcon size={18} color="#4ce1b6" />
		);
	}
	
	return (
		<ArrowEqualIcon size={18} color="#999" />
	);
    
};


const processHistory = (offeringPlan) => {
	
	let lastPrice = offeringPlan.statCard_ppOnAccep ? parseFloat(offeringPlan.statCard_ppOnAccep.replace(/,/g,'').replace('$', '')).toFixed(0) : offeringPlan.price_current;	
	
	const newTotalPriceRegEx = /new\s+total\s+offering\s+price\s+\$[0-9\,]+/;
	const newTotalPriceReplaceRegEx = /new\s+total\s+offering\s+price\s+\$/;
	
	let amendments = [
		{
			number: 0,
			total_price : lastPrice,
			submitted_date : moment(offeringPlan.submitted_date).format('MM/DD/YYYY'),
			contents : offeringPlan.file_number.toUpperCase()
		}
	];
	
	offeringPlan.amendments.map(item => {
		const newPriceMatch = item.contents.match(newTotalPriceRegEx);
		let newPrice = lastPrice;
		if(newPriceMatch && newPriceMatch[0]){
			newPrice = parseInt(newPriceMatch[0].replace(newTotalPriceReplaceRegEx, '').replace(/\,/g, ''));
			lastPrice = newPrice;
		}
		let amendment = {
			number: item.amendmentnum,
			total_price : newPrice,
			submitted_date : item.submitteddate,
			contents : item.contents
		}
		amendments.push(amendment);	
	});
	
	return amendments;
}



class OfferingHistory extends PureComponent {
	
	constructor(props) {
	    super(props);
	    this.state = {	     
	      offeringPlanAmendments: false,
	    };
	  }
	  
	  componentDidMount() {
		  	
	  }
	  
	  _getBuildingOPData = async () => {
			
			const addressQ = this.props.buildingRes.alternate_addresses.join('" OR "');						
			const q = `alternate_addresses:("${addressQ}") AND (zip:${this.props.buildingRes.zip} OR borough:"${this.props.buildingRes.borough}") AND id:c*`;	
			let axiosData = {
				url: apiURL + "we3-offering-plans-amendments",
				method: "post",
				query: {
					q: q,
					sort: "solr_updated_date desc",
					wt: "json",
					rows: 999,
					
				}
			};
	
			let formattedData = await _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					if(res.docs && res.docs.length>0){
						
						const offeringPlan = res.docs.map(item => {
							try{
								item.data = JSON.parse(item.amendments);
							}catch(e){
								item.data = false;
							}							
							return item;														
						});
						this.setState({offeringPlan: offeringPlan[0]});
					}					
				})
				.catch(error => {
					console.log("error: " + error);
				});	
			// console.log(formattedData)
			return formattedData;
	  };

	  render() {
		  const { offeringPlan, user } = this.props;
		  console.log(offeringPlan)
		  
		  if(!_hasPermission(user, "finance-tab")){
				return (
					<EnterpriseGate 
						copy={<span>Access to offering history requires an Enterprise subscription.</span>}
						upgrade={false}
						user={user}
						nextPlan={"enterprise"}
					/>	
				)   
			   
		  }
		  
		  if(!offeringPlan || !offeringPlan[0]){
			  return null;
		  }
		  
		  
		  
		  
		  if(offeringPlan && offeringPlan[0] && offeringPlan[0].source_data_json){
			  
			  try{
				  const source = JSON.parse(offeringPlan[0].source_data_json);
				  console.log(source)
			  }catch(e){
				  
			  }
		  }
		  
		  return offeringPlan.map(op => {
			  if(!op.amendments || !Array.isArray(op.amendments)){
				  return (
					  <React.Fragment>
					
					    <Row>
					    	<Col md={12}>	
					    	<Card>
							 <CardBody >    	
								<div>				 
								    <Table responsive striped className="dashboard__table-orders">
								      <thead>
								        <tr>
								          
								          <th style={{width:140}}>Document</th>
								          <th style={{width:140}}>Submitted Date</th>
								          <th style={{width:50}} />
								          <th style={{width:160}}>Total Offering Price</th>
								          <th>Document Summary</th>
								          <th />
								        </tr>
								      </thead>
								      <tbody>
								      	<tr>	
								          			            
								            <td className="" style={{whiteSpace:'nowrap'}}>
								              Initial Offering Plan
								              
								            </td>
								            <td className="dashboard__table-orders-total" dir="ltr">{moment(offeringPlan[0].submitted_date).format('MM/DD/YYYY')}</td>
								            <td style={{paddingRight:0}}>
								            	<Trend last={null} current={null} /> 
								            </td>
								            <td className="dashboard__table-orders-total" dir="ltr">{offeringPlan[0].price_initial ? '$'+_formatNumber(offeringPlan[0].price_initial) : '--'}
								            	
								            </td>
								            <td className="" dir="ltr">						               
									            <ul>
									            	<li>{offeringPlan[0].file_number.toUpperCase()}</li>
									            </ul>
												
									            
								            </td>
								            <td></td>
								          </tr>
								      
								       </tbody>
								    </Table>
								  </div>
								</CardBody>
							    </Card>
					    	</Col>
					    </Row>
					</React.Fragment>			      
					  
				  )
			  }else{
				  const amendments = processHistory(op);
			  
				  return (
				    <React.Fragment>
						
					    <Row>
					    	<Col md={12}>	
					    	<Card>
							 <CardBody >    	
								<div>				 
								    <Table responsive striped className="dashboard__table-orders">
								      <thead>
								        <tr>
								          
								          <th  style={{width:140}}>Document</th>
								          <th  style={{width:140}}>Submitted Date</th>
								          <th style={{width:50}} />
								          <th style={{width:160}}>Total Offering Price</th>
								          <th>Document Summary</th>
								          <th />
								        </tr>
								      </thead>
								      <tbody>
								        {amendments.map((event, index) => (
								          <tr key={index}>	
								          			            
								            <td className="" style={{whiteSpace:'nowrap'}}>
								              {event.number === 0 ? 'Initial Offering Plan' : `Amendment ${event.number}`}
								              
								            </td>
								            <td className="dashboard__table-orders-total" dir="ltr">{event.submitted_date}</td>
								            <td style={{paddingRight:0}}>
								            	<Trend last={amendments[index > 0 ? (index-1) : 0]} current={event} /> 
								            </td>
								            <td className="dashboard__table-orders-total" dir="ltr"> ${_formatNumber(event.total_price)}
								            	
								            </td>
								            <td className="" dir="ltr">						               
									            {event.contents 
										          ? <ul>								          
											          	{event.contents.replace(/^[0-9]+;/, '').replace(/^[0-9]+\s;/, '').replace(/^[0-9]+\s-\s/, '').replace(/^[0-9]+-\s/, '').replace(/\([0-9a-z]+\)/, '').split(';').map(item => {
												          	const text = item.trim().replace(/^[0-9]+\,\s/, '').replace(/^99-/, '');
											            	if(text && text.length>2 && isNaN(text)){
												            	return <li>{_capitalizeText(text).replace("Cd", "CD")}</li>
												            }								            	
												        })}
									            	</ul>
												  :
												  <React.Fragment>--</React.Fragment>
									            }
								            </td>
								            <td></td>
								          </tr>
								        ))}
								      </tbody>
								    </Table>
								  </div>
								</CardBody>
							    </Card>
					    	</Col>
					    </Row>
				    </React.Fragment>
				  );
				  
			  }
			  
			  
			  
			  
		  })
		  
		  
	}
}

export default (OfferingHistory);
