import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Collapse
} from 'reactstrap';
import { setUserProfile } from '../../../redux/actions/authActions';
import { Publisher } from "./../../pubsub/publisher.js";
import CheckIcon from 'mdi-react/CheckCircleOutlineIcon';
import CircleIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import ChangeIcon from 'mdi-react/RotateLeftIcon';

import { setPlan } from '../../../redux/actions/planActions';
import { subscriptionPlans, appFeatures } from './plans.js';

import TopBar from './../TopBar'
import Footer from './../Footer'


import{
	_capitalizeText,
	_formatNumber
} from '../../helpers/utils';
import{
	_axiosCall
} from '../../helpers/apicalls';



import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './stripe/CheckoutForm';
import CouponForm from './stripe/CouponForm';
import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import SelectedIcon from 'mdi-react/CheckCircleOutlineIcon';
import SelectIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';


import CacheOffIcon from 'mdi-react/LayersOffOutlineIcon';
import CacheOnIcon from 'mdi-react/LayersOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import './Payment.scss'


const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);
const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';
const oktaToken = process.env.OKTA_TOKEN;


const PurpleSwitch = withStyles({
  switchBase: {
    color: '#9600BF',
   
    '&$checked': {
      color: '#9600BF',
    },
    '&$checked + $track': {
      backgroundColor: '#BF96D9',
    },
  },
  checked: {},
  track: { backgroundColor: '#BF96D9' },
})(Switch);


class Pay extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    
    
    
    this.state = {
      selectedPlan: false,
      coupon:false,
      trial:true
    };
    
    this.logout = this.logout.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.setCoupon = this.setCoupon.bind(this);
    
  }
  
  componentDidMount() {
	console.log('pay componentDidMount', subscriptionPlans);
	const {dispatch, user, plan } = this.props;
	let currentPlan = plan.id ? subscriptionPlans.filter(subPlan => subPlan.id === plan.id)[0] : false;
	if(!currentPlan && localStorage && localStorage.getItem("mpnd-plan")){
		let plan_id = localStorage.getItem("mpnd-plan");
		currentPlan = subscriptionPlans.filter(subPlan => subPlan.id === plan_id).length>0 ? subscriptionPlans.filter(subPlan => subPlan.id === plan_id)[0] : false;
		dispatch(setPlan(plan_id));
	}
	if(currentPlan){
		this.setState({selectedPlan: currentPlan.stripeProductId, coupon:false});
	}	
	
  }

 
  onSuccess(res) {
    if (res.status === 'SUCCESS') {
      return this.props.authService.redirect({
        sessionToken: res.session.token
      });
   } else {
    
    }
  }

  onError(err) {
    console.log('error logging in', err);
  }
  
  async logout() {
    this.props.authService.logout('/');
  }
  
  setCoupon(coupon) {
   this.setState( {coupon: coupon} )
  }
    
  updateUserProfile(payment, callback){
     // console.log('_getBuildingData called', this.state)
          
      const { authState, authService, dispatch, user, plan } = this.props;	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  
	  if(!payment.id) return false;
	  
	  let profile = Object.assign({}, user, { subscriptionPlan : this.state.selectedPlan, subscriptionId: payment.id });
	  
	  if(payment.status){
		  profile['subscriptionStatus'] = payment.status;
	  }	  
	  if(payment.trial_start){
		  profile['trialStart'] = payment.trial_start;
	  }
	  if(payment.trial_end){
		  profile['trialEnd'] = payment.trial_end;
	  }
	  const user_id = profile.id;
	  if(profile.id) delete profile.id
	  if(profile.login) delete profile.login
	  if(profile.role) delete profile.role
	  
	  if(user.role && Array.isArray(user.role) && user.role.length > 0){
		     let newRoles = user.role.slice();
		     let subIds = subscriptionPlans.map(plan => plan.id);			     
		     newRoles = newRoles.filter(role => subIds.indexOf(role) < 0);
		     profile.role = newRoles.concat(plan.id);
	  }else{
		     profile.role = [plan.id];
	  }

	  let userData = {
			url: apiUrl+'user/update',
			method: "post",
			query: {
				id: user_id,
				profile: profile
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {	      
	      if(res && res.profile){
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
		      if(typeof callback === 'function'){
			      callback(oktaUser);
		      }
		      return oktaUser;
		      
		  }else{
			  return false;
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    return false;
	  });
	  
	  return null;
   }

  render() {
	const { selectedPlan, coupon, trial } = this.state;
	const { user, plan } = this.props;
	
	let currentPlan = plan.id ? subscriptionPlans.filter(subPlan => subPlan.id === plan.id)[0] : false;
	
	if(!currentPlan && localStorage && localStorage.getItem("mpnd-plan")){
		let plan_id = localStorage.getItem("mpnd-plan");
		currentPlan = subscriptionPlans.filter(subPlan => subPlan.id === plan_id).length>0 ? subscriptionPlans.filter(subPlan => subPlan.id === plan_id)[0] : false;
	}
	
	if(!currentPlan){
		return <Redirect  to={{ pathname: '/pricing' }}/> 
	}else if(!user){
		return <Redirect  to={{ pathname: '/signin/register' }}/> 
	}else if(user && user.subscriptionPlan){
		Publisher.publish("login", user);
		return <Redirect  to={{ pathname: '/dashboard' }}/> 
	}
	
	
	let totalAtCheckout = currentPlan.totalAtCheckout;
	
	if(coupon){
		if(coupon.percent_off){
			totalAtCheckout = Math.round(totalAtCheckout * ((100-coupon.percent_off)/100));
		}else if(coupon.amount_off){
			totalAtCheckout = Math.round(totalAtCheckout - (coupon.amount_off/100));
		}
	}
	
	const taxes = (totalAtCheckout * .08875);
	
	totalAtCheckout = (parseFloat(totalAtCheckout) + parseFloat(taxes)).toFixed(2);
	return (
		<div className="plan__wrapper share__wrapper">
		<TopBar goTo={'plans'} buttonText={'Change Plan'} />
		<Row className="login-container signup">
	    	<Col lg={6} className="pt-0 signin">
    		<div className="payment-container widget-container">
    			<div className="payment-header"> 
    				<Link className="topbar__logo" to="/" style={{ display:"block", margin:"15px auto"}} />
    			</div>
		      {/*	<h4 className="payment-header-text">SELECT A PLAN</h4> */}
		    

		      	
				<div className="payment-elements">
				{/*<div className="trial-container">	  	
		      		<div className={'trial-type'}>
		      			<span>Try It</span>
		      			<span className={'break'} />
		      			<span className={'sub-text'}>(7-day Trial)</span>
		      		</div>
			        <Tooltip title={`${trial ? "Buy It Instead" : "Try It Instead"}`}>	
				    <PurpleSwitch 
				        checked={!trial}
				    	onChange={()=>this.setState({trial:!trial, coupon: false})}
				    />
				    </Tooltip>					      	
				    <div className={'trial-type'}>
				    	<span>Buy It</span>
				    	<span className={'break'} />
		      			<span className={'sub-text'}>(Fully-featured)</span>
				    	
				    </div>
				</div>*/}
				<div className="select-plan-container">				
					 <div className={`plan-type selected`} >	        			        			
	        			<div className="select-banner">
		        			{/*selectedPlan === plan.id  ? <SelectedIcon /> : <SelectIcon />*/}
		        			<span>{currentPlan.checkTitle}</span>
	        			</div>    			
        				<div className={`trial-plan-text`}>       					
        					<h3>${currentPlan.pricePerMonth % 1 != 0 ? currentPlan.pricePerMonth : _formatNumber(currentPlan.pricePerMonth)}</h3>				        					
							<div className="time small-text">Per Month</div>
							<h6 style={{marginBottom:20}}><span style={{fontSize:14,fontWeight:600,color:'#363347',textTransform:'uppercase'}}>{currentPlan.name} PLAN</span></h6>
							<div className="alt-price">{currentPlan.description}</div>	
        				</div>				        					        					        			
					</div> 
							        
				</div>
			        <hr/>
			        <div className="charge-amount">
			        	<div className="checkout-line">{currentPlan.name} Subscription <strong>${currentPlan.totalAtCheckout}</strong></div>
			            {coupon && coupon.name &&
				           <div className="checkout-line">{coupon.name} {coupon.percent_off ? <strong>-{coupon.percent_off}% Off</strong> : <strong>-${Math.round(coupon.amount_off/100)} Off</strong>}</div>
			            }
			            {trial &&
				           <div className="checkout-line">7-day Free Trial <span className="charge-text">(End on {moment().add(7, 'days').format('M/D/YYYY')})</span></div>
				        }
			            {trial &&
				           <div className="checkout-line">Payment After Trial <span className="charge-text">(+${parseFloat(taxes).toFixed(2)} Sales Tax)</span>  <strong>${totalAtCheckout}</strong></div>
				        }
				        {!trial &&
				        	<div className="checkout-line">NYC Sales Tax  <strong>${parseFloat(taxes).toFixed(2)}</strong></div>
				        }
			        	<div className="total-payment">{`Today's Total`} <strong>${trial ? 0 : parseFloat(totalAtCheckout).toFixed(2)}</strong></div>
			        	
			        	<span className="charge-text">
			        		Billed once per {currentPlan.billingFrequency}.  Cancel anytime.
			        	</span>
			        </div>
			        <div>
			        	<Elements stripe={stripePromise}>
					      <CouponForm user={user} setCoupon={this.setCoupon} currentCoupon={coupon} currentPlan={currentPlan} trial={trial} />
					    </Elements>
					</div>
			        <hr/>
			       
					<Elements stripe={stripePromise}>
				      <CheckoutForm selectedPlan={selectedPlan} totalAtCheckout={totalAtCheckout} currentPlan={currentPlan} user={user} coupon={coupon} updateUserProfile={this.updateUserProfile} trial={trial} />
				    </Elements>
				    <a className="cancel-payment" href="javascript:void" onClick={this.logout}>{`Cancel, I'll try another time.`}</a>
				</div>
				<p className="disclaimer">By creating an account, you agree to our{` `}<a href="https://marketproof.com/terms-of-use" target="_blank">Terms of Service</a>{` `}and 
			    {` `}<a href="https://marketproof.com/privacy-policy" target="_blank">Privacy Policy</a>.</p> 
		    </div> 
		       
		    </Col>
    		<Col lg={6} className="signin-hero-container" style={{marginTop:100}}>
    			<div className="signin-hero">
	    			<a href="https://marketproof.com/new-development" target="_blank"><h1>{`Start your 7-day`}<br/>{`free trial`}</h1></a>
	    			
	    			<h3 style={{marginBottom:0}}><span style={{color:'#9600BF', fontWeight:800}}>
	    				${currentPlan.pricePerMonth % 1 != 0 ? currentPlan.pricePerMonth : _formatNumber(currentPlan.pricePerMonth)} / month</span>
	    			</h3>
	    			<h6 style={{marginBottom:20}}><span style={{fontSize:15,fontWeight:600,color:'#363347',textTransform:'uppercase'}}>{currentPlan.name} PLAN</span></h6>
	    			<hr/>
	    			<div className="plan-features">
	    				{appFeatures.map(feature => {
						     if(feature.plans.indexOf(plan.id)>=0){
							     return <p className="pricing-card__feature"><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {feature.name}</p>
						     }else{
							     return <p className="pricing-card__feature pricing-card__feature--inactive"><CircleIcon size={16} style={{marginTop:-3}}  /> {feature.name}</p>
						     }
						    
					      })}
	    			
	    			</div>
	    			<hr/>
	    			<Link to={`/pricing`} style={{color:'#9600BF', textAlign:'right'}}><ChangeIcon size={16} style={{marginTop:-2}} /> Change Plan </Link>						    			
	    		</div>

		    </Col>
		</Row>
		<Footer />
		</div>
	)
		
	
      

    	
  }
};

export default connect(state => ({
  user: state.user,
  plan: state.plan
}))(withOktaAuth(Pay))