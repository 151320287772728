import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFormValues } from 'redux-form';

import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';
import LinearLoading from '../LinearLoading';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import ShareIcon from 'mdi-react/AccountPlusOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ExportIcon from 'mdi-react/CloudDownloadIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import HelpIcon from 'mdi-react/HelpCircleOutlineIcon';
import ContactIcon from 'mdi-react/ForumOutlineIcon';
import LockIcon from 'mdi-react/LockOutlineIcon';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import SnackBar from '../SimpleSnackBar';
import DataExportForm from './DataExportForm';

import { Publisher } from './../../pubsub/publisher.js';

import TrialSubscribe from './../payment/TrialSubscribe';

import { subscriptionPlans, dataExportCreditMap, coreTranslate, calculateCredits, translateCreditToDollars, costPerCredit, maxDownload, DataCreditUsageProgress } from './../payment/plans.js';


import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_slugifyText,
	_formatNumberDecimal
} from '../../../shared/helpers/utils';
import{
	_axiosCall
} from '../../helpers/apicalls';
const apiUrl = process.env.API_ROOT;

class ExportButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: 'Contact',
	    modal: false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
	    getCostInProgress:false,
	    getInvoiceProgress:false,
	    costDetails:false,
	    invoiceDetails:false,
	    customer:false,
		subscription:false,
		paymentMethod:false,
		doPaymentProgress:false,
		creditsNeeded : false,
	    additionalCreditsNeeded : false,
	    totalAmount : false,
	    creditRemaining : false,
	    taxes : false,
	    totalAmountWithTax : false,
	    userCreditUsed: false,
	    creditAvail: false,
	    subscribe: false,
	    customSubscription: false
	    
	};
	this.toggle = this.toggle.bind(this);
	this._requestDataExport = this._requestDataExport.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
	this._requestDataExportCost = this._requestDataExportCost.bind(this);
	this._getUpcomingInvoice = this._getUpcomingInvoice.bind(this);
	this._doPayment = this._doPayment.bind(this);
	this._getUserCredits = this._getUserCredits.bind(this);
    
  }
  
   handleSubmit(values) {
	   
	   const { totalAmountWithTax } = this.state;
	   
	   if(totalAmountWithTax>0){
		   this._doPayment();
	   }else{
		   this._requestDataExport(false);
	   }
	   
    
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	  if(prevState.modal !== this.state.modal){
		  if(this.state.modal){
			  this._getSubscriptionDetails();
			  this._requestDataExportCost();
			  
		  }else if(this.state.modal === false){
			  this.setState({getCostInProgress:false,
						    getInvoiceProgress:false,
						    costDetails:false,
						    invoiceDetails:false,
						    customer:false,
							subscription:false,
							paymentMethod:false,
							doPaymentProgress:false,
							creditsNeeded : false,
						    additionalCreditsNeeded : false,
						    totalAmount : false,
						    creditRemaining : false,
						    taxes : false,
						    totalAmountWithTax : false,
						    userCreditUsed: false,
						    planCredits: false,
						    creditAvail: false});
		  }
	  }
	  
	  if(this.state.creditsNeeded === false && this.state.costDetails !== false && this.state.userCreditUsed !== false){
		  this._calculatePayment(this.state.costDetails);
	  }
	 
  }
  
   snackOpen = (message, type) => {
		this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	};
	
	snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_calculatePayment = (costDetails) => {
		
		const { subscriptionPlan, monthlyDataCreditOverride } = this.props.user; 
		const { userCreditUsed } = this.state;
		
		const currentPlan = subscriptionPlans.filter(plan => plan.stripeProductId === subscriptionPlan)[0];
		
		let creditAvail = 0;
		
		let dataCredits = 0;
console.log(currentPlan, subscriptionPlan);
		if(currentPlan && currentPlan.dataCredits){		
			 dataCredits = currentPlan.dataCredits				 
		}else if(this.props.user.role.indexOf('admin')>=0){
			dataCredits = 9999999;
		}
		
		if(typeof monthlyDataCreditOverride ==='number' && monthlyDataCreditOverride >= 0){
			dataCredits = monthlyDataCreditOverride;
		}
		
		
		creditAvail = dataCredits - userCreditUsed;
		if(creditAvail < 0) creditAvail = 0;
		
		
		
		let creditsNeeded = 0;
	    let additionalCreditsNeeded = 0;
	    let totalAmount = 0;
	    let creditRemaining = 0;
	    let taxes = 0;
	    let totalAmountWithTax = 0;
	    
	    if(costDetails){
		    creditsNeeded = calculateCredits(costDetails.core_name, costDetails.rows, costDetails.facets);	    
		    additionalCreditsNeeded = creditsNeeded - creditAvail;
		    if(additionalCreditsNeeded < 0) additionalCreditsNeeded = 0;
		    
		    if(additionalCreditsNeeded>0){
			    totalAmount = translateCreditToDollars(additionalCreditsNeeded);
			    taxes = (totalAmount * .08875);
			    totalAmountWithTax = (parseFloat(totalAmount) + parseFloat(taxes)).toFixed(2);
		    }
		    
		    creditRemaining = creditAvail - creditsNeeded;
		    if(creditRemaining < 0) creditRemaining = 0;
		    
		    this.setState({planCredits: dataCredits, creditAvail: creditAvail, creditsNeeded: creditsNeeded, additionalCreditsNeeded: additionalCreditsNeeded, totalAmount: totalAmount, creditRemaining: creditRemaining, taxes: taxes, totalAmountWithTax: totalAmountWithTax});
		    
	    }
		
	}
	
		
	
	_requestDataExportCost = () => {
		
	  if(this.state.getCostInProgress) return null;
	  
	  this.setState({getCostInProgress: true});
	  
	  const costFacets = {
		  	"we3-properties" : { "by_status": {
									"type" : "terms",
									"field" : "current_status"
									}
							   },
			"we3-buildings" :  { "by_units": {
									"range": {
										"field": "inventory_total",
										"start": 0,
										"end": 1000,
										"gap": "+50"
										}
									}
								}

	  }
	  
	  const { user, core_name, q, fq, fl, sort, formValues } = this.props;
	  let userData = {
			url: apiUrl+'/dataexport/get-cost',
			method: "post",
			query: {
				user: user,
				core_name: core_name,
				export_name: formValues && formValues.exportName,
				options : {
					q: q,
					fq: fq,
					fl: fl,
					sort : sort
				}
			}
	  };
	  
	  if(costFacets[core_name]){
		  userData.query.options["json.facet"] = costFacets[core_name];
	  }
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      this.setState({getCostInProgress: false})
	      if(res && res.success){	
		        
				this.setState({costDetails: res, getCostInProgress: false})
					      
		  }else{
				this.setState({costDetails: null, getCostInProgress: false})	  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
  }
  
  _getSubscriptionDetails = () => {
  	  const { user } = this.props;
	  const { subscriptionId } = user;
	  
	  
	  if(this.props.user.role.indexOf('admin')>=0){
		   this.setState({
			  customer: this.props.user,
		      subscription: {},
		      paymentMethod: {},
		      userCreditUsed: 0
	      });
	      return false;
		   
	  }
	  
	  if(!subscriptionId) return false;
	  /**User without a subscripton - prepaid or custom plan**/
	  if(user.downloadAccess === 'manual'){
		  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
		  var firstDay = Math.floor(new Date(y, m, 1).getTime() / 1000);
		  var lastDay = Math.floor(new Date(y, m + 1, 0).getTime() / 1000);
		  const subscription = {
			  current_period_start:firstDay,
			  current_period_end:lastDay
		  }
		  this._getUserCredits(subscription);
		  this.setState({
			  customer: this.props.user,
		      subscription: {},
		      paymentMethod: {},
		      customSubscription: true
	      });
	      
	      return false;
	  }
	   /**User without a subscripton - prepaid or custom plan**/
	   
	  let userData = {
			url: apiUrl+'/buyerslist/stripe/get-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.customer){	
		      this._getUserCredits(res.subscription);
		      this.setState(
			      {
				      customer: res.customer,
				      subscription: res.subscription,
				      paymentMethod: res.paymentMethod
			      }
		      )
		  }else{
			  this.setState({
				  customer: this.props.user,
			      subscription: {},
			      paymentMethod: {}
		      });		  
						  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	    this.setState({
			  customer: this.props.user,
		      subscription: {},
		      paymentMethod: {}
	      });
	  });
	
	
  }
  
  _getUserCredits = (subscription) => {
		
	  const { id } = this.props.user;
	  let userData = {
			url: apiUrl+'/dataexport/analytics/aggregate',
			method: "post",
			query: [
				    {
				        "$match": {
				            "$and" :[
				                { "user_key" : id },
				                { "created_timestamp_unix" : {
				                    "$gte" : subscription.current_period_start,
				                    "$lt" : subscription.current_period_end
				                }}
				            ]           
				        }
				    },
				    {
				        "$group" : {
				            "_id" : null,
				            "total" : {
				                "$sum" : "$credits"
				            }
				        }
				    }
				]
			
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res && res.length>0 && res[0].total){	
	        this.setState({userCreditUsed:res[0].total})	      
		  }else{
			this.setState({userCreditUsed:0})		  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
  }
	
  _getUpcomingInvoice = () => {
	  if(this.state.getInvoiceProgress) return null;
	  
	  this.setState({getInvoiceProgress: true})

	  const { subscriptionId } = this.props.user;
	 console.log(subscriptionId) 
	  if(!subscriptionId) return false;
	
	  let userData = {
			url: apiUrl+'/buyerslist/stripe/get-upcoming-invoice',
			method: "post",
			query: {
				subscription_id: subscriptionId
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res){	
		     this.setState({invoiceDetails: res, getInvoiceProgress: false})
		  }else{
			 this.setState({invoiceDetails: null, getInvoiceProgress: false}) 		  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
	}
	
	
	_doPayment = () => {
		
	  if(this.state.doPaymentProgress) return null;
	  
	  this.setState({doPaymentProgress: true});
	  
	  const { customer, subscription, paymentMethod, additionalCreditsNeeded, totalAmount, taxes, totalAmountWithTax } = this.state;
	  
	  let description = `Purchase of ${_formatNumber(additionalCreditsNeeded)} Marketproof Data Credits ($${costPerCredit} per credit) + NYC Sales Tax`;
	  
	  const stripeAmount = parseInt(parseFloat(totalAmountWithTax) * 100);
	  let userData = {
			url: apiUrl+'/buyerslist/stripe/onetime',
			method: "post",
			query: {
				customer : customer.id,
				amount : stripeAmount,
				payment_method :paymentMethod.id,
				description: description
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      this.setState({doPaymentProgress: false});
	      if(res && res.amount_received == stripeAmount){	
				this._requestDataExport(res);
					      
		  }else{
				this.snackOpen('Oops, something went wrong, your data export was not successful.', 'error');	  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
  }

  
  _requestDataExport = (payment) => {
	  
	  const { user, core_name, q, fq, fl, sort, formValues } = this.props;
	  
	  const {creditsNeeded, customer, subscription, paymentMethod, additionalCreditsNeeded, totalAmount, taxes, totalAmountWithTax } = this.state;
	   
	  let userData = {
			url: apiUrl+'/dataexport',
			method: "post",
			query: {
				user: user,
				core_name: core_name,
				export_name: formValues && formValues.exportName,
				credits: creditsNeeded,
				credits_paid: additionalCreditsNeeded,
				payment_id: payment ? payment.id : 'credit',
				payment_taxes: parseFloat(taxes),
				payment_amount: parseFloat(totalAmountWithTax),
				options : {
					q: q,
					fq: fq,
					fl: fl,
					sort : sort,
					rows: maxDownload(core_name, user)
				}
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.success){	
				this.snackOpen(<Fragment><span>Your data export request was successful! <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>You will recevied an email when your data export is ready.</p></span></Fragment>);
				this.setState({modal:false});
					      
		  }else{
			 if(res && res.message){
				this.snackOpen(<Fragment><span>Oops, your data export was not successful. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your data export was not successful.', 'error');
			 }		  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
  }
  
  toggle() {
	const { buildingRes, property } = this.props;
	
	
    this.setState(prevState => ({ modal: !prevState.modal }));
   
  }
   modalClass = classNames({
      'modal-dialog--colored': false,
      'modal-dialog--header': true,
   });
   
   setSubscribe = (subscribe) => {
	   this.setState({subscribe: subscribe});
   }
  

  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, listing, title, description, export_name
    } = this.props;
    
    const { modal,  snackShow, snackMessage, snackType, costDetails, invoiceDetails, customer, subscription, paymentMethod, planCredits, creditAvail, creditsNeeded, additionalCreditsNeeded, totalAmount, creditRemaining, taxes, totalAmountWithTax, doPaymentProgress, userCreditUsed, subscribe, customSubscription} = this.state;
    
    if(!user || !user.subscriptionPlan || (user.downloadAccess && user.downloadAccess == "disabled")) return null;
    
    if(user.subscriptionStatus==='trialing'){
	    return(
		    <Fragment>
		    	<Tooltip title={title ? title : `Request Data Export`}>
			        <IconButton
			        	color="inherit"
				        onClick={() => {this.setSubscribe(true)}}
			        >	<ExportIcon />
				     </IconButton>				 
			  	</Tooltip>
		    	<TrialSubscribe subscribe={subscribe} setSubscribe={this.setSubscribe} dataDownload />
		    </Fragment>
	    )
    }
    
       
/*
    let creditsNeeded = 0;
    let additionalCreditsNeeded = 0;
    let totalAmount = 0;
    let creditRemaining = 0;
    let taxes = 0;
    let totalAmountWithTax = 0;
    
    if(costDetails!==false && customer!==false){
	    creditsNeeded = calculateCredits(costDetails.core_name, costDetails.rows);	    
	    additionalCreditsNeeded = creditsNeeded - creditAvail;
	    if(additionalCreditsNeeded < 0) additionalCreditsNeeded = 0;
	    
	    if(additionalCreditsNeeded>0){
		    totalAmount = translateCreditToDollars(additionalCreditsNeeded);
		    taxes = (totalAmount * .08875);
		    totalAmountWithTax = (parseFloat(totalAmount) + parseFloat(taxes)).toFixed(2);
	    }
	    
	    creditRemaining = creditAvail - creditsNeeded;
	    if(creditRemaining < 0) creditRemaining = 0;
	    
    }
*/
  
    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
		    <Tooltip title={title ? title : `Request Data Export`}>
		        <IconButton
		        	color="inherit"
			        onClick={this.toggle}
		        >	<ExportIcon />
			     </IconButton>				 
		  	</Tooltip>
		  	
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`ltr-support`}
			      className={`modal-dialog--primary ${this.modalClass}`}
		        >
		           <div className="modal__header">
			        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
			        <h4 className="text-modal  modal__title">{title ? title : 'Data Export'}</h4>
			      </div>	
			      
			      
				  <div className="modal__body background_white">
			       
			
				   {(costDetails===false || customer===false || creditsNeeded===false) ?
				      <LinearLoading />
				      :
				      
				      <Fragment>
				      {costDetails.rows > maxDownload(costDetails.core_name, user) ?
					   <div>					   
					   	{description && <p style={{fontSize:15}}>{description}</p>}
						   <div style={{fontSize:16,background:"#F5F7FA",padding:10,marginTop:20,border:'1px solid #780F9E'}}>
						    <h4 className={"semi-bold-text mb-2"}>Download Limit Exceeded</h4>
					       	Export contains <span className="semi-bold-text">{_formatNumber(costDetails.rows)}</span> records which exceeds the <span className="semi-bold-text">{_formatNumber(maxDownload(costDetails.core_name, user))}</span> maxmium {coreTranslate[costDetails.core_name]} records allowed for each download.<br/><br/>
					       	  Please narrow your search to reduce the total number of records in your dataset.
					       	  <div className="text-right mt-3">
					       	  <a href="https://help.marketproof.com/en/articles/4857038-guide-to-exporting-data-on-marketproof-new-development" className="link-text small-text" target="_blank">Learn More <HelpIcon size={12} style={{marginTop:-3}} /></a>
					       	  </div>
						   </div> 
						   <hr/>
						   <ButtonToolbar className="modal__footer" style={{padding:0}}>
				            <Tooltip title="Cancel">
						        <Button
							         onClick={this.toggle}
							         variant="outlined"
							         color=""
							         className=""		         
							         startIcon={null}
							         style={{marginRight:10}}
							     >	Cancel
							     </Button>				 
						  	</Tooltip>{' '}
				            <Tooltip title="Contact Us">
						        <Button
							         variant="contained"
							         color="primary"
							         className="buyersList__primary-button intercom_launch"		         
							         startIcon={<ContactIcon />}
							     >	Contact Us
							     </Button>				 
						  	</Tooltip>
				         </ButtonToolbar>
					   </div>
				      
				      :
				      <div>				      	
				      	<p style={{fontSize:15}}>
				       	{description ? description : `Please confirm your data download request.`}<br/>  <br/>  
				       	This request will export <span className="semi-bold-text">{_formatNumber(costDetails.rows)} {coreTranslate[costDetails.core_name]}</span> record{costDetails.rows > 1 ? 's' : ''}
				       	{(coreTranslate[costDetails.core_name] == "unit" 
					       	&& costDetails.facets.by_status 
					       	&& costDetails.facets.by_status.buckets 
					       	&& costDetails.facets.by_status.buckets.filter(bucket => bucket.val === "shadow" && bucket.count>0).length > 0) 
					       	&&
					       	<Fragment>{` `}including{` `} 
					       		<span className="semi-bold-text">
					       		{_formatNumber(costDetails.facets.by_status.buckets.filter(bucket => bucket.val === "shadow" && bucket.count>0)[0].count)} shadow units
					       		</span>
					       	</Fragment>
				       	}.
					   </p>
					   {/*coreTranslate[costDetails.core_name] == "building" ?
					       	<div style={{fontSize:13,background:'#FCFCFC',padding:10,marginTop:20,border:'1px solid #A3ADC2'}}> 
					       		<h5 className="bold-text mb-1">{_capitalizeText(coreTranslate[costDetails.core_name])} Data Credits</h5>
					       		<strong><strong>1 credit</strong> = {dataExportCreditMap[coreTranslate[costDetails.core_name]]} {coreTranslate[costDetails.core_name]} record{dataExportCreditMap[coreTranslate[costDetails.core_name]] > 1 ? 's' : ''}</strong> for buildings of 50 units or less.<br/>1 credit is added for each additional 50 units.  For example, a 100 unit building reqires 2 credits and a 200 unit building requires 4 credits.
					       	</div>
					    : coreTranslate[costDetails.core_name] == "unit" ?
					       	<div style={{fontSize:13,background:'#FCFCFC',padding:10,marginTop:20,border:'1px solid #A3ADC2'}}> 
					       		<h5 className="bold-text mb-1">{_capitalizeText(coreTranslate[costDetails.core_name])} Data Credits</h5>
					       		<strong><strong>1 credit</strong> = {dataExportCreditMap[coreTranslate[costDetails.core_name]]} {coreTranslate[costDetails.core_name]} record{dataExportCreditMap[coreTranslate[costDetails.core_name]] > 1 ? 's' : ''}</strong>
					       		{(costDetails.facets.by_status 
							   		&& costDetails.facets.by_status.buckets 
							   		&& costDetails.facets.by_status.buckets.filter(bucket => bucket.val === "shadow" && bucket.count>0).length > 0) &&
							   		<Fragment><br/>Each shadow unit counts as 5 unit records.  For example, 15 shadow units will use 5 credits and 15 non-shadow units will use 1 credit.</Fragment>
					       		}
					       	</div>
					    : <div style={{fontSize:13,background:'#FCFCFC',padding:10,marginTop:20,border:'1px solid #A3ADC2'}}> 
					    		<h5 className="bold-text mb-1">{_capitalizeText(coreTranslate[costDetails.core_name])} Data Credits</h5>
					       		<strong><strong>1 credit</strong> = {dataExportCreditMap[coreTranslate[costDetails.core_name]]} {coreTranslate[costDetails.core_name]} record{dataExportCreditMap[coreTranslate[costDetails.core_name]] > 1 ? 's' : ''}</strong>
					      </div>
					       	

				       */}
					   <hr/>
					   <div className={"mb-2"}><h4 className="semi-bold-text" style={{fontSize:16,display:"inline-block"}}>Confirm Data Export</h4> <a href="https://help.marketproof.com/en/articles/4857038-guide-to-exporting-data-on-marketproof-new-development" className="link-text float-right small-text" target="_blank">Learn More <HelpIcon size={12} style={{marginTop:-3}} /></a></div>
					   <p style={{fontSize:15}} className={"mb-3"}>
					   	
					   	<DataCreditUsageProgress credit_total={planCredits} credit_used={userCreditUsed} />		   
					   </p>
					   
					   {customSubscription && additionalCreditsNeeded > 0 ?
				        	<div className="data-credit-details"> 
				        		<div className="charge-amount"> 
				        		<div style={{fontSize:16,background:"#F5F7FA",padding:10,marginTop:20,border:'1px solid #780F9E'}}>
				        			<h4 className={"semi-bold-text mb-2"}>Data Credits Exceeded</h4>
				        			This export requires <span className="semi-bold-text">{_formatNumber(creditsNeeded)} credits</span> which exceed your available Data Credits.  Please reduce the number of records in your search or contact us to increase your Data Credit limit.
								</div>
								</div>
								<ButtonToolbar className="modal__footer" style={{padding:0}}>
						            <Tooltip title="Cancel">
								        <Button
									         onClick={this.toggle}
									         variant="outlined"
									         color=""
									         className=""		         
									         startIcon={null}
									         style={{marginRight:10}}
									     >	Cancel
									     </Button>				 
								  	</Tooltip>{' '}
						            <Tooltip title="Contact Us">
								        <Button
									         variant="contained"
									         color="primary"
									         className="buyersList__primary-button intercom_launch"		         
									         startIcon={<ContactIcon />}
									     >	Contact Us
									     </Button>				 
								  	</Tooltip>
						         </ButtonToolbar>
				        	</div>
							        	
						:
						<Fragment>
							   <div style={{display:"flex"}}>   				      	
									<div className="pricing" style={{padding:'22px 10px', border:"1px solid #A3ADC2", borderRadius:"10px", textAlign:"center", marginRight:20,height:120}}>							 
							          <span className="small-text light-text">Needed for Export</span>					          		          
							          <h3 className="pricing-plan" style={{color:'#780F9E'}}>{_formatNumber(creditsNeeded)}</h3>
							          <p className="pricing-plan mt-0">DATA CREDITS</p>		
							          	
							          				            			                   				          
							        </div>
							        {customSubscription ?
								        <Fragment>
								        	
									        	<div className="data-credit-details">  
													<div className="charge-amount">
														<div className="checkout-line">Current Credit Balance <strong>{creditAvail} Credits</strong></div>
														<div className="checkout-line">Credits Needed <strong>-{creditsNeeded ? _formatNumber(creditsNeeded) : '0'}  Credits</strong></div>
														<div className="total-payment">Credit Balance After Export <strong>{creditRemaining}  Credits</strong></div>
													</div>
												</div>
		
								        </Fragment>
								        
								        
								    :
									<div className="data-credit-details">  
										<div className="charge-amount">
								        	<div className="checkout-line">Current Credit Balance <strong>{creditAvail} Credits</strong></div>
								        	{additionalCreditsNeeded > 0 ?
								            	<div className="checkout-line">Additional Credits Needed <strong>{additionalCreditsNeeded ? _formatNumber(additionalCreditsNeeded) : '0'}  Credits</strong>
								            		{/*<div className="charge-text mt-0">{dataExportCreditMap[coreTranslate[costDetails.core_name]]} {coreTranslate[costDetails.core_name]} record(s) = 1 credit</div>*/}
								            	</div>
								            	:
								            	<div className="checkout-line">Credits Needed <strong>-{creditsNeeded ? _formatNumber(creditsNeeded) : '0'}  Credits</strong>
								            		{/*<div className="charge-text mt-0">{dataExportCreditMap[coreTranslate[costDetails.core_name]]} {coreTranslate[costDetails.core_name]} record(s) = 1 credit</div>*/}
								            	</div>
								            }
								            
								            {creditRemaining > 0 ?
									            <div className="checkout-line">Credit Balance After Export <strong>{creditRemaining}  Credits</strong></div>
									            :
								            	<div className="checkout-line">Purchase Credits <span className="small-text light-text">({_formatNumber(additionalCreditsNeeded)} x ${costPerCredit} per credit)</span>  <strong>${totalAmount ? _formatNumber(totalAmount) : '0'}</strong></div>
								            }
								            {taxes > 0 &&
									            <div className="checkout-line">NYC Sales Tax  <strong>${parseFloat(taxes).toFixed(2)}</strong></div>
								            }
								        	<div className="total-payment">{`Total Amount Due`} 
								        		{additionalCreditsNeeded > 0 && <span className="small-text light-text"></span>}
								        	 <strong>${totalAmountWithTax ? _formatNumberDecimal(totalAmountWithTax) : '0'}</strong></div>
								        </div>
								        {totalAmount > 0 && <span className="charge-text">
							        		Total amount will be charged to your credit card on file.
							        	</span>}
									</div>
									}
								 </div>
						      	<hr/>
							  	<DataExportForm user={user} toggle={this.toggle} handleSubmit={this.handleSubmit} export_name={export_name} 
							  		btnText={totalAmountWithTax > 0 ? "Pay & Export" : "Export"}/>
					  		</Fragment>
					  	}
					  		
					    <div className={"modal-overlay"} style={{display: doPaymentProgress ? 'block' : 'none'}}>
					    	<LinearLoading />
					    	<h4>Payment In Progress</h4>
					    	<LockIcon size={48} color={'#9600BF'} />
					    </div>
				      </div>
				      }
				     </Fragment> 
			      }
				    
				   			
				          
			       </div>
			       
			       
			        
			     {/*  <ButtonToolbar className="modal__footer">
				        <Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}
				        <Button className="modal_ok" type="button" color={'primary'} onClick={this._requestDataExport}>
				        	<ExportIcon style={{marginTop:-3}} /> Export
				        </Button>
				    </ButtonToolbar>	*/}					 
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("export_form")(state) 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ExportButton));
