import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import CircleSlice4Icon from 'mdi-react/CircleSlice4Icon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 

import{
	_capitalizeText,
} from '../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../shared/helpers';


  


const calculateBarData = (buildingRes) => {	
	
	return [{ value: 6, fill: getDefaultChartColors[2] },
  				{ value: 9, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}



const PercentStat = (props, { t }) => (
    <Card>
      <CardBody className="dashboard__health-chart-card">
        <div className="card__title">
          <h5 className="bold-text card__title-center">{`Listed vs. Shadow`}</h5>
        </div>
        <div className="dashboard__health-chart"> 
          <ResponsiveContainer height={180}>
            <PieChart>
              <Pie data={calculateBarData(props.buildingRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
            </PieChart>
          </ResponsiveContainer>
          <div className="dashboard__health-chart-info">
            <CircleSlice4Icon style={{ fill: getDefaultChartColors[2] }} />
            <p className="dashboard__health-chart-number">{parseFloat(93).toFixed(1)}<span className="small-text">%</span></p>
            <p className="dashboard__health-chart-units"> units listed</p>
          </div>
        </div>
        <p className="dashboard__goal"> Units Never Listed</p>
      </CardBody>
    </Card>
);

PercentStat.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PercentStat);
