const buyersListActions = {
  addMyBuyersList(key, units, user) {
    return {
      type: 'ADD_TODO',
      key,
      units,
      user
    };
  },
  saveProject(key, user) {
    return {
      type: 'SAVE_PROJECT',
      key,
      user
    };
  },
  saveOrganization(key, user) {
    return {
      type: 'SAVE_ORGANIZATION',
      key,
      user
    };
  },
  saveContact(key, user) {
    return {
      type: 'SAVE_CONTACT',
      key,
      user
    };
  },
  viewBuilding(key, user) {
    return {
      type: 'VIEW_BUILDING',
      key,
      user
    };
  },
  setUserData(user) {
    return {
      type: 'SET_DATA',
      user
    };
  },
  completeTodo(id) {
    return {
      type: 'COMPLETE_TODO',
      id,
    };
  },
  deleteTodo(id) {
    return {
      type: 'DELETE_TODO',
      id,
    };
  },
  togglePriorityFilter(priorityFilter) {
    return {
      type: 'PRIORITY_FILTER',
      priorityFilter,
    };
  },
};
export default buyersListActions;
