import {
	_capitalizeText,
	_formatListingHeadline,
	_formatUnitDisplay,
	_nameToUrl,
} from "./utils";

export const _getUnitDisplay = (listing, type) => {
	// console.log('_getUnitDisplay listing', listing, 'type', type)
	let unit = false;
	
	if(type === "building") {
    unit = _formatUnitDisplay(listing.address_2 ? listing.address_2 : listing.address_2_normalized.replace("apt", ""))
	}

  return unit;
}

export const _getListingAdr = (listing) => {
	const adr = listing.display_full_street_address
    ? _capitalizeText(listing.display_full_street_address).trim()
    : listing.display_name && listing.display_name.match(/^\d/)
      ? _capitalizeText(
          _formatListingHeadline(listing.display_name.indexOf(',')>0 ? listing.display_name.substring(0, listing.display_name.indexOf(',')) : listing.display_name)
        ).trim()
      
      : listing.full_street_address
        ? _capitalizeText(listing.full_street_address).trim()
        : listing.display_name
          ? _formatListingHeadline(listing.display_name)
          : false;

	return adr;
}

export const _getListingTagInfo = (type, listing, adr, unit) => {
	if(!listing){return false;}

	const listingTag = {
    building_slug: listing.building_slugs && listing.building_slugs[0] 
      ? listing.building_slugs[0] 
      : listing.building
        ? listing.building.substr(4)
        : false,
    building_display:
      type === "building"
        ? undefined
        : adr,
    display_adr: adr,
    display: unit,
    unit_slug:
      type === "building"
        ? undefined
        : listing.unit_slug 
          ? listing.unit_slug 
          :  listing.address_2_normalized
            ? _nameToUrl(listing.address_2_normalized.replace("/", "_").replace("apt", "unit"))
            : undefined,
  };

  return listingTag;
}

export const _getListingUnitTag = (listingTagInfo, type) => {
	// console.log('_getListingUnitTag listingTagInfo', listingTagInfo, 'type', type)
  if(!listingTagInfo) {return false;}
  
  let unitObj = undefined;  
    
  if(type === 'unit') {
  	unitObj = {
      building_slug: listingTagInfo.building_slug
        ? listingTagInfo.building_slug
        : false,
      unit_slug: listingTagInfo.unit_slug
        ? listingTagInfo.unit_slug
        : false,
      display: listingTagInfo.display
        ? listingTagInfo.display
        : false
    };
  }

  return unitObj;
}

export const _getUrlObj = (listing, listingTag, hood) => {
	if(!listing) {return false;}
	const urlObj = {
    hood: hood ? hood : "nyc",
    borough: listing.borough ? listing.borough : "new york",
    building_slug: listingTag && listingTag.building_slug
			? listingTag.building_slug
			: false,
		unit_slug: listingTag && listingTag.unit_slug
			? listingTag.unit_slug
			: false,
    propertyListingId: listing.key ? listing.key : listing && listing.data && listing.data.key ? listing.data.key : false,
	};

	return urlObj;
}
