import React, { Component, Fragment } from "react";
import { Col, Container, Row, Modal, ButtonToolbar } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { RTLProps, ThemeProps } from "../../../shared/prop-types/ReducerProps";
import buyersListActions from "../../../redux/actions/buyersListActions";
import { Button } from "reactstrap";
import classNames from 'classnames';
import { withRouter, Link } from "react-router-dom";
import ContactIcon from 'mdi-react/ForumOutlineIcon';
import { setUserProfile } from '../../../redux/actions/authActions';

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import HeartIcon from "mdi-react/HeartIcon";
import TrashCanIcon from "mdi-react/TrashCanOutlineIcon";
import GetContactIcon from "mdi-react/AccountPlusIcon";
import ContactUnlockedIcon from "mdi-react/AccountCheckIcon";

import { Publisher } from "./../../pubsub/publisher.js";
import { _axiosCall } from "../../../shared/helpers/apicalls";
import SnackBar from '../SimpleSnackBar';
import LinearLoading from '../LinearLoading';

import moment from "moment";
import { getCreditsAllowed, DataCreditUsageProgress } from './../payment/plans.js';

const apiURL = process.env.API_ROOT;

const _hasContactInfo = (contactRes) => {
  
  return (contactRes.email && contactRes.email.length > 0) ||
      (contactRes.email_addresses &&
      contactRes.email_addresses.length) ||
      (contactRes.phone &&
        contactRes.phone.length > 0) ||
      (contactRes.phone_numbers &&
      contactRes.phone_numbers.length)
  
}

class ViewContactButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    projectKey: PropTypes.string,
    units: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      isUnlocked: false,
      modal: false,
      usage:null,
      snackShow: false,
      snackMessage: false,
      snackType: "success"
    };
    this.toggle = this.toggle.bind(this);
    this._handleShow = this._handleShow.bind(this);
    this.snackOpen = this.snackOpen.bind(this);
    this.snackClose = this.snackClose.bind(this);
    this.showContactCheck = this.showContactCheck.bind(this);
  }
  
  

  componentDidMount() {
    const { contact, setShow, show, contactsShown } = this.props;
    const isUnlocked =
      contactsShown &&
      Array.isArray(contactsShown) &&
      contactsShown.filter((c) => c._id["contact"] == contact.key).length > 0;

    if (isUnlocked) {
      setShow(true);
      this.setState({ isUnlocked: isUnlocked });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contact, setShow, show, contactsShown, user } = this.props;
    if (this.props.contactsShown.length != prevProps.contactsShown.length) {
      const isUnlocked =
        contactsShown &&
        Array.isArray(contactsShown) &&
        contactsShown.filter((c) => c._id["contact"] == contact.key).length > 0;

      if (isUnlocked) {
        setShow(true);
        this.setState({ isUnlocked: isUnlocked });
      }
    }
    if(prevState.modal !== this.state.modal){
      if(this.state.modal){
        this._getUserEvents(user)
      }
    }
  }

  toggle(event) {
    event.stopPropagation();
      
    
    this.setState(prevState => ({ modal: !prevState.modal }));
   
  }
  showContactCheck(event){
    event.stopPropagation();
    const { user } = this.props;
    this._getUserEvents(user);
  }
  snackOpen = (message, type) => {
    this.setState({ snackShow: true, snackMessage: message, snackType: type })
  }
  
  snackClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    this.setState({ snackShow: false, snackMessage: "" })
  }
  _getUserEvents = async (user) => {
    const creditsAllowed = getCreditsAllowed(user);
    
    const dayOfMonth = user.pipelineViewResetDayOfMonth ? user.pipelineViewResetDayOfMonth : 1;
    var date = new Date(), d = date.getDate(), y = date.getFullYear(), m = date.getMonth();
    if(dayOfMonth > d){
      m--;
    }
    var firstDay = Math.floor(new Date(y, m, dayOfMonth).getTime() / 1000);
    var lastDay = Math.floor(new Date(y, m, dayOfMonth-1).getTime() / 1000);
    let dateQuery = {
      "$gte": moment.unix(firstDay).format('YYYY-MM-DD'), "$lt" : moment.unix(lastDay).add(1, 'month').format('YYYY-MM-DD')
    }
   
    let mongoQuery = [{
        "$addFields": {
          "formattedDate": {
            "$dateToString": {
              "format": "%Y-%m-%d",
              "date": "$created_timestamp"
            }
          }
        }
      },
      {
        "$match": {
          "formattedDate": dateQuery,
          "event_type": {"$in" : ["showContactInfo", "showCompanyInfo"]},
          "user_key" : user.id
        }
      },
      {
        "$facet": {
          "shown": [{
              "$group": {
                "_id": null,
                "count": {
                  "$sum": 1
                }
              }
    
            }
          ]
    
        }
      }
    
    ]	 
    let axiosData = {
      url: `${process.env.API_ROOT}/buyerslist/event/aggregate`,
      method: "post",
      query: mongoQuery,
    };
  
    let response = await _axiosCall(axiosData).then(res => {
       let usage = 0;
       if(res && res[0] && res[0].shown && res[0].shown[0] && res[0].shown[0]['count']){
         usage = res[0].shown[0]['count'];
         //this.setState({usage: 100})
       }
        if(creditsAllowed <= usage){
          this.setState(prevState => ({usage:usage, modal: true }));
        }else{
          this.setState(prevState => ({usage:usage }));
          this._handleShow();
          
        }
    });
  
    return response;
  };
  
  _handleShow() {
    
    

    const { contact, setShow, show, user, companyCard, contactsShown, dispatch} = this.props;
    const { modal, isUnlocked, snackShow, snackMessage, snackType, usage } = this.state;       
    const creditsAllowed = getCreditsAllowed(user);
    if(companyCard){
      Publisher.publish(`track-mp.showCompanyInfo.card`, {
        user: user,
        contact: contact,
      });
    }else{
      Publisher.publish(`track-mp.showContactInfo.card`, {
        user: user,
        contact: contact,
      });
    }
   

    setShow(true);
    this.snackOpen(`Contact Info Unlocked! ${(creditsAllowed-(usage+1))} Tokens Remaining This Period`);
    let contactsShownUpdated = contactsShown.slice();
    contactsShownUpdated.push({"_id" : { "contact" : contact.key }})
    let combinedUser = Object.assign({}, user, {"contactsShown" : contactsShownUpdated})
    dispatch(setUserProfile(combinedUser));
    dispatch(buyersListActions.setUserData(combinedUser));
    //this.toggle(event);
  }
  modalClass = classNames({
      'modal-dialog--colored': false,
      'modal-dialog--header': true,
   });
  render() {
    const { contact, setShow, show, contactsShown, companyCard, user } = this.props;

    const { modal, isUnlocked, snackShow, snackMessage, snackType, usage } = this.state;

    if (contactsShown === null) return false;
    
    if(contact && !_hasContactInfo(contact)) return null;
    
    const creditsAllowed = getCreditsAllowed(user);

    if (isUnlocked || show) {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />
          <Button
            className={
              this.props.tableView
                ? "contact_card_table_btn"
                : "contact_card_button"
            }
            onClick={(e) => {
              this._handleShow(e);
            }}
            disabled
          >
            <ContactUnlockedIcon size={16} style={{ marginTop: -2 }} /> Added to My Contacts
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <SnackBar
            snackShow={snackShow}
            snackMessage={snackMessage}
            snackClose={this.snackClose}
            snackDuration={5000}
            snackType={snackType}
          />
          <Button
            className={
              this.props.tableView
                ? "contact_card_table_btn"
                : "contact_card_button"
            }
            onClick={(e) => {
              this.showContactCheck(e);
            }}
          >
            <GetContactIcon size={16} style={{ marginTop: -2 }} /> Get Contact
            Info
          </Button>
        
          <Modal
            isOpen={modal}
            toggle={this.toggle}
            modalClassName={`ltr-support`}
            className={`modal-dialog--primary ${this.modalClass}`}
          >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <h4 className="text-modal  modal__title">{creditsAllowed <= usage ? "Contact Unlock Limit Exceeded" : "Get Contact Information"}</h4>
          </div>	
          
          
          <div className="modal__body background_white">
            {(usage===null) ?
              <LinearLoading />
               :
               creditsAllowed <= usage ?
               <Fragment>
                <div>					   
                  <div style={{fontSize:15}} className={"mb-3"}>
                      <DataCreditUsageProgress credit_total={creditsAllowed} credit_used={usage} />		   
                   </div>
                  <div style={{fontSize:16,background:"#F5F7FA",padding:10,marginTop:20,border:'1px solid #780F9E'}}>
                    You have exceed the number of tokens required to unlock this contact.  Your tokens reset on the first of each month.
                    
                    Please contact us to upgrade your plan.
                  </div>
                  <hr/>
                  <ButtonToolbar className="modal__footer" style={{padding:0}}>
                       <Tooltip title="Cancel">
                       <Button
                          onClick={this.toggle}
                          variant="outlined"
                          color=""
                          className=""		         
                          startIcon={null}
                          style={{marginRight:10}}
                      >	Cancel
                      </Button>				 
                   </Tooltip>{' '}
                       <Tooltip title="Contact Us">
                       <Button
                          variant="contained"
                          color="primary"
                          className="buyersList__primary-button intercom_launch"		         
                          startIcon={<ContactIcon />}
                      >	Contact Us
                      </Button>				 
                   </Tooltip>
                    </ButtonToolbar>
                </div>
                </Fragment>
                :
                <Fragment>
                   <div style={{fontSize:16,background:"#F5F7FA",padding:10,marginTop:20,border:'1px solid #780F9E'}}>
                     Unlocking contact information requires <strong>1 credit per contact</strong>.  
                     Once unlocked, the contact info will remain unlocked.
                   </div>
                   <hr/>
                   <div style={{fontSize:15}} className={"mb-3"}>
                      <DataCreditUsageProgress credit_total={creditsAllowed} credit_used={usage} />		   
                   </div>
                   
                    <div className="data-credit-details">  
                       <div className="charge-amount">
                         <div className="checkout-line">Current Monthly Credit Balance <strong>{(creditsAllowed-usage)} Credits</strong></div>
                         <div className="checkout-line">Credits Needed <strong>{usage}  Credits</strong></div>
                         <div className="total-payment">Credit Balance After <strong>{((creditsAllowed-usage)-1)}  Credits</strong></div>
                       </div>
                   </div>
                  <hr/>
                   <ButtonToolbar className="modal__footer" style={{padding:0}}>
                        <Tooltip title="Cancel">
                        <Button
                           onClick={this.toggle}
                           variant="outlined"
                           color=""
                           className=""		         
                           startIcon={null}
                           style={{marginRight:10}}
                       >	Cancel
                       </Button>				 
                    </Tooltip>{' '}
                        <Tooltip title="Get Contact Information">
                        <Button
                           onClick={(e) => this._handleShow(e)}
                           variant="contained"
                           color="primary"
                           className="buyersList__primary-button intercom_launch"		         
                           startIcon={<GetContactIcon />}
                       >	Get Contact Info
                       </Button>				 
                    </Tooltip>
                     </ButtonToolbar>
              </Fragment>
          }
          </div>
          </Modal>
          </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(
        (item) => item.priority === state.buyersLists.priorityFilter
      )
    : state.buyersLists.buyersLists;
  return {
    buyersLists,
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
    savedProjects: Array.isArray(state.buyersLists.savedProjects)
      ? state.buyersLists.savedProjects.slice()
      : [],
    savedOrganizations: Array.isArray(state.buyersLists.savedOrganizations)
      ? state.buyersLists.savedOrganizations.slice()
      : [],
    savedContacts: Array.isArray(state.buyersLists.savedContacts)
      ? state.buyersLists.savedContacts.slice()
      : [],
    contactsShown: Array.isArray(state.user.contactsShown)
      ? state.user.contactsShown.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch), dispatch: dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ViewContactButton));
