import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Badge, Progress } from 'reactstrap';
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import { connect } from 'react-redux';

import EditIcon from 'mdi-react/PencilIcon';


import classNames from 'classnames';
import todoCard from '../types';
import BuyersListCount from './BuyersListCount';
import moment from "moment";


import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_shouldShow,
	_getBuildingURL,
	_getBuildingURLBase,
	BuildingCurrentStatus,
	BuildingPhoto,
	SalesProgress,
	StatusBadge,
	_urlToName
} from '../../../../../../../shared/helpers/utils';

const UnitsAvailable = ({ item }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': item.priority === 'medium',
    'dashboard__table-orders-amount--low': item.priority === 'low',
    'dashboard__table-orders-amount--high': item.priority === 'high',
  });
  if (item.priority === 'high') {
    return (
	  <div className="dashboard__stat-item"  >
        <div className="dashboard__stat">
          <div className="progress-wrap progress-wrap--small progress-wrap--purple-gradient progress-wrap--rounded">
            <p className="progress__label">{item.inventory_unsold} Available</p>
		      <div className={amountClass}>
		        <div />
		        <div />
		        <div />
		        <div />
		        <div />
		      </div>
			 </div>
        </div>
      </div>
    );
  } if (item.priority === 'medium') {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
      </div>
    );
  } 
  return (
    <div className={amountClass}>
      <div />
    </div>
  );
};



class BuyersListItem extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editTodo: PropTypes.func,
      completeTodo: PropTypes.func,
      deleteTodo: PropTypes.func,
      updateTodo: PropTypes.func,
    }).isRequired,
    todo: todoCard.isRequired,
  };

  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = {
    
    };
  }

  handleComplete() {
    const { item, actions } = this.props;
    const { checked } = this.state;

    actions.completeTodo(item.id);
    this.setState({
      checked: !checked,
    });
  }

  handleDelete(e) {
	e.stopPropagation(); 
    const { item, actions } = this.props;

    actions.deleteTodo(item.id);
  }

  handleEdit() {
    const { item, actions } = this.props;

    actions.editTodo(item.id);
  }

  handleUpdate() {
    const { item, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updateTodo(item.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, '_id') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  

  render() {
    const {
      completed, priority, listCount,
    } = this.state;
    
    const { item, user, type } = this.props;
    
    
	const buildingNameOrAdr = item.name 
      ? _capitalizeText(item.name)
      : item.display_full_street_address
        ? _capitalizeText(item.display_full_street_address)
        : _capitalizeText(item.full_street_address);
	const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
	
	const _boro = _urlToName(item.address.borough && item.address.borough);
	const _hood = _urlToName(item.address.neighborhood && item.address.neighborhood[0]);
	
	const buildingURLBase = _getBuildingURLBase(item, 'key');

    const priorityColorsClass = classNames({
      'todo__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });

    return (
      <Card>
        <CardBody className="todo__item" onClick={event => this.handleBuildingClick(event, item)}>
          
         {/* <label htmlFor={title} className="todo__label-checkbox">
            <input
              id={title}
              type="checkbox"
              className="todo__complete-toggle"
              defaultChecked={completed}
              required
              onClick={this.handleComplete.bind(this)}
            />
            <span className="checkbox-indicator" />
          </label>*/}
          <Row>
	          <Col sm={12} md={4} xl={4}>
	          	<BuildingPhoto item={item} />
	          </Col>
	          <Col sm={12} md={8} xl={8}>
		          <div className="todo__info">
		            <div className="todo__header">
		            
		              <h3>{buildingNameOrAdr}</h3>
		              <div className="todo__description">{item.name ? _capitalizeText(adr) : ''}{item.name && _boro ? ', ' : ''}{_hood && (_capitalizeText(_hood)+', ')}{_boro && ' ' + _capitalizeText(_boro)} </div>
		             
		              <div className="mt-3">
		            {/*    <p className="todo__due-date">Joined: {moment(item.solr_updated_date).fromNow()}</p>
		                <span className="todo__small-divider" /> */}
		                
		                {/*<div><StatusBadge building={item} /></div>*/}
		              
			          
							<span className="light-text small-text">{item.pipeline_building_stories_proposed > 0 ? `${_formatNumber(item.pipeline_building_stories_proposed)} Floor${item.pipeline_building_stories_proposed>1 ? "s" :""}` : `--`}  </span>				    
							<span className="todo__small-divider">|</span>
							{type === "residential" ?
								
								<span className="light-text small-text">{item.pipeline_dwelling_units_proposed > 0 ? `${_formatNumber(item.pipeline_dwelling_units_proposed)} Units` : `--`}</span>
							:
								<span className="light-text small-text">{item.pipeline_total_construction_floor_area > 0 ? `${_formatNumber(item.pipeline_total_construction_floor_area)} SF` : `--`}</span>
							}
              
              <div className="mt-1">
                <span className="light-text small-text">Plans Filed{": "} 
                {moment(item.pipeline_filing_date).year() > 1970 ? _capitalizeText(moment(item.pipeline_filing_date).fromNow()) : 
                 
                  '--' }
              </span>
						</div>
						{/*<span className="todo__small-divider">|</span>
		                <span className="light-text small-text">{_formatNumber(item.inventory_total)} Total Units</span>*/}

		                
		              </div>
		            </div>
					</div>
				</Col>
			</Row>
                        
            
		  
        </CardBody>
      </Card>
    );
  }
}
export default withRouter(connect(state => ({
  user: state.user,
}))(BuyersListItem));
