import React from 'react';
import { Badge, Progress } from 'reactstrap';
import moment from "moment";

import{
	_capitalizeText,
	_formatNumber,
	_slugifyText
} from '../../helpers/utils';
import { _axiosCall } from "../../helpers/apicalls";
const apiURL = process.env.API_ROOT;

export const subscriptionPlans = [
	
	{
		id: 'pipeline-basic',
		name: 'Basic',
		pricePerMonth:14.99,
		pricePerYear:240,
		billingFrequency:'month',
		billingFrequencyMonths:12,
		description:'Cancel Anytime',
		totalAtCheckout:14.99,
		freeTrial : false,
		contactUs : true,
		stripeProductId: process.env.NODE_ENV === "development" ? 'price_1HZi3xG44QC64PsROMcvDFxz' : 'price_1HZi0JG44QC64PsRACC9qKor',
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Homebuyers', 'Real Estate Agents'],
		checkTitle: '',
		dataCredits : 0,
		hidePrice:true,
		projectViews: 50,
		usersInlcuded: 1,
	},
	{
		id: 'pipeline-complete',
		name: 'Complete',
		pricePerMonth:249,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Cancel Anytime',
		totalAtCheckout:249,
		freeTrial : true,
		contactUs : true,
		stripeProductId: process.env.NODE_ENV === "development" ? 'price_1I75VmG44QC64PsRiKWWdjQJ' : 'price_1I75XFG44QC64PsRtPbcXYpV',
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Real Estate Brokers', 'CRE Brokers', 'Mortgage & Banking', 'Private Equity', 'Title', 'Legal', 'Developers'],
		checkTitle: '',
		dataCredits : 50,
		hidePrice:true,
		projectViews: 500,
		usersInlcuded: "Number of Users Needed",
		upTo:true
	},

	{
		id: 'pipeline-enterprise',
		name: 'Enterprise',
		pricePerMonth:1999,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Contact Us',
		totalAtCheckout:1999,
		freeTrial : false,
		contactUs : true,
		stripeProductId: process.env.NODE_ENV === "development" ? 'price_1IHaZAG44QC64PsRIOwydeuU' : 'price_1IHabzG44QC64PsRkp84BqGF',
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Real Estate Brokers', 'CRE Brokers', 'Mortgage & Banking', 'Private Equity', 'Title', 'Legal', 'Developers'],
		hidePrice:true,
		checkTitle: "",
		dataCredits : 50,
		projectViews: "Unlimited",
		usersInlcuded: "Number of Users Needed",
	
	},
	
];


export const appFeatures = [
	{
		name: 'Users Included',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Monthly Project Views',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: ' Pipeline Daily (Email)',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Weekly Pipeline Update (Email)',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Pipeline News',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Pre-development Project Search',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Construction & Operational Project Search',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},	
	{
		name: 'Pipeline Project Map Search',
		plans: ['pipeline-basic', 'pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},	
	{
		name: 'Pipeline Contacts',
		plans: ['pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Company Search',
		plans: ['pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'People Search',
		plans: ['pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Project Sharing',
		plans: ['pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Create Collections',
		plans: ['pipeline-complete', 'pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Pipeline Project Analysis',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},	
	{
		name: 'Construction Activity - Analysis',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},
	
	{
		name: 'Construction Activity - Details',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Land / Building Transfer Search',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},
	
	{
		name: 'Land / Building Transfer Map',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},
	// {
	// 	name: 'Buyer / Seller Search',
	// 	plans: ['pipeline-complete','pipeline-enterprise'],
	// 	description: ''
	// },
	// {
	// 	name: 'Architects - Analysis',
	// 	plans: ['pipeline-complete','pipeline-enterprise'],
	// 	description: ''
	// },
	// {
	// 	name: 'Developers - Analysis',
	// 	plans: ['pipeline-complete','pipeline-enterprise'],
	// 	description: ''
	// },
	{
		name: 'Project Permit / Violation Details',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Project Financing Details',
		plans: ['pipeline-complete','pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Single Sign-On',
		plans: ['pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Access to Marketproof Team',
		plans: ['pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Introductions & Networking',
		plans: ['pipeline-enterprise'],
		description: ''
	},
	{
		name: 'Research On Demand',
		plans: ['pipeline-enterprise'],
		description: ''
	}
	

]

export const dataFeatures = [
	
	{
		name: 'No Tokens Inlcuded',
		plans: ['pipeline-complete', 'pipeline-enterprise'],
		description: 'Tokens allow you to access contact information such as email and phone number',
	}

]

export const coreTranslate = {
	"we3-properties" : "unit",
	"we3-buildings" : "building",
	"we3-activities-3" : "activity",
	"we3-transactions" : "sale"
}

export const dataExportCreditMap = {
	"building" : 1,
	"unit" : 15,
	"activity" : 50,
	"sale" : 15,
}
export const contactCreditMap = {
	"contact" : 100
}

export const getCreditsAllowed = (user) => {
	let credits = 0;
	const { subscriptionPlan, monthlyContactUnlockCreditOverride } = user; 
	if(typeof monthlyContactUnlockCreditOverride ==='number' && monthlyContactUnlockCreditOverride >= 0){
		credits =  monthlyContactUnlockCreditOverride;
		return credits;
	}
	const userRoles = !Array.isArray(user.role) ? [] : user.role.slice(0);
	if(userRoles && Array.isArray(userRoles)){ 
		userRoles.map(role => {
			subscriptionPlans.map(plan => {
				if(plan.id === role && plan.dataCredits > credits){
					credits = plan.dataCredits;
				}	
			})
			
		})
		
	}
	return credits;
	
}
export const maxDownload = (core_name, user) => {
	let dataExportCreditMap = {
		"building" : 100,
		"unit" : 500,
		"activity" : 2000,
		"sale" : 2000
	}
	if(user && user.role.indexOf('admin')>=0){
		dataExportCreditMap = {
			"building" : 9999999,
			"unit" : 9999999,
			"activity" : 9999999,
			"sale" : 9999999
		}
	}
	return dataExportCreditMap[coreTranslate[core_name]];
}


export const calculateCredits = (core_name, rows, facets) => {
	
	let total_records = 0;
	if(facets){
		if(coreTranslate[core_name] == "building"){			
			if(facets.by_units && facets.by_units.buckets){
				facets.by_units.buckets.forEach(bucket => {					
					if(bucket.count){
						const multiplier = (bucket.val + 50) / 50;
						total_records += bucket.count * multiplier;
					}	
				})
				
			}
			
		}else if(coreTranslate[core_name] == "unit"){
			if(facets.by_status && facets.by_status.buckets){
				facets.by_status.buckets.forEach(bucket => {					
					if(bucket.count){
						const multiplier = bucket.val == "shadow" ? 5 : 1;
						total_records += bucket.count * multiplier;
					}	
				})
				
			}			
		}
	}else{
		total_records = rows;
	}
	
	return Math.ceil(total_records / dataExportCreditMap[coreTranslate[core_name]]);	
}

export const costPerCredit = 5; 

export const translateCreditToDollars = (credits) => {
	return credits * costPerCredit;
}

export const DataCreditUsageProgress = ({ credit_used, credit_total, maxWidth, label, color, usage } ) => { 
		
		let creditAvail = credit_total - credit_used;
		if(creditAvail < 0) creditAvail = 0;
		
		let progress = parseFloat(((creditAvail) / credit_total) * 100);		
		let credit_limit_used = credit_used > credit_total ? credit_total : credit_used;		
		
		if(usage){
			return (
		      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :'100%' }} >
		        <div className="dashboard__stat">
		          <div className={`progress-wrap progress-wrap--big`}>	          
		            <Progress multi>
		            	<Progress bar value={credit_limit_used} max={credit_total}>
		            		<span>{credit_limit_used ? _formatNumber(credit_limit_used) : '0'} of {credit_total > 0 ? _formatNumber(credit_total) : '0'} <span className="small-text light-text" style={{color:'#FFF'}}>Included</span></span>
		            	</Progress>
		            	
		            </Progress>
		          </div>
		        </div>
		      </div>
		    ); 
		}
		return (
	      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :'100%' }} >
	        <div className="dashboard__stat">
	          <div className={`progress-wrap reverse progress-wrap--middle progress-wrap--${color ? color : 'purple'}-gradient progress-wrap--rounded`}>	          
	            <p className="progress__label" style={{fontSize:13}}><span className="semi-bold-text">{creditAvail > 0 ? _formatNumber(creditAvail) : '0'} of {credit_total > 0 ? _formatNumber(credit_total) : '0'}</span> tokens available</p>	           
	            <Progress value={progress} />
	          </div>
	        </div>
	      </div>
	    );  

}

export const _isPipelineSubscriber = (user, projectID) => {
   return user && user.role && user.role.filter(r => ['pipeline-complete', 'pipeline-basic', 'pipeline-enterprise'].indexOf(r)>-1).length>0
}

export const _currentPipelinePlan = (user) => {
	
	 
	if(_isPipelineSubscriber(user)){
		let currentPlan = user.role.filter(r => ['pipeline-complete', 'pipeline-basic', 'pipeline-enterprise'].indexOf(r)>-1)[0];
		
		if(currentPlan){
			  currentPlan = subscriptionPlans.filter(subPlan => subPlan.id === currentPlan)[0];
			  return currentPlan;
		}
	}
	return false;
}

export const _isNewDevSubscriber = (user) => {
   return user && user.subscriptionPlan
}


export const _getUserProjectViews = async (user, currentProjectId) => {
	  const dayOfMonth = user.pipelineViewResetDayOfMonth ? user.pipelineViewResetDayOfMonth : 1;
	  var date = new Date(), d = date.getDate(), y = date.getFullYear(), m = date.getMonth();
	  if(dayOfMonth > d){
		  m--;
	  }
	  var firstDay = Math.floor(new Date(y, m, dayOfMonth).getTime() / 1000);
	  var lastDay = Math.floor(new Date(y, m, dayOfMonth-1).getTime() / 1000);
	  let dateQuery = {
		  "$gte": moment.unix(firstDay).format('YYYY-MM-DD'), "$lt" : moment.unix(lastDay).add(1, 'month').format('YYYY-MM-DD')
	  }
	const queryData = [
	  {
		  "$addFields": {
			  "formattedDate": {
				  "$dateToString": {
					  "format": "%Y-%m-%d",
					  "date": "$created_timestamp"
				  }
			  }
		  }
	  },
	  {
		$match: {
		  user_key: { $in: [user.id, user.id.toString().toLowerCase()] },
		  "formattedDate": dateQuery
		},
	  },

	  {
		$facet: {
		  projects: [
			{
			  $match: {
				event_type: "projectView",
			  },
			},

			{
			  $group: {
				_id: {
				  property: "$project.key",
				},
				created_timestamp: { $max: "$created_timestamp" },
				count: {
				  $sum: 1,
				},
			  },
			},
			{
			  $sort: {
				created_timestamp: -1,
			  },
			},
			{
			  $limit: 999,
			},
		  ]
		},
	  },
	];

	let axiosData = {
	  url: apiURL + "/buyerslist/event/aggregate?cache=refresh",
	  method: "post",
	  query: queryData,
	};

	let response = await _axiosCall(axiosData)
	  .then((res) => {
		console.log(res)
		const projectIDs = [];
		
		let planViews = {
			viewed: 0,
			allowed: 0,
			limitReached: false
		}
		if (res && res[0]) {
		  if (res[0]["projects"] && res[0]["projects"].length > 0) {
			res[0]["projects"].map((building) => {
			  projectIDs.push(building._id.property);
			});
			
		  } 
		}
		let currentPlan = _currentPipelinePlan(user);
		planViews.viewed = projectIDs.length;
		
		if(!projectIDs.includes(currentProjectId)){
			planViews.viewed++;
		}
		
		if( user && user.pipelineProjectViews && user.pipelineProjectViews > 0){
			planViews.allowed = user.pipelineProjectViews;
			if(!isNaN(planViews.allowed) && planViews.viewed>=currentPlan.projectViews){
				planViews.limitReached = true;
			}
		}else if(currentPlan && currentPlan.projectViews){
			planViews.allowed = currentPlan.projectViews;
			if(!isNaN(planViews.allowed) && planViews.viewed>=currentPlan.projectViews){
				planViews.limitReached = true;
			}
		}
		return planViews;
		
	  })
	  .catch((error) => {
		console.log("error: " + error);
	  });
	  
	  return response;
  };